import { styled } from '@mui/material'
import { HTMLAttributes } from 'react'
import { useNavigate } from 'react-router-dom'

import { CalendarSyncStatus, Role, useBaseProviderQuery } from '@nuna/api'
import { useHasRole } from '@nuna/auth'
import {
  IconCalendar,
  IconSettings,
  OutlineButton,
  StatusLabel,
  greySet,
  makeTypographyComponent,
  progressIndicator,
} from '@nuna/tunic'

import { getCalendarStatusLabel, getCalendarStatusLabelIntent } from '../utils/calendarSync'
import { GreyBox } from './ProviderLayout'

interface Props extends HTMLAttributes<HTMLDivElement> {
  providerId?: string | null
}

export function CalendarSyncWidget({ providerId }: Props) {
  const isAdmin = useHasRole(Role.Admin)
  const navigate = useNavigate()
  const providerIdToLookup = providerId || ''

  const { error, data, loading } = useBaseProviderQuery({
    variables: { id: providerIdToLookup },
    skip: !providerIdToLookup,
    fetchPolicy: 'cache-and-network',
  })

  const status = data?.provider?.calendarAccountSyncStatus

  const navigateToCalendarSettings = () => {
    if (isAdmin) {
      navigate(`/providers/${providerIdToLookup}/preferences/calendar`)
    } else {
      navigate('/me/preferences/calendar')
    }
  }

  if (error || loading || !data?.provider) {
    return null
  }

  switch (status) {
    case CalendarSyncStatus.Connected:
    case CalendarSyncStatus.SettingUp:
    case CalendarSyncStatus.SyncError:
      return (
        <StatusBarContainer className="v-align ml-auto" onClick={navigateToCalendarSettings} hover={true}>
          <Heading>
            <span className="flex-row mr-1 v-align">
              <span className="text-bold">Calendar Sync</span>
            </span>
          </Heading>

          <div className="flex-end v-align">
            <StatusLabel intent={getCalendarStatusLabelIntent(status)} scheme="light">
              {getCalendarStatusLabel(status)}
            </StatusLabel>
            {status !== CalendarSyncStatus.SettingUp && <IconSettings className="ml-2" />}
            {status === CalendarSyncStatus.SettingUp && (
              <img src={progressIndicator} className="ml-1 mr-xs flex-static" alt="" />
            )}
          </div>
        </StatusBarContainer>
      )
    default:
      return (
        <OutlineButton variant="primary" onClick={navigateToCalendarSettings} style={{ cursor: 'pointer' }}>
          <IconCalendar className="mr-1" />
          Sync Calendar
        </OutlineButton>
      )
  }
}

const Heading = makeTypographyComponent('body mb-0 text-bold', 'h2')

const StatusBarContainer = styled(GreyBox)<{ hover: boolean }>`
  display: flex;
  flex-direction: row;
  align-items: center;
  min-height: 60px;
  min-width: 320px;
  justify-content: space-between;

  &:hover {
    ${props => props.hover && `border: 1px solid ${greySet[30].hex};`}
    ${props => props.hover && `cursor: pointer;`}
  }
`
