import { css, styled } from '@mui/material'
import { NavLink, NavLinkProps } from 'react-router-dom'

import { greySet, salmonSet, tealSet, white, whiteTintSet } from '../../styles/colorSets'
import { body2, error } from '../../styles/colors'
import { csx } from '../../utils/csx'
import { styledUtils } from '../../utils/styled'
import { Tooltip } from '../Tooltip/Tooltip'
import { ButtonProps } from './ButtonBase'

const { transientPropOptions } = styledUtils

type Variant = 'primary' | 'secondary' | 'dark' | 'destroy'

export interface IconButtonProps extends Omit<ButtonProps, 'isLoading' | 'as'> {
  tooltip: string
  active?: boolean
  variant?: Variant
  small?: boolean
  customSize?: number
}

interface IconButtonStyleProps {
  $variant?: Variant
  $small?: boolean
  $customSize?: number
}

const styles = ({ $variant = 'primary', $small = false, $customSize }: IconButtonStyleProps) => css`
  align-items: center;
  color: ${(() => {
    if ($variant === 'secondary') {
      return body2
    }

    if ($variant === 'destroy') {
      return error
    }

    if ($variant === 'dark') {
      return white.hex
    }

    return tealSet.primary.hex
  })()};
  display: inline-flex;
  justify-content: center;
  position: relative;

  ${(() => {
    const size = $customSize ? `${$customSize}px` : $small ? '40px' : '46px'

    return `
    height: ${size};
    width: ${size};
  `
  })()}

  svg {
    z-index: 0;
  }

  &:hover {
    cursor: pointer;
  }

  &.button-active {
    color: ${(() => {
      if ($variant === 'dark') {
        return tealSet[90].hex
      }

      return tealSet[80].hex
    })()};
    &::before {
      transform: scale(1);
      transition: transform 200ms ease-in-out;
      background-color: ${(() => {
        if ($variant === 'dark') {
          return tealSet[30].hex
        }

        return tealSet[5].hex
      })()};
      border: 2px solid;
      border-color: ${(() => {
        if ($variant === 'dark') {
          return tealSet[15].hex
        }

        return tealSet[50].hex
      })()};
    }

    &:hover {
      &::before {
        background-color: ${(() => {
          if ($variant === 'secondary') {
            return greySet.tint[40]
          }

          if ($variant === 'destroy') {
            return salmonSet.tint[40]
          }

          if ($variant === 'dark') {
            return whiteTintSet[15]
          }

          return tealSet.tint[40]
        })()};
        border: none;
      }
    }
  }

  &::before {
    background-color: ${(() => {
      if ($variant === 'secondary') {
        return greySet.tint[40]
      }

      if ($variant === 'destroy') {
        return salmonSet.tint[40]
      }

      if ($variant === 'dark') {
        return whiteTintSet[15]
      }

      return tealSet.tint[40]
    })()};
    border-radius: 50%;
    content: '';
    height: 100%;
    left: 0;
    position: absolute;
    transition: transform 150ms ease-in-out;
    transform: scale(0);
    top: 0;
    width: 100%;
  }

  &:focus {
    outline: none;
  }

  &:hover,
  &:focus {
    color: ${(() => {
      if ($variant === 'secondary') {
        return greySet[80].hex
      }

      if ($variant === 'destroy') {
        return error
      }

      if ($variant === 'dark') {
        return white.hex
      }

      return tealSet[80].hex
    })()};

    &::before {
      transform: scale(1);
      transition: transform 200ms ease-in-out;
    }
  }

  &:disabled,
  &.disabled {
    cursor: not-allowed;
    opacity: 0.4;

    &::before {
      display: none;
    }
  }
`

const IconButtonBase = styled('button', transientPropOptions)<IconButtonStyleProps>`
  ${styles}
`

export function IconButton({ tooltip, active, className, small, variant, customSize, ...props }: IconButtonProps) {
  return (
    <Tooltip content={tooltip}>
      <IconButtonBase
        aria-label={tooltip}
        className={csx([{ 'button-active': !!active }, className])}
        $small={small}
        $variant={variant}
        $customSize={customSize}
        {...props}
      />
    </Tooltip>
  )
}

interface IconButtonLinkProps extends NavLinkProps {
  tooltip: string
  variant?: Variant
  small?: boolean
  customSize?: number
}

const IconButtonLinkBase = styled(NavLink, transientPropOptions)<IconButtonStyleProps>`
  ${styles}
`

export function IconButtonLink({ tooltip, small, variant, customSize, ...props }: IconButtonLinkProps) {
  return (
    <Tooltip content={tooltip}>
      <IconButtonLinkBase aria-label={tooltip} $small={small} $variant={variant} $customSize={customSize} {...props} />
    </Tooltip>
  )
}

interface IconButtonExternalLinkProps extends React.HTMLAttributes<HTMLAnchorElement> {
  tooltip: string
  href: string // y tho?
  download?: string | boolean
  variant?: Variant
  small?: boolean
  customSize?: number
}

const IconButtonExternalLinkBase = styled('a', transientPropOptions)<IconButtonStyleProps>`
  ${styles}
`

IconButtonExternalLinkBase.defaultProps = {
  target: '_blank',
  rel: 'noreferrer noopener',
}

export function IconButtonExternalLink({ tooltip, small, variant, customSize, ...props }: IconButtonExternalLinkProps) {
  return (
    <Tooltip content={tooltip}>
      <IconButtonExternalLinkBase
        aria-label={tooltip}
        $small={small}
        $variant={variant}
        $customSize={customSize}
        {...props}
      />
    </Tooltip>
  )
}

const IconButtonLabelBase = styled('label')<IconButtonStyleProps>`
  ${styles}
`

interface IconButtonLabelProps extends React.HTMLAttributes<HTMLLabelElement> {
  tooltip: string
  variant?: Variant
  small?: boolean
  customSize?: number
}

export function IconButtonLabel({ tooltip, small, variant, customSize, ...props }: IconButtonLabelProps) {
  return (
    <Tooltip content={tooltip}>
      <IconButtonLabelBase aria-label={tooltip} $small={small} $variant={variant} $customSize={customSize} {...props} />
    </Tooltip>
  )
}
