import { styled } from '@mui/material'
import { GraphQLError } from 'graphql'
import moment from 'moment'

import {
  DocumentType,
  Patient,
  Role,
  UserDocumentDownloadFragment,
  UserDocumentStatus,
  useDocumentUpload,
  useSaveUserDocumentMutation,
  useUserDocumentsQuery,
} from '@nuna/api'
import { LoginData, hasRole } from '@nuna/auth'
import { ReadOnlyProfileSection } from '@nuna/common'
import { Card, FileTypeIcon, FileUpload, IconDownload, IconTrash, Tooltip, toast } from '@nuna/tunic'

interface ClientProfileDocumentsProps {
  patient: Pick<Patient, 'nativeIntakeBlob' | 'typeformIntakeBlob' | 'loginId'> | undefined
  loginData: LoginData
}

const heading = 'Documents'

export function ClientProfileDocuments({ patient, loginData }: ClientProfileDocumentsProps) {
  const { uploadDocument, loading: uploadLoading } = useDocumentUpload()
  const [saveUserDocument, { loading: saveLoading }] = useSaveUserDocumentMutation()

  const {
    data,
    loading: getLoading,
    refetch,
  } = useUserDocumentsQuery({
    variables: {
      searchOptions: {
        createdBy: hasRole(loginData.role, Role.Provider) ? loginData.id : undefined,
        loginId: patient?.loginId,
        status: UserDocumentStatus.Default,
      },
      includeDownloadUrl: true,
    },
    skip: !patient?.loginId,
  })

  const handleOnSelection = async (files: File[]) => {
    try {
      if (!files.length) return

      for (const file of files) {
        const document = await uploadDocument(file, DocumentType.UserCustomDocument)

        const { errors: userDocumentErrors } = await saveUserDocument({
          variables: {
            userDocument: {
              documentId: document.id,
              loginId: patient?.loginId,
            },
          },
        })

        if (userDocumentErrors?.length) {
          throw new Error(userDocumentErrors[0].message)
        }

        await refetch({
          searchOptions: {
            createdBy: hasRole(loginData.role, Role.Provider) ? loginData.id : undefined,
            loginId: patient?.loginId,
            status: UserDocumentStatus.Default,
          },
        })
      }
    } catch (e) {
      toast.urgent('There was an error uploading client document')
      console.error(e)
    }
  }

  const handleOnRemove = async (userDocument: UserDocumentDownloadFragment) => {
    try {
      const { errors: userDocumentErrors } = await saveUserDocument({
        variables: {
          userDocument: {
            id: userDocument.id,
            documentId: userDocument.document.id,
            status: UserDocumentStatus.Archived,
          },
        },
      })

      if (userDocumentErrors?.length) {
        throw new Error(userDocumentErrors[0].message)
      }

      await refetch({
        searchOptions: {
          createdBy: hasRole(loginData.role, Role.Provider) ? loginData.id : undefined,
          loginId: patient?.loginId,
          status: UserDocumentStatus.Default,
        },
      })
    } catch (e) {
      toast.urgent((e as GraphQLError).message)
      console.error(e)
    }
  }

  const loading = saveLoading || getLoading || uploadLoading

  if (!patient) {
    return <ReadOnlyProfileSection isLoading heading={heading} renderDisplayValue={null} />
  }

  return (
    <ReadOnlyProfileSection
      heading={heading}
      displayWidth="full"
      renderDisplayValue={
        <>
          {data?.userDocuments.map(userDocument => {
            return (
              <FileDisplayDiv key={userDocument.document.fileName}>
                <div className="v-align">
                  <FileTypeIcon mimeType={userDocument.document.documentType} className="mr-1" />
                  <Tooltip content={userDocument.document.fileName}>
                    <span className="text-medium mr-1">{userDocument.document.fileName}</span>
                  </Tooltip>
                  <span>{moment(userDocument.document?.createdAt).format('YYYY/MM/DD')}</span>
                </div>

                <div className="v-align">
                  <a className="ml-auto" href={userDocument.document?.downloadUrl || undefined} download>
                    <IconDownload
                      style={{ justifySelf: 'flex-end', cursor: 'pointer', opacity: loading ? '0.5' : 'initial' }}
                      size={16}
                    />
                  </a>

                  <IconTrash
                    className="ml-2"
                    style={{ justifySelf: 'flex-end', cursor: 'pointer', opacity: loading ? '0.5' : 'initial' }}
                    onClick={() => !loading && handleOnRemove(userDocument)}
                  />
                </div>
              </FileDisplayDiv>
            )
          })}

          <FileUpload
            fit="fixed"
            accept={[
              'application/msword', // doc
              'application/pdf',
              'application/rtf',
              'application/vnd.oasis.opendocument.text', // odt
              'application/vnd.openxmlformats-officedocument.wordprocessingml.document', // docx
              'application/x-tex',
              'image/jpeg',
              'image/png',
              'image/svg+xml',
              'text/plain',
            ]}
            renderInlineDisplay={false}
            multiple={true}
            onDrop={handleOnSelection}
            requirementDescription="Accepts multiple files at once"
            size="sm"
            disabled={loading}
          />
        </>
      }
    />
  )
}

const FileDisplayDiv = styled(Card)`
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 56px;
  margin-bottom: 16px;
  padding: 8px 12px 8px 12px;
`
