import { IconProps } from './IconProps'

export function IconLeading({ size = 19, color = 'currentColor', ...props }: IconProps) {
  return (
    <svg width={size} height={size} viewBox="0 0 19 18" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.56447 11.2236C3.67013 11.2236 4.56644 10.3061 4.56644 9.1743C4.56644 8.0425 3.67013 7.125 2.56447 7.125C1.45881 7.125 0.5625 8.0425 0.5625 9.1743C0.5625 10.3061 1.45881 11.2236 2.56447 11.2236ZM16.5605 11.2236C17.6662 11.2236 18.5625 10.3061 18.5625 9.1743C18.5625 8.0425 17.6662 7.125 16.5605 7.125C15.4549 7.125 14.5586 8.0425 14.5586 9.1743C14.5586 10.3061 15.4549 11.2236 16.5605 11.2236ZM11.5822 9.1743C11.5822 10.3061 10.6859 11.2236 9.58022 11.2236C8.47456 11.2236 7.57825 10.3061 7.57825 9.1743C7.57825 8.0425 8.47456 7.125 9.58022 7.125C10.6859 7.125 11.5822 8.0425 11.5822 9.1743Z"
        fill={color}
      />
    </svg>
  )
}
