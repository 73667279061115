import { styled } from '@mui/material'
import { useFormikContext } from 'formik'
import { isNil } from 'lodash'
import moment from 'moment'
import { ReactNode, useEffect } from 'react'

import { formService } from '@nuna/core'
import {
  Card,
  DesktopLarge,
  IconAISparkles,
  IconNotebook,
  TextButton,
  TextField,
  body2,
  interactiveText,
  makeTypographyComponent,
  paragraphsLineHeight,
} from '@nuna/tunic'

import { useSaveSessionDraft } from '../../../hooks/useSaveSessionDraft'
import { SessionData } from '../../../types'
import { hasAutonote, hasAutonoteError } from '../../../utils'
import { DraftSavingStatus } from '../../DraftSavingStatus'
import loadingGifSrc from '../img/auto-note-loading.gif'
import errorImgSrc from '../img/big-scribble.png'
import { InputHeading } from './InputHeading'
import { SessionNoteFormValues } from './SessionNoteForm'

const { composeHelperTextWithError } = formService

interface Props {
  sessionData: SessionData
  disabled?: boolean
  renderCardFooter?: ReactNode
}

export function NoteField({ sessionData, renderCardFooter = null, disabled = false }: Props) {
  const { values, errors, touched, handleChange, handleBlur, setFieldValue } = useFormikContext<SessionNoteFormValues>()
  const { loading, lastSavedAt } = useSaveSessionDraft({
    draft: {
      ...values,
      therapyTypeSpecialtyId: values.therapyTypeSpecialtyId ?? '',
      schemaVersion: '2.0',
      note: sessionData.inSessionNotes ?? null,
    },
    appointmentId: sessionData.appointmentId,
    patientId: sessionData.patient.id,
    sessionId: sessionData.sessionId,
  })

  useEffect(() => {
    if (sessionData.workingDraft && isNil(values.workingDraft)) {
      // sets the value if the auto note is in a loading state and becomes available
      setFieldValue('workingDraft', sessionData.workingDraft)
    }
  }, [sessionData.workingDraft, setFieldValue, values.workingDraft])

  const handleAutonoteBypassClick = () => {
    setFieldValue('workingDraft', sessionData.inSessionNotes ?? '')
  }

  const isAutonoteLoading = isNil(values.workingDraft)
  const isAutonoteErrored = isNil(values.workingDraft) && hasAutonoteError(sessionData)

  return (
    <StyledCard depth={-0.5}>
      {(() => {
        if (isAutonoteErrored) {
          return (
            <LoadingContainer className="paragraphs">
              <h5 className="h4 text-default mt-5">Error while generating the session note.</h5>
              <ErrorImage src={errorImgSrc} alt="" className="mt-3" />
              <p className="mb-2">We apologize for the inconvenience.</p>
              <p className="mb-5">
                <TextButton className="mb-3" onClick={handleAutonoteBypassClick}>
                  Click here
                </TextButton>{' '}
                to write your own.
              </p>
            </LoadingContainer>
          )
        } else if (isAutonoteLoading) {
          return (
            <LoadingContainer className="paragraphs">
              <LoadingGif src={loadingGifSrc} alt="" className="mt-5" />
              <h5 className="h4 text-default mb-3">Tava Scribe is generating the session note...</h5>
              <p className="mb-2">Hang tight while Tava Scribe works on your note.</p>
              <p className="mb-5">
                Taking too long?
                <br />
                <TextButton className="mb-3" onClick={handleAutonoteBypassClick}>
                  Click here
                </TextButton>{' '}
                to write your own.
              </p>
            </LoadingContainer>
          )
        } else {
          return (
            <>
              <div className="v-align">
                <InputHeading className="text-default mb-0 v-align">
                  {(() => {
                    if (sessionData.sessionSubmittedAt) {
                      return (
                        <>
                          <IconNotebook className="mr-1" size={20} color={interactiveText} /> Submitted{' '}
                          {sessionData.sessionSubmittedAt.format('M/DD/YY')}
                        </>
                      )
                    }

                    if (hasAutonote(sessionData)) {
                      return (
                        <>
                          <IconAISparkles className="mr-1" size={20} color={interactiveText} /> Tava Scribe draft
                        </>
                      )
                    }

                    return (
                      <>
                        <IconNotebook className="mr-1" size={20} color={body2} /> Session note draft
                      </>
                    )
                  })()}
                </InputHeading>

                {!sessionData.sessionId && (
                  <span className="ml-auto text-medium">
                    Submit by {moment(sessionData.scheduledStartTime).add(72, 'hours').format('M/DD')}
                  </span>
                )}
              </div>

              {sessionData.sessionId && disabled ? (
                <div className="pre-line paragraphs text-secondary mt-3">{values.workingDraft}</div>
              ) : (
                <TextField
                  disabled={disabled}
                  multiline
                  rows={4}
                  value={values.workingDraft}
                  onChange={handleChange}
                  name="workingDraft"
                  variant="outlined"
                  onBlur={handleBlur}
                  className="fs-exclude"
                  {...composeHelperTextWithError('', errors.workingDraft, touched.workingDraft)}
                />
              )}
            </>
          )
        }
      })()}
      {!isAutonoteLoading && !disabled && (
        <DraftSavingStatus sx={{ pt: 1 }} loading={loading} lastSavedAt={lastSavedAt} />
      )}
      {renderCardFooter}
    </StyledCard>
  )
}

const StyledCard = styled(Card)`
  padding: var(--spacing-3) var(--spacing-2);

  @media (${DesktopLarge}) {
    padding: var(--spacing-3);
  }

  textarea {
    line-height: ${paragraphsLineHeight};
  }
`

const LoadingContainer = styled(makeTypographyComponent('mx-auto text-center', 'div'))`
  max-width: 385px;
`

const LoadingGif = styled('img')`
  max-width: 300px;
  width: 100%;
`

const ErrorImage = styled('img')`
  max-width: 150px;
  width: 100%;
`
