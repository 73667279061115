import { ProviderDataField, ProviderDataSummaryFragment } from '@nuna/api'

function getRequiredItems(items: ProviderDataSummaryFragment[]) {
  return items.filter(item => item.required)
}

export function getProviderDataItem(field: ProviderDataField, items: ProviderDataSummaryFragment[]) {
  return items.find(item => item.field === field) ?? { hasValue: false }
}

function allRequirementsMet(items: ProviderDataSummaryFragment[]) {
  return getRequiredItems(items).every(item => item.hasValue)
}

export const providerDataSummaryUtils = {
  getRequiredItems,
  allRequirementsMet,
}
