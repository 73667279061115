import mixpanel from 'mixpanel-browser'
import { useEffect, useState } from 'react'

import {
  type CancelPolicyFragment,
  type PaymentPreference,
  type ProviderDetailsQuery,
  type PublicAvailabilitySlot,
} from '@nuna/api'
import { DrawerStepper } from '@nuna/common'
import { LateFeePolicyForm, LateFeePolicyIntro } from '@nuna/fee-policies'

import { AppointmentDrawerPaddedContainer } from '../../../AppointmentDrawer'
import { AppointmentDrawerHeader } from './AppointmentDrawerHeader'

interface AcceptLateFeeProps {
  provider: ProviderDetailsQuery['provider']
  timeSlot: PublicAvailabilitySlot
  cancelPolicy: CancelPolicyFragment
  paymentPreference?: PaymentPreference | null
}

export function AcceptLateFee({ provider, timeSlot, cancelPolicy, paymentPreference }: AcceptLateFeeProps) {
  useEffect(() => {
    mixpanel.track('saw late policy acceptance', { providerId: provider.id })
  }, [provider])

  const [showIntro, setShowIntro] = useState(true)

  return (
    <>
      <AppointmentDrawerHeader provider={provider} timeSlot={timeSlot} />

      <AppointmentDrawerPaddedContainer>
        {showIntro ? (
          <>
            <LateFeePolicyIntro provider={provider} />
            <DrawerStepper
              className="mt-6"
              nextButtonText="Review Policy"
              nextButtonProps={{ 'data-testid': 'signup-submit' }}
              onNextButtonClick={() => setShowIntro(false)}
            />
          </>
        ) : (
          <LateFeePolicyForm
            cancelPolicy={cancelPolicy}
            provider={provider}
            paymentPreference={paymentPreference}
            renderButtons={loading => (
              <DrawerStepper
                className="mt-6"
                loading={loading}
                nextButtonText="Accept"
                nextButtonProps={{ 'data-testid': 'signup-submit', type: 'submit' }}
                onBackButtonClick={() => setShowIntro(true)}
              />
            )}
          />
        )}
      </AppointmentDrawerPaddedContainer>
    </>
  )
}
