import { styled } from '@mui/material'
import { Moment } from 'moment'

import { AppointmentSlotFragment } from '@nuna/api'
import { Card, greySet, shadowDepth } from '@nuna/tunic'

interface AppointmentSlotWithSkippedSlots extends AppointmentSlotFragment {
  skippedSlots?: AppointmentSlotFragment[]
  addressId?: string
}

export interface EditableAppointmentSlot {
  slot: AppointmentSlotWithSkippedSlots
  included: boolean
  newStart?: Moment
  newLocation?: string
}

export interface AppointmentFormValues {
  patientId: string
  appointments: EditableAppointmentSlot[]
  singleStartTime?: string
  force: boolean
  singleAddressId?: string | 'Virtual'
  multiAddressId?: string | 'Virtual'
}

export const NewCard = styled(Card)<{ inactive?: boolean }>`
  background-color: ${props => (props.inactive ? greySet[15].hex : '#fff')};
  border: 1px solid ${props => (props.inactive ? greySet[30].hex : greySet[15].hex)};
  border-radius: var(--border-radius);
  box-shadow: ${shadowDepth(1)};
  padding: 1rem;

  ${props => props.inactive && `color: ${greySet[80].hex};`};
`
