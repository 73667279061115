import { isNil } from 'lodash'
import { useMemo } from 'react'

import { InsuranceCoverageDetailsFragment } from '@nuna/api'
import { numberService } from '@nuna/core'
import { CoverageList, CoverageListItem, InsuranceCoverageCard } from '@nuna/coverage'
import { IconDollarSymbol } from '@nuna/tunic'

interface Props {
  coverage: InsuranceCoverageDetailsFragment
}

export function InsuranceCoverageGlance({ coverage }: Props) {
  const { copay } = coverage
  const formattedCopay = useMemo(() => (!isNil(copay) ? numberService.centsToFormattedDollars(copay) : ''), [copay])
  return (
    <>
      <InsuranceCoverageCard coverage={coverage} />
      <CoverageList className="mt-2 ml-1">
        <CoverageListItem
          adornment={<IconDollarSymbol size={16} />}
          tooltip={
            !isNil(formattedCopay)
              ? `Client will be billed once their insurance has processed the claim. This will include a ${formattedCopay} copay and the balance of the session cost, if any.`
              : `We were unable to retrieve this info from their insurance provider.`
          }
        >
          {formattedCopay ? `${formattedCopay} copay + variable session costs` : 'Variable session costs: Unknown'}
        </CoverageListItem>
      </CoverageList>
    </>
  )
}
