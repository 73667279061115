import { AvailabilityConstraintInput, AvailabilityPeriods } from '../types/internal-only/availability.types'
import { timeService } from './time'

const { adjustTimeForTimeZoneChange } = timeService

function removeTypename<T extends { __typename?: string }>(obj: T) {
  const { __typename: _, ...rest } = obj
  return rest
}

type AllowDayAndTime = Omit<AvailabilityPeriods['allowDayAndTime'][number], 'id'> & { id?: string | null }
type AllowPeriod = Omit<AvailabilityPeriods['allowPeriod'][number], 'id'> & { id?: string | null }

// Need to support absent IDs here for creates
interface AvailabilityPeriodsToUpdate {
  allowDayAndTime?: AllowDayAndTime[]
  allowPeriod?: AllowPeriod[]
  blockPeriod?: Array<Omit<AvailabilityPeriods['blockPeriod'][number], 'id'> & { id?: string | null }>
  calendarBlockPeriod?: Array<Omit<AvailabilityPeriods['calendarBlockPeriod'][number], 'id'> & { id?: string | null }>
}

function constraintTimeZoneAdjustment(
  constraints: AvailabilityPeriodsToUpdate,
  newTimeZone: string,
  oldTimeZone: string,
): AvailabilityPeriodsToUpdate {
  const allowDayAndTime: AllowDayAndTime[] | undefined = constraints.allowDayAndTime
    ? constraints.allowDayAndTime.map(it => ({
        ...it,
        start: adjustTimeForTimeZoneChange({ newTimeZone, oldTimeZone, time: it.start }),
        end: adjustTimeForTimeZoneChange({ newTimeZone, oldTimeZone, time: it.end }),
      }))
    : undefined

  return {
    ...constraints,
    allowDayAndTime,
  }
}

export function getAvailabilityConstraintInput({
  availabilityConstraints,
  isAdmin,
  providerTimeZone,
  customTimeZone,
}: {
  availabilityConstraints: AvailabilityPeriodsToUpdate
  isAdmin?: boolean
  providerTimeZone?: string | null
  customTimeZone?: string | null
}): AvailabilityConstraintInput[] {
  const availabilityConstraintInput: AvailabilityConstraintInput[] = []

  if (isAdmin && providerTimeZone && customTimeZone && providerTimeZone !== customTimeZone) {
    availabilityConstraints = constraintTimeZoneAdjustment(availabilityConstraints, providerTimeZone, customTimeZone)
  }

  if (availabilityConstraints.allowDayAndTime?.length) {
    availabilityConstraintInput.push(
      ...availabilityConstraints.allowDayAndTime.map(dayAndTime => {
        const prepped = removeTypename(dayAndTime)
        return { allow_day_and_time: prepped }
      }),
    )
  }

  if (availabilityConstraints.allowPeriod?.length) {
    availabilityConstraintInput.push(
      ...availabilityConstraints.allowPeriod.map(period => ({ allow_period: removeTypename(period) })),
    )
  }

  if (availabilityConstraints.blockPeriod?.length) {
    availabilityConstraintInput.push(
      ...availabilityConstraints.blockPeriod.map(period => ({ block_period: removeTypename(period) })),
    )
  }

  return availabilityConstraintInput
}

export const availabilityService = {
  getAvailabilityConstraintInput,
}
