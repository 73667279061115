import { styled } from '@mui/material'
import { SyntheticEvent, useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'

import { InsuranceSortKeys, SearchInsurancePoliciesQuery, useSearchInsurancePoliciesQuery } from '@nuna/api'
import { Dialog, MenuItem, ResponsiveHorizontalMenu, Select, greySet, makeTypographyComponent } from '@nuna/tunic'

import { PolicyForm } from './components/PolicyForm'
import { PolicyValidations } from './components/PolicyValidations'

interface InsurancePolicyDialogProps {
  isOpen: boolean
  closeDialog: () => void
}

type InsurancePolicy = SearchInsurancePoliciesQuery['searchInsurancePolicies']['items'][number]

export function InsurancePolicyDialog({ isOpen, closeDialog }: InsurancePolicyDialogProps) {
  const [activeTab, setActiveTab] = useState<'details' | 'echecks'>('details')
  const { id = '' } = useParams<{ id: string }>()
  const { data: insurancePolicies } = useSearchInsurancePoliciesQuery({
    fetchPolicy: 'no-cache',
    variables: {
      pagination: { limit: 50 },
      order: [{ key: InsuranceSortKeys.MemberId }],
      filters: { patientId: id },
    },
  })
  const [selectedPolicy, setSelectedPolicy] = useState<InsurancePolicy>()

  useEffect(() => {
    const policies = insurancePolicies?.searchInsurancePolicies.items || []
    const newSelectedPolicy =
      policies.find(p => p.id === selectedPolicy?.id) ||
      policies.reduce((agg, policy) => {
        if (!agg) return policy
        if (policy.active) return policy
        if (policy.verifiedAt && !agg.verifiedAt) return policy
        return agg
      }, undefined as InsurancePolicy | undefined)
    setSelectedPolicy(newSelectedPolicy)
  }, [insurancePolicies, selectedPolicy, setSelectedPolicy])

  function handleSelectPolicy(event: SyntheticEvent<HTMLSelectElement>) {
    const policyId = event.currentTarget.value
    const policy = (insurancePolicies?.searchInsurancePolicies?.items || []).find(policy => policy.id === policyId)
    setSelectedPolicy(policy)
  }

  return (
    <Dialog isOpen={isOpen} maxWidth="md" onClose={() => closeDialog()}>
      <Container>
        <Header>
          <Select className="mb-2 mr-2" value={selectedPolicy?.id ?? ''} onChange={handleSelectPolicy} label="Policy">
            {(insurancePolicies?.searchInsurancePolicies?.items || []).map(policy => (
              <option key={policy.id} value={policy.id}>
                {policy.insurancePayer?.name}: {policy.memberId}
              </option>
            ))}
          </Select>
          <ResponsiveHorizontalMenu className="flex-remaining-space mr-2">
            <MenuItem
              button
              id="insurance-policy-details"
              isActive={() => activeTab === 'details'}
              onClick={() => setActiveTab('details')}
            >
              Policy Details
            </MenuItem>
            <MenuItem
              button
              id="insurance-policy-echecks"
              isActive={() => activeTab === 'echecks'}
              onClick={() => setActiveTab('echecks')}
            >
              Eligibility Checks
            </MenuItem>
          </ResponsiveHorizontalMenu>
        </Header>

        <ScrollableBody id="insurance-policy-content">
          {selectedPolicy && (
            <>
              <PolicyForm policy={selectedPolicy} visible={activeTab === 'details'} />
              <PolicyValidations policy={selectedPolicy} visible={activeTab === 'echecks'} />
            </>
          )}
        </ScrollableBody>
      </Container>
    </Dialog>
  )
}

const Header = styled(makeTypographyComponent('v-align mb-2', 'div'))`
  border-bottom: 1px solid ${greySet[15].hex};
`
const Container = styled(makeTypographyComponent('p-2 flex-column full-height overflow-hidden', 'div'))`
  min-width: 600px;
`
const ScrollableBody = makeTypographyComponent('overflow-auto flex-remaining-space', 'div')
