/* eslint-disable @typescript-eslint/no-explicit-any */

export type CoverageProviderFragment = {
  __typename?: 'CoverageProvider'
  id: string
  avatarUrl?: string | null
  cashCost?: number | null
  firstName: string
  lastName: string
  inInsuranceNetwork?: boolean | null
  therapyTypes: Array<string>
  therapyTypeItems: Array<{ id: string; name: string; enabled: boolean }>
}
export type CashCoverageDetailsFragment = {
  __typename?: 'CashCoverageDetails'
  cost: number
  cardBrand?: string | null
  cardNumber?: string | null
  cardExpiration?: string | null
  cardIsExpired?: boolean | null
  providers: Array<CoverageProviderFragment>
}
export type InsuranceCoverageDetailsFragment = {
  __typename?: 'InsuranceCoverageDetails'
  active: boolean
  subscriber: string
  planName: string
  planMemberId: string
  expirationDate?: any | null
  verifiedDate?: any | null
  cost: number
  copay: number
  individualDeductible: number
  individualSpentTowardsDeductible: number
  familyDeductible: number
  familySpentTowardsDeductible: number
  insuranceWebsiteUrl?: string | null
  insuranceLogoUrl?: string | null
  availableToDependents: boolean
  costAfterCoverage: number
  failureType?: string | null
  failureReason?: string | null
  cardFrontUrl?: string | null
  cardBackUrl?: string | null
  allowCouplesAndFamilyTherapy: boolean
  providers: Array<CoverageProviderFragment>
}
export type AccessCodeCoverageDetailsFragment = {
  __typename?: 'AccessCodeCoverageDetails'
  sessionsCap?: number | null
  sessionsExhausted: number
  sessionsScheduled: number
  cost: number
  coupon: string
  expirationDate?: any | null
  medManagement: boolean
  availableToDependents: boolean
  costAfterCoverage: number
  providers: Array<CoverageProviderFragment>
}

export type EapCoverageDetailsFragment = {
  __typename?: 'EAPCoverageDetails'
  companyId: string
  sessionsCap?: number | null
  sessionsExhausted: number
  sessionsScheduled: number
  sessionsCapExceeded: boolean
  sessionsAttended: number
  sessionsCapStatus: string
  alternateCoverageSuggestion?: PaymentPreference | null | undefined
  cost: number
  resetDate?: any | null
  medManagement: boolean
  availableToDependents: boolean
  employerLogo?: string | null
  employerName: string
  costAfterCoverage: number
  providers: Array<CoverageProviderFragment>
  referralId?: string | null
}

export type PatientCoverageDetailsFragment =
  | CashCoverageDetailsFragment
  | InsuranceCoverageDetailsFragment
  | AccessCodeCoverageDetailsFragment
  | EapCoverageDetailsFragment

export type PatientCoverageFragment = {
  type: PaymentPreference
  details: PatientCoverageDetailsFragment
}

export enum OrganizationRelationship {
  Child = 'CHILD',
  Employee = 'EMPLOYEE',
  SpousePartner = 'SPOUSE_PARTNER',
}

export type PatientIntakeContractFragment = {
  __typename?: 'PatientIntakeContract'
  companyId?: string | null
  id?: string | null
  assignedDate?: any | null
  contractId?: string | null
  contractType?: ContractType | null
  couponId?: string | null
  relatedEmployeeName?: string | null
  relationshipToOrganization?: OrganizationRelationship | null
  unassignedDate?: any | null
}

export enum CapStatus {
  Approaching = 'APPROACHING',
  Close = 'CLOSE',
  Exhausted = 'EXHAUSTED',
  Good = 'GOOD',
  Nocap = 'NOCAP',
}

export enum PaymentPreference {
  Accesscode = 'ACCESSCODE',
  Cash = 'CASH',
  Employer = 'EMPLOYER',
  Insurance = 'INSURANCE',
}

export enum ContractType {
  CashContract = 'CASH_CONTRACT',
  CompanyContract = 'COMPANY_CONTRACT',
  CouponContract = 'COUPON_CONTRACT',
  SponsoredCompanyContract = 'SPONSORED_COMPANY_CONTRACT',
}
