import { styled } from '@mui/material'
import { HTMLAttributes, useRef } from 'react'
import { useInView } from 'react-intersection-observer'
import { scrollIntoView } from 'scroll-js'

import { FAQItem, isMobile } from '@nuna/common'
import { Logo, SignupLayout as SignupLayoutComponents, TextButton, TextButtonLink, csx, plumSet } from '@nuna/tunic'

import { SignupContextProvider } from '../scenes/signup/SignupContext'
import { FAQ } from '../scenes/signup/faqItems'
import { login } from '../util/routes'
import yarn from './img/provider-signup-yarn.png'

const {
  CenteredContent,
  FAQContainer,
  FAQText,
  FloatingGetStartedButton,
  GreyBackground,
  Header,
  Heading,
  Left,
  Right,
  RightAlignedHeader,
  SignupContainer,
  Top,
  TypographyContainer,
} = SignupLayoutComponents

interface Props extends HTMLAttributes<HTMLDivElement> {
  showSignInLink?: boolean
  faqItems: FAQ[]
  maxWidth?: number
  emptyLayout?: boolean
}

export function SignupLayout({ children, showSignInLink, faqItems, maxWidth, emptyLayout }: Props) {
  const faqSectionRef = useRef<HTMLDivElement>(null)
  const formRef = useRef<HTMLDivElement>(null)
  const [inViewFormRef, isFormInView] = useInView()

  const scrollToForm = () => {
    if (formRef.current) {
      scrollIntoView(formRef.current)
    }
  }

  const scrollToFaq = () => {
    if (faqSectionRef.current) {
      scrollIntoView(faqSectionRef.current)
    }
  }

  if (emptyLayout) {
    return <EmptyLayout>{children}</EmptyLayout>
  }

  return (
    <SignupContextProvider>
      <style>{`
      body {
        overflow: auto;
      }
      
      #root {
        overflow: initial;
      }`}</style>
      <TypographyContainer style={{ width: '100%' }}>
        <FloatingGetStartedButton hidden={isFormInView} onClick={scrollToForm}>
          Get Started
        </FloatingGetStartedButton>
        <Top>
          <Left $backgroundColor={plumSet[100].hex} $backgroundImage={yarn}>
            <Header>
              <Logo scheme="dark" color="plum" height={36} />
            </Header>
            <CenteredContent>
              <Heading className="mb-3">Grow your caseload with Tava</Heading>
              <p className="large mt-0">
                Grow and manage your caseload with expedited credentialing & insurance billing, free EHR & telehealth
                tools, marketing support and more.
              </p>
              <FAQText>
                Questions? Check out the{' '}
                <TextButton scheme="dark" onClick={scrollToFaq}>
                  FAQ
                </TextButton>
              </FAQText>
            </CenteredContent>
          </Left>
          <Right ref={formRef}>
            <RightAlignedHeader className={csx({ relative: isMobile })}>
              {showSignInLink && (
                <span>
                  Have an account? <TextButtonLink to={login}>Log in</TextButtonLink>
                </span>
              )}
            </RightAlignedHeader>
            <CenteredContent ref={inViewFormRef} maxWidth={maxWidth ?? 468}>
              {children}
            </CenteredContent>
          </Right>
        </Top>
        <GreyBackground>
          <FAQContainer ref={faqSectionRef}>
            <SignupContainer>
              <h2>Frequently asked questions about Tava</h2>
              {faqItems.map(({ question, answer }) => (
                <FAQItem fullWidth question={question} answer={answer} key={question} />
              ))}
            </SignupContainer>
          </FAQContainer>
        </GreyBackground>
      </TypographyContainer>
    </SignupContextProvider>
  )
}

const EmptyLayout = styled('div')`
  width: 100%;
`
