import { styled } from '@mui/material'
import { MouseEventHandler } from 'react'

import { FillButton, IconButton, IconClose, eggshell, shadowDepth } from '@nuna/tunic'

import { ABOVE_BUTTONS } from '../util/zIndexes'

interface EndSessionWarningProps {
  onConfirmClick: MouseEventHandler<HTMLButtonElement>
  onCancelClick: MouseEventHandler<HTMLButtonElement>
}

export function EndSessionWarning({ onConfirmClick, onCancelClick }: EndSessionWarningProps) {
  return (
    <Container>
      <span className="text-bold mr-2">End session for you and your client?</span>

      <FillButton onClick={onConfirmClick} variant="destroy" className="mr-2" data-component="end-session-for-all">
        End for all
      </FillButton>

      <IconButton tooltip="Cancel" variant="secondary" onClick={onCancelClick}>
        <IconClose />
      </IconButton>
    </Container>
  )
}

const Container = styled('div')`
  align-items: center;
  background-color: ${eggshell};
  bottom: 2.5rem;
  border-radius: 6px;
  box-shadow: ${shadowDepth(4)};
  display: inline-flex;
  padding: 1rem;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  z-index: ${ABOVE_BUTTONS};
`
