import { useCallback } from 'react'

import { WhoIsResponsibleForPayment, useEstimatePaymentResponsibilityLazyQuery } from '@nuna/api'
import { toast } from '@nuna/tunic'

export function useWarnOnCashPay() {
  const [estimatePaymentResponsibility] = useEstimatePaymentResponsibilityLazyQuery()

  const warnIfAppointmentFallsToCashPay = useCallback(
    async (appointmentId: string) => {
      const result = await estimatePaymentResponsibility({ variables: { input: { appointmentId } } })
      if (!result.data) {
        console.error('Failed to estimate payment responsibility', result.error)
        return
      }

      const { estimatePaymentResponsibility: estimate } = result.data
      // Did we fall back to cash and have a reason returned?
      if (estimate.whoIsResponsibleForPayment === WhoIsResponsibleForPayment.Client && estimate.cashFallbackReason) {
        toast.caution(
          `Heads up!  This session will not be covered because ${estimate.cashFallbackReason.toLocaleLowerCase()}.`,
          { duration: Number.POSITIVE_INFINITY },
        )
      }
    },
    [estimatePaymentResponsibility],
  )

  return { warnIfAppointmentFallsToCashPay }
}
