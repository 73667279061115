import { styled } from '@mui/material'

import { BelowTablet, body1 } from '@nuna/tunic'
import { greySet } from '@nuna/tunic'

const sidebarWidth = '362px'

export const SidebarContentWrapper = styled('div')`
  display: flex;
  background-color: ${greySet[5].hex};
  border-radius: 12px;
  color: ${body1};
  flex-direction: column;
  height: 100%;
  left: 0;
  top: 0;
  min-width: ${sidebarWidth};
  @media (${BelowTablet}) {
    min-width: unset;
    width: 100%;
  }
`
