import { HistorySessionItemProps, SessionItem } from './SessionItem'

const JOINED_TAVA = 'joined tava '

export function MilestoneSessionItem({ historyItem, showDate }: HistorySessionItemProps) {
  if (historyItem.name.toLocaleLowerCase().startsWith(JOINED_TAVA)) {
    const title = historyItem.name.substring(0, JOINED_TAVA.length)
    const text = historyItem.name.substring(JOINED_TAVA.length)
    return <SessionItem showDateOutside card={false} date={showDate && historyItem.date} title={title} text={text} />
  }

  return <SessionItem showDateOutside card={false} date={showDate && historyItem.date} title={historyItem.name} />
}
