import { AddressOwner, AddressType, useProviderAddressesQuery } from '@nuna/api'

import { useHasAvailabilityForLocations } from './useHasAvailabilityForLocations'

export function useProviderLocationsHaveAvailability(providerId?: string | null) {
  const { data: addressData } = useProviderAddressesQuery({
    variables: {
      searchOptions: { providerId, owner: AddressOwner.Provider, providerAddressType: AddressType.ProviderPractice },
    },
  })

  return useHasAvailabilityForLocations(addressData?.providerAddresses)
}
