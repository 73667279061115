import { IconProps } from './IconProps'

export function IconFileVideo({ size = 24, color = 'currentColor', ...props }: IconProps) {
  return (
    <svg width={size} height={size} viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.31027 5.72027C6.31027 4.93815 6.95752 4.29613 7.74041 4.29613H16.041C16.4439 4.29613 17.1394 4.58832 17.4083 4.86498L24.1206 11.7706C24.4079 12.0662 24.6897 12.7568 24.6897 13.1669V26.2761C24.6897 27.065 24.0498 27.7039 23.2663 27.7039H7.73367C6.95025 27.7039 6.31027 27.0667 6.31027 26.2893V23.1994V9.70329V5.72027ZM4 23.1994V26.2893C4 28.334 5.67344 30 7.73367 30H23.2663C25.3281 30 27 28.3308 27 26.2761V13.1669C27 12.1605 26.4849 10.8982 25.7821 10.1752L19.0699 3.26964C18.3648 2.54426 17.0692 2 16.041 2H7.74041C5.68261 2 4 3.66902 4 5.72027V9.70329V23.1994ZM13.7181 23.5772L19.1296 20.1916C20.2631 19.4857 20.2631 18.1227 19.1282 17.416L13.719 14.0154C12.5542 13.2826 11.359 13.9429 11.359 15.3073V22.2805C11.359 23.6465 12.5555 24.3058 13.7181 23.5772ZM12.8991 15.3073C12.8991 15.2258 12.9096 15.1723 12.9207 15.1483C12.9193 15.1515 12.9143 15.1591 12.9052 15.1693C12.8903 15.1859 12.8705 15.2018 12.8474 15.2146C12.824 15.2275 12.7996 15.2359 12.7771 15.2398C12.7628 15.2422 12.7529 15.2423 12.7487 15.2418C12.7744 15.2451 12.8254 15.2648 12.8955 15.3089L18.3077 18.7114C18.3785 18.7555 18.4201 18.7949 18.4394 18.8246C18.4405 18.8224 18.4392 18.8137 18.4392 18.8042C18.4392 18.7947 18.4405 18.786 18.4425 18.7794C18.443 18.7778 18.4424 18.7792 18.4394 18.7838C18.4201 18.8135 18.3785 18.8529 18.3104 18.8953L12.8971 22.282C12.8276 22.3256 12.7772 22.345 12.7517 22.3482C12.7557 22.3477 12.7653 22.3478 12.7792 22.3502C12.8013 22.354 12.8252 22.3623 12.8482 22.3749C12.8711 22.3875 12.8907 22.4032 12.9054 22.4196C12.9145 22.4297 12.9194 22.4373 12.9209 22.4404C12.9097 22.4163 12.8991 22.3626 12.8991 22.2805V15.3073Z"
        fill={color}
      />
    </svg>
  )
}
