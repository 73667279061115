import { HTMLAttributes } from 'react'

import { LocalNetworkQualityIndicator } from './components/LocalNetworkQualityIndicator'
import { RemoteNetworkQualityIndicator } from './components/RemoteNetworkQualityIndicator'

interface RemoteProps extends HTMLAttributes<HTMLDivElement> {
  type: 'remote'
  sessionId: string
}

interface LocalProps extends HTMLAttributes<HTMLDivElement> {
  type: 'local'
}

type Props = RemoteProps | LocalProps

export function NetworkQualityIndicator(props: Props) {
  // Have to check the type property on props so TS can discriminate the type
  if (props.type === 'remote') {
    const { type: _, sessionId, ...rest } = props
    return <RemoteNetworkQualityIndicator sessionId={sessionId} {...rest} />
  }

  const { type: _, ...rest } = props

  return <LocalNetworkQualityIndicator {...rest} />
}
