import { ApolloQueryResult, OperationVariables, useQuery } from '@apollo/client'

import {
  PatientCapDocument,
  PatientCapFragment,
  PatientCapQuery,
  PatientContextCapDocument,
  PatientContextCapQuery,
} from '@nuna/api'

export function useCapProgressQuery(patientId?: string | null): {
  loading: boolean
  data: PatientCapFragment | null
  refetch: (
    variables?: Partial<OperationVariables> | undefined,
  ) => Promise<ApolloQueryResult<PatientCapQuery | PatientContextCapQuery>> | null
} {
  const variables = patientId ? { id: patientId } : {}
  const document = patientId ? PatientCapDocument : PatientContextCapDocument
  const { data, loading, refetch } = useQuery<PatientCapQuery | PatientContextCapQuery>(document, {
    variables,
    fetchPolicy: 'cache-and-network',
  })

  if (!data) return { loading: true, data: null, refetch }

  if ('patientContext' in data) {
    return { loading, data: data.patientContext.patient, refetch }
  } else {
    return { loading, data: data.patient, refetch }
  }
}
