import { styled } from '@mui/material'
import { HTMLAttributes, ReactNode, useState } from 'react'

import { IconChevronThick, IconMinus, IconPlus } from '../../icons'
import { greySet } from '../../styles/colorSets'
import { borderGrey, primary } from '../../styles/colors'
import { fontSize } from '../../styles/global/typography.utils'
import { Collapse } from '../Collapse/Collapse'

type ExpandIcons = 'plusMinus' | 'chevron'

export type CollapsingListItemLabelProps = HTMLAttributes<HTMLSpanElement>

export interface CollapsingListItemProps extends HTMLAttributes<HTMLDivElement> {
  label: ReactNode
  labelProps?: CollapsingListItemLabelProps
  showBottomBorder?: boolean
  compact?: boolean
  expandIcon?: ExpandIcons
  hasDefaultSpacing?: boolean
  openByDefault?: boolean
}

const renderIcon = (isOpen: boolean, expandIcon: ExpandIcons) => {
  if (expandIcon === 'chevron') {
    return IconChevronThick
  } else {
    return isOpen ? IconMinus : IconPlus
  }
}

export function CollapsingListItem({
  children,
  label,
  labelProps = defaultLabelProps,
  showBottomBorder = false,
  expandIcon = 'plusMinus',
  compact = false,
  hasDefaultSpacing = true,
  openByDefault = false,
  ...props
}: CollapsingListItemProps) {
  const [isOpen, setIsOpen] = useState(openByDefault)

  const Icon = renderIcon(isOpen, expandIcon)

  return (
    <StyledCollapsingListItem
      {...props}
      $hasDefaultSpacing={hasDefaultSpacing}
      $showBottomBorder={showBottomBorder}
      $compact={compact}
    >
      <div role="button" onClick={() => setIsOpen(!isOpen)} className={`${hasDefaultSpacing && 'label'} space-between`}>
        <span {...labelProps}>{label}</span>
        <Icon
          size={compact ? 14 : 16}
          className={`open-icon flex-static `}
          color={expandIcon === 'chevron' ? greySet[70].hex : primary}
          direction={isOpen ? 'up' : 'down'}
        />
      </div>
      <Collapse isOpen={isOpen}>
        <div className="children-container">{children}</div>
      </Collapse>
    </StyledCollapsingListItem>
  )
}

const StyledCollapsingListItem = styled('div')<{
  $showBottomBorder: boolean
  $compact: boolean
  $hasDefaultSpacing: boolean
}>`
  border-top: 1px solid ${borderGrey};
  border-bottom: ${props => (props.$showBottomBorder ? `1px solid ${borderGrey}` : 'none')};
  font-size: ${props => (props.$compact ? fontSize.caption : fontSize.body)};
  padding: var(--spacing-2) var(--spacing-3);

  .open-icon {
    margin-top: ${props => (props.$compact ? 2 : 4)}px;
  }
  .label {
    cursor: pointer;
    padding: var(--spacing-2) 0;

    span {
      padding-right: var(--spacing-1);
    }
  }

  .children-container {
    padding-bottom: ${props => (props.$hasDefaultSpacing ? `var(--spacing-2)` : `0`)};
  }
`

const sansSerifLabelProps: CollapsingListItemLabelProps = {
  className: 'text-secondary text-medium',
}

const serifLabelProps: CollapsingListItemLabelProps = {
  className: 'text-secondary text-medium serif',
}

const defaultLabelProps = sansSerifLabelProps

export const collapsingListItemLabelProps = {
  sansSerif: sansSerifLabelProps,
  serif: serifLabelProps,
  default: defaultLabelProps,
}
