import { css, styled } from '@mui/material'
import React, { MouseEvent, useEffect, useLayoutEffect, useMemo, useState } from 'react'
import { HTMLAttributes, ReactNode, useRef } from 'react'
import { NavLink, matchPath, useLocation } from 'react-router-dom'

import { IconLeading } from '../../icons'
import { greySet, tealSet } from '../../styles/colorSets'
import { csx } from '../../utils/csx'
import { useResizeObserver } from '../../utils/useResizeObserver'
import { Menu } from '../Menu/Menu'

export interface ResponsiveHorizontalMenuProps extends HTMLAttributes<HTMLDivElement> {
  horizontalMin?: number
}

export function ResponsiveHorizontalMenu({ children, horizontalMin = 2, ...props }: ResponsiveHorizontalMenuProps) {
  const [hiddenIndices, setHiddenIndices] = useState<number[]>([])
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const [paths, setPaths] = useState<string[]>([])
  const parentRef = useRef<HTMLUListElement>(null)
  const menuItemCount = React.Children.count(children)
  const overflowVisible = hiddenIndices.length > 0

  const location = useLocation()

  const overflowMenuActive = useMemo<boolean>(() => {
    return paths.reduce<boolean>((pval, path, index) => {
      if (pval) {
        return true
      }
      return hiddenIndices.includes(index) && !!matchPath(path, location.pathname)
    }, false)
  }, [location.pathname, paths, hiddenIndices])

  const [containerRef, { width }] = useResizeObserver()

  useEffect(() => {
    if (!overflowVisible) {
      setAnchorEl(null)
    }
  }, [overflowVisible])

  useLayoutEffect(() => {
    if (parentRef.current) {
      // For some reason, ChildNode isn't a generic and doesn't overlap HTMLElement
      // enough for typescript to let you cast it without casting to unknown first
      const childNodes = parentRef.current.childNodes as unknown as HTMLElement[]
      setHiddenIndices(getHiddenItemIndices(width - 46, menuItemCount, childNodes))
    }
  }, [menuItemCount, width])

  useLayoutEffect(() => {
    if (parentRef.current) {
      const hrefs: string[] = []
      const anchors = parentRef.current.querySelectorAll('a')
      for (let i = 0; i < anchors.length; i++) {
        const url = new URL(anchors[i].href)
        hrefs.push(url.pathname)
      }
      setPaths(hrefs)
    }
  }, [])

  return (
    <StyledNav {...props} ref={containerRef}>
      <ul ref={parentRef} className="horizontal-menu">
        {React.Children.map<ReactNode, ReactNode>(children, (child, index) => {
          if (React.isValidElement(child)) {
            return (
              <HorizontalMenuItem
                key={index}
                visible={!hiddenIndices.includes(index) || index < horizontalMin}
                index={index}
              >
                {child}
              </HorizontalMenuItem>
            )
          }
          return null
        })}
      </ul>

      {overflowVisible && (
        <span className="overflow-menu-button-wrapper">
          <NavMenuButton
            type="button"
            className={csx({ active: !!anchorEl || overflowMenuActive })}
            onClick={(e: MouseEvent<HTMLButtonElement>) => setAnchorEl(e.currentTarget)}
          >
            <IconLeading />
          </NavMenuButton>
        </span>
      )}

      <Menu
        open={!!anchorEl}
        anchorEl={anchorEl}
        onClick={() => setAnchorEl(null)}
        onClose={() => setAnchorEl(null)}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
      >
        {React.Children.map<ReactNode, ReactNode>(children, (child, index) => {
          if (React.isValidElement(child) && hiddenIndices.includes(index)) {
            return child
          }
          return null
        })}
      </Menu>
    </StyledNav>
  )
}

interface HorizontalMenuItemProps {
  children: ReactNode
  index: number
  visible: boolean
}
function HorizontalMenuItem({ children, index, visible }: HorizontalMenuItemProps) {
  if (React.isValidElement(children)) {
    return (
      <li nav-item={index} className={csx({ visible })}>
        {children}
      </li>
    )
  }
  return null
}

const StyledNav = styled('nav')`
  position: relative;
  display: flex;
  align-items: center;
  overflow: hidden;
  .horizontal-menu {
    list-style: none;
    padding-left: 0;
    display: flex;
    white-space: nowrap;
    overflow: hidden;
    margin: 0;
    position: relative;
    /// padding to prevent focus visible shadow from being cropped
    padding: 4px;

    li {
      /* visibility: hidden; */

      padding: 0 2px;

      .menu-item {
        padding-left: 6px 12px;
      }

      &.visible {
        visibility: visible;
      }

      &:not(.visible) {
        position: absolute;
        left: 0;
        opacity: 0;
        height: 0px;
        pointer-events: none;
        overflow-y: hidden;
      }
    }
  }
`

const navButtonStyles = css`
  cursor: pointer;
  font-weight: bold;
  user-select: none;
  height: 2.25rem;
  padding: 0 0.5rem;
  box-sizing: border-box;
  transition: 0.1s;
  border: none;
  color: ${greySet[70].hex};
  border-radius: 0.25rem;
  transition: background 300ms ease;
  display: inline-flex;
  align-items: center;

  &.focus-visible {
    box-shadow: 0 0 0 4px ${greySet.tint[40]};
    outline: none;
  }

  &:hover {
    background-color: ${greySet[50].transparency(0.32)};
    color: ${greySet[80].hex};
  }

  &.active {
    background-color: ${greySet[50].transparency(0.18)};
    color: ${tealSet[80].hex};
  }
`

// Copied over from Harmony. Devin says this will become a standard component. When that
// happens, we should export it as a button type
export const NavMenuButton = styled('button')`
  ${navButtonStyles}
`

export const NavMenuButtonLink = styled(NavLink)`
  ${navButtonStyles}
`

function getHiddenItemIndices(width: number, menuItemCount: number, nodes: HTMLElement[]) {
  let accumulatedWidth = 0
  const hiddenIndices: number[] = []

  for (const el of nodes) {
    const elWidth = el.clientWidth
    const index = parseInt(el.getAttribute('nav-item') as string)
    if (isNaN(index)) {
      break
    }
    accumulatedWidth = accumulatedWidth + elWidth

    if (accumulatedWidth > width) {
      hiddenIndices.push(index)
    }
  }

  if (hiddenIndices.length === 1 && menuItemCount > 3) {
    hiddenIndices.push(hiddenIndices[0] - 1)
  }
  return hiddenIndices
}
