import { compact, isBoolean } from 'lodash'
import { useCallback, useMemo } from 'react'
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom'

import { PaymentPreference, type PublicAvailabilitySlot, SwitchProviderChangeReason } from '@nuna/api'
import { appointmentService } from '@nuna/core'

export interface CancelAppointmentDrawerSearchParams {
  cancelAppointment?: string
}

export interface ScheduleAppointmentDrawerSearchParams {
  scheduleAppointment?: string
  timeSlot?: string
}

interface AppointmentDrawerLocationState {
  cancelMessage?: string
  payerName?: string
  changeReason?: SwitchProviderChangeReason
  freeFormReason?: string
  providerAcceptsInsurance?: boolean
  filters?: object // This is only used to send to mixpanel, hence the generic object type
}

interface OpenScheduleAppointmentDrawerOptions {
  timeSlot?: string
  oldProviderId?: string
  state?: AppointmentDrawerLocationState
  patientId?: string
  paymentRequired?: boolean
  closeOnSave?: boolean
  wipeOldParams?: boolean
  registerThenTimeSlot?: boolean
  addressId?: string
}

const drawerParams = [
  'cancelAppointment',
  'rescheduleAppointment',
  'scheduleAppointment',
  'schedulePatientId',
  'appointment',
  'timeSlot',
  'switchingCoverage',
  'closeOnSave',
  'paymentRequired',
  'appointmentSet',
  'registerThenTimeSlot',
  'addressId',
]

export function useAppointmentDrawerSearchParams() {
  const location = useLocation()
  const navigate = useNavigate()
  const [searchParams, setSearchParams] = useSearchParams()

  const getRetainedParams = () => {
    return Array.from(searchParams.entries()).reduce<{ [key: string]: string }>((acc, [key, value]) => {
      if (!drawerParams.includes(key)) {
        acc[key] = value
      }

      return acc
    }, {})
  }

  const openScheduleAppointmentDrawer = (
    providerId: string,
    {
      patientId = '',
      paymentRequired = true,
      closeOnSave = false,
      timeSlot,
      oldProviderId,
      state = {},
      wipeOldParams = false,
      registerThenTimeSlot = false,
      addressId,
    }: OpenScheduleAppointmentDrawerOptions = {},
  ) => {
    const scheduleSearchParams = new URLSearchParams({
      scheduleAppointment: providerId,
      ...(!wipeOldParams ? getRetainedParams() : {}),
    })

    if (addressId) {
      scheduleSearchParams.append('addressId', addressId)
    }

    if (timeSlot) {
      scheduleSearchParams.append('timeSlot', timeSlot)
    }

    if (oldProviderId) {
      scheduleSearchParams.append('oldProviderId', oldProviderId)
    }

    if (patientId) {
      scheduleSearchParams.append('schedulePatientId', patientId)
    }

    if (isBoolean(closeOnSave)) {
      scheduleSearchParams.append('closeOnSave', closeOnSave.toString())
    }

    if (isBoolean(paymentRequired)) {
      scheduleSearchParams.append('paymentRequired', paymentRequired.toString())
    }

    if (isBoolean(registerThenTimeSlot)) {
      scheduleSearchParams.append('registerThenTimeSlot', registerThenTimeSlot.toString())
    }

    navigate({ pathname: location.pathname, search: `?${scheduleSearchParams}` }, { state })
  }

  const openCancelAppointmentDrawer = (
    appointmentId: string | null = null,
    state: AppointmentDrawerLocationState | null = {},
  ) => {
    const cancelSearchParams = new URLSearchParams({
      cancelAppointment: appointmentId || 'true',
      ...getRetainedParams(),
    })
    navigate({ pathname: location.pathname, search: `?${cancelSearchParams}` }, { state })
  }

  const openRescheduleAppointmentDrawer = (appointmentId: string | null = null) => {
    const rescheduleSearchParams = new URLSearchParams({
      rescheduleAppointment: appointmentId || 'true',
      ...getRetainedParams(),
    })
    navigate({ pathname: location.pathname, search: `?${rescheduleSearchParams}` })
  }

  const openAppointmentDetailDrawer = (appointmentId: string) => {
    setSearchParams({ appointment: appointmentId, ...getRetainedParams() })
  }

  const confirmProviderChange = () => {
    setSearchParams(
      searchParams => {
        searchParams.set('confirmProviderChange', 'true')
        return searchParams
      },
      { state: location.state },
    )
  }

  const showSwitchCoverage = () => {
    setSearchParams(
      searchParams => {
        searchParams.set('switchingCoverage', 'true')
        return searchParams
      },
      { state: location.state },
    )
  }

  const showPrefilledSwitchCoverage = (preference: PaymentPreference) => {
    setSearchParams(
      searchParams => {
        searchParams.set('switchingCoverage', 'true')
        searchParams.set('switchingPrefilled', preference)
        return searchParams
      },
      { state: location.state },
    )
  }

  const closeSwitchCoverage = () => {
    setSearchParams(
      searchParams => {
        searchParams.delete('switchingCoverage')
        searchParams.delete('switchingPrefilled')
        return searchParams
      },
      { state: location.state },
    )
  }

  const setTimeSlot = useCallback(
    (timeSlot: PublicAvailabilitySlot | null) => {
      setSearchParams(
        searchParams => {
          if (timeSlot === null) {
            searchParams.delete('timeSlot')
          } else {
            searchParams.set('timeSlot', appointmentService.timeSlotToTimeStamp(timeSlot))
          }
          return searchParams
        },
        { state: location.state },
      )
    },
    [location.state, setSearchParams],
  )

  const setAddressId = useCallback(
    (addressId: string | null) => {
      setSearchParams(
        searchParams => {
          if (addressId === null) {
            searchParams.delete('addressId')
          } else {
            searchParams.set('addressId', addressId)
          }
          return searchParams
        },
        { state: location.state },
      )
    },
    [location.state, setSearchParams],
  )

  const closeDrawer = () => {
    navigate(
      {
        pathname: location.pathname,
        search: new URLSearchParams(getRetainedParams()).toString(),
      },
      { state: null },
    )
  }

  const drawerConfig = useMemo(() => {
    const cancel = searchParams.get('cancelAppointment')
    const reschedule = searchParams.get('rescheduleAppointment')
    const schedule = searchParams.get('scheduleAppointment')
    const detail = searchParams.get('appointment')
    const timeSlot = searchParams.get('timeSlot') // could be used by both schedule and reschedule
    const switching = searchParams.get('switchingCoverage')
    const switchingPrefilled = searchParams.get('switchingPrefilled') // this will auto set the prefilled to a specific page
    const oldProviderId = searchParams.get('oldProviderId')
    const confirmProviderChange = searchParams.get('confirmProviderChange')
    const schedulePatientId = searchParams.get('schedulePatientId')
    const closeOnSave = searchParams.get('closeOnSave')
    const paymentRequired = searchParams.get('paymentRequired')
    const registerThenTimeSlot = searchParams.get('registerThenTimeSlot')
    const addressId = searchParams.get('addressId')
    const appointmentId = cancel ?? reschedule ?? detail

    let switchingPrefilledTyped: PaymentPreference | undefined
    switch (switchingPrefilled) {
      case PaymentPreference.Insurance:
        switchingPrefilledTyped = PaymentPreference.Insurance
        break
      case PaymentPreference.Cash:
        switchingPrefilledTyped = PaymentPreference.Cash
        break
    }

    return {
      drawerOpen: compact([cancel, reschedule, schedule, detail]).length > 0,
      cancel: !!cancel,
      reschedule: !!reschedule,
      schedule: !!schedule,
      detail: !!detail,
      switching: !!switching,
      switchingPrefilled: switchingPrefilledTyped,
      appointmentId: appointmentId === 'true' ? null : appointmentId,
      scheduleProviderId: schedule !== 'true' ? schedule : undefined,
      timeSlot: timeSlot ? appointmentService.timeStampToTimeSlot(timeSlot) : undefined,
      oldProviderId: oldProviderId ?? undefined,
      confirmedProviderChange: confirmProviderChange === 'true',
      schedulePatientId: schedulePatientId,
      closeOnSave: closeOnSave === 'true',
      paymentRequired: paymentRequired === 'true',
      registerThenTimeSlot: registerThenTimeSlot === 'true',
      addressId,
    }
  }, [searchParams])

  return {
    searchParams,
    openScheduleAppointmentDrawer,
    openCancelAppointmentDrawer,
    openRescheduleAppointmentDrawer,
    openAppointmentDetailDrawer,
    setAddressId,
    setTimeSlot,
    closeDrawer,
    drawerConfig,
    showSwitchCoverage,
    showPrefilledSwitchCoverage,
    closeSwitchCoverage,
    confirmProviderChange,
    state: location.state as AppointmentDrawerLocationState | null,
  }
}
