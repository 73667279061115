import { styled } from '@mui/material'
import { HTMLAttributes, useMemo, useRef, useState } from 'react'

import { useProviderCaseloadQuery, useProviderMeQuery } from '@nuna/api'
import { useProviderId } from '@nuna/auth'
import { CopyToClipboardButtonLink } from '@nuna/common'
import { routeService } from '@nuna/core'
import { Card, IconInfo, Skeleton, TextButtonLink, greySet } from '@nuna/tunic'

import { ReferralsPopover } from './components/ReferralsPopover'

export function ReferralsStatus(props: HTMLAttributes<HTMLDivElement>) {
  const infoIcon = useRef<HTMLElement>(null)
  const providerId = useProviderId()
  const { data: caseloadData } = useProviderCaseloadQuery({ variables: { providerId } })
  const { data: providerData } = useProviderMeQuery()
  const [opened, setOpened] = useState<boolean>(false)

  const areReferralsOn = caseloadData?.provider.acceptsReferrals
  const publicProfileLink = providerData?.providerMe.slug
    ? routeService.publicProviderRoute(providerData.providerMe.slug, true)
    : null
  const isExploreFirstProvider = !providerData?.providerMe.isProfileComplete

  const practiceWebsiteLink = useMemo(() => {
    if (isExploreFirstProvider) {
      return <TextButtonLink to={'/signup-intake/resume-intake'}>Setup</TextButtonLink>
    } else if (publicProfileLink) {
      return (
        <CopyToClipboardButtonLink tooltipText="Link copied!" to={publicProfileLink}>
          Share
        </CopyToClipboardButtonLink>
      )
    } else {
      return <Skeleton width={3} height={1} />
    }
  }, [isExploreFirstProvider, publicProfileLink])

  const referralsLink = useMemo(() => {
    if (isExploreFirstProvider) {
      return <TextButtonLink to={'/signup-intake/resume-intake'}>Setup</TextButtonLink>
    } else {
      return (
        <TextButtonLink to={routeService.providerCaseload} variant={areReferralsOn ? 'primary' : 'secondary'}>
          {areReferralsOn ? 'On' : 'Off'}
        </TextButtonLink>
      )
    }
  }, [areReferralsOn, isExploreFirstProvider])

  return (
    <Container {...props}>
      <div className="space-between caption text-medium">
        <div className="v-align" onMouseLeave={() => setOpened(false)}>
          Referrals
          {/* The popover doesn't like SVGElements hence the span */}
          <span ref={infoIcon} className="v-align" onMouseEnter={() => setOpened(true)}>
            <IconInfo className="ml-xs" size={18} color={greySet[30].hex} />
          </span>
          <ReferralsPopover
            style={{ pointerEvents: 'none' }}
            anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
            open={opened}
            anchorEl={infoIcon.current}
            PaperProps={{
              onMouseEnter: () => setOpened(true),
              onMouseLeave: () => setOpened(false),
            }}
            classes={{
              paper: 'pointer-events-auto',
            }}
          />
        </div>
        {referralsLink}
      </div>

      <div className="mt-2 space-between caption text-medium">
        <span>Practice Website</span>
        {practiceWebsiteLink}
      </div>
    </Container>
  )
}

const Container = styled(Card)`
  box-shadow: none;
  color: ${greySet[80].hex};
  font-size: 14px;
  padding: 0.75rem;

  > .space-between {
    align-items: baseline;
  }
`
