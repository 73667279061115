import { styled } from '@mui/material'
import { useSearchParams } from 'react-router-dom'

import { PatientContextQuery, useCancelPolicyStatusQuery, usePatientContextQuery } from '@nuna/api'
import { ACCEPT_PROVIDER_FEES_PARAM, MODAL_LATE_CANCELATION_POLICY } from '@nuna/core'
import { Avatar, ContextualAlert, IconChevronThick, greySet, makeTypographyComponent } from '@nuna/tunic'

export function ProviderFeeList() {
  const { data: patientContextData } = usePatientContextQuery()
  const patient = patientContextData?.patientContext?.patient
  const providers = patient?.providers
  return (
    <ProviderList>
      {providers &&
        patient &&
        providers.map(provider => <ProviderFeeListItem key={provider.id} patientId={patient.id} provider={provider} />)}
    </ProviderList>
  )
}

interface Props {
  patientId: string
  provider: PatientContextQuery['patientContext']['patient']['providers'][number]
}

function ProviderFeeListItem({ patientId, provider }: Props) {
  const [_, setSearchParams] = useSearchParams()
  const { data: cancelPolicyData } = useCancelPolicyStatusQuery({
    variables: {
      providerId: provider.id,
      clientId: patientId,
      defaultToTavaPolicy: false,
    },
  })

  const { availableCancelPolicy, cancelPolicy } = cancelPolicyData?.cancelPolicyStatus ?? {}

  if (!cancelPolicy && !availableCancelPolicy) return null

  const openProviderFeeDrawer = () => {
    if (availableCancelPolicy) {
      setSearchParams(oldSearchParams => {
        oldSearchParams.set(ACCEPT_PROVIDER_FEES_PARAM, provider.id)
        return oldSearchParams
      })
    } else if (cancelPolicy && !availableCancelPolicy) {
      setSearchParams(oldParams => {
        oldParams.set(MODAL_LATE_CANCELATION_POLICY, provider.id)
        return oldParams
      })
    }
  }

  return (
    <ProviderItem>
      <ProviderButton onClick={openProviderFeeDrawer} className="v-align py-1-5 pr-1">
        <Avatar size="xs" url={provider.avatarUrl} />
        <span>{provider.firstName}'s policy details</span>
        <IconChevronThick direction="right" size={14} className="ml-auto shrink-0" />
      </ProviderButton>
      {availableCancelPolicy && (
        <ContextualAlert intent="caution" className="mb-1">
          You have not accepted the latest policy. Please review and accept.
        </ContextualAlert>
      )}
    </ProviderItem>
  )
}

const ProviderList = styled(makeTypographyComponent('unstyled-list divide-y', 'ul'))`
  margin-top: 40px;
`

const ProviderItem = styled(makeTypographyComponent('flex-column', 'li'))`
  border-color: ${greySet[30].hex};
  border-style: solid;
  border-width: 1px 0 0 0;

  &:last-of-type {
    border-bottom-width: 1px;
  }
`

const ProviderButton = styled('button')`
  gap: var(--spacing-2);
  cursor: pointer;
`
