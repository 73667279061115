import {
  PatientPaymentMethodInput,
  useDeletePaymentMethodMutation,
  useListPaymentMethodsQuery,
  useSavePaymentMethodMutation,
} from '@nuna/api'

export function useClientPaymentMethods(
  patientId: string | undefined | null,
  options?: Partial<Parameters<typeof useListPaymentMethodsQuery>[0]>,
) {
  const searchOptions = options?.variables?.searchOptions ?? { active: true }

  const queryResult = useListPaymentMethodsQuery({
    variables: { searchOptions: { patientId, ...searchOptions } },
    skip: !patientId,
    ...options,
  })
  const [savePaymentMethodMutation, saveMutationResult] = useSavePaymentMethodMutation()
  const [deletePaymentMethodMutation, deleteMutationResult] = useDeletePaymentMethodMutation()

  const savePaymentMethod = async (input: PatientPaymentMethodInput) => {
    const response = await savePaymentMethodMutation({
      variables: { paymentMethod: input },
    })

    const savedCard = response?.data?.savePaymentMethod

    if (!savedCard) {
      throw new Error('Response did not contain saved card')
    }

    queryResult.refetch()

    return savedCard
  }

  const deletePaymentMethod = async (paymentMethodId: string) => {
    const response = await deletePaymentMethodMutation({ variables: { id: paymentMethodId } })

    if (!response.data?.deletePaymentMethod) {
      throw new Error('Card was not deleted')
    }

    queryResult.updateQuery(prev => {
      const updatedPaymentMethods = prev.listPaymentMethods.filter(method => method.id !== paymentMethodId)

      return {
        ...prev,
        listPaymentMethods: updatedPaymentMethods,
      }
    })
  }

  const paymentMethods = queryResult.data?.listPaymentMethods ?? []
  const defaultForFees = paymentMethods.find(method => method.defaultForFees)
  const defaultForSessions = paymentMethods.find(method => method.defaultForSessions)

  return {
    paymentMethods,
    defaultForFees,
    defaultForSessions,
    savePaymentMethod,
    deletePaymentMethod,
    queryResult,
    saveMutationResult,
    deleteMutationResult,
  }
}
