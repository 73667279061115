import { styled } from '@mui/material'

import { BelowTablet } from '../../../styles/breakpoints'
import { borderGrey, eggshell } from '../../../styles/colors'

export const NavButton = styled('button')`
  background-color: ${eggshell};
  border: 1px solid ${borderGrey};
  border-radius: 56px 56px 12px 56px;
  display: flex;
  align-items: center;
  padding: 0.25rem;

  &:focus,
  &:hover {
    box-shadow: 0 0 0 2px #fff;
    outline: none;
  }

  @media (${BelowTablet}) {
    img {
      height: 24px;
      width: 24px;
    }
  }
`
