import * as Yup from 'yup'
import { Formik } from 'formik'

import {
  AppointmentChangeReason,
  AppointmentWhoNoShowed,
  ConversationMessageStatus,
  useCancelAppointmentAsProviderMutation,
  useMarkAppointmentAsNoShowMutation,
} from '@nuna/api'
import { formService } from '@nuna/core'
import { useMessageComposerContext } from '@nuna/messaging'
import { FillButton, GhostButton, Radio, RadioGroup, Tooltip, toast } from '@nuna/tunic'

import { SessionData, SessionWhatHappened } from '../../../types'
import { NoShowConfirmation } from './NoShowConfirmation'
import { PrivateNote } from './PrivateNote'
import { SessionFeeForm } from './SessionFeeForm'
import { SessionNoteCancelForm } from './SessionNoteCancelForm'

const { composeHelperTextWithError } = formService

interface MissedSessionFormValues {
  whatHappened?: SessionWhatHappened | null
  cancelReason?: AppointmentChangeReason | null
  shouldProviderBePaid: boolean
  noShowWho: string
  noShowConfirmation: boolean
  reasonData?: string | null
}

const CANCEL_VALUE = 'cancel'
const CANCEL_OPTION_VALUES: (SessionWhatHappened | null | undefined)[] = [
  SessionWhatHappened.Cancel,
  SessionWhatHappened.TechnicalDifficulties,
]
const NO_SHOW_VALUES: (SessionWhatHappened | null | undefined)[] = [
  SessionWhatHappened.ClientNoShow,
  SessionWhatHappened.ProviderNoShow,
]

interface Props {
  sessionData: SessionData
  onMarkedAsNoShow: () => void
  onCanceled: () => void
  onCancelEdit: () => void
}

export function MissedSessionForm({ sessionData, onCanceled, onCancelEdit, onMarkedAsNoShow }: Props) {
  const { sendMessage } = useMessageComposerContext()
  const [markAppointmentAsNoShow, { loading: noShowLoading }] = useMarkAppointmentAsNoShowMutation()
  const [cancelAppointmentAsProvider, { loading: cancelLoading }] = useCancelAppointmentAsProviderMutation()

  const isLoading = noShowLoading || cancelLoading

  const { patient, provider } = sessionData

  const handleCancel = async (values: MissedSessionFormValues) => {
    try {
      const requestedByClient = values.cancelReason === AppointmentChangeReason.RequestedByPatient
      const shouldProviderBePaid = requestedByClient ? values.shouldProviderBePaid : false
      const messageId = shouldProviderBePaid
        ? (await sendMessage(patient.loginId ?? '', { status: ConversationMessageStatus.Draft }))?.id
        : undefined

      await cancelAppointmentAsProvider({
        variables: {
          appointmentId: sessionData.appointmentId,
          reason: values.cancelReason as AppointmentChangeReason,
          reasonData: values.reasonData,
          shouldProviderBePaid,
          messageId,
        },
      })

      onCanceled()
    } catch (e) {
      console.error(e)
      toast.urgent("There was an error updating the appointment's status. Please try again later.")
    }
  }

  const handleConfirmNoShow = async (values: MissedSessionFormValues) => {
    try {
      const clientNoShowed = values.noShowWho === AppointmentWhoNoShowed.Patient
      const shouldProviderBePaid = clientNoShowed ? values.shouldProviderBePaid : false
      const messageId = shouldProviderBePaid
        ? (await sendMessage(patient.loginId ?? '', { status: ConversationMessageStatus.Draft }))?.id
        : undefined

      await markAppointmentAsNoShow({
        variables: {
          appointmentId: sessionData.appointmentId,
          noShowStatus: true,
          whoNoShowed: values.noShowWho as AppointmentWhoNoShowed,
          reasonData: values.reasonData,
          shouldProviderBePaid,
          messageId,
        },
      })

      onMarkedAsNoShow()
    } catch (e) {
      console.error(e)
      toast.urgent("There was an error updating the appointment's status. Please try again later.")
    }
  }

  const handleSubmit = (values: MissedSessionFormValues) => {
    if (NO_SHOW_VALUES.includes(values.whatHappened)) {
      handleConfirmNoShow(values)
    } else if (CANCEL_OPTION_VALUES.includes(values.whatHappened)) {
      handleCancel(values)
    } else {
      console.error('Unexpected form value:', values.whatHappened)
    }
  }

  const initialValues: MissedSessionFormValues = {
    whatHappened: null,
    cancelReason: null,
    shouldProviderBePaid: false,
    noShowWho: AppointmentWhoNoShowed.Patient,
    noShowConfirmation: false,
    reasonData: '',
  }

  return (
    <Formik initialValues={initialValues} onSubmit={handleSubmit} validationSchema={buildSchema()}>
      {({ values, handleChange, errors, touched, setFieldValue, handleSubmit }) => {
        const showCancelFeeForm =
          values.cancelReason === AppointmentChangeReason.RequestedByPatient || values.whatHappened === 'clientNoShow'
        const isNoteRequired =
          NO_SHOW_VALUES.includes(values.whatHappened) || values.cancelReason === AppointmentChangeReason.FreeForm

        return (
          <form style={{ maxWidth: '48rem' }} onSubmit={handleSubmit}>
            <h3 className="h6 sans-serif mb-2 mt-3">What happened?</h3>
            <RadioGroup {...composeHelperTextWithError('', errors.whatHappened, touched.whatHappened)}>
              <Tooltip content="Select this option if you were notified by the client that they would not make this session.">
                {/* div is here because Tooltip needs a ref for positioning and the ref for radio points to the hidden input element */}
                <div style={{ display: 'inline-block' }}>
                  <Radio
                    value="cancel"
                    name="whatHappened"
                    onChange={handleChange}
                    checked={values.whatHappened === 'cancel'}
                  >
                    Session was canceled or rescheduled
                  </Radio>
                </div>
              </Tooltip>
              <Radio
                value={SessionWhatHappened.ClientNoShow}
                name="whatHappened"
                onChange={e => {
                  setFieldValue('cancelReason', undefined)
                  setFieldValue('noShowWho', AppointmentWhoNoShowed.Patient)
                  handleChange(e)
                }}
                checked={values.whatHappened === SessionWhatHappened.ClientNoShow}
              >
                Client didn't show
              </Radio>
              <Radio
                value={SessionWhatHappened.ProviderNoShow}
                name="whatHappened"
                onChange={e => {
                  setFieldValue('cancelReason', undefined)
                  setFieldValue('noShowWho', AppointmentWhoNoShowed.Provider)
                  handleChange(e)
                }}
                checked={values.whatHappened === SessionWhatHappened.ProviderNoShow}
              >
                Therapist didn't show
              </Radio>
              <Radio
                value={SessionWhatHappened.TechnicalDifficulties}
                name="whatHappened"
                onChange={e => {
                  setFieldValue('cancelReason', SessionWhatHappened.TechnicalDifficulties)
                  handleChange(e)
                }}
                checked={values.whatHappened === SessionWhatHappened.TechnicalDifficulties}
              >
                Technical difficulties prevented the session
              </Radio>
            </RadioGroup>

            {values.whatHappened === SessionWhatHappened.ClientNoShow && <NoShowConfirmation className="mt-4" />}

            {CANCEL_OPTION_VALUES.includes(values.whatHappened) && (
              <SessionNoteCancelForm showCancelReasons={values.whatHappened === CANCEL_VALUE} />
            )}

            <PrivateNote required={isNoteRequired} className="mt-4" />

            {showCancelFeeForm && (
              <SessionFeeForm
                appointment={{ startDatetime: sessionData.scheduledStartTime }}
                providerId={provider.id}
                client={patient}
                className="mt-4"
                policyType={values.whatHappened === 'clientNoShow' ? 'noShow' : 'cancel'}
              />
            )}

            <div className={'mt-5'}>
              <FillButton disabled={isLoading} type="submit" isLoading={isLoading}>
                {values.shouldProviderBePaid ? 'Submit And Charge Fees' : 'Submit'}
              </FillButton>

              <GhostButton onClick={onCancelEdit} className="ml-2">
                Cancel
              </GhostButton>
            </div>
          </form>
        )
      }}
    </Formik>
  )
}

function requireNote(
  whatHappened: SessionWhatHappened | null | undefined,
  cancelReason?: AppointmentChangeReason | null,
) {
  if (NO_SHOW_VALUES.includes(whatHappened)) {
    return true
  }

  return cancelReason === AppointmentChangeReason.FreeForm
}

function buildSchema() {
  return Yup.object().shape<MissedSessionFormValues>({
    whatHappened: Yup.mixed()
      .oneOf(Object.values(SessionWhatHappened), 'Select why the session did not happen')
      .required('Select why the session did not happen'),
    reasonData: Yup.string().when(['whatHappened', 'cancelReason'], {
      is: (whatHappened, cancelReason) => requireNote(whatHappened, cancelReason),
      then: Yup.string().required('Add a note explaining what happened'),
    }),
    noShowWho: Yup.string().when(['whatHappened'], {
      is: whatHappened => NO_SHOW_VALUES.includes(whatHappened),
      then: Yup.string().required(),
    }),
    noShowConfirmation: Yup.boolean().when(['whatHappened'], {
      is: whatHappened => whatHappened === SessionWhatHappened.ClientNoShow,
      then: Yup.boolean().oneOf(
        [true],
        'You must confirm that you waited at least 15 minutes before ending the session',
      ),
    }),
    cancelReason: Yup.mixed()
      .oneOf([...Object.values(AppointmentChangeReason), null, undefined])
      .when(['whatHappened'], {
        is: whatHappened => whatHappened === SessionWhatHappened.Cancel,
        then: Yup.mixed()
          .oneOf(Object.values(AppointmentChangeReason), 'Select why the session was canceled')
          .required('Select why the session was canceled'),
      }),
    shouldProviderBePaid: Yup.boolean(),
  })
}
