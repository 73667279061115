import { sortBy } from 'lodash'

import { DocumentType } from '../types/internal-only/document.types'

type ReadableDocumentTypeMap = Record<DocumentType, string>

const readableDocumentTypeMap: ReadableDocumentTypeMap = {
  [DocumentType.Attestation]: 'Attestation',
  [DocumentType.BackgroundCheck]: 'Background Check',
  [DocumentType.BenefitsGuide]: 'Benefits Guide',
  [DocumentType.CaqhForm]: 'CAQH Form',
  [DocumentType.ClientDocument]: 'Client Document',
  [DocumentType.CompanyLogo]: 'Company Logo',
  [DocumentType.CredentialingNotificationLetter]: 'Credentialing Notification Letter',
  [DocumentType.CredentialingPacket]: 'Credentialing Packet',
  [DocumentType.InsuranceCardBack]: 'Insurance Card Back',
  [DocumentType.InsuranceCardFront]: 'Insurance Card Front',
  [DocumentType.MessageAttachment]: 'Message Attachment',
  [DocumentType.PartnerLogo]: 'Partner Logo',
  [DocumentType.PatientImage]: 'Patient Image',
  [DocumentType.PayerLogo]: 'Payer Logo',
  [DocumentType.PracticeAddressW9]: 'Practice Address W9',
  [DocumentType.ProviderImage]: 'Provider Image',
  [DocumentType.Resume]: 'Resume',
  [DocumentType.UserCustomDocument]: 'User Custom Document',
  [DocumentType.PayerApprovalLetter]: 'Payer Approval Letter',
  [DocumentType.StateLicense]: 'State License',
  [DocumentType.PayerEnrollmentForm]: 'Payer Enrollment Form',
  [DocumentType.PayerRosterSource]: 'Payer Roster Source',
  [DocumentType.PayerTavaRoster]: 'Insurance Payer Roster',
  [DocumentType.LiabilityInsurance]: 'Liability Insurance',
  [DocumentType.ProfessionalLiabilityInsurance]: 'Liability Insurance',
  [DocumentType.TavaEin]: 'Tava EIN',
}

export type DocumentTypeOption = { value: keyof typeof readableDocumentTypeMap; label: string }

function getDocumentTypeOptions(): DocumentTypeOption[] {
  return sortBy(
    Object.entries(readableDocumentTypeMap).map(([value, label]) => ({ value: value as DocumentType, label })),
    docType => docType.label,
  )
}

function getReadableDocumentType(documentType: DocumentType): string {
  // The DocumentType enum used here must match the one generated by codegen. Update the enum with missing values
  // and update the map if values are added to the codegen version
  return readableDocumentTypeMap[documentType] ?? 'Unknown'
}

function downloadDocument({ name, url, element }: { name: string; url: string; element?: HTMLElement | null }) {
  const elId = 'doc-download-link'
  const container = element ?? document
  container.querySelector(`#${elId}`)?.remove()

  const a = document.createElement('a')
  a.id = elId
  a.href = url
  a.download = name
  container.appendChild(a)
  a.click()
}

export const documentService = {
  getDocumentTypeOptions,
  getReadableDocumentType,
  downloadDocument,
}
