import { IconProps } from './IconProps'

// Copied from MdHome
export function IconHome({ size = 24, color = 'currentColor', ...props }: IconProps) {
  return (
    <svg width={size} height={size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path fill="none" d="M0 0h24v24H0z"></path>
      <path d="M10 20v-6h4v6h5v-8h3L12 3 2 12h3v8z" fill={color}></path>
    </svg>
  )
}
