import { styled } from '@mui/material'
import { useMediaQuery } from 'react-responsive'
import { NavLink, Outlet, Route, Routes, useSearchParams } from 'react-router-dom'

import { AppointmentDrawer } from '@nuna/appointment-drawer'
import { ClientInviteDrawer } from '@nuna/client-invite'
import { routeService } from '@nuna/core'
import { IconChevronThick, Logo, TabletPortrait, greySet } from '@nuna/tunic'

import { ExploreMoreWelcome } from '../scenes/signup/ExploreMoreWelcome'
import { useGlobalWidgetContext } from '../shared/GlobalWidgetContext'
import { NavBar } from '../shared/NavBar/NavBar'
import { ResourceCenter } from '../shared/ResourceCenter/ResourceCenter'
import { Sidebar } from '../shared/Sidebar/Sidebar'
import { HeaderHeight } from '../shared/styles/sizes'

const { conversationsBaseRoute, conversationsDetailFullPath } = routeService

/**
 * Layout inspired by https://www.bitovi.com/blog/use-flexbox-to-create-a-sticky-header-and-sidebar-with-flexible-content
 */

const Body = styled('div')`
  height: 100%;
  overflow: hidden; /*makes the body non-scrollable (we will add scrolling to the sidebar and main content containers)*/
  margin: 0px; /*removes default style*/
  box-sizing: border-box;
  width: 100%;
  position: relative;
`

const Header = styled('header')`
  display: flex;
  justify-content: space-between;
  background-color: ${greySet[0].hex};
  border-bottom: 1px solid ${greySet[15].hex};
  align-items: center;
  padding-left: 20px;
  color: white;
  height: ${HeaderHeight};
  width: 100%;
`

const MainLayout = styled('div')`
  display: flex;
  height: 100%;
`

const LeftColumn = styled('div')`
  height: 100%; /*allows both columns to span the full height of the browser window*/
  display: flex;
  flex-direction: column; /*places the left and right headers above the bottom content*/
  flex-shrink: 0; /*makes sure that content is not cut off in a smaller browser window*/
  width: 192px;
  z-index: 6;

  @media print {
    display: none;
  }
`

const RightColumn = styled('div')`
  height: 100%; /*allows both columns to span the full height of the browser window*/
  display: flex;
  flex-direction: column; /*places the left and right headers above the bottom content*/
  background-color: white;
  width: 100%;
  position: relative;
`

const BottomRight = styled('main')`
  flex-grow: 1; /*ensures that the container will take up the full height of the parent container*/
  overflow-y: auto; /*adds scroll to this container*/
  background-color: white;
  height: 100%; // Necessary for Safari 13
`

export function NavLayout() {
  const isTabletPortrait = useMediaQuery({
    query: `(${TabletPortrait})`,
  })

  const [searchParams] = useSearchParams()

  const { closeResourceCenter, closeBeacon } = useGlobalWidgetContext()

  // Event handler for handling clicks made to left or right column
  // this allows for closing helpscout beacon and the resource center
  const handleBodyClick = () => {
    closeResourceCenter()
    closeBeacon()
  }

  return (
    <Body>
      {isTabletPortrait && (
        <Header>
          <Routes>
            <Route
              path={conversationsDetailFullPath}
              element={
                <NavLink to={conversationsBaseRoute}>
                  <span className="text-secondary v-align text-bold">
                    <IconChevronThick className="mr-xs" direction="left" size={16} />
                    Back
                  </span>
                </NavLink>
              }
            />
          </Routes>

          <NavLink to="/">
            <Logo color="plum" height={36} />
          </NavLink>
          <NavBar />
        </Header>
      )}

      <MainLayout>
        {!isTabletPortrait && (
          <LeftColumn onClick={handleBodyClick}>
            <Sidebar />
          </LeftColumn>
        )}
        <ResourceCenter />
        <RightColumn onClick={handleBodyClick} id="right-column-wrapper">
          <BottomRight>
            <Outlet />
          </BottomRight>
        </RightColumn>
      </MainLayout>
      {searchParams.get('exploreFirstWelcome') === 'true' && <ExploreMoreWelcome />}
      <AppointmentDrawer audience="provider" />
      <ClientInviteDrawer audience="provider" />
    </Body>
  )
}
