import { compact, every } from 'lodash'
import { useCallback, useRef } from 'react'

import {
  EstimatePaymentResponsibilityQuery,
  WhoIsResponsibleForPayment,
  useEstimatePaymentResponsibilityLazyQuery,
} from '@nuna/api'

import { WrappedResponse } from '../../../types'
import { CoverageFormPatient } from '../../CoverageForm'

export interface SimpleUpcomingAppointment {
  id: string
  startDatetime: string
  endDatetime: string
  provider: { id: string; avatarUrl?: string | null | undefined; firstName: string; lastName: string }
}

export interface UpcomingAppointmentsStatus {
  notCoveredAppointments: SimpleUpcomingAppointment[]
  providerCoverageIssues: {
    provider: { id: string; avatarUrl?: string | null | undefined; firstName: string; lastName: string }
    reason: string
  }[]
}

type Estimate = EstimatePaymentResponsibilityQuery['estimatePaymentResponsibility']

export function useCheckUpcomingAppointmentsForCoverage() {
  const [estimatePaymentResponsibility, estimatePaymentResult] = useEstimatePaymentResponsibilityLazyQuery()

  const estimateCache = useRef(new Map<string, Estimate>())

  const isCoveredFromEstimate = (estimate: Estimate) =>
    estimate.whoIsResponsibleForPayment === WhoIsResponsibleForPayment.Insurance

  const checkUpcomingAppointmentCoverage = useCallback(
    async (
      upcomingAppointments: SimpleUpcomingAppointment[],
      patient?: CoverageFormPatient,
    ): Promise<WrappedResponse<UpcomingAppointmentsStatus>> => {
      const appointmentStatuses = await Promise.all(
        upcomingAppointments.map(appointment => {
          const cachedEstimate = estimateCache.current.get(appointment.id)

          if (cachedEstimate !== undefined) {
            return Promise.resolve({
              appointment,
              isCovered: isCoveredFromEstimate(cachedEstimate),
              reason: cachedEstimate.cashFallbackReason,
            })
          }

          return estimatePaymentResponsibility({
            variables: { input: { appointmentId: appointment.id, ignorePaymentPreference: true } },
          })
            .then(result => {
              if (result.data) {
                estimateCache.current.set(appointment.id, result.data.estimatePaymentResponsibility)

                return {
                  appointment,
                  isCovered: isCoveredFromEstimate(result.data.estimatePaymentResponsibility),
                  reason: result.data.estimatePaymentResponsibility.cashFallbackReason,
                }
              }

              return { appointment, isCovered: null, reason: null }
            })
            .catch(() => {
              return { appointment, isCovered: null, reason: null }
            })
        }),
      )

      const providers = upcomingAppointments.length === 0 && !!patient ? patient.providers ?? [] : []
      const providerCoverageIssues = await Promise.all(
        providers.map(provider => {
          const cachedEstimate = estimateCache.current.get(provider.id)

          if (cachedEstimate !== undefined) {
            return cachedEstimate.cashFallbackReason
              ? Promise.resolve({
                  provider,
                  reason: cachedEstimate.cashFallbackReason,
                })
              : undefined
          }

          if (!patient) return undefined
          return estimatePaymentResponsibility({
            variables: { input: { providerId: provider.id, patientId: patient.id, ignorePaymentPreference: true } },
          })
            .then(result => {
              if (result.data) {
                estimateCache.current.set(provider.id, result.data.estimatePaymentResponsibility)

                if (result.data.estimatePaymentResponsibility.cashFallbackReason) {
                  return {
                    provider,
                    reason: result.data.estimatePaymentResponsibility.cashFallbackReason,
                  }
                }
              }

              return undefined
            })
            .catch(() => {
              return undefined
            })
        }),
      )

      const filteredProviderIssues = compact(providerCoverageIssues)
      if (filteredProviderIssues?.length) {
        return {
          didSucceed: true,
          error: null,
          data: { notCoveredAppointments: [], providerCoverageIssues: filteredProviderIssues },
        }
      }

      if (every(appointmentStatuses.map(status => status.isCovered !== null))) {
        const notCoveredAppointments = appointmentStatuses
          .filter(status => !status.isCovered)
          .map(status => status.appointment)

        return {
          didSucceed: true,
          error: null,
          data: { notCoveredAppointments, providerCoverageIssues: [] },
        }
      }

      return {
        didSucceed: false,
        error: new Error(`Failed to look up future coverages for appointments and providers`),
        data: null,
      }
    },
    [estimatePaymentResponsibility],
  )

  return [checkUpcomingAppointmentCoverage, estimatePaymentResult] as const
}
