import { styled } from '@mui/material'
import { StytchLogin } from '@stytch/react'
import { OAuthProviders, OneTapPositions, Products, type StyleConfig } from '@stytch/vanilla-js'
import { pick } from 'lodash'
import platform from 'platform'
import { useMemo } from 'react'

import type { Audience } from '@nuna/core'
import { useEnvironmentContext } from '@nuna/environment'
import { GhostButton, csx, greySet, sansSerifFontStack } from '@nuna/tunic'

import { ALLOWED_OAUTH_REDIRECT_PARAMS } from '../hooks/useSignupSearchParams'
import { useSignupSearchParams } from '../hooks/useSignupSearchParams'

export type AuthIntent = 'login' | 'signup'

const styles: StyleConfig = {
  container: {
    borderColor: 'none',
    width: '100%',
  },
  fontFamily: sansSerifFontStack,
  hideHeaderText: true,
}

const shouldUseOneTap = (audience: Audience) => {
  if (audience !== 'admin') return false
  if (platform.layout === 'WebKit') return false
  if (platform.name === 'Firefox') return false
  // TODO: OneTap appears to be broken after FedCM / Stytch upgrade issues
  return false
}

const getRolesForAudience = (audience: Audience) => {
  if (audience === 'admin') return ['admin', 'customer']
  if (audience === 'provider') return ['provider']
  return ['patient']
}

interface Props {
  audience: Audience
  emailHint?: string
  from?: string
  heading: React.ReactNode
  intent: AuthIntent
  onSwitchToPasswordLogin?: () => void
  showSwitchToPasswordLogin?: boolean
  extraSignupParams?: Record<string, string>
}

export function SocialLogin({
  audience,
  emailHint,
  from,
  heading,
  intent,
  onSwitchToPasswordLogin,
  showSwitchToPasswordLogin,
  extraSignupParams = {},
}: Props) {
  const { API_ENDPOINT } = useEnvironmentContext()
  const signupSearchParams = useSignupSearchParams()
  const allSignupParams = useMemo(
    () => ({ ...signupSearchParams, ...extraSignupParams }),
    [signupSearchParams, extraSignupParams],
  )

  const fromUrl = new URL(from ?? '', window.location.protocol + window.location.host)
  const roles = getRolesForAudience(audience)

  const loginRedirectURL = new URL(`${API_ENDPOINT}/oauth/authenticate`)
  loginRedirectURL.searchParams.append('from', encodeURIComponent(fromUrl.href))
  roles.forEach(role => loginRedirectURL.searchParams.append('roles', role))

  const signupRedirectURL = new URL(`${API_ENDPOINT}/oauth/signup`)
  roles.forEach(role => signupRedirectURL.searchParams.append('roles', role))
  Object.entries(pick(allSignupParams, ALLOWED_OAUTH_REDIRECT_PARAMS)).forEach(([param, value]) => {
    if (value) signupRedirectURL.searchParams.append(param, value)
  })

  const stytchConfig = {
    products: [Products.oauth],
    oauthOptions: {
      loginRedirectURL: intent === 'signup' ? signupRedirectURL.href : loginRedirectURL.href,
      signupRedirectURL: intent === 'signup' ? signupRedirectURL.href : loginRedirectURL.href,
      providers: [
        {
          type: OAuthProviders.Google,
          one_tap: shouldUseOneTap(audience),
          position: OneTapPositions.floating,
          provider_params: emailHint ? { login_hint: emailHint } : undefined,
        },
      ],
    },
  }

  return (
    <LoginContainer $centered={audience === 'admin'} className={csx(['flex-column'])}>
      {heading}
      <StytchLogin config={stytchConfig} styles={styles} />
      {showSwitchToPasswordLogin && (
        <GhostButton
          variant="secondary"
          onClick={() => {
            onSwitchToPasswordLogin?.()
          }}
        >
          Log in with password
        </GhostButton>
      )}
    </LoginContainer>
  )
}

const LoginContainer = styled('div')<{ $centered: boolean }>`
  align-items: ${props => (props.$centered ? 'center' : 'flex-start')};

  [id^='stytch-magic-link'] {
    width: 100%;
  }

  .oauth-buttons {
    font-weight: 500;
    /* Cancels out padding that comes with Stytch's social login component */
    margin: -24px -32px;
  }
`

export const OrDivider = styled('p')`
  &::before,
  &::after {
    background-color: ${greySet[30].hex};
    content: '';
    display: inline-block;
    height: 1px;
    position: relative;
    vertical-align: middle;
    width: 50%;
  }

  &::before {
    right: 0.75rem;
    margin-left: -50%;
  }

  &::after {
    left: 0.75rem;
    margin-right: -50%;
  }

  color: ${greySet[50].hex};
`
