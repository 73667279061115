import { styled } from '@mui/material'
import { uniqueId } from 'lodash'
import React, { useRef } from 'react'

import { greySet } from '../../styles/colorSets'
import { body1, interactiveText, interactiveTextHover } from '../../styles/colors'
import { shadowDepth } from '../../styles/shadows'

interface SegementedControlProps extends React.HTMLAttributes<HTMLDivElement> {
  children: React.ReactNode
  value: string
}

function SegmentedControl({ children, value, ...props }: SegementedControlProps) {
  return (
    <Group hasValue={!!value} role="group" {...props}>
      {children}
    </Group>
  )
}

const Group = styled('div')<{ hasValue: boolean }>`
  background-color: ${greySet[5].hex};
  border-radius: 6px;
  color: ${props => (props.hasValue ? greySet[70].hex : body1)};
  display: flex;
  padding: 8px 4px;
  justify-content: stretch;
`

interface SegementedControlItemProps extends React.HTMLProps<HTMLInputElement> {
  children: React.ReactNode
  containerProps?: React.HTMLAttributes<HTMLDivElement>
}

export function SegmentedControlItem({
  children,
  id,
  value,
  containerProps = {},
  ...props
}: SegementedControlItemProps) {
  const idRef = useRef(id ?? uniqueId('segmented-control-'))

  return (
    <Wrapper {...containerProps}>
      <input id={idRef.current} type="radio" value={value} {...props} />

      <LabelButton htmlFor={idRef.current}>{children}</LabelButton>
    </Wrapper>
  )
}

const Wrapper = styled('div')`
  flex: 1 1 0;
  font-weight: 500;
  padding-left: 4px;
  padding-right: 4px;

  input[type='radio'] {
    /* For more reading on why it's hidden this way: https://blog.bitsrc.io/customise-radio-buttons-without-compromising-accessibility-b03061b5ba93 */
    opacity: 0;
    pointer-events: none;
    position: absolute;
  }
`

const LabelButton = styled('label')`
  align-items: center;
  border-radius: 6px;
  border: 1px solid transparent;
  cursor: pointer;
  display: flex;
  height: 43px;
  justify-content: center;
  transition: box-shadow 0.2s, color 0.2s;

  input:not(:disabled):not(:checked) + &:hover {
    background-color: white;
    color: ${interactiveTextHover};
  }

  input:checked + & {
    background-color: white;
    box-shadow: ${shadowDepth(1.5)};
    color: ${interactiveText};
  }

  input:disabled + & {
    color: ${greySet[50].hex};
    cursor: default;
  }
`

SegmentedControl.Item = SegmentedControlItem

export { SegmentedControl }
