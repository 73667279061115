import {
  DailyVideo,
  useAudioTrack,
  useDaily,
  useDevices,
  useLocalSessionId,
  useVideoTrack,
} from '@daily-co/daily-react'
import { styled } from '@mui/material'
import { noop } from 'lodash'
import { HTMLAttributes, MouseEventHandler } from 'react'

import { Avatar, BelowTablet, IconArrow, IconGear } from '@nuna/tunic'

import { useVideoCallContext } from '../context/VideoCallContext'
import { videoSceneExtraDark, videoSceneTeal } from '../util/colors'
import { saveDevicePreferences } from '../util/devicePreferences'
import { MicrophoneButton } from './MicrophoneButton'
import { VideoToggleButton } from './VideoActionButton'
import { VideoOverlayText } from './VideoOverlayText'

interface VideoSettingsPreviewProps extends HTMLAttributes<HTMLDivElement> {
  showAudioVideoToggle?: boolean
  persist?: boolean
  roundedCorners?: boolean
}

type ConditionalSettingsProps =
  | { showSettings: true; onSettingsClick: MouseEventHandler<HTMLButtonElement>; isSettingsOpen: boolean }
  | { showSettings?: false; onSettingsClick?: () => void; isSettingsOpen?: boolean }

export function VideoSettingsPreview({
  showSettings = false,
  showAudioVideoToggle = true,
  onSettingsClick = noop,
  isSettingsOpen = false,
  persist = false,
  roundedCorners = false,
  ...props
}: VideoSettingsPreviewProps & ConditionalSettingsProps) {
  const callObject = useDaily()
  const localSessionId = useLocalSessionId()
  const { cameras } = useDevices()
  const localVideoTrack = useVideoTrack(localSessionId)
  const localAudioTrack = useAudioTrack(localSessionId)
  const { localUser } = useVideoCallContext()

  const handleVideoToggleClick = () => {
    callObject?.setLocalVideo(localVideoTrack.isOff)
    if (persist) {
      saveDevicePreferences({ isVideoEnabled: localVideoTrack.isOff })
    }
  }
  const handleAudioToggleClick = () => {
    callObject?.setLocalAudio(localAudioTrack.isOff)
    if (persist) {
      saveDevicePreferences({ isAudioEnabled: localAudioTrack.isOff })
    }
  }

  return (
    <VideoContainer
      className="fs-exclude"
      isVideoEnabled={!localVideoTrack.isOff}
      $roundedCorners={roundedCorners}
      {...props}
    >
      {localVideoTrack.isOff && (
        <VideoDisabledMessage>
          <VideoOverlayText>{localVideoTrack ? 'Join with camera off' : 'No camera available'}</VideoOverlayText>
          <Avatar className="mt-1" size="sm" url={localUser.avatarUrl} />
        </VideoDisabledMessage>
      )}

      {(showSettings || showAudioVideoToggle) && (
        <Settings>
          {showAudioVideoToggle && (
            <>
              <VideoToggleButton
                disabled={cameras.length === 0}
                compact
                isEnabled={!localVideoTrack.isOff}
                onClick={handleVideoToggleClick}
              />
              <MicrophoneButton
                disabled={!localAudioTrack}
                compact
                isEnabled={!localAudioTrack.isOff}
                onClick={handleAudioToggleClick}
              />
            </>
          )}

          {showSettings && (
            <SettingsButton
              showBackButton={isSettingsOpen}
              onClick={onSettingsClick}
              data-component="video-knock-settings"
            >
              {isSettingsOpen ? <IconArrow direction="left" size={20} /> : <IconGear />}
            </SettingsButton>
          )}
        </Settings>
      )}

      <DailyVideo fit="cover" automirror sessionId={localSessionId} type="video" />
    </VideoContainer>
  )
}

const Settings = styled('div')`
  position: absolute;
  bottom: 1rem;
  z-index: 1;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`

const SettingsButton = styled('button')<{ showBackButton: boolean }>`
  align-items: center;
  background-blend-mode: multiply;
  background-color: ${props => (props.showBackButton ? videoSceneTeal : 'rgba(88, 88, 88, 0.4);')};
  border-radius: 6px;
  border: 2px solid rgba(16, 40, 54, 0.26);
  color: #fff;
  display: inline-flex;
  height: 40px;
  justify-content: center;
  position: absolute;
  right: 1rem;
  width: 40px;

  &:focus {
    outline: none;
  }

  &:hover,
  &:focus {
    border-color: #fff;
  }
`

const VideoContainer = styled('div')<{ isVideoEnabled: boolean; $roundedCorners: boolean }>`
  align-items: center;
  background-color: ${videoSceneExtraDark};
  ${props => props.$roundedCorners && 'border-radius: 12px;'}
  display: flex;
  height: 0;
  justify-content: center;
  overflow: hidden;
  padding-bottom: 75%;
  position: relative;

  video {
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;

    @media (${BelowTablet}) {
      object-fit: contain;
    }
  }

  ${props =>
    !props.isVideoEnabled &&
    `
    video {
      display: none;
    }
  `}
`

const VideoDisabledMessage = styled('div')`
  align-items: center;
  display: flex;
  flex-direction: column;
  position: absolute;
  text-align: center;
  top: calc(50% - 26px);
  transform: translateY(-50%);
  width: 100%;
`
