import mixpanel from 'mixpanel-browser'
import { useCallback } from 'react'
import { useSearchParams } from 'react-router-dom'

export type RankTrackingSearchParams = {
  providerId: string
  providerRank: string
  totalSuggestions: string
  score: string
}

export function useRankTracking() {
  const [searchParams, setSearchParams] = useSearchParams()

  const setProviderRank = useCallback(
    (values: RankTrackingSearchParams) => {
      setSearchParams(
        new URLSearchParams({
          providerId: values.providerId,
          r: values.providerRank,
          n: values.totalSuggestions,
          s: values.score,
        }),
        { replace: true },
      )
    },
    [setSearchParams],
  )

  const trackProviderRank = () => {
    const providerId = searchParams.get('providerId')
    const providerRankString = searchParams.get('r')
    const totalSuggestionsString = searchParams.get('n')
    const scoreString = searchParams.get('s')

    if (providerId && providerRankString && totalSuggestionsString && scoreString) {
      mixpanel.track('Scheduled with ranked provider', {
        providerId,
        providerRank: parseInt(providerRankString, 10),
        totalSuggestions: parseInt(totalSuggestionsString, 10),
        score: parseFloat(scoreString),
      })
    }
  }

  return { setProviderRank, trackProviderRank }
}
