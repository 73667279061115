import { styled } from '@mui/material'
import { replace } from 'lodash'
import { HTMLAttributes } from 'react'

import { BelowTablet, csx } from '@nuna/tunic'
import { Chip, FillButton, GhostButtonLink } from '@nuna/tunic'

interface TimezoneMismatchPromptProps extends HTMLAttributes<HTMLDivElement> {
  patientTimezone: string
  browserTimezone: string
  onDismiss(): void
  compact?: boolean
}

export function TimezoneMismatchPrompt({
  onDismiss,
  patientTimezone,
  browserTimezone,
  compact = false,
  ...props
}: TimezoneMismatchPromptProps) {
  const humanizeTimezone = (timezone: string): string => replace(timezone, '_', ' ')

  return (
    <Container {...props}>
      <Top>
        <Heading compact={compact}>Hmmm, looks like you're in a different time zone than normal</Heading>
        <PromptText compact={compact}>Your primary time zone is set to:</PromptText>

        {compact ? (
          <p>{humanizeTimezone(patientTimezone || '')}</p>
        ) : (
          <ChipStyled className="mx-2" disabled>
            {humanizeTimezone(patientTimezone || '')}
          </ChipStyled>
        )}

        <GhostButtonLink className="mt-3" to="/profile">
          Update primary time zone
        </GhostButtonLink>
      </Top>
      <Bottom compact={compact}>
        <FillButton onClick={onDismiss}>Load Schedule in {humanizeTimezone(browserTimezone)}</FillButton>
        <Fyi className={csx([{ 'text-medium caption': compact }])} compact={compact}>
          <span className="text-bold">Fyi:</span> If you plan to attend this appointment back in your primary time zone,
          please adjust your schedule accordingly
        </Fyi>
      </Bottom>
    </Container>
  )
}

const Container = styled('div')`
  margin-bottom: 40px;

  @media (${BelowTablet}) {
    margin: 0;
  }
`

const Heading = styled('h4')<{ compact: boolean }>`
  margin: 0 3rem 3rem 0rem;

  ${({ compact }) => compact && `margin: 0.5rem 0; font-size: 1.5rem;`}

  @media (${BelowTablet}) {
    margin: 0.5rem 0;
    font-size: 1.5rem;
  }
`

const ChipStyled = styled(Chip)`
  @media (${BelowTablet}) {
    margin-left: 0;
  }
`

const Top = styled('div')``

const Bottom = styled('div')<{ compact: boolean }>`
  margin-top: 70px;

  ${({ compact }) => compact && `margin-top: var(--margin-4);`}
`

const PromptText = styled('span')<{ compact: boolean }>`
  font-size: 18px;
  line-height: 40px;

  ${({ compact }) => compact && `font-size: 1rem;`}

  @media (${BelowTablet}) {
    display: block;
    margin-bottom: 8px;
  }
`

const Fyi = styled('p')<{ compact: boolean }>`
  max-width: 45%;
  margin-top: 20px;

  ${({ compact }) => compact && `max-width: 100%;`}

  @media (${BelowTablet}) {
    max-width: 100%;
  }
`
