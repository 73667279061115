import { useApolloClient } from '@apollo/client'
import { noop } from 'lodash'
import mixpanel from 'mixpanel-browser'
import { createContext, useCallback, useContext } from 'react'

import { LoggedInUserDocument, Role, useLoggedInUserQuery, useLogoutMutation } from '@nuna/api'

import { redirectToLogin } from '../util/utils'

export interface LoginData {
  id: string
  email: string
  firstName: string
  lastName: string
  role: Role | ''
  companyId?: string
  providerId?: string | null | undefined
  patientId?: string | null | undefined
  partnerAdminId?: string | null | undefined
  partnerId?: string | null | undefined
  organizationId?: string | null | undefined
  organizationSlug?: string | null | undefined
  memberId?: string | null | undefined
}

export interface AuthData {
  login?: LoginData
}

interface AuthDataContext extends AuthData {
  onLogin: (val: AuthData) => void
  onLogout: () => void
  loading: boolean
  loggedIn: boolean
}

export const AuthDataContext = createContext<AuthDataContext>({
  onLogin: noop,
  onLogout: noop,
  loading: false,
  loggedIn: false,
})

interface Props {
  allowedRoles: Role[]
  [props: string]: unknown
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const AuthDataProvider = ({ allowedRoles, ...props }: Props) => {
  const client = useApolloClient()
  const [logout] = useLogoutMutation({ fetchPolicy: 'no-cache' })
  const { data, loading, refetch } = useLoggedInUserQuery({
    onCompleted: currentLogin => {
      const currentRole = currentLogin.authContext?.role
      if (currentRole && allowedRoles.includes(currentRole)) return

      // If the login we get for a user when loading the app doesn't match the allowed
      // roles for this app, we treat them as logged out (aka don't persist their auth)
      // information to the cache at all. This can happen if a user is already logged
      // into one app and tries to open another app in the same browser context.
      client.writeQuery({ query: LoggedInUserDocument, data: { authContext: null } })
    },
  })
  const { authContext } = data ?? {}

  const onLogout = useCallback(
    async (role?: Role) => {
      await Promise.all([client.clearStore(), logout()])

      mixpanel.track('logout', {})
      redirectToLogin({ role, organizationSlug: authContext?.organizationSlug ?? undefined })
    },
    [authContext, client, logout],
  )

  const onLogin = async (loginData: AuthData) => {
    if (!loginData.login?.role) return

    client.writeQuery({
      query: LoggedInUserDocument,
      // Apollo cache expects providerId, patientId, partnerId, and partnerAdminId to always be provided for the Login type,
      // and the sign-up mutations only provide one or the other. So we default them here.
      data: {
        authContext: { providerId: null, patientId: null, partnerAdminId: null, partnerId: null, ...loginData.login },
      },
    })
    refetch()

    mixpanel.register({ id: loginData?.login?.id })
    mixpanel.track('login')
  }

  const authDataValue = {
    login: authContext ?? undefined,
    loading,
    loggedIn: !!authContext?.id,
    onLogin,
    onLogout,
  }

  return <AuthDataContext.Provider value={authDataValue} {...props} />
}

export function useAuthDataContext() {
  return useContext(AuthDataContext)
}
