import { styled } from '@mui/material'

import { BelowTablet, Box, borderGrey, greySet, interactiveText } from '@nuna/tunic'

import laptopMan from '../../../img/laptop-man.png'

interface EmailedParentGuardianProps {
  parentGuardianEmail: string
}

export const EmailedParentGuardian = ({ parentGuardianEmail }: EmailedParentGuardianProps) => (
  <>
    <Main as="main">
      <h1 className="h4 mt-6">We've emailed your parent or guardian at the following email address:</h1>
      <Email>{parentGuardianEmail}</Email>
      <p className="h6 mt-3 sans-serif">
        Keep an eye on your own email. We’ll drop you a message as soon as your parent or guardian signs a few
        documents.
      </p>
    </Main>
    <Box display="flex" justifyContent="center" alignItems="flex-start" mt={6}>
      <img src={laptopMan} alt="man holding laptop illustration" style={{ maxWidth: '200px' }} />
    </Box>
  </>
)

const Main = styled('div')`
  padding: 0 2rem;
  h1 {
    line-height: 1.2;

    @media (${BelowTablet}) {
      font-size: 2rem;
    }
  }

  p {
    line-height: 1.4;

    @media (${BelowTablet}) {
      font-size: 1.1875rem;
    }
  }
`

const Email = styled('span')`
  background: ${greySet[15].hex};
  border-radius: var(--border-radius);
  border: 1px solid ${borderGrey};
  color: ${interactiveText};
  display: inline-block;
  font-weight: 600;
  padding: 20px 22px;
  overflow: hidden;
  overflow-wrap: break-word;
  max-width: 100%;
`
