import { IconProps } from './IconProps'

export function IconReports({ size = 24, color = 'currentColor', ...props }: IconProps) {
  return (
    <svg width={size} height={size} viewBox="0 0 56 56" fill={color} xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M39.1111 45C39.1111 45.5523 39.5588 46 40.1111 46H47C47.5523 46 48 45.5523 48 45V17.5263C48 16.974 47.5523 16.5263 47 16.5263H40.1111C39.5588 16.5263 39.1111 16.974 39.1111 17.5263V45ZM23.5556 45C23.5556 45.5523 24.0033 46 24.5556 46H31.4444C31.9967 46 32.4444 45.5523 32.4444 45V7C32.4444 6.44771 31.9967 6 31.4444 6H24.5556C24.0033 6 23.5556 6.44772 23.5556 7V45ZM8 45C8 45.5523 8.44772 46 9 46H15.8889C16.4412 46 16.8889 45.5523 16.8889 45V30.1579C16.8889 29.6056 16.4412 29.1579 15.8889 29.1579H9C8.44772 29.1579 8 29.6056 8 30.1579V45Z"
        fill="#596778"
      />
    </svg>
  )
}
