import { ChangeEvent, HTMLAttributes } from 'react'

import { BasicSpecialtyFragment, useSpecialtiesQuery } from '@nuna/api'
import { specialtiesService } from '@nuna/core'
import { Radio } from '@nuna/tunic'

interface Props extends Omit<HTMLAttributes<HTMLDivElement>, 'onChange'> {
  value: BasicSpecialtyFragment | null
  onChange: (specialty: BasicSpecialtyFragment | null) => void
}

export function GenderExclusionsSelect({ value, onChange, ...props }: Props) {
  const { data } = useSpecialtiesQuery()
  const menOnly = data?.specialties.find(specialty => specialty.name === specialtiesService.MEN_ONLY)
  const womenOnly = data?.specialties.find(specialty => specialty.name === specialtiesService.WOMEN_ONLY)

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    const inputValue = event.currentTarget.value
    let value: BasicSpecialtyFragment | null = null

    if (inputValue === menOnly?.id) {
      value = menOnly
    } else if (inputValue === womenOnly?.id) {
      value = womenOnly
    }

    onChange(value)
  }

  return (
    <div role="group" {...props}>
      <Radio onChange={handleChange} value={undefined} checked={value === null} dataTestId="gender-1">
        I work with all genders
      </Radio>
      <Radio onChange={handleChange} value={womenOnly?.id} checked={value?.id === womenOnly?.id} dataTestId="gender-2">
        I work ONLY with Women
      </Radio>
      <Radio onChange={handleChange} value={menOnly?.id} checked={value?.id === menOnly?.id} dataTestId="gender-3">
        I work ONLY with Men
      </Radio>
    </div>
  )
}
