import { formatPhoneNumber } from 'react-phone-number-input'

import { SessionPatientDetailsFragment } from '@nuna/api'

interface EmergencyContactGlanceProps {
  patient?: SessionPatientDetailsFragment
}

export function EmergencyContactGlance({ patient }: EmergencyContactGlanceProps) {
  if (!patient) {
    return <div className="loading" style={{ height: 164, borderRadius: 6 }}></div>
  }

  const { emergencyContactName, emergencyContactRelationship, emergencyContactPhone } = patient
  return (
    <div className="mt-1 mb-4 text-dark-gray fs-exclude">
      {emergencyContactName} / {emergencyContactRelationship} <br /> {formatPhoneNumber(emergencyContactPhone ?? '')}
    </div>
  )
}
