import { cloneDeep, findIndex, isNil } from 'lodash'
import { useCallback, useEffect, useMemo, useState } from 'react'

import { OrderBy, PaginationFragment, TraversablePaginationInput, TraversablePaginationSortInput } from '@nuna/api'

import { DataTableState } from '../components/DataTable'
import { PaginatorProps } from '../components/Paginator'

interface PaginationOptions {
  limit: number
  totalLabel?: string | ((plural: boolean) => string)
}

const DEFAULT_OPTIONS: PaginationOptions = {
  limit: 20,
  totalLabel: 'total records',
}

interface Config<F extends object> {
  pagination?: PaginationFragment | null
  loading: boolean
  initialSort?: TraversablePaginationSortInput[]
  filters?: F
}

interface QueryOptions<F extends object> {
  paginationInput: TraversablePaginationInput
  sortInput: TraversablePaginationSortInput[]
  filters?: F
}

export function usePagination<T extends object, F extends object>(
  { pagination: initialPagination, loading: initialLoading, initialSort = [], filters }: Config<F>,
  options?: Partial<PaginationOptions>,
) {
  const [pagination, setPagination] = useState<PaginationFragment | null | undefined>(() => initialPagination)
  const [loading, setLoading] = useState<boolean>(() => initialLoading)
  const memoizedOptions = useMemo(() => ({ ...DEFAULT_OPTIONS, ...(options ?? {}) }), [options])
  const totalLabel = useMemo(() => {
    if (typeof memoizedOptions.totalLabel === 'string') {
      return memoizedOptions.totalLabel
    }

    if (typeof memoizedOptions.totalLabel === 'function') {
      return memoizedOptions.totalLabel((pagination?.totalCount ?? 0) > 1)
    }

    return ''
  }, [pagination, memoizedOptions])
  const { limit } = memoizedOptions

  const [queryOptions, setQueryOptions] = useState<QueryOptions<F>>({
    paginationInput: { limit, page: 1 },
    sortInput: initialSort,
    filters,
  })

  const setPage = useCallback((page: number) => {
    setQueryOptions(current => {
      const { paginationInput } = current
      return { ...current, paginationInput: { ...paginationInput, page } }
    })
  }, [])

  const initialTableState = useMemo<DataTableState<T>>(
    () => ({
      sortBy: initialSort.map(sortItem => ({
        /** The type safety isn't great here because key on TraversablePaginationSortInput is typed as a string */
        id: sortItem.key as keyof T,
        desc: sortItem.direction === OrderBy.Desc,
      })),
    }),
    [initialSort],
  )

  const getPaginatorProps = useCallback<() => PaginatorProps>(
    () => ({
      ...(pagination ?? {}),
      limit: memoizedOptions.limit,
      loading,
      onPageChange: newPage => setPage(newPage),
      page: queryOptions.paginationInput.page ?? undefined,
      totalLabel,
    }),
    [loading, pagination, memoizedOptions, queryOptions, setPage, totalLabel],
  )

  const handleSort = useCallback((key: string, desc: boolean | undefined, multiSort: boolean) => {
    setQueryOptions(currentQuery => {
      const newQuery: QueryOptions<F> = {
        ...currentQuery,
        paginationInput: { ...currentQuery.paginationInput, page: 1 },
      }
      const cloned = cloneDeep(currentQuery.sortInput)
      const existingIdx = findIndex(cloned, sortItem => sortItem.key === key)

      if (isNil(desc)) {
        cloned.splice(existingIdx, 1)
        return { ...newQuery, sortInput: cloned }
      }

      const direction = desc ? OrderBy.Desc : OrderBy.Asc
      const newSortItem: TraversablePaginationSortInput = { key, direction }

      let sortItems: TraversablePaginationSortInput[] = []

      if (!multiSort) {
        sortItems = [newSortItem]
      } else {
        if (existingIdx >= 0) {
          cloned.splice(existingIdx, 1, newSortItem)
        } else {
          cloned.push(newSortItem)
        }
        sortItems = cloned
      }

      return { ...newQuery, sortInput: sortItems }
    })
  }, [])

  useEffect(() => setPagination(initialPagination), [initialPagination])
  useEffect(() => setLoading(initialLoading), [initialLoading])

  useEffect(() => {
    // Apply new filters and reset page to 1
    setQueryOptions(currentQuery => {
      const { paginationInput } = currentQuery
      return { ...currentQuery, filters, paginationInput: { ...paginationInput, page: 1 } }
    })
  }, [filters])

  return {
    limit,
    getPaginatorProps,
    handleSort,
    initialTableState,
    queryOptions,
    setPage,
    setPagination,
    setLoading,
  }
}
