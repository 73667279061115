import { DailyEventObjectAppMessage } from '@daily-co/daily-js'
import { useCallback, useState } from 'react'

import { DailyMessage, useDailyMessage } from '../../hooks/useDailyMessage'
import { PatientVideoUser, TavaVideoUser } from '../../types'
import { isPatient } from '../../util/videoUsers'

interface AutonotesStatus {
  enabled: boolean
  showAlert: boolean
}

export function useAutonotesContext({
  localUser,
  expectedUsers,
}: {
  localUser: TavaVideoUser
  expectedUsers: TavaVideoUser[]
}) {
  // this is built as a map to support multiple patients when that day comes
  const [patientAutonotesStatus, setPatientAutonotesStatus] = useState<Map<string, AutonotesStatus>>(() => {
    if (localUser.isProvider) {
      // if local user is provider, put all other patients' status in the map
      return new Map(
        expectedUsers
          .filter(isPatient)
          .map(patient => [patient.loginId, { enabled: patient.initialAutonotesEnabled, showAlert: false }]),
      )
    }

    // if local user is the patient, just put their status in the map
    return new Map([[localUser.loginId, { enabled: localUser.initialAutonotesEnabled, showAlert: false }]])
  })

  // util to simplify updating the map safely
  const updatePatientAutonotesStatus = useCallback((loginId: string, value: AutonotesStatus) => {
    setPatientAutonotesStatus(prev => {
      const newMap = new Map(prev)
      newMap.set(loginId, value)
      return newMap
    })
  }, [])

  // update state when receiving toggle notifications from patient
  const handleAutonotesToggled = useCallback(
    (e: DailyEventObjectAppMessage<DailyMessage>) => {
      if (e.data.type === 'autonotes-toggle') {
        const loginId = e.data.loginId
        const enabled = e.data.enabled

        updatePatientAutonotesStatus(loginId, { enabled, showAlert: true })
      }
    },
    [updatePatientAutonotesStatus],
  )

  const sendDailyMessage = useDailyMessage(handleAutonotesToggled)

  // called as patient when toggling preference, updates local state and sends notification to provider
  const togglePatientAutonotesStatus = useCallback(
    (user: PatientVideoUser, value: boolean) => {
      updatePatientAutonotesStatus(user.loginId, { enabled: value, showAlert: false })
      sendDailyMessage({ type: 'autonotes-toggle', enabled: value, loginId: user.loginId })
    },
    [sendDailyMessage, updatePatientAutonotesStatus],
  )

  const dismissPatientAutonoteAlert = useCallback(
    (user: PatientVideoUser) => {
      const status = patientAutonotesStatus.get(user.loginId)
      if (!status) {
        console.error('Tried to dismiss alert for patient with no status')
        return
      }

      updatePatientAutonotesStatus(user.loginId, {
        enabled: status.enabled,
        showAlert: false,
      })
    },
    [patientAutonotesStatus, updatePatientAutonotesStatus],
  )

  // get status for a specific patient
  const getPatientAutonotesStatus = useCallback(
    (user: PatientVideoUser) => {
      const status = patientAutonotesStatus.get(user.loginId)
      if (!status) {
        console.error('Tried to get status for patient with no status')
        return { enabled: false, showAlert: false }
      }

      return status
    },
    [patientAutonotesStatus],
  )

  // simplified boolean status when only needing the enabled status and not alerts
  const isPatientAutonotesEnabled = useCallback(
    (user: PatientVideoUser) => getPatientAutonotesStatus(user).enabled,
    [getPatientAutonotesStatus],
  )

  return {
    dismissPatientAutonoteAlert,
    getPatientAutonotesStatus,
    isPatientAutonotesEnabled,
    togglePatientAutonotesStatus,
  }
}

export type AutonotesContextValues = ReturnType<typeof useAutonotesContext>
