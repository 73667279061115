import { MutationFunctionOptions, MutationHookOptions } from '@apollo/client'

import {
  ContractType,
  PatientIntakeContractFragment,
  SavePatientAccessCodeMutation,
  SavePatientAccessCodeMutationVariables,
  useSavePatientAccessCodeMutation,
} from '@nuna/api'
import { errorService } from '@nuna/core'

import { WrappedResponse } from '../types'

export function useSaveAccessCodeCoverage(
  baseOptions?: MutationHookOptions<SavePatientAccessCodeMutation, SavePatientAccessCodeMutationVariables>,
) {
  const [savePatientAccessCodeMutation, mutationResult] = useSavePatientAccessCodeMutation(baseOptions)

  const savePatientAccessCode = async (
    options?: MutationFunctionOptions<SavePatientAccessCodeMutation, SavePatientAccessCodeMutationVariables>,
  ): Promise<WrappedResponse<PatientIntakeContractFragment>> => {
    try {
      const response = await savePatientAccessCodeMutation(options)

      if (response.data) {
        const couponContract = response.data.savePatientAccessCode.contractRelations?.find(
          contract => contract?.contractType === ContractType.CouponContract,
        )

        if (couponContract) {
          return {
            didSucceed: true,
            error: null,
            data: couponContract,
          }
        }
      }
    } catch (e) {
      return {
        didSucceed: false,
        error: errorService.getErrorFromUnknown(e),
        data: null,
      }
    }

    return {
      didSucceed: false,
      error: Error(
        "Oops, we weren't able to find an eligible contract. Double check your information or contact support.",
      ),
      data: null,
    }
  }

  return [savePatientAccessCode, mutationResult] as const
}
