import { styled } from '@mui/material'
import { formatPhoneNumber } from 'react-phone-number-input'

import { IconPhone, tealSet, whiteTintSet } from '@nuna/tunic'

interface LiveSessionPhonePromptProps {
  mobilePhone: string | undefined | null
  minutesFromNow: number | null | undefined
}

export function LiveSessionPhonePrompt({ mobilePhone, minutesFromNow }: LiveSessionPhonePromptProps) {
  if (!mobilePhone || !minutesFromNow) return null
  if (minutesFromNow > -5 || minutesFromNow <= -15) return null

  return (
    <Container>
      <p className="caption italic">If they haven't shown up yet, give them a call</p>
      <div className="v-align">
        <IconPhone className="mr-1" size={20} color={tealSet[30].hex} />
        <Phone className="caption text-medium">{formatPhoneNumber(mobilePhone ?? '')}</Phone>
      </div>
    </Container>
  )
}

const Container = styled('div')`
  border-top: 1px solid ${whiteTintSet[15]};
  color: ${whiteTintSet[70]};
  margin-top: var(--margin-1);
  padding-top: var(--margin-1);
`

const Phone = styled('span')`
  color: #fff;
`
