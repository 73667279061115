import { Moment } from 'moment'
import { useEffect, useState } from 'react'

import { Box, BoxProps, Typography } from '@nuna/tunic'

interface Props extends BoxProps {
  loading: boolean
  lastSavedAt: Moment | undefined
}

const TEN_SECONDS = 10000 // since we're using fromNow (a few seconds ago, a minute ago, etc.), we don't need to update it every second

export function DraftSavingStatus({ loading, lastSavedAt, ...props }: Props) {
  const [timeAgoString, setTimeAgoString] = useState(lastSavedAt?.fromNow())

  useEffect(() => {
    setTimeAgoString(lastSavedAt?.fromNow())

    const interval = setInterval(() => {
      setTimeAgoString(lastSavedAt?.fromNow())
    }, TEN_SECONDS)

    return () => clearInterval(interval)
  }, [lastSavedAt])

  if (!loading && !timeAgoString) {
    return null
  }

  return (
    <Box {...props}>
      <Typography variant="caption">{loading ? 'Saving...' : `Draft saved ${timeAgoString}`}</Typography>
    </Box>
  )
}
