import { styled } from '@mui/material'
import { Message } from '@twilio/conversations'
import Linkify from 'linkify-react'
import moment from 'moment'

import { Avatar, greySet, tealSet } from '@nuna/tunic'

import { useVideoChatContext } from '../../../context/VideoChatContext'
import { useTavaVideoUsers } from '../../../hooks/useTavaVideoUsers'

interface Props {
  message: Message
  index: number
}

export function VideoChatMessage({ message, index }: Props) {
  const { messages, previousLastReadMessageIndex } = useVideoChatContext()
  const { getUserByLoginId } = useTavaVideoUsers()

  const previousMessage = messages[index - 1]
  const isNewMessage = previousMessage && previousMessage.index === previousLastReadMessageIndex
  const showUserHeader = shouldDisplayUserHeader(message, previousMessage)

  return (
    <>
      {isNewMessage && (
        <NewMessageLine className="v-align mt-1">
          <hr />
          <span className="italic text-teal ml-1 mr-1 small text-bold">NEW</span>
        </NewMessageLine>
      )}

      {showUserHeader && (
        <AuthorHeader className="v-align">
          <HeaderLeftSpacer>
            <Avatar
              url={message.author ? getUserByLoginId(message.author)?.avatarUrl : undefined}
              size="mini"
              className="fs-exclude"
            />
          </HeaderLeftSpacer>
          <span className="small text-bold">
            {message.author ? getUserByLoginId(message.author)?.firstName : undefined}
          </span>
          <span className="italic small pl-xs">{moment(message.dateCreated).format('h:mma')}</span>
        </AuthorHeader>
      )}

      <MessageBody className={messages.length - 1 === index ? 'mb-2' : ''}>
        <HeaderLeftSpacer />
        <span className="fs-exclude message-content">
          <Linkify
            options={{
              target: '_blank',
              rel: 'noreferrer',
            }}
          >
            {message.body}
          </Linkify>
        </span>
      </MessageBody>
    </>
  )
}

function shouldDisplayUserHeader(message: Message, previousMessage: Message | undefined) {
  if (!previousMessage) {
    return true
  }

  if (message.author !== previousMessage.author) {
    return true
  }

  if (message.dateCreated?.getMinutes() !== previousMessage.dateCreated?.getMinutes()) {
    return true
  }

  return false
}

export const AuthorHeader = styled('div')`
  color: ${greySet[50].hex};
  margin-bottom: 0.05rem;
`

export const HeaderLeftSpacer = styled('span')`
  display: inline-block;
  width: 34px;
`

const NewMessageLine = styled('div')`
  hr {
    background-color: ${tealSet[50].hex};
    border: 0;
    flex: 1;
    height: 1px;
  }
`

const MessageBody = styled('div')`
  color: ${greySet[70].hex};
  display: flex;
  margin-bottom: 0.5rem;

  /* from https://css-tricks.com/snippets/css/prevent-long-urls-from-breaking-out-of-container/ */
  /* These are technically the same, but use both */
  overflow-wrap: break-word;
  word-wrap: break-word;

  -ms-word-break: break-all;
  /* This is the dangerous one in WebKit, as it breaks things wherever */
  word-break: break-all;
  /* Instead use this non-standard one: */
  word-break: break-word;

  /* Adds a hyphen where the word breaks, if supported (No Blink) */
  -ms-hyphens: auto;
  -moz-hyphens: auto;
  -webkit-hyphens: auto;
  hyphens: auto;

  .message-content {
    flex: 1;
  }
`
