import { styled } from '@mui/material'
import { uniqueId } from 'lodash'
import React, { useRef } from 'react'

import { greySet, tealSet } from '../../styles/colorSets'
import { error } from '../../styles/colors'
import check from './img/check.svg'

interface LabelProps extends React.HTMLAttributes<HTMLLabelElement> {
  'data-testid'?: string
}

export interface CheckboxProps extends React.HTMLProps<HTMLInputElement> {
  containerProps?: React.HTMLAttributes<HTMLDivElement>
  error?: boolean
  labelProps?: LabelProps
  onChange?: React.ChangeEventHandler<HTMLInputElement>
}

export function Checkbox({
  id,
  style,
  className,
  onChange,
  checked,
  children,
  containerProps = {},
  labelProps = {},
  error = false,
  ...props
}: CheckboxProps) {
  const idRef = useRef(id ?? uniqueId('checkbox-'))

  return (
    <Container className={className} style={style} {...containerProps}>
      <input
        id={idRef.current}
        type="checkbox"
        checked={checked}
        onChange={onChange}
        className="fs-exception"
        {...props}
      />

      <Label error={error} htmlFor={idRef.current} {...labelProps} disabled={props.disabled}>
        <CheckboxSquare error={error} className="checkbox-display" />

        {children}
      </Label>
    </Container>
  )
}

const Container = styled('div')`
  position: relative;

  input[type='checkbox'] {
    /* For more reading on why it's hidden this way: https://blog.bitsrc.io/customise-radio-buttons-without-compromising-accessibility-b03061b5ba93 */
    opacity: 0;
    position: absolute;
    pointer-events: none;
  }

  [role='group'] & {
    margin-bottom: 1rem;
  }
`

const CheckboxSquare = styled('span')<{ error?: boolean }>`
  border-radius: 4px;
  display: inline-block;
  flex: 0 0 auto;
  height: 24px;
  margin-right: 0.5rem;
  position: relative;
  width: 24px;

  &::after {
    border: 2px solid ${props => (props.error ? error : greySet[50].hex)};
    border-radius: 4px;
    content: '';
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
  }
`

const Label = styled('label')<{ error?: boolean; disabled?: boolean }>`
  cursor: pointer;
  display: flex;

  ${props => props.error && `color: ${error};`};

  ${props =>
    props.disabled &&
    `
    color: ${greySet.tint[60]};
    cursor: default;
  `}

  line-height: 24px;

  // Hover
  input:not(:disabled) + &:hover > ${CheckboxSquare} {
    &::after {
      border-color: ${props => (props.error ? error : greySet[70].hex)};
      height: calc(100% + 2px);
      left: -1px;
      position: absolute;
      top: -1px;
      width: calc(100% + 2px);
    }
  }

  // Checked
  input:checked + & > ${CheckboxSquare} {
    background-color: ${tealSet[50].hex};
    // prettier-ignore
    background-image: url("${check}");
    background-position: center;

    &::after {
      border-color: ${tealSet[50].hex};
    }
  }

  // Checked + Hover
  input:checked + &:hover > ${CheckboxSquare} {
    background-color: ${tealSet[50].hover};

    &::after {
      border-color: ${tealSet[50].hover};
    }
  }

  // Focus
  input:focus:not(:checked) + & > ${CheckboxSquare} {
    box-shadow: 0 0 0 6px ${greySet.tint[40]};

    &::after {
      border-color: ${props => (props.error ? error : greySet[70].hex)};
    }
  }

  // Focus + Checked
  input:focus:checked + & > ${CheckboxSquare} {
    box-shadow: 0 0 0 6px ${tealSet.tint[40]};
  }

  input:disabled + & > ${CheckboxSquare} {
    color: ${greySet[50].transparency(0.55)};
  }

  // Disabled + Not Checked
  input:disabled:not(:checked) + & > ${CheckboxSquare} {
    &::after {
      border-color: ${greySet.tint[60]};
    }
  }

  // Disabled + Checked
  input:disabled:checked + & > ${CheckboxSquare} {
    background-color: ${tealSet[50].transparency(0.55)};
    &::after {
      border-color: transparent;
    }
  }
`
