import { isNil } from 'lodash'

import { EapCoverageDetailsFragment } from '@nuna/api'
import { Card, CardBody, CardHeader, CardProps, IconCustomer, StatusLabel } from '@nuna/tunic'

import { CoverageLogoRatio, CoveragePanelName } from './CoverageTypography'

interface Props extends CardProps {
  coverage: EapCoverageDetailsFragment
}

export function EapCoverageCard({ coverage, ...props }: Props) {
  const { employerLogo, employerName, sessionsCapExceeded, sessionsCap } = coverage

  const isZeroCapCompany = !isNil(sessionsCap) && sessionsCap === 0

  return (
    <Card depth={sessionsCapExceeded ? -4 : 1} {...props}>
      <CardHeader className="v-align space-between">
        <span>Employer Benefit</span>
        <IconCustomer />
      </CardHeader>
      <CardBody>
        {employerLogo && <CoverageLogoRatio className="mt-1" src={employerLogo} alt={employerName} />}
        {!employerLogo && <CoveragePanelName className="mt-1">{employerName}</CoveragePanelName>}
        {!isZeroCapCompany && sessionsCapExceeded && (
          <StatusLabel intent="urgent" className="mt-1">
            No sessions remaining
          </StatusLabel>
        )}
      </CardBody>
    </Card>
  )
}
