import { difference, first, isNil, some } from 'lodash'

import { PotentialInsurancePayerCompensationsQuery } from '@nuna/api'

import {
  AccessCodeCoverageFragment,
  CashCoverageFragment,
  EAPCoverageFragment,
  InsuranceCoverageFragment,
} from '../types/exported'
import {
  AccessCodeCoverageDetailsFragment,
  CapStatus,
  CashCoverageDetailsFragment,
  ContractType,
  EapCoverageDetailsFragment,
  InsuranceCoverageDetailsFragment,
  PatientCoverageDetailsFragment,
  PatientCoverageFragment,
  PatientIntakeContractFragment,
  PaymentPreference,
} from '../types/internal-only/coverage.types'

// in the order of "waterfall" coverage this is the default ordering
const DEFAULT_COVERAGE_ORDER = [
  PaymentPreference.Employer,
  PaymentPreference.Accesscode,
  PaymentPreference.Insurance,
  PaymentPreference.Cash,
]

// trying to eliminate casting coverage types everywhere with these type guards
function isCashCoverage(coverage: PatientCoverageFragment): coverage is CashCoverageFragment {
  return coverage.type === PaymentPreference.Cash
}

function isEAPCoverage(coverage: PatientCoverageFragment): coverage is EAPCoverageFragment {
  return coverage.type === PaymentPreference.Employer
}

function isAccessCodeCoverage(coverage: PatientCoverageFragment): coverage is AccessCodeCoverageFragment {
  return coverage.type === PaymentPreference.Accesscode
}

function isInsuranceCoverage(coverage: PatientCoverageFragment): coverage is InsuranceCoverageFragment {
  return coverage.type === PaymentPreference.Insurance
}

// converts PatientCoverageFragment[] to (CashCoverageFragment | EAPCoverageFragment | AccessCodeCoverageFragment | InsuranceCoverageFragment)[]
function getDiscriminatedCoverageFragments(coverages: PatientCoverageFragment[]) {
  return coverages.map(coverage => {
    if (isCashCoverage(coverage)) {
      return coverage
    }

    if (isInsuranceCoverage(coverage)) {
      return coverage
    }

    if (isEAPCoverage(coverage)) {
      return coverage
    }

    if (isAccessCodeCoverage(coverage)) {
      return coverage
    }

    throw new Error('Unknown coverage type encountered')
  })
}

// these function overloads ensure that the returned fragment has the correct type based on the passed-in payment preference type
function getCoverageByType(
  coverages: PatientCoverageFragment[],
  type: PaymentPreference.Cash,
): CashCoverageFragment | undefined
function getCoverageByType(
  coverages: PatientCoverageFragment[],
  type: PaymentPreference.Employer,
): EAPCoverageFragment | undefined
function getCoverageByType(
  coverages: PatientCoverageFragment[],
  type: PaymentPreference.Accesscode,
): AccessCodeCoverageFragment | undefined
function getCoverageByType(
  coverages: PatientCoverageFragment[],
  type: PaymentPreference.Insurance,
): InsuranceCoverageFragment | undefined
function getCoverageByType(coverages: PatientCoverageFragment[], type: PaymentPreference) {
  return getDiscriminatedCoverageFragments(coverages).find(c => c.type === type)
}

export interface CoverageValidationOptions {
  considerZeroCapValid?: boolean
  checkAlternatePaymentCoverage?: PatientCoverageFragment[]
}

function isEAPValid(coverageDetails: EapCoverageDetailsFragment, options?: CoverageValidationOptions) {
  if (options?.considerZeroCapValid && coverageDetails.sessionsCap === 0) return true

  if (options?.checkAlternatePaymentCoverage) {
    const coverages = getMostRecentCoverageByType(options.checkAlternatePaymentCoverage)
    const needsMoreCoverage = needsAdditionalZeroCapCoverage(coverages) ? false : true
    return needsMoreCoverage
  }
  return coverageDetails.sessionsCapStatus !== CapStatus.Exhausted
}

function isInsuranceValid(coverageDetails: InsuranceCoverageDetailsFragment) {
  return coverageDetails.active
}

function isAccessCodeValid(coverageDetails: AccessCodeCoverageDetailsFragment) {
  if (!coverageDetails.sessionsCap) return true
  return coverageDetails.sessionsExhausted + coverageDetails.sessionsScheduled < coverageDetails.sessionsCap
}

function isCashValid(coverageDetails: CashCoverageDetailsFragment) {
  return coverageDetails.cardIsExpired === false
}

function isCoverageValid(
  coverageDetails: PatientCoverageDetailsFragment | undefined,
  options?: CoverageValidationOptions,
): boolean {
  if (!coverageDetails) return false

  if ('cardIsExpired' in coverageDetails) {
    return isCashValid(coverageDetails)
  }

  if ('active' in coverageDetails) {
    return isInsuranceValid(coverageDetails)
  }

  if ('sessionsCapStatus' in coverageDetails) {
    return isEAPValid(coverageDetails, options)
  }

  if ('sessionsExhausted' in coverageDetails) {
    return isAccessCodeValid(coverageDetails)
  }

  return false
}

function isZeroCapEAP(coverageDetails: EapCoverageDetailsFragment | undefined) {
  if (!coverageDetails) return false

  return coverageDetails.sessionsCap === 0
}

function hasCardOnFile(coverageDetails: CashCoverageDetailsFragment) {
  return !isNil(coverageDetails.cardNumber)
}

function hasOutOfNetworkProviders(coverageDetails: InsuranceCoverageDetailsFragment) {
  return some(coverageDetails.providers, provider => provider.inInsuranceNetwork === false)
}

function hasUploadedInsuranceCard(
  coverageDetails: Pick<InsuranceCoverageDetailsFragment, 'cardFrontUrl' | 'cardBackUrl'> | null | undefined,
) {
  if (!coverageDetails) return false
  return !!coverageDetails.cardFrontUrl && !!coverageDetails.cardBackUrl
}

const humanReadableByPaymentPreference: Record<PaymentPreference, string> = {
  [PaymentPreference.Cash]: 'Credit Card',
  [PaymentPreference.Insurance]: 'Insurance',
  [PaymentPreference.Accesscode]: 'Access Code',
  [PaymentPreference.Employer]: 'Sponsor',
}

function getHumanReadablePaymentPreference(type: PaymentPreference) {
  return humanReadableByPaymentPreference[type]
}

function getMostRecentCoverageByType(coverages: PatientCoverageFragment[]): {
  [PaymentPreference.Cash]: CashCoverageFragment | undefined
  [PaymentPreference.Insurance]: InsuranceCoverageFragment | undefined
  [PaymentPreference.Accesscode]: AccessCodeCoverageFragment | undefined
  [PaymentPreference.Employer]: EAPCoverageFragment | undefined
} {
  const cashCoverage = first(coverages.filter(isCashCoverage))
  const insuranceCoverage = first(coverages.filter(isInsuranceCoverage))
  const employerCoverage = first(coverages.filter(isEAPCoverage))
  const accessCodeCoverage = first(coverages.filter(isAccessCodeCoverage))

  return {
    [PaymentPreference.Cash]: cashCoverage && hasCardOnFile(cashCoverage.details) ? cashCoverage : undefined,
    [PaymentPreference.Insurance]: insuranceCoverage,
    [PaymentPreference.Accesscode]: accessCodeCoverage,
    [PaymentPreference.Employer]: employerCoverage,
  }
}

/**
 * Returns an array of types so that you can loop through them in the following
 * order: primary, valid, invalid, nonexistent
 */
function getSortedCoverageTypes(coverages: PatientCoverageFragment[]) {
  const typedCoverages = getDiscriminatedCoverageFragments(coverages).filter(
    coverage =>
      coverage.type !== PaymentPreference.Cash ||
      (coverage.type === PaymentPreference.Cash && hasCardOnFile(coverage.details)), // ignore cash if they don't have a card on file
  )
  const primaryCoverage = getPrimaryCoverage(coverages)

  const existentCoverages = typedCoverages
    .sort((a, b) => {
      if (a === primaryCoverage) return -1
      if (b === primaryCoverage) return 1
      if (isCoverageValid(a.details) && !isCoverageValid(b.details)) return -1
      if (!isCoverageValid(a.details) && isCoverageValid(b.details)) return 1
      return 0
    })
    .map(c => c.type)

  // add nonexistent coverages so we can prompt to add that type of coverage in the panel
  return [...existentCoverages, ...difference(DEFAULT_COVERAGE_ORDER, existentCoverages)]
}

function hasValidCoverage(coverages: ReturnType<typeof getMostRecentCoverageByType>) {
  return (
    isCoverageValid(coverages[PaymentPreference.Cash]?.details) ||
    isCoverageValid(coverages[PaymentPreference.Insurance]?.details) ||
    isCoverageValid(coverages[PaymentPreference.Accesscode]?.details) ||
    isCoverageValid(coverages[PaymentPreference.Employer]?.details)
  )
}

function needsAdditionalZeroCapCoverage(coverages: ReturnType<typeof getMostRecentCoverageByType>) {
  // NOTE: this only works because inside of hasValidCoverage we are not considering zerocap "valid" for Eap
  return isZeroCapEAP(coverages[PaymentPreference.Employer]?.details) && !hasValidCoverage(coverages)
}

function getPostCapPreference(
  client: { paymentPreference: PaymentPreference },
  coverages: ReturnType<typeof getMostRecentCoverageByType>,
) {
  /** Since EAP/Access code are used regardless of preference, this function is to tell us what it would fall to if EAP/Access code sessions were used up */

  // if their preference is set to anything other than Employer/Access code then we can just respect that
  if (
    client.paymentPreference !== PaymentPreference.Employer &&
    client.paymentPreference !== PaymentPreference.Accesscode
  ) {
    return client.paymentPreference
  }

  // if their preference is Employer/Code and they have Insurance, that will be next in line after cap
  if (isCoverageValid(coverages[PaymentPreference.Insurance]?.details)) {
    return PaymentPreference.Insurance
  }

  // Everything else falls to cash
  return PaymentPreference.Cash
}

function getPrimaryCoverage(coverages: PatientCoverageFragment[]) {
  // usually the first coverage in the array from the backend is the primary one, but in some edge cases (zero cap, insurance scenarios) the one that comes back first isn't valid..
  // since the purpose of displaying "primary" is to communicate to the user which coverage would get charged first, we need to do some extra logic to determine which coverage would be charged.
  // this logic will likely need to be updated one day when we make the backend more robust

  const typedCoverages = getDiscriminatedCoverageFragments(coverages)
  const firstValidCoverage = typedCoverages.find(coverage => isCoverageValid(coverage.details))

  if (firstValidCoverage) {
    return firstValidCoverage
  }

  return first(typedCoverages) // if everything is invalid then just show the preference
}

function getEmployerContract(contracts: (PatientIntakeContractFragment | null)[]) {
  // the graphql types on PatientIntake could probably be improved to not have so many undefined | null :(
  return contracts.find(
    contract =>
      contract?.contractType === ContractType.CompanyContract ||
      contract?.contractType === ContractType.SponsoredCompanyContract,
  )
}

type PotentialPayouts = PotentialInsurancePayerCompensationsQuery['potentialInsurancePayerCompensations']

export const calculateMinAndMaxRate = (rates: PotentialPayouts) => {
  const calcValue = rates.reduce(
    (final, rate) => {
      if (rate.minRate < final.minRate) final.minRate = rate.minRate

      if (rate.maxRate > final.maxRate) final.maxRate = rate.maxRate

      return final
    },
    { minRate: rates[0].minRate, maxRate: rates[0].maxRate },
  )

  return calcValue
}

export const coverageService = {
  getCoverageByType,
  getEmployerContract,
  getHumanReadablePaymentPreference,
  getMostRecentCoverageByType,
  getPostCapPreference,
  getPrimaryCoverage,
  getSortedCoverageTypes,
  hasCardOnFile,
  hasOutOfNetworkProviders,
  hasUploadedInsuranceCard,
  hasValidCoverage,
  isAccessCodeValid,
  isCashValid,
  isCoverageValid,
  isEAPValid,
  isInsuranceValid,
  isZeroCapEAP,
  needsAdditionalZeroCapCoverage,
  calculateMinAndMaxRate,
}
