import { IconProps } from './IconProps'

export function IconProgressIndicator({ size = 28, ...props }: IconProps) {
  return (
    <svg width={size} height={size} viewBox="0 0 28 29" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <style>
        {`
    @keyframes rotate {
      from {
        transform: rotate(0)
      }

      to {
        transform: rotate(360deg)
      }
    }

    #progress-indicator-svg {
      animation: rotate 3s linear infinite;
      transform-origin: center;
    }
  `}
      </style>

      <defs>
        <linearGradient id="paint0_angular_2411_33348" gradientTransform="rotate(80)">
          <stop stopColor="#D390B5" />
          <stop offset="0.50" stopColor="#81B2D6" />
          <stop offset="1" stopColor="#6AD0C4" />
        </linearGradient>
      </defs>

      <g id="progress-indicator-svg">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M7 14.5C7 18.3623 10.1377 21.5 14 21.5C17.8623 21.5 21 18.3623 21 14.5C21 10.6377 17.8623 7.5 14 7.5C10.1377 7.5 7 10.6377 7 14.5ZM14 25.5C7.92852 25.5 3 20.5715 3 14.5C3 8.42852 7.92852 3.5 14 3.5C20.0715 3.5 25 8.42852 25 14.5C25 20.5715 20.0715 25.5 14 25.5Z"
          fill="url(#paint0_angular_2411_33348)"
        />
        <line x1="17.0077" y1="12.2635" x2="24.0077" y2="8.26351" stroke="white" strokeWidth="4" />
      </g>
    </svg>
  )
}
