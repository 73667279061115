import { Form, Formik, FormikHelpers } from 'formik'
import { isString } from 'lodash'
import moment from 'moment'
import { KeyboardEvent, useCallback, useEffect, useState } from 'react'
import { Navigate, Route, Routes } from 'react-router-dom'

import { ProviderIntakeFragment, useNpiCheckLazyQuery } from '@nuna/api'
import { useTitle } from '@nuna/common'
import { dateService, routeService } from '@nuna/core'
import { toast } from '@nuna/tunic'

import { IntakeLayout } from '../../layouts/IntakeLayout'
import { SignupIntakeHeader } from '../../layouts/IntakeLayout/SignupIntakeHeader/SignupIntakeHeader'
import { useProviderAppContext } from '../../shared/ProviderAppContext'
import { IntakeHome } from './IntakeHome/IntakeHome'
import { ProviderSignupIntakeContextProvider, useProviderSignupIntakeContext } from './ProviderSignupIntakeContext'
import { ResumeIntake } from './ResumeIntake'
import { ProviderSignupIntakeSkeleton } from './shared/ProviderSignupIntakeSkeleton'
import { ProviderSignupFormValues, ProviderSignupNavigationDirection } from './shared/provider-signup-intake-types'
import { INTAKE_PATHS } from './signup-intake-steps'
import { buildInitialAddress, initialLicenses, logIntakeFormErrors } from './signup-intake-utils'
import { IntakeAvailability } from './steps/IntakeAvailability/IntakeAvailability'
import { IntakeCancelPolicy } from './steps/IntakeCancelPolicy/IntakeCancelPolicy'
import { IntakePresentation } from './steps/IntakePresentation/IntakePresentation'
import { IntakeProfile } from './steps/IntakeProfile/IntakeProfile'
import { Professional } from './steps/Professional/Professional'
import { Skills } from './steps/Skills/Skills'
import { WelcomeFullSetup } from './steps/WelcomeFullSetup'

function IntakeWithContext() {
  useTitle('Provider Intake')
  const { provider } = useProviderAppContext()
  const [initialValues, setInitialValues] = useState<ProviderSignupFormValues | null | undefined>()
  const [npiCheckQuery] = useNpiCheckLazyQuery()
  const {
    data,
    saveAndNavigate,
    saveIntakeLoading,
    currentStep,
    currentFormStepConfig: { validationSchema },
  } = useProviderSignupIntakeContext()

  const npiCheck = useCallback(
    (npi: number) => npiCheckQuery({ variables: { npi } }).then(result => !!result.data?.npiCheck),
    [npiCheckQuery],
  )

  useEffect(() => {
    if (data && provider?.id) {
      setInitialValues(prepareInitialValues(data, provider.id))
    }
  }, [data, provider?.id, npiCheck])

  const handleSubmit = async (
    values: ProviderSignupFormValues,
    formikHelpers: FormikHelpers<ProviderSignupFormValues>,
  ) => {
    if (['finish', 'professional'].includes(currentStep) && isString(values.npi) && values.npi.length) {
      const npiVerified = await npiCheck(parseInt(values.npi))
      if (!npiVerified) {
        toast.urgent(
          currentStep === 'professional'
            ? 'Please enter a valid NPI.'
            : 'Please navigate to the professional step and enter a valid NPI.',
        )
        return
      }
    }

    const saved = await saveAndNavigate(values, values.navigationPath ?? ProviderSignupNavigationDirection.Forward)
    if (saved) {
      formikHelpers.setTouched({})
    }
  }

  if (!initialValues) {
    return <ProviderSignupIntakeSkeleton />
  }

  return (
    <Formik initialValues={initialValues} onSubmit={handleSubmit} validationSchema={validationSchema}>
      {formProps => {
        const formStepProps = {
          ...formProps,
          saveIntakeLoading,
          logFormErrors: () => logIntakeFormErrors(formProps.errors),
        }

        return (
          <IntakeLayout header={<SignupIntakeHeader />}>
            <Form style={{ width: '100%' }} onKeyDown={handleFormKeyDown}>
              <Routes>
                <Route index element={<IntakeHome {...formStepProps} />} />
                <Route path={INTAKE_PATHS['welcome']} element={<WelcomeFullSetup />} />
                <Route path={INTAKE_PATHS['professional']} element={<Skills {...formStepProps} />} />
                <Route path={INTAKE_PATHS['availability']} element={<IntakeAvailability {...formStepProps} />} />
                <Route path={INTAKE_PATHS['profile']} element={<IntakeProfile {...formStepProps} />} />
                <Route path={INTAKE_PATHS['abilities']} element={<Professional {...formStepProps} />} />

                <Route path="video-bio" element={<Navigate to={`../${INTAKE_PATHS['bios']}`} replace />} />
                <Route path={INTAKE_PATHS['bios']} element={<IntakePresentation {...formStepProps} />} />
                <Route path="resume-intake" element={<ResumeIntake />} />
                <Route path="background-check" element={<Navigate replace to={routeService.backgroundCheckRoute} />} />
              </Routes>
            </Form>
            {/* moving the cancel policy outside of the larger intake form to avoid nested forms */}
            <Routes>
              <Route path={INTAKE_PATHS['cancel-policy']} element={<IntakeCancelPolicy />} />
            </Routes>
          </IntakeLayout>
        )
      }}
    </Formik>
  )
}

export function Intake() {
  return (
    <ProviderSignupIntakeContextProvider>
      <IntakeWithContext />
    </ProviderSignupIntakeContextProvider>
  )
}

function prepareInitialValues(values: ProviderIntakeFragment, providerId: string): ProviderSignupFormValues {
  const {
    dob,
    address,
    credentials,
    bio,
    hasMalpractice = false,
    timezone,
    expectedStartDate,
    licenseTitle,
    ...rest
  } = values
  const initialAddress = buildInitialAddress(address)
  return {
    ...rest,
    timezone: timezone ?? moment.tz.guess(),
    dob: dateService.utcFormat(dob, 'YYYY-MM-DD'),
    expectedStartDate: expectedStartDate ? moment.utc(expectedStartDate) : null,
    address: initialAddress,
    bio: bio ?? '',
    hasMalpractice,
    receiveBackgroundCheckCopy: false,
    licenses: initialLicenses(providerId, credentials),
    licenseTitle: licenseTitle ?? '',
  }
}

function handleFormKeyDown(keyEvent: KeyboardEvent<HTMLFormElement>) {
  if ((keyEvent.charCode || keyEvent.keyCode) === 13 || keyEvent.key === 'Enter') {
    keyEvent.preventDefault()
  }
}
