import { styled } from '@mui/material'

import { useProviderMeQuery } from '@nuna/api'
import { ProviderProfile, withProviderLoadingState } from '@nuna/provider'
import { BelowTablet } from '@nuna/tunic'

export function ProviderProfileForProvider() {
  const { loading, refetch, data } = useProviderMeQuery({ fetchPolicy: 'cache-and-network' })

  const provider = data?.providerMe

  return (
    <Container>
      <CenteredContent>{withProviderLoadingState(ProviderProfile, { loading, refetch, provider })}</CenteredContent>
    </Container>
  )
}

const Container = styled('div')`
  display: flex;
  flex-direction: column;
  height: 100%;
  padding: 0 7rem;

  @media (${BelowTablet}) {
    padding: 0;
  }
`
const CenteredContent = styled('div')`
  display: flex;
  justify-content: center;
  height: 100vh;
  flex: 1;
`
