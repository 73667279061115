import moment from 'moment-timezone'
import { useMemo } from 'react'
import { useNavigate, useParams } from 'react-router-dom'

import { CustomerContractListItemFragment, useCustomerContractsQuery } from '@nuna/api'
import { numberService } from '@nuna/core'
import { DataTable, DataTableColumn } from '@nuna/data-table'
import { StatusLabel } from '@nuna/tunic'

import { AvailableToLabels } from './shared'

export function CustomerContractsList() {
  const { id = '' } = useParams()
  const navigate = useNavigate()
  const { data, loading } = useCustomerContractsQuery({
    variables: { companyId: id },
    fetchPolicy: 'cache-and-network',
  })
  const rowData = useMemo(() => data?.contracts, [data])
  const columns = useMemo<DataTableColumn<CustomerContractListItemFragment>[]>(
    () => [
      {
        Header: 'Go Live Date',
        accessor: 'goLiveDate',
        Cell: ({ value }) => <>{moment.utc(value).format('ll hh:mm a')}</>,
      },
      {
        Header: 'End Date',
        accessor: 'endDate',
        Cell: ({ value }) => <>{moment.utc(value).format('ll hh:mm a')}</>,
      },
      {
        Header: 'Cap Reset Date',
        accessor: 'capResetDate',
        Cell: ({ value }) =>
          value ? <>{moment.utc(value).format('ll hh:mm a')}</> : <StatusLabel intent="urgent">Not Set</StatusLabel>,
      },
      {
        Header: 'Session allowance',
        accessor: 'sessionAllowanceToType',
      },
      {
        Header: 'Cost after cap',
        accessor: 'postCapPatientSessionCost',
        Cell: ({ value }) => <>{numberService.centsToFormattedDollars(value)}</>,
      },
      {
        Header: 'Available to',
        accessor: 'availableToFullTime',
        Cell: ({ row }) => <AvailableToLabels contract={row.original} />,
      },
      {
        Header: 'Belongs to a partner',
        accessor: 'partnerId',
        // eslint-disable-next-line react/jsx-no-useless-fragment
        Cell: ({ value }) => <>{value ? 'Yes' : 'No'}</>,
      },
    ],
    [],
  )
  return <DataTable rowData={rowData} columns={columns} loading={loading} onRowClick={row => navigate(row.id)} />
}
