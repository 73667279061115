import { styled } from '@mui/material'

import { tealSet } from '../../styles/colorSets'
import { body2, borderGrey, interactiveFill, interactiveText } from '../../styles/colors'

export const PlaceholderButton = styled('button')`
  border: 1px dashed ${borderGrey};
  border-radius: var(--border-radius);
  color: ${body2};
  display: block;
  font-weight: 500;
  padding: 1rem 1.5rem;
  position: relative;
  width: 100%;

  &:hover:not([disabled]),
  &.focus-visible:not([disabled]) {
    border-color: ${interactiveFill};
    color: ${interactiveText};
  }

  &.focus-visible {
    outline: none;

    &::after {
      border-radius: calc(var(--border-radius) + 2px);
      box-shadow: 0 0 0 6px ${tealSet[5].hex};
      content: '';
      display: block;
      height: calc(100% + 6px);
      left: -3px;
      position: absolute;
      top: -3px;
      width: calc(100% + 6px);
    }
  }

  &:disabled,
  &[disabled] {
    color: ${borderGrey};
    cursor: not-allowed;
  }
`
