import { styled } from '@mui/material'
import moment from 'moment-timezone'
import { useMemo, useState } from 'react'

import { PeriodConstraint } from '@nuna/api'
import { useIsAdmin } from '@nuna/auth'
import { availabilityService, timeService } from '@nuna/core'
import { Checkbox, DatePicker, GhostButton, PlaceholderButton } from '@nuna/tunic'

import { useAvailabilityContext } from '../context/AvailabilityContextProvider'
import { ProviderAvailabilityProps } from '../types'
import { AvailabilityPeriods } from './AvailabilityPeriods'
import { AvailabilityTimezoneDisplay } from './AvailabilityTimezoneDisplay'
import {
  Container,
  DescriptionCaption,
  ErrorText,
  HoursContainer,
  SeperatorText,
  StyledSelect,
  TimeForm,
} from './ProviderAvailabilitySharedStyles'

const { getAvailabilityConstraintInput } = availabilityService
const { hoursOfDay, mergeDateAndTime, rangesOverlap } = timeService

export function ProviderAwayTime({ provider }: ProviderAvailabilityProps) {
  const isAdmin = useIsAdmin()
  const { deleteConstraints, updateLoading, updateConstraints, timezone } = useAvailabilityContext()

  const blockPeriods = provider.availabilityPeriods.blockPeriod
  const allowPeriods = provider.availabilityPeriods.allowPeriod

  const [isEditing, setIsEditing] = useState(false)

  const [startDate, setStartDate] = useState(moment())
  const [startTime, setStartTime] = useState(hoursOfDay[28].value)

  const [endDate, setEndDate] = useState(moment())
  const [endTime, setEndTime] = useState(hoursOfDay[29].value)

  const [isAllDay, setIsAllDay] = useState(false)

  const startDateTime = isAllDay ? startDate.startOf('day') : mergeDateAndTime(startDate, startTime)
  const endDateTime = isAllDay ? endDate.endOf('day') : mergeDateAndTime(endDate, endTime)

  const isValid = startDateTime.isBefore(endDateTime)

  const conflictingExtraTime = allowPeriods.some(extraTimeRange =>
    rangesOverlap(extraTimeRange, { start: startDateTime, end: endDateTime }),
  )

  const handleAddAvailabilityPeriod = async () => {
    const newPeriod = {
      start: startDateTime.tz(timezone ?? '', false).toISOString(),
      end: endDateTime.tz(timezone ?? '', false).toISOString(),
    }
    const availabilityConstraintsInput = getAvailabilityConstraintInput({
      availabilityConstraints: { blockPeriod: [newPeriod] },
      isAdmin,
    })
    await updateConstraints(availabilityConstraintsInput)
    setIsEditing(false)
  }

  const handleRemoveAvailabilityPeriod = async (constraintToRemove: PeriodConstraint) => {
    await deleteConstraints([constraintToRemove.id])
  }

  const periodsToShow = useMemo(
    () =>
      blockPeriods
        .filter(constraint => moment(constraint.start).isAfter(moment()) || moment(constraint.end).isAfter(moment()))
        .sort((a, b) => moment(a.start).unix() - moment(b.start).unix()),
    [blockPeriods],
  )

  return (
    <Container>
      <DescriptionCaption>
        <span>Take time off from your regular hours</span>
        <AvailabilityTimezoneDisplay />
      </DescriptionCaption>

      {!isEditing ? (
        <PlaceholderButton className="mt-2" type="button" onClick={() => setIsEditing(true)}>
          Add Away Time
        </PlaceholderButton>
      ) : (
        <TimeForm>
          <UnavailabilityForm isAllDay={isAllDay}>
            <DatePicker
              disablePast
              format="MMM D, YYYY"
              value={startDate}
              containerProps={{ className: 'v-align' }}
              label="&nbsp;"
              error={!isValid}
              onChange={date => {
                if (date) {
                  setStartDate(date)
                }
              }}
            />

            {!isAllDay && (
              <StyledSelect error={!isValid} value={startTime} onChange={e => setStartTime(e.currentTarget.value)}>
                {hoursOfDay.map(hour => (
                  <option key={hour.value} value={hour.value}>
                    {hour.display}
                  </option>
                ))}
              </StyledSelect>
            )}

            <SeperatorText>until</SeperatorText>

            {!isAllDay && (
              <StyledSelect error={!isValid} value={endTime} onChange={e => setEndTime(e.currentTarget.value)}>
                {hoursOfDay.map(hour => (
                  <option key={hour.value} value={hour.value}>
                    {hour.display}
                  </option>
                ))}
              </StyledSelect>
            )}

            <DatePicker
              disablePast
              hideIcon
              format="MMM D, YYYY"
              value={endDate}
              containerProps={{ className: 'v-align' }}
              label="&nbsp;"
              error={!isValid}
              onChange={date => {
                if (date) {
                  setEndDate(date)
                }
              }}
            />

            {!isValid && !conflictingExtraTime && <ErrorText>Start time must be before end time</ErrorText>}
            {conflictingExtraTime && (
              <ErrorText>
                You have already added Extra time during this period. To add this Away time, please first remove the
                conflicting Extra time.
              </ErrorText>
            )}
          </UnavailabilityForm>

          <div className="v-align mt-3">
            <Checkbox className="ml-4" checked={isAllDay} onChange={e => setIsAllDay(e.currentTarget.checked)}>
              All Day
            </Checkbox>

            <GhostButton
              className="no-wrap ml-auto"
              onClick={handleAddAvailabilityPeriod}
              isLoading={updateLoading}
              disabled={!isValid || conflictingExtraTime}
            >
              Add Time
            </GhostButton>
          </div>
        </TimeForm>
      )}

      <HoursContainer>
        <AvailabilityPeriods
          type="away"
          providerTimezone={timezone}
          constraints={periodsToShow}
          onRemoveClick={handleRemoveAvailabilityPeriod}
        />
      </HoursContainer>
    </Container>
  )
}

const UnavailabilityForm = styled('div')<{ isAllDay?: boolean }>`
  align-items: center;
  display: grid;
  grid-template-rows: auto auto;
  grid-template-columns: ${props => (props.isAllDay ? '1fr auto 1fr' : '2fr 1fr auto 1fr 2fr')};
  grid-gap: 0 var(--margin-2);

  ${ErrorText} {
    grid-column: 1 / 5;
    padding-left: var(--margin-4);
  }
`
