import * as Yup from 'yup'
import { ApolloError } from '@apollo/client'
import { styled } from '@mui/material'
import { Form, Formik } from 'formik'
import { useMemo } from 'react'

import {
  Credential,
  LicenseAbbreviation,
  LicenseType,
  StateAbbreviation,
  useLicenseTypesQuery,
  useSaveLicensesMutation,
} from '@nuna/api'
import { StateOption, addressService, errorService, formService } from '@nuna/core'
import {
  Drawer,
  FillButton,
  Skeleton,
  StateAutocompleteMultiple,
  TextField,
  eggshell,
  makeTypographyComponent,
  toast,
} from '@nuna/tunic'

import { LicenseWithSimpleStatus } from '../types'

const { getStateAbbreviations, statesAsOptions } = addressService

const PSYPACT_ABBR = 'APIT'

interface FormValues {
  number: string
  stateOptions: StateOption[]
}

const FORM_SCHEMA = Yup.object().shape<FormValues>({
  number: Yup.string().required('Number is required'),
  stateOptions: Yup.array<StateOption>(Yup.object()).min(1, 'Please select at least 1 state'),
})

interface Props {
  providerId: string
  isOpen: boolean
  licenses: Pick<Credential, 'id' | 'licenseTypeId' | 'state'>[]
  onClose: () => void
  onSaved: () => void
}

export function CreatePsyPactLicenseDrawer({ providerId, isOpen, licenses, onClose, onSaved }: Props) {
  const { data, loading } = useLicenseTypesQuery()

  const [saveLicenses, { loading: saveLoading }] = useSaveLicensesMutation()

  const psyPactLicenseType = useMemo(
    () => (data?.licenseTypes ?? []).find(licenseType => licenseType.abbreviation === PSYPACT_ABBR),
    [data],
  )
  const disabledStates = useMemo(() => getDisabledStates(licenses, psyPactLicenseType), [licenses, psyPactLicenseType])

  const initialValues = useMemo<FormValues>(() => {
    const stateOptions = statesAsOptions()
    return { number: '', stateOptions: stateOptions.filter(option => !disabledStates.includes(option.value)) }
  }, [disabledStates])

  const handleSubmit = async (values: FormValues) => {
    try {
      if (!psyPactLicenseType) {
        throw new Error('PSYPACT license type not found. Unable to save licenses.')
      }
      await saveLicenses({ variables: { licenses: buildLicenses(values, providerId, psyPactLicenseType.id) } })
      onSaved()
      onClose()
    } catch (e) {
      const errorMessage = e instanceof ApolloError ? errorService.transformGraphQlError(e) : (e as Error)?.message
      toast.urgent(errorMessage || 'Unable to save licenses')
    }
  }

  return (
    <StyledDrawer size="min(400px, 100vw)" isOpen={isOpen} onClose={onClose}>
      <div className="p-2">
        <Header>Create PSYPACT licenses</Header>
        {loading && <Skeleton height={4} />}
        {!loading && (
          <Formik initialValues={initialValues} validationSchema={FORM_SCHEMA} onSubmit={handleSubmit}>
            {({ getFieldProps, setFieldValue, values, touched, errors }) => (
              <Form>
                <TextField
                  label="Number"
                  {...getFieldProps('number')}
                  {...formService.composeHelperTextWithError('', errors.number, touched.number)}
                />
                <StateAutocompleteMultiple
                  className="mt-2"
                  getOptionDisabled={option => disabledStates.includes(option.value)}
                  value={values.stateOptions}
                  useCheckboxes
                  onChange={options => setFieldValue('stateOptions', options)}
                  {...formService.composeHelperTextWithError('', errors.stateOptions, !!touched.stateOptions)}
                />
                <FillButton type="submit" isLoading={saveLoading} className="mt-3 full-width">
                  Create Licenses
                </FillButton>
              </Form>
            )}
          </Formik>
        )}
      </div>
    </StyledDrawer>
  )
}

function getDisabledStates(
  existingLicenses: Pick<Credential, 'licenseTypeId' | 'state'>[],
  psyPactLicenseType?: Pick<LicenseType, 'supportedStates' | 'id'> | null,
) {
  const psyPactStates = psyPactLicenseType?.supportedStates ?? []
  const existingPsyPactLicenseStates = existingLicenses
    .filter(license => license.licenseTypeId === psyPactLicenseType?.id)
    .map(license => license.state)

  return getStateAbbreviations().filter(
    abbr => !psyPactStates.includes(abbr as StateAbbreviation) || existingPsyPactLicenseStates.includes(abbr),
  )
}

function buildLicenses(
  values: FormValues,
  providerId: string,
  psyPactLicenseTypeId: string,
): LicenseWithSimpleStatus[] {
  return values.stateOptions.map<LicenseWithSimpleStatus>(option => {
    return {
      currentlyUtilized: true,
      providerId,
      state: option.value as StateAbbreviation,
      number: values.number,
      licenseTypeId: psyPactLicenseTypeId,
      type: LicenseAbbreviation.Apit,
    }
  })
}

const Header = makeTypographyComponent('sans-serif text-medium large space-between v-align', 'h1')

const StyledDrawer = styled(Drawer)`
  .MuiPaper-root {
    background-color: ${eggshell};
  }

  .content-wrapper {
    height: 100%;
    overflow: auto;
  }
`
