import { IconProps } from './IconProps'

export function IconScreenShare({ color = 'currentColor', size = 24, ...props }: IconProps) {
  return (
    <svg width={size} height={size} viewBox="0 0 48 48" fill={color} xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.12067 31.2727V13.78C9.12067 12.9978 9.75771 12.3636 10.5435 12.3636H37.4564C38.2422 12.3636 38.8792 12.9974 38.8792 13.78V31.2727H28.9597C28.9597 32.5781 27.897 33.6364 26.5869 33.6364H21.413C20.1025 33.6364 19.0402 32.5784 19.0402 31.2727H9.12067ZM41.3591 11.8893C41.3591 10.8447 40.5096 10 39.4617 10H8.53824C7.49037 10 6.64079 10.8459 6.64079 11.8893V31.2727H1.28682C0.135362 31.2727 -0.329829 32.0774 0.248745 33.07L0.908965 34.2027C1.48759 35.1954 2.89035 36 4.04223 36H43.9577C45.1098 36 46.5124 35.1953 47.0909 34.2027L47.7512 33.07C48.3298 32.0773 47.865 31.2727 46.7131 31.2727H41.3591V11.8893ZM19.8897 21.8695L19.1577 21.1761C18.9474 20.977 18.9478 20.6528 19.1568 20.4538L23.7154 16.1121C23.8725 15.9624 24.1277 15.9628 24.2844 16.1121L28.8432 20.4538C29.053 20.6536 29.0518 20.9778 28.8424 21.1761L28.1104 21.8695C27.9001 22.0687 27.5591 22.0676 27.3494 21.8679L25.3805 19.9928C25.275 19.8924 25.1895 19.9277 25.1895 20.0708L25.1895 28.5931C25.1895 28.8178 25.0011 29 24.7713 29L23.2068 29C22.9758 29 22.7886 28.8139 22.7887 28.594L22.7925 20.0901C22.7925 19.9454 22.7071 19.9093 22.6029 20.0085L20.6507 21.8679C20.4407 22.0679 20.0991 22.0678 19.8897 21.8695Z"
      />
    </svg>
  )
}
