import * as Yup from 'yup'
import { styled } from '@mui/material'
import { useFormikContext } from 'formik'
import { ReactNode } from 'react'

import { OrganizationRelationship } from '@nuna/api'
import { formService } from '@nuna/core'
import { Phone, TextField } from '@nuna/tunic'

import { Employer, EmployerAutoComplete, EmployerAutoCompleteProps } from '../../EmployerAutoComplete'
import { CoverageFormValues } from '../CoverageForm'
import { EmployerAssociationForm } from './EmployerAssociationForm'
import { CoverageSectionHeading } from './SharedStyles'

const { composeNestedHelperTextWithError, makeSelection, oopsRequired } = formService

export interface EmployerCoverageFormValues {
  employer: Employer | null
  employerId: string
  employerAssociation: OrganizationRelationship | null
  employerAssociationEmployee: string
  referralId?: string
}

export const employerSchema = Yup.object<Employer>().nullable().required(oopsRequired('employer'))

export const employerCoverageSchema = Yup.object().shape<EmployerCoverageFormValues>({
  employer: employerSchema,
  employerId: Yup.string(),
  employerAssociation: Yup.mixed<OrganizationRelationship>()
    .nullable()
    .when(['employer'], {
      is: employer => employer?.associationRequired && employer?.offersTava,
      then: Yup.mixed<OrganizationRelationship>().required(makeSelection),
    }),
  employerAssociationEmployee: Yup.string().when(['employerAssociation', 'employer'], {
    is: (employerAssociation, employer) =>
      employer?.associationRequired &&
      employer?.offersTava &&
      employerAssociation !== OrganizationRelationship.Employee,
    then: Yup.string().required('Oops, you forgot their name'),
    otherwise: Yup.string().notRequired(),
  }),
  referralId: Yup.string().when(['employer'], {
    is: employer => employer?.isReferralIdRequired,
    then: Yup.string().required('This employer requires a referral ID'),
    otherwise: Yup.string().notRequired(),
  }),
})

export const employerInitialValues: EmployerCoverageFormValues = {
  employer: null,
  employerId: '',
  employerAssociation: null,
  employerAssociationEmployee: '',
}

interface EmployerCoverageFormProps {
  isCheckout: boolean
  autocompleteProps?: Partial<EmployerAutoCompleteProps>
  skipButtonSlot?: ReactNode
}

export function EmployerCoverageForm({ isCheckout, skipButtonSlot, autocompleteProps }: EmployerCoverageFormProps) {
  const { values, errors, touched, setFieldValue, setTouched, handleChange, handleBlur } =
    useFormikContext<CoverageFormValues>()
  const referralIdRequired = values.employerValues?.employer?.isReferralIdRequired ?? false

  const commonProps = {
    onBlur: handleBlur,
    onChange: handleChange,
    name: 'employerValues.employerAssociation',
  }

  const employerAssociationEmployeeProps = {
    ...commonProps,
    ...composeNestedHelperTextWithError('', errors, touched, 'employerValues', 'employerAssociationEmployee'),
    name: 'employerValues.employerAssociationEmployee',
    fullWidth: true,
    onBlur: handleBlur,
    onChange: handleChange,
  }

  return (
    <section>
      <CoverageSectionHeading isCheckout={isCheckout}>
        Which organization is sponsoring your care?
      </CoverageSectionHeading>
      <AutocompleteWrapper>
        <div className="grow">
          <EmployerAutoComplete
            label="Sponsoring organization"
            onChange={company => {
              // the types for nested objects don't work right :(
              // eslint-disable-next-line @typescript-eslint/no-explicit-any
              setTouched({ employerValues: { employer: true } } as any)
              setFieldValue('employerValues.employer', company)
            }}
            value={values.employerValues?.employer ?? ''}
            initialValueEmployerId={values.employerValues?.employerId}
            noOptionsText={<span className="caption">No results... please select a different kind of coverage</span>}
            hideIcon
            {...composeNestedHelperTextWithError('', errors, touched, 'employerValues', 'employer')}
            {...autocompleteProps}
          />
        </div>

        {skipButtonSlot}
      </AutocompleteWrapper>
      {referralIdRequired && (
        <div className="my-3">
          <h3 className="body text-bold">What is your referral ID?</h3>
          <TextField
            label="Referral ID"
            name="employerValues.referralId"
            onBlur={handleBlur}
            onChange={handleChange}
            value={values.employerValues?.referralId}
            {...composeNestedHelperTextWithError('', errors, touched, 'employerValues', 'referralId')}
          />
        </div>
      )}
      {values.employerValues?.employer &&
        values.employerValues.employer.offersTava &&
        values.employerValues.employer.associationRequired && (
          <div className={referralIdRequired ? '' : 'mt-3'}>
            <EmployerAssociationForm
              {...{
                employerValues: values.employerValues,
                errors,
                touched,
                commonProps,
                employerAssociationEmployeeProps,
                isCheckout,
                parentKey: 'employerValues',
                nestedKeys: ['employerAssociationEmployee', 'employerAssociation'],
              }}
            />
          </div>
        )}
    </section>
  )
}

const AutocompleteWrapper = styled('div')`
  display: flex;
  gap: 1.5rem;

  @media (${Phone}) {
    display: block;
  }
`
