import { DailyEventObjectParticipant, DailyEventObjectParticipantLeft } from '@daily-co/daily-js'
import { useDailyEvent } from '@daily-co/daily-react'
import { useCallback, useState } from 'react'

import { OnlineUser } from '../../types'

export function useOnlineUsersContext() {
  const [onlineUsers, setOnlineUsers] = useState<OnlineUser[]>([])

  const handleParticipantJoined = useCallback((participantEvent: DailyEventObjectParticipant) => {
    const participantLoginId = participantEvent.participant.user_name

    setOnlineUsers(prev => [
      ...prev,
      { loginId: participantLoginId, sessionId: participantEvent.participant.session_id },
    ])
  }, [])

  const handleParticipantLeft = useCallback((participantEvent: DailyEventObjectParticipantLeft) => {
    const participantLoginId = participantEvent.participant.user_name

    setOnlineUsers(prev => prev.filter(onlineUser => onlineUser.loginId !== participantLoginId))
  }, [])

  // typically we should avoid calling daily hooks within the VideoCallContext, but this is the one exception in order to create a mapping of session IDs (Daily) to login IDs (Tava)
  useDailyEvent('participant-joined', handleParticipantJoined)
  useDailyEvent('participant-left', handleParticipantLeft)

  const isUserOnline = useCallback(
    (loginId: string) => onlineUsers.some(onlineUser => onlineUser.loginId === loginId),
    [onlineUsers],
  )

  return { onlineUsers, isUserOnline }
}

export type OnlineUsersContextValues = ReturnType<typeof useOnlineUsersContext>
