import { DailyEventObjectAppMessage } from '@daily-co/daily-js'
import { useNetwork } from '@daily-co/daily-react'
import { useCallback, useState } from 'react'

import { DailyMessage, useDailyMessage } from './useDailyMessage'

type NetworkQualityThreshold = ReturnType<typeof useNetwork>['threshold']

export function useRemoteNetworkQuality(sessionId: string) {
  const [remoteNetworkThreshold, setRemoteNetworkThreshold] = useState<NetworkQualityThreshold>('good') // assume good until we hear otherwise

  const handleMessageReceived = useCallback(
    (e: DailyEventObjectAppMessage<DailyMessage>) => {
      if (e.fromId === sessionId && e.data.type === 'network-quality') {
        setRemoteNetworkThreshold(e.data.threshold)
      }
    },
    [sessionId],
  )

  useDailyMessage(handleMessageReceived)

  return remoteNetworkThreshold
}
