import { IconProps } from './IconProps'

export function IconCategory({ size = 24, color = 'currentColor', ...props }: IconProps) {
  return (
    <svg width={size} height={size} viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M23.3376 1.16636L2.60828 13.0453C2.4266 13.1494 2.27535 13.297 2.16895 13.474C1.82473 14.0468 2.02322 14.7824 2.61229 15.117L23.4116 26.9339C23.7957 27.1521 24.2709 27.1527 24.6555 26.9353L45.3851 15.2232C45.5675 15.1201 45.7197 14.9734 45.8272 14.797C46.175 14.2263 45.9812 13.4896 45.3942 13.1514L24.5946 1.1678C24.2072 0.944585 23.7255 0.944033 23.3376 1.16636ZM2.60828 23.1353L5.87687 21.2622L23.4116 31.2243C23.7957 31.4425 24.2709 31.4431 24.6555 31.2258L42.123 21.3567L45.3942 23.2414C45.9812 23.5795 46.175 24.3163 45.8272 24.887C45.7197 25.0634 45.5675 25.2101 45.3851 25.3131L24.6555 37.0253C24.2709 37.2426 23.7957 37.2421 23.4116 37.0238L2.61229 25.207C2.02322 24.8723 1.82473 24.1367 2.16895 23.564C2.27535 23.387 2.4266 23.2394 2.60828 23.1353ZM2.60828 33.7814L5.87687 31.9083L23.4116 41.8704C23.7957 42.0886 24.2709 42.0892 24.6555 41.8719L42.123 32.0028L45.3942 33.8875C45.9812 34.2256 46.175 34.9624 45.8272 35.5331C45.7197 35.7095 45.5675 35.8562 45.3851 35.9592L24.6555 47.6714C24.2709 47.8887 23.7957 47.8882 23.4116 47.6699L2.61229 35.8531C2.02322 35.5184 1.82473 34.7828 2.16895 34.2101C2.27535 34.0331 2.4266 33.8855 2.60828 33.7814Z"
        fill={color}
      />
    </svg>
  )
}
