import { css } from '@mui/material'

import { Color } from '../../styles/colorSets'

interface TextColorOptions {
  disabledAlpha?: number
  hoverColor?: string
}

export function buttonTextColor(color: Color, { disabledAlpha = 0.5, hoverColor = color.hex }: TextColorOptions = {}) {
  return css`
    color: ${color.hex};

    &:hover,
    &.focus-visible {
      color: ${hoverColor};
    }

    &:disabled,
    &.disabled {
      color: ${color.transparency(disabledAlpha)};
    }
  `
}

interface BackgroundColorOptions {
  outline?: boolean
  hoverColor?: string
}

interface ButtonColorSet {
  color: string
  focusColor: string
}

export function buttonBackgroundColor(
  colorSet: ButtonColorSet,
  { outline = false, hoverColor = colorSet.color }: BackgroundColorOptions = {},
) {
  return css`
    background-color: ${outline ? 'transparent' : colorSet.color};
    border-color: ${colorSet.color};

    &:hover,
    &.focus-visible {
      ${outline ? '' : `background-color: ${hoverColor};`}
      border-color: ${hoverColor};
      /* using box shadow so that the height of the element doesn't change and affect layout */
      box-shadow: 0 0 0 1px ${hoverColor};
    }

    &.focus-visible {
      &::after {
        box-shadow: 0 0 0 8px ${colorSet.focusColor};
      }
    }
  `
}

interface GhostColorSet {
  text: Color
  background: string
  underline: string
  hoverText?: string
}

export function ghostButtonColor(colorSet: GhostColorSet) {
  const textColor = buttonTextColor(colorSet.text, { hoverColor: colorSet.hoverText ?? colorSet.text.hex })
  const backgroundColor = colorSet.background
  const underlineColor = colorSet.underline

  return css`
    ${textColor}

    &::before {
      background-color: ${backgroundColor};
    }

    &::after {
      background-color: ${underlineColor};
    }
  `
}
