import { IconProps } from './IconProps'

export function IconRelationship({ color = 'currentColor', size = 24, ...props }: IconProps) {
  const height = (19 / 22) * size

  return (
    <svg width={size} height={height} viewBox="0 0 22 19" fill={color} xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0.162654 13.1958C0.162654 13.1958 -0.157724 10.6857 0.162654 10.1627C0.519735 9.5779 1.7202 9.21451 2.90766 8.6955C4.09513 8.17569 4.37728 7.996 4.37728 7.996L4.38875 6.79996C4.38875 6.79996 3.94297 6.44139 3.80534 5.31673C3.52625 5.40096 3.23492 4.88115 3.21887 4.60681C3.20357 4.34289 3.2571 3.6105 3.59659 3.6843C3.52778 3.13241 3.47731 2.63666 3.50178 2.37435C3.58971 1.41415 4.49121 0.400997 5.87747 0.400997C7.26298 0.400997 8.16447 1.41415 8.25317 2.37435C8.27764 2.63666 8.22717 3.13241 8.15759 3.6843C8.49708 3.6105 8.55137 4.34289 8.53531 4.60681C8.52002 4.88115 8.22794 5.40096 7.94961 5.31673C7.81121 6.44139 7.3662 6.79996 7.3662 6.79996L7.37691 7.996C7.37691 7.996 7.65905 8.17569 8.84652 8.6955C9.34633 8.91381 9.84817 9.10459 10.2885 9.29589C10.2197 9.45306 9.95791 9.58958 9.34041 9.85989C9.30691 9.87451 9.30691 9.87451 9.27249 9.88944C8.94923 10.0294 7.70352 10.5365 7.42346 10.6576C6.13177 11.2158 5.39462 11.6772 4.91469 12.4631C4.64012 12.9114 4.52407 13.4453 4.49562 14.1706C1.49012 13.9765 0.162654 13.1958 0.162654 13.1958ZM16.0202 10.3402L16.0057 8.71648C16.0057 8.71648 16.6099 8.22968 16.7977 6.70284C17.1756 6.81719 17.5721 6.11149 17.5929 5.73904C17.6147 5.38074 17.541 4.38644 17.0801 4.48663C17.1746 3.73737 17.2431 3.06434 17.2099 2.70822C17.0894 1.40464 15.8656 0.0291748 13.9846 0.0291748C12.1026 0.0291748 10.8787 1.40464 10.7593 2.70822C10.7261 3.06434 10.7946 3.73737 10.888 4.48663C10.4271 4.38644 10.3545 5.38074 10.3752 5.73904C10.397 6.11149 10.7925 6.81719 11.1714 6.70284C11.3583 8.22968 11.9635 8.71648 11.9635 8.71648L11.9479 10.3402C11.9479 10.3402 11.5649 10.5842 9.95274 11.2899C8.34062 11.9945 6.71086 12.4878 6.22608 13.2818C5.79113 13.9918 6.22608 17.3995 6.22608 17.3995C6.22608 17.3995 8.57603 18.7817 13.9846 18.7817C19.3932 18.7817 21.9301 17.3995 21.9301 17.3995C21.9301 17.3995 22.177 13.9918 21.7431 13.2818C21.2573 12.4878 19.6286 11.9945 18.0154 11.2899C16.4033 10.5842 16.0202 10.3402 16.0202 10.3402Z"
      />
    </svg>
  )
}
