import { useState } from 'react'

import { type PublicAvailabilitySlot, PublicProviderFragment } from '@nuna/api'
import { CreateAccountForm, LoginForm, useSourceReferralSearchParams } from '@nuna/auth'
import { DrawerStepper } from '@nuna/common'

import { AppointmentDrawerPaddedContainer } from '../../../AppointmentDrawer'
import { AppointmentDrawerHeader } from './AppointmentDrawerHeader'

interface AuthorizationProps {
  provider: PublicProviderFragment | undefined
  timeSlot?: PublicAvailabilitySlot
}

export function Authorization({ provider, timeSlot }: AuthorizationProps) {
  const [showLogin, setShowLogin] = useState(false)

  const sourceParams = useSourceReferralSearchParams()

  return (
    <>
      <AppointmentDrawerHeader provider={provider} timeSlot={timeSlot} />

      <AppointmentDrawerPaddedContainer>
        {showLogin ? (
          <LoginForm
            onSignUpClick={() => setShowLogin(false)}
            renderButtons={loading => (
              <DrawerStepper
                className="mt-6"
                nextButtonText="Sign in"
                loading={loading}
                nextButtonProps={{ type: 'submit', 'data-testid': 'signup-submit' }}
              />
            )}
          />
        ) : (
          <CreateAccountForm
            {...sourceParams}
            onLoginClick={() => setShowLogin(true)}
            provider={provider}
            renderButtons={loading => (
              <DrawerStepper
                className="mt-6"
                nextButtonText="Create Account"
                loading={loading}
                nextButtonProps={{ type: 'submit', 'data-testid': 'signup-submit' }}
              />
            )}
          />
        )}
      </AppointmentDrawerPaddedContainer>
    </>
  )
}
