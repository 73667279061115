import { css, styled } from '@mui/material'
import { CSSProperties, HTMLAttributes } from 'react'
import { Location, NavLink, NavLinkProps, useLocation } from 'react-router-dom'

import { greySet, tealSet } from '../../styles/colorSets'
import { body2 } from '../../styles/colors'
import { csx } from '../../utils/csx'
import { Stack, StackProps } from '../Stack/Stack'

type Icon = (props: { size: number; className?: string; style?: CSSProperties }) => JSX.Element

interface CommonProps {
  leadingIcon?: Icon
  leadingIconSize?: number
  trailingIcon?: Icon
  trailingIconSize?: number
  className?: string
  externalLink?: boolean
  isActive?: (location: Location) => boolean
  disabled?: boolean
}

type NavLinkPropsSansClassName = Omit<NavLinkProps, 'className'>

export type MenuItemLinkProps = { button?: false } & CommonProps & NavLinkPropsSansClassName
export type MenuItemButtonProps = { button: true } & CommonProps & HTMLAttributes<HTMLButtonElement>

export type MenuItemProps = MenuItemLinkProps | MenuItemButtonProps

export function MenuItem({
  leadingIcon,
  leadingIconSize = 18,
  trailingIcon,
  trailingIconSize = 18,
  isActive,
  children,
  className,
  ...props
}: MenuItemProps) {
  const location = useLocation()
  const LeadingIcon = leadingIcon
  const TrailingIcon = trailingIcon

  const contents = (
    <>
      {LeadingIcon && <LeadingIcon className="mr-1" size={leadingIconSize} />}
      {children}
      {TrailingIcon && (
        <span className="ml-auto v-align-inline">
          <TrailingIcon className="ml-2" size={trailingIconSize} />
        </span>
      )}
    </>
  )

  const composeClassNames = () => {
    // apply the active class if a custom isActive function is passed in and it returns true
    // otherwise we'll allow NavLink to add the active class. The limitation of this approach is
    // a use case where we don't accept the default logic of NavLink's matching. Our isActive approach
    // does not override.
    return csx([{ active: isActive ? isActive(location) : false }, className, 'menu-item'])
  }

  if (props.button) {
    return (
      <NavButton type="button" className={composeClassNames()} {...props}>
        {contents}
      </NavButton>
    )
  }

  if (props.externalLink) {
    const { style, ...rest } = props
    return (
      <ExternalLink
        href={props.to as string}
        target="_blank"
        rel="noreferrer noopener"
        className={composeClassNames()}
        style={style as CSSProperties}
        {...rest}
      >
        {contents}
      </ExternalLink>
    )
  }

  return (
    <Link className={composeClassNames()} {...props}>
      {contents}
    </Link>
  )
}

export const MenuItemStack = (props: StackProps) => <Stack {...props} spacing={0.75} />

const navLinkStyles = () => css`
  align-items: center;
  border-radius: var(--border-radius-sm);
  color: ${body2};
  display: flex;
  font-weight: 500;
  text-decoration: none;

  &.menu-item {
    // styles that can be overriden by parent components go here to make it easier to target without
    // needing to use !important
    padding: 0.875rem 0.75rem;
  }

  &.active {
    background-color: ${greySet[50].transparency(0.18)};
    color: ${tealSet[80].hex};
  }

  &.focus-visible {
    box-shadow: 0 0 0 4px ${greySet.tint[40]};
    outline: none;
  }

  &:hover {
    background-color: ${greySet[50].transparency(0.32)};
    color: ${greySet[80].hex};
  }
`

const NavButton = styled('button', {
  shouldForwardProp: (prop: string) => !['button', 'externalLink'].includes(prop),
})<CommonProps>`
  ${navLinkStyles()}
`

const Link = styled(NavLink, {
  shouldForwardProp: (prop: string) => !['button', 'externalLink'].includes(prop),
})<CommonProps>`
  ${navLinkStyles()}
`

const ExternalLink = styled('a', {
  shouldForwardProp: (prop: string) => !['button', 'externalLink'].includes(prop),
})<CommonProps>`
  ${navLinkStyles()}
`
