import { styled } from '@mui/material'

import { Illustration } from '@nuna/common'
import { BelowTablet, GhostButtonLink, Phone } from '@nuna/tunic'

import ProviderSrc from './img/ProviderLove.png'

export function ProviderProfileExploreMore() {
  return (
    <Container className="mt-5">
      <EmptyIllustration src={ProviderSrc} style={{ maxWidth: '240px', height: '100%' }} />
      <div className="ml-4">
        <h2 className="h4">Your digital business card for the Tava community, where clients get to know you.</h2>
        <p className="large">
          <GhostButtonLink to="/signup-intake/resume-intake">Complete your profile</GhostButtonLink> to showcase your
          expertise, therapy approach, and what makes your practice unique.
        </p>
      </div>
    </Container>
  )
}

const Container = styled('div')`
  display: flex;
  align-content: center;

  @media (${BelowTablet}) {
    display: block;
  }
  @media (${Phone}) {
    padding-top: 20px;
  }
`

const EmptyIllustration = styled(Illustration)`
  @media (${BelowTablet}) {
    margin: auto;
  }

  @media (${Phone}) {
    display: none;
  }
`
