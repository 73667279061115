import * as Yup from 'yup'
import { Formik } from 'formik'
import { Fragment } from 'react'

import { AssessmentBundleDocument, AssessmentType, useSaveAssessmentBundleMutation } from '@nuna/api'
import { formService } from '@nuna/core'
import { ChipGroup, FillButtonWithChevron, Grid, Radio, toast } from '@nuna/tunic'

import { useAssessmentsContext } from '../../context/AssessmentsContext'
import { AssessmentFromBundleQuery, Phq9FormValues } from '../../types/types'
import { phq9Questions, phq9Responses, validationForAssessmentFields } from '../../util/assessments'

const { getFieldProps, composeNestedHelperTextWithError } = formService

interface Phq9Props {
  assessment: AssessmentFromBundleQuery
}

export function Phq9({ assessment }: Phq9Props) {
  const [saveBundle, { loading }] = useSaveAssessmentBundleMutation({ awaitRefetchQueries: true })
  const { assessmentBundle, navigateWithinAssessmentBundle } = useAssessmentsContext()
  const { id: bundleId = '' } = assessmentBundle ?? {}

  const handleSubmit = async (values: Phq9FormValues) => {
    const suicidalityRisk = values['phq9:9'].value !== 0 && values['phq9:9'].value !== null
    // check if cssrs already exists so we don't keep creating new ones if the user is navigating back and forth
    const cssrsAlreadyExists = assessmentBundle?.assessments.some(a => a.type === AssessmentType.CSsrs)
    try {
      const { data } = await saveBundle({
        variables: {
          data: {
            id: bundleId,
            assessments: [
              {
                id: assessment.id,
                type: AssessmentType.Phq_9,
                values,
              },
              ...(suicidalityRisk && !cssrsAlreadyExists
                ? [
                    {
                      type: AssessmentType.CSsrs,
                      values: {},
                      loginId: assessment.loginId,
                      requesterId: assessment.requesterId,
                      source: assessment.source,
                    },
                  ]
                : []),
            ],
          },
        },
        refetchQueries: [
          {
            query: AssessmentBundleDocument,
            variables: { bundleId },
          },
        ],
        awaitRefetchQueries: true,
      })
      navigateWithinAssessmentBundle(data?.saveAssessmentBundle)
    } catch (e) {
      console.error(e)
      toast.urgent('Error saving assessment')
    }
  }

  return (
    <Formik initialValues={buildInitialValues(assessment.values)} validationSchema={phq9Schema} onSubmit={handleSubmit}>
      {formikProps => {
        const { values, handleSubmit, setFieldValue, errors, touched, submitCount } = formikProps
        return (
          <form onSubmit={handleSubmit}>
            {phq9Questions.map(({ question, key }) => {
              const { error, onBlur, name } = getFieldProps(key, formikProps)
              return (
                <Fragment key={key}>
                  <p className="mb-2 text-secondary">Over the last two weeks, how often have you been bothered by…</p>
                  <h2 className="h5 mb-4" id={`${key}-heading`}>
                    {question}
                  </h2>
                  <ChipGroup
                    className="mb-6"
                    aria-labelledby={`${key}-heading`}
                    {...composeNestedHelperTextWithError('', errors, touched, key, 'value', submitCount > 0)}
                  >
                    <Grid container>
                      {phq9Responses.map((response, index) => (
                        <Grid
                          key={response}
                          size={{
                            xs: 12,
                            sm: 'auto',
                          }}
                        >
                          <Radio
                            inline
                            checked={values[key].value === index}
                            onChange={() => setFieldValue(`${name}.value`, index)}
                            onBlur={onBlur}
                            name={`${name}.value`}
                            value={index.toString()}
                            error={error}
                            dataTestId={`${name}-${index}`}
                            className="fs-exclude"
                          >
                            {response}
                          </Radio>
                        </Grid>
                      ))}
                    </Grid>
                  </ChipGroup>
                </Fragment>
              )
            })}
            <FillButtonWithChevron type="submit" isLoading={loading}>
              Next
            </FillButtonWithChevron>
          </form>
        )
      }}
    </Formik>
  )
}

function buildInitialValues(data: AssessmentFromBundleQuery['values']): Phq9FormValues {
  const nullValue = { value: null }
  return {
    'phq9:1': data['phq9:1'] ?? nullValue,
    'phq9:2': data['phq9:2'] ?? nullValue,
    'phq9:3': data['phq9:3'] ?? nullValue,
    'phq9:4': data['phq9:4'] ?? nullValue,
    'phq9:5': data['phq9:5'] ?? nullValue,
    'phq9:6': data['phq9:6'] ?? nullValue,
    'phq9:7': data['phq9:7'] ?? nullValue,
    'phq9:8': data['phq9:8'] ?? nullValue,
    'phq9:9': data['phq9:9'] ?? nullValue,
  }
}

export const phq9Schema = Yup.object().shape<Phq9FormValues>({
  'phq9:1': validationForAssessmentFields,
  'phq9:2': validationForAssessmentFields,
  'phq9:3': validationForAssessmentFields,
  'phq9:4': validationForAssessmentFields,
  'phq9:5': validationForAssessmentFields,
  'phq9:6': validationForAssessmentFields,
  'phq9:7': validationForAssessmentFields,
  'phq9:8': validationForAssessmentFields,
  'phq9:9': validationForAssessmentFields,
})
