import { keyframes, styled } from '@mui/material'
import { HTMLAttributes, ReactNode } from 'react'
import baseToast, { Toast as IToast, ToastPosition } from 'react-hot-toast'

import { greySet, salmonSet, tealSet, whiteTintSet, yellowSet } from '../../styles/colorSets'
import { shadowDepth } from '../../styles/shadows'
import { CustomToastType, ToastAction } from './types'

interface ToastProps extends HTMLAttributes<HTMLDivElement> {
  toast: IToast
  type: CustomToastType
  action?: ToastAction
  canDismiss?: boolean
  message?: ReactNode
}

function isAtTop(position: ToastPosition | undefined) {
  if (position === 'top-left' || position === 'top-center' || position === 'top-right') {
    return true
  }

  return false
}

export function Toast({ toast, message, type, canDismiss = true, action, ...props }: ToastProps) {
  return (
    <Container $isAtTop={isAtTop(toast.position)} $visible={toast.visible} {...toast.ariaProps} {...props}>
      <Message>
        <>
          {toast.icon}
          {message ?? toast.message}
        </>
      </Message>

      {(canDismiss || action) && (
        <ButtonContainer>
          {action && (
            <Button toastType={type} onClick={action.onClick}>
              {action.buttonText}
            </Button>
          )}
          {canDismiss && action && <Divider />}
          {canDismiss && <Button onClick={() => baseToast.dismiss(toast.id)}>Dismiss</Button>}
        </ButtonContainer>
      )}
    </Container>
  )
}

const buttonStackBreakPoint = 500

const Button = styled('button')<{ toastType?: CustomToastType }>`
  align-items: center;
  border-radius: 6px;

  color: ${({ toastType }) => {
    if (toastType === 'success' || toastType === 'info') {
      return tealSet[30].hex
    } else if (toastType === 'urgent') {
      return salmonSet[30].hex
    } else if (toastType === 'caution') {
      return yellowSet[30].hex
    } else {
      return greySet[30].hex
    }
  }};

  display: inline-flex;
  font-weight: 700;
  padding: 0.75rem;

  @media (min-width: ${buttonStackBreakPoint + 1}px) {
    &:hover {
      background-color: ${greySet[90].hex};
    }
  }

  @media (max-width: ${buttonStackBreakPoint}px) {
    flex-grow: 1;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
  }
`

const Divider = styled('div')`
  display: none;
  width: 100%;
  height: 1px;
  background-color: ${whiteTintSet[30]};

  @media (max-width: ${buttonStackBreakPoint}px) {
    display: block;
    flex-direction: column;
  }
`

const ButtonContainer = styled('div')`
  align-items: center;
  border-left: 1px solid ${whiteTintSet[30]};
  display: flex;
  flex-shrink: 2;
  height: 100%;
  justify-content: center;
  margin-left: 0.25rem;
  padding: 0.25rem 0.375rem;

  @media (max-width: ${buttonStackBreakPoint}px) {
    flex-direction: column;
    padding: 0;
  }
`

const Message = styled('div')`
  display: flex;
  align-items: center;
  padding: 0.75rem;
`

const topEnter = keyframes`
  0% {
    opacity: 0;
    transform: translateY(-100%);
  }

  33% {
    opacity: 0;
    transform: translateY(-67%);
  }

  100% {
    opacity: 1;
    transform: translateY(0);
  }
`

const topExit = keyframes`
  0% {
    opacity: 1;
    transform: translateY(0);
  }

  67% {
    opacity: 0;
    transform: translateY(-67%);
  }

  100% {
    opacity: 0;
    transform: translateY(-100%);
  }
`

const bottomEnter = keyframes`
  0% {
    opacity: 0;
    transform: translateY(100%);
  }

  33% {
    opacity: 0;
    transform: translateY(67%);
  }

  100% {
    opacity: 1;
    transform: translateY(0);
  }
`

const bottomExit = keyframes`
  0% {
    opacity: 1;
    transform: translateY(0);
  }

  67% {
    opacity: 0;
    transform: translateY(67%);
  }

  100% {
    opacity: 0;
    transform: translateY(100%);
  }
`

const Container = styled('div')<{ $visible: boolean; $isAtTop?: boolean }>`
  animation: ${({ $visible, $isAtTop = false }) => {
      if ($visible && $isAtTop) {
        return topEnter
      } else if ($visible && !$isAtTop) {
        return bottomEnter
      } else if (!$visible && $isAtTop) {
        return topExit
      } else if (!$visible && !$isAtTop) {
        return bottomExit
      }
    }}
    300ms;
  animation-fill-mode: forwards;
  background-color: ${greySet[80].hex};
  border-radius: 6px;
  box-shadow: ${shadowDepth(8)};
  color: #fff;
  display: flex;
  align-items: center;

  img {
    margin-right: 0.25rem;
  }
`
