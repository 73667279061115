import { css } from '@mui/material'

import recolataLight from '../../assets/fonts/recoleta-light.otf'
import recolataMedium from '../../assets/fonts/recoleta-medium.otf'
import recolataRegular from '../../assets/fonts/recoleta-regular.otf'
import sofiaProBoldItalic from '../../assets/fonts/sofia-pro-bold-italic.otf'
import sofiaProBold from '../../assets/fonts/sofia-pro-bold.otf'
import sofiaProLightItalic from '../../assets/fonts/sofia-pro-light-italic.otf'
import sofiaProLight from '../../assets/fonts/sofia-pro-light.otf'
import sofiaProMedium from '../../assets/fonts/sofia-pro-medium.otf'

export const fonts = css`
  @font-face {
    font-family: 'Recoleta';
    src: url(${recolataLight});
    font-weight: 300;
    font-style: normal;
  }

  @font-face {
    font-family: 'Recoleta';
    src: url(${recolataRegular});
    font-weight: 400;
    font-style: normal;
  }

  @font-face {
    font-family: 'Recoleta';
    src: url(${recolataMedium});
    font-weight: 500;
    font-style: normal;
  }

  @font-face {
    font-family: 'Sofia Pro';
    src: url(${sofiaProLight});
    font-weight: 400;
    font-style: normal;
  }

  @font-face {
    font-family: 'Sofia Pro';
    src: url(${sofiaProLightItalic});
    font-weight: 400;
    font-style: italic;
  }

  @font-face {
    font-family: 'Sofia Pro';
    src: url(${sofiaProMedium});
    font-weight: 500;
    font-style: normal;
  }

  @font-face {
    font-family: 'Sofia Pro';
    src: url(${sofiaProBold});
    font-weight: 700;
    font-style: normal;
  }

  @font-face {
    font-family: 'Sofia Pro';
    src: url(${sofiaProBoldItalic});
    font-weight: 700;
    font-style: italic;
  }
`
