import { styled } from '@mui/material'

import { BelowTablet, greySet } from '@nuna/tunic'

export const Divider = styled('span')`
  display: inline-block;
  height: 28px;
  width: 1px;
  background-color: ${greySet[50].hex};
  margin: 0 1rem;

  @media (${BelowTablet}) {
    display: none;
  }
`
