import { styled } from '@mui/material'
import moment from 'moment'
import { Fragment } from 'react'
import { useParams } from 'react-router-dom'

import { Role, usePatientDetailsQuery } from '@nuna/api'
import { hasRole, useAuthDataContext } from '@nuna/auth'
import { PayoutPanel, PolicyPanel } from '@nuna/client-financial-summary'
import { ProviderTabs } from '@nuna/common'
import { ClientCoveragePanel } from '@nuna/coverage'
import { MediumMin, toast } from '@nuna/tunic'

import { ClientAssessmentSettings } from './components/ClientAssessmentSettings'
import { ClientProfileAreasOfImpact } from './components/ClientProfileAreasOfImpact'
import { ClientProfileCareTeam } from './components/ClientProfileCareTeam'
import { ClientProfileConcernsChallenges } from './components/ClientProfileConcernsChallenges'
import { ClientProfileContactInfo } from './components/ClientProfileContactInfo'
import { ClientProfileDocuments } from './components/ClientProfileDocuments'
import { ClientProfileEmergencyContact } from './components/ClientProfileEmergencyContact'
import { ClientProfileGuardianInfo } from './components/ClientProfileGuardianInfo'
import { ClientProfileMedication } from './components/ClientProfileMedication'
import { ClientProfileName } from './components/ClientProfileName'
import { ClientProfilePersonalInfo } from './components/ClientProfilePersonalInfo'
import { ClientProfileTherapistPreferences } from './components/ClientProfileTherapistPreferences'

export function ClientProfile() {
  const { id = '' } = useParams<{ id: string }>()

  const { error, data, refetch } = usePatientDetailsQuery({
    variables: { id, includeSignupUrl: true },
    fetchPolicy: 'cache-and-network',
  })

  const { login } = useAuthDataContext()

  const role = login?.role

  if (error) {
    toast.urgent('Error loading profile data')
  }

  const patient = data?.patient
  const isMinor = patient?.dob ? moment().diff(patient.dob, 'years') < 18 : false

  return (
    <main className="px-1">
      <FixedSidebarGrid>
        <div>
          <ClientProfileName patient={patient} />
          <ClientProfileCareTeam patient={patient} />
          <ClientProfileContactInfo patient={patient} />
          <ClientProfilePersonalInfo patient={patient} />
          {hasRole(role, [Role.Admin]) && isMinor && <ClientProfileGuardianInfo patient={patient} />}
          <ClientProfileConcernsChallenges patient={patient} />
          <ClientProfileAreasOfImpact patient={patient} />
          <ClientProfileEmergencyContact patient={patient} />
          <ClientProfileTherapistPreferences patient={patient} />
          <ClientProfileMedication patient={patient} />
          {hasRole(role, [Role.Provider, Role.Admin]) && login && (
            <ClientProfileDocuments patient={patient} loginData={login} />
          )}
        </div>

        <div>
          {patient && <ClientCoveragePanel client={patient} refetchClient={refetch} />}

          {role === Role.Admin ? (
            patient && (
              <ProviderTabs
                className="mt-1"
                providers={patient.providers}
                renderTabPanel={provider => (
                  <Fragment key={provider.id}>
                    <PayoutPanel className="mt-1" audience="admin" client={patient} provider={provider} />
                    <PolicyPanel className="mt-1" audience="admin" clientId={id} provider={provider} />
                    <ClientAssessmentSettings patientId={patient.id} providerId={provider.id} />
                  </Fragment>
                )}
              />
            )
          ) : (
            <>
              {patient && <PayoutPanel className="mt-1" client={patient} providerId={login?.providerId ?? ''} />}
              <PolicyPanel className="mt-1" clientId={id} providerId={login?.providerId ?? ''} />
            </>
          )}
        </div>
      </FixedSidebarGrid>
    </main>
  )
}

const FixedSidebarGrid = styled('div')`
  display: grid;
  grid-template-columns: 1fr;
  gap: 2rem;

  @media (${MediumMin}) {
    grid-template-columns: 1fr 350px;
  }
`
