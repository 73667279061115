import { styled } from '@mui/material'
import { HTMLAttributes } from 'react'

import {
  AccessCodeCoverageDetailsFragment,
  CashCoverageDetailsFragment,
  EapCoverageDetailsFragment,
  InsuranceCoverageDetailsFragment,
  PaymentPreference,
  usePatientCoverageQuery,
} from '@nuna/api'
import { Link } from '@nuna/common'
import { routeService } from '@nuna/core'
import { Skeleton, greySet } from '@nuna/tunic'

import { HeaderLink } from '../../SidebarCard'
import { AccessCodeCoverageGlance } from './AccessCodeCoverageGlance'
// uncomment to use mock coverages
// import { mockCoverages } from '@nuna/coverage'
import { CashCoverageGlance } from './CashCoverageGlance'
import { EapCoverageGlance } from './EapCoverageGlance'
import { InsuranceCoverageGlance } from './InsuranceCoverageGlance'

interface Props extends HTMLAttributes<HTMLDivElement> {
  clientId: string
}

export function ClientCoverageGlance({ clientId, ...props }: Props) {
  const { data, error } = usePatientCoverageQuery({ variables: { patientId: clientId } })

  const coverages = data?.patientCoverage

  // uncomment to use mock coverages
  // const coverages = mockCoverages()

  const currentCoverage = (coverages ?? [])[0]

  const cashCoverage: CashCoverageDetailsFragment | undefined = (coverages ?? []).find(
    coverage => coverage.type === PaymentPreference.Cash,
  )?.details as CashCoverageDetailsFragment

  const creditCardOnFile = !!cashCoverage?.cardNumber

  return (
    <div {...props}>
      <header className="my-1 v-align">
        <span>Coverage</span>
        <DetailsLink className="v-align" as={Link} openInNewTab to={routeService.patientDetail(clientId)}>
          View Details
        </DetailsLink>
      </header>

      {!coverages && <Skeleton height={8} style={{ width: '100%' }} />}

      {(() => {
        if (error || !currentCoverage) {
          return <div>Something went wrong fetching the client's current coverage.</div>
        }
      })()}

      {coverages &&
        currentCoverage &&
        (() => {
          const { details, type } = currentCoverage

          switch (type) {
            case PaymentPreference.Employer:
              return (
                <EapCoverageGlance
                  coverage={details as EapCoverageDetailsFragment}
                  creditCardOnFile={creditCardOnFile}
                />
              )
            case PaymentPreference.Insurance:
              return <InsuranceCoverageGlance coverage={details as InsuranceCoverageDetailsFragment} />
            case PaymentPreference.Cash:
              return <CashCoverageGlance coverage={details as CashCoverageDetailsFragment} />
            case PaymentPreference.Accesscode:
              return <AccessCodeCoverageGlance coverage={details as AccessCodeCoverageDetailsFragment} />
          }
        })()}
    </div>
  )
}

const DetailsLink = styled(HeaderLink)`
  flex-grow: 1;
  &:after {
    content: '';
    border-top: 1px solid ${greySet[30].hex};
    height: 1px;
    flex-grow: 1;
    margin: 0 0.5rem;
  }
`
