import React, { ReactNode } from 'react'

import { HelperText } from '../HelperText'

export interface RadioGroupProps extends React.HTMLAttributes<HTMLDivElement> {
  error?: boolean
  helperText?: string | ReactNode
}

export function RadioGroup({ error = false, helperText, children, ...props }: RadioGroupProps) {
  return (
    <div role="group" {...props}>
      {children}
      {helperText && <HelperText error={error}>{helperText}</HelperText>}
    </div>
  )
}
