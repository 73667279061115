import { MouseEventHandler, useEffect } from 'react'

import { usePatientContextQuery } from '@nuna/api'
import { useEffectOnce } from '@nuna/common'
import { type CashCoverageFragment } from '@nuna/core'
import { CreditCardSelectChange, PaymentMethodSelect, useClientPaymentMethods } from '@nuna/payment-methods'
import { Skeleton, TextButton } from '@nuna/tunic'

import { CoverageTypeCardProps } from '../../CoverageTypeCard'
import { Cost } from './Cost'

interface CashSummaryProps {
  date: Date
  coverageCardDetails: CoverageTypeCardProps
  currentCoverage: CashCoverageFragment
  onAddCardSuccess: (card: CreditCardSelectChange) => void
  onChangePaymentClick: MouseEventHandler<HTMLButtonElement>
  onCoverageValidityChange: (isValid: boolean) => void
}

export function CashSummary({
  date,
  coverageCardDetails,
  currentCoverage,
  onAddCardSuccess,
  onChangePaymentClick,
  onCoverageValidityChange,
}: CashSummaryProps) {
  const { data: patientContextData } = usePatientContextQuery()

  const {
    paymentMethods,
    defaultForSessions,
    savePaymentMethod,
    queryResult: { loading: paymentMethodsLoading },
    saveMutationResult: { loading: savePaymentMethodLoading },
  } = useClientPaymentMethods(patientContextData?.patientContext.patient?.id)

  useEffect(() => {
    onCoverageValidityChange(!coverageCardDetails.isInvalid)
  }, [coverageCardDetails.isInvalid, onCoverageValidityChange])

  // TODO: This is tech debt which was forced by the rushed zero cap company rollout. We will be overhauling coverage soon and at that point we can clean some of this up and think through it better.
  // We have a problem where we default to the first card in the list, but sometimes that card has not been marked as defaultForSessions. If someone interacts with the CC Select then it gets set properly.
  // But if they just click through then it schedules the appointment but when the backend charges the card it fails because it is not marked as defaultForSessions.
  // If a card is returned as the defaultForSessions but it is not marked as such in the db then we need to update it here before continuing with scheduling.
  useEffectOnce(() => {
    if (defaultForSessions) {
      savePaymentMethod({
        id: defaultForSessions.id,
        isHsa: defaultForSessions.isHsa,
        isUserAcknowledged: true,
        defaultForSessions: true,
        patientId: defaultForSessions.patientId,
      }).then(onAddCardSuccess)
    }
  }, defaultForSessions?.defaultForSessions === false)

  return (
    <>
      <Cost type="cash" sessionCost={currentCoverage.details.cost ?? 0} />

      {paymentMethodsLoading || savePaymentMethodLoading ? (
        <Skeleton height={3} />
      ) : (
        <>
          <PaymentMethodSelect
            date={date}
            paymentMethods={paymentMethods}
            value={defaultForSessions}
            defaultFor="sessions"
            loading={paymentMethodsLoading || savePaymentMethodLoading}
            savePaymentMethod={savePaymentMethod}
            showHSAWarning={false}
            onChange={onAddCardSuccess}
            className="mt-3"
            patientId={patientContextData?.patientContext.patient?.id || ''}
          />

          {!coverageCardDetails.isInvalid && (
            <>
              <p className="caption text-medium text-center mt-2">You won’t be charged until after your session.</p>
              <div className="text-center mt-4">
                <TextButton variant="secondary" onClick={onChangePaymentClick}>
                  Change payment method
                </TextButton>
              </div>
            </>
          )}
        </>
      )}
    </>
  )
}
