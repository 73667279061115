import { styled } from '@mui/material'
import { Dialog as MuiDialog, DialogProps as MuiDialogProps } from '@mui/material'

import { greySet } from '../../styles/colorSets'
import { semiOpaqueBackground } from '../../styles/global/misc.utils'
import { shadowDepth } from '../../styles/shadows'

export interface DialogProps extends Omit<MuiDialogProps, 'open'> {
  isOpen: boolean
  opaqueBackdrop?: boolean
  noChrome?: boolean
}

export function Dialog({ children, opaqueBackdrop = true, noChrome = false, isOpen, ...props }: DialogProps) {
  return (
    <StyledDialog open={isOpen} opaqueBackdrop={opaqueBackdrop} noChrome={noChrome} {...props}>
      {children}
    </StyledDialog>
  )
}

export type DialogPaddingSize = 'sm' | 'md' | 'lg'

export const DialogContent = styled('div')<{ paddingSize?: DialogPaddingSize }>`
  padding: ${({ paddingSize = 'sm' }) => {
    switch (paddingSize) {
      case 'sm':
        return 'var(--margin-2)'
      case 'md':
        return 'var(--margin-4)'
      case 'lg':
        return 'var(--margin-6)'
    }
  }};
`

interface StyledDialogProps {
  opaqueBackdrop: boolean
  noChrome: boolean
}
const StyledDialog = styled(MuiDialog, {
  shouldForwardProp: (prop: string) => !['opaqueBackdrop', 'noChrome'].includes(prop),
})<StyledDialogProps>`
  .MuiBackdrop-root {
    ${({ opaqueBackdrop }) =>
      opaqueBackdrop
        ? semiOpaqueBackground
        : `
        background-color: transparent;

    `}
  }

  .MuiPaper-root {
    color: unset;
  }

  .MuiDialog-paper {
    border: ${({ noChrome }) => (noChrome ? 'none' : `1px solid ${greySet[15].hex}`)};
    border-radius: var(--border-radius);
    box-shadow: ${({ noChrome }) => (noChrome ? 'none' : shadowDepth(4))};
    ${({ noChrome }) => (noChrome ? 'background: transparent;' : '')}
  }
`
