import { noop } from 'lodash'

import { specialtiesService } from '@nuna/core'

import { SpecialtiesSectionBase, SpecialtiesSectionProps } from './SpecialtiesSectionBase'

export function AgeGroupsSection({ specialties, id, refetch = noop }: SpecialtiesSectionProps) {
  return (
    <SpecialtiesSectionBase
      headingText="Client Focus - Ages"
      description="We'll use your selection to match you with your ideal clients. Though you may be comfortable treating all age groups, please select those that you are uniquely experienced with helping."
      selectedSpecialties={specialties}
      specialtiesFilter={specialtiesService.getProviderAgesSpecialties}
      refetch={refetch}
      providerId={id}
    />
  )
}
