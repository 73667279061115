import { ChangeEvent, HTMLAttributes } from 'react'

import {
  MarketingPreference,
  MarketingSubscriptionAction,
  MarketingSubscriptionMethod,
  useMarketingSubscriptionsQuery,
  useSaveMarketingSubscriptionMutation,
} from '@nuna/api'
import { errorService } from '@nuna/core'
import { Box, Stack, StackProps, Switch, makeTypographyComponent, toast } from '@nuna/tunic'

interface Props extends HTMLAttributes<HTMLDivElement> {
  providerLoginId: string | undefined
}

const SecondaryHeader = makeTypographyComponent('text-medium large sans-serif', 'h3')

export function MarketingNotifications({ providerLoginId }: Props) {
  const { data } = useMarketingSubscriptionsQuery({
    variables: {
      loginId: providerLoginId,
    },
  })
  const [saveMarketingSubscription] = useSaveMarketingSubscriptionMutation()

  const emailSubs =
    data?.marketingSubscriptions?.filter(it => it.subscriptionMethod === MarketingSubscriptionMethod.Email) || []

  const smsSubs =
    data?.marketingSubscriptions?.filter(it => it.subscriptionMethod === MarketingSubscriptionMethod.Sms) || []

  const toggleSubscription = (sub: MarketingPreference) => async (e: ChangeEvent<HTMLInputElement>) => {
    try {
      await saveMarketingSubscription({
        variables: {
          subscriptionId: sub.id,
          loginId: providerLoginId,
          action: e.target.checked ? MarketingSubscriptionAction.Subscribe : MarketingSubscriptionAction.Unsubscribe,
        },
        optimisticResponse: {
          saveMarketingSubscription: {
            ...sub,
            subscribed: e.target.checked,
          },
        },
      })
    } catch (err) {
      toast.urgent(
        errorService.transformGraphQlError(
          err,
          `There was an issue ${e.target.checked ? 'subscribing' : 'unsubscribing'} notifications`,
        ),
      )
    }
  }

  return (
    <div>
      {!!emailSubs.length && (
        <>
          <SecondaryHeader className="mt-4">Emails</SecondaryHeader>
          <Stack {...switchParentProps}>
            {emailSubs.map((sub: MarketingPreference) => (
              <Box key={sub.id}>
                <Switch checked={sub.subscribed} onChange={toggleSubscription(sub)}>
                  {sub.label}
                </Switch>
                <p className="text-secondary mt-2">{sub.description}</p>
              </Box>
            ))}
          </Stack>
        </>
      )}

      {!!smsSubs.length && (
        <>
          <SecondaryHeader className="mt-4">Text Messages</SecondaryHeader>
          <Stack {...switchParentProps}>
            {smsSubs.map((sub: MarketingPreference) => (
              <Box key={sub.id}>
                <Switch checked={sub.subscribed} onChange={toggleSubscription(sub)}>
                  {sub.label}
                </Switch>
                <p className="text-secondary mt-2">{sub.description}</p>
              </Box>
            ))}
          </Stack>
        </>
      )}
    </div>
  )
}

const switchParentProps: StackProps = {
  spacing: 2,
}
