import * as Yup from 'yup'

import { AssessmentBundleType, AssessmentResponseItem, AssessmentType, Role } from '@nuna/api'
import { formService } from '@nuna/core'

import {
  AssessmentFromAssessmentsQuery,
  Core10Question,
  Core10Responses,
  CssrsFormValues,
  CssrsQuestion,
  CssrsResponseEnum,
  CssrsSeverities,
  GAD7PHQ9Responses,
  Gad7Question,
  Phq9Question,
  Severity,
  SeverityThresholds,
} from '../types/types'

const { makeSelection } = formService

export const core10Questions: Core10Question[] = [
  { question: 'I have felt tense, anxious or nervous', key: 'core10:1' },
  { question: 'I have felt I have someone to turn to for support when needed', key: 'core10:2' },
  { question: 'I have felt able to cope when things go wrong', key: 'core10:3' },
  { question: 'Talking to people has felt too much for me', key: 'core10:4' },
  { question: 'I have felt panic or terror', key: 'core10:5' },
  { question: 'I made plans to end my life', key: 'core10:6' },
  { question: 'I have had difficulty getting to sleep or staying asleep', key: 'core10:7' },
  { question: 'I have felt despairing or hopeless', key: 'core10:8' },
  { question: 'I have felt unhappy', key: 'core10:9' },
  { question: 'Unwanted images or memories have been distressing me', key: 'core10:10' },
]
export const core10Responses = ['Not at all', 'Only occasionally', 'Sometimes', 'Often', 'Most or all of the time']
export const formattedCore10AssessmentResponses = {
  [Core10Responses.NotAtAll]: 'Not at all',
  [Core10Responses.Occasionally]: 'Only occasionally',
  [Core10Responses.Sometimes]: 'Sometimes',
  [Core10Responses.Often]: 'Often',
  [Core10Responses.AllOfTheTime]: 'Most or all of the time',
}

export const gad7Questions: Gad7Question[] = [
  { question: 'Feeling nervous, anxious, or on edge', key: 'gad7:1' },
  { question: 'Not being able to stop or control worrying', key: 'gad7:2' },
  { question: 'Worrying too much about different things', key: 'gad7:3' },
  { question: 'Trouble relaxing', key: 'gad7:4' },
  { question: 'Being so restless that it is hard to sit still', key: 'gad7:5' },
  { question: 'Becoming easily annoyed or irritable', key: 'gad7:6' },
  { question: 'Feeling afraid, as if something awful might happen', key: 'gad7:7' },
]
export const gad7Responses = ['Not at all', 'Several days', 'More than half the days', 'Nearly every day']
export const formattedGAD7PHQ9AssessmentResponses = {
  [GAD7PHQ9Responses.NotAtAll]: 'Not at all',
  [GAD7PHQ9Responses.SeveralDays]: 'Several days',
  [GAD7PHQ9Responses.MoreThanHalf]: 'More than half the days',
  [GAD7PHQ9Responses.EveryDay]: 'Nearly every day',
}

export const phq9Questions: Phq9Question[] = [
  { question: 'Little interest or pleasure in doing things', key: 'phq9:1' },
  { question: 'Feeling down, depressed, or hopeless', key: 'phq9:2' },
  { question: 'Trouble falling or staying asleep, or sleeping too much', key: 'phq9:3' },
  { question: 'Feeling tired or having little energy', key: 'phq9:4' },
  { question: 'Poor appetite or overeating', key: 'phq9:5' },
  {
    question: 'Feeling bad about yourself—or that you are a failure or have let yourself or your family down',
    key: 'phq9:6',
  },
  { question: 'Trouble concentrating on things, such as reading the newspaper or watching television', key: 'phq9:7' },
  {
    question:
      'Moving or speaking so slowly that other people could have noticed. Or the opposite – being so fidgety or restless that you have been moving around a lot more than usual',
    key: 'phq9:8',
  },
  { question: 'Thoughts that you would be better off dead, or of hurting yourself', key: 'phq9:9' },
]
export const phq9Responses = ['Not at all', 'Several days', 'More than half the days', 'Nearly every day']

// If YES to 2, ask questions 3, 4, 5, 6. If NO to 2, go directly to question 7
// If YES to 7, ask question 8
export const cssrsQuestions: CssrsQuestion[] = [
  { question: 'Have you wished you were dead or wished you could go to sleep and not wake up?', key: 'cssrs:1' },
  { question: 'Have you actually had any thoughts of killing yourself?', key: 'cssrs:2' },
  {
    question: 'Have you been thinking about how you might do this?',
    key: 'cssrs:3',
    conditionalOn: {
      key: 'cssrs:2',
      value: CssrsResponseEnum.Yes,
    },
  },
  {
    question: 'Have you had these thoughts and had some intention of acting on them?',
    key: 'cssrs:4',
    conditionalOn: {
      key: 'cssrs:2',
      value: CssrsResponseEnum.Yes,
    },
  },
  {
    question: 'Have you started to work out or worked out the details of how to kill yourself? ',
    key: 'cssrs:5',
    conditionalOn: {
      key: 'cssrs:2',
      value: CssrsResponseEnum.Yes,
    },
  },
  {
    question: 'Do you intend to carry out this plan?',
    key: 'cssrs:6',
    conditionalOn: {
      key: 'cssrs:2',
      value: CssrsResponseEnum.Yes,
    },
  },
  {
    question: 'Have you ever done anything, started to do anything, or prepared to do anything to end your life?',
    key: 'cssrs:7',
    hideWithinLabel: true,
  },
  {
    question: 'Was this within the past three months?',
    key: 'cssrs:8',
    conditionalOn: {
      key: 'cssrs:7',
      value: CssrsResponseEnum.Yes,
    },
    hideWithinLabel: true,
  },
]
export const cssrsResponses = [
  { label: 'Yes', value: 1 },
  { label: 'No', value: 0 },
]

export const getFormattedAssessmentResponse = (type: AssessmentType, response: AssessmentResponseItem) => {
  if (type === AssessmentType.Core_10) {
    return {
      question: core10Questions.find(q => q.key === response.id)?.question || '',
      response: formattedCore10AssessmentResponses[response.value as Core10Responses],
    }
  }

  if (type === AssessmentType.Gad_7) {
    return {
      question: gad7Questions.find(q => q.key === response.id)?.question || '',
      response: formattedGAD7PHQ9AssessmentResponses[response.value as GAD7PHQ9Responses],
    }
  }

  if (type === AssessmentType.Phq_9) {
    return {
      question: phq9Questions.find(q => q.key === response.id)?.question || '',
      response: formattedGAD7PHQ9AssessmentResponses[response.value as GAD7PHQ9Responses],
    }
  }

  if (type === AssessmentType.CSsrs) {
    return {
      question: cssrsQuestions.find(q => q.key === response.id)?.question || '',
      response: response.value === 1 ? 'Yes' : 'No',
    }
  }

  console.error(`Unsupported assessment type: ${type}`)

  return {
    question: response.id,
    response: response.value.toString(),
  }
}

const readableAssessmentNames: {
  [key in AssessmentType]: { [Role.Provider]: string; [Role.Patient]: string; [key: string]: string }
} = {
  [AssessmentType.Gad_7]: { [Role.Provider]: 'GAD 7', [Role.Patient]: 'Anxiety' },
  [AssessmentType.Phq_9]: { [Role.Provider]: 'PHQ 9', [Role.Patient]: 'Depression' },
  [AssessmentType.Core_10]: { [Role.Provider]: 'CORE 10', [Role.Patient]: 'CORE 10' },
  [AssessmentType.CSsrs]: { [Role.Provider]: 'CSSRS', [Role.Patient]: 'CSSRS' },
}

const longerReadableAssessmentNames: {
  [key in AssessmentType]: { [Role.Provider]: string; [Role.Patient]: string; [key: string]: string }
} = {
  [AssessmentType.Gad_7]: { [Role.Provider]: 'GAD 7', [Role.Patient]: '(Anxiety) Mental Health Assessment' },
  [AssessmentType.Phq_9]: { [Role.Provider]: 'PHQ 9', [Role.Patient]: '(Depression) Mental Health Assessment' },
  [AssessmentType.Core_10]: { [Role.Provider]: 'CORE 10', [Role.Patient]: 'CORE 10' },
  [AssessmentType.CSsrs]: { [Role.Provider]: 'CSSRS', [Role.Patient]: 'Suicide Risk Assessment' },
}

export function getReadableAssessmentName(type: AssessmentType, role: Role): string {
  switch (role) {
    case Role.Provider:
      return readableAssessmentNames[type][Role.Provider]
    case Role.Patient:
      return readableAssessmentNames[type][Role.Patient]
    default:
      return readableAssessmentNames[type][Role.Patient]
  }
}

export function getLongerReadableAssessmentName(type: AssessmentType, role: Role): string {
  switch (role) {
    case Role.Provider:
      return longerReadableAssessmentNames[type][Role.Provider]
    case Role.Patient:
      return longerReadableAssessmentNames[type][Role.Patient]
    default:
      return longerReadableAssessmentNames[type][Role.Patient]
  }
}

export const assessmentBundleTypeDisplayLookupDesktop = {
  [AssessmentBundleType.MentalHealthAssessment]: 'Mental Health Assessment',
  [AssessmentBundleType.SuicidalityAssessment]: 'Suicidality Assessment',
}

export const assessmentBundleTypeDisplayLookupMobile = {
  [AssessmentBundleType.MentalHealthAssessment]: 'Assessment',
  [AssessmentBundleType.SuicidalityAssessment]: 'Suicidality Assessment',
}

export const validationForAssessmentFields = Yup.object().shape({
  value: Yup.mixed().nullable(true).required(makeSelection),
})

export const zeroValue = { value: 0 } as { value: 0 }

export function determineSeverity(
  score: number | null | undefined,
  type: AssessmentType.Gad_7 | AssessmentType.Phq_9,
): Severity {
  if (score === null || score === undefined) {
    return Severity.Minimal
  }

  const thresholds = severityThresholds[type]

  if (score <= thresholds[0]) return Severity.Minimal
  if (score <= thresholds[1]) return Severity.Mild
  if (score <= thresholds[2]) return Severity.Moderate
  return Severity.Severe
}

export const severityThresholds: SeverityThresholds = {
  [AssessmentType.Gad_7]: [4, 9, 14, 21],
  [AssessmentType.Phq_9]: [4, 9, 19, 27],
}

enum gad7SeverityLabels {
  Minimal = 'Minimal anxiety',
  Mild = 'Mild anxiety',
  Moderate = 'Moderate anxiety',
  Severe = 'Severe anxiety',
}

enum phq9SeverityLabels {
  Minimal = 'Minimal depression',
  Mild = 'Mild depression',
  Moderate = 'Moderate depression',
  ModeratelySevere = 'Moderately severe depression',
  Severe = 'Severe depression',
}

export const severityLookupWithLabels = {
  [AssessmentType.Gad_7]: [
    { maxScore: 4, label: gad7SeverityLabels.Minimal, severity: Severity.Minimal },
    { maxScore: 9, label: gad7SeverityLabels.Mild, severity: Severity.Mild },
    { maxScore: 14, label: gad7SeverityLabels.Moderate, severity: Severity.Moderate },
    { maxScore: Infinity, label: gad7SeverityLabels.Severe, severity: Severity.Severe },
  ],
  [AssessmentType.Phq_9]: [
    { maxScore: 4, label: phq9SeverityLabels.Minimal, severity: Severity.Minimal },
    { maxScore: 9, label: phq9SeverityLabels.Mild, severity: Severity.Mild },
    { maxScore: 14, label: phq9SeverityLabels.Moderate, severity: Severity.Moderate },
    { maxScore: 19, label: phq9SeverityLabels.ModeratelySevere, severity: Severity.Moderate },
    { maxScore: Infinity, label: phq9SeverityLabels.Severe, severity: Severity.Severe },
  ],
}

export const cssrsSeverityLabelLookup = (values: CssrsFormValues) => {
  if (values['cssrs:4']?.value || values['cssrs:5']?.value || values['cssrs:6']?.value || values['cssrs:8']?.value) {
    return CssrsSeverities.HighRisk
  }
  if (values['cssrs:3']?.value || values['cssrs:7']?.value) {
    return CssrsSeverities.ModerateRisk
  }
  return CssrsSeverities.LowRisk
}

function cssrsSeverityToSeverity(severity: CssrsSeverities) {
  switch (severity) {
    case CssrsSeverities.LowRisk:
      return Severity.Mild
    case CssrsSeverities.ModerateRisk:
      return Severity.Moderate
    case CssrsSeverities.HighRisk:
      return Severity.Severe
  }
}

export function getAssessmentSeverityAndLabel(assessment: AssessmentFromAssessmentsQuery | undefined) {
  if (!assessment) return { label: 'Loading', severity: Severity.Minimal }
  const { type } = assessment
  if (type === AssessmentType.Phq_9 || type === AssessmentType.Gad_7) {
    const severity = severityLookupWithLabels[type].find(range => (assessment.score || 0) <= range.maxScore)
    return { label: severity?.label, severity: severity?.severity }
  }
  if (type === AssessmentType.CSsrs) {
    const severityLabel = cssrsSeverityLabelLookup(assessment.values as CssrsFormValues)
    const severity = cssrsSeverityToSeverity(severityLabel)
    return { label: severityLabel, severity }
  }
  return { label: 'Unknown', severity: Severity.Minimal }
}

export const assessmentService = {
  getAssessmentSeverityAndLabel,
  getReadableAssessmentName,
  determineSeverity,
}
