import { css } from '@mui/material'

import { plumSet, salmonSet } from '../colorSets'

export const primaryColorVars = css`
  :root {
    --primary-5: ${salmonSet[5].hex};
    --primary-15: ${salmonSet[15].hex};
    --primary-30: ${salmonSet[30].hex};
    --primary-50: ${salmonSet[50].hex};
    --primary-70: ${salmonSet[70].hex};
    --primary-80: ${salmonSet[80].hex};
    --primary-tint-20: ${salmonSet.tint[20]};
    --primary-tint-40: ${salmonSet.tint[40]};
    --primary-hover: ${salmonSet[50].hover};
  }

  [data-tunic-theme='harmony'] {
    --primary-5: ${plumSet[5].hex};
    --primary-15: ${plumSet[15].hex};
    --primary-30: ${plumSet[30].hex};
    --primary-50: ${plumSet[50].hex};
    --primary-70: ${plumSet[70].hex};
    --primary-80: ${plumSet[80].hex};
    --primary-tint-20: ${plumSet.tint[20]};
    --primary-tint-40: ${plumSet.tint[40]};
    --primary-hover: ${plumSet[50].hover};
  }
`
