import { useMemo } from 'react'

import { useListCancelPoliciesQuery } from '@nuna/api'
import { cancelPolicyService } from '@nuna/core'
import { IntakeCancelPolicyForm } from '@nuna/fee-policies'

import { useProviderAppContext } from '../../../../shared/ProviderAppContext'
import { IntakeStepContainer } from '../../shared/IntakeStepContainer'
import { IntakeHeader } from '../../shared/IntakeTypography'
import { ProviderIntakeFormStepper } from '../../shared/ProviderIntakeFormStepper'

export function IntakeCancelPolicy() {
  const { provider } = useProviderAppContext()

  const { id: providerId } = provider ?? {}
  const { data } = useListCancelPoliciesQuery({
    variables: { providerId: providerId ?? '' },
    skip: !providerId,
    fetchPolicy: 'cache-and-network',
  })

  const cancelPolicies = useMemo(
    () => cancelPolicyService.prepareCancelPoliciesList(data?.listCancelPolicies ?? []),
    [data],
  )

  return (
    <IntakeStepContainer maxWidth="932px">
      <IntakeHeader type="h1" md={{}} xs={{ mb: 5 }}>
        Cancellation and no show policies
      </IntakeHeader>
      <div className="text-secondary display mb-4">
        Set up your cancellation and no-show policies to ensure clients are aware of the consequences of missing
        appointment.
      </div>

      <IntakeCancelPolicyForm cancelPolicies={cancelPolicies} providerId={providerId} />

      <ProviderIntakeFormStepper
        currentSection="cancel-policy"
        mutationLoading={false}
        step="cancel-policy"
        isValid={cancelPolicies.length > 0}
        forwardDisabled={cancelPolicies.length === 0}
        className="mt-5"
        type="navigation"
      />
    </IntakeStepContainer>
  )
}
