import { useFormikContext } from 'formik'
import { MouseEventHandler, useEffect, useState } from 'react'

import { InsurancePolicyFragment, PaymentPreference, documentUtils, useSavePatientIntakeMutation } from '@nuna/api'
import { useIsAdmin } from '@nuna/auth'
import { useEffectOnce } from '@nuna/common'
import {
  Checkbox,
  ContextualAlert,
  FillButton,
  GhostButton,
  IconButton,
  IconChevronThick,
  SiderailComponents,
  TextButton,
  toast,
} from '@nuna/tunic'

import { useSaveInsuranceCoverage } from '../../../hooks/useSaveInsuranceCoverage'
import { WrappedResponse } from '../../../types'
import { CompactAppointmentCard, CompactAppointmentCardLoader } from '../../CompactAppointmentCard'
import { CoverageFormPatient, CoverageFormValues, buildInsuranceCoverageInput } from '../../CoverageForm'
import { CoverageTypeCard } from '../../CoverageTypeCard'
import { useCancelAppointments } from '../hooks/useCancelAppointments'
import {
  SimpleUpcomingAppointment,
  UpcomingAppointmentsStatus,
  useCheckUpcomingAppointmentsForCoverage,
} from '../hooks/useCheckUpcomingAppointmentsForCoverage'

interface SwitchToInsuranceVerificationProps {
  patient: CoverageFormPatient
  upcomingAppointments: SimpleUpcomingAppointment[]
  onSubmitComplete: () => void
  onCancelClick: MouseEventHandler<HTMLButtonElement>
  onBackClick: MouseEventHandler<HTMLButtonElement>
  isNewAccount?: boolean
}

export function SwitchToInsuranceVerification({
  patient,
  upcomingAppointments,
  onSubmitComplete,
  onCancelClick,
  onBackClick,
  isNewAccount = false,
}: SwitchToInsuranceVerificationProps) {
  const { values } = useFormikContext<CoverageFormValues>()

  const isAdmin = useIsAdmin()

  const possessivePronoun = isAdmin ? 'their' : 'your'

  const [verifyInsuranceCoverage, { loading: verifyLoading }] = useSaveInsuranceCoverage()
  const [checkUpcomingAppointmentCoverage, { loading: coverageLoading }] = useCheckUpcomingAppointmentsForCoverage()
  const [cancelAppointments, { loading: cancelLoading }] = useCancelAppointments()

  const [savePatientIntake, { loading: isIntakeLoading }] = useSavePatientIntakeMutation({
    refetchQueries: [isAdmin ? 'PatientCoveragesAndIntakeCompleteness' : 'PatientCoverage'], // if preference changes, requery anything using PatientCoverage
  })

  const [insurancePolicyResult, setInsurancePolicyResult] = useState<WrappedResponse<InsurancePolicyFragment> | null>(
    null,
  )

  const [upcomingCoverageStatus, setUpcominCoverageStatus] =
    useState<WrappedResponse<UpcomingAppointmentsStatus> | null>(null)

  const [agreeToCancelCheckbox, setAgreeToCancelCheckbox] = useState(false)

  const insurancePolicy = insurancePolicyResult?.data
  const outOfNetworkAppointments = upcomingCoverageStatus?.data?.notCoveredAppointments ?? null
  const providerIssues = upcomingCoverageStatus?.data?.providerCoverageIssues ?? []

  useEffectOnce(() => {
    verifyInsuranceCoverage({
      variables: {
        input: buildInsuranceCoverageInput(values, patient),
      },
    }).then(setInsurancePolicyResult)
  }, patient && !insurancePolicyResult)

  useEffect(() => {
    if (patient && insurancePolicy && insurancePolicy.memberId && insurancePolicy.active && !upcomingCoverageStatus) {
      checkUpcomingAppointmentCoverage(upcomingAppointments, patient).then(setUpcominCoverageStatus)
    }
  }, [checkUpcomingAppointmentCoverage, insurancePolicy, upcomingCoverageStatus, patient, upcomingAppointments])

  if (!patient) return null

  const handleSubmit = async () => {
    if (!outOfNetworkAppointments) {
      // The button should be disabled if this is the case. This is just here as a hail mary/typecheck thing
      toast.urgent("An unexpected error occured. Can't submit with unknown appointment status. Try refreshing the page")
      return
    }

    if (outOfNetworkAppointments.length > 0) {
      const cancelAppointmentsResult = await cancelAppointments(outOfNetworkAppointments)
      if (!cancelAppointmentsResult.didSucceed) {
        toast.urgent(
          `Oops, we ran into an issue canceling ${possessivePronoun} appointments. Try submitting the form again`,
          {
            canDismiss: false,
            action: { onClick: handleSubmit, buttonText: 'Try again' },
          },
        )
        return
      }
    }

    try {
      await savePatientIntake({
        variables: {
          id: patient.id,
          input: {
            paymentPreference: PaymentPreference.Insurance,
          },
        },
      })

      onSubmitComplete()
    } catch (e) {
      console.error(e)
      toast.urgent(
        `Oops, we ran into an issue updating ${possessivePronoun} payment method. Try submitting the form again`,
        {
          canDismiss: false,
          action: { onClick: handleSubmit, buttonText: 'Try again' },
        },
      )
    }
  }

  const isButtonDisabled = () => {
    if (!insurancePolicy || !insurancePolicy.active || outOfNetworkAppointments === null) {
      return true
    }

    if (outOfNetworkAppointments.length > 0) {
      return !agreeToCancelCheckbox
    }

    if (outOfNetworkAppointments.length === 0) {
      return false
    }

    return false
  }

  return (
    <>
      {/* switch-container class is for backwards compatibility with the old SwitchCoverageForm in arrow and can be deleted with that component */}
      <div className="switch-container">
        <CoverageTypeCard
          type="insurance"
          isInvalid={!!insurancePolicyResult && !insurancePolicyResult.didSucceed}
          footerOverride={insurancePolicyResult?.error ? 'Oops, something went wrong' : undefined}
          failureReason={
            insurancePolicyResult?.error
              ? `We could not verify the status of ${possessivePronoun} insurance. Please try again.`
              : undefined
          }
          memberId={insurancePolicy?.memberId ?? values.insuranceValues?.memberId ?? ''}
          verifiedAt={insurancePolicy?.verifiedAt}
          payerName={values.insuranceValues?.insurancePayer?.name ?? ''}
          payerLogoUrl={
            values.insuranceValues?.insurancePayer?.logoDocumentId
              ? documentUtils.documentUrl(
                  documentUtils.DocumentPath.PayerLogo,
                  values.insuranceValues.insurancePayer.id,
                  values.insuranceValues.insurancePayer.logoDocumentId,
                )
              : undefined
          }
          insuredFullName={insurancePolicy?.insuredFirstName ?? values.insuranceValues?.insurancePayer?.name ?? ''}
        />

        {/* the call to verify insurance failed */}
        {insurancePolicyResult?.error && (
          <TextButton
            className="mt-4"
            onClick={() => {
              setInsurancePolicyResult(null)
              verifyInsuranceCoverage({
                variables: {
                  input: buildInsuranceCoverageInput(values, patient),
                },
              }).then(setInsurancePolicyResult)
            }}
          >
            Try again
          </TextButton>
        )}

        {/* insurance call succeeded but insurance isn't valid */}
        {insurancePolicyResult?.data && insurancePolicyResult.didSucceed === false && (
          <TextButton className="mt-4" onClick={onBackClick}>
            Edit info
          </TextButton>
        )}

        {insurancePolicyResult?.didSucceed && !isNewAccount && (
          <h3 className="mt-4 body text-bold">Verify appointment coverage</h3>
        )}

        {insurancePolicyResult?.didSucceed && upcomingCoverageStatus === null && <CompactAppointmentCardLoader />}

        {/* one or more calls to estimatePaymentResponsibility failed */}
        {upcomingCoverageStatus?.error && (
          <>
            <ContextualAlert className="mt-1" intent="urgent">
              We could not verify the coverage status of {possessivePronoun} upcoming appointments. Please try again
            </ContextualAlert>

            <TextButton
              className="mt-2"
              onClick={() => {
                setUpcominCoverageStatus(null) // kicks off effect
              }}
            >
              Try again
            </TextButton>
          </>
        )}

        {outOfNetworkAppointments && outOfNetworkAppointments.length > 0 && (
          <>
            {outOfNetworkAppointments.map(appointment => (
              <CompactAppointmentCard key={appointment.id} className="mb-2" outOfNetwork appointment={appointment} />
            ))}

            <ContextualAlert className="mt-1 mb-2" intent="urgent">
              Uh oh, one or more upcoming appointments are not covered by {possessivePronoun} insurance and will be
              canceled by proceeding
            </ContextualAlert>

            <div className="mt-2 px-1">
              <Checkbox checked={agreeToCancelCheckbox} onChange={e => setAgreeToCancelCheckbox(e.target.checked)}>
                I understand that by proceeding these appointments will be canceled.
              </Checkbox>
            </div>

            <div className="text-center mt-5">
              <GhostButton onClick={onCancelClick}>
                Nevermind, keep {isAdmin ? 'their' : 'my'} current coverage
              </GhostButton>
            </div>
          </>
        )}

        {outOfNetworkAppointments && outOfNetworkAppointments.length === 0 && upcomingAppointments.length > 0 && (
          <>
            {upcomingAppointments.map(appointment => (
              <CompactAppointmentCard className="mb-2" key={appointment.id} inNetwork appointment={appointment} />
            ))}
          </>
        )}

        {outOfNetworkAppointments &&
          outOfNetworkAppointments.length === 0 &&
          !isNewAccount &&
          providerIssues.length === 0 && (
            <ContextualAlert className="mt-1 mb-2" intent="information">
              {upcomingAppointments.length > 0
                ? `Great! ${
                    isAdmin ? 'Their' : 'Your'
                  } upcoming appointments will be covered by ${possessivePronoun} insurance.`
                : `${
                    isAdmin ? 'They' : 'You'
                  } have no upcoming appointments. If ${possessivePronoun} current provider is out-of-network you will be prompted to change when ${
                    isAdmin ? 'they' : 'you'
                  } schedule.`}
            </ContextualAlert>
          )}

        {providerIssues.length > 0 && (
          <>
            {providerIssues.map(({ provider, reason }) => (
              <ContextualAlert key={provider.id} className="mt-1" intent="urgent">
                Heads up! Future appointments with {possessivePronoun} therapist, {provider.firstName}{' '}
                {provider.lastName}, will not be covered because {reason.toLocaleLowerCase()}.
              </ContextualAlert>
            ))}

            <div className="text-center mt-5">
              <GhostButton onClick={onCancelClick}>
                Nevermind, keep {isAdmin ? 'their' : 'my'} current coverage
              </GhostButton>
            </div>
          </>
        )}
      </div>

      {/* switch-buttons class is for backwards compatibility with the old SwitchCoverageForm in arrow and can be deleted with that component */}
      <div className="mt-6 v-align switch-buttons">
        <IconButton className="mt-auto" tooltip="Back" onClick={onBackClick}>
          <IconChevronThick size={20} />
        </IconButton>

        <SiderailComponents.Divider className="ml-xs mr-2" />
        <FillButton
          isLoading={verifyLoading || coverageLoading || cancelLoading || isIntakeLoading}
          type="button"
          onClick={handleSubmit}
          disabled={isButtonDisabled()}
          data-testid="verify-insurance-coverage"
        >
          {isNewAccount ? 'Save' : 'Update'} coverage
        </FillButton>
      </div>
    </>
  )
}
