import { usePatientDetailsQuery } from '@nuna/api'
import { CopyToClipboardButtonLink } from '@nuna/common'
import { useEnvironmentContext } from '@nuna/environment'
import { ContextualAlert, IconCautionCircle } from '@nuna/tunic'

import { SessionData } from '../../../types'

interface Props {
  sessionData: SessionData
}

export function IncompleteIntakeAlert({ sessionData }: Props) {
  const { ARROW_BASE_URL } = useEnvironmentContext()

  const { data: patientDetails } = usePatientDetailsQuery({
    variables: { id: sessionData.patient.id, includeSignupUrl: true },
  })

  const signupUrl = patientDetails?.patient?.signupUrl ?? ''

  const iconStyle = { style: { height: 15 } } // the icon caution circle does not have the same amount of white space as other icons so it needs some custom love :(

  return (
    <div className="constrain-width mt-2">
      {!sessionData.patient.login.lastLoginAt ? (
        <ContextualAlert intent="caution" icon={<IconCautionCircle {...iconStyle} />}>
          Looks like {sessionData.patient.firstName} hasn't completed intake. Ask them to create their password{' '}
          <CopyToClipboardButtonLink to={signupUrl || ''} variant="caution">
            <span>here</span>
          </CopyToClipboardButtonLink>{' '}
          and complete intake so you can submit this session note. You can write the note now and your draft will save
          automatically.
        </ContextualAlert>
      ) : (
        <ContextualAlert intent="caution" icon={<IconCautionCircle {...iconStyle} />}>
          Looks like {sessionData.patient.firstName} hasn't completed intake. Ask them to log in at{' '}
          <CopyToClipboardButtonLink to={`${ARROW_BASE_URL}/login`} variant="caution">
            <span>{ARROW_BASE_URL}/login</span>
          </CopyToClipboardButtonLink>{' '}
          and complete intake so you can submit this session note. You can write the note now and your draft will save
          automatically.
        </ContextualAlert>
      )}
    </div>
  )
}
