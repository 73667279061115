import { HTMLAttributes, ReactNode, useId, useState } from 'react'

import { Avatar, MenuItem, ResponsiveHorizontalMenu } from '@nuna/tunic'

interface TabProvider {
  id: string
  firstName: string
  lastName: string
  avatarUrl?: string | null
}

interface Props<T> extends HTMLAttributes<HTMLDivElement> {
  providers: T[]
  renderTabPanel: (provider: T) => ReactNode
  tabTitle?: (provider: T) => string | undefined
}

export function ProviderTabs<T extends TabProvider>({ providers, renderTabPanel, ...props }: Props<T>) {
  const idPrefix = useId()
  const [activeProvider, setActiveProvider] = useState(providers?.[0])

  if (providers.length === 1) {
    // if there is only one provider, don't render the tabs
    return <>{renderTabPanel(providers[0])}</>
  }

  const getTabPanelId = (providerId: string) => `${idPrefix}-tabpanel-${providerId}`
  const getTabId = (providerId: string) => `${idPrefix}-tab-${providerId}`

  return (
    <div {...props}>
      <ResponsiveHorizontalMenu role="tablist">
        {providers.map(provider => (
          <MenuItem
            key={provider.id}
            button
            role="tab"
            aria-controls={getTabPanelId(provider.id)}
            aria-selected={activeProvider.id === provider.id}
            id={getTabId(provider.id)}
            onClick={() => setActiveProvider(provider)}
            title={`${provider.firstName} ${provider.lastName}`}
            isActive={() => activeProvider.id === provider.id}
          >
            <Avatar size="xs" url={provider.avatarUrl} />
          </MenuItem>
        ))}
      </ResponsiveHorizontalMenu>

      {providers.map(provider => (
        <div
          hidden={activeProvider.id !== provider.id}
          role="tabpanel"
          key={provider.id}
          id={getTabPanelId(provider.id)}
          aria-labelledby={getTabId(provider.id)}
        >
          {renderTabPanel(provider)}
        </div>
      ))}
    </div>
  )
}
