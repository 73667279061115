/* eslint-disable @typescript-eslint/no-explicit-any */
import { ApolloQueryResult } from '@apollo/client'
import { IField, ISettings } from '@flatfile/adapter/build/main/interfaces'
import {
  FlatfileButton,
  FlatfileImporter,
  FlatfileResults,
  IDataHookResponse,
  ScalarDictionaryWithCustom,
} from '@flatfile/react'
import { omit, omitBy, partition } from 'lodash'
import moment, { Moment } from 'moment'
import React, { ChangeEventHandler, useState } from 'react'
import { useNavigate } from 'react-router-dom'

import {
  CustomerCompanyDetailsFragment,
  CustomerCompanyQuery,
  CustomerEmployeeDimension,
  Role,
  useCustomerEmployeeDimensionsQuery,
  useStageRosterUploadMutation,
} from '@nuna/api'
import { useAuthDataContext } from '@nuna/auth'
import { addressService } from '@nuna/core'
import { useEnvironmentContext } from '@nuna/environment'
import { DOBTextField, FillButton, SpinningLoader, toast } from '@nuna/tunic'

import { StagedRosterInsights } from './StagedRosterInsights'

const { getCountryAbbreviationFromFullName, getStateAbbreviationFromFullName } = addressService

interface RosterUploadProps {
  customerCompany: CustomerCompanyDetailsFragment
  refetchCustomerCompany: () => Promise<ApolloQueryResult<CustomerCompanyQuery>>
}

function generateFlatfileSettingsForUser(
  customDimensions: CustomerEmployeeDimension[],
  companyName: string,
  production: boolean,
): ISettings {
  const baseFields: IField[] = [
    {
      label: 'Employee Id',
      key: 'ancillaryEmployeeId',
    },
    { label: 'Preferred First Name', key: 'preferredFirstName' }, // O
    { label: 'First Name', key: 'firstName', validators: [{ validate: 'required' }] }, //  X
    { label: 'Last Name', key: 'lastName', validators: [{ validate: 'required' }] }, //  X
    { label: 'Date of Birth', key: 'dob', validators: [{ validate: 'required' }] }, // X
    {
      label: 'Work Email',
      key: 'workEmail',
      validators: [
        {
          validate: 'regex_matches',
          regex:
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
              .source,
          error: 'Must be a valid email',
          regexFlags: {
            ignoreCase: true,
          },
        },
      ],
    },
    {
      label: 'Personal Email',
      key: 'personalEmail',
      validators: [
        {
          validate: 'regex_matches',
          regex:
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
              .source,
          error: 'Must be a valid email',
          regexFlags: {
            ignoreCase: true,
          },
        },
      ],
    },
    { label: 'Personal Phone Number', key: 'phoneNumber' }, // O
    { label: 'Home Address Line One', key: 'homeAddressLineOne' }, // O// Strongly Recommended
    { label: 'Home Address Line Two', key: 'homeAddressLineTwo' }, // O// Strongly Recommended
    { label: 'Home City', key: 'homeCity' }, // O // Strongly Recommended
    {
      label: 'Home State',
      key: 'homeState',
      validators: [
        {
          validate: 'regex_matches',
          regex: '[A-Z]{2}',
          error: 'State must be the two letter abbreviation. e.g. AZ',
          regexFlags: {
            ignoreCase: true,
          },
        },
        { validate: 'required_with_values', fieldValues: { relationshipToCompany: 'EMPLOYEE' } },
        // X
      ],
    },
    {
      label: 'Home Country',
      key: 'homeCountry',
      validators: [
        {
          validate: 'regex_matches',
          regex: '[A-Z]{2}',
          error: 'Country must be the two letter abbreviation. e.g. US',
          regexFlags: {
            ignoreCase: true,
          },
        },
      ],
    }, // O
    { label: 'Home Zip Code', key: 'homeZipCode' }, //O Strongly Recommended
    { label: 'Work Address Line One', key: 'workAddressLineOne' }, // O
    { label: 'Work Address Line Two', key: 'workAddressLineTwo' }, // O
    { label: 'Work City', key: 'workCity' }, // O
    {
      label: 'Work State',
      key: 'workState',
      validators: [
        {
          validate: 'regex_matches',
          regex: '[A-Z]{2}',
          error: 'State must be the two letter abbreviation. e.g. AZ',
          regexFlags: {
            ignoreCase: true,
          },
        },
        // O
      ],
    },
    {
      label: 'Work Country',
      key: 'workCountry',
      validators: [
        {
          validate: 'regex_matches',
          regex: '[A-Z]{2}',
          error: 'Country must be the two letter abbreviation. e.g. US',
          regexFlags: {
            ignoreCase: true,
          },
        },
      ],
    }, // O
    { label: 'Work Zip Code', key: 'workZipCode' }, // O
    { label: 'Work Location', key: 'workLocation' }, // O
    { label: 'Marital Status', key: 'maritalStatus' }, // O
    { label: 'Ethnicity', key: 'ethnicity' }, // O
    {
      label: 'Gender',
      key: 'gender',
    },
    { label: 'Department', key: 'teamName' }, // O
    {
      label: 'Employment Status',
      key: 'employmentStatus',
      type: 'select',
      description: 'Only rows marked as ACTIVE will be eligible to use Tava Health.',
      options: [
        { value: 'ACTIVE', label: 'Active' },
        { value: 'INACTIVE', label: 'Inactive' },
      ],
    },
    {
      label: 'Current Hire Date',
      key: 'currentHireDate',
      description: 'Most recent hire date',
    },
    { label: 'Current Termination Date', key: 'currentTerminationDate', description: 'Most recent termination date' }, // O
    { label: 'Job Title', key: 'jobTitle' }, // O
    {
      label: 'Employment Type',
      key: 'employmentType',
      type: 'select',
      options: [
        { value: 'full_time', label: 'Full Time' },
        { value: 'part_time', label: 'Part Time' },
        { value: 'intern', label: 'Intern' },
        { value: 'temp', label: 'Temp' },
        { value: 'seasonal', label: 'Seasonal' },
        { value: 'individual_contractor', label: 'Contractor' },
        { value: '', label: 'Not Applicable' },
      ],
    },
    {
      label: 'Relationship to Company',
      key: 'relationshipToCompany',
      type: 'select',
      options: [
        { value: 'EMPLOYEE', label: 'Employee' },
        { value: 'SPOUSE_PARTNER', label: 'Spouse or Domestic Partner' },
        { value: 'CHILD', label: 'Child' },
      ],
    },
    { label: 'Related Employee Id', key: 'relatedEmployeeId' },
    { label: 'Related Employee First Name', key: 'relatedEmployeeFirstName' },
    { label: 'Related Employee Last Name', key: 'relatedEmployeeLastName' },
    { label: 'Related Employee Date of Birth', key: 'relatedEmployeeDob' },
    { label: 'Subscriber Id', key: 'subscriberId' },
    {
      label: 'Dependent First Name',
      key: 'dependentFirstName',
      validators: [
        {
          validate: 'required_with',
          fields: ['dependentLastName', 'dependentDob', 'dependentRelationship'],
        },
      ],
    },
    {
      label: 'Dependent Last Name',
      key: 'dependentLastName',
      validators: [
        {
          validate: 'required_with',
          fields: ['dependentFirstName', 'dependentDob', 'dependentRelationship'],
        },
      ],
    },
    {
      label: 'Dependent Date of Birth',
      key: 'dependentDob',
      validators: [
        {
          validate: 'required_with',
          fields: ['dependentFirstName', 'dependentLastName', 'dependentRelationship'],
        },
      ],
    },
    {
      label: 'Dependent Relationship',
      key: 'dependentRelationship',
      type: 'select',
      options: [
        { value: 'SPOUSE_PARTNER', label: 'Spouse or Domestic Partner' },
        { value: 'CHILD', label: 'Child' },
        { value: '', label: 'Not Applicable' },
      ],
      validators: [
        {
          validate: 'required_with',
          fields: ['dependentFirstName', 'dependentLastName', 'dependentDob'],
        },
      ],
    },
    { label: 'Dependent Gender', key: 'dependentGender' },
    { label: 'Dependent Id', key: 'dependentId' },
    {
      label: 'Salary Type',
      key: 'salaryType',
      type: 'select',
      options: [
        { label: 'Salaried', value: 'Salaried' },
        { label: 'Hourly', value: 'Hourly' },
        { value: '', label: 'Not Applicable' },
      ],
    },
  ]

  const customFields: IField[] = customDimensions.map(dimension => ({
    label: dimension.name,
    key: dimension.customerEmployeeRecordField,
    ...omitBy(dimension.valueMapping, gqlFilter),
    ...(dimension.valueMapping?.options && {
      options: dimension.valueMapping.options.map(option => omitBy(option, gqlFilter)),
    }),
  }))

  const fields = applyCustomFields(baseFields, customFields)

  return {
    type: 'Employee',
    allowInvalidSubmit: false,
    title: `Upload employees for ${companyName}`,
    disableManualInput: true,
    allowCustom: false,
    devMode: !production,
    fields,
    managed: true,
  }
}

function applyCustomFields(fields: IField[], customFields: IField[]): IField[] {
  const [existingFields, addedFields] = partition(customFields, custom =>
    fields.some(field => custom.key === field.key),
  )

  return [
    // overlay any custom fields that modify base fields
    ...fields.map(field => ({
      ...field,
      ...existingFields.find(existing => existing.key === field.key),
    })),
    ...addedFields,
  ]
}

function gqlFilter(value: any, key: string) {
  return key === '__typename' || value === null
}

function generateHooks() {
  return {
    onRecordChange: handleRecordChange,
    onRecordInit: (record: ScalarDictionaryWithCustom) => {
      return {
        ...handleRecordChange(record),
      } as any
    },
  }
}

function handleVirtualFieldCreation(record: ScalarDictionaryWithCustom) {
  const response = {} as IDataHookResponse
  if (!record.employmentStatus) {
    response.employmentStatus = {
      value: 'ACTIVE',
      info: [
        {
          message: 'Assuming all provided records are active since no status was provided.',
          level: 'info',
        },
      ],
    }
  }
  if (!record.ancillaryEmployeeId) {
    response.ancillaryEmployeeId = {
      value: undefined, // we make sure it's at least included in the sent objects as undefined
    }
  }
  if (!record.relationshipToCompany) {
    response.relationshipToCompany = {
      value: 'EMPLOYEE',
      info: [
        {
          message: 'Assuming all provided records are employees since no relationship to company was provided.',
          level: 'info',
        },
      ],
    }
  }

  return response
}

// Try different permutations of the format (e.g. 05/22/2021 vs 5/22/2021)
function generateMonthDayYearMomentInPast(dateItem: string): Moment {
  const parsedMoment = ['M/DD/YY', 'M/D/YY', 'M/DD/YYYY', 'M/D/YYYY', 'MM/D/YYYY', 'YYYYMMDD'].reduce((acc, item) => {
    if (acc.isValid()) return acc

    const newAttempt = moment(dateItem, item, true)
    if (newAttempt.isValid()) return newAttempt

    return acc
  }, moment(dateItem, 'MM/DD/YYYY', true))

  // If we are given a 2 digit year it is often in the future, so subtract 100 years
  if (parsedMoment.isAfter(moment())) {
    parsedMoment.subtract(100, 'years')
  }

  return parsedMoment
}

function recordHasDependentColumnValues(record: ScalarDictionaryWithCustom): boolean {
  return (
    !!record.dependentDob || !!record.dependentFirstName || !!record.dependentLastName || !!record.dependentRelationship
  )
}

function handleRecordChange(record: ScalarDictionaryWithCustom): IDataHookResponse {
  const hireDateMoment = generateMonthDayYearMomentInPast(record.currentHireDate as string)
  const terminationDateMoment = generateMonthDayYearMomentInPast(record.currentTerminationDate as string)
  const dobMoment = generateMonthDayYearMomentInPast(record.dob as string)
  const homeState = (record.homeState as string) || ''
  const homeCountry = (record.homeCountry as string) || ''
  const workState = (record.workState as string) || ''
  const workCountry = (record.workCountry as string) || ''
  const relationshipToCompany = record.relationshipToCompany || 'EMPLOYEE'

  const result = {} as IDataHookResponse

  if (dobMoment.isValid()) {
    result.dob = {
      value: dobMoment.format('MM/DD/YYYY') as string,
      info: [],
    }
  } else {
    result.dob = {
      value: record.dob as string,
      info: [
        {
          message: 'Date of birth must be Month/Day/Year. e.g. 05/31/2021',
          level: 'error',
        },
      ],
    }
  }

  if (homeState && homeState.length !== 2) {
    const abbreviation = getStateAbbreviationFromFullName(homeState)
    if (abbreviation) {
      result.homeState = {
        value: abbreviation.toLocaleUpperCase(),
        info: [],
      }
    } else {
      result.homeState = {
        value: homeState.toLocaleUpperCase(),
        info: [
          {
            message: 'Home state must be the states two letter abbreviation',
            level: 'error',
          },
        ],
      }
    }
  }

  if (homeCountry && homeCountry.length !== 2) {
    const abbreviation = getCountryAbbreviationFromFullName(homeCountry)
    if (abbreviation) {
      result.homeCountry = {
        value: abbreviation.toLocaleUpperCase(),
        info: [],
      }
    } else {
      result.homeCountry = {
        value: homeCountry.toLocaleUpperCase(),
        info: [
          {
            message: 'Home country must be the country two letter abbreviation',
            level: 'error',
          },
        ],
      }
    }
  }

  if (relationshipToCompany === 'EMPLOYEE') {
    if (workState && workState.length !== 2) {
      const abbreviation = getStateAbbreviationFromFullName(workState)
      if (abbreviation) {
        result.workState = {
          value: abbreviation.toLocaleUpperCase(),
          info: [],
        }
      } else {
        result.workState = {
          value: workState.toLocaleUpperCase(),
          info: [
            {
              message: 'Work state must be the states two letter abbreviation',
              level: 'error',
            },
          ],
        }
      }
    }

    if (workCountry && workCountry.length !== 2) {
      const abbreviation = getCountryAbbreviationFromFullName(workCountry)
      if (abbreviation) {
        result.workCountry = {
          value: abbreviation.toLocaleUpperCase(),
          info: [],
        }
      } else {
        result.workCountry = {
          value: workCountry.toLocaleUpperCase(),
          info: [
            {
              message: 'Home country must be the country two letter abbreviation',
              level: 'error',
            },
          ],
        }
      }
    }

    if (!record.workEmail) {
      result.workEmail = {
        value: '',
        info: [
          {
            message: 'Work Email is heavily recommended.',
            level: 'warning',
          },
        ],
      }
    }

    if (!record.employmentType) {
      result.employmentType = {
        value: '',
        info: [
          {
            message: 'Employment Type is heavily recommended.',
            level: 'warning',
          },
        ],
      }
    }

    if (!record.currentHireDate || hireDateMoment.isValid()) {
      result.currentHireDate = {
        value: record.currentHireDate ? (hireDateMoment.format('MM/DD/YYYY') as string) : '',
        info: [],
      }
    } else {
      result.currentHireDate = {
        value: record.currentHireDate as string,
        info: [
          {
            message: 'Current hire date must be Month/Day/Year. e.g. 05/31/2021',
            level: 'error',
          },
        ],
      }
    }

    if (!record.currentTerminationDate || terminationDateMoment.isValid()) {
      result.currentTerminationDate = {
        value: record.currentTerminationDate ? (terminationDateMoment.format('MM/DD/YYYY') as string) : '',
        info: [],
      }
    } else {
      result.currentTerminationDate = {
        value: record.currentTerminationDate as string,
        info: [
          {
            message: 'Current termination date must be Month/Day/Year. e.g. 05/31/2021',
            level: 'error',
          },
        ],
      }
    }
  } else {
    if (!record.relatedEmployeeId && !record.subscriberId && !record.relatedEmployeeFirstName) {
      result.relatedEmployeeFirstName = {
        value: record.relatedEmployeeFirstName as string,
        info: [
          {
            message: 'Related Employee First Name is required if no Related Employee Id is provided.',
            level: 'error',
          },
        ],
      }
    }

    if (!record.relatedEmployeeId && !record.subscriberId && !record.relatedEmployeeLastName) {
      result.relatedEmployeeLastName = {
        value: record.relatedEmployeeLastName as string,
        info: [
          {
            message: 'Related Employee Last Name is required if no Related Employee Id is provided.',
            level: 'error',
          },
        ],
      }
    }

    if (!record.relatedEmployeeId && !record.subscriberId && !record.relatedEmployeeDob) {
      result.relatedEmployeeDob = {
        value: record.relatedEmployeeDob as string,
        info: [
          {
            message: 'Related Employee Date of Birth is required if no Related Employee Id is provided.',
            level: 'error',
          },
        ],
      }
    }
  }

  // Check for dependent fields
  if (recordHasDependentColumnValues(record)) {
    const dependentDobMoment = generateMonthDayYearMomentInPast(record.dependentDob as string)

    if (dependentDobMoment.isValid()) {
      result.dependentDob = {
        value: dependentDobMoment.format('MM/DD/YYYY') as string,
        info: [],
      }
    } else {
      result.dependentDob = {
        value: record.dependentDob as string,
        info: [
          {
            message: 'If a dependent is present, date of birth must be Month/Day/Year. e.g. 05/31/2021',
            level: 'error',
          },
        ],
      }
    }
  }

  return result as IDataHookResponse
}

export function RosterUpload({ customerCompany }: RosterUploadProps) {
  const { onLogout, login, loggedIn } = useAuthDataContext()
  const navigate = useNavigate()
  const [historicalRosterDateString, setHistoricalRosterDateString] = useState<string>(
    moment.tz('UTC').format('MM/DD/YYYY'),
  )

  if (!loggedIn) {
    onLogout()
    navigate('/')
    return null
  }

  const { id, name } = customerCompany

  const historicalRosterDate = moment.tz(new Date(historicalRosterDateString), 'UTC')

  const handleHistoricalRosterDateChange: ChangeEventHandler<HTMLInputElement> = e => {
    setHistoricalRosterDateString(e.target.value)
  }

  const isUserAnAdmin = login?.role === Role.Admin
  const companyId = isUserAnAdmin ? id : null
  const userId: string = login?.id as string
  const userName: string = (login?.firstName + ' ' + login?.lastName) as string
  const userEmail: string = login?.email as string
  const destinationCompanyId: string = (login?.role === Role.Admin ? id : login?.companyId) as string

  return (
    <div>
      <StagedRosterInsights customerCompany={customerCompany}>
        <>
          <h1 className="h5">Upload Employee Roster</h1>
          <p className="text-light large-body serif">
            The customer's employee roster will be securely uploaded by our partner, Flatfile.
          </p>
          <p className="mb-4 text-light large-body serif">
            You'll have a chance to review the changes before the final submission.
          </p>
          {isUserAnAdmin && (
            <div className="v-align mb-4">
              <DOBTextField
                label="Roster Upload Date"
                value={historicalRosterDateString}
                placeholder="MM/DD/YYYY"
                fullWidth
                name="dob"
                onChange={handleHistoricalRosterDateChange}
                helperText="Date of the roster, which must be after the last uploaded roster"
                error={!historicalRosterDate.isValid()}
              />
            </div>
          )}
          <div className="flex flex-row v-align mt-2 flex-end">
            <RosterUploadButton
              companyId={companyId}
              userId={userId}
              userName={userName}
              userEmail={userEmail}
              destinationCompanyId={destinationCompanyId}
              companyName={name}
              historicalRosterDate={historicalRosterDate}
            />
          </div>
        </>
      </StagedRosterInsights>
    </div>
  )
}

function handleUploadCancel() {
  toast.caution('Cancelled upload.')
}

interface RosterUploadButtonProps {
  companyId: string | null
  userId: string
  userName: string
  userEmail: string
  destinationCompanyId: string
  companyName: string
  historicalRosterDate?: Moment | null | undefined
}

const RosterUploadButton: React.FunctionComponent<RosterUploadButtonProps> = ({
  companyId,
  userId,
  userName,
  userEmail,
  destinationCompanyId,
  companyName,
  historicalRosterDate,
}) => {
  const { production } = useEnvironmentContext()

  const [stageRosterUpload] = useStageRosterUploadMutation({
    refetchQueries: ['CustomerCompany', 'StagedRosterInsights'],
  })
  const { loading, data } = useCustomerEmployeeDimensionsQuery({
    variables: { companyId },
  })
  const companyDimensions = (data?.customerEmployeeDimensions as CustomerEmployeeDimension[]) || []

  async function handleOnUpload(results: FlatfileResults): Promise<Error | string> {
    toast.caution('Uploading roster. Please do not close the window')

    // clean out relationship on "Not Applicable" entries
    const employeeData = results.validData.map(r => {
      if (r.dependentRelationship === '') {
        return omit(r, ['dependentRelationship'])
      }
      return r
    })

    try {
      const uploadResult = await stageRosterUpload({
        variables: {
          date: !historicalRosterDate ? null : historicalRosterDate.toDate(),
          employeeRoster: {
            companyId,
            employeeData,
          },
        },
      })
      if (uploadResult && uploadResult.errors && uploadResult.errors?.length > 0) {
        throw uploadResult.errors[0]
      }

      return `Staged records successfully!`
    } catch (e) {
      toast.urgent('Failed to stage roster. Please try again or contact support.')
      return e as Error
    }
  }

  return loading ? (
    <SpinningLoader $inFlow size={24} />
  ) : (
    <FlatfileButton
      licenseKey="0c0e9ee0-ef40-4e84-ba05-327f1fa815c1"
      customer={{
        companyId: destinationCompanyId,
        companyName: companyName,
        email: userEmail,
        name: userName,
        userId,
      }}
      settings={generateFlatfileSettingsForUser(companyDimensions, companyName, production)}
      {...generateHooks()}
      onCancel={handleUploadCancel}
      onData={async (results: FlatfileResults) => {
        const result = await handleOnUpload(results)
        if (result instanceof Error) throw result
        return result
      }}
      render={(importer: FlatfileImporter | undefined, launch: () => void) => {
        importer?.registerVirtualRecordHook(handleVirtualFieldCreation)
        importer?.registerStepHook('review', ({ headers_matched }) => {
          if (!headers_matched?.find(header => header.matched_key === 'employmentStatus')) {
            importer.addVirtualField({
              label: 'Employment Status',
              key: 'employmentStatus',
              type: 'select',
              description: 'Only rows marked as ACTIVE will be eligible to use Tava Health.',
              options: [
                { value: 'ACTIVE', label: 'Active' },
                { value: 'INACTIVE', label: 'Inactive' },
              ],
              validators: [{ validate: 'required' }],
            })
          }
          if (!headers_matched?.find(header => header.matched_key === 'ancillaryEmployeeId')) {
            importer.addVirtualField({
              label: 'Employee Id',
              key: 'ancillaryEmployeeId',
              validators: [{ validate: 'unique' }],
            })
          }
          if (!headers_matched?.find(header => header.matched_key === 'relationshipToCompany')) {
            importer.addVirtualField({
              label: 'Relationship to Company',
              key: 'relationshipToCompany',
              type: 'select',
              options: [
                { value: 'EMPLOYEE', label: 'Employee' },
                { value: 'SPOUSE_PARTNER', label: 'Spouse or Domestic Partner' },
                { value: 'CHILD', label: 'Child' },
              ],
            })
          }

          return true
        })
        return (
          <FillButton className="m-0" onClick={launch} disabled={!historicalRosterDate?.isValid()}>
            Upload Roster
          </FillButton>
        )
      }}
    ></FlatfileButton>
  )
}
