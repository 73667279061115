export const popCultureLoadingMessages = [
  'Simply walking into Mordor',
  'Warping through hyperspace',
  'Crossing the Rainbow Bridge to Asgard',
  'Engaging warp drive',
  'Making the Kessel Run in less than twelve parsecs',
  'Assembling the Avengers',
  'Waiting for Gandalf to open the gate',
  'Hacking the mainframe',
  'Summoning the dragon',
  'Dialing the Stargate',
  'Consulting the Oracle',
  'Preparing the DeLorean for time travel',
  'Tuning into the Upside Down',
  'Loading the Matrix',
  'Fetching the Infinity Stones',
  'Activating the Bat-Signal',
  'Calling in a favor from John Wick',
  'Bending the elements',
  'Charging the flux capacitor',
  'Summoning Godzilla',
  'Brewing potions',
  'Waiting for the Sorting Hat',
  'Constructing additional pylons',
  'Firing up the proton pack',
  'Setting phasers to stun',
  'Unlocking the Chamber of Secrets',
  "Plotting a heist with Ocean's Eleven",
  'Raising the Black Pearl',
  'Disturbing the Force',
  'Consulting with Sherlock',
  "Loading Please don't turn off your Game Boy.",
  'Sneaking through Hogwarts',
  'Using the invisibility cloak',
  'Convincing the council of Elrond',
  'Waiting for Spock to analyze',
  'Training with Yoda',
  'Catching Pokémon',
  'Building a lightsaber',
  'Hunting Horcruxes',
  'Waiting for the TARDIS to land',
  'Summoning a Patronus',
  'Traveling to Wakanda',
  'Climbing Mount Doom',
  'Avoiding the Daleks',
  'Plotting with Tyrion Lannister',
  'Decrypting the Enigma',
  'Looking for the Dragon Balls',
  'Finding Dory',
  'Joining the Fellowship',
  'Waiting for Groot to say something',
  'Compiling Reticulated Splines',
  'Finding the One Ring',
  'Loading the Holodeck',
  'Browsing the Oasis',
  'Loading the Batmobile',
  'Finding the Tesseract',
  'Constructing another Death Star',
  'Stealing the Millennium Falcon',
  'Catching the Snitch',
]

export const genericLoadingMessages = [
  'Loading',
  'Calculating',
  'Almost there',
  'AI algorithms fine tuning',
  'Just a moment',
  'Hurry up and wait',
  'Beep boop beep',
  'Just a sec',
  'Using advanced algorithms',
  'Optimizing AI algorithms',
]

export const navigationalLoadingMessages = [
  'Navigating',
  'Fetching directions',
  'Finding the best route',
  'Checking traffic',
  'Loading Maps',
  'Getting directions',
  'Loading directions',
  'Loading Google Maps',
  'Charting the course',
  'Loading route',
  'Plotting the course',
  'Calculating the best route',
  'Putting the pedal to the metal',
  'Calculating route',
  'There and back again',
]

export const allNonSpecificLoadingMessages = [...genericLoadingMessages, ...popCultureLoadingMessages]
