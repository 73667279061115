import * as Yup from 'yup'
import { Field, Formik, FormikErrors, FormikTouched } from 'formik'
import { AnimatePresence, motion } from 'framer-motion'
import { noop, some } from 'lodash'
import moment from 'moment-timezone'
import { ChangeEvent, FormEvent, MouseEventHandler, useState } from 'react'
import { useNavigate } from 'react-router-dom'

import {
  AccountSource,
  AccountSourceChannel,
  PublicProviderFragment,
  StateAbbreviation,
  usePatientSignupWithSignupInfoMutation,
} from '@nuna/api'
import { ErrorWithLink, errorService, formService, patientService, routeService, signupService } from '@nuna/core'
import { useEnvironmentContext } from '@nuna/environment'
import {
  Checkbox,
  ContextualAlert,
  DOBTextField,
  Grid,
  IconLightning,
  StateAutocompleteSingle,
  TextButton,
  TextButtonExternalLink,
  TextField,
  toast,
} from '@nuna/tunic'

import { type AuthData, useAuthDataContext } from '../../context/AuthDataContext'
import { useSignupPrevalidation } from '../../hooks/useSignupPrevalidation'
import { EmailVerificationField } from '../EmailVerificationField'
import { PasswordValidator } from '../PasswordValidator'
import { PrevalidationErrors } from './PrevalidationErrors'

const { transformGraphQlError } = errorService
const { oopsRequired, composeHelperTextWithError } = formService
const { agreesToTermsSchema, passwordSchema, confirmPasswordSchema } = signupService
const { isQualifiedMinor } = patientService

interface CreateAccountFormValues {
  firstName: string
  lastName: string
  dob: string
  email: string
  parentGuardianEmail?: string
  state: StateAbbreviation | null
  password: string
  confirmPassword: string
  agreesToTerms: boolean
  verifyEmail: string
}

const initialValues: CreateAccountFormValues = {
  firstName: '',
  lastName: '',
  dob: '',
  email: '',
  parentGuardianEmail: '',
  state: null,
  password: '',
  confirmPassword: '',
  agreesToTerms: false,
  verifyEmail: '',
}

interface CreateAccountFormProps {
  onLoginClick: MouseEventHandler<HTMLButtonElement>
  renderButtons: (isLoading: boolean) => JSX.Element
  provider?: PublicProviderFragment
  onSuccess?: (loginData: AuthData) => void
  source?: AccountSource
  sourceChannel?: AccountSourceChannel
}

export function CreateAccountForm({
  renderButtons,
  onLoginClick,
  provider,
  source,
  sourceChannel,
  onSuccess = noop,
}: CreateAccountFormProps) {
  const { onLogin } = useAuthDataContext()
  const navigate = useNavigate()
  const [
    prevalidatePatientSignup,
    { undismissedErrors: prevalidationErrors, dismissError },
    { loading: prevalidationLoading },
  ] = useSignupPrevalidation()
  const [signupWithSignupInfo, { loading: signupLoading }] = usePatientSignupWithSignupInfoMutation()
  const { ARROW_BASE_URL } = useEnvironmentContext()

  const [showEmailVerification, setShowEmailVerification] = useState(false)
  const [isPasswordFocused, setIsPasswordFocused] = useState(false)
  const [isConfirmPasswordFocused, setIsConfirmPasswordFocused] = useState(false)

  const handleSubmit = async (values: CreateAccountFormValues) => {
    try {
      const response = await signupWithSignupInfo({
        variables: {
          signupInfo: {
            dob: values.dob,
            email: values.email,
            parentGuardianEmail: values.parentGuardianEmail,
            firstName: values.firstName,
            lastName: values.lastName,
            state: values.state,
            password: values.password,
            source: source,
            sourceChannel,
            sourceProviderId: provider?.id,
            timezone: moment.tz.guess(),
          },
        },
      })

      const loginData = response.data?.patientSignupWith

      if (loginData) {
        onLogin(loginData)
        onSuccess(loginData)
      } else {
        throw new Error('Request succeeded but the patient was not created')
      }
    } catch (e) {
      toast.urgent(transformGraphQlError(e, 'Error completing signup. Please try again or contact support.'))
    }
  }

  const isMissingInformation = (
    errors: FormikErrors<CreateAccountFormValues> | ErrorWithLink[],
    touched: FormikTouched<CreateAccountFormValues>,
  ) => {
    const errorKeys = Object.keys(errors).filter(key => key !== 'verifyEmail')
    const touchedKeys = Object.keys(touched)

    return some(errorKeys, error => touchedKeys.includes(error))
  }

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={handleSubmit}
      validationSchema={buildSchema(ARROW_BASE_URL, provider)}
    >
      {({ handleSubmit, handleBlur, errors, touched, values, setFieldValue, setFieldTouched, handleChange }) => (
        <form
          onSubmit={async (e: FormEvent<HTMLFormElement>) => {
            e.preventDefault()

            if (Object.keys(errors).length === 1 && errors.verifyEmail && !showEmailVerification) {
              const response = await prevalidatePatientSignup(values.email)
              if (response.valid) {
                setShowEmailVerification(true)
              }
              return
            }

            if (showEmailVerification && values.email.toLowerCase() !== values.verifyEmail.toLowerCase()) {
              return
            }

            handleSubmit(e)
          }}
        >
          {showEmailVerification ? (
            <div>
              <h3 className="body text-medium">Let's verify we got that right.</h3>
              <p className="mb-4 text-secondary text-light">Please retype your email address.</p>

              <EmailVerificationField
                error={!!errors.verifyEmail}
                existingEmail={values.email}
                helperText={errors.verifyEmail ?? ''}
                name="verifyEmail"
                onChange={(e: ChangeEvent<HTMLInputElement>) => {
                  prevalidationErrors.forEach(dismissError)
                  handleChange(e)
                }}
                onLoginClick={() => navigate(routeService.login())}
                onUpdateEmailClick={async () => {
                  const response = await prevalidatePatientSignup(values.verifyEmail)

                  if (response.valid) {
                    setFieldValue('email', values.verifyEmail)
                  }
                }}
                touched={!!touched.verifyEmail}
                typedEmail={values.verifyEmail}
                onBlur={handleBlur}
                showUpdateError={false}
                isLoading={false}
              />

              <PrevalidationErrors prevalidationErrors={prevalidationErrors} onLoginClick={onLoginClick} />
            </div>
          ) : (
            <Grid container spacing={4}>
              <Grid size={12}>
                <h3 className="body text-medium">Create your account</h3>
                <p className="mb-0 text-secondary text-light">
                  Already have an account?{' '}
                  <TextButton variant="secondary" onClick={onLoginClick}>
                    Sign in
                  </TextButton>
                </p>
              </Grid>
              <Grid size={6}>
                <Field
                  as={TextField}
                  name="firstName"
                  label="First name"
                  {...composeHelperTextWithError('', errors.firstName, touched.firstName)}
                  inputProps={{ 'data-testid': 'signup-first-name' }}
                />
              </Grid>
              <Grid size={6}>
                <Field
                  as={TextField}
                  name="lastName"
                  label="Last name"
                  {...composeHelperTextWithError('', errors.lastName, touched.lastName)}
                  inputProps={{ 'data-testid': 'signup-last-name' }}
                />
              </Grid>
              <Grid size={12}>
                <Field
                  as={DOBTextField}
                  name="dob"
                  label="Date of birth"
                  error={touched.dob && !!errors.dob}
                  helperText={touched.dob ? errors.dob ?? '' : ''}
                  inputProps={{ 'data-testid': 'signup-dob' }}
                />
                <AnimatePresence>
                  {isQualifiedMinor(values.dob) && (
                    <motion.div initial={{ opacity: 0 }} animate={{ opacity: 1 }} exit={{ opacity: 0 }}>
                      <ContextualAlert className="mt-2 mb-1" icon={<IconLightning />}>
                        We’ll need your parent or guardian’s consent to get you set up with a therapist. We’ll email
                        them everything they need to know.
                      </ContextualAlert>
                      <Field
                        as={TextField}
                        name="parentGuardianEmail"
                        label="Parent or Guardian's Email"
                        {...composeHelperTextWithError('', errors.parentGuardianEmail, touched.parentGuardianEmail)}
                        inputProps={{ 'data-testid': 'signup-parent-guardian-email' }}
                      />
                    </motion.div>
                  )}
                </AnimatePresence>
              </Grid>
              <Grid size={12}>
                <Field
                  as={TextField}
                  name="email"
                  label="Email"
                  {...composeHelperTextWithError('', errors.email, touched.email)}
                  inputProps={{ 'data-testid': 'signup-email' }}
                  onChange={(e: ChangeEvent<HTMLInputElement>) => {
                    prevalidationErrors.forEach(dismissError)
                    handleChange(e)
                  }}
                />
                <PrevalidationErrors prevalidationErrors={prevalidationErrors} onLoginClick={onLoginClick} />
              </Grid>
              <Grid size={12}>
                <StateAutocompleteSingle
                  value={values.state}
                  onChange={state => {
                    setFieldTouched('state')
                    setFieldValue('state', state?.value ?? '')
                  }}
                  {...composeHelperTextWithError('', errors.state, touched.state)}
                />
              </Grid>
              <Grid size={12}>
                <Field
                  as={TextField}
                  name="password"
                  label="Password"
                  type="password"
                  onFocus={() => setIsPasswordFocused(true)}
                  onBlur={(e: FormEvent<HTMLInputElement>) => {
                    setIsPasswordFocused(false)
                    handleBlur(e)
                  }}
                  {...composeHelperTextWithError('', errors.password, touched.password)}
                  inputProps={{ 'data-testid': 'signup-password' }}
                />
                <PasswordValidator
                  inDrawer
                  wrapperPadding="16px 4px 0 4px"
                  password={values.password}
                  isExpanded={isPasswordFocused}
                  error={(errors.password && touched.password) as boolean}
                />
              </Grid>
              <Grid size={12}>
                <Field
                  as={TextField}
                  name="confirmPassword"
                  label="Confirm password"
                  type="password"
                  onFocus={() => setIsConfirmPasswordFocused(true)}
                  onBlur={(e: FormEvent<HTMLInputElement>) => {
                    setIsConfirmPasswordFocused(false)
                    handleBlur(e)
                  }}
                  {...composeHelperTextWithError('', errors.confirmPassword, touched.confirmPassword)}
                  inputProps={{ 'data-testid': 'signup-confirm-password' }}
                />
                <PasswordValidator
                  inDrawer
                  confirm
                  wrapperPadding="16px 4px 0 4px"
                  matchingPassword={values.password}
                  password={values.confirmPassword}
                  isExpanded={isConfirmPasswordFocused}
                  error={(errors.confirmPassword && touched.confirmPassword) as boolean}
                />
              </Grid>
              <Grid size={12}>
                <Checkbox
                  className="mt-3"
                  error={(errors.agreesToTerms && touched.agreesToTerms) as boolean}
                  checked={values.agreesToTerms}
                  name="agreesToTerms"
                  onChange={handleChange}
                  labelProps={{
                    'data-testid': 'signup-tos',
                  }}
                >
                  <span>
                    I agree to the{' '}
                    <TextButtonExternalLink href="https://www.tavahealth.com/consent-to-care">
                      Consent to Care
                    </TextButtonExternalLink>{' '}
                    &amp;{' '}
                    <TextButtonExternalLink href="https://www.tavahealth.com/notice-of-privacy-practices">
                      Notice of Privacy Practices
                    </TextButtonExternalLink>
                  </span>
                </Checkbox>
              </Grid>
            </Grid>
          )}

          {renderButtons(prevalidationLoading || signupLoading)}

          {isMissingInformation(errors, touched) && (
            <div className="mt-4">
              <ContextualAlert intent="urgent">Some information is missing above</ContextualAlert>
            </div>
          )}
        </form>
      )}
    </Formik>
  )
}

function buildSchema(arrowBaseUrl: string, provider: PublicProviderFragment | undefined) {
  return Yup.object().shape({
    firstName: Yup.string().required('First name is required'),
    lastName: Yup.string().required('Last name is required'),
    dob: Yup.date()
      .required('Date of birth is required')
      .min(moment().subtract('125', 'years').toDate(), 'Date of birth is out of range')
      .max(moment().subtract('13', 'years').toDate(), () => <span>Sorry, therapy is only available for ages 13+</span>)
      .typeError('Date is invalid'),
    email: Yup.string().required('Email is required').email("Oops, that email isn't formatted correctly"),
    parentGuardianEmail: Yup.string()
      .email('Invalid email format')
      .when('dob', {
        is: dob => isQualifiedMinor(dob),
        then: Yup.string().required('Parent/Guardian email is required for minors'),
        otherwise: Yup.string().notRequired(),
      }),
    state: provider
      ? Yup.string()
          .nullable()
          .required(oopsRequired('state'))
          .test(
            'credentialed-in-state',
            () => ({
              message: `Sorry, ${provider.firstName} isn't licensed in your state. `,
              link: (
                <a href={arrowBaseUrl} target="_blank" rel="noreferrer">
                  Find a provider in your state.
                </a>
              ),
            }),
            value => provider.credentialedInStates.includes(value),
          )
      : Yup.string().nullable().required(oopsRequired('state')),
    password: passwordSchema,
    confirmPassword: confirmPasswordSchema,
    agreesToTerms: agreesToTermsSchema,
    verifyEmail: Yup.string()
      .email("Oops, that email isn't formatted correctly")
      .required('Please verify your email address'),
  })
}
