import moment from 'moment-timezone'
import { ReactNode, createContext, useCallback, useContext, useState } from 'react'

import {
  AvailabilityConstraintInput,
  ProviderAvailabilityFragment,
  useDeleteProviderAvailabilityConstraintsMutation,
  useSaveProviderAvailabilityMutation,
  useUpdateProviderTimezoneMutation,
} from '@nuna/api'
import { toast } from '@nuna/tunic'

interface ContextValue {
  deleteConstraints: (idsToDelete: string[]) => Promise<void>
  updateConstraints: (newConstraints: AvailabilityConstraintInput[]) => Promise<void>
  updateTimezone: (newTimezone: string) => Promise<void>
  updateLoading: boolean
  providerHasTimezoneSet: boolean
  timezone: string
}

const AvailabilityContext = createContext<ContextValue>(buildDefaultContextValue())

interface Props {
  children: ReactNode
  provider: ProviderAvailabilityFragment
  refetchQueries: string[]
}
export function AvailabilityContextProvider({ children, provider, refetchQueries }: Props) {
  const [providerHasTimezoneSet, setProviderHasTimezoneSet] = useState(!!provider.timezone)
  const [timezone, setTimezone] = useState(provider.timezone ? provider.timezone : moment.tz.guess())
  const [saveProviderAvailability, { loading: constraintsUpdating }] = useSaveProviderAvailabilityMutation()
  const [deleteProviderAvailabilityConstraints, { loading: constraintsDeleting }] =
    useDeleteProviderAvailabilityConstraintsMutation()

  const [updateTimezoneMutation, { loading: timezoneUpdateLoading }] = useUpdateProviderTimezoneMutation()

  const updateLoading = constraintsUpdating || constraintsDeleting || timezoneUpdateLoading

  const updateTimezone = useCallback(
    async (newTimezone: string) => {
      try {
        await updateTimezoneMutation({
          variables: {
            id: provider.id,
            timezone: newTimezone,
          },
        })
        setProviderHasTimezoneSet(true)
        setTimezone(newTimezone)
        toast.success('Your timezone has been updated')
      } catch (e) {
        toast.urgent('There was an error updating your timezone. Try again later.')
      }
    },
    [provider.id, updateTimezoneMutation],
  )

  const doConstraintUpdate = useCallback(
    async (updateFn: () => Promise<void>) => {
      try {
        if (!providerHasTimezoneSet) {
          // If the provider has not set a timezone and elects not to change from the moment detected timezone
          // then go ahead and set it for them before updating constraints
          await updateTimezone(timezone)
        }

        await updateFn()

        toast.success('Your availability has been updated')
      } catch (e) {
        toast.urgent('There was an error updating your availability. Try again later.')
      }
    },
    [providerHasTimezoneSet, updateTimezone, timezone],
  )

  const updateConstraints = useCallback(
    async (newConstraints: AvailabilityConstraintInput[]) =>
      doConstraintUpdate(async () => {
        await saveProviderAvailability({
          variables: {
            providerId: provider.id,
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            constraints: newConstraints.filter(constraint => !(constraint as any).calendar_block_period),
          },
          refetchQueries,
        })
      }),
    [provider.id, refetchQueries, saveProviderAvailability, doConstraintUpdate],
  )

  const deleteConstraints = useCallback(
    async (idsToDelete: string[]) =>
      doConstraintUpdate(async () => {
        await deleteProviderAvailabilityConstraints({
          variables: {
            providerId: provider.id,
            constraintIds: idsToDelete,
          },
          refetchQueries,
        })
      }),
    [provider.id, refetchQueries, deleteProviderAvailabilityConstraints, doConstraintUpdate],
  )

  const value: ContextValue = {
    deleteConstraints,
    providerHasTimezoneSet,
    updateConstraints,
    updateTimezone,
    updateLoading,
    timezone,
  }

  return <AvailabilityContext.Provider value={value}>{children}</AvailabilityContext.Provider>
}

export function useAvailabilityContext() {
  return useContext(AvailabilityContext)
}

function buildDefaultContextValue(): ContextValue {
  return {
    deleteConstraints: () => Promise.resolve(),
    providerHasTimezoneSet: true,
    updateConstraints: () => Promise.resolve(),
    updateTimezone: () => Promise.resolve(),
    updateLoading: false,
    timezone: '',
  }
}
