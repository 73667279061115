import daily from '@daily-co/daily-js'
import { DailyCallOptions, DailyInputSettings } from '@daily-co/daily-js'

import { localStorageService } from '@nuna/core'

import tavaBg1 from '../img/tava-virtual-bg-1.jpg'
import tavaBg2 from '../img/tava-virtual-bg-2.jpg'
import tavaBg3 from '../img/tava-virtual-bg-3.jpg'
import tavaBg4 from '../img/tava-virtual-bg-4.jpg'
import { DevicePreferences, PersistedAudioDevicePreferences, PersistedVideoDevicePreferences } from '../types'
import { supportsAudioProcessing } from './browserSupport'

const { supportsVideoProcessing } = daily.supportedBrowser()

export const DEVICE_PREFERENCES_LOCAL_STORAGE_KEY = 'tavaVideoPreferences'

export const blurStrengths = [
  { value: 0.5, label: 'Low' },
  { value: 1, label: 'High' },
]

// Daily needs a full url and Vite just gives us a relative path
export const bgImages = [tavaBg1, tavaBg2, tavaBg3, tavaBg4].map(img => new URL(img, import.meta.url).href)

const defaultPreferences: DevicePreferences = {
  videoDeviceId: undefined,
  audioDeviceId: 'default',
  audioOutputDeviceId: 'default',
  isVideoEnabled: true,
  isAudioEnabled: true,
  isNoiseCancelationEnabled: supportsAudioProcessing,
  videoProcessorType: 'none',
  bgBlurStrength: 0.5,
  bgImageIndex: 0,
}

// this just represents what is currently stored in local storage it can live at the top-level and is essentially an app-wide singleton.
// Will be slightly more efficient to keep in memory than to read from local storage every time.
// It is not meant to represent UI state, but rather for initializing the camera/mic when the call starts.
// Once the call starts, the state about what device is being used will be available via Daily. This just represents what was saved as their preference.
// Treat this as a private variable and only use the getter and setter functions to interact with it
let devicePreferences = updatePreferenceSchema({
  ...defaultPreferences, // spread default in so that if a new preference is added and the user does not have it in their local storage it will be present
  ...localStorageService.getData(DEVICE_PREFERENCES_LOCAL_STORAGE_KEY, defaultPreferences),
})

export function getDevicePreferences() {
  const isNoiseCancelationEnabled = supportsAudioProcessing ? devicePreferences.isNoiseCancelationEnabled : false
  const videoProcessorType = supportsVideoProcessing ? devicePreferences.videoProcessorType : 'none'

  return { ...devicePreferences, isNoiseCancelationEnabled, videoProcessorType }
}

export function saveDevicePreferences(updatedPreferences: Partial<DevicePreferences>) {
  devicePreferences = { ...devicePreferences, ...updatedPreferences }
  localStorageService.writeData(DEVICE_PREFERENCES_LOCAL_STORAGE_KEY, devicePreferences)
}

export function getInputSettingsFromDevicePreferences(
  preferences: PersistedVideoDevicePreferences | PersistedAudioDevicePreferences,
): Partial<DailyInputSettings> {
  if ('isNoiseCancelationEnabled' in preferences) {
    return {
      audio: {
        processor: {
          type: preferences.isNoiseCancelationEnabled ? 'noise-cancellation' : 'none',
        },
      },
    }
  }

  if ('videoProcessorType' in preferences) {
    switch (preferences.videoProcessorType) {
      case 'background-blur':
        return { video: { processor: { type: 'background-blur', config: { strength: preferences.bgBlurStrength } } } }
      case 'background-image':
        return {
          video: { processor: { type: 'background-image', config: { source: bgImages[preferences.bgImageIndex] } } },
        }
      default:
        return { video: { processor: { type: 'none' } } }
    }
  }

  return {}
}

// converts the device preferences into the format that can be passed straight to Daily's functions
// if startVideoOff and startAudioOff are both true then the browser permission prompt won't appear and the user will get stuck.
// when starting the camera with preferences from the Give Access button, use forceDeviceOn makes sure the browser prompt appears
export function getDailyDevicePreferences({ forceDeviceOn = false } = {}): Partial<DailyCallOptions> {
  const preferences = getDevicePreferences()

  return {
    startVideoOff: forceDeviceOn ? false : !preferences.isVideoEnabled,
    startAudioOff: forceDeviceOn ? false : !preferences.isAudioEnabled,
    videoSource: preferences.videoDeviceId,
    audioSource: preferences.audioDeviceId,
    inputSettings: {
      ...getInputSettingsFromDevicePreferences({
        isNoiseCancelationEnabled: preferences.isNoiseCancelationEnabled,
      }),
      ...getInputSettingsFromDevicePreferences({
        videoProcessorType: preferences.videoProcessorType,
        bgBlurStrength: preferences.bgBlurStrength,
        bgImageIndex: preferences.bgImageIndex,
      }),
    },
  }
}

function extractNumberFromUrl(url: string): number {
  const regex = /tava-virtual-bg-(\d+)/
  const match = url.match(regex)
  return match ? parseInt(match[1], 10) - 1 : 0
}

// Should only be necessary for a temporary time
function updatePreferenceSchema(preferences: DevicePreferences & { bgImage?: string }) {
  if (preferences.bgImage) {
    preferences.bgImageIndex = extractNumberFromUrl(preferences.bgImage)
    delete preferences.bgImage
    localStorageService.writeData(DEVICE_PREFERENCES_LOCAL_STORAGE_KEY, preferences)
  }

  // default is not a valid option for video like it is for audio and it will cause an error. If it's undefined, Daily will find the first available camera.
  if (preferences.videoDeviceId === 'default') {
    preferences.videoDeviceId = undefined
    localStorageService.writeData(DEVICE_PREFERENCES_LOCAL_STORAGE_KEY, preferences)
  }

  return preferences
}
