import { useEffect, useState } from 'react'

/*
 * This hook is used to delay the loading indicator to prevent changing the UI too quickly
 * @param isLoading - boolean value to determine if the loading indicator should be shown
 * @param delay - delay in milliseconds before the loading indicator is shown
 */
export function useDelayedLoading(isLoading: boolean, delay = 1000) {
  const [showLoading, setShowLoading] = useState(isLoading)

  useEffect(() => {
    let timer: NodeJS.Timeout

    if (isLoading) {
      setShowLoading(true)
    } else {
      timer = setTimeout(() => setShowLoading(false), delay)
    }

    return () => clearTimeout(timer)
  }, [isLoading, delay])

  return showLoading
}
