import { isNil, sortBy } from 'lodash'
import { useMemo } from 'react'

import { BasicSpecialtyFragment, useSpecialtiesQuery } from '@nuna/api'
import { Chip, ChipGroup, ChipGroupProps } from '@nuna/tunic'

interface Props extends Omit<ChipGroupProps, 'onChange'> {
  specialtyFilter: (specialties: BasicSpecialtyFragment[]) => BasicSpecialtyFragment[]
  value: BasicSpecialtyFragment[]
  onChange: (specialties: BasicSpecialtyFragment[]) => void
  dataTestId: string
}

export function SpecialtiesChipsSelect({ specialtyFilter, value, dataTestId, onChange, onBlur, ...props }: Props) {
  const { data } = useSpecialtiesQuery()
  const availableSpecialties = useMemo(() => {
    const specialties = specialtyFilter(data?.specialties ?? [])
    if (specialties[0] && !isNil(specialties[0].rankInCategory)) {
      return sortBy(specialties, s => s.rankInCategory)
    }
    return specialties
  }, [data, specialtyFilter])

  const valueIds = useMemo(() => value.map(v => v.id), [value])

  const handleChipClick = (specialty: BasicSpecialtyFragment) => {
    onChange(valueIds.includes(specialty.id) ? value.filter(v => v.id !== specialty.id) : [...value, specialty])
  }

  return (
    <ChipGroup style={{ position: 'relative' }} {...props}>
      {availableSpecialties.map((specialty, idx) => (
        <Chip
          dataTestId={`${dataTestId}-${idx}`}
          key={specialty.id}
          onBlur={onBlur}
          onClick={() => handleChipClick(specialty)}
          checked={valueIds.includes(specialty.id)}
        >
          {specialty.name}
        </Chip>
      ))}
    </ChipGroup>
  )
}
