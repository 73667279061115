import { BasicSpecialtyFragment, Patient } from '@nuna/api'
import { ReadOnlyProfileSection } from '@nuna/common'
import { carePreferencesService } from '@nuna/core'
import { Chip, ChipGroup, ContextualAlert } from '@nuna/tunic'

import { SPECIALTY_CATEGORY_CHALLENGES } from '../../constants'

const { parseTypeformIntake } = carePreferencesService

interface ClientProfileConcernsChallengesProps {
  patient:
    | {
        specialties: BasicSpecialtyFragment[]
        typeformIntakeBlob?: Patient['typeformIntakeBlob']
      }
    | undefined
}

const heading = 'Concerns or Challenges'

export function ClientProfileConcernsChallenges({ patient }: ClientProfileConcernsChallengesProps) {
  if (!patient) {
    return <ReadOnlyProfileSection isLoading heading={heading} renderDisplayValue={null} />
  }

  const nativeChallenges = (patient.specialties || []).filter(
    specialty => specialty.specialtyCategory.name === SPECIALTY_CATEGORY_CHALLENGES,
  )

  const { contributingFactors } = parseTypeformIntake(patient.typeformIntakeBlob)
  const fallbackChallenges = contributingFactors === 'N/A' ? [] : contributingFactors.split(', ')

  const challenges = nativeChallenges.length > 0 ? nativeChallenges : fallbackChallenges.map(c => ({ name: c }))

  return (
    <ReadOnlyProfileSection
      heading={heading}
      renderDisplayValue={
        challenges.length > 0 ? (
          <ChipGroup>
            {challenges.map(challenge => (
              <Chip key={challenge.name} disabled small checked>
                {challenge.name}
              </Chip>
            ))}
          </ChipGroup>
        ) : (
          <ContextualAlert role="status" showScribble largeText>
            No info provided
          </ContextualAlert>
        )
      }
    />
  )
}
