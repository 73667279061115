import { styled } from '@mui/material'
import { groupBy, range } from 'lodash'
import moment from 'moment'
import { useParams } from 'react-router-dom'

import { HistoryItemType, usePatientHistoryQuery } from '@nuna/api'
import { ContextualAlert, IconCalendarNone, toast } from '@nuna/tunic'

import {
  CanceledSessionItem,
  CompletedSessionItem,
  HistoryNoteSessionItem,
  MilestoneSessionItem,
  NoShowSessionItem,
  SessionItemForm,
  SessionItemSkeleton,
  UploadSessionItem,
} from '../SessionItem'
import { ClientHistoryItemForm } from './components/ClientHistoryItemForm'

type RouteProps = {
  id: string
}

interface PatientHistoryProps {
  isAddingNoteState: [boolean, (value: boolean) => void]
}

export function ClientHistory({ isAddingNoteState }: PatientHistoryProps) {
  const { id = '' } = useParams<RouteProps>()
  const [isAddingNote, setIsAddingNote] = isAddingNoteState

  const { loading, error, data } = usePatientHistoryQuery({
    variables: {
      patientId: id,
    },
    fetchPolicy: 'cache-and-network',
  })

  if (error) {
    toast.urgent('Error occurred loading session history')
  }

  const patientHistory = data?.patientHistory ?? []

  const groupedPatientHistory = groupBy(patientHistory, item => moment(item.date).format('YYYY-MM-DD'))
  const sortedGroupedPatientHistoryDates = Object.keys(groupedPatientHistory).sort().reverse()

  return (
    <HistoryContent>
      {isAddingNote && (
        <SessionItemForm id="session-history-item-form">
          <ClientHistoryItemForm
            patientId={id}
            onSuccess={() => setIsAddingNote(false)}
            onCancel={() => setIsAddingNote(false)}
          />
        </SessionItemForm>
      )}

      {patientHistory.length === 0 && !loading && (
        <ContextualAlert largeText>
          <IconCalendarNone /> No History
        </ContextualAlert>
      )}

      {patientHistory.length === 0 && loading && range(4).map(key => <SessionItemSkeleton showDateOutside key={key} />)}

      {sortedGroupedPatientHistoryDates.map(date => {
        const dateHistoryItems = groupedPatientHistory[date]

        return dateHistoryItems.map((historyItem, index) => {
          const key = `${historyItem.date}-${historyItem.type}`
          const sessionItemProps = {
            showDate: index === 0,
            historyItem,
          }

          switch (historyItem.type) {
            case HistoryItemType.HistoryCompletedSession:
              return <CompletedSessionItem {...sessionItemProps} key={key} />
            case HistoryItemType.HistoryAppointmentRescheduled:
              return <CanceledSessionItem {...sessionItemProps} key={key} />
            case HistoryItemType.HistoryAppointmentCancel:
              return <CanceledSessionItem {...sessionItemProps} key={key} />
            case HistoryItemType.HistoryAppointmentNoShow:
            case HistoryItemType.HistoryAppointmentUndocumented:
              return <NoShowSessionItem {...sessionItemProps} key={key} />
            case HistoryItemType.HistoryTavaMilestone:
              return <MilestoneSessionItem {...sessionItemProps} key={key} />
            case HistoryItemType.HistoryClinicalConsultationMeta:
            case HistoryItemType.HistoryReferralMeta:
            case HistoryItemType.HistoryCareCoordinationMeta:
            case HistoryItemType.HistoryRemoveCaseloadMeta:
            case HistoryItemType.HistoryAppointmentFailed:
              return <HistoryNoteSessionItem {...sessionItemProps} key={key} />
            case HistoryItemType.HistoryFileUploadMeta:
              return <UploadSessionItem {...sessionItemProps} key={key} />

            default:
              return null
          }
        })
      })}
    </HistoryContent>
  )
}

const HistoryContent = styled('div')`
  height: 100%;
`
