import { InsuranceCoverageInput } from '@nuna/api'

import { CoverageFormPatient, PendingPatient } from '../../CoverageForm/context/CoverageFormContext'
import { CoverageFormValues } from '../CoverageForm'

export function buildInsuranceCoverageInput(
  values: Pick<CoverageFormValues, 'insuranceValues'>,
  patient: CoverageFormPatient,
): InsuranceCoverageInput {
  return {
    ...buildPendingPatientInsuranceCoverageInput(values, patient),
    patientId: patient.id,
  }
}

export function buildPendingPatientInsuranceCoverageInput(
  values: Pick<CoverageFormValues, 'insuranceValues'>,
  patient: PendingPatient,
): Omit<InsuranceCoverageInput, 'patientId'> {
  const insuranceValues = values.insuranceValues

  if (!insuranceValues) throw new Error('No insurance values')
  if (!insuranceValues.insurancePayer?.externalEligibilityId) throw new Error('No payer eligibility id')

  return {
    dependent: insuranceValues.isDependent
      ? {
          dateOfBirth: patient.dob,
          firstName: patient.firstName,
          lastName: patient.lastName,
          memberId: insuranceValues.memberId,
          relationshipToSubscriber: insuranceValues.relationshipToSubscriber,
        }
      : undefined,
    isDependent: insuranceValues.isDependent ?? false,
    insurancePayerId: insuranceValues.insurancePayer.id,
    subscriber: {
      dateOfBirth: insuranceValues.isDependent ? insuranceValues.insuredDob : patient.dob,
      firstName: insuranceValues.insuredFirstName,
      lastName: insuranceValues.insuredLastName,
      memberId: insuranceValues.memberId,
    },
  }
}
