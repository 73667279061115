import { styled } from '@mui/material'
import { HTMLAttributes } from 'react'
import { Navigate, Route, Routes } from 'react-router-dom'

import { useIsAdmin } from '@nuna/auth'
import { FeatureFlagCheck, useFeatureFlags } from '@nuna/feature-flag'
import { CustomRates, PoliciesSetup } from '@nuna/fee-policies'
import { Grid, List, ListItem, MenuItem } from '@nuna/tunic'

import { AssessmentPreferences } from './components/Assessments/AssessmentPreferences'
import { Autonotes } from './components/Autonotes'
import { CalendarSync } from './components/CalendarSync/CalendarSync'
import { CaseloadManagement } from './components/CaseloadManagement/CaseloadManagement'
import { Locations } from './components/Locations/Locations'
import { MarketingNotifications } from './components/Notifications/MarketingNotifications'

interface Props extends HTMLAttributes<HTMLDivElement> {
  providerId: string | undefined | null
  providerLoginId: string | undefined
}

export function Preferences({ providerId, providerLoginId }: Props) {
  const isAdmin = useIsAdmin()
  const { assessmentAutomation } = useFeatureFlags()

  return (
    <div>
      <Grid container spacing={5}>
        <Grid style={{ width: '200px' }}>
          <List>
            <ListItem>
              <CustomMenuItem to="caseload">Caseload</CustomMenuItem>
            </ListItem>
            <ListItem>
              <CustomMenuItem to="calendar">Calendar Sync</CustomMenuItem>
            </ListItem>

            <ListItem>
              <CustomMenuItem to="fee-policies">Policy and Fees</CustomMenuItem>
            </ListItem>

            <FeatureFlagCheck flags={['customRates']} featureType="component">
              <ListItem>
                <CustomMenuItem to="custom-rates">Custom Rates</CustomMenuItem>
              </ListItem>
            </FeatureFlagCheck>
            <ListItem>
              <CustomMenuItem to="locations">Locations</CustomMenuItem>
            </ListItem>
            {isAdmin && (
              <ListItem>
                <CustomMenuItem to="notifications">Notifications</CustomMenuItem>
              </ListItem>
            )}

            {assessmentAutomation && (
              <ListItem>
                <CustomMenuItem to="assessments">Assessments</CustomMenuItem>
              </ListItem>
            )}

            <ListItem>
              <CustomMenuItem to="tava-scribe">Tava Scribe</CustomMenuItem>
            </ListItem>
          </List>
        </Grid>
        <Grid
          size={{
            xs: 12,
            sm: 'grow',
          }}
        >
          <Routes>
            <Route index element={<Navigate to={'caseload'} />} />
            <Route path="caseload" element={<CaseloadManagement providerId={providerId} />} />
            <Route path="calendar" element={<CalendarSync providerId={providerId} />} />
            <Route path="fee-policies/*" element={<PoliciesSetup providerId={providerId} />} />
            <Route
              path="custom-rates"
              element={
                <FeatureFlagCheck flags={['customRates']} featureType="component">
                  <CustomRates providerId={providerId} />
                </FeatureFlagCheck>
              }
            />
            <Route path="locations" element={<Locations />} />
            <Route path="notifications" element={<MarketingNotifications providerLoginId={providerLoginId} />} />

            <Route path="assessments" element={<AssessmentPreferences providerId={providerId} />} />
            <Route path="automatic-notes" element={<Navigate to="../tava-scribe" replace />} />
            <Route path="tava-scribe" element={<Autonotes providerId={providerId} />} />
          </Routes>
        </Grid>
      </Grid>
    </div>
  )
}

const CustomMenuItem = styled(MenuItem)`
  &.active {
    background-color: #3daaaa;
    color: #fff;
    font-weight: 500;
    font-size: 18px;
  }
`
