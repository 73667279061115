import { compact, pick, sortBy, trim } from 'lodash'

import { CountryOption, ProviderFormAddress, StateOption } from '../types/exported/address'
import {
  Address,
  AddressFragment,
  AddressOwner,
  AddressType,
  ProviderAddressFragment,
} from '../types/internal-only/address.types'

function formatAddress({ addressLineOne, addressLineTwo, city, state, zipCode, county }: Address) {
  const elements = compact([addressLineOne, addressLineTwo, city, state])

  return trim(`${elements.join(', ')} ${zipCode || ''} ${county ? `(${county})` : ''}`)
}

const TOKEN = import.meta.env['VITE_MAPBOX_TOKEN'] ?? ''
const baseUrl = 'https://api.mapbox.com/geocoding/v5/mapbox.places/'

function queryMapbox(resource: string, params: URLSearchParams = new URLSearchParams()) {
  params.append('access_token', TOKEN)
  const paramsString = params.toString()
  const url = `${baseUrl}${resource}.json${paramsString ? `?${paramsString}` : ''}`
  return fetch(url).then(response => {
    if (!response.ok) {
      return response
        .json()
        .catch(() => {
          // Couldn't parse the JSON
          throw new Error(response.status.toString())
        })
        .then(({ message }) => {
          // Got valid JSON with error response, use it
          throw new Error(message || response.status)
        })
    }
    // Successful response, parse the JSON and return the data
    return response.json()
  })
}

function getStateByZip(zipCode: string) {
  const params = new URLSearchParams({ limit: '1', types: 'postcode' })
  return queryMapbox(zipCode, params).then(response => {
    if (!response.features.length) {
      throw new InvalidZipCodeError('Invalid zip code')
    }
    return response.features[0].context
      .find((context: { id: string | string[] }) => context.id.includes('region'))
      .short_code.split('-')[1]
  })
}

export class InvalidZipCodeError extends Error {
  constructor(public message: string) {
    super()
  }
}

const countries: { [abbreviation: string]: string } = {
  AF: 'Afghanistan',
  AL: 'Albania',
  DZ: 'Algeria',
  AS: 'American Samoa',
  AD: 'Andorra',
  AO: 'Angola',
  AI: 'Anguilla',
  AQ: 'Antarctica',
  AG: 'Antigua and Barbuda',
  AR: 'Argentina',
  AM: 'Armenia',
  AW: 'Aruba',
  AU: 'Australia',
  AT: 'Austria',
  AZ: 'Azerbaijan',
  BS: 'Bahamas',
  BH: 'Bahrain',
  BD: 'Bangladesh',
  BB: 'Barbados',
  BY: 'Belarus',
  BE: 'Belgium',
  BZ: 'Belize',
  BJ: 'Benin',
  BM: 'Bermuda',
  BT: 'Bhutan',
  BO: 'Bolivia',
  BA: 'Bosnia and Herzegovina',
  BW: 'Botswana',
  BR: 'Brazil',
  IO: 'British Indian Ocean Territory',
  VG: 'British Virgin Islands',
  BN: 'Brunei',
  BG: 'Bulgaria',
  BF: 'Burkina Faso',
  BI: 'Burundi',
  KH: 'Cambodia',
  CM: 'Cameroon',
  CA: 'Canada',
  CV: 'Cape Verde',
  KY: 'Cayman Islands',
  CF: 'Central African Republic',
  TD: 'Chad',
  CL: 'Chile',
  CN: 'China',
  CX: 'Christmas Island',
  CC: 'Cocos Islands',
  CO: 'Colombia',
  KM: 'Comoros',
  CK: 'Cook Islands',
  CR: 'Costa Rica',
  HR: 'Croatia',
  CU: 'Cuba',
  CW: 'Curacao',
  CY: 'Cyprus',
  CZ: 'Czech Republic',
  CD: 'Democratic Republic of the Congo',
  DK: 'Denmark',
  DJ: 'Djibouti',
  DM: 'Dominica',
  DO: 'Dominican Republic',
  TL: 'East Timor',
  EC: 'Ecuador',
  EG: 'Egypt',
  SV: 'El Salvador',
  GQ: 'Equatorial Guinea',
  ER: 'Eritrea',
  EE: 'Estonia',
  ET: 'Ethiopia',
  FK: 'Falkland Islands',
  FO: 'Faroe Islands',
  FJ: 'Fiji',
  FI: 'Finland',
  FR: 'France',
  PF: 'French Polynesia',
  GA: 'Gabon',
  GM: 'Gambia',
  GE: 'Georgia',
  DE: 'Germany',
  GH: 'Ghana',
  GI: 'Gibraltar',
  GR: 'Greece',
  GL: 'Greenland',
  GD: 'Grenada',
  GU: 'Guam',
  GT: 'Guatemala',
  GG: 'Guernsey',
  GN: 'Guinea',
  GW: 'Guinea-Bissau',
  GY: 'Guyana',
  HT: 'Haiti',
  HN: 'Honduras',
  HK: 'Hong Kong',
  HU: 'Hungary',
  IS: 'Iceland',
  IN: 'India',
  ID: 'Indonesia',
  IR: 'Iran',
  IQ: 'Iraq',
  IE: 'Ireland',
  IM: 'Isle of Man',
  IL: 'Israel',
  IT: 'Italy',
  CI: 'Ivory Coast',
  JM: 'Jamaica',
  JP: 'Japan',
  JE: 'Jersey',
  JO: 'Jordan',
  KZ: 'Kazakhstan',
  KE: 'Kenya',
  KI: 'Kiribati',
  XK: 'Kosovo',
  KW: 'Kuwait',
  KG: 'Kyrgyzstan',
  LA: 'Laos',
  LV: 'Latvia',
  LB: 'Lebanon',
  LS: 'Lesotho',
  LR: 'Liberia',
  LY: 'Libya',
  LI: 'Liechtenstein',
  LT: 'Lithuania',
  LU: 'Luxembourg',
  MO: 'Macau',
  MK: 'Macedonia',
  MG: 'Madagascar',
  MW: 'Malawi',
  MY: 'Malaysia',
  MV: 'Maldives',
  ML: 'Mali',
  MT: 'Malta',
  MH: 'Marshall Islands',
  MR: 'Mauritania',
  MU: 'Mauritius',
  YT: 'Mayotte',
  MX: 'Mexico',
  FM: 'Micronesia',
  MD: 'Moldova',
  MC: 'Monaco',
  MN: 'Mongolia',
  ME: 'Montenegro',
  MS: 'Montserrat',
  MA: 'Morocco',
  MZ: 'Mozambique',
  MM: 'Myanmar',
  NA: 'Namibia',
  NR: 'Nauru',
  NP: 'Nepal',
  NL: 'Netherlands',
  AN: 'Netherlands Antilles',
  NC: 'New Caledonia',
  NZ: 'New Zealand',
  NI: 'Nicaragua',
  NE: 'Niger',
  NG: 'Nigeria',
  NU: 'Niue',
  KP: 'North Korea',
  MP: 'Northern Mariana Islands',
  NO: 'Norway',
  OM: 'Oman',
  PK: 'Pakistan',
  PW: 'Palau',
  PS: 'Palestine',
  PA: 'Panama',
  PG: 'Papua New Guinea',
  PY: 'Paraguay',
  PE: 'Peru',
  PH: 'Philippines',
  PN: 'Pitcairn',
  PL: 'Poland',
  PT: 'Portugal',
  PR: 'Puerto Rico',
  QA: 'Qatar',
  CG: 'Republic of the Congo',
  RE: 'Reunion',
  RO: 'Romania',
  RU: 'Russia',
  RW: 'Rwanda',
  BL: 'Saint Barthelemy',
  SH: 'Saint Helena',
  KN: 'Saint Kitts and Nevis',
  LC: 'Saint Lucia',
  MF: 'Saint Martin',
  PM: 'Saint Pierre and Miquelon',
  VC: 'Saint Vincent and the Grenadines',
  WS: 'Samoa',
  SM: 'San Marino',
  ST: 'Sao Tome and Principe',
  SA: 'Saudi Arabia',
  SN: 'Senegal',
  RS: 'Serbia',
  SC: 'Seychelles',
  SL: 'Sierra Leone',
  SG: 'Singapore',
  SX: 'Sint Maarten',
  SK: 'Slovakia',
  SI: 'Slovenia',
  SB: 'Solomon Islands',
  SO: 'Somalia',
  ZA: 'South Africa',
  KR: 'South Korea',
  SS: 'South Sudan',
  ES: 'Spain',
  LK: 'Sri Lanka',
  SD: 'Sudan',
  SR: 'Suriname',
  SJ: 'Svalbard and Jan Mayen',
  SZ: 'Swaziland',
  SE: 'Sweden',
  CH: 'Switzerland',
  SY: 'Syria',
  TW: 'Taiwan',
  TJ: 'Tajikistan',
  TZ: 'Tanzania',
  TH: 'Thailand',
  TG: 'Togo',
  TK: 'Tokelau',
  TO: 'Tonga',
  TT: 'Trinidad and Tobago',
  TN: 'Tunisia',
  TR: 'Turkey',
  TM: 'Turkmenistan',
  TC: 'Turks and Caicos Islands',
  TV: 'Tuvalu',
  VI: 'U.S. Virgin Islands',
  UG: 'Uganda',
  UA: 'Ukraine',
  AE: 'United Arab Emirates',
  GB: 'United Kingdom',
  US: 'United States',
  UY: 'Uruguay',
  UZ: 'Uzbekistan',
  VU: 'Vanuatu',
  VA: 'Vatican',
  VE: 'Venezuela',
  VN: 'Vietnam',
  WF: 'Wallis and Futuna',
  EH: 'Western Sahara',
  YE: 'Yemen',
  ZM: 'Zambia',
  ZW: 'Zimbabwe',
}

const getCountryNameFromAbbreviation = (abbreviation?: string) =>
  abbreviation && countries[abbreviation.toLocaleUpperCase()]

const getCountryAbbreviationFromFullName = (name: string) =>
  Object.keys(countries).find(key => countries[key].toLocaleLowerCase() === name.toLocaleLowerCase())
const countriesAsOptions = (): CountryOption[] =>
  Object.keys(countries).map(key => ({ value: key, label: countries[key] }))

const USStates: { [abbreviation: string]: string } = {
  AL: 'Alabama',
  AK: 'Alaska',
  AZ: 'Arizona',
  AR: 'Arkansas',
  CA: 'California',
  CO: 'Colorado',
  CT: 'Connecticut',
  DE: 'Delaware',
  FL: 'Florida',
  GA: 'Georgia',
  HI: 'Hawaii',
  ID: 'Idaho',
  IL: 'Illinois',
  IN: 'Indiana',
  IA: 'Iowa',
  KS: 'Kansas',
  KY: 'Kentucky',
  LA: 'Louisiana',
  ME: 'Maine',
  MD: 'Maryland',
  MA: 'Massachusetts',
  MI: 'Michigan',
  MN: 'Minnesota',
  MS: 'Mississippi',
  MO: 'Missouri',
  MT: 'Montana',
  NE: 'Nebraska',
  NV: 'Nevada',
  NH: 'New Hampshire',
  NJ: 'New Jersey',
  NM: 'New Mexico',
  NY: 'New York',
  NC: 'North Carolina',
  ND: 'North Dakota',
  OH: 'Ohio',
  OK: 'Oklahoma',
  OR: 'Oregon',
  PA: 'Pennsylvania',
  RI: 'Rhode Island',
  SC: 'South Carolina',
  SD: 'South Dakota',
  TN: 'Tennessee',
  TX: 'Texas',
  UT: 'Utah',
  VT: 'Vermont',
  VA: 'Virginia',
  WA: 'Washington',
  DC: 'Washington, D.C.',
  WV: 'West Virginia',
  WI: 'Wisconsin',
  WY: 'Wyoming',
}

const alternativeStateNames: { [abbreviation: string]: string[] } = {
  DC: ['Washington DC', 'District of Columbia', 'Washington D.C.'],
}

const getStateNameFromAbbreviation = (abbreviation: string | null | undefined) =>
  abbreviation && USStates[abbreviation.toUpperCase()]

const getStateAbbreviationFromFullName = (name: string) =>
  Object.keys(USStates).find(key => USStates[key].toLocaleLowerCase() === name.toLocaleLowerCase()) ||
  Object.keys(alternativeStateNames).find(key =>
    alternativeStateNames[key].some(state => state.toLocaleLowerCase() === name.toLocaleLowerCase()),
  )

function statesAsOptions() {
  return Object.keys(USStates).map<StateOption>(key => ({ value: key, label: USStates[key] }))
}

function getStateAbbreviations() {
  return Object.keys(USStates)
}

const stateOptions = statesAsOptions()
const getStateOptionFromAbbreviation = (abbreviation: string | null | undefined) =>
  stateOptions.find(state => state.value === abbreviation)

/** Provider address */

const NEW_HOME_ADDRESS_KEY = 'home_address'
const NEW_PRACTICE_ADDRESS_KEY = 'practice_address'
const NEW_ADDRESS_KEYS = [NEW_HOME_ADDRESS_KEY, NEW_PRACTICE_ADDRESS_KEY]

function generateProviderAddresses(
  providerAddresses: ProviderAddressFragment[],
  providerId: string,
): [ProviderFormAddress, ProviderFormAddress[]] {
  const homeAddress = buildHomeAddress(
    providerId,
    providerAddresses.find(providerAddress => providerAddress.address.addressType === AddressType.ProviderHome),
  )

  const allPracticeProviderAddresses = providerAddresses.filter(
    providerAddress => providerAddress.address.addressType === AddressType.ProviderPractice,
  )

  const matchedPracticeAddress = allPracticeProviderAddresses.find(
    providerAddress => providerAddress.address.addressHash === homeAddress?.addressHash,
  )
  if (matchedPracticeAddress) {
    homeAddress.matchedPracticeAddressId = matchedPracticeAddress.address.id
    homeAddress.defaultAddress = matchedPracticeAddress.primaryPracticeLocation
  }

  const practiceAddresses = allPracticeProviderAddresses
    .filter(providerAddress => providerAddress.address.addressHash !== homeAddress.addressHash)
    .map(providerAddress =>
      mapAddressValues(providerId, !!providerAddress.primaryPracticeLocation, providerAddress.address),
    )

  if ((practiceAddresses.length > 0 || homeAddress.id) && !homeAddress.matchedPracticeAddressId) {
    homeAddress.sameAsPracticeAddress = false
  }

  return [homeAddress, sortBy(practiceAddresses, address => address.defaultAddress).reverse()]
}

function buildHomeAddress(providerId: string, providerAddress?: ProviderAddressFragment | null) {
  return mapAddressValues(
    providerId,
    !!providerAddress?.primaryPracticeLocation,
    providerAddress?.address ?? { addressType: AddressType.ProviderHome },
  )
}

function mapAddressValues(
  providerId: string,
  primaryPracticeLocation: boolean,
  providerAddress: Partial<AddressFragment> = {},
): ProviderFormAddress {
  const formAddress = pick({ ...providerAddress, providerId, defaultAddress: primaryPracticeLocation }, [
    'id',
    'name',
    'addressHash',
    'addressLineOne',
    'addressLine',
    'city',
    'state',
    'country',
    'zipCode',
    'providerId',
    'addressType',
    'owner',
    'defaultAddress',
  ])

  if (providerAddress.addressType === AddressType.ProviderHome) {
    return {
      ...formAddress,
      sameAsPracticeAddress: false,
      matchedPracticeAddressId: '',
      addressType: AddressType.ProviderHome,
      name: providerAddress.name ?? 'Home',
      providerId,
      key: providerAddress?.id ?? NEW_HOME_ADDRESS_KEY,
      owner: AddressOwner.Provider,
    }
  }
  return {
    ...formAddress,
    addressType: AddressType.ProviderPractice,
    providerId,
    key: providerAddress?.id ?? NEW_PRACTICE_ADDRESS_KEY,
    owner: formAddress.owner ?? AddressOwner.Provider,
  }
}

function isHomeAddress(address: Partial<ProviderFormAddress>) {
  return address.addressType === AddressType.ProviderHome
}

function isPracticeAddress(address: Partial<ProviderFormAddress>) {
  return address.addressType === AddressType.ProviderPractice
}

export const addressService = {
  countries,
  countriesAsOptions,
  formatAddress,
  generateProviderAddresses,
  getCountryAbbreviationFromFullName,
  getCountryNameFromAbbreviation,
  getStateAbbreviationFromFullName,
  getStateAbbreviations,
  getStateByZip,
  getStateNameFromAbbreviation,
  getStateOptionFromAbbreviation,
  isHomeAddress,
  isPracticeAddress,
  mapAddressValues,
  NEW_ADDRESS_KEYS,
  NEW_HOME_ADDRESS_KEY,
  NEW_PRACTICE_ADDRESS_KEY,
  statesAsOptions,
  USStates,
}
