import { noop } from 'lodash'

import { specialtiesService } from '@nuna/core'

import { SpecialtiesSectionBase, SpecialtiesSectionProps } from './SpecialtiesSectionBase'

const PRESELECTED = ['Anxiety', 'Depression', 'Stress']

export function ChallengesSection({ specialties, id, refetch = noop }: SpecialtiesSectionProps) {
  return (
    <SpecialtiesSectionBase
      headingText="Challenges Treated"
      description="We'll use your selection to match you with your ideal clients. Though you may be able to treat many or all of these challenges, please limit your selection to challenges you are uniquely experienced treating."
      selectedSpecialties={specialties}
      specialtiesFilter={specialtiesService.getChallenges}
      refetch={refetch}
      providerId={id}
      min={5}
      preselected={PRESELECTED}
    />
  )
}
