import { useFormikContext } from 'formik'
import { HTMLAttributes } from 'react'

import { PaymentPreference } from '@nuna/api'
import { Audience } from '@nuna/core'
import { InsuranceCoverageForm, PendingPatient, insuranceInitialValues } from '@nuna/coverage'
import { ChipGroup, ContextualAlert, IconInfo, Radio } from '@nuna/tunic'

import { ClientInviteFormValues, Section, SectionTitle } from '../../shared'

interface ClientInvitePaymentMethodProps extends HTMLAttributes<HTMLDivElement> {
  audience: Audience
  disabled: boolean
  patient: PendingPatient
  providerId?: string
}

export function ClientInvitePaymentMethod({ audience, disabled, patient, ...props }: ClientInvitePaymentMethodProps) {
  const { values, setValues } = useFormikContext<ClientInviteFormValues>()

  return (
    <Section {...props}>
      <SectionTitle>Payment Method (optional)</SectionTitle>
      <ChipGroup className="ml-1">
        <Radio
          name="paymentPreference"
          disabled={disabled}
          checked={values.paymentPreference === PaymentPreference.Cash}
          value={PaymentPreference.Cash}
          onChange={() =>
            setValues({
              ...values,
              paymentPreference: PaymentPreference.Cash,
              insuranceValues: null,
              employerValues: null,
              accessCodeValues: null,
            })
          }
        >
          Cash
        </Radio>
        <Radio
          name="paymentPreference"
          disabled={!values.state || disabled}
          checked={values.paymentPreference === PaymentPreference.Insurance}
          value={PaymentPreference.Insurance}
          onChange={() =>
            setValues({
              ...values,
              paymentPreference: PaymentPreference.Insurance,
              insuranceValues: insuranceInitialValues,
              employerValues: null,
              accessCodeValues: null,
            })
          }
        >
          Insurance
        </Radio>
      </ChipGroup>

      {values.paymentPreference === PaymentPreference.Cash && (
        <ContextualAlert icon={<IconInfo />} intent="default" role="alert" scribbleType="default">
          You can add credit card details after saving client.
        </ContextualAlert>
      )}

      {values.insuranceValues && (
        <InsuranceCoverageForm audience={audience} inDrawer={true} patient={patient} state={values.state} />
      )}
    </Section>
  )
}
