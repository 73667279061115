import { User, UserUpdateReason } from '@twilio/conversations'
import { useEffect, useState } from 'react'

import { useVideoChatContext } from '../context/VideoChatContext'
import { TavaVideoUser } from '../types'

/**
 * Indicates if a user is currently connected to the video chat
 * @param user TavaVideoUser
 * @returns boolean or undefined if status is not yet known
 */
export function useIsConnectedToChat(user: TavaVideoUser) {
  const [isOnline, setIsOnline] = useState<boolean>()
  const { chatClient } = useVideoChatContext()

  const { loginId } = user

  useEffect(() => {
    if (!chatClient) return
    let isCanceled = false

    chatClient.getUser(loginId).then(user => {
      if (isCanceled) return
      setIsOnline(user.isOnline ?? undefined)
    })

    return () => {
      isCanceled = true
    }
  }, [chatClient, loginId])

  useEffect(() => {
    if (!chatClient) return

    const handleUserUpdated = ({ user, updateReasons }: { user: User; updateReasons: UserUpdateReason[] }) => {
      if (user.identity === loginId && updateReasons.includes('reachabilityOnline')) {
        setIsOnline(user.isOnline ?? undefined)
      }
    }

    chatClient.addListener('userUpdated', handleUserUpdated)

    return () => {
      chatClient.removeListener('userUpdated', handleUserUpdated)
    }
  }, [chatClient, loginId])

  return isOnline
}
