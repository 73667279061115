import { styled } from '@mui/material'
import { HTMLAttributes, MouseEvent, useRef, useState } from 'react'

import { GhostButton, Popover, StatusLabel, TimezoneSelect } from '@nuna/tunic'

import { useTimeZoneContext } from '../providers/TimezoneContextProvider'
import { CurrentTime } from './CurrentTime'

export function TimeZoneControl(props: HTMLAttributes<HTMLSpanElement>) {
  const [switcherAnchorEl, setSwitcherAnchorEl] = useState<HTMLElement | undefined>()
  const { timeZoneToUse, timeZoneAbbr, setCustomTimezone, usersInContext } = useTimeZoneContext()
  const labelRef = useRef<HTMLSpanElement>(null)

  return (
    <Container ref={labelRef} {...props}>
      <StatusLabel className="label" onClick={(e: MouseEvent<HTMLElement>) => setSwitcherAnchorEl(e.currentTarget)}>
        {timeZoneAbbr}
      </StatusLabel>
      <Popover
        onClose={() => setSwitcherAnchorEl(undefined)}
        open={!!switcherAnchorEl}
        anchorEl={switcherAnchorEl}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      >
        <SwitcherContainer>
          <div>
            {timeZoneToUse.replace(/_/g, ' ')} <br />
            Current time: <CurrentTime timezone={timeZoneToUse} /> <br />
            <br />
          </div>
          <TimezoneSelect className="mb-2" value={timeZoneToUse} onChange={value => setCustomTimezone(value)} />
          {usersInContext.map(user => (
            <div key={user.firstName} className="mt-3">
              <GhostButton onClick={() => setCustomTimezone(user.timezone)}>
                Use {user.firstName}'s time zone
              </GhostButton>
            </div>
          ))}
        </SwitcherContainer>
      </Popover>
    </Container>
  )
}

const Container = styled('span')`
  .label {
    cursor: pointer;
  }
`

const SwitcherContainer = styled('div')`
  width: 400px;
`
