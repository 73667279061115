import * as Yup from 'yup'
import { ApolloError } from '@apollo/client'
import { RadioGroup, styled } from '@mui/material'
import { Formik } from 'formik'
import { omit } from 'lodash'
import { HTMLAttributes } from 'react'

import { CustomerEmployeeDimension, useSaveCustomerEmployeeDimensionMutation } from '@nuna/api'
import { formService } from '@nuna/core'
import {
  Card,
  FillButton,
  IconButton,
  IconPlus,
  IconTrash,
  OutlineButton,
  Radio,
  Select,
  TextField,
  toast,
} from '@nuna/tunic'

const { composeHelperTextWithError } = formService

const Form = styled('form')`
  .text-field {
    width: 150px;

    input[type='number'] {
      text-align: right;
    }
  }
  .actions {
    text-align: right;
  }
`

const VALIDATION_SCHEMA = Yup.object().shape({
  name: Yup.string().required('Name is required'),
  customerEmployeeRecordField: Yup.mixed()
    .oneOf(['c1', 'c2', 'c3'])
    .required('Custom Field is required and cannot be duplicate'),
  valueMapping: Yup.object()
    .shape({
      options: Yup.array()
        .of(
          Yup.object().shape({
            label: Yup.string().required('Label is required'),
            value: Yup.string().required('Value is required'),
          }),
        )
        .nullable(),
      type: Yup.string().nullable(),
      description: Yup.string().nullable(),
    })
    .required('Value Mapping is required'),
})

const omitKey = (obj: Partial<CustomerEmployeeDimension>, key: string) =>
  JSON.parse(JSON.stringify(obj, (_key, val) => (_key === key ? undefined : val)))

export function CustomerEmployeeDimensionForm({
  dimension = { valueMapping: {} },
  onDismiss,
}: {
  dimension?: Partial<CustomerEmployeeDimension>
  onDismiss: () => void
} & HTMLAttributes<HTMLDivElement>) {
  const [saveDimension, { loading }] = useSaveCustomerEmployeeDimensionMutation({
    refetchQueries: ['CustomerEmployeeDimensions'],
  })

  const submit = async (values: Partial<CustomerEmployeeDimension>) => {
    try {
      await saveDimension({
        variables: {
          customerEmployeeDimension: omit(values, ['__typename']),
        },
      })
      toast.success('Customer dimension saved')
      onDismiss()
    } catch (error) {
      let errorMessage = 'There was a problem saving the dimension.'
      if (error instanceof ApolloError && error.graphQLErrors[0]) {
        errorMessage = error.graphQLErrors[0].message
      }
      toast.urgent(errorMessage)
    }
  }

  return (
    <Card className="p-2">
      <Formik initialValues={omitKey(dimension, '__typename')} validationSchema={VALIDATION_SCHEMA} onSubmit={submit}>
        {({ values, handleChange, handleBlur, errors, touched, handleSubmit, setFieldValue }) => (
          <Form onSubmit={handleSubmit}>
            <div className="v-align">
              <TextField
                name="name"
                label="Name"
                {...composeHelperTextWithError('', errors.name, touched.name)}
                className="text-field"
                value={values.name ?? ''}
                onChange={handleChange}
                onBlur={handleBlur}
              />

              <Select
                name="customerEmployeeRecordField"
                label="Custom Field"
                {...composeHelperTextWithError(
                  '',
                  errors.customerEmployeeRecordField,
                  touched.customerEmployeeRecordField,
                )}
                className="ml-2 text-field"
                value={values.customerEmployeeRecordField ?? 'c1'}
                onChange={handleChange}
                onBlur={handleBlur}
              >
                {['c1', 'c2', 'c3'].map(col => (
                  <option key={col} value={col}>
                    {col}
                  </option>
                ))}
              </Select>
            </div>
            <div className="v-align pt-2">
              <RadioGroup>
                <Radio
                  checked={!values.valueMapping?.options?.length}
                  onChange={() => {
                    setFieldValue('valueMapping', { type: null, description: null, options: [] })
                  }}
                >
                  Allow any value
                </Radio>
                <Radio
                  className="pt-1"
                  checked={!!values.valueMapping?.options?.length}
                  onChange={() => {
                    setFieldValue('valueMapping', {
                      type: 'select',
                      description: null,
                      options: [{ label: 'label', value: 'value' }],
                    })
                  }}
                >
                  Require certain values
                </Radio>
              </RadioGroup>
            </div>
            {(values.valueMapping?.options || []).map((option, index) => (
              <div key={option.value} className="v-align pt-1">
                <TextField
                  name={`valueMapping.options.${index}.label`}
                  label="Label"
                  className="text-field"
                  value={option.label}
                  onChange={handleChange}
                />
                <TextField
                  name={`valueMapping.options.${index}.value`}
                  label="Value"
                  className="ml-2 text-field"
                  value={option.value}
                  onChange={handleChange}
                />

                <IconButton
                  tooltip="Remove option"
                  onClick={() => {
                    setFieldValue(
                      'valueMapping.options',
                      values.valueMapping?.options?.filter((_, i) => i !== index),
                    )
                  }}
                >
                  <IconTrash />
                </IconButton>

                {index === (values.valueMapping?.options || []).length - 1 && (
                  <IconButton
                    tooltip="Add new option"
                    onClick={() => {
                      setFieldValue('valueMapping.options', [
                        ...(values.valueMapping?.options || []),
                        { label: '', value: '' },
                      ])
                    }}
                  >
                    <IconPlus size={15} />
                  </IconButton>
                )}
              </div>
            ))}

            <div className="v-align pt-2">
              <span className="ml-auto actions">
                <OutlineButton onClick={onDismiss}>Cancel</OutlineButton>
                <FillButton isLoading={loading} type="submit" className="ml-1">
                  Save
                </FillButton>
              </span>
            </div>
          </Form>
        )}
      </Formik>
    </Card>
  )
}
