import { SessionStorage } from '../types/exported'

const tavaSignupSourceParamsKey: keyof SessionStorage = 'tavaSignupSourceParams'

const setSignupSourceParams = ({
  source,
  channel,
  isProviderSourced,
  providerSourcedProviderId,
}: SessionStorage['tavaSignupSourceParams']) => {
  const existingSessionStorageSignupSourceParams = sessionStorage.getItem(tavaSignupSourceParamsKey)
  const existingSessionStorageSourceParamsParsed = existingSessionStorageSignupSourceParams
    ? JSON.parse(existingSessionStorageSignupSourceParams)
    : null
  sessionStorage.setItem(
    tavaSignupSourceParamsKey,
    JSON.stringify({
      ...existingSessionStorageSourceParamsParsed,
      source,
      channel,
      isProviderSourced,
      providerSourcedProviderId,
    } as SessionStorage[typeof tavaSignupSourceParamsKey]),
  )
}

const getSignupSourceParams = () => {
  const existingSessionStorageSignupSourceParams = sessionStorage.getItem(tavaSignupSourceParamsKey)
  return existingSessionStorageSignupSourceParams
    ? (JSON.parse(existingSessionStorageSignupSourceParams) as SessionStorage['tavaSignupSourceParams'])
    : {}
}

export const sessionStorageService = {
  getSignupSourceParams,
  setSignupSourceParams,
}
