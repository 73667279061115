import { capitalize } from 'lodash'

import { PaymentPreference, Role, useSavePatientIntakeMutation } from '@nuna/api'
import { hasRole, useUserRole } from '@nuna/auth'
import { coverageService, errorService } from '@nuna/core'
import { useFeatureFlags } from '@nuna/feature-flag'
import { IconCheckCircle, TextButton, toast } from '@nuna/tunic'

import { CoverageList, CoverageListItem, CoverageSection, CoverageSectionHeading } from '../../../../CoverageTypography'
import { useClientCoveragePanelContext } from '../../../context/ClientCoveragePanelContext'

const { getHumanReadablePaymentPreference } = coverageService

interface Props {
  type: PaymentPreference
}

export function PreferenceCoverageSection({ type }: Props) {
  const { autoCharge: autoChargeFeature } = useFeatureFlags()
  const role = useUserRole()
  const { client, postCapPreference, primaryCoverage, refetchClient, refetchCoverage } = useClientCoveragePanelContext()
  const [savePatientIntake, { loading }] = useSavePatientIntakeMutation()

  const possessivePronoun = hasRole(role, Role.Patient) ? 'your' : 'their'
  const isPreference = postCapPreference === type

  const handleSetPreferenceClick = async () => {
    try {
      await savePatientIntake({ variables: { id: client.id, input: { paymentPreference: type } } })
      toast.success(`Coverage preference set to ${getHumanReadablePaymentPreference(type)}`)
      refetchCoverage()
      refetchClient()
    } catch (e) {
      console.error(e)
      toast.urgent(errorService.transformGraphQlError(e, 'Failed to update coverage preference'))
    }
  }

  return (
    <CoverageSection>
      <CoverageSectionHeading>Coverage preference</CoverageSectionHeading>

      {(() => {
        if (primaryCoverage?.type === type) {
          return (
            <CoverageList>
              <CoverageListItem adornment={<IconCheckCircle size={16} />}>
                Session costs will be charged to this coverage
              </CoverageListItem>
            </CoverageList>
          )
        } else if (
          primaryCoverage?.type === PaymentPreference.Employer ||
          primaryCoverage?.type === PaymentPreference.Accesscode
        ) {
          return (
            <>
              <p>
                Once {possessivePronoun}{' '}
                {primaryCoverage?.type === PaymentPreference.Employer
                  ? `sessions with ${primaryCoverage.details.employerName}`
                  : `access code sessions`}{' '}
                are used, costs will be charged to{' '}
                {isPreference ? 'this coverage' : getHumanReadablePaymentPreference(postCapPreference)}.
              </p>
              {!isPreference && hasRole(role, [Role.Admin, Role.Patient]) && (
                <TextButton isLoading={loading} onClick={() => handleSetPreferenceClick()}>
                  Use {getHumanReadablePaymentPreference(type)} instead
                </TextButton>
              )}
            </>
          )
        } else {
          return (
            <>
              <p>
                {autoChargeFeature && postCapPreference === PaymentPreference.Insurance ? (
                  <>This card will be used for any remaining balance not covered by insurance.</>
                ) : (
                  <>
                    {capitalize(possessivePronoun)} preferred coverage is currently set to{' '}
                    {getHumanReadablePaymentPreference(postCapPreference)}.
                  </>
                )}
              </p>
              {hasRole(role, [Role.Admin, Role.Patient]) && (
                <TextButton isLoading={loading} onClick={() => handleSetPreferenceClick()}>
                  Set preference to {getHumanReadablePaymentPreference(type)}
                </TextButton>
              )}
            </>
          )
        }
      })()}
    </CoverageSection>
  )
}
