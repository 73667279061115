export enum FinancialAttribution {
  Provider = 'PROVIDER',
  TavaHealth = 'TAVA_HEALTH',
}

export enum WhoIsResponsibleForPayment {
  Client = 'CLIENT',
  Company = 'COMPANY',
  Free = 'FREE',
  Insurance = 'INSURANCE',
}
