import { Moment } from 'moment'

import {
  AppointmentChangeReason,
  Diagnosis,
  InternalAppointment,
  InternalAppointmentQuery,
  PaymentPreference,
  SessionSummaryQuery,
  SessionWithPatientLastLoginAtQuery,
} from '@nuna/api'

interface NoShowForm {
  noShowWho: string
}

interface CancelForm {
  cancelReason?: AppointmentChangeReason | null
}

interface CancelFeeForm {
  shouldProviderBePaid: boolean
}

export type SessionAppointment = Pick<InternalAppointment, 'id' | 'startDatetime' | 'endDatetime' | 'coverageType'>
export type SessionNoteAppointment = NonNullable<InternalAppointmentQuery['internalAppointment']>

export enum SessionWhatHappened {
  Cancel = 'cancel',
  ClientNoShow = 'clientNoShow',
  ProviderNoShow = 'providerNoShow',
  TechnicalDifficulties = 'TECHNICAL_DIFFICULTIES',
}

export interface SessionForm extends NoShowForm, CancelForm, CancelFeeForm {
  didSessionHappen: boolean
  whatHappened?: SessionWhatHappened | null
  patientId: string
  firstName: string
  lastName: string
  date: string
  startTime: string
  endTime: string
  appointmentId: string
  note: string
  plan?: string | null
  billedDuration?: number | null
  therapyTypeSpecialtyId?: string
  diagnoses: Diagnosis[]
  reasonData?: string | null
  noShowConfirmation: boolean
  addressId: string
  address?: {
    id: string
    name: string
  }
}

export interface SessionData {
  addressId: string
  appointmentId: string
  billableFieldsDisabled: boolean
  diagnoses: Diagnosis[]
  endTime: string
  locked: boolean
  patient: SessionWithPatientLastLoginAtQuery['session']['patient']
  provider: SessionWithPatientLastLoginAtQuery['session']['provider']
  scheduledEndTime: string
  scheduledStartTime: string
  startTime: string
  therapyTypeSpecialtyId: string
  workingDraft: string | null
  coverageType?: PaymentPreference
  inSessionNotes?: string
  isFirstSession?: boolean
  sessionId?: string
  sessionSubmittedAt?: Moment
  sessionSummary?: SessionSummaryQuery['sessionSummary']
  template?: string
}

export interface InputDiagnosis {
  id: string
  label: string
}

export interface SessionDraftDeprecated {
  version: number
  plan?: string | null
  note?: string
  therapyTypeSpecialtyId?: string
  startTime: string
  endTime: string
  date: string
  diagnoses: InputDiagnosis[]
}
