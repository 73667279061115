import * as Yup from 'yup'

import {
  BasicSpecialtyFragment,
  LicenseAbbreviation,
  LicenseInput,
  ProviderIntakeFragment,
  StateAbbreviation,
} from '@nuna/api'
import { specialtiesService } from '@nuna/core'
import { formService } from '@nuna/core'
import { NPIInput } from '@nuna/provider'
import { Radio, RadioGroup, TextField } from '@nuna/tunic'

import { useProviderAppContext } from '../../../../shared/ProviderAppContext'
import { specialtyToSpecialtyInput } from '../../ProviderSignupIntakeContext'
import { IntakeStepContainer } from '../../shared/IntakeStepContainer'
import { IntakeHeader } from '../../shared/IntakeTypography'
import { ModalitiesSelect } from '../../shared/ModalitiesSelect'
import { ProviderIntakeFormStepper } from '../../shared/ProviderIntakeFormStepper'
import { SpecialtiesChipsSelect } from '../../shared/SpecialtiesChipSelect'
import {
  ProviderSignupFormSchema,
  ProviderSignupFormValues,
  ProviderSignupIntakeFormProps,
  SaveProviderIntakeInput,
} from '../../shared/provider-signup-intake-types'
import { useProviderSignupIntakeStepSetup } from '../../shared/useProviderSignupIntakeStepSetup'
import { initialLicenses } from '../../signup-intake-utils'

const { composeHelperTextWithError } = formService

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore - ENG-4528
const validationSchema: ProviderSignupFormSchema = Yup.object({
  licenses: Yup.array<LicenseInput>(
    Yup.object({
      currentlyUtilized: Yup.boolean(),
      type: Yup.string<LicenseAbbreviation>(),
      licenseTypeId: Yup.string().required('Title is required'),
      state: Yup.string<StateAbbreviation>().required('State is required'),
      number: Yup.string().required('License Number is required'),
    }),
  ),
  modalities: Yup.array(Yup.object<BasicSpecialtyFragment>()).min(1, 'Please select at least one modality'),
  languages: Yup.array(Yup.object<BasicSpecialtyFragment>()).min(1, 'Please select at least one language'),
  therapyTypes: Yup.array(Yup.object<BasicSpecialtyFragment>()).min(1, 'Please select at least one type of therapy'),
  hasMalpractice: Yup.boolean().nullable(),
  hasMalpracticeReason: Yup.string().when('hasMalpractice', {
    is: true,
    then: Yup.string().required('Please provide details of the claim').typeError('Please provide details of the claim'),
    otherwise: Yup.string().nullable(),
  }),
  npi: Yup.string().typeError('NPI Number is required').length(10, 'A valid NPI is 10 digits').nullable(),
})

export function Professional({
  values,
  getFieldProps,
  setFieldValue,
  errors,
  touched,
  setFieldTouched,
  saveIntakeLoading,
  validateForm,
  isValid,
}: ProviderSignupIntakeFormProps) {
  const { provider } = useProviderAppContext()

  const afterSave = (data: ProviderIntakeFragment) => {
    setFieldValue('licenses', initialLicenses(provider?.id ?? '', data.credentials))
  }

  useProviderSignupIntakeStepSetup({ validationSchema, buildSaveValues, afterSave }, validateForm)

  const specialtySelectProps = (key: keyof ProviderSignupFormValues) => {
    return {
      ...{
        onChange: (v: BasicSpecialtyFragment[]) => setFieldValue(key, v),
        onBlur: () => setFieldTouched(key),
        value: values[key] as BasicSpecialtyFragment[],
      },
      ...composeHelperTextWithError('', errors[key], !!touched[key]),
    }
  }

  return (
    <IntakeStepContainer>
      <IntakeHeader type="h1" md={{ mb: 6 }}>
        Let's not forget your professional abilities
      </IntakeHeader>
      <div>
        <IntakeHeader type="h2" xs={{ mb: 1 }} md={{ mb: 5 }}>
          Qualifications
        </IntakeHeader>
        <NPIInput {...getFieldProps('npi')} {...composeHelperTextWithError('', errors.npi, !!touched.npi)} />
      </div>
      <div className="my-5">
        <p className="mb-2">Have you ever had an action taken against your malpractice insurance?</p>
        <RadioGroup className="v-align">
          <Radio checked={!!values.hasMalpractice} onChange={() => setFieldValue('hasMalpractice', true)}>
            Yes
          </Radio>
          <Radio
            checked={!values.hasMalpractice}
            onChange={() => setFieldValue('hasMalpractice', false)}
            className="ml-2"
          >
            No
          </Radio>
        </RadioGroup>
        {values.hasMalpractice && (
          <TextField
            multiline
            placeholder="Description"
            rowsMax={20}
            {...getFieldProps('hasMalpracticeReason')}
            {...composeHelperTextWithError('', errors.hasMalpracticeReason, !!touched.hasMalpracticeReason)}
          />
        )}
      </div>
      <IntakeHeader type="h2" xs={{ mt: 4 }} md={{ mt: 6, mb: 2 }}>
        Modalities
      </IntakeHeader>
      <ModalitiesSelect
        {...getFieldProps('modalities')}
        {...composeHelperTextWithError('', errors.modalities, !!touched.modalities)}
        onChange={(_e, modalities) => {
          setFieldValue('modalities', modalities)
        }}
        onRemove={specialtyId => {
          setFieldTouched('modalities', true)
          setFieldValue(
            'modalities',
            values.modalities.filter(d => d.id !== specialtyId),
          )
        }}
      />
      <IntakeHeader type="h2" xs={{ mt: 4 }} md={{ mt: 6, mb: 2 }}>
        Type of Therapy or Intervention
      </IntakeHeader>
      <div className="text-secondary my-2">Please select any that apply</div>
      <SpecialtiesChipsSelect
        dataTestId="therapy-type-specialties"
        specialtyFilter={specialtiesService.getTypesOfTherapy}
        {...specialtySelectProps('therapyTypes')}
      />
      <IntakeHeader type="h2" xs={{ mt: 4 }} md={{ mt: 5, mb: 2 }}>
        Languages
      </IntakeHeader>
      <div className="text-secondary my-2">Please select any language you choose to offer therapy in.</div>
      <SpecialtiesChipsSelect
        dataTestId="language-specialties"
        specialtyFilter={specialtiesService.getLanguagesSpokenSpecialties}
        {...specialtySelectProps('languages')}
      />
      <ProviderIntakeFormStepper
        className="mt-6"
        isValid={isValid}
        mutationLoading={saveIntakeLoading}
        step="abilities"
        currentSection="profile"
      />
    </IntakeStepContainer>
  )
}

function buildSaveValues(formValues: ProviderSignupFormValues): SaveProviderIntakeInput {
  const { licenses, modalities, languages, therapyTypes, npi, hasMalpractice, hasMalpracticeReason } = formValues
  return {
    licenses: licenses.filter(license => !!license.number),
    modalities: modalities.map(specialtyToSpecialtyInput),
    languages: languages.map(specialtyToSpecialtyInput),
    therapyTypes: therapyTypes.map(specialtyToSpecialtyInput),
    npi,
    hasMalpractice,
    hasMalpracticeReason: hasMalpractice ? hasMalpracticeReason : '',
  }
}
