import { styled } from '@mui/material'
import { setNestedObjectValues, useFormikContext } from 'formik'
import { useMediaQuery } from 'react-responsive'
import { Link, useNavigate } from 'react-router-dom'

import { Illustration } from '@nuna/common'
import { BelowTablet, DonutGauge, FillButton, Grid, IconCheckOutline, csx, tealSet } from '@nuna/tunic'

import { useProviderSignupIntakeContext } from '../ProviderSignupIntakeContext'
import BikingGirlSrc from '../img/BikingGirl.png'
import { IntakeHeader } from '../shared/IntakeTypography'
import { ProviderSignupFormValues } from '../shared/provider-signup-intake-types'
import { getPath } from '../signup-intake-steps'

export function IntakeProgressReport() {
  const navigate = useNavigate()
  const { values, setTouched } = useFormikContext<ProviderSignupFormValues>()
  const { stepStatuses, resumePath } = useProviderSignupIntakeContext()
  const isMobile = useMediaQuery({ query: `(${BelowTablet})` })

  const handleContinueClick = () => {
    setTouched(setNestedObjectValues(values, true))
    navigate(resumePath)
  }

  return (
    <>
      <Grid container className="v-align" direction="row-reverse">
        <Grid
          size={{
            xs: 12,
            md: 6,
          }}
        >
          <Illustration src={BikingGirlSrc} alt="biking girl" />
        </Grid>
        <Grid
          size={{
            md: 6,
          }}
        >
          <IntakeHeader type="h1" md={{ mb: 2, mt: 3 }}>
            We've saved your progress. Just a few steps left to finish.
          </IntakeHeader>
          <p className={csx([{ 'mb-4': isMobile, 'mb-5': !isMobile }, 'text-secondary large'])}>
            Bookmark this page, we'll see you back soon!
          </p>
        </Grid>
      </Grid>

      <IntakeHeader type="h3">Not too much left:</IntakeHeader>
      <StepList>
        {stepStatuses.map(step => (
          <li key={step.label} className="v-align text-secondary mb-2">
            <Link to={getPath(step.screens[0].step).pathname} className="v-align">
              {step.completed >= step.fieldCount ? (
                <IconCheckOutline color={tealSet[50].hex} size={26} className="mr-1" />
              ) : (
                <DonutGauge target={step.fieldCount} current={step.completed} className="mr-1" />
              )}

              {step.label}
            </Link>
          </li>
        ))}
      </StepList>
      <FillButton type="button" className="mt-4" onClick={handleContinueClick}>
        Continue Setup
      </FillButton>
    </>
  )
}

const StepList = styled('ul')`
  list-style: none;
  padding-left: 0;
`
