import { styled } from '@mui/material'
import { isNil } from 'lodash'
import { ChangeEvent, useMemo } from 'react'

import { ProviderStatus, useProviderCaseloadQuery, useSaveProviderNetworkMutation } from '@nuna/api'
import { errorService } from '@nuna/core'
import { Switch, tealSet, toast } from '@nuna/tunic'

import { ReferralLink } from './ReferralLink'

type Props = {
  providerId?: string | null
}

export function AcceptingSwitch({ providerId }: Props) {
  const [updateProviderNetwork] = useSaveProviderNetworkMutation()
  const { data: providerCaseload } = useProviderCaseloadQuery({
    variables: { providerId: providerId ?? '' },
    skip: isNil(providerId),
  })
  const { status, acceptsReferrals, eapReferralsLocked } = providerCaseload?.provider ?? {}

  const inPendingStatus = !status || [ProviderStatus.UnderReview, ProviderStatus.Onboarding].includes(status)
  const switchDisabled = eapReferralsLocked || inPendingStatus

  const switchText = useMemo(
    () =>
      eapReferralsLocked
        ? 'Contact clinicalops@tavahealth.com to turn on Tava Referrals'
        : `Tava referrals ${acceptsReferrals ? 'ON' : 'OFF'}`,
    [acceptsReferrals, eapReferralsLocked],
  )

  const altText = () => {
    if (eapReferralsLocked) return ''

    if (inPendingStatus) {
      return <> until your profile is approved</>
    }

    return ''
  }

  const updateAcceptingStatus = async (isAccepting: boolean) => {
    try {
      await updateProviderNetwork({
        variables: { id: providerId ?? '', acceptsReferrals: isAccepting },
        refetchQueries: ['ProviderCaseload'],
        awaitRefetchQueries: true,
      })
    } catch (e) {
      toast.urgent(errorService.transformGraphQlError(e, 'There was an issue toggling new referrals acceptance.'))
      return e
    }
  }

  const handleToggleAcceptingStatus = async (e: ChangeEvent<HTMLInputElement>) => {
    const isAccepting = e.currentTarget.checked
    await updateAcceptingStatus(isAccepting)
  }

  return (
    <div>
      <StyledSwitchText className="large">
        {switchText}

        {altText()}
      </StyledSwitchText>

      <div className="v-align mt-1">
        <Switch
          disabled={switchDisabled}
          checked={acceptsReferrals ?? false}
          onChange={handleToggleAcceptingStatus}
        ></Switch>
        <ReferralLink providerId={providerId} />
      </div>
    </div>
  )
}

const StyledSwitchText = styled('span')`
  color: ${tealSet[90].hex};
  font-weight: 500;
`
