import { useScreenShare } from '@daily-co/daily-react'
import { styled } from '@mui/material'
import { useMediaQuery } from 'react-responsive'

import { BelowTablet, IconScreenShare, error, greySet } from '@nuna/tunic'

import { VideoActionButton } from '../../VideoActionButton'

export function ScreenShareButton() {
  const { isSharingScreen, startScreenShare, stopScreenShare, screens } = useScreenShare()

  const handleClick = () => {
    isSharingScreen ? stopScreenShare() : startScreenShare()
  }

  const isMobile = useMediaQuery({ query: `(${BelowTablet})` })
  if (isMobile) return null

  const remoteScreens = screens.filter(screen => !screen.local)

  return (
    <StyledScreenShareButton
      aria-label="Toggle screen share"
      isEnabled={isSharingScreen}
      onClick={handleClick}
      data-component="screen-share-toggle"
      disabled={remoteScreens.length > 0}
    >
      {isSharingScreen ? 'Stop screen share' : <IconScreenShare />}
    </StyledScreenShareButton>
  )
}

const StyledScreenShareButton = styled(VideoActionButton)`
  overflow: hidden;
  position: relative;
  padding: 0;
  border-radius: 100%;

  svg {
    position: relative;
  }
  ${props =>
    props.isEnabled === false &&
    `
    border-color: rgba(255, 255, 255, 0.13);

    svg {
      fill: white;
    }

    &:not(:disabled):hover {
      border-color: white;
      
      svg {
        fill: white;
      }
    }
  `}
  ${props =>
    props.isEnabled === true &&
    `
    border-color: ${greySet[30].hex};
    padding: .3rem 1rem;
    border-radius: 48px;
    
    svg {
      fill: ${greySet[30].hex};
    }

    &:not(:disabled):hover {
      border-color: ${error};
      
      svg {
        fill: white;
      }
    }
  `}
`
