import { Autocomplete, AutocompleteProps, TextField } from '@mui/material'
import FuzzySearch from 'fuzzy-search'
import { isString } from 'lodash'
import { useMemo, useState } from 'react'

import { DocumentTypeOption, documentService } from '@nuna/core'

type LimitedAutocompleteProps = Omit<
  AutocompleteProps<DocumentTypeOption | undefined, false, boolean | undefined, false>,
  'onChange' | 'options' | 'renderInput' | 'value' | 'multiple'
>

interface Props extends LimitedAutocompleteProps {
  value?: DocumentTypeOption | string | null
  onChange: (state: DocumentTypeOption | undefined | null) => void
  label?: string
  placeholder?: string
}

const OPTIONS = documentService.getDocumentTypeOptions()

export function DocumentTypeSelect({ value, onChange, label = 'Document Type', ...props }: Props) {
  const [searchTerm, setSearchTerm] = useState('')

  const documentTypeOptions = useMemo<DocumentTypeOption[]>(() => {
    const searcher = new FuzzySearch(OPTIONS, ['label'], {
      caseSensitive: false,
    })
    if (!searchTerm) {
      return OPTIONS
    }
    return searcher.search(searchTerm)
  }, [searchTerm])

  const valueAsOption = useMemo<DocumentTypeOption | null | undefined>(() => {
    if (isString(value)) {
      return OPTIONS.find(option => option.value === value) ?? null
    }
    return value
  }, [value])

  return (
    <Autocomplete
      {...props}
      onChange={(_event, value) => {
        onChange(value)
      }}
      getOptionLabel={state => state?.label ?? ''}
      isOptionEqualToValue={(option, value) => option?.value === value?.value}
      options={documentTypeOptions}
      value={valueAsOption}
      ListboxProps={{ className: 'MuiAutocomplete-listbox' }}
      onInputChange={(_event, searchTerm) => setSearchTerm(searchTerm)}
      renderInput={params => (
        <TextField data-testid="document-type-autocomplete" label={label} placeholder="Type to search" {...params} />
      )}
    />
  )
}
