import { useCallback, useReducer } from 'react'

interface ChangeFilterVal<T> {
  type: 'changeFilterVal'
  key: keyof T
  payload: unknown
}

interface ResetFilterVal<T> {
  type: 'resetFilterVal'
  key: keyof T
}

interface ClearFilters {
  type: 'clearFilters'
}

type Actions<T> = ChangeFilterVal<T> | ResetFilterVal<T> | ClearFilters

export function useDataTableFiltering<T extends Record<string, unknown>>(initial: T) {
  const reducer = useCallback(
    (state: T, action: Actions<T>) => {
      switch (action.type) {
        case 'changeFilterVal':
          return { ...state, [action.key]: action.payload }
        case 'resetFilterVal':
          return { ...state, [action.key]: initial[action.key] }
        case 'clearFilters':
          return { ...initial }
        default:
          return { ...state }
      }
    },
    [initial],
  )

  const [filterValues, dispatch] = useReducer(reducer, initial)

  const setFilterVal = useCallback((key: keyof T, value: unknown) => {
    dispatch({ type: 'changeFilterVal', key, payload: value })
  }, [])

  const resetFilterVal = useCallback((key: keyof T) => {
    dispatch({ type: 'resetFilterVal', key })
  }, [])

  const clearFilters = useCallback(() => {
    dispatch({ type: 'clearFilters' })
  }, [])

  return { filterValues, setFilterVal, resetFilterVal, clearFilters }
}
