import { isNil } from 'lodash'
import { useState } from 'react'
import { useParams } from 'react-router-dom'

import {
  CapStatus,
  EapCoverageDetailsFragment,
  PaymentPreference,
  useGetCompanyQuery,
  useUpdatePatientCompanyReferralMutation,
} from '@nuna/api'
import { useIsAdmin } from '@nuna/auth'
import { errorService, routeService } from '@nuna/core'
import {
  Avatar,
  IconButton,
  IconCompany,
  IconPencil,
  OutlineButton,
  PersistentAlert,
  TextButtonLink,
  TextField,
  toast,
} from '@nuna/tunic'

import { EAPCard } from '../../../../CoverageCard/components/EAPCard'
import { CoverageTypeIcon } from '../../../../CoverageTypeIcon'
import { CoverageList, CoverageListItem, CoverageSection, CoverageSectionHeading } from '../../../../CoverageTypography'
import { DefaultTherapyTypeListItems } from '../../../../DefaultTherapyTypeListItems'
import { useClientCoveragePanelContext } from '../../../context/ClientCoveragePanelContext'
import { PreferenceCoverageSection } from './PreferenceCoverageSection'

interface Props {
  details: EapCoverageDetailsFragment
}

export function EAPDetails({ details }: Props) {
  const isAdmin = useIsAdmin()
  const [formReferralId, setFormReferralId] = useState(details.referralId || '')
  const { id: patientId = '' } = useParams()
  const { sessionsCap, providers, sessionsCapStatus } = details
  const { coverages, openCoverageForm } = useClientCoveragePanelContext()
  const { data: companyData } = useGetCompanyQuery({ variables: { id: details.companyId }, skip: !isAdmin })
  const { company } = companyData || {}
  const { isReferralIdRequired } = company || {}
  const [updatePatientCompanyReferral, { loading: isLoadingUpdateReferral }] = useUpdatePatientCompanyReferralMutation({
    refetchQueries: ['PatientCoverage'],
  })
  const hasOtherCoverage = !!coverages[PaymentPreference.Insurance] || !!coverages[PaymentPreference.Cash]
  const handleSaveReferralId = async () => {
    try {
      await updatePatientCompanyReferral({
        variables: { referralId: formReferralId, companyId: details.companyId, patientId },
      })
      toast.success('Referral ID updated')
    } catch (e) {
      toast.urgent(errorService.transformGraphQlError(e, `There was an error updating the referral ID`))
    }
  }

  return (
    <>
      <h3 className="h5 v-align">
        <CoverageTypeIcon className="mr-1" type={PaymentPreference.Insurance} />
        Sponsor Detail
      </h3>

      <EAPCard details={details} />

      <div className="mt-1 v-align">
        {(() => {
          if (sessionsCap === 0) {
            return hasOtherCoverage ? null : (
              <PersistentAlert intent="caution">Insurance or card needed</PersistentAlert>
            )
          } else if (sessionsCapStatus === CapStatus.Exhausted) {
            return <PersistentAlert intent="urgent">No sessions remaining</PersistentAlert>
          }

          return null
        })()}

        {isAdmin && (
          <IconButton
            className="ml-auto"
            variant="secondary"
            tooltip="Edit"
            onClick={() => openCoverageForm(PaymentPreference.Employer)}
          >
            <IconPencil />
          </IconButton>
        )}
      </div>

      {isAdmin && (
        <CoverageSection>
          <CoverageSectionHeading>Admin</CoverageSectionHeading>
          {'referralId' in details && isAdmin && patientId && isReferralIdRequired && (
            <div className="mt-2 mb-3 v-align">
              <TextField
                label="Referral ID"
                className="mr-1"
                value={formReferralId}
                onChange={e => {
                  setFormReferralId(e.target.value)
                }}
                style={{ flex: 1 }}
              />
              <OutlineButton onClick={handleSaveReferralId} isLoading={isLoadingUpdateReferral}>
                Update
              </OutlineButton>
            </div>
          )}
          <CoverageList>
            <CoverageListItem adornment={<IconCompany size={18} />}>
              <TextButtonLink to={routeService.customerDetail(details.companyId)}>
                {details.employerName}
              </TextButtonLink>
            </CoverageListItem>
          </CoverageList>
        </CoverageSection>
      )}

      {sessionsCap !== 0 && sessionsCapStatus !== CapStatus.Exhausted && (
        <PreferenceCoverageSection type={PaymentPreference.Employer} />
      )}

      {sessionsCap === 0 ? (
        <CoverageSection>
          <p className="large paragraphs">{details.employerName} uses insurance to cover sessions.</p>
        </CoverageSection>
      ) : (
        <>
          <CoverageSection>
            <CoverageSectionHeading>Includes</CoverageSectionHeading>

            <CoverageList>
              {isNil(sessionsCap) ? (
                <p>Unlimited covered sessions</p>
              ) : (
                <CoverageListItem adornment={sessionsCap}>Covered sessions</CoverageListItem>
              )}

              <DefaultTherapyTypeListItems />
            </CoverageList>
          </CoverageSection>

          {providers.length > 0 && (
            <CoverageSection>
              <CoverageSectionHeading>Care Team</CoverageSectionHeading>

              <CoverageList>
                {providers.map(provider => (
                  <CoverageListItem key={provider.id} adornment={<Avatar size="mini" url={provider.avatarUrl} />}>
                    {provider.firstName} {provider.lastName}
                  </CoverageListItem>
                ))}
              </CoverageList>
            </CoverageSection>
          )}
        </>
      )}
    </>
  )
}
