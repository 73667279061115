import * as Yup from 'yup'
import { Field, Formik } from 'formik'
import { noop } from 'lodash'
import { MouseEventHandler, useState } from 'react'

import { usePatientLoginMutation } from '@nuna/api'
import { errorService, formService } from '@nuna/core'
import { ContextualAlert, Grid, TextButton, TextField } from '@nuna/tunic'

import { type AuthData, useAuthDataContext } from '../context/AuthDataContext'

const { composeHelperTextWithError } = formService

interface LoginValues {
  email: string
  password: string
}

const validationSchema = Yup.object().shape({
  email: Yup.string().required('Email Address is required').email(`Oops, that's not an email address`),
  password: Yup.string().required('Password is required'),
})

const initialValues: LoginValues = {
  email: '',
  password: '',
}

interface LoginFormProps {
  renderButtons: (isLoading: boolean) => JSX.Element
  onSignUpClick: MouseEventHandler<HTMLButtonElement>
  onSuccess?: (loginData: AuthData) => void
}

export function LoginForm({ renderButtons, onSignUpClick, onSuccess = noop }: LoginFormProps) {
  const { onLogin } = useAuthDataContext()
  const [error, setError] = useState('')

  const [login, { loading }] = usePatientLoginMutation()

  const handleSubmit = async (values: LoginValues) => {
    setError('')

    try {
      const response = await login({
        variables: {
          email: values.email,
          password: values.password,
        },
      })

      const loginData = response.data?.patientLogin

      if (loginData) {
        onLogin(loginData)
        onSuccess(loginData)
      } else {
        throw new Error('Unable to login. Please double check your email and password.')
      }
    } catch (e) {
      setError(errorService.transformGraphQlError(e, 'Unable to login. Please double check your email and password.'))
    }
  }

  return (
    <Formik initialValues={initialValues} onSubmit={handleSubmit} validationSchema={validationSchema}>
      {({ handleSubmit, errors, touched }) => (
        <form onSubmit={handleSubmit}>
          <Grid container spacing={4}>
            <Grid size={12}>
              <h3 className="body text-medium">Welcome back</h3>
              <p className="mb-0 text-secondary text-light">
                No account?{' '}
                <TextButton variant="secondary" onClick={onSignUpClick}>
                  Sign up
                </TextButton>
              </p>
            </Grid>

            <Grid size={12}>
              <Field
                as={TextField}
                name="email"
                label="Email"
                inputProps={{
                  'data-testid': 'login-email',
                }}
                {...composeHelperTextWithError('', errors.email, touched.email)}
              />
            </Grid>

            <Grid size={12}>
              <Field
                as={TextField}
                name="password"
                label="Password"
                type="password"
                inputProps={{
                  'data-testid': 'login-password',
                }}
                {...composeHelperTextWithError('', errors.password, touched.password)}
              />
            </Grid>

            {error && (
              <Grid size={12}>
                <ContextualAlert intent="urgent">{error}</ContextualAlert>
              </Grid>
            )}
          </Grid>

          {renderButtons(loading)}
        </form>
      )}
    </Formik>
  )
}
