import { styled } from '@mui/material'
import { ReactNode } from 'react'

import { Card, CardProps, IconButton, IconPencil, body1, csx, makeTypographyComponent } from '@nuna/tunic'

export const FormSection = styled('div')`
  color: ${body1};
  margin: var(--spacing-3) 0;
`
export const FieldLabel = makeTypographyComponent('large text-medium mb-xs', 'div')
export const CaptionDark = makeTypographyComponent('caption text-secondary italic', 'div')
export const CaptionLight = makeTypographyComponent('caption text-light-grey italic', 'div')

interface FormCardProps extends CardProps {
  isEditing: boolean
  onEdit: () => void
  canEdit: boolean
  buttonsRightSlot?: ReactNode
}
export function FormCard({
  isEditing,
  canEdit,
  onEdit,
  className,
  buttonsRightSlot,
  children,
  ...props
}: FormCardProps) {
  return (
    <Card className={csx([className, 'px-3 relative'])} depth={isEditing ? -1 : 1} {...props}>
      {!isEditing && (
        <ButtonContainer className="v-align">
          {canEdit && (
            <IconButton variant="secondary" tooltip="Edit policy" onClick={onEdit}>
              <IconPencil />
            </IconButton>
          )}
          {buttonsRightSlot}
        </ButtonContainer>
      )}
      {children}
    </Card>
  )
}

const ButtonContainer = styled('span')`
  position: absolute;
  top: var(--spacing-1);
  right: var(--spacing-1);
`
