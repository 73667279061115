import { useEffect, useState } from 'react'

import { useUnreadMessageCountQuery } from '@nuna/api'
import { useAuthDataContext } from '@nuna/auth'
import { useVisibilityChanged } from '@nuna/common'

const POLL_INTERVAL = 60 * 1000

export function useUnreadMessageCountPolling() {
  const visible = useVisibilityChanged()
  const { login } = useAuthDataContext()

  const [lastPollTime, setLastPollTime] = useState<number | null>(null)
  const { data, stopPolling, startPolling } = useUnreadMessageCountQuery({
    fetchPolicy: 'network-only',
    pollInterval: POLL_INTERVAL,
    notifyOnNetworkStatusChange: true,
    skip: !login?.id,
    onCompleted: () => {
      setLastPollTime(Date.now())
    },
  })

  useEffect(() => {
    if (visible && login) {
      startPolling(POLL_INTERVAL)
    } else {
      stopPolling()
    }
  }, [visible, login, startPolling, stopPolling])

  const unreadCount = data?.unreadMessageCount ?? 0

  return { unreadCount, hasUnread: unreadCount > 0, lastPollTime }
}
