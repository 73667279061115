import { styled } from '@mui/material'
import { Autocomplete, TextField } from '@mui/material'
import { debounce } from 'lodash'
import { CSSProperties, useMemo, useState } from 'react'

import { Role, SearchConversationParticipantFragment, useSearchConversationParticipantsLazyQuery } from '@nuna/api'
import { Avatar, IconPlus, csx } from '@nuna/tunic'

interface ConversationCreateDropdownProps {
  onChange: (patient: SearchConversationParticipantFragment) => void
  className?: string
  style?: CSSProperties
}

export function ConversationCreateDropdown({ onChange, className = '', style = {} }: ConversationCreateDropdownProps) {
  const [searchConversationParticipants, { data, loading }] = useSearchConversationParticipantsLazyQuery()
  const [inputValue, setInputValue] = useState('')

  const participants = data?.searchConversationParticipants.items ?? []

  const debouncedSearchConversationParticipants = useMemo(
    () =>
      debounce((term: string) => searchConversationParticipants({ variables: { filters: { search: { term } } } }), 400),
    [searchConversationParticipants],
  )

  return (
    <Autocomplete
      style={style}
      className={className}
      options={participants}
      forcePopupIcon={false}
      renderOption={(renderOptionProps, participant) => {
        return (
          <li {...renderOptionProps}>
            <Option className={csx(['v-align', { 'fs-mask': participant.displayData?.role === Role.Patient }])}>
              <Avatar className="mr-1" size="mini" url={participant.displayData.avatarUrl} />
              {participant.displayData.firstName} {participant.displayData.lastName}
            </Option>
          </li>
        )
      }}
      loading={loading}
      onChange={(_e, participant) => {
        if (participant) {
          onChange(participant)
          setInputValue('')
        }
      }}
      filterOptions={options => options}
      clearOnBlur
      renderInput={params => (
        <StyledTextField
          {...params}
          value={inputValue}
          variant="outlined"
          InputProps={{
            ...params.InputProps,
            startAdornment: <IconPlus size={12} className="mx-1" />,
          }}
          onChange={e => {
            setInputValue(e.target.value)
            debouncedSearchConversationParticipants(e.target.value)
          }}
        />
      )}
    />
  )
}

const Option = styled('span')`
  padding: var(--margin-1);
`

const StyledTextField = styled(TextField)`
  .MuiOutlinedInput-root {
    border-radius: 10px;
  }

  .MuiAutocomplete-inputRoot[class*='MuiOutlinedInput-root'] {
    padding-top: 0.125rem;
    padding-bottom: 0.125rem;
  }
`
