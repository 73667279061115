import { styled } from '@mui/material'
import { NavLink } from 'react-router-dom'

import { Patient } from '@nuna/api'
import { useIsAdmin } from '@nuna/auth'
import { TimeZoneControl, useAppointmentDrawerSearchParams } from '@nuna/common'
import { routeService } from '@nuna/core'
import { Avatar, IconButton, IconClose } from '@nuna/tunic'

import { AppointmentDrawerPaddedContainer } from '../AppointmentDrawer'

interface Props {
  client: Pick<Patient, '__typename' | 'firstName' | 'lastName' | 'avatarUrl' | 'id'>
}
export function ClientDrawerHeader({ client }: Props) {
  const isAdmin = useIsAdmin()
  const { closeDrawer } = useAppointmentDrawerSearchParams()
  return (
    <Container className="space-between px-3">
      <span className="v-align">
        <Avatar url={client.avatarUrl} className="fs-exclude" size="xs" />
        <NavLink className="large text-medium text-default ml-1" to={routeService.userProfile(client)}>
          {client.firstName} {client.lastName}
        </NavLink>
        {isAdmin && <TimeZoneControl className="ml-2" />}
      </span>
      <IconButton tooltip="Close" className="close-button" variant="secondary" onClick={() => closeDrawer()}>
        <IconClose size={20} />
      </IconButton>
    </Container>
  )
}

const Container = styled(AppointmentDrawerPaddedContainer)`
  .close-button {
    margin-right: -0.5rem;
  }
`
