import { IconProps } from './IconProps'

export function IconCustomer({ size = 24, color = 'currentColor', ...props }: IconProps) {
  const height = (15 / 17) * size

  return (
    <svg width={height} height={height} viewBox="0 0 17 15" fill={color} xmlns="http://www.w3.org/2000/svg" {...props}>
      <path d="M8.1875 3.20312V0.015625L0.21875 0.015625L0.21875 14.3594L16.1562 14.3594V3.20312L8.1875 3.20312ZM3.40625 12.7656H1.8125L1.8125 11.1719H3.40625L3.40625 12.7656ZM3.40625 9.57812H1.8125L1.8125 7.98438L3.40625 7.98437V9.57812ZM3.40625 6.39062L1.8125 6.39062L1.8125 4.79688L3.40625 4.79687V6.39062ZM3.40625 3.20312H1.8125L1.8125 1.60937L3.40625 1.60937V3.20312ZM6.59375 12.7656L5 12.7656V11.1719L6.59375 11.1719V12.7656ZM6.59375 9.57812L5 9.57812L5 7.98437H6.59375L6.59375 9.57812ZM6.59375 6.39062H5V4.79687H6.59375V6.39062ZM6.59375 3.20312H5L5 1.60937L6.59375 1.60937L6.59375 3.20312ZM14.5625 12.7656H8.1875V11.1719H9.78125V9.57812H8.1875L8.1875 7.98437L9.78125 7.98437V6.39062L8.1875 6.39062V4.79687L14.5625 4.79687L14.5625 12.7656ZM12.9688 6.39062H11.375V7.98437H12.9688V6.39062ZM12.9688 9.57812H11.375V11.1719H12.9688V9.57812Z" />
    </svg>
  )
}
