import { styled } from '@mui/material'

import { Persona } from '@nuna/core'
import { HeaderProgressBar } from '@nuna/tunic'

type Props = {
  center?: React.ReactNode
  left?: React.ReactNode
  right?: React.ReactNode
  resumePath?: string
  backgroundColor?: string
  hasProgressBar?: boolean
  now?: number
  max?: number
  persona?: Persona
}

export function LayoutHeader({ center, left, right, backgroundColor, hasProgressBar, now, max, persona }: Props) {
  return (
    <HomeHeader bgColor={backgroundColor} className=" text-secondary">
      <HomeInner className="space-between v-align">
        {left ?? <div></div>}
        {center ?? <div></div>}
        {right ?? <div></div>}
      </HomeInner>
      {hasProgressBar && (
        <div>
          <HeaderProgressBar
            style={{ position: 'absolute', bottom: 0, left: 0 }}
            now={now ?? 0}
            min={0}
            max={max ?? 100}
            persona={persona ?? 'provider'}
          />
        </div>
      )}
    </HomeHeader>
  )
}

const HomeHeader = styled('div')<{ bgColor?: string | undefined }>`
  padding: 0 1.5rem;
  width: 100%;
  ${({ bgColor }) => (bgColor ? `background-color: ${bgColor}` : '')};
  position: fixed;
  z-index: 9999;
`

const HomeInner = styled('div')`
  flex-shrink: 0;
  height: 64px;
  align-items: center;
  padding-bottom: 7px;
`
