import { isNil } from 'lodash'
import { ChangeEvent } from 'react'

import { useProviderCaseloadQuery, useSaveProviderNetworkMutation } from '@nuna/api'
import { useIsAdmin } from '@nuna/auth'
import { errorService } from '@nuna/core'
import { Switch, toast } from '@nuna/tunic'

type Props = {
  providerId?: string | null
}

export function AdminLockSwitch({ providerId }: Props) {
  const isAdmin = useIsAdmin()
  const [updateProviderNetwork] = useSaveProviderNetworkMutation()
  const { data: providerCaseload } = useProviderCaseloadQuery({
    variables: { providerId: providerId ?? '' },
    skip: isNil(providerId),
  })
  const { eapReferralsLocked } = providerCaseload?.provider ?? {}

  const updateReferralLockStatus = async (isLocked: boolean) => {
    try {
      await updateProviderNetwork({
        variables: { id: providerId ?? '', eapReferralsLocked: isLocked },
        refetchQueries: ['ProviderCaseload'],
        awaitRefetchQueries: true,
      })
    } catch (e) {
      toast.urgent(errorService.transformGraphQlError(e, 'There was an issue locking the provider.'))
    }
  }

  const handleToggleTavaReferrals = async (e: ChangeEvent<HTMLInputElement>) => {
    const isLocked = e.currentTarget.checked
    await updateReferralLockStatus(isLocked)
  }

  const switchText = eapReferralsLocked
    ? 'Provider is locked from accepting referrals'
    : 'Lock provider from accepting referrals'

  return isAdmin ? (
    <Switch checked={eapReferralsLocked ?? false} onChange={handleToggleTavaReferrals}>
      {switchText}
    </Switch>
  ) : null
}
