import { styled } from '@mui/material'
import moment from 'moment-timezone'
import { NavLink, useMatch } from 'react-router-dom'

import { useCurrentAppointmentsQuery } from '@nuna/api'
import { routeService } from '@nuna/core'
import { Dialog, DialogProps, TextButton, greySet, tealSet } from '@nuna/tunic'

import { useGlobalWidgetContext } from '../../shared/GlobalWidgetContext'
import { overlappingSessions } from '../../util/routes'

export function OverlappingSessionsModal(props: Omit<DialogProps, 'isOpen' | 'onClose'>) {
  const overlappingSessionsMatch = useMatch(overlappingSessions())
  const { loading, data } = useCurrentAppointmentsQuery()
  const { toggleBeacon } = useGlobalWidgetContext()

  if (loading) {
    return null
  }

  const appointments = data?.currentAppointments || []

  return (
    <OverlappingSessionsDialog {...props} transitionDuration={500} isOpen={!!overlappingSessionsMatch}>
      <h5 className="text-light-gray mb-2">You've got a few active sessions</h5>

      {appointments.map(appointment => {
        if (!appointment) {
          return null
        }
        const minutesFromNow = appointment?.minutesFromNow || 0
        return (
          <AppointmentLink to={routeService.videoSession(appointment.id)} key={appointment.id}>
            <h3 className="h5">
              {appointment?.patient.firstName} {appointment?.patient.lastName}
            </h3>
            <h3 className="h5">
              {moment(appointment?.startDatetime).format('h:mm')}-{moment(appointment?.endDatetime).format('h:mm A')}
            </h3>
            {minutesFromNow >= 0 && <h5 className="text-white">Starts in {minutesFromNow} min</h5>}
            <TextButton scheme="dark">{minutesFromNow >= 0 ? 'Join Session' : 'Rejoin Session'}</TextButton>
          </AppointmentLink>
        )
      })}

      <p className="mt-2 text-light-gray text-light">
        Something seem off?{' '}
        <TextButton scheme="dark" onClick={toggleBeacon}>
          Reach Out
        </TextButton>
      </p>
    </OverlappingSessionsDialog>
  )
}

const OverlappingSessionsDialog = styled(Dialog)`
  .MuiPaper-root {
    background: ${tealSet[90].hex};
    border: none;
    color: #fff;
    padding: 1rem;
    text-align: center;
  }
`

const AppointmentLink = styled(NavLink)`
  align-items: center;
  background: ${tealSet[100].hex};
  border: 1px solid #002338;
  border-radius: 12px;
  color: #596778;
  display: flex;
  flex-direction: column;
  /* justify-content: center; */
  padding: 1rem 0;

  &:hover {
    text-decoration: none;
    border: 1px solid ${greySet[30].hex};
  }

  h3 {
    color: #fff;
    width: 100%;
  }

  h5 {
    font-weight: 300;
  }
`
AppointmentLink.defaultProps = { className: 'mb-1' }
