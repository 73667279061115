import { styled } from '@mui/material'
import { compact, groupBy } from 'lodash'
import { useState } from 'react'
import { useMediaQuery } from 'react-responsive'

import { ProviderCompensationRate, useProviderMeQuery } from '@nuna/api'
import { compensationService, routeService } from '@nuna/core'
import {
  BelowTablet,
  Dialog,
  IconButton,
  IconClose,
  MenuItem,
  MenuItemStack,
  Select,
  TextButtonExternalLink,
  eggshell,
  fontSize,
  greySet,
  makeTypographyComponent,
} from '@nuna/tunic'

import { RateDetail, RateDetailGrid } from '../shared'

interface Props {
  isOpen: boolean
  rates: ProviderCompensationRate[]
  onClose: () => void
}

export function InsuranceFeesModal({ isOpen, onClose, rates }: Props) {
  const isMobile = useMediaQuery({ query: `(${BelowTablet})` })
  const { data: providerData } = useProviderMeQuery()
  const grouped = groupBy(rates, 'description')
  const payers = compact(Object.keys(grouped).sort())
  const [selectedPayer, setSelectedPayer] = useState<keyof typeof grouped>(payers[0] ?? '')
  const selectedRates = grouped[selectedPayer]

  const publicProfileUrl = routeService.publicProviderRoute(providerData?.providerMe?.slug || '', true)

  return (
    <StyledDialog
      fullScreen={isMobile}
      isOpen={isOpen}
      onClose={onClose}
      PaperProps={{ style: { minHeight: 500 }, className: 'flex-column' }}
    >
      <div className="v-align space-between header">
        <h5 className="modal-header">Insurance Session Rates</h5>
        <IconButton tooltip="Close" onClick={onClose}>
          <IconClose size={15} />
        </IconButton>
      </div>
      <div className="content flex-remaining-space">
        <div className="payer-list">
          <div className="scroll-container">
            <MenuItemStack>
              {payers.map(payer => (
                <StyledMenuItem
                  isActive={() => payer === selectedPayer}
                  button
                  key={payer}
                  onClick={() => setSelectedPayer(payer)}
                >
                  {payer}
                </StyledMenuItem>
              ))}
            </MenuItemStack>
          </div>
        </div>
        <div className="detail-column flex-column">
          <div className="payer-select-container">
            <Select value={selectedPayer as string} onChange={e => setSelectedPayer(e.currentTarget.value)}>
              {payers.map(payer => (
                <option value={payer} key={payer}>
                  {payer}
                </option>
              ))}
            </Select>
          </div>
          <div className="rates-container">
            <PayerHeader>{selectedPayer}</PayerHeader>
            <RateDetailGrid>
              {selectedRates.map(rate => (
                <RateDetail
                  description={rate.shortDescription}
                  value={compensationService.compensationRangeDisplay(rate)}
                />
              ))}
            </RateDetailGrid>
          </div>
          <div className="detail-footer text-secondary italic">
            <p>Your payout within these ranges depends on the client's specific insurance plan.</p>
            <p>
              These higher rates apply to clients you refer through{' '}
              <TextButtonExternalLink variant="secondary" href={publicProfileUrl} includeReferrer>
                your website
              </TextButtonExternalLink>
              .
            </p>
          </div>
        </div>
      </div>
    </StyledDialog>
  )
}

const contentPadding = `calc(var(--spacing-4) + var(--spacing-half))`

const StyledDialog = styled(Dialog)`
  .header {
    padding: var(--spacing-2) calc(var(--spacing-4) + var(--spacing-half) - 0.75rem) var(--spacing-2)
      calc(var(--spacing-4) + var(--spacing-half) + 0.75rem);
    background-color: ${greySet[5].hex};
    h5 {
      margin-bottom: 0;
    }
  }

  .payer-list {
    border-right: 1px solid ${greySet[15].hex};
    padding-bottom: ${contentPadding};
    max-height: 540px;
    overflow-y: hidden;

    .scroll-container {
      height: 100%;
      overflow-y: auto;
      padding: 0 ${contentPadding};
    }
  }

  .content {
    display: flex;
    align-items: stretch;
    height: 100%;
    padding-top: ${contentPadding};
  }

  .detail-column {
    padding-top: 14px;
    width: 410px;

    .rates-container {
      padding-left: ${contentPadding};
      padding-right: ${contentPadding};
    }

    .detail-footer {
      margin-top: auto;
      background-color: ${eggshell};
      padding: calc(var(--spacing-4) + var(--spacing-half));

      p:last-child {
        margin-bottom: 0;
      }
    }
  }

  .payer-select-container {
    display: none;
    padding: 0 ${contentPadding} ${contentPadding};
  }

  @media (${BelowTablet}) {
    .modal-header {
      font-size: ${fontSize.h6};
    }

    .content {
      padding-top: 0;
    }

    .payer-list {
      display: none;
    }

    .payer-select-container {
      display: block;
    }
  }
`

const PayerHeader = makeTypographyComponent('text-black text-medium mb-2', 'div')

const StyledMenuItem = styled(MenuItem)`
  color: ${greySet[80].hex};
`
