import { SignupLayout } from '../../layouts/SignupLayout'
import { faqItems } from './faqItems'

export function SupportWelcome() {
  return (
    <SignupLayout showSignInLink={false} faqItems={faqItems}>
      <div>Some intro stuff with video and what not</div>
    </SignupLayout>
  )
}
