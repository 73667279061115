import { Fragment } from 'react'

import { CashCoverageDetailsFragment, PaymentPreference, Role } from '@nuna/api'
import { hasRole, useAuthDataContext } from '@nuna/auth'
import { ProviderTabs } from '@nuna/common'
import { coverageService, numberService } from '@nuna/core'
import { useFeatureFlags } from '@nuna/feature-flag'
import { IconButton, IconDollarSymbol, IconInfo, IconPencil, PersistentAlert } from '@nuna/tunic'

import { CashCard } from '../../../../CoverageCard/components/CashCard'
import { CoverageTypeIcon } from '../../../../CoverageTypeIcon'
import { CoverageList, CoverageListItem, CoverageSection, CoverageSectionHeading } from '../../../../CoverageTypography'
import { DefaultTherapyTypeListItems } from '../../../../DefaultTherapyTypeListItems'
import { useClientCoveragePanelContext } from '../../../context/ClientCoveragePanelContext'
import { PreferenceCoverageSection } from './PreferenceCoverageSection'

interface Props {
  details: CashCoverageDetailsFragment
}

export function CashDetails({ details }: Props) {
  const { autoCharge: autoChargeFeature } = useFeatureFlags()
  const role = useAuthDataContext().login?.role
  const { login } = useAuthDataContext()
  const { openCoverageForm } = useClientCoveragePanelContext()
  const { providers } = details

  return (
    <>
      <h3 className="h5 v-align">
        <CoverageTypeIcon className="mr-1" type={PaymentPreference.Cash} /> Credit Card Detail
      </h3>

      <CashCard details={details} />

      <div className="mt-1 v-align">
        {!coverageService.isCashValid(details) ? (
          <PersistentAlert className="mt-1 pr-2" intent="urgent">
            Expired card
          </PersistentAlert>
        ) : (
          autoChargeFeature && (
            <PersistentAlert icon={<IconInfo size={20} />} intent="information" className="mt-1">
              <small className="pr-1">
                Card will be <b>automatically</b> charged
              </small>
            </PersistentAlert>
          )
        )}

        {hasRole(role, [Role.Admin, Role.Patient]) && (
          <IconButton
            className="ml-auto"
            variant="secondary"
            tooltip="Edit"
            onClick={() => openCoverageForm(PaymentPreference.Cash)}
          >
            <IconPencil />
          </IconButton>
        )}
      </div>

      <PreferenceCoverageSection type={PaymentPreference.Cash} />

      <CoverageSection>
        <CoverageSectionHeading>Includes</CoverageSectionHeading>

        <CoverageList>
          <DefaultTherapyTypeListItems includeFamily />
        </CoverageList>
      </CoverageSection>

      <ProviderTabs
        className="mt-1"
        providers={login?.role === Role.Provider ? providers.filter(p => p.id === login.providerId) : providers}
        renderTabPanel={provider => (
          <Fragment key={provider.id}>
            <CoverageSection>
              <CoverageSectionHeading>
                {login?.role === Role.Provider ? '' : `${provider.firstName}'s `}Session Cost
              </CoverageSectionHeading>

              <CoverageList>
                <CoverageListItem adornment={<IconDollarSymbol size={16} />}>
                  {numberService.centsToFormattedDollars(provider.cashCost ?? undefined)} per session
                </CoverageListItem>
              </CoverageList>
            </CoverageSection>
          </Fragment>
        )}
      />
    </>
  )
}
