import { Autocomplete, AutocompleteProps, TextField } from '@mui/material'
import FuzzySearch from 'fuzzy-search'
import { ReactNode, useMemo, useState } from 'react'

import { StateOption, addressService } from '@nuna/core'

import { Checkbox } from './Checkbox/Checkbox'

const { statesAsOptions } = addressService

const STATES = statesAsOptions()

type LimitedAutocompleteProps = Omit<
  AutocompleteProps<StateOption, true, boolean | undefined, false>,
  'onChange' | 'options' | 'renderInput'
>

interface Props extends LimitedAutocompleteProps {
  value?: StateOption[]
  onChange: (states: StateOption[]) => void
  label?: string
  helperText?: string | ReactNode
  error?: boolean
  useCheckboxes?: boolean
}

export function StateAutocompleteMultiple({
  value,
  onChange,
  label = 'States',
  useCheckboxes = false,
  error = false,
  helperText,
  ...props
}: Props) {
  const [searchTerm, setSearchTerm] = useState('')

  const filteredSpecialties = useMemo<StateOption[]>(() => {
    const searcher = new FuzzySearch(STATES, ['label'], {
      caseSensitive: false,
    })
    if (!searchTerm) {
      return STATES
    }
    return searcher.search(searchTerm)
  }, [searchTerm])

  return (
    <Autocomplete
      {...props}
      multiple
      onChange={(_event, value) => {
        onChange(value)
      }}
      getOptionLabel={state => state.label}
      isOptionEqualToValue={(option, value) => option.value === value.value}
      options={filteredSpecialties}
      disableCloseOnSelect
      value={value}
      ListboxProps={{ className: 'MuiAutocomplete-listbox' }}
      onInputChange={(_event, searchTerm) => setSearchTerm(searchTerm)}
      renderInput={params => <TextField error={error} helperText={helperText} label={label} {...params} />}
      renderOption={
        useCheckboxes
          ? (renderOptionProps, option, { selected }) => (
              <li {...renderOptionProps}>
                <Checkbox checked={selected}>{option.label}</Checkbox>
              </li>
            )
          : undefined
      }
    />
  )
}
