import { css, styled } from '@mui/material'
import { HTMLAttributes, forwardRef, useMemo } from 'react'

import { IconCautionCircle, IconCheckCircle, IconError, IconInfo, IconProgressIndicator } from '../../icons'
import { greySet, tealSet, yellowSet } from '../../styles/colorSets'
import { borderGrey, eggshell, error } from '../../styles/colors'
import { shadowDepth } from '../../styles/shadows'
import { Intent } from '../../types/Intent'
import { csx } from '../../utils/csx'
import { DonutGauge } from '../DonutGauge/DonutGauge'

export type PersistentAlertIntent = Exclude<Intent, 'default'> | 'loading' | 'success' | 'gauge'

interface BaseProps {
  intent: PersistentAlertIntent
  small?: boolean
  current?: number
  target?: number
  icon?: React.ReactNode
}

export type PersistentAlertProps = BaseProps & HTMLAttributes<HTMLDivElement>
export type PersistentAlertButtonProps = BaseProps & HTMLAttributes<HTMLButtonElement>

function PersistentAlertInnerContent({
  intent,
  children,
  small = false,
  current = 0,
  target = 0,
  icon: customIcon,
}: PersistentAlertProps | PersistentAlertButtonProps) {
  const icon = useMemo(() => {
    if (customIcon) return customIcon
    const size = small ? 17 : 24

    switch (intent) {
      case 'success':
        return <IconCheckCircle size={size} style={{ color: tealSet[50].hex }} />
      case 'information':
        return <IconInfo size={size + 2} />
      case 'loading':
        return <IconProgressIndicator size={size} />
      case 'caution':
        return <IconCautionCircle size={size} style={{ color: yellowSet[50].hex }} />
      case 'urgent':
        return <IconError size={size} style={{ color: error }} />
      case 'gauge':
        return <DonutGauge size={size - 2} current={current} target={target} />
    }
  }, [customIcon, current, intent, small, target])
  return (
    <>
      {icon}
      <span className={csx(['persistent-alert-content', { 'caption text-medium ml-xs': small, 'ml-1': !small }])}>
        {children}
      </span>
    </>
  )
}

export const PersistentAlert = forwardRef<HTMLDivElement, PersistentAlertProps>(({ className, ...props }, ref) => {
  return (
    <PersistentAlertStyled
      ref={ref}
      small={props.small ?? false}
      className={csx([className, 'text-secondary v-align-inline'])}
      {...props}
    >
      <PersistentAlertInnerContent {...props} />
    </PersistentAlertStyled>
  )
})

export function PersistentAlertButton({ className, ...props }: PersistentAlertButtonProps) {
  return (
    <PersistentAlertButtonStyled
      small={props.small ?? false}
      className={csx([className, 'text-secondary v-align-inline'])}
      {...props}
    >
      <PersistentAlertInnerContent {...props} />
    </PersistentAlertButtonStyled>
  )
}

type PersistentAlertStyleProps = { small: boolean }
const sharedStyles = (props: PersistentAlertStyleProps) => css`
  background-color: ${eggshell};
  border: 1px solid ${borderGrey};
  border-radius: 36px;
  padding: ${props.small ? '6px var(--spacing-1-5) 6px var(--spacing-1)' : 'var(--spacing-1)'};
`

const PersistentAlertStyled = styled('div')<PersistentAlertStyleProps>`
  ${sharedStyles}
`

const PersistentAlertButtonStyled = styled('button')<{ small: boolean }>`
  ${sharedStyles}
  transition: background-color 0.3s, box-shadow 0.3s;
  .persistent-alert-content {
    text-decoration: underline;
  }
  &:hover {
    box-shadow: ${shadowDepth(1)};
    background-color: #fff;
    outline: 1px solid ${borderGrey};
  }
  &:focus-visible {
    outline: none;
    box-shadow: 0 0 0 7px ${greySet[15].hex};
  }
`
