import { IconProps } from './IconProps'

export function IconPreviewOff({ size = 24, color = 'currentColor', ...props }: IconProps) {
  return (
    <svg width={size} height={size} viewBox="0 0 56 56" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <g id="Preview Off">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M39.0146 6.28617C38.5433 5.9952 37.9252 6.14275 37.6362 6.61525L33.07 14.08C31.4198 13.6157 29.7143 13.3769 28 13.37C14.75 13.37 4 28.37 4 28.37C7.52178 33.0403 11.8912 37.0061 16.88 40.06L13.0691 46.0798C12.7725 46.5484 12.9138 47.1688 13.384 47.4627L15.9466 49.0644C16.4057 49.3513 17.0097 49.2208 17.3094 48.77L18.3 47.28L21.48 42.18L24.03 38.08L26.22 34.57L30.22 28.09L35.22 20.09L37.81 15.93L41.2402 10.2787L41.8879 9.24121C42.1814 8.77106 42.0366 8.15187 41.565 7.86071L39.0146 6.28617ZM27.91 22.35V21.35C24.1526 21.2943 21.0603 24.2927 21 28.05C20.9975 29.3331 21.3807 30.5874 22.1 31.65L19.85 35.26C18.0202 33.3049 17.0015 30.7278 17 28.05C17.0711 24.6891 18.6849 21.5478 21.3757 19.5327C24.0664 17.5175 27.5348 16.8527 30.78 17.73L27.91 22.35ZM52.0899 28.1677C52.0899 28.1677 41.3499 43.1677 28.0899 43.1677C27.5599 43.1677 27.0899 43.1677 26.5199 43.1077H26.4099L29.2999 38.5377C34.734 37.9491 38.8656 33.3834 38.9099 27.9177C38.9107 26.7041 38.701 25.4995 38.2899 24.3577L42.0899 18.3577L42.2599 18.4777C45.9822 21.2221 49.2924 24.4852 52.0899 28.1677Z"
          fill={color}
        />
      </g>
    </svg>
  )
}
