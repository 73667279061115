import { styled } from '@mui/material'
import { useLocation, useParams } from 'react-router-dom'

import { Role, usePatientDetailsQuery } from '@nuna/api'
import { useHasRole, useProviderId } from '@nuna/auth'
import { routeService } from '@nuna/core'
import { BelowTablet, FillButtonLink, GhostButtonLink, Skeleton, greySet, white } from '@nuna/tunic'

import { useClientAssessments } from '../hooks/useClientAssessments'
import { AssessmentAutomationSettings } from './AssessmentAutomationSettings'
import { AssessmentChart } from './AssessmentChart'
import { AssessmentResponses } from './AssessmentResponses'
import { NoAssessments } from './NoAssessments'

type RouteProps = {
  id?: string
}

export function AssessmentsDashboard() {
  const providerId = useProviderId()
  const { assessments, error, loading, isComplete } = useClientAssessments()
  const { pathname } = useLocation()
  const isTherapist = useHasRole(Role.Provider)
  const isTherapistOrAdminView = pathname.includes('clients')
  const { id: patientIdForTherapist = '' } = useParams<RouteProps>()
  const { data: patientData } = usePatientDetailsQuery({
    variables: { id: patientIdForTherapist },
    skip: !patientIdForTherapist,
  })

  const assessmentNavLink = routeService.conversationParticipantRoute(patientData?.patient.loginId || '')

  if (loading || error || !assessments) {
    return AssessmentsDashboardSkeleton()
  } else if (isComplete && !assessments.length) {
    return isTherapist ? (
      <AdminOrTherapistContainer style={{ paddingTop: '3rem' }}>
        <NoAssessments
          justifyContent="flex-start"
          header={`You’ll be able to review ${patientData?.patient.firstName}’s assessments here`}
          subheader={
            <>
              <GhostButtonLink to={assessmentNavLink}>Send an assessment</GhostButtonLink> to uncover and monitor their
              progress
            </>
          }
        />
        <AssessmentAutomationSettings patientId={patientData?.patient?.id ?? ''} providerId={providerId} />
      </AdminOrTherapistContainer>
    ) : (
      // this is only used for therapists and admins. Client NoAssessments is in ClientAssessments.tsx
      <NoAssessments
        header={`You’ll be able to review ${patientData?.patient.firstName}’s assessments here`}
        subheader={<>No assessments have been taken yet</>}
      />
    )
  }

  if (isTherapistOrAdminView) {
    return (
      <AdminOrTherapistContainer>
        <AssessmentResponses
          assessments={assessments}
          headerAction={<FillButtonLink to={assessmentNavLink}>Send an assessment</FillButtonLink>}
        />
        <AssessmentChart assessments={assessments} />
        <AssessmentAutomationSettings patientId={patientData?.patient?.id ?? ''} providerId={providerId} />
      </AdminOrTherapistContainer>
    )
  }

  return (
    <ClientContainer>
      <AssessmentChart assessments={assessments} />
      <AssessmentResponses assessments={assessments} displayTopBorder={true} />
    </ClientContainer>
  )
}

const Container = styled('div')`
  width: 100%;
`

const ClientContainer = styled('div')`
  width: 100%;
  overflow: auto;
  background-color: ${white.hex};
  border-radius: var(--border-radius);
  margin-bottom: var(--spacing-4);
  border: 1px solid ${greySet[15].hex};
  padding: var(--spacing-4);
`

const AdminOrTherapistContainer = styled('div')`
  display: flex;
  flex-direction: column;
  padding: 0rem 3rem;
  gap: 3rem;
  width: 100%;

  @media (${BelowTablet}) {
    padding: 0rem 1rem;
  }
`

export const AssessmentsDashboardSkeleton = () => {
  return (
    <Container>
      <Skeleton height={30} className="mb-2" />
    </Container>
  )
}
