import { usePatientContextQuery } from '@nuna/api'

export function usePatientId(patientId?: string) {
  const { data } = usePatientContextQuery({
    skip: !!patientId,
  })

  if (patientId) return patientId
  if (!data) {
    // patientContext should always be available from the apollo cache since the app doesn't render w/o it
    console.warn('patientContext was empty when usePatientId was called')
    return ''
  }

  return data.patientContext.patient.id
}
