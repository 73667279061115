import '@fullcalendar/react/dist/vdom'

import FullCalendar, { DatesSetArg, EventApi, EventClickArg, EventContentArg, EventInput } from '@fullcalendar/react'

import { DateClickArg } from '@fullcalendar/interaction'
import { styled } from '@mui/material'
import { pick } from 'lodash'
import moment, { Moment } from 'moment-timezone'
import { useEffect, useRef, useState } from 'react'
import { createPortal } from 'react-dom'
import { useNavigate } from 'react-router-dom'

import {
  AppointmentCurrentStatus,
  AppointmentInfoSummary,
  ProviderCalendarQuery,
  ProviderEnrollmentStatus,
  useInsurancePayersProviderAcceptsQuery,
  useProviderCalendarQuery,
} from '@nuna/api'
import { CalendarSyncDebugToggle } from '@nuna/appointment'
import { useIsAdmin } from '@nuna/auth'
import { CurrentTime, FullCalendarWrapper, TimeZoneControl, useAppointmentDrawerSearchParams } from '@nuna/common'
import { Audience, appointmentService, routeService } from '@nuna/core'
import {
  FillButton,
  IconArmchair,
  IconCancelAppointment,
  IconHalfway,
  IconPlus,
  IconVideoOutline,
  PersistentAlertButton,
  StatusLabel,
  Tooltip,
  greySet,
  yellowSet,
} from '@nuna/tunic'

import { ProviderScheduleControls } from './ProviderScheduleControls'

interface Props {
  providerId: string
  timezone?: string
  controlsContainer: HTMLDivElement | null
  audience: Audience
}
interface CustomEventProps {
  addressId?: string
  type?: string
}

interface CustomEventContentArg extends EventContentArg {
  event: EventApi & { extendedProps: CustomEventProps }
}

const weekDays = ['sunday', 'monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday']

const renderInsuranceStatusString = (insurancePayersStatusCount: Record<string, number>) => {
  return Object.entries(insurancePayersStatusCount).map(([key, value], idx) => {
    if (key === 'total') {
      return null
    }
    return (
      <span key={key}>
        {key} ({value}){idx < Object.keys(insurancePayersStatusCount).length - 1 && ', '}
      </span>
    )
  })
}

const getAppointmentSlots = (data?: ProviderCalendarQuery['providerCalendar']): EventInput[] => {
  const appts: AppointmentInfoSummary[] = data?.appointments || []
  if (appts.length === 0) return []

  return appts.map(appt => {
    return {
      id: appt.id,
      title: appt.patientName,
      start: appt.startDatetime,
      end: appt.endDatetime,
      date: appt.startDatetime,
      resourceId: appt.providerId,
      extendedProps: {
        ...pick(appt, ['addressId', 'currentStatus']),
        type: 'appointment',
      },
      backgroundColor: appt.currentStatus !== AppointmentCurrentStatus.Active ? yellowSet[15].hex : '',
      borderColor: appt.currentStatus !== AppointmentCurrentStatus.Active ? yellowSet[50].hex : '',
    }
  })
}

const getAvailabilitySlots = (data?: ProviderCalendarQuery['providerCalendar'], debug?: boolean): EventInput[] => {
  const constraints = [...(data?.availability?.blockPeriod || []), ...(data?.availability.calendarBlockPeriod || [])]

  if (constraints.length === 0) return []

  return constraints.map(away => ({
    title: 'ancillaryId' in away ? 'Personal Calendar' : 'Away',
    start: away.start,
    end: away.end,
    display: 'ancillaryId' in away && !debug ? 'background' : 'auto',
    ...('ancillaryId' in away
      ? {}
      : {
          borderColor: greySet[50].hex,
          textColor: greySet[70].hex,
          color: greySet[30].hex,
        }),
    extendedProps: {
      type: 'ancillaryId' in away ? 'availability' : 'away',
    },
  }))
}

const renderEventContent = (eventInfo: CustomEventContentArg) => {
  if (eventInfo.event.extendedProps?.type === 'availability') {
    return (
      <div className="fc-event-title-container">
        <div className="fc-event-title fc-sticky">{eventInfo.event.title}</div>
      </div>
    )
  }

  const isAway = eventInfo.event.extendedProps?.type === 'away'

  const isCanceled = [
    AppointmentCurrentStatus.CanceledByAdmin,
    AppointmentCurrentStatus.CanceledByPatient,
    AppointmentCurrentStatus.CanceledByProvider,
  ].includes(eventInfo.event.extendedProps.currentStatus)
  return (
    <div className={`fc-event-main-frame ${isCanceled ? 'canceled' : ''}`}>
      <div className="fc-event-time v-align" style={{ justifyContent: 'space-between' }}>
        {eventInfo.timeText}{' '}
        {(() => {
          if (isAway) return null
          if (isCanceled) {
            return <IconCancelAppointment isHovered={false} size={13} color={yellowSet[80].hex} />
          }
          if (eventInfo.event.extendedProps.addressId) {
            return <IconArmchair size={13} />
          }

          return <IconVideoOutline size={13} />
        })()}
      </div>
      <div className="fc-event-title-container">
        <div className="fc-event-title fc-sticky">{eventInfo.event.title}</div>
      </div>
    </div>
  )
}

export function ProviderSchedule({ providerId, timezone = moment.tz.guess(), controlsContainer, audience }: Props) {
  const isAdmin = useIsAdmin()
  const navigate = useNavigate()
  const {
    openAppointmentDetailDrawer,
    openScheduleAppointmentDrawer,
    drawerConfig: { drawerOpen },
  } = useAppointmentDrawerSearchParams()

  // base state
  const [isSyncDebugEnabled, setIsSyncDebugEnabled] = useState(false)
  const [businessHours, setBusinessHours] = useState<EventInput[]>([
    {
      daysOfWeek: [0, 1, 2, 3, 4, 5, 6],
      startTime: '08:00',
      endTime: '23:00',
    },
  ])
  const calendarRef = useRef<FullCalendar>(null)

  // controls
  const [date, setDate] = useState<{ start: Moment; end: Moment } | null>({
    start: moment().startOf('week'),
    end: moment().endOf('week'),
  }) // Update the type of 'date' state variable
  const handleDatesSet = ({ start, end }: DatesSetArg) => {
    setDate({ start: moment(start), end: moment(end).subtract('1', 'm') })
  }
  const timezoneDisplay = timezone && moment.tz(date?.start.toISOString() ?? '', timezone).zoneAbbr()

  const { data: calendarData, refetch: refetchCalendar } = useProviderCalendarQuery({
    variables: {
      providerId,
      start: date?.start,
      end: date?.end,
    },
    fetchPolicy: 'network-only',
  })
  const { data: payersData } = useInsurancePayersProviderAcceptsQuery({
    variables: { providerId: providerId ?? '' },
    skip: !providerId,
    fetchPolicy: 'cache-first',
    nextFetchPolicy: 'cache-first',
  })

  const data = calendarData?.providerCalendar
  const events = [
    ...getAppointmentSlots(calendarData?.providerCalendar),
    ...getAvailabilitySlots(calendarData?.providerCalendar, isSyncDebugEnabled),
  ]

  useEffect(() => {
    // kinda ugly but we need to refetch events when appointment is canceled or scheduled and this is the only way to do it as of right now
    refetchCalendar()
  }, [drawerOpen, refetchCalendar])

  const handleOnNewAppointment = () => {
    openScheduleAppointmentDrawer(providerId)
  }

  const handleDateClick = async (selection: DateClickArg) => {
    if (moment(selection.date).isAfter(moment())) {
      openScheduleAppointmentDrawer(providerId, {
        timeSlot: appointmentService.timeSlotToTimeStamp({
          start: moment(selection.date).toDate(),
          end: moment(selection.date).add(50, 'minutes').toDate(),
        }),
      })

      calendarRef.current?.getApi().select({
        start: moment(selection.date).toDate(),
        end: moment(selection.date).add(50, 'minutes').toDate(),
      })
    }
  }

  const handleEventClick = (eventArg: EventClickArg) => {
    if (eventArg.event.groupId.startsWith('debug')) {
      // eslint-disable-next-line no-console
      console.log('Debug event:', {
        id: eventArg.event.id,
        calendarId: eventArg.event.groupId.replace('debug-', ''),
        calendarName: eventArg.event.title,
      })
      return
    }

    openAppointmentDetailDrawer(eventArg.event.id)
  }

  useEffect(() => {
    const constraints = data?.availability
    const providerTimezone = data?.timezone ?? timezone

    if (!constraints || !providerTimezone) return

    const eventInputs: EventInput[] = []

    constraints.allowDayAndTime.forEach(constraint => {
      const { day, start, end } = constraint // Data is like: { day: 'Monday', start: '08:00', end: '17:00' }

      // Combine day and time into a single moment object
      const startTimeMoment = moment.tz(`${day} ${start}`, 'dddd HH:mm', providerTimezone)
      const endTimeMoment = moment.tz(`${day} ${end}`, 'dddd HH:mm', providerTimezone)

      // Convert to viewing timezone
      const startTimeConverted = startTimeMoment.clone().tz(timezone)
      const endTimeConverted = endTimeMoment.clone().tz(timezone)

      // Handle potential day change
      const startDayConverted = startTimeConverted.format('dddd').toLowerCase()
      const endDayConverted = endTimeConverted.format('dddd').toLowerCase()

      eventInputs.push({
        daysOfWeek: [weekDays.indexOf(startDayConverted)],
        startTime: startTimeConverted.format('HH:mm'),
        endTime: startTimeConverted.isSame(endTimeConverted, 'day') ? endTimeConverted.format('HH:mm') : '23:59',
      })

      if (!startTimeConverted.isSame(endTimeConverted, 'day')) {
        eventInputs.push({
          daysOfWeek: [weekDays.indexOf(endDayConverted)],
          startTime: '00:00',
          endTime: endTimeConverted.format('HH:mm'),
        })
      }
    })

    for (const extra of constraints.allowPeriod) {
      const { start, end } = extra
      eventInputs.push({
        daysOfWeek: [moment(start).day()],
        startTime: moment(start).format('HH:mm'),
        endTime: moment(end).format('HH:mm'),
      })
    }

    setBusinessHours(eventInputs)
  }, [data, timezone])

  const insurancePayersStatusCount = payersData?.insurancePayersProviderAccepts.reduce(
    (final: Record<string, number>, cur) => {
      const cleanedStatus = cur.enrollmentStatus === ProviderEnrollmentStatus.Enrolled ? 'enrolled' : 'pending'
      final['total'] += 1
      if (final[cleanedStatus]) {
        final[cleanedStatus] += 1
      } else {
        final[cleanedStatus] = 1
      }
      return final
    },
    { total: 0 },
  )

  return (
    <>
      {controlsContainer &&
        createPortal(
          <ProviderScheduleControls
            date={date}
            calendarRef={calendarRef}
            renderPrimaryAction={
              <span className="ml-auto v-align">
                {audience === 'admin' ? (
                  <CalendarSyncDebugToggle
                    calendarRef={calendarRef}
                    providerId={providerId}
                    date={date}
                    isEnabled={isSyncDebugEnabled}
                    onToggle={setIsSyncDebugEnabled}
                  />
                ) : null}
                {insurancePayersStatusCount && insurancePayersStatusCount?.total > 0 && (
                  <PersistentAlertButton
                    icon={
                      insurancePayersStatusCount &&
                      insurancePayersStatusCount.total !== insurancePayersStatusCount.enrolled && (
                        <IconHalfway color="#017d8d" size={20} />
                      )
                    }
                    intent="success"
                    onClick={() => navigate(routeService.providerInsurance)}
                    className="mr-2"
                  >
                    Insurance {renderInsuranceStatusString(insurancePayersStatusCount ?? {})}
                  </PersistentAlertButton>
                )}
                <FillButton onClick={handleOnNewAppointment} data-component="schedule-new-appointment-button">
                  <IconPlus className="mr-1" size={14} /> New Appointment
                </FillButton>
              </span>
            }
          />,

          controlsContainer,
        )}
      <TimeZoneContainer>
        {/* Admins see a timezone switcher */}
        {isAdmin && <TimeZoneControl className="timezone-label mr-1" />}

        {/* Providers see their timezone */}
        {!isAdmin && timezoneDisplay && timezone && (
          <Tooltip
            content={
              <span>
                {timezone.replace(/_/g, ' ')} <br />
                Current time: <CurrentTime timezone={timezone} /> <br />
                <br />
                <span>
                  This is the time detected by your device. If this looks incorrect your device may be set to the
                  wrong&nbsp;timezone.
                </span>
              </span>
            }
          >
            <StatusLabel className="timezone-label mr-1">{timezoneDisplay}</StatusLabel>
          </Tooltip>
        )}
        <FullCalendarWrapper
          timeZone={timezone ?? 'local'}
          calendarRef={calendarRef}
          dateClick={handleDateClick}
          eventClick={handleEventClick}
          selectMirror={true}
          selectOverlap={false}
          unselectAuto={true}
          events={events}
          eventContent={renderEventContent}
          businessHours={businessHours}
          datesSet={handleDatesSet}
        />
      </TimeZoneContainer>
    </>
  )
}

const TimeZoneContainer = styled('div')`
  height: 100%;
  display: flex;
  align-items: flex-start;
  position: relative;

  .timezone-label {
    position: absolute;
    top: 1rem;
    z-index: 2;
  }
`
