import { useVideoTrack } from '@daily-co/daily-react'

import { ParticipantVideoSizer } from './ParticipantVideoSizer'

export function ParticipantVideo({ sessionId }: { sessionId: string }) {
  const videoTrack = useVideoTrack(sessionId)

  if (videoTrack.isOff) return null

  return <ParticipantVideoSizer sessionId={sessionId} />
}
