import * as Yup from 'yup'

import { BasicSpecialtyFragment, CaseloadExpectation } from '@nuna/api'
import { Illustration } from '@nuna/common'
import { formService, specialtiesService } from '@nuna/core'
import { Grid, Radio, RadioGroup } from '@nuna/tunic'

import { specialtyToSpecialtyInput } from '../../ProviderSignupIntakeContext'
import teamImgSrc from '../../img/Team.png'
import { IntakeStepContainer } from '../../shared/IntakeStepContainer'
import { IntakeHeader } from '../../shared/IntakeTypography'
import { ProviderIntakeFormStepper } from '../../shared/ProviderIntakeFormStepper'
import { SpecialtiesChipsSelect } from '../../shared/SpecialtiesChipSelect'
import {
  ProviderSignupFormSchema,
  ProviderSignupFormValues,
  ProviderSignupIntakeFormProps,
  SaveProviderIntakeInput,
} from '../../shared/provider-signup-intake-types'
import { useProviderSignupIntakeStepSetup } from '../../shared/useProviderSignupIntakeStepSetup'
import { GenderExclusionsSelect } from './GenderExclusionsSelect'

const { composeHelperTextWithError } = formService

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore - ENG-4528
const validationSchema: ProviderSignupFormSchema = Yup.object({
  ageSpecialties: Yup.array<BasicSpecialtyFragment>(Yup.object()).min(1, 'Please select at least one age range'),
  populationSpecialties: Yup.array<BasicSpecialtyFragment>(Yup.object()),
  challengeSpecialties: Yup.array<BasicSpecialtyFragment>(Yup.object()).min(
    5,
    'Please select at least five challenges treated',
  ),
  expectedCaseloadUsage: Yup.mixed<CaseloadExpectation>()
    .oneOf(
      Object.values(CaseloadExpectation).filter(v => v !== CaseloadExpectation.Unknown),
      'Please indicate why you are choosing Tava',
    )
    .required('Please indicate why you are choosing Tava'),
})

export function Skills({
  values,
  setFieldValue,
  errors,
  touched,
  setFieldTouched,
  saveIntakeLoading,
  validateForm,
  isValid,
}: ProviderSignupIntakeFormProps) {
  useProviderSignupIntakeStepSetup({ validationSchema, buildSaveValues }, validateForm)
  const genderExclusionValue =
    values.populationSpecialties.find(specialty =>
      [specialtiesService.MEN_ONLY, specialtiesService.WOMEN_ONLY].includes(specialty.name),
    ) ?? null

  const handleGenderExclusionChange = (exclusion: BasicSpecialtyFragment | null) => {
    const filteredPopSpecialties = values.populationSpecialties.filter(
      specialty => ![specialtiesService.MEN_ONLY, specialtiesService.WOMEN_ONLY].includes(specialty.name),
    )
    if (exclusion) {
      filteredPopSpecialties.push(exclusion)
    }

    setFieldValue('populationSpecialties', filteredPopSpecialties)
  }

  const specialtySelectProps = (key: keyof ProviderSignupFormValues) => {
    return {
      ...{
        onChange: (v: BasicSpecialtyFragment[]) => setFieldValue(key, v),
        onBlur: () => setFieldTouched(key),
        value: values[key] as BasicSpecialtyFragment[],
      },
      ...composeHelperTextWithError('', errors[key], !!touched[key]),
    }
  }

  return (
    <IntakeStepContainer>
      <div style={{ paddingTop: 24, paddingBottom: 24 }}>
        <Grid container spacing={6} alignItems="center" direction="row-reverse">
          <Grid
            size={{
              xs: 12,
              md: 5,
            }}
          >
            <Illustration src={teamImgSrc} alt="team" />
          </Grid>
          <Grid
            size={{
              xs: 12,
              md: 7,
            }}
          >
            <IntakeHeader type="h1">Who are you skilled at helping, and what can you help with?</IntakeHeader>
            <p className="large text-secondary">This lets us cast the net wide, so lots of clients can find you.</p>
          </Grid>
        </Grid>
      </div>

      <IntakeHeader type="h2">Client Focus</IntakeHeader>
      <IntakeHeader type="h3" md={{ mt: 2 }}>
        Ages
      </IntakeHeader>
      <SpecialtiesChipsSelect
        dataTestId="age-specialties"
        specialtyFilter={specialtiesService.getProviderAgesSpecialties}
        {...specialtySelectProps('ageSpecialties')}
      />
      <IntakeHeader type="h3" md={{ mt: 3 }} xs={{ mt: 3 }}>
        Unique Populations (optional)
      </IntakeHeader>
      <SpecialtiesChipsSelect
        dataTestId="population-specialties"
        specialtyFilter={specialtiesService.getProviderPopulationSpecialtiesExludingGender}
        {...specialtySelectProps('populationSpecialties')}
      />
      <IntakeHeader type="h3" md={{ mt: 3 }} xs={{ mt: 3 }}>
        Gender Exclusions
      </IntakeHeader>
      <GenderExclusionsSelect value={genderExclusionValue} onChange={handleGenderExclusionChange} />

      <IntakeHeader type="h3" md={{ mt: 5 }} xs={{ mt: 5 }}>
        Challenges Treated
      </IntakeHeader>
      <div className="text-secondary my-2">Select at least five</div>
      <SpecialtiesChipsSelect
        dataTestId="challenge-specialties"
        specialtyFilter={specialtiesService.getChallenges}
        {...specialtySelectProps('challengeSpecialties')}
      />
      <IntakeHeader type="h2" md={{ mt: 5 }} xs={{ mt: 4 }}>
        Why are you choosing Tava?
      </IntakeHeader>
      <RadioGroup {...composeHelperTextWithError('', errors.expectedCaseloadUsage, !!touched.expectedCaseloadUsage)}>
        <Radio
          dataTestId="why-tava-1"
          name="expectedCaseloadUsage"
          checked={values.expectedCaseloadUsage === CaseloadExpectation.FullTime}
          onChange={() => setFieldValue('expectedCaseloadUsage', CaseloadExpectation.FullTime)}
        >
          I'm relying on Tava Referrals for my full caseload
        </Radio>
        <Radio
          dataTestId="why-tava-2"
          name="expectedCaseloadUsage"
          checked={values.expectedCaseloadUsage === CaseloadExpectation.PartTime}
          onChange={() => setFieldValue('expectedCaseloadUsage', CaseloadExpectation.PartTime)}
        >
          I'm supplementing an existing caseload with new Tava Referrals
        </Radio>
        <Radio
          dataTestId="why-tava-3"
          name="expectedCaseloadUsage"
          checked={values.expectedCaseloadUsage === CaseloadExpectation.SideHustle}
          onChange={() => setFieldValue('expectedCaseloadUsage', CaseloadExpectation.SideHustle)}
        >
          I have a full-time job and am moonlighting on the side with Tava
        </Radio>
      </RadioGroup>
      <ProviderIntakeFormStepper
        currentSection="profile"
        className="mt-6"
        mutationLoading={saveIntakeLoading}
        isValid={isValid}
        step="professional"
      />
    </IntakeStepContainer>
  )
}

function buildSaveValues(formValues: ProviderSignupFormValues): SaveProviderIntakeInput {
  const { ageSpecialties, populationSpecialties, challengeSpecialties, expectedCaseloadUsage } = formValues
  return {
    ageSpecialties: ageSpecialties.map(specialtyToSpecialtyInput),
    populationSpecialties: populationSpecialties.map(specialtyToSpecialtyInput),
    challengeSpecialties: challengeSpecialties.map(specialtyToSpecialtyInput),
    expectedCaseloadUsage,
  }
}
