export enum DocumentType {
  Attestation = 'ATTESTATION',
  BackgroundCheck = 'BACKGROUND_CHECK',
  BenefitsGuide = 'BENEFITS_GUIDE',
  CaqhForm = 'CAQH_FORM',
  CredentialingNotificationLetter = 'CREDENTIALING_NOTIFICATION_LETTER',
  CredentialingPacket = 'CREDENTIALING_PACKET',
  ClientDocument = 'CLIENT_DOCUMENT',
  CompanyLogo = 'COMPANY_LOGO',
  InsuranceCardBack = 'INSURANCE_CARD_BACK',
  InsuranceCardFront = 'INSURANCE_CARD_FRONT',
  LiabilityInsurance = 'LIABILITY_INSURANCE',
  MessageAttachment = 'MESSAGE_ATTACHMENT',
  PartnerLogo = 'PARTNER_LOGO',
  PatientImage = 'PATIENT_IMAGE',
  PayerApprovalLetter = 'PAYER_APPROVAL_LETTER',
  PayerEnrollmentForm = 'PAYER_ENROLLMENT_FORM',
  PayerLogo = 'PAYER_LOGO',
  PayerRosterSource = 'PAYER_ROSTER_SOURCE',
  PayerTavaRoster = 'PAYER_TAVA_ROSTER',
  ProfessionalLiabilityInsurance = 'PROFESSIONAL_LIABILITY_INSURANCE',
  PracticeAddressW9 = 'PRACTICE_ADDRESS_W9',
  ProviderImage = 'PROVIDER_IMAGE',
  Resume = 'RESUME',
  StateLicense = 'STATE_LICENSE',
  UserCustomDocument = 'USER_CUSTOM_DOCUMENT',
  TavaEin = 'TAVA_EIN',
}
