import '@fullcalendar/react/dist/vdom'

import FullCalendar from '@fullcalendar/react'

import { Moment } from 'moment-timezone'
import { ReactNode, RefObject } from 'react'

import { IconButton, IconChevronThick, OutlineButton } from '@nuna/tunic'

interface ProviderScheduleControlsProps {
  calendarRef: RefObject<FullCalendar>
  date: { start: Moment; end: Moment } | null
  renderPrimaryAction?: ReactNode
}

export function ProviderScheduleControls({ calendarRef, date, renderPrimaryAction }: ProviderScheduleControlsProps) {
  return (
    <div className="v-align">
      <OutlineButton
        variant="secondary"
        className="mr-2"
        onClick={() => {
          calendarRef.current?.getApi().today()
        }}
      >
        This Week
      </OutlineButton>

      <IconButton
        tooltip="Previous week"
        variant="secondary"
        onClick={() => {
          calendarRef.current?.getApi().prev()
        }}
      >
        <IconChevronThick size={16} />
      </IconButton>

      <IconButton
        tooltip="Next week"
        variant="secondary"
        onClick={() => {
          calendarRef.current?.getApi().next()
        }}
        className="mr-2"
      >
        <IconChevronThick direction="right" size={16} />
      </IconButton>

      {date && (
        <h2 style={{ minWidth: 180 }} className="large sans-serif text-secondary text-normal mb-0 mr-3">
          {date.start.format('MMM D')} &ndash; {date.end.format('MMM D, YYYY')}
        </h2>
      )}

      {renderPrimaryAction}
    </div>
  )
}
