import { IconProps } from './IconProps'

export function IconSessions({ size = 24, color = 'currentColor', ...props }: IconProps) {
  const height = (21 / 20) * size

  return (
    <svg width={height} height={height} viewBox="0 0 20 21" fill={color} xmlns="http://www.w3.org/2000/svg" {...props}>
      <path d="M14.5 0.75L3.5 0.75C2.99374 0.75 2.58333 1.16041 2.58333 1.66667V2.58333L15.4167 2.58333V1.66667C15.4167 1.16041 15.0063 0.75 14.5 0.75ZM2.58333 4.41667L15.4167 4.41667C15.9229 4.41667 16.3333 4.82707 16.3333 5.33333V6.25L1.66667 6.25V5.33333C1.66667 4.82707 2.07707 4.41667 2.58333 4.41667ZM14.5 8.08333L3.5 8.08333C1.98122 8.08333 0.75 9.31455 0.75 10.8333L0.75 14.5C0.75 16.0188 1.98122 17.25 3.5 17.25L14.5 17.25C16.0188 17.25 17.25 16.0188 17.25 14.5L17.25 10.8333C17.25 9.31455 16.0188 8.08333 14.5 8.08333ZM2.58333 10.8333C2.58333 10.3271 2.99374 9.91667 3.5 9.91667L14.5 9.91667C15.0063 9.91667 15.4167 10.3271 15.4167 10.8333V14.5C15.4167 15.0063 15.0063 15.4167 14.5 15.4167L3.5 15.4167C2.99374 15.4167 2.58333 15.0063 2.58333 14.5L2.58333 10.8333Z" />
    </svg>
  )
}
