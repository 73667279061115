import { styled } from '@mui/material'
import React from 'react'

import { IconCamera } from '../icons'
import { greySet } from '../styles/colorSets'
import { Avatar } from './Avatar/Avatar'

interface AvatarUploadPlaceholderProps extends React.HTMLAttributes<HTMLLabelElement> {
  htmlFor?: string // no reason this shouldn't be coming from the extended types but idk?
  error?: boolean
}

export function AvatarUploadPlaceholder({ error, ...props }: AvatarUploadPlaceholderProps) {
  return (
    <AvatarUploadLabel {...props}>
      <AvatarResponsive error={error} size="lg" />
      <IconCamera
        size={38}
        color={greySet[15].hex}
        className="absolute-center"
        style={{
          pointerEvents: 'none',
        }}
      />
    </AvatarUploadLabel>
  )
}

const AvatarUploadLabel = styled('label')`
  display: inline-flex;
  align-items: center;
  cursor: pointer;
  max-width: 300px;
  position: relative;
  width: 100%;
`

const AvatarResponsive = styled(Avatar)`
  height: auto;
  margin: auto;
  max-width: 300px;
  width: 100%;
`
AvatarResponsive.defaultProps = { uploadPrompt: true }
