import { styled } from '@mui/material'
import { noop } from 'lodash'
import moment from 'moment'
import { HTMLAttributes, useCallback, useEffect, useState } from 'react'

import { IconClock, borderGrey, yellowSet } from '@nuna/tunic'

interface CountDownProps extends HTMLAttributes<HTMLSpanElement> {
  startDatetime: string | null | undefined
  inline: boolean
  renderWhenPastDue?: JSX.Element
}

const MINUTES_BEFORE_START_TO_END_COUNTDOWN = 15

export function Countdown({ startDatetime, renderWhenPastDue, inline, className }: CountDownProps) {
  const [countDownString, setCountDownString] = useState<string>()
  const [sessionStartInPast, setSessionStartInPast] = useState(false)

  const trySetCountdown = useCallback(
    (interval?: NodeJS.Timeout) => {
      if (sessionStartInPast && interval) {
        clearInterval(interval)
      }
      const now = moment()
      const start = moment(startDatetime)
      if (now.clone().add(MINUTES_BEFORE_START_TO_END_COUNTDOWN, 'minutes') > start) {
        setSessionStartInPast(true)
      } else {
        const diff = moment(start).diff(now)
        const duration = moment.duration(diff)
        setCountDownString(formatDuration(duration))
      }
    },
    [setSessionStartInPast, setCountDownString, sessionStartInPast, startDatetime],
  )

  useEffect(() => {
    if (startDatetime) {
      trySetCountdown()
      const interval = setInterval(() => {
        trySetCountdown(interval)
      }, 1000)
      return () => {
        if (interval) {
          clearInterval(interval)
        }
      }
    }
    return noop
  }, [startDatetime, trySetCountdown])

  if (!startDatetime) {
    return null
  }

  if (sessionStartInPast && renderWhenPastDue) {
    return renderWhenPastDue
  }

  if (sessionStartInPast) {
    return null
  }

  if (!countDownString) {
    return null
  }

  if (inline) {
    return (
      <InlineDisplay className={`v-align ${className ?? ''}`}>
        <IconClock size={16} className="mr-1" /> Session starts in {countDownString}
      </InlineDisplay>
    )
  }

  return (
    <Wrapper className={className}>
      <h3 className="h5">Your session starts in:</h3>
      <CountdownBadge className="v-align">
        <IconClock size={16} className="mr-1" /> {countDownString}
      </CountdownBadge>
    </Wrapper>
  )
}

const Wrapper = styled('div')`
  align-items: center;
  display: flex;
  padding: 2rem 0;
  flex-direction: column;
  width: 100%;
`

const CountdownBadge = styled('span')`
  padding: 0.5rem;

  border: 1px solid ${borderGrey};
  border-radius: 6px;
`

const InlineDisplay = styled('div')`
  border: 1px solid ${yellowSet[30].transparency(0.45)};
  border-radius: 6px;
  color: ${yellowSet[50].hex};
  padding: 0.5rem;
`

function hoursUnitLabel(hours: number): string {
  if (hours > 1) {
    return 'hrs'
  }
  return 'hr'
}

function formatDuration(duration: moment.Duration): string {
  const days = duration.days()
  const hours = duration.hours()
  const minutes = duration.minutes()

  if (days > 0) {
    return `${days} days, ${hours} ${hoursUnitLabel(hours)} and ${minutes} min`
  }

  if (hours > 0) {
    return `${hours} ${hoursUnitLabel(hours)} and ${minutes} min`
  }

  return `${minutes} min`
}
