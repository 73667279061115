/* eslint-disable @typescript-eslint/no-explicit-any */
import * as Yup from 'yup'
import { Formik } from 'formik'
import { capitalize, startCase } from 'lodash'

import { WriteOffReasonCode, useWriteOffAppointmentMutation } from '@nuna/api'
import { errorService } from '@nuna/core'
import { FillButton, Select, TextField, toast } from '@nuna/tunic'

const writeOffSchema = Yup.object().shape({
  reasonCode: Yup.string().oneOf(Object.values(WriteOffReasonCode)).required(),
  reason: Yup.string().optional(),
})

export const WriteOffForm = ({ appointmentId, onComplete }: { appointmentId: string; onComplete: () => void }) => {
  const [writeOffAppointment, { loading, error }] = useWriteOffAppointmentMutation()

  const handleSubmit = async (values: any, { resetForm }: any) => {
    try {
      await writeOffAppointment({
        variables: {
          appointmentId,
          reasonCode: values.reasonCode,
          reason: values.reason,
        },
        refetchQueries: ['InternalAppointmentDetail'],
      })

      resetForm()

      onComplete()
      toast.success('Wrote off appointment')
    } catch (e) {
      // Give them the graphql error if present.
      // could be something else other then, that which is undeseriable.
      // but graphql shrug
      console.error(e)
      toast.urgent(errorService.transformUserGraphqlError(e, 'Failed to write off appointment'))
    }
  }

  return (
    <Formik
      validationSchema={writeOffSchema}
      initialValues={{
        reasonCode: WriteOffReasonCode.Administrative,
        reason: undefined,
      }}
      onSubmit={handleSubmit}
    >
      {({ values, handleChange, handleSubmit, errors, touched }) => (
        <form onSubmit={handleSubmit}>
          <h1 className="h4 mb-2">Write Off Appointment</h1>

          <Select
            value={values.reasonCode}
            name="reasonCode"
            onChange={handleChange}
            id="reason-code"
            label="Reason Code"
            className="mb-3"
          >
            {Object.values(WriteOffReasonCode)
              .sort()
              .map(reason => (
                <option value={reason} key={reason}>
                  {capitalize(startCase(reason))}
                </option>
              ))}
          </Select>

          <TextField
            id="reason"
            placeholder="Write a care coordination note that will be included on the patient history"
            rows={4}
            name="reason"
            onChange={handleChange}
            value={values.reason ?? ''}
            error={!!(touched.reason && errors.reason)}
            helperText={touched.reason && errors.reason}
            multiline
          />

          {!!error && <div>Error occurred saving {errorService.transformGraphQlError(error)}</div>}

          <FillButton isLoading={loading} type="submit" style={{ width: '100%' }} className="mt-2">
            Write Off Appointment
          </FillButton>
        </form>
      )}
    </Formik>
  )
}
