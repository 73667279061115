import { IconRelationship, IconUser } from '@nuna/tunic'

import { CoverageIcon, CoverageListItem } from './CoverageTypography'

interface Props {
  includeFamily?: boolean
}

export function DefaultTherapyTypeListItems({ includeFamily = true }: Props) {
  return (
    <>
      <CoverageListItem
        adornment={
          <CoverageIcon>
            <IconUser size={18} />
          </CoverageIcon>
        }
      >
        Individual Therapy
      </CoverageListItem>
      {includeFamily && (
        <CoverageListItem
          adornment={
            <CoverageIcon>
              <IconRelationship size={20} />
            </CoverageIcon>
          }
        >
          Couples or Family Therapy
          <br />
          <p style={{ lineHeight: 1.2 }} className="mt-1 mb-0 caption italic">
            This coverage can be used for couples or family therapy but availability may vary by provider
          </p>
        </CoverageListItem>
      )}
    </>
  )
}
