enum DocumentPath {
  PayerLogo = '/payer-logo/',
}
function documentUrl(
  documentPath: DocumentPath,
  id: string | null | undefined,
  cacheBuster: string | null | undefined,
) {
  if (!id) {
    return ''
  }
  return `${import.meta.env['VITE_API_ENDPOINT']}${documentPath}${id}${cacheBuster ? `?v=${cacheBuster}` : ''}`
}

export const documentUtils = {
  DocumentPath,
  documentUrl,
}
