import { CSSProperties, HTMLAttributes } from 'react'

import { csx } from '../../utils/csx'

interface Props {
  height?: number
  width?: number
  dimensionUnits?: 'px' | 'rem' | '%'
  fullWidth?: boolean
}

export function Skeleton({
  height,
  width,
  style = {},
  className,
  dimensionUnits = 'rem',
  fullWidth = false,
  ...props
}: Props & HTMLAttributes<HTMLDivElement>) {
  const dimensions: CSSProperties = {
    height: height ? `${height}${dimensionUnits}` : undefined,
    width: fullWidth ? '100%' : width ? `${width}${dimensionUnits}` : undefined,
  }

  return <div style={{ ...style, ...dimensions }} className={csx(['loading', className])} {...props}></div>
}
