import * as Sentry from '@sentry/browser'
import * as Yup from 'yup'
import { LazyQueryExecFunction } from '@apollo/client'
import { styled } from '@mui/material'
import { FormControl, InputLabel, MenuItem, Select } from '@mui/material'
import { Formik } from 'formik'
import { range } from 'lodash'
import moment from 'moment'
import { useRef } from 'react'

import {
  BasicPatientFragment,
  Cadence,
  MultipleAppointmentsInfoQuery,
  MultipleAppointmentsInfoQueryVariables,
} from '@nuna/api'
import { AppointmentLocationSelect, AppointmentLocationSelectValue } from '@nuna/appointment'
import { useAppointmentDrawerSearchParams } from '@nuna/common'
import { FillButton, SegmentedControl, toast } from '@nuna/tunic'

import { DrawerCaption } from '../../DrawerCaption'
import { DrawerSubheading } from '../../DrawerSubheading'
import { NewCard } from '../shared'

interface BookingInfoValues {
  cadence: Cadence
  day: number | ''
  time: number | ''
  multiAddressId: string
}

const bookingInfoSchema = Yup.object().shape<BookingInfoValues>({
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  cadence: Yup.string<Cadence>().required(),
  day: Yup.number().required(),
  time: Yup.number().required(),
  multiAddressId: Yup.string().required(),
})

interface ScheduleFormProps {
  patient: BasicPatientFragment
  desiredApptCount: number
  providerId: string
  fetchMultipleAppointmentsInfo: LazyQueryExecFunction<
    MultipleAppointmentsInfoQuery,
    MultipleAppointmentsInfoQueryVariables
  >

  isLoadingAppointmentsInfo?: boolean
  setMultiSchedulerLocation: (selectedAddress: AppointmentLocationSelectValue) => void
}

export function NewAppointmentMultiScheduler({
  desiredApptCount,
  patient,
  providerId,
  fetchMultipleAppointmentsInfo,
  isLoadingAppointmentsInfo,
  setMultiSchedulerLocation,
}: ScheduleFormProps) {
  const {
    drawerConfig: { timeSlot, paymentRequired },
  } = useAppointmentDrawerSearchParams()
  const menuRef = useRef<HTMLDivElement | null>(null)

  const handleSubmit = async (values: BookingInfoValues) => {
    if (values.day !== '' && values.time !== '') {
      const currentDayOfWeek = moment().day()

      const date = moment()
        .day(currentDayOfWeek >= values.day ? values.day + 7 : values.day)
        .hour(Math.floor(values.time))
        .minute((values.time % 1) * 60)

      try {
        const response = await fetchMultipleAppointmentsInfo({
          variables: {
            cadence: values.cadence,
            patientId: patient.id,
            providerId,
            desiredApptCount: desiredApptCount,
            dateTime: date,
            paymentRequired,
          },
        })

        toast.urgent(response.error ? response.error.message : 'Error loading available appointment slots')
      } catch (e) {
        toast.urgent(`Error loading available appointment slots`)

        Sentry.captureEvent({
          message: 'Failed to load recurring appointment slots',
          extra: {
            error: (e as Error).message,
          },
        })
      }
    }
  }

  return (
    <Formik
      initialValues={{
        desiredApptCount: 0,
        cadence: Cadence.Weekly,
        day: timeSlot?.start ? timeSlot.start.day() : '',
        time: timeSlot?.start ? timeSlot.start.hour() + timeSlot.start.minute() / 60 : '',
        multiAddressId: 'Virtual',
      }}
      onSubmit={handleSubmit}
      validationSchema={bookingInfoSchema}
    >
      {({ values, handleChange, setFieldValue, handleSubmit }) => (
        <form onSubmit={handleSubmit}>
          <NewCard>
            <DrawerSubheading className="mb-0">When?</DrawerSubheading>

            <div className="v-align mb-4">
              <FormControl fullWidth style={{ flex: '1 1 176px' }}>
                <PlaceholderHackLabel disableAnimation id="day-of-week">
                  Day of Week
                </PlaceholderHackLabel>

                <Select
                  value={values.day}
                  onChange={e => setFieldValue('day', parseInt(e.target.value as string, 10))}
                  labelId="day-of-week"
                >
                  {[
                    ['0', 'Sundays'],
                    ['1', 'Mondays'],
                    ['2', 'Tuesdays'],
                    ['3', 'Wednesdays'],
                    ['4', 'Thursdays'],
                    ['5', 'Fridays'],
                    ['6', 'Saturdays'],
                  ].map(([value, label]) => (
                    <MenuItem key={value} value={value}>
                      {label}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>

              <span className="text-medium-gray italic" style={{ padding: '1rem 0.7rem 0 0.5rem' }}>
                at
              </span>

              <FormControl fullWidth style={{ flex: '1 1 80px' }}>
                <PlaceholderHackLabel disableAnimation id="Time">
                  Time
                </PlaceholderHackLabel>

                <Select
                  fullWidth
                  value={values.time}
                  onChange={e => setFieldValue('time', e.target.value)}
                  labelId="time"
                  MenuProps={{
                    ref: menuRef,
                    transitionDuration: 0,
                    TransitionProps: {
                      onEntered: () => {
                        if (!values.time && menuRef.current) {
                          const paperRoot = menuRef.current.querySelector('.MuiPaper-root')

                          if (paperRoot) {
                            const ITEM_SIZE = 36
                            const TOP_TIME = 6
                            paperRoot.scrollTop = ITEM_SIZE * 2 * TOP_TIME
                          }
                        }
                      },
                    },
                    style: {
                      maxHeight: 350,
                    },
                  }}
                >
                  {range(0, 24, 0.5)
                    .map(time => {
                      const hours = Math.floor(time)
                      const minutes = (time % 1) * 60

                      return [time, moment(`${hours}:${minutes}`, 'h:mm').format('LT')]
                    })
                    .map(([value, label]) => (
                      <MenuItem key={value} value={value}>
                        {label}
                      </MenuItem>
                    ))}
                </Select>
              </FormControl>
            </div>

            <DrawerSubheading className="mb-2">Where?</DrawerSubheading>
            {timeSlot && (
              <AppointmentLocationSelect
                providerId={providerId}
                patient={patient}
                onAddressChange={selectedAddress => {
                  setFieldValue('multiAddressId', selectedAddress.id)
                  setMultiSchedulerLocation(selectedAddress)
                }}
                containerProps={{ className: 'mb-2' }}
                value={values.multiAddressId}
              />
            )}

            <DrawerSubheading id="cadence-heading" className="mb-2 mt-4">
              How often?
            </DrawerSubheading>
            <SegmentedControl
              data-component="appointment-cadence-selector"
              aria-labelledby="cadence-heading"
              value={`${values.cadence}`}
            >
              {[
                ['Weekly', Cadence.Weekly],
                ['Every 2 Weeks', Cadence.Biweekly],
              ].map(([label, value]) => (
                <SegmentedControl.Item
                  checked={value === values.cadence}
                  onChange={handleChange}
                  key={value}
                  name="cadence"
                  value={value}
                >
                  {label}
                </SegmentedControl.Item>
              ))}
            </SegmentedControl>

            <DrawerCaption className="mt-3">We'll check your availability and recommend a few dates.</DrawerCaption>

            <div className="v-align mt-3">
              <FillButton
                disabled={values.day === '' || values.time === ''}
                className="full-width"
                type="submit"
                isLoading={isLoadingAppointmentsInfo}
              >
                Show Sessions
              </FillButton>
            </div>
          </NewCard>
        </form>
      )}
    </Formik>
  )
}

const PlaceholderHackLabel = styled(InputLabel)`
  &.MuiInputLabel-shrink {
    display: none;
  }
`
