import * as Sentry from '@sentry/browser'
import { Client, Conversation } from '@twilio/conversations'
import { useEffect, useState } from 'react'

import { useCreateAppointmentVideoConversationMutation } from '@nuna/api'

/**
 * Initialize Twilio conversation for a given appointment
 * @param chatClient Initialized Twilio chat client
 * @param appointmentId
 * @returns Twilio conversation object once ready, otherwise undefined
 */
export function useTwilioConversation({
  chatClient,
  appointmentId,
}: {
  chatClient: Client | undefined
  appointmentId: string
}) {
  const [conversation, setConversation] = useState<Conversation>()

  const [createAppointmentVideoConversation, { data: conversationData }] =
    useCreateAppointmentVideoConversationMutation({
      variables: { appointmentId },
    })

  // get or create conversation via backend
  useEffect(() => {
    if (!conversationData) {
      createAppointmentVideoConversation().catch(e => {
        console.error(e)
        Sentry.captureEvent({
          message: 'Failed to get conversation data from Tava API',
          extra: {
            error: e,
          },
        })
      })
    }
  }, [conversationData, createAppointmentVideoConversation])

  // get Twilio conversation
  useEffect(() => {
    if (chatClient && conversationData && !conversation) {
      chatClient
        .getConversationByUniqueName(conversationData.createAppointmentVideoConversation.uniqueName)
        .then(existingConversation => {
          setConversation(existingConversation)
        })
        .catch(e => {
          console.error(e)
          Sentry.captureEvent({
            message: 'Failed to get conversation from Twilio',
            extra: {
              error: e,
            },
          })
        })
    }
  }, [chatClient, conversationData, conversation])

  return conversation
}
