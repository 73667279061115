import { useSessionDraftConflictContext } from '@nuna/session-notes'
import { ContextualAlert, ContextualAlertProps } from '@nuna/tunic'

import { useVideoCallContext } from '../../../../../context/VideoCallContext'

export function DraftConflictAlert(props: Omit<ContextualAlertProps, 'children'>) {
  const { appointmentId } = useVideoCallContext()
  const { hasConflictingTabs } = useSessionDraftConflictContext()

  if (!hasConflictingTabs(appointmentId)) return null

  return (
    <ContextualAlert {...props} intent="urgent">
      Another tab is open with this same session's note. Please close it to avoid overwrite conflicts.
    </ContextualAlert>
  )
}
