import { compact } from 'lodash'

import { Address, AddressOwner, ProviderAddress, useAdminAvailabilityConstraintsQuery } from '@nuna/api'

interface UseAvailabilityForLocationArg extends Pick<ProviderAddress, 'providerId'> {
  address: Pick<Address, 'owner' | 'id'>
}

export function useHasAvailabilityForLocations(
  providerAddresses?: (UseAvailabilityForLocationArg | null | undefined)[] | null,
) {
  const shouldSkip =
    !providerAddresses ||
    providerAddresses?.every(providerAddress => providerAddress?.address.owner !== AddressOwner.Provider)

  const providerId = providerAddresses?.[0]?.providerId ?? ''

  const { data: availabilityData } = useAdminAvailabilityConstraintsQuery({
    variables: { providerId },
    skip: shouldSkip || !providerId,
  })

  if (shouldSkip || !availabilityData || compact(providerAddresses).length === 0) {
    return true
  }

  return availabilityData.provider.availabilityPeriods.allowDayAndTime.some(period =>
    compact(providerAddresses)
      .map(providerAddress => providerAddress.address.id)
      .includes(period.addressId as string),
  )
}
