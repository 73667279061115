import { styled } from '@mui/material'
import { HTMLAttributes } from 'react'

import { csx, tealSet } from '@nuna/tunic'

export function AddressCardHeader({ className, ...props }: HTMLAttributes<HTMLDivElement>) {
  return <StyledAddressCardHeader {...props} className={csx([className, 'sans-serif h6 text-medium'])} />
}

const StyledAddressCardHeader = styled('h3')`
  color: ${tealSet[70].hex};
`
