import { HTMLAttributes } from 'react'

import { IconCommentFilled } from '@nuna/tunic'

import { useVideoCallContext } from '../../../context/VideoCallContext'
import { useVideoChatContext } from '../../../context/VideoChatContext'
import { SidebarState } from '../../../context/hooks/useSidebarContext'
import { TopbarToggleButton } from './TopbarToggleButton'

export function ChatToggleButton(props: HTMLAttributes<HTMLButtonElement>) {
  const { activeSidebar, toggleSidebar } = useVideoCallContext()
  const { unreadMessagesCount } = useVideoChatContext()

  return (
    <TopbarToggleButton
      {...props}
      data-component="video-chat-sidebar"
      active={activeSidebar === SidebarState.Chat}
      onClick={() => toggleSidebar(SidebarState.Chat)}
      showAlert={unreadMessagesCount > 0}
    >
      <IconCommentFilled />
    </TopbarToggleButton>
  )
}
