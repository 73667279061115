import * as Yup from 'yup'
import { Form, Formik } from 'formik'
import { pick } from 'lodash'
import { isValidPhoneNumber } from 'react-phone-number-input'

import { AddressType, useProviderMeQuery, useSaveProviderIntakeMutation } from '@nuna/api'
import { addressValidationSchemaChecks } from '@nuna/common'
import { AddressBase } from '@nuna/core'
import {
  Card,
  CardBody,
  CardHeader,
  ContextualAlert,
  FillButtonWithChevron,
  IconInfo,
  IntakeFormStepper,
  toast,
} from '@nuna/tunic'

import { SignupIntakeAddressForm } from '../SignupIntake/shared/SignupIntakeAddressForm'
import { useSignupContext } from './SignupContext'
import { BaseSignupFormProps } from './shared'

type FormValues = AddressBase & {
  mobilePhone?: string | null
  isValidAddress?: boolean
}

const initialValues = {
  addressLineOne: '',
  addressLineTwo: '',
  city: '',
  state: '',
  zipCode: '',
  mobilePhone: '',
  isValidAddress: false,
}

interface Validation extends AddressBase {
  mobilePhone: string
  isValidAddress: boolean
}

const validationSchema = Yup.object().shape<Validation>({
  ...addressValidationSchemaChecks,
  mobilePhone: Yup.string()
    .required('Mobile Phone is required')
    .test('invalid-phone-number', 'Invalid Phone Number', num => num && isValidPhoneNumber(num)),
  isValidAddress: Yup.boolean().oneOf([true], 'Valid Address is required'),
})

export function SignupLocation({ onStepSubmit, formValues, step }: BaseSignupFormProps) {
  const [saveIntakeMutation, { loading }] = useSaveProviderIntakeMutation()
  const { data: providerData } = useProviderMeQuery()
  const { selectedOption } = useSignupContext()

  const providerId = providerData?.providerMe.id

  if (!providerId) {
    return null
  }

  const handleSubmit = async (locationFormValues: FormValues) => {
    try {
      await saveIntakeMutation({
        variables: {
          address: {
            ...pick(locationFormValues, ['addressLineOne', 'addressLineTwo', 'city', 'state', 'zipCode']),
            name: AddressType.ProviderHome,
            addressType: AddressType.ProviderHome,
          },
          mobilePhone: locationFormValues.mobilePhone,
          id: providerId,
        },
      })
      onStepSubmit(formValues, step, selectedOption === 'explore')
    } catch (error) {
      toast.urgent('There was a problem saving the address')
    }
  }

  return (
    <section>
      <h2 className="h2">Add your location</h2>

      <p className="text-medium-gray large">Add your home address for payouts and rate calculations.</p>
      <ContextualAlert
        className="mt-2 mb-2"
        icon={<IconInfo />}
        intent="default"
        role="alert"
        scheme="light"
        scribbleType="default"
      >
        This will not be seen by clients and is for internal use only.
      </ContextualAlert>
      <Formik
        initialValues={initialValues}
        onSubmit={handleSubmit}
        validationSchema={validationSchema}
        enableReinitialize
      >
        <Form>
          <Card>
            <CardHeader className={'large text-teal'} divider={false}>
              Home Address
            </CardHeader>
            <CardBody>
              <SignupIntakeAddressForm />
            </CardBody>
          </Card>
          <div className="mt-5">
            <IntakeFormStepper
              previousPath="/signup/licenses"
              mutationLoading={loading}
              nextButton={() => (
                <FillButtonWithChevron type="submit" isLoading={loading} data-testid="intake-continue-button">
                  Continue
                </FillButtonWithChevron>
              )}
            />
          </div>
        </Form>
      </Formik>
    </section>
  )
}
