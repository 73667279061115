import { styled } from '@mui/material'
import { isEmpty } from 'lodash'
import { HTMLAttributes, useEffect, useState } from 'react'

import { AddressOwner } from '@nuna/api'
import { type ProviderFormAddress, addressService } from '@nuna/core'
import { GhostButton, StatusLabel, greySet, shadowDepth } from '@nuna/tunic'

import { AddressCardHeader } from './AddressCardHeader'
import { ProviderAddressForm } from './ProviderAddressForm'

interface Props extends HTMLAttributes<HTMLDivElement> {
  address: ProviderFormAddress
}

export function AddressCard({ address, ...props }: Props) {
  const [isEditMode, setIsEditMode] = useState(false)

  useEffect(() => {
    setIsEditMode(isEmpty(address.addressHash))
  }, [address.addressHash])

  const handleCancel = () => {
    setIsEditMode(false)
  }

  const handleAfterSave = () => {
    setIsEditMode(false)
  }

  return (
    <StyledAddressCard {...props}>
      {isEditMode && (
        <ProviderAddressForm providerAddress={address} onCancel={handleCancel} afterSave={handleAfterSave} />
      )}
      {!isEditMode && (
        <div className="space-between v-align">
          <div>
            <AddressCardHeader>
              {address.name}{' '}
              {address.defaultAddress && (
                <StatusLabel className="ml-1" intent="information">
                  Primary
                </StatusLabel>
              )}
            </AddressCardHeader>
            <div className="caption">{addressService.formatAddress(address)}</div>
          </div>
          {address.owner === AddressOwner.Provider && (
            <span>
              <GhostButton onClick={() => setIsEditMode(true)}>Edit</GhostButton>
            </span>
          )}
        </div>
      )}
    </StyledAddressCard>
  )
}

const StyledAddressCard = styled('div')`
  border: 1px solid ${greySet[15].hex};
  border-radius: var(--border-radius);
  padding: var(--margin-2);
  box-shadow: ${shadowDepth(1)};
`
