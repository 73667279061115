import { FullStory, isInitialized } from '@fullstory/browser'

export interface CustomSessionFields {
  tavaScribeEnabled?: boolean
}

const identify = (login: { id: string; firstName: string; lastName: string; email: string }) => {
  if (isInitialized()) {
    FullStory('setIdentity', {
      uid: login.id,
      properties: {
        displayName: `${login.firstName} ${login.lastName}`,
        email: login.email,
      },
    })
  }
}

const setCustomFields = (fields: CustomSessionFields) => {
  if (isInitialized()) {
    FullStory('setProperties', {
      type: 'user',
      properties: fields,
    })
  }
}

export const sessionCaptureService = {
  identify,
  setCustomFields,
}
