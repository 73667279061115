import { CustomerCompanyDetailsFragment, useCustomerBenefitsGuideQuery } from '@nuna/api'
import { InfoWithHeading, PrimaryInfo, Skeleton } from '@nuna/tunic'

import { BenefitsGuideDownload } from '../../../BenefitsGuideDownload'

interface Props {
  customerCompany: CustomerCompanyDetailsFragment | undefined
}

export function CustomerBenefitsGuideView({ customerCompany }: Props) {
  const { data } = useCustomerBenefitsGuideQuery({
    variables: { companyId: customerCompany?.id ?? '' },
    skip: !customerCompany?.id,
  })

  const contract = data?.publicCompany.contract

  return (
    <>
      <h2 className="h6 mb-3">Benefits</h2>
      <InfoWithHeading
        heading="Eligibility"
        info={contract?.readableBenefitDetails.employeeTypeEligibility ?? <Skeleton height={1.3} width={10} />}
      />
      <InfoWithHeading
        heading="Session Limit"
        info={contract?.readableBenefitDetails.sessionLimit ?? <Skeleton height={1.3} width={10} />}
      />
      <InfoWithHeading
        heading="Benefits Guide"
        info={
          contract ? (
            <BenefitsGuideDownload contract={contract} renderOnEmpty={<PrimaryInfo>N/A</PrimaryInfo>}>
              {contract.benefitsGuideDocument?.fileName}
            </BenefitsGuideDownload>
          ) : (
            <Skeleton height={1.3} width={10} />
          )
        }
      />
    </>
  )
}
