import { styled } from '@mui/material'

import { CaqhValidationSection } from '@nuna/api'
import { CircularCheckmark, CollapsingListItem, GhostButtonExternalLink, IconExport, greySet } from '@nuna/tunic'

import { CAQHLiabilityInsuranceFields } from './CAQHLiabilityInsurance'

const filterIsRequiredText = (text: string) => {
  if (text.endsWith('is required')) {
    return text.replace('is required', '')
  }
  return text
}

type Props = {
  section: CaqhValidationSection
  compact?: boolean
}

export function CAQHValidationSection({ section, compact = false }: Props) {
  return (
    <CollapsingListItem
      className="px-0"
      expandIcon="chevron"
      compact={compact}
      hasDefaultSpacing={!compact}
      openByDefault
      label={
        <div className="flex-row">
          <CircularCheckmark isFilled={section.complete} />
          {compact ? (
            <p className="ml-1 mb-0">{section.name}</p>
          ) : (
            <h3 className="h5 ml-1 mb-0 text-default">{section.name}</h3>
          )}
        </div>
      }
    >
      <ul>
        {section.errors.map(error =>
          compact ? (
            <li>
              <p className="mb-1 text-secondary">{error.message}</p>
            </li>
          ) : (
            <MissingFieldBullet className="mt-1">{filterIsRequiredText(error.message)}</MissingFieldBullet>
          ),
        )}
      </ul>
      {section.name === 'Professional Liability Insurance' && <CAQHLiabilityInsuranceFields />}
      <GhostButtonExternalLink style={compact ? { fontSize: '12px' } : {}} className="ml-4" href={`${section.href}`}>
        Go to {section.name} <IconExport size={18} style={{ marginLeft: '6px' }} />
      </GhostButtonExternalLink>
    </CollapsingListItem>
  )
}

const MissingFieldBullet = styled('li')`
  font-size: 16px;
  color: ${greySet[70].hex};
`
