import { HTMLAttributes } from 'react'

import { CancelPolicy, DrawerAppointmentFragment, useListPaymentMethodsQuery } from '@nuna/api'
import { Audience, appointmentService, numberService, paymentMethodService } from '@nuna/core'
import { NoCreditCardCard } from '@nuna/coverage'
import { CreditCardCard } from '@nuna/payment-methods'
import { Card, Checkbox, CheckboxProps, Collapse, Skeleton, csx, greySet, makeTypographyComponent } from '@nuna/tunic'

interface Props extends Omit<HTMLAttributes<HTMLDivElement>, 'onChange'>, Pick<CheckboxProps, 'onChange'> {
  appointment: DrawerAppointmentFragment
  cancelPolicy: CancelPolicy
  acceptedDate?: string | null
  value: boolean
  audience: Audience
  feeType: 'cancel' | 'reschedule'
}

export function ChargeFee({
  appointment,
  cancelPolicy,
  acceptedDate,
  audience,
  value,
  onChange,
  feeType,
  className,
  ...props
}: Props) {
  const { data: paymentMethodData, loading: paymentMethodLoading } = useListPaymentMethodsQuery({
    variables: { searchOptions: { defaultForFees: true, active: true, patientId: appointment.patient.id } },
  })

  const feeCreditCard = (paymentMethodData?.listPaymentMethods ?? [])[0]
  const creditCardValid = paymentMethodService.isCardValid(feeCreditCard)

  if (
    appointmentService.subjectToCancelationFee({
      policyAcceptedDate: acceptedDate,
      appointmentStartDatetime: appointment.startDatetime,
      cancelPeriodMinutes: cancelPolicy?.cancelPeriodMinutes,
    })
  ) {
    return (
      <Card depth={-1} {...props} className={csx([className, 'p-2'])}>
        <Checkbox checked={value} onChange={onChange}>
          Charge a late {feeType === 'cancel' ? `cancelation` : 'reschedule'} fee
        </Checkbox>
        <ItalicText className="mt-1" style={{ paddingLeft: 34 }}>
          {audience === 'provider' && (
            <>
              Your policy is to charge clients {numberService.centsToFormattedDollars(cancelPolicy.chargeAmount)} for
              no-showing or canceling less than{' '}
              {numberService.minutesToFormattedHours(cancelPolicy.cancelPeriodMinutes)} beforehand.
            </>
          )}
          {audience === 'admin' && (
            <>
              {appointment.provider.firstName} may elect to charge their client a fee later in their session note. This
              is available for 72 hours after any cancelation.
            </>
          )}
        </ItalicText>
        <Collapse isOpen={value}>
          <ItalicText className="mt-1 mb-xs">Fee:</ItalicText>
          <Card className="p-2">
            <CardHeader>
              {numberService.centsToFormattedDollars(cancelPolicy.chargeAmount)} Late{' '}
              {feeType === 'cancel' ? 'Cancelation' : 'Reschedule'} Fee
            </CardHeader>
            {cancelPolicy.reimbursedAmount && (
              <ItalicText style={{ color: greySet[50].hex }} className="mt-xs">
                {numberService.centsToFormattedDollars(cancelPolicy.reimbursedAmount)} reimbursed to{' '}
                {audience === 'provider' ? 'you' : appointment.provider.firstName} after processing fees
              </ItalicText>
            )}
          </Card>
          <ItalicText className="mt-1 mb-xs">Charged to:</ItalicText>
          {paymentMethodLoading && <Skeleton height={5} />}
          {!paymentMethodLoading && creditCardValid && (
            <CreditCardCard name={feeCreditCard?.description ?? ''} number={feeCreditCard?.accountLastFour ?? ''} />
          )}
          {!paymentMethodLoading && !creditCardValid && <NoCreditCardCard />}
        </Collapse>
      </Card>
    )
  }
  return null
}

const ItalicText = makeTypographyComponent('caption text-secondary italic', 'div')
const CardHeader = makeTypographyComponent('text-medium', 'div')
