import { styled } from '@mui/material'
import React, { ChangeEvent, useState } from 'react'

import { Patient, RemoveFromCaseloadReason, useProviderQuery, useRemovePatientFromCaseloadMutation } from '@nuna/api'
import { useAuthDataContext } from '@nuna/auth'
import {
  Dialog,
  DialogContent,
  FillButton,
  GhostButton,
  IconButton,
  IconClose,
  IconCommentFilled,
  Radio,
  RadioGroup,
  TextField,
  UserChip,
  toast,
} from '@nuna/tunic'
import { makeTypographyComponent } from '@nuna/tunic'

const DialogHeading = styled('div')`
  margin-bottom: 1.6rem;
`

const DialogBody = styled('div')``

const DialogFooter = styled('div')``

const DialogSection = styled('div')`
  margin-bottom: 1.6rem;
`
const Label = makeTypographyComponent('text-bold text-default', 'label')

const reasons = [
  {
    key: RemoveFromCaseloadReason.GoalsCompleted,
    value: 'Client has completed their goals for therapy',
  },
  {
    key: RemoveFromCaseloadReason.NoInterest,
    value: 'Client is no longer interested in therapy',
  },
  {
    key: RemoveFromCaseloadReason.StoppedCommunicating,
    value: 'Client has stopped communicating with me',
  },
  {
    key: RemoveFromCaseloadReason.NoShowCancelled,
    value: 'Client has no showed or canceled multiple times',
  },
  {
    key: RemoveFromCaseloadReason.LeavingTava,
    value: 'Client is leaving Tava (eligibility, financial reasons)',
  },
  {
    key: RemoveFromCaseloadReason.ReferToCommunity,
    value: "I'm referring the client to community resources",
  },
  {
    key: RemoveFromCaseloadReason.ReferToHigherCare,
    value: "I'm referring the client to a higher level of care",
  },
  {
    key: RemoveFromCaseloadReason.ReferToOtherProvider,
    value: "I'm referring the client to another Tava provider",
  },
]

interface PatientMessageDialogProps {
  onRemove: (patientId: string) => void
  patient: Pick<Patient, 'id' | 'firstName' | 'lastName' | 'avatarUrl'>
  providerId?: string
}

export function ClientRemoveDialog({ patient, onRemove, providerId }: PatientMessageDialogProps) {
  const { login } = useAuthDataContext()
  const { data: providerData } = useProviderQuery({ variables: { id: providerId ?? '' }, skip: !providerId })
  const [isOpen, setIsOpen] = useState(false)
  const [note, setNote] = useState('')
  const [reason, setReason] = useState(RemoveFromCaseloadReason.GoalsCompleted.toString())
  const [removePatientFromCaseload] = useRemovePatientFromCaseloadMutation()

  if (!login?.id || !providerData) {
    return null
  }

  const handleOpen = (event: React.MouseEvent<HTMLElement>) => {
    event.preventDefault()
    event.stopPropagation()

    setIsOpen(true)
  }

  const handleClose = () => {
    setIsOpen(false)
  }

  const handleTextChange = (event: ChangeEvent<HTMLTextAreaElement>) => {
    setNote(event.target.value)
  }

  const handleRadioChange = (event: ChangeEvent<HTMLInputElement>) => {
    setReason(event.currentTarget.value)
  }

  const handleRemoval = async () => {
    try {
      const hasReason = reasons.find(it => it.key === reason)
      await removePatientFromCaseload({
        variables: {
          patientId: patient.id,
          providerId: providerId ?? '',
          reason: hasReason?.key,
          note: ` of ${providerData.provider.firstName} ${providerData.provider.lastName} • Removed by ${login.firstName} ${login.lastName} due to "${hasReason?.value}" - "${note}"`,
        },
      })

      onRemove(patient.id)
    } catch (error) {
      if ((error as Error)?.message.indexOf('Please cancel all') > -1) {
        toast.urgent((error as Error)?.message)
      } else {
        console.error(error)
        toast.urgent('Unable to remove client from caseload')
      }
    } finally {
      setIsOpen(false)
    }
  }

  return (
    <div onClick={e => e.stopPropagation()}>
      <IconButton variant="secondary" tooltip="Remove client" small onClick={handleOpen} style={{ marginTop: '1px' }}>
        <IconClose size={18} />
      </IconButton>

      <Dialog isOpen={isOpen} onClose={handleClose}>
        <DialogContent style={{ padding: '16px 24px', minWidth: 450 }}>
          <DialogHeading>
            <div>
              <h2 className="h5 mb-0 v-align">Remove Client?</h2>
            </div>
          </DialogHeading>

          <DialogBody>
            <DialogSection>
              <Label>Client:</Label>
              <br />
              <UserChip className="fs-mask" avatarUrl={patient.avatarUrl}>
                {patient.firstName}
                {` `}
                {patient.lastName}
              </UserChip>
            </DialogSection>

            <DialogSection>
              <Label>Reason for removing client from caseload:</Label>
              <br />
              <RadioGroup style={{ marginTop: 16 }}>
                {reasons.map(({ key, value }) => {
                  return (
                    <Radio key={key} value={key} checked={key === reason} onChange={handleRadioChange}>
                      {value}
                    </Radio>
                  )
                })}
              </RadioGroup>
            </DialogSection>

            <DialogSection>
              <Label>Leave a comment for the client record:</Label>
              <br />
              <TextField
                id="note"
                className="fs-mask"
                onChange={handleTextChange}
                multiline
                placeholder={'Why are they being removed'}
                value={note}
                renderIcon={color => <IconCommentFilled color={color} />}
              />
            </DialogSection>
          </DialogBody>

          <DialogFooter style={{ float: 'right' }}>
            <GhostButton variant="secondary" onClick={handleClose} style={{ marginRight: 8 }}>
              Nevermind
            </GhostButton>
            <FillButton onClick={handleRemoval} disabled={!note.trim().length || !reason.trim().length}>
              Remove Client
            </FillButton>
          </DialogFooter>
        </DialogContent>
      </Dialog>
    </div>
  )
}
