import { IconProps } from './IconProps'

export function IconCheckCircle({ size = 24, color = 'currentColor', ...props }: IconProps) {
  return (
    <svg width={size} height={size} viewBox="0 0 48 48" fill={color} {...props} xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2 24C2 36.143 11.857 46 24 46C36.143 46 46 36.143 46 24C46 11.857 36.143 2 24 2C11.857 2 2 11.857 2 24ZM13.9711 24L17.0992 20.8719L22.3758 26.1484L31.4336 17.0906L34.5617 20.2188L22.3758 32.4047L13.9711 24Z"
      />
    </svg>
  )
}
