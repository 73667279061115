import { styled } from '@mui/material'
import moment from 'moment'
import { useState } from 'react'

import { Card, GhostButton, IconCreditCard, StatusLabel, shadowDepth, yellowSet } from '@nuna/tunic'

import { SelectedCardDisplayProps } from './types'

export function DefaultCreditCardValueDisplay({
  card,
  showHSAWarning = false,
  isExpired = false,
  ...props
}: SelectedCardDisplayProps) {
  const [hasAcknowledgedHSAWarning, setHasAcknowledgedHSAWarning] = useState(false)
  const needsHSAAcknowledgement = showHSAWarning && card.isHsa && !hasAcknowledgedHSAWarning

  const cardDescription = `${card.isHsa ? 'HSA ' : ''}${card.description}`
  const cardNumber = `•••• •••• •••• ${card.accountLastFour}`
  const cardInlineDisplay = (
    <div className="v-align p-2">
      <IconCreditCard style={{ marginRight: 10 }} size={32} />
      <div className="text-medium text-default">
        <div className="v-align">
          {cardDescription}
          {(() => {
            if (isExpired && card.expirationDate) {
              return (
                <StatusLabel intent="urgent" className="ml-1">
                  EXPIRED {moment(card.expirationDate).format('MM/DD/YY')}
                </StatusLabel>
              )
            }

            return null
          })()}
        </div>
        <div className="caption">{cardNumber}</div>
      </div>
    </div>
  )

  if (needsHSAAcknowledgement) {
    return (
      <HSACardDisplay {...props}>
        <div className="shadow-container">{cardInlineDisplay}</div>
        <div className="hsa-warning v-align caption p-2">
          <span className="text-medium">
            Cancelation and no-show fees are not HSA-eligible. Using this card may result in penalties.
          </span>
          <GhostButton className="ml-1" variant="secondary" onClick={() => setHasAcknowledgedHSAWarning(true)}>
            Got it
          </GhostButton>
        </div>
      </HSACardDisplay>
    )
  }

  return (
    <Card {...props} depth={isExpired ? -1 : 1}>
      {cardInlineDisplay}
    </Card>
  )
}

const HSACardDisplay = styled('div')`
  border: 1px solid ${yellowSet[80].hex};
  border-radius: var(--border-radius);

  .shadow-container {
    box-shadow: ${shadowDepth(1)};
  }

  .hsa-warning {
    color: ${yellowSet[80].hex};
    border-top: 1px solid ${yellowSet[80].hex};
    background-color: ${yellowSet.tint[20]};

    button {
      white-space: nowrap;
    }
  }
`
