/** Long term, I'm not sure where the best place for this is. Since providers are the only types of users syncing calendars I put it in @nuna/provider for now */
import { Calendar, CalendarSyncStatus } from '@nuna/api'
import { supportService } from '@nuna/telemetry'
import { toast } from '@nuna/tunic'

export type CalendarStatus = 'active' | 'support' | 'setup' | 'setting_up'

export function getCalendarStatus(syncStatus: CalendarSyncStatus | undefined | null): CalendarStatus | null {
  switch (syncStatus) {
    case CalendarSyncStatus.Connected:
    case CalendarSyncStatus.Running:
    case CalendarSyncStatus.Partial:
      return 'active'
    case CalendarSyncStatus.Stopped:
    case CalendarSyncStatus.SyncError:
      return 'support'
    case CalendarSyncStatus.SettingUp:
      return 'setting_up'
    case CalendarSyncStatus.Invalid:
    default:
      return 'setup'
  }
}

export function getSyncingCalendar(calendars: Calendar[]) {
  // NOTE: we are only syncing a primary calendar right now
  // so just grab it off the top, will have to circle back when
  // we support syncing more then one calendar
  return calendars.filter(calendar => calendar.isSyncing)[0]
}

export async function onAuthWithNylasClick(
  apiEndpoint: string,
  calendarProvider = 'auto',
  email = '',
  providerId = '',
) {
  const url = new URL(apiEndpoint)

  url.pathname = 'getNylasAuthUrlForUser'
  url.searchParams.append('calendarProvider', calendarProvider)
  url.searchParams.append('providerEmail', email)

  if (providerId) {
    url.searchParams.append('providerId', providerId)
  }

  const response = await fetch(url.toString(), { credentials: 'include' })
    .then(data => data.json())
    .then(data => data.auth_url)
    .catch(() => null)

  if (!response) {
    return toast.urgent(`Something went wrong. Please contact ${supportService.supportEmails.providerSupport}.`)
  } else if (response.error && response.invalidEmail) {
    return toast.urgent(response.error)
  } else if (response.error) {
    return toast.urgent(`Something went wrong. Please contact ${supportService.supportEmails.providerSupport}.`)
  }

  return (window.location.href = response)
}

export function getCalendarStatusLabel(calendarStatus: CalendarSyncStatus) {
  switch (calendarStatus) {
    case CalendarSyncStatus.Connected:
    case CalendarSyncStatus.Running:
      return 'Active'
    case CalendarSyncStatus.SettingUp:
      return 'Syncing'
    case CalendarSyncStatus.SyncError:
    case CalendarSyncStatus.Invalid:
    case CalendarSyncStatus.Stopped:
      return 'Not Syncing'
    case CalendarSyncStatus.Expired:
      return 'Expired'
    default:
      return 'Unknown'
  }
}

export function getCalendarStatusLabelIntent(calendarStatus: CalendarSyncStatus) {
  switch (calendarStatus) {
    case CalendarSyncStatus.Connected:
    case CalendarSyncStatus.Running:
      return 'information'
    case CalendarSyncStatus.SettingUp:
      return 'caution'
    case CalendarSyncStatus.SyncError:
    case CalendarSyncStatus.Invalid:
    case CalendarSyncStatus.Expired:
      return 'urgent'
    default:
      return 'default'
  }
}

export function getCalendarTypeByEmail(email: string) {
  if (!email) {
    return 'Email Account'
  }

  const domain = email.split('@')[1]

  switch (domain) {
    case 'googlemail.com':
    case 'gmail.com':
      return 'GOOGLE Account'
    case 'icloud.com':
      return 'ICLOUD Account'
    case 'yahoo.com':
      return 'Yahoo Account'
    case 'outlook.com':
    case 'live.com':
      return 'Microsoft Account'
    default:
      return 'Email Account'
  }
}
