import { styled } from '@mui/material'
import { last } from 'lodash'
import { useMemo } from 'react'

import { ProviderTaxDocumentsQuery, useProviderTaxDocumentsQuery } from '@nuna/api'
import { useEnvironmentContext } from '@nuna/environment'
import { IconFileDocument, OutlineButtonExternalLink, Skeleton, greySet } from '@nuna/tunic'
import { ContextualAlert } from '@nuna/tunic'

import { PayrollSummaryCollapsableSection, SubHeading } from './shared'

interface TaxDocumentsProps {
  providerId: string
}

export function TaxDocuments({ providerId }: TaxDocumentsProps) {
  const { data, loading } = useProviderTaxDocumentsQuery({
    variables: { providerId },
    fetchPolicy: 'cache-and-network',
  })
  const { API_ENDPOINT } = useEnvironmentContext()

  const documentsByYear = useMemo(() => {
    return [...(data?.providerTaxDocuments?.items || [])]
      .sort((a, b) => (a.year === b.year ? a.label.localeCompare(b.label) : a.year - b.year))
      .reduce((agg, document) => {
        const lastYear = last(agg)
        if (!lastYear || lastYear[0] !== document.year) {
          agg.push([document.year, [document]])
        } else {
          lastYear[1].push(document)
        }
        return agg
      }, [] as [number, ProviderTaxDocumentsQuery['providerTaxDocuments']['items']][])
  }, [data])

  if (loading) return <Skeleton className="mt-4" height={4} style={{ width: '100%' }} />

  return (
    <PayrollSummaryCollapsableSection headerText="Tax Documents">
      {documentsByYear.length ? (
        documentsByYear.map(([year, documents]) => (
          <div key={year}>
            <SubHeading className="body">{year}</SubHeading>
            {documents.map(document => (
              <div className="m-1" key={document.id}>
                <DocumentButton variant="secondary" href={`${API_ENDPOINT}/payroll/tax-document/${document.id}`}>
                  <IconFileDocument className="mr-1" />
                  {document.label}
                </DocumentButton>
              </div>
            ))}
          </div>
        ))
      ) : (
        <ContextualAlert>No documents</ContextualAlert>
      )}
    </PayrollSummaryCollapsableSection>
  )
}

const DocumentButton = styled(OutlineButtonExternalLink)`
  border: 1px solid ${greySet[30].hex};
  border-radius: 6px;
  font-size: 1rem;
  font-weight: 300;
`
