import { HTMLAttributes } from 'react'

import { ContextualAlert } from '@nuna/tunic'

export function IncompleteSectionAlert(props: HTMLAttributes<HTMLDivElement>) {
  const className = props.className ?? 'mr-5'
  return (
    <ContextualAlert className={className} showScribble role="status">
      There's nothing here. Please update.
    </ContextualAlert>
  )
}
