import { styled } from '@mui/material'
import { motion } from 'framer-motion'
import { useEffect } from 'react'
import { useMediaQuery } from 'react-responsive'

import { useUpcomingPatientAppointmentQuery } from '@nuna/api'
import { AppointmentDrawer } from '@nuna/appointment-drawer'
import { useAppointmentDrawerSearchParams } from '@nuna/common'
import { appointmentService } from '@nuna/core'
import {
  BelowTablet,
  IconCalendar,
  IconCalendarNone,
  IconCheckCircle,
  IconClose,
  TextButton,
  borderGrey,
} from '@nuna/tunic'

import { useVideoCallContext } from '../../../../context/VideoCallContext'
import { useSchedulingNotification } from '../../../../hooks/useSchedulingNotification'
import { videoSceneDarkBorder, videoSceneExtraDark, videoSceneTeal } from '../../../../util/colors'
import { isPatient, isProvider } from '../../../../util/videoUsers'
import { NextAppointment } from './components/NextAppointment'

interface Props {
  isExpanded: boolean
  setIsExpanded: (value: boolean) => void
}

export function ScheduleToggleButton({ isExpanded, setIsExpanded }: Props) {
  const {
    openScheduleAppointmentDrawer,
    openRescheduleAppointmentDrawer,
    drawerConfig: { timeSlot, drawerOpen },
  } = useAppointmentDrawerSearchParams()

  const isMobile = useMediaQuery({ query: `(${BelowTablet})` })
  const { localUser, expectedUsers } = useVideoCallContext()
  const { schedulingNotification, clearSchedulingNotification, sendSchedulingNotification } =
    useSchedulingNotification()

  const patient = expectedUsers.find(isPatient)
  const provider = expectedUsers.find(isProvider)

  const { data, refetch: refetchUpcomingAppointment } = useUpcomingPatientAppointmentQuery({
    variables: {
      forSpecificProvider: true,
      startDatetimeBuffer: 11,
      patientId: localUser.isProvider ? patient?.patientId : undefined,
      providerId: !localUser.isProvider ? provider?.providerId : undefined,
    },
    skip: localUser.isProvider ? !patient : !provider,
  })

  useEffect(() => {
    if (schedulingNotification) {
      refetchUpcomingAppointment()
    }
  }, [schedulingNotification, refetchUpcomingAppointment])

  const handleScheduleChangeClick = () => {
    setIsExpanded(false) // onMouseLeave doesn't always fire when the drawer opens at the same time. This ensures the toggle collapses
    !upcomingAppointment
      ? openScheduleAppointmentDrawer(localUser.isProvider ? localUser.providerId : provider?.providerId || '', {
          patientId: patient?.patientId,
          timeSlot: timeSlot ? appointmentService.timeSlotToTimeStamp(timeSlot) : undefined,
          closeOnSave: true,
        })
      : openRescheduleAppointmentDrawer(upcomingAppointment.id)
  }

  const handleToggleClick = () => {
    if (!isMobile) return

    if (!isExpanded) {
      setIsExpanded(true)
    }
  }

  const handleMouseEnter = () => {
    if (isMobile) return

    setIsExpanded(true)
  }

  const handleMouseLeave = () => {
    if (isMobile) return

    setIsExpanded(false)
  }

  const upcomingAppointment = data?.upcomingAppointmentForPatient

  const Icon = upcomingAppointment ? IconCalendar : IconCalendarNone

  return (
    <>
      {/** the AppointmentDrawer renders into a portal so where it renders doesn't matter.
       * Putting it here because it greatly simplifies dealing with the state for the notification.
       */}
      <AppointmentDrawer
        audience={localUser.isProvider ? 'provider' : 'client'}
        onAppointmentScheduled={sendSchedulingNotification}
        transparentBackground
      />

      {schedulingNotification ? (
        <Notification>
          <IconCheckCircle color={videoSceneTeal} size={20} className="mr-1" />
          <span className="nowrap">All set for {schedulingNotification.format('MMM Do [at] h:mma')}</span>
          <ActionButton className="ml-1" onClick={clearSchedulingNotification}>
            Dismiss
          </ActionButton>
        </Notification>
      ) : (
        <Container
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
          animate={{ width: isExpanded ? 'auto' : 40 }}
          transition={{ type: 'tween' }}
          onClick={handleToggleClick}
          className="v-align"
        >
          <Icon />

          {/** There is an issue that I think it's due to the way framer works and the dialog opening at the same time.
           * When the drawer opens the animation on this toggle shows part of the GhostButton instead of the icon
           * This is a hack to make sure it re-renders and doesn't show a weird cropped "Schedule" */}
          {!drawerOpen && (
            <>
              <NextAppointment
                isMobile={isMobile}
                localUser={localUser}
                patient={patient}
                upcomingAppointment={upcomingAppointment}
              />
              <ActionButton
                className="ml-1"
                onClick={handleScheduleChangeClick}
                data-component="video-schedule-sidebar"
              >
                {upcomingAppointment ? 'Reschedule' : 'Schedule'}
              </ActionButton>
            </>
          )}

          <MobileCloseIcon className="ml-1" size={16} onClick={() => setIsExpanded(false)} />
        </Container>
      )}
    </>
  )
}

const Container = styled(motion.span)`
  border-radius: 6px;
  border: 1px solid transparent;
  color: ${borderGrey};
  min-width: 40px;
  overflow: hidden;
  padding: 0.5rem;
  transition: background-color 0.3s, border-color 0.3s;
  width: 40px;
  white-space: nowrap;

  svg {
    flex: 0 0 auto;
  }

  &:hover {
    background-color: ${videoSceneExtraDark};
    border-color: ${videoSceneDarkBorder};
  }
`

const ActionButton = styled(TextButton)`
  font-weight: 600;
`
ActionButton.defaultProps = { scheme: 'dark' }

const Notification = styled('span')`
  align-items: center;
  background-color: ${videoSceneExtraDark};
  border: 1px solid ${videoSceneTeal};
  border-radius: 6px;
  color: ${borderGrey};
  display: flex;
  padding: 0.5rem;
  height: 42px;
`

const MobileCloseIcon = styled(IconClose)`
  color: ${videoSceneTeal};
  display: none;

  @media (${BelowTablet}) {
    display: block;
  }
`
