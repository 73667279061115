import { Slider as MuiSlider, SliderProps, ThemeProvider, createTheme, styled } from '@mui/material'
import { forwardRef } from 'react'

import { IconMinus, IconPlus, borderGrey, primary, shadowDepth } from '@nuna/tunic'

// This could potentially be moved to the main theme, but the RangeField also uses the slider and has different styles. Could be consolidated.
const imageSliderTheme = createTheme({
  components: {
    MuiSlider: {
      styleOverrides: {
        track: {
          backgroundColor: borderGrey,
          borderRadius: 4,
          height: 7,
          border: 0,
          opacity: 1,
        },
        rail: {
          backgroundColor: borderGrey,
          borderRadius: 4,
          height: 7,
          opacity: 1,
        },
      },
    },
  },
})

const ThumbComponent = forwardRef<HTMLSpanElement, React.HTMLAttributes<HTMLSpanElement>>(
  ({ children, ...props }, ref) => {
    return (
      <ThumbContainer {...props} ref={ref}>
        <span />
        <span />
        <span />
        {children}
      </ThumbContainer>
    )
  },
)

const ThumbContainer = styled('span')`
  &.MuiSlider-thumb {
    background-color: ${primary};
    border: 1px solid ${borderGrey};
    border-radius: 50%;
    box-shadow: ${shadowDepth(1)};
    display: block;
    height: 28px;
    position: relative;
    margin-top: -10px;
    width: 28px;

    & > span {
      background-color: #fff;
      bottom: 0;
      display: block;
      height: 11px;
      left: 0;
      margin: auto;
      position: absolute;
      right: 0;
      top: 0;
      width: 2px;

      &:nth-of-type(1) {
        transform: translateX(-5px);
      }

      &:nth-of-type(2) {
        height: 16px;
      }

      &:nth-of-type(3) {
        transform: translateX(5px);
      }
    }
  }
`

export function Slider({ className = '', ...props }: SliderProps) {
  return (
    <ThemeProvider theme={imageSliderTheme}>
      <SliderContainer className={className}>
        <IconMinus size={16} className="mr-2" />
        <MuiSlider slots={{ thumb: ThumbComponent }} {...props} />
        <IconPlus size={16} className="ml-2" />
      </SliderContainer>
    </ThemeProvider>
  )
}

const SliderContainer = styled('div')`
  align-items: center;
  display: flex;

  svg {
    transform: translateY(1.5px);
  }
`
