import { useEffect, useState } from 'react'

import { Skeleton } from '@nuna/tunic'

import { useProviderAppContext } from '../../../shared/ProviderAppContext'
import { useProviderSignupIntakeContext } from '../ProviderSignupIntakeContext'
import { IntakeStepContainer } from '../shared/IntakeStepContainer'
import { ProviderSignupIntakeFormProps } from '../shared/provider-signup-intake-types'
import { IntakeFinish } from './IntakeFinish'
import { IntakeProgressReport } from './IntakeProgressReport'

export function IntakeHome(props: ProviderSignupIntakeFormProps) {
  const [statusesReloaded, setStatusesReloaded] = useState(false)
  const { refetchStatus } = useProviderAppContext()
  const { allRequirementsMet } = useProviderSignupIntakeContext()

  useEffect(() => {
    if (!statusesReloaded) {
      refetchStatus().then(() => setStatusesReloaded(true))
    }
  }, [refetchStatus, statusesReloaded])

  if (!statusesReloaded) {
    return <Skeleton style={{ height: '100%', width: '100%', margin: '2rem' }} />
  }

  return (
    <IntakeStepContainer>
      {(() => {
        if (allRequirementsMet) {
          return <IntakeFinish {...props} />
        }

        return <IntakeProgressReport />
      })()}
    </IntakeStepContainer>
  )
}
