import { greySet, salmonSet, tealSet } from './colorSets'

/** @deprecated Use theme.palette.salmon['500'] from MUI Theme */
export const primary = salmonSet.primary.hex
/** @deprecated Use theme.palette.error.main from MUI Theme */
export const error = salmonSet[80].hex

/** @deprecated Use theme.palette.teal['500'] from MUI Theme */
export const interactiveFill = tealSet[50].hex
/** @deprecated Use theme.palette.teal['600'] from MUI Theme */
export const interactiveFillHover = tealSet[50].hover

/** @deprecated Use theme.palette.teal['600'] from MUI Theme */
export const interactiveText = tealSet[70].hex
/** @deprecated Use theme.palette.teal['700'] from MUI Theme */
export const interactiveTextHover = tealSet[70].hover

/** @deprecated Use theme.palette.text.primary */
export const bodyPrimary = tealSet[90].hex
/** @deprecated Use theme.palette.text.secondary */
export const bodySecondary = greySet[70].hex

/** @deprecated Use theme.palette.text.primary */
export const body1 = bodyPrimary
/** @deprecated Use theme.palette.text.secondary */
export const body2 = bodySecondary

/** @deprecated Use theme.palette.grey['50'] from MUI Theme */
export const eggshell = greySet[0].hex
/** @deprecated Use theme.palette.grey['300'] from MUI Theme */
export const borderGrey = greySet[30].hex
