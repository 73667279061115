import { useNetwork } from '@daily-co/daily-react'
import { HTMLAttributes } from 'react'

import { NetworkQualityIcon } from './NetworkQualityIcon'

export function LocalNetworkQualityIndicator(props: HTMLAttributes<HTMLDivElement>) {
  const { threshold } = useNetwork()

  return <NetworkQualityIcon threshold={threshold} {...props} />
}
