import { css, styled } from '@mui/material'
import { NavLinkProps } from 'react-router-dom'

import { greySet, salmonSet, tealSet, white, whiteTintSet, yellowSet } from '../../styles/colorSets'
import { sansSerifFontStack } from '../../styles/global/typography.utils'
import { Scheme } from '../../types/Scheme'
import { ButtonProps, ButtonWithLoader, ExternalButtonLink, InternalButtonLink } from './ButtonBase'
import { ghostButtonColor } from './utils'

type GhostVariant = 'primary' | 'secondary' | 'destroy' | 'caution'

export interface GhostButtonProps extends ButtonProps {
  variant?: GhostVariant
  scheme?: Scheme
}

export interface GhostButtonLinkProps extends NavLinkProps {
  variant?: GhostVariant
  scheme?: Scheme
}

export interface GhostButtonExternalLinkProps extends React.HTMLAttributes<HTMLAnchorElement> {
  variant?: GhostVariant
  scheme?: Scheme
  href: string
}

const underlineHeight = 5
const buttonHeight = 26

const styles = ({ variant = 'primary', scheme = 'light' }: Pick<GhostButtonProps, 'variant' | 'scheme'>) => css`
  align-items: center;
  cursor: pointer;
  display: inline-flex;
  font-family: ${sansSerifFontStack};
  font-size: 1.125rem; // 18px
  font-weight: 500;
  justify-content: center;
  padding: 0 2px;
  position: relative;

  && {
    text-decoration: none;
  }

  &::before,
  &::after {
    bottom: -4px;
    content: '';
    display: inline-block;
    left: 0;
    width: 100%;
    position: absolute;
  }

  &::before {
    height: calc(100% + 8px);
    transform: scaleY(${underlineHeight / buttonHeight});
    transition: transform 100ms;
    transform-origin: center bottom;
  }

  &.focus-visible,
  &:hover {
    &::before {
      transform: scaleY(1);
    }
  }

  &.focus-visible {
    outline: none;

    &::after {
      border-radius: 2px;
      height: 2px;
    }
  }

  &:disabled,
  &.disabled {
    cursor: not-allowed;

    &:hover {
      box-shadow: none;
    }

    &.focus-visible {
      &::after {
        display: none;
      }
    }
  }

  ${(() => {
    if (scheme === 'dark' && variant === 'secondary') {
      return ghostButtonColor({
        text: white,
        background: whiteTintSet[15],
        underline: white.hex,
      })
    }

    if (scheme === 'dark' && variant === 'primary') {
      return ghostButtonColor({
        text: tealSet[30],
        background: tealSet.tint[20],
        underline: tealSet[30].hex,
      })
    }

    if (scheme === 'light' && variant === 'secondary') {
      return ghostButtonColor({
        text: greySet[70],
        hoverText: greySet[80].hex,
        background: greySet.tint[40],
        underline: greySet[80].hex,
      })
    }

    if (variant === 'destroy') {
      return ghostButtonColor({
        text: salmonSet[80],
        hoverText: salmonSet[80].hex,
        background: salmonSet.tint[40],
        underline: salmonSet[80].hex,
      })
    }

    if (variant === 'caution') {
      return ghostButtonColor({
        text: yellowSet[80],
        hoverText: yellowSet[80].hex,
        background: yellowSet.tint[60],
        underline: yellowSet[80].hex,
      })
    }

    return ghostButtonColor({
      text: tealSet[70],
      hoverText: tealSet[80].hex,
      background: tealSet.tint[40],
      underline: tealSet[80].hex,
    })
  })()}
`

export const GhostButton = styled(ButtonWithLoader)<GhostButtonProps>`
  ${styles}
`

export const GhostButtonLink = styled(InternalButtonLink)<GhostButtonLinkProps>`
  ${styles}
`

export const GhostButtonExternalLink = styled(ExternalButtonLink)<GhostButtonExternalLinkProps>`
  ${styles}
`
