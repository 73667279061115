import { useEffect } from 'react'

import {
  InsurancePayerSortKeys,
  InsurancePayerStatus,
  OrderBy,
  PaymentPreference,
  usePatientContextQuery,
  usePayersForClientQuery,
} from '@nuna/api'
import { Skeleton } from '@nuna/tunic'
import { SiderailComponents, toast } from '@nuna/tunic'

import { insuranceInitialValues } from '../CoverageForm/components/InsuranceCoverageForm'
import { SwitchCoverageForm, SwitchCoverageFormProps } from './SwitchCoverageForm'

interface SwitchCoverageFormPrefilledProps extends SwitchCoverageFormProps {
  payerName?: string | null
  switchingPrefilledPreference?: PaymentPreference | null | undefined
  providerAcceptsReferrals?: boolean
}

// Envisioning as the need comes up to prefill other things we can expand upon this
export function SwitchCoverageFormPrefilled({
  payerName,
  switchingPrefilledPreference,
  ...props
}: SwitchCoverageFormPrefilledProps) {
  if (payerName) {
    return <SwitchCoverageFormPrefilledPayerName payerName={payerName} {...props} />
  }

  if (switchingPrefilledPreference === PaymentPreference.Insurance) {
    return <SwitchCoverageFormInsuranceOnly {...props} />
  }

  if (switchingPrefilledPreference === PaymentPreference.Cash) {
    return <SwitchCoverageFormCashOnly {...props} />
  }

  return <SwitchCoverageForm {...props} />
}

function SwitchCoverageFormCashOnly(props: SwitchCoverageFormProps) {
  return (
    <SwitchCoverageForm
      {...props}
      prefilledValues={{
        paymentPreference: PaymentPreference.Cash,
      }}
    />
  )
}

function SwitchCoverageFormInsuranceOnly(props: SwitchCoverageFormProps) {
  return (
    <SwitchCoverageForm
      {...props}
      prefilledValues={{
        paymentPreference: PaymentPreference.Insurance,
        insuranceValues: { ...insuranceInitialValues },
      }}
    />
  )
}

interface SwitchCoverageFormPrefilledPayerNameProps extends SwitchCoverageFormProps {
  payerName: string
}

function SwitchCoverageFormPrefilledPayerName({ payerName, ...props }: SwitchCoverageFormPrefilledPayerNameProps) {
  const { data: patientContextData } = usePatientContextQuery()
  const patient = patientContextData?.patientContext.patient

  const { data, loading, error } = usePayersForClientQuery({
    variables: {
      pagination: {
        limit: 1,
      },
      order: [{ key: InsurancePayerSortKeys.Name, direction: OrderBy.Asc }],
      filters: {
        state: patient?.state,
        name: payerName,
        status: InsurancePayerStatus.Active,
      },
    },
    skip: !patient?.state,
  })

  useEffect(
    function showErrorToast() {
      if (error) {
        toast.urgent(error.message)
      }
    },
    [error],
  )

  // null is the form default, so if a payer isn't found it will just render normally
  const payer = data?.insurancePayers.items?.[0] ?? null

  if (loading || !patient) {
    return (
      <SiderailComponents.Container>
        <Skeleton height={4} />
      </SiderailComponents.Container>
    )
  }

  return (
    <SwitchCoverageForm
      {...props}
      prefilledValues={{
        paymentPreference: PaymentPreference.Insurance,
        insuranceValues: { ...insuranceInitialValues, insurancePayer: payer },
      }}
    />
  )
}
