import { Participant } from '@twilio/conversations'
import { Fragment, useEffect, useState } from 'react'

import { Avatar, UserIsTypingIndicator } from '@nuna/tunic'

import { useVideoChatContext } from '../../../context/VideoChatContext'
import { useTavaVideoUsers } from '../../../hooks/useTavaVideoUsers'
import { AuthorHeader, HeaderLeftSpacer } from './VideoChatMessage'

export function TypingUsers() {
  const { conversation } = useVideoChatContext()
  const { getUserByLoginId } = useTavaVideoUsers()

  const [typingUserIds, setTypingUserIds] = useState<string[]>([])

  useEffect(() => {
    if (conversation) {
      const handleTypingStarted = (participant: Participant) => {
        setTypingUserIds(prev => {
          if (participant.identity && !prev.includes(participant.identity)) {
            return [...prev, participant.identity]
          }

          return prev
        })
      }
      const handleTypingEnded = (participant: Participant) => {
        setTypingUserIds(prev => prev.filter(id => id !== participant.identity))
      }

      conversation.addListener('typingStarted', handleTypingStarted)
      conversation.addListener('typingEnded', handleTypingEnded)

      return () => {
        conversation.removeListener('typingStarted', handleTypingStarted)
        conversation.removeListener('typingEnded', handleTypingEnded)
      }
    }
  }, [conversation])

  return (
    <>
      {typingUserIds.map(loginId => {
        const user = getUserByLoginId(loginId)

        if (!user) return null

        return (
          <Fragment key={user.loginId}>
            <AuthorHeader className="v-align">
              <HeaderLeftSpacer>
                <Avatar url={user.avatarUrl} size="mini" className="fs-exclude" />
              </HeaderLeftSpacer>
              <span className="small text-bold">{user.firstName}</span>
            </AuthorHeader>
            <div className="v-align">
              <HeaderLeftSpacer />
              <UserIsTypingIndicator />
            </div>
          </Fragment>
        )
      })}
    </>
  )
}
