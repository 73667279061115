import { styled } from '@mui/material'

import { routeService } from '@nuna/core'
import { IconMail, MenuItem } from '@nuna/tunic'

import { useConversationUnreadContext } from '../context/ConversationUnreadContext'
import { UnreadBadge } from './UnreadBadge'

const { conversationsBaseRoute } = routeService

export function ConversationMenuItemLink() {
  const { hasAnyUnread } = useConversationUnreadContext()

  return (
    <MenuItemStyled to={conversationsBaseRoute} leadingIcon={IconMail}>
      Messages
      {hasAnyUnread() && <UnreadBadgeStyled />}
    </MenuItemStyled>
  )
}

const MenuItemStyled = styled(MenuItem)`
  position: relative;
`

const UnreadBadgeStyled = styled(UnreadBadge)`
  position: absolute;
  right: 0.5rem;
`
