import moment from 'moment'

import { CaqhValidationSection } from '@nuna/api'

import { Maybe } from '../types/internal-only'
import { ProviderCredentialing, ProviderCredentialingStatus } from '../types/internal-only/credentialing.types'
import { ProviderStatus } from '../types/internal-only/provider.types'

const READABLE_STATUS_MAP: Record<ProviderStatus, string> = {
  [ProviderStatus.AcceptingClients]: 'Accepting Clients',
  [ProviderStatus.NotAcceptingClients]: 'Not Accepting Clients',
  [ProviderStatus.Onboarding]: 'Onboarding',
  [ProviderStatus.PausedOnboarding]: 'Paused Onboarding',
  [ProviderStatus.Terminated]: 'Terminated',
  [ProviderStatus.Offboarding]: 'Offboarding',
  [ProviderStatus.UnderReview]: 'Under Review',
  [ProviderStatus.Denied]: 'Denied',
}

const READABLE_CREDENTIALING_STATUS_MAP: Record<ProviderCredentialingStatus, string> = {
  [ProviderCredentialingStatus.Archived]: 'Archived',
  [ProviderCredentialingStatus.CommitteeReview]: 'Committee Review',
  [ProviderCredentialingStatus.Credentialed]: 'Credentialed',
  [ProviderCredentialingStatus.CredentialingPacket]: 'Credentialing Packet',
  [ProviderCredentialingStatus.CredentialingPacketConcern]: 'Credentialing Packet Concern',
  [ProviderCredentialingStatus.DataCollection]: 'Data Collection',
  [ProviderCredentialingStatus.DataComplete]: 'Data Complete',
  [ProviderCredentialingStatus.Denied]: 'Denied',
  [ProviderCredentialingStatus.MissingInformation]: 'Missing Information',
  [ProviderCredentialingStatus.VerificationPending]: 'Verification Pending',
}

function readableProviderStatus(status: Maybe<ProviderStatus>): string {
  if (!status) return ''

  return READABLE_STATUS_MAP[status]
}

function readableCredentialingStatus(status?: Maybe<ProviderCredentialingStatus>): string {
  if (!status) return ''

  return READABLE_CREDENTIALING_STATUS_MAP[status]
}

function calculatePercentCaqhValidationCompleted(validationSections: Array<CaqhValidationSection>) {
  if (validationSections) {
    const completedSections = validationSections.filter(section => section.complete) ?? []

    return Math.floor((completedSections.length / validationSections.length) * 100)
  }
  return 0
}

function mostRecentCredentialingDate(
  providerCredentialing?: Pick<ProviderCredentialing, 'initialCredentialedDate' | 'recredentialedDate'> | null,
) {
  const { initialCredentialedDate, recredentialedDate } = providerCredentialing ?? {}
  if (!initialCredentialedDate && !recredentialedDate) return null

  if (initialCredentialedDate && !recredentialedDate) {
    return moment(initialCredentialedDate)
  }

  return moment(recredentialedDate).isAfter(initialCredentialedDate)
    ? moment(recredentialedDate)
    : moment(initialCredentialedDate)
}

export const providerService = {
  readableProviderStatus,
  calculatePercentCaqhValidationCompleted,
  readableCredentialingStatus,
  mostRecentCredentialingDate,
}
