/* eslint-disable @typescript-eslint/no-explicit-any */
// Todo: Sorry just don't feel like dealing with all these anys right now
import * as Yup from 'yup'
import { ApolloError, useMutation } from '@apollo/client'
import { styled } from '@mui/material'
import { Formik } from 'formik'
import { AnimatePresence } from 'framer-motion'
import { noop } from 'lodash'
import React, { useEffect, useRef, useState } from 'react'
import { useMediaQuery } from 'react-responsive'
import { useLocation, useNavigate } from 'react-router-dom'

import {
  Exact,
  PatientLoginDocument,
  PatientLoginMutation,
  ProviderLoginDocument,
  ProviderLoginMutation,
  useSendResetPasswordEmailMutation,
  useSsoOrgsForEmailLazyQuery,
} from '@nuna/api'
import { type Persona, errorService, routeService } from '@nuna/core'
import { useEnvironmentContext } from '@nuna/environment'
import {
  BelowTablet,
  CollapsePresence,
  ContextualAlert,
  type CustomToastOptions,
  FillButton,
  GhostButton,
  Grid,
  IconChevronThick,
  TextField,
  toast,
} from '@nuna/tunic'

import { LoginData, useAuthDataContext } from '../context/AuthDataContext'
import { getFromPath } from '../util/utils'
import { OrDivider, SocialLogin } from './SocialLogin'

const buildSignupSchema = (requirePassword = true) =>
  Yup.object().shape({
    email: Yup.string().required('Email Address is required').email(`Oops, that's not an email address`),
    password: requirePassword ? Yup.string().required('Password is required') : Yup.string(),
  })

const ProviderLoginSchema = Yup.object().shape({
  email: Yup.string()
    .required('Email address is required')
    .email("Oops, that's not an email address")
    .matches(/^(?!.*@tavahealth\.com$).+$/i, 'Oops, please log in with your personal or practice email.'),
  password: Yup.string().required('Password is required'),
})

const ResetSchema = Yup.object().shape({
  email: Yup.string().required('Email is required').email(`Oops, that's not an email address`),
})

interface FormValues {
  email: string
  password: string
}

interface Props {
  getRedirectPath?: (
    loginData: LoginData,
    fromPath?: string | null,
  ) => Promise<string | null | undefined> | string | null | undefined
  audience: Persona
  onLoginSuccess?: (loginData: LoginData) => void
  withSso?: boolean
}

const audienceToWelcomeMessage = {
  client: 'Client Login',
  provider: 'Therapist Login',
  admin: 'Admin Login',
  partnerAdmin: 'Admin Login',
}

export function LoginWidget({ audience, getRedirectPath, onLoginSuccess = noop, withSso = false }: Props) {
  const location = useLocation()
  const navigate = useNavigate()
  const isMobile = useMediaQuery({ query: `(${BelowTablet})` })
  const [showPasswordWidget, setShowPasswordWidget] = useState(audience === 'admin' ? false : true)
  const [showResetWidget, setShowResetWidget] = useState(false)
  const [sharedEmailValue, setSharedEmailValue] = useState('')
  const [loginError, setLoginError] = useState('')
  const [showPasswordField, setShowPasswordField] = useState(withSso ? false : true)
  const [loginMutation, { loading }] = useLoginMutation(audience)
  const { onLogin } = useAuthDataContext()
  const [sendResetPasswordEmailMutation] = useSendResetPasswordEmailMutation()
  const [resetSubmissionSuccessful, setResetSubmissionSuccessful] = useState(false)
  const [ssoOrgsQuery] = useSsoOrgsForEmailLazyQuery()
  const { app } = useEnvironmentContext()
  const forgotPasswordButtonRef = useRef<HTMLButtonElement>(null)

  const fromPath = getFromPath(location)
  const queryParams = new URLSearchParams(location.search)
  const loggedOutRole = queryParams.get('loggedOutRole')
  const socialLoginFailed = queryParams.get('socialLoginFailed')
  const ssoLoginFailed = queryParams.get('ssoLoginFailed')
  const reason = queryParams.get('reason')
  const email = queryParams.get('email')

  useEffect(() => {
    if (loggedOutRole) {
      toast.caution(
        `You have been logged out of your session as a ${loggedOutRole}. You can only be logged into one app at a time.`,
      )
    }
  }, [loggedOutRole])

  useEffect(() => {
    if (socialLoginFailed || ssoLoginFailed) {
      let error = `${socialLoginFailed ? 'Social' : 'SSO'} login failed! Please try a different login method.`
      let options: CustomToastOptions | undefined = undefined
      if (reason === 'accountDeactivated') error = 'Unabled to login: this account is deactivated.'
      if (reason === 'invalidRequiredFields')
        error = 'Unable to create account: required fields are missing or invalid.'
      if (reason === 'accountNotFound' && email) {
        error = `No account found for ${email}. Create a new one instead?`
        options = { action: { buttonText: 'Sign up', onClick: () => navigate(routeService.signup) } }
      }
      toast.urgent(error, options)
    } else if (email) {
      setSharedEmailValue(email)
      setShowPasswordField(true)
    }
  }, [email, navigate, reason, socialLoginFailed, ssoLoginFailed])

  const handleSsoLookup = async (email: string) => {
    try {
      const { data } = await ssoOrgsQuery({ variables: { email }, fetchPolicy: 'network-only' })
      const ssoOrg = data?.ssoOrganizationsForEmail?.[0]

      if (ssoOrg) {
        navigate(routeService.ssoLogin(ssoOrg.slug))
      } else {
        setShowPasswordField(true)
      }
    } catch (error) {
      console.error('Email lookup failed', errorService.transformGraphQlError(error))
      setShowPasswordField(true)
    }
  }

  const handleSubmit = async (
    { email, password }: FormValues,
    { setSubmitting }: { setSubmitting: any; setErrors: any },
  ): Promise<void> => {
    if (!showPasswordField && email) return await handleSsoLookup(email)

    setLoginError('')
    try {
      const response = await loginMutation({
        variables: {
          email,
          password,
        },
      })

      const loginData = response.data ? deserializeLoginData(response.data) : null

      if (loginData) {
        // Notify auth provider of login
        onLogin?.({ login: loginData.login })

        const customPath = getRedirectPath ? await getRedirectPath(loginData.login, fromPath) : null

        // Redirect to appropriate route after login
        let nextPath = '/'
        if (customPath) {
          nextPath = customPath
        } else if (fromPath) {
          nextPath = fromPath
        }

        setSubmitting(false)
        onLoginSuccess(loginData.login)
        // Hard navigation to force reloading JS bundle
        window.location.href = nextPath
      } else {
        setSubmitting(false)
        throw new Error('Login data not received.')
      }
    } catch (error) {
      if (error instanceof ApolloError) {
        setLoginError(error.graphQLErrors[0] ? error.graphQLErrors[0].message : 'Error')
        forgotPasswordButtonRef.current?.scrollIntoView({ behavior: 'smooth' })
      }
      setSubmitting(false)
    }
  }

  const handleResetSubmit = async (
    { email }: { email: string },
    { setSubmitting, setErrors }: { setSubmitting: any; setErrors: any },
  ): Promise<void> => {
    try {
      await sendResetPasswordEmailMutation({
        variables: {
          email,
        },
      })

      setTimeout(() => {
        setResetSubmissionSuccessful(true)

        setTimeout(() => {
          setResetSubmissionSuccessful(false)
          setShowResetWidget(false)
        }, 2500)
      }, 250)

      setSubmitting(false)
    } catch (error) {
      if (error instanceof ApolloError) {
        setErrors({ password: error.graphQLErrors[0] ? error.graphQLErrors[0].message : 'Error' })
      }
      setSubmitting(false)
    }
  }

  if (resetSubmissionSuccessful) {
    return (
      <Container>
        <h1 className="h4">Reset Email Sent.</h1>
        <p className="mb-0"> Please Check Your Email.</p>
      </Container>
    )
  }

  const loginHeading = <h1 className={isMobile ? 'h4' : 'h3'}>{audienceToWelcomeMessage[audience]}</h1>

  return (
    <Container>
      {!showResetWidget && (
        <div className="my-6">
          <Formik
            initialValues={{ email: sharedEmailValue, password: '' }}
            validationSchema={app === 'harmony' ? ProviderLoginSchema : buildSignupSchema(showPasswordField)}
            onSubmit={handleSubmit}
            enableReinitialize
          >
            {({ values, errors, touched, handleBlur, handleSubmit, handleChange, isSubmitting }) => (
              <>
                <SocialLogin
                  audience={audience}
                  emailHint={withSso && showPasswordField ? values.email : undefined}
                  from={fromPath}
                  heading={loginHeading}
                  intent="login"
                  onSwitchToPasswordLogin={() => setShowPasswordWidget(true)}
                  showSwitchToPasswordLogin={!showPasswordWidget}
                />
                <LoginFormContainer
                  style={{
                    maxHeight: showPasswordWidget ? '440px' : '0',
                    marginTop: showPasswordWidget ? '0' : '16px',
                  }}
                  aria-hidden={!showPasswordWidget}
                  socialLogin={!showResetWidget}
                >
                  <OrDivider className="text-center mb-4">or</OrDivider>
                  <form onSubmit={handleSubmit}>
                    <TextField
                      label="Email"
                      type="email"
                      name="email"
                      onChange={e => {
                        setSharedEmailValue(e.target.value)
                        // Ensure that we require another lookup in SSO mode when email changes
                        if (withSso) setShowPasswordField(false)
                        handleChange(e)
                      }}
                      onBlur={handleBlur}
                      value={values.email}
                      helperText={touched.email && errors.email}
                      inputProps={{
                        'data-testid': 'login-email',
                      }}
                      error={(errors.email && touched.email) as boolean}
                      className="mb-3"
                    />

                    <WithConditionalAnimation animationEnabled={withSso} isVisible={showPasswordField}>
                      <TextField
                        className="mb-5"
                        label="Password"
                        type="password"
                        name="password"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.password}
                        autoFocus
                        inputProps={{
                          'data-testid': 'login-password',
                        }}
                        error={(errors.password && touched.password) as boolean}
                        helperText={touched.password && errors.password}
                      />
                    </WithConditionalAnimation>

                    <Grid container alignContent="center">
                      <Grid size={12}>
                        <FillButton
                          isLoading={loading}
                          type="submit"
                          disabled={isSubmitting}
                          data-testid="login-submit"
                        >
                          {showPasswordField ? 'Sign In' : 'Continue'}
                        </FillButton>
                      </Grid>
                      {loginError ? (
                        <Grid size={12}>
                          <ContextualAlert className="mt-2" intent="urgent">
                            {loginError}
                          </ContextualAlert>
                        </Grid>
                      ) : null}
                      <Grid size={12}>
                        <GhostButton
                          className="mt-5"
                          variant="secondary"
                          onClick={() => {
                            setShowResetWidget(true)
                          }}
                          ref={forgotPasswordButtonRef}
                        >
                          Forgot Password?
                        </GhostButton>
                      </Grid>
                    </Grid>
                  </form>
                </LoginFormContainer>
              </>
            )}
          </Formik>
        </div>
      )}
      {showResetWidget && (
        <React.Fragment>
          <h1 className={`mb-1 ${isMobile ? 'h3' : 'h2'}`}>Forgot your password?</h1>
          <div className="large mb-5">It happens. We'll just email you a reset link.</div>
          <Formik
            initialValues={{ email: sharedEmailValue }}
            onSubmit={handleResetSubmit}
            validationSchema={ResetSchema}
          >
            {({ values, errors, touched, handleBlur, handleSubmit, handleChange, isSubmitting, isValid }) => (
              <form onSubmit={handleSubmit}>
                <div>
                  <TextField
                    className="mb-5"
                    label="Email Address"
                    type="email"
                    name="email"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.email}
                    error={touched.email && !!errors.email}
                    helperText={touched.email && errors.email}
                    inputProps={{
                      'data-testid': 'login-reset-email',
                    }}
                  />

                  <Grid container alignContent="center">
                    <Grid size={12}>
                      <FillButton
                        isLoading={false}
                        type="submit"
                        disabled={!isValid || isSubmitting}
                        data-testid="login-reset-submit"
                      >
                        Send Link
                      </FillButton>
                    </Grid>
                    <Grid size={12}>
                      <GhostButton
                        className="mt-5"
                        variant="secondary"
                        onClick={() => {
                          setShowResetWidget(false)
                        }}
                      >
                        <IconChevronThick className="mr-xs" size={16} /> Back to Login
                      </GhostButton>
                    </Grid>
                  </Grid>
                </div>
              </form>
            )}
          </Formik>
        </React.Fragment>
      )}
    </Container>
  )
}

const WithConditionalAnimation = ({
  animationEnabled,
  children,
  isVisible,
}: React.PropsWithChildren<{
  animationEnabled: boolean
  isVisible?: boolean
}>) => {
  if (!animationEnabled) return children as JSX.Element

  return <AnimatePresence>{isVisible ? <CollapsePresence>{children}</CollapsePresence> : null}</AnimatePresence>
}

const Container = styled('div')`
  background-color: white;
  width: 486px;
  max-width: 100%;
`

const LoginFormContainer = styled('div')<{ socialLogin?: boolean }>`
  margin: -16px auto 0;
  overflow: hidden;
  transition-property: max-height;
  transition-duration: 600ms;
  transition-timing-function: ease-out;
`

type LoginMutation = PatientLoginMutation | ProviderLoginMutation

function useLoginMutation(audience: Persona) {
  const document = audience === 'client' ? PatientLoginDocument : ProviderLoginDocument
  return useMutation<
    LoginMutation,
    Exact<{
      email: string
      password: string
    }>
  >(document)
}

function deserializeLoginData(data: LoginMutation) {
  if ((data as PatientLoginMutation).patientLogin) {
    return (data as PatientLoginMutation).patientLogin
  } else {
    return (data as ProviderLoginMutation).providerAndAdminLogin
  }
}
