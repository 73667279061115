import React from 'react'

import { error } from '../styles/colors'
import { IconHoverProps } from './IconProps'

export function IconCancelAppointment({ isHovered, size = 24, color = 'currentColor', ...props }: IconHoverProps) {
  return (
    <svg width={size} height={size} viewBox="0 0 24 24" fill={color} xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        fill={isHovered ? error : color}
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.1245 14.4749L14.9891 17.3395L12.1245 20.204C11.8642 20.4644 11.8642 20.8865 12.1245 21.1468L12.9687 21.991C13.229 22.2513 13.6511 22.2513 13.9115 21.991L16.776 19.1264L19.6406 21.991C19.9009 22.2513 20.323 22.2513 20.5834 21.991L21.4276 21.1468C21.6879 20.8865 21.6879 20.4644 21.4276 20.204L18.563 17.3395L21.4276 14.4749C21.6879 14.2146 21.6879 13.7924 21.4276 13.5321L20.5834 12.6879C20.323 12.4276 19.9009 12.4276 19.6406 12.6879L16.776 15.5525L13.9115 12.6879C13.6511 12.4276 13.229 12.4276 12.9687 12.6879L12.1245 13.5321C11.8642 13.7924 11.8642 14.2146 12.1245 14.4749Z"
      />
      <path d="M6.24906 19.0857C5.53691 19.0857 4.93569 18.4976 4.93569 17.7723V10.2739H17.4317V12.1044C17.4317 12.2854 17.6472 12.3808 17.7819 12.2599C18.2361 11.8525 18.5644 11.5619 18.979 11.1016C19.0468 11.0264 19.0833 10.9285 19.0833 10.8274V7.64961C19.0833 6.20651 17.9038 5.0358 16.4497 5.0358L16.4444 3.72627C16.4444 3.00603 15.8507 2.41675 15.125 2.41675C14.3993 2.41675 13.8056 3.00603 13.8056 3.72627V5.0358H8.52778V3.72627C8.52778 3.00603 7.93403 2.41675 7.20833 2.41675C6.48264 2.41675 5.88889 3.00603 5.88889 3.72627V5.0358C4.42958 5.0358 3.25 6.20651 3.25 7.64961V18.1363C3.25 19.5794 4.42958 20.7501 5.88361 20.7501H9.34171C9.45905 20.7501 9.5711 20.7003 9.65028 20.6137C10.4388 19.7515 10.6251 19.593 10.8091 19.4236C10.9429 19.3005 10.8504 19.0857 10.6685 19.0857H6.24906Z" />
    </svg>
  )
}
