import { isArray } from 'lodash'

import { Role } from '@nuna/api'

import { useAuthDataContext } from '../context/AuthDataContext'

export function useUserRole() {
  const { login } = useAuthDataContext()
  return login?.role
}

export function useHasRole(roleInput: Role | (Role | string)[]) {
  const role = useUserRole()

  if (!role) {
    return false
  }

  return hasRole(role, roleInput)
}

export function hasRole(role: Role | string | undefined, roleInput: Role | (Role | string)[]) {
  if (!role) {
    return false
  }

  if (isArray(roleInput)) {
    return roleInput.includes(role)
  }

  return role === roleInput
}
