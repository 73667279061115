import { styled } from '@mui/material'
import { TextareaAutosize } from '@mui/material'
import React, { useRef } from 'react'
import mergeRefs from 'react-merge-refs'

import { greySet } from '../../styles/colorSets'
import { borderGrey } from '../../styles/colors'
import { lineHeight } from '../../styles/global/typography.utils'
import { csx } from '../../utils/csx'
import { styledUtils } from '../../utils/styled'
import { type InputStylesProps, RawInputProps, inputStyles } from '../BaseInput/BaseInput'

export type MultilineTextFieldVariant = 'standard' | 'outlined'

const { transientPropOptions } = styledUtils

interface MultilineTextFieldProps extends Omit<React.HTMLProps<HTMLTextAreaElement>, 'as'>, RawInputProps {
  onChange: React.ChangeEventHandler<HTMLTextAreaElement>
  rows?: number
  rowsMax?: number
  variant?: MultilineTextFieldVariant
}

export const MultilineTextField = React.forwardRef<HTMLTextAreaElement, MultilineTextFieldProps>(
  ({ value, rowsMax, rows = 2, inputProps, variant = 'standard', ...props }, ref) => {
    const textAreaRef = useRef<HTMLTextAreaElement>(null)

    return (
      <MultilineInput
        {...props}
        className={csx([variant])}
        {...inputProps}
        ref={mergeRefs([textAreaRef, ref])}
        value={value}
        maxRows={rowsMax}
        minRows={rows}
        $placeholderAlwaysVisible={!!props.placeholder && !props.label}
      />
    )
  },
)

const MultilineInput = styled(TextareaAutosize, transientPropOptions)<InputStylesProps>`
  ${inputStyles}
  resize: none;
  line-height: ${lineHeight};

  &.outlined {
    border: 1px solid ${borderGrey};
    padding: var(--margin-1);
    border-radius: var(--border-radius-sm);

    transition: border-color 0.3s;

    &:focus {
      border-color: ${greySet[50].hex};
    }
  }
`
