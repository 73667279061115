import { IconProps } from './IconProps'

export function IconThumbsUp({ size = 24, color = 'currentColor', ...props }: IconProps) {
  return (
    <svg width={size} height={size} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M18.4742 9.94009C18.6105 10.8963 17.9836 11.8094 17.0223 12.5529C16.1878 14.9101 15.0472 17.7114 14.1896 18.2063C13.9814 18.326 13.5903 18.4853 12.5968 18.4853C10.6863 18.4853 7.16582 17.5321 5.99082 17.0557C5.67582 16.928 5.49241 16.6745 5.49241 16.3371V9.10858C5.49241 8.82741 5.59567 8.61627 5.82858 8.41775C6.11956 8.16972 7.11274 7.33562 8.45235 6.57345C10.2421 5.55459 11.4682 4.21602 11.7128 3.47402C11.7568 3.33991 11.7971 3.20811 11.8361 3.08035C12.0995 2.21922 12.4601 1.03955 13.8405 1.03955C14.5549 1.03955 15.1429 1.45359 15.4535 2.17542C15.9523 3.33377 15.6885 5.35746 14.3479 7.31526C15.079 7.49317 15.9779 7.722 16.5075 7.89414C17.5084 8.21884 18.3418 9.01134 18.4742 9.94009ZM15.9696 11.4322C16.574 11.0139 16.8865 10.5346 16.8268 10.1173C16.7796 9.7845 16.4894 9.50073 16.0509 9.35875C15.1304 9.05979 12.8688 8.54296 12.8462 8.53778C12.584 8.47802 12.3727 8.27975 12.2921 8.01788C12.2113 7.756 12.2739 7.47031 12.4559 7.26799C14.11 5.43066 14.3982 3.54549 14.0746 2.79369C13.9812 2.57658 13.8931 2.57658 13.8405 2.57658C13.6421 2.57658 13.5681 2.57658 13.2738 3.53819C13.2316 3.67633 13.188 3.8187 13.1403 3.96376C12.7276 5.21625 11.1382 6.80458 9.18551 7.91585C8.45678 8.3306 7.73216 8.85531 7.25528 9.2047C7.04502 9.35874 6.99846 9.52585 6.99846 9.70127V15.4543C6.99846 15.7675 7.04669 16.0187 7.42293 16.1214C8.6736 16.4627 11.017 16.948 12.5966 16.948C13.1503 16.948 13.373 16.8914 13.4367 16.8704C13.8673 16.4535 14.9369 13.9422 15.6832 11.8097C15.7373 11.6562 15.8374 11.5238 15.9696 11.4322ZM2.40291 8.99072C2.73643 8.92454 3.51492 8.79707 3.88973 8.71169C4.26453 8.62631 4.34184 8.89942 4.34184 9.25765V16.1172C4.34184 16.5254 4.11585 16.6436 3.81974 16.5963C3.62384 16.565 2.4005 16.4052 2.04636 16.2437C1.80482 16.1336 1.68701 15.9299 1.68701 15.6386V9.76933C1.68701 9.24378 1.78091 9.11415 2.40291 8.99072Z"
        fill={color}
      />
    </svg>
  )
}
