import { styled } from '@mui/material'
import { MouseEventHandler } from 'react'

import {
  ConversationMessageAttachmentFragment,
  DocumentType,
  useDocumentUpload,
  useRemoveMessageAttachmentMutation,
  useSaveMessageAttachmentMutation,
} from '@nuna/api'
import { useEffectOnce } from '@nuna/common'
import { AttachmentChip } from '@nuna/tunic'

import { useMessageComposerContext } from '../../../context/MessageComposerContext'

interface ConversationMessageAttachmentProps {
  attachment: ConversationMessageAttachmentFragment
  onRemoveClick?: MouseEventHandler<HTMLButtonElement>
}

export function ConversationMessageAttachment({ attachment, onRemoveClick }: ConversationMessageAttachmentProps) {
  if (!attachment.url) return null

  return (
    <StyledAttachmentChip
      downloadUrl={attachment.url}
      fileName={attachment.fileName}
      mimeType={attachment.mimeType}
      onRemove={onRemoveClick}
    />
  )
}

export type PendingAttachmentStatus = 'pending' | 'uploading' | 'attaching' | 'success' | 'error'

export interface PendingAttachment {
  id?: string
  file: File
  status: PendingAttachmentStatus
  retryKey?: number
}

interface PendingConversationAttachmentProps {
  pendingAttachment: PendingAttachment
  onRemoveClick: MouseEventHandler<HTMLButtonElement>
  onSuccess: (pendingAttachment: PendingAttachment) => void
  onUploadStart: (pendingAttachment: PendingAttachment) => void
  onAttachmentStart: (pendingAttachment: PendingAttachment) => void
  onError: (pendingAttachment: PendingAttachment) => void
}

export function PendingConversationAttachment({
  pendingAttachment,
  onRemoveClick,
  onUploadStart,
  onAttachmentStart,
  onSuccess,
  onError,
}: PendingConversationAttachmentProps) {
  const { getDraft } = useMessageComposerContext()
  const { uploadDocument } = useDocumentUpload()
  const [saveMessageAttachment, { data: conversationMessageAttachmentData }] = useSaveMessageAttachmentMutation()
  const [removeMessageAttachment, { loading: removeLoading }] = useRemoveMessageAttachmentMutation()

  const upload = async () => {
    try {
      onUploadStart(pendingAttachment)
      const [messageDraft, document] = await Promise.all([
        getDraft(),
        uploadDocument(pendingAttachment.file, DocumentType.MessageAttachment),
      ])

      onAttachmentStart(pendingAttachment)
      const attachment = await saveMessageAttachment({
        variables: { documentId: document.id, messageId: messageDraft.id },
      }).then(response => {
        if (!response.data) {
          throw new Error('Response from saveMessageAttachment was empty')
        }

        return response.data.saveMessageAttachment
      })

      onSuccess({ ...pendingAttachment, id: attachment.id })
    } catch (e) {
      console.error(e)

      onError(pendingAttachment)
    }
  }

  useEffectOnce(() => {
    upload()
  }, pendingAttachment.status === 'pending')

  if (conversationMessageAttachmentData) {
    const attachment = conversationMessageAttachmentData.saveMessageAttachment

    return (
      <StyledAttachmentChip
        status={removeLoading ? 'loading' : 'success'}
        fileName={attachment.fileName}
        mimeType={attachment.mimeType}
        onRemove={async e => {
          await removeMessageAttachment({
            variables: { attachmentId: attachment.id },
          })
          onRemoveClick(e)
        }}
      />
    )
  }

  const getPendingStatus = () => {
    if (pendingAttachment.status === 'success') {
      return 'success'
    }
    if (pendingAttachment.status === 'error') {
      return 'error'
    }

    return 'loading'
  }

  return (
    <StyledAttachmentChip
      fileName={pendingAttachment.file.name}
      mimeType={pendingAttachment.file.type}
      status={getPendingStatus()}
    />
  )
}

// util function that creates a callback to be passed to parent component's setPendingAttachments
export function updateAttachmentStatus(updatingAttachment: PendingAttachment, updatedStatus: PendingAttachmentStatus) {
  return function setStateCb(existingAttachments: PendingAttachment[]) {
    return existingAttachments.map(attachment => {
      if (updatingAttachment.file === attachment.file) {
        // Increment retryKey to reset status of attachment component. This allows us to rely on useEffectOnce to trigger upload on mount which is more predictable
        const retryKey = updatedStatus === 'pending' ? (attachment.retryKey ?? 0) + 1 : attachment.retryKey

        // Comparing file here since the identity of the File object doesn't change and we don't have to worry about the onError closure noted above
        return { ...updatingAttachment, status: updatedStatus, retryKey }
      }

      return attachment
    })
  }
}

const StyledAttachmentChip = styled(AttachmentChip)`
  margin-top: var(--spacing-1);
  margin-right: var(--spacing-1);
`
