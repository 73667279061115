import { LDFlagSet } from 'launchdarkly-node-client-sdk'
import { camelCase } from 'lodash'

import { FeatureFlags } from '../generated/generated'

export function camelizeFlags(flags: LDFlagSet): FeatureFlags {
  const camelized = {} as Record<string, string>
  Object.entries(flags).forEach(([key, value]) => (camelized[camelCase(key)] = value))

  return camelized as unknown as FeatureFlags
}
