import { IconProps } from './IconProps'

export function IconPencil({ size = 24, color = 'currentColor', ...props }: IconProps) {
  return (
    <svg width={size} height={size} viewBox="0 0 24 24" fill={color} xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M18.8822 9.4982L20.033 8.34833C20.4398 7.94423 20.4299 7.28388 20.0231 6.88964L17.0005 3.94596C16.5904 3.54515 15.9521 3.58129 15.5884 4.01824L14.5169 5.29953L18.8822 9.4982ZM5.53167 14.4065C5.12821 14.8172 4.6983 15.609 4.57925 16.1609L3.6665 20.3333L7.89951 19.5547C8.46501 19.4495 9.25208 19.0454 9.66215 18.6446L18.1249 10.359L13.753 6.06501L5.53167 14.4065ZM6.12363 15.3494C6.12363 15.3494 7.1422 16.3186 6.73212 17.2385C7.74408 17.0085 8.73619 17.9744 8.73619 17.9744L5.54821 18.4015L6.12363 15.3494Z"
      />
    </svg>
  )
}
