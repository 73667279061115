import { useMemo } from 'react'

import { AppointmentCurrentStatus, DrawerAppointmentFragment } from '@nuna/api'
import { AppointmentCard, AppointmentCardStatus } from '@nuna/appointment'
import { ProviderClientPaymentStatusAlert } from '@nuna/common'
import { Audience, routeService } from '@nuna/core'
import { FillButtonLink, GhostButtonLink, StatusLabel } from '@nuna/tunic'

import { AppointmentDrawerPaddedContainer } from '../../../AppointmentDrawer'
import { ClientDrawerHeader } from '../../ClientDrawerHeader'
import { mapAppointmentStatusToCardStatus } from './utils'

interface Props {
  appointment: DrawerAppointmentFragment
  audience: Audience
}

export function PastAppointment({ appointment, audience }: Props) {
  const sessionId = appointment.session?.id
  const paymentStatus = appointment?.patient?.paymentStatus
  const isCanceled = [
    AppointmentCurrentStatus.CanceledByAdmin,
    AppointmentCurrentStatus.CanceledByPatient,
    AppointmentCurrentStatus.CanceledByProvider,
  ].includes(appointment.currentStatus)

  const appointmentStatus = useMemo(
    () =>
      appointment?.currentStatus
        ? mapAppointmentStatusToCardStatus(appointment?.currentStatus)
        : AppointmentCardStatus.Active,
    [appointment?.currentStatus],
  )
  return (
    <>
      <ClientDrawerHeader client={appointment.patient} />
      {!!paymentStatus && (
        <AppointmentDrawerPaddedContainer className="mt-3">
          <ProviderClientPaymentStatusAlert scene={'clientInfo'} paymentStatus={paymentStatus} />
        </AppointmentDrawerPaddedContainer>
      )}
      <AppointmentDrawerPaddedContainer className="mt-2">
        <AppointmentCard
          appointment={appointment}
          client={appointment.patient}
          provider={appointment.provider}
          showAvatar={false}
          audience={audience}
          appointmentStatus={appointmentStatus}
          additionalSlots={
            sessionId || appointmentStatus === AppointmentCardStatus.Canceled
              ? {}
              : { detailTop: <StatusLabel className="mb-1">Incomplete Note</StatusLabel> }
          }
          showTopStatus={appointmentStatus === AppointmentCardStatus.Canceled}
          boot={appointmentStatus !== AppointmentCardStatus.Canceled}
        />
        {!sessionId && audience === 'provider' && !isCanceled && (
          <FillButtonLink className="mt-2 full-width" to={routeService.createSessionNote(appointment.id)}>
            Write Session Note
          </FillButtonLink>
        )}
        {!!sessionId && (
          <div className="mt-2 text-center">
            <GhostButtonLink to={routeService.sessionNote(sessionId, audience)}>Review Session Note</GhostButtonLink>
          </div>
        )}
      </AppointmentDrawerPaddedContainer>
    </>
  )
}
