import { IconProps } from './IconProps'

export function IconUser({ size = 24, color = 'currentColor', ...props }: IconProps) {
  const height = (21 / 20) * size

  return (
    <svg width={height} height={height} viewBox="0 0 20 21" fill={color} xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.2836 11.5353L12.267 9.73703C12.267 9.73703 12.9555 9.1979 13.1696 7.50695C13.6002 7.63359 14.0521 6.85204 14.0758 6.43955C14.1006 6.04274 14.0166 4.94157 13.4914 5.05254C13.599 4.22274 13.6771 3.47737 13.6392 3.08297C13.502 1.63927 12.1073 0.115967 9.9638 0.115967C7.8191 0.115967 6.4244 1.63927 6.28836 3.08297C6.2505 3.47737 6.32858 4.22274 6.43505 5.05254C5.90981 4.94157 5.82701 6.04274 5.85067 6.43955C5.87551 6.85204 6.32621 7.63359 6.75799 7.50695C6.97092 9.1979 7.66059 9.73703 7.66059 9.73703L7.64284 11.5353C7.64284 11.5353 7.20633 11.8055 5.3692 12.587C3.53207 13.3674 1.67483 13.9138 1.12239 14.793C0.626734 15.5794 1.12239 19.3534 1.12239 19.3534C1.12239 19.3534 3.80034 20.8841 9.9638 20.8841C16.1273 20.8841 19.0184 19.3534 19.0184 19.3534C19.0184 19.3534 19.2997 15.5794 18.8052 14.793C18.2516 13.9138 16.3955 13.3674 14.5572 12.587C12.7201 11.8055 12.2836 11.5353 12.2836 11.5353Z"
      />
    </svg>
  )
}
