import { IconProps } from './IconProps'

export function IconClose({ size = 24, color = 'currentColor', ...props }: IconProps) {
  return (
    <svg width={size} height={size} viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M19.5234 24L4 8.47656L8.47656 4L24 19.5234L39.5234 4L44 8.47656L28.4766 24L44 39.5234L39.5234 44L24 28.4766L8.47656 44L4 39.5234L19.5234 24Z"
        fill={color}
      />
    </svg>
  )
}
