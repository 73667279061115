import { styled } from '@mui/material'
import moment from 'moment-timezone'
import { useMemo, useState } from 'react'

import { PeriodConstraint } from '@nuna/api'
import { useIsAdmin } from '@nuna/auth'
import { availabilityService, timeService } from '@nuna/core'
import { DatePicker, GhostButton, PlaceholderButton } from '@nuna/tunic'

import { useAvailabilityContext } from '../context/AvailabilityContextProvider'
import { ProviderAvailabilityProps } from '../types'
import { AvailabilityPeriods } from './AvailabilityPeriods'
import { AvailabilityTimezoneDisplay } from './AvailabilityTimezoneDisplay'
import {
  Container,
  DescriptionCaption,
  ErrorText,
  HoursContainer,
  SeperatorText,
  StyledSelect,
  TimeForm,
} from './ProviderAvailabilitySharedStyles'

const { getAvailabilityConstraintInput } = availabilityService
const { hoursOfDay, mergeDateAndTime, rangesOverlap } = timeService

export function ProviderExtraTime({ provider }: ProviderAvailabilityProps) {
  const isAdmin = useIsAdmin()
  const { deleteConstraints, updateConstraints, updateLoading, timezone } = useAvailabilityContext()

  const [isEditing, setIsEditing] = useState(false)
  const [date, setDate] = useState(moment())
  const [startTime, setStartTime] = useState(hoursOfDay[28].value)
  const [endTime, setEndTime] = useState(hoursOfDay[30].value)

  const startDateTime = mergeDateAndTime(date, startTime)
  const endDateTime = mergeDateAndTime(date, endTime)

  const isValid = startDateTime.isBefore(endDateTime)

  const blockPeriods = provider.availabilityPeriods.blockPeriod
  const allowPeriods = provider.availabilityPeriods.allowPeriod

  const conflictingAwayTime = blockPeriods.some(awayTimeRange =>
    rangesOverlap(awayTimeRange, { start: startDateTime, end: endDateTime }),
  )

  const handleAddAvailabilityPeriod = async () => {
    const newPeriod = {
      start: startDateTime.tz(timezone ?? '', false).toISOString(),
      end: endDateTime.tz(timezone ?? '', false).toISOString(),
    }

    const availabilityConstraintsInput = getAvailabilityConstraintInput({
      availabilityConstraints: { allowPeriod: [newPeriod] },
      isAdmin,
    })
    await updateConstraints(availabilityConstraintsInput)
    setIsEditing(false)
  }

  const handleRemoveAvailabilityPeriod = async (constraintToRemove: PeriodConstraint) => {
    await deleteConstraints([constraintToRemove.id])
  }

  // Only show periods that are in the future
  // Sort by start time
  const periodsToShow = useMemo(
    () =>
      allowPeriods
        .filter(constraint => moment(constraint.start).isAfter(moment()) || moment(constraint.end).isAfter(moment()))
        .sort((a, b) => moment(a.start).unix() - moment(b.start).unix()),
    [allowPeriods],
  )

  return (
    <Container>
      <DescriptionCaption>
        <span>Add extra availability outside of your regular hours</span>
        <AvailabilityTimezoneDisplay />
      </DescriptionCaption>

      {!isEditing ? (
        <PlaceholderButton className="mt-2" type="button" onClick={() => setIsEditing(true)}>
          Add Extra Hours
        </PlaceholderButton>
      ) : (
        <div className="v-align">
          <TimeForm>
            <ExtraForm>
              <DatePicker
                disablePast
                format="MMM D, YYYY"
                value={date}
                containerProps={{ className: 'v-align' }}
                label="&nbsp;"
                onChange={date => {
                  if (date) {
                    setDate(date)
                  }
                }}
              />

              <SeperatorText>from</SeperatorText>

              <StyledSelect value={startTime} onChange={e => setStartTime(e.currentTarget.value)} error={!isValid}>
                {hoursOfDay.map(hour => (
                  <option key={hour.value} value={hour.value}>
                    {hour.display}
                  </option>
                ))}
              </StyledSelect>

              <SeperatorText>until</SeperatorText>

              <StyledSelect value={endTime} onChange={e => setEndTime(e.currentTarget.value)} error={!isValid}>
                {hoursOfDay.map(hour => (
                  <option key={hour.value} value={hour.value}>
                    {hour.display}
                  </option>
                ))}
              </StyledSelect>

              {!isValid && !conflictingAwayTime && (
                <PositionedError position={3}>Start time must be before end time</PositionedError>
              )}
              {conflictingAwayTime && (
                <PositionedError position={1}>
                  You have already added Away time during this period. To add this Extra time, please first remove the
                  conflicting Away time.
                </PositionedError>
              )}
            </ExtraForm>
          </TimeForm>

          <div className="ml-3 flex-static">
            <GhostButton
              onClick={handleAddAvailabilityPeriod}
              disabled={!isValid || conflictingAwayTime}
              isLoading={updateLoading}
            >
              Add Extra Time
            </GhostButton>
          </div>
        </div>
      )}

      <HoursContainer>
        <AvailabilityPeriods
          type="extra"
          providerTimezone={timezone}
          constraints={periodsToShow}
          onRemoveClick={handleRemoveAvailabilityPeriod}
        />
      </HoursContainer>
    </Container>
  )
}

const PositionedError = styled(ErrorText)<{ position: number }>`
  grid-column: ${({ position }) => position} / 6;
`

const ExtraForm = styled('div')`
  align-items: center;
  display: grid;
  grid-template-rows: auto auto;
  grid-template-columns: 2fr auto 1fr auto 1fr;
  grid-gap: 0 var(--margin-2);
`
