import { DailyEventObjectAppMessage, DailyEventObjectNetworkConnectionEvent } from '@daily-co/daily-js'
import { useAppMessage, useDailyEvent, useMeetingState } from '@daily-co/daily-react'
import { useCallback, useEffect, useState } from 'react'

import { type NetworkQualityThreshold } from '../types'

export interface SchedulingNotificationMessage {
  type: 'scheduling-notification'
  appointmentStartTime: string
}

export interface SessionEndedMessage {
  type: 'session-ended'
  endTime: string
}

export interface NetworkQualityMessage {
  type: 'network-quality'
  threshold: NetworkQualityThreshold
}

export interface AutonotesToggleMessage {
  type: 'autonotes-toggle'
  enabled: boolean
  loginId: string
}

export type DailyMessage =
  | SchedulingNotificationMessage
  | SessionEndedMessage
  | NetworkQualityMessage
  | AutonotesToggleMessage

/**
 * Wrapper to add types and prejoin queue to Daily App Messages
 * @param handleMessageReceived - Memoized (useCallback) callback to handle the message. Daily will error if it is not memoized.
 */
export function useDailyMessage(handleMessageReceived?: (e: DailyEventObjectAppMessage<DailyMessage>) => void) {
  const [messageQueue, setMessageQueue] = useState<{ message: DailyMessage; to?: string }[]>([])
  const [isNetworkConnected, setIsNetworkConnected] = useState(false)
  const meetingState = useMeetingState()

  const canSend = meetingState === 'joined-meeting' && isNetworkConnected

  const sendAppMessage = useAppMessage({ onAppMessage: handleMessageReceived })

  const handleNetworkConnectionEvent = useCallback((e: DailyEventObjectNetworkConnectionEvent) => {
    if ((e.type === 'sfu' || e.type === 'peer-to-peer') && e.event === 'connected') {
      // we use SFU for all calls, but just in case check p2p as well so if the settings change we don't break messaging
      setIsNetworkConnected(true)
    }
  }, [])

  useDailyEvent('network-connection', handleNetworkConnectionEvent)

  useEffect(() => {
    if (canSend && messageQueue.length > 0) {
      messageQueue.forEach(({ message, to }) => sendAppMessage(message, to))
      setMessageQueue([])
    }
  }, [canSend, messageQueue, sendAppMessage])

  const sendDailyMessage = useCallback(
    (message: DailyMessage, to?: string) => {
      if (!canSend) {
        setMessageQueue(prev => [...prev, { message, to }])
        return
      }

      sendAppMessage(message, to)
    },
    [sendAppMessage, canSend],
  )

  return sendDailyMessage
}
