import { isNil, noop } from 'lodash'
import { ReactNode, createContext, useCallback, useContext, useEffect, useMemo, useState } from 'react'
import { Path, useLocation, useNavigate } from 'react-router-dom'

import {
  BasicSpecialtyFragment,
  HubspotContactOwnerFragment,
  ProviderIntakeFragment,
  SpecialtyInput,
  useHubspotContactOwnerQuery,
  useListCancelPoliciesQuery,
  useProviderCredentialingDataQuery,
  useProviderSignupIntakeQuery,
  useSaveProviderIntakeMutation,
} from '@nuna/api'
import { errorService, routeService } from '@nuna/core'
import { providerDataSummaryUtils } from '@nuna/provider'
import { toast } from '@nuna/tunic'

import { useProviderAppContext } from '../../shared/ProviderAppContext'
import {
  ProviderSignupFormStepConfig,
  ProviderSignupFormValues,
  ProviderSignupNavigationDirection,
  SaveProviderIntakeInput,
} from './shared/provider-signup-intake-types'
import {
  IntakeInsuranceStepStatus,
  IntakeStep,
  IntakeStepStatus,
  buildBackgroundCheckStatuses,
  buildCancelPolicyStatus,
  buildInsuranceStepsStatuses,
  buildStepStatuses,
  getEarliestIncompleteStep,
  getNextIntakePath,
  getPath,
  getPrevIntakePath,
  getStepFromPath,
} from './signup-intake-steps'

interface ProviderSignupIntakeContext {
  saveIntake: (values: SaveProviderIntakeInput) => Promise<boolean>
  buildValuesAndSave: (formValues: ProviderSignupFormValues | null | undefined) => Promise<boolean>
  saveAndNavigate: (
    formValues: ProviderSignupFormValues | null | undefined,
    path: 'forward' | 'back' | string,
  ) => Promise<boolean>
  saveIntakeLoading: boolean
  data?: ProviderIntakeFragment | null
  queryLoading: boolean
  currentStep: IntakeStep
  currentFormStepConfig: ProviderSignupFormStepConfig
  setCurrentFormStepConfig: (config: ProviderSignupFormStepConfig) => void
  stepStatuses: IntakeStepStatus[]
  insuranceStepsStatuses: IntakeInsuranceStepStatus[]
  backgroundCheckStatuses: IntakeInsuranceStepStatus | undefined
  cancelPolicyStatus: IntakeInsuranceStepStatus | undefined
  allRequirementsMet: boolean
  resumePath: string | Path
  guide: HubspotContactOwnerFragment
}

const ProviderSignupIntakeContext = createContext(buildDefault())

interface ProviderSignupIntakeContextProviderProps {
  children?: ReactNode
}

export function ProviderSignupIntakeContextProvider({ children }: ProviderSignupIntakeContextProviderProps) {
  const navigate = useNavigate()
  const location = useLocation()
  const { provider, providerDataSummaryItems, refetchStatus } = useProviderAppContext()
  const [currentStep, setCurrentStep] = useState<IntakeStep>('welcome-support')
  const [currentFormStepConfig, setCurrentFormStepConfig] = useState<ProviderSignupFormStepConfig>(
    buildDefaultFormStepConfig(),
  )

  const {
    data: intakeData,
    loading: queryLoading,
    refetch: refetchProviderIntake,
  } = useProviderSignupIntakeQuery({
    skip: !provider?.id,
    variables: { id: provider?.id ?? '' },
  })

  const { data: hubspotContactData } = useHubspotContactOwnerQuery({
    skip: !provider?.id,
    variables: { providerId: provider?.id ?? '' },
  })

  const { data: credentialingData } = useProviderCredentialingDataQuery({
    variables: { providerId: provider?.id ?? '' },
    skip: !provider?.id,
  })

  const { data: lateCancelData } = useListCancelPoliciesQuery({
    variables: { providerId: provider?.id ?? '' },
    skip: !provider?.id,
    fetchPolicy: 'cache-and-network',
  })

  const guide: HubspotContactOwnerFragment = !hubspotContactData?.hubspotContactOwner
    ? { email: '', scheduleUrl: '' }
    : hubspotContactData.hubspotContactOwner

  const stepStatuses = useMemo(
    () => ({
      stepStatuses: buildStepStatuses(providerDataSummaryItems),
      backgroundCheckStatuses: buildBackgroundCheckStatuses(providerDataSummaryItems),
      cancelPolicyStatus: buildCancelPolicyStatus(lateCancelData?.listCancelPolicies ?? []),
    }),
    [providerDataSummaryItems, lateCancelData],
  )

  const insuranceStepsStatuses = useMemo(
    () => (credentialingData ? buildInsuranceStepsStatuses(credentialingData?.providerCredentialingData) : []),
    [credentialingData],
  )
  const resumePath = useMemo(() => {
    const nextStep = getEarliestIncompleteStep(stepStatuses.stepStatuses)

    if (!nextStep) {
      return routeService.backgroundCheckRoute
    }

    // this is hacky but we need to check to see if the earliest incomplete step is the first step
    // and they haven't completed anything on it, (i.e. they signed up using "explore first" and
    // are now going back to complete intake we should send them to the welcome screen
    if (nextStep === 'professional' && stepStatuses.stepStatuses[0].completed === 0) {
      return routeService.signupIntake('welcome')
    }

    return getPath(nextStep).pathname
  }, [stepStatuses])

  const allRequirementsMet = !providerDataSummaryItems.length
    ? false
    : providerDataSummaryUtils.allRequirementsMet(providerDataSummaryItems)

  const [saveIntakeMutation, { loading: saveIntakeLoading }] = useSaveProviderIntakeMutation()

  const saveIntake = useCallback(
    async (intakeValues: SaveProviderIntakeInput | null | undefined) => {
      if (isNil(intakeValues) || (intakeValues && Object.keys(intakeValues).length === 0)) {
        return true
      }
      try {
        await saveIntakeMutation({ variables: { id: provider?.id ?? '', ...intakeValues } })
        if (intakeValues.intakeCompleted) {
          // If the user is completing intake, refetch provider status so that the app
          // will allow the user to navigate to other parts of harmony since the status
          // should no longer be ONBOARDING
          await refetchStatus()
        } else {
          // refetch status on every save but let it happen in the background if it's not
          // the final save
          refetchStatus()
        }
        if (currentFormStepConfig.afterSave) {
          const updatedResponse = await refetchProviderIntake()
          if (updatedResponse.data) {
            currentFormStepConfig.afterSave(updatedResponse.data.providerIntake)
          }
        }
        return true
      } catch (e) {
        toast.urgent(errorService.transformGraphQlError(e))
        return false
      }
    },
    [saveIntakeMutation, provider, currentFormStepConfig, refetchProviderIntake, refetchStatus],
  )

  const buildValuesAndSave = useCallback(
    async (formValues: ProviderSignupFormValues | null | undefined) => {
      return formValues && currentFormStepConfig.buildSaveValues
        ? await saveIntake(currentFormStepConfig.buildSaveValues(formValues))
        : true
    },
    [currentFormStepConfig, saveIntake],
  )

  const saveAndNavigate = useCallback(
    async (
      formValues: ProviderSignupFormValues | null | undefined,
      path: ProviderSignupNavigationDirection | string,
    ) => {
      const saved = await buildValuesAndSave(formValues)

      if (!saved) {
        return false
      }

      if (path === ProviderSignupNavigationDirection.Forward) {
        navigate(currentStep === 'finish' ? routeService.backgroundCheckRoute : getNextIntakePath(currentStep))
      } else if (path === ProviderSignupNavigationDirection.Back) {
        navigate(getPrevIntakePath(currentStep))
      } else {
        navigate(path)
      }
      return true
    },
    [navigate, currentStep, buildValuesAndSave],
  )

  useEffect(() => {
    const newStep = getStepFromPath(location.pathname)
    if (newStep !== currentStep) {
      setCurrentStep(newStep)
    }
  }, [location.pathname, currentStep])

  const value: ProviderSignupIntakeContext = {
    saveIntake,
    buildValuesAndSave,
    saveAndNavigate,
    saveIntakeLoading,
    data: intakeData?.providerIntake,
    queryLoading,
    currentStep,
    currentFormStepConfig,
    setCurrentFormStepConfig,
    stepStatuses: stepStatuses.stepStatuses,
    insuranceStepsStatuses,
    backgroundCheckStatuses: stepStatuses.backgroundCheckStatuses,
    cancelPolicyStatus: stepStatuses.cancelPolicyStatus,
    allRequirementsMet,
    resumePath,
    guide,
  }

  return <ProviderSignupIntakeContext.Provider value={value}>{children}</ProviderSignupIntakeContext.Provider>
}

function buildDefault(): ProviderSignupIntakeContext {
  return {
    saveIntake: () => new Promise(() => true),
    buildValuesAndSave: () => new Promise(() => true),
    saveAndNavigate: () => new Promise(() => true),
    saveIntakeLoading: false,
    queryLoading: true,
    currentStep: 'welcome-support',
    currentFormStepConfig: buildDefaultFormStepConfig(),
    setCurrentFormStepConfig: noop,
    stepStatuses: [],
    insuranceStepsStatuses: [],
    backgroundCheckStatuses: undefined,
    cancelPolicyStatus: undefined,
    allRequirementsMet: false,
    resumePath: routeService.signupIntake(),
    guide: { email: '', scheduleUrl: '' },
  }
}

export function buildDefaultFormStepConfig(): ProviderSignupFormStepConfig {
  return {
    validationSchema: null,
    buildSaveValues: null,
    afterSave: null,
  }
}

export function useProviderSignupIntakeContext() {
  return useContext(ProviderSignupIntakeContext)
}

export function specialtyToSpecialtyInput(specialty: BasicSpecialtyFragment): SpecialtyInput {
  return { id: specialty.id }
}
