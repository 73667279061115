import { noop } from 'lodash'

import { specialtiesService } from '@nuna/core'

import { SpecialtiesSectionBase, SpecialtiesSectionProps } from './SpecialtiesSectionBase'

export function TypesOfTherapySection({ specialties, id, refetch = noop }: SpecialtiesSectionProps) {
  return (
    <SpecialtiesSectionBase
      headingText="Type of Therapy or Intervention"
      description=""
      selectedSpecialties={specialties}
      specialtiesFilter={specialtiesService.getTypesOfTherapy}
      refetch={refetch}
      providerId={id}
    />
  )
}
