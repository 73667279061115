import { IconProps } from './IconProps'

/** @deprecated Wrong size. use IconShield */
export function IconShieldDeprecated({ size = 24, color = 'currentColor', ...props }: IconProps) {
  return (
    <svg width={size} height={size} viewBox="0 0 22 21" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M11 1.875L3.875 5.01136V9.71591C3.875 14.0676 6.915 18.137 11 19.125C15.085 18.137 18.125 14.0676 18.125 9.71591V5.01136L11 1.875ZM11 4.33929L16.4286 6.57955C16.4286 6.57955 16.535 9.63025 15.9762 11.8442C14.619 16.4367 11 16.7727 11 16.7727V4.33929Z"
        fill={color}
      />
    </svg>
  )
}
