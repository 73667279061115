import { styled } from '@mui/material'

import { greySet, interactiveText, sansSerifFontStack, shadowDepth } from '@nuna/tunic'

interface SidebarCardProps {
  padded?: boolean
}
export const SidebarCard = styled('div')<SidebarCardProps>`
  border-radius: 6px;
  background-color: white;
  box-shadow: ${shadowDepth(1)};
  color: ${greySet[70].hex};
  position: relative;
  opacity: 1;

  ${props => (props.padded === true || props.padded === undefined ? 'padding: 1rem;' : '')}

  section {
    &:not(:last-child) {
      border-bottom: 1px solid ${greySet[15].hex};
    }
    padding: 1rem;
  }
`

export const SideBarCardHeader = styled('div')`
  font-size: 16px;
  font-weight: 600;
  line-height: 18px;
`

type HeaderLinkProps = {
  openInNewTab?: boolean
  to?: string
}
export const HeaderLink = styled('a')<HeaderLinkProps>`
  font-family: ${sansSerifFontStack};
  font-weight: 700;
  font-size: 16px;
  border-left: 1px solid ${greySet[30].hex};
  color: ${interactiveText};
  margin-left: 0.5rem;
  padding-left: 0.5rem;
`
