import { styled } from '@mui/material'
import { noop } from 'lodash'
import { HTMLAttributes } from 'react'
import { useNavigate } from 'react-router-dom'

import {
  ContextualAlert,
  FillButton,
  FillButtonProps,
  IconButton,
  IconChevronThick,
  borderGrey,
  csx,
} from '@nuna/tunic'

interface FormProps {
  submitCount: number
  isValid: boolean
}
interface Props extends HTMLAttributes<HTMLDivElement> {
  nextButtonText?: string
  loading?: boolean
  nextButtonDiabled?: boolean
  onNextButtonClick?: () => void
  onBackButtonClick?: () => void
  nextButtonProps?: FillButtonProps
  formProps?: FormProps
}

export function DrawerStepper({
  nextButtonText = 'Continue',
  loading = false,
  nextButtonDiabled = false,
  onNextButtonClick = noop,
  onBackButtonClick,
  nextButtonProps = {},
  className,
  formProps = { submitCount: 0, isValid: true },
  ...props
}: Props) {
  const navigate = useNavigate()
  return (
    <>
      <div className={csx(['v-align', className])} {...props}>
        <IconButton type="button" onClick={onBackButtonClick || (() => navigate(-1))} tooltip="Previous">
          <IconChevronThick size={16} style={{ marginRight: '0.25rem' }} />
        </IconButton>

        <Separator />

        <FillButton {...nextButtonProps} onClick={onNextButtonClick} isLoading={loading} disabled={nextButtonDiabled}>
          {nextButtonText}
        </FillButton>
      </div>
      {formProps.submitCount > 0 && !formProps.isValid && (
        <ContextualAlert className="mt-1" style={{ marginLeft: 80 }} intent="urgent">
          Some information is missing above
        </ContextualAlert>
      )}
    </>
  )
}

const Separator = styled('span')`
  background-color: ${borderGrey};
  display: inline-block;
  height: 22px;
  margin-left: 0.5rem;
  margin-right: 1.5rem;
  width: 1px;
`
