import { styled } from '@mui/material'

import { liabilityInsuranceInformation } from '@nuna/core'
import { CopyButton, greySet } from '@nuna/tunic'

const {
  policyNumber,
  coverageAmountAggregate,
  coverageAmountPerOccurrence,
  currentEffectiveDate,
  currentExpirationDate,
  unlimitedCoverage,
  carrierName,
  individualCoverage,
  selfInsured,
} = liabilityInsuranceInformation
export function CAQHLiabilityInsuranceFields() {
  return (
    <LiabilityInsuranceContainer className="p-4 mx-4 mb-4">
      <h6 className="mb-3">Tava Liability Insurance</h6>

      <LiabilityInsuranceInfo>
        <div>Policy Number: </div>
        <CopyButton className="body" value={policyNumber} />
      </LiabilityInsuranceInfo>
      <LiabilityInsuranceInfo>
        <div>Current Effective Date: </div>
        <CopyButton className="body" value={currentEffectiveDate} />
      </LiabilityInsuranceInfo>
      <LiabilityInsuranceInfo>
        <div>Current Expiration Date: </div>
        <CopyButton className="body" value={currentExpirationDate} />
      </LiabilityInsuranceInfo>
      <LiabilityInsuranceInfo>
        <div>Carrier/Self Insured Name: </div>
        <CopyButton className="body" value={carrierName} />
      </LiabilityInsuranceInfo>
      <LiabilityInsuranceInfo>
        <div>Do you have unlimited coverage with this insurance carrier?</div>
        <span>{unlimitedCoverage}</span>
      </LiabilityInsuranceInfo>
      <LiabilityInsuranceInfo>
        <div>Amount of coverage per occurrence: </div>
        <span className="body">{coverageAmountPerOccurrence}</span>
      </LiabilityInsuranceInfo>
      <LiabilityInsuranceInfo>
        <div>Amount of coverage aggregate:</div>
        <span className="body"> {coverageAmountAggregate}</span>
      </LiabilityInsuranceInfo>
      <LiabilityInsuranceInfo>
        <div>Individual Coverage:</div> <span className="body">{individualCoverage}</span>
      </LiabilityInsuranceInfo>
      <LiabilityInsuranceInfo>
        <div>Self Insured:</div>
        <span className="body"> {selfInsured}</span>
      </LiabilityInsuranceInfo>
    </LiabilityInsuranceContainer>
  )
}

const LiabilityInsuranceContainer = styled('div')`
  background: white;
  border: 1px solid ${greySet[15].hex};
  border-radius: 12px;
`

const LiabilityInsuranceInfo = styled('div')`
  margin-top: 8px;
  line-height: 24px;
  display: flex;
  justify-content: space-between;
  div {
    width: 45%;
  }
`
