import { noop } from 'lodash'
import { FormEvent, useState } from 'react'

import { BenefitsGuideContractFragment } from '@nuna/api'
import { FileUpload, GhostButton, toast } from '@nuna/tunic'

import { useBenefitsGuideUpload } from './useBenefitsGuideUpload'

interface BenefitsGuideUploadProps {
  contract: BenefitsGuideContractFragment
  onSuccess?: () => void
  onCancel?: () => void
}

export function BenefitsGuideUpload({ contract, onSuccess = noop, onCancel }: BenefitsGuideUploadProps) {
  const { uploadBenefitsGuide, loading } = useBenefitsGuideUpload()

  const [file, setFile] = useState<File>()

  const handleSubmit = async (e: FormEvent) => {
    e.preventDefault()

    if (!file) return

    try {
      await uploadBenefitsGuide(file, contract.id)
      toast.success('Document uploaded successfully')
      onSuccess()
    } catch (e) {
      toast.urgent('Error uploading document')
    }
  }

  return (
    <form className="v-align" onSubmit={handleSubmit}>
      <FileUpload onDrop={files => setFile(files[0])} accept=".pdf" />

      {onCancel && (
        <GhostButton onClick={onCancel} className="ml-2" type="button">
          Cancel
        </GhostButton>
      )}

      <GhostButton className="ml-2" disabled={!file} type="submit" isLoading={loading}>
        Upload
      </GhostButton>
    </form>
  )
}
