import { styled } from '@mui/material'
import { FocusEvent, InputHTMLAttributes, ReactNode, useRef, useState } from 'react'

import { IconChevronThick } from '../../icons'
import { tealSet } from '../../styles/colorSets'
import { body1, body2, borderGrey, error } from '../../styles/colors'
import { fontSize } from '../../styles/global/typography.utils'
import { shadowDepth } from '../../styles/shadows'
import { triggerChangeEvent } from '../../utils/event'
import { HelperText } from '../HelperText'

export interface NumberFieldProps extends InputHTMLAttributes<HTMLInputElement> {
  min?: number
  max?: number
  step?: number
  helperText?: ReactNode
  error?: boolean
}

export function NumberField({
  value,
  onChange,
  onBlur,
  onFocus,
  className = '',
  style = {},
  min,
  max,
  step = 1,
  helperText,
  error = false,
  ...props
}: NumberFieldProps) {
  const inputRef = useRef<HTMLInputElement>(null)
  const [isFocused, setIsFocused] = useState(false)

  const makeInRange = (newValue: number) => {
    if (max) {
      newValue = Math.min(newValue, max)
    }

    if (min) {
      newValue = Math.max(newValue, min)
    }

    return newValue
  }

  const handleStepClick = (direction: 'up' | 'down') => {
    let numericValue = Number(value)

    if (isNaN(numericValue)) {
      numericValue = 0
    }

    const newValue = direction === 'up' ? numericValue + step : numericValue - step

    if (inputRef.current) {
      triggerChangeEvent(inputRef.current, makeInRange(newValue).toString())
    }

    inputRef.current?.focus()
  }

  const handleBlur = (e: FocusEvent<HTMLInputElement>) => {
    setIsFocused(false)
    onBlur && onBlur(e)

    if (e.target.value === '') return // Number('') === 0 so we want to bail early if it's an empty string so the input can be cleared
    const numericValue = Number(e.target.value)
    if (isNaN(numericValue)) return
    const inRangeValue = makeInRange(numericValue)
    if (inRangeValue === numericValue) return
    if (!inputRef.current) return

    triggerChangeEvent(inputRef.current, inRangeValue.toString())
  }

  return (
    <div className={className} style={style}>
      <Container error={error} isFocused={isFocused}>
        <input
          type="number"
          inputMode="numeric"
          ref={inputRef}
          value={value}
          onChange={onChange}
          onBlur={handleBlur}
          onFocus={e => {
            setIsFocused(true)
            onFocus && onFocus(e)
          }}
          min={min}
          max={max}
          step={step}
          {...props}
        />

        <button type="button" tabIndex={-1} onClick={() => handleStepClick('up')}>
          <IconChevronThick size={14} direction="up" />
        </button>

        <button type="button" tabIndex={-1} onClick={() => handleStepClick('down')}>
          <IconChevronThick size={14} direction="down" />
        </button>
      </Container>
      {helperText && <HelperText error={error}>{helperText}</HelperText>}
    </div>
  )
}

const Container = styled('div')<{ isFocused: boolean; error: boolean }>`
  background-color: #fff;
  border: 2px solid ${props => (props.error ? error : props.isFocused ? tealSet[50].hex : borderGrey)};
  border-radius: var(--border-radius-sm);
  box-shadow: ${shadowDepth(1)};
  display: inline-flex;
  padding: 0.75rem;
  position: relative;
  width: 97px;

  input {
    appearance: none;
    border: 0;
    border-radius: 0;
    color: ${body1};
    font-size: ${fontSize.large};
    font-weight: 500;
    height: 100%;
    padding: 0;
    width: 100%;
    line-height: 1;

    &:focus {
      outline: 0;
    }

    -moz-appearance: textfield;
    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
      -webkit-appearance: none;
    }
  }

  button {
    color: ${body2};
    position: absolute;
    right: 0.75rem;

    &:hover {
      color: ${tealSet[50].hover};
    }

    &:first-of-type {
      top: 0.5rem;
    }

    &:last-of-type {
      bottom: 0.5rem;
    }
  }
`
