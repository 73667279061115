import * as Yup from 'yup'
import { styled } from '@mui/material'
import moment from 'moment-timezone'

import {
  AccountSource,
  AccountSourceChannel,
  PaymentPreference,
  PreconfigurePatientAccountMutationVariables,
  PreconfiguredAppointmentInput,
  PublicAvailabilitySlot,
} from '@nuna/api'
import { formService } from '@nuna/core'
import {
  AccessCodeCoverageFormValues,
  EmployerCoverageFormValues,
  InsuranceCoverageFormValues,
  accessCodeSchema,
  buildEmployerCoverageInput,
  buildPendingPatientInsuranceCoverageInput,
  employerCoverageSchema,
  insuranceCoverageSchema,
} from '@nuna/coverage'
import { phoneUSAValidationRegex, tealSet } from '@nuna/tunic'

const { oopsRequired } = formService

export const FormContainer = styled('form')`
  height: 100%;
  overflow-y: scroll;
  margin-bottom: 72px;
`

export const FormActions = styled('div')`
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 72px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  background-color: white;
`

export const Section = styled('section')`
  padding: 16px 24px;
  display: flex;
  flex-flow: column;
`

export const SectionTitle = styled('span')`
  font-size: 16px;
  font-weight: 800;
  color: ${tealSet[90].hex};
  margin-bottom: 16px;
`

export interface ClientInviteFormValues {
  firstName: string
  lastName: string
  dob: string
  email: string
  phone: string
  timezone: string
  state: string
  appointment: PreconfiguredAppointmentInput | null
  paymentPreference: PaymentPreference | null
  insuranceValues: InsuranceCoverageFormValues | null
  employerValues: EmployerCoverageFormValues | null
  accessCodeValues: AccessCodeCoverageFormValues | null
  source: AccountSource
  sourceChannel: AccountSourceChannel
}

export const clientInviteFormInitialValues: ClientInviteFormValues = {
  firstName: '',
  lastName: '',
  dob: '',
  email: '',
  phone: '',
  timezone: 'UTC',
  state: '',
  appointment: null,
  paymentPreference: null,
  insuranceValues: null,
  employerValues: null,
  accessCodeValues: null,
  source: AccountSource.ProviderForm,
  sourceChannel: AccountSourceChannel.Provider,
}

export const clientInviteFormSchema = Yup.object().shape({
  firstName: Yup.string().required('First name is required'),
  lastName: Yup.string().required('Last name is required'),
  dob: Yup.date()
    .required('Date of birth is required')
    .min(moment().subtract('125', 'years').toDate(), 'Date of birth is out of range')
    .max(
      moment().subtract('18', 'years').toDate(),
      'Ages 13-18 must use the signup page in order to get parent/guardian consent. Therapy is not available to anyone under 13.',
    )
    .typeError('Date is invalid'),
  email: Yup.string().required('Email is required').email("Oops, that email isn't formatted correctly"),
  state: Yup.string().required(oopsRequired('state')),
  phone: Yup.string().matches(phoneUSAValidationRegex, 'Please enter a valid US phone number'),
  appointment: Yup.object<PublicAvailabilitySlot>().nullable(),
  insuranceValues: insuranceCoverageSchema.nullable().when('paymentPreference', {
    is: PaymentPreference.Insurance,
    then: insuranceCoverageSchema.required(oopsRequired('insurance')),
  }),
  employerValues: employerCoverageSchema.nullable().when('paymentPreference', {
    is: PaymentPreference.Employer,
    then: employerCoverageSchema.required(oopsRequired('employer')),
  }),
  accessCodeValues: accessCodeSchema.nullable().when('paymentPreference', {
    is: PaymentPreference.Accesscode,
    then: accessCodeSchema.required(oopsRequired('access code')),
  }),
  source: Yup.string<AccountSource>().required(oopsRequired('source')),
  sourceChannel: Yup.string<AccountSourceChannel>(),
})

export const clientInviteInsuranceSchema = Yup.object().shape({
  insuranceValues: insuranceCoverageSchema.required(oopsRequired('insurance')),
})

export function buildSaveValues(
  values: ClientInviteFormValues,
  tokenId: string,
  appointmentId: string,
  providerId: string,
): PreconfigurePatientAccountMutationVariables {
  const mutationValues: PreconfigurePatientAccountMutationVariables = {
    source: values.source,
    sourceChannel: values.sourceChannel,
    tokenId,
    accountInfo: tokenId
      ? undefined
      : {
          firstName: values.firstName,
          lastName: values.lastName,
          dob: moment(values.dob, 'MM/DD/YYYY').format('YYYY/MM/DD'),
          email: values.email,
          state: values.state,
          mobilePhone: values.phone,
          timezone: values.timezone,
          providerId: providerId,
        },
  }

  if (values.appointment?.startDatetime && !appointmentId) {
    if (!providerId) throw new Error('Must include a provider to schedule an appointment')

    mutationValues.appointmentInfo = {
      providerId: providerId,
      startDatetime: values.appointment.startDatetime,
      therapyTypeSpecialtyId: values.appointment.therapyTypeSpecialtyId,
    }
  }

  if (values.paymentPreference) {
    mutationValues.paymentInfo = {
      paymentPreference: values.paymentPreference,
    }

    if (values.paymentPreference === PaymentPreference.Employer) {
      if (!values.employerValues?.employer) throw new Error('Must include an employer to add coverage')

      mutationValues.paymentInfo.employer = buildEmployerCoverageInput(values)
    }

    if (values.paymentPreference === PaymentPreference.Accesscode) {
      if (!values.accessCodeValues) throw new Error('Must include a coupon code to add coverage')

      mutationValues.paymentInfo.coupon = {
        code: values.accessCodeValues.accessCode,
      }
    }

    if (values.paymentPreference === PaymentPreference.Insurance) {
      if (!values.insuranceValues?.insurancePayer) throw new Error('Must include insurance details to add coverage')

      mutationValues.paymentInfo.insurance = buildPendingPatientInsuranceCoverageInput(values, {
        dob: values.dob,
        firstName: values.firstName,
        lastName: values.lastName,
      })
    }
  }

  return mutationValues
}
