import { startCase } from 'lodash'
import { ReactNode } from 'react'

import { paymentMethodService } from '@nuna/core'
import { Card, CardProps, IconCreditCard, csx, makeTypographyComponent } from '@nuna/tunic'

interface Props extends CardProps {
  number: string | number
  name?: string | null
  rightSlot?: ReactNode
}

export function CreditCardCard({ className, name, number, rightSlot, ...props }: Props) {
  return (
    <Card className={csx([className, 'v-align p-2'])} {...props}>
      <IconCreditCard size={32} className="mr-2" />
      <div>
        <span className="text-bold">{startCase(name ?? undefined)}</span>
        <br />
        <CreditCardNumber>{paymentMethodService.maskCardNumber(number)}</CreditCardNumber>
      </div>

      {rightSlot && <div className="ml-auto">{rightSlot}</div>}
    </Card>
  )
}

const CreditCardNumber = makeTypographyComponent('text-medium', 'span')
