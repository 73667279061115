import { styled } from '@mui/material'
import { Autocomplete, TextField } from '@mui/material'
import { debounce } from 'lodash'
import { useEffect, useMemo, useState } from 'react'

import { ProviderListItemFragment, ProviderNetworkStatus, useSearchProvidersLazyQuery } from '@nuna/api'
import { AuthorizedAvatar } from '@nuna/common'
import { makeTypographyComponent } from '@nuna/tunic'

interface ProviderSelectForClientProps {
  state: string | undefined | null
  insurancePayerId?: string | null
  onSelect: (provider: ProviderListItemFragment | null) => void
  selectedProvider: ProviderListItemFragment | null
  disabled?: boolean
}

export function ProviderSelectForClient({
  state = '',
  onSelect,
  selectedProvider,
  insurancePayerId,
  disabled = false,
}: ProviderSelectForClientProps) {
  const [searchInput, setSearchInput] = useState('')
  const [debounceLoading, setDebounceLoading] = useState(false)

  const [searchProviders, { data, loading }] = useSearchProvidersLazyQuery({
    onCompleted: () => setDebounceLoading(false),
  })

  const debouncedSearchProviders = useMemo(
    () =>
      debounce(
        (term: string, state: string) =>
          searchProviders({
            variables: {
              pagination: { limit: 100 },
              filters: {
                credentialedInStates: [state],
                active: true,
                networkStatusIn: [ProviderNetworkStatus.Approved],
                insurancePayerId,
                search: { term },
              },
            },
          }),
        400,
      ),
    [searchProviders, insurancePayerId],
  )

  useEffect(() => {
    setDebounceLoading(false)
  }, [data])

  useEffect(() => {
    if (state && searchInput) {
      setDebounceLoading(true)
      debouncedSearchProviders(searchInput, state)
    }
  }, [state, searchInput, debouncedSearchProviders])

  const providers = data?.searchProviders.items ?? []

  return (
    <Autocomplete
      disabled={disabled}
      options={providers}
      value={selectedProvider}
      onChange={(_event, provider) => {
        onSelect(provider)
      }}
      loading={loading || debounceLoading}
      inputValue={searchInput}
      onInputChange={(_e, value) => setSearchInput(value)}
      renderInput={params => <TextField label="Provider" {...params} />}
      noOptionsText={!searchInput ? 'Begin typing to see providers' : 'No options'}
      renderOption={(renderOptionProps, provider) => {
        return (
          <li {...renderOptionProps}>
            <div className="v-align">
              <AuthorizedAvatar className="mr-1" size="xs" url={provider.avatarUrl} />
              <Name>
                {provider.firstName} {provider.lastName}
              </Name>
            </div>
          </li>
        )
      }}
      getOptionLabel={provider => `${provider.firstName} ${provider.lastName}`}
      isOptionEqualToValue={(option, value) => option.id === value.id}
    />
  )
}

const Name = styled(makeTypographyComponent('text-medium text-secondary', 'span'))``
