import * as Yup from 'yup'
import { styled } from '@mui/material'
import { Form, Formik } from 'formik'

import { useSaveCaqhCredentialsMutation } from '@nuna/api'
import { errorService, formService } from '@nuna/core'
import { Card, FillButton, OutlineButton, TextField, toast } from '@nuna/tunic'

const { composeHelperTextWithError } = formService

export interface CAQHFormValues {
  providerId: string
  caqhUsername?: string | null
  caqhPassword?: string | null
  caqhId?: string | null
  id?: string | null
}

interface Props {
  initialFormValues: CAQHFormValues
  afterSave: (result: CAQHFormValues) => void
  onCancel: () => void
}

const schema = Yup.object().shape<CAQHFormValues>({
  providerId: Yup.string().required(),
  caqhUsername: Yup.string().nullable(),
  caqhPassword: Yup.mixed().when('caqhUsername', {
    is: value => !!value,
    then: Yup.string().required('Password is required'),
    otherwise: Yup.string().nullable(),
  }),
  caqhId: Yup.string().nullable(),
})

export function CAQHCredentialsForm({ initialFormValues, afterSave, onCancel }: Props) {
  const [saveCredentials] = useSaveCaqhCredentialsMutation()

  const handleSubmit = async (values: CAQHFormValues) => {
    try {
      const result = await saveCredentials({ variables: { data: values } })
      const savedCredentials = result.data?.saveProviderCaqh
      if (savedCredentials) {
        toast.success('CAQH credentials saved')
        afterSave({
          caqhUsername: savedCredentials.caqhUsername,
          caqhPassword: savedCredentials.caqhPassword,
          caqhId: savedCredentials.caqhId,
          id: savedCredentials.id,
          providerId: savedCredentials.providerId,
        })
      }
    } catch (e) {
      toast.urgent(errorService.transformGraphQlError(e, 'There was an error saving CAQH credentials'))
    }
  }

  return (
    <Formik initialValues={initialFormValues} validationSchema={schema} onSubmit={handleSubmit}>
      {({ getFieldProps, errors, touched }) => (
        <StyledForm>
          <Card className="p-2" depth={-1}>
            <TextField
              label="CAQH ID"
              {...getFieldProps('caqhId')}
              {...composeHelperTextWithError('', errors.caqhId, touched.caqhId)}
            />
            <TextField
              label="Username"
              className="mt-1"
              {...getFieldProps('caqhUsername')}
              {...composeHelperTextWithError('', errors.caqhUsername, touched.caqhUsername)}
            />
            <TextField
              label="Password"
              type="password"
              className="mt-1"
              {...getFieldProps('caqhPassword')}
              {...composeHelperTextWithError('', errors.caqhPassword, touched.caqhPassword)}
            />
            <div className="v-align mt-3">
              <span className="ml-auto">
                {initialFormValues.caqhId && <OutlineButton onClick={onCancel}>Cancel</OutlineButton>}
                <FillButton type="submit" className="ml-1">
                  Save
                </FillButton>
              </span>
            </div>
          </Card>
        </StyledForm>
      )}
    </Formik>
  )
}

const StyledForm = styled(Form)`
  max-width: 440px;
`
