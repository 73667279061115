import { every } from 'lodash'
import { useCallback, useRef } from 'react'

import {
  AppointmentChangeReason,
  AppointmentCurrentStatus,
  useCancelAppointmentAsAdminMutation,
  useCancelAppointmentAsPatientMutation,
} from '@nuna/api'
import { useIsAdmin } from '@nuna/auth'
import { appointmentService } from '@nuna/core'

import { WrappedResponse } from '../../../types'
import { SimpleUpcomingAppointment } from './useCheckUpcomingAppointmentsForCoverage'

export function useCancelAppointments() {
  const [cancelAppointmentAsPatientMutation, patientMutationResult] = useCancelAppointmentAsPatientMutation()
  const [cancelAppointmentAsAdminMutation, adminMutationResult] = useCancelAppointmentAsAdminMutation()

  const canceledAppointmentsCache = useRef<string[]>([])

  const isAdmin = useIsAdmin()

  const cancelAppointments = useCallback(
    async (appointmentsToBeCancelled: SimpleUpcomingAppointment[]): Promise<WrappedResponse<null>> => {
      const appointmentStatuses = await Promise.all(
        appointmentsToBeCancelled.map(appointment => {
          if (canceledAppointmentsCache.current.includes(appointment.id)) {
            return Promise.resolve({ appointmentId: appointment.id, isCanceled: true })
          }

          if (isAdmin) {
            return cancelAppointmentAsAdminMutation({
              variables: {
                appointmentId: appointment.id,
                reason: AppointmentChangeReason.FreeForm,
                reasonData: 'Provider did not accept insurance',
                shouldProviderBePaid: false,
                appointmentCancelStatus: AppointmentCurrentStatus.CanceledByPatient,
              },
            })
              .then(result => {
                if (result.data && appointmentService.isAppointmentCanceled(result.data.cancelAppointmentAsAdmin)) {
                  canceledAppointmentsCache.current.push(appointment.id)
                  return { appointmentId: appointment.id, isCanceled: true }
                }

                return { appointmentId: appointment.id, isCanceled: false }
              })
              .catch(() => {
                return { appointmentId: appointment.id, isCanceled: false }
              })
          }

          return cancelAppointmentAsPatientMutation({
            variables: {
              id: appointment.id,
              reason: AppointmentChangeReason.FreeForm,
              reasonData: 'Provider did not accept insurance',
            },
          })
            .then(result => {
              if (result.data && appointmentService.isAppointmentCanceled(result.data.cancelAppointmentAsPatient)) {
                canceledAppointmentsCache.current.push(appointment.id)
                return { appointmentId: appointment.id, isCanceled: true }
              }

              return { appointmentId: appointment.id, isCanceled: false }
            })
            .catch(() => {
              return { appointmentId: appointment.id, isCanceled: false }
            })
        }),
      )

      if (every(appointmentStatuses.map(status => status.isCanceled === true))) {
        return {
          didSucceed: true,
          error: null,
          data: null,
        }
      }

      return {
        didSucceed: false,
        error: new Error('Could not cancel all appointments'),
        data: null,
      }
    },
    [isAdmin, cancelAppointmentAsPatientMutation, cancelAppointmentAsAdminMutation],
  )

  return [cancelAppointments, isAdmin ? adminMutationResult : patientMutationResult] as const
}
