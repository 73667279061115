import { Dialog, DialogActions, DialogContent, DialogTitle, Divider } from '@mui/material'
import { styled } from '@mui/system'

import { Provider } from '@nuna/api'
import { FillButton } from '@nuna/tunic'

import { CredentialingDocumentsDialogContent } from './CredentialingDocumentsDialogContent'
import { CredentialingPacketBuilderDialogContent } from './CredentialingPacketBuilderDialogContent'

type DialogTypes = 'documents' | 'packetBuilder'

type DialogContentItem = {
  title: string | React.ReactNode
  content: string | React.ReactNode
}
type DialogContent = Record<string, DialogContentItem>

type Props = {
  provider?: Provider
  open: boolean
  type: DialogTypes
  handleClose: () => void
}

export function CredentialingDialog({ provider, open, handleClose, type }: Props) {
  const dialogContent: DialogContent = {
    documents: {
      title: 'Credentialing Documents',
      content: <CredentialingDocumentsDialogContent provider={provider} />,
    },
    packetBuilder: {
      title: 'Credentialing Packet Builder',
      content: <CredentialingPacketBuilderDialogContent provider={provider} />,
    },
  }

  const content: DialogContentItem = dialogContent[type]

  return (
    <Dialog open={open} onClose={handleClose} fullWidth={true} maxWidth="xl">
      <Title>{content.title}</Title>
      <Divider />
      <Content id="credential-checklist-alert-dialog-description">{content.content}</Content>
      <Actions>
        <FillButton onClick={handleClose} autoFocus className="ml-auto">
          Close
        </FillButton>
      </Actions>
    </Dialog>
  )
}

const Title = styled(DialogTitle)`
  font-size: 1.5rem;
  font-weight: 400;
  padding: var(--spacing-2) var(--spacing-2) var(--spacing-1);
  margin: 0;
`

const Content = styled(DialogContent)`
  padding: var(--spacing-1) var(--spacing-2);
  height: 600px;
`

const Actions = styled(DialogActions)`
  padding: var(--spacing-2);
`
