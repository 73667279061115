/* eslint-disable @typescript-eslint/no-explicit-any */
import * as Yup from 'yup'
import { Formik } from 'formik'
import moment from 'moment'

import {
  AppointmentChangeReason,
  AppointmentCurrentStatus,
  AppointmentScheduleFragment,
  useCancelAppointmentAsAdminMutation,
} from '@nuna/api'
import { appointmentService, errorService } from '@nuna/core'
import { Checkbox, FillButton, Select, TextField, toast } from '@nuna/tunic'

const { appointmentChangeReasons } = appointmentService

const cancellationSchema = Yup.object().shape({
  reason: Yup.string().required(),
  appointmentCancelStatus: Yup.string().required(),
  reasonData: Yup.string().required(),
  shouldProviderBePaid: Yup.boolean(),
})

const whoCanceledReasons = [
  { value: AppointmentCurrentStatus.CanceledByPatient, display: 'Patient' },
  { value: AppointmentCurrentStatus.CanceledByProvider, display: 'Provider' },
]

export const CancelAsAdminForm = ({
  appointment,
  onComplete,
}: {
  appointment: Pick<AppointmentScheduleFragment, 'id' | 'startDatetime'>
  onComplete: () => void
}) => {
  const [cancelAppointmentAsAdmin, { loading, error }] = useCancelAppointmentAsAdminMutation()
  const appointmentId = appointment.id
  const isApptInTheFuture = moment(appointment.startDatetime).isAfter(moment())

  const handleSubmit = async (values: any, { resetForm }: any) => {
    try {
      await cancelAppointmentAsAdmin({
        variables: {
          appointmentId,
          reason: values.reason,
          reasonData: values.reasonData,
          shouldProviderBePaid: values.shouldProviderBePaid || false,
          appointmentCancelStatus: values.appointmentCancelStatus,
        },
        refetchQueries: ['InternalAppointmentDetail'],
      })

      resetForm()

      onComplete()
      toast.success('Marked appointment as canceled')
    } catch (e) {
      // Give them the graphql error if present.
      // could be something else other then, that which is undeseriable.
      // but graphql shrug
      console.error(e)
      toast.urgent('Failed to cancel appointment')
    }
  }

  return (
    <Formik
      validationSchema={cancellationSchema}
      initialValues={{
        reason: AppointmentChangeReason.SchedulingConflict,
        appointmentCancelStatus: AppointmentCurrentStatus.CanceledByPatient,
        reasonData: '',
        shouldProviderBePaid: false,
      }}
      onSubmit={handleSubmit}
    >
      {({ values, handleChange, handleSubmit, errors, touched }) => (
        <form onSubmit={handleSubmit}>
          <h1 className="h4 mb-2">Cancel Appointment</h1>

          <Select
            value={values.appointmentCancelStatus}
            name="appointmentCancelStatus"
            onChange={handleChange}
            id="user-requested"
            label="Requested by"
            className="mb-3"
          >
            {whoCanceledReasons.map(reason => (
              <option value={reason.value} key={`AdminCancelForm-User:${reason.value}`}>
                {reason.display}
              </option>
            ))}
          </Select>

          <Select
            className="mb-3"
            label="Reason"
            value={values.reason}
            name="reason"
            onChange={handleChange}
            id="cancellation-reason"
          >
            {appointmentChangeReasons
              .filter(reason => reason.requestableBy.includes(values.appointmentCancelStatus))
              .map(reason => (
                <option value={reason.value} key={`AdminCancelForm-Reason:${reason.value}`}>
                  {reason.display}
                </option>
              ))}
          </Select>

          <TextField
            id="cancellation-message"
            placeholder="Write a message to be shared with the provider or patient"
            rows={4}
            name="reasonData"
            className="mb-3"
            onChange={handleChange}
            value={values.reasonData}
            error={!!(touched.reasonData && errors.reasonData)}
            helperText={touched.reasonData && errors.reasonData}
            multiline
          />

          {!isApptInTheFuture && values.appointmentCancelStatus !== AppointmentCurrentStatus.CanceledByProvider && (
            <div>
              <Checkbox checked={values.shouldProviderBePaid} name="shouldProviderBePaid" onChange={handleChange}>
                Provider should be paid
              </Checkbox>
            </div>
          )}

          {!!error && <div>Error occurred saving {errorService.transformGraphQlError(error)}</div>}

          <FillButton isLoading={loading} type="submit" style={{ width: '100%' }} className="mt-2">
            Cancel Appointment
          </FillButton>
        </form>
      )}
    </Formik>
  )
}
