import { AppointmentChangeReason } from '@nuna/api'
import { Audience } from '@nuna/core'

interface Args {
  isLegacyCancelPolicy: boolean
  cancelReason: AppointmentChangeReason
  audience: Audience
  requestedBy: Exclude<Audience, 'admin'>
}
export function useShowCancelFeeInfo({ isLegacyCancelPolicy, cancelReason, audience, requestedBy }: Args) {
  const validReasons = [AppointmentChangeReason.RequestedByPatient]
  const isAdminOnBehalfOfClient = audience === 'admin' && requestedBy === 'client'
  const isProvider = audience === 'provider'

  if (isAdminOnBehalfOfClient) {
    return true
  }

  if (!isLegacyCancelPolicy && isProvider) {
    validReasons.push(AppointmentChangeReason.FreeForm)
  }

  return validReasons.includes(cancelReason)
}
