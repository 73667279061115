import { styled } from '@mui/material'

import { BasicPatientFragment } from '@nuna/api'
import { supportService } from '@nuna/telemetry'
import {
  ContextualAlert,
  GhostButtonExternalLink,
  IconDollarSymbol,
  IconError,
  IconExportDeprecated,
  IconMailDeprecated,
  OutlineButtonExternalLink,
  body1,
  salmonSet,
  yellowSet,
} from '@nuna/tunic'

interface ExhaustedCapMessageProps {
  patient: BasicPatientFragment
}

export function ExhaustedCapMessage({ patient }: ExhaustedCapMessageProps) {
  return (
    <>
      <ContextualAlert intent="urgent" className="mb-1" icon={<IconError size={14} className="flex-static" />}>
        <>
          {patient.firstName} has used all sessions covered by their employer, and will need to update their coverage to
          continue therapy.
        </>
      </ContextualAlert>

      <div style={{ padding: '1rem', display: 'flex', flexFlow: 'column', justifyContent: 'center' }}>
        <span>
          To continue care, instruct {patient.firstName} to follow these simple steps:
          <ol>
            <li className="mb-1">Schedule another session.</li>
            <li>During checkout, select a new coverage type.</li>
          </ol>
        </span>
        <GhostButtonExternalLink
          href={supportService.articles.coverageOptions}
          variant="secondary"
          className="mx-auto my-2"
        >
          Review coverage options <IconExportDeprecated className="ml-1" size={22} />
        </GhostButtonExternalLink>
        <OutlineButtonExternalLink href={`mailto:${patient.email}`} className="full-width mt-2">
          <IconMailDeprecated className="mr-1" />
          Email {patient.firstName}
        </OutlineButtonExternalLink>
      </div>
    </>
  )
}

interface LastSessionCapMessageProps {
  patient: BasicPatientFragment
  remainingSessions: number
}

export function LastSessionCapMessage({ patient, remainingSessions }: LastSessionCapMessageProps) {
  return (
    <CapAlert className="mb-2">
      <CapMessage>
        <IconDollarSymbol className="flex-static mr-1" size={14} />

        <p className="mb-0 italic">
          You can schedule {remainingSessions} more session with {patient.firstName}. After that, they’ll need to add a
          credit card to schedule more.
        </p>
      </CapMessage>
    </CapAlert>
  )
}

const CapMessage = styled('div')`
  display: flex;

  svg {
    margin-top: 0.125rem;
  }
`

const CapAlert = styled('div')<{ exhausted?: boolean }>`
  background-color: ${props => (props.exhausted ? salmonSet.tint[40] : yellowSet.tint[20])};
  border-radius: 6px;
  color: ${body1};
  padding: 1rem;
`
