import { useBaseProviderQuery } from '@nuna/api'
import { useAuthDataContext } from '@nuna/auth'
import { MenuDivider, MenuItem, NavMenu } from '@nuna/tunic'

import { insurance, licenses, payrollSummary, preferences, providerProfile } from '../../util/routes'

export function ProviderNavMenu() {
  const { login, onLogout } = useAuthDataContext()
  const { data } = useBaseProviderQuery({ variables: { id: login?.providerId || '' }, skip: !login?.providerId })
  const provider = data?.provider

  return (
    <NavMenu firstName={provider?.firstName} avatarUrl={provider?.avatarUrl}>
      <MenuItem to={providerProfile()}>My Profile</MenuItem>
      <MenuItem to={payrollSummary()}>Payouts</MenuItem>
      <MenuItem to={preferences()}>Preferences</MenuItem>
      <MenuItem to={licenses()}>Licenses</MenuItem>
      <MenuItem to={insurance()}>Insurance</MenuItem>
      <MenuDivider />
      <MenuItem button onClick={() => onLogout && onLogout()}>
        Sign Out
      </MenuItem>
    </NavMenu>
  )
}
