import { styled } from '@mui/material'
import mixpanel from 'mixpanel-browser'
import moment, { type Moment } from 'moment'
import { HTMLAttributes } from 'react'
import { useMatch } from 'react-router-dom'

import { DrawerAppointmentFragment, ProviderDetailsQuery, PublicProviderFragment } from '@nuna/api'
import { RescheduleTimeslots, TimeslotSkeleton, useProviderAvailableTimeSlots } from '@nuna/appointment'
import { useAppointmentDrawerSearchParams } from '@nuna/common'
import { routeService } from '@nuna/core'
import { borderGrey, greySet } from '@nuna/tunic'

import { AppointmentDrawerPaddedContainer } from '../../../AppointmentDrawer'

interface PeriodTimeSlotsProps extends HTMLAttributes<HTMLDivElement> {
  reschedulingAppointmentId?: string
  provider: PublicProviderFragment | ProviderDetailsQuery['provider'] | DrawerAppointmentFragment['provider']
  patient: DrawerAppointmentFragment['patient'] | undefined
  date?: Moment
  addressId?: string | null
}

export function CalendarPeriodTimeSlots({
  reschedulingAppointmentId,
  provider,
  patient,
  date,
  addressId,
  ...props
}: PeriodTimeSlotsProps) {
  const isPublicProviderPage = !!useMatch(routeService.publicProviderPattern)
  const { setTimeSlot } = useAppointmentDrawerSearchParams()

  const { slots, loading } = useProviderAvailableTimeSlots({
    date,
    addressId: (addressId === 'VIRTUAL' ? undefined : addressId) ?? undefined,
    providerId: provider.id,
    patientId: patient?.id,
    reschedulingAppointmentId,
  })

  return (
    <Container {...props}>
      <p className="body caption italic">Select a start time</p>

      {loading && <TimeslotSkeleton />}

      {!loading && slots.length === 0 && (
        <p className="text-secondary p-2">There are no available times for this day.</p>
      )}

      {slots.length > 0 && (
        <RescheduleTimeslots
          timeslots={slots}
          onSlotClick={slot => {
            if (isPublicProviderPage) {
              mixpanel.track('chose public provider timeslot', { providerId: provider.id, start: slot.start })
            }
            setTimeSlot(slot)
          }}
        />
      )}

      <Footer>
        <p className="mb-0 caption text-medium text-secondary">
          Appointments last 50m • Times shown in {moment.tz(moment.tz.guess()).format('z')}
          {!date && (
            <>
              <br />
              Showing availablity for the next 4 weeks
            </>
          )}
        </p>
      </Footer>
    </Container>
  )
}

const Container = styled(AppointmentDrawerPaddedContainer)`
  background-color: #fff;
  border-top: 1px solid ${greySet[15].hex};
  padding-top: var(--spacing-2);
  padding-bottom: var(--spacing-5);
`

const Footer = styled('div')`
  background-color: #fff;
  border-top: 1px solid ${borderGrey};
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  padding: 1rem;
  z-index: 2;
`
