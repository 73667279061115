import { styled } from '@mui/material'
import { motion } from 'framer-motion'
import { uniqueId } from 'lodash'
import { HTMLAttributes, ReactNode, useRef, useState } from 'react'

import { IconMinus, IconPlus, greySet, primary, tealSet } from '@nuna/tunic'

interface FAQItemProps extends HTMLAttributes<HTMLDivElement> {
  question: React.ReactNode
  answer: React.ReactNode
  id?: string
  fullWidth?: boolean
}

export interface FAQ {
  question: string
  answer: ReactNode
}

/**
 * @deprecated - use CollapsingListItem instead
 */
export function FAQItem({ question, answer, id, fullWidth = false, ...props }: FAQItemProps) {
  const [isOpen, setIsOpen] = useState(false)

  const idPrefix = useRef(id ?? uniqueId('faq-item'))
  const buttonId = `${idPrefix.current}-button`
  const answerId = `${idPrefix.current}-answer`

  return (
    <QuestionAnswerContainer {...props}>
      <QuestionButton
        aria-controls={answerId}
        aria-expanded={isOpen ? 'true' : 'false'}
        id={buttonId}
        isOpen={isOpen}
        type="button"
        onClick={() => setIsOpen(!isOpen)}
      >
        <Question className="h5 mb-0">{question}</Question>
      </QuestionButton>

      <AnswerCollapser
        aria-labelledby={buttonId}
        initial={false}
        animate={{ height: isOpen ? 'auto' : 0 }}
        transition={{ duration: 0.3, ease: 'easeOut' }}
        id={answerId}
        role="region"
        $fullWidth={fullWidth}
      >
        <Answer>{answer}</Answer>
      </AnswerCollapser>

      {isOpen ? (
        <IconMinus className="mt-xs" size={16} color={primary} />
      ) : (
        <IconPlus className="mt-xs" size={16} color={primary} />
      )}
    </QuestionAnswerContainer>
  )
}

const Question = styled('h3')`
  padding-bottom: 2rem;
  margin-bottom: 0;
  line-height: 1.4;
`

const QuestionAnswerContainer = styled('div')`
  border-bottom: 1px solid ${greySet[15].hex};
  border-top: 1px solid ${greySet[15].hex};
  position: relative;

  & + & {
    border-top: 0;
  }

  svg {
    position: absolute;
    top: 2rem;
    right: 0;
    fill: #fe7674;
    pointer-events: none;
  }

  button {
    text-align: left;
  }
`

const QuestionButton = styled('button')<{ isOpen: boolean }>`
  width: 100%;
  padding-top: 2rem;
  padding-right: 2rem;
  position: relative;

  &.focus-visible {
    outline: none;
    box-shadow: 0 0 0 3px ${tealSet[15].hex};
  }
`

const Answer = styled('div')`
  padding-bottom: 2rem;
`
Answer.defaultProps = { className: 'large text-light' }

const AnswerCollapser = styled(motion.div)<{ $fullWidth: boolean }>`
  ${props => !props.$fullWidth && 'max-width: 548px;'}
  padding-right: 3rem;
  overflow: hidden;
`
