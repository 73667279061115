import { omit } from 'lodash'
import { HTMLAttributes } from 'react'

import { csx } from '../../utils/csx'
import {
  CollapsingListItem,
  CollapsingListItemLabelProps,
  CollapsingListItemProps,
  collapsingListItemLabelProps,
} from './CollapsingListItem'

export interface CollapsingListProps extends HTMLAttributes<HTMLDivElement> {
  listItems: CollapsingListItemProps[]
  sharedListItemProps?: Omit<CollapsingListItemProps, 'label' | 'children' | 'showBottomBorder'>
  labelFontFamily?: 'serif' | 'sansSerif'
  showBottomBorder?: boolean
}

export function CollapsingList({
  listItems,
  labelFontFamily,
  sharedListItemProps = {},
  showBottomBorder = true,
  ...props
}: CollapsingListProps) {
  return (
    <div {...props}>
      {listItems.map((listItem, idx) => (
        <CollapsingListItem
          key={listItem.label?.toString()}
          label={listItem.label}
          labelProps={getLabelProps(listItem.labelProps, sharedListItemProps.labelProps, labelFontFamily)}
          showBottomBorder={showBottomBorder && idx + 1 === listItems.length}
          {...omit(sharedListItemProps, ['labelProps'])}
        >
          {listItem.children}
        </CollapsingListItem>
      ))}
    </div>
  )
}

function getLabelProps(
  labelProps: CollapsingListItemLabelProps | null | undefined = {},
  sharedProps: CollapsingListItemLabelProps | null | undefined = {},
  labelFontFamily?: 'serif' | 'sansSerif',
) {
  const initial = {
    ...collapsingListItemLabelProps.default,
    ...sharedProps,
    ...labelProps,
    className: csx([collapsingListItemLabelProps.default.className, sharedProps?.className, labelProps?.className]),
  }

  if (labelFontFamily) {
    return {
      ...initial,
      className: csx([collapsingListItemLabelProps[labelFontFamily].className, initial.className]),
    }
  }
  return initial
}
