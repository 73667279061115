import { IconProps } from './IconProps'

export function IconFileDocument({ size = 24, color = 'currentColor', ...props }: IconProps) {
  return (
    <svg width={size} height={size} viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.31027 5.72027C6.31027 4.93815 6.95752 4.29613 7.74041 4.29613H16.041C16.4439 4.29613 17.1394 4.58832 17.4083 4.86498L24.1206 11.7706C24.4079 12.0662 24.6897 12.7568 24.6897 13.1669V26.2761C24.6897 27.065 24.0498 27.7039 23.2663 27.7039H7.73367C6.95025 27.7039 6.31027 27.0667 6.31027 26.2893V23.1994V9.70329V5.72027ZM4 23.1994V26.2893C4 28.334 5.67344 30 7.73367 30H23.2663C25.3281 30 27 28.3308 27 26.2761V13.1669C27 12.1605 26.4849 10.8982 25.7821 10.1752L19.0699 3.26964C18.3648 2.54426 17.0692 2 16.041 2H7.74041C5.68261 2 4 3.66902 4 5.72027V9.70329V23.1994ZM14.7492 24.3554C15.1745 24.3554 15.5193 24.0127 15.5193 23.59C15.5193 23.1673 15.1745 22.8246 14.7492 22.8246H9.61523C9.18993 22.8246 8.84515 23.1673 8.84515 23.59C8.84515 24.0127 9.18993 24.3554 9.61523 24.3554H14.7492ZM14.7492 19.9544C15.1745 19.9544 15.5193 19.6118 15.5193 19.1891C15.5193 18.7664 15.1745 18.4237 14.7492 18.4237H9.61523C9.18993 18.4237 8.84515 18.7664 8.84515 19.1891C8.84515 19.6118 9.18993 19.9544 9.61523 19.9544H14.7492ZM21.1351 18.2323C21.5601 18.2323 21.9046 18.5753 21.9046 18.9977V23.8451C21.9046 24.2678 21.5592 24.6105 21.1351 24.6105H18.0535C17.6285 24.6105 17.284 24.2676 17.284 23.8451V18.9977C17.284 18.575 17.6294 18.2323 18.0535 18.2323H21.1351Z"
        fill={color}
      />
    </svg>
  )
}
