import { styled } from '@mui/material'
import { HTMLAttributes, useEffect, useRef, useState } from 'react'

import { useVideoCallContext } from '../../context/VideoCallContext'
import { useVideoChatContext } from '../../context/VideoChatContext'
import { ChatOnlineIndicator } from './components/ChatOnlineIndicator'
import { TypingUsers } from './components/TypingUsers'
import { VideoChatMessage } from './components/VideoChatMessage'
import { VideoChatMessageComposer } from './components/VideoChatMessageComposer'

export function VideoChat(props: HTMLAttributes<HTMLDivElement>) {
  const [initialized, setInitialized] = useState(false)
  const { expectedUsers } = useVideoCallContext()
  const { messages, markAllMessagesRead, updatePreviousLastReadMessageIndex } = useVideoChatContext()
  const scrollBody = useRef<HTMLDivElement>(null)

  useEffect(() => {
    if (messages.length) {
      markAllMessagesRead()
    }
  }, [messages, markAllMessagesRead])

  useEffect(() => {
    if (!initialized) {
      updatePreviousLastReadMessageIndex()
      setInitialized(true)
    }
  }, [updatePreviousLastReadMessageIndex, initialized])

  useEffect(() => {
    if (scrollBody.current) {
      // TODO make scrolling behavior smarter - this will require some indicator that new
      // messages were received if the user is not scrolled to the bottom and new messages arrive
      scrollBody.current.scrollTo(0, scrollBody.current.scrollHeight)
    }
  }, [messages])

  return (
    <Wrapper {...props}>
      <TopSection ref={scrollBody}>
        {messages.map((message, index) => (
          <VideoChatMessage key={message.sid} message={message} index={index} />
        ))}

        <TypingUsers />
      </TopSection>
      <BottomSection>
        {expectedUsers.map(user => (
          <ChatOnlineIndicator key={user.loginId} user={user} />
        ))}
        <VideoChatMessageComposer />
      </BottomSection>
    </Wrapper>
  )
}

const Wrapper = styled('div')`
  display: flex;
  flex-direction: column;
  height: 100%;
`

const TopSection = styled('section')`
  flex: 1 1 0;
  overflow: auto;
  padding-top: 1rem;
`

const BottomSection = styled('section')`
  margin-top: auto;
  padding-top: 0.5rem;
`
