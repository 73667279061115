export type BaseShadowDepth = 1 | 4 | 8
export type ShadowDepth = 1 | 1.5 | 4 | 4.5 | 8 | 8.5
export type ShadowColor = 'warm' | 'cool'

export const shadowDepth = (depth: ShadowDepth, color: ShadowColor = 'warm') => {
  if (color === 'warm') {
    switch (depth) {
      case 1:
        return `0px 3px 3px -1px rgba(50, 40, 30, 0.12), 0px 4px 6px -3px rgba(69, 57, 43, 0.08), 0px 12px 12px -25px rgba(69, 57, 43, 0.04)`
      case 1.5:
        return `0px 8px 10px -4px rgba(50, 40, 30, 0.12), 0px 24px 30px -12px rgba(36, 28, 19, 0.08), 0px 24px 40px -18px rgba(36, 28, 19, 0.05)`
      case 4:
        return `0px 24px 36px -15px rgba(50, 40, 30, 0.15), 0px 40px 60px -20px rgba(36, 28, 19, 0.12), 0px 60px 80px -25px rgba(36, 28, 19, 0.05)`
      case 4.5:
        return `0px 32px 44px -20px rgba(50, 40, 30, 0.18), 0px 45px 70px -40px rgba(36, 28, 19, 0.12), 0px 60px 90px -15px rgba(36, 28, 19, 0.05)`
      case 8:
        return `0px 32px 44px -20px rgba(50, 40, 30, 0.2), 0px 45px 70px -25px rgba(37, 22, 36, 0.15), 0px 60px 90px -30px rgba(36, 28, 19, 0.08)`
      case 8.5:
        return `0px 50px 70px -30px rgba(50, 40, 30, 0.36), 0px 70px 130px -50px rgba(36, 28, 19, 0.18), 0px 80px 210px 40px rgba(36, 28, 19, 0.1)`
    }
  }

  switch (depth) {
    case 1:
      return `0px 3px 3px -1px rgba(41, 67, 66, 0.12), 0px 4px 6px -3px rgba(19, 40, 39, 0.08), 0px 12px 12px -25px rgba(19, 40, 39, 0.04);`
    case 1.5:
      return `0px 8px 10px -4px rgba(41, 67, 66, 0.12), 0px 24px 30px -12px rgba(19, 40, 39, 0.08), 0px 24px 40px -18px rgba(19, 40, 39, 0.05)`
    case 4:
      return `0px 24px 36px -15px rgba(41, 67, 66, 0.15), 0px 40px 60px -20px rgba(19, 40, 39, 0.12), 0px 60px 80px -25px rgba(19, 40, 39, 0.05)`
    case 4.5:
      return `0px 32px 44px -20px rgba(41, 67, 66, 0.18), 0px 45px 70px -40px rgba(19, 40, 39, 0.12), 0px 60px 90px -15px rgba(19, 40, 39, 0.05)`
    case 8:
      return `0px 32px 44px -20px rgba(41, 67, 66, 0.2), 0px 45px 70px -25px rgba(19, 40, 39, 0.15), 0px 60px 90px -30px rgba(19, 40, 39, 0.08)`
    case 8.5:
      return `0px 50px 70px -30px rgba(41, 67, 66, 0.36), 0px 70px 130px -50px rgba(19, 40, 39, 0.18), 0px 80px 210px 40px rgba(19, 40, 39, 0.1)`
  }
}

export const shadowWithHoverTransition = (depth: BaseShadowDepth) => `
  box-shadow: ${shadowDepth(depth as ShadowDepth)};
  transition: transform 0.3s, box-shadow 0.3s;

  &:hover {
    box-shadow: ${shadowDepth((depth + 0.5) as ShadowDepth)};
    transform: translateY(-3px);
  }
`
