import '@fullcalendar/react/dist/vdom'

import FullCalendar, { CalendarOptions } from '@fullcalendar/react'

import dayGridPlugin from '@fullcalendar/daygrid'
import interactionPlugin from '@fullcalendar/interaction'
import listPlugin from '@fullcalendar/list'
import momentTimezonePlugin from '@fullcalendar/moment-timezone'
import resourceTimelinePlugin from '@fullcalendar/resource-timeline'
import timeGridPlugin from '@fullcalendar/timegrid'
import timelinePlugin from '@fullcalendar/timeline'
import moment from 'moment'
import { RefObject } from 'react'

interface FullCalendarWrapperProps extends CalendarOptions {
  calendarRef: RefObject<FullCalendar>
}

export function FullCalendarWrapper({ calendarRef, ...props }: FullCalendarWrapperProps) {
  return (
    <FullCalendar
      ref={calendarRef}
      schedulerLicenseKey="GPL-My-Project-Is-Open-Source"
      plugins={[
        listPlugin,
        dayGridPlugin,
        timeGridPlugin,
        timelinePlugin,
        resourceTimelinePlugin,
        interactionPlugin,
        momentTimezonePlugin,
      ]}
      eventStartEditable={false}
      eventDurationEditable={false}
      editable={false}
      fixedWeekCount={false}
      nowIndicator={true}
      businessHours={{
        daysOfWeek: [0, 1, 2, 3, 4, 5, 6],
        startTime: '23:00',
        endTime: '23:00',
      }}
      scrollTime="8:00"
      themeSystem="standard"
      allDaySlot={false}
      height="100%"
      slotMinTime={'0:00'}
      slotMaxTime={'24:00'}
      headerToolbar={{
        right: '',
        center: '',
        left: '',
      }}
      buttonText={{
        week: 'Week',
        day: 'Day',
        today: 'This Week',
      }}
      initialView="timeGridWeek"
      dayHeaderContent={args => {
        const date =
          props.timeZone && props.timeZone !== 'local' ? moment(args.date).tz(props.timeZone) : moment(args.date)

        return {
          html: `<span class="fc-header-day uppercase caption">${date.format('ddd')}</span>
              <h4 class="fc-header-date sans-serif mb-0 display text-normal mt-1">${date.format('D')}</h4>`,
        }
      }}
      {...props}
    />
  )
}
