import { styled } from '@mui/material'
import { useFormikContext } from 'formik'
import { HTMLAttributes } from 'react'

import { Checkbox, body2, error, makeTypographyComponent } from '@nuna/tunic'

import { SessionForm } from '../../../types'

export function NoShowConfirmation(props: HTMLAttributes<HTMLDivElement>) {
  const { getFieldProps, touched, errors } = useFormikContext<SessionForm>()
  return (
    <Container {...props}>
      <div>
        <Header className="mb-2">No-show confirmation</Header>
        <Checkbox {...getFieldProps('noShowConfirmation')} className="mb-xs">
          I confirm that I waited at least 15 min before ending the session
        </Checkbox>
        <Caption className="noshow-caption">
          <div>
            A session is considered a no-show if both parties have not joined within 15 minutes of the scheduled start
            time.
          </div>
          {touched.noShowConfirmation && errors.noShowConfirmation && (
            <HelperText error>{errors.noShowConfirmation}</HelperText>
          )}
        </Caption>
      </div>
    </Container>
  )
}

const Header = makeTypographyComponent('h6 sans-serif', 'h3')
const Caption = makeTypographyComponent('caption italic text-secondary', 'div')

const Container = styled('div')`
  max-width: 560px;

  .noshow-caption {
    margin-left: 1.92rem;
  }
`

const HelperText = styled('p')<{ error: boolean }>`
  color: ${body2};
  display: flex;
  font-size: 12px;
  line-height: 1.4;
  margin: 5px 0 0;

  ${props => props.error && `color: ${error};`}
`
