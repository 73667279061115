import '@fullcalendar/react/dist/vdom'

import FullCalendar, { CalendarOptions, EventApi, type EventContentArg, EventSourceInput } from '@fullcalendar/react'

import interactionPlugin from '@fullcalendar/interaction'
import momentTimezonePlugin from '@fullcalendar/moment-timezone'
import timeGridPlugin from '@fullcalendar/timegrid'
import { Popover, styled } from '@mui/material'
import moment, { DurationInputArg1, unitOfTime } from 'moment-timezone'
import { useEffect, useMemo, useRef, useState } from 'react'
import { useMediaQuery } from 'react-responsive'

import { DayAndTimeInput } from '@nuna/api'
import { useIsAdmin } from '@nuna/auth'
import { useTimeZoneContext } from '@nuna/common'
import { timeService } from '@nuna/core'
import {
  BelowTablet,
  Box,
  Grid,
  IconArmchair,
  IconVideoOutline,
  Stack,
  greySet,
  shadowDepth,
  tealSet,
} from '@nuna/tunic'

import {
  AvailabilityEventEditForm,
  AvailabilityExtendedProps,
  AvailabilitySubmission,
} from './AvailabilityEventEditForm'

const { daysOfWeek, toAvailabilityFormat, adjustTimeForTimeZoneChange } = timeService
const EVENT_BREAKPOINT_QUERY = '(max-width: 79em)'

export interface DraggableCalendarProps extends Omit<CalendarOptions, 'events'> {
  newEventDuration?: [DurationInputArg1, unitOfTime.DurationConstructor]
  onChange: (availabilityPeriods: DayAndTimeInput[]) => void
  onDelete: (periodsToDelete: DayAndTimeInput[]) => void
  availabilityPeriods: DayAndTimeInput[]
  calendarDate?: Date
  timeZone: string
}

interface SelectedEvent {
  event: EventApi
  el: HTMLElement
}
interface CustomEventContentArg extends EventContentArg {
  event: EventApi & { extendedProps: AvailabilityExtendedProps }
}

export function DraggableCalendar({
  availabilityPeriods,
  onChange,
  onDelete,
  timeZone,
  newEventDuration = [1, 'h'],
  calendarDate = new Date(),
  ...props
}: DraggableCalendarProps) {
  const isAdmin = useIsAdmin()
  const { timeZoneToUse } = useTimeZoneContext()
  const calendarRef = useRef<FullCalendar>(null)
  const [selectedEvent, setSelectedEvent] = useState<SelectedEvent | null>(null)
  const isMobile = useMediaQuery({ query: `(${BelowTablet})` })

  useEffect(() => {
    calendarRef.current?.getApi().gotoDate(calendarDate)
  }, [calendarDate])

  useEffect(() => {
    if (isMobile) {
      calendarRef.current?.getApi().changeView('timeGridThreeDay')
    } else {
      calendarRef.current?.getApi().changeView('timeGridWeek')
    }
  }, [isMobile])

  const getAvailabilityPeriodFromEvent = (event: AvailabilitySubmission): DayAndTimeInput => ({
    ...toAvailabilityFormat(event.start, event.end, timeZone),
    id: event.id,
    virtualAllowed: event.extendedProps.virtualAllowed,
    addressId: event.extendedProps.addressId,
  })

  const addEvent = (start: Date, end: Date) => {
    const newPeriod = toAvailabilityFormat(start, end, timeZone)
    // Periods must be an hour and can't cross midnight so don't allow 11:30 blocks
    if (newPeriod.start === '23:30') return

    onChange([newPeriod])
  }

  const removeEvent = (selectedEvent: SelectedEvent) => {
    selectedEvent.event.remove()
    setSelectedEvent(null)
    onDelete([getAvailabilityPeriodFromEvent(selectedEvent.event)])
  }

  const events = useMemo(
    () =>
      getRecurringEventsFromAvailabilityPeriods({
        availabilityPeriods,
        providerTimeZone: timeZone,
        timeZoneToUse,
        isAdmin,
      }),
    [availabilityPeriods, timeZone, timeZoneToUse, isAdmin],
  )

  return (
    <CalendarStyleWrapper>
      <FullCalendar
        ref={calendarRef}
        events={events}
        plugins={[timeGridPlugin, interactionPlugin, momentTimezonePlugin]}
        editable={true}
        selectable={true}
        selectMirror={true}
        selectOverlap={false}
        eventClick={arg => {
          arg.jsEvent.preventDefault()
          arg.el.focus()
          setSelectedEvent(arg)
        }}
        businessHours={{
          daysOfWeek: [0, 1, 2, 3, 4, 5, 6],
          startTime: '23:00',
          endTime: '23:00',
        }}
        eventContent={(arg: CustomEventContentArg) => <EventContent {...arg} />}
        selectMinDistance={56}
        select={arg => {
          // ignore drags less than an hour
          if (moment(arg.end).diff(arg.start) >= 3600000) {
            addEvent(arg.start, arg.end)
          }

          calendarRef.current?.getApi().unselect()
        }}
        dateClick={arg => {
          addEvent(
            arg.date,
            moment(arg.date)
              .add(...newEventDuration)
              .toDate(),
          )
        }}
        eventDrop={({ event }) => onChange([getAvailabilityPeriodFromEvent(event)])}
        eventResize={({ event }) => onChange([getAvailabilityPeriodFromEvent(event)])}
        eventOverlap={false}
        eventResizableFromStart={true}
        eventClassNames={arg => {
          const classNames = ['availability']

          if (arg.event.startStr === selectedEvent?.event.startStr) {
            classNames.push('selected')
          }

          return classNames
        }}
        dayHeaders={false}
        headerToolbar={false}
        scrollTime="8:00"
        themeSystem="standard"
        allDaySlot={false}
        height="100%"
        eventDidMount={({ el, event }) => {
          el.tabIndex = 0
          el.setAttribute('role', 'button')

          el.addEventListener('keydown', e => {
            if (e.code === 'Space' || e.code === 'Enter') {
              setSelectedEvent({ event, el })
            }
          })
        }}
        views={{
          timeGridThreeDay: {
            type: 'timeGrid',
            visibleRange: currentDate => {
              return {
                start: moment(currentDate).add(-1, 'day').toDate(),
                end: moment(currentDate).add(1, 'day').toDate(),
              }
            },
          },
        }}
        initialView={isMobile ? 'timeGridThreeDay' : 'timeGridWeek'}
        timeZone={timeZone}
        {...props}
      />

      {selectedEvent && (
        <StyledPopover
          anchorOrigin={{ horizontal: selectedEvent.el.clientWidth - 16, vertical: 'top' }}
          open={!!selectedEvent}
          onClose={() => setSelectedEvent(null)}
          anchorEl={selectedEvent.el}
          onKeyDown={e => {
            if (e.code === 'Backspace') {
              removeEvent(selectedEvent)
            }
          }}
        >
          <AvailabilityEventEditForm
            key={selectedEvent.event.startStr}
            timeZone={timeZone}
            event={selectedEvent.event}
            onRemove={() => {
              removeEvent(selectedEvent)
            }}
            onSubmit={event => {
              const { start, end, extendedProps } = event
              const { virtualAllowed, addressId } = extendedProps
              if (!start || !end) return
              selectedEvent.event.setDates(start, end)
              selectedEvent.event.setExtendedProp('virtualAllowed', virtualAllowed)
              selectedEvent.event.setExtendedProp('addressId', addressId)
              onChange([getAvailabilityPeriodFromEvent(event)])
              setSelectedEvent(null)
            }}
          />
        </StyledPopover>
      )}
    </CalendarStyleWrapper>
  )
}

const LocationIcons = ({ availabilityLocation }: { availabilityLocation: AvailabilityExtendedProps }) => {
  const { virtualAllowed, addressId } = availabilityLocation
  const isTabletIshDown = useMediaQuery({ query: EVENT_BREAKPOINT_QUERY })

  if (addressId && virtualAllowed) {
    return (
      <Grid
        container
        spacing={1}
        rowSpacing={0.5}
        justifyContent={isTabletIshDown ? 'flex-start' : 'flex-end'}
        style={isTabletIshDown ? { marginTop: '2px' } : {}}
      >
        <Grid>
          <IconArmchair size={13} />
        </Grid>
        <Grid>
          <IconVideoOutline size={13} />
        </Grid>
      </Grid>
    )
  }
  if (addressId) {
    return <IconArmchair size={13} />
  }
  return <IconVideoOutline size={13} />
}

const EventContent = (eventInfo: CustomEventContentArg) => {
  return (
    <div className="fc-event-main-frame">
      <Stack direction="row" className="fc-event-time" justifyContent="space-between" flexWrap="wrap">
        <Box component="span" sx={{ pr: 1 }}>
          {eventInfo.timeText}
        </Box>{' '}
        <LocationIcons availabilityLocation={eventInfo.event.extendedProps} />
      </Stack>
      <div className="fc-event-title-container">
        <div className="fc-event-title fc-sticky">{eventInfo.event.title}</div>
      </div>
    </div>
  )
}

function getRecurringEventsFromAvailabilityPeriods({
  availabilityPeriods,
  providerTimeZone,
  timeZoneToUse,
  isAdmin,
}: {
  availabilityPeriods: DayAndTimeInput[]
  providerTimeZone: string
  timeZoneToUse?: string | null
  isAdmin: boolean
}): EventSourceInput {
  return availabilityPeriods.map(availabilityPeriod => {
    return {
      id: availabilityPeriod.id ?? undefined,
      allDay: false,
      title: 'Open',
      daysOfWeek: [daysOfWeek.indexOf(availabilityPeriod.day)],
      startTime:
        timeZoneToUse && isAdmin
          ? adjustTimeForTimeZoneChange({
              time: availabilityPeriod.start,
              oldTimeZone: providerTimeZone,
              newTimeZone: timeZoneToUse,
            })
          : availabilityPeriod.start,
      endTime:
        timeZoneToUse && isAdmin
          ? adjustTimeForTimeZoneChange({
              time: availabilityPeriod.end,
              oldTimeZone: providerTimeZone,
              newTimeZone: timeZoneToUse,
            })
          : availabilityPeriod.end,
      virtualAllowed: availabilityPeriod.virtualAllowed,
      addressId: availabilityPeriod.addressId,
    }
  })
}

const StyledPopover = styled(Popover)`
  .MuiPopover-paper {
    border: 1px solid ${greySet[15].hex};
    border-radius: var(--border-radius);
    box-shadow: ${shadowDepth(4)};

    color: unset;
  }
`

const CalendarStyleWrapper = styled('div')`
  height: 100%;

  .fc .fc-timegrid-col.fc-day-today {
    .fc-timegrid-col-frame {
      border-left: 0;
      border-right: 0;
    }
  }

  .fc .fc-timegrid-col.fc-day {
    cursor: cell;
  }

  @media (${BelowTablet}) {
    .fc {
      .fc-timegrid-col:nth-child(1) {
        border: 0;
      }

      .fc-timegrid-col:nth-child(2),
      .fc-timegrid-col:nth-child(4) {
        width: 35px;

        .fc-event-main {
          visibility: hidden;
        }
      }

      .fc-timegrid-col:nth-child(2) {
        .fc-timegrid-event {
          border-left: 0;
          border-top-left-radius: 0;
          border-bottom-left-radius: 0;
        }

        .fc-timegrid-col-frame {
          &::after {
            content: '';
            position: absolute;
            top: 0;
            left: -1px;
            width: 100%;
            height: 100%;
            background-image: linear-gradient(90deg, #ffffff 0, rgba(255, 255, 255, 0.2) 100%);
            z-index: 100;
          }
        }
      }

      .fc-timegrid-col:nth-child(4) {
        .fc-timegrid-event {
          border-right: 0;
          border-top-right-radius: 0;
          border-bottom-right-radius: 0;
        }
      }
    }
  }

  .availability {
    background-color: rgba(255, 255, 255, 0.6);
    border-radius: 8px;
    cursor: grab;

    .fc-event-main-frame {
      padding-top: 6px;
    }

    &::before,
    &::after {
      background-color: ${tealSet[50].hex};
      border-radius: 20px;
      border: 5px solid ${tealSet[15].hex};
      content: '';
      height: 13px;
      left: 0;
      margin: auto;
      position: absolute;
      right: 0;
      width: 60%;

      @media (${BelowTablet}) {
        display: none;
      }
    }

    &:hover::before,
    &:hover::after {
      width: 90%;
    }

    &::before {
      top: -6.5px;
    }

    &::after {
      bottom: -6.5px;
    }

    &:focus,
    &.selected {
      background-color: ${tealSet.tint[20]};
      border: 3px solid ${tealSet.primary.hex};
      outline: 0;
    }
  }
`
