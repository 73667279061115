import moment, { MomentInput } from 'moment'
import { useEffect, useState } from 'react'

interface Options {
  formatString?: string
  updateInterval?: number
}

export function useDurationString(startTime: MomentInput, { formatString, updateInterval = 1000 }: Options = {}) {
  const [waitingDurationString, setWaitingDurationString] = useState<string>('')

  useEffect(() => {
    const interval = setInterval(() => {
      const duration = moment().diff(startTime, 'milliseconds')

      const defaultFormatString = duration / 1000 < 60 ? 's [sec]' : 'm [min] s [sec]'

      setWaitingDurationString(moment.utc(duration).format(formatString ?? defaultFormatString))
    }, updateInterval)
    return () => {
      clearInterval(interval)
    }
  }, [formatString, startTime, updateInterval])

  return waitingDurationString
}
