import { styled } from '@mui/material'
import { motion } from 'framer-motion'
import { CSSProperties, ReactNode } from 'react'

export interface CollapseProps {
  children: ReactNode
  isOpen: boolean
  duration?: number
  className?: string
  style?: CSSProperties
}

export function Collapse({ duration = 0.3, isOpen, ...props }: CollapseProps) {
  return (
    <Container initial={{ height: 0 }} animate={{ height: isOpen ? 'auto' : 0 }} transition={{ duration }} {...props} />
  )
}

// Difference here is that this does not accept an isOpen prop and is meant to animate in/out as it is add/removed from the DOM
// Must be used as a child of AnimatePresence
// This is useful for animating content that is conditionally rendered while the other Collapse is good for content that is always rendered but collapsible
export function CollapsePresence({ duration = 0.3, ...props }: Omit<CollapseProps, 'isOpen'>) {
  return (
    <Container
      initial={{ height: 0 }}
      animate={{ height: 'auto' }}
      exit={{ height: 0 }}
      transition={{ duration }}
      {...props}
    />
  )
}

const Container = styled(motion.div)`
  overflow: hidden;
`
