import { styled } from '@mui/material'
import { HTMLAttributes, useState } from 'react'

import { Card, GhostButton, OutlineButtonLink, PlaceholderButton, makeTypographyComponent } from '@nuna/tunic'

export function AddNewPolicyButton(props: HTMLAttributes<HTMLDivElement>) {
  const [showWarning, setShowWarning] = useState(false)
  return (
    <div {...props}>
      {showWarning && (
        <StyledCard className="p-4 v-align">
          <div className="pr-2">
            <Description>
              Creating a new policy will force all clients to accept it before they can book a session with you.
            </Description>
            <Subtext>Avoid frequent changes to your policy that may whiplash your clients</Subtext>
          </div>
          <div className="ml-auto text-center">
            <OutlineButtonLink to="new" data-testid="confirm-add-cancel-policy-button">
              Continue
            </OutlineButtonLink>
            <GhostButton className="mt-2" variant="secondary" onClick={() => setShowWarning(false)}>
              Cancel
            </GhostButton>
          </div>
        </StyledCard>
      )}
      {!showWarning && (
        <PlaceholderButton onClick={() => setShowWarning(true)} data-testid="add-cancel-policy-button">
          New Policy
        </PlaceholderButton>
      )}
    </div>
  )
}

const StyledCard = styled(Card)``

const Description = makeTypographyComponent('large text-medium text-primary', 'div')
const Subtext = makeTypographyComponent('italic text-secondary mt-1', 'div')
