import { styled } from '@mui/material'
import { MouseEvent, useState } from 'react'
import { useMediaQuery } from 'react-responsive'

import { BelowTablet, Drawer, IconButton, IconClose, IconHamburgerMenu, Menu } from '@nuna/tunic'

import { useProviderAppContext } from '../../../../shared/ProviderAppContext'
import { SignupIntakeMenu } from './SignupIntakeMenu'

interface Props {
  onSaveAndNavigate: (to: string) => Promise<boolean> | void
  variant?: 'primary' | 'secondary' | 'dark' | 'destroy'
}

export function SignupIntakeNavMenu({ onSaveAndNavigate, variant = 'dark' }: Props) {
  const isMobile = useMediaQuery({ query: `(${BelowTablet})` })
  const { refetchStatus } = useProviderAppContext()
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)

  const toggleOpen = (e: MouseEvent<HTMLButtonElement>) => {
    anchorEl && refetchStatus()
    setAnchorEl(anchorEl ? null : e.currentTarget)
  }

  const handleClose = () => {
    refetchStatus()
    setAnchorEl(null)
  }

  const handleSaveAndNavigate = async (to: string) => {
    await onSaveAndNavigate(to)
    handleClose()
  }

  const menuOpened = !!anchorEl

  return (
    <>
      <IconButton tooltip="Nav menu" type="button" variant={variant} onClick={toggleOpen}>
        {!menuOpened && <IconHamburgerMenu size={10} />}
        {menuOpened && <IconClose size={16} />}
      </IconButton>
      {isMobile && (
        <MobileDrawer anchor="left" isOpen={menuOpened} onClose={handleClose}>
          <IconButton className="drawer-close-button" tooltip="Close" onClick={handleClose}>
            <IconClose />
          </IconButton>
          <SignupIntakeMenu className="drawer-menu" onSaveAndNavigate={handleSaveAndNavigate} />
        </MobileDrawer>
      )}
      {!isMobile && (
        <Menu
          open={menuOpened}
          anchorEl={anchorEl}
          onClose={handleClose}
          style={{ width: 300 }}
          anchorOrigin={{ vertical: 48, horizontal: 'left' }}
        >
          <SignupIntakeMenu onSaveAndNavigate={handleSaveAndNavigate} />
        </Menu>
      )}
    </>
  )
}

const MobileDrawer = styled(Drawer)`
  position: relative;
  .drawer-menu {
    padding: 1rem;
  }
  .drawer-close-button {
    position: absolute;
    right: 1rem;
    top: 1rem;
  }
`
