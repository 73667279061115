import { useEffect } from 'react'

import { type AccessCodeCoverageFragment } from '@nuna/core'

import { AccessCodeProps, CoverageTypeCard } from '../../CoverageTypeCard'
import { Cost } from './Cost'

interface AccessCodeSummaryProps {
  coverageCardDetails: AccessCodeProps
  currentCoverage: AccessCodeCoverageFragment
  onCoverageValidityChange: (isValid: boolean) => void
}

export function AccessCodeSummary({
  coverageCardDetails,
  currentCoverage,
  onCoverageValidityChange,
}: AccessCodeSummaryProps) {
  useEffect(() => {
    onCoverageValidityChange(!coverageCardDetails.isInvalid)
  }, [coverageCardDetails.isInvalid, onCoverageValidityChange])

  return (
    <>
      <CoverageTypeCard {...coverageCardDetails} />
      <Cost type="accessCode" sessionCost={currentCoverage.details.cost} />
    </>
  )
}
