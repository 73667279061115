import { IconProps } from './IconProps'

export function IconBulletedList({ size = 24, color = 'currentColor', ...props }: IconProps) {
  return (
    <svg width={size} height={size} viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.6345 9.03958C14.6345 8.4873 15.0822 8.03958 15.6345 8.03958H44.2198C44.7721 8.03958 45.2198 8.4873 45.2198 9.03958V13.0262C45.2198 13.5785 44.7721 14.0262 44.2198 14.0262H15.6345C15.0822 14.0262 14.6345 13.5785 14.6345 13.0262V9.03958ZM14.6345 22.0128C14.6345 21.4605 15.0822 21.0128 15.6345 21.0128H44.2198C44.7721 21.0128 45.2198 21.4605 45.2198 22.0128V25.9994C45.2198 26.5517 44.7721 26.9994 44.2198 26.9994H15.6345C15.0822 26.9994 14.6345 26.5517 14.6345 25.9994V22.0128ZM14.6345 34.9738C14.6345 34.4216 15.0822 33.9738 15.6345 33.9738H44.2198C44.7721 33.9738 45.2198 34.4216 45.2198 34.9738V38.9604C45.2198 39.5127 44.7721 39.9604 44.2198 39.9604H15.6345C15.0822 39.9604 14.6345 39.5127 14.6345 38.9604V34.9738ZM6.17226 13.7469C4.29878 13.7469 2.78003 12.23 2.78003 10.3589C2.78003 8.48782 4.29878 6.97098 6.17226 6.97098C8.04574 6.97098 9.5645 8.48782 9.5645 10.3589C9.5645 12.23 8.04574 13.7469 6.17226 13.7469ZM6.17226 27.3879C4.29878 27.3879 2.78003 25.8711 2.78003 24C2.78003 22.1289 4.29878 20.612 6.17226 20.612C8.04574 20.612 9.5645 22.1289 9.5645 24C9.5645 25.8711 8.04574 27.3879 6.17226 27.3879ZM6.17226 41.029C4.29878 41.029 2.78003 39.5121 2.78003 37.641C2.78003 35.7699 4.29878 34.2531 6.17226 34.2531C8.04574 34.2531 9.5645 35.7699 9.5645 37.641C9.5645 39.5121 8.04574 41.029 6.17226 41.029Z"
        fill={color}
      />
    </svg>
  )
}
