import { IconProps } from './IconProps'

export function IconTrash({ size = 24, color = 'currentColor', ...props }: IconProps) {
  return (
    <svg width={size} height={size} viewBox="0 0 21 22" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.4106 4.4676C15.8049 4.4676 16.125 4.78258 16.125 5.17112V5.79799C16.125 6.18649 15.8051 6.5015 15.4106 6.5015H5.58941C5.19506 6.5015 4.875 6.18653 4.875 5.79799V5.17112C4.875 4.78261 5.19485 4.4676 5.58941 4.4676H8.97365V4.20351C8.97365 3.81501 9.29344 3.5 9.68793 3.5H11.2835C11.6777 3.5 11.9978 3.81497 11.9978 4.20351V4.4676H15.4106ZM4.91997 8.48009L6.02942 17.8008C6.07538 18.187 6.43273 18.5 6.82699 18.5H14.173C14.5675 18.5 14.9246 18.1872 14.9706 17.8008L16.08 8.48009C16.126 8.09394 15.8433 7.78091 15.4488 7.78091H5.55117C5.15661 7.78091 4.87398 8.09374 4.91997 8.48009Z"
        fill={color}
      />
    </svg>
  )
}
