import { AppointmentCollectionStatusQuery, ConversationParticipantDisplayData, SessionPaymentStatus } from '@nuna/api'
import {
  AppointmentCard,
  AppointmentCardFeeType,
  AppointmentCardProps,
  AppointmentCardStatus,
  AppointmentChargeStatus,
  AppointmentUser,
} from '@nuna/appointment'
import { Audience, appointmentCollectionStatusService } from '@nuna/core'

import { FeeActionsBoot } from './FeeActionsBoot'

const { canReverseFeeDecision, wasRescheduled, hasScheduledFee, wasClientNoShow, hasWaivedFee } =
  appointmentCollectionStatusService

interface Props {
  appointmentData: AppointmentCollectionStatusQuery['appointmentCollectionStatus']
  audience: Audience
  onFeeDecision: (shouldProviderBePaid: boolean) => void
}

export function MessageAppointmentCard({ appointmentData, audience, onFeeDecision }: Props) {
  const rescheduled = wasRescheduled(appointmentData)
  const wasNoShow = wasClientNoShow(appointmentData)
  const showActions =
    (hasScheduledFee(appointmentData) || hasWaivedFee(appointmentData)) && canReverseFeeDecision(appointmentData)

  const getAppointmentCardStatus = () => {
    if (wasNoShow) {
      return AppointmentCardStatus.NoShow
    } else if (rescheduled) {
      return AppointmentCardStatus.Rescheduled
    } else {
      return AppointmentCardStatus.Canceled
    }
  }

  return (
    <AppointmentCard
      {...appointmentDataToAppointmentCardProps(appointmentData)}
      className="mt-2"
      audience={audience}
      appointmentStatus={getAppointmentCardStatus()}
      intent={showActions && audience === 'provider' ? 'caution-action' : undefined}
      chargeStatus={mapChargeStatus(appointmentData.paymentStatus)}
      hideChargeStatus={audience === 'provider' && showActions}
      boot={
        showActions &&
        audience === 'provider' && <FeeActionsBoot appointmentData={appointmentData} onFeeDecision={onFeeDecision} />
      }
    />
  )
}

function appointmentDataToAppointmentCardProps(
  data: AppointmentCollectionStatusQuery['appointmentCollectionStatus'],
): Pick<AppointmentCardProps, 'appointment' | 'client' | 'provider' | 'cancelPolicyStatus'> {
  const cancelPolicyStatus = mapCancelPolicy(data)
  const appointment = { ...data, startDatetime: data.startDate, endDatetime: data.endDate }
  const client = mapAppointmentUser(data.patient)
  const provider = mapAppointmentUser(data.provider)
  return { appointment, client, provider, cancelPolicyStatus }
}

function mapCancelPolicy(
  data: AppointmentCollectionStatusQuery['appointmentCollectionStatus'],
): AppointmentCardProps['cancelPolicyStatus'] {
  return {
    acceptedDate: '',
    cancelPolicy: {
      cancelPeriodMinutes: 0,
      chargeAmount: data.feeAmount ?? 0,
      type: wasClientNoShow(data) ? AppointmentCardFeeType.NoShow : AppointmentCardFeeType.LateCancel,
    },
  }
}

function mapAppointmentUser(convoParticipant: ConversationParticipantDisplayData | undefined | null): AppointmentUser {
  const { firstName = '', lastName = '', entityId: id = '', avatarUrl = '' } = convoParticipant ?? {}
  return { firstName, lastName: lastName ?? '', id, avatarUrl }
}

function mapChargeStatus(status?: SessionPaymentStatus | null): AppointmentChargeStatus | null | undefined {
  if (!status) {
    return status
  }

  switch (status) {
    case SessionPaymentStatus.Scheduled:
    case SessionPaymentStatus.Pending:
      return AppointmentChargeStatus.Pending
    case SessionPaymentStatus.Success:
      return AppointmentChargeStatus.Success
    case SessionPaymentStatus.Failure:
      return AppointmentChargeStatus.Failure
    case SessionPaymentStatus.Waived:
      return AppointmentChargeStatus.Waived
  }
}
