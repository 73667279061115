import { HTMLAttributes, useEffect, useState } from 'react'

import { Patient, Provider, ProviderPayoutPaymentType, useProviderPayoutRatesQuery } from '@nuna/api'
import { errorService, numberService } from '@nuna/core'
import { SidebarPanel } from '@nuna/coverage'
import { ClientDiscountModal } from '@nuna/fee-policies'
import { GhostButton, IconInfo, Skeleton, Tooltip, borderGrey, toast } from '@nuna/tunic'

type Props = HTMLAttributes<HTMLDivElement> & {
  client: Pick<Patient, 'id' | 'firstName'>
} & (
    | {
        audience: 'admin'
        provider: Pick<Provider, 'id' | 'firstName'>
      }
    | {
        audience?: 'provider'
        providerId: string
      }
  )

export function PayoutPanel({ client, ...props }: Props) {
  const providerId = props.audience === 'admin' ? props.provider.id : props.providerId

  const [isDiscountModalOpen, setIsDiscountModalOpen] = useState(false)
  const {
    data,
    error,
    refetch: refetchProviderPayoutRates,
  } = useProviderPayoutRatesQuery({ variables: { clientId: client.id, providerId } })

  useEffect(() => {
    if (error) {
      console.error(error)
      toast.urgent(errorService.transformUserGraphqlError(error, 'Error loading provider payouts'))
    }
  }, [error])

  const payoutRates = data?.providerPayoutRates

  return (
    <>
      <SidebarPanel {...props}>
        <h2 className="h6 mb-3 v-align">
          {props.audience === 'admin' ? `${props.provider.firstName}'s` : 'My'} Payout{' '}
        </h2>

        {!payoutRates ? (
          <Skeleton height={3} />
        ) : (
          <>
            <h3 className="body text-secondary text-medium v-align">
              {payoutRates.isSelfReferred ? 'Own client' : 'Tava referral'} rates{' '}
              {payoutRates && (
                <Tooltip
                  content={
                    payoutRates.isSelfReferred ? (
                      <span>
                        Because {props.audience === 'admin' ? `${props.provider.firstName}` : 'you'} brought{' '}
                        {client.firstName} to Tava, you're seeing increased rates
                      </span>
                    ) : (
                      'Tava incurs costs to acquire this client, so your payout is lower than clients you bring on your own.'
                    )
                  }
                >
                  <span className="v-align">
                    <IconInfo className="ml-xs" color={borderGrey} size={20} />
                  </span>
                </Tooltip>
              )}
            </h3>

            <dl className="unstyled-list text-secondary m-0">
              {payoutRates.payouts.map(payoutDetail => (
                <div key={payoutDetail.description} className="v-align mt-xs">
                  <dt>{payoutDetail.description}</dt>
                  <dd className="ml-auto text-medium">
                    {numberService.centsToFormattedDollars(payoutDetail.amount, { maximumFractionDigits: 2 })}
                  </dd>
                </div>
              ))}
            </dl>

            {payoutRates.paymentType === ProviderPayoutPaymentType.Cash && payoutRates.isSelfReferred && (
              <GhostButton variant="secondary" className="mt-3" onClick={() => setIsDiscountModalOpen(true)}>
                {payoutRates.hasDiscountedRate ? 'Adjust' : 'Set'} discount
              </GhostButton>
            )}
          </>
        )}
      </SidebarPanel>

      <ClientDiscountModal
        client={client}
        providerId={providerId}
        isOpen={isDiscountModalOpen}
        onUpdate={refetchProviderPayoutRates}
        onClose={() => setIsDiscountModalOpen(false)}
      />
    </>
  )
}
