import moment from 'moment'
import { useEffect, useMemo } from 'react'
import { Navigate, useNavigate, useParams } from 'react-router-dom'

import {
  useAppointmentForVideoQuery,
  usePatientDetailsForLiveSessionQuery,
  useProviderMeForVideoQuery,
  useVideoTokenInfoQuery,
} from '@nuna/api'
import { useTitle } from '@nuna/common'
import { routeService } from '@nuna/core'
import { DailyVideoScene, TavaVideoUser } from '@nuna/sundance'
import { supportService } from '@nuna/telemetry'
import { toast } from '@nuna/tunic'

import { environment } from '../../environments/environment'

export function ProviderVideo() {
  useTitle('Appointment')
  const navigate = useNavigate()
  const { appointmentId = '' } = useParams<{ appointmentId: string }>()
  const { data: videoData, error: videoDataError } = useVideoTokenInfoQuery({
    variables: { appointmentId, useDaily: true },
    fetchPolicy: 'network-only',
  })
  const { data: providerData } = useProviderMeForVideoQuery()
  const { data: appointmentData } = useAppointmentForVideoQuery({ variables: { appointmentId } })

  const patientId = videoData?.videoInfoForAppointment.patientId ?? ''
  const providerId = videoData?.videoInfoForAppointment.providerId ?? ''
  const patientAutonotesEnabled = videoData?.videoInfoForAppointment.patientAutonotesEnabled ?? true
  const providerAutonotesEnabled = videoData?.videoInfoForAppointment.providerAutonotesEnabled ?? false

  const { data: patientData } = usePatientDetailsForLiveSessionQuery({
    variables: { id: patientId },
    skip: !patientId,
    fetchPolicy: 'cache-and-network',
  })

  useEffect(() => {
    if (videoDataError && navigate) {
      console.error('Error fetching video data', videoDataError)
      toast.urgent(
        'There was an error connecting to the video call. Try loading again from your schedule on the home screen.',
        {
          action: { onClick: () => navigate('/'), buttonText: 'Go to home screen' },
        },
      )
    }
  }, [videoDataError, navigate])

  const expectedUsers: TavaVideoUser[] = useMemo(() => {
    if (!patientData) return []

    return [
      {
        ...patientData.patient,
        loginId: patientData.patient.login.id,
        isProvider: false,
        patientId,
        initialAutonotesEnabled: patientAutonotesEnabled,
      },
    ]
  }, [patientAutonotesEnabled, patientData, patientId])

  const localUser: TavaVideoUser | undefined = useMemo(() => {
    if (!providerData) return undefined

    return {
      ...providerData.providerMe,
      isProvider: true,
      providerId,
      initialAutonotesEnabled: providerAutonotesEnabled,
    }
  }, [providerAutonotesEnabled, providerData, providerId])

  const endTime = appointmentData?.internalAppointment?.endDatetime
  if (environment.production && endTime && moment(endTime).add(1, 'hour').isBefore(moment())) {
    return <Navigate to={routeService.videoSession()} />
  }

  if (!videoData || !patientData || !providerData || !localUser) return null

  const videoToken = videoData.videoInfoForAppointment.videoToken
  const chatToken = videoData.videoInfoForAppointment.chatToken
  const roomUrl = videoData.videoInfoForAppointment.roomUrl

  if (!roomUrl) {
    // if daily was requested, roomUrl should always have a value. This is here as a safety check
    toast.urgent(
      `Video call is not available at this time. Please contact ${supportService.supportEmails.providerSupport}.`,
    )
    console.error('Did not receive roomUrl for Daily call')
    return null
  }

  return (
    <DailyVideoScene
      appointmentId={appointmentId}
      roomUrl={roomUrl}
      videoToken={videoToken}
      localUser={localUser}
      expectedUsers={expectedUsers}
      chatToken={chatToken}
    />
  )
}
