import { NavLink } from 'react-router-dom'

import { ViewLinkAndPreview } from '@nuna/api'
import { useEnvironmentContext } from '@nuna/environment'

import { HistorySessionItemProps, SessionItem } from './SessionItem'

export function NoShowSessionItem({ historyItem, showDate }: HistorySessionItemProps) {
  const meta = historyItem.meta as ViewLinkAndPreview
  const { commonRoutes } = useEnvironmentContext()
  const path = meta.appointmentId ? commonRoutes.adminSessionDetail(meta.appointmentId) : ''

  const sessionItem = (
    <SessionItem
      showDateOutside
      card={false}
      date={showDate && historyItem.date}
      title={historyItem.name}
      text={meta.preview}
    />
  )

  return path ? (
    <NavLink className="block my-2 no-underline" to={path}>
      {sessionItem}
    </NavLink>
  ) : (
    sessionItem
  )
}
