import { styled } from '@mui/material'
import { ReactNode, useState } from 'react'

import { IconButton, IconMaximize, IconMinimize, tealSet } from '@nuna/tunic'

interface Props {
  children: ReactNode
}

export function CollapsibleThumbnail({ children }: Props) {
  const [isCollapsed, setIsCollapsed] = useState(false)

  return (
    <Container isCollapsed={isCollapsed} className="text-white v-align h-align">
      {isCollapsed ? <span className="pl-2">My View</span> : children}

      <div className="collapse-toggle relative p-xs">
        <IconButton
          key={isCollapsed ? 'hidden' : 'shown'} // ensures the button re-renders when the state changes so tooltip/focus reset. otherwise looks funky.
          variant="dark"
          tooltip={isCollapsed ? 'Show my view' : 'Hide my view'}
          onClick={() => setIsCollapsed(!isCollapsed)}
        >
          {isCollapsed ? <IconMaximize /> : <IconMinimize />}
        </IconButton>
      </div>
    </Container>
  )
}

const Container = styled('div')<{ isCollapsed: boolean }>`
  background-color: ${tealSet[70].hex};
  border-radius: var(--border-radius);
  overflow: hidden;
  position: relative;

  ${({ isCollapsed }) => {
    if (!isCollapsed) {
      return `
        .collapse-toggle {
          display: none;
          z-index: 1;
          position: absolute;
        }

        &::after {
          background-color: rgba(0, 49, 56, 0.4);
          content: '';
          display: block;
          height: 100%;
          left: 0;
          position: absolute;
          top: 0;
          width: 100%;
          opacity: 0;
          transition: opacity 0.3s;
        }

        &:hover {
          .collapse-toggle {
            display: flex;
            align-items: center;
            justify-content: center;
          }

          &::after {
            opacity: 1;

            @supports (backdrop-filter: blur(10px)) {
              backdrop-filter: blur(10px);
            }
          }
        }
      `
    } else {
      return `
        .collapse-toggle {
          display: block;
          position: static;
          margin-left: auto;
        }
      `
    }
  }}
`
