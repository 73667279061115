import { styled } from '@mui/material'
import { Autocomplete, TextField } from '@mui/material'
import moment from 'moment-timezone'
import { useState } from 'react'

import { useIsAdmin } from '@nuna/auth'
import { TimeZoneControl } from '@nuna/common'
import { timeService } from '@nuna/core'
import { IconButton, IconClose, TextButton, borderGrey, greySet } from '@nuna/tunic'

import { useAvailabilityContext } from '../context/AvailabilityContextProvider'

const { timezones } = timeService

export function AvailabilityTimezoneDisplay() {
  const isAdmin = useIsAdmin()
  const { timezone, providerHasTimezoneSet, updateTimezone, updateLoading } = useAvailabilityContext()
  const [isEditing, setIsEditing] = useState(false)

  const handleTimezoneChange = async (value: string | null) => {
    if (!value) {
      return
    }

    await updateTimezone(value)
    setIsEditing(false)
  }

  if (isEditing) {
    return (
      <TimezoneSelectWrapper className="ml-1">
        <Autocomplete
          loading={updateLoading}
          className="autocomplete"
          onChange={(_event, value) => handleTimezoneChange(value)}
          id="timezone-select"
          options={timezones}
          value={timezone}
          renderInput={params => <TextField name="timezone" {...params} label="Timezone" />}
        />
        <IconButton small className="close-button" tooltip="Cancel" onClick={() => setIsEditing(false)}>
          <IconClose size={12} />
        </IconButton>
      </TimezoneSelectWrapper>
    )
  }

  return (
    <span className="timezone">
      Times shown in {isAdmin ? <TimeZoneControl className="ml-1" /> : moment.tz(timezone).format('z')}
      {!providerHasTimezoneSet && (
        <TextButton className="ml-1" onClick={() => setIsEditing(true)}>
          Not your timezone? Click to update.
        </TextButton>
      )}
    </span>
  )
}

const TimezoneSelectWrapper = styled('span')`
  background-color: ${greySet[5].hex};
  border: 1px solid ${borderGrey};
  border-radius: var(--border-radius);
  padding: var(--margin-3) var(--margin-2) var(--margin-2) var(--margin-2);
  position: relative;
  width: 300px;

  .autocomplete {
    width: 100%;
  }

  .close-button {
    position: absolute;
    top: 0;
    right: 0;
  }
`
