import { noop } from 'lodash'
import { useState } from 'react'
import { useNavigate } from 'react-router-dom'

import { AppointmentForNotificationFragment, usePatientContextQuery } from '@nuna/api'
import { useAuthDataContext } from '@nuna/auth'
import { useAppointmentDrawerSearchParams } from '@nuna/common'
import { errorService } from '@nuna/core'
import { useCapProgressQuery } from '@nuna/coverage'
import { CustomToastOptions, Skeleton, toast } from '@nuna/tunic'

import { useMixedAuthProviderQuery } from '../../hooks/useMixedAuthProviderQuery'
import { useRejectInvalidTimeSlot } from '../../hooks/useRejectInvalidTimeSlot'
import { useScheduleWithProvider } from '../../hooks/useScheduleWithProvider'
import { useShowFeePolicy } from '../../hooks/useShowFeePolicy'
import { useSwitchToProvider } from '../../hooks/useSwitchToProvider'
import { AcceptLateFee } from './components/AcceptLateFee'
import { Authorization } from './components/Authorization'
import { CancelPolicyAlert } from './components/CancelPolicyAlert'
import { EmailedParentGuardian } from './components/EmailedParentGuardian'
import { PaymentReview } from './components/PaymentReview'
import { ScheduleAppointmentTimeSlotSelect } from './components/ScheduleAppointmentTimeSlotSelect'
import { SwitchProviderConfirmation } from './components/SwitchProviderConfirmation'

export function ScheduleAppointmentAsClient({
  onAppointmentScheduled = noop,
}: {
  onAppointmentScheduled?: (appointment: AppointmentForNotificationFragment) => void
}) {
  const {
    drawerConfig: {
      scheduleProviderId,
      timeSlot,
      oldProviderId,
      confirmedProviderChange,
      registerThenTimeSlot,
      addressId,
    },
    confirmProviderChange,
  } = useAppointmentDrawerSearchParams()
  const { loggedIn } = useAuthDataContext()
  const navigate = useNavigate()

  useRejectInvalidTimeSlot(timeSlot, scheduleProviderId)
  const showFeePolicyStatus = useShowFeePolicy(scheduleProviderId)
  const { authorizedProvider, publicProvider } = useMixedAuthProviderQuery(scheduleProviderId)
  const { scheduleWithProvider, loading: scheduleWithProviderLoading } = useScheduleWithProvider()
  const { switchToProvider, loading: switchToProviderLoading } = useSwitchToProvider()
  const { data: patientContextData } = usePatientContextQuery({ skip: !loggedIn })
  const patientId = patientContextData?.patientContext.patient.id ?? ''
  const { refetch: refetchCapProgress } = useCapProgressQuery(patientId)

  const [therapyTypeId, setTherapyTypeId] = useState<string | null>(null)

  const provider = authorizedProvider || publicProvider

  if (!loggedIn && (registerThenTimeSlot || timeSlot)) {
    return <Authorization provider={publicProvider} timeSlot={timeSlot} />
  }

  if (!provider) {
    return <Skeleton height={3} />
  }

  if (!timeSlot) {
    return <ScheduleAppointmentTimeSlotSelect provider={provider} />
  }

  if (!authorizedProvider || showFeePolicyStatus === null || patientContextData === undefined) {
    return <Skeleton height={3} />
  }

  if (!patientContextData.patientContext.patient.startIntakePermitted) {
    const parentGuardianEmail = patientContextData.patientContext.patient.parentGuardianEmail
    return <EmailedParentGuardian parentGuardianEmail={parentGuardianEmail || 'Parent/Guardian email is missing'} />
  }

  if (showFeePolicyStatus.needsAcceptance) {
    return (
      <AcceptLateFee
        cancelPolicy={showFeePolicyStatus.cancelPolicy}
        paymentPreference={showFeePolicyStatus.cancelPolicyStatus.paymentPreference}
        provider={authorizedProvider}
        timeSlot={timeSlot}
      />
    )
  }

  if (oldProviderId && !confirmedProviderChange) {
    return (
      <SwitchProviderConfirmation
        newProvider={authorizedProvider}
        timeSlot={timeSlot}
        oldProviderId={oldProviderId}
        onConfirmClick={() => confirmProviderChange()}
      />
    )
  }

  const onSubmit = async () => {
    try {
      if (oldProviderId) {
        const bookingId = await switchToProvider({ provider: authorizedProvider, timeSlot, oldProviderId })
        onAppointmentScheduled({
          startDatetime: timeSlot.start,
          id: bookingId ?? '',
        })
      } else {
        const getAddressId = (addressId: string | undefined | null) => {
          if (addressId === 'VIRTUAL') {
            return undefined
          }
          return addressId ? addressId : undefined
        }
        const bookingId = await scheduleWithProvider({
          provider: authorizedProvider,
          timeSlot,
          therapyTypeId,
          addressId: getAddressId(addressId),
        })
        onAppointmentScheduled({
          startDatetime: timeSlot.start,
          id: bookingId ?? '',
        })
      }
      refetchCapProgress()
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (e: any) {
      const options: CustomToastOptions = {}
      const errorExtensions = errorService.getGraphQLErrorExtensions(e)
      const link = errorExtensions.find((ext?: Record<string, unknown>) => ext?.link)?.link as string
      if (link) {
        options.action = {
          onClick: () => navigate(link),
          buttonText: `Go There`,
        }
        options.duration = Number.POSITIVE_INFINITY
      }
      console.error(e)
      toast.urgent(e.message, options)
    }
  }

  return (
    <PaymentReview
      provider={authorizedProvider}
      timeSlot={timeSlot}
      therapyTypeId={therapyTypeId}
      loading={oldProviderId ? switchToProviderLoading : scheduleWithProviderLoading}
      cancelPolicyAlert={
        showFeePolicyStatus.cancelPolicyStatus ? (
          <CancelPolicyAlert
            provider={authorizedProvider}
            timeSlot={timeSlot}
            cancelPolicyStatus={showFeePolicyStatus.cancelPolicyStatus}
            className="mt-3"
          />
        ) : undefined
      }
      onTherapyTypeChange={setTherapyTypeId}
      onSubmit={onSubmit}
    />
  )
}
