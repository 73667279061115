export enum Gender {
  Female = 'FEMALE',
  GenderNonConforming = 'GENDER_NON_CONFORMING',
  Male = 'MALE',
  PreferNotToAnswer = 'PREFER_NOT_TO_ANSWER',
  QuestioningGender = 'QUESTIONING_GENDER',
  TransFemale = 'TRANS_FEMALE',
  TransMale = 'TRANS_MALE',
}

export enum Pronouns {
  HerHers = 'HER_HERS',
  HerThey = 'HER_THEY',
  HimHis = 'HIM_HIS',
  HimThey = 'HIM_THEY',
  TheyThem = 'THEY_THEM',
}
