import { styled } from '@mui/material'
import { HTMLAttributes } from 'react'
import ReactPlayer from 'react-player'

import { VideoAskResponse } from '@nuna/api'
import { Box } from '@nuna/tunic'

import videoPlaceholderSrc from './img/video-placeholder.png'
import videoProcessingSrc from './img/video-processing.png'

interface Props extends HTMLAttributes<HTMLDivElement> {
  video?: Pick<VideoAskResponse, 'mediaUrl'>
  videoRecorded?: boolean
}
export function VideoBioEditDisplay({ video, videoRecorded, ...props }: Props) {
  return (
    <div {...props}>
      {(() => {
        if (video) {
          return (
            <VideoPlayer
              url={video.mediaUrl}
              controls={true}
              style={{ borderRadius: '12px' }}
              width="100%"
              height="240px"
            />
          )
        }

        if (videoRecorded === false) {
          return <Box sx={{ maxWidth: '100%' }} component="img" src={videoPlaceholderSrc} alt="Video placeholder" />
        }

        if (videoRecorded === true) {
          return <Box sx={{ maxWidth: '100%' }} component="img" src={videoProcessingSrc} alt="Video processing" />
        }

        return null
      })()}
    </div>
  )
}

const VideoPlayer = styled(ReactPlayer)`
  video {
    border-radius: 12px;
  }
`
