import * as Yup from 'yup'
import { Formik } from 'formik'
import mixpanel from 'mixpanel-browser'

import {
  BasicProviderFragment,
  CancelPolicyFragment,
  PatientPaymentMethodFragment,
  PaymentPreference,
  useAcceptCancelPolicyMutation,
  usePatientContextQuery,
} from '@nuna/api'
import { cancelPolicyService, errorService, formService, paymentMethodService } from '@nuna/core'
import { CreditCard, PaymentMethodSelect, useClientPaymentMethods } from '@nuna/payment-methods'
import { Skeleton, makeTypographyComponent, toast } from '@nuna/tunic'

import { CollapsiblePolicyDetails } from './components/CollapsiblePolicyDetails'

const { composeHelperTextWithError } = formService

interface LateFeePolicyFormValues {
  card: PatientPaymentMethodFragment | null
  acceptedPolicy: boolean
}

interface LateFeePolicyFormProps {
  cancelPolicy: CancelPolicyFragment
  provider: BasicProviderFragment
  renderButtons: (loading: boolean) => JSX.Element
  onSubmitSuccess?: () => void
  paymentPreference?: PaymentPreference | null
}

export function LateFeePolicyForm({
  cancelPolicy,
  provider,
  renderButtons,
  onSubmitSuccess,
  paymentPreference,
}: LateFeePolicyFormProps) {
  const { data: patientContextData } = usePatientContextQuery()
  const patient = patientContextData?.patientContext.patient

  const [acceptCancelPolicyMutation, { loading }] = useAcceptCancelPolicyMutation({
    refetchQueries: ['CancelPolicyStatus', 'PatientCoverage'],
  })
  const {
    paymentMethods,
    defaultForFees,
    savePaymentMethod,
    queryResult: { loading: paymentMethodsLoading },
    saveMutationResult: { loading: savePaymentMethodLoading },
  } = useClientPaymentMethods(patient?.id)

  const handleSubmit = async (values: LateFeePolicyFormValues) => {
    try {
      if (!provider.id || !patient?.id) throw new Error('Unexpected error: Missing providerId or patientId')

      /**  If the specified card was not previously set as the default for fees then mark it as default going forward.
       * Note: this is a rare edge case that will only happen for some people during rollout */
      if (values.card && !values.card.defaultForFees) {
        await savePaymentMethod({
          id: values.card.id,
          patientId: values.card.patientId,
          isHsa: values.card.isHsa,
          isUserAcknowledged: true,
          defaultForFees: true,
        })
      }

      const response = await acceptCancelPolicyMutation({
        variables: {
          providerId: provider.id,
          clientId: patient.id,
        },
      })

      if (!response.data?.acceptCancelPolicy.acceptedDate) {
        throw new Error('An error occured while accepting the late fee policy. Please try again.')
      }
      onSubmitSuccess?.()

      mixpanel.track('accepted late policy', { cancelPolicyId: cancelPolicy?.id, providerId: provider.id })
    } catch (e) {
      console.error(e)
      toast.urgent(errorService.transformGraphQlError(e, 'Unable to accept late fee policy. Please try again.'))
    }
  }

  if (paymentMethodsLoading) return <Skeleton height={4} />

  return (
    <Formik
      initialValues={buildInitialValues(cancelPolicy, defaultForFees ?? null)}
      onSubmit={handleSubmit}
      validationSchema={buildValidationSchema(cancelPolicy)}
    >
      {({ handleSubmit, values, errors, touched, setFieldValue }) => (
        <form onSubmit={handleSubmit}>
          <div className="mb-0">
            <h2 className="h5 mb-3">{provider.firstName}'s policy</h2>

            <SmallHeading className="mb-1">Incidental fees</SmallHeading>
            <p className="italic caption text-secondary">Only applicable if you cancel too late or don't show up.</p>

            <CollapsiblePolicyDetails
              provider={provider}
              cancelPolicy={cancelPolicy}
              paymentPreference={paymentPreference}
            />

            {!cancelPolicyService.isZeroDollarPolicy(cancelPolicy) && (
              <>
                <SmallHeading className="mt-4">Incidentals card</SmallHeading>
                <PaymentMethodSelect
                  defaultFor="fees"
                  defaultToFormIfEmpty
                  savePaymentMethod={savePaymentMethod}
                  value={values.card}
                  paymentMethods={paymentMethods}
                  onChange={card => {
                    setFieldValue('card', card)
                  }}
                  loading={paymentMethodsLoading}
                  patientId={patient?.id || ''}
                  {...composeHelperTextWithError('', errors.card, touched.card)}
                />
              </>
            )}
          </div>

          {renderButtons(loading || savePaymentMethodLoading)}
        </form>
      )}
    </Formik>
  )
}

const SmallHeading = makeTypographyComponent('body text-bold', 'h3')

function buildValidationSchema(cancelPolicy: CancelPolicyFragment) {
  return Yup.object().shape({
    card: cancelPolicyService.isZeroDollarPolicy(cancelPolicy)
      ? Yup.object<CreditCard>().nullable()
      : Yup.object<CreditCard>()
          .nullable()
          .required("You'll need to select a card to continue")
          .test('is-expired', "You'll need to add a new card to continue", (card: CreditCard | null) =>
            paymentMethodService.isCardValid(card),
          ),
  })
}

function buildInitialValues(cancelPolicy: CancelPolicyFragment, card: PatientPaymentMethodFragment | null) {
  return {
    card,
    acceptedPolicy: cancelPolicyService.isZeroDollarPolicy(cancelPolicy) ? true : false,
  }
}
