import { useLayoutEffect, useState } from 'react'

export function useWindowWidth() {
  const [width, setWidth] = useState(window.innerWidth)

  useLayoutEffect(() => {
    let timeout: number
    function updateWidth() {
      if (timeout) {
        window.cancelAnimationFrame(timeout)
      }

      timeout = window.requestAnimationFrame(() => setWidth(window.innerWidth))
    }

    window.addEventListener('resize', updateWidth)

    return () => window.removeEventListener('resize', updateWidth)
  }, [])

  return width
}
