import { Ref, forwardRef } from 'react'

import { Confirm, ConfirmRef } from '@nuna/tunic'

export const MisMatchTimeZoneConfirm = forwardRef<ConfirmRef>((_props, ref: Ref<ConfirmRef> | undefined) => {
  return (
    <Confirm ref={ref}>
      You are about to adjust a provider's availibility using a time zone other than the provider's. Do you wish to
      continue?
    </Confirm>
  )
})
