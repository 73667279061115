import '@fullcalendar/react/dist/vdom'

import FullCalendar, { EventSourceInput } from '@fullcalendar/react'

import { Moment } from 'moment-timezone'
import { useEffect, useMemo, useState } from 'react'

import { ProviderCalendarDebugEventsQuery, useProviderCalendarDebugEventsQuery } from '@nuna/api'
import { Checkbox, IconBug, IconButton, Menu, MenuDivider } from '@nuna/tunic'

type ProviderCalendar = ProviderCalendarDebugEventsQuery['providerCalendarDebugEvents'][0]

interface Props {
  calendarRef: React.RefObject<FullCalendar>
  date: { start: Moment; end: Moment } | null
  isEnabled: boolean
  onToggle: (isEnabled: boolean) => void
  providerId: string
}

export function CalendarSyncDebugToggle({ isEnabled, providerId, date, calendarRef, onToggle }: Props) {
  const [anchorEl, setAnchorEl] = useState<HTMLElement>()
  const [selectedCalendarIds, setSelectedCalendarIds] = useState<string[]>([])
  const { data } = useProviderCalendarDebugEventsQuery({
    variables: {
      providerId,
      start: date?.start,
      end: date?.end,
    },
    skip: !date || !isEnabled, // very important that we don't hit this query unnecessarily
  })

  const shownEvents: EventSourceInput = useMemo(() => {
    const selectedEvents = (
      data?.providerCalendarDebugEvents.filter(calendar => selectedCalendarIds.includes(calendar.id)) ?? []
    ).flatMap(calendar =>
      calendar.debugEvents.map(event => {
        return {
          id: event.id,
          title: `${event.isTavaEvent ? '(Tava) ' : ''}${calendar.name}`,
          start: event.start,
          end: event.end,
          groupId: `debug-${calendar.id}`,
        }
      }),
    )

    return {
      events: selectedEvents,
      color: 'var(--primary-15)',
    }
  }, [selectedCalendarIds, data])

  useEffect(() => {
    const eventSource = calendarRef.current?.getApi().addEventSource(shownEvents)

    return () => eventSource?.remove()
  }, [calendarRef, shownEvents])

  const calendars = data?.providerCalendarDebugEvents ?? []

  return (
    <>
      <IconButton
        onClick={e => setAnchorEl(e.currentTarget)}
        className="mr-1"
        tooltip="Debug synced calendars"
        variant="secondary"
      >
        <IconBug />
      </IconButton>

      <Menu
        open={!!anchorEl}
        anchorEl={anchorEl}
        onClose={() => setAnchorEl(undefined)}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
      >
        <div className="flex-column" style={{ gap: '0.5rem' }}>
          <Checkbox checked={isEnabled} onChange={e => onToggle(e.currentTarget.checked)}>
            Enable calendar sync debug
          </Checkbox>

          {isEnabled && (
            <>
              <MenuDivider style={{ marginBottom: '0.375rem' }} />
              {data === undefined ? 'Loading...' : calendars.length === 0 ? 'No calendars' : null}
              {calendars.map(calendar => (
                <Checkbox
                  key={calendar.id}
                  checked={selectedCalendarIds.includes(calendar.id)}
                  onChange={e =>
                    e.currentTarget.checked
                      ? setSelectedCalendarIds(prev => [...prev, calendar.id])
                      : setSelectedCalendarIds(prev => prev.filter(id => id !== calendar.id))
                  }
                >
                  {calendar.name}
                  {getStatusString(calendar)}
                </Checkbox>
              ))}
            </>
          )}
        </div>
      </Menu>
    </>
  )
}

function getStatusString(calendar: ProviderCalendar) {
  if (calendar.isReadingFrom && calendar.isWritingTo) return ' (read/write)'
  if (calendar.isReadingFrom && !calendar.isWritingTo) return ' (read)'
  if (!calendar.isReadingFrom && calendar.isWritingTo) return ' (write)'

  return ''
}
