import { IconProps } from './IconProps'

export function IconVideo({ size = 24, color = 'currentColor', ...props }: IconProps) {
  return (
    <svg width={size} height={size} viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M36.802 18.9063V11.1914C36.802 9.78524 35.6515 8.63477 34.2454 8.63477H3.56613C2.15999 8.63477 1.00952 9.78524 1.00952 11.1914V36.7574C1.00952 38.1635 2.15999 39.314 3.56613 39.314H34.2454C35.6515 39.314 36.802 38.1635 36.802 36.7574V29.0425L43.7782 35.779C43.8589 35.8569 43.945 35.9299 44.0424 35.9856C45.3524 36.7355 47.0284 35.7962 47.0284 34.2451V13.7037C47.0284 12.1526 45.3524 11.2133 44.0424 11.9631C43.945 12.0189 43.8589 12.0919 43.7782 12.1698L36.802 18.9063Z"
        fill={color}
      />
    </svg>
  )
}
