import { styled } from '@mui/material'
import { ImgHTMLAttributes } from 'react'

import { ProviderDirectoryType } from '@nuna/api'
import { psychologyTodayLogo, zocdocLogo } from '@nuna/tunic'

const directoryToLogoMap = {
  [ProviderDirectoryType.PsychologyToday]: psychologyTodayLogo,
  [ProviderDirectoryType.Zocdoc]: zocdocLogo,
}

interface Props extends ImgHTMLAttributes<HTMLImageElement> {
  directoryType: ProviderDirectoryType
}

export function DirectoryLogo(props: Props) {
  return <DirectoryImage {...props} src={directoryToLogoMap[props.directoryType]} alt={props.directoryType} />
}

const DirectoryImage = styled('img')`
  width: 26px;
  height: 26px;
  border-radius: 20px;
`
