import { noop } from 'lodash'

import { specialtiesService } from '@nuna/core'

import { SpecialtiesSectionBase, SpecialtiesSectionProps } from './SpecialtiesSectionBase'

export function GenderFocusSection({ specialties, id, refetch = noop }: SpecialtiesSectionProps) {
  return (
    <SpecialtiesSectionBase
      headingText="Client Focus - Gender"
      description="We'll use your selection to match you with your ideal clients."
      selectedSpecialties={specialties}
      specialtiesFilter={specialtiesService.getProviderPopulationSpecialtiesOnlyGender}
      refetch={refetch}
      providerId={id}
      isRadio={true}
    />
  )
}
