import { Scalars } from './common.types'

export enum ProviderStatus {
  AcceptingClients = 'ACCEPTING_CLIENTS',
  Denied = 'DENIED',
  NotAcceptingClients = 'NOT_ACCEPTING_CLIENTS',
  Offboarding = 'OFFBOARDING',
  Onboarding = 'ONBOARDING',
  PausedOnboarding = 'PAUSED_ONBOARDING',
  Terminated = 'TERMINATED',
  UnderReview = 'UNDER_REVIEW',
}

export type Provider = {
  id: Scalars['ID']
  type: Scalars['String']
}
