import { styled } from '@mui/material'
import { HTMLAttributes, ReactNode } from 'react'

import { BelowTablet, Phone } from '@nuna/tunic'

import { Illustration } from './Illustration'

interface Props extends HTMLAttributes<HTMLDivElement> {
  textBlock: ReactNode
  illustrationStyle?: React.CSSProperties
  illustrationSrc: string
  topOffset?: number
  leftOffset?: number
}
export function ExploreFirstEmptyState({
  className,
  illustrationSrc,
  textBlock,
  illustrationStyle,
  topOffset,
  leftOffset,
}: Props) {
  return (
    <Section className={className} topOffset={topOffset} leftOffset={leftOffset}>
      <EmptyIllustration src={illustrationSrc} className="mr-2" style={illustrationStyle ?? { maxWidth: '270px' }} />
      <TextWrap>{textBlock}</TextWrap>
    </Section>
  )
}

const EmptyIllustration = styled(Illustration)`
  @media (${BelowTablet}) {
    margin: auto;
  }

  @media (${Phone}) {
    display: none;
  }
`

const Section = styled('section')<{ topOffset?: number; leftOffset?: number }>`
  height: ${props => (props.topOffset ? `calc(100% - ${props.topOffset}px)` : '100%')};
  position: absolute;
  top: ${props => (props.topOffset ? `${props.topOffset}px` : '0')};
  left: ${props => (props.leftOffset ? `${props.leftOffset}px` : '0')};
  right: 0;
  margin: auto;
  z-index: 999;
  background: rgba(255, 255, 255, 0.9);
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;

  @media (${BelowTablet}) {
    display: block;
    padding-top: 35px;
  }
  @media (${Phone}) {
    padding-top: 20px;
  }
`

const TextWrap = styled('div')`
  margin-left: -30px;

  @media (${BelowTablet}) {
    margin: auto;
    width: 75%;
  }

  h2 {
    max-width: 480px;
  }
`
