import { capitalize, startCase } from 'lodash'
import { HTMLAttributes, RefObject, useMemo } from 'react'
import { CellProps, Row } from 'react-table'

import {
  AddressEnrollmentStatus,
  InsurancePayersProviderAcceptsQuery,
  PotentialInsurancePayerCompensationsQuery,
  ProviderEnrollmentStatus,
} from '@nuna/api'
import { addressService } from '@nuna/core'
import { DataTable, DataTableColumn, DataTableState } from '@nuna/data-table'
import { Skeleton, StatusLabel } from '@nuna/tunic'

import { StateList } from './StateList'

type RowData = InsurancePayersProviderAcceptsQuery['insurancePayersProviderAccepts'][0] & {
  maxRate?: number
  minRate?: number
}
type CompData = PotentialInsurancePayerCompensationsQuery | undefined
interface Props extends HTMLAttributes<HTMLDivElement> {
  rowData: RowData[]
  loading: boolean
  compData: CompData
}
type ExpandedCell = CellProps<RowData, ProviderEnrollmentStatus> & {
  isExpanded?: boolean
}
const COLUMNS: DataTableColumn<RowData>[] = [
  { Header: 'Payer', accessor: 'name', sortType: (a, b) => a.original.name.localeCompare(b.original.name) },
  {
    Header: 'Status',
    accessor: 'enrollmentStatus',
    sortType: (a, b) => a.original.enrollmentStatus.localeCompare(b.original.enrollmentStatus),
    Cell: ({ isExpanded, cell: { row, value } }: ExpandedCell) => {
      const addressEnrollments = [...row.original.addressEnrollments]
      const renderInsuranceStatuses = () => {
        const enrollmentStatuses = addressEnrollments.reduce((final: Record<string, number>, cur) => {
          const cleanedStatus = cur.enrollmentStatus === AddressEnrollmentStatus.Enrolled ? 'enrolled' : 'pending'
          if (final[cleanedStatus]) {
            final[cleanedStatus] += 1
          } else {
            final[cleanedStatus] = 1
          }
          return final
        }, {})

        return Object.entries(enrollmentStatuses).map(([key, value], idx) => {
          return (
            <span key={key}>
              {value} {key}
              {idx < Object.keys(enrollmentStatuses).length - 1 && ', '}
            </span>
          )
        })
      }
      const labelIntent = (isExpanded: boolean | undefined) => {
        if (isExpanded) {
          const label = renderInsuranceStatuses()
          return Object.entries(label).every(([key]) => key === 'enrolled') ? 'information' : 'default'
        } else {
          return value === ProviderEnrollmentStatus.Enrolled ? 'information' : 'default'
        }
      }
      return (
        <StatusLabel intent={labelIntent(isExpanded)}>
          {isExpanded ? renderInsuranceStatuses() : capitalize(startCase(value))}
        </StatusLabel>
      )
    },
  },
  {
    Header: 'Available in States',
    accessor: 'states',
    Cell: ({ value, row }) => {
      const emptyState =
        row.original.enrollmentStatus === ProviderEnrollmentStatus.OptedOut
          ? 'Not Applicable'
          : 'Not available in licensed states'
      return <StateList states={value} maxStatesBeforeTruncate={3} displayIfEmpty={emptyState} />
    },
  },
]

const INITIAL_TABLE_STATE: DataTableState<RowData> = {
  sortBy: [
    { id: 'enrollmentStatus', desc: false },
    { id: 'name', desc: false },
  ],
}

export function PayersAcceptedByProviderTable({ loading, rowData, compData, ...props }: Props) {
  const compiledData = useMemo(() => {
    if (!compData) return rowData
    return rowData.map(row => {
      const compRow = compData.potentialInsurancePayerCompensations.find(it => it.id === row.id)
      return {
        ...row,
        minRate: compRow?.minRate,
        maxRate: compRow?.maxRate,
      }
    })
  }, [compData, rowData])

  if (loading) {
    return (
      <div {...props}>
        <Skeleton height={10} />
      </div>
    )
  }

  return (
    <div {...props}>
      <DataTable
        columns={COLUMNS}
        rowData={compiledData}
        initialState={INITIAL_TABLE_STATE}
        loading={loading}
        showExpandedRow={row => row.original.addressEnrollments.length > 0}
        expandedRow={expandedRowProps => <AddressRow {...expandedRowProps} />}
      />
    </div>
  )
}

function AddressRow({ row, triggerRowRef }: { row: Row<RowData>; triggerRowRef: RefObject<HTMLTableRowElement> }) {
  const { addressEnrollments } = row.original

  const rowWidths = (() => {
    const widths: number[] = []
    triggerRowRef.current?.querySelectorAll('td').forEach(td => widths.push(td.getBoundingClientRect().width))
    return widths
  })()

  return (
    <div className="py-1">
      {addressEnrollments.map((addressEnrollment, idx) => (
        <div className="v-align pb-2" key={[addressEnrollment.address.id, idx].join('')}>
          <div style={{ width: rowWidths[0] ?? 50 }}></div>
          <div style={{ width: rowWidths[1] ?? 200 }}>{addressService.formatAddress(addressEnrollment.address)}</div>
          <span>
            <StatusLabel
              intent={
                addressEnrollment.enrollmentStatus === AddressEnrollmentStatus.Enrolled ? 'information' : 'default'
              }
            >
              {startCase(addressEnrollment.enrollmentStatus)}
            </StatusLabel>
          </span>
        </div>
      ))}
    </div>
  )
}
