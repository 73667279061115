import { styled } from '@mui/material'
import { Popover as MUIPopover, PopoverProps as MUIPopoverProps } from '@mui/material'

import { greySet } from '../../styles/colorSets'
import { BaseShadowDepth, shadowDepth } from '../../styles/shadows'
import { styledUtils } from '../../utils/styled'

const { transientPropOptions } = styledUtils

export interface PopoverProps extends Omit<MUIPopoverProps, 'elevation'> {
  depth?: BaseShadowDepth
  padding?: string
}

export function Popover({ depth = 8, padding = 'var(--spacing-4)', ...props }: PopoverProps) {
  return <StyledPopover elevation={depth} $padding={padding} {...props} />
}

const StyledPopover = styled(MUIPopover, transientPropOptions)<{ $padding: string }>`
  .MuiPopover-paper {
    border: 1px solid ${greySet[15].hex};
    border-radius: var(--border-radius);
    padding: ${({ $padding }) => $padding};
  }

  .MuiPaper-elevation8 {
    box-shadow: ${shadowDepth(8)};
  }

  .MuiPaper-elevation4 {
    box-shadow: ${shadowDepth(4)};
  }

  .MuiPaper-elevation1 {
    box-shadow: ${shadowDepth(1)};
  }
`
