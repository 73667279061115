import hexRgb from 'hex-rgb'
import rgbHex from 'rgb-hex'

import { grey, plum, salmon, teal, white as whitePalette, yellow } from './theme/palette'

interface RGB {
  red: number
  green: number
  blue: number
}

export class Color {
  rgbColor: RGB

  constructor(color: RGB | string) {
    if (typeof color === 'string') {
      this.rgbColor = hexRgb(color)
    } else {
      this.rgbColor = color
    }
  }

  transparency(alpha: number) {
    if (alpha < 0 || alpha > 1) {
      throw new Error('Invalid alpha value')
    }

    return `rgba(${this.rgbColor.red}, ${this.rgbColor.green}, ${this.rgbColor.blue}, ${alpha})`
  }

  get hex() {
    return `#${rgbHex(this.rgbColor.red, this.rgbColor.green, this.rgbColor.blue)}`
  }

  get rgb() {
    return `rgb(${this.rgbColor.red}, ${this.rgbColor.green}, ${this.rgbColor.blue})`
  }

  toString() {
    return this.hex
  }
}

class ColorWithHover extends Color {
  hoverColor: RGB

  constructor(color: RGB | string, hoverColor: RGB | string) {
    super(color)

    if (typeof hoverColor === 'string') {
      this.hoverColor = hexRgb(hoverColor)
    } else {
      this.hoverColor = hoverColor
    }
  }

  /** @deprecated For hover colors, use the next darkest shade in the color palette. e.g. if the unhovered color is teal['500'], use teal['600'] for the hover */
  get hover() {
    return `rgb(${this.hoverColor.red}, ${this.hoverColor.green}, ${this.hoverColor.blue})`
  }
}

export interface ColorTintSet {
  20: string
  40: string
}

export interface ColorSet {
  0?: Color
  5?: Color
  15: Color
  30: Color
  50: Color
  70: Color
  80: Color
  90?: Color
  100?: Color
  primary: Color
}

/** @deprecated Use theme.palette.salmon from MUI Theme */
export const salmonSet = {
  /** @deprecated Use theme.palette.salmon['100'] from MUI Theme */
  5: new Color(salmon[100]),
  /** @deprecated Use theme.palette.salmon['200'] from MUI Theme */
  15: new Color(salmon[200]),
  /** @deprecated Use theme.palette.salmon['300'] from MUI Theme */
  30: new Color(salmon[300]),
  /** @deprecated Use theme.palette.salmon['500'] from MUI Theme */
  50: new ColorWithHover(salmon[500], '#ED6967'),
  /** @deprecated Use theme.palette.salmon['500'] from MUI Theme */
  get 70() {
    return this[50]
  },
  /** @deprecated Use theme.palette.salmon['700'] from MUI Theme */
  80: new Color(salmon[700]),
  /** @deprecated Use theme.palette.salmon['800'] from MUI Theme */
  90: new Color(salmon[800]),
  tint: {
    /** @deprecated Use theme.palette.salmon['A100'] from MUI Theme */
    20: salmon.A100,
    /** @deprecated Use theme.palette.salmon['A200'] from MUI Theme */
    40: salmon.A200,
  },
  /** @deprecated Use theme.palette.salmon['500'] from MUI Theme */
  get primary() {
    return this[50]
  },
}

/** @deprecated Use theme.palette.teal from MUI Theme */
export const tealSet = {
  /** @deprecated Use theme.palette.teal['100'] from MUI Theme */
  5: new Color(teal[100]),
  /** @deprecated Use theme.palette.teal['200'] from MUI Theme */
  15: new Color(teal[200]),
  /** @deprecated Use theme.palette.teal['300'] from MUI Theme */
  30: new Color(teal[300]),
  /** @deprecated Use theme.palette.teal['500'] from MUI Theme */
  50: new ColorWithHover(teal[500], '#33A3A3'),
  /** @deprecated Use theme.palette.teal['600'] from MUI Theme */
  70: new ColorWithHover(teal[600], '#057483'),
  /** @deprecated Use theme.palette.teal['700'] from MUI Theme */
  80: new Color(teal[700]),
  /** @deprecated Use theme.palette.teal['800'] from MUI Theme */
  90: new Color(teal[800]),
  /** @deprecated Use theme.palette.teal['900'] from MUI Theme */
  100: new Color(teal[900]),
  tint: {
    /** @deprecated Use theme.palette.teal['A100'] from MUI Theme */
    20: teal.A100,
    /** @deprecated Use theme.palette.teal['A200'] from MUI Theme */
    40: teal.A200,
    /** @deprecated Use theme.palette.teal['A400'] from MUI Theme */
    60: teal.A400,
  },
  /** @deprecated Use theme.palette.teal['600'] from MUI Theme */
  get primary() {
    return this[70]
  },
}

/** @deprecated Use theme.palette.plum from MUI Theme */
export const plumSet = {
  /** @deprecated Use theme.palette.plum['100'] from MUI Theme */
  5: new Color(plum[100]),
  /** @deprecated Use theme.palette.plum['200'] from MUI Theme */
  15: new Color(plum[200]),
  /** @deprecated Use theme.palette.plum['300'] from MUI Theme */
  30: new Color(plum[300]),
  /** @deprecated Use theme.palette.plum['500'] from MUI Theme */
  50: new ColorWithHover(plum[500], '#9C4875'),
  /** @deprecated Use theme.palette.plum['500'] from MUI Theme */
  get 70() {
    return this[50]
  },
  /** @deprecated Use theme.palette.plum['700'] from MUI Theme */
  80: new Color(plum[700]),
  /** @deprecated Use theme.palette.plum['800'] from MUI Theme */
  90: new Color(plum[800]),
  /** @deprecated Use theme.palette.plum['900'] from MUI Theme */
  100: new Color(plum[900]),
  tint: {
    /** @deprecated Use theme.palette.plum['A100'] from MUI Theme */
    20: plum.A100,
    /** @deprecated Use theme.palette.plum['A200'] from MUI Theme */
    40: plum.A200,
  },
  /** @deprecated Use theme.palette.plum['500'] from MUI Theme */
  get primary() {
    return this[50]
  },
}

/** @deprecated Use theme.palette.grey from MUI Theme */
export const greySet = {
  /** @deprecated Use theme.palette.grey[50] from MUI Theme */
  0: new Color(grey[50]),
  /** @deprecated Use theme.palette.grey['100'] from MUI Theme */
  5: new Color(grey[100]),
  /** @deprecated Use theme.palette.grey['200'] from MUI Theme */
  15: new Color(grey[200]),
  /** @deprecated Use theme.palette.grey['300'] from MUI Theme */
  30: new Color(grey[300]),
  /** @deprecated Use theme.palette.grey['500'] from MUI Theme */
  50: new Color(grey[500]),
  /** @deprecated Use theme.palette.grey['600'] from MUI Theme */
  70: new Color(grey[600]),
  /** @deprecated Use theme.palette.grey['700'] from MUI Theme */
  80: new Color(grey[700]),
  /** @deprecated Use theme.palette.grey['800'] from MUI Theme */
  90: new Color(grey[800]),
  tint: {
    /** @deprecated Use theme.palette.grey['A100'] from MUI Theme */
    20: grey.A100,
    /** @deprecated Use theme.palette.grey['A200'] from MUI Theme */
    40: grey.A200,
    /** @deprecated Use theme.palette.grey['A400'] from MUI Theme */
    60: grey.A400,
  },
  /** @deprecated Use theme.palette.grey['700'] from MUI Theme */
  get primary() {
    return this[80]
  },
}

/** @deprecated Use theme.palette.yellow from MUI Theme */
export const yellowSet = {
  /** @deprecated Use theme.palette.yellow['200'] from MUI Theme */
  15: new Color(yellow[200]),
  /** @deprecated Use theme.palette.yellow['300'] from MUI Theme */
  30: new Color(yellow[300]),
  /** @deprecated Use theme.palette.yellow['500'] from MUI Theme */
  50: new Color(yellow[500]),
  /** @deprecated Use theme.palette.yellow['500'] from MUI Theme */
  get 70() {
    return this[50]
  },
  /** @deprecated Use theme.palette.yellow['700'] from MUI Theme */
  80: new Color(yellow[700]),
  tint: {
    /** @deprecated Use theme.palette.yellow['A100'] from MUI Theme */
    20: yellow.A100,
    /** @deprecated Use theme.palette.yellow['A200'] from MUI Theme */
    40: yellow.A200,
    /** @deprecated Use theme.palette.yellow['A400'] from MUI Theme */
    60: yellow.A400,
  },
  /** @deprecated Use theme.palette.yellow['500'] from MUI Theme */
  get primary() {
    return this[50]
  },
}

/** @deprecated Use theme.palette.common.white from MUI Theme */
export const white = new Color('#ffffff')

export const whiteTintSet = {
  /** @deprecated Use theme.palette.white['A100'] from MUI Theme */
  15: whitePalette.A100,
  /** @deprecated Use theme.palette.white['A200'] from MUI Theme */
  30: whitePalette.A200,
  /** @deprecated Use theme.palette.white['A400'] from MUI Theme */
  50: whitePalette.A400,
  /** @deprecated Use theme.palette.white['A700'] from MUI Theme */
  70: whitePalette.A700,
  /** @deprecated Use theme.palette.white['A700'] from MUI Theme */
  get 80() {
    return this[70]
  },
}

/** @deprecated Only used in Tunic buttons and will be removed soon. Consult with Dallin if you have a scenario where this is necessary. */
export const primarySet = {
  5: 'var(--primary-5)',
  15: 'var(--primary-15)',
  30: 'var(--primary-30)',
  50: 'var(--primary-50)',
  70: 'var(--primary-70)',
  80: 'var(--primary-80)',
  tint: {
    20: 'var(--primary-tint-20)',
    40: 'var(--primary-tint-40)',
  },
  hover: 'var(--primary-hover)',
}
