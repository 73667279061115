import * as Yup from 'yup'
import { styled } from '@mui/material'
import { formatPhoneNumber } from 'react-phone-number-input'

import { FullProviderFragment, Maybe, useUpdateProviderMutation } from '@nuna/api'
import { ProfileDisplayValue, ProfileSection } from '@nuna/common'
import { BelowTablet, Grid, PhoneTextField, phoneUSAValidationRegex } from '@nuna/tunic'

import { incompleteSectionNotification } from '../../../utils/util'

export type PersonalContactInfoProps = Pick<FullProviderFragment, 'id' | 'mobilePhone' | 'personalEmail'>

type PersonalContactInfoValues = {
  phone?: Maybe<string> | undefined
} & Omit<PersonalContactInfoProps, 'id' | 'mobilePhone'>

export function PersonalContactInfo({ id, mobilePhone }: PersonalContactInfoProps) {
  const [updateProvider, { loading: updateLoading }] = useUpdateProviderMutation()

  const initialValues: PersonalContactInfoValues = {
    phone: mobilePhone ?? '',
  }

  const [isComplete, valueDisplay] = incompleteSectionNotification<PersonalContactInfoValues>(
    initialValues,
    ['phone'],
    <ProfileDisplayValue>{formatPhoneNumber(mobilePhone ?? '')}</ProfileDisplayValue>,
  )

  return (
    <ProfileSection
      heading="Personal Contact Info"
      useOutlineSaveButton
      description={
        'You will receive a text when a client schedules or cancels an appointment, as well as a reminder text 15 minutes prior to the appointment beginning. Clients can only contact you through Tava Messages and never your phone number.'
      }
      displayWidth="full"
      updateLoading={updateLoading}
      initialValues={initialValues}
      handleSubmit={values => updateProvider({ variables: Object.assign({ id }, values) })}
      disabled={({ isValid }) => !isValid}
      sectionComplete={isComplete}
      renderDisplayValue={valueDisplay}
      renderForm={({ values, handleBlur, touched, errors, setFieldValue }) => (
        <FormGrid container spacing={2}>
          <Grid
            size={{
              xs: 12,
              md: 6,
            }}
          >
            <PhoneTextField
              name="phone"
              label="Phone Number"
              value={values.phone}
              onChange={value => setFieldValue('phone', value)}
              onBlur={handleBlur}
              fullWidth
              error={(errors.phone && touched.phone) as boolean}
              helperText={touched.phone && errors.phone ? errors.phone : null}
            />
          </Grid>
        </FormGrid>
      )}
      validationSchema={Yup.object().shape({
        phone: Yup.string()
          .required('Phone Number is required')
          .matches(phoneUSAValidationRegex, 'Please enter a valid US phone number'),
      })}
    ></ProfileSection>
  )
}

const FormGrid = styled(Grid)`
  padding-right: var(--margin-4);
  @media (${BelowTablet}) {
    padding-right: initial;
  }
`
