import * as Yup from 'yup'
import { formatPhoneNumber } from 'react-phone-number-input'

import { Patient, useUpdateClientEmergencyContactInfoMutation } from '@nuna/api'
import { useIsAdmin } from '@nuna/auth'
import { ProfileSection, ReadOnlyProfileSection } from '@nuna/common'
import { carePreferencesService, formService } from '@nuna/core'
import { Grid, InfoWithHeading, PhoneTextField, TextField, phoneUSAValidationRegex, toast } from '@nuna/tunic'

const { parseTypeformIntake } = carePreferencesService
const { composeHelperTextWithError } = formService

interface ClientProfileEmergencyContactProps {
  patient:
    | Pick<
        Patient,
        'emergencyContactName' | 'emergencyContactRelationship' | 'emergencyContactPhone' | 'typeformIntakeBlob' | 'id'
      >
    | undefined
}

type FormValues = Pick<Patient, 'emergencyContactName' | 'emergencyContactRelationship' | 'emergencyContactPhone'>

const heading = 'Emergency Contact'

export const validationSchema = Yup.object().shape({
  emergencyContactName: Yup.string().required('Emergency Contant Name is required'),
  emergencyContactPhone: Yup.string()
    .required('Emergency Contact Phone is required')
    .matches(phoneUSAValidationRegex, 'Please enter a valid US phone number'),
  emergencyContactRelationship: Yup.string().required('Emergency Contact Relationship is required'),
})

export function ClientProfileEmergencyContact({ patient }: ClientProfileEmergencyContactProps) {
  const isAdmin = useIsAdmin()

  const [updateEmergencyContact, { loading }] = useUpdateClientEmergencyContactInfoMutation({
    refetchQueries: ['PatientDetails'],
  })

  if (!patient) {
    return <ReadOnlyProfileSection isLoading heading={heading} renderDisplayValue={null} />
  }

  const { emergencyContactName, emergencyContactNumber } = parseTypeformIntake(patient.typeformIntakeBlob)

  const initialValues: FormValues = {
    emergencyContactName: patient.emergencyContactName ?? emergencyContactName,
    emergencyContactRelationship: patient.emergencyContactRelationship ?? '',
    emergencyContactPhone: patient.emergencyContactPhone ?? emergencyContactNumber,
  }

  return (
    <ProfileSection
      heading={heading}
      description={null}
      isLoading={loading}
      initialValues={initialValues}
      validationSchema={validationSchema}
      handleSubmit={async values => {
        try {
          await updateEmergencyContact({
            variables: {
              patientId: patient.id,
              ...values,
            },
          })

          toast.success('Updated emergency contact info successfully')
        } catch (e) {
          console.error(e)
          toast.urgent('Unable to update emergency contact info')
        }
      }}
      showEditButton={isAdmin}
      displayWidth="full"
      renderDisplayValue={
        <>
          <InfoWithHeading
            className="fs-exclude"
            heading="Name"
            info={patient.emergencyContactName ?? emergencyContactName}
            secondaryInfo={patient.emergencyContactRelationship}
          />
          <InfoWithHeading
            className="fs-exclude"
            heading="Phone"
            info={formatPhoneNumber(patient.emergencyContactPhone ?? '') ?? emergencyContactNumber}
          />
        </>
      }
      renderForm={({ values, setFieldValue, getFieldProps, errors, touched }) => (
        <div className="mr-5">
          <Grid container spacing={4}>
            <Grid
              size={{
                md: 6,
                xs: 12,
              }}
            >
              <TextField
                {...getFieldProps('emergencyContactName')}
                {...composeHelperTextWithError('', errors.emergencyContactName, touched.emergencyContactName)}
                label="Emergency Contact's Full Name"
                className="fs-exclude"
                inputProps={{
                  'data-testid': 'profile-emergency-name',
                }}
              />
            </Grid>
            <Grid
              size={{
                xs: 12,
                md: 6,
              }}
            >
              <PhoneTextField
                {...getFieldProps('emergencyContactPhone')}
                {...composeHelperTextWithError('', errors.emergencyContactPhone, touched.emergencyContactPhone)}
                label="Their Mobile Phone"
                className="fs-exclude full-width"
                onChange={value => setFieldValue('emergencyContactPhone', value)}
                value={values.emergencyContactPhone}
                inputProps={{
                  'data-testid': 'profile-emergency-phone',
                }}
              />
            </Grid>
            <Grid
              size={{
                xs: 12,
                md: 6,
              }}
            >
              <TextField
                {...getFieldProps('emergencyContactRelationship')}
                {...composeHelperTextWithError(
                  '',
                  errors.emergencyContactRelationship,
                  touched.emergencyContactRelationship,
                )}
                label="Relationship"
                inputProps={{
                  'data-testid': 'profile-emergency-relationship',
                }}
              />
            </Grid>
          </Grid>
        </div>
      )}
    />
  )
}
