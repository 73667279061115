import { Patient } from '@nuna/api'
import { ReadOnlyProfileSection } from '@nuna/common'
import { carePreferencesService } from '@nuna/core'
import { InfoWithHeading } from '@nuna/tunic'

const { getMedicationOutputString, parseTypeformIntake } = carePreferencesService

interface ClientProfileMedicationProps {
  patient: Pick<Patient, 'nativeIntakeBlob' | 'typeformIntakeBlob'> | undefined
}

const heading = 'Medication'

export function ClientProfileMedication({ patient }: ClientProfileMedicationProps) {
  if (!patient) {
    return <ReadOnlyProfileSection isLoading heading={heading} renderDisplayValue={null} />
  }

  const { wantsMedMgmt, allergies, medsBeingTaken } = parseTypeformIntake(patient.typeformIntakeBlob)

  return (
    <ReadOnlyProfileSection
      heading={heading}
      renderDisplayValue={
        <>
          <InfoWithHeading
            heading="Interested in Medication Management"
            info={patient.nativeIntakeBlob?.wantsMedMgmt ?? wantsMedMgmt}
          />
          <InfoWithHeading
            heading="Medications"
            info={
              patient.nativeIntakeBlob?.medications
                ? getMedicationOutputString(patient.nativeIntakeBlob.medications)
                : medsBeingTaken
            }
          />
          <InfoWithHeading heading="Allergies" info={patient.nativeIntakeBlob?.allergies || allergies} />
        </>
      }
    />
  )
}
