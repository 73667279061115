import { IconProps } from './IconProps'

export function IconSearch({ color = 'currentColor', size = 24, ...props }: IconProps) {
  return (
    <svg width={size} height={size} viewBox="0 0 24 24" fill={color} xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17.1896 15.719C18.0475 14.5046 18.5226 13.0466 18.5226 11.5209C18.5226 9.56225 17.7501 7.70507 16.3488 6.31811C14.9475 4.93115 13.071 4.16663 11.0921 4.16663C9.11314 4.16663 7.23672 4.93115 5.83539 6.31811C2.94387 9.17999 2.94387 13.8618 5.83539 16.7237C7.23672 18.1107 9.11314 18.8752 11.0921 18.8752C12.7566 18.8752 14.3357 18.3373 15.631 17.3495L19.2403 20.9218C19.4693 21.1485 19.7496 21.25 20.0469 21.25C20.3443 21.25 20.6246 21.1451 20.8536 20.9218C21.274 20.4888 21.274 19.7784 20.8365 19.3251L17.1896 15.719ZM7.43154 7.93172C8.41247 6.96085 9.69076 6.43989 11.075 6.43989C12.4592 6.43989 13.7375 6.97776 14.7185 7.93172C15.6994 8.9026 16.2257 10.1678 16.2257 11.5378C16.2257 12.9079 15.6823 14.1764 14.7185 15.1473C13.7375 16.1182 12.4592 16.6392 11.075 16.6392C9.70785 16.6392 8.41247 16.1013 7.43154 15.1473C5.41499 13.1514 5.41499 9.9276 7.43154 7.93172Z"
      />
    </svg>
  )
}
