import { compact } from 'lodash'
import { useMemo } from 'react'
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom'

const drawerParams = ['clientInvite']

export function useClientInviteDrawerSearchParams() {
  const location = useLocation()
  const navigate = useNavigate()
  const [searchParams, setSearchParams] = useSearchParams()

  const getRetainedParams = () => {
    return Array.from(searchParams.entries()).reduce<{ [key: string]: string }>((acc, [key, value]) => {
      if (!drawerParams.includes(key)) {
        acc[key] = value
      }

      return acc
    }, {})
  }

  const resetClientInviteDrawer = ({ providerId }: { providerId?: string | undefined }) => {
    const searchParams: { [key: string]: string } = {
      clientInvite: 'true',
    }

    if (providerId) {
      searchParams['providerId'] = providerId
    }

    setSearchParams({ ...searchParams })
  }

  const openClientInviteDrawer = ({
    patientId,
    providerId,
    redirectToAppointmentScheduling,
    wipeOldParams = false,
  }: {
    patientId?: string | undefined
    providerId?: string | undefined
    redirectToAppointmentScheduling?: boolean
    wipeOldParams?: boolean
  }) => {
    const searchParams: { [key: string]: string } = {
      clientInvite: 'true',
    }

    if (providerId) {
      searchParams['providerId'] = providerId
    }

    if (patientId) {
      searchParams['patientId'] = patientId
    }

    if (redirectToAppointmentScheduling) {
      searchParams['redirectToAppointmentScheduling'] = 'true'
    }

    setSearchParams({ ...searchParams, ...(!wipeOldParams ? getRetainedParams() : {}) })
  }

  const closeDrawer = () => {
    navigate(
      {
        pathname: location.pathname,
        search: new URLSearchParams().toString(),
      },
      { state: null },
    )
  }

  const drawerConfig = useMemo(() => {
    const clientInvite = searchParams.get('clientInvite')
    const providerId = searchParams.get('providerId')
    const patientId = searchParams.get('patientId')
    const redirectToAppointmentScheduling = searchParams.get('redirectToAppointmentScheduling')

    return {
      drawerOpen: compact([clientInvite]).length > 0,
      patientId: patientId || '',
      providerId: providerId || '',
      clientInvite: !!clientInvite,
      redirectToAppointmentScheduling: !!redirectToAppointmentScheduling,
    }
  }, [searchParams])

  return {
    drawerConfig,
    searchParams,
    closeDrawer,
    openClientInviteDrawer,
    resetClientInviteDrawer,
  }
}
