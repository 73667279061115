import { type Persona } from '@nuna/core'

export interface HelpfulBlurb {
  body: string
  attribution?: string | null
  audience: Persona[]
  type: 'quote' | 'tip' | 'mentalHealthMonthTip'
}

export const HELPFUL_BLURBS: HelpfulBlurb[] = [
  {
    body: 'Family therapy has proven positive outcomes. Chat with us to get started.',
    audience: ['client'],
    attribution: '',
    type: 'tip',
  },
  {
    body: 'Writing down your feelings can be a useful way to cope with feelings of anxiety.',
    audience: ['client'],
    attribution: '',
    type: 'tip',
  },
  {
    body: 'You may not control all the events that happen to you, but you can decide not to be reduced by them.',
    audience: ['client'],
    attribution: 'Maya Angelou',
    type: 'quote',
  },
  {
    body: `I'm not perfect… But I'm enough.`,
    audience: ['client'],
    attribution: 'Carl Rogers',
    type: 'quote',
  },
  {
    body: 'You never change your life until you step out of your comfort zone; change begins at the end of your comfort zone.',
    audience: ['client'],
    attribution: 'Roy T. Bennett',
    type: 'quote',
  },
  {
    body: 'Do not set aside your happiness. Do not wait to be happy in the future. The best time to be happy is always now.',
    audience: ['client'],
    attribution: 'Roy T. Bennett',
    type: 'quote',
  },
  {
    body: `What would you do if you weren't afraid?`,
    audience: ['client'],
    attribution: 'Sheryl Sandberg',
    type: 'quote',
  },
  {
    body: `Courage doesn't happen when you have all the answers. It happens when you are ready to face the questions you have been avoiding your whole life.`,
    audience: ['client'],
    attribution: 'Shannon L. Alder',
    type: 'quote',
  },
  {
    body: 'Words of comfort, skillfully administered, are the oldest therapy known to man.',
    audience: ['provider'],
    attribution: 'Louis Nizer',
    type: 'quote',
  },
  {
    body: 'Our wounds are often the openings into the best and most beautiful part of us.',
    audience: ['client'],
    attribution: 'David Richo',
    type: 'quote',
  },
  {
    body: 'Cognitive therapy is based on the idea that when you change the way you think, you can change the way you feel and behave.',
    audience: ['client'],
    attribution: 'David D. Burns',
    type: 'quote',
  },
  {
    body: "Feeling like your therapist isn't a good fit? That's ok. We make it easy to switch therapists.",
    audience: ['client'],
    attribution: '',
    type: 'tip',
  },
  {
    body: "Have you shared with your therapist what's working in therapy?",
    audience: ['client'],
    attribution: '',
    type: 'tip',
  },
  {
    body: 'You are the expert on your life. Your therapist can help guide you along the way.',
    audience: ['client'],
    attribution: '',
    type: 'tip',
  },
  {
    body: "Feeling like therapy isn't working for you? Talk with your therapist about what is not working for you.",
    audience: ['client'],
    attribution: '',
    type: 'tip',
  },
  {
    body: 'Have you moved your body today? Physical activity is one of the best ways to increase overall feelings of wellness.',
    audience: ['client'],
    attribution: '',
    type: 'tip',
  },
  {
    body: 'The curious paradox is that when I accept myself just as I am, then I can change.',
    audience: ['client'],
    attribution: 'Carl Rogers',
    type: 'quote',
  },
  {
    body: 'In therapy, I see myself in the mirror differently.',
    audience: ['provider'],
    attribution: 'Ricky Williams',
    type: 'quote',
  },
  {
    body: 'The shoe that fits one person pinches another; there is no one recipe for living that suits all cases.',
    audience: ['provider'],
    attribution: 'Carl Jung',
    type: 'quote',
  },
  {
    body: 'Change begins at the end of your comfort zone.',
    audience: ['provider'],
    attribution: 'Roy T. Bennett',
    type: 'quote',
  },
  {
    body: 'People will forget what you said, people will forget what you did, but people will never forget how you made them feel.',
    audience: ['provider'],
    attribution: 'Maya Angelou',
    type: 'quote',
  },
  {
    body: 'If you want others to be happy, practice compassion. If you want to be happy, practice compassion.',
    audience: ['provider'],
    attribution: 'Dalai Lama',
    type: 'quote',
  },
  {
    body: 'Our greatest glory is not in never falling, but in rising every time we fall.',
    audience: ['provider'],
    attribution: 'Confucius',
    type: 'quote',
  },
  {
    body: 'The best way out is always through.',
    audience: ['provider'],
    attribution: 'Robert Frost',
    type: 'quote',
  },
  {
    body: 'We know what we are, but know not what we may be.',
    audience: ['provider'],
    attribution: 'William Shakespeare',
    type: 'quote',
  },
  {
    body: 'I find the best way to love someone is not to change them, but instead, help them reveal the greatest version of themselves.',
    audience: ['provider'],
    attribution: 'Steve Maraboli',
    type: 'quote',
  },
  {
    body: 'A person is a fluid process, not a fixed and static entity; a flowing river of change, not a block of solid material; a continually changing constellation of potentialities, not a fixed quantity of traits.',
    audience: ['provider'],
    attribution: 'Carl Rogers',
    type: 'quote',
  },
  {
    body: 'There is nothing too shameful, embarrassing, or taboo to talk about in therapy.',
    audience: ['client'],
    attribution: 'Laura Mueller',
    type: 'quote',
  },
  {
    body: 'Did you know? We offer a referral bonus if you refer an amazing therapist from your network to join Tava.',
    audience: ['provider'],
    attribution: '',
    type: 'tip',
  },
  {
    body: `Tava's values are: teamwork not ego, be open, be an owner, be urgent, and be there.`,
    audience: ['provider'],
    attribution: '',
    type: 'tip',
  },
  {
    body: "Tava's vision is to make therapy normal by helping more people use high quality mental health care.",
    audience: ['provider'],
    attribution: '',
    type: 'tip',
  },
  {
    body: 'At Tava we believe that humans heal humans, and what we build reflects that.',
    audience: ['provider'],
    attribution: '',
    type: 'tip',
  },
  {
    body: 'Finding the right therapist and fully embracing the process can help you restore the flow and balance in your life.',
    audience: ['client'],
    attribution: '',
    type: 'tip',
  },
  {
    body: "You can ask questions. You are allowed to disagree with your therapist. And if something doesn't make sense to you, say so.",
    audience: ['client'],
    attribution: '',
    type: 'tip',
  },
  {
    body: 'Tava started in January 2019 when Dallen and Cami Allred decided to experiment with the idea that aligning incentives in mental healthcare was possible.',
    audience: ['provider'],
    attribution: '',
    type: 'tip',
  },
  {
    body: "It's a slow process, but quitting won't speed it up.",
    audience: ['client'],
    attribution: 'Jodie Webster',
    type: 'quote',
  },
  {
    body: 'Did you know? At Tava we always want to hear your feedback and ideas. Get in touch with us via chat or email anytime.',
    audience: ['provider'],
    attribution: '',
    type: 'tip',
  },
  {
    body: "Sometimes we just don't feel like going to therapy. You can cancel sessions in advance, but be cognizant of this feeling and why you may be feeling it. Is it time to find a different therapist? Or are you working through something really challenging? Therapy is similar to exercising: we might want to veg out instead of work out, but we usually feel better if we exercise.",
    audience: ['client'],
    attribution: '',
    type: 'tip',
  },
  {
    body: `The therapeutic process isn't an overnight thing. It's about learning, growing, and making small changes that will be long-lasting.`,
    audience: ['client'],
    attribution: 'Donald McCasland',
    type: 'quote',
  },
  {
    body: 'You are never too old to set another goal or to dream a new dream.',
    audience: ['client'],
    attribution: 'C.S. Lewis',
    type: 'quote',
  },
  {
    body: 'Great things never came from comfort zones.',
    audience: ['client', 'provider'],
    attribution: 'Ben Francia',
    type: 'quote',
  },
  {
    body: 'During your session, you might come to new understandings about yourself, others, and the world. It can be helpful to make a note, either during or immediately following your session, in a journal.',
    audience: ['client'],
    attribution: '',
    type: 'tip',
  },
  {
    body: `Sometimes we can't be choosy with the timing of our therapy hour, but if you can, it's best to schedule it when you can have some time to reflect after the session is over.`,
    audience: ['client'],
    attribution: '',
    type: 'tip',
  },
  {
    body: `No matter how many mistakes you make or how slow your progress, you are still way ahead of everyone who isn't trying.`,
    audience: ['client'],
    attribution: 'Tony Robbins',
    type: 'quote',
  },
  {
    body: "It's OK to ask your therapist questions about how we are doing the work of therapy together, the strategy for the sessions, and the therapeutic relationship.",
    audience: ['client'],
    attribution: 'Laura Mueller',
    type: 'quote',
  },
  {
    body: "Your therapy session shouldn't exist in a vacuum. Implementing the tools you've learned in therapy in your day-to-day life is the best way to see your personal growth.",
    audience: ['client'],
    attribution: '',
    type: 'tip',
  },
  {
    body: "Don't be surprised if you experience unexpectedly raw emotions. Many people cry in therapy. Let it out! That's what therapy is for.",
    audience: ['client'],
    attribution: '',
    type: 'tip',
  },
  {
    body: "Therapy is hard work. Positive changes take time. It can be frustrating, and that's ok. If you get frustrated, talk about it with your therapist.",
    audience: ['client'],
    attribution: '',
    type: 'tip',
  },
  {
    body: "Be curious about why you are the way you are, and don't judge yourself for what you find out.",
    audience: ['client'],
    attribution: 'Walt Whitman',
    type: 'quote',
  },
  {
    body: "Listening is active. At its most basic level, it's about focus, paying attention.",
    audience: ['provider'],
    attribution: 'Simon Sinek',
    type: 'quote',
  },
  {
    body: 'No one cares how much you know, until they know how much you care.',
    audience: ['provider'],
    attribution: 'Theodore Roosevelt',
    type: 'quote',
  },
  {
    body: 'The best way to find yourself is to lose yourself in the service of others.',
    audience: ['provider'],
    attribution: 'Mahatma Gandhi',
    type: 'quote',
  },
  {
    body: 'We cannot teach people anything; we can only help them discover it within themselves.',
    audience: ['provider'],
    attribution: 'Galileo Galilei',
    type: 'quote',
  },
]

export const mentalHealthMonthTips: HelpfulBlurb[] = [
  {
    body: 'Go for a walk without your phone',
    audience: ['client'],
    type: 'mentalHealthMonthTip',
  },
  {
    body: 'Add a plant to your home — indoor, outdoor, or bouquet',
    audience: ['client'],
    type: 'mentalHealthMonthTip',
  },
  {
    body: 'Listen, sing, or dance to your favorite song',
    audience: ['client'],
    type: 'mentalHealthMonthTip',
  },
  {
    body: 'Do a 15 minute full-body stretch',
    audience: ['client'],
    type: 'mentalHealthMonthTip',
  },
  {
    body: 'Schedule a session with your therapist',
    audience: ['client'],
    type: 'mentalHealthMonthTip',
  },
  {
    body: 'Journal out your boundaries and brainstorm changes you can make',
    audience: ['client'],
    type: 'mentalHealthMonthTip',
  },
  {
    body: 'Find a new recipe and make it',
    audience: ['client'],
    type: 'mentalHealthMonthTip',
  },
  {
    body: 'Turn on nature sounds or white noise and relax',
    audience: ['client'],
    type: 'mentalHealthMonthTip',
  },
  {
    body: 'Swipe through some pictures of your favorite memories',
    audience: ['client'],
    type: 'mentalHealthMonthTip',
  },
  {
    body: "Find somewhere you'd like to volunteer and sign up",
    audience: ['client'],
    type: 'mentalHealthMonthTip',
  },
  {
    body: 'Encourage someone you know to schedule a therapy session',
    audience: ['client'],
    type: 'mentalHealthMonthTip',
  },
  {
    body: 'Send a thank you note to your therapist',
    audience: ['client'],
    type: 'mentalHealthMonthTip',
  },
  {
    body: 'Go out for your favorite treat',
    audience: ['client'],
    type: 'mentalHealthMonthTip',
  },
  {
    body: 'Write someone a thank you note and send it',
    audience: ['client'],
    type: 'mentalHealthMonthTip',
  },
  {
    body: 'Take a social media hiatus for a day, a week, or the rest of May',
    audience: ['client'],
    type: 'mentalHealthMonthTip',
  },
  {
    body: 'Write your future self a love letter',
    audience: ['client'],
    type: 'mentalHealthMonthTip',
  },
  {
    body: 'Engage in your favorite creative activity',
    audience: ['client'],
    type: 'mentalHealthMonthTip',
  },
  {
    body: 'Write a gratitude list',
    audience: ['client'],
    type: 'mentalHealthMonthTip',
  },
  {
    body: 'Do a 10-minute meditation',
    audience: ['client'],
    type: 'mentalHealthMonthTip',
  },
  {
    body: 'Eat a meal outside',
    audience: ['client'],
    type: 'mentalHealthMonthTip',
  },
  {
    body: 'Watch the sunrise or sunset',
    audience: ['client'],
    type: 'mentalHealthMonthTip',
  },
  {
    body: 'Turn off your phone an hour before bed',
    audience: ['client'],
    type: 'mentalHealthMonthTip',
  },
  {
    body: 'Learn a breathing exercise',
    audience: ['client'],
    type: 'mentalHealthMonthTip',
  },
  {
    body: 'Elevate your workspace: add décor, rearrange, tidy up',
    audience: ['client'],
    type: 'mentalHealthMonthTip',
  },
  {
    body: 'Take a relaxing bath or shower',
    audience: ['client'],
    type: 'mentalHealthMonthTip',
  },
  {
    body: 'Schedule a session with your therapist',
    audience: ['client'],
    type: 'mentalHealthMonthTip',
  },
  {
    body: 'Declutter and organize a neglected space in your home',
    audience: ['client'],
    type: 'mentalHealthMonthTip',
  },
  {
    body: 'Engage in your favorite physical outdoor activity',
    audience: ['client'],
    type: 'mentalHealthMonthTip',
  },
  {
    body: "Connect with someone you've lost touch with",
    audience: ['client'],
    type: 'mentalHealthMonthTip',
  },
  {
    body: 'Compliment or appreciate someone for who they are',
    audience: ['client'],
    type: 'mentalHealthMonthTip',
  },
  {
    body: 'Write down five ways you can prioritize your mental health going forward',
    audience: ['client'],
    type: 'mentalHealthMonthTip',
  },
]
