import { DialogActions } from '@mui/material'
import { useMemo, useState } from 'react'

import {
  CredentialFragment,
  VerifiableProviderLicense,
  useListSourceLicensesQuery,
  useReassignSourceLicenseMutation,
} from '@nuna/api'
import { errorService } from '@nuna/core'
import { DataTable, DataTableColumn } from '@nuna/data-table'
import { Dialog, DialogContent, FillButton, GhostButton, toast } from '@nuna/tunic'

interface Props {
  license: CredentialFragment
  isOpen: boolean
  onClose: (sourceLicense?: VerifiableProviderLicense | null) => void
}
export function SourceLicenseReassign({ license, isOpen, onClose }: Props) {
  const [reassignSourceLicense] = useReassignSourceLicenseMutation()
  const { data, loading } = useListSourceLicensesQuery({
    variables: { providerId: license.providerId },
    skip: !isOpen || !license.providerId,
  })
  const [selectedLicense, setSelectedLicense] = useState<VerifiableProviderLicense | null>(null)

  const rowData = useMemo<VerifiableProviderLicense[]>(() => data?.listSourceLicenses ?? [], [data?.listSourceLicenses])
  const columns = useMemo<DataTableColumn<VerifiableProviderLicense>[]>(
    () => [
      {
        Header: 'License Type',
        accessor: 'licenseType',
        // eslint-disable-next-line react/jsx-no-useless-fragment
        Cell: ({ row }) => <>{row.original?.licenseType?.name}</>,
      },
      {
        Header: 'State',
        accessor: 'state',
      },
      {
        Header: 'License Number',
        accessor: 'licenseNumber',
      },
      {
        Header: 'Verification Status',
        accessor: 'currentVerificationStatus',
      },
    ],
    [],
  )

  const handleClose = () => {
    onClose()
  }

  const handleReassign = async () => {
    if (!selectedLicense) return

    try {
      await reassignSourceLicense({
        variables: {
          licenseId: license.id,
          sourceId: selectedLicense.id,
          status: selectedLicense.currentVerificationStatus ?? '',
        },
      })
      toast.success('Reassigned provider license source')

      onClose()
    } catch (e) {
      console.error(e)
      toast.urgent(errorService.transformGraphQlError(e, 'Failed to reassign provider license'))
    }
  }

  const initial = data?.listSourceLicenses?.find(sourceLicense => sourceLicense.id === license.verifiableId)

  if (!selectedLicense && initial) {
    setSelectedLicense(initial)
  }

  return (
    <Dialog isOpen={isOpen} onClose={handleClose} maxWidth="md" fullWidth>
      <DialogContent paddingSize="md">
        <h2 className="h4 mb-5">Reassign Source License</h2>

        <DataTable
          columns={columns}
          rowData={rowData}
          loading={loading}
          noRecordsMessage="No licenses found"
          isRowSelected={data => data.id === selectedLicense?.id}
          onRowClick={setSelectedLicense}
        />
      </DialogContent>
      <DialogActions>
        <GhostButton onClick={handleClose}>Cancel</GhostButton>
        <FillButton className="ml-2" onClick={handleReassign} disabled={!selectedLicense}>
          Ok
        </FillButton>
      </DialogActions>
    </Dialog>
  )
}
