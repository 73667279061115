import { ReactNode, createContext, useCallback, useContext, useMemo, useState } from 'react'

import { ProviderDirectoryType } from '@nuna/api'

interface DirectoriesContextValues {
  selectedDirectoryType: ProviderDirectoryType | undefined
  handleDirectoryDrawerClick: (directoryType: ProviderDirectoryType) => void
  closeDrawer: () => void
}

const DirectoriesContext = createContext<DirectoriesContextValues | undefined>(undefined)

export function DirectoriesContextProvider({ children }: { children: ReactNode }) {
  const [selectedDirectoryType, setSelectedDirectoryType] = useState<ProviderDirectoryType>()

  const handleDirectoryDrawerClick = useCallback((directoryType: ProviderDirectoryType) => {
    setSelectedDirectoryType(directoryType)
  }, [])

  const closeDrawer = useCallback(() => {
    setSelectedDirectoryType(undefined)
  }, [])

  const values = useMemo(
    () => ({
      selectedDirectoryType,
      handleDirectoryDrawerClick,
      closeDrawer,
    }),
    [closeDrawer, handleDirectoryDrawerClick, selectedDirectoryType],
  )

  return <DirectoriesContext.Provider value={values}>{children}</DirectoriesContext.Provider>
}

export function useDirectoriesContext() {
  const context = useContext(DirectoriesContext)
  if (!context) {
    throw new Error('useDirectoriesContext must be used within a DirectoriesContextProvider')
  }

  return context
}
