import { IconProps } from './IconProps'

export function IconCalendarNone({ size = 24, color = 'currentColor', ...props }: IconProps) {
  return (
    <svg width={size} height={size} viewBox="0 0 56 56" fill={color} {...props} xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M42.0927 4.70905C41.6146 4.44399 41.0122 4.61208 40.7403 5.08642L40.0519 6.28755C40.0456 6.27906 40.0393 6.27059 40.0329 6.26216L29.9489 23.8571H29.9825L17.862 45.0053H17.8284L15.539 49H15.5726L14.5764 50.7382C14.299 51.2222 14.4713 51.8396 14.9592 52.11L17.0898 53.2911C17.568 53.5561 18.1704 53.388 18.4422 52.9137L20.6852 49H40.6793C44.169 49 47 46.1903 47 42.7268V17.5588C47 14.6142 44.9536 12.1421 42.1958 11.4676L44.6061 7.26192C44.8835 6.77791 44.7113 6.16052 44.2233 5.89006L42.0927 4.70905ZM35.0951 23.8571L22.9746 45.0053H39.884C41.6249 45.0053 43.0361 43.6291 43.0361 41.8533V23.8571H35.0951ZM21.6667 11.2857H32.0412L24.8363 23.8571H13.0457V41.8533C13.0457 42.4784 13.2317 43.061 13.5493 43.551L11.2646 47.5374C9.88046 46.3865 9 44.6583 9 42.7268V17.5588C9 14.0954 11.831 11.2857 15.3333 11.2857V8.14283C15.3333 6.41426 16.7583 4.99998 18.5 4.99998C20.2417 4.99998 21.6667 6.41426 21.6667 8.14283V11.2857Z"
      />
    </svg>
  )
}
