import { IconProps } from './IconProps'

export function IconUpload({ size = 24, color = 'currentColor', ...props }: IconProps) {
  return (
    <svg width={size} height={size} viewBox="0 0 48 48" fill={color} xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M28.3386 36.9977C28.0615 36.999 27.8363 36.7748 27.8363 36.4977V28.7608C27.8363 28.5399 28.0154 28.3608 28.2363 28.3608H32.0625C32.1656 28.3608 32.2648 28.321 32.3393 28.2496C32.4987 28.0967 32.5041 27.8435 32.3512 27.684L24.3146 19.3003C24.1445 19.1359 23.8913 19.1405 23.738 19.2995L15.6535 27.6832C15.5816 27.7577 15.5414 27.8573 15.5414 27.9608C15.5414 28.1818 15.7205 28.3608 15.9414 28.3608H19.9558C20.1767 28.3608 20.3558 28.5399 20.3558 28.7608V36.5C20.3558 36.7761 20.1319 37 19.8558 37H10C4.47715 37 0 32.5228 0 27C0 22.8856 2.48474 19.3516 6.03549 17.8167C6.37699 14.548 9.14093 12 12.5 12C13.8738 12 15.1481 12.4262 16.1978 13.1536C18.2018 8.9247 22.5093 6 27.5 6C34.4036 6 40 11.5964 40 18.5C40 18.6855 39.996 18.8701 39.988 19.0536C44.4937 19.5456 48 23.3633 48 28C48 32.6326 44.5 36.4476 40 36.9451L28.3386 36.9977Z"
      />
    </svg>
  )
}
