import { xor } from 'lodash'
import { useState } from 'react'

import { usePatientSignupPrevalidationLazyQuery } from '@nuna/api'

export type PrevalidationError = { field: string; error: string }

export function useSignupPrevalidation() {
  const [prevalidatePatientSignup, queryData] = usePatientSignupPrevalidationLazyQuery()
  const [errors, setErrors] = useState<PrevalidationError[]>([])
  const [dismissedErrors, setDismissedErrors] = useState<PrevalidationError[]>([])

  const prevalidate = async (email: string) => {
    const response = await prevalidatePatientSignup({ variables: { email } })
    setDismissedErrors([])
    setErrors([])

    if (response.data?.patientSignupPrevalidation.valid) {
      return { valid: true }
    }

    const errors = response.data?.patientSignupPrevalidation.errors ?? []

    const filteredErrors: PrevalidationError[] = []
    errors.forEach(error => {
      if (error) {
        filteredErrors.push(error)
      }
    })

    setErrors(filteredErrors)

    return { valid: false }
  }

  return [
    prevalidate,
    {
      errors,
      dismissedErrors,
      undismissedErrors: xor(errors, dismissedErrors),
      dismissError(error: PrevalidationError) {
        setDismissedErrors([...dismissedErrors, error])
      },
    },
    queryData,
  ] as const
}
