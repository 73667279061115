import { styled } from '@mui/material'
import { isBoolean, isNil } from 'lodash'
import moment from 'moment'
import { useEffect, useState } from 'react'

import { appointmentService } from '@nuna/core'
import { Card, CardProps, salmonSet, yellowSet } from '@nuna/tunic'

import * as BootComponents from './components/Boot'
import * as DetailComponents from './components/Detail'
import { AppointmentCardIntent, AppointmentCardProps, AppointmentCardStatus } from './types'

const { subjectToCancelationFee, upcomingAppointmentInfo } = appointmentService
function AppointmentCard({
  appointment,
  appointmentStatus,
  audience,
  condensed = false,
  className = '',
  provider,
  client,
  showAvatar = true,
  previousAppointmentDatetime,
  detail,
  intent: intentOverride,
  cancelPolicyStatus,
  chargeStatus,
  boot,
  additionalSlots,
  hideChargeStatus,
  showTopStatus = false,
  ...props
}: AppointmentCardProps) {
  const [lapsedMinutes, setLapsedMinutes] = useState(0)

  useEffect(() => {
    // Cause the component to re-render every minute
    const timeout = setTimeout(() => setLapsedMinutes(lapsedMinutes + 1), 60000)
    return () => clearTimeout(timeout)
  }, [lapsedMinutes])
  const intent = intentOverride || inferIntent({ appointment, appointmentStatus, cancelPolicyStatus })

  const getCardDepth = () => {
    if (
      ([AppointmentCardStatus.Canceled, AppointmentCardStatus.Canceling, AppointmentCardStatus.NoShow].includes(
        appointmentStatus,
      ) ||
        ['urgent', 'caution-action'].includes(intent)) &&
      !boot
    ) {
      return -1
    }

    return 1
  }

  const getBackgroundColor = () => {
    switch (intent) {
      case 'urgent':
        return salmonSet.tint[20]
      case 'caution-action':
        return yellowSet.tint[20]
      default:
        return undefined
    }
  }

  const backgroundColor = getBackgroundColor()

  return (
    <ExtendedCard className={`overflow-hidden ${className}`} depth={getCardDepth()} {...props} intent={intent}>
      {!!detail && detail}
      {!detail && (
        <DetailComponents.Detail
          appointment={appointment}
          appointmentStatus={appointmentStatus}
          audience={audience}
          condensed={condensed}
          provider={provider}
          client={client}
          showAvatar={showAvatar}
          previousAppointmentDatetime={previousAppointmentDatetime}
          stackedBorderColor={backgroundColor}
          chargeStatus={chargeStatus}
          cancelPolicyStatus={cancelPolicyStatus}
          additionalSlots={additionalSlots}
          showTopStatus={showTopStatus}
          hideChargeStatus={hideChargeStatus}
        />
      )}
      {isBoolean(boot) && boot && (
        <BootComponents.Boot
          audience={audience}
          appointment={appointment}
          appointmentStatus={appointmentStatus}
          cancelPolicyStatus={cancelPolicyStatus}
          intent={intent}
        />
      )}
      {!isBoolean(boot) && !!boot && boot}
    </ExtendedCard>
  )
}

interface ExtendedCardProps extends Omit<CardProps, 'intent'> {
  intent?: AppointmentCardIntent
}

function ExtendedCard({ intent, depth, shadowColor, className, ...props }: ExtendedCardProps) {
  if (intent === 'caution-action') {
    return <StyledExtendedCard className={`${intent} ${className}`} {...props} />
  }

  return <Card intent={intent} depth={depth} shadowColor={shadowColor} className={className} {...props} />
}

const StyledExtendedCard = styled(Card)`
  &.caution-action {
    background-color: ${yellowSet.tint[20]};
    border: 1px solid ${yellowSet[50].hex};
    box-shadow: none;

    .card-header-inner-wrapper {
      border-bottom-color: ${yellowSet[50].hex};
    }
  }
`

function inferIntent({
  cancelPolicyStatus,
  appointment,
  appointmentStatus,
}: Pick<AppointmentCardProps, 'cancelPolicyStatus' | 'appointment' | 'appointmentStatus'>): AppointmentCardIntent {
  const { startDatetime } = appointment
  const cancelPolicy = cancelPolicyStatus?.cancelPolicy
  const { cancelPeriodMinutes } = cancelPolicy ?? {}

  if (!moment(startDatetime).isValid()) {
    return 'default'
  }

  const { minutesFromNow } = upcomingAppointmentInfo(startDatetime)
  const isSubjectToFees = subjectToCancelationFee({
    appointmentStartDatetime: startDatetime,
    policyAcceptedDate: cancelPolicyStatus?.acceptedDate,
    cancelPeriodMinutes,
  })

  // If the appointment is within 10 minutes and the user is not in the act of canceling, reuturn 'information'
  if (minutesFromNow <= 10 && appointmentStatus !== AppointmentCardStatus.Canceling) {
    return 'information'
  }

  // If the appointment falls within the cancelation fee window and the user is in the act of canceling, return 'urgent'
  if (!isNil(cancelPeriodMinutes) && isSubjectToFees && appointmentStatus === AppointmentCardStatus.Canceling) {
    return 'urgent'
  }

  // If the appointment falls within the cancelation fee window and the user is not in the act of canceling, return 'caution'
  if (!isNil(cancelPeriodMinutes) && isSubjectToFees && appointmentStatus !== AppointmentCardStatus.Canceling) {
    return 'caution'
  }

  return 'default'
}

AppointmentCard.Avatar = DetailComponents.AppointmentAvatar
AppointmentCard.otherParticipantInfo = DetailComponents.otherParticipantInfo
AppointmentCard.StatusDisplay = DetailComponents.StatusDisplay
AppointmentCard.Time = DetailComponents.AppointmentTime
AppointmentCard.ChargeStatus = DetailComponents.ChargeStatus
AppointmentCard.inferIntent = inferIntent
AppointmentCard.statusDisplayText = DetailComponents.statusDisplayText

export { AppointmentCard }
