import { styled } from '@mui/material'
import { AnimatePresence, motion } from 'framer-motion'
import { noop } from 'lodash'
import { ChangeEventHandler, FocusEventHandler, MouseEventHandler, useState } from 'react'

import { ContextualAlert, IconCheckCircle, TextButton, TextField, body2, greySet } from '@nuna/tunic'

interface EmailVerificationFieldProps {
  error: boolean
  existingEmail: string
  helperText: string
  isLoading: boolean
  name: string
  onBlur: FocusEventHandler<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>
  onChange: ChangeEventHandler<HTMLInputElement>
  onFocus?: FocusEventHandler<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>
  onLoginClick: MouseEventHandler<HTMLButtonElement>
  onUpdateEmailClick: MouseEventHandler<HTMLButtonElement>
  showUpdateError: boolean
  touched: boolean
  typedEmail: string
}

export function EmailVerificationField({
  error,
  existingEmail,
  helperText,
  isLoading,
  name,
  onBlur,
  onChange,
  onFocus = noop,
  onLoginClick,
  onUpdateEmailClick,
  showUpdateError,
  touched,
  typedEmail,
}: EmailVerificationFieldProps) {
  const doesEmailMatch = existingEmail.toLowerCase() === typedEmail.toLowerCase()
  const [isFocused, setIsFocused] = useState(false)
  const showUpdateButton = !showUpdateError && !isFocused && !doesEmailMatch && !error && touched

  const getHelperText = () => {
    if (isFocused || !touched) return
    if (helperText) return helperText
    if (!doesEmailMatch) return "Your email doesn't match"
  }

  return (
    <>
      <EmailContainer>
        <TextField
          autoFocus
          error={touched && (error || (!isFocused && typedEmail.length > 0 && !doesEmailMatch))}
          helperText={getHelperText()}
          disabled={isLoading}
          onFocus={e => {
            setIsFocused(true)
            onFocus(e)
          }}
          onChange={onChange}
          type="email"
          onBlur={e => {
            setIsFocused(false)
            onBlur(e)
          }}
          name={name}
          value={typedEmail}
          inputProps={{ 'data-testid': 'signup-email-verification' }}
        />
        <VerificationInput isFocused={isFocused} existingValue={existingEmail} newValue={typedEmail} />
      </EmailContainer>

      <AnimatePresence>
        {showUpdateButton && (
          <Expander>
            <TextButton className="mt-3" disabled={isLoading} isLoading={isLoading} onClick={onUpdateEmailClick}>
              Update to this email
            </TextButton>
          </Expander>
        )}

        {showUpdateError && (
          <Expander>
            <ContextualAlert className="mt-3" intent="urgent" dismissButtonText="Login" onDismiss={onLoginClick}>
              Oops, we encountered an error. If you already have an account, please log in instead.
            </ContextualAlert>
          </Expander>
        )}

        {doesEmailMatch && (
          <Expander>
            <ContextualAlert className="mt-2" icon={<IconCheckCircle />} intent="information">
              Oh yeah, it's a match
            </ContextualAlert>
          </Expander>
        )}
      </AnimatePresence>
    </>
  )
}

function compareStrings(baseValue: string, testValue: string) {
  for (let i = 0; i < testValue.length; i++) {
    if (baseValue[i]?.toLowerCase() !== testValue[i]?.toLowerCase()) {
      return { same: testValue.slice(0, i), different: testValue.slice(i) }
    }
  }

  return { same: testValue, different: null }
}

interface VerificationInputProps {
  existingValue: string
  newValue: string
  isFocused: boolean
}

function VerificationInput({ existingValue, newValue, isFocused }: VerificationInputProps) {
  const diff = compareStrings(existingValue, newValue)

  return (
    <>
      <ExistingEmail moved={isFocused || !!newValue}>{existingValue}</ExistingEmail>
      <NewlyTypedEmail>
        {diff.same}
        {diff.different && <span className="text-error">{diff.different}</span>}
      </NewlyTypedEmail>
    </>
  )
}

const EmailContainer = styled('div')`
  position: relative;

  input {
    caret-color: ${body2};
    color: transparent;
    // we can't change the color of part of the input string so make it invisible and render <NewlyTypedEmail/> in its place
  }
`

const ExistingEmail = styled('span')<{ moved: boolean }>`
  color: ${greySet[50].hex};
  position: absolute;
  left: 0;
  pointer-events: none;
  top: 22px;
  transition: transform 0.3s;

  ${props => props.moved && 'transform: translateY(-1.75rem)'}
`

const NewlyTypedEmail = styled('span')`
  position: absolute;
  left: 0;
  pointer-events: none;
  top: 22px;
`

const Expander = styled(motion.div)`
  overflow: hidden;
`
Expander.defaultProps = {
  initial: { height: 0 },
  animate: { height: 'auto' },
  exit: { height: 0 },
  transition: { duration: 0.2 },
}
