import { Audience } from '@nuna/core'
import { Select, SelectProps } from '@nuna/tunic'

interface Props extends Omit<SelectProps, 'children'> {
  value: Audience
}

export function RequestedBySelect(props: Props) {
  return (
    <Select {...props}>
      <option value="provider">Provider</option>
      <option value="client">Client</option>
    </Select>
  )
}
