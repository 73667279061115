import { noop } from 'lodash'
import mixpanel from 'mixpanel-browser'

import { AppointmentForNotificationFragment, CancelPolicyStatusFragment, DrawerAppointmentFragment } from '@nuna/api'
import { DrawerStepper } from '@nuna/common'
import { Audience, cancelPolicyService, formService } from '@nuna/core'
import { MessageComposer, useMessageComposerContext } from '@nuna/messaging'

import { AppointmentDrawerPaddedContainer } from '../../../AppointmentDrawer'
import { useRescheduleForm } from '../../../hooks/useRescheduleForm'
import { useShowCancelFeeInfo } from '../../../hooks/useShowCancelFeeInfo'
import { AppointmentChangeReasonForm } from '../../AppointmentChangeReasonForm'
import { ChargeFee } from '../../ChargeFee'
import { MessageToChips } from '../../MessageToChips'
import { RequestedBySelect } from '../../RequestedBySelect'
import { NewAppointmentForm } from './RescheduleForm/NewAppointmentForm'

const { composeHelperTextWithError } = formService

interface Props {
  appointment: DrawerAppointmentFragment
  cancelPolicyStatus: CancelPolicyStatusFragment
  canChargeLateFee: boolean
  audience: Exclude<Audience, 'client'>
  onAppointmentScheduled?: (appointment: AppointmentForNotificationFragment) => void
}

export function RescheduleAsProviderOrAdmin({
  appointment,
  cancelPolicyStatus,
  canChargeLateFee,
  audience,
  onAppointmentScheduled = noop,
}: Props) {
  const { formProps } = useRescheduleForm({ appointment, audience })
  const { loading: saveMessageLoading, invalidAttachments } = useMessageComposerContext()

  const { cancelPolicy, acceptedDate } = cancelPolicyStatus

  const isLegacyCancelPolicy = cancelPolicyService.isLegacy(cancelPolicy)

  const { values, setFieldValue, submitForm, setFieldTouched, getFieldProps, touched, errors, submitCount } = formProps

  const showCancelFeeInfo = useShowCancelFeeInfo({
    audience,
    requestedBy: values.requestedBy,
    cancelReason: values.reason,
    isLegacyCancelPolicy,
  })

  return (
    <AppointmentDrawerPaddedContainer>
      <NewAppointmentForm
        appointment={appointment}
        cancelPolicyStatus={cancelPolicyStatus}
        value={values.newStart}
        providerId={appointment.provider.id}
        onChange={newStart => {
          setFieldTouched('newStart', true)
          setFieldValue('newStart', newStart)
        }}
        canChargeLateFee={canChargeLateFee}
        audience={audience}
        containerProps={{ className: 'mt-1' }}
        formProps={formProps}
        {...composeHelperTextWithError('', errors.newStart, touched.newStart)}
      />
      <MessageToChips
        users={audience === 'provider' ? [appointment.patient] : [appointment.patient, appointment.provider]}
        className="mt-3 mb-1"
      />
      {audience === 'admin' && (
        <RequestedBySelect label="Requested by" className="my-2" {...getFieldProps('requestedBy')} />
      )}
      <MessageComposer
        placeholder={audience === 'provider' ? 'Message your client' : 'Write your message'}
        audience="provider"
        autoCollapse={false}
        error={submitCount > 0 && !!errors.hasMessage}
        disableAssessments
        boot={
          <AppointmentChangeReasonForm
            formProps={formProps}
            requestedBy={values.requestedBy}
            audience={audience}
            labelText="Why Reschedule?"
          />
        }
      />
      {!isLegacyCancelPolicy && showCancelFeeInfo && cancelPolicy && (
        <ChargeFee
          className="mt-2"
          feeType="reschedule"
          value={values.shouldProviderBePaid}
          cancelPolicy={cancelPolicy}
          acceptedDate={acceptedDate}
          appointment={appointment}
          audience={audience}
          onChange={e => setFieldValue('shouldProviderBePaid', e.currentTarget.checked)}
        />
      )}
      <DrawerStepper
        className="mt-3"
        nextButtonText="Confirm And Send"
        onNextButtonClick={async () => {
          const didSubmit = await submitForm()
          if (!didSubmit) return

          onAppointmentScheduled({ ...appointment, startDatetime: values.newStart })

          if (showCancelFeeInfo && cancelPolicy) {
            mixpanel.track('rescheduled late', { appointmentId: appointment.id })

            if (!isLegacyCancelPolicy) {
              mixpanel.track(`${values.shouldProviderBePaid ? 'charged' : 'waived'} fee`, {
                appointmentId: appointment.id,
                location: 'drawer',
              })
            }
          }
        }}
        nextButtonDiabled={invalidAttachments || saveMessageLoading}
        nextButtonProps={{ 'data-testid': 'reschedule-appointment-submit' }}
        formProps={formProps}
      />
    </AppointmentDrawerPaddedContainer>
  )
}
