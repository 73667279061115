import { styled } from '@mui/material'

import { eggshell, fontSize, greySet } from '@nuna/tunic'

export const DataTable = styled('table')<{
  cellPadding?: string
  className?: string | undefined
}>`
  border-collapse: separate;
  border-spacing: 0;

  th,
  td {
    // tbody td padding can be overridden with cellPadding prop
    padding: 11px;
    text-align: left;
    vertical-align: top;
  }

  tr {
    cursor: pointer;
  }

  thead {
    position: sticky;
    position: -webkit-sticky;
    z-index: 2;
    top: 0;
    th {
      background-color: ${greySet[15].hex};
      color: ${greySet[80].hex};
      font-size: ${fontSize.caption};
      font-weight: 700;
      text-transform: uppercase;
      &:first-of-type {
        border-radius: 6px 0 0 6px;
      }
      &:last-child {
        border-radius: 0 6px 6px 0;
      }
    }
  }

  tbody {
    tr {
      &:first-of-type td {
        box-shadow: none;
      }
      &:nth-child(odd) {
        background: none;
      }
      &:nth-child(even) {
        background: ${eggshell};
      }

      td {
        color: var(--body-secondary-color);
        font-size: ${fontSize.body};
        vertical-align: middle;
        ${props => props.cellPadding && `padding: ${props.cellPadding};`}

        :first-of-type {
          border-radius: 6px 0 0 6px;
        }
        &:last-child {
          border-radius: 0 6px 6px 0;
        }
      }
      &:hover {
        td {
          background: ${greySet[5].hex};
        }
      }
    }
  }
`
