export function randomArrayItem<T>(items: T[]): T {
  const randomIndex = Math.floor(Math.random() * items.length)
  const randomItem = items[randomIndex]
  return randomItem
}

export function randomEnumValue<T extends object>(anEnum: T): T[keyof T] {
  const enumValues = Object.keys(anEnum).map(n => {
    const key = n as keyof typeof anEnum

    return anEnum[key]
  })

  const randomIndex = Math.floor(Math.random() * enumValues.length)
  const randomEnumValue = enumValues[randomIndex]
  return randomEnumValue
}

export function randomNumber(min: number, max: number) {
  return Math.random() * (max - min) + min
}

export function randomAngle() {
  return Math.random() * Math.PI * 2
}

export function randomPointOnCirclePerimeter(
  radius: number,
  angle: number = randomAngle(),
): [{ x: number; y: number }, { angle: number }] {
  return [
    {
      x: Math.cos(angle) * radius,
      y: Math.sin(angle) * radius,
    },
    {
      angle,
    },
  ]
}
