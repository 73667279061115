import { IconRotatableProps } from './IconProps'
import { getIconRotation } from './iconUtils'

export function IconChevronThick({
  direction = 'left',
  size = 24,
  color = 'currentColor',
  style = {},
  ...props
}: IconRotatableProps) {
  const rotation = getIconRotation(direction, 'left')

  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 48 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={{ transform: rotation, transition: 'transform .3s', ...style }}
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.886 24 29.75 3l5.365 6.005L21.644 24l13.47 14.995L29.749 45 10.886 24Z"
        fill={color}
      />
    </svg>
  )
}
