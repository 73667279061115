import { useAudioTrack, useDaily, useDailyEvent, useLocalSessionId } from '@daily-co/daily-react'
import { styled } from '@mui/material'
import { MouseEventHandler, useCallback, useEffect, useState } from 'react'

import { IconMicrophone, IconMicrophoneOff } from '@nuna/tunic'

import { videoSceneDark } from '../util/colors'
import { VideoActionButton, VideoActionButtonProps } from './VideoActionButton'

interface MicrophoneButtonProps extends VideoActionButtonProps {
  onClick: MouseEventHandler<HTMLButtonElement>
}

export function MicrophoneButton({ isEnabled, onClick, ...props }: MicrophoneButtonProps) {
  const callObject = useDaily()
  const localSessionId = useLocalSessionId()
  const audioTrack = useAudioTrack(localSessionId)
  const [micLevel, setMicLevel] = useState(0)

  useEffect(() => {
    if (audioTrack.isOff) {
      setMicLevel(0) // if the audio is toggled off, reset the mic level otherwise it sticks at whatever level it was at
    }
  }, [audioTrack.isOff])

  useEffect(() => {
    if (!callObject || callObject.isDestroyed()) return

    let wasStarted = false

    // Note: I tried to have this happen once for the whole call but the implementation of this seems to be buggy so there are weird issues, e.g. for some reason the audio level observer throws an error when you try to call .join() on the callObject.
    // Best solution was to start it when/where we need it and ignore it if it has already been started. This is the best solution I could come up with for now.
    callObject
      .startLocalAudioLevelObserver()
      ?.then(() => {
        wasStarted = true
      })
      .catch(() => {
        // audio observer was already started
        wasStarted = false
      })

    return () => {
      if (wasStarted && !callObject.isDestroyed()) {
        callObject.stopLocalAudioLevelObserver()
      }
    }
  }, [callObject])

  useDailyEvent(
    'local-audio-level',
    useCallback(e => {
      setMicLevel(e.audioLevel)
    }, []),
  )

  return (
    <Button aria-label="Toggle audio" isEnabled={isEnabled} onClick={onClick} {...props}>
      <AudioMeter style={{ transform: `scaleY(${micLevel})` }} />
      {isEnabled ? <IconMicrophone /> : <IconMicrophoneOff />}
    </Button>
  )
}

const Button = styled(VideoActionButton)`
  clip-path: circle(50% at 50% 50%);
  overflow: hidden;
  position: relative;

  svg {
    position: relative;
  }
`

const AudioMeter = styled('div')`
  background-color: ${videoSceneDark};
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  transition: transform 0.2s;
  transform-origin: center bottom;
`
