import { DailyEventObjectAppMessage } from '@daily-co/daily-js'
import moment, { Moment } from 'moment-timezone'
import { useCallback, useEffect, useState } from 'react'

import { AppointmentForNotificationFragment } from '@nuna/api'

import { DailyMessage, useDailyMessage } from './useDailyMessage'

const SCHEDULE_NOTIFICATION_TIMEOUT = 1000 * 15

export function useSchedulingNotification() {
  const [schedulingNotification, setSchedulingNotification] = useState<Moment>()

  useEffect(() => {
    if (schedulingNotification) {
      const timeout = setTimeout(() => {
        setSchedulingNotification(undefined)
      }, SCHEDULE_NOTIFICATION_TIMEOUT)

      return () => {
        clearTimeout(timeout)
      }
    }
  }, [schedulingNotification])

  const handleMessageReceived = useCallback((e: DailyEventObjectAppMessage<DailyMessage>) => {
    if (e.data.type === 'scheduling-notification') {
      setSchedulingNotification(moment(e.data.appointmentStartTime))
    }
  }, [])

  const clearSchedulingNotification = useCallback(() => {
    setSchedulingNotification(undefined)
  }, [])

  const sendDailyMessage = useDailyMessage(handleMessageReceived)

  const sendSchedulingNotification = useCallback(
    (appointment: AppointmentForNotificationFragment) => {
      // The date types on appointment come from Codegen which is Scalar['Date'] which ends up just being 'any'.
      // Sometimes it's a string and sometimes it's a Moment object. We need to ensure it is a Moment object here.
      // Thankfully, a string and a Moment object are both valid moment inputs so we can just pass it in and not have to worry about type checking.
      const startTime = moment(appointment.startDatetime)

      setSchedulingNotification(startTime)

      sendDailyMessage({
        type: 'scheduling-notification',
        appointmentStartTime: startTime.toISOString(), // Daily will freak out and error when trying to stringify a Moment object so we need to ensure it is a string here.
      })
    },
    [sendDailyMessage],
  )

  return { schedulingNotification, clearSchedulingNotification, sendSchedulingNotification }
}
