import { useNetwork } from '@daily-co/daily-react'
import { useEffect } from 'react'

import { useDailyMessage } from './useDailyMessage'

/**
 * Broadcasts the local user's network quality to all participants so we can display remote quality indicators.
 * Hook only needs to be called from a single place at the top of the Daily call component.
 */
export function useNetworkQualityBroadcast() {
  const { threshold } = useNetwork()
  const sendDailyMessage = useDailyMessage()

  useEffect(() => {
    sendDailyMessage({ type: 'network-quality', threshold })
  }, [threshold, sendDailyMessage])
}
