import { styled } from '@mui/material'
import { FocusEvent, HTMLAttributes, MouseEventHandler, useRef, useState } from 'react'

import { IconClose, IconSearch } from '../icons'
import { BelowTablet, TabletLandscape } from '../styles/breakpoints'
import { greySet, tealSet } from '../styles/colorSets'
import { body2, interactiveFill } from '../styles/colors'
import { styledUtils } from '../utils/styled'
import { IconButton, OutlineButtonLink } from './Button'
import { Card, CardBody } from './Card/Card'
import { TextField, TextFieldProps } from './TextField/TextField'

export const PageWrapperWithSidebar = styled('div')<{ $hasSidebar?: boolean }>`
  display: ${({ $hasSidebar }) => ($hasSidebar ? 'flex' : 'block')};
  height: 100%;
  width: 100%;
  flex-wrap: wrap;
  @media (${BelowTablet}) {
    flex-direction: column;
  }
`
export const PageWrapperSidebar = styled('div')`
  flex: 1 0 180px;

  @media (${TabletLandscape}) {
    width: 100%;
    margin: auto;
  }
`
export const PageWrapper = styled('div')<{ $hasSidebar?: boolean; $fullAccountSetup?: boolean }>`
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow: hidden;
  ${({ $hasSidebar }) =>
    $hasSidebar ? `border-right: 1px solid #eee; flex: 1 1 auto; width: calc(100% - 330px);` : ''}

  @media (${TabletLandscape}) {
    width: 100%;
    height: calc(100% - ${({ $fullAccountSetup }) => ($fullAccountSetup ? '190' : '0')}px);
  }

  @media (${BelowTablet}) {
    height: calc(100% - ${({ $fullAccountSetup }) => ($fullAccountSetup ? '295' : '0')}px);
  }
`

export const PageHeader = styled('header')<{ border?: boolean; withBottomMargin?: boolean; $hasSidebar?: boolean }>`
  align-items: center;
  ${({ border = true }) => (border ? `border-bottom: 1px solid #eee;` : ``)}
  display: flex;
  ${({ $hasSidebar }) => ($hasSidebar ? `grid-area: 1 / 1 / 3 / 13; ` : '')}
  justify-content: space-between;
  padding: var(--margin-2) var(--margin-3);
  min-height: 80px;
  flex: 0 0 auto;
  ${({ withBottomMargin }) => (withBottomMargin ? `margin-bottom: var(--margin-1);` : '')}
`

interface PageHeadingProps extends HTMLAttributes<HTMLHeadingElement> {
  withDivider?: boolean
}
export const PageHeading = ({ className, children, withDivider = false, ...props }: PageHeadingProps) => {
  return (
    <StyledPageHeading className={`h4 ${className}`} withDivider={withDivider} {...props}>
      {children}
    </StyledPageHeading>
  )
}

type PageSearchProps = Omit<TextFieldProps, 'multiline'> & {
  onClear?: MouseEventHandler<HTMLButtonElement>
  placeholder?: string
}

export function PageSearch({ style, className, onClear, ...props }: PageSearchProps) {
  const [isFocused, setIsFocused] = useState(false)
  const ref = useRef<HTMLInputElement>(null)

  const handleFocus = (e: FocusEvent<HTMLInputElement>) => {
    setIsFocused(true)
    props.onFocus && props.onFocus(e)
  }

  const handleBlur = (e: FocusEvent<HTMLInputElement>) => {
    setIsFocused(false)
    props.onBlur && props.onBlur(e)
  }

  const handleIconClick = () => {
    ref.current?.focus()
  }

  return (
    <PageSearchContainer style={style} className={`v-align ${className}`}>
      <IconSearch
        className="mr-1"
        color={isFocused ? interactiveFill : body2}
        onClick={handleIconClick}
        style={{ cursor: 'pointer' }}
      />
      <TextField
        $hideUnderline
        {...props}
        className="search-input"
        onFocus={handleFocus}
        onBlur={handleBlur}
        $inputRef={ref}
      />
      {props.value && onClear && (
        <IconButton tooltip="Clear search" variant="secondary" customSize={32} onClick={onClear}>
          <IconClose size={16} />
        </IconButton>
      )}
    </PageSearchContainer>
  )
}

const PageSearchContainer = styled('div')`
  position: relative;

  button {
    position: absolute;
    right: -0.5rem;
  }

  svg {
    margin-bottom: 2px;
  }

  > div > div {
    padding-top: 0;
  }
`

const { transientPropOptions } = styledUtils

export const PageContent = styled('div', transientPropOptions)<{ $paddingTop?: boolean; $hasSidebar?: boolean }>`
  ${({ $hasSidebar }) => ($hasSidebar ? `grid-area: 3 / 1 / 13 / 12;` : '')}
  flex: 1 1 100%;
  position: relative;
  &:not(.overflow-hidden) {
    overflow: auto;
  }
  padding: ${({ $paddingTop = true }) =>
    $paddingTop ? `var(--margin-2) var(--margin-2);` : `0 var(--margin-2) var(--margin-2) var(--margin-2)`};
`

const StyledPageHeading = styled('h1')<{ withDivider: boolean }>`
  white-space: nowrap;
  ${({ withDivider }) =>
    withDivider
      ? `
    border-right: 1px solid ${greySet[30].hex};
    padding-right: var(--margin-2);
    margin-right: var(--margin-2);
  `
      : ``}
`

export function FullAccountSidebarCard({ intakeLink, insuranceOnly }: { intakeLink: string; insuranceOnly: boolean }) {
  return (
    <PageWrapperSidebar className="px-2 mt-2">
      <Card>
        <CardBody>
          <JoinHeader>{insuranceOnly ? 'Insurance' : 'Account'} Setup</JoinHeader>

          <p className="mt-1">
            {insuranceOnly
              ? 'Complete enrollment to accept insurance plans'
              : 'Complete setup to access free referrals, accept insurance plans and use your new practice website'}
          </p>

          <OutlineButtonLink className="full-width" to={intakeLink} variant="secondary">
            Go
          </OutlineButtonLink>
        </CardBody>
      </Card>
    </PageWrapperSidebar>
  )
}

const JoinHeader = styled('h6')`
  color: ${tealSet[90].hex};
`
