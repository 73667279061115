import { css, keyframes } from '@mui/material'

import { BelowTablet, TabletPortraitMin } from '../breakpoints'
import { greySet } from '../colorSets'
import { eggshell } from '../colors'

const loadingAnimation = keyframes`
  0% {
    background-color: ${greySet[30].hex};
  }

  50% {
    background-color: ${greySet[15].hex};
  }

  100% {
    background-color: ${greySet[30].hex};
  }
`

const rotate = keyframes`
  from {
    transform: rotate(0)
  }

  to {
    transform: rotate(360deg)
  }
`

export const miscUtils = css`
  .absolute-center {
    bottom: 0;
    left: 0;
    margin: auto;
    position: absolute;
    right: 0;
    top: 0;
  }

  .full-width {
    width: 100%;
  }

  .full-height {
    height: 100%;
  }

  .half-width {
    width: 50%;
  }

  .w-auto {
    width: auto;
  }

  .unstyled-list {
    list-style: none;
    padding: 0;
  }

  .flex-static {
    flex: 0 0 auto;
  }

  .flex-column {
    display: flex;
    flex-direction: column;
  }

  .flex-row {
    display: flex;
    flex-direction: row;
  }

  .flex-remaining-space {
    flex: 1;
  }

  .grow {
    flex-grow: 1;
  }

  .shrink-0 {
    flex-shrink: 0;
  }

  .v-align {
    display: flex;
    align-items: center;
  }

  .h-align {
    display: flex;
    justify-content: center;
  }

  .top-align {
    display: flex;
    align-items: flex-start;
  }

  .bottom-align {
    display: flex;
    align-items: flex-end;
  }

  .v-align-inline {
    display: inline-flex;
    align-items: center;
  }

  .baseline-align {
    display: flex;
    align-items: baseline;
  }

  .vh-align {
    align-items: center;
    display: flex;
    justify-content: center;
  }

  .unset-align {
    display: flex;
    align-items: unset;
  }

  .space-between {
    display: flex;
    justify-content: space-between;
  }

  .flex-end {
    display: flex;
    justify-content: flex-end;
  }

  .flex-start {
    display: flex;
    justify-content: flex-start;
  }

  .flex-wrap {
    flex-wrap: wrap;
  }

  .no-flex-wrap {
    flex-wrap: nowrap;
  }

  .self-start {
    align-self: flex-start;
  }

  .self-end {
    align-self: flex-end;
  }

  .self-center {
    align-self: center;
  }

  .text-center {
    text-align: center;
  }

  .overflow-hidden {
    overflow: hidden;
  }

  .overflow-auto {
    overflow: auto;
  }

  .text-left {
    text-align: left;
  }

  .text-truncate {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .divide-y > * + * {
    border: 0 solid;
    border-top-width: 1px;
  }

  .divide-x > * + * {
    border: 0 solid;
    border-left-width: 1px;
  }

  .loading {
    animation: ${loadingAnimation} 1.5s ease-in-out 0.5s infinite;
    color: transparent !important;
    border-radius: var(--border-radius-sm);

    &::before,
    &::after,
    & * {
      visibility: hidden !important;
    }
  }

  .rounded {
    border-radius: 6px;
  }

  .no-wrap {
    white-space: nowrap;
  }

  .pre-line {
    white-space: pre-line;
  }

  .block {
    display: block;
  }

  .relative {
    position: relative;
  }

  .fixed {
    position: fixed;
  }

  .inline {
    display: inline;
  }

  .mobile-only {
    @media (${TabletPortraitMin}) {
      display: none !important;
    }
  }

  .desktop-only {
    @media (${BelowTablet}) {
      display: none !important;
    }
  }

  .mobile-fullwidth {
    @media (${BelowTablet}) {
      width: 100%;
    }
  }

  .background-eggshell {
    background-color: ${eggshell};
  }

  .background-white {
    background-color: #fff;
  }

  .rotate {
    animation: ${rotate} 3s linear infinite;
    transform-origin: center;
  }

  .no-interaction {
    pointer-events: none;
  }

  .balance-wrap {
    text-wrap: balance;
  }

  .no-underline {
    &:hover {
      text-decoration: none;
    }
  }

  .pointer-events-auto {
    pointer-events: auto;
  }
`

export const semiOpaqueBackground = css`
  background-color: rgba(135, 159, 163, 0.85);

  @supports (backdrop-filter: blur(16px)) {
    backdrop-filter: blur(16px);
    background-color: rgba(0, 49, 56, 0.4);
  }
`
