import { styled } from '@mui/material'
import { useState } from 'react'
import { useEffect } from 'react'
import { MdClose, MdMenu } from 'react-icons/md'
import { useLocation } from 'react-router-dom'

import { IconButton, body2 } from '@nuna/tunic'

import { Avatar, Sizes as AvatarSizes } from '../Avatar/Avatar'
import { MenuButton } from '../Buttons'
import { Drawer } from '../Drawer'
import { SidebarNavigationItems } from '../Sidebar/SidebarNavigationItems'

const MenuIconContainer = styled('div')`
  display: inline-flex;
  align-items: center;
  font-size: 1.5rem;
  margin-right: 0.25rem;
`

const DrawerContainer = styled('div')`
  padding: 1rem 0 2rem 0;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`

const AvatarContainer = styled('div')`
  display: flex;
  justify-content: center;
  margin-top: 1rem;
`

const CloseButtonContainer = styled('div')`
  display: flex;
  justify-content: flex-end;
  margin-right: 0.5rem;
`

const MenuContainer = styled('div')`
  padding: 1rem;
`

interface ResponsiveMenuProps {
  onLogout?: () => void
  firstName?: string
  lastName?: string
  avatarUrl?: string
}

export function ResponsiveMenu({ firstName, lastName, avatarUrl }: ResponsiveMenuProps) {
  const [isOpen, setIsOpen] = useState(false)
  const location = useLocation()

  const handleOpen = () => {
    setIsOpen(true)
  }

  const handleClose = () => {
    setIsOpen(false)
  }

  useEffect(() => {
    return () => location && handleClose()
  }, [location])

  return (
    <>
      <MenuButton onClick={handleOpen}>
        <MenuIconContainer>
          <MdMenu />
        </MenuIconContainer>
        Menu
      </MenuButton>
      <Drawer isOpen={isOpen} onClose={handleClose} size="18rem" label="Menu">
        <DrawerContainer className="flex-column full-height">
          <div className="flex-column full-height">
            <CloseButtonContainer>
              <IconButton tooltip="Close menu" variant="secondary" onClick={handleClose}>
                <MdClose size={24} color={body2} />
              </IconButton>
            </CloseButtonContainer>
            <AvatarContainer>
              <Avatar size={AvatarSizes.Large} url={avatarUrl} name={{ first: firstName, last: lastName }} />
            </AvatarContainer>
            <h2 className="h5 text-center mt-2 mb-5">
              {firstName} {lastName}
            </h2>
            <MenuContainer className="flex-column flex-remaining-space" style={{ overflow: 'auto' }}>
              <SidebarNavigationItems />
            </MenuContainer>
          </div>
        </DrawerContainer>
      </Drawer>
    </>
  )
}
