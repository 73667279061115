import { IconProps } from './IconProps'

export function IconNumberedList({ size = 24, color = 'currentColor', ...props }: IconProps) {
  return (
    <svg width={size} height={size} viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.6345 9.03958C14.6345 8.4873 15.0822 8.03958 15.6345 8.03958H44.2198C44.7721 8.03958 45.2198 8.4873 45.2198 9.03958V13.0262C45.2198 13.5785 44.7721 14.0262 44.2198 14.0262H15.6345C15.0822 14.0262 14.6345 13.5785 14.6345 13.0262V9.03958ZM14.6345 22.0128C14.6345 21.4605 15.0822 21.0128 15.6345 21.0128H44.2198C44.7721 21.0128 45.2198 21.4605 45.2198 22.0128V25.9994C45.2198 26.5517 44.7721 26.9994 44.2198 26.9994H15.6345C15.0822 26.9994 14.6345 26.5517 14.6345 25.9994V22.0128ZM14.6345 34.9738C14.6345 34.4216 15.0822 33.9738 15.6345 33.9738H44.2198C44.7721 33.9738 45.2198 34.4216 45.2198 34.9738V38.9604C45.2198 39.5127 44.7721 39.9604 44.2198 39.9604H15.6345C15.0822 39.9604 14.6345 39.5127 14.6345 38.9604V34.9738Z"
        fill={color}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.09474 20.0072V11.6132H1.81885V9.62235C2.27132 9.64028 2.72379 9.60441 3.15816 9.51473C3.59253 9.44299 3.97261 9.2995 4.31649 9.08427C4.66036 8.88698 4.94994 8.61794 5.18523 8.27716C5.42051 7.93637 5.5834 7.54179 5.6558 7.03958H7.75526V20.0072H5.09474ZM0.561064 30.3187C0.760151 29.673 1.04973 29.135 1.46601 28.6507C1.86418 28.1844 2.37095 27.8077 2.96821 27.5387C3.56547 27.2696 4.27132 27.1441 5.04957 27.1441C5.64683 27.1441 6.2079 27.2338 6.75086 27.4131C7.29383 27.5925 7.7644 27.8615 8.18067 28.2202C8.59694 28.561 8.92272 28.9915 9.15801 29.4937C9.41139 29.9959 9.51998 30.5698 9.51998 31.1976C9.51998 31.8433 9.41139 32.4172 9.19421 32.8836C8.97702 33.3499 8.70554 33.7624 8.34356 34.1211C7.99968 34.4798 7.60151 34.8206 7.16714 35.1076C6.71467 35.4125 6.28029 35.6995 5.82782 35.9865C5.37535 36.2734 4.94098 36.5963 4.52471 36.9191C4.09034 37.2599 3.72836 37.6545 3.40258 38.085H9.57428V40.3449H0C0 39.5916 0.108593 38.9279 0.325779 38.3899C0.542965 37.8338 0.850645 37.3496 1.21262 36.9012C1.5746 36.4528 2.02707 36.0582 2.53384 35.6815C3.0406 35.3228 3.58357 34.9462 4.12653 34.5516C4.41611 34.3543 4.72379 34.157 5.04957 33.9418C5.37535 33.7445 5.66493 33.5113 5.93642 33.2781C6.2079 33.027 6.42508 32.7401 6.60607 32.4352C6.78706 32.1302 6.87756 31.7715 6.87756 31.3769C6.87756 30.7492 6.69657 30.247 6.33459 29.9062C5.97261 29.5654 5.48395 29.3681 4.92288 29.3681C4.54281 29.3681 4.21703 29.4578 3.94554 29.6372C3.67406 29.8165 3.45688 30.0497 3.29399 30.3367C3.1311 30.6236 3.0225 30.9465 2.95011 31.2873C2.89581 31.628 2.84152 31.9868 2.84152 32.3275H0.30768C0.271482 31.646 0.361977 30.9465 0.561064 30.3187Z"
        fill={color}
      />
    </svg>
  )
}
