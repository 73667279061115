import * as Yup from 'yup'
import { styled } from '@mui/material'
import { Formik, FormikErrors, FormikTouched } from 'formik'
import { noop, omit } from 'lodash'
import { useNavigate } from 'react-router-dom'

import {
  AddressOwner,
  AddressType,
  AvailabilityConstraintInput,
  AvailabilityPeriodsFragment,
  ProviderAvailabilityConstraintsQuery,
  useProviderAddressesQuery,
  useProviderAvailabilityConstraintsQuery,
  useSaveProviderIntakeMutation,
} from '@nuna/api'
import { Layout, LayoutHeader } from '@nuna/common'
import { getAvailabilityConstraintInput } from '@nuna/core'
import { GhostButtonLink, IconCheck, Logo, OutlineButton, greySet, toast, white } from '@nuna/tunic'

import { useProviderAppContext } from '../../shared/ProviderAppContext'
import { AvailabilityFields } from '../SignupIntake/shared/AvailabilityFields'
import { ProviderSignupFormSchema } from '../SignupIntake/shared/provider-signup-intake-types'

const validationSchema: ProviderSignupFormSchema = Yup.object({
  // expectedCaseloadAmount: Yup.number().required('Please set a max number of clients'),
  expectedStartDate: Yup.date().required('Please set a start date').typeError('Please set a valid start date'),
  availabilityConstraints: Yup.array(Yup.object<AvailabilityConstraintInput>()).min(
    1,
    'Please select a day and time for availability',
  ),
  timezone: Yup.string().required('Please select a timezone'),
})

const buildInitialValues = (constraintsData: ProviderAvailabilityConstraintsQuery) => {
  return {
    expectedStartDate: null,
    availabilityConstraints: constraintsData?.providerMe.availabilityPeriods,
    timezone: '',
    availabilityPeriods: constraintsData?.providerMe.availabilityPeriods,
  }
}

export function StandaloneAvailability() {
  const { provider } = useProviderAppContext()
  const { id: providerId } = provider ?? {}

  const navigate = useNavigate()

  const { data: providerAddressData } = useProviderAddressesQuery({
    variables: {
      searchOptions: {
        providerId,
        providerAddressType: AddressType.ProviderPractice,
        owner: AddressOwner.Provider,
      },
    },
    skip: !providerId,
  })

  const { data: constraintsData } = useProviderAvailabilityConstraintsQuery()
  const [saveIntakeMutation, { loading: saveIntakeLoading }] = useSaveProviderIntakeMutation()

  const handleSubmit = async (availabilityValues: AvailabilityConstraintInput[], timezone: string) => {
    if (!providerId) {
      return
    }
    try {
      await saveIntakeMutation({
        variables: {
          id: providerId,
          expectedAvailability: availabilityValues,
          timezone,
        },
      })

      navigate('/availability')
    } catch (error) {
      toast.urgent('There was a problem saving your availability.')
    }
  }

  if (!constraintsData) {
    return <div></div>
  }

  return (
    <>
      <LayoutHeader
        center={<Logo color="plum" height={32} renderText />}
        right={<GhostButtonLink to="/">Exit</GhostButtonLink>}
        backgroundColor={white.hex}
      />
      <Layout backgroundColor={greySet[0].hex}>
        <Wrapper>
          <h3>Let's open the doors for business!</h3>
          <Formik
            initialValues={buildInitialValues(constraintsData)}
            onSubmit={noop}
            validationSchema={validationSchema}
          >
            {({ touched, errors, setFieldValue, values, handleBlur }) => {
              return (
                <>
                  <AvailabilityFields
                    touched={{
                      expectedStartDate: touched.expectedStartDate,
                      availabilityPeriods: touched.availabilityPeriods as FormikTouched<unknown>,
                      timezone: touched.timezone,
                    }}
                    errors={{
                      expectedStartDate: errors.expectedStartDate,
                      availabilityPeriods: errors.availabilityPeriods as FormikErrors<unknown>,
                      timezone: errors.timezone,
                    }}
                    timezone={values.timezone}
                    expectedStartDate={values.expectedStartDate}
                    availabilityConstraints={values.availabilityPeriods as unknown as AvailabilityPeriodsFragment}
                    providerAddressData={providerAddressData?.providerAddresses ?? []}
                    setFieldValue={setFieldValue}
                    handleBlur={handleBlur}
                    acceptingClientsDate={false}
                  />
                  <OutlineButton
                    isLoading={saveIntakeLoading}
                    onClick={() =>
                      handleSubmit(
                        getAvailabilityConstraintInput({
                          availabilityConstraints: {
                            ...values.availabilityPeriods,
                            allowDayAndTime: values.availabilityPeriods.allowDayAndTime.map(period =>
                              omit(period, 'id'),
                            ),
                          },
                        }),
                        values.timezone,
                      )
                    }
                    className="mb-4 mt-6"
                  >
                    <IconCheck /> Add Availability
                  </OutlineButton>
                </>
              )
            }}
          </Formik>
        </Wrapper>
      </Layout>
    </>
  )
}

const Wrapper = styled('div')`
  max-width: 932px;
  margin-left: auto;
  margin-right: auto;
  padding-left: var(--margin-3);
  padding-right: var(--margin-3);
  flex: 1;
`
