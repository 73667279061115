import { useState } from 'react'

interface FocusAwareInputProps {
  children: (isFocused: boolean, setIsFocused: (isFocused: boolean) => void) => JSX.Element
}

/* Render prop pattern -  https://reactjs.org/docs/render-props.html */
export function FocusAwareInput({ children }: FocusAwareInputProps) {
  const [isFocused, setIsFocused] = useState(false)

  return children(isFocused, setIsFocused)
}
