import { styled } from '@mui/material'
import moment from 'moment'

import { type ProviderDetailsQuery, type PublicAvailabilitySlot, type PublicProviderFragment } from '@nuna/api'
import { Avatar, csx, shadowDepth } from '@nuna/tunic'

interface AppointmentDrawerHeaderProps {
  provider: ProviderDetailsQuery['provider'] | PublicProviderFragment | null | undefined
  timeSlot?: PublicAvailabilitySlot
}

export function AppointmentDrawerHeader({ provider, timeSlot }: AppointmentDrawerHeaderProps) {
  return (
    <TimeSlotHeader className="mb-3 flex-start v-align">
      <Avatar url={provider?.avatarUrl} size="sm" />

      {timeSlot ? (
        <div className="ml-1 mt-1">
          <p className="large text-medium mb-1">
            {moment(timeSlot.start).format('dddd, MMMM Do')}
            <br />
            from {moment(timeSlot.start).format('h:mm')}&ndash;{moment(timeSlot.end).format('h:mma')}
          </p>

          <p className={csx(['mb-0', { loading: provider === null }])}>
            with {provider?.firstName} {provider?.lastName}
          </p>
        </div>
      ) : (
        <p style={{ flex: 1 }}>
          Before we schedule you with {provider?.firstName} {provider?.lastName}, let’s start with some basic
          information.
        </p>
      )}
    </TimeSlotHeader>
  )
}

const TimeSlotHeader = styled('div')`
  box-shadow: ${shadowDepth(1)};
  padding: 0.5rem;
  margin-top: -1rem; // need to offset the padding on AppointmentDrawer PaperProps. I don't love this solution but it works for now
`
