import { styled } from '@mui/material'
import moment from 'moment'
import { useMemo } from 'react'

import { CapStatus, EapCoverageDetailsFragment } from '@nuna/api'
import { EapCoverageCard, capInfoUtils } from '@nuna/coverage'
import { ProgressBar, greySet } from '@nuna/tunic'

interface Props {
  coverage: EapCoverageDetailsFragment
  creditCardOnFile: boolean
}

export function EapCoverageGlance({ coverage, creditCardOnFile }: Props) {
  const { sessionsCap, resetDate, sessionsExhausted, sessionsScheduled } = coverage

  const { progressBars } = useMemo(
    () =>
      capInfoUtils.generateCapInfoDisplayData(
        {
          sessionsCountingTowardsCap: coverage.sessionsExhausted,
          sessionAllowanceToType: coverage.sessionsCap,
          sessionsAttended: coverage.sessionsAttended,
          capStatus: coverage.sessionsCapStatus as CapStatus,
        },
        creditCardOnFile,
      ),
    [coverage, creditCardOnFile],
  )

  return (
    <>
      <EapCoverageCard coverage={coverage} />
      <div className="ml-1">
        <div className="text-medium text-medium-grey mt-2">Covered for {sessionsCap} total sessions</div>
        <ProgressBarWrapper>
          <ProgressBar
            className="mr-1"
            style={{ maxWidth: '108px', transform: 'translateY(1px)' }}
            bars={progressBars}
          />
          {sessionsExhausted} Attended • {sessionsScheduled} Upcoming
        </ProgressBarWrapper>
        <div className="mt-1">Resets annually on {moment(resetDate).format('MMMM DD, YYYY')}</div>
      </div>
    </>
  )
}

const ProgressBarWrapper = styled('div')`
  align-items: center;
  color: ${greySet[80].hex};
  display: flex;
  margin-top: 0.25rem;
`
