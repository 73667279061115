import * as Yup from 'yup'
import { styled } from '@mui/material'
import { FormikProps } from 'formik'
import { noop, sortBy } from 'lodash'
import { FormEventHandler } from 'react'

import { BasicSpecialtyFragment, FullProviderFragment, useSpecialtiesQuery, useUpdateProviderMutation } from '@nuna/api'
import { ProfileSection } from '@nuna/common'
import { specialtiesService } from '@nuna/core'
import { Chip, ChipGroup, ChipLoader, Grid, Radio } from '@nuna/tunic'

import { IncompleteSectionAlert } from '../IncompleteSectionAlert'
import { VisibleToClientsLabel } from './VisibleToClientsLabel'

interface SpecialtiesValues {
  specialties: string[]
}

type SpecialtiesUpdateProvider = Pick<FullProviderFragment, 'specialties' | 'id'>

export interface SpecialtiesSectionProps extends SpecialtiesUpdateProvider {
  refetch?: () => void
}

interface Props {
  headingText: string
  selectedSpecialties: BasicSpecialtyFragment[]
  specialtiesFilter: (specialties: BasicSpecialtyFragment[]) => BasicSpecialtyFragment[]
  providerId: string
  refetch: () => void
  description: string
  min?: number
  preselected?: string[]
  isRadio?: boolean
}

export function SpecialtiesSectionBase({
  selectedSpecialties,
  specialtiesFilter,
  providerId,
  refetch,
  headingText,
  description,
  min,
  preselected = [],
  isRadio,
}: Props) {
  const [updateProvider, { loading: updateLoading }] = useUpdateProviderMutation()
  const { data: specialtiesData, loading: areSpecialtiesLoading } = useSpecialtiesQuery()

  const unsorted = specialtiesFilter(specialtiesData?.specialties ?? [])
  const availableSpecialties =
    unsorted[0] && unsorted[0].rankInCategory ? sortBy(unsorted, s => s.rankInCategory) : unsorted
  const displaySpecialties = specialtiesFilter(selectedSpecialties)

  if (isRadio) {
    const allGendersOption = {
      name: 'All Options',
      id: '0',
      specialtyCategory: {
        id: '00',
        name: 'All',
      },
    }

    availableSpecialties.unshift(allGendersOption)

    if (displaySpecialties.length === 0) {
      displaySpecialties.unshift(allGendersOption)
    }
  }

  const hasSelectedSpecialties = !!displaySpecialties.length

  const specialtiesForEdit = hasSelectedSpecialties
    ? displaySpecialties
    : specialtiesService.getSpecialtiesByNames(specialtiesData?.specialties ?? [], preselected)

  const otherSpecialties = selectedSpecialties
    .filter(specialty => !specialtiesForEdit.includes(specialty))
    .map(specialty => ({ id: specialty.id }))

  const initialValues: SpecialtiesValues = {
    specialties: specialtiesForEdit.map(specialty => specialty.id),
  }

  const submit = async (values: SpecialtiesValues) => {
    const specialties = [...otherSpecialties, ...values.specialties.map(value => ({ id: value }))]

    await updateProvider({
      variables: Object.assign({ id: providerId }, { specialties: specialties.filter(s => s.id !== '0') }),
    })

    refetch()
  }

  const disabled = (formikProps: FormikProps<SpecialtiesValues>) => {
    if (min) {
      return !formikProps.isValid || formikProps.values.specialties.length < min
    }

    return !formikProps.isValid
  }

  return (
    <ProfileSection
      heading={
        <>
          {headingText} <VisibleToClientsLabel className="ml-1" />
        </>
      }
      useOutlineSaveButton
      description={description}
      displayWidth="full"
      updateLoading={updateLoading}
      initialValues={initialValues}
      handleSubmit={submit}
      disabled={disabled}
      sectionComplete={hasSelectedSpecialties}
      renderDisplayValue={
        hasSelectedSpecialties ? (
          <ChipGroup>
            {displaySpecialties.map(specialty => (
              <Chip key={specialty.id} checked onChange={noop} small disabled>
                {specialty.name}
              </Chip>
            ))}
          </ChipGroup>
        ) : (
          <IncompleteSectionAlert />
        )
      }
      renderForm={({ values, setFieldValue }) => {
        const handleChange: FormEventHandler<HTMLInputElement> = chip => {
          const toggleValue = chip.currentTarget.value
          let newValues: string[] = []

          if (isRadio) {
            newValues = [toggleValue]
          } else if (values.specialties.includes(toggleValue)) {
            newValues = values.specialties.filter(specialty => specialty !== toggleValue)
          } else {
            newValues = [...values.specialties, toggleValue]
          }

          setFieldValue('specialties', newValues)
        }

        return (
          <Grid container>
            <Grid
              size={{
                xs: 12,
                sm: 10,
              }}
            >
              <ChipGroupHeader>
                <div>
                  <span className="text-medium">
                    {isRadio
                      ? 'Please select one'
                      : min && values.specialties.length < min
                      ? `Please select a minimum of ${min}`
                      : min === 0
                      ? 'Select any that you are uniquely experienced with helping, or leave blank'
                      : 'Please select any that apply'}
                  </span>
                  {!hasSelectedSpecialties && !!preselected.length && (
                    <span className="ml-1 text-light">(we pre-selected the most common)</span>
                  )}
                </div>
              </ChipGroupHeader>

              {areSpecialtiesLoading ? (
                <ChipLoader quantity={20} />
              ) : (
                <ChipGroup style={{ position: 'relative' }}>
                  {availableSpecialties.map(challenge => {
                    if (isRadio) {
                      return (
                        <Radio
                          key={challenge.id}
                          value={challenge.id}
                          checked={values.specialties.includes(challenge.id)}
                          onChange={handleChange}
                        >
                          {challenge.name}
                        </Radio>
                      )
                    }

                    return (
                      <Chip
                        key={challenge.id}
                        value={challenge.id}
                        checked={values.specialties.includes(challenge.id)}
                        onChange={handleChange}
                      >
                        {challenge.name}
                      </Chip>
                    )
                  })}
                </ChipGroup>
              )}
            </Grid>
          </Grid>
        )
      }}
      validationSchema={Yup.object().shape({
        specialties: min === 0 ? Yup.array<string>() : Yup.array<string>().required(),
      })}
    ></ProfileSection>
  )
}

const ChipGroupHeader = styled('div')`
  display: block;
  padding-bottom: var(--margin-3);
`
