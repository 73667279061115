import { ReactNode, createContext, useContext, useEffect, useState } from 'react'

import { CredentialingChecklist, useCredentialingChecklistQuery } from '@nuna/api'

type CredentialingChecklistContext = {
  checklist: CredentialingChecklist | null
  isLoading: boolean
}

export const CredentialingChecklistContext = createContext<CredentialingChecklistContext | null>(null)

interface Props {
  children: ReactNode
  providerId: string
}
export function CredentialingChecklistContextProvider({ children, providerId }: Props) {
  const [checklist, setChecklist] = useState<CredentialingChecklist | null>(null)

  const { data, loading } = useCredentialingChecklistQuery({
    variables: { providerId },
    skip: !providerId,
  })

  useEffect(() => {
    if (data) {
      setChecklist(data.credentialingChecklistData)
    }
  }, [data])

  return (
    <CredentialingChecklistContext.Provider
      value={{
        checklist,
        isLoading: loading,
      }}
    >
      {children}
    </CredentialingChecklistContext.Provider>
  )
}

export function useCredentialingChecklistContext() {
  const context = useContext(CredentialingChecklistContext)
  if (!context) {
    throw new Error('useCredentialingContext must be used within a CredentialingContextProvider')
  }
  return context
}
