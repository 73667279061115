import { AnimatePresence } from 'framer-motion'
import { MouseEventHandler } from 'react'

import { PrevalidationError } from '@nuna/api'
import { Collapse, ContextualAlert, TextButton } from '@nuna/tunic'

interface PrevalidationErrorsProps {
  prevalidationErrors: PrevalidationError[]
  onLoginClick: MouseEventHandler<HTMLButtonElement>
}

export function PrevalidationErrors({ prevalidationErrors, onLoginClick }: PrevalidationErrorsProps) {
  return (
    <AnimatePresence>
      {prevalidationErrors.map(error => {
        if (error.field === 'email') {
          return (
            <Collapse key={error.field} isOpen>
              <ContextualAlert className="mt-2" intent="urgent">
                <div>
                  Oops, we encountered an error. If you already have an account, please{' '}
                  <TextButton variant="destroy" onClick={onLoginClick}>
                    log in
                  </TextButton>{' '}
                  instead.
                </div>
              </ContextualAlert>
            </Collapse>
          )
        }

        return (
          <Collapse key={error.field} isOpen>
            <ContextualAlert largeText intent="urgent">
              {error.error}
            </ContextualAlert>
          </Collapse>
        )
      })}
    </AnimatePresence>
  )
}
