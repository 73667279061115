import { css } from '@mui/material'
import { AnimatePresence, motion } from 'framer-motion'
import React from 'react'
import ReactDOM from 'react-dom'
import { Config, TriggerType, usePopperTooltip } from 'react-popper-tooltip-4'
import 'react-popper-tooltip-4/dist/styles.css'

import { greySet } from '../../styles/colorSets'
import { borderGrey } from '../../styles/colors'
import { deviceUtils } from '../../utils/device'

export interface TooltipProps {
  children: JSX.Element
  content: React.ReactNode
  options?: Config
  showOnMobileTap?: boolean
}

export function Tooltip({ children, content, options, showOnMobileTap = false }: TooltipProps) {
  const trigger: TriggerType[] = ['focus']

  if (!deviceUtils.isTouchDevice()) {
    trigger.push('hover')
  } else if (showOnMobileTap) {
    trigger.push('click')
  }

  const { getArrowProps, getTooltipProps, setTooltipRef, setTriggerRef, visible } = usePopperTooltip({
    ...options,
    placement: 'top',
    delayShow: 300,
    offset: [0, 10],
    trigger,
  })

  return (
    <>
      {React.cloneElement(children, { ref: setTriggerRef })}
      {ReactDOM.createPortal(
        <AnimatePresence>
          {visible && (
            <motion.div
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              transition={{ duration: 0.15 }}
              exit={{ opacity: 0 }}
              ref={setTooltipRef}
              {...getTooltipProps({ className: 'tooltip-container' })}
            >
              {content}
              <div {...getArrowProps({ className: 'tooltip-arrow' })} />
            </motion.div>
          )}
        </AnimatePresence>,
        document.body,
      )}
    </>
  )
}

// imported into GlobalStyle.ts
export const tooltipStyles = css`
  .tooltip-container {
    --tooltipBackground: ${greySet[90].hex};
    --tooltipBorder: transparent;

    border-radius: 4px;
    box-shadow: none;
    color: ${borderGrey};
    max-width: 220px;
    font-size: 13px;
    font-weight: 500;
    padding: 0.375rem 0.625rem;
    text-align: center;
  }
`
