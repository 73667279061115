import { useDaily } from '@daily-co/daily-react'
import { styled } from '@mui/material'
import { useEffect, useState } from 'react'

import {
  FillButton,
  IconCommentFilled,
  IconVideo,
  IconVirtualBGFill,
  Tooltip,
  makeTypographyComponent,
  toast,
} from '@nuna/tunic'

import { useVideoCallContext } from '../../context/VideoCallContext'
import { getDailyDevicePreferences } from '../../util/devicePreferences'
import { BackgroundOptions } from '../BackgroundOptions'
import { DeviceSettings } from '../DeviceSettings'
import { TopbarToggleButton } from '../Topbar/components/TopbarToggleButton'
import { VideoDialog } from '../VideoDialog'
import { VideoSettingsPreview } from '../VideoSettingsPreview'
import { AutonotesFooter } from './components/AutonotesFooter'
import { HairCheckChat } from './components/HairCheckChat'

type TabType = 'settings' | 'image' | 'chat'

export function HairCheck() {
  const callObject = useDaily()
  const { localUser, isCameraStarted, setIsCameraStarted } = useVideoCallContext()
  const [isJoining, setIsJoining] = useState(false)
  const [activeTab, setActiveTab] = useState<TabType>('settings')

  useEffect(() => {
    if (isCameraStarted) return

    callObject?.startCamera(getDailyDevicePreferences()).then(() => setIsCameraStarted(true))
  }, [callObject, isCameraStarted, setIsCameraStarted])

  const handleJoinCall = async () => {
    setIsJoining(true)
    try {
      console.info('[Daily Lifecycle] Attempting to join call')
      await callObject?.join()

      const access = callObject?.accessState().access
      const mustRequestAccess = access === 'unknown' || access?.level !== 'full'

      if (mustRequestAccess) {
        console.info('[Daily Lifecycle] Requesting access to call', access)
        await callObject?.requestAccess({ access: { level: 'full' }, name: localUser.loginId })
      } else {
        console.info('[Daily Lifecycle] Joined call')
      }
    } catch (e) {
      console.error(e)
      toast.urgent('There was an error joining the video call. Please refresh the page and try again.')
    } finally {
      setIsJoining(false)
    }
  }

  const getButtonProps = (tab: TabType) => ({
    onClick: () => setActiveTab(tab),
    active: activeTab === tab,
  })

  return (
    <VideoDialog unpadded isOpen scroll="body">
      <div className="v-align p-2">
        <span className="text-medium">
          {localUser.isProvider ? 'Join the session when ready' : "Knock when you're ready"}
        </span>

        <FillButton
          className="ml-auto"
          data-component={localUser.isProvider ? 'confirm-provider-video-settings' : 'video-knock'}
          onClick={handleJoinCall}
          isLoading={isJoining}
        >
          {localUser.isProvider ? 'Join' : 'Knock'}
        </FillButton>
      </div>

      <VideoSettingsPreview persist />

      <Tabs className="v-align px-2 py-1">
        <Tooltip content="Audio/Video settings">
          <TabButton aria-label="Audio/Video settings" {...getButtonProps('settings')}>
            <IconVideo size={20} />
          </TabButton>
        </Tooltip>

        <Tooltip content="Background options">
          <TabButton aria-label="Background options" {...getButtonProps('image')}>
            <IconVirtualBGFill size={22} />
          </TabButton>
        </Tooltip>

        {!localUser.isProvider && (
          <Tooltip content="Send a message">
            <TabButton aria-label="Send a message" {...getButtonProps('chat')}>
              <IconCommentFilled size={20} />
            </TabButton>
          </Tooltip>
        )}
      </Tabs>

      {(() => {
        switch (activeTab) {
          case 'settings':
            return (
              <TabContent>
                <DeviceSettings />
              </TabContent>
            )
          case 'image':
            return (
              <TabContent>
                <BackgroundOptions />
              </TabContent>
            )
          case 'chat':
            return <HairCheckChat />
        }
      })()}

      <AutonotesFooter />
    </VideoDialog>
  )
}

const TabContent = styled(makeTypographyComponent('p-2', 'div'))`
  height: 325px;
  display: flex;
`

const Tabs = styled('div')`
  border-bottom: rgba(255, 255, 255, 0.26) 1px solid;
  gap: 0.75rem;
`

const TabButton = styled(TopbarToggleButton)`
  width: 36px;
  height: 36px;
`
