import { useEffect } from 'react'

import { type Role, useSupportIdentifierQuery } from '@nuna/api'
import { useAuthDataContext } from '@nuna/auth'
import type { Audience } from '@nuna/core'
import type { EnvironmentContextValues } from '@nuna/environment'

import { supportService } from '../lib/support'

interface Props {
  audience: Audience
  environment: EnvironmentContextValues
}

export function useSupportChat({ audience, environment }: Props) {
  const { login, loggedIn } = useAuthDataContext()
  const { data: jwtData, refetch } = useSupportIdentifierQuery({
    fetchPolicy: 'network-only',
    skip: !loggedIn,
  })
  const zendeskJwt = jwtData?.supportIdentifier

  useEffect(() => {
    // Skip zendesk in CI so that it doesn't interfere with e2e tests
    if (!environment.CI) {
      supportService.excludeFromTrackingOnOpen()
    }
  }, [audience, environment.CI])

  useEffect(() => {
    if (zendeskJwt) supportService.loginUser(zendeskJwt, refetch)
  }, [zendeskJwt, refetch])

  useEffect(() => {
    if (login?.role) {
      supportService.populateUserFields({ ...login, role: login.role as Role }, environment)
    }
  }, [login, environment])

  useEffect(() => {
    if (loggedIn) refetch()
    else supportService.logoutUser()
  }, [refetch, loggedIn])
}
