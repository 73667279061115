import * as Sentry from '@sentry/browser'
import { Client, State } from '@twilio/conversations'
import { useEffect, useState } from 'react'

/**
 * Initialize Twilio chat client
 * @param token Twilio chat token for the user (generated on backend)
 * @returns Twilio chat client once initialized, otehrwise undefined
 */
export function useTwilioChatClient(token: string) {
  const [chatClient] = useState<Client>(new Client(token))
  const [isClientInitialized, setIsClientInitialized] = useState(false)

  useEffect(() => {
    const handleClientStateChanged = (state: State) => {
      if (state === 'failed') {
        console.error('[DEBUG] chatClient::stateChanged:failed', state)
        Sentry.captureEvent({
          message: 'Failed to initialize Conversation client',
        })
        return
      }

      if (state === 'initialized') {
        setIsClientInitialized(true)
        return
      }

      console.error('[DEBUG] chatClient::stateChanged::unexpected', state)
    }

    chatClient.on('stateChanged', handleClientStateChanged)

    return () => {
      chatClient.off('stateChanged', handleClientStateChanged)
    }
  }, [chatClient])

  return isClientInitialized ? chatClient : undefined
}
