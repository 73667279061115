import * as Yup from 'yup'
import { useApolloClient } from '@apollo/client'
import { styled } from '@mui/material'
import { InputAdornment, InputProps, TextField as MUITextField } from '@mui/material'
import { Form, Formik } from 'formik'
import { omit, pick, startCase } from 'lodash'
import moment from 'moment'

import {
  InsurancePolicyInput,
  RelationshipToSubscriber,
  SearchInsurancePoliciesQuery,
  useSaveInsurancePolicyMutation,
  useVerifyInsuranceCoverageMutation,
} from '@nuna/api'
import { formService, numberService } from '@nuna/core'
import {
  AttachmentChip,
  Card,
  CardBody,
  Checkbox,
  ContextualAlert,
  DatePicker,
  FillButton,
  Grid,
  OutlineButton,
  Select,
  TextField,
  makeTypographyComponent,
  toast,
} from '@nuna/tunic'

import { PayerPlanSelect } from '../../PayerPlanSelect'

const { composeHelperText } = formService

type Policy = SearchInsurancePoliciesQuery['searchInsurancePolicies']['items'][number]

interface PolicyFormProps {
  policy: Policy
  visible: boolean
}

type InsurancePolicyFormValues = Omit<
  InsurancePolicyInput,
  | 'id'
  | 'effectiveDate'
  | 'endDate'
  | 'insuredMiddleName'
  | 'insuredSsn'
  | 'isPrimaryInsurance'
  | 'patientId'
  | 'realtimeVerificationSkipped'
>

const insurancePolicySchema = Yup.object<InsurancePolicyFormValues>().shape({
  memberId: Yup.string().required('Member ID is required'),
  active: Yup.boolean().required('Active is required'),
  isDependent: Yup.boolean().required('Must specify whether they are a dependent'),
  insuredDob: Yup.string().required('Date of birth is required'),
  insuredFirstName: Yup.string().required('First name is required'),
  insuredLastName: Yup.string().required('Last name is required'),
  relationshipToSubscriber: Yup.string().required(),
  coinsurance: Yup.number().min(0).nullable(),
  copay: Yup.number().min(0).nullable(),
  failureReason: Yup.string().nullable(),
  failureType: Yup.string().nullable(),
  familyDeductible: Yup.number().min(0).nullable(),
  familyDeductibleRemaining: Yup.number().min(0).nullable(),
  familyOutOfPocketMax: Yup.number().min(0).nullable(),
  familyOutOfPocketMaxRemaining: Yup.number().min(0).nullable(),
  groupId: Yup.string().nullable(),
  individualDeductible: Yup.number().min(0).nullable(),
  individualDeductibleRemaining: Yup.number().min(0).nullable(),
  individualOutOfPocketMax: Yup.number().min(0).nullable(),
  individualOutOfPocketMaxRemaining: Yup.number().min(0).nullable(),
  insurancePayerId: Yup.string().required('Insurance payer is required'),
  insurancePayerPlanId: Yup.string().nullable(),
  insuredAddressLineOne: Yup.string().nullable(),
  insuredAddressLineTwo: Yup.string().nullable(),
  insuredCity: Yup.string().nullable(),
  insuredState: Yup.string().nullable(),
  insuredZipCode: Yup.string().nullable(),
  verifiedAt: Yup.date().nullable(),
})

export function PolicyForm({ policy, visible }: PolicyFormProps) {
  const apolloClient = useApolloClient()
  const [verifyInsuranceCoverage, { loading: verifyLoading }] = useVerifyInsuranceCoverageMutation({
    refetchQueries: ['SearchInsurancePolicies'],
  })
  const [savePolicy, { loading: saveLoading }] = useSaveInsurancePolicyMutation({
    refetchQueries: ['SearchInsurancePolicies'],
  })

  async function handleVerifyInsurance(updatedPolicy: InsurancePolicyFormValues) {
    const isDependent = updatedPolicy.isDependent || false
    try {
      const response = await verifyInsuranceCoverage({
        variables: {
          input: {
            isDependent,
            patientId: policy.patientId,
            insurancePayerId: policy.insurancePayer?.id,
            subscriber: {
              memberId: (updatedPolicy.memberId ?? '').trim(),
              firstName: (updatedPolicy.insuredFirstName ?? '').trim(),
              lastName: (updatedPolicy.insuredLastName ?? '').trim(),
              dateOfBirth: updatedPolicy.insuredDob,
              relationshipToSubscriber: RelationshipToSubscriber.Self,
            },
            ...(isDependent && {
              dependent: {
                memberId: (updatedPolicy.memberId ?? '').trim(),
                firstName: (policy.patient.firstName ?? '').trim(),
                lastName: (policy.patient.lastName ?? '').trim(),
                dateOfBirth: policy.patient.dob,
                relationshipToSubscriber: updatedPolicy.relationshipToSubscriber,
              },
            }),
          },
        },
      })

      if (response.errors) {
        throw Error(response.errors.map(e => e.message).join(' '))
      }

      toast.success('Coverage verification responded successfully')
    } catch (error) {
      toast.urgent(`Failed to verify coverage ${(error as Error).message}`)
    }
    apolloClient.refetchQueries({
      include: ['PatientCoveragesAndIntakeCompleteness', 'SearchInsurancePolicyValidations'],
    })
  }

  async function handleSubmit(updatedPolicy: InsurancePolicyFormValues) {
    try {
      const saveResponse = await savePolicy({
        variables: {
          insurancePolicyInput: {
            id: policy.id,
            patientId: policy.patientId,
            ...updatedPolicy,
            copay: numberService.dollarsToCents(updatedPolicy.copay ?? 0),
            familyDeductible: numberService.dollarsToCents(updatedPolicy.familyDeductible ?? 0),
            familyDeductibleRemaining: numberService.dollarsToCents(updatedPolicy.familyDeductibleRemaining ?? 0),
            familyOutOfPocketMax: numberService.dollarsToCents(updatedPolicy.familyOutOfPocketMax ?? 0),
            familyOutOfPocketMaxRemaining: numberService.dollarsToCents(
              updatedPolicy.familyOutOfPocketMaxRemaining ?? 0,
            ),
            individualDeductible: numberService.dollarsToCents(updatedPolicy.individualDeductible ?? 0),
            individualDeductibleRemaining: numberService.dollarsToCents(
              updatedPolicy.individualDeductibleRemaining ?? 0,
            ),
            individualOutOfPocketMax: numberService.dollarsToCents(updatedPolicy.individualOutOfPocketMax ?? 0),
            individualOutOfPocketMaxRemaining: numberService.dollarsToCents(
              updatedPolicy.individualOutOfPocketMaxRemaining || 0,
            ),
          },
        },
      })

      if (saveResponse.errors) {
        throw Error(saveResponse.errors.map(e => e.message).join(' '))
      }

      toast.success('Policy updated successfully')

      // Cause the coverage panel to get reloaded when going from active to inactive
      apolloClient.refetchQueries({ include: ['PatientCoveragesAndIntakeCompleteness'] })
    } catch (error) {
      toast.urgent(`Failed to update insurance policy ${(error as Error).message}`)
    }
  }

  async function handleMarkVerified() {
    try {
      const savedResponse = await savePolicy({
        variables: {
          insurancePolicyInput: {
            id: policy.id,
            patientId: policy.patientId,
            verifiedAt: moment.utc().toISOString(),
          },
        },
      })

      if (savedResponse.errors) {
        throw Error(savedResponse.errors.map(e => e.message).join(' '))
      }

      toast.success('Policy marked as verified successfully')
    } catch (error) {
      toast.urgent(`Failed to mark insurance policy as verified ${(error as Error).message}`)
    }
  }

  return (
    <Formik
      initialValues={{
        ...pick(policy, [
          'memberId',
          'active',
          'insuredDob',
          'failureReason',
          'failureType',
          'groupId',
          'insuredAddressLineOne',
          'insuredAddressLineTwo',
          'insuredCity',
          'insuredState',
          'insuredZipCode',
          'verifiedAt',
          'coinsurance',
        ]),
        insurancePayerId: policy.insurancePayer?.id,
        insurancePayerPlanId: policy.insurancePayerPlan?.id,
        isDependent: policy.isDependent ?? false,
        insuredFirstName: policy.insuredFirstName || policy.patient.firstName,
        insuredLastName: policy.insuredLastName || policy.patient.lastName,
        relationshipToSubscriber: policy.relationshipToSubscriber ?? RelationshipToSubscriber.Self,
        copay: numberService.centsToDollars(policy.copay ?? 0),
        familyDeductible: numberService.centsToDollars(policy.familyDeductible ?? 0),
        familyDeductibleRemaining: numberService.centsToDollars(policy.familyDeductibleRemaining ?? 0),
        familyOutOfPocketMax: numberService.centsToDollars(policy.familyOutOfPocketMax ?? 0),
        familyOutOfPocketMaxRemaining: numberService.centsToDollars(policy.familyOutOfPocketMaxRemaining ?? 0),
        individualDeductible: numberService.centsToDollars(policy.individualDeductible ?? 0),
        individualDeductibleRemaining: numberService.centsToDollars(policy.individualDeductibleRemaining ?? 0),
        individualOutOfPocketMax: numberService.centsToDollars(policy.individualOutOfPocketMax ?? 0),
        individualOutOfPocketMaxRemaining: numberService.centsToDollars(policy.individualOutOfPocketMaxRemaining || 0),
      }}
      validationSchema={insurancePolicySchema}
      onSubmit={handleSubmit}
      enableReinitialize
    >
      {({ values, handleChange, handleBlur, touched, errors, setFieldValue, dirty, isValid }) => {
        const isError = (key: keyof InsurancePolicyFormValues) => {
          return !!touched[key] && !!errors[key]
        }
        const helperText = (key: keyof InsurancePolicyFormValues, text = '') => {
          const isTouched = touched[key] as boolean
          const errorMsg = errors[key] as string | undefined
          return composeHelperText(text, errorMsg, isTouched)
        }

        const fieldProps = (key: keyof InsurancePolicyFormValues) => ({
          name: key,
          error: isError(key),
          onBlur: handleBlur,
          onChange: handleChange,
          helperText: helperText(key),
        })

        const moneyAdornmentInputProps: Partial<InputProps> = {
          startAdornment: <InputAdornment position="start">$</InputAdornment>,
        }

        return (
          <Form style={{ display: visible ? 'block' : 'none' }}>
            <FormHeading>e-Check</FormHeading>
            <Card className="mb-2">
              <CardBody>
                <Grid container spacing={2} alignItems="center">
                  <Grid
                    size={{
                      xs: 12,
                      md: 4,
                    }}
                  >
                    <Checkbox checked={values.isDependent ?? false} {...omit(fieldProps('isDependent'), 'helperText')}>
                      Dependent
                    </Checkbox>
                  </Grid>
                  <Grid
                    size={{
                      xs: 12,
                      md: 4,
                    }}
                  >
                    <TextField label="Member ID" value={values.memberId} {...fieldProps('memberId')} />
                  </Grid>

                  <Grid
                    size={{
                      xs: 12,
                      md: 4,
                    }}
                  >
                    <Select
                      label="Relationship to client"
                      value={values.relationshipToSubscriber ?? RelationshipToSubscriber.Self}
                      {...fieldProps('relationshipToSubscriber')}
                    >
                      {Object.values(RelationshipToSubscriber).map(relationship => (
                        <option key={relationship} value={relationship}>
                          {startCase(relationship.toLowerCase())}
                        </option>
                      ))}
                    </Select>
                  </Grid>
                  <Grid
                    size={{
                      xs: 12,
                      md: 4,
                    }}
                  >
                    <TextField
                      label="Insured First Name"
                      value={values.insuredFirstName}
                      {...fieldProps('insuredFirstName')}
                    />
                  </Grid>
                  <Grid
                    size={{
                      xs: 12,
                      md: 4,
                    }}
                  >
                    <TextField
                      label="Insured Last Name"
                      value={values.insuredLastName}
                      {...fieldProps('insuredLastName')}
                    />
                  </Grid>
                  <Grid
                    size={{
                      xs: 12,
                      md: 4,
                    }}
                  >
                    <DatePicker
                      label="Date of Birth"
                      value={moment(values.insuredDob)}
                      disableFuture
                      {...fieldProps('insuredDob')}
                      onChange={dob => {
                        if (dob?.isValid()) {
                          setFieldValue('insuredDob', dob.format('YYYY-MM-DD'), true)
                        }
                      }}
                    />
                  </Grid>
                  <Grid className="text-right" size={12}>
                    {policy.updatedBy && <ContextualAlert>Last Updated by {policy.updatedBy}</ContextualAlert>}
                    {policy.verifiedAt && (
                      <ContextualAlert className="ml-2">
                        Last Verified {moment(policy.verifiedAt).format('l LT')}
                      </ContextualAlert>
                    )}
                    <FillButton
                      type="button"
                      variant="primary"
                      className="ml-2"
                      isLoading={verifyLoading}
                      disabled={!isValid}
                      onClick={() => handleVerifyInsurance(values)}
                    >
                      Perform e-Check
                    </FillButton>
                  </Grid>
                </Grid>
              </CardBody>
            </Card>
            <FormHeading className="mt-3">Policy Details</FormHeading>
            <Card>
              <CardBody>
                <Grid container spacing={2} alignItems="center">
                  <Grid size={12}>
                    <ContextualAlert intent="caution" className="mb-2">
                      Only edit the policy using the fields below when unable to get coverage to work properly.
                      Subsequent coverage attempts may overwrite your changes!
                    </ContextualAlert>
                  </Grid>
                  <Grid size={12}>
                    <Checkbox checked={!!values.active} {...omit(fieldProps('active'), 'helperText')}>
                      Active
                    </Checkbox>
                  </Grid>
                  <Grid
                    className="h6"
                    size={{
                      xs: 12,
                      md: 6,
                    }}
                  >
                    <PayerPlanSelect
                      label="Plan"
                      value={values.insurancePayerPlanId}
                      searchFilters={{ insurancePayerId: policy.insurancePayer?.id, includePayerNetworkPlans: true }}
                      onChange={plan => setFieldValue('insurancePayerPlanId', plan?.id ?? null)}
                      helperText={helperText('insurancePayerPlanId')}
                      error={isError('insurancePayerPlanId')}
                    />
                  </Grid>
                  <Grid
                    size={{
                      xs: 12,
                      md: 6,
                    }}
                  >
                    <TextField label="Group ID" value={values.groupId} {...fieldProps('groupId')} />
                  </Grid>
                  <Grid
                    size={{
                      xs: 12,
                      md: 6,
                    }}
                  >
                    <TextField
                      label="Address Line 1"
                      value={values.insuredAddressLineOne}
                      {...fieldProps('insuredAddressLineOne')}
                    />
                  </Grid>
                  <Grid
                    size={{
                      xs: 12,
                      md: 6,
                    }}
                  >
                    <TextField
                      label="Address Line 2"
                      value={values.insuredAddressLineTwo}
                      {...fieldProps('insuredAddressLineTwo')}
                    />
                  </Grid>
                  <Grid
                    size={{
                      xs: 12,
                      md: 4,
                    }}
                  >
                    <TextField label="City" value={values.insuredCity} {...fieldProps('insuredCity')} />
                  </Grid>
                  <Grid
                    size={{
                      xs: 12,
                      md: 4,
                    }}
                  >
                    <TextField label="State" value={values.insuredState} {...fieldProps('insuredState')} />
                  </Grid>
                  <Grid
                    size={{
                      xs: 12,
                      md: 4,
                    }}
                  >
                    <TextField label="Zip Code" value={values.insuredZipCode} {...fieldProps('insuredZipCode')} />
                  </Grid>

                  <Grid size={12}>
                    <SectionHeading>Copay</SectionHeading>
                  </Grid>
                  <Grid
                    size={{
                      xs: 12,
                      md: 3,
                    }}
                  >
                    <MUITextField
                      fullWidth
                      label="Copay"
                      value={values.copay}
                      {...fieldProps('copay')}
                      InputProps={moneyAdornmentInputProps}
                    />
                  </Grid>
                  <Grid
                    size={{
                      xs: 12,
                      md: 3,
                    }}
                  >
                    <MUITextField
                      type="number"
                      fullWidth
                      label="Coinsurance"
                      value={values.coinsurance}
                      {...fieldProps('coinsurance')}
                      InputProps={{
                        endAdornment: <InputAdornment position="end">%</InputAdornment>,
                      }}
                    />
                  </Grid>

                  <Grid size={12}>
                    <SectionHeading>Deductibles</SectionHeading>
                  </Grid>
                  <Grid
                    size={{
                      xs: 12,
                      md: 3,
                    }}
                  >
                    <MUITextField
                      fullWidth
                      label="Family"
                      value={values.familyDeductible}
                      {...fieldProps('familyDeductible')}
                      InputProps={moneyAdornmentInputProps}
                    />
                  </Grid>
                  <Grid
                    size={{
                      xs: 12,
                      md: 3,
                    }}
                  >
                    <MUITextField
                      fullWidth
                      label="Family Remaining"
                      value={values.familyDeductibleRemaining}
                      {...fieldProps('familyDeductibleRemaining')}
                      InputProps={moneyAdornmentInputProps}
                    />
                  </Grid>
                  <Grid
                    size={{
                      xs: 12,
                      md: 3,
                    }}
                  >
                    <MUITextField
                      fullWidth
                      label="Individual"
                      value={values.individualDeductible}
                      {...fieldProps('individualDeductible')}
                      InputProps={moneyAdornmentInputProps}
                    />
                  </Grid>
                  <Grid
                    size={{
                      xs: 12,
                      md: 3,
                    }}
                  >
                    <MUITextField
                      fullWidth
                      label="Individual Remaining"
                      value={values.individualDeductibleRemaining}
                      {...fieldProps('individualDeductibleRemaining')}
                      InputProps={moneyAdornmentInputProps}
                    />
                  </Grid>
                  <Grid size={12}>
                    <SectionHeading>Out-of-Pocket Max</SectionHeading>
                  </Grid>
                  <Grid
                    size={{
                      xs: 12,
                      md: 3,
                    }}
                  >
                    <MUITextField
                      fullWidth
                      label="Family"
                      value={values.familyOutOfPocketMax}
                      {...fieldProps('familyOutOfPocketMax')}
                      InputProps={moneyAdornmentInputProps}
                    />
                  </Grid>
                  <Grid
                    size={{
                      xs: 12,
                      md: 3,
                    }}
                  >
                    <MUITextField
                      fullWidth
                      label="Family Remaining"
                      value={values.familyOutOfPocketMaxRemaining}
                      {...fieldProps('familyOutOfPocketMaxRemaining')}
                      InputProps={moneyAdornmentInputProps}
                    />
                  </Grid>
                  <Grid
                    size={{
                      xs: 12,
                      md: 3,
                    }}
                  >
                    <MUITextField
                      fullWidth
                      label="Individual"
                      value={values.individualOutOfPocketMax}
                      {...fieldProps('individualOutOfPocketMax')}
                      InputProps={moneyAdornmentInputProps}
                    />
                  </Grid>
                  <Grid
                    size={{
                      xs: 12,
                      md: 3,
                    }}
                  >
                    <MUITextField
                      fullWidth
                      label="Individual Remaining"
                      value={values.individualOutOfPocketMaxRemaining}
                      {...fieldProps('individualOutOfPocketMaxRemaining')}
                      InputProps={moneyAdornmentInputProps}
                    />
                  </Grid>

                  <Grid size={12}>
                    <SectionHeading>Errors</SectionHeading>
                  </Grid>
                  <Grid
                    size={{
                      xs: 12,
                      md: 4,
                    }}
                  >
                    <TextField label="Failure Type" value={values.failureType} {...fieldProps('failureType')} />
                  </Grid>
                  <Grid
                    size={{
                      xs: 12,
                      md: 8,
                    }}
                  >
                    <TextField
                      label="Failure Reason"
                      placeholder="This is shown to the client on inactive policies"
                      value={values.failureReason}
                      {...fieldProps('failureReason')}
                    />
                  </Grid>
                  <Grid className="text-right" size={12}>
                    {policy.cardFrontUrl && (
                      <AttachmentChip
                        fileName="Front"
                        mimeType="image"
                        downloadUrl={policy.cardFrontUrl}
                        className="mr-1"
                      />
                    )}
                    {policy.cardBackUrl && (
                      <AttachmentChip
                        fileName="Back"
                        mimeType="image"
                        downloadUrl={policy.cardBackUrl}
                        className="mr-3"
                      />
                    )}

                    <OutlineButton type="button" onClick={handleMarkVerified} className="mr-2">
                      Set to Verified
                    </OutlineButton>
                    <FillButton type="submit" disabled={!dirty || !isValid} isLoading={saveLoading}>
                      Save Overrides
                    </FillButton>
                  </Grid>
                </Grid>
              </CardBody>
            </Card>
          </Form>
        )
      }}
    </Formik>
  )
}

const FormHeading = makeTypographyComponent('h6', 'h2')

const SectionHeading = styled(makeTypographyComponent('mt-2 mb-0 body text-bold uppercase text-default', 'h3'))`
  margin-bottom: -0.5rem;
`
