import { styled } from '@mui/material'

import { AppointmentCollectionStatusQuery } from '@nuna/api'
import { appointmentCollectionStatusService, appointmentService, centsToFormattedDollars } from '@nuna/core'
import { GhostButton, IconInfo, Tooltip, fontSize, makeTypographyComponent, whiteTintSet, yellowSet } from '@nuna/tunic'

interface Props {
  appointmentData: AppointmentCollectionStatusQuery['appointmentCollectionStatus']
  onFeeDecision: (shouldProviderBePaid: boolean) => void
}

export function FeeActionsBoot({ appointmentData, onFeeDecision }: Props) {
  const isWaived = appointmentCollectionStatusService.hasWaivedFee(appointmentData)

  const feeCountdown = <b>{appointmentService.upcomingAppointmentInfo(appointmentData.feeDue, true).text}</b>
  const feeAmount = centsToFormattedDollars(appointmentData.feeAmount ?? 0)

  return (
    <Container>
      <Description>
        {isWaived ? (
          <span>This fee is waived. You have {feeCountdown} to assign a fee instead</span>
        ) : (
          <span>
            {feeAmount} will be charged to the client in {feeCountdown}
          </span>
        )}

        <Tooltip
          showOnMobileTap
          content={
            isWaived
              ? 'You have 72 hours after the appointment to assign a fee instead'
              : 'Clients will automatically be charged your fee after 72 hours'
          }
        >
          <span className="v-align ml-xs">
            <IconInfo size={24} color={whiteTintSet[50]} />
          </span>
        </Tooltip>
      </Description>
      <span>
        <GhostButton
          type="button"
          scheme="dark"
          variant="secondary"
          className="action-button no-wrap"
          onClick={() => onFeeDecision(isWaived ? true : false)}
        >
          {isWaived ? `Assign ${feeAmount} Fee` : `Waive Fees`}
        </GhostButton>
      </span>
    </Container>
  )
}

const Container = styled(makeTypographyComponent('p-2 space-between v-align', 'div'))`
  border-top: 1px solid ${yellowSet[50].hex};
  background-color: ${yellowSet[80].hex};
  color: #fff;
  flex-wrap: wrap;
  gap: var(--spacing-1);

  .action-button {
    .inner-button {
      font-size: ${fontSize.caption};
    }
  }
`

const Description = makeTypographyComponent('text-medium v-align', 'div')
