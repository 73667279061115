import * as Yup from 'yup'
import { Formik } from 'formik'
import moment from 'moment-timezone'
import { useMemo, useState } from 'react'

import { Role, usePatientAppointmentsQuery } from '@nuna/api'
import { useUserRole } from '@nuna/auth'
import { appointmentService } from '@nuna/core'
import { FillButton } from '@nuna/tunic'

import {
  CoverageFormPatient,
  InsuranceCoverageForm,
  insuranceCoverageSchema,
  insuranceInitialValues,
} from '../../CoverageForm'
import { SwitchToInsuranceVerification } from '../../SwitchCoverageForm/components/SwitchToInsuranceVerification'

interface Props {
  client: CoverageFormPatient
  onSubmitComplete: () => void
}

const schema = Yup.object().shape({
  insuranceValues: insuranceCoverageSchema,
})

export function DrawerInsuranceForm({ client, onSubmitComplete }: Props) {
  const { data } = usePatientAppointmentsQuery({ variables: { id: client.id }, fetchPolicy: 'cache-and-network' })
  const [showInsuranceVerification, setShowInsuranceVerification] = useState(false)
  const role = useUserRole()

  // TODO: When we make checkout smarter we can simplify these checks
  const upcomingAppointments = useMemo(
    () =>
      (data?.patient.appointments ?? []).filter(
        appointment =>
          moment(appointment.endDatetime).isAfter(moment()) && !appointmentService.isAppointmentCanceled(appointment),
      ),
    [data?.patient.appointments],
  )

  const handleSubmit = () => {
    setShowInsuranceVerification(true)
  }

  return (
    <Formik
      validationSchema={schema}
      initialValues={{ insuranceValues: insuranceInitialValues }}
      onSubmit={handleSubmit}
    >
      {({ handleSubmit }) =>
        showInsuranceVerification ? (
          <SwitchToInsuranceVerification
            patient={client}
            upcomingAppointments={upcomingAppointments}
            onSubmitComplete={onSubmitComplete}
            onBackClick={() => setShowInsuranceVerification(false)}
            onCancelClick={() => {
              setShowInsuranceVerification(false)
            }}
            isNewAccount={false}
          />
        ) : (
          <form onSubmit={handleSubmit}>
            <InsuranceCoverageForm
              audience={role === Role.Provider ? 'provider' : role === Role.Admin ? 'admin' : 'client'}
              patient={client}
              inDrawer
              state={client.state ?? ''}
            />
            <FillButton className="mt-4" type="submit">
              Submit
            </FillButton>
          </form>
        )
      }
    </Formik>
  )
}
