import { styled } from '@mui/material'
import { ComponentProps, useRef, useState } from 'react'

import { IconPreview, IconPreviewOff } from '../../icons'
import { greySet } from '../../styles/colorSets'
import { type InputStylesProps, RawInputProps, inputStyles } from '../BaseInput/BaseInput'

type Props = Omit<ComponentProps<typeof Input>, 'type'> & RawInputProps

export function PasswordField({ inputProps, ...props }: Props) {
  const inputRef = useRef<HTMLInputElement>(null)
  const [isMasked, setIsMasked] = useState(true)

  const Icon = isMasked ? IconPreview : IconPreviewOff

  const handleMaskClick = (event: React.MouseEvent<HTMLOrSVGElement, MouseEvent>) => {
    event.preventDefault()
    event.stopPropagation()
    setIsMasked(!isMasked)

    // use set timeout before refocusing to defer action until browser has rendered updated input
    setTimeout(() => {
      if (inputRef.current) {
        inputRef.current.focus()
        inputRef.current.setSelectionRange(inputRef.current.value.length, inputRef.current.value.length + 1)
      }
    })
  }

  return (
    <Wrapper>
      <Input {...props} {...inputProps} type={isMasked ? 'password' : 'text'} ref={inputRef} />
      <IconWrapper>
        <Icon onClick={handleMaskClick} />
      </IconWrapper>
    </Wrapper>
  )
}

const Wrapper = styled('span')`
  display: flex;
  border-bottom: 1px solid ${greySet[50].hex};
`

const Input = styled('input')<InputStylesProps>`
  ${inputStyles}
  border-bottom: none;
  flex: 1;
  width: inherit;
`

const IconWrapper = styled('span')`
  align-self: flex-end;
  cursor: pointer;
  color: ${greySet[50].hex};
`
