import { Illustration } from '@nuna/common'
import { Grid } from '@nuna/tunic'

import intakeImg from '../img/intake-illustration.png'
import { IntakeStepContainer } from '../shared/IntakeStepContainer'
import { IntakeHeader } from '../shared/IntakeTypography'
import { ProviderIntakeFormStepper } from '../shared/ProviderIntakeFormStepper'

export function WelcomeFullSetup() {
  return (
    <IntakeStepContainer centered>
      <Grid container alignItems="center" spacing={4} direction="row-reverse">
        <Grid
          size={{
            xs: 12,
            md: 4,
          }}
        >
          <Illustration src={intakeImg} />
        </Grid>
        <Grid
          size={{
            xs: 12,
            md: 8,
          }}
        >
          <IntakeHeader type="h1">Let’s build your profile.</IntakeHeader>
          <p className="text-secondary large">
            After we gather some of your info, you’ll have a{' '}
            <span className="text-medium text-medium-dark-grey">professional practice website</span> where clients can
            book appointments with you.
          </p>
          <p className="text-secondary large">Your progress is saved as you go through the process.</p>
        </Grid>

        <Grid
          size={{
            xs: 12,
          }}
        >
          <ProviderIntakeFormStepper
            customText={'Start Profile Creation'}
            type="navigation"
            step="welcome"
            mutationLoading={false}
            isValid
            currentSection="profile"
          />
        </Grid>
      </Grid>
    </IntakeStepContainer>
  )
}
