import { useEffect, useState } from 'react'

interface Props {
  delay?: number
  children: JSX.Element
}

export function DelayedRender({ children, delay = 300 }: Props) {
  const [showComponent, setShowComponent] = useState(false)

  useEffect(() => {
    const timeout = setTimeout(() => setShowComponent(true), delay)

    return () => clearTimeout(timeout)
  }, [delay])

  if (!showComponent) return null

  return children
}
