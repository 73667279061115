import * as Yup from 'yup'
import moment from 'moment-timezone'

import { FullProviderFragment, useUpdateProviderMutation } from '@nuna/api'
import { useIsAdmin } from '@nuna/auth'
import { CurrentTime, ProfileDisplayValue, ProfileSection } from '@nuna/common'
import { timeService } from '@nuna/core'
import { Grid, IconInfo, StatusLabel, TimezoneSelect, Tooltip } from '@nuna/tunic'

import { incompleteSectionNotification } from '../../../utils/util'

export type TimezoneSectionProps = Pick<FullProviderFragment, 'id' | 'timezone'>

type TimezoneValues = Omit<TimezoneSectionProps, 'id'>

const { getReadableTimezone } = timeService

export function TimezoneSection({ id, timezone }: TimezoneSectionProps) {
  const [updateProvider, { loading: updateLoading }] = useUpdateProviderMutation()
  const isAdmin = useIsAdmin()

  const initialValues: TimezoneValues = {
    timezone: timezone,
  }

  const [isComplete, valueDisplay] = incompleteSectionNotification<TimezoneValues>(
    initialValues,
    ['timezone'],
    <ProfileDisplayValue>
      {getReadableTimezone(timezone)} - <CurrentTime timezone={timezone ?? undefined} />
    </ProfileDisplayValue>,
  )

  return (
    <ProfileSection
      heading="Timezone"
      useOutlineSaveButton
      description={
        <>
          {!isAdmin && (
            <p className="caption v-align">
              Detected timezone: <StatusLabel className="ml-xs">{getReadableTimezone(moment.tz.guess())}</StatusLabel>
              <Tooltip content="Your detected timezone is the timezone according to the settings & location of your device (laptop, computer, tablet, phone, etc.) This may change if you travel to different timezones. If this looks incorrect, you may need to double check the timezone settings on your device.">
                <span className="ml-xs">
                  <IconInfo size={18} />
                </span>
              </Tooltip>
            </p>
          )}
          In some cases, you and your client may be in different time zones. We notify you of your appointments in your
          respective timezone.
        </>
      }
      displayWidth="full"
      updateLoading={updateLoading}
      initialValues={initialValues}
      handleSubmit={values => updateProvider({ variables: Object.assign({ id }, values) })}
      disabled={({ isValid }) => !isValid}
      sectionComplete={isComplete}
      renderDisplayValue={valueDisplay}
      renderForm={({ values, setFieldValue, errors, touched, setTouched }) => (
        <Grid container>
          <Grid
            size={{
              xs: 12,
              sm: 10,
            }}
          >
            <TimezoneSelect
              style={{ minWidth: 300 }}
              value={values.timezone}
              name="timezone"
              onChange={timezone => setFieldValue('timezone', timezone)}
              inputProps={{
                onFocus: () => setTouched({ timezone: true }),
                error: touched.timezone && !!errors.timezone,
                helperText: touched.timezone && errors.timezone ? errors.timezone : null,
              }}
            />
          </Grid>
        </Grid>
      )}
      validationSchema={Yup.object().shape({
        timezone: Yup.string().required('Timezone is required').typeError('Timezone is required'),
      })}
    ></ProfileSection>
  )
}
