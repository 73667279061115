import { styled } from '@mui/material'

import { BasicProviderFragment } from '@nuna/api'

interface Props {
  provider: BasicProviderFragment
}

export function LateFeePolicyIntro({ provider }: Props) {
  return (
    <div>
      <h2 className="h5">{provider.firstName} has a new policy for late cancelation and missed appointments.</h2>

      <h3 className="body text-medium mt-4">What you'll need to do:</h3>

      <List>
        <li>Add a card to cover incidentals</li>
        <li>Accept {provider.firstName}'s' policy</li>
      </List>
    </div>
  )
}

const List = styled('ol')`
  line-height: 1.5;
  padding-left: 1.5rem;

  li::marker {
    font-weight: 500;
  }
`
