import { styled } from '@mui/material'
import { formatPhoneNumber } from 'react-phone-number-input'

import { SessionPatientDetailsFragment } from '@nuna/api'
import { IconMail, IconPhone, IconUser, greySet } from '@nuna/tunic'

interface ContactInfoGlanceProps {
  patient?: SessionPatientDetailsFragment
}

export function ContactInfoGlance({ patient }: ContactInfoGlanceProps) {
  if (!patient) {
    return <div className="loading" style={{ height: 164, borderRadius: 6 }}></div>
  }

  const { mobilePhone, email, addressLineOne, addressLineTwo, city, state, zipCode } = patient
  return (
    <DefinitionList>
      <dt>
        <IconPhone size={18} />
      </dt>
      <dd>{formatPhoneNumber(mobilePhone ?? '')}</dd>
      <dt>
        <IconMail size={18} />
      </dt>
      <dd>{email}</dd>
      <dt>
        <IconUser size={15} />
      </dt>
      <dd>
        {addressLineOne}
        {!!addressLineTwo && <>, {addressLineTwo}</>}
        <br /> {city}, {state} {zipCode}
      </dd>
    </DefinitionList>
  )
}

const DefinitionList = styled('dl')`
  display: flex;
  flex-wrap: wrap;
  color: ${greySet[80].hex};
  width: 100%;
  dt,
  dd {
    display: inline-block;
    margin-bottom: 0.5rem;
  }
  dt {
    float: left;
    width: 10%;
  }
  dd {
    flex-grow: 1;
    float: left;
    margin-left: 0;
    width: 90%;
  }
`
