import { SxProps, Theme, styled } from '@mui/material'
import { isNil } from 'lodash'
import { HTMLAttributes, MouseEventHandler, ReactNode } from 'react'

import { IconClose } from '../../icons'
import { BelowTablet } from '../../styles/breakpoints'
import { greySet, salmonSet, tealSet, yellowSet } from '../../styles/colorSets'
import { fontSize } from '../../styles/global/typography.utils'
import { Intent } from '../../types/Intent'
import { Scheme } from '../../types/Scheme'
import { csx } from '../../utils/csx'
import { GhostButton, IconButton } from '../Button'
import scribbleSrc from './img/scribble.png'
import starSrc from './img/star.png'

export interface ContextualAlertProps extends HTMLAttributes<HTMLDivElement> {
  children: ReactNode
  dismissButtonText?: string
  fullWidth?: boolean
  icon?: ReactNode
  intent?: Intent
  largeText?: boolean
  onDismiss?: MouseEventHandler<HTMLButtonElement>
  role?: 'alert' | 'status'
  showScribble?: boolean
  scribbleType?: 'default' | 'star'
  scheme?: Scheme
  dismissType?: 'text' | 'icon'
  contentProps?: HTMLAttributes<HTMLDivElement>
  variant?: 'filled' | 'outlined'
  centerText?: boolean
  sx?: SxProps<Theme>
}

export function ContextualAlert({
  children,
  onDismiss,
  dismissType = 'text',
  dismissButtonText = 'Dismiss',
  fullWidth = false,
  icon = null,
  intent = 'default',
  largeText = false,
  role = 'alert',
  showScribble = false,
  scheme = 'light',
  scribbleType = 'default',
  contentProps = {},
  variant = 'filled',
  centerText = false,
  sx,
  ...props
}: ContextualAlertProps) {
  const content = icon ? (
    <IconAndContentWrapper largeText={largeText}>
      <div className="icon">{icon}</div>
      <div>{children}</div>
    </IconAndContentWrapper>
  ) : (
    children
  )

  return (
    <Container
      {...props}
      dismissType={dismissType}
      variant={variant}
      role={role}
      intent={intent}
      fullWidth={fullWidth}
      scheme={scheme}
      icon={icon}
      sx={sx}
    >
      <Content
        largeText={largeText}
        hasButton={!!onDismiss}
        dismissType={dismissType}
        className={csx([{ 'vh-align full-width': centerText, 'v-align': !centerText }])}
        {...contentProps}
      >
        {showScribble &&
          (() => {
            if (scribbleType === 'star') {
              return <StarScribble className="mr-1" src={starSrc} alt="" />
            } else {
              return <DefaultScribble className="mr-1" src={scribbleSrc} alt="" />
            }
          })()}
        {content}
      </Content>

      {onDismiss && dismissType === 'text' && (
        <DismissButton
          scheme={scheme}
          variant={intent === 'urgent' ? 'destroy' : intent === 'information' ? 'primary' : 'secondary'}
          onClick={onDismiss}
        >
          {dismissButtonText}
        </DismissButton>
      )}

      {onDismiss && dismissType === 'icon' && (
        <IconButton
          customSize={20}
          className="flex-static ml-auto"
          tooltip={dismissButtonText}
          variant={(() => {
            if (scheme === 'dark') {
              return 'dark'
            }

            if (intent === 'information') {
              return 'primary'
            }

            return 'secondary'
          })()}
          onClick={onDismiss}
        >
          <IconClose size={14} />
        </IconButton>
      )}
    </Container>
  )
}

interface AlertColorOptions {
  backgroundColor: string
  color: string
  borderColor?: string
  iconColor?: string
}

function getAlertColors({
  backgroundColor,
  color,
  borderColor = color,
  iconColor = 'currentcolor',
}: AlertColorOptions) {
  return `
    background-color: ${backgroundColor};
    border-color: ${borderColor};
    color: ${color};

    svg {
      fill: ${iconColor};
    }
  `
}

const colors: { [key in Intent]: string } = {
  information: getAlertColors({ backgroundColor: tealSet.tint[20], color: tealSet[70].hex }),
  urgent: getAlertColors({ backgroundColor: salmonSet.tint[20], color: salmonSet[80].hex }),
  caution: getAlertColors({ backgroundColor: yellowSet.tint[20], color: yellowSet[80].hex }),
  default: getAlertColors({ backgroundColor: greySet.tint[20], color: greySet[50].hex }),
}

const outlineColors: { [key in Intent]: string } = {
  information: getAlertColors({
    backgroundColor: greySet.tint[20],
    borderColor: tealSet[70].hex,
    color: greySet[70].hex,
    iconColor: tealSet[70].hex,
  }),
  urgent: getAlertColors({
    backgroundColor: greySet.tint[20],
    borderColor: salmonSet[80].hex,
    color: greySet[70].hex,
    iconColor: salmonSet[80].hex,
  }),
  caution: getAlertColors({
    backgroundColor: greySet.tint[20],
    borderColor: yellowSet[80].hex,
    color: greySet[70].hex,
    iconColor: yellowSet[80].hex,
  }),
  default: getAlertColors({ backgroundColor: greySet[5].hex, borderColor: greySet[15].hex, color: greySet[70].hex }),
}

const darkColors: { [key in Intent]: string } = {
  information: getAlertColors({ backgroundColor: greySet.tint[20], color: tealSet[30].hex }),
  urgent: getAlertColors({ backgroundColor: greySet.tint[20], color: salmonSet[50].hex }),
  caution: getAlertColors({ backgroundColor: greySet.tint[20], color: yellowSet[50].hex }),
  default: getAlertColors({ backgroundColor: greySet.tint[20], color: greySet[30].hex, borderColor: greySet[50].hex }),
}

type ContainerProps = Required<
  Pick<ContextualAlertProps, 'variant' | 'intent' | 'fullWidth' | 'scheme' | 'dismissType' | 'icon'>
>

const Container = styled('div')`
  align-items: center;
  display: ${({ fullWidth }: ContainerProps) => (fullWidth ? 'flex' : 'inline-flex')};
  border: 1px solid;
  border-radius: 6px;
  padding: 0.5rem 1rem;

  ${({ dismissType }: ContainerProps) => dismissType === 'icon' && 'padding-right: 0.5rem;'}
  ${({ icon }: ContainerProps) => !isNil(icon) && 'padding-left: 0.5rem;'}

  ${({ intent, scheme, variant }: ContainerProps) => {
    if (variant === 'outlined') {
      return outlineColors[intent]
    }

    if (scheme === 'dark') {
      return darkColors[intent]
    }

    return colors[intent]
  }}

  @media (${BelowTablet}) {
    flex-direction: column;
    align-items: flex-start;
  }
`

const IconAndContentWrapper = styled('div')<{ largeText: boolean }>`
  display: flex;
  align-items: flex-start;

  .icon {
    display: flex;
    align-items: center;
    /* 
      set the height to match the line height multiplied by font size to ensure icon is centered 
      with the first line of text
    */
    height: calc(1.4 * ${({ largeText }) => (largeText ? fontSize.body : fontSize.caption)});
  }
`

const DismissButton = styled(GhostButton)`
  margin-left: auto;
  white-space: nowrap;
  margin-top: 8px;
  margin-bottom: 8px;
  @media (${BelowTablet}) {
    margin-left: 0;
    margin-top: var(--margin-2);
  }
`

const Content = styled('div')<{ hasButton: boolean; largeText: boolean; dismissType: 'text' | 'icon' }>`
  display: flex;
  /* 
    using the same calculation as the icon container height ensures centering
  */
  line-height: calc(1.4 * ${({ largeText }) => (largeText ? fontSize.body : fontSize.caption)});

  ${({ largeText }) =>
    largeText ? `font-size: ${fontSize.body};` : `font-size: ${fontSize.caption}; font-weight: 500;`}

  ${({ hasButton, dismissType }) => hasButton && `padding-right: ${dismissType === 'icon' ? '1' : '2'}rem;`}

  svg {
    height: 20px;
    vertical-align: middle;
    width: auto;
    margin-right: 0.5rem;
  }

  p {
    margin: 0;

    & + p {
      margin-top: 1em;
    }
  }
`

const DefaultScribble = styled('img')`
  max-width: 43px;
`

const StarScribble = styled('img')`
  max-width: 35px;
`
