import { useEffect } from 'react'
import { useNavigate, useParams } from 'react-router-dom'

import { useAuthDataContext } from '@nuna/auth'
import { routeService } from '@nuna/core'
import { Skeleton } from '@nuna/tunic'

import { useSaveConversationMutationWithCacheUpdate } from '../hooks/useSaveConversationMutationWithCacheUpdate'

const { conversationsBaseRoute, conversationRoute } = routeService

/**
 * There are parts of the app where we need to link to a conversation with a specific participant but we don't know the conversation ID, only the participant ID.
 * This component exists to retrieve the conversation from a participant ID so we can link to a conversation with a specific person
 */

export function ConversationParticipantLookup() {
  const { participantId } = useParams()
  const { login } = useAuthDataContext()
  const navigate = useNavigate()
  // saveConversation is an upsert. So we can use this to create or retrieve the appropriate conversation
  const [saveConversationMutation] = useSaveConversationMutationWithCacheUpdate()

  useEffect(() => {
    if (participantId && login) {
      saveConversationMutation({ variables: { recipients: [participantId, login.id] } })
        .then(response => {
          if (response.data) {
            navigate(conversationRoute(response.data.saveConversation.id), { replace: true })
          } else {
            navigate(conversationsBaseRoute, { replace: true })
          }
        })
        .catch(e => {
          console.error(e)
          navigate(conversationsBaseRoute, { replace: true })
        })
    }
  }, [navigate, participantId, login, saveConversationMutation])

  return <Skeleton fullWidth height={6} />
}
