import { useNavigate } from 'react-router-dom'

import { useUpcomingProviderAppointmentQuery } from '@nuna/api'
import { NoCurrentSessionModal } from '@nuna/sundance'

import { useGlobalWidgetContext } from '../../shared/GlobalWidgetContext'

export function ProviderNoCurrentSessionModal() {
  const navigate = useNavigate()
  const { loading, data: appointmentData } = useUpcomingProviderAppointmentQuery()
  const { toggleBeacon } = useGlobalWidgetContext()

  const handleClose = () => {
    navigate('/schedule', { replace: true })
  }

  const appointment = appointmentData?.upcomingAppointmentForProvider

  return (
    <NoCurrentSessionModal
      loading={loading}
      appointment={appointment}
      onClose={handleClose}
      onHelpClick={toggleBeacon}
    />
  )
}
