import { useExportPatientRecordMutation } from '@nuna/api'
import { IconButton, IconDownload, toast } from '@nuna/tunic'

interface Props {
  patientId?: string
}

export function ExportPatientRecordButton({ patientId }: Props) {
  const [updatePatientProfile, { loading }] = useExportPatientRecordMutation()

  const handleClick = async () => {
    try {
      await updatePatientProfile({
        variables: { patientId: patientId || '' },
        awaitRefetchQueries: true,
        refetchQueries: ['PatientHistory'],
      })

      toast.success(`Client record now available.`)
    } catch (e) {
      toast.urgent(`There was an error exporting the client's record`)
    }
  }

  return (
    <IconButton
      tooltip="Export Client Record PDF"
      type="button"
      variant="secondary"
      disabled={loading}
      onClick={handleClick}
    >
      <IconDownload size={24} />
    </IconButton>
  )
}
