export const liabilityInsuranceInformation = {
  policyNumber: 'AA1122000',
  currentEffectiveDate: '03/13/2024',
  currentExpirationDate: '03/13/2025',
  carrierName: 'Lloyds of London',
  unlimitedCoverage: 'No',
  coverageAmountPerOccurrence: '$1,000,000',
  coverageAmountAggregate: '$3,000,000',
  individualCoverage: 'No',
  selfInsured: 'No',
}
