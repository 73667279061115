import * as Yup from 'yup'
import { Form, Formik } from 'formik'
import { noop } from 'lodash'
import moment from 'moment'
import { HTMLAttributes, useMemo, useState } from 'react'
import { useNavigate } from 'react-router-dom'

import { useSaveCancelPolicyMutation } from '@nuna/api'
import { useIsAdmin } from '@nuna/auth'
import { CancelPolicyItem, dateService, errorService, formService, numberService } from '@nuna/core'
import { ContextualAlert, DatePicker, FillButton, GhostButtonLink, IconInfo, tealSet, toast } from '@nuna/tunic'

import { CaptionDark, CaptionLight, FieldLabel, FormCard, FormSection } from '../../../../shared/FormComponents'
import { ProviderTakeEstimate } from '../../../../shared/ProviderTakeEstimate'
import { InlineInput } from '../../InlineInput'

const { composeHelperTextWithError } = formService
const { centsToDollars, dollarsToCents } = numberService

type FormValues = {
  id?: string | null
  cancelPeriodMinutes: number
  chargeAmount: number
  noShowChargeAmount: number
  startDate: string | Date
  providerId: string
}

const schema = Yup.object().shape<FormValues>({
  id: Yup.string().nullable(),
  chargeAmount: Yup.number()
    .required('Late Cancelation Fee is required')
    .typeError('Late Cancelation Fee is required')
    .min(0, 'Late Cancelation Fee must be more than $0')
    .max(150, 'Late Cancelation Fee must be less than $150'),
  noShowChargeAmount: Yup.number()
    .required('No Show Fee is required')
    .typeError('No Show Fee is required')
    .min(0, 'No-Show Fee must be more than $0')
    .max(150, 'No-Show Fee must be less than $150'),
  startDate: Yup.date().required('Start Date is required').typeError('Start Date is required'),
  cancelPeriodMinutes: Yup.number(),
  providerId: Yup.string(),
})

// cancelPeriodMinutes hard-coded to 24 hours
const CANCEL_PERIOD_MINUTES = 60 * 24

interface Props extends HTMLAttributes<HTMLDivElement> {
  cancelPolicy?: CancelPolicyItem | null
  providerId: string
  navigateTo?: string | null
  afterSubmit?: () => void
  canCancel?: boolean
}

export function CancelPolicyForm({
  cancelPolicy,
  providerId,
  navigateTo = '../',
  afterSubmit = noop,
  canCancel = true,
  ...props
}: Props) {
  const navigate = useNavigate()
  const isAdmin = useIsAdmin()
  const [policyId, setPolicyId] = useState<string | undefined>(() => cancelPolicy?.id)

  const [savePolicy] = useSaveCancelPolicyMutation({ refetchQueries: ['ListCancelPolicies'] })
  const initialValues = useMemo(() => buildInitialValues(cancelPolicy, providerId), [cancelPolicy, providerId])
  const isDisabled = isAdmin || cancelPolicy?.isOld

  const handleSubmit = async (values: FormValues) => {
    try {
      const result = await savePolicy({ variables: { policy: prepareFormValues(values, policyId) } })
      setPolicyId(result.data?.saveCancelPolicy?.id)
      toast.success('Your cancel policy has been saved.')
      navigateTo && navigate(navigateTo)
      afterSubmit()
    } catch (e) {
      toast.urgent(errorService.transformGraphQlError(e, 'There was an error saving your cancel policy.'))
    }
  }

  return (
    <FormCard onEdit={noop} canEdit={!isAdmin && !!cancelPolicy?.isFuture} isEditing {...props}>
      <Formik initialValues={initialValues} validationSchema={schema} onSubmit={handleSubmit}>
        {({ values, getFieldProps, errors, touched, setFieldValue, isValid }) => (
          <Form>
            <FormSection>
              <FieldLabel>Late Cancelation Fee</FieldLabel>

              <div className="v-align">
                <InlineInput
                  {...getFieldProps('chargeAmount')}
                  type="number"
                  leftAdornment="$"
                  style={{ width: 72 }}
                  className="mr-1"
                  disabled={isDisabled}
                  error={!!errors.chargeAmount && touched.chargeAmount}
                  data-testid="cancel-fee-input"
                />
                <div>
                  will be charged to clients that cancel <b>less than 24 hours</b> before a session
                </div>
              </div>
              <ProviderTakeEstimate
                fee={values.chargeAmount}
                debounceKey="cancelCharge"
                {...composeHelperTextWithError('', errors.chargeAmount, touched.chargeAmount)}
              />
            </FormSection>
            <FormSection>
              <FieldLabel>No-Show Fee</FieldLabel>
              <div className="v-align">
                <InlineInput
                  {...getFieldProps('noShowChargeAmount')}
                  type="number"
                  leftAdornment="$"
                  style={{ width: 72 }}
                  className="mr-1"
                  disabled={isDisabled}
                  error={!!errors.noShowChargeAmount && touched.noShowChargeAmount}
                  data-testid="no-show-fee-input"
                />
                <div>
                  will be charged to clients that do not show up <b>in the first 15 minutes</b> of a session.
                </div>
              </div>
              <ProviderTakeEstimate
                fee={values.noShowChargeAmount}
                debounceKey="noShowCharge"
                {...composeHelperTextWithError('', errors.noShowChargeAmount, touched.noShowChargeAmount)}
              />
            </FormSection>
            <FormSection>
              <FieldLabel>Policy Effective Date</FieldLabel>
              <DatePicker
                containerProps={{ className: 'v-align', style: { maxWidth: 400 } }}
                disablePast={!isDisabled}
                format="MMM Do, YYYY"
                label=""
                onChange={date => setFieldValue('startDate', moment(date).startOf('day').format('YYYY-MM-DD'))}
                value={moment(values.startDate)}
                minDate={isDisabled ? undefined : moment().add(1, 'day').startOf('day')}
                disabled={isDisabled}
              />
              <CaptionLight className="mt-1">
                Clients will be required to accept your new policy after this date.
              </CaptionLight>
            </FormSection>
            <FormSection>
              <FillButton type="submit" disabled={isDisabled} data-testid="save-cancel-policy-button">
                Submit Policy
              </FillButton>

              {canCancel && (
                <GhostButtonLink variant="secondary" className="ml-2" to="../">
                  Cancel
                </GhostButtonLink>
              )}

              {!isValid && (
                <div className="mt-1">
                  <ContextualAlert intent="urgent">Some information is missing or incorrect above</ContextualAlert>
                </div>
              )}
              <CaptionDark className="mt-2 v-align">
                <IconInfo color={tealSet[70].hex} className="mr-1" />
                Each client will need to accept your policy individually before you can charge them. <br /> You may
                choose to waive fees for each session for up to 72 hours after a client cancels.
              </CaptionDark>
            </FormSection>
          </Form>
        )}
      </Formik>
    </FormCard>
  )
}

function buildInitialValues(cancelPolicy: CancelPolicyItem | null | undefined, providerId: string): FormValues {
  return {
    id: cancelPolicy?.id,
    chargeAmount: cancelPolicy?.chargeAmount ? centsToDollars(cancelPolicy.chargeAmount) : 75,
    noShowChargeAmount: cancelPolicy?.noShowChargeAmount ? centsToDollars(cancelPolicy.noShowChargeAmount) : 125,
    startDate: cancelPolicy?.startDate
      ? dateService.utcFormat(cancelPolicy.startDate, 'YYYY-MM-DD')
      : dateService.utcFormat(moment().add(1, 'day').startOf('day'), 'YYYY-MM-DD'),
    cancelPeriodMinutes: CANCEL_PERIOD_MINUTES,
    providerId,
  }
}

function prepareFormValues(formValues: FormValues, id?: string): FormValues {
  return {
    ...formValues,
    id,
    chargeAmount: dollarsToCents(formValues.chargeAmount),
    startDate: dateService.utcFormat(moment(formValues.startDate), 'YYYY-MM-DD'),
    noShowChargeAmount: dollarsToCents(formValues.noShowChargeAmount),
  }
}
