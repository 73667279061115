import { Skeleton, styled } from '@mui/material'
import { MouseEventHandler, useEffect } from 'react'

import { DrawerAppointmentFragment, usePatientContextQuery } from '@nuna/api'
import { type InsuranceCoverageFragment, numberService, routeService } from '@nuna/core'
import { useFeatureFlags } from '@nuna/feature-flag'
import {
  IconCreditCard,
  IconInfo,
  OutlineButton,
  OutlineButtonLink,
  PersistentAlert,
  TextButton,
  TextButtonLink,
} from '@nuna/tunic'

import { useIsProviderInNetwork } from '../../../hooks/useIsProviderInNetwork'
import { CoverageTypeCard, InsuranceProps } from '../../CoverageTypeCard'
import { Cost } from './Cost'

interface InsuranceSummaryProps {
  provider: DrawerAppointmentFragment['provider']
  coverageCardDetails: InsuranceProps
  currentCoverage: InsuranceCoverageFragment
  onChangePaymentClick: MouseEventHandler<HTMLButtonElement>
  onCoverageValidityChange: (isValid: boolean) => void
}

export function InsuranceSummary({
  provider,
  coverageCardDetails,
  currentCoverage,
  onChangePaymentClick,
  onCoverageValidityChange,
}: InsuranceSummaryProps) {
  const { autoCharge: autoChargeFeature } = useFeatureFlags()
  const { data: patientContextData } = usePatientContextQuery()
  const [coverageFallbackReason, loading] = useIsProviderInNetwork({
    providerId: provider.id,
  })

  useEffect(() => {
    onCoverageValidityChange(!coverageFallbackReason && !coverageCardDetails.isInvalid)
  }, [coverageCardDetails.isInvalid, coverageFallbackReason, onCoverageValidityChange])

  if (loading) {
    return <Skeleton height={200} />
  }

  const isIntakeCompleted = patientContextData?.patientContext.patient.intakeCompleted
  const CostDisplay = () => {
    return autoChargeFeature ? (
      <>
        <Cost type="insurance" copay={currentCoverage.details.copay} />

        <CostInfo className="vertical-align mt-4">
          <p className="caption text-secondary">
            <IconCreditCard size={20} style={{ marginBottom: '-5px' }} />{' '}
            <strong style={{ color: '#A74E7E' }}>You'll be billed in a few weeks</strong> after your appointment, once
            your insurance has processed the claim. This will include your{' '}
            {numberService.centsToFormattedDollars(currentCoverage.details.copay)} copay and the balance of the session
            cost, if any, after your insurance has covered its portion.
          </p>
        </CostInfo>

        <PersistentAlert icon={<IconInfo size={20} />} intent="information" className="mt-3">
          <small className="pr-1">
            Your primary card will be <b>automatically</b> charged
          </small>
        </PersistentAlert>
      </>
    ) : (
      <>
        <Cost type="insurance" copay={currentCoverage.details.copay} />
        <p className="caption italic mx-1 mt-3 text-secondary">
          <strong>You’ll be billed in a few weeks</strong> once your insurance has processed the claim. This will
          include your {numberService.centsToFormattedDollars(currentCoverage.details.copay)} copay and the balance of
          the session cost, if any, after your insurance has covered its portion. Watch for a text message.
        </p>
      </>
    )
  }
  return (
    <>
      <CoverageTypeCard
        {...coverageCardDetails}
        isProviderInNetwork={!coverageFallbackReason}
        isInvalid={coverageCardDetails.isInvalid || !!coverageFallbackReason}
      />

      {!!coverageFallbackReason && (
        <div className="text-center">
          <p className="caption text-error mt-2 mb-0 text-medium">
            Uh oh, this session with {provider?.firstName} {provider?.lastName} will not be covered by insurance because{' '}
            {coverageFallbackReason.toLocaleLowerCase()}. Choose an option below to continue.
          </p>

          {!isIntakeCompleted ? (
            <>
              <OutlineButton className="mt-5" onClick={onChangePaymentClick}>
                Change payment method
              </OutlineButton>
              <TextButtonLink variant="secondary" className="mt-3" to={routeService.intake()}>
                Finish intake and get a therapist suggestion
              </TextButtonLink>
            </>
          ) : (
            <>
              <OutlineButtonLink className="mt-5" to={routeService.switchProvider()}>
                Select a new therapist
              </OutlineButtonLink>
              <TextButton className="mt-2" variant="secondary" onClick={onChangePaymentClick}>
                Change payment method
              </TextButton>
            </>
          )}
        </div>
      )}

      {!coverageCardDetails.isInvalid && !coverageFallbackReason && <CostDisplay />}
    </>
  )
}

const CostInfo = styled('div')`
  border: 1px solid transparent;
  border-radius: 12px;
  background-color: #eceae9;
  padding: var(--spacing-2);
`
