import { IconProps } from './IconProps'

export function IconHalfway({ size = 24, color = 'currentColor', ...props }: IconProps) {
  return (
    <svg width={size} height={size} viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M24 7C14.6112 7 7 14.6112 7 24C7 33.3888 14.6112 41 24 41C33.3888 41 41 33.3888 41 24C41 14.6112 33.3888 7 24 7ZM0 24C0 10.7452 10.7452 0 24 0C37.2548 0 48 10.7452 48 24C48 37.2548 37.2548 48 24 48C10.7452 48 0 37.2548 0 24Z"
        fill="#D9D9D9"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M20.5 3.5C20.5 1.567 22.067 0 24 0C37.2548 0 48 10.7452 48 24C48 37.2548 37.2548 48 24 48C22.067 48 20.5 46.433 20.5 44.5C20.5 42.567 22.067 41 24 41C33.3888 41 41 33.3888 41 24C41 14.6112 33.3888 7 24 7C22.067 7 20.5 5.433 20.5 3.5Z"
        fill={color}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M21.9592 32.2068L13.0005 23.1836L16.3369 19.8188L21.9592 25.4815L31.6212 15.75L34.9576 19.1148L21.9592 32.2068Z"
        fill="#D5D3D0"
      />
    </svg>
  )
}
