import { capitalize, noop } from 'lodash'
import { useCallback, useEffect, useState } from 'react'

import { NpiRegistryFragment, useNpiCheckLazyQuery } from '@nuna/api'
import { errorService } from '@nuna/core'
import { Grid, PersistentAlert, TextField, TextFieldProps, toast } from '@nuna/tunic'

interface Props {
  onValidCheck?: (isValid?: boolean) => void
}

export function NPIInput({ onValidCheck = noop, onBlur, className, style, ...props }: Props & TextFieldProps) {
  const [isInitialized, setIsInitialized] = useState(false)
  const [npiValid, setNpiValid] = useState<boolean | null>(null)
  const [npiName, setNpiName] = useState('')
  const [npiCheckQuery] = useNpiCheckLazyQuery()

  const handleNpiCheckResponse = useCallback(
    (npiRegistry?: NpiRegistryFragment | null) => {
      setNpiName(
        npiRegistry && npiRegistry.first_name && npiRegistry.last_name
          ? `${capitalize(npiRegistry.first_name)} ${capitalize(npiRegistry.last_name)}`
          : '',
      )
      setNpiValid(!!npiRegistry)
      onValidCheck(!!npiRegistry)
    },
    [onValidCheck],
  )

  useEffect(() => {
    if (!isInitialized) {
      setIsInitialized(true)

      if (props.value) {
        npiCheckQuery({ variables: { npi: parseFloat(props.value as string) } }).then(response => {
          handleNpiCheckResponse(response.data?.npiCheck)
        })
      }
    }
  }, [isInitialized, props.value, npiCheckQuery, handleNpiCheckResponse])

  const handleBlur = async (
    e: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement, Element>,
  ) => {
    const npi = e.currentTarget.value
    if (!npi) {
      handleNpiCheckResponse()
      return
    }

    try {
      const response = await npiCheckQuery({ variables: { npi: parseFloat(npi) } })
      handleNpiCheckResponse(response.data?.npiCheck)
    } catch (e) {
      toast.urgent(
        errorService.transformGraphQlError(e, 'An error occured validating entered NPI. NPI must be a number.'),
      )
    }
  }

  return (
    <Grid container spacing={2} alignItems="center" className={className} style={style}>
      <Grid
        size={{
          xs: 12,
          md: 6,
        }}
      >
        <TextField
          label="NPI Number"
          onBlur={e => {
            handleBlur(e)
            if (onBlur) {
              onBlur(e)
            }
          }}
          {...props}
        />
      </Grid>
      <Grid>
        {(() => {
          if (npiValid) {
            return <PersistentAlert intent="success">NPI verified{npiName ? ` for ${npiName}` : ''}</PersistentAlert>
          }

          if (npiValid === false) {
            return <PersistentAlert intent="urgent">We can't find that NPI, try entering it again</PersistentAlert>
          }

          return <PersistentAlert intent="information">We'll verify if your NPI is correct</PersistentAlert>
        })()}
      </Grid>
    </Grid>
  )
}
