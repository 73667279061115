import { range } from 'lodash'
import moment from 'moment'
import { useParams } from 'react-router-dom'

import { usePatientAppointmentsQuery } from '@nuna/api'
import { appointmentService } from '@nuna/core'
import { ContextualAlert, IconCalendarNone, toast } from '@nuna/tunic'

import { SessionItemSkeleton, UpcomingSessionItem } from './SessionItem'

const { sortAppointmentsByStartTime } = appointmentService

type RouteProps = {
  id: string
}

export function UpcomingSessions() {
  const { id = '' } = useParams<RouteProps>()

  const { loading, error, data } = usePatientAppointmentsQuery({
    variables: {
      id,
    },
    fetchPolicy: 'cache-and-network',
  })

  if (error) {
    toast.urgent('Error loading upcoming sessions')
  }

  if (loading || !data) {
    return (
      <div>
        {range(2).map(key => (
          <SessionItemSkeleton key={key} card style={{ height: 80 }} />
        ))}
      </div>
    )
  }

  const upcomingAppointments = data.patient.appointments
    .filter(appt => moment(appt.startDatetime).isAfter(moment()))
    .filter(appt => !appt.canceled)
    .sort(sortAppointmentsByStartTime)

  return (
    <div>
      {upcomingAppointments.length ? (
        upcomingAppointments.map(appt => <UpcomingSessionItem showDate key={appt.id} appointment={appt} />)
      ) : (
        <ContextualAlert largeText>
          <IconCalendarNone /> No upcoming appointments
        </ContextualAlert>
      )}
    </div>
  )
}
