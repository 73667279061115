import { noop } from 'lodash'
import moment from 'moment'
import React, { useState } from 'react'

import {
  LifecycleChecklistItemStatus,
  ProviderLifecycleJourneyQuery,
  ProviderNetworkStatus,
  useProviderLifecycleJourneyQuery,
} from '@nuna/api'
import { useAuthDataContext } from '@nuna/auth'
import { useTitle } from '@nuna/common'
import { AvailabilityTabs, CalendarSyncWidget } from '@nuna/provider'
import { PageContent, PageHeader, PageHeading, PageWrapper, TabId } from '@nuna/tunic'

import { useProviderAppContext } from '../../../shared/ProviderAppContext'
import { ProviderManageAvailability } from './ProviderManageAvailability'
import { ProviderOpeningsPreview } from './ProviderOpeningsPreview'

const hasEmptyAvailability = (lifeCycleJourney: ProviderLifecycleJourneyQuery | undefined) => {
  const availabilityChecklistItem = lifeCycleJourney?.providerLifecycleJourney.checklist.find(
    item => item.name === 'Availability',
  )

  return availabilityChecklistItem?.status === LifecycleChecklistItemStatus.Incomplete
}

export const AvailabilityWindowContext = React.createContext({
  window: {
    from: moment(),
    to: moment().add(1, 'weeks'),
  },
  setWindow: noop,
})

export function ProviderAvailability() {
  const { login } = useAuthDataContext()

  const [window, setWindow] = useState({
    from: moment().startOf('week'),
    to: moment().startOf('week').add(1, 'weeks'),
  })

  const [activeAvailabilityTab, setActiveAvailabilityTab] = useState<TabId>('')
  const { provider, providerNetworkStatus } = useProviderAppContext()
  const { data: lifecycleData } = useProviderLifecycleJourneyQuery({
    variables: { providerId: provider?.id },
    skip: !provider,
    fetchPolicy: 'network-only',
  })
  useTitle('Availability')

  if (!lifecycleData || !providerNetworkStatus) return null

  const showExploreFirstMessage =
    providerNetworkStatus === ProviderNetworkStatus.InProgress && hasEmptyAvailability(lifecycleData)
  return (
    <AvailabilityWindowContext.Provider value={{ window, setWindow: setWindow }}>
      <PageWrapper>
        <PageHeader border={false}>
          <PageHeading className="mb-0">Availability</PageHeading>
          <CalendarSyncWidget providerId={login?.providerId} />
        </PageHeader>

        <PageContent className={`relative ${showExploreFirstMessage ? 'overflow-hidden' : ''}`} $paddingTop={false}>
          <ProviderManageAvailability
            onTabChange={setActiveAvailabilityTab}
            showExploreFirstMessage={showExploreFirstMessage}
            activeAvailabilityTab={activeAvailabilityTab}
          />
          {activeAvailabilityTab !== AvailabilityTabs.Regular && <ProviderOpeningsPreview />}
        </PageContent>
      </PageWrapper>
    </AvailabilityWindowContext.Provider>
  )
}
