import { Dictionary } from '@fullcalendar/react'

import moment from 'moment'
import { ReactNode } from 'react'

import { ProviderStatus } from '@nuna/api'
import { Intent } from '@nuna/tunic'

import { IncompleteSectionAlert } from '../components/ProviderProfile/IncompleteSectionAlert'

// OFFBOARDING is not an official status in the ProviderStatus enum so we have to augment
// our types and consts to include it. The status is inferred using the provider's endDate
// in the providerStatusDetails function

type OnboardingStatusMap = {
  [key in ProviderStatus | 'OFFBOARDING']?: string
}

type OnboardingStatusIntentColorMap = {
  [key in ProviderStatus | 'OFFBOARDING']?: Intent
}

export interface ProviderStatusOption {
  label: string
  value: string
}

export const OFFBOARDING_KEY = 'OFFBOARDING'

export const ReadableProviderStatusMap: OnboardingStatusMap = {
  ACCEPTING_CLIENTS: 'Accepting Clients',
  NOT_ACCEPTING_CLIENTS: 'Not Accepting Clients',
  ONBOARDING: 'Onboarding',
  PAUSED_ONBOARDING: 'Paused Onboarding',
  TERMINATED: 'Terminated',
  OFFBOARDING: 'Offboarding',
  UNDER_REVIEW: 'Under Review',
  DENIED: 'Denied',
}

export const ProviderStatusOptions: ProviderStatusOption[] = Object.entries(ReadableProviderStatusMap).map(
  ([key, value]) => ({ label: value, value: key }),
)

export const ProviderStatusIntentColorMap: OnboardingStatusIntentColorMap = {
  ACCEPTING_CLIENTS: 'information',
  NOT_ACCEPTING_CLIENTS: 'caution',
  UNDER_REVIEW: 'caution',
  PAUSED_ONBOARDING: 'caution',
  ONBOARDING: 'default',
  TERMINATED: 'urgent',
  OFFBOARDING: 'urgent',
  DENIED: 'urgent',
}

export function incompleteSectionNotification<T>(
  values: Dictionary,
  keys: (keyof T)[],
  Display: ReactNode,
): [boolean, ReactNode] {
  const isComplete = keys.map(key => values[key as string]).some(value => !!value)
  return [isComplete, isComplete ? Display : <IncompleteSectionAlert />]
}

export function providerStatusDetails(
  status: ProviderStatus | null | undefined,
  endDate?: string | null,
): { statusText: string; statusValue: string; intent: Intent } {
  if (!status) {
    return { statusText: '', intent: 'default', statusValue: '' }
  }
  const isOffboarding = endDate ? moment() < moment(endDate) : false
  const updatedStatus = isOffboarding ? OFFBOARDING_KEY : status
  const statusText = ReadableProviderStatusMap[updatedStatus] ?? ''
  const intent = ProviderStatusIntentColorMap[updatedStatus] ?? 'default'

  return { statusText, intent, statusValue: updatedStatus }
}
