export function listFormat(listItems: string[], { useOxfordComma = false, useAmpersand = false } = {}) {
  if (listItems.length <= 1) {
    return listItems.join('')
  }

  const [lastItem] = listItems.slice(-1)
  const firstItems = listItems.slice(0, -1)

  return `${firstItems.join(', ')}${useOxfordComma && listItems.length > 2 ? ',' : ''} ${
    useAmpersand ? '&' : 'and'
  } ${lastItem}`
}
