import moment from 'moment'

import { AccessCodeCoverageDetailsFragment } from '@nuna/api'
import { AccessCodeCoverageCard } from '@nuna/coverage'

interface Props {
  coverage: AccessCodeCoverageDetailsFragment
}

export function AccessCodeCoverageGlance({ coverage }: Props) {
  const { expirationDate } = coverage
  return (
    <>
      <AccessCodeCoverageCard coverage={coverage} />
      <div className="text-secondary mt-2 ml-1">
        Valid for unlimited session of therapy
        {expirationDate ? ` through ${moment(expirationDate).format('M/D/YY')}` : ''}
      </div>
    </>
  )
}
