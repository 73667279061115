import { styled } from '@mui/material'
import moment from 'moment'
import { useMemo } from 'react'

import { PaymentStatus, ProviderPayout } from '@nuna/api'
import { numberService } from '@nuna/core'
import { DataTable, DataTableColumn } from '@nuna/data-table'
import {
  GhostButtonLink,
  IconButton,
  IconChevronLeft,
  IconChevronRight,
  StatusLabel,
  borderGrey,
  greySet,
} from '@nuna/tunic'

import { payrollDetail } from '../../util/routes'
import emptyImage from './img/stars-girl-color.png'

interface ProviderPayoutRow {
  amount: number
  status: PaymentStatus
  payday: string
  payrollId: string
}

const paymentStatusIntent = (status: PaymentStatus) => {
  switch (status) {
    case PaymentStatus.Paid:
      return 'information'
    case PaymentStatus.Failed:
      return 'caution'
    default:
      return 'default'
  }
}

const columns: DataTableColumn<ProviderPayoutRow>[] = [
  {
    Header: 'Pay Date',
    accessor: 'payday',
    Cell: ({ row }) => (
      <GhostButtonLink to={payrollDetail(row.original.payrollId)}>
        {moment(row.original.payday).format('MMM D, YYYY')}
      </GhostButtonLink>
    ),
  },
  {
    Header: 'Payout',
    accessor: 'amount',
    Cell: ({ row }) => <>{numberService.formatCurrency(row.original.amount || 0.0, { maximumFractionDigits: 2 })}</>,
  },
  {
    Header: 'Status',
    accessor: 'status',
    Cell: ({ row }) => (
      <StatusLabel intent={paymentStatusIntent(row.original.status)}>{row.original.status}</StatusLabel>
    ),
  },
]

interface TherapistPayoutsProps {
  payments?: ProviderPayout[]
  loading: boolean
  hasNext: boolean
  hasPrevious: boolean
  nextPage: () => void
  previousPage: () => void
}

export function TherapistPayouts({
  payments,
  loading,
  hasNext,
  hasPrevious,
  nextPage,
  previousPage,
}: TherapistPayoutsProps) {
  const rowData = useMemo(() => payments || [], [payments])
  const shouldPaginate = hasNext || hasPrevious

  function EmptyPayoutsSection() {
    return (
      <EmptyPayouts className="rounded">
        <img src={emptyImage} alt="Time to get paid" />
        <div className="h5">No payouts have come in, yet.</div>
        <EmptyExplanation>
          We'll pay you on the 10th and 25th of each month. All payouts will be reported here when they happen.
        </EmptyExplanation>
      </EmptyPayouts>
    )
  }

  return (
    <>
      {!rowData.length && !loading && <EmptyPayoutsSection />}
      {!!rowData.length && (
        <>
          <div className="h6 mt-4 mb-2">Payout History</div>
          <DataTable columns={columns} rowData={rowData} loading={loading} />
          {shouldPaginate && (
            <StyledPaginator>
              <span>
                <IconButton tooltip="Page back" disabled={!hasPrevious} onClick={previousPage}>
                  <IconChevronLeft />
                </IconButton>
                <IconButton tooltip="Page forward" disabled={!hasNext} onClick={nextPage}>
                  <IconChevronRight />
                </IconButton>
              </span>
            </StyledPaginator>
          )}
        </>
      )}
    </>
  )
}

const EmptyPayouts = styled('div')`
  text-align: center;
  margin-top: var(--margin-4);
  border: 1px solid ${greySet[15].hex};
  padding: var(--margin-4);
`

const EmptyExplanation = styled('div')`
  width: 30rem;
  margin: 0px auto var(--margin-3) auto;
`

const StyledPaginator = styled('div')`
  border-top: 1px solid ${borderGrey};
  margin-top: var(--margin-1);
  text-align: right;
`
