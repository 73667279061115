import { styled } from '@mui/material'
import { HTMLAttributes } from 'react'
import { GrConnect } from 'react-icons/gr'

import { Role } from '@nuna/api'
import { useAuthDataContext } from '@nuna/auth'
import { routeService } from '@nuna/core'
import { ConversationMenuItemLink } from '@nuna/messaging'
import { IconCalendar, IconClock, IconSessions, IconUser, MenuItem, MenuItemStack, borderGrey } from '@nuna/tunic'

import { SessionIndexTabs } from '../../scenes/Sessions/SessionIndex'
import { availabilityDetail, customerIntegrationDashboard, sessionIndex } from '../../util/routes'
import { useGlobalWidgetContext } from '../GlobalWidgetContext'
import { LiveSessionNotification } from './LiveSessionNotification'
import { ProviderAlerts } from './ProviderAlerts'
import { ProviderNavMenu } from './ProviderNavMenu'
import { ReferralsStatus } from './ReferralsStatus'

export function SidebarNavigationItems(props: HTMLAttributes<HTMLDivElement>) {
  const { login } = useAuthDataContext()
  const { resourceCenterOpen, toggleResourceCenter } = useGlobalWidgetContext()

  return (
    <div {...props}>
      <MenuItemStack>
        {login?.role === Role.Customer && (
          <MenuItem to={customerIntegrationDashboard()} leadingIcon={GrConnect}>
            Integrations
          </MenuItem>
        )}
        {(login?.role === Role.Provider || (login?.role === Role.Admin && !!login.providerId)) && (
          <>
            <LiveSessionNotification />

            <MenuItem to={routeService.scheduleDetail()} leadingIcon={IconCalendar}>
              Schedule
            </MenuItem>

            <MenuItem to={availabilityDetail()} leadingIcon={IconClock}>
              Availability
            </MenuItem>

            <MenuItem
              to={sessionIndex(SessionIndexTabs.PREVIOUS)}
              leadingIcon={IconSessions}
              isActive={location => location.pathname.startsWith('/sessions')}
            >
              Sessions
            </MenuItem>

            <MenuItem to="/clients" leadingIcon={IconUser}>
              Clients
            </MenuItem>

            <ConversationMenuItemLink />
          </>
        )}
      </MenuItemStack>

      <div className="mt-auto" style={{ paddingTop: '1rem' }}>
        <ProviderNavMenu />
        <ReferralsStatus className="mt-2" />

        <ProviderAlerts />

        <MenuItem
          className="caption"
          button
          onClick={e => {
            e.stopPropagation()
            toggleResourceCenter()
          }}
          isActive={() => resourceCenterOpen}
        >
          Help Resources
        </MenuItem>

        <MenuItem className="caption beamerTrigger" button>
          Updates
        </MenuItem>
      </div>
    </div>
  )
}

export const SidebarDivider = styled('div')`
  border-top: 1px solid ${borderGrey};
  margin: 1rem 0;
`
