import { styled } from '@mui/material'
import { useFormikContext } from 'formik'
import { HTMLAttributes } from 'react'

import {
  CancelPolicy,
  InternalAppointment,
  Patient,
  useCancelPolicyStatusQuery,
  useListPaymentMethodsQuery,
} from '@nuna/api'
import { cancelPolicyService, numberService, paymentMethodService } from '@nuna/core'
import { NoCreditCardCard } from '@nuna/coverage'
import { MessageComposer } from '@nuna/messaging'
import { CreditCardCard } from '@nuna/payment-methods'
import {
  Card,
  Checkbox,
  Collapse,
  Grid,
  Skeleton,
  StatusLabel,
  UserChip,
  csx,
  greySet,
  makeTypographyComponent,
} from '@nuna/tunic'

import { SessionForm } from '../../../types'

interface Props extends HTMLAttributes<HTMLDivElement> {
  providerId: string
  appointment: Pick<InternalAppointment, 'startDatetime'>
  client: Pick<Patient, 'id' | 'firstName' | 'lastName' | 'avatarUrl'>
  policyType: 'cancel' | 'noShow'
}
export function SessionFeeForm({ client, providerId, policyType, appointment, ...props }: Props) {
  const { values, getFieldProps } = useFormikContext<SessionForm>()

  const { data: cancelPolicyStatusData } = useCancelPolicyStatusQuery({
    variables: { providerId, clientId: client.id, defaultToTavaPolicy: true, statusAsOf: appointment.startDatetime },
  })

  const isLegacy = cancelPolicyService.isLegacy(cancelPolicyStatusData?.cancelPolicyStatus?.cancelPolicy)

  if (!cancelPolicyStatusData) {
    return <Skeleton height={10} {...props} />
  }

  // This should never be true since we pass defaultToTavaPolicy: true to the query, but this is here
  // for type safety
  if (!cancelPolicyStatusData.cancelPolicyStatus?.cancelPolicy) {
    return null
  }

  const policy = cancelPolicyStatusData.cancelPolicyStatus.cancelPolicy
  const chargeAmount = policyType === 'cancel' ? policy.chargeAmount : policy.noShowChargeAmount

  return (
    <div {...props}>
      {!isLegacy && (
        <div>
          <h6 className="sans-serif mb-2">Fees?</h6>
          <Checkbox {...getFieldProps('shouldProviderBePaid')} checked={values.shouldProviderBePaid}>
            Charge a {policyType === 'cancel' ? 'late cancelation' : 'no show'} fee
          </Checkbox>
          <div className="text-secondary caption italic mt-xs" style={{ marginLeft: 34 }}>
            Your policy is to charge clients {numberService.centsToFormattedDollars(chargeAmount ?? 0)} for{' '}
            {policyType === 'cancel' ? 'canceling' : 'not showing up'} less than 24 hours beforehand.{' '}
          </div>
        </div>
      )}
      <Collapse isOpen={values.shouldProviderBePaid || isLegacy} className={csx({ 'mt-3': !isLegacy })}>
        <Card depth={-1} className="p-2">
          {!isLegacy && (
            <>
              <CancelPolicyDisplay policy={policy} policyType={policyType} client={client} />
              <div className="v-align mt-3 mb-1">
                <span className="text-secondary text-medium mr-1">Message to:</span>
                <UserChip avatarUrl={client.avatarUrl} className="fs-mask">
                  {client.firstName} {client.lastName}
                </UserChip>
              </div>
              <MessageComposer audience="provider" />
            </>
          )}
        </Card>
      </Collapse>
    </div>
  )
}

interface PolicyProps {
  policy: CancelPolicy
  client: Pick<Patient, 'firstName' | 'id'>
  policyType: 'cancel' | 'noShow'
}

function CancelPolicyDisplay({ policy, policyType, client }: PolicyProps) {
  const { data: paymentMethodData, loading: paymentMethodLoading } = useListPaymentMethodsQuery({
    variables: { searchOptions: { defaultForFees: true, active: true, patientId: client.id } },
  })

  const feeCreditCard = (paymentMethodData?.listPaymentMethods ?? [])[0]
  const creditCardValid = paymentMethodService.isCardValid(feeCreditCard)
  const chargeAmount = policyType === 'cancel' ? policy.chargeAmount : policy.noShowChargeAmount
  const reimbursedAmount = policyType === 'cancel' ? policy.reimbursedAmount : policy.noShowReimbursedAmount

  return (
    <>
      <h6 className="text-secondary v-align mb-2">
        Client Invoice <StatusLabel className="ml-1">Your Policy</StatusLabel>
      </h6>
      <Grid container alignItems="flex-end" spacing={1}>
        <Grid
          size={{
            xs: 12,
            sm: 6,
          }}
        >
          <PolicyLabel className="mb-xs">Policy (accepted by this client):</PolicyLabel>
          <Card className="p-2">
            <CardHeader className="mb-xs">
              {numberService.centsToFormattedDollars(chargeAmount)}{' '}
              {policyType === 'cancel' ? `Late Cancelation Fee` : `No Show Fee`}
            </CardHeader>
            <Explanation>
              {numberService.centsToFormattedDollars(reimbursedAmount ?? 0)} reimbursed to you after processing fees
            </Explanation>
          </Card>
        </Grid>
        <Grid
          size={{
            xs: 12,
            sm: 6,
          }}
        >
          <PolicyLabel className="mb-xs">Charged to:</PolicyLabel>
          {paymentMethodLoading && <Skeleton height={5} />}
          {!paymentMethodLoading && creditCardValid && (
            <CreditCardCard name={feeCreditCard?.description ?? ''} number={feeCreditCard?.accountLastFour ?? ''} />
          )}
          {!paymentMethodLoading && !creditCardValid && <NoCreditCardCard />}
        </Grid>
      </Grid>
    </>
  )
}

const PolicyLabel = makeTypographyComponent('italic text-secondary caption', 'div')
const CardHeader = makeTypographyComponent('text-medium', 'div')
const Explanation = styled(makeTypographyComponent('caption italic', 'div'))`
  color: ${greySet[50].hex};
`
