import { styled } from '@mui/material'
import { noop, omit } from 'lodash'
import { ComponentType, ReactElement, cloneElement, isValidElement, useState } from 'react'
import toast from 'react-hot-toast'

import { DrawerAppointmentFragment } from '@nuna/api'
import { addressService } from '@nuna/core'
import { ButtonProps, greySet } from '@nuna/tunic'

import { allNonSpecificLoadingMessages } from '../utils/loadingMessages'
import { LoadingMessages } from './LoadingMessages'

const { formatAddress } = addressService

interface NavigationLinkProps {
  address: DrawerAppointmentFragment['address']
  showIcon?: boolean
  prefixLabel?: string
  icon?: React.ReactNode
  containerProps?: React.HTMLProps<HTMLDivElement>
  usesTrailingEllipsis?: boolean
  usesSpinner?: boolean
  labelOverride?: string
  buttonElement?: ReactElement | ComponentType<ButtonProps>
  iconIsInButton?: boolean
  buttonProps?: ButtonProps
  usesLoadingMessages?: boolean
  loadingMessages?: string[]
}

export function NavigationLink({
  address,
  icon,
  prefixLabel,
  buttonProps,
  containerProps,
  buttonElement,
  usesTrailingEllipsis = false,
  usesSpinner = true,
  labelOverride = formatAddress(address || {}),
  iconIsInButton = false,
  loadingMessages = allNonSpecificLoadingMessages,
}: NavigationLinkProps) {
  const [isLoadingNewTabFromButton, setIsLoadingNewTabFromButton] = useState(false)

  const formattedAddress = formatAddress(address || {})
  const urlFormattedDestination = formattedAddress.replace(/ /g, '+') // replace spaces with + for google maps link
  const googleMapsStaticLink = `https://google.com/maps/place/${urlFormattedDestination}`

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleButtonClick = (e: any) => {
    handleDirectionsClick(e, googleMapsStaticLink, urlFormattedDestination, setIsLoadingNewTabFromButton)
  }

  const buttonPropsWithOnClick = {
    ...buttonProps,
    onClick: handleButtonClick,
  }

  const buttonContent = (
    <LoadingMessages
      isLoading={isLoadingNewTabFromButton}
      messages={loadingMessages}
      usesTrailingEllipsis={usesTrailingEllipsis}
      usesSpinner={usesSpinner}
      wrapperProps={{ className: 'v-align' }}
    >
      <>
        {icon && iconIsInButton && icon}
        <span>{labelOverride}</span>
      </>
    </LoadingMessages>
  )

  // Render function or JSX element based on the type of buttonElement
  const RenderButton = () => {
    if (isValidElement(buttonElement)) {
      // It's an instantiated JSX element, clone it
      return cloneElement(buttonElement, buttonPropsWithOnClick, buttonContent)
    } else if (buttonElement) {
      // It's a component, render it with props
      const ButtonComponent = buttonElement as ComponentType<ButtonProps>
      return <ButtonComponent {...buttonPropsWithOnClick}>{buttonContent}</ButtonComponent>
    } else {
      // Fallback to default button
      return <button {...buttonPropsWithOnClick}>{buttonContent}</button>
    }
  }

  return (
    <div
      className={`caption v-align text-medium text-secondary ${containerProps?.className}`}
      {...omit(containerProps, 'className')}
    >
      {icon && !iconIsInButton && icon}
      {prefixLabel && (
        <>
          <span>{prefixLabel}</span> <TextDivider>|</TextDivider>{' '}
        </>
      )}
      <RenderButton />
    </div>
  )
}

const TextDivider = styled('span')`
  margin: 0 0.5rem;
  color: ${greySet[50].hex};
  font-size: 0.75rem;
  line-height: 1;
  font-size: 20px;
  font-weight: 300;
  line-height: 29px;
  letter-spacing: -0.005em;
`

const handleDirectionsClick = (
  e: React.MouseEvent<HTMLAnchorElement | HTMLButtonElement>,
  googleMapsStaticLink: string,
  urlFormattedDestination: string,
  loadingStateSetter: React.Dispatch<React.SetStateAction<boolean>> = noop,
) => {
  e.preventDefault()
  if (!navigator.geolocation) {
    console.error('Geolocation is not supported by this browser.')
    window.open(googleMapsStaticLink, '_blank')
  }
  loadingStateSetter(true)
  navigator.geolocation.getCurrentPosition(
    position => {
      const { latitude, longitude } = position.coords
      const userLocation = `${latitude},${longitude}`

      const directionsLink = `https://www.google.com/maps/dir/${userLocation}/${urlFormattedDestination}`
      const attemptOpenWindow = (url: string) => {
        const win = window.open(url, '_blank')
        if (!win) {
          toast.error('Please allow popups to open directions in a new tab')
        } else {
          win.focus()
        }
      }
      attemptOpenWindow(directionsLink)
      loadingStateSetter(false)
    },
    error => {
      console.error("Error obtaining user's location: ", error)
      loadingStateSetter(false)
    },
  )
}
