import { styled } from '@mui/material'
import { useState } from 'react'

import { OrDivider, SocialLogin } from '@nuna/auth'
import { BelowTabletLandscape, Card, CardBody, CardHeader, FillButton, Radio, tealSet } from '@nuna/tunic'

import { useSignupContext } from './SignupContext'
import { BaseSignupFormProps } from './shared'

export function SignupAccountOptions({ formValues, onStepSubmit, step }: BaseSignupFormProps) {
  const [highlightedOption, setHighlightedOption] = useState<string | null>(null)
  const { setSelectedOption } = useSignupContext()
  const selectedOption = localStorage.getItem('signupAccountOption')

  const selectOption = (option: string) => {
    setHighlightedOption(option)
    setSelectedOption(option)
  }
  return (
    <div>
      <h3 className="h3">Welcome!</h3>
      <p className="body  text-medium-gray">How would you like to get started with Tava?</p>

      <OptionCard
        className={`mb-2 ${highlightedOption === 'fullSetup' ? 'active' : ''}`}
        onClick={() => selectOption('fullSetup')}
      >
        <div className="baseline-align">
          <div className="ml-2 mr-1">
            <Radio
              checked={highlightedOption === 'fullSetup'}
              onChange={() => selectOption('fullSetup')}
              hasLabel={false}
              data-testid="full-setup-radio"
            />
          </div>
          <div>
            <AccountOptionCardHeader
              style={{ color: tealSet[80].hex, fontWeight: '400' }}
              divider={false}
              className="serif display"
            >
              Access our full suite of tools for free
            </AccountOptionCardHeader>
            <CardBody>
              <div>Receive EAP referrals and enroll with 20+ insurance plans</div>
            </CardBody>
          </div>
        </div>
      </OptionCard>

      <OptionCard className={highlightedOption === 'explore' ? 'active' : ''} onClick={() => selectOption('explore')}>
        <div className="baseline-align">
          <div className="ml-2 mr-1">
            <Radio
              checked={highlightedOption === 'explore'}
              onChange={() => selectOption('explore')}
              hasLabel={false}
            />
          </div>
          <div>
            <AccountOptionCardHeader
              style={{ color: tealSet[80].hex, fontWeight: '400' }}
              divider={false}
              className="serif display"
            >
              Explore the platform first
            </AccountOptionCardHeader>
            <CardBody>
              <div>Add your self-pay clients and test drive our free EHR</div>
            </CardBody>
          </div>
        </div>
      </OptionCard>
      {selectedOption && (
        <LoginWrapper className="mt-4">
          <SocialLogin audience="provider" from="/" heading={null} intent="signup" />
          <OrDivider style={{ marginTop: '-16px' }} className="text-center mb-2 px-3">
            or
          </OrDivider>
          <FillButton
            onClick={() => onStepSubmit(formValues, step)}
            className="full-width"
            variant="primary"
            data-testid="continue-with-email"
          >
            Continue with Email
          </FillButton>
        </LoginWrapper>
      )}
    </div>
  )
}

const OptionCard = styled(Card)`
  cursor: pointer;

  &.active {
    border: 1px solid ${tealSet[15].hex};
  }
`

const LoginWrapper = styled('div')`
  @media (${BelowTabletLandscape}) {
    bottom: 0;
    width: 100%;
    background: white;
    z-index: 999;
    left: 0;
    padding: var(--margin-2);
    position: fixed;
    box-shadow: 0px 4px 5px rgba(41, 67, 66, 0.12), 0px -4px 5px rgba(41, 67, 66, 0.12);
  }
`

const AccountOptionCardHeader = styled(CardHeader)`
  color: ${tealSet[80].hex};
  font-weight: 400;
`
