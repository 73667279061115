import { type CarePreferences } from '@nuna/core'

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function parseTherapistPreferences(rawTherapistPreferences: any) {
  return {
    therapistAvailability: rawTherapistPreferences.therapistAvailability ?? [],
    therapistAge: rawTherapistPreferences.therapistAge ?? [],
    mostImportantAttribute: rawTherapistPreferences.mostImportantAttribute ?? '',
    therapistGender: rawTherapistPreferences.therapistGender ?? [],
    therapistLanguage: rawTherapistPreferences.therapistGender ?? '',
    anythingElse: rawTherapistPreferences.anythingElse ?? '',
  } as CarePreferences
}
