import { Autocomplete, TextField as MUITextField } from '@mui/material'
import { ReactNode, useEffect, useState } from 'react'

import {
  InsurancePayerSortKeys,
  InsurancePayerStatus,
  OrderBy,
  OrderByNulls,
  PayersForClientQuery,
  ProviderEnrollmentStatus,
  usePayersForClientQuery,
} from '@nuna/api'
import { useEffectOnce } from '@nuna/common'
import { addressService } from '@nuna/core'
import { ContextualAlert, IconInfo } from '@nuna/tunic'

type InsurancePayer = PayersForClientQuery['insurancePayers']['items'][number]

interface PayerAutocompleteProps {
  value: string
  onChange: (payer: InsurancePayer | null) => void
  label: string
  state: string
  providerId?: string
  sponsorCompanyId?: string
  error?: boolean
  helperText?: string | ReactNode
  valueKey?: keyof Pick<InsurancePayer, 'id' | 'externalEligibilityId' | 'externalPayerId'>
}

function isSponsoredPayer(
  payer: Pick<InsurancePayer, 'groupPolicyCompanyIds'> | undefined | null,
  sponsorCompanyId: string | undefined | null,
) {
  if (!sponsorCompanyId) return false
  return payer?.groupPolicyCompanyIds?.includes(sponsorCompanyId) ?? false
}

function isStatePayer(payer: Pick<InsurancePayer, 'insurancePayerStates' | 'insurancePayerNetwork'>, state: string) {
  return payer.insurancePayerStates?.some(s => s.state === state) || !payer.insurancePayerNetwork?.id
}

export function PayerAutocomplete({
  onChange,
  label,
  value,
  state,
  providerId,
  sponsorCompanyId,
  error = false,
  helperText = '',
  valueKey = 'externalEligibilityId',
}: PayerAutocompleteProps) {
  const { data, loading } = usePayersForClientQuery({
    variables: {
      pagination: {
        limit: 1000,
      },
      order: [
        { key: InsurancePayerSortKeys.InsurancePayerNetworkId, direction: OrderBy.Asc, nulls: OrderByNulls.First },
        { key: InsurancePayerSortKeys.Name, direction: OrderBy.Asc },
      ],
      filters: {
        state,
        status: InsurancePayerStatus.Active,
        providerId,
        enrollmentActive: true,
        enrollmentStatus: ProviderEnrollmentStatus.Enrolled,
        includePayerNetwork: true,
      },
    },
    skip: !state,
  })
  const [selectedPayer, setSelectedPayer] = useState<InsurancePayer | null>(null)

  const payers = (data?.insurancePayers.items.slice() ?? []).sort((a, b) => {
    const aIsSponsoredPayer = isSponsoredPayer(a, sponsorCompanyId)
    const bIsSponsoredPayer = isSponsoredPayer(b, sponsorCompanyId)
    const aIsStatePayer = isStatePayer(a, state)
    const bIsStatePayer = isStatePayer(b, state)

    if (aIsSponsoredPayer !== bIsSponsoredPayer) {
      return aIsSponsoredPayer ? -1 : 1
    } else if (aIsStatePayer !== bIsStatePayer) {
      return aIsStatePayer ? -1 : 1
    } else {
      return a.name.localeCompare(b.name)
    }
  })

  useEffect(() => {
    if (data?.insurancePayers.items && value && !selectedPayer) {
      const payer = data.insurancePayers.items.find(payerNetwork => payerNetwork[valueKey] === value)

      if (payer) {
        setSelectedPayer(payer)
      }
    }
  }, [data, selectedPayer, value, valueKey])

  useEffectOnce(() => {
    const associatedPayers = data?.insurancePayers.items.filter(item => isSponsoredPayer(item, sponsorCompanyId))

    if (associatedPayers?.length === 1) {
      onChange(associatedPayers[0])
    }
  }, !!data?.insurancePayers.items && !value && !!sponsorCompanyId)

  return (
    <div className="flex-column gap-2">
      <Autocomplete
        options={payers}
        getOptionLabel={payer => payer.name}
        groupBy={payer => {
          if (isSponsoredPayer(payer, sponsorCompanyId)) return 'Offered by your sponsor'
          if (isStatePayer(payer, state)) return `Accepted in ${addressService.getStateNameFromAbbreviation(state)}`
          return `Through the ${payer.insurancePayerNetwork?.name} network`
        }}
        loading={loading}
        onChange={(_event, payer) => {
          setSelectedPayer(payer)
          onChange(payer)
        }}
        renderInput={params => (
          <MUITextField
            {...params}
            label={label}
            error={error}
            helperText={helperText}
            inputProps={{
              ...params.inputProps,
              'data-testid': 'coverage-insurance-payer',
            }}
          />
        )}
        value={selectedPayer}
      />
      {isSponsoredPayer(selectedPayer, sponsorCompanyId) && (
        <ContextualAlert icon={<IconInfo />} intent="information">
          This plan is offered by your sponsoring organization. Most members choose this insurance, unless you have a
          unique situation.
        </ContextualAlert>
      )}
    </div>
  )
}
