import * as Yup from 'yup'
import { styled } from '@mui/material'
import { GridSpacing, SelectChangeEvent } from '@mui/material'
import { useFormikContext } from 'formik'
import { noop, pick } from 'lodash'
import { useCallback, useEffect, useState } from 'react'

import {
  AddressVerificationStatus,
  StateAbbreviation,
  UsAddress,
  UsAddressVerificationResult,
  useVerifyUsAddressLazyQuery,
} from '@nuna/api'
import { ErrorWithLink, errorService, formService } from '@nuna/core'
import { supportService } from '@nuna/telemetry'
import {
  BelowTablet,
  ContextualAlert,
  FillButton,
  Grid,
  OutlineButton,
  PersistentAlert,
  PersistentAlertIntent,
  Radio,
  StateSelect,
  TextField,
  toast,
} from '@nuna/tunic'

import { AddressAutoComplete } from './AddressAutoComplete'

const { composeHelperText, oopsRequired } = formService

export interface UsAddressValues {
  addressLineOne: string
  addressLineTwo?: string | null
  city: string
  county?: string | null
  state: string
  zipCode: string
  enforceValidAddress?: boolean
  isValidAddress?: boolean
  longitute?: number | null
  latitude?: number | null
}

interface AddressFormProps {
  gridSpacing?: GridSpacing
  dataTestPrefix?: string
  displayStateChangeWarning?: boolean
  initialState?: string | null
  allowInvalidAddress?: boolean
  hideCounty?: boolean
  hideAddressLineTwo?: boolean
  canEdit?: boolean
}

export const AddressInitialValues: UsAddressValues = {
  addressLineOne: '',
  city: '',
  county: '',
  state: '',
  zipCode: '',
  enforceValidAddress: false,
  isValidAddress: false,
}

export const OmittedAddressFields = ['enforceValidAddress', 'isValidAddress']

export const addressValidationSchemaChecks = {
  addressLineOne: Yup.string().required(oopsRequired('address')),
  addressLineTwo: Yup.string().nullable().notRequired(),
  city: Yup.string().required(oopsRequired('city')),
  county: Yup.string().nullable().notRequired(),
  state: Yup.mixed().oneOf(Object.values(StateAbbreviation)).required(oopsRequired('state')),
  zipCode: Yup.string().required(oopsRequired('zip code')),
  latitude: Yup.number().nullable().notRequired(),
  longitude: Yup.number().nullable().notRequired(),
}

export function stateAbbreviationFromString(abbreviation?: string | null): StateAbbreviation | undefined {
  return Object.values(StateAbbreviation).find(state => abbreviation === state.toString())
}

interface AlertProps {
  intent: PersistentAlertIntent
  message: string
  onClick?: () => void
}

export function AddressForm({
  gridSpacing,
  dataTestPrefix = 'address',
  displayStateChangeWarning,
  initialState,
  allowInvalidAddress = true,
  hideCounty = true,
  hideAddressLineTwo = true,
  canEdit = true,
}: AddressFormProps) {
  const disabled = !canEdit
  const [verificationResult, setVerificationResult] = useState<UsAddressVerificationResult>()
  const [hideWarning, setHideWarning] = useState(false)
  const [isLoadingLookupAddress, setIsLoadingLookupAddress] = useState(false)
  const [hasOptions, setHasOptions] = useState(false)
  const [alertState, setAlertState] = useState({
    message: "We'll validate this for you",
    messageType: 'information' as PersistentAlertIntent,
    onClick: undefined as (() => void) | undefined,
  })
  const [acceptVerificationResult, setAcceptVerificationResult] = useState(true)
  const [verifyUSAddress] = useVerifyUsAddressLazyQuery({ fetchPolicy: 'network-only' })
  const { values, handleBlur, handleChange, setFieldValue, touched, errors } = useFormikContext<UsAddressValues>()
  const { addressLineOne, addressLineTwo = '', city, county = '', state, zipCode } = values
  const isEditMode = !!values.addressLineOne && !!values.city && !!values.state && !!values.zipCode
  const [initialLoad, setInitialLoad] = useState(true)
  useEffect(() => {
    setFieldValue('enforceValidAddress', !allowInvalidAddress, false)
  }, [allowInvalidAddress, setFieldValue])

  useEffect(() => {
    if (isEditMode && initialLoad) {
      setAlertState({
        message: "We'll validate this for you",
        messageType: 'information',
        onClick: undefined,
      })
      setInitialLoad(false)
    }
  }, [isEditMode, initialLoad])

  useEffect(() => {
    const getAlertData = (status: AddressVerificationStatus): AlertProps => {
      const alertDataMap = {
        [AddressVerificationStatus.Valid]: {
          intent: 'success' as PersistentAlertIntent,
          message: "Thanks! That's a valid address",
        },
        [AddressVerificationStatus.Invalid]: {
          intent: 'caution' as PersistentAlertIntent,
          message: 'Address not found',
          onClick: () => setHideWarning(false),
        },
        [AddressVerificationStatus.ValidWithRequiredChanges]: {
          intent: 'urgent' as PersistentAlertIntent,
          message: 'Address may not be valid',
        },
        [AddressVerificationStatus.ValidAndSuggestions]: {
          intent: 'caution' as PersistentAlertIntent,
          message: 'We recommend changes',
        },
      }

      const defaultAlertData = {
        intent: 'information' as PersistentAlertIntent,
        message: "We'll validate this for you",
      }
      return alertDataMap[status] || defaultAlertData
    }
    if (isLoadingLookupAddress) {
      setAlertState({ message: "We'll validate this for you", messageType: 'loading', onClick: undefined })
    } else if (
      !hasOptions &&
      values.addressLineOne.length > 0 &&
      verificationResult?.status !== 'Valid' &&
      touched.addressLineOne
    ) {
      setAlertState({ message: 'Keep typing...', messageType: 'loading', onClick: undefined })
    } else if (verificationResult && values.addressLineOne.length > 0) {
      const { intent, message, onClick } = getAlertData(verificationResult.status)
      setAlertState({ message, messageType: intent, onClick })
    } else {
      setAlertState({ message: "We'll validate this for you", messageType: 'information', onClick: undefined })
    }
  }, [
    isLoadingLookupAddress,
    hasOptions,
    verificationResult,
    values.addressLineOne,
    isEditMode,
    initialLoad,
    touched.addressLineOne,
  ])

  const verifyAddress = async (values: Partial<UsAddressValues>) => {
    setVerificationResult(undefined)
    try {
      const address = pick(values, ['addressLineOne', 'addressLineTwo', 'city', 'zipCode'])
      const state = Object.values(StateAbbreviation).find(v => v === values.state)
      const { data } = await verifyUSAddress({
        variables: {
          address: {
            ...address,
            state,
          },
        },
      })
      setVerificationResult(data?.verifyUSAddress || undefined)
      const isValid = data?.verifyUSAddress?.status === AddressVerificationStatus.Valid
      setFieldValue('isValidAddress', isValid)
      setHideWarning(false)
    } catch (error) {
      const errorMessage = errorService.transformGraphQlError(error, 'Unable to save address')
      setFieldValue('isValidAddress', false)
      toast.urgent(errorMessage)
    }
  }

  const isError = (key: keyof UsAddressValues) => {
    return !!touched[key] && !!errors[key]
  }

  const helperText = (key: keyof UsAddressValues, text = '') => {
    const isTouched = touched[key] as boolean
    const errorMsg = errors[key] as string | ErrorWithLink | undefined
    return composeHelperText(text, errorMsg, isTouched)
  }

  const handleAutoCompleteChange = useCallback(
    (event: React.ChangeEvent<unknown>, addressLineOne?: string, address?: UsAddress) => {
      setVerificationResult(undefined)
      if (addressLineOne) {
        setFieldValue('addressLineOne', addressLineOne)
        setVerificationResult(undefined)
      }
      if (address) {
        setFieldValue('addressLineOne', address.addressLineOne)
        setFieldValue('addressLineTwo', address.addressLineTwo)
        setFieldValue('city', address.city)
        setFieldValue('county', address.county)
        setFieldValue('state', address.state)
        setFieldValue('zipCode', address.zipCode)
        setVerificationResult({
          status: AddressVerificationStatus.Valid,
          suggestedAddress: address,
          errors: undefined,
        })
        setFieldValue('isValidAddress', true)
        handleBlur(event)
      }
      handleChange(event)
    },
    [handleBlur, handleChange, setFieldValue],
  )

  const handleAddressChange = (event: React.ChangeEvent<unknown> | SelectChangeEvent<unknown>) => {
    setVerificationResult(undefined)
    handleChange(event)
  }

  const verifyOnCompleteAddress = (event: React.FocusEvent<unknown>) => {
    setTimeout(() => handleBlur(event), 0)
    if (addressLineOne && !!city && !!state && !!zipCode && !verificationResult) {
      verifyAddress(values)
    }
  }

  const handleVerificationWarning = () => {
    if (acceptVerificationResult) {
      const address = verificationResult?.suggestedAddress
      if (address) {
        setFieldValue('addressLineOne', address.addressLineOne)
        setFieldValue('addressLineTwo', address.addressLineTwo)
        setFieldValue('city', address.city)
        setFieldValue('county', address.county)
        setFieldValue('state', address.state)
        setFieldValue('zipCode', address.zipCode)
        setFieldValue('longitude', address.longitude)
        setFieldValue('latitude', address.latitude)
        setVerificationResult({
          status: AddressVerificationStatus.Valid,
          suggestedAddress: address,
          errors: undefined,
        })
        setFieldValue('isValidAddress', true)
      }
    } else {
      setHideWarning(true)
    }
  }

  const addressOrLine1 =
    addressLineOne && addressLineTwo && city && state && zipCode
      ? ({ addressLineOne, addressLineTwo, city, state, zipCode } as UsAddress)
      : addressLineOne

  return (
    <Grid container spacing={gridSpacing ?? 6}>
      <Grid size={12}>
        <div>
          <Grid container>
            <Grid
              size={{
                xs: 12,
                sm: 'grow',
              }}
            >
              <AddressAutoComplete
                disabled={disabled}
                label="Street address"
                name="addressLineOne"
                onChange={handleAutoCompleteChange}
                onBlur={verifyOnCompleteAddress}
                value={addressOrLine1}
                error={isError('addressLineOne')}
                helperText={helperText('addressLineOne')}
                inputProps={{ 'data-testid': `${dataTestPrefix}-address` }}
                setIsLoadingLookupAddress={setIsLoadingLookupAddress}
                setHasOptions={setHasOptions}
              />
            </Grid>
            {canEdit && (
              <MobileMarginTopGrid
                size={{
                  xs: 12,
                  sm: 'auto',
                }}
              >
                <PersistentAlert intent={alertState.messageType} onClick={alertState.onClick}>
                  {alertState.message}
                </PersistentAlert>
              </MobileMarginTopGrid>
            )}
          </Grid>
        </div>
      </Grid>
      {!hideAddressLineTwo && (
        <Grid size={12}>
          <TextField
            label="Address (line two)"
            name="addressLineTwo"
            onChange={handleAddressChange}
            onBlur={verifyOnCompleteAddress}
            value={addressLineTwo}
            disabled={disabled}
          />
        </Grid>
      )}
      <Grid size={12}>
        <TextField
          label="City"
          name="city"
          onChange={handleAddressChange}
          onBlur={verifyOnCompleteAddress}
          value={city}
          error={isError('city')}
          helperText={helperText('city')}
          inputProps={{ 'data-testid': `${dataTestPrefix}-city` }}
          disabled={disabled}
        />
      </Grid>
      {!hideCounty && (
        <Grid size={12}>
          <TextField
            label="County"
            name="county"
            onChange={handleAddressChange}
            onBlur={verifyOnCompleteAddress}
            value={county}
            error={isError('county')}
            helperText={helperText('county')}
            inputProps={{ 'data-testid': `${dataTestPrefix}-county` }}
            disabled={disabled}
          />
        </Grid>
      )}
      <Grid
        size={{
          xs: 12,
          md: 6,
        }}
      >
        <StateSelect
          name="state"
          label="State"
          onChange={handleAddressChange}
          onBlur={verifyOnCompleteAddress}
          value={state}
          error={isError('state')}
          helperText={helperText('state')}
          disabled={disabled}
        />
      </Grid>
      <Grid
        size={{
          xs: 12,
          md: 6,
        }}
      >
        <TextField
          name="zipCode"
          label="Zip code"
          onChange={handleAddressChange}
          onBlur={verifyOnCompleteAddress}
          value={zipCode}
          error={isError('zipCode')}
          helperText={helperText('zipCode')}
          inputProps={{ 'data-testid': `${dataTestPrefix}-zip` }}
          disabled={disabled}
        />
      </Grid>
      {!!verificationResult && !hideWarning && verificationResult.status !== AddressVerificationStatus.Valid && (
        <Grid size={12}>
          {verificationResult.status === AddressVerificationStatus.Invalid && (
            <ContextualAlert
              dismissButtonText={allowInvalidAddress ? 'Use My Address Anyway' : undefined}
              onDismiss={allowInvalidAddress ? () => setHideWarning(true) : undefined}
              intent={allowInvalidAddress ? 'caution' : 'urgent'}
              fullWidth
            >
              We can't locate this address with the USPS: {verificationResult.errors?.join(' ')}
            </ContextualAlert>
          )}
          {[AddressVerificationStatus.ValidAndSuggestions, AddressVerificationStatus.ValidWithRequiredChanges].includes(
            verificationResult.status,
          ) && (
            <ContextualAlert
              intent={
                verificationResult.status === AddressVerificationStatus.ValidWithRequiredChanges ? 'urgent' : 'caution'
              }
              contentProps={{ style: { flexWrap: 'wrap' } }}
            >
              <Grid container spacing={3} className="py-1">
                <Grid size={12}>
                  <p>
                    {verificationResult.status === AddressVerificationStatus.ValidAndSuggestions &&
                      'USPS recommends this address to optimize your experience.'}
                    {verificationResult.status === AddressVerificationStatus.ValidWithRequiredChanges &&
                      'The address you entered may not be valid.'}
                  </p>
                </Grid>
                {allowInvalidAddress && (
                  <Grid size="auto">
                    <Radio
                      labelAlign="flex-start"
                      checked={!acceptVerificationResult}
                      onClick={() => setAcceptVerificationResult(false)}
                      onChange={noop}
                    >
                      <AddressDisplay
                        title="You Entered:"
                        address={{
                          addressLineOne,
                          addressLineTwo,
                          city,
                          state: state as StateAbbreviation,
                          zipCode,
                        }}
                      />
                    </Radio>
                  </Grid>
                )}
                <Grid size="auto">
                  <Radio
                    labelAlign="flex-start"
                    checked={acceptVerificationResult}
                    onClick={() => setAcceptVerificationResult(true)}
                    onChange={noop}
                  >
                    <AddressDisplay title="Recommended by USPS:" address={verificationResult.suggestedAddress} />
                  </Radio>
                </Grid>
                <Grid size={12}>
                  {verificationResult.status === AddressVerificationStatus.ValidWithRequiredChanges && (
                    <FillButton variant="primary" onClick={handleVerificationWarning} type="button">
                      Use Selected Address
                    </FillButton>
                  )}
                  {verificationResult.status === AddressVerificationStatus.ValidAndSuggestions && (
                    <OutlineButton onClick={handleVerificationWarning} type="button">
                      Use Selected Address
                    </OutlineButton>
                  )}
                </Grid>
              </Grid>
            </ContextualAlert>
          )}
        </Grid>
      )}
      {displayStateChangeWarning && initialState !== values.state && (
        <Grid size={12}>
          <ContextualAlert intent="urgent" dismissButtonText="Message Support" onDismiss={supportService.openChat}>
            Uh oh, your therapist must be licensed in the state you reside and may be unable to serve you in your new
            state. Please message our support team to update your state.
          </ContextualAlert>
        </Grid>
      )}
    </Grid>
  )
}

const MobileMarginTopGrid = styled(Grid)`
  padding-left: var(--margin-2);
  && {
    @media (${BelowTablet}) {
      padding-left: 0;
      margin-top: var(--margin-2);
    }
  }
`
interface AddressDisplayProps {
  title: string
  address: UsAddress
}

const AddressDisplay = ({ title, address }: AddressDisplayProps) => (
  <p className="text-medium-grey">
    <span className="text-default text-bold">{title}</span>
    <br />
    {address.addressLineOne}
    <br />
    {address.addressLineTwo}
    {address.addressLineTwo && <br />}
    {address.city}, {address.state} &nbsp;{address.zipCode}
  </p>
)
