import { resolvePath } from 'react-router-dom'

import {
  CaqhSyncStatus,
  DataCollectionStep,
  ListCancelPoliciesQuery,
  ProviderCredentialingDataQuery,
  ProviderDataField,
  ProviderDataSummaryFragment,
} from '@nuna/api'
import { routeService } from '@nuna/core'

import { signupIntake } from '../../util/routes'

export type IntakeStep =
  | 'welcome-support'
  | 'welcome'
  | 'professional'
  | 'availability'
  | 'profile'
  | 'abilities'
  | 'bios'
  | 'cancel-policy'
  | 'finish'
  | 'completed'

type IntakePath = {
  [key in IntakeStep]: string
}

export const INTAKE_STEPS: IntakeStep[] = [
  // TODO - uncomment when we add welcome-support step
  // 'welcome-support',
  'welcome',
  'professional',
  'profile',
  'bios',
  'abilities',
  'availability',
  'cancel-policy',
  'finish',
]

interface IntakeStepScreenStatus {
  fieldCount: number
  completed: number
  fields: ProviderDataSummaryFragment[]
  step: IntakeStep
}
export interface IntakeStepStatus {
  fieldCount: number
  completed: number
  label: string
  screens: IntakeStepScreenStatus[]
}

export interface IntakeInsuranceStepStatus {
  label: string
  available: boolean
  completed: boolean
  slug: string
}

interface SignupIntakeCategoryScreen {
  step: IntakeStep
  fields: ProviderDataField[]
}
export interface SignupIntakeCategoryDetail {
  label: string
  screens: SignupIntakeCategoryScreen[]
}

export const INTAKE_PATHS: IntakePath = {
  'welcome-support': routeService.signupIntake('welcome-support'),
  welcome: routeService.intakeWelcome,
  professional: routeService.intakeProfessional,
  profile: routeService.intakeProfile,
  bios: routeService.intakeBios,
  abilities: routeService.intakeAbilities,
  availability: routeService.intakeAvailability,
  'cancel-policy': routeService.intakeCancelPolicy,
  finish: routeService.backgroundCheckRoute,
  completed: routeService.patientIndex,
}

export const SIGNUP_INTAKE_CATEGORY_DETAILS: SignupIntakeCategoryDetail[] = [
  {
    label: 'Professional Info',
    screens: [
      {
        step: 'professional',
        fields: [
          ProviderDataField.AgeSpecialties,
          ProviderDataField.ChallengeSpecialties,
          ProviderDataField.ExpectedCaseloadUsage,
          ProviderDataField.ExpectedCaseloadAmount,
          ProviderDataField.PopulationSpecialties,
        ],
      },
    ],
  },
  {
    label: 'Personal Info',
    screens: [
      {
        step: 'profile',
        fields: [
          ProviderDataField.AvatarUrl,
          ProviderDataField.LegalFirstName,
          ProviderDataField.LegalLastName,
          ProviderDataField.Gender,
          ProviderDataField.Pronouns,
          ProviderDataField.Ethnicity,
          ProviderDataField.Dob,
          ProviderDataField.Address,
          ProviderDataField.MobilePhone,
        ],
      },
    ],
  },
  {
    label: 'Bios',
    screens: [{ step: 'bios', fields: [ProviderDataField.VideoBioSubmitted, ProviderDataField.Bio] }],
  },
  {
    label: 'Abilities',
    screens: [
      {
        step: 'abilities',
        fields: [
          ProviderDataField.Credentials,
          ProviderDataField.Npi,
          ProviderDataField.Modalities,
          ProviderDataField.TherapyTypes,
          ProviderDataField.Languages,
        ],
      },
    ],
  },
  {
    label: 'Schedule',
    screens: [
      {
        step: 'availability',
        fields: [
          ProviderDataField.Timezone,
          ProviderDataField.ExpectedStartDate,
          ProviderDataField.ExpectedAvailability,
        ],
      },
    ],
  },
]

export function getEarliestIncompleteStep(statuses: IntakeStepStatus[]) {
  const screens: IntakeStepScreenStatus[] = []
  statuses.forEach(status => screens.push(...status.screens))
  return screens.find(screen => screen.completed < screen.fieldCount)?.step
}

function getStepFromStep(step: IntakeStep, distance: number) {
  return INTAKE_STEPS[INTAKE_STEPS.indexOf(step) + distance]
}

export function getPath(step: IntakeStep, distance?: number) {
  if (!distance) {
    return resolvePath(INTAKE_PATHS[step], signupIntake)
  }
  const newStep = getStepFromStep(step, distance)
  const relativePath = INTAKE_PATHS[newStep] ?? ''
  return resolvePath(relativePath, signupIntake)
}

export function getPrevIntakePath(step: IntakeStep) {
  return getPath(step, -1)
}

export function getNextIntakePath(step: IntakeStep) {
  return getPath(step, 1)
}

export function getStepNumber(step: IntakeStep) {
  return INTAKE_STEPS.indexOf(step)
}

export function getNextStep(step: IntakeStep) {
  return getStepFromStep(step, 1)
}

export function getStepFromPath(path: string) {
  const step = (Object.keys(INTAKE_PATHS) as IntakeStep[]).find(
    (key: IntakeStep) => resolvePath(INTAKE_PATHS[key], signupIntake).pathname === path,
  )
  return step ?? 'welcome-support'
}

export function buildStepStatuses(items: ProviderDataSummaryFragment[]): IntakeStepStatus[] {
  return SIGNUP_INTAKE_CATEGORY_DETAILS.map(detail => {
    const screens = detail.screens.map(screen => {
      const fields = items.filter(item => item.field && screen.fields.includes(item.field) && item.required)

      return {
        step: screen.step,
        fieldCount: fields.length,
        completed: fields.filter(item => item.hasValue).length,
        fields,
      }
    })

    return {
      fieldCount: screens.reduce((aggregate, screen) => screen.fieldCount + aggregate, 0),
      completed: screens.reduce((aggregate, screen) => aggregate + screen.completed, 0),
      label: detail.label,
      screens,
    }
  })
}

export function buildBackgroundCheckStatuses(items: ProviderDataSummaryFragment[]) {
  const backgroundCheckFields = items.filter(item => item.field === ProviderDataField.RequestedBackgroundCheck)
  const backgroundCheckField = backgroundCheckFields[0]
  return {
    label: 'Background Check',
    available: true,
    completed: backgroundCheckField.hasValue ?? false,
    slug: 'background-check',
  }
}

export function buildCancelPolicyStatus(cancelPolicyData: ListCancelPoliciesQuery['listCancelPolicies']) {
  return {
    label: 'Cancel Policy',
    available: true,
    completed: !!cancelPolicyData.length,
    slug: 'cancel',
  }
}

const CREDENTIALING_DATA_STEPS = [
  DataCollectionStep.Started,
  DataCollectionStep.CaqhCredentials,
  DataCollectionStep.Resume,
  DataCollectionStep.ExistingInsurance,
  DataCollectionStep.Caqh,
  DataCollectionStep.AttestationSignature,
  DataCollectionStep.Completed,
]

export function buildInsuranceStepsStatuses(
  providerCredentialingData: ProviderCredentialingDataQuery['providerCredentialingData'] | undefined,
) {
  const { currentStep, caqhSyncStatus, resumeDocumentId, acceptsInsurance } = providerCredentialingData ?? {}
  const insuranceSteps = [
    {
      label: 'CAQH Credentials',
      available: !!providerCredentialingData,
      completed: !!providerCredentialingData && caqhSyncStatus !== CaqhSyncStatus.NotStarted && caqhSyncStatus !== null,
      slug: 'caqh-sync',
    },
    {
      label: 'Resume',
      available: !!(currentStep && CREDENTIALING_DATA_STEPS.indexOf(currentStep) >= 2) || !!resumeDocumentId,
      completed: !!resumeDocumentId,
      slug: 'resume',
    },
    {
      label: 'Insurances Accepted',
      available:
        !!(currentStep && CREDENTIALING_DATA_STEPS.indexOf(currentStep) >= 3) ||
        (acceptsInsurance !== null && acceptsInsurance !== undefined),
      completed: acceptsInsurance !== null && acceptsInsurance !== undefined,
      slug: 'existing-insurance',
    },
    {
      label: 'CAQH Missing Fields',
      available: !!(currentStep && CREDENTIALING_DATA_STEPS.indexOf(currentStep) >= 4),
      completed: !!providerCredentialingData && providerCredentialingData?.caqhCompletedAt !== null,
      slug: 'caqh-profile',
    },
    {
      label: 'Sign Attestation',
      available: !!(currentStep && CREDENTIALING_DATA_STEPS.indexOf(currentStep) >= 5),
      completed:
        !!providerCredentialingData &&
        providerCredentialingData?.attestationDocumentId !== null &&
        providerCredentialingData?.attestationSignedAt !== null,
      slug: 'attestation',
    },
  ]

  return insuranceSteps
}
