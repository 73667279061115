import { createContext, useContext, useState } from 'react'

import { PaymentPreference, usePatientCoverageQuery } from '@nuna/api'
import { type DiscriminatedPatientCoverageFragment, coverageService } from '@nuna/core'

import { type ClientCoveragePatient } from '../../../types'

const { getMostRecentCoverageByType, getPostCapPreference, getPrimaryCoverage, getSortedCoverageTypes } =
  coverageService

interface ContextValues {
  activeCoverageDetails: DiscriminatedPatientCoverageFragment | undefined
  activeCoverageFormType: PaymentPreference | undefined
  client: ClientCoveragePatient
  closeCoverageDetails: () => void
  closeCoverageForm: () => void
  coverages: ReturnType<typeof getMostRecentCoverageByType>
  isHorizontalLayout: boolean
  openCoverageDetails: (coverage: DiscriminatedPatientCoverageFragment) => void
  openCoverageForm: (type: PaymentPreference) => void
  postCapPreference: PaymentPreference
  primaryCoverage: DiscriminatedPatientCoverageFragment | undefined
  refetchClient: () => void
  refetchCoverage: () => void
  sortedCoverageTypes: PaymentPreference[]
}

const ClientCoveragePanelContext = createContext<ContextValues | null>(null)

interface Props {
  children: React.ReactNode
  client: ClientCoveragePatient
  isHorizontalLayout: boolean
  refetchClient: () => void
}

export function ClientCoveragePanelContextProvider({ client, refetchClient, children, isHorizontalLayout }: Props) {
  const [activeCoverageDetails, setActiveCoverageDetails] = useState<DiscriminatedPatientCoverageFragment>()
  const [activeCoverageFormType, setActiveCoverageFormType] = useState<PaymentPreference>()

  const { data, refetch: refetchCoverage } = usePatientCoverageQuery({
    variables: { patientId: client.id },
    fetchPolicy: 'cache-and-network',
  })

  const coverages = getMostRecentCoverageByType(data?.patientCoverage ?? [])
  const sortedCoverageTypes = getSortedCoverageTypes(data?.patientCoverage ?? [])
  const primaryCoverage = getPrimaryCoverage(data?.patientCoverage ?? [])
  const postCapPreference = getPostCapPreference(client, coverages)

  function closeCoverageDetails() {
    setActiveCoverageDetails(undefined)
  }

  function closeCoverageForm() {
    setActiveCoverageFormType(undefined)
  }

  function openCoverageDetails(coverage: DiscriminatedPatientCoverageFragment) {
    closeCoverageForm()
    setActiveCoverageDetails(coverage)
  }

  function openCoverageForm(type: PaymentPreference) {
    closeCoverageDetails()
    setActiveCoverageFormType(type)
  }

  return (
    <ClientCoveragePanelContext.Provider
      value={{
        activeCoverageDetails,
        activeCoverageFormType,
        client,
        closeCoverageDetails,
        closeCoverageForm,
        coverages,
        isHorizontalLayout,
        openCoverageDetails,
        openCoverageForm,
        postCapPreference,
        primaryCoverage,
        refetchClient,
        refetchCoverage,
        sortedCoverageTypes,
      }}
    >
      {children}
    </ClientCoveragePanelContext.Provider>
  )
}

export function useClientCoveragePanelContext() {
  const context = useContext(ClientCoveragePanelContext)
  if (!context) {
    throw new Error('useClientCoveragePanelContext must be used within a ClientCoveragePanelContextProvider')
  }
  return context
}
