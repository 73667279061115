import React, { useEffect, useState } from 'react'

import { SpinningLoader } from '@nuna/tunic'

interface LoadingMessagesProps {
  isLoading: boolean
  messages?: string[]
  interval?: number
  children: React.ReactNode
  wrapperProps?: React.HTMLProps<HTMLSpanElement>
  usesSpinner?: boolean
  usesTrailingEllipsis?: boolean
}

export const LoadingMessages = ({
  isLoading,
  messages,
  interval = 1600,
  children,
  wrapperProps,
  usesSpinner = true,
  usesTrailingEllipsis = false,
}: LoadingMessagesProps) => {
  const [currentMessageIndex, setCurrentMessageIndex] = useState(0)

  useEffect(() => {
    if (isLoading && messages && messages.length > 0) {
      const intervalId = setInterval(() => {
        const randomIndex = Math.floor(Math.random() * messages.length)
        setCurrentMessageIndex(randomIndex)
      }, interval)

      return () => {
        clearInterval(intervalId)
      }
    }
  }, [isLoading, messages, interval])

  return (
    <span {...wrapperProps}>
      {!isLoading ? (
        children
      ) : (
        <span className="v-align">
          <span className={`${usesSpinner ? 'mr-1 v-align' : ''}`}>
            {messages?.[currentMessageIndex] ?? children}
            {usesTrailingEllipsis && '...'}
          </span>
          {usesSpinner && <SpinningLoader size={15} style={{ right: '5px' }} />}
        </span>
      )}
    </span>
  )
}
