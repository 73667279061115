import { useFormikContext } from 'formik'
import { HTMLAttributes, useMemo } from 'react'

import { ProviderListItemFragment, useBasicProviderQuery } from '@nuna/api'
import { useIsAdmin } from '@nuna/auth'
import { useClientInviteDrawerSearchParams } from '@nuna/common'
import { Audience } from '@nuna/core'
import { PendingPatient } from '@nuna/coverage'
import { ProviderSelectForClient } from '@nuna/provider'
import { supportService } from '@nuna/telemetry'
import { ContextualAlert, FillButton, GhostButton, TextButtonExternalLink, greySet } from '@nuna/tunic'

import { ClientInviteFormValues, FormActions, FormContainer, Section, SectionTitle } from '../shared'
import { ClientInviteContactInfo } from './components/ClientInviteContactInfo'
import { ClientInvitePaymentMethod } from './components/ClientInvitePaymentMethod'

interface ClientInviteFormProps extends HTMLAttributes<HTMLFormElement> {
  audience: Audience
  providerId: string
  loading: boolean
}

export function ClientInviteForm({ audience, providerId, loading, ...props }: ClientInviteFormProps) {
  const isAdmin = useIsAdmin()

  const { dirty, isValid, values, handleSubmit } = useFormikContext<ClientInviteFormValues>()

  const { closeDrawer, openClientInviteDrawer } = useClientInviteDrawerSearchParams()

  const { data } = useBasicProviderQuery({
    variables: { id: providerId || '' },
    skip: !providerId,
  })

  const provider = useMemo(() => {
    if (providerId && data) {
      return data.provider as unknown as ProviderListItemFragment
    }

    return null
  }, [providerId, data])

  const patient: PendingPatient = useMemo(
    () => ({
      firstName: values.firstName,
      lastName: values.lastName,
      dob: values.dob,
      state: values.state,
      timezone: values.timezone,
    }),
    [values.dob, values.firstName, values.lastName, values.state, values.timezone],
  )

  return (
    <FormContainer onSubmit={handleSubmit} {...props}>
      <Section style={{ backgroundColor: 'white', paddingBottom: '0px' }}>
        <h2 className="h5 mb-3" style={{ alignSelf: 'flex-start' }}>
          Add a Client
        </h2>
      </Section>

      <ClientInviteContactInfo
        disabled={loading}
        isAdmin={audience === 'admin' && isAdmin}
        style={{ backgroundColor: 'white', paddingTop: '0px' }}
        providerId={providerId}
      />

      {audience === 'admin' && isAdmin && (
        <Section>
          <SectionTitle>Provider (optional)</SectionTitle>
          <ProviderSelectForClient
            disabled={!values.state}
            state={values.state}
            onSelect={provider => {
              openClientInviteDrawer({ providerId: provider?.id })
            }}
            selectedProvider={provider}
          />
        </Section>
      )}
      <Section
        style={{ backgroundColor: 'white', paddingBottom: '30px', borderBottom: `1px solid ${greySet[15].hex}` }}
      >
        <ContextualAlert dismissButtonText="Dismiss" intent="default" role="alert" scribbleType="default">
          <p>
            Your client will be sent a link to finish intake. <br />
            Learn more about what they'll see{' '}
            <TextButtonExternalLink variant="secondary" href={supportService.articles.whatWillClientSee}>
              here
            </TextButtonExternalLink>
            .
          </p>
        </ContextualAlert>
      </Section>

      <ClientInvitePaymentMethod audience={audience} disabled={loading} patient={patient} providerId={provider?.id} />

      <FormActions>
        <GhostButton onClick={closeDrawer} disabled={loading} variant="secondary" style={{ cursor: 'pointer' }}>
          Cancel
        </GhostButton>
        <FillButton type="submit" className="ml-2 mr-2" isLoading={loading} disabled={!dirty || !isValid}>
          Add Client
        </FillButton>
      </FormActions>
    </FormContainer>
  )
}
