// eslint-disable-next-line prettier/prettier, no-restricted-imports
import { type CreateStyled } from '@emotion/styled'

/*
 * https://github.com/emotion-js/emotion/issues/2193#issuecomment-864787519
 *
 * This is helper function to automatically remove any props that start with $
 * when using the MUI styled() function. This is a semantic that we came up with
 * to avoid having to write custom remove proper handlers all over the place.
 *
 * if you need to pass a proper into a MUI styled component but do NOT want it
 * forwarded to the styled element. Prefix it wtih a `$`
 */
const transientPropOptions: Parameters<CreateStyled>[1] = {
  shouldForwardProp: (propName: string) => !propName.startsWith('$'),
}

export const styledUtils = {
  transientPropOptions,
}
