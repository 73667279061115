import { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'

import { ProviderPayout, useProviderPayoutsQuery } from '@nuna/api'
import { useAuthDataContext } from '@nuna/auth'

import { payrollDetail } from '../../util/routes'
import { PayrollDetail } from './PayrollDetail'
import { PayrollSummary } from './PayrollSummary'
import { UpcomingPayoutsContextProvider } from './UpcomingPayoutsContext'

export const upcomingParam = 'upcoming'
export const unpaidParam = 'unpaid'

export function Payroll() {
  const params = useParams()
  const navigate = useNavigate()
  const [payment, setPayment] = useState<ProviderPayout>()
  const { login } = useAuthDataContext()
  const providerId = login?.providerId || ''

  const { loading, data, refetch } = useProviderPayoutsQuery({
    variables: { providerId },
    fetchPolicy: 'cache-and-network',
    notifyOnNetworkStatusChange: true,
  })

  useEffect(() => {
    const newPayment = data?.providerPayouts?.items.find(p => p.payrollId === params['*'])
    if (!loading) {
      setPayment(newPayment)
    }
  }, [params, data, loading])

  function handlePaginate(direction: 'next' | 'prev'): () => Promise<ProviderPayout[]> {
    return async (): Promise<ProviderPayout[]> => {
      const pageToken =
        direction === 'next'
          ? data?.providerPayouts?.pagination?.nextToken
          : data?.providerPayouts?.pagination?.prevToken
      return refetch({
        providerId,
        pagination: {
          pageToken,
        },
      }).then(response => response.data?.providerPayouts?.items)
    }
  }

  const payments = data?.providerPayouts?.items
  const paymentIndex = data?.providerPayouts?.items.findIndex(p => p.id === payment?.id)
  const hasNextPage = !!data?.providerPayouts?.pagination?.nextToken
  const hasPreviousPage = !!data?.providerPayouts?.pagination?.prevToken
  const hasNextPayment =
    params['*'] !== upcomingParam &&
    params['*'] !== unpaidParam &&
    paymentIndex !== undefined &&
    payments !== undefined &&
    (paymentIndex < payments.length - 1 || hasNextPage)
  const hasPreviousPayment =
    params['*'] !== upcomingParam &&
    params['*'] !== unpaidParam &&
    paymentIndex !== undefined &&
    payments !== undefined &&
    (paymentIndex > 0 || hasPreviousPage)

  function handlePaymentPaginate(direction: 'next' | 'prev') {
    return async () => {
      if (paymentIndex === undefined || payments === undefined) return

      if (direction === 'next') {
        if (paymentIndex === payments.length - 1) {
          const payments = await handlePaginate('next')()
          navigate(payrollDetail(payments[0].payrollId))
        } else {
          navigate(payrollDetail(payments[paymentIndex + 1].payrollId))
        }
      } else {
        if (paymentIndex === 0) {
          const payments = await handlePaginate('prev')()
          navigate(payrollDetail(payments[payments.length - 1].payrollId))
        } else {
          navigate(payrollDetail(payments[paymentIndex - 1].payrollId))
        }
      }
    }
  }

  return (
    <UpcomingPayoutsContextProvider>
      {payment || params['*'] === upcomingParam || params['*'] === unpaidParam ? (
        <PayrollDetail
          providerId={providerId}
          payment={payment}
          hasNext={hasNextPayment}
          hasPrevious={hasPreviousPayment}
          onPrevious={handlePaymentPaginate('prev')}
          onNext={handlePaymentPaginate('next')}
          detailId={params['*'] || ''}
        />
      ) : (
        <PayrollSummary
          loading={loading}
          payments={payments}
          hasNext={hasNextPage}
          hasPrevious={hasPreviousPage}
          nextPage={handlePaginate('next')}
          previousPage={handlePaginate('prev')}
        />
      )}
    </UpcomingPayoutsContextProvider>
  )
}
