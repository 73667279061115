import { useState } from 'react'

import { CancelPolicyItem } from '@nuna/core'

import { CancelPolicyDetail } from './components/CancelPolicies/components/CancelPolicyDetail'
import { CancelPolicyForm } from './components/CancelPolicies/components/CancelPolicyForm'
import { CancelPolicyList } from './components/CancelPolicies/components/CancelPolicyList'

type Props = {
  cancelPolicies: CancelPolicyItem[]
  providerId?: string
}

export function IntakeCancelPolicyForm({ cancelPolicies, providerId }: Props) {
  const [editedPolicy, setEditedPolicy] = useState<null | CancelPolicyItem>(null)
  const [detailedPolicy, setDetailedPolicy] = useState<null | CancelPolicyItem>(null)
  return (
    <>
      {!editedPolicy && !detailedPolicy && (
        <CancelPolicyList
          cancelPolicies={cancelPolicies}
          providerId={providerId ?? ''}
          supressAdd
          onEdit={setEditedPolicy}
          onViewDetail={setDetailedPolicy}
        />
      )}

      {(!cancelPolicies.length || !!editedPolicy) && (
        <CancelPolicyForm
          afterSubmit={() => setEditedPolicy(null)}
          cancelPolicy={editedPolicy ?? null}
          providerId={providerId ?? ''}
          navigateTo={null}
          canCancel={false}
        />
      )}

      {detailedPolicy && (
        <CancelPolicyDetail
          hideDetail={() => setDetailedPolicy(null)}
          cancelPolicy={detailedPolicy}
          providerId={providerId ?? ''}
        />
      )}
    </>
  )
}
