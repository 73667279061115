import * as Sentry from '@sentry/browser'
import { ApolloProvider } from '@apollo/client'
import { FullStory, init as initFullStory } from '@fullstory/browser'
import { fullStoryIntegration } from '@sentry/fullstory'
import 'focus-visible'
import mixpanel from 'mixpanel-browser'
import { StrictMode } from 'react'
import { createRoot } from 'react-dom/client'

import { Role } from '@nuna/api'
import { AuthDataProvider } from '@nuna/auth'

import { App } from './App/App'
import { environment } from './environments/environment'
import { client } from './util/apollo'

if (import.meta.env.PROD) {
  initFullStory({ orgId: 'NXA0K' })

  Sentry.init({
    dsn: 'https://426999d494b041dbbdcb699435546cb2@o339347.ingest.us.sentry.io/1875466',
    integrations: [fullStoryIntegration('339347', { client: FullStory })],
    environment: import.meta.env.VITE_ENVIRONMENT || 'UNKNOWN_ENV',
  })
}

try {
  mixpanel.init(environment.MIXPANEL_TOKEN, {
    test: environment.CI,
    opt_out_tracking_by_default: environment.CI,
  })
} catch (err) {
  console.warn('Mixpanel could not load! booting anyway: ', err)
}

const rootElement = document.getElementById('root')

if (rootElement) {
  const root = createRoot(rootElement)

  const app = (
    <ApolloProvider client={client}>
      <AuthDataProvider allowedRoles={[Role.Provider]}>
        <App />
      </AuthDataProvider>
    </ApolloProvider>
  )
  root.render(environment.STRICT_MODE ? <StrictMode>{app}</StrictMode> : app)

  // @ts-expect-error - value replaced by vite
  // eslint-disable-next-line no-console
  console.info('Deployed at', __BUILD_TIMESTAMP__, 'production:', environment.production)
}
