import { BasicSpecialtyFragment } from '@nuna/api'
import { ReadOnlyProfileSection } from '@nuna/common'
import { Chip, ChipGroup, ContextualAlert } from '@nuna/tunic'

import { SPECIALTY_CATEGORY_AREA_OF_IMPACT } from '../../constants'

interface ClientProfileAreasOfImpactProps {
  patient:
    | {
        specialties: BasicSpecialtyFragment[]
      }
    | undefined
}

const heading = 'Areas of Impact'

export function ClientProfileAreasOfImpact({ patient }: ClientProfileAreasOfImpactProps) {
  if (!patient) {
    return <ReadOnlyProfileSection isLoading heading={heading} renderDisplayValue={null} />
  }

  const areasOfImpact = (patient.specialties || []).filter(
    specialty => specialty.specialtyCategory.name === SPECIALTY_CATEGORY_AREA_OF_IMPACT,
  )

  return (
    <ReadOnlyProfileSection
      heading={heading}
      renderDisplayValue={
        areasOfImpact.length > 0 ? (
          <ChipGroup>
            {areasOfImpact.map(area => (
              <Chip key={area.id} disabled small checked>
                {area.name}
              </Chip>
            ))}
          </ChipGroup>
        ) : (
          <ContextualAlert role="status" fullWidth showScribble largeText>
            No info provided
          </ContextualAlert>
        )
      }
    />
  )
}
