import { styled } from '@mui/material'
import moment from 'moment'
import pluralize from 'pluralize'
import { useNavigate } from 'react-router-dom'

import {
  type ProviderDetailsQuery,
  type PublicAvailabilitySlotFragment,
  usePatientContextQuery,
  useProviderDetailsQuery,
} from '@nuna/api'
import { DrawerStepper } from '@nuna/common'
import {
  Card,
  CardBody,
  GhostButton,
  IconCalendar,
  IconError,
  IconSwitch,
  UserChip,
  borderGrey,
  makeTypographyComponent,
  toast,
} from '@nuna/tunic'

import { AppointmentDrawerPaddedContainer } from '../../../AppointmentDrawer'
import { AppointmentDrawerHeader } from './AppointmentDrawerHeader'

interface SwitchProviderConfirmationProps {
  newProvider: ProviderDetailsQuery['provider']
  timeSlot: PublicAvailabilitySlotFragment
  oldProviderId: string
  onConfirmClick: () => void
}

export function SwitchProviderConfirmation({
  newProvider,
  timeSlot,
  oldProviderId,
  onConfirmClick,
}: SwitchProviderConfirmationProps) {
  const { data: patientContextData } = usePatientContextQuery()
  const { data: oldProviderData } = useProviderDetailsQuery({ variables: { id: oldProviderId } })
  const navigate = useNavigate()

  const oldProvider = oldProviderData?.provider

  const appointments =
    patientContextData?.patientContext.patient.appointments
      .filter(appt => new Date(appt.endDatetime) >= new Date())
      .filter(appt => !appt.canceled)
      .filter(appt => appt.provider.id === oldProviderId) ?? []

  return (
    <div>
      <AppointmentDrawerHeader provider={newProvider} timeSlot={timeSlot} />

      <AppointmentDrawerPaddedContainer>
        <h3 className="h5 mb-3">By confirming this appointment you will</h3>

        <Grid>
          <IconSwitch size={20} className="mr-1 mb-1" style={{ position: 'relative', top: '2px' }} />
          <span className="mb-1">Change your personal therapist</span>
          <SmallText>from</SmallText>{' '}
          <span>
            <UserChip avatarUrl={oldProvider?.avatarUrl}>
              {oldProvider?.firstName} {oldProvider?.lastName}
            </UserChip>
          </span>
          <SmallText>to</SmallText>{' '}
          <span>
            <UserChip avatarUrl={newProvider?.avatarUrl}>
              {newProvider?.firstName} {newProvider?.lastName}
            </UserChip>
          </span>
        </Grid>

        {appointments.length > 0 && (
          <Grid className="mt-2">
            <IconError size={20} className="mr-1 mb-1" />{' '}
            <span className="mb-1">
              And cancel {pluralize('this', appointments.length)} {appointments.length} remaining{' '}
              {pluralize('appointment', appointments.length)} with {oldProvider?.firstName}
            </span>
            {appointments.map(appointment => (
              <>
                <span />
                <Card>
                  <CardBody className="v-align">
                    <IconCalendar className="mr-1" />
                    {moment(appointment.startDatetime).format('dddd, MMM Do [at] LT')}
                  </CardBody>
                </Card>
              </>
            ))}
          </Grid>
        )}

        <div className="text-center">
          <GhostButton
            className="mt-5"
            onClick={() => {
              toast.info(
                `We'll keep you with ${oldProvider?.firstName} for now. Remember, you can change at any time.`,
                { noIcon: true },
              )
              navigate('/')
            }}
          >
            Nevermind, stick with {oldProvider?.firstName}
          </GhostButton>
        </div>
      </AppointmentDrawerPaddedContainer>

      <DrawerStepper className="mt-6" nextButtonText="Confirm changes" onNextButtonClick={onConfirmClick} />
    </div>
  )
}

const SmallText = makeTypographyComponent('caption text-medium text-secondary', 'span')

const Section = styled('section')`
  border-bottom: 1px solid ${borderGrey};
  padding-bottom: 2rem;
`

const Grid = styled(Section)`
  align-items: center;
  display: grid;
  gap: 0.5rem 1rem;
  grid-template-columns: auto 1fr;
`
