import { Palette, ThemeOptions } from '@mui/material'

// Note: The types for the custom colors are defined in types/muiThemeAugmentation.d.ts

/** These Color objects exported only for reference in deprecated colors.ts, colorSets.ts. Use theme.palette everywhere else. */
export const salmon: Palette['salmon'] = {
  50: '#FFF6F5',
  100: '#FCEEEC',
  200: '#FFE5E1',
  300: '#FCAEA4',
  400: '#FC918B',
  500: '#FD7472',
  600: '#D75856',
  700: '#B23D3B',
  800: '#832322',
  900: '#491313',
  A100: 'rgba(255, 189, 180, 0.2)',
  A200: 'rgba(255, 189, 180, 0.45)',
  A400: 'rgba(255, 189, 180, 0.60)',
  A700: 'rgba(255, 189, 180, 0.8)',
}

/** These Color objects exported only for reference in deprecated colors.ts, colorSets.ts. Use theme.palette everywhere else. */
export const plum: Palette['plum'] = {
  50: '#FFF5FA',
  100: '#FFECF6',
  200: '#F8DEEC',
  300: '#D390B5',
  400: '#BD6F99',
  500: '#A74E7E',
  600: '#8C3E64',
  700: '#722E4A',
  800: '#49182C',
  900: '#39081C',
  A100: 'rgba(214, 130, 175, 0.16)',
  A200: 'rgba(214, 130, 175, 0.3)',
  A400: 'rgba(214, 130, 175, 0.55)',
  A700: 'rgba(214, 130, 175, 0.70)',
}

/** These Color objects exported only for reference in deprecated colors.ts, colorSets.ts. Use theme.palette everywhere else. */
export const teal: Palette['teal'] = {
  50: '#F0FAF9',
  100: '#E1F6F4',
  200: '#C6EFED',
  300: '#6AD0C4',
  400: '#53BDB7',
  500: '#3DAAAA',
  600: '#017D8D',
  700: '#065D69',
  800: '#00424D',
  900: '#003138',
  A100: 'rgba(82, 226, 201, 0.18)',
  A200: 'rgba(82, 226, 201, 0.36)',
  A400: 'rgba(82, 226, 201, 0.56)',
  A700: 'rgba(82, 226, 201, 0.70)',
}

/** These Color objects exported only for reference in deprecated colors.ts, colorSets.ts. Use theme.palette everywhere else. */
export const yellow: Palette['yellow'] = {
  50: '#FFFAF0',
  100: '#FFF6E1',
  200: '#FFF2D3',
  300: '#FFDA83',
  400: '#FFCA55',
  500: '#F3B521',
  600: '#EF9C1A',
  700: '#D76700',
  800: '#A34400',
  900: '#803300',
  A100: 'rgba(255, 218, 131, 0.2)',
  A200: 'rgba(255, 218, 131, 0.45)',
  A400: 'rgba(255, 181, 33, 0.55)',
  A700: 'rgba(255, 181, 33, 0.7)',
}

/** These Color objects exported only for reference in deprecated colors.ts, colorSets.ts. Use theme.palette everywhere else. */
export const grey: Palette['grey'] = {
  50: '#FAF8F7',
  100: '#F3F2F2',
  200: '#ECEAE9',
  300: '#D4D3D0',
  400: '#ACAEAD',
  500: '#838989',
  600: '#606969',
  700: '#374343',
  800: '#1A2828',
  900: '#0D1414',
  A100: 'rgba(167, 157, 149, 0.18)',
  A200: 'rgba(167, 157, 149, 0.32)',
  A400: 'rgba(167, 157, 149, 0.55)',
  A700: 'rgba(167, 157, 149, 0.70)',
}

/** These Color objects exported only for reference in deprecated colors.ts, colorSets.ts. Use theme.palette everywhere else. */
export const white: Palette['white'] = {
  A100: 'rgba(255, 255, 255, 0.15)',
  A200: 'rgba(255, 255, 255, 0.3)',
  A400: 'rgba(255, 255, 255, 0.5)',
  A700: 'rgba(255, 255, 255, 0.6)',
}

export const palette: ThemeOptions['palette'] = {
  text: { primary: teal['800'], secondary: grey['600'] },
  // TODO: the semantic color mappings are keeping the old settings to avoid surprises where we use MUI components. Will update when we do buttons and consolidate components
  primary: {
    main: teal['500'],
  },
  secondary: {
    main: teal['800'],
  },
  error: {
    main: salmon['700'],
  },
  salmon,
  plum,
  teal,
  yellow,
  grey,
  white,
}
