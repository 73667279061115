import { GlobalStyles, css } from '@mui/material'

import { fullCalendarStyles } from './overrides/fullCalendarStyles'
import { materialUIStyles } from './overrides/materialUIStyles'

const harmonyAshokaCss = css`
  body {
    height: 100%;
    overflow: hidden; /*makes the body non-scrollable (we will add scrolling to the sidebar and main content containers)*/
    display: flex; /*enables flex content for its children*/
  }

  #root {
    margin: 0;
    height: 100%;
    overflow: hidden; /*makes the body non-scrollable (we will add scrolling to the sidebar and main content containers)*/
    display: flex; /*enables flex content for its children*/
    box-sizing: border-box;
    width: 100%;
  }

  /** UTILS */
  .BeaconFabButtonFrame {
    display: none;
  }

  ${fullCalendarStyles}
  ${materialUIStyles}
`

// recommended by MUI to avoid re-rendering the <style> tag https://mui.com/material-ui/customization/how-to-customize/#api
export const harmonyAshokaStyles = <GlobalStyles styles={harmonyAshokaCss} />
