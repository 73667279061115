import * as Yup from 'yup'
import { omit } from 'lodash'
import moment from 'moment'

import { AddressOwner, AddressType, AvailabilityConstraintInput, useProviderAddressesQuery } from '@nuna/api'
import { availabilityService } from '@nuna/core'

import { useProviderAppContext } from '../../../../shared/ProviderAppContext'
import { AvailabilityFields } from '../../shared/AvailabilityFields'
import { IntakeStepContainer } from '../../shared/IntakeStepContainer'
import { IntakeHeader } from '../../shared/IntakeTypography'
import { ProviderIntakeFormStepper } from '../../shared/ProviderIntakeFormStepper'
import {
  ProviderSignupFormSchema,
  ProviderSignupFormValues,
  ProviderSignupIntakeFormProps,
  SaveProviderIntakeInput,
} from '../../shared/provider-signup-intake-types'
import { useProviderSignupIntakeStepSetup } from '../../shared/useProviderSignupIntakeStepSetup'

const { getAvailabilityConstraintInput } = availabilityService

const validationSchema: ProviderSignupFormSchema = Yup.object({
  // expectedCaseloadAmount: Yup.number().required('Please set a max number of clients'),
  expectedStartDate: Yup.date().required('Please set a start date').typeError('Please set a valid start date'),
  availabilityConstraints: Yup.array(Yup.object<AvailabilityConstraintInput>()).min(
    1,
    'Please select a day and time for availability',
  ),
  timezone: Yup.string().required('Please select a timezone'),
})

export function IntakeAvailability({
  values,
  setFieldValue,
  errors,
  touched,
  handleBlur,
  saveIntakeLoading,
  validateForm,
  isValid,
}: ProviderSignupIntakeFormProps) {
  const { provider } = useProviderAppContext()
  const { id: providerId } = provider ?? {}

  useProviderSignupIntakeStepSetup({ validationSchema, buildSaveValues }, validateForm)
  const { data: providerAddressData } = useProviderAddressesQuery({
    variables: {
      searchOptions: {
        providerId,
        providerAddressType: AddressType.ProviderPractice,
        owner: AddressOwner.Provider,
      },
    },
    skip: !providerId,
  })

  const availabilityConstraints = values.availabilityPeriods

  return (
    <IntakeStepContainer maxWidth="932px">
      <IntakeHeader type="h1" md={{ mb: 6 }} xs={{ mb: 5 }}>
        Let's open the doors for business
      </IntakeHeader>
      <AvailabilityFields
        touched={{
          expectedStartDate: touched.expectedStartDate,
          availabilityPeriods: touched.availabilityPeriods,
          timezone: touched.timezone,
        }}
        errors={{
          expectedStartDate: errors.expectedStartDate,
          availabilityPeriods: errors.availabilityPeriods,
          timezone: errors.timezone,
        }}
        timezone={values.timezone}
        expectedStartDate={values.expectedStartDate}
        availabilityConstraints={availabilityConstraints}
        providerAddressData={providerAddressData?.providerAddresses ?? []}
        setFieldValue={setFieldValue}
        handleBlur={handleBlur}
      />

      <ProviderIntakeFormStepper
        className="mt-6"
        mutationLoading={saveIntakeLoading}
        isValid={isValid}
        step="availability"
        currentSection="profile"
      />
    </IntakeStepContainer>
  )
}

function buildSaveValues(formValues: ProviderSignupFormValues): SaveProviderIntakeInput {
  const { expectedCaseloadAmount, expectedStartDate, timezone, availabilityPeriods } = formValues
  // Remove temp IDs used for managing local updates and removals before we actually save
  const allowDayAndTime = availabilityPeriods.allowDayAndTime.map(period => omit(period, 'id'))

  return {
    expectedCaseloadAmount,
    expectedStartDate: expectedStartDate ? moment.utc(expectedStartDate).startOf('day').toISOString() : null,
    expectedAvailability: getAvailabilityConstraintInput({
      availabilityConstraints: { ...availabilityPeriods, allowDayAndTime },
    }),
    timezone,
  }
}
