import { styled } from '@mui/material'
import { Formik, FormikProps } from 'formik'
import { noop } from 'lodash'
import qs from 'qs'
import React, { useState } from 'react'
import { useLocation } from 'react-router-dom'
import { ObjectSchema } from 'yup'

import {
  BelowTablet,
  FillButton,
  GhostButton,
  OutlineButton,
  body2,
  borderGrey,
  fontSize,
  interactiveText,
  toast,
} from '@nuna/tunic'

type WidthType = 'full' | 'partial'

interface ProfileSectionProps<T extends Record<string, unknown>> extends React.HTMLAttributes<HTMLFormElement> {
  heading?: React.ReactNode
  description?: React.ReactNode
  renderDisplayValue: React.ReactNode
  renderForm: (formikProps: FormikProps<T>) => React.ReactNode
  initialValues: T
  validationSchema: ObjectSchema<T>
  isLoading?: boolean
  disabled?: (formikProps: FormikProps<T>) => boolean
  displayWidth?: WidthType
  inEditMode?: boolean
  headingShownInEditOnly?: boolean
  useOutlineSaveButton?: boolean
  showTopBorder?: boolean
  showEditButton?: boolean
  updateLoading?: boolean
  sectionComplete?: boolean
  onCancel?: () => void
  handleSubmit: (values: T) => Promise<unknown>
}

export function ProfileSection<T extends Record<string, unknown>>({
  heading,
  description,
  renderDisplayValue,
  initialValues,
  validationSchema,
  renderForm,
  isLoading = false,
  disabled = () => false,
  inEditMode = false,
  headingShownInEditOnly = false,
  useOutlineSaveButton = false,
  showTopBorder = true,
  displayWidth = 'partial',
  showEditButton = true,
  updateLoading = false,
  sectionComplete = false,
  handleSubmit,
  onCancel = noop,
  ...props
}: ProfileSectionProps<T>) {
  const { search } = useLocation()
  const queryParams = qs.parse(search, { ignoreQueryPrefix: true })
  const [isBeingEditedInternally, setIsBeingEditedInternally] = useState(
    sectionComplete ? false : (queryParams.edit as string) === 'true',
  )

  const shouldShowEditButton = !isLoading && showEditButton
  const isBeingEdited = isBeingEditedInternally || inEditMode
  const showHeading = !headingShownInEditOnly || isBeingEdited

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleSubmitWrapper = async (values: T) => {
    try {
      await handleSubmit(values)
      setIsBeingEditedInternally(false)
    } catch (e) {
      toast.urgent('Error updating profile. We are looking into it.')
    }
  }

  return (
    <Formik
      enableReinitialize
      initialValues={initialValues}
      onSubmit={handleSubmitWrapper}
      validationSchema={validationSchema}
    >
      {formikProps => (
        <Form onSubmit={formikProps.handleSubmit} {...props} showTopBorder={showTopBorder}>
          <DisplayContainer displayWidth={displayWidth}>
            {heading && showHeading && <h2 className="h5 mb-4">{heading}</h2>}

            {isLoading ? <DisplayValueSkeleton /> : isBeingEdited ? renderForm(formikProps) : renderDisplayValue}
            {description && <Description displayWidth={displayWidth}>{description}</Description>}
          </DisplayContainer>

          {isBeingEdited ? (
            <EditActions>
              {useOutlineSaveButton ? (
                <OutlineButton disabled={disabled(formikProps)} isLoading={updateLoading} type="submit">
                  Save
                </OutlineButton>
              ) : (
                <FillButton disabled={disabled(formikProps)} isLoading={updateLoading} type="submit">
                  Save
                </FillButton>
              )}

              <br />
              <CancelButton
                onClick={() => {
                  formikProps.resetForm()
                  setIsBeingEditedInternally(false)
                  onCancel()
                }}
              >
                Cancel
              </CancelButton>
            </EditActions>
          ) : (
            shouldShowEditButton && <EditButton onClick={() => setIsBeingEditedInternally(true)}>Edit</EditButton>
          )}
        </Form>
      )}
    </Formik>
  )
}

function DisplayValueSkeleton() {
  return (
    <ProfileDisplayValue className="loading" style={{ width: 280 }}>
      Loading
    </ProfileDisplayValue>
  )
}

type ReadOnlyProfileSectionProps = Pick<
  ProfileSectionProps<never>,
  'displayWidth' | 'heading' | 'renderDisplayValue' | 'description' | 'showTopBorder' | 'isLoading'
>

export function ReadOnlyProfileSection({
  heading,
  renderDisplayValue,
  description,
  showTopBorder = true,
  displayWidth = 'partial',
  isLoading = false,
}: ReadOnlyProfileSectionProps) {
  return (
    <Form as="section" showTopBorder={showTopBorder}>
      <DisplayContainer displayWidth={displayWidth}>
        <h2 className="h5 mb-4">{heading}</h2>

        {isLoading ? <DisplayValueSkeleton /> : renderDisplayValue}
        {description && <Description displayWidth={displayWidth}>{description}</Description>}
      </DisplayContainer>
    </Form>
  )
}

const Form = styled('form')<{ showTopBorder: boolean }>`
  ${({ showTopBorder }) => showTopBorder && `border-top: 1px solid ${borderGrey};`}
  display: flex;
  align-items: center;
  padding-bottom: var(--margin-4);
  padding-top: var(--margin-4);

  h2 {
    margin-bottom: var(--margin-4);
  }

  .MuiTextField-root {
    margin-bottom: var(--margin-3);
  }

  @media (${BelowTablet}) {
    display: block;
  }
`

const DisplayContainer = styled('div')<{ displayWidth: WidthType }>`
  ${({ displayWidth }) => (displayWidth === 'partial' ? 'max-width: 475px;' : 'width: 100%;')}
`

const EditButton = styled(GhostButton)`
  margin-left: auto;

  @media (${BelowTablet}) {
    margin-left: 0;
    margin-top: var(--margin-2);
  }
`

const CancelButton = styled(GhostButton)`
  margin-top: var(--margin-3);

  @media (${BelowTablet}) {
    margin-top: 0;
    margin-left: var(--margin-2);
  }
`

const EditActions = styled('div')`
  margin-left: auto;
  text-align: center;

  @media (${BelowTablet}) {
    align-items: center;
    display: flex;
    margin-top: var(--margin-4);
  }
`

const Description = styled('p')<{ displayWidth: WidthType }>`
  color: ${body2};
  line-height: 1.4;
  ${({ displayWidth }) => displayWidth === 'partial' && `max-width: 475px;`}
  margin-top: var(--margin-2);
  padding-right: var(--margin-5);

  @media (${BelowTablet}) {
    padding-right: 0;
  }
`

export const ProfileDisplayValue = styled('p')`
  color: ${interactiveText};
  font-size: ${fontSize.large};
  font-weight: 500;
  line-height: 1.4;
  font-style: normal;
`
