import { styled } from '@mui/material'

import { tealSet } from '@nuna/tunic'

export const VideoOverlayText = styled('span')`
  background-color: ${tealSet[90].transparency(0.7)};
  border-radius: 4px;
  color: white;
  font-weight: 600;
  font-size: 14px;
  padding: 0.375rem 0.5rem;
`
