import { ReactNode, useState } from 'react'

import { GhostButtonLinkProps, TextButtonLink, Tooltip } from '@nuna/tunic'

interface CopyToClipboardButtonLinkProps extends GhostButtonLinkProps {
  to: string
  tooltipText?: string
  children: ReactNode
}

function useCopyToClipboard(): [boolean, (text: string) => void] {
  const [isCopied, setCopied] = useState(false)

  const handleCopyClick = (text: string) => {
    navigator.clipboard.writeText(text).then(() => {
      setCopied(true)
      setTimeout(() => setCopied(false), 3000)
    })
  }

  return [isCopied, handleCopyClick]
}

export function CopyToClipboardButtonLink({
  to,
  children,
  tooltipText = 'Copied!',
  ...rest
}: CopyToClipboardButtonLinkProps) {
  const [isCopied, handleCopyClick] = useCopyToClipboard()

  const handleClick = (e: React.MouseEvent, to: string) => {
    e.preventDefault()
    handleCopyClick(to)
  }

  return (
    <Tooltip content={isCopied ? tooltipText : ''} options={{ trigger: 'click', visible: isCopied }}>
      <TextButtonLink {...rest} to={to} onClick={e => handleClick(e, to)}>
        {children}
      </TextButtonLink>
    </Tooltip>
  )
}
