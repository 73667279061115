import { PaymentPreference } from '@nuna/api'
import { type DiscriminatedPatientCoverageFragment, type InvalidSponsorCoverageFragment } from '@nuna/core'

import { AccessCodeCard } from './components/AccessCodeCard'
import { BaseCardProps } from './components/BaseCard'
import { CashCard } from './components/CashCard'
import { EAPCard } from './components/EAPCard'
import { InsuranceCard } from './components/InsuranceCard'

interface Props extends BaseCardProps {
  coverage: DiscriminatedPatientCoverageFragment | InvalidSponsorCoverageFragment
}

export function CoverageCard({ coverage, ...props }: Props) {
  switch (coverage.type) {
    case PaymentPreference.Accesscode:
      return <AccessCodeCard details={coverage.details} {...props} />
    case PaymentPreference.Cash:
      return <CashCard details={coverage.details} {...props} />
    case PaymentPreference.Employer:
      return <EAPCard details={coverage.details} {...props} />
    case PaymentPreference.Insurance:
      return <InsuranceCard details={coverage.details} {...props} />
    default:
      console.warn('CoverageCard received an unknown coverage type')
      return null
  }
}
