import { HTMLAttributes } from 'react'
import { useParams } from 'react-router-dom'

import { usePatientQuery } from '@nuna/api'
import { Avatar, StatusLabel } from '@nuna/tunic'

type RouteProps = {
  id: string
}

export function ClientDetailHeader({ className = '', ...props }: HTMLAttributes<HTMLDivElement>) {
  const { id = '' } = useParams<RouteProps>()

  const { error, data } = usePatientQuery({
    variables: {
      id,
    },
    fetchPolicy: 'cache-and-network',
  })

  if (error || !data) {
    return (
      <div className={className}>
        <div className="loading" style={{ borderRadius: '4px', width: 180, height: 34 }} />
      </div>
    )
  }

  const patient = data.patient
  const isAccountActive = data.patient.login.active

  return (
    <div className={`v-align ${className}`} {...props}>
      <Avatar url={data.patient.avatarUrl} size="xs" className="mr-1 fs-exclude" />
      <h1 className="h5 mb-0 mr-2">{`${patient.firstName} ${patient.lastName}`}</h1>
      {!isAccountActive && <StatusLabel>De-activated</StatusLabel>}
    </div>
  )
}
