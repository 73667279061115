import { styled } from '@mui/material'
import { uniqueId } from 'lodash'
import { useRef } from 'react'

import { greySet, tealSet } from '../../styles/colorSets'
import { error } from '../../styles/colors'
import { csx } from '../../utils/csx'
import { CheckboxProps } from '../Checkbox/Checkbox'
import check from './img/switch-check.svg'
import clear from './img/switch-clear.svg'

export interface SwitchProps extends CheckboxProps {
  labelPosition?: 'left' | 'right'
  disabled?: boolean
  loading?: boolean
}

export function Switch({
  id,
  style,
  className,
  onChange,
  checked,
  children,
  containerProps = {},
  labelProps = {},
  error = false,
  disabled = false,
  labelPosition = 'right',
  loading = false,
  ...props
}: SwitchProps) {
  const idRef = useRef(id ?? uniqueId('switch-'))

  return (
    <Container className={className} style={style} {...containerProps} disabled={disabled}>
      <input
        id={idRef.current}
        type="checkbox"
        checked={checked}
        onChange={onChange}
        {...props}
        disabled={disabled}
        className="fs-exception"
      />
      <Label error={error} disabled={disabled} htmlFor={idRef.current} {...labelProps}>
        {labelPosition === 'left' && children}
        <SwitchContainer className={csx({ loading })} labelLeft={labelPosition === 'left'}>
          <Thumb />
          <Track disabled={disabled}>
            <img src={check} className="checkmark" alt="" />
            <img src={clear} className="clear" alt="" />
          </Track>
        </SwitchContainer>
        {labelPosition === 'right' && children}
      </Label>
    </Container>
  )
}

const Container = styled('div')<{ disabled: boolean }>`
  position: relative;

  ${({ disabled }) => (disabled ? 'pointer-events: none;' : 'pointer-events: all;')}

  input[type='checkbox'] {
    /* For more reading on why it's hidden this way: https://blog.bitsrc.io/customise-radio-buttons-without-compromising-accessibility-b03061b5ba93 */
    opacity: 0;
    position: absolute;
    pointer-events: none;
  }

  [role='group'] & {
    margin-bottom: 1rem;
  }
`

const SwitchContainer = styled('span')<{ labelLeft: boolean }>`
  position: relative;
  display: flex;
  width: 42px;
  height: 23px;
  flex: 0 0 auto;
  ${({ labelLeft }) => (labelLeft ? 'margin-left: 0.5rem;' : 'margin-right: 0.5rem;')}

  &.loading {
    border-radius: 23px;
  }
`

const Track = styled('span')<{ disabled: boolean }>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 5px;
  width: 100%;
  background-color: ${({ disabled }) => (disabled ? greySet.tint[60] : greySet[70].hex)};
  border-radius: var(--border-radius);
`

const Thumb = styled('span')`
  border-radius: 50%;
  position: absolute;
  transform: translateX(3px);
  top: 3px;
  transition: transform 150ms ease-in-out, box-shadow 150ms ease-in-out;
  box-shadow: none;

  &::after {
    border-radius: 50%;
    display: block;
    content: '';
    height: 17px;
    width: 17px;
    background-color: #fff;
    transition: transform 150ms ease-in-out;
  }
`

const Label = styled('label')<{ error?: boolean; disabled: boolean }>`
  align-items: center;
  cursor: pointer;
  display: flex;

  ${props => {
    if (props.disabled) {
      return `color: ${greySet.tint[60]};`
    }
    if (props.error) {
      return `color: ${error};`
    }

    return ''
  }};

  input:checked + & {
    ${SwitchContainer} {
      > ${Thumb} {
        transform: translateX(22px);
      }
      > ${Track} {
        background-color: ${tealSet[50].hex};
      }
    }
  }

  input:checked:focus + &,
  input:checked + &:hover {
    ${SwitchContainer} {
      > ${Thumb} {
        box-shadow: 0 0 0 8px ${tealSet[50].transparency(0.2)};
      }
      > ${Track} {
        background-color: ${tealSet[50].hover};
      }
    }
  }

  &:hover,
  input:focus + & {
    ${Track} {
      background-color: ${greySet[80].hex};
    }

    ${Thumb} {
      box-shadow: 0 0 0 8px ${greySet[80].transparency(0.2)};

      &::after {
        transform: scale(1.24);
      }
    }
  }
`
