import { ReactNode, useState } from 'react'

import { TextButton, listFormat } from '@nuna/tunic'

interface Props {
  states: string[]
  maxStatesBeforeTruncate: number
  displayIfEmpty: ReactNode
}

export function StateList({ states, maxStatesBeforeTruncate, displayIfEmpty }: Props) {
  const [showAll, setShowAll] = useState(false)
  let stateText: ReactNode

  if (states.length === 0) {
    // eslint-disable-next-line react/jsx-no-useless-fragment
    return <>{displayIfEmpty}</>
  }
  if (states.length <= maxStatesBeforeTruncate) {
    stateText = <>{listFormat(states)}</>
  } else if (showAll) {
    stateText = (
      <>
        {listFormat(states)}{' '}
        <TextButton variant="secondary" onClick={() => setShowAll(false)}>
          less...
        </TextButton>
      </>
    )
  } else {
    stateText = (
      <>
        {states.slice(0, maxStatesBeforeTruncate).join(', ')}
        {', '}
        <TextButton variant="secondary" onClick={() => setShowAll(true)}>
          more...
        </TextButton>
      </>
    )
  }

  return stateText
}
