import mixpanel from 'mixpanel-browser'

import { CancelPolicyStatusFragment, DrawerAppointmentFragment } from '@nuna/api'
import { DrawerStepper } from '@nuna/common'
import { Audience, cancelPolicyService } from '@nuna/core'
import { MessageComposer, useMessageComposerContext } from '@nuna/messaging'

import { AppointmentDrawerPaddedContainer } from '../../../AppointmentDrawer'
import { useCancelForm } from '../../../hooks/useCancelForm'
import { useShowCancelFeeInfo } from '../../../hooks/useShowCancelFeeInfo'
import { AppointmentChangeReasonForm } from '../../AppointmentChangeReasonForm'
import { ChargeFee } from '../../ChargeFee'
import { MessageToChips } from '../../MessageToChips'
import { RequestedBySelect } from '../../RequestedBySelect'

interface Props {
  appointment: DrawerAppointmentFragment
  cancelPolicyStatus: CancelPolicyStatusFragment
  audience: Exclude<Audience, 'client'>
}

export function CancelAsProviderOrAdmin({ appointment, cancelPolicyStatus, audience }: Props) {
  const { formProps } = useCancelForm({ appointment, audience })
  const { loading: saveMessageLoading, invalidAttachments } = useMessageComposerContext()

  const { cancelPolicy, acceptedDate } = cancelPolicyStatus

  const isLegacyCancelPolicy = cancelPolicyService.isLegacy(cancelPolicy)

  const { submitForm, values, setFieldValue, getFieldProps } = formProps

  const showCancelFeeInfo = useShowCancelFeeInfo({
    audience,
    requestedBy: values.requestedBy,
    cancelReason: values.reason,
    isLegacyCancelPolicy,
  })

  return (
    <AppointmentDrawerPaddedContainer className="mt-4">
      <MessageToChips
        users={audience === 'provider' ? [appointment.patient] : [appointment.provider, appointment.patient]}
        className="mb-1"
      />
      {audience === 'admin' && (
        <RequestedBySelect label="Requested by" className="my-2" {...getFieldProps('requestedBy')} />
      )}
      <MessageComposer
        placeholder={audience === 'provider' ? 'Message your client' : 'Write your message'}
        audience={audience}
        autoCollapse={false}
        disableAssessments
        boot={
          <AppointmentChangeReasonForm
            formProps={formProps}
            audience={audience}
            requestedBy={values.requestedBy}
            labelText="Why Cancel?"
          />
        }
      />
      {!isLegacyCancelPolicy && showCancelFeeInfo && cancelPolicy && (
        <ChargeFee
          className="mt-2"
          feeType="cancel"
          value={values.shouldProviderBePaid}
          cancelPolicy={cancelPolicy}
          acceptedDate={acceptedDate}
          appointment={appointment}
          audience={audience}
          onChange={e => setFieldValue('shouldProviderBePaid', e.currentTarget.checked)}
        />
      )}
      <DrawerStepper
        nextButtonText="Confirm And Send"
        nextButtonDiabled={invalidAttachments || saveMessageLoading}
        onNextButtonClick={async () => {
          await submitForm()

          if (showCancelFeeInfo && cancelPolicy) {
            mixpanel.track('canceled late', { appointmentId: appointment.id })

            if (!isLegacyCancelPolicy) {
              mixpanel.track(`${values.shouldProviderBePaid ? 'charged' : 'waived'} fee`, {
                appointmentId: appointment.id,
                location: 'drawer',
              })
            }
          }
        }}
        nextButtonProps={{ 'data-testid': 'cancel-appointment-submit' }}
        className="mt-4"
        formProps={formProps}
      />
    </AppointmentDrawerPaddedContainer>
  )
}
