import * as Yup from 'yup'
import { useEffect, useState } from 'react'
import { useMediaQuery } from 'react-responsive'

import { ProviderDataField } from '@nuna/api'
import { formService } from '@nuna/core'
import { VideoBioEditDisplay, VideoBioFaqs, VideoBioVideoAsk } from '@nuna/provider'
import { supportService } from '@nuna/telemetry'
import { BelowTablet, FillButton, Grid, TextButtonExternalLink, TextField } from '@nuna/tunic'

import { useProviderAppContext } from '../../../../shared/ProviderAppContext'
import { useProviderSignupIntakeContext } from '../../ProviderSignupIntakeContext'
import { IntakeStepContainer } from '../../shared/IntakeStepContainer'
import { IntakeHeader } from '../../shared/IntakeTypography'
import { ProviderIntakeFormStepper } from '../../shared/ProviderIntakeFormStepper'
import {
  ProviderSignupFormSchema,
  ProviderSignupFormValues,
  ProviderSignupIntakeFormProps,
  SaveProviderIntakeInput,
} from '../../shared/provider-signup-intake-types'
import { useProviderSignupIntakeStepSetup } from '../../shared/useProviderSignupIntakeStepSetup'

const { composeHelperTextWithError } = formService

const validationSchema: ProviderSignupFormSchema = Yup.object({
  bio: Yup.string().required('You bio is required'),
})

function buildSaveValues(formValues: ProviderSignupFormValues): SaveProviderIntakeInput {
  const { bio } = formValues
  return {
    bio,
  }
}

export function IntakePresentation({
  values,
  validateForm,
  getFieldProps,
  errors,
  touched,
  isValid,
}: ProviderSignupIntakeFormProps) {
  const isMobile = useMediaQuery({ query: `(${BelowTablet})` })
  const { videoBios } = values
  const { provider, providerDataSummaryItems, getSummaryItemByField } = useProviderAppContext()
  const [dialogOpen, setDialogOpen] = useState(false)
  const [videoBioRecorded, setVideoBioRecorded] = useState<undefined | boolean>()
  const { saveIntake, saveIntakeLoading } = useProviderSignupIntakeContext()

  useProviderSignupIntakeStepSetup({ validationSchema, buildSaveValues }, validateForm)

  useEffect(() => {
    if (providerDataSummaryItems.length) {
      setVideoBioRecorded(!!getSummaryItemByField(ProviderDataField.VideoBioSubmitted).hasValue)
    }
  }, [getSummaryItemByField, providerDataSummaryItems.length])

  const handleVideoRecorded = async () => {
    setVideoBioRecorded(true)
    await saveIntake({ triggerVideoSubmission: true })
    setDialogOpen(false)
  }

  return (
    <IntakeStepContainer>
      <IntakeHeader type="h1" md={{ mb: 3 }}>
        Connection begins with a first impression.
      </IntakeHeader>
      <div className="text-secondary display">
        Written and video bios make a big impact on how confident a client feels when choosing you as their provider.
      </div>
      <IntakeHeader type="h2" xs={{ mb: 0, mt: 4 }} md={{ mb: 0, mt: 6 }}>
        Written Bio
      </IntakeHeader>
      <TextField
        multiline
        rowsMax={20}
        placeholder="Add bio"
        showBottomBorderOnFocus={false}
        inputProps={{ 'data-testid': 'bio-input' }}
        {...getFieldProps('bio')}
        {...composeHelperTextWithError('', errors.bio, !!touched.bio)}
      />
      <div className="mt-3">
        Need some help with your bio?{' '}
        <TextButtonExternalLink href={supportService.articles.bioTips}>Review our tips.</TextButtonExternalLink>
      </div>
      <IntakeHeader type="h2" xs={{ mb: 0, mt: 5 }} md={{ mb: 1, mt: 6 }}>
        Video Bio
      </IntakeHeader>
      <Grid container className="v-align" spacing={isMobile ? 2 : 4}>
        <Grid
          size={{
            xs: 12,
            md: 'auto',
          }}
        >
          <VideoBioEditDisplay video={videoBios[0]} videoRecorded={videoBioRecorded} />
        </Grid>
        <Grid
          size={{
            xs: 12,
            md: 6,
          }}
        >
          <div className="text-secondary display mb-2">We'll open the video recorder in a new window.</div>
          <FillButton type="button" onClick={() => setDialogOpen(true)}>
            Record Video Bio
          </FillButton>
        </Grid>
      </Grid>
      <VideoBioFaqs className="mt-3" />

      <ProviderIntakeFormStepper
        className="mt-6"
        mutationLoading={saveIntakeLoading}
        step="bios"
        isValid={isValid}
        currentSection="profile"
      />

      <VideoBioVideoAsk
        providerId={provider?.id ?? ''}
        isOpen={dialogOpen}
        onClose={() => setDialogOpen(false)}
        onVideoRecorded={handleVideoRecorded}
      />
    </IntakeStepContainer>
  )
}

// Uncomment when we get the tips video
// const TipsCard = styled(Card)`
//   width: 300px;
//   .tips-text {
//     padding: var(--margin-2);
//   }
// `
