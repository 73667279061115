import { styled } from '@mui/material'
import { InputHTMLAttributes, ReactNode, useState } from 'react'

import { body2, borderGrey, csx, error, greySet, tealSet } from '@nuna/tunic'

interface InlineInput extends InputHTMLAttributes<HTMLInputElement> {
  leftAdornment?: ReactNode
  rightAdornment?: ReactNode
  type?: string
  disabled?: boolean
  error?: boolean
  helperText?: string | ReactNode
}

// TODO - Move this to Tunic once design has fully fleshed out this component
export function InlineInput({
  onFocus,
  onBlur,
  leftAdornment,
  rightAdornment,
  className,
  style,
  error = false,
  disabled,
  helperText,
  ...props
}: InlineInput) {
  const [isFocused, setIsFocused] = useState(false)
  return (
    <OuterWrapper>
      <Container
        className={csx([{ 'left-adorned': !!leftAdornment, focused: isFocused, error }, className])}
        style={style}
      >
        {leftAdornment && <span className="adornment">{leftAdornment}</span>}
        <div className="input-wrapper">
          <input
            {...props}
            disabled={disabled}
            onFocus={e => {
              setIsFocused(true)
              onFocus && onFocus(e)
            }}
            onBlur={e => {
              setIsFocused(false)
              onBlur && onBlur(e)
            }}
          />
        </div>
        {rightAdornment && <span className="adornment">{rightAdornment}</span>}
      </Container>
      {helperText && (
        <HelperText className="full-width" error={error}>
          {helperText}
        </HelperText>
      )}
    </OuterWrapper>
  )
}

const OuterWrapper = styled('div')`
  display: flex;
  flex-wrap: wrap;
`

const Container = styled('div')`
  border: 1px solid ${borderGrey};
  border-radius: 6px;
  display: flex;
  overflow: hidden;
  background-color: #fff;

  &.focused {
    border-color: ${tealSet[50].hex};
  }

  &.error {
    border-color: ${error};
  }

  .adornment {
    color: ${greySet[50].hex};
    width: 23px;
    background-color: ${greySet[15].hex};
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
  }

  .input-wrapper {
    padding: var(--spacing-half) var(--spacing-1);
    flex: 1;
  }

  input {
    appearance: none;
    border-width: 0;
    width: 100%;

    &:focus {
      outline: 0;
    }

    -moz-appearance: textfield;
    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
      -webkit-appearance: none;
    }
  }
`

const HelperText = styled('p')<{ error: boolean }>`
  color: ${body2};
  display: flex;
  font-size: 12px;
  line-height: 1.4;
  margin: 5px 0 0;

  ${props => props.error && `color: ${error};`}
`
