import { PaymentPreference } from '@nuna/api'
import {
  CoverageValidationOptions,
  type DiscriminatedPatientCoverageFragment,
  EAPCoverageFragment,
  InvalidSponsorCoverageFragment,
  coverageService,
} from '@nuna/core'
import { IconCaution, IconCheckCircle, IconError, StatusLabel, error, interactiveFill, salmonSet } from '@nuna/tunic'

import { CoverageStatus, CoverageStatusProps } from '../../CoverageStatus'
import { useClientCoveragePanelContext } from '../context/ClientCoveragePanelContext'

interface Props {
  coverage: DiscriminatedPatientCoverageFragment
  className?: string
}

export function PanelCoverageStatus({ coverage, className, ...props }: Props) {
  const { coverages, isHorizontalLayout, primaryCoverage, openCoverageDetails } = useClientCoveragePanelContext()

  const isPrimary = coverage === primaryCoverage

  const handleClick = (coverage: DiscriminatedPatientCoverageFragment | InvalidSponsorCoverageFragment) => {
    if ('invalid' in coverage) {
      // The InvalidSponsorCoverageFragment is only used to display an invalidly entered sponsor that is not actually stored in the DB
      // The Coverage Panel should only be displaying coverages stored in the DB
      console.warn('Unexpectedly rendered an invalid Sponsor in the coverage panel')
      return
    }

    openCoverageDetails(coverage)
  }

  return (
    <CoverageStatus
      className={`panel-coverage-status ${className}`}
      coverage={coverage}
      expanded={isHorizontalLayout || isPrimary}
      onClick={handleClick}
      {...(coverage.type === PaymentPreference.Employer
        ? mapEAPCoverageStatusToDisplayProps({ coverage, isPrimary, coverages, isHorizontalLayout })
        : mapCoverageStatusToDisplayProps({ coverage, isPrimary, isHorizontalLayout }))}
      {...props}
    />
  )
}

interface MapOptions {
  coverage: Exclude<DiscriminatedPatientCoverageFragment, EAPCoverageFragment>
  isPrimary: boolean
  isHorizontalLayout: boolean
}

interface EAPMapOptions extends Omit<MapOptions, 'coverage'> {
  coverage: EAPCoverageFragment
  coverages: ReturnType<typeof useClientCoveragePanelContext>['coverages']
}

function getDefaultHeaderSlot({ isHorizontalLayout, isPrimary }: Pick<MapOptions, 'isHorizontalLayout' | 'isPrimary'>) {
  if (isPrimary) {
    return (
      <StatusLabel intent="information" className="mr-xs">
        Primary
      </StatusLabel>
    )
  }

  if (!isHorizontalLayout) {
    return <IconCheckCircle size={17} color={interactiveFill} />
  }

  return null
}

// EAP has more complicated statuses and logic so it gets its own func
function mapEAPCoverageStatusToDisplayProps({
  coverage,
  isPrimary,
  coverages,
  isHorizontalLayout,
}: EAPMapOptions): Pick<CoverageStatusProps, 'intent' | 'boot' | 'headerSlot'> {
  const isZeroCap = coverageService.isZeroCapEAP(coverage.details)
  /**
   * NOTE: because we want to show a boot that says the sponsor is covered by
   * insurance or credit card IF the zero cap comapny we must bypass the cap
   * validation failure
   */
  const isValidOptions: CoverageValidationOptions = {}
  if (isZeroCap) {
    isValidOptions.considerZeroCapValid = true
  }
  const isValid = coverageService.isCoverageValid(coverage.details, isValidOptions)
  const needsAdditionalZeroCapCoverage = coverageService.needsAdditionalZeroCapCoverage(coverages)
  const showPrimaryErrors = isPrimary && !isHorizontalLayout

  // zero cap company w/o alternative coverage
  if (needsAdditionalZeroCapCoverage) {
    return {
      intent: 'caution',
      boot: (
        <>
          <IconCaution size={16} className="mr-xs" />
          <span className="caption text-medium">Add insurance or card</span>
        </>
      ),
      headerSlot: null,
    }
  }

  // Has coverage by insurance or credit card and is is Zero Cap
  if (isZeroCap && isValid) {
    return {
      intent: 'information',
      boot: (
        <span className="caption text-medium" id="sponsor-boot-zero-cap-covered-by-insurance-or-card">
          Coverered by insurance or card
        </span>
      ),
      headerSlot: getDefaultHeaderSlot({ isHorizontalLayout, isPrimary }),
    }
  }

  // out of sessions w/o alternative coverage
  if (showPrimaryErrors && !isValid && !isZeroCap) {
    return {
      intent: 'urgent',
      boot: (
        <>
          <IconError color={salmonSet[30].hex} size={16} className="mr-xs" />
          <span className="caption text-medium">No sessions remaining</span>
        </>
      ),
      headerSlot: null,
    }
  }

  // out of sessions w/ alternative coverage
  if (!showPrimaryErrors && !isValid && !isZeroCap) {
    return {
      intent: 'default',
      boot: null,
      headerSlot: <StatusLabel>Sessions used</StatusLabel>,
    }
  }

  return {
    intent: 'default',
    boot: null,
    headerSlot: getDefaultHeaderSlot({ isHorizontalLayout, isPrimary }),
  }
}

function getErrorMessage(type: PaymentPreference) {
  switch (type) {
    case PaymentPreference.Accesscode:
      return 'Invalid code'
    case PaymentPreference.Cash:
      return 'Expired card'
    case PaymentPreference.Insurance:
      return 'Verification failed'
  }
}

function mapCoverageStatusToDisplayProps({
  coverage,
  isPrimary,
  isHorizontalLayout,
}: MapOptions): Pick<CoverageStatusProps, 'intent' | 'boot' | 'headerSlot'> {
  const isValid = coverageService.isCoverageValid(coverage.details)
  const showPrimaryErrors = isPrimary && !isHorizontalLayout

  if (showPrimaryErrors && !isValid) {
    return {
      intent: 'urgent',
      boot: (
        <>
          <IconError color={salmonSet[30].hex} size={16} className="mr-xs" />
          <span className="caption text-medium">{getErrorMessage(coverage.type)}</span>
        </>
      ),
      headerSlot: null,
    }
  }

  if (!showPrimaryErrors && !isValid) {
    return {
      intent: 'default',
      boot: null,
      headerSlot: <IconError size={17} color={error} />,
    }
  }

  return {
    intent: 'default',
    boot: null,
    headerSlot: getDefaultHeaderSlot({ isHorizontalLayout, isPrimary }),
  }
}
