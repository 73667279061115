import { styled } from '@mui/material'

import { BelowTablet } from '../../styles/breakpoints'
import { greySet } from '../../styles/colorSets'
import { eggshell } from '../../styles/colors'
import { shadowDepth } from '../../styles/shadows'
import { csx } from '../../utils/csx'
import { Radio, RadioProps } from './Radio'

export interface RadioCardProps extends Omit<RadioProps, 'children'> {
  text: string
  subtext: string
  compact?: boolean
}

export function RadioCard({ text, subtext, checked, className, compact = false, ...props }: RadioCardProps) {
  return (
    <Container compact={compact} checked={checked} className={className}>
      <Radio checked={checked} {...props}>
        <TextContainer>
          <span className={csx([{ h6: compact }, { h5: !compact }, 'mb-0'])}>{text}</span>
          <Subtext className="caption text-medium text-secondary mt-1">{subtext}</Subtext>
        </TextContainer>
      </Radio>
    </Container>
  )
}

const Subtext = styled('span')`
  display: block;
`
Subtext.defaultProps = { className: 'caption text-medium text-secondary mt-1' }

const TextContainer = styled('span')`
  display: block;
  margin-top: -2px;
`

const Container = styled('div')<{ checked: boolean; compact: boolean }>`
  ${props => props.compact && `background-color: ${eggshell};`}
  border: 1px solid transparent;
  border-radius: var(--border-radius);
  height: 100%;
  padding: 1.5rem 1.5rem 0.75rem;

  label {
    align-items: flex-start;
  }

  ${props =>
    props.checked &&
    `
    background-color: #fff;
    box-shadow: ${shadowDepth(1.5)};
    border-color: ${greySet[15].hex};
  `}

  @media (${BelowTablet}) {
    padding: 1rem 1rem 0.5rem;
  }
`
