import { IconProps } from './IconProps'

export function IconHamburgerMenu({ size = 15, color = 'currentColor', ...props }: IconProps) {
  return (
    <svg width={size} height={size} viewBox={`0 0 11 11`} xmlns="http://www.w3.org/2000/svg" {...props}>
      <g>
        <rect width="15" height="1.67" fill={color} />
        <rect width="15" height="1.67" y="4.67" fill={color} />
        <rect width="15" height="1.67" y="9.33" fill={color} />
      </g>
    </svg>
  )
}
