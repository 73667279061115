/* eslint-disable @typescript-eslint/no-explicit-any */
import * as Yup from 'yup'
import { Formik } from 'formik'

import { useCptCodesQuery, useCreateAdminAdjustmentFinancialMutation } from '@nuna/api'
import { errorService, numberService } from '@nuna/core'
import { FillButton, Select, TextField, toast } from '@nuna/tunic'

const adminAdjustmentSchema = Yup.object().shape({
  appointmentId: Yup.string().required(),
  providerCompensatedAmount: Yup.number().min(0.01).max(500).required(),
  note: Yup.string().required(),
  cptCode: Yup.string().optional(),
})

export const AdminPayoutAdjustmentForm = ({
  appointmentId,
  onComplete,
}: {
  appointmentId: string
  onComplete: () => void
}) => {
  const [createAdminAdjustmentFinancial, { loading, error }] = useCreateAdminAdjustmentFinancialMutation()
  const { data: cptCodes, loading: cptCodesLoading } = useCptCodesQuery({})

  const handleSubmit = async (values: any, { resetForm }: any) => {
    try {
      await createAdminAdjustmentFinancial({
        variables: {
          adminAdjustment: {
            appointmentId,
            providerCompensatedAmount: numberService.dollarsToCents(values.providerCompensatedAmount),
            note: values.note,
            cptCode: values.cptCode,
          },
        },
        refetchQueries: ['InternalAppointmentDetail'],
      })

      resetForm()

      onComplete()
      toast.success('Added payout')
    } catch (e) {
      console.error(e)
      toast.urgent(errorService.transformUserGraphqlError(e, 'Failed to add payout adjustment'))
    }
  }

  return (
    <Formik
      validationSchema={adminAdjustmentSchema}
      initialValues={{
        appointmentId,
        appointmentHistoryItemId: null,
        providerCompensatedAmount: null,
        note: '',
        cptCode: '90837',
      }}
      onSubmit={handleSubmit}
    >
      {({ values, handleChange, handleSubmit, errors, touched }) => (
        <form onSubmit={handleSubmit}>
          <h1 className="h4 mb-2">Add Adjustment Payout</h1>

          <TextField
            id="providerCompensatedAmount"
            placeholder="Amount"
            name="providerCompensatedAmount"
            onChange={handleChange}
            value={values.providerCompensatedAmount ?? ''}
            error={!!(touched.providerCompensatedAmount && errors.providerCompensatedAmount)}
            helperText={touched.providerCompensatedAmount && errors.providerCompensatedAmount}
          />

          <TextField
            id="note"
            placeholder="Why are you making this adjustment?"
            name="note"
            onChange={handleChange}
            value={values.note ?? ''}
            error={!!(touched.note && errors.note)}
            helperText={touched.note && errors.note}
          />

          <Select
            value={values.cptCode}
            name="cptCode"
            onChange={handleChange}
            id="reason-code"
            label="Reason Code"
            className="mb-3"
          >
            {Object.values(cptCodes?.cptCodes ?? [])
              .sort((a, b) => a.code.localeCompare(b.code))
              .map(code => (
                <option value={code.code} key={code.id}>
                  {code.code}
                </option>
              ))}
          </Select>

          {!!error && <div>Error occurred saving {errorService.transformGraphQlError(error)}</div>}

          <FillButton isLoading={loading || cptCodesLoading} type="submit" style={{ width: '100%' }} className="mt-2">
            Add Adjustment
          </FillButton>
        </form>
      )}
    </Formik>
  )
}
