/* eslint-disable @typescript-eslint/ban-types */
import { styled } from '@mui/material'
import { isNil, noop, times } from 'lodash'
import moment from 'moment'
import { CSSProperties, HTMLAttributes, ReactNode, RefObject, useMemo, useRef } from 'react'
import { useMediaQuery } from 'react-responsive'
import {
  Column,
  ColumnInstance,
  ColumnWithLooseAccessor,
  HeaderGroup,
  Row,
  TableRowProps,
  TableState,
  useGlobalFilter,
  useSortBy,
  useTable,
} from 'react-table'

import { ProfileUser, userService } from '@nuna/core'
import { ContextualAlert, IconSortArrow, borderGrey, csx, eggshell, fontSize, greySet, tealSet } from '@nuna/tunic'

import { DefaultTableRow } from './DefaultTableRow'
import { MobileDataTable } from './MobileDataTable'
import { Paginator, PaginatorProps } from './Paginator'

const { userFullName } = userService

type DataTableScrollStrategy = 'within' | 'outside'

interface DataTableSortBy<I extends object> {
  id: keyof I
  desc: boolean
}
export interface DataTableState<I extends object> extends TableState<I> {
  sortBy?: DataTableSortBy<I>[]
}

export interface DataTableRowComponentProps<I extends object> extends TableRowProps {
  row: Row<I>
  onRowClick?: (row: I) => void
  expandedRow?: (props: { row: Row<I>; triggerRowRef: RefObject<HTMLTableRowElement> }) => JSX.Element
  showExpandedRow?: (row: Row<I>) => boolean
  customCellStyle?: (row: Row<I>) => CSSProperties
}

interface ColumnExtension<I extends Object> {
  paginatedSortable?: boolean
  className?: string
  style?: CSSProperties
  sortType?: (a: Row<I>, b: Row<I>) => number
  MobileCell?: ColumnWithLooseAccessor<I>['Cell']
  MobileHeader?: ColumnWithLooseAccessor<I>['Header']
  showInMobileHeader?: boolean
  groupInMobile?: boolean
  isExpanded?: boolean
}

export type DataTableColumn<I extends Object> = Column<I> & ColumnExtension<I>

interface HeaderGroupExtension {
  getSortByToggleProps: () => Record<string, unknown>
  isSortedDesc?: boolean
  toggleSortBy: (isDesc: boolean) => void
  isSorted: boolean
  clearSortBy: () => void
}

type DataTableHeaderGroup<I extends Object> = HeaderGroup<I> & HeaderGroupExtension & ColumnExtension<I>

export const DEFAULT_PAGINATOR_PROPS: PaginatorProps = {
  loading: true,
  onPageChange: noop,
  totalLabel: 'total records',
  limit: 20,
}

export interface DataTableProps<I extends object> extends HTMLAttributes<HTMLTableElement> {
  columns: DataTableColumn<I>[]
  rowData?: I[] | null
  loading: boolean
  isSkeleton?: boolean
  loadingRows?: number
  noRecordsMessage?: string
  cellPadding?: string
  initialState?: DataTableState<I>
  onRowClick?: (row: I) => void
  isRowSelected?: (row: I) => boolean
  disableSort?: boolean
  customCellStyle?: (row: Row<I>) => CSSProperties
  customRow?: (props: DataTableRowComponentProps<I>, idx: number, rows: Row<I>[]) => JSX.Element
  expandedRow?: (props: { row: Row<I>; triggerRowRef: RefObject<HTMLTableRowElement> }) => JSX.Element
  showExpandedRow?: (row: Row<I>) => boolean
  getUniqueId?: (row: Row<I>) => string | number
  paginated?: boolean
  paginatorProps?: Partial<PaginatorProps>
  upperSlot?: ReactNode
  onSort?: (accessor: string, desc: boolean | undefined, multiSort: boolean) => void
  headerRowProps?: HTMLAttributes<HTMLTableRowElement>
  scrollStrategy?: DataTableScrollStrategy
  mobileBreakpoint?: string
  evenRowBGColor?: string
}

const tableCellPadding = 11

export function DataTable<I extends object>({
  columns,
  rowData,
  loading,
  isSkeleton,
  loadingRows = 20,
  noRecordsMessage = 'No records to show.',
  cellPadding,
  initialState = {},
  onRowClick,
  isRowSelected = () => false,
  disableSort,
  customCellStyle = () => ({}),
  customRow,
  getUniqueId,
  onSort = noop,
  paginated = false,
  paginatorProps = {},
  headerRowProps = {},
  expandedRow,
  showExpandedRow,
  scrollStrategy = 'outside',
  upperSlot,
  mobileBreakpoint = 'max-width: 0px', // mobile layout is opt-in (for now)
  evenRowBGColor = eggshell,
  ...props
}: DataTableProps<I>) {
  interface CustomColumn extends ColumnInstance<I> {
    className?: string
  }

  interface CustomHeaderGroup extends HeaderGroup<I> {
    style?: CSSProperties
  }

  const isPaginated = paginated
  const isMobile = useMediaQuery({ query: `(${mobileBreakpoint})` })

  const tableData = useMemo(() => rowData ?? [], [rowData])

  const tableRef = useRef<HTMLTableElement>(null)

  const totalColSpan = expandedRow ? columns.length + 1 : columns.length

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow, toggleSortBy } = useTable<I>(
    {
      data: tableData,
      columns: columns,
      initialState,
      // TODO: can we fix this to not be ts-ignored
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      manualSortBy: isPaginated,
    },
    useGlobalFilter,
    useSortBy,
  )

  const getPaginatedColumnProps = (column: DataTableHeaderGroup<I>) => {
    if (!isPaginated) {
      return {}
    }
    return {
      onClick: (event: MouseEvent) => {
        const multiSort = !!event.shiftKey
        let shouldSortDesc

        if (column.isSorted && !column.isSortedDesc) {
          shouldSortDesc = true
        } else if (!column.isSorted) {
          shouldSortDesc = false
        }

        if (isNil(shouldSortDesc)) {
          column.clearSortBy()
        } else {
          toggleSortBy(column.id, shouldSortDesc, multiSort)
        }
        onSort(column.id, shouldSortDesc, multiSort)
      },
    }
  }

  const getColumnSortByProps = (column: DataTableHeaderGroup<I>) => {
    if ((isPaginated && !column.paginatedSortable) || disableSort) {
      return {}
    }
    const defaultProps = column.getSortByToggleProps()
    return { ...defaultProps, ...getPaginatedColumnProps(column) }
  }

  const getRowProps = (row: Row<I>, index: number): TableRowProps => {
    const defaultProps = row.getRowProps()
    return {
      ...defaultProps,
      className: csx([defaultProps.className, bandedClass(index), { selected: isRowSelected(row.original) }]),
    }
  }

  const getHeaderRowProps = (headerRow: HeaderGroup<I>) => {
    const defaultProps = headerRow.getHeaderGroupProps()
    const style = headerRowProps && headerRowProps.style ? headerRowProps.style : {}
    return {
      ...defaultProps,
      className: csx([headerRowProps.className, defaultProps.className]),
      style: { ...defaultProps.style, ...style },
    }
  }

  const getHeaderProps = (header: CustomHeaderGroup) => {
    // The typings for react table with the sortby plugin are not great
    // Hence, we have to do some ignoring
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const defaultProps = header.getHeaderProps(getColumnSortByProps(header))
    const style = header.style ?? {}
    return { ...defaultProps, style: { ...defaultProps.style, ...style } }
  }

  if (isMobile) {
    return (
      <MobileDataTable
        rows={rows}
        prepareRow={prepareRow}
        loading={loading}
        paginated={paginated}
        paginatorProps={paginatorProps}
      />
    )
  }

  const table = (
    <StyledDataTable
      {...getTableProps()}
      hasRowInteraction={!!onRowClick || !!expandedRow}
      cellPadding={cellPadding}
      ref={tableRef}
      evenRowBGColor={evenRowBGColor}
    >
      <thead>
        {headerGroups.map(headerGroup => {
          const { key, ...headerRowProps } = getHeaderRowProps(headerGroup)
          return (
            <tr key={key} {...headerRowProps}>
              {!!expandedRow && <th></th>}
              {headerGroup.headers.map(column => {
                const { key: columnKey, ...headerProps } = getHeaderProps(column)
                return (
                  <th key={columnKey} {...headerProps} className={csx([(column as CustomColumn).className])}>
                    <span>{column.render('Header')}</span>
                    <span>
                      {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
                      {/* @ts-ignore */}
                      {column.isSorted ? (
                        /* eslint-disable-next-line @typescript-eslint/ban-ts-comment */
                        /* @ts-ignore */
                        column.isSortedDesc ? (
                          <IconSortArrow
                            direction="down"
                            size={16}
                            style={{ marginBottom: '-.3rem', marginLeft: '.4rem' }}
                          />
                        ) : (
                          <IconSortArrow
                            direction="up"
                            size={16}
                            style={{ marginBottom: '-.3rem', marginLeft: '.4rem' }}
                          />
                        )
                      ) : (
                        <span style={{ width: '.4rem' }} />
                      )}
                    </span>
                  </th>
                )
              })}
            </tr>
          )
        })}
      </thead>
      <tbody {...getTableBodyProps()}>
        {(() => {
          if (isSkeleton) {
            return times(paginatorProps.limit ?? loadingRows).map(index => (
              <tr key={`skeltonrow:${index}`} className={bandedClass(index)}>
                <td colSpan={totalColSpan} style={{ padding: '0' }}>
                  <div className="my-1" style={{ height: '35px' }}></div>
                </td>
              </tr>
            ))
          }

          if (loading) {
            return times(paginatorProps.limit ?? loadingRows).map(index => (
              <tr key={`datatableloader:${index}`}>
                <td colSpan={totalColSpan} style={{ padding: '0' }}>
                  <div className="loading my-1" style={{ height: '35px' }}></div>
                </td>
              </tr>
            ))
          }

          if (rowData && rows.length === 0) {
            return (
              <tr>
                <td colSpan={totalColSpan}>
                  <ContextualAlert style={{ marginLeft: `-${tableCellPadding}px` }}>{noRecordsMessage}</ContextualAlert>
                </td>
              </tr>
            )
          }

          return rows.map((row, idx) => {
            prepareRow(row)
            const rowProps = getRowProps(row, idx)
            const key = getUniqueId ? getUniqueId(row) : `foo_${idx}`
            return customRow ? (
              customRow({ row, ...rowProps }, idx, rows)
            ) : (
              <DefaultTableRow
                {...rowProps}
                row={row}
                key={key}
                customCellStyle={customCellStyle}
                onRowClick={onRowClick}
                expandedRow={expandedRow}
                showExpandedRow={showExpandedRow}
              />
            )
          })
        })()}
      </tbody>
    </StyledDataTable>
  )

  const pagination = !!paginated && <Paginator {...{ ...DEFAULT_PAGINATOR_PROPS, ...paginatorProps }} />

  if (scrollStrategy === 'within') {
    return (
      <ScrollWithinContainer {...props}>
        {upperSlot}
        <div className="scroll-container">{table}</div>
        {pagination}
      </ScrollWithinContainer>
    )
  }

  return (
    <div {...props}>
      {upperSlot}
      {table}
      {pagination}
    </div>
  )
}

const ScrollWithinContainer = styled('div')`
  overflow: hidden;
  display: flex;
  flex-direction: column;

  .scroll-container {
    height: 100%;
    overflow: auto;
    flex: 1;
  }
`

const StyledDataTable = styled('table')<{
  cellPadding?: string
  hasRowInteraction: boolean
  height?: string
  evenRowBGColor: string
}>`
  border-collapse: separate;
  border-spacing: 0;
  width: 100%;

  th,
  td {
    // tbody td padding can be overridden with cellPadding prop
    padding: ${`${tableCellPadding}px`};
    text-align: left;
    vertical-align: top;
    &.right-align {
      text-align: right;
    }
    &.center-align {
      text-align: center;
    }
  }

  thead {
    position: sticky;
    position: -webkit-sticky;
    z-index: 2;
    top: 0;
    th {
      background-color: ${greySet[15].hex};
      color: ${greySet[80].hex};
      font-size: ${fontSize.caption};
      font-weight: 700;
      text-transform: uppercase;
      white-space: nowrap;
      &:first-of-type {
        border-radius: 6px 0 0 6px;
      }
      &:last-child {
        border-radius: 0 6px 6px 0;
      }
    }
  }

  tbody {
    tr {
      cursor: pointer;
      &:not(.expanded-row) {
        cursor: ${({ hasRowInteraction }) => (hasRowInteraction ? 'pointer' : 'auto')};
      }
      &:first-of-type td {
        box-shadow: none;
      }

      &:nth-of-type(odd) {
        background: none;
      }

      &.row-even {
        background: ${props => props.evenRowBGColor};
      }

      &.selected {
        background-color: ${tealSet[15].hex};
        &:hover {
          td {
            background: transparent;
          }
        }
      }

      &.row-expanded,
      &.expanded-row {
        td {
          border: 0 solid ${borderGrey};
        }
      }

      &.no-expand {
        pointer-events: none;
      }

      &.row-expanded {
        td {
          border-top-width: 1px;
          &:first-of-type {
            border-radius: 0;
            border-left-width: 1px;
            border-top-left-radius: 6px;
          }
          &:last-child {
            border-radius: 0;
            border-right-width: 1px;
            border-top-right-radius: 6px;
          }
        }
      }

      &.expanded-row {
        > td:last-child {
          border-top-right-radius: 0;
          border-bottom-left-radius: 6px;
          border-width: 0 1px 1px 1px;
        }
      }

      td {
        color: var(--body-secondary-color);
        font-size: ${fontSize.body};
        vertical-align: middle;
        ${props => props.cellPadding && `padding: ${props.cellPadding};`}

        &.align-top {
          vertical-align: top;
        }

        &:first-of-type {
          border-radius: 6px 0 0 6px;
        }
        &:last-child {
          border-radius: 0 6px 6px 0;
        }
      }
      &:hover:not(.expanded-row):not(.selected) {
        td {
          background: ${({ hasRowInteraction }) => (hasRowInteraction ? greySet[5].hex : 'transparent')};
        }
      }
    }
  }
`

function bandedClass(index: number) {
  return index % 2 ? 'row-even' : 'row-odd'
}

export function profileUserSortComparer<T extends object = {}>(predicate: (row: Row<T>) => ProfileUser) {
  return (a: Row<T>, b: Row<T>) => {
    return userFullName(predicate(a)).localeCompare(userFullName(predicate(b)))
  }
}

export function dateSortComparer<T extends object = {}>(predicate: (row: Row<T>) => string | Date) {
  return (a: Row<T>, b: Row<T>) => {
    return moment(predicate(a)).isBefore(moment(predicate(b))) ? -1 : 1
  }
}
