import { IconProps } from './IconProps'

export function IconGear({ size = 24, color = 'currentColor', ...props }: IconProps) {
  return (
    <svg width={size} height={size} viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17.4925 7.06844C17.8554 6.60454 19.0691 4.93048 18.2103 4.0753L17.7667 3.60737C17.0328 2.8732 15.1417 3.94218 14.6578 4.23262C14.1135 3.92201 13.5248 3.68401 12.9078 3.51862C12.807 2.90547 12.3756 0.815918 11.1659 0.815918H10.682C9.6417 0.815918 9.04493 2.98211 8.90783 3.53476C8.29896 3.70418 7.71832 3.94621 7.18203 4.25279C6.73445 3.93411 4.9159 2.74411 4.0409 3.61947L3.57316 4.01075C2.81509 4.77316 4.09332 6.73363 4.35138 7.11281C4.05703 7.64125 3.78687 8.21003 3.62557 8.80704C3.10541 8.89176 0.911865 9.31128 0.911865 10.5577V11.0418C0.911865 12.1148 3.19009 12.7199 3.63767 12.8288C3.80299 13.4218 4.05703 13.9906 4.35541 14.515C4.09735 14.9385 2.94412 16.9595 3.69816 17.7098L4.14574 18.0769C5.14977 19.0773 7.20219 17.3911 7.20219 17.3911C7.76228 17.6698 8.31536 17.9322 8.89977 18.0971C9.01267 18.5529 9.61348 20.8159 10.6861 20.8159H11.1699C12.5853 20.8159 12.9401 18.1334 12.9401 18.1334C13.5525 17.9171 14.1144 17.675 14.6336 17.3831C15.0812 17.6816 16.8433 18.7788 17.5772 18.0446L18.0691 17.5485C19.0248 16.5924 17.6215 14.6965 17.4925 14.5311C17.799 13.9946 18.0369 13.4137 18.2062 12.8087C18.7748 12.6675 20.9119 12.0705 20.9119 11.0378V10.5537C20.9078 9.19833 18.4361 8.81915 18.2022 8.78687C18.0369 8.17776 17.7949 7.60091 17.4925 7.06844ZM7.49654 10.7917C7.49654 8.91999 9.01267 7.40325 10.8836 7.40325C12.7546 7.40325 14.3433 8.91999 14.3433 10.7917C14.3433 12.6634 12.7546 14.2286 10.8836 14.2286C9.01267 14.2286 7.49654 12.6634 7.49654 10.7917Z"
        fill={color}
      />
    </svg>
  )
}
