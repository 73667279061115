import moment, { Moment } from 'moment'

import { useProviderAvailabilitySlotsQuery } from '@nuna/api'

export function useProviderAvailableTimeSlots({
  providerId,
  patientId,
  addressId,
  date,
  dayFormat = 'MM/DD/YYYY',
  reschedulingAppointmentId,
}: {
  providerId: string
  patientId?: string
  date?: Moment
  dayFormat?: string | undefined
  addressId?: string | undefined
  reschedulingAppointmentId?: string | undefined
}) {
  const lastAvailableDate = moment().endOf('day').add(1, 'month').toISOString()
  const { loading, data } = useProviderAvailabilitySlotsQuery({
    variables: {
      providerId,
      addressId,
      patientId,
      to: lastAvailableDate,
      reschedulingAppointmentId,
    },
    fetchPolicy: 'cache-and-network',
  })

  const slots = data?.providerAvailability ?? []
  const dateFilter = date && moment(date).format(dayFormat)

  return {
    loading,
    slots: dateFilter ? slots.filter(slot => moment(slot.start).format(dayFormat) === dateFilter) : slots,
    lastAvailableDate,
  }
}
