import { styled } from '@mui/material'

import { IconCaution, IconCheckCircle, greySet, makeTypographyComponent, shadowDepth, yellowSet } from '@nuna/tunic'

import { CoverageLogoRatio } from './CoverageTypography'

interface Props {
  employerName: string
  employerLogo?: string | null
  isIntakeStep?: boolean
}

export function ZeroCapEAPCard({ employerName, employerLogo, isIntakeStep = false }: Props) {
  return (
    <Card isIntakeStep={isIntakeStep}>
      {employerLogo ? (
        <Logo className="mb-3" src={employerLogo} alt={employerName} />
      ) : (
        <p className="large text-medium">{employerName}</p>
      )}
      <Heading>Employer Benefit</Heading>
      <Footer isIntakeStep={isIntakeStep}>
        {isIntakeStep ? (
          <span className="v-align">
            <IconCheckCircle size={20} color="var(--interactive-fill-color)" className="mr-xs" /> Sponsor Verified
          </span>
        ) : (
          <span className="v-align caption text-yellow text-medium">
            <IconCaution size={20} className="mr-xs" /> {employerName} uses insurance or credit card to cover sessions.
          </span>
        )}
      </Footer>
    </Card>
  )
}

const Card = styled('div')<{ isIntakeStep: boolean }>`
  background-color: ${props => (props.isIntakeStep ? '#fff' : yellowSet.tint[20])};
  border: 1px solid ${props => (props.isIntakeStep ? greySet[15].hex : yellowSet[50].hex)};
  border-radius: var(--border-radius);
  box-shadow: ${shadowDepth(1)};
  flex-basis: 350px;
  padding: var(--margin-2);
`

const Heading = makeTypographyComponent('body text-bold', 'h4')

const Logo = styled(CoverageLogoRatio)`
  margin-top: var(--margin-2);
  margin-bottom: var(--margin-4);
`

const Footer = styled('div')<{ isIntakeStep: boolean }>`
  border-top: 1px solid ${props => (props.isIntakeStep ? greySet[15].hex : yellowSet[50].hex)};
  margin-top: var(--margin-2);
  padding-top: var(--margin-2);
  margin-left: calc(-1 * var(--margin-2));
  margin-right: calc(-1 * var(--margin-2));
  padding-left: var(--margin-2);
  padding-right: var(--margin-2);
`
