import { noop } from 'lodash'
import { HTMLAttributes } from 'react'
import { useNavigate } from 'react-router-dom'

import { CancelPolicyItem, numberService } from '@nuna/core'
import { IconPreviewOff, IconToggleButton } from '@nuna/tunic'

import { CaptionLight, FieldLabel, FormCard, FormSection } from '../../../../shared/FormComponents'
import { ProviderTakeEstimate } from '../../../../shared/ProviderTakeEstimate'
import { CancelPolicyDate } from './CancelPolicyDate'

const { centsToFormattedDollars, centsToDollars } = numberService

interface Props extends HTMLAttributes<HTMLDivElement> {
  cancelPolicy: CancelPolicyItem
  providerId: string
  hideDetail?: () => void
}

export function CancelPolicyDetail({ cancelPolicy, hideDetail, ...props }: Props) {
  const navigate = useNavigate()
  return (
    <FormCard
      canEdit={false}
      isEditing={false}
      onEdit={noop}
      buttonsRightSlot={
        <IconToggleButton
          tooltip="Hide Policy Details"
          isActive
          onClick={hideDetail ? () => hideDetail() : () => navigate('../')}
          size="md"
        >
          <IconPreviewOff />
        </IconToggleButton>
      }
      {...props}
    >
      <FormSection>
        <FieldLabel>Late Cancelation Fee</FieldLabel>
        <div>
          <b>{centsToFormattedDollars(cancelPolicy.chargeAmount, { maximumFractionDigits: 2 })}</b> will be charged to
          clients that cancel <b>less than 24 hours</b> before a session
        </div>
        <ProviderTakeEstimate fee={centsToDollars(cancelPolicy.chargeAmount)} debounceKey="cancelCharge" />
      </FormSection>
      <FormSection>
        <FieldLabel>No-Show Fee</FieldLabel>
        <div>
          <b>{centsToFormattedDollars(cancelPolicy.noShowChargeAmount, { maximumFractionDigits: 2 })}</b> will be
          charged to clients that do not show up <b>in the first 15 minutes</b> of a session.
        </div>
        <ProviderTakeEstimate fee={centsToDollars(cancelPolicy.noShowChargeAmount)} debounceKey="noShowCharge" />
      </FormSection>
      <FormSection>
        <FieldLabel>Policy Effective Date</FieldLabel>
        <CancelPolicyDate
          cancelPolicy={cancelPolicy}
          descriptionDisplay={description => <span className="text-medium">{description}</span>}
        />
        {!cancelPolicy.isOld && (
          <CaptionLight className="mt-1">
            Clients will be required to accept your new policy after this date.
          </CaptionLight>
        )}
      </FormSection>
    </FormCard>
  )
}
