import daily from '@daily-co/daily-js'

export const supportsAudioProcessing = (() => {
  const { supportsAudioProcessing: supportsAudioProcessingDaily, name, version } = daily.supportedBrowser()

  const numericVersion = parseFloat(version)

  // in the case numericVersion is NaN, NaN < 18 will evaluate to false and we'll fall back to Daily
  if (name === 'Safari' && numericVersion < 18) {
    return false
  }

  return supportsAudioProcessingDaily
})()
