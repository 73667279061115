import { styled } from '@mui/material'
import { Drawer as MuiDrawer, DrawerProps as MuiDrawerProps } from '@mui/material'

import { semiOpaqueBackground } from '../../styles/global/misc.utils'
import { shadowDepth } from '../../styles/shadows'

export interface DrawerProps extends Omit<MuiDrawerProps, 'open' | 'elevation'> {
  isOpen: boolean
  onClose: () => void
  size?: string
  blurBackground?: boolean
  transparentBackground?: boolean // This is different from MUI's hideBackdrop because you can still click away to dismiss with this
}

export function Drawer({
  anchor = 'right',
  size = 'auto',
  isOpen,
  blurBackground = true,
  transparentBackground = false,
  ...props
}: DrawerProps) {
  return (
    <StyledDrawer
      {...props}
      blurBackground={blurBackground}
      transparentBackground={transparentBackground}
      size={size}
      anchor={anchor}
      open={isOpen}
      elevation={0}
    />
  )
}

const StyledDrawer = styled(MuiDrawer, {
  shouldForwardProp: (prop: string) => !['blurBackground', 'transparentBackground'].includes(prop),
})<Pick<DrawerProps, 'size' | 'blurBackground' | 'transparentBackground'>>`
  .MuiBackdrop-root {
    ${({ blurBackground, transparentBackground }) => {
      if (transparentBackground) {
        return 'background: transparent;'
      } else if (blurBackground) {
        return semiOpaqueBackground
      }

      return ''
    }}
  }

  .MuiPaper-root {
    color: unset;
  }

  .MuiDrawer-paperAnchorRight {
    width: ${({ size }) => size};
  }

  .MuiDrawer-paperAnchorBottom {
    border-radius: 1rem 1rem 0 0;
    height: ${({ size }) => size};
  }

  .MuiDrawer-paper {
    box-shadow: ${shadowDepth(4)};
  }
`
