import { useAudioTrack } from '@daily-co/daily-react'
import { styled } from '@mui/material'

import { BelowTablet, error } from '@nuna/tunic'

import { useTavaVideoUsers } from '../../../hooks/useTavaVideoUsers'
import { ABOVE_VIDEO } from '../../../util/zIndexes'
import { NetworkQualityIndicator } from '../../NetworkQualityIndicator'
import { MutedIndicator } from '../../VideoActionButton'
import { VideoOverlayText } from '../../VideoOverlayText'

interface Props {
  sessionId: string
}

export function ParticipantVideoStatus({ sessionId }: Props) {
  const audioTrack = useAudioTrack(sessionId)
  const { getUserBySessionId } = useTavaVideoUsers()
  const tavaVideoUser = getUserBySessionId(sessionId)

  return (
    <CornerContainer>
      <VideoOverlayText className="mr-1">{tavaVideoUser?.firstName}</VideoOverlayText>
      {audioTrack.isOff && <MutedIndicator color={error} className="mr-1" />}
      <NetworkQualityIndicator type="remote" sessionId={sessionId} />
    </CornerContainer>
  )
}

const CornerContainer = styled('div')`
  display: flex;
  position: absolute;
  bottom: 0.625rem;
  left: 0.625rem;
  z-index: ${ABOVE_VIDEO};

  @media (${BelowTablet}) {
    bottom: 0.25rem;
    left: 0.25rem;
  }
`
