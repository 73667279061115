import { DailyEventObjectWaitingParticipant } from '@daily-co/daily-js'
import { useDailyEvent, useWaitingParticipants } from '@daily-co/daily-react'
import { useCallback, useState } from 'react'

/**
 * Hook that returns the waiting participant ID for a given login ID if the user is awaiting access.
 * Includes event listeners to handle edge cases when a user knocks, disconnects, and knocks again
 * @param loginId
 * @returns waitingId if user is awaiting access, otherwise undefined
 */
export function useWaitingParticipantId(loginId: string) {
  const { waitingParticipants } = useWaitingParticipants()
  // find the user in any already existing waiting participants
  const [waitingId, setWaitingId] = useState(() => waitingParticipants.find(p => p.name === loginId)?.id)

  // if we get a waiting participant ID from the same login ID, replace the stale one with the new one
  const handleWaitingParticipantAdded = useCallback(
    (event: DailyEventObjectWaitingParticipant) => {
      if (event.participant.name === loginId) {
        setWaitingId(event.participant.id)
      }
    },
    [loginId],
  )

  // only reset this if it matches the current waiting ID, otherwise it's just a stale event and we can ignore
  const handleWaitingParticipantRemoved = useCallback(
    (event: DailyEventObjectWaitingParticipant) => {
      if (event.participant.id === waitingId) {
        setWaitingId(undefined)
      }
    },
    [waitingId],
  )

  useDailyEvent('waiting-participant-added', handleWaitingParticipantAdded)
  useDailyEvent('waiting-participant-removed', handleWaitingParticipantRemoved)

  return waitingId
}
