import { styled } from '@mui/material'
import { useFormikContext } from 'formik'
import { HTMLAttributes, useState } from 'react'
import { useNavigate } from 'react-router-dom'

import { isMobile } from '@nuna/common'
import { routeService } from '@nuna/core'
import {
  BelowTablet,
  ContextualAlert,
  GhostButton,
  GhostButtonLink,
  IntakeFormStepper,
  OutlineButtonWithChevron,
} from '@nuna/tunic'

import { useProviderSignupIntakeContext } from '../ProviderSignupIntakeContext'
import { IntakeStep, getNextIntakePath, getPrevIntakePath } from '../signup-intake-steps'
import { logIntakeFormErrors } from '../signup-intake-utils'
import { ProviderSignupFormValues } from './provider-signup-intake-types'

type IntakeSections = 'profile' | 'insurance' | 'background' | 'cancel-policy'

interface Props extends HTMLAttributes<HTMLDivElement> {
  step?: IntakeStep
  mutationLoading: boolean
  type?: 'form-submit' | 'navigation'
  forwardDisabled?: boolean
  isValid: boolean
  customText?: string
  currentSection?: IntakeSections
}

const renderSkipSlugAndText = (currentSection: IntakeSections, allRequirementsMet?: boolean) => {
  switch (currentSection) {
    case 'profile':
      return { slug: routeService.backgroundCheckRoute, text: 'Profile Creation' }
    case 'insurance':
      return { slug: routeService.scheduleDetail(), text: 'Insurance Enrollment' }

    case 'background':
      return { slug: routeService.caqhSetup('intro'), text: 'Background Check' }

    case 'cancel-policy':
      return { slug: allRequirementsMet ? routeService.intake() : routeService.backgroundCheckRoute, text: 'for now' }
    default:
      return { slug: '/', text: '' }
  }
}

export function ProviderIntakeFormStepper({
  step,
  mutationLoading,
  type = 'form-submit',
  forwardDisabled = false,
  isValid,
  customText,
  currentSection,
  ...props
}: Props) {
  const { values, errors } = useFormikContext<ProviderSignupFormValues>()
  const { buildValuesAndSave, allRequirementsMet } = useProviderSignupIntakeContext()
  const navigate = useNavigate()
  const [forwardClicked, setForwardClicked] = useState(false)

  const handleNavigateForward = () => {
    if (currentSection === 'background') {
      navigate(routeService.caqhSetup('intro'))
      return
    }

    step && navigate(getNextIntakePath(step))
  }

  const handleForwardClicked = () => {
    logIntakeFormErrors(errors)
    setForwardClicked(true)
    if (type === 'navigation') {
      handleNavigateForward()
    }
  }

  const handlePreviousClicked = () => {
    if (type === 'form-submit') {
      buildValuesAndSave(values)
    }
  }

  const handleSkipClick = (slug: string) => {
    buildValuesAndSave(values)
    navigate(slug)
  }

  const isError = !isValid && forwardClicked

  return (
    <Container {...props}>
      <IntakeFormStepper
        previousPath={step ? getPrevIntakePath(step) : '/'}
        mutationLoading={mutationLoading}
        onPreviousClick={handlePreviousClicked}
        className={isMobile ? 'flex-wrap' : ''}
        nextButton={defaultButtonProps => (
          <>
            <OutlineButtonWithChevron
              disabled={forwardDisabled}
              variant={isError ? 'destroy' : 'primary'}
              onClick={handleForwardClicked}
              {...defaultButtonProps}
              type={type === 'form-submit' ? 'submit' : 'button'}
              data-testid="intake-continue-button"
            >
              {customText ?? 'Continue'}
            </OutlineButtonWithChevron>
            {currentSection &&
              (currentSection === 'profile' ? (
                <GhostButton
                  variant="secondary"
                  onClick={() => handleSkipClick(renderSkipSlugAndText(currentSection).slug)}
                  className={`ml-2 ${isMobile ? 'mr-5 mt-2' : ''}`}
                  data-testid="skip-intake-section"
                >
                  Skip {renderSkipSlugAndText(currentSection).text}
                </GhostButton>
              ) : (
                <GhostButtonLink
                  variant="secondary"
                  to={renderSkipSlugAndText(currentSection, allRequirementsMet).slug}
                  className={`ml-2 ${isMobile ? 'mr-5 mt-2' : ''}`}
                  data-testid="skip-intake-section"
                >
                  Skip {renderSkipSlugAndText(currentSection).text}
                </GhostButtonLink>
              ))}
          </>
        )}
      />
      {isError && (
        <ContextualAlert intent="urgent" className="mt-2" style={{ marginLeft: '5rem' }}>
          Some information is missing above
        </ContextualAlert>
      )}
    </Container>
  )
}

const Container = styled('div')`
  margin-bottom: 5rem;

  @media (${BelowTablet}) {
    margin-bottom: 2.5rem;
  }
`
