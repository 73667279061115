import { IconProgressIndicator } from '@nuna/tunic'

export function CaqhSyncLoading() {
  return (
    <div className="text-center">
      <h2 className="h3 mb-4 mt-4">Pulling Your CAQH Profile Status</h2>
      <p className=" text-secondary mb-4">We need to confirm your profile information before we finalize enrollment</p>
      <IconProgressIndicator size={64} />
    </div>
  )
}
