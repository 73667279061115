import { IconProps } from './IconProps'

export function IconChevronRightThin({ size = 24, color = 'currentColor', ...props }: IconProps) {
  return (
    <svg width={size} height={size} viewBox="0 0 18 18" fill={color} xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.68519 8.97341L6.47809 15.3182C6.42707 15.4208 6.46971 15.5449 6.5729 15.5953L7.2696 15.9555C7.37299 16.0061 7.49812 15.9639 7.54904 15.8616L10.9475 9.0309L10.9914 8.94278C11.0059 8.91356 11.0056 8.86607 10.9908 8.83724L10.946 8.7496L7.54946 2.11306C7.49739 2.01132 7.37166 1.97083 7.26905 2.02239L6.54773 2.46955C6.44493 2.52121 6.4039 2.64575 6.45594 2.74742L9.68432 8.81473C9.70667 8.85841 9.7072 8.92918 9.68519 8.97341Z"
      />
    </svg>
  )
}
