import { Maybe, Scalars } from './common.types'

export enum AppointmentCurrentStatus {
  Active = 'ACTIVE',
  CanceledByAdmin = 'CANCELED_BY_ADMIN',
  CanceledByPatient = 'CANCELED_BY_PATIENT',
  CanceledByProvider = 'CANCELED_BY_PROVIDER',
  NoShowByPatient = 'NO_SHOW_BY_PATIENT',
  NoShowByProvider = 'NO_SHOW_BY_PROVIDER',
  RescheduledByPatient = 'RESCHEDULED_BY_PATIENT',
  RescheduledByProvider = 'RESCHEDULED_BY_PROVIDER',
  Undocumented = 'UNDOCUMENTED',
}

export enum AppointmentChangeReason {
  ClientNoShow = 'CLIENT_NO_SHOW',
  FeelingNervous = 'FEELING_NERVOUS',
  FreeForm = 'FREE_FORM',
  InvalidCoverage = 'INVALID_COVERAGE',
  LossOfInterest = 'LOSS_OF_INTEREST',
  NotFeelingWell = 'NOT_FEELING_WELL',
  ProviderNoShow = 'PROVIDER_NO_SHOW',
  RequestedByPatient = 'REQUESTED_BY_PATIENT',
  SchedulingConflict = 'SCHEDULING_CONFLICT',
  SwitchedProvider = 'SWITCHED_PROVIDER',
  TechnicalDifficulties = 'TECHNICAL_DIFFICULTIES',
}

export enum HistoryAppointmentCurrentStatus {
  Active = 'ACTIVE',
  CanceledByAdmin = 'CANCELED_BY_ADMIN',
  CanceledByPatient = 'CANCELED_BY_PATIENT',
  CanceledByProvider = 'CANCELED_BY_PROVIDER',
  NoShowByPatient = 'NO_SHOW_BY_PATIENT',
  NoShowByProvider = 'NO_SHOW_BY_PROVIDER',
  RescheduledByAdmin = 'RESCHEDULED_BY_ADMIN',
  RescheduledByPatient = 'RESCHEDULED_BY_PATIENT',
  RescheduledByProvider = 'RESCHEDULED_BY_PROVIDER',
  Undocumented = 'UNDOCUMENTED',
}

export enum SessionPaymentStatus {
  Failure = 'FAILURE',
  Pending = 'PENDING',
  Scheduled = 'SCHEDULED',
  Success = 'SUCCESS',
  Waived = 'WAIVED',
}

export type Appointment = {
  currentStatus: AppointmentCurrentStatus
  startDatetime: Scalars['Date']
  endDatetime: Scalars['Date']
}

export type TimeSlot = { start: Scalars['Date']; end: Scalars['Date'] }

export type AppointmentCollectionStatus = {
  appointmentHistoryItemId?: Maybe<Scalars['ID']>
  appointmentHistoryItemStatus?: Maybe<HistoryAppointmentCurrentStatus>
  appointmentId: Scalars['ID']
  currentChangeReason?: Maybe<AppointmentChangeReason>
  currentStatus?: Maybe<AppointmentCurrentStatus>
  endDate: Scalars['Date']
  feeAmount?: Maybe<Scalars['Int']>
  feeDue?: Maybe<Scalars['Date']>
  originalEndDate?: Maybe<Scalars['Date']>
  originalStartDate?: Maybe<Scalars['Date']>
  paymentStatus?: Maybe<SessionPaymentStatus>
  sessionPaymentId?: Maybe<Scalars['ID']>
  startDate: Scalars['Date']
  waivedAt?: Maybe<Scalars['Date']>
}

export enum AccountSource {
  CareNavigator = 'CareNavigator',
  Fixture = 'Fixture',
  Manual = 'Manual',
  PracticeFusion = 'PRACTICE_FUSION',
  ProviderForm = 'ProviderForm',
  PublicProviderProfilePage = 'PublicProviderProfilePage',
  Signup = 'Signup',
  Typeform = 'TYPEFORM',
  TavaHealth = 'TavaHealth',
  Zocdoc = 'Zocdoc',
}
