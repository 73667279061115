import { FormikErrors, FormikTouched } from 'formik'
import { partition } from 'lodash'
import moment from 'moment'
import { useState } from 'react'
import { v4 as uuidv4 } from 'uuid'

import { AvailabilityPeriodsFragment, ProviderAddressesQuery, ProviderAvailabilityFragment } from '@nuna/api'
import { formService } from '@nuna/core'
import { AvailabilityCalendar, LocationCard } from '@nuna/provider'
import { DatePicker, IconClock, PlaceholderButton, TimezoneSelect, greySet } from '@nuna/tunic'

import { IntakeHeader } from './IntakeTypography'

const { composeHelperTextWithError } = formService

type Props = {
  handleBlur: (event: React.ChangeEvent<unknown>) => void
  setFieldValue: (field: string, value: unknown) => void
  expectedStartDate: Date | null
  timezone: string | null | undefined
  availabilityConstraints: AvailabilityPeriodsFragment
  errors: FormikErrors<ProviderAvailabilityFragment & { expectedStartDate: Date }>
  touched: FormikTouched<ProviderAvailabilityFragment & { expectedStartDate: Date }>
  providerAddressData: ProviderAddressesQuery['providerAddresses']
  acceptingClientsDate?: boolean
}

export function AvailabilityFields({
  handleBlur,
  setFieldValue,
  touched,
  expectedStartDate,
  timezone,
  availabilityConstraints,
  errors,
  providerAddressData,
  acceptingClientsDate = true,
}: Props) {
  const [isAddAddressOpen, setIsAddAddressOpen] = useState(false)
  const availabilityError = composeHelperTextWithError('', errors.availabilityPeriods, !!touched.availabilityPeriods)

  return (
    <>
      {/* <IntakeHeader type="h2">Set the max number of clients you’d like in your total caseload.</IntakeHeader>
      <p>
        Your total caseload includes your own clients and any Tava referrals. Note that clients have varied preferences
        for session cadence (weekly, bi-weekly, and monthly), so your hours spent in session may vary from week to week.
      </p>
      <NumberField
        className="mt-2"
        data-testid="expected-caseload"
        name="expectedCaseloadAmount"
        value={values.expectedCaseloadAmount ?? 0}
        onChange={(e) => setFieldValue('expectedCaseloadAmount', Number(e.target.value))}
        onBlur={handleBlur}
        min={0}
        {...composeHelperTextWithError('', errors.expectedCaseloadAmount, !!touched.expectedCaseloadAmount)}
      /> */}
      {acceptingClientsDate && (
        <>
          <IntakeHeader md={{ mt: 6 }} xs={{ mt: 5 }} type="h2">
            What date would you like to begin accepting clients?
          </IntakeHeader>
          <DatePicker
            containerProps={{ style: { maxWidth: 400 } }}
            placeholder="Soonest date of availability"
            value={moment(expectedStartDate)}
            minDate={moment()}
            onChange={date => {
              setFieldValue('expectedStartDate', date)
            }}
            data-testid="soonest-available-date"
            onBlur={handleBlur}
            {...composeHelperTextWithError('', errors.expectedStartDate, !!touched.expectedStartDate)}
          />
        </>
      )}
      <IntakeHeader md={{ mt: 6 }} xs={{ mt: 5 }} type="h2">
        In-person locations
      </IntakeHeader>
      <p>Optional: Add locations where you'll see patients in person.</p>
      <p className="caption text-medium mt-3" style={{ color: greySet[70].hex }}>
        You can always change this later in Account / Preferences / Locations
      </p>
      {providerAddressData?.map(providerAddress => (
        <LocationCard key={providerAddress.addressId} address={providerAddress} className="mb-2" />
      ))}
      {!isAddAddressOpen ? (
        <PlaceholderButton onClick={() => setIsAddAddressOpen(true)}>Add a location</PlaceholderButton>
      ) : (
        <LocationCard isEmbedded onCancel={() => setIsAddAddressOpen(false)} />
      )}

      <IntakeHeader md={{ mt: 6 }} xs={{ mt: 5 }} type="h2">
        Set your availability
      </IntakeHeader>
      <p>
        Click and drag to add days and times for clients to book with you. Then click to edit your location. You can add
        multiple openings for each day. You’ll be able to modify your availability at any point once you get started.
      </p>

      <div className="mb-6">
        <AvailabilityCalendar
          timeZone={timezone ?? moment.tz.guess()}
          availabilityPeriods={availabilityConstraints.allowDayAndTime}
          onChange={dayAndTimes => {
            const [updatedPeriods, newPeriods] = partition(dayAndTimes, period => !!period.id)
            const existingPeriods = availabilityConstraints.allowDayAndTime.filter(
              p1 => p1.id && !updatedPeriods.some(p2 => p2.id === p1.id),
            )
            setFieldValue('availabilityPeriods', {
              ...availabilityConstraints,
              allowDayAndTime: [
                ...existingPeriods,
                ...updatedPeriods,
                ...newPeriods.map(period => ({ ...period, id: uuidv4(), virtualAllowed: true })),
              ],
            })
          }}
          onDelete={periodsToDelete => {
            setFieldValue('availabilityPeriods', {
              ...availabilityConstraints,
              allowDayAndTime: availabilityConstraints.allowDayAndTime.filter(
                p1 => !periodsToDelete.some(p2 => p1.id === p2.id),
              ),
            })
          }}
        />

        {availabilityError.error && <p className="caption text-error mt-1">{availabilityError.helperText}</p>}
      </div>

      <IntakeHeader type="h2" md={{ mb: 1 }}>
        Confirm timezone
      </IntakeHeader>

      <div className="v-align">
        <IconClock size={20} className="mr-1 flex-static" />
        <TimezoneSelect
          style={{ minWidth: 300 }}
          value={timezone}
          name="timezone"
          onChange={timezone => setFieldValue('timezone', timezone)}
        />
      </div>
    </>
  )
}
