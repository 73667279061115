import { SxProps, Theme } from '@mui/material'
import moment, { Moment } from 'moment'
import { ChangeEventHandler, useMemo, useState } from 'react'

import { PatientSessionNotesQuery } from '@nuna/api'
import {
  Box,
  Card,
  CardBody,
  IconButton,
  IconChevron,
  IconNotebook,
  Stack,
  TextField,
  Typography,
  bodyPrimary,
  bodySecondary,
  fontWeight,
  paragraphsLineHeight,
  sansSerifFontStack,
} from '@nuna/tunic'

import { DraftSavingStatus } from './DraftSavingStatus'

type Props = {
  noteDraft: string | undefined
  pastSessionNotes: PastSessionNote[]
  sx?: SxProps<Theme>
} & (
  | {
      isEditable?: false
    }
  | {
      isEditable: true
      onChange: ChangeEventHandler<HTMLTextAreaElement>
      lastSavedAt: Moment | undefined
      isSaving: boolean
    }
)

type PastSessionNote = PatientSessionNotesQuery['patient']['sessions'][0]

// Simplified interface so we can put past notes and in-session notes in same array
interface DisplayNote {
  date?: PastSessionNote['date']
  note: string
  isDraft?: boolean
}

export function SessionNoteHistory({ noteDraft, pastSessionNotes, sx, ...props }: Props) {
  const [currentIndex, setCurrentIndex] = useState(0)

  const notes: DisplayNote[] = useMemo(() => {
    // Most recent first
    const sortedNotes = [...pastSessionNotes].reverse()

    if (noteDraft || props.isEditable) {
      return [{ date: undefined, note: noteDraft ?? '', isDraft: true }, ...sortedNotes]
    }

    return sortedNotes
  }, [noteDraft, pastSessionNotes, props.isEditable])

  const activeNote = notes[currentIndex]

  return (
    <Card sx={sx}>
      <CardBody sx={{ py: props.isEditable ? 2 : 3 }}>
        <Stack alignItems="center" direction="row" justifyContent="space-between">
          <Stack direction="row" alignItems="center">
            {!props.isEditable && <IconNotebook size={20} className="mr-xs" />}
            <Typography
              variant={props.isEditable ? 'body1' : 'h6'}
              component="h4"
              sx={{ color: bodyPrimary, fontFamily: sansSerifFontStack, mb: 0, fontWeight: fontWeight.medium }}
            >
              {activeNote.date ? (
                <>
                  <Box component="span" sx={{ '@media (max-width: 400px)': { display: 'none' } }}>
                    Session note
                  </Box>{' '}
                  {moment(activeNote.date).format('M/D/YY')}
                </>
              ) : (
                'In-session notes'
              )}
            </Typography>
          </Stack>

          {pastSessionNotes.length > 0 && (
            <Stack flexWrap="nowrap" spacing={0.5} alignItems="center" direction="row">
              <IconButton
                type="button"
                customSize={24}
                tooltip="Previous"
                disabled={currentIndex === 0}
                onClick={() => setCurrentIndex(Math.max(0, currentIndex - 1))}
                data-testid="previous-note"
              >
                <IconChevron direction="left" />
              </IconButton>

              <Box component="span" sx={{ fontVariantNumeric: 'tabular-nums' }}>
                {currentIndex + 1} | {notes.length}
              </Box>

              <IconButton
                type="button"
                customSize={24}
                tooltip="Next"
                disabled={currentIndex === notes.length - 1}
                onClick={() => setCurrentIndex(Math.min(notes.length - 1, currentIndex + 1))}
                data-testid="next-note"
              >
                <IconChevron direction="right" />
              </IconButton>
            </Stack>
          )}
        </Stack>

        {activeNote.isDraft && props.isEditable ? (
          <>
            <TextField
              multiline
              variant="outlined"
              value={activeNote.note}
              onChange={props.onChange}
              className="fs-exclude"
              rows={8}
            />
            <DraftSavingStatus sx={{ pt: 1 }} loading={props.isSaving} lastSavedAt={props.lastSavedAt} />
          </>
        ) : (
          <Box
            sx={{ whiteSpace: 'pre-line', color: bodySecondary, pt: 3, lineHeight: paragraphsLineHeight }}
            className="fs-exclude"
          >
            {activeNote.note}
          </Box>
        )}
      </CardBody>
    </Card>
  )
}
