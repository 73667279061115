import { IconProps } from './IconProps'

export function IconHorizontalReport({ size = 24, color = 'currentColor', ...props }: IconProps) {
  const height = (21 / 22) * size

  return (
    <svg width={size} height={height} viewBox="0 0 21 22" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1.375 14.8398L10.1776 14.8398C10.3847 14.8398 10.5526 15.0077 10.5526 15.2148V17.0481C10.5526 17.2552 10.3847 17.4231 10.1776 17.4231L1.375 17.4231C1.16789 17.4231 1 17.2552 1 17.0481V15.2148C1 15.0077 1.16789 14.8398 1.375 14.8398ZM1 10.5064C1 10.2993 1.16789 10.1314 1.375 10.1314L18.25 10.1314C18.4571 10.1314 18.625 10.2993 18.625 10.5064V12.3398C18.625 12.5469 18.4571 12.7148 18.25 12.7148L1.375 12.7148C1.16789 12.7148 1 12.5469 1 12.3398V10.5064ZM0.999999 5.7981C0.999999 5.59099 1.16789 5.4231 1.375 5.4231L15.9408 5.4231C16.1479 5.4231 16.3158 5.59099 16.3158 5.7981V7.63143C16.3158 7.83854 16.1479 8.00643 15.9408 8.00643L1.375 8.00643C1.16789 8.00643 0.999999 7.83854 0.999999 7.63143V5.7981Z"
        fill={color}
      />
    </svg>
  )
}
