import { styled } from '@mui/material'
import { HTMLAttributes } from 'react'

import { Skeleton } from '@nuna/tunic'

export function SessionNotesFormSkeletonNotesOnly(props: HTMLAttributes<HTMLDivElement>) {
  return (
    <Wrapper {...props}>
      <section className="mt-2 mb-2">
        <Skeleton fullWidth={true} height={3} />
      </section>
      <Skeleton fullWidth={true} height={19} className="mb-2" />
      <Skeleton fullWidth={true} height={1} />
    </Wrapper>
  )
}

const Wrapper = styled('div')`
  display: flex;
  padding: 1rem;
  flex-direction: column;
  overflow: hidden;
  width: 100%;
`
