import { Avatar, PageContent, PageHeader, PageWrapper, Skeleton } from '@nuna/tunic'

import { SessionDetailHeaderSkeleton } from './SessionNote/components/SessionDetailHeader'

export function SessionNotesSkeleton() {
  return (
    <PageWrapper>
      <PageHeader>
        <SessionDetailHeaderSkeleton />
      </PageHeader>
      <PageContent>
        <div style={{ maxWidth: '48rem' }}>
          <div className="mb-5 body large text-light v-align" style={{ padding: '7px 0' }}>
            Session with: <Avatar size="mini" className="mx-1 fs-exclude" />
            <Skeleton width={10} height={2} />
          </div>
          <div className="mt-5 v-align">
            <Skeleton height={3} width={12} />
            <Skeleton height={3} width={12} className="ml-1" />
          </div>
          <section className="mt-5">
            <h3 className="h6 mt-2">Session Summary</h3>
            <Skeleton style={{ width: '100%' }} height={3} />
            <Skeleton style={{ width: '100%' }} height={5} className="mt-1" />
          </section>
          <section className="mt-5">
            <h3 className="h6 mt-2">Going Forward</h3>
            <Skeleton style={{ width: '100%' }} height={5} />
          </section>
        </div>
      </PageContent>
    </PageWrapper>
  )
}
