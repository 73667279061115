import { HTMLAttributes } from 'react'

import { Patient } from '@nuna/api'
import { UserChip, csx } from '@nuna/tunic'

interface Props extends HTMLAttributes<HTMLDivElement> {
  users: Pick<Patient, 'avatarUrl' | 'firstName'>[]
}

export function MessageToChips({ users, className, ...props }: Props) {
  return (
    <div className={csx([className, 'v-align'])} {...props}>
      <span className="text-medium text-secondary">Message to:</span>
      {users.map(user => (
        <UserChip className="ml-1 fs-exclude" key={user.avatarUrl} avatarUrl={user.avatarUrl}>
          {user.firstName}
        </UserChip>
      ))}
    </div>
  )
}
