import { useProviderPreferencesQuery } from '@nuna/api'
import { routeService } from '@nuna/core'
import { Box, BoxProps, IconExternalLink, TextButtonLink } from '@nuna/tunic'

interface Props extends BoxProps {
  providerId: string
}

export function AutonoteStatus({ providerId, ...props }: Props) {
  // since this reflects their preference setting and not necessarily the pref for the current session, query for it instead of pulling from sessionData
  const { data } = useProviderPreferencesQuery({ variables: { providerId } })

  const isAutonotesEnabled = data?.provider?.preferences?.autonotesEnabled ?? false

  return (
    <Box {...props}>
      Tava Scribe is <span className="text-medium">{isAutonotesEnabled ? 'on' : 'off'}</span>.{' '}
      <TextButtonLink variant="secondary" to={routeService.providerPreferencesScribe()}>
        Preferences <IconExternalLink className="ml-xs" size={18} />
      </TextButtonLink>
    </Box>
  )
}
