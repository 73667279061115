import '@fullcalendar/react/dist/vdom'

import FullCalendar, { DatesSetArg } from '@fullcalendar/react'

import { useApolloClient } from '@apollo/client'
import { EventSourceFunc } from '@fullcalendar/core'
import { styled } from '@mui/material'
import moment, { Moment } from 'moment'
import { useRef, useState } from 'react'

import {
  AppointmentCurrentStatus,
  AppointmentInfoSummary,
  AppointmentStatsDocument,
  AppointmentStatsQueryVariables,
  ProviderMeAvailabilityDocument,
  ProviderMeAvailabilityQueryVariables,
  PublicAvailabilitySlot,
  useProviderAvailabilityConstraintsQuery,
} from '@nuna/api'
import { FullCalendarWrapper } from '@nuna/common'
import { ProviderScheduleControls } from '@nuna/provider'
import { body1, body2, borderGrey, greySet, primarySet, tealSet, toast } from '@nuna/tunic'

const Container = styled('div')`
  padding: 2rem;
`

export function ProviderOpeningsPreview() {
  const client = useApolloClient()
  const calendarRef = useRef<FullCalendar>(null)
  const [date, setDate] = useState<{ start: Moment; end: Moment } | null>(null)

  const { data: constraintsData } = useProviderAvailabilityConstraintsQuery()

  const awayTime = [
    ...(constraintsData?.providerMe.availabilityPeriods.blockPeriod ?? []),
    ...(constraintsData?.providerMe.availabilityPeriods.calendarBlockPeriod ?? []),
  ]

  const handleDatesSet = ({ start, end }: DatesSetArg) => {
    setDate({ start: moment(start), end: moment(end).subtract('1', 'm') })
  }

  const getEvents: EventSourceFunc = async ({ start, end }, callback) => {
    try {
      const ProviderAvailabilityQueryConfig: {
        query: typeof ProviderMeAvailabilityDocument
        variables: ProviderMeAvailabilityQueryVariables
        fetchPolicy: 'network-only'
      } = {
        query: ProviderMeAvailabilityDocument,
        variables: {
          from: moment(start).format(),
          to: moment(end).format(),
        },
        fetchPolicy: 'network-only',
      }

      const apptQueryConfig: {
        query: typeof AppointmentStatsDocument
        variables: AppointmentStatsQueryVariables
        fetchPolicy: 'network-only'
      } = {
        query: AppointmentStatsDocument,
        variables: {
          options: {
            start: moment(start).format(),
            end: moment(end).format(),
            statusIsIn: [AppointmentCurrentStatus.Active],
          },
        },
        fetchPolicy: 'network-only',
      }

      let appts: AppointmentInfoSummary[] = []
      let slots: PublicAvailabilitySlot[] = []

      try {
        const [apptsResponse, slotsResponse] = await Promise.all([
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          client.query<any>(apptQueryConfig),
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          client.query<any>(ProviderAvailabilityQueryConfig),
        ])

        appts = apptsResponse?.data.appointmentStats.filteredAppointments || []
        slots = slotsResponse.data.providerMe.availableTimes
      } catch (e) {
        toast.urgent('Error loading schedule')
      }

      // Build events for calendar
      const openingsForCalendar = slots.map(slot => {
        return {
          id: `${slot.start}:${slot.end}`,
          title: `Open`,
          start: slot.start,
          end: moment(slot.start).add(60, 'minutes').format(),
          date: slot.start,
          color: primarySet.tint[40],
          textColor: primarySet[80],
        }
      })

      const appointmentsForCalendar = appts.map(appt => {
        return {
          id: appt.id,
          title: appt.patientName,
          start: appt.startDatetime,
          end: appt.endDatetime,
          date: appt.startDatetime,
          color: tealSet[15].hex,
          textColor: body1,
          borderColor: tealSet[30].hex,
        }
      })

      const awaySlotsForCalendar = awayTime.map(awaySlot => {
        return {
          id: `${awaySlot.start}:${awaySlot.end}`,
          title: 'ancillaryId' in awaySlot ? 'Personal Calendar' : 'Away',
          start: awaySlot.start,
          end: awaySlot.end,
          color: greySet[30].hex,
          textColor: body2,
          date: awaySlot.start,
          display: 'ancillaryId' in awaySlot ? 'background' : 'auto',
          borderColor: greySet[50].hex,
        }
      })

      const allCalendarEvents = openingsForCalendar.concat(appointmentsForCalendar).concat(awaySlotsForCalendar)

      callback(allCalendarEvents)
      return allCalendarEvents
    } catch (e) {
      console.error(e)
    }

    return []
  }

  return (
    <Container className="availability-calendar">
      <div className="v-align mb-3">
        <h2 className="h5 mr-2 mb-1 nowrap">Availability Preview</h2>
        <p>
          Based on your availability settings. Each open slot on this calendar is available for clients to schedule.
        </p>
      </div>

      <Controls>
        <ProviderScheduleControls calendarRef={calendarRef} date={date} />
      </Controls>

      <FullCalendarWrapper calendarRef={calendarRef} datesSet={handleDatesSet} events={getEvents} height={708} />
    </Container>
  )
}

const Controls = styled('div')`
  border-bottom: 1px solid ${borderGrey};
  padding-bottom: 1rem;
`
