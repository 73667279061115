import * as Yup from 'yup'
import { startCase } from 'lodash'
import { useEffect, useState } from 'react'

import {
  BasicProviderFragment,
  Patient,
  PatientProfileProviderFragment,
  useBulkUpdateProviderToPatientAssignmentMutation,
} from '@nuna/api'
import { useIsAdmin } from '@nuna/auth'
import { ProfileSection, ProviderSelect, ReadOnlyProfileSection, UserLink } from '@nuna/common'
import { SecondaryInfo } from '@nuna/tunic'

const validationSchema = Yup.object().shape({
  providerIds: Yup.array().of(Yup.string()),
})

interface ProfileCareTeamPatient extends Pick<Patient, 'id'> {
  providers: PatientProfileProviderFragment[]
}

interface ClientProfileCareTeamProps {
  patient: ProfileCareTeamPatient | undefined
}

const heading = 'Care Team'

export function ClientProfileCareTeam({ patient }: ClientProfileCareTeamProps) {
  const isAdmin = useIsAdmin()
  const [bulkUpdateProviderToPatientAssignmentMutation, { loading }] = useBulkUpdateProviderToPatientAssignmentMutation(
    {
      refetchQueries: ['PatientDetails'],
    },
  )

  const [selectedProviders, setSelectedProviders] = useState<BasicProviderFragment[]>([])

  useEffect(() => {
    if (patient) {
      setSelectedProviders(patient.providers)
    }
  }, [patient])

  if (!patient) {
    return <ReadOnlyProfileSection heading={heading} isLoading renderDisplayValue={null} />
  }

  return (
    <ProfileSection
      heading={heading}
      displayWidth="full"
      isLoading={loading}
      initialValues={{ providerIds: patient.providers.map(provider => provider.id) }}
      validationSchema={validationSchema}
      handleSubmit={async values => {
        await bulkUpdateProviderToPatientAssignmentMutation({
          variables: {
            patientId: patient.id,
            ...values,
          },
        })
      }}
      onCancel={() => setSelectedProviders(patient.providers)}
      showEditButton={isAdmin}
      renderDisplayValue={patient.providers.map(provider => (
        <div key={provider.id} className="v-align mb-1">
          <UserLink user={provider} />
          <SecondaryInfo>{startCase(provider.type.toLowerCase())}</SecondaryInfo>
        </div>
      ))}
      renderForm={({ setFieldValue }) => (
        <div className="mr-5">
          <ProviderSelect
            selectedProviders={selectedProviders}
            onChange={providers => {
              setSelectedProviders(providers ?? [])
              setFieldValue(
                'providerIds',
                (providers ?? []).map(provider => provider.id),
              )
            }}
          />
        </div>
      )}
    />
  )
}
