import { HTMLAttributes } from 'react'

import { CapStatus, usePatientCapQuery } from '@nuna/api'
import { capInfoUtils } from '@nuna/coverage'
import { ProgressBar, StatusLabel } from '@nuna/tunic'

interface Props extends HTMLAttributes<HTMLDivElement> {
  clientId: string
}
export function ClientCapInfo({ clientId, ...props }: Props) {
  const {
    data,
    loading: isLoadingCap,
    error: didErrorLoadingCap,
  } = usePatientCapQuery({
    variables: { id: clientId },
    fetchPolicy: 'cache-and-network',
  })

  const capInfo = data?.patient.appointmentConfiguration.capInfo

  const hasPaymentMethod = data?.patient.appointmentConfiguration.patientHasPaymentMethod
  const { progressBars, capPercentageUsed } = capInfoUtils.generateCapInfoDisplayData(capInfo, hasPaymentMethod)

  const remainingSessions =
    capInfo?.sessionAllowanceToType && capInfo?.sessionsCountingTowardsCap
      ? capInfo?.sessionAllowanceToType - capInfo?.sessionsCountingTowardsCap
      : null

  const isLastCoveredAppointment = !hasPaymentMethod && remainingSessions === 1

  if (didErrorLoadingCap) {
    return <div className="mt-1 text-magenta">Error loading contract information for this patient.</div>
  }

  if (isLoadingCap || capInfo?.capStatus !== CapStatus.Nocap) {
    return (
      <div {...props}>
        <div className={`mt-1 text-dark ${isLoadingCap ? 'loading' : ''}`}>
          Covered for {capInfo?.sessionAllowanceToType} total sessions.
        </div>

        {capPercentageUsed > 0 && (
          <figure className={`v-align mt-1 mb-0 mx-0 ${isLoadingCap ? 'loading' : ''}`}>
            <ProgressBar style={{ maxWidth: '108px', transform: 'translateY(1px)' }} bars={progressBars} />
            <figcaption className="ml-1 nowrap text-secondary">
              {capInfo?.sessionsAttended} Attended • {capInfo?.futureAppointmentsInCap} Upcoming
            </figcaption>
          </figure>
        )}

        {!hasPaymentMethod && isLastCoveredAppointment && (
          <StatusLabel intent="caution" className="mt-1">
            one left to schedule
          </StatusLabel>
        )}

        {!hasPaymentMethod && remainingSessions === 0 && (
          <StatusLabel intent="urgent" className="mt-1">
            none left to schedule
          </StatusLabel>
        )}
      </div>
    )
  }

  return null
}
