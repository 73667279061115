import { styled } from '@mui/material'

import { BelowTablet } from '@nuna/tunic'

export const Illustration = styled('img')`
  max-width: 325px;
  width: 100%;

  @media (${BelowTablet}) {
    max-width: 200px;
    margin-bottom: var(--margin-2);
    display: block;
  }
`
