import { styled } from '@mui/material'
import { noop } from 'lodash'
import { HTMLAttributes, useState } from 'react'

import { AddressEnrollmentStatus, AddressOwner, AddressType, type ProviderAddressesQuery } from '@nuna/api'
import { useIsAdmin } from '@nuna/auth'
import { addressService } from '@nuna/core'
import { styledUtils } from '@nuna/tunic'
import {
  Card,
  GhostButton,
  Grid,
  IconArmchair,
  IconCalendarNone,
  PersistentAlert,
  StatusLabel,
  Tooltip,
  greySet,
} from '@nuna/tunic'

import { useHasAvailabilityForLocations } from '../../../../hooks/useHasAvailabilityForLocations'
import { LocationForm } from './LocationForm'
import { LocationInsuranceIndicator } from './LocationInsuranceIndicator'

interface LocationCardProps extends HTMLAttributes<HTMLDivElement> {
  address?: ProviderAddressesQuery['providerAddresses'][0]
  onCancel?: () => void
  isTavaVirtual?: boolean
  isEmbedded?: boolean
}

export function LocationCard({
  address: addressData,
  onCancel = noop,
  isTavaVirtual = false,
  isEmbedded = false,
  ...props
}: LocationCardProps) {
  const isAdmin = useIsAdmin()
  const hasAvailability = useHasAvailabilityForLocations([addressData])
  const { address, addressId, primaryPracticeLocation, providerId } = addressData || {}
  const {
    name,
    internalName,
    addressType,
    addressLineOne,
    addressLineTwo,
    usedNightsAndWeekends,
    county,
    city,
    state,
    zipCode,
    adaWheelchairAccessible,
    adaPublicTransitAccessible,
    adaPublicParkingAccessible,
    owner,
  } = address || {
    name: '',
    internalName: '',
    addressLineOne: '',
    addressLineTwo: '',
    addressType: AddressType.ProviderHome,
    usedNightsAndWeekends: false,
    county: '',
    city: '',
    state: '',
    zipCode: '',
    adaWheelchairAccessible: false,
    adaPublicTransitAccessible: false,
    adaPublicParkingAccessible: false,
  }
  const [isEditing, setIsEditing] = useState(address ? false : true)
  const isProviderAddress = owner === AddressOwner.Provider
  const showInternalName = isProviderAddress && isAdmin && addressType === AddressType.ProviderPractice

  const canEdit = (address?.addressEnrollments ?? []).every(addressEnrollment =>
    [AddressEnrollmentStatus.Pending, AddressEnrollmentStatus.Denied].includes(addressEnrollment.payerStatus),
  )

  return (
    <StyledLocationCard $isEditing={isEditing} {...props}>
      <div className="p-3">
        {!isEditing ? (
          <Grid container justifyContent="center" alignItems="center">
            <Grid
              wrap="wrap"
              size={{
                xs: 12,
                md: 11,
              }}
            >
              <h5 className="body large text-medium text-teal v-align">
                {isProviderAddress && <IconArmchair className="mr-1" style={{ flexShrink: 0 }} />}
                {name}
                {primaryPracticeLocation && (
                  <StatusLabel className="ml-1" intent="information">
                    Primary
                  </StatusLabel>
                )}
                {isProviderAddress && (
                  <StatusIndicatorContainer className="v-align ml-2 gap-1">
                    <LocationInsuranceIndicator
                      className="indicator-wrapper"
                      providerId={providerId}
                      addressId={addressId}
                    />
                    <Tooltip content="Your in-person locations are ready for self-pay and EAP clients now.">
                      <span className="indicator-wrapper">
                        <PersistentAlert intent="success" small>
                          Self-pay & EAP
                        </PersistentAlert>
                      </span>
                    </Tooltip>
                  </StatusIndicatorContainer>
                )}
              </h5>
              {showInternalName && (
                <div className="caption pb-1">
                  Name used with insurance: <b>{internalName}</b>
                </div>
              )}
              {address && <p className="caption text-medium">{addressService.formatAddress(address)}</p>}
              {!hasAvailability && (
                <div className="caption text-secondary v-align">
                  <IconCalendarNone className="mr-xs" size={18} /> No availability set for this location
                </div>
              )}
            </Grid>
            <Grid
              size={{
                xs: 12,
                md: 1,
              }}
            >
              {!isTavaVirtual && (
                <GhostButton onClick={() => setIsEditing(true)}>{canEdit ? 'Edit' : 'Details'}</GhostButton>
              )}
            </Grid>
          </Grid>
        ) : (
          <LocationForm
            canEdit={canEdit}
            isTavaVirtual={isTavaVirtual}
            isEmbedded={isEmbedded}
            initialFormData={{
              addressId,
              name,
              addressLineOne,
              addressLineTwo,
              usedNightsAndWeekends,
              county,
              city,
              state,
              zipCode,
              adaCompliant: {
                wheelchair: adaWheelchairAccessible,
                publicTransport: adaPublicTransitAccessible,
                parking: adaPublicParkingAccessible,
              },
              enforceValidAddress: true,
              isValidAddress: addressLineOne ? true : false, // if we have an existing address, we assume it is valid
            }}
            onCompleted={() => {
              setIsEditing(false)
              onCancel()
            }}
          />
        )}
      </div>
    </StyledLocationCard>
  )
}
const { transientPropOptions } = styledUtils
const StyledLocationCard = styled(Card, transientPropOptions)<{ $isEditing: boolean }>`
  ${props => props.$isEditing && `background-color: ${greySet[5].hex};`}
`

const StatusIndicatorContainer = styled('div')`
  .indicator-wrapper {
    white-space: nowrap;
  }
`
