import { styled } from '@mui/material'
import { HTMLAttributes, useState } from 'react'
import { useMediaQuery } from 'react-responsive'

import { type Persona } from '@nuna/core'
import { BelowTablet, Grid, Logo, greySet, plumSet, salmonSet, tealSet, yarnBackground } from '@nuna/tunic'

import { getHelpfulItem } from '../util/helpfulBlurbs/helpfulBlurbs'
import { MentalHealthAwarenessMonthContent } from './MentalHealthAwarenessMonthContent'

interface Props extends HTMLAttributes<HTMLDivElement> {
  audience: Persona
  headerRight?: JSX.Element
}

export function QuoteContentWrapper({ audience, headerRight, children, ...props }: Props) {
  const [helpfulItem] = useState(getHelpfulItem(audience))

  const isMobile = useMediaQuery({ query: `(${BelowTablet})` })
  const logoColor = audience === 'client' ? 'salmon' : 'plum'
  return (
    <OuterWrapper container {...props}>
      <Grid
        size={{
          xs: 12,
          sm: 6,
        }}
      >
        <ContentOuterContainer>
          <Header className="v-align">
            <Logo color={logoColor} height={36} />
            {headerRight && <span className="ml-auto">{headerRight}</span>}
          </Header>
          <ContentContainer>{children}</ContentContainer>
        </ContentOuterContainer>
      </Grid>
      {!isMobile && ['tip', 'quote'].includes(helpfulItem.type) && (
        <QuoteOuterContainer audience={audience} size={{ sm: 6 }}>
          <QuoteContainer>
            <Mark audience={audience} className="serif">
              “
            </Mark>
            <Quote className="serif">{helpfulItem.body}”</Quote>
            {helpfulItem.attribution && <Attribution>- {helpfulItem.attribution}</Attribution>}
          </QuoteContainer>
        </QuoteOuterContainer>
      )}
      {!isMobile && ['mentalHealthMonthTip'].includes(helpfulItem.type) && (
        <QuoteOuterContainer audience={audience} size={{ sm: 6 }}>
          <QuoteContainer style={{ display: 'flex', justifyContent: 'center', width: '80%' }}>
            <MentalHealthAwarenessMonthContent helpfulItemContent={helpfulItem.body} />
          </QuoteContainer>
        </QuoteOuterContainer>
      )}
    </OuterWrapper>
  )
}

const OuterWrapper = styled(Grid)`
  min-height: 100vh;
  overflow-y: auto;
  width: 100%;
`

const ContentOuterContainer = styled('div')`
  display: flex;
  flex-direction: column;
  height: 100%;
  position: relative;
`

const Header = styled('header')`
  padding: var(--margin-2);
  width: 100%;
  position: absolute;
`

const ContentContainer = styled('main')`
  max-width: 80%;
  justify-self: center;
  align-self: center;
  flex: 1;
  display: flex;
  align-items: center;
`

const QuoteOuterContainer = styled(Grid)<{ audience: Persona }>`
  background-color: ${props => (props.audience === 'client' ? tealSet[5].hex : greySet[5].hex)};
  background-size: cover;
  background-image: url(${yarnBackground});
  display: flex;
  align-items: center;
  justify-content: center;
`

const QuoteContainer = styled('section')`
  width: 60%;
`
const Mark = styled('div')<{ audience: Persona }>`
  color: ${({ audience }) => (audience === 'client' ? salmonSet[50].hex : plumSet[50].hex)};
  font-size: 80px;
  line-height: 0;
`
const Quote = styled('p')`
  color: ${tealSet[90].hex};
  font-size: 24px;
`
const Attribution = styled('div')`
  color: ${greySet[90].hex};
  font-size: 20px;
`
