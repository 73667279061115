import * as Yup from 'yup'
import { Field, Formik } from 'formik'
import { isNil } from 'lodash'

import { ProviderCredentialingDataQuery, useProviderCredentialingDataQuery } from '@nuna/api'
import { formService } from '@nuna/core'
import { GhostButton, Radio, RadioGroup, Skeleton, TextField } from '@nuna/tunic'

import { useCAQHSetupContext } from '../../../context/CAQHSetupContext'
import { CAQHStepper } from '../CAQHStepper'

type YesNo = 'yes' | 'no'

interface ExistingInsuranceFormValues {
  acceptsInsurance: YesNo | null // dealing with boolean values in the Radio form fields is a pain so I use the string literal and convert to boolean on submit and convert to string in buildInitialValues
  existingInsurance: string[]
}

const schema = Yup.object().shape<ExistingInsuranceFormValues>({
  acceptsInsurance: Yup.string<YesNo>().nullable().required(formService.makeSelection),
  existingInsurance: Yup.array<string>().when('acceptsInsurance', {
    is: 'yes',
    then: (schema: Yup.ArraySchema<string>) =>
      schema
        .min(1)
        .test(
          'non-empty-string-check',
          'Oops, you forgot to provide the name of at least one insurance',
          (value: (string | undefined)[]) => value.some(v => v && v.trim() !== ''),
        ),
  }),
})

export function ExistingInsurance() {
  const { providerId } = useCAQHSetupContext()

  const { data } = useProviderCredentialingDataQuery({
    fetchPolicy: 'cache-and-network',
    variables: { providerId },
    skip: !providerId,
  })
  return (
    <div>
      <h2 className="h3 mb-5">Do You Currently Accept Insurance?</h2>

      {!data ? (
        <>
          <Skeleton height={5} />
          <Skeleton className="mt-2" height={5} />
          <Skeleton className="mt-2" height={5} />
        </>
      ) : (
        <Formik
          initialValues={buildInitialValues(data)}
          validationSchema={schema}
          onSubmit={() => {
            return
          }}
        >
          {formikProps => {
            const { values, errors, handleSubmit, setFieldValue, touched } = formikProps

            return (
              <form onSubmit={handleSubmit}>
                <RadioGroup
                  className="mb-5"
                  {...formService.composeHelperTextWithError('', errors.acceptsInsurance, touched.acceptsInsurance)}
                >
                  <Field as={Radio} name="acceptsInsurance" value="yes" checked={values.acceptsInsurance === 'yes'}>
                    Yes
                  </Field>
                  <Field as={Radio} name="acceptsInsurance" value="no" checked={values.acceptsInsurance === 'no'}>
                    Not yet
                  </Field>
                </RadioGroup>

                {values.acceptsInsurance === 'yes' && (
                  <section>
                    <h3 className="body text-bold mb-3">Insurance Accepted</h3>

                    {values.existingInsurance.map((_, index) => (
                      <Field
                        as={TextField}
                        className="mb-2"
                        name={`existingInsurance.${index}`}
                        label="Name of insurance"
                        {...(index === 0
                          ? formService.composeHelperTextWithError(
                              '',
                              errors.existingInsurance,
                              touched.existingInsurance,
                            )
                          : undefined)}
                      />
                    ))}

                    <GhostButton
                      className="mt-3"
                      variant="secondary"
                      onClick={() => setFieldValue('existingInsurance', [...values.existingInsurance, ''])}
                    >
                      Add Another
                    </GhostButton>
                  </section>
                )}

                <div className="mt-6">
                  <CAQHStepper
                    credentialingData={{
                      acceptsInsurance: values.acceptsInsurance === 'yes', // convert string to boolean
                      existingInsurance: values.existingInsurance.filter(value => value.trim().length > 0),
                    }}
                    responseChecker={response => !isNil(response.saveProviderCredentialingData?.acceptsInsurance)}
                  />
                </div>
              </form>
            )
          }}
        </Formik>
      )}
    </div>
  )
}

function buildInitialValues(data: ProviderCredentialingDataQuery): ExistingInsuranceFormValues {
  const acceptsInsurance = data.providerCredentialingData?.acceptsInsurance
  const existingInsurance = data.providerCredentialingData?.existingInsurance

  return {
    acceptsInsurance: isNil(acceptsInsurance) ? null : acceptsInsurance ? 'yes' : 'no', // convert boolean to string
    existingInsurance: isNil(existingInsurance) || existingInsurance.length === 0 ? [''] : existingInsurance,
  }
}
