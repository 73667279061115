import { Formik } from 'formik'

import { AccountSource, AccountSourceChannel, usePreconfigurePatientAccountMutation } from '@nuna/api'
import { useClientInviteDrawerSearchParams } from '@nuna/common'
import { Audience, errorService } from '@nuna/core'
import { toast } from '@nuna/tunic'

import { ClientInviteForm } from '../ClientInviteForm/ClientInviteForm'
import {
  ClientInviteFormValues,
  buildSaveValues,
  clientInviteFormInitialValues,
  clientInviteFormSchema,
} from '../shared'

interface ClientInviteProps {
  audience: Audience
  providerId: string
}

export function ClientInviteRequired({ audience, providerId }: ClientInviteProps) {
  const [preconfigurePatientAccount, { loading }] = usePreconfigurePatientAccountMutation()
  const { openClientInviteDrawer } = useClientInviteDrawerSearchParams()

  const handleSubmit = async (values: ClientInviteFormValues) => {
    try {
      const variables = buildSaveValues(values, '', '', providerId)
      const response = await preconfigurePatientAccount({
        variables,
      })

      const responsePatientData = response.data?.preconfigurePatientAccount

      if (responsePatientData?.patientId) {
        openClientInviteDrawer({ providerId, patientId: responsePatientData.patientId })

        toast.success('Account was created successfully')
      } else {
        throw new Error('Request succeeded but the patient was not created')
      }
    } catch (e) {
      console.error(e)
      toast.urgent(errorService.transformGraphQlError(e, 'Unable to create client'))
    }
  }

  const source = audience === 'admin' ? AccountSource.CareNavigator : clientInviteFormInitialValues.source
  const sourceChannel = audience === 'admin' ? AccountSourceChannel.Other : clientInviteFormInitialValues.sourceChannel

  return (
    <Formik
      initialValues={{
        ...clientInviteFormInitialValues,
        source: source,
        sourceChannel: sourceChannel,
      }}
      onSubmit={handleSubmit}
      validationSchema={clientInviteFormSchema}
    >
      <ClientInviteForm audience={audience} loading={loading} providerId={providerId} />
    </Formik>
  )
}
