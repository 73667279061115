import * as Yup from 'yup'
import moment from 'moment'
import { useMemo } from 'react'

import { OrganizationRelationship, PatientDetailsFragment, Role, useChangePatientNameMutation } from '@nuna/api'
import { useHasRole, useIsAdmin } from '@nuna/auth'
import { ProfileSection, ProviderClientPaymentStatusAlert } from '@nuna/common'
import {
  Avatar,
  ContextualAlert,
  CopyButton,
  Grid,
  IconInfo,
  StatusLabel,
  TextButtonLink,
  TextField,
} from '@nuna/tunic'

interface ClientProfileNameProps {
  patient:
    | Pick<
        PatientDetailsFragment,
        | 'id'
        | 'firstName'
        | 'lastName'
        | 'avatarUrl'
        | 'dob'
        | 'currentContractRelation'
        | 'intakeCompleted'
        | 'signupUrl'
        | 'paymentStatus'
      >
    | undefined
}

const validationSchema = Yup.object().shape({
  firstName: Yup.string().required('First Name is required'),
  lastName: Yup.string().required('Last Name is required'),
})

export function ClientProfileName({ patient }: ClientProfileNameProps) {
  const isAdmin = useIsAdmin()
  const isAdminOrProvider = useHasRole([Role.Admin, Role.Provider])

  const [changePatientNameMutation, { loading }] = useChangePatientNameMutation({ refetchQueries: ['PatientDetails'] })

  const labels = useMemo(() => {
    const labelValues = []
    if (patient?.dob && moment().diff(patient.dob, 'years') < 18) {
      labelValues.push('Minor')
    }

    if (patient?.currentContractRelation?.relationshipToOrganization) {
      labelValues.push(formatEmployerAssociation(patient.currentContractRelation.relationshipToOrganization))
    }

    return labelValues
  }, [patient?.dob, patient?.currentContractRelation?.relationshipToOrganization])

  if (!patient || loading) {
    return (
      <div className="v-align mb-3">
        <Avatar className="flex-static mr-1" size="md" />
        <h2 style={{ marginBottom: 0, minWidth: 400 }} className="h3 loading">
          Loading
        </h2>
      </div>
    )
  }

  const showStatusLabels = isAdminOrProvider && labels.length > 0

  return (
    <>
      <div className="v-align mb-3">
        <Avatar className="flex-static mr-1" url={patient.avatarUrl} size="md" />

        <ProfileSection
          className="full-width"
          showTopBorder={false}
          displayWidth="full"
          renderForm={({ values, handleChange, handleBlur, touched, errors }) => (
            <div className="mr-5">
              <Grid container spacing={2}>
                <Grid
                  size={{
                    xs: 12,
                    md: 6,
                  }}
                >
                  <TextField
                    onChange={handleChange}
                    onBlur={handleBlur}
                    name="firstName"
                    label="First Name"
                    value={values.firstName}
                    error={(errors.firstName && touched.firstName) as boolean}
                    helperText={touched.firstName && errors.firstName ? errors.firstName : null}
                  />
                </Grid>

                <Grid
                  size={{
                    xs: 12,
                    md: 6,
                  }}
                >
                  <TextField
                    onChange={handleChange}
                    onBlur={handleBlur}
                    name="lastName"
                    label="Last Name"
                    value={values.lastName}
                    error={(errors.lastName && touched.lastName) as boolean}
                    helperText={touched.lastName && errors.lastName ? errors.lastName : null}
                  />
                </Grid>
              </Grid>
            </div>
          )}
          initialValues={{ firstName: patient.firstName, lastName: patient.lastName }}
          validationSchema={validationSchema}
          handleSubmit={async values => {
            await changePatientNameMutation({ variables: { patientId: patient.id, ...values } })
          }}
          showEditButton={isAdmin}
          renderDisplayValue={
            <>
              <h2 style={{ marginBottom: 0 }} className="h3">
                {patient.firstName} {patient.lastName}
              </h2>
              {showStatusLabels && (
                <div className="mt-1 v-align">
                  <span className="v-align" style={{ maxWidth: 260 }}>
                    <label className="text-secondary text-bold caption mr-1">Client ID:</label>
                    <span className="flex-remaining-space overflow-hidden">
                      <CopyButton value={patient.id} />
                    </span>
                  </span>
                  {labels.map(label => (
                    <StatusLabel key="label" className="ml-1">
                      {label}
                    </StatusLabel>
                  ))}
                </div>
              )}

              {!patient.intakeCompleted && patient.signupUrl && isAdminOrProvider && (
                <ContextualAlert
                  className="mt-2 mr-1"
                  icon={<IconInfo />}
                  intent="information"
                  role="alert"
                  scribbleType="default"
                >
                  {patient.firstName} hasn't yet completed intake. They won't be able to join their first session until
                  they complete it. You can send them their{' '}
                  <TextButtonLink to={patient.signupUrl || ''} variant="primary">
                    link
                  </TextButtonLink>{' '}
                  again if they can't find it in their email, or they can reset their password.
                </ContextualAlert>
              )}
            </>
          }
        />
      </div>
      {!!patient.paymentStatus && (
        <ProviderClientPaymentStatusAlert scene={'clientProfile'} paymentStatus={patient.paymentStatus} />
      )}
    </>
  )
}

function formatEmployerAssociation(relationship: OrganizationRelationship | null | undefined) {
  if (!relationship) return ''

  if (relationship === OrganizationRelationship.SpousePartner) return 'Spouse of Employee'
  if (relationship === OrganizationRelationship.Child) return 'Child of Employee'

  return 'Employee'
}
