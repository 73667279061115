import { noop } from 'lodash'

import { specialtiesService } from '@nuna/core'

import { SpecialtiesSectionBase, SpecialtiesSectionProps } from './SpecialtiesSectionBase'

export function LanguagesSpokenSection({ specialties, id, refetch = noop }: SpecialtiesSectionProps) {
  return (
    <SpecialtiesSectionBase
      headingText="Languages Spoken"
      description="We'll use your selection to match you with your ideal clients. Please select languages that you are uniquely experienced with helping."
      selectedSpecialties={specialties}
      specialtiesFilter={specialtiesService.getLanguagesSpokenSpecialties}
      refetch={refetch}
      providerId={id}
    />
  )
}
