import { styled } from '@mui/material'
import moment from 'moment'
import { ReactNode } from 'react'

import { AppointmentCard, AppointmentCardProps, AppointmentCardStatus } from '@nuna/appointment'
import { csx, error, greySet, salmonSet } from '@nuna/tunic'

interface Props extends Omit<AppointmentCardProps, 'appointmentStatus'> {
  form: ReactNode
  canChargeLateFee: boolean
}

export function RescheduleAppointmentCard({
  form,
  cancelPolicyStatus,
  audience,
  appointment,
  canChargeLateFee,
}: Props) {
  if (!('patient' in appointment)) return null
  const { patient, provider } = appointment
  if (!('id' in provider) || !('id' in patient)) return null

  const getHeaderText = () => {
    return audience === 'admin' ? (
      <span>
        {patient.firstName} and {provider.firstName}
      </span>
    ) : (
      <>
        {patient.firstName} {patient.lastName}
      </>
    )
  }
  return (
    <AppointmentCard
      appointment={appointment}
      cancelPolicyStatus={cancelPolicyStatus}
      audience={audience}
      appointmentStatus={AppointmentCardStatus.Canceling}
      detail={
        <DetailContainer className={csx({ 'border-bottom': !canChargeLateFee })}>
          <div className={csx([{ 'p-2': audience === 'admin' }, 'v-align'])}>
            <AppointmentCard.Avatar audience={audience} provider={provider} client={patient} />
            <span className={csx([{ 'p-2': audience === 'admin' }, 'large text-medium name'])}>{getHeaderText()}</span>
          </div>
          {canChargeLateFee && (
            <div className="late-banner italic px-2">
              <b>{countdown(appointment.startDatetime)}</b> remain until session
            </div>
          )}
        </DetailContainer>
      }
      boot={form}
    />
  )
}

const DetailContainer = styled('div')`
  &.border-bottom {
    border-bottom: 1px solid ${greySet[15].hex};
  }
  .name {
    color: ${greySet[80].hex};
  }
  .late-banner {
    background-color: ${error};
    color: ${salmonSet[30].hex};
    padding-top: 10px;
    padding-bottom: 10px;
  }
`

function countdown(startDatetime: string) {
  const now = moment()
  const start = moment(startDatetime)
  if (!start.isValid()) {
    return ''
  }
  const hours = start.diff(now, 'hours')
  const minutes = start.diff(now, 'minutes') - hours * 60
  return [hours, minutes.toString().padStart(2, '0')].join(':')
}
