import * as Yup from 'yup'
import { useFormikContext } from 'formik'
import { HTMLAttributes, useEffect } from 'react'

import {
  ClientInsurancePayerFragment,
  InsurancePayer,
  PatientIntakeInsurancePolicy,
  PaymentPreference,
  RelationshipToSubscriber,
  usePatientCoverageQuery,
} from '@nuna/api'
import { Audience, coverageService, formService } from '@nuna/core'
import { Checkbox, ChipGroup, Radio } from '@nuna/tunic'

import { CoverageFormValues, VerificationStatus } from '../CoverageForm'
import { CoverageFormPatient, PendingPatient } from '../context/CoverageFormContext'
import { InsuranceForm } from './InsuranceForm'
import { CoverageSectionHeading } from './SharedStyles'

const { composeNestedHelperTextWithError, oopsRequired } = formService

type PatientInsurancePolicy = Omit<PatientIntakeInsurancePolicy, 'insurancePayer'> & {
  insurancePayer: Omit<InsurancePayer, 'insurancePayerConfiguration'> | null
}

export interface InsuranceCoverageFormValues {
  insurancePayer: ClientInsurancePayerFragment | null
  otherProvider: string
  memberId: string
  policyChecked: boolean | null
  isDependent: boolean | null
  insuredFirstName: string
  insuredMiddleName: string
  insuredLastName: string
  insuredDob: string
  relationshipToSubscriber: RelationshipToSubscriber | null
  insurancePolicy: PatientInsurancePolicy | null
  insuranceStatus: VerificationStatus | null
  failureType: string | null
  failureReason: string | null
}

export const insuranceCoverageSchema = Yup.object().shape<InsuranceCoverageFormValues>({
  insurancePayer: Yup.object<ClientInsurancePayerFragment>()
    .nullable()
    .when('insurancePolicy', {
      is: null,
      then: Yup.object<ClientInsurancePayerFragment>().required(oopsRequired('insurance')),
    }),
  otherProvider: Yup.string(),
  memberId: Yup.string().when('insurancePolicy', {
    is: null,
    then: Yup.string().required(oopsRequired('member id')),
  }),
  policyChecked: Yup.boolean().nullable().when('insurancePayer.supportsRealtimeVerification', {
    is: false,
    then: Yup.boolean().required(),
    otherwise: Yup.boolean().notRequired(),
  }),
  isDependent: Yup.boolean().nullable().required('Oops, you forgot to indicate the policy holder'),
  insuredFirstName: Yup.string().when('insurancePolicy', {
    is: null,
    then: Yup.string().required('Oops, you forgot the first name'),
  }),
  insuredLastName: Yup.string().when('insurancePolicy', {
    is: null,
    then: Yup.string().required('Oops, you forgot the last name'),
  }),
  insuredDob: Yup.string().when(['isDependent', 'insurancePolicy'], {
    is: (isDependent, insurancePolicy) => isDependent && !insurancePolicy,
    then: Yup.string().required('Oops, you forgot the date of birth'),
  }),
  relationshipToSubscriber: Yup.mixed<RelationshipToSubscriber>()
    .nullable()
    .when(['isDependent', 'insurancePolicy'], {
      is: (isDependent, insurancePolicy) => isDependent && !insurancePolicy,
      then: Yup.mixed<RelationshipToSubscriber>().required(
        'Oops, you forgot to indicate your relationship to the subscriber',
      ),
    }),
  insuredMiddleName: Yup.string(),
  insurancePolicy: Yup.object<PatientInsurancePolicy>().nullable(),
  insuranceStatus: Yup.string<VerificationStatus>().nullable(),
  failureType: Yup.string().nullable(),
  failureReason: Yup.string().nullable(),
})

export const insuranceInitialValues: InsuranceCoverageFormValues = {
  insurancePayer: null,
  otherProvider: '',
  memberId: '',
  policyChecked: null,
  isDependent: false,
  insuredFirstName: '',
  insuredMiddleName: '',
  insuredLastName: '',
  insuredDob: '',
  relationshipToSubscriber: null,
  insurancePolicy: null,
  insuranceStatus: null,
  failureType: null,
  failureReason: null,
}

interface InsuranceCoverageFormProps extends HTMLAttributes<HTMLDivElement> {
  saveButton?: boolean
  providerId?: string
  audience: Audience
  inDrawer: boolean
  state: string
  patient: CoverageFormPatient | PendingPatient
}

export function InsuranceCoverageForm({
  saveButton = false,
  providerId,
  audience,
  patient,
  inDrawer,
  state,
  ...props
}: InsuranceCoverageFormProps) {
  const patientId = 'id' in patient ? patient.id : undefined
  const { data: coverageData } = usePatientCoverageQuery({
    variables: { patientId: patientId ?? '' },
    skip: !patientId,
  })
  const sponsorCompanyId = coverageData
    ? coverageService.getCoverageByType(coverageData.patientCoverage, PaymentPreference.Employer)?.details.companyId
    : undefined

  const { values, errors, touched, setFieldValue, handleChange } = useFormikContext<CoverageFormValues>()

  useEffect(() => {
    if (
      values.insuranceValues?.isDependent === false &&
      patient &&
      !values.insuranceValues.insuredFirstName &&
      !values.insuranceValues.insuredLastName
    ) {
      setFieldValue('insuranceValues.insuredFirstName', patient.firstName)
      setFieldValue('insuranceValues.insuredLastName', patient.lastName)
    }

    if (
      values.insuranceValues?.isDependent === true &&
      patient &&
      values.insuranceValues.insuredFirstName === patient.firstName &&
      values.insuranceValues.insuredLastName === patient.lastName
    ) {
      setFieldValue('insuranceValues.insuredFirstName', '')
      setFieldValue('insuranceValues.insuredLastName', '')
    }
  }, [
    patient,
    setFieldValue,
    values.insuranceValues?.insuredFirstName,
    values.insuranceValues?.insuredLastName,
    values.insuranceValues?.isDependent,
  ])

  const handleCheckboxChange: React.ChangeEventHandler<HTMLInputElement> = ev => {
    if (ev.target.checked) {
      setFieldValue('insuranceValues.policyChecked', ev.target.checked)
    } else {
      setFieldValue('insuranceValues.policyChecked', null)
    }
  }

  if (!values.insuranceValues) {
    console.error('values.insuranceValues is null')
    return null
  }

  return (
    <div {...props}>
      <CoverageSectionHeading isCheckout={inDrawer} className="mt-2">
        Who is the primary policy holder?
      </CoverageSectionHeading>

      <ChipGroup
        className="mb-4 ml-1"
        {...composeNestedHelperTextWithError('', errors, touched, 'insuranceValues', 'isDependent')}
      >
        <Radio
          dataTestId="coverage-insurance-self"
          checked={values.insuranceValues.isDependent === false}
          value="false"
          onChange={() => setFieldValue('insuranceValues.isDependent', false)}
        >
          {audience === 'client' ? 'Self' : 'Client'}
        </Radio>
        <Radio
          dataTestId="coverage-insurance-dependent"
          checked={values.insuranceValues.isDependent === true}
          value="true"
          onChange={() => setFieldValue('insuranceValues.isDependent', true)}
        >
          Someone else
        </Radio>
      </ChipGroup>

      {values.insuranceValues && (
        <>
          {values.insuranceValues.isDependent === false && (
            <InsuranceForm
              isDependent={false}
              insuredFirstName={values.insuranceValues.insuredFirstName}
              insuredLastName={values.insuranceValues.insuredLastName}
              insurancePayer={values.insuranceValues.insurancePayer}
              memberId={values.insuranceValues.memberId}
              onChange={handleChange}
              isCheckout={inDrawer}
              state={state}
              saveButton={saveButton}
              providerId={providerId}
              sponsorCompanyId={sponsorCompanyId}
            />
          )}

          {values.insuranceValues.isDependent === true && (
            <InsuranceForm
              isDependent
              insuredFirstName={values.insuranceValues.insuredFirstName}
              insuredLastName={values.insuranceValues.insuredLastName}
              insurancePayer={values.insuranceValues.insurancePayer}
              insuredDob={values.insuranceValues.insuredDob}
              relationshipToSubscriber={values.insuranceValues.relationshipToSubscriber}
              memberId={values.insuranceValues.memberId}
              onChange={handleChange}
              isCheckout={inDrawer}
              state={state}
              saveButton={saveButton}
              providerId={providerId}
              sponsorCompanyId={sponsorCompanyId}
            />
          )}

          {values.insuranceValues.insurancePayer?.supportsRealtimeVerification === false && (
            <Checkbox
              checked={!!values.insuranceValues.policyChecked}
              className="mt-4"
              name="verifyPolicy"
              onChange={handleCheckboxChange}
            >
              I've doubled checked insurance policy details for accuracy
            </Checkbox>
          )}
        </>
      )}
    </div>
  )
}
