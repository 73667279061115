import * as Yup from 'yup'
import { getIn } from 'formik'
import { omit, pick } from 'lodash'
import moment from 'moment'
import { formatPhoneNumber } from 'react-phone-number-input'

import {
  Patient,
  PatientDetailsQuery,
  StateAbbreviation,
  useResendMinorConsentFormMutation,
  useUpdateClientGuardianInfoMutation,
} from '@nuna/api'
import { useIsAdmin } from '@nuna/auth'
import { AddressForm, ProfileSection, ReadOnlyProfileSection } from '@nuna/common'
import { addressService, errorService, formService } from '@nuna/core'
import {
  DOBTextField,
  GhostButton,
  Grid,
  InfoWithHeading,
  PhoneTextField,
  TextField,
  phoneUSAValidationRegex,
  toast,
} from '@nuna/tunic'

const validationSchema = Yup.object().shape({
  id: Yup.string().required(),
  parentGuardianEmail: Yup.string().email('Invalid email address').required(),
  parentGuardianBillingInfo: Yup.object()
    .shape({
      firstName: Yup.string().required('First Name is required'),
      lastName: Yup.string().required('Last Name is required'),
      dob: Yup.date()
        .required('Date of birth is required')
        .min(moment().subtract('125', 'years').toDate(), 'Date of birth is out of range')
        .max(moment().subtract('18', 'years').toDate(), 'Guardian must be 18+ years old')
        .typeError('Date is invalid'),
      phone: Yup.string()
        .matches(phoneUSAValidationRegex, 'Please enter a valid US phone number')
        .nullable()
        .notRequired(),
    })
    .default(null)
    .nullable()
    .notRequired(),
  // manually handle address to be able to use nested form component
  addressLineOne: Yup.string().nullable().notRequired(),
  addressLineTwo: Yup.string().nullable().notRequired(),
  city: Yup.string().when('addressLineOne', {
    is: Boolean,
    then: Yup.string().required(formService.oopsRequired('city')),
    else: Yup.string().nullable().notRequired(),
  }),
  county: Yup.string().nullable().notRequired(),
  state: Yup.mixed().when('addressLineOne', {
    is: Boolean,
    then: Yup.mixed().oneOf(Object.values(StateAbbreviation)).required(formService.oopsRequired('state')),
    else: Yup.string().nullable().notRequired(),
  }),
  zipCode: Yup.string().when('addressLineOne', {
    is: Boolean,
    then: Yup.string().required(formService.oopsRequired('zip code')),
    else: Yup.string().nullable().notRequired(),
  }),
})

type PersonalInfoGuardian = Pick<Patient, 'id' | 'parentGuardianEmail' | 'parentGuardianBillingInfo'>

interface PatientProfileGuardianInfoProps {
  patient?: PatientDetailsQuery['patient']
}

const heading = 'Guardian Info'

export function ClientProfileGuardianInfo({ patient }: PatientProfileGuardianInfoProps) {
  const isAdmin = useIsAdmin()
  const [updateClientGuardianInfo, { loading }] = useUpdateClientGuardianInfoMutation({
    refetchQueries: ['PatientDetails'],
  })
  const [resendMinorConsentForm, { loading: sendingConsentForm }] = useResendMinorConsentFormMutation()

  if (!patient) {
    return <ReadOnlyProfileSection isLoading heading={heading} renderDisplayValue={null} />
  }

  const handleResendMinorConsentForm = async () => {
    try {
      const response = await resendMinorConsentForm({ variables: { patientId: patient.id } })
      toast.success(`Resent minor consent form to ${response.data?.resendMinorConsentForm}`)
    } catch (e) {
      console.error(e)
      toast.urgent(errorService.transformUserGraphqlError(e, 'Unable to resend minor consent form'))
    }
  }

  const initialValues: Pick<PersonalInfoGuardian, 'id' | 'parentGuardianEmail' | 'parentGuardianBillingInfo'> & {
    addressLineOne?: string | null
    addressLineTwo?: string | null
    city?: string | null
    county?: string | null
    state?: string | null
    zipCode?: string | null
  } & Record<string, unknown> = {
    id: patient.id,
    parentGuardianEmail: patient.parentGuardianEmail,
    parentGuardianBillingInfo:
      patient.parentGuardianBillingInfo && omit(patient.parentGuardianBillingInfo, ['address', '__typename']),
    ...(patient.parentGuardianBillingInfo?.address ?? {
      addressLineOne: '',
      addressLineTwo: '',
      city: '',
      state: '',
      zipCode: '',
    }),
  }

  const minorConsentComplete = patient.startIntakePermitted
  const hasBillingInfo = !!patient.parentGuardianBillingInfo?.firstName
  const billing = patient.parentGuardianBillingInfo

  return (
    <ProfileSection
      heading={heading}
      description={null}
      isLoading={loading}
      initialValues={initialValues}
      validationSchema={validationSchema}
      handleSubmit={async values => {
        try {
          await updateClientGuardianInfo({
            variables: {
              patientId: patient.id,
              parentGuardianEmail: values.parentGuardianEmail,
              ...(values.parentGuardianBillingInfo?.firstName && {
                parentGuardianBillingInfo: {
                  ...values.parentGuardianBillingInfo,
                  ...(values.addressLineOne && {
                    address: {
                      ...pick(values, ['addressLineOne', 'addressLineTwo', 'city', 'zipCode']),
                      state: values.state as StateAbbreviation,
                    },
                  }),
                },
              }),
            },
          })

          toast.success('Updated guardian info successfully')
        } catch (e) {
          console.error(e)
          toast.urgent('Unable to update guardian info')
        }
      }}
      showEditButton={isAdmin}
      displayWidth="full"
      renderDisplayValue={
        <>
          <InfoWithHeading
            heading="Email"
            info={patient.parentGuardianEmail}
            tertiaryInfo={
              !minorConsentComplete ? (
                <GhostButton onClick={handleResendMinorConsentForm} isLoading={sendingConsentForm}>
                  Resend Minor Consent Form
                </GhostButton>
              ) : null
            }
          />
          {hasBillingInfo && (
            <>
              <InfoWithHeading
                heading="Name"
                info={hasBillingInfo ? `${billing?.firstName} ${billing?.lastName}` : undefined}
              />
              <InfoWithHeading
                heading="Birthdate"
                info={billing ? moment(billing?.dob).format('MMMM DD, YYYY') : undefined}
                secondaryInfo={billing ? `${moment().diff(billing?.dob, 'years')} years old` : undefined}
              />
              <InfoWithHeading heading="Phone" info={formatPhoneNumber(billing?.phone ?? '')} />
              <InfoWithHeading
                heading="Address"
                info={billing?.address ? addressService.formatAddress(billing.address) : undefined}
              />
            </>
          )}
        </>
      }
      renderForm={({ values, handleChange, handleBlur, setFieldValue, touched, errors }) => {
        const hasError = (field: string) => getIn(errors, field) && getIn(touched, field)
        const helperText = (field: string) =>
          getIn(touched, field) && getIn(errors, field) ? getIn(errors, field) : null

        return (
          <div className="mr-5">
            <Grid container spacing={2}>
              <Grid
                size={{
                  xs: 12,
                  md: 12,
                }}
              >
                <TextField
                  onChange={handleChange}
                  onBlur={handleBlur}
                  name="parentGuardianEmail"
                  label="Email"
                  value={values.parentGuardianEmail}
                  error={(errors.parentGuardianEmail && touched.parentGuardianEmail) as boolean}
                  helperText={
                    touched.parentGuardianEmail && errors.parentGuardianEmail ? errors.parentGuardianEmail : null
                  }
                />
              </Grid>
              {minorConsentComplete && (
                <>
                  <Grid
                    size={{
                      xs: 12,
                      md: 6,
                    }}
                  >
                    <TextField
                      onChange={handleChange}
                      onBlur={handleBlur}
                      name="parentGuardianBillingInfo.firstName"
                      label="First Name"
                      value={values.parentGuardianBillingInfo?.firstName}
                      error={hasError('parentGuardianBillingInfo.firstName')}
                      helperText={helperText('parentGuardianBillingInfo.firstName')}
                    />
                  </Grid>
                  <Grid
                    size={{
                      xs: 12,
                      md: 6,
                    }}
                  >
                    <TextField
                      onChange={handleChange}
                      onBlur={handleBlur}
                      name="parentGuardianBillingInfo.lastName"
                      label="Last Name"
                      value={values.parentGuardianBillingInfo?.lastName}
                      error={hasError('parentGuardianBillingInfo.lastName')}
                      helperText={helperText('parentGuardianBillingInfo.lastName')}
                    />
                  </Grid>
                  <Grid
                    size={{
                      xs: 12,
                      md: 6,
                    }}
                  >
                    <DOBTextField
                      name="parentGuardianBillingInfo.dob"
                      label="Date of Birth"
                      value={values.parentGuardianBillingInfo?.dob}
                      placeholder="MM/DD/YYYY"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      error={hasError('parentGuardianBillingInfo.dob')}
                      helperText={helperText('parentGuardianBillingInfo.dob')}
                    />
                  </Grid>
                  <Grid
                    size={{
                      xs: 12,
                      md: 6,
                    }}
                  >
                    <PhoneTextField
                      name="parentGuardianBillingInfo.phone"
                      label="Phone"
                      value={values.parentGuardianBillingInfo?.phone}
                      onChange={value => setFieldValue('parentGuardianBillingInfo.phone', value)}
                      onBlur={handleBlur}
                      error={hasError('parentGuardianBillingInfo.phone')}
                      helperText={helperText('parentGuardianBillingInfo.phone')}
                      fullWidth
                    />
                  </Grid>
                </>
              )}
            </Grid>
            {minorConsentComplete && <AddressForm gridSpacing={2} />}
          </div>
        )
      }}
    />
  )
}
