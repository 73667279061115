import { useState } from 'react'

import { AssessmentBundleType } from '@nuna/api'
import { IconAssessment, IconButtonLabel, Menu, MenuItem, MenuItemStack } from '@nuna/tunic'

interface AssessmentButtonProps {
  onSelect: (bundleType: AssessmentBundleType) => void
}

export function AssessmentButton({ onSelect }: AssessmentButtonProps) {
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null)

  return (
    <>
      <IconButtonLabel
        tooltip="Send assessment"
        customSize={35}
        variant="secondary"
        style={{ position: 'relative' }}
        onClick={e => setAnchorEl(e.currentTarget)}
      >
        <IconAssessment size={18} />
      </IconButtonLabel>
      <Menu
        open={!!anchorEl}
        anchorEl={anchorEl}
        anchorOrigin={{
          horizontal: 'right',
          vertical: 'bottom',
        }}
        onClose={() => setAnchorEl(null)}
      >
        <MenuItemStack>
          <MenuItem
            button={true}
            onClick={() => {
              onSelect(AssessmentBundleType.MentalHealthAssessment)
              setAnchorEl(null)
            }}
          >
            PHQ 9 + GAD 7 (Mental Health Assessment)
          </MenuItem>
          <MenuItem
            button={true}
            onClick={() => {
              onSelect(AssessmentBundleType.SuicidalityAssessment)
              setAnchorEl(null)
            }}
          >
            C-SRSS (Suicidality Assessment)
          </MenuItem>
        </MenuItemStack>
      </Menu>
    </>
  )
}
