import { DailyAudio, useLocalSessionId, useParticipantIds, useScreenShare } from '@daily-co/daily-react'
import { styled } from '@mui/material'

import { BelowTablet } from '@nuna/tunic'

import { useVideoCallContext } from '../../context/VideoCallContext'
import { ABOVE_VIDEO } from '../../util/zIndexes'
import { CollapsibleThumbnail } from './components/CollapsibleThumbnail'
import { FloatingVideo } from './components/FloatingVideo'
import { Participant } from './components/Participant'
import { VideoButtons } from './components/VideoButtons'

export function Call() {
  const participantIds = useParticipantIds({ filter: 'remote' })
  const localSessionId = useLocalSessionId()
  const { expectedUsers } = useVideoCallContext()
  const { screens } = useScreenShare()

  const remoteScreens = screens.filter(screen => !screen.local)

  const isScreenShareActive = remoteScreens.length > 0

  return (
    <>
      <ThumbnailsContainer>
        {isScreenShareActive &&
          participantIds.map(participantId => <FloatingVideo isLocal={false} sessionId={participantId} />)}

        <CollapsibleThumbnail>
          <FloatingVideo isLocal sessionId={localSessionId} />
        </CollapsibleThumbnail>
      </ThumbnailsContainer>

      <Participants>
        {/* Theoretically we will only have 1 screen sharer at a time, but this would gracefully render multiple into a grid if it ever happens */}
        {isScreenShareActive
          ? remoteScreens.map(screen => (
              <Participant key={screen.session_id} sessionId={screen.session_id} isScreenShare />
            ))
          : expectedUsers.map(user => <Participant key={user.loginId} user={user} />)}
      </Participants>

      <VideoButtons />
      <DailyAudio />
    </>
  )
}

const Participants = styled('div')`
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  flex-wrap: wrap;
`

const ThumbnailsContainer = styled('div')`
  display: flex;
  position: absolute;
  right: 1.5rem;
  top: 1.5rem;
  z-index: ${ABOVE_VIDEO};
  flex-direction: column;
  gap: var(--spacing-2);

  @media (${BelowTablet}) {
    flex-direction: row;
    justify-content: flex-end;
    padding: 0 1rem;
    right: 0;
    top: 1rem;
  }
`
