import { flatten } from 'lodash'
import { Column } from 'react-table'

import { DeEligibilizationBreakdown, StagedRosterStat } from '@nuna/api'
import { DataTable, DataTableState } from '@nuna/data-table'

interface DeEligibilizationBreakdownRow {
  id: string
  reason: string
  count: string
}

function transformBreakdownDependentReasonToReadableType(breakdownReason: string) {
  switch (breakdownReason) {
    case 'DEPENDENT_AGE_CUTOFF_MET':
      return 'Dependent aged out'
    case 'UNDERAGE_DEPENDENT':
      return 'Dependent is underage'
    case 'CONTRACT_DOESNT_ALLOW_DEPENDENTS':
      return 'Company does not allow dependents'
    case 'SPONSORING_EMPLOYEE_REMOVED':
      return "Dependent's sponsoring employee removed"
    default:
      return breakdownReason
  }
}

function transformBreakdownEmploymentTypeReasonToReadableType(breakdownReason: string) {
  switch (breakdownReason) {
    case 'full_time':
      return 'Employee is full time'
    case 'part_time':
      return 'Employee is part time'
    case 'intern':
      return 'Employee is an intern'
    case 'individual_contractor':
      return 'Employee is an individual contractor'
    default:
      return breakdownReason
  }
}

export function DeEligibilizationBreakdownTable({ breakdown }: { breakdown: DeEligibilizationBreakdown }) {
  const INITIAL_TABLE_STATE: DataTableState<DeEligibilizationBreakdownRow> = {
    sortBy: [
      {
        id: 'reason',
        desc: false,
      },
    ],
  }

  const rowData = flatten(
    Object.entries({
      dependent: breakdown.dependent.map(wrapper => ({
        ...wrapper,
        type: transformBreakdownDependentReasonToReadableType(wrapper.type),
      })),
      employmentStatus: breakdown.employmentStatus.map(wrapper => ({
        ...wrapper,
        type: 'Employment deactivated',
      })),
      employmentType: breakdown.employmentType.map(wrapper => ({
        ...wrapper,
        type: transformBreakdownEmploymentTypeReasonToReadableType(wrapper.type),
      })),
      homeState: breakdown.homeState.map(wrapper => ({
        ...wrapper,
        type: `Moved to an ineligible region — ${wrapper.type}`,
      })),
    }).map(([key, reasons]) => {
      return reasons.map((value: StagedRosterStat) => ({
        id: `${key}.${value.type}`,
        reason: value.type,
        count: value.droppedCount.toString(),
      }))
    }),
  )

  const columns: Column<DeEligibilizationBreakdownRow>[] = [
    {
      Header: 'Reason',
      accessor: 'reason',
    },
    {
      Header: 'Count',
      accessor: 'count',
    },
  ]
  return (
    <DataTable
      columns={columns}
      rowData={rowData}
      loading={false}
      initialState={INITIAL_TABLE_STATE}
      getUniqueId={row => row.id}
    />
  )
}
