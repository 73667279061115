import { ReactNode, createContext, useContext } from 'react'

interface ContextValues {
  selectedOption: string | null
  setSelectedOption: (d: string) => void
}

const SignupContext = createContext<ContextValues | null>(null)

interface Props {
  children: ReactNode
}

export function SignupContextProvider({ ...props }: Props) {
  const setSelectedOption = (selectedOption: string) => localStorage.setItem('signupAccountOption', selectedOption)

  const values = {
    selectedOption: localStorage.getItem('signupAccountOption'),
    setSelectedOption: (option: string) => setSelectedOption(option),
  }

  return <SignupContext.Provider {...props} value={values} />
}

export function useSignupContext() {
  const context = useContext(SignupContext)
  if (!context) {
    throw new Error('useSignupContext must be used within a SignupContextProvider')
  }
  return context
}
