import { styled } from '@mui/material'
import { isNil, some } from 'lodash'
import { ReactNode } from 'react'

import { AssessmentBundleType } from '@nuna/api'
import { AssessmentButton, INSERT_ASSESSMENT_COMMAND } from '@nuna/assessments'
import { Audience } from '@nuna/core'
import { Card, Collapse, RichTextArea, RichTextToolbar, TextButton, csx, error, plumSet, tealSet } from '@nuna/tunic'

import { useMessageComposerContext } from '../../context/MessageComposerContext'
import { AttachmentButton } from './components/AttachmentButton'
import { PendingConversationAttachment, updateAttachmentStatus } from './components/MessageAttachment'

interface MessageComposerProps {
  audience: Audience
  boot?: ReactNode
  toolbarRightSlot?: ReactNode
  autoCollapse?: boolean
  placeholder?: string
  error?: boolean
  disableAssessments?: boolean
}

export function MessageComposer({
  audience,
  autoCollapse = true,
  boot = null,
  toolbarRightSlot = null,
  error = false,
  placeholder = 'Start typing a new message...',
  disableAssessments = false,
}: MessageComposerProps) {
  const { editor, pendingAttachments, setPendingAttachments, isContentEmpty } = useMessageComposerContext()
  const isTherapist = audience === 'provider'
  const handleSelectFiles = (files: File[]) => {
    const newPendingAttachments = files.map(file => {
      return { file, status: 'pending' as const }
    })

    setPendingAttachments([...pendingAttachments, ...newPendingAttachments])
  }

  const handleSelectAssessment = (bundleType: AssessmentBundleType) => {
    editor.dispatchCommand(INSERT_ASSESSMENT_COMMAND, {
      id: null,
      type: bundleType,
    })
  }

  return (
    <ShadowBuffer>
      <Container audience={audience}>
        <ComposerContainer className={csx({ error })}>
          <RichTextArea
            style={{ maxHeight: '50vh', overflow: 'auto' }}
            error={error}
            className="fs-mask"
            placeholder={placeholder}
          />

          {pendingAttachments.length > 0 && (
            <div className="mt-1">
              {pendingAttachments.map(pendingAttachment => (
                <PendingConversationAttachment
                  key={`${pendingAttachment.file.name}${
                    isNil(pendingAttachment.retryKey) ? '' : pendingAttachment.retryKey
                  }`}
                  pendingAttachment={pendingAttachment}
                  onRemoveClick={() => {
                    setPendingAttachments(pendingAttachments.filter(attachedFile => attachedFile !== pendingAttachment))
                  }}
                  onUploadStart={uploadingPendingAttachment => {
                    setPendingAttachments(updateAttachmentStatus(uploadingPendingAttachment, 'uploading'))
                  }}
                  onAttachmentStart={uploadingPendingAttachment => {
                    setPendingAttachments(updateAttachmentStatus(uploadingPendingAttachment, 'attaching'))
                  }}
                  onError={erroredPendingAttachment => {
                    setPendingAttachments(updateAttachmentStatus(erroredPendingAttachment, 'error'))
                  }}
                  onSuccess={successfulPendingAttachment => {
                    setPendingAttachments(updateAttachmentStatus(successfulPendingAttachment, 'success'))
                  }}
                />
              ))}

              {some(pendingAttachments, ['status', 'error']) && (
                <p className="mt-1 caption text-error text-medium">
                  Oops, some of your attachements failed to upload. Remove them, or{' '}
                  <TextButton
                    onClick={() => {
                      // TODO: Could add more efficiency here by getting more fine-grained with the statuses and retrying only the steps that failed.
                      // Currently this restarts the whole upload process from the start
                      pendingAttachments
                        .filter(file => file.status === 'error')
                        .forEach(file => {
                          setPendingAttachments(updateAttachmentStatus(file, 'pending'))
                        })
                    }}
                    variant="destroy"
                  >
                    retry the upload.
                  </TextButton>
                </p>
              )}
            </div>
          )}
        </ComposerContainer>

        <Collapse isOpen={autoCollapse === false || !isContentEmpty || pendingAttachments.length > 0}>
          <ToolbarContainer className={csx({ error })}>
            <RichTextToolbar
              toolbarItems={[
                'bold',
                'italic',
                <AttachmentButton key="attachment" onSelectFiles={handleSelectFiles} />,
                ...(isTherapist && !disableAssessments
                  ? [<AssessmentButton key="assessment" onSelect={handleSelectAssessment} />]
                  : []),
              ]}
            />
            {toolbarRightSlot}
          </ToolbarContainer>

          {boot}
        </Collapse>
      </Container>
    </ShadowBuffer>
  )
}

const ShadowBuffer = styled('div')`
  padding-left: 2px;
  padding-right: 2px;
`

const ComposerContainer = styled('div')`
  padding: 1rem;
  &.error {
    border: solid ${error};
    border-width: 1px 1px 0;
    border-radius: var(--border-radius) 0 0 0;
  }
`

const Container = styled(Card)<{ audience: Audience }>`
  border-right: 12px solid ${props => (props.audience === 'provider' ? plumSet[50].hex : tealSet[50].hex)};
  padding: 0;
  width: 100%;
`

const ToolbarContainer = styled('div')`
  align-items: center;
  display: flex;
  justify-content: center;
  padding-top: var(--margin-1);
  padding-bottom: var(--margin-3);

  &.error {
    border: solid ${error};
    border-width: 0 1px 1px;
  }

  html:not([data-tunic-theme='harmony']) & {
    --primary-50: ${tealSet[50].hex};
    --primary-tint-40: ${tealSet.tint[40]};
    --primary-hover: ${tealSet[50].hover};
  }
`
