import { IconProps } from './IconProps'

export function IconMinimize({ size = 24, color = 'currentColor', ...props }: IconProps) {
  return (
    <svg width={size} height={size} viewBox="0 0 56 56" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M47.6211 4.55435C48.4195 3.79106 49.6855 3.81953 50.4488 4.61795L51.4457 5.66067C52.209 6.45908 52.1805 7.72509 51.3821 8.48838L41.0779 18.6487H46.8648C47.9694 18.6487 48.8648 19.5441 48.8648 20.6487V22.3243C48.8648 23.4289 47.9694 24.3243 46.8648 24.3243H33.6757C32.5711 24.3243 31.6757 23.4289 31.6757 22.3243V9.13522C31.6757 8.03065 32.5711 7.13522 33.6757 7.13522H35.3513C36.4559 7.13522 37.3513 8.03065 37.3513 9.13522V14.6808L47.6211 4.55435ZM7.13525 35.3513C7.13525 36.4559 8.03068 37.3513 9.13525 37.3513H14.9221L4.61795 47.5116C3.81953 48.2749 3.79106 49.5409 4.55435 50.3393L5.55119 51.3821C6.31448 52.1805 7.58049 52.209 8.37891 51.4457L18.6487 41.3192V46.8648C18.6487 47.9693 19.5442 48.8648 20.6487 48.8648H22.3244C23.4289 48.8648 24.3244 47.9693 24.3244 46.8648V33.6756C24.3244 32.5711 23.4289 31.6756 22.3244 31.6756H9.13525C8.03069 31.6756 7.13525 32.5711 7.13525 33.6756V35.3513Z"
        fill={color}
      />
    </svg>
  )
}
