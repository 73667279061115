import { Widget } from '@typeform/embed-react'
import { useState } from 'react'

import { Dialog, DialogContent, IconButton, IconThumbsDown, IconThumbsUp, csx } from '@nuna/tunic'

import { SessionData } from '../../../types'
import { hasAutonote } from '../../../utils'

const FORM_ID = 'dLusUMyV'

interface Props extends React.HTMLAttributes<HTMLDivElement> {
  sessionData: SessionData
}

export function AutonoteFeedback({ sessionData, className, ...props }: Props) {
  const [isOpen, setIsOpen] = useState(false)

  if (!hasAutonote(sessionData)) return null

  return (
    <div className={csx(['v-align', className])} {...props}>
      <IconButton small variant="secondary" tooltip="Tava Scribe negative feedback" onClick={() => setIsOpen(true)}>
        <IconThumbsDown size={20} />
      </IconButton>

      <IconButton small variant="secondary" tooltip="Tava Scribe positive feedback" onClick={() => setIsOpen(true)}>
        <IconThumbsUp size={20} />
      </IconButton>

      <Dialog isOpen={isOpen} onClose={() => setIsOpen(false)} fullWidth maxWidth="sm">
        <DialogContent>
          <Widget
            id={FORM_ID}
            style={{ width: '100%', height: '480px' }}
            hidden={{
              provider_id: sessionData.provider.id,
              provider_name: `${sessionData.provider.firstName} ${sessionData.provider.lastName}`,
              appointment_id: sessionData.appointmentId,
              session_id: sessionData.sessionId ?? '',
              session_summary_id: sessionData.sessionSummary?.id ?? '',
            }}
          />
        </DialogContent>
      </Dialog>
    </div>
  )
}
