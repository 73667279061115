import { styled } from '@mui/material'
import { TextField, TextFieldProps } from '@mui/material'

import { Collapse } from './Collapse/Collapse'

export function ConditionalTextField({ isOpen, ...props }: { isOpen: boolean } & TextFieldProps) {
  return (
    <Collapse isOpen={isOpen}>
      <TextFieldWrapper className="mt-1">
        <TextField {...props} />
      </TextFieldWrapper>
    </Collapse>
  )
}

const TextFieldWrapper = styled('div')`
  margin-bottom: 3rem;
`
