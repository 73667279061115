import { useState } from 'react'

import { DrawerStepper, useAppointmentDrawerSearchParams } from '@nuna/common'
import { IconCancelAppointment, TextButton } from '@nuna/tunic'

import { AppointmentDrawerPaddedContainer } from '../../AppointmentDrawer'
import { DrawerAppointmentSelect } from '../DrawerAppointmentSelect'
import { DrawerHeader } from '../DrawerHeader'

export function CancelApointmentSelect() {
  const [selectedAppointmentId, setSelectedAppointmentId] = useState<string | null>(null)
  const { state, openCancelAppointmentDrawer, openRescheduleAppointmentDrawer } = useAppointmentDrawerSearchParams()

  return (
    <>
      <DrawerHeader icon={<IconCancelAppointment isHovered style={{ marginTop: -2 }} />}>Cancel</DrawerHeader>
      <AppointmentDrawerPaddedContainer>
        <DrawerAppointmentSelect
          className="mt-2"
          value={selectedAppointmentId}
          onChange={e => setSelectedAppointmentId(e.currentTarget.value)}
        />
        <DrawerStepper
          className="mt-4"
          nextButtonDiabled={!selectedAppointmentId}
          onNextButtonClick={() => openCancelAppointmentDrawer(selectedAppointmentId, state)}
        />
        <div className="text-secondary mt-2">
          You can also{' '}
          <TextButton
            disabled={!selectedAppointmentId}
            onClick={() => openRescheduleAppointmentDrawer(selectedAppointmentId)}
          >
            reschedule this session
          </TextButton>
        </div>
      </AppointmentDrawerPaddedContainer>
    </>
  )
}
