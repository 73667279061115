import { styled } from '@mui/material'
import { last, omit } from 'lodash'
import moment from 'moment-timezone'
import { HTMLAttributes, useState } from 'react'

import { DayAndTimeInput } from '@nuna/api'
import { type FullDayOfWeek, timeService } from '@nuna/core'
import {
  BelowTablet,
  IconChevronThick,
  IconPlus,
  body1,
  body2,
  borderGrey,
  fontSize,
  greySet,
  safariBorderRadiusOutline,
  shadowWithHoverTransition,
  tealSet,
} from '@nuna/tunic'

import { DraggableCalendar } from './components/DraggableCalendar'

const { addHoursToTime } = timeService

interface AvailabilityCalendarProps extends Omit<HTMLAttributes<HTMLDivElement>, 'onChange'> {
  availabilityPeriods: DayAndTimeInput[]
  onChange: (availabillityPeriods: DayAndTimeInput[]) => void
  onDelete: (periodsToDelete: DayAndTimeInput[]) => void
  timeZone: string
  sideBorders?: boolean
}

// so I only have to write markup for one DayButton instead of 7
const days: { display: string; value: FullDayOfWeek }[] = [
  {
    display: 'S',
    value: 'sunday',
  },
  {
    display: 'M',
    value: 'monday',
  },
  {
    display: 'T',
    value: 'tuesday',
  },
  {
    display: 'W',
    value: 'wednesday',
  },
  {
    display: 'Th',
    value: 'thursday',
  },
  {
    display: 'F',
    value: 'friday',
  },
  {
    display: 'Sa',
    value: 'saturday',
  },
]

export function AvailabilityCalendar({
  availabilityPeriods,
  onChange,
  onDelete,
  sideBorders = true,
  timeZone,
  ...props
}: AvailabilityCalendarProps) {
  const [calendarDate, setCalendarDate] = useState(new Date())
  const [deletedDays, setDeletedDays] = useState<Partial<Record<FullDayOfWeek, DayAndTimeInput[]>>>({})

  const isDayActive = (day: FullDayOfWeek) => {
    return !!availabilityPeriods.find(period => period.day === day)
  }

  const removeTimesForDay = (day: FullDayOfWeek) => {
    const deletedPeriods = availabilityPeriods.filter(period => period.day === day)
    setDeletedDays({
      ...deletedDays,
      [day]: deletedPeriods.map(period => omit<DayAndTimeInput>(period, ['id', '__typename'])),
    })
    onDelete(deletedPeriods)
  }

  const addTimeToDay = (day: FullDayOfWeek) => {
    const deletedPeriods = deletedDays[day]

    if (deletedPeriods) {
      onChange(deletedPeriods)
    } else {
      const existingHoursOnDay = availabilityPeriods
        .filter(period => period.day === day)
        .map(period => period.end)
        .sort()

      const lastEnd = last(existingHoursOnDay)

      if (lastEnd) {
        onChange([{ day, start: lastEnd, end: addHoursToTime(lastEnd, 1) }])
      } else {
        onChange([{ day, start: '09:00', end: '10:00' }])
      }
    }
  }

  const handleClick = (day: FullDayOfWeek) => {
    if (isDayActive(day)) {
      removeTimesForDay(day)
    } else {
      addTimeToDay(day)
    }
  }

  return (
    <div {...props}>
      <DayBar>
        {days.map(day => (
          <DayButton
            data-testid={`day-button-${day.value}`}
            key={day.value}
            onClick={() => handleClick(day.value)}
            active={isDayActive(day.value)}
          >
            {day.display}
          </DayButton>
        ))}
      </DayBar>

      <MobileDayBar>
        <DayButton
          onClick={() => {
            const momentDate = moment(calendarDate)
            let daysToMove = -1

            if (momentDate.day() === 0) {
              daysToMove = 6
            }

            setCalendarDate(momentDate.add('day', daysToMove).toDate())
          }}
        >
          <IconChevronThick />
        </DayButton>

        <h3 className="h4 mb-0">{moment(calendarDate).format('dddd[s]')}</h3>

        <DayButton
          onClick={() => {
            const momentDate = moment(calendarDate)
            let daysToMove = 1

            if (momentDate.day() === 6) {
              daysToMove = -6
            }

            setCalendarDate(momentDate.add(daysToMove, 'day').toDate())
          }}
        >
          <IconChevronThick direction="right" />
        </DayButton>
      </MobileDayBar>

      <CalendarContainer sideBorders={sideBorders}>
        <CalendarHeightContainer>
          <DraggableCalendar
            calendarDate={calendarDate}
            availabilityPeriods={availabilityPeriods}
            onChange={onChange}
            onDelete={onDelete}
            timeZone={timeZone}
          />
        </CalendarHeightContainer>

        <AddSlotBar>
          {days.map(day => (
            <AddButton
              key={day.value}
              onClick={() => {
                addTimeToDay(day.value)
              }}
            >
              <IconPlus size={12} />
            </AddButton>
          ))}
        </AddSlotBar>

        <MobileAddSlotBar>
          <MobileAddButton
            onClick={() => {
              addTimeToDay(days[calendarDate.getDay()].value)
            }}
          >
            <IconPlus />
          </MobileAddButton>
        </MobileAddSlotBar>
      </CalendarContainer>
    </div>
  )
}

const CalendarContainer = styled('div')<{ sideBorders: boolean }>`
  background-color: #fff;
  padding: 1.5rem 1.5rem 1.5rem 3rem;
  ${props =>
    props.sideBorders
      ? `border: 1px solid ${borderGrey}; border-radius: 12px;`
      : `border-top: 1px solid ${greySet[15].hex}; padding-top: 0;`}
  width: 100%;

  @media (${BelowTablet}) {
    padding-left: 1.5rem;
  }
`

const CalendarHeightContainer = styled('div')`
  height: 825px;

  @media (${BelowTablet}) {
    height: 60vh;
    min-height: 400px;
    max-height: 700px;
  }
`

const DayBar = styled('div')`
  align-items: center;
  display: flex;
  justify-content: space-around;
  margin-left: 45px;
  padding: 1.5rem 1.5rem 1.5rem 3rem;

  @media (${BelowTablet}) {
    display: none;
  }
`

const MobileDayBar = styled('div')`
  display: none;
  @media (${BelowTablet}) {
    display: flex;
  }

  align-items: center;
  justify-content: space-between;
  margin-bottom: 1rem;
  margin-top: var(--margin-3);
`

const MobileAddSlotBar = styled('div')`
  display: none;
  @media (${BelowTablet}) {
    display: flex;
  }

  align-items: center;
  justify-content: center;
  margin-top: var(--margin-2);
`

const DayButton = styled('button')<{ active?: boolean }>`
  height: 50px;
  width: 50px;
  background-color: ${props => (props.active ? tealSet[5].hex : '#fff')};
  border: ${props => (props.active ? `2px solid ${tealSet[30].hex}` : `1px solid ${greySet[5].hex}`)};
  border-radius: 50px;
  font-size: ${fontSize.large};
  font-weight: 300;
  color: ${props => (props.active ? body1 : body2)};
  display: inline-flex;
  align-items: center;
  justify-content: center;
  position: relative;

  ${shadowWithHoverTransition(1)}

  &:hover,
  &:focus-visible {
    color: ${props => (props.active ? body1 : tealSet.primary.hex)};
  }

  &:hover {
    transform: translateY(-1px);
    border: 0;
    ${props => safariBorderRadiusOutline(props.active ? tealSet[50].hex : greySet[5].hex, 2, 50)}
  }

  &:focus-visible {
    outline: none;
    border-color: ${props => (props.active ? tealSet[50].hex : greySet[30].hex)};

    &::after {
      content: '';
      position: absolute;
      width: 100%;
      height: 100%;
      box-shadow: 0 0 0 8px ${props => (props.active ? tealSet.tint[40] : greySet.tint[40])};
      border-radius: 50px;
    }
  }
`
DayButton.defaultProps = { type: 'button' }

const MobileAddButton = styled(DayButton)`
  background-color: ${tealSet[50].hex};
  color: #fff;
`

const AddSlotBar = styled('div')`
  align-items: center;
  display: flex;
  justify-content: space-around;
  margin-left: 45px;
  position: relative;
  top: -1rem;
  z-index: 1;

  @media (${BelowTablet}) {
    display: none;
  }
`

const AddButton = styled(DayButton)`
  height: 32px;
  width: 32px;
  opacity: 0;
  pointer-events: none;

  &.focus-visible {
    opacity: 1;
    pointer-events: auto;
  }
`
