import { styled } from '@mui/material'
import mixpanel from 'mixpanel-browser'

import { useAppointmentCollectionStatusQuery, useManageSessionPaymentMutation } from '@nuna/api'
import { Audience, errorService } from '@nuna/core'
import { supportService } from '@nuna/telemetry'
import { Skeleton, greySet, makeTypographyComponent, toast } from '@nuna/tunic'

import { ChangeReasonDisplay } from './components/ChangeReasonDisplay'
import { MessageAppointmentCard } from './components/MessageAppointmentCard'

interface Props {
  appointmentId: string
  appointmentHistoryItemId?: string | null
  audience: Audience
}

export function AppointmentFooter({ appointmentId, appointmentHistoryItemId, audience }: Props) {
  const { data, refetch } = useAppointmentCollectionStatusQuery({
    variables: { appointmentId, appointmentHistoryItemId },
  })

  const [manageSessionPayment] = useManageSessionPaymentMutation({
    onCompleted: () => {
      refetch()
    },
  })
  const appointmentData = data?.appointmentCollectionStatus

  const handleManageSessionPayment = async (shouldProviderBePaid: boolean) => {
    try {
      await manageSessionPayment({
        variables: {
          id: data?.appointmentCollectionStatus?.sessionPaymentId ?? '',
          shouldProviderBePaid,
        },
      })

      mixpanel.track(`${shouldProviderBePaid ? 'charged' : 'waived'} fee`, { appointmentId, location: 'messages' })
      toast.success(shouldProviderBePaid ? 'Fee charged' : 'Fee waived')
    } catch (e) {
      toast.urgent(
        errorService.transformGraphQlError(
          e,
          `An error occurred, please contact ${
            audience === 'provider'
              ? supportService.supportEmails.providerSupport
              : supportService.supportEmails.clientSupport
          }.`,
        ),
      )
    }
  }

  return (
    <Container>
      {!appointmentData && <Skeleton height={3} />}
      {appointmentData && (
        <>
          <ChangeReasonDisplay appointmentData={appointmentData} />
          <MessageAppointmentCard
            appointmentData={appointmentData}
            audience={audience}
            onFeeDecision={handleManageSessionPayment}
          />
        </>
      )}
    </Container>
  )
}

const Container = styled(makeTypographyComponent('pt-2 mt-2', 'div'))`
  border-top: 1px solid ${greySet[15].hex};
`
