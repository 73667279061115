import { ApolloQueryResult } from '@apollo/client'
import { sortBy } from 'lodash'
import moment from 'moment-timezone'
import { useMemo } from 'react'
import { Column } from 'react-table'

import { CustomerCompanyDetailsFragment, CustomerCompanyQuery } from '@nuna/api'
import { DataTable } from '@nuna/data-table'

import { HrisIntegrationRow, HrisIntegrationRowData } from './HrisIntegrationRow'
import { StagedRosterInsights } from './StagedRosterInsights'

interface Props {
  isAdmin: boolean
  customerCompany: CustomerCompanyDetailsFragment
  refetchCustomerCompany: () => Promise<ApolloQueryResult<CustomerCompanyQuery>>
}

export function HrisIntegrations({ isAdmin, refetchCustomerCompany, customerCompany }: Props) {
  const rowData = useMemo<HrisIntegrationRowData[]>(() => {
    return sortBy(customerCompany.hrisIntegrations ?? [], ['shouldStage', 'shouldSync', 'name'])
      .reverse()
      .map(integration => ({
        ...integration,
        lastRanSyncDate: integration.lastRanSyncDate
          ? moment.tz(integration.lastRanSyncDate, 'UTC').format('Do MMM, h:mm a')
          : '',
        stageStatus: integration.shouldStage ? 'STAGED' : 'ACTIVE',
        syncStatus: integration.shouldSync ? 'SYNCING' : 'NOT SYNCING',
        empty: '',
      }))
  }, [customerCompany])

  const columns = useMemo<Column<HrisIntegrationRowData>[]>(
    () => [
      {
        Header: 'Source',
        accessor: 'name',
        width: 180,
      },
      {
        Header: 'Last Sync',
        accessor: 'lastRanSyncDate',
        width: 180,
      },
      {
        Header: 'Sync Status',
        accessor: 'syncStatus',
        width: 150,
      },
      {
        Header: 'Status',
        accessor: 'stageStatus',
        width: 120,
      },
      {
        Header: 'Facilitator',
        accessor: 'integrationType',
        width: 150,
      },
      {
        Header: '',
        accessor: 'empty',
        width: 20,
      },
    ],
    [],
  )

  return (
    <>
      {isAdmin && <StagedRosterInsights customerCompany={customerCompany} />}

      <h2 className="h5">HRIS Roster Integrations</h2>
      <DataTable
        rowData={rowData}
        columns={columns}
        loading={false}
        customRow={customRowProps => (
          <HrisIntegrationRow {...customRowProps} refetchCustomerCompany={refetchCustomerCompany} />
        )}
      />
    </>
  )
}
