import { useDevices } from '@daily-co/daily-react'
import { ChangeEvent } from 'react'

import { useEffectOnce } from '@nuna/common'
import { IconInfo, Select } from '@nuna/tunic'

import { getDevicePreferences, saveDevicePreferences } from '../util/devicePreferences'

export function AudioOutputSelect() {
  const { audioOutputDeviceId } = getDevicePreferences()
  const { speakers, setSpeaker, currentSpeaker } = useDevices()

  // some browsers (Safari) do not support selecting audio output. When that is the case, speakers.length will be 0
  const browserSupported = speakers.length > 0

  /**
   * Daily does not provide a way of specifying the audio output device when starting the camera, unlike the camera and microphone.
   * This will switch the speaker to the one specified in the device preferences when the settings are first shown in the Hair Check.
   */
  useEffectOnce(() => {
    setSpeaker(audioOutputDeviceId)
  }, browserSupported && currentSpeaker?.device.deviceId !== audioOutputDeviceId)

  const handleChangeSpeaker = (e: ChangeEvent<HTMLSelectElement>) => {
    setSpeaker(e.currentTarget.value)
    saveDevicePreferences({ audioOutputDeviceId: e.currentTarget.value })
  }

  return (
    <Select
      disabled={speakers.length === 0}
      scheme="dark"
      label="Speaker"
      value={currentSpeaker?.device.deviceId ?? ''}
      onChange={handleChangeSpeaker}
      className="full-width mr-1 mt-2"
      inputProps={{ className: 'fs-exception' }}
      data-component="video-settings-speaker-select"
      shrink
      helperText={
        !browserSupported ? (
          <span className="top-align mt-1 text-white">
            <IconInfo size={16} className="shrink-0 mr-xs" />
            <span className="italic pr-1">
              Your browser doesn't allow changing audio output. To change it, look in your device's audio preferences.
            </span>
          </span>
        ) : (
          ''
        )
      }
    >
      {!browserSupported ? (
        <option value="">Same as System</option>
      ) : (
        speakers.map(speaker => (
          <option key={speaker.device.deviceId} value={speaker.device.deviceId}>
            {speaker.device.deviceId === 'default' || !browserSupported ? 'Same as System' : speaker.device.label}
          </option>
        ))
      )}
    </Select>
  )
}
