import { PatientPaymentMethodFragment, PatientPaymentMethodInput } from '@nuna/api'
import { errorService } from '@nuna/core'
import { Skeleton, toast } from '@nuna/tunic'

import { CreditCardSelectChange } from '../types'
import { CreditCardManagerProps, CreditCardSelect } from './CreditCardSelect'

interface PaymentMethodSelectProps extends Omit<CreditCardManagerProps, 'onChange' | 'cards'> {
  date?: Date
  paymentMethods: PatientPaymentMethodFragment[]
  onChange: (change: PatientPaymentMethodFragment) => Promise<void> | void
  savePaymentMethod: (input: PatientPaymentMethodInput) => Promise<PatientPaymentMethodFragment>
  loading: boolean
  patientId: string
  defaultFor?: 'fees' | 'sessions'
  showHSAWarning?: boolean
}

export function PaymentMethodSelect({
  paymentMethods,
  onChange,
  value,
  loading,
  savePaymentMethod,
  patientId,
  defaultFor,
  showHSAWarning = defaultFor === 'fees',
  ...props
}: PaymentMethodSelectProps) {
  if (paymentMethods.length === 0 && loading) return <Skeleton height={4.5} />

  const handleChange = async (creditCardChange: CreditCardSelectChange) => {
    const paymentMethod = paymentMethods.find(
      paymentMethod => paymentMethod.stripePaymentMethodId === creditCardChange.stripePaymentMethodId,
    )

    try {
      // default for session if specifically requested or there is no other card on file for sessions
      const defaultForSessions =
        defaultFor === 'sessions' || !paymentMethods.some(pm => pm.defaultForSessions) ? true : undefined
      // default for fees if specifically requested or it's not an HSA card and no other card is set for fees
      const defaultForFees =
        defaultFor === 'fees' || (!creditCardChange.isHsa && !paymentMethods.some(pm => pm.defaultForFees))
          ? true
          : undefined

      const newCard = await savePaymentMethod({
        id: paymentMethod ? paymentMethod.id : undefined,
        patientId,
        isHsa: creditCardChange.isHsa,
        stripePaymentMethodId: creditCardChange.stripePaymentMethodId,
        isUserAcknowledged: true,
        defaultForSessions,
        defaultForFees,
      })

      onChange(newCard)
    } catch (error) {
      toast.urgent(errorService.transformUserGraphqlError(error, 'Failed to save payment method'))
    }
  }

  return (
    <CreditCardSelect
      {...props}
      showHSAWarning={showHSAWarning}
      value={value}
      onChange={handleChange}
      cards={paymentMethods}
      patientid={patientId}
    />
  )
}
