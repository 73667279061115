import { styled } from '@mui/material'
import React from 'react'

import { Grid, GridProps, Typography } from '@nuna/tunic'

import NoAssessmentsImage from '../img/StarsGirl.png'

interface NoAssessmentsProps {
  header: string
  subheader: React.ReactNode
  justifyContent?: GridProps['justifyContent']
}

export function NoAssessments({ header, subheader, justifyContent = 'center' }: NoAssessmentsProps) {
  return (
    <Container className="mt-4 mb-6">
      <Grid container spacing={0} alignItems="center" justifyContent={justifyContent}>
        <Image src={NoAssessmentsImage} alt="No assessments" />
        <Grid size={{ xs: 12, md: 6 }}>
          <Message>
            <h2 className="h4 mb-4">{header}</h2>
            <Typography variant="body1" component="div">
              {subheader}
            </Typography>
          </Message>
        </Grid>
      </Grid>
    </Container>
  )
}

const Container = styled('div')`
  display: flex;
  gap: 1rem;
  width: 100%;
  text-align: center;
`

const Image = styled('img')`
  width: 100%;
  max-width: 210px;
  height: auto;
`

const Message = styled('div')`
  text-align: left;
`
