import { styled } from '@mui/material'
import { Popover } from '@mui/material'

import { greySet } from '../../styles/colorSets'
import { shadowDepth } from '../../styles/shadows'

export const Menu = styled(Popover)`
  .MuiPopover-paper {
    background-color: ${greySet[0].hex};
    border: 1px solid ${greySet[30].hex};
    border-radius: var(--border-radius);
    box-shadow: ${shadowDepth(4.5)};
    padding: 0.75rem 1rem;
  }
`
