import { IconProps } from './IconProps'

export function IconMicrophoneOff({ size = 24, color = 'currentColor' }: IconProps) {
  return (
    <svg width={size} height={size} viewBox="0 0 28 28" fill={color} xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17.7763 13.3684C17.7763 15.4653 16.0963 17.1579 13.9994 17.1579C13.7028 17.1579 13.4143 17.124 13.1375 17.06L11.9019 19.4849C12.5721 19.6975 13.2798 19.8105 13.9994 19.8105C17.3302 19.8105 20.4073 17.3893 20.6753 13.8687C20.6962 13.5934 20.918 13.3684 21.1942 13.3684H22.3415C22.6177 13.3684 22.8431 13.5929 22.8261 13.8685C22.5825 17.814 19.4796 21.0354 15.6885 21.7837C15.4451 21.8318 15.2626 22.0408 15.2626 22.289V25.5C15.2626 25.7762 15.0387 26 14.7626 26H13.2363C12.9601 26 12.7363 25.7762 12.7363 25.5V22.2877C12.7363 22.0401 12.5546 21.8313 12.3118 21.7827C11.8398 21.6883 11.3785 21.5557 10.9321 21.3883L9.00423 25.172C8.75349 25.664 8.15132 25.8597 7.65923 25.609L7.46489 25.51C6.9728 25.2592 6.77714 24.657 7.02787 24.165L17.8995 2.8282C18.1502 2.33612 18.7524 2.14046 19.2445 2.39119L19.4388 2.49021C19.9309 2.74094 20.1266 3.34312 19.8758 3.83521L17.7853 7.93811L17.7763 13.3684ZM13.9993 4.14486C14.2383 4.14486 14.4668 4.18818 14.6777 4.2674L15.411 2.8282C15.4959 2.66148 15.6212 2.52878 15.7689 2.43594C15.2411 2.1575 14.639 2 13.9994 2C11.9026 2 10.2099 3.69263 10.2099 5.78948V13.0358L12.0768 9.37188L12.0768 6.0674C12.0768 5.0036 12.9355 4.14486 13.9993 4.14486ZM8.3022 16.7799C7.7578 15.9407 7.40641 14.9572 7.32357 13.8687C7.30261 13.5934 7.08081 13.3684 6.80467 13.3684H5.6573C5.38115 13.3684 5.15568 13.5929 5.17279 13.8685C5.28981 15.7531 6.06276 17.474 7.25307 18.839L8.3022 16.7799Z"
      />
    </svg>
  )
}
