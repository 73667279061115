import { FormControl, FormHelperText, InputLabel, Select, SelectProps } from '@mui/material'
import { ReactNode } from 'react'

import { addressService } from '@nuna/core'

const { USStates } = addressService

type StateSelectProps = Omit<SelectProps, 'variant'> & {
  error: boolean
  helperText?: string | ReactNode
  dataTestId?: string
}

export function StateSelect({
  error,
  helperText,
  name = 'state',
  dataTestId = 'signup-state',
  ...props
}: StateSelectProps) {
  return (
    <FormControl style={{ width: '100%' }}>
      <InputLabel error={error} htmlFor="state">
        State
      </InputLabel>

      <Select
        id="state"
        native
        name={name}
        error={error}
        inputProps={{
          'data-testid': dataTestId,
        }}
        {...props}
      >
        <option aria-label="None" value="" disabled />
        {Object.entries(USStates).map(([abbreviation, name]) => (
          <option key={abbreviation} value={abbreviation}>
            {name}
          </option>
        ))}
      </Select>

      {helperText && <FormHelperText error={error}>{helperText}</FormHelperText>}
    </FormControl>
  )
}
