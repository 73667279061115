import { useEffect } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'

import { useProviderSignupIntakeContext } from './ProviderSignupIntakeContext'

export function ResumeIntake() {
  const { resumePath } = useProviderSignupIntakeContext()
  const location = useLocation()
  const navigate = useNavigate()

  useEffect(() => {
    if (!resumePath) {
      navigate('/signup-intake', { replace: true })
    }
    if (resumePath && location.pathname !== resumePath) {
      navigate(resumePath, { replace: true })
    }
  }, [resumePath, location, navigate])
  return <div></div>
}
