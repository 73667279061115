import { IconRotatableProps } from './IconProps'
import { getIconRotation } from './iconUtils'

export function IconArrow({
  direction = 'right',
  size = 24,
  color = 'currentColor',
  style = {},
  ...props
}: IconRotatableProps) {
  const rotation = getIconRotation(direction, 'up')

  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 48 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={{ transform: rotation, ...style }}
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M19.3077 17.9509C19.3077 17.3143 18.9406 17.1557 18.4925 17.5921L10.098 25.7691C9.19481 26.6488 7.72599 26.6484 6.82556 25.7761L3.67801 22.7269C2.77387 21.851 2.7754 20.4256 3.67419 19.5501L23.2547 0.492942C23.9304 -0.165209 25.0277 -0.163418 25.7016 0.492942L45.3259 19.5501C46.228 20.4287 46.2226 21.8546 45.3222 22.7269L42.1746 25.7761C41.2705 26.652 39.8039 26.6475 38.9023 25.7693L30.436 17.523C29.9826 17.0813 29.615 17.2368 29.615 17.866L29.615 24.2818L29.5793 46.0186C29.5775 47.1129 28.6694 48 27.5577 48H21.3782C20.2635 48 19.3581 47.1025 19.3559 45.9931L19.3499 42.8473C19.3499 36.6588 19.2908 24.2818 19.2908 24.2818L19.3077 17.9509Z"
        fill={color}
      />
    </svg>
  )
}
