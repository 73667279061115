import { IconProps } from './IconProps'

export function IconKey({ size = 24, color = 'currentColor', ...props }: IconProps) {
  return (
    <svg width={size} height={size} viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M21.474 17.8464C19.4649 13.7346 15.7761 12.0321 11.551 12.0321C5.17472 12.0321 0.0129395 17.0819 0.0129395 23.4229C0.0129395 29.764 5.18118 34.955 11.551 34.955C15.7761 34.955 19.4649 32.655 21.474 29.3657H23.4961L28.3478 24.5408L32.379 28.5497L36.4102 24.5408L41.2619 29.3657H42.1858L47.9613 23.6092L42.1858 17.8464H21.474ZM5.50419 23.333C5.50419 21.3221 7.14511 19.6902 9.16718 19.6902C11.1893 19.6902 12.8302 21.3221 12.8302 23.333C12.8302 25.3439 11.1893 26.9757 9.16718 26.9757C7.14511 26.9757 5.50419 25.3439 5.50419 23.333Z"
        fill={color}
      />
    </svg>
  )
}
