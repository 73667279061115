import { MutationFunctionOptions, MutationHookOptions } from '@apollo/client'

import {
  PatientIntakeContractFragment,
  SavePatientEmployerMutation,
  SavePatientEmployerMutationVariables,
  useSavePatientEmployerMutation,
} from '@nuna/api'
import { coverageService, errorService } from '@nuna/core'

import { WrappedResponse } from '../types'

export function useSaveEmployerCoverage(
  baseOptions?: MutationHookOptions<SavePatientEmployerMutation, SavePatientEmployerMutationVariables>,
) {
  const [savePatientEmployerMutation, mutationResult] = useSavePatientEmployerMutation(baseOptions)

  const savePatientEmployer = async (
    options?: MutationFunctionOptions<SavePatientEmployerMutation, SavePatientEmployerMutationVariables>,
  ): Promise<WrappedResponse<PatientIntakeContractFragment>> => {
    try {
      const response = await savePatientEmployerMutation(options)

      if (response.data) {
        const employerContract = coverageService.getEmployerContract(
          response.data.savePatientEmployer.contractRelations ?? [],
        )

        if (employerContract) {
          return {
            didSucceed: true,
            error: null,
            data: employerContract,
          }
        }
      }
    } catch (e) {
      return {
        didSucceed: false,
        error: errorService.getErrorFromUnknown(e),
        data: null,
      }
    }

    return {
      didSucceed: false,
      error: Error(
        "Oops, we weren't able to find an eligible contract. Double check your information or contact support.",
      ),
      data: null,
    }
  }

  return [savePatientEmployer, mutationResult] as const
}
