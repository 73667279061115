import { styled } from '@mui/material'
import { groupBy } from 'lodash'
import { Fragment, useState } from 'react'
import { Cell, ColumnInstance, Row as ReactTableRow } from 'react-table'

import { Card, Collapse, IconChevronThick, borderGrey } from '@nuna/tunic'

import { DEFAULT_PAGINATOR_PROPS, DataTableColumn } from './DataTable'
import { Paginator, PaginatorProps } from './Paginator'

interface Props<I extends object> {
  rows: ReactTableRow<I>[]
  prepareRow: (row: ReactTableRow<I>) => void
  loading: boolean
  paginated?: boolean
  paginatorProps?: Partial<PaginatorProps>
}

export function MobileDataTable<I extends object>({ rows, prepareRow, paginated, paginatorProps }: Props<I>) {
  const groupingColumn = rows[0]?.cells.find(cell => (cell.column as DataTableColumn<I>)?.groupInMobile)

  if (groupingColumn) {
    const groupedRows = groupBy(rows, row => row.values[groupingColumn.column.id])
    return (
      <Container>
        {Object.values(groupedRows).map(groupedRows => {
          const groupHeader = groupedRows[0]?.cells.find(cell => (cell.column as DataTableColumn<I>).groupInMobile)
          if (groupHeader) {
            return (
              <Fragment key={groupHeader?.value}>
                <GroupHeader className="text-secondary">{mobileCellRender(groupHeader)}</GroupHeader>

                {groupedRows.map(row => {
                  prepareRow(row)
                  return <Row key={row.id} row={row} indented={true} />
                })}
              </Fragment>
            )
          }
          return null
        })}

        {!!paginated && <Paginator topBorder={false} {...{ ...DEFAULT_PAGINATOR_PROPS, ...paginatorProps }} />}
      </Container>
    )
  }

  return (
    <Container>
      {rows.map(row => {
        prepareRow(row)
        return <Row key={row.id} row={row} />
      })}

      {!!paginated && <Paginator {...{ ...DEFAULT_PAGINATOR_PROPS, ...paginatorProps }} />}
    </Container>
  )
}

type SortedColumnInstance<I extends object> = ColumnInstance<I> & {
  isSorted: boolean
}

function Row<I extends object>({ row, indented = false }: { row: ReactTableRow<I>; indented?: boolean }) {
  const [isOpen, setIsOpen] = useState(false)

  // we may want to support multiple cells in the future but the css only supports 1 for now
  const headerCells = row.cells.filter(cell => (cell.column as DataTableColumn<I>).showInMobileHeader)
  if (headerCells.length > 1) {
    console.warn('Mobile Data Table only supports 1 header cell. Only 1 will be shown.')
  } else if (headerCells.length === 0) {
    console.warn('Mobile Data Table did not receive a header cell.')
  }

  const headerCell = headerCells.length > 0 ? headerCells[0] : null
  const sortedColumnCell = row.cells.find(cell => (cell.column as SortedColumnInstance<I>).isSorted)

  return (
    <RowContainer style={{ marginLeft: indented ? 'var(--spacing-2)' : 0 }}>
      <RowHeader onClick={() => setIsOpen(!isOpen)} className="text-secondary">
        {headerCell ? (
          <span key={headerCell.column.id} className="text-bold">
            {mobileCellRender(headerCell)}
          </span>
        ) : (
          <span /> // empty span so our css grid still works
        )}

        {sortedColumnCell && <span className="text-light">{mobileCellRender(sortedColumnCell)}</span>}
        <IconChevronThick size={16} direction={isOpen ? 'up' : 'down'} />
      </RowHeader>
      <Collapse isOpen={isOpen}>
        <CardGrid depth={-1} as="dl">
          {row.cells.map(cell => (
            <Fragment key={cell.column.id}>
              <dt className="row-label">{mobileHeaderRender(cell.column)}</dt>
              <dd className="row-data">{mobileCellRender(cell)}</dd>
            </Fragment>
          ))}
        </CardGrid>
      </Collapse>
    </RowContainer>
  )
}

function mobileCellRender<I extends object>(cell: Cell<I, unknown>) {
  return 'MobileCell' in cell.column ? cell.render('MobileCell') : cell.render('Cell')
}

function mobileHeaderRender<I extends object>(column: ColumnInstance<I>) {
  return 'MobileHeader' in column ? column.render('MobileHeader') : column.render('Header')
}

const Container = styled('div')``

const GroupHeader = styled('div')`
  padding: var(--spacing-1) 0;
`

const gridTemplateColumns = '2fr 3fr'

const RowHeader = styled('button')`
  display: grid;
  grid-template-columns: ${gridTemplateColumns};
  grid-gap: var(--spacing-1);
  padding: var(--spacing-2) 0;
  position: relative;
  text-align: left;
  width: 100%;
  padding-right: var(--spacing-3);

  svg {
    position: absolute;
    right: 0;
    top: 0;
    bottom: 0;
    margin-top: auto;
    margin-bottom: auto;
  }
`

const RowContainer = styled('div')`
  border-top: 1px solid ${borderGrey};
`

const CardGrid = styled(Card)`
  border: 0;
  display: grid;
  grid-template-columns: ${gridTemplateColumns};
  grid-gap: var(--spacing-1);
  margin-bottom: var(--spacing-2);
  padding: 0.75rem 0;

  dd {
    margin: 0;
  }

  .row-label {
    padding-left: 0.75rem; // adding padding here allows the grid in the card and the grid in the header to align
  }

  .row-data {
    padding-right: 0.75rem;
  }
`
