import { PaymentPreference } from '@nuna/api'
import { coverageService } from '@nuna/core'
import { PlaceholderButton, csx } from '@nuna/tunic'

import { CoverageTypeIcon } from '../../CoverageTypeIcon'
import { useClientCoveragePanelContext } from '../context/ClientCoveragePanelContext'

interface Props {
  type: PaymentPreference
  onClick: (type: PaymentPreference) => void
  className?: string
}

export function AddCoverageButton({ type, className, onClick }: Props) {
  const { isHorizontalLayout } = useClientCoveragePanelContext()

  return (
    <PlaceholderButton
      onClick={() => onClick(type)}
      className={csx([
        { 'text-left': !isHorizontalLayout, 'text-center': isHorizontalLayout },
        `panel-add-coverage-button ${className}`,
      ])}
    >
      <span className={csx([{ 'h-align': isHorizontalLayout, 'v-align': !isHorizontalLayout }, 'text-bold'])}>
        <CoverageTypeIcon type={type} size={18} className="mr-xs" /> Add{' '}
        {coverageService.getHumanReadablePaymentPreference(type)}
      </span>
    </PlaceholderButton>
  )
}
