import { useApolloClient } from '@apollo/client'
import { ReactNode, createContext, useCallback, useContext, useEffect, useState } from 'react'

import { CaqhSyncStatus, ProviderCredentialingDataQuery, useProviderCredentialingDataQuery } from '@nuna/api'
import { useVisibilityChanged } from '@nuna/common'

interface ContextValues {
  providerId: string
  providerLoginId: string
  credentialData: ProviderCredentialingDataQuery | undefined
  stopCredentialPolling: () => void
  startCredentialPolling: (interval?: number) => void
  isCredentialPolling: boolean
  resetCredentialingFlowStatus: () => void
}

const CAQHSetupContext = createContext<ContextValues | null>(null)

interface Props {
  children: ReactNode
  providerId: string
  providerLoginId: string
}

const POLL_INTERVAL = 2000

export function CAQHSetupContextProvider({ providerId, providerLoginId, ...props }: Props) {
  const client = useApolloClient()
  const {
    data,
    stopPolling: stopCredentialPolling,
    startPolling: startCredentialPolling,
  } = useProviderCredentialingDataQuery({
    fetchPolicy: 'network-only',
    pollInterval: POLL_INTERVAL,
    notifyOnNetworkStatusChange: true,
    variables: {
      providerId,
    },
  })

  const visible = useVisibilityChanged()

  const [isCredentialPolling, setIsCredentialPolling] = useState(false)

  const handleStartCredentialPolling = useCallback(() => {
    setIsCredentialPolling(true)
  }, [setIsCredentialPolling])

  const handleStopCredentialPolling = useCallback(() => {
    setIsCredentialPolling(false)
  }, [setIsCredentialPolling])

  const resetCredentialingFlowStatus = useCallback(() => {
    // Clears the credentialingFlowStatus field from the logged-in provider so that the
    // ProviderCredentialingFlowStatus query will need to re-fetch it the next time it mounts
    client.cache.evict({ id: `Provider:${providerId}`, fieldName: 'credentialingFlowStatus' })
  }, [client, providerId])

  useEffect(() => {
    if (isCredentialPolling && visible) {
      startCredentialPolling(POLL_INTERVAL)
    } else {
      stopCredentialPolling()
    }
  }, [visible, stopCredentialPolling, startCredentialPolling, isCredentialPolling])

  useEffect(() => {
    if (data?.providerCredentialingData?.caqhSyncStatus === CaqhSyncStatus.Pending) {
      handleStartCredentialPolling()
    } else {
      handleStopCredentialPolling()
    }
  }, [data, handleStartCredentialPolling, handleStopCredentialPolling])

  const values = {
    credentialData: data,
    providerId,
    providerLoginId,
    stopCredentialPolling: handleStopCredentialPolling,
    startCredentialPolling: handleStartCredentialPolling,
    isCredentialPolling,
    resetCredentialingFlowStatus,
  }

  return <CAQHSetupContext.Provider {...props} value={values} />
}

export function useCAQHSetupContext() {
  const context = useContext(CAQHSetupContext)
  if (!context) {
    throw new Error('useCAQHSetupContext must be used within a CAQHSetupContextProvider')
  }
  return context
}
