import { IconProps } from './IconProps'

export function IconFileSlides({ size = 24, color = 'currentColor', ...props }: IconProps) {
  return (
    <svg width={size} height={size} viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.31027 5.72027C6.31027 4.93815 6.95752 4.29613 7.74041 4.29613H16.041C16.4439 4.29613 17.1394 4.58832 17.4083 4.86498L24.1206 11.7706C24.4079 12.0662 24.6897 12.7568 24.6897 13.1669V26.2761C24.6897 27.065 24.0498 27.7039 23.2663 27.7039H7.73367C6.95025 27.7039 6.31027 27.0667 6.31027 26.2893V23.1994V9.70329V5.72027ZM4 23.1994V26.2893C4 28.334 5.67344 30 7.73367 30H23.2663C25.3281 30 27 28.3308 27 26.2761V13.1669C27 12.1605 26.4849 10.8982 25.7821 10.1752L19.0699 3.26964C18.3648 2.54426 17.0692 2 16.041 2H7.74041C5.68261 2 4 3.66902 4 5.72027V9.70329V23.1994ZM10.1733 16.36H20.57C20.4991 16.36 20.4414 16.3129 20.4414 16.2556V22.0688C20.4414 22.0113 20.4988 21.9645 20.57 21.9645H10.1733C10.2442 21.9645 10.3019 22.0116 10.3019 22.0688V16.2556C10.3019 16.3131 10.2446 16.36 10.1733 16.36ZM8.76172 23.3344C8.76172 24.1099 9.39338 24.738 10.1733 24.738H20.57C21.3498 24.738 21.9816 24.1094 21.9816 23.3344V16.2556C21.9816 15.4801 21.3499 14.8519 20.57 14.8519H10.1733C9.39354 14.8519 8.76172 15.4806 8.76172 16.2556V23.3344Z"
        fill={color}
      />
    </svg>
  )
}
