import { IconProps } from './IconProps'

export function IconCheck({ size = 18, color = 'currentColor', ...props }: IconProps) {
  return (
    <svg width={size} height={size} viewBox="0 0 18 19" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.32982 15.8686L0 8.486L2.72982 5.73299L7.32982 10.3661L15.2351 2.40393L17.9649 5.15694L7.32982 15.8686Z"
        fill={color}
      />
    </svg>
  )
}
