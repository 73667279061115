import { IconProps } from './IconProps'

export function IconThumbsDown({ size = 24, color = 'currentColor', ...props }: IconProps) {
  return (
    <svg width={size} height={size} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M18.4742 10.0013C18.3418 10.9301 17.5084 11.7226 16.5075 12.0473C15.9779 12.2194 15.079 12.4483 14.3479 12.6262C15.6885 14.584 15.9523 16.6077 15.4535 17.766C15.1429 18.4879 14.5549 18.9019 13.8405 18.9019C12.4601 18.9019 12.0995 17.7222 11.8361 16.8611C11.7971 16.7333 11.7568 16.6015 11.7128 16.4674C11.4682 15.7254 10.2421 14.3869 8.45235 13.368C7.11274 12.6058 6.11956 11.7717 5.82858 11.5237C5.59567 11.3252 5.49241 11.114 5.49241 10.8329V3.60434C5.49241 3.26693 5.67582 3.01341 5.99082 2.88571C7.16582 2.40937 10.6863 1.45618 12.5968 1.45618C13.5903 1.45618 13.9814 1.61545 14.1896 1.73515C15.0472 2.23007 16.1878 5.03132 17.0223 7.38855C17.9836 8.13209 18.6105 9.04512 18.4742 10.0013ZM15.9696 8.50924C15.8374 8.41759 15.7373 8.28522 15.6832 8.13171C14.9369 5.99926 13.8673 3.48794 13.4367 3.07102C13.373 3.05008 13.1503 2.9934 12.5966 2.9934C11.017 2.9934 8.6736 3.4787 7.42293 3.82004C7.04669 3.92272 6.99846 4.17396 6.99846 4.48715V10.2402C6.99846 10.4156 7.04502 10.5827 7.25528 10.7367C7.73216 11.0861 8.45678 11.6108 9.18551 12.0256C11.1382 13.1369 12.7276 14.7252 13.1403 15.9777C13.188 16.1227 13.2316 16.2651 13.2738 16.4033C13.5681 17.3649 13.6421 17.3649 13.8405 17.3649C13.8931 17.3649 13.9812 17.3649 14.0746 17.1478C14.3982 16.396 14.11 14.5108 12.4559 12.6734C12.2739 12.4711 12.2113 12.1854 12.2921 11.9236C12.3727 11.6617 12.584 11.4634 12.8462 11.4037C12.8688 11.3985 15.1304 10.8817 16.0509 10.5827C16.4894 10.4407 16.7796 10.1569 16.8268 9.82417C16.8865 9.40687 16.574 8.9275 15.9696 8.50924ZM2.40291 10.9507C1.78091 10.8273 1.68701 10.6977 1.68701 10.1721V4.30282C1.68701 4.0115 1.80482 3.80783 2.04636 3.69771C2.4005 3.53626 3.62384 3.37646 3.81974 3.34514C4.11585 3.2978 4.34184 3.41602 4.34184 3.82421V10.6838C4.34184 11.042 4.26453 11.3151 3.88973 11.2298C3.51492 11.1444 2.73643 11.0169 2.40291 10.9507Z"
        fill={color}
      />
    </svg>
  )
}
