import { throttle } from 'lodash'
import moment, { Moment } from 'moment'
import { useCallback, useMemo, useState } from 'react'
import { useDeepCompareEffect } from 'use-deep-compare'

import { useSaveSessionDraftMutation } from '@nuna/api'
import { SessionDraft, errorService } from '@nuna/core'
import { toast } from '@nuna/tunic'

interface Params {
  draft: SessionDraft
  appointmentId: string
  patientId: string
  sessionId?: string
}

export function useSaveSessionDraft({ draft, appointmentId, patientId, sessionId }: Params) {
  const [saveSessionDraftMutation, { loading }] = useSaveSessionDraftMutation()
  const [lastSavedAt, setLastSavedAt] = useState<Moment>()

  const saveDraft = useCallback(
    async (draftValues: SessionDraft) => {
      try {
        await saveSessionDraftMutation({ variables: { patientId, appointmentId, draft: draftValues } })
        setLastSavedAt(moment())
      } catch (e) {
        toast.urgent(errorService.transformGraphQlError(e, 'Failed to save draft'))
      }
    },
    [appointmentId, patientId, saveSessionDraftMutation],
  )

  const throttledSaveDraft = useMemo(() => throttle(saveDraft, 5000, { trailing: true }), [saveDraft])

  useDeepCompareEffect(() => {
    if (sessionId) return

    throttledSaveDraft(draft)
  }, [draft, sessionId, throttledSaveDraft])

  return { lastSavedAt, loading }
}
