import { useNavigate } from 'react-router-dom'

import { useIsAdmin } from '@nuna/auth'
import { CancelPolicyItem, numberService } from '@nuna/core'
import { Card, CardProps, IconPencil, IconPreview, IconToggleButton, csx, makeTypographyComponent } from '@nuna/tunic'

import { CaptionLight } from '../../../../shared/FormComponents'
import { CancelPolicyDate } from './CancelPolicyDate'

const { centsToFormattedDollars } = numberService

interface Props extends CardProps {
  cancelPolicy: CancelPolicyItem
  onEdit?: ((item: CancelPolicyItem) => void) | null
  onViewDetail?: ((item: CancelPolicyItem) => void) | null
}

export function CancelPolicyCard({ cancelPolicy, className, onEdit, onViewDetail, ...props }: Props) {
  const isAdmin = useIsAdmin()
  const navigate = useNavigate()
  const { chargeAmount, noShowChargeAmount, id, isCurrent, isFuture } = cancelPolicy

  return (
    <Card className={csx([className, 'p-3 v-align'])} {...props} depth={isCurrent ? 1 : -0.5}>
      <div>
        <Title>
          {centsToFormattedDollars(chargeAmount, { maximumFractionDigits: 2 })} Late Cancelation /{' '}
          {centsToFormattedDollars(noShowChargeAmount, { maximumFractionDigits: 2 })} No-Show
        </Title>
        <CancelPolicyDate
          className="mt-1"
          cancelPolicy={cancelPolicy}
          descriptionDisplay={description => <CaptionLight>{description}</CaptionLight>}
        />
      </div>
      <span className="ml-auto v-align">
        {!isAdmin && isFuture && (
          <IconToggleButton
            tooltip="Edit"
            onClick={onEdit ? () => onEdit(cancelPolicy) : () => navigate(`${id}/edit`)}
            className="mr-xs edit-button"
            size="md"
          >
            <IconPencil />
          </IconToggleButton>
        )}
        <IconToggleButton
          tooltip="Show Policy Details"
          onClick={onViewDetail ? () => onViewDetail(cancelPolicy) : () => navigate(id)}
          size="md"
          className="details-button"
        >
          <IconPreview />
        </IconToggleButton>
      </span>
    </Card>
  )
}

const Title = makeTypographyComponent('text-primary text-medium', 'div')
