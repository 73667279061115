import { styled } from '@mui/material'

import { body1, greySet } from '@nuna/tunic'

export const MenuButton = styled('button')`
  cursor: pointer;
  font-weight: bold;
  user-select: none;
  height: 2.25rem;
  padding: 0 0.5rem;
  box-sizing: border-box;
  transition: 0.1s;
  border: none;
  color: ${greySet[70].hex};
  border-radius: 0.25rem;
  transition: background 300ms ease;
  display: inline-flex;
  align-items: center;

  &:focus {
    outline: 1px solid #fff;
  }

  &:hover {
    /* color: ${body1}; */
    background-color: ${greySet[15].hex};
  }
`
