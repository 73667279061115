import { FormikProps } from 'formik'
import { isBoolean } from 'lodash'
import { useEffect } from 'react'

import { useHasValidFeePaymentMethod } from '@nuna/fee-policies'

export interface FormValues {
  hasValidPaymentMethod: boolean | null
}

interface Args {
  formProps: Pick<FormikProps<FormValues>, 'setFieldValue'>
  subjectToFee: boolean
}

export function useClientCancelOrReschedulePaymentMethods({ formProps: { setFieldValue }, subjectToFee }: Args) {
  const [hasValidPaymentMethod, paymentMethodDetails] = useHasValidFeePaymentMethod()

  useEffect(() => {
    if (!subjectToFee) {
      setFieldValue('hasValidPaymentMethod', true)
    } else if (isBoolean(hasValidPaymentMethod)) {
      setFieldValue('hasValidPaymentMethod', hasValidPaymentMethod)
    }
  }, [hasValidPaymentMethod, setFieldValue, subjectToFee])

  return paymentMethodDetails
}
