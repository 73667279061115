import { useMemo, useState } from 'react'

import {
  CustomerCompanyDetailsFragment,
  CustomerEmployeeDimension,
  useCustomerEmployeeDimensionsQuery,
} from '@nuna/api'
import { DataTable, DataTableColumn } from '@nuna/data-table'
import { FillButton } from '@nuna/tunic'

import { CustomerEmployeeDimensionForm } from './CustomerEmployeeDimensionForm'
import { CustomerEmployeeDimensionRow } from './CustomerEmployeeDimensionRow'

export function CustomerEmployeeDimensionList({
  customerCompany,
}: {
  customerCompany: CustomerCompanyDetailsFragment
}) {
  const [showNew, setShowNew] = useState(false)
  const { loading, data } = useCustomerEmployeeDimensionsQuery({
    variables: { companyId: customerCompany.id },
  })
  const rowData = (data?.customerEmployeeDimensions as CustomerEmployeeDimension[]) || []

  const columns = useMemo<DataTableColumn<CustomerEmployeeDimension>[]>(
    () => [
      {
        Header: 'Name',
        accessor: 'name',
      },
      {
        Header: 'Custom Field',
        accessor: 'customerEmployeeRecordField',
      },
      {
        Header: 'Mapping',
        accessor: 'valueMapping',
        Cell: ({ value }) => {
          if (value?.type === 'select') {
            return <span>{value.options?.map(opt => opt.label).join(', ') ?? ''}</span>
          }

          return <span>N/A</span>
        },
      },
      {
        Header: 'Edit',
        accessor: 'id',
      },
      {
        Header: <span style={{ paddingRight: '0.5rem' }}>Delete</span>,
        accessor: 'companyId',
        className: 'right-align',
      },
    ],
    [],
  )
  return (
    <div className="flex-column">
      <h1 className="h5">Custom Employee Dimensions</h1>
      <DataTable
        rowData={rowData}
        columns={columns}
        loading={loading}
        customRow={row => <CustomerEmployeeDimensionRow {...row} />}
      />
      {showNew && (
        <CustomerEmployeeDimensionForm
          dimension={{
            companyId: customerCompany.id,
            name: 'Salary Type',
            valueMapping: {},
            customerEmployeeRecordField: 'c1',
          }}
          onDismiss={() => setShowNew(false)}
        />
      )}
      {!showNew && (
        <FillButton style={{ alignSelf: 'end' }} onClick={() => setShowNew(true)}>
          New
        </FillButton>
      )}
    </div>
  )
}
