import { styled } from '@mui/material'
import { HTMLAttributes, ReactNode, useState } from 'react'

import { IconChevronThick } from '../../icons'
import { greySet } from '../../styles/colorSets'
import { csx } from '../../utils/csx'
import { Collapse } from '../Collapse/Collapse'

export interface AccordionLabelProps {
  isOpen: boolean
}

export interface AccordionSection {
  label: (accordionLabelProps: AccordionLabelProps) => ReactNode
  content: ReactNode
  defaultOpen?: boolean
}

export interface AccordionProps extends HTMLAttributes<HTMLDivElement> {
  sections: AccordionSection[]
  buttonProps?: HTMLAttributes<HTMLButtonElement>
  collapseProps?: HTMLAttributes<HTMLDivElement>
}

export function Accordion({ sections, buttonProps = {}, collapseProps = {}, ...props }: AccordionProps) {
  const [openSection, setOpenSection] = useState<number | null>(
    () => sections.findIndex(section => section.defaultOpen) ?? null,
  )

  const toggleSection = (sectionIndex: number) => {
    setOpenSection(openSection === sectionIndex ? null : sectionIndex)
  }

  const { className: buttonClassName, ...buttonRest } = buttonProps
  const { className: collapseClassName, ...collapseRest } = collapseProps

  return (
    <div {...props}>
      {sections.map((section, idx) => {
        const isOpen = openSection === idx
        return (
          <div key={idx} className={csx({ 'mb-2': idx + 1 < sections.length })}>
            <AccordionButton
              type="button"
              onClick={() => toggleSection(idx)}
              className={csx(['text-medium py-1 v-align', buttonClassName])}
              {...buttonRest}
            >
              <IconChevronThick className="mr-1" size={11} direction={isOpen ? 'down' : 'right'} />
              {section.label({ isOpen })}
            </AccordionButton>
            <Collapse className={collapseClassName} isOpen={isOpen} {...collapseRest}>
              {section.content}
            </Collapse>
          </div>
        )
      })}
    </div>
  )
}

const AccordionButton = styled('button')`
  color: ${greySet[80].hex};
  width: 100%;
`
