export class ConversationUnreadStatus {
  unreadCount: number
  lastMessageId: string | null
  lastReadMessageId: string | null

  constructor({
    unreadCount,
    lastMessageId = null,
    lastReadMessageId = null,
  }: {
    unreadCount: number
    lastMessageId: string | null | undefined
    lastReadMessageId: string | null | undefined
  }) {
    this.unreadCount = unreadCount
    this.lastMessageId = lastMessageId
    this.lastReadMessageId = lastReadMessageId
  }

  hasUnread() {
    return this.unreadCount > 0 && this.lastMessageId !== this.lastReadMessageId
  }

  updateLastReadMessageId(lastReadMessageId: string) {
    return new ConversationUnreadStatus({
      unreadCount: this.unreadCount,
      lastMessageId: this.lastMessageId,
      lastReadMessageId,
    })
  }
}
