import { styled } from '@mui/material'

export function Layout({
  children,
  backgroundColor,
  header,
}: {
  children: React.ReactNode
  backgroundColor?: string
  header?: React.ReactNode
}) {
  return (
    <LayoutStyled bgColor={backgroundColor}>
      {header}
      <main className="main flex-column">{children}</main>
    </LayoutStyled>
  )
}

const LayoutStyled = styled('div')<{ bgColor?: string }>`
  width: 100%;
  height: 100%;
  overflow-y: auto;
  ${({ bgColor }) => (bgColor ? `background-color: ${bgColor};` : '')}
  .main {
    flex: 1;
    margin-top: 78px;
  }

  .chat-button {
    position: fixed;
    bottom: 30px;
    right: 30px;
    z-index: 2;
  }
`
