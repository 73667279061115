import React, { HtmlHTMLAttributes } from 'react'
import { NavLink, Path } from 'react-router-dom'

interface LinkProps extends HtmlHTMLAttributes<HTMLAnchorElement> {
  to: string | undefined | Path
  openInNewTab?: boolean
}

export function NewLink({ to = '', openInNewTab = false, children, ...props }: LinkProps) {
  if (openInNewTab) {
    return (
      <a href={to as string} target="_blank" rel="noreferrer noopener" {...props}>
        {children}
      </a>
    )
  }

  return (
    <NavLink to={to} {...props}>
      {children}
    </NavLink>
  )
}
