function getData<T>(key: string, defaultData: T) {
  const raw = localStorage.getItem(key)

  if (!raw) {
    return defaultData
  }

  try {
    return JSON.parse(raw) as T
  } catch {
    console.error(`Unable to parse data from local storage for key ${key}`)
    return defaultData
  }
}

function writeData<T>(key: string, data: T) {
  localStorage.setItem(key, JSON.stringify(data))
}

// will overwrite all previous appointment data that may be in localStorage. This is on purpose to avoid collecting too much old data.
function writeAppointmentData<T>(appointmentId: string, key: string, data: T) {
  writeData(key, { [appointmentId]: data })
}

function getAppointmentData<T>(appointmentId: string, key: string, defaultData: T) {
  const data = getData(key, { [appointmentId]: defaultData })

  return data[appointmentId] ?? defaultData
}

// used when we need to store data that is specific to a user and there may be multiple users using one device
function writeUserData<T>(userId: string, key: string, data: T) {
  const existingData = getData(key, { [userId]: data })
  writeData(key, { ...existingData, [userId]: data })
}

function getUserData<T>(userId: string, key: string, defaultData: T) {
  const data = getData(key, { [userId]: defaultData })

  return data[userId] ?? defaultData
}

export const localStorageService = {
  getAppointmentData,
  getData,
  getUserData,
  writeAppointmentData,
  writeData,
  writeUserData,
}
