import { useMemo } from 'react'
import { resolvePath, useLocation, useResolvedPath } from 'react-router-dom'

import { DataCollectionStep } from '@nuna/api'
import { routeService } from '@nuna/core'

const { caqhSteps } = routeService

const paths = [
  caqhSteps[DataCollectionStep.Started],
  caqhSteps[DataCollectionStep.CaqhCredentials],
  caqhSteps[DataCollectionStep.Resume],
  caqhSteps[DataCollectionStep.ExistingInsurance],
  caqhSteps[DataCollectionStep.Caqh],
  caqhSteps[DataCollectionStep.AttestationSignature],
  caqhSteps[DataCollectionStep.Completed],
]

// relativeParentPath and useResolvedPath allows ashoka and harmony to embed this at different paths
// relativeParentPath is different for the CAQHSetupHeader and routes within the CAQHSetup component, so it must be configurable
export function useCAQHNavigation({ relativeParentPath = '..' } = {}) {
  const location = useLocation()
  const parentPath = useResolvedPath(relativeParentPath).pathname
  const resolvedPaths = useMemo(() => paths.map(path => resolvePath(path, parentPath).pathname), [parentPath])

  const currentPathIndex = resolvedPaths.findIndex(path => location.pathname === path)
  const nextPath: string | undefined = resolvedPaths[currentPathIndex + 1]
  const previousPath: string | undefined = resolvedPaths[currentPathIndex - 1]

  return {
    currentPath: location.pathname,
    currentPathIndex,
    nextPath,
    previousPath,
  }
}
