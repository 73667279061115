import { styled } from '@mui/material'
import { Navigate, Route, Routes, useLocation } from 'react-router-dom'

import { DataCollectionStep, useProviderCredentialingDataQuery } from '@nuna/api'
import { routeService } from '@nuna/core'

import { CAQHSetupContextProvider } from '../../context/CAQHSetupContext'
import { AttestationSignature } from './components/AttestationSignature'
import { CAQHProfile } from './components/CAQHProfile'
import { CAQHSync } from './components/CAQHSync'
import { CAQHSyncIndicator } from './components/CAQHSyncIndicator'
import { CAQHWelcome } from './components/CAQHWelcome'
import { Completed } from './components/Completed'
import { ExistingInsurance } from './components/ExistingInsurance'
import { Intro } from './components/Intro'
import { Resume } from './components/Resume'

const { caqhSteps, caqhSetup } = routeService

interface Props {
  providerId: string
  providerLoginId: string
}

export function CAQHSetup({ providerId, providerLoginId }: Props) {
  const { data } = useProviderCredentialingDataQuery({ variables: { providerId } })
  const location = useLocation()

  if (!data) return null // TODO: loading state

  const currentStepPath = caqhSteps[data.providerCredentialingData?.currentStep ?? DataCollectionStep.Started]
  // we don't want to show the indicator on the profile screen because it already has it's own loading component
  const showSyncIndicator = caqhSetup(caqhSteps[DataCollectionStep.Caqh]) !== location.pathname
  return (
    <CAQHSetupContextProvider providerId={providerId} providerLoginId={providerLoginId}>
      <IntakeStepContainerStyled className="full-width" $centered={false}>
        {showSyncIndicator && <CAQHSyncIndicator />}

        <Routes>
          <Route index element={<Navigate to={currentStepPath} replace />} />
          {/* nothing is pointing to the caqh welcome screen route yet, this will be done in ENG-5603 */}
          <Route path={routeService.caqhWelcome} element={<CAQHWelcome />} />
          <Route path={caqhSteps[DataCollectionStep.Started]} element={<Intro />} />
          <Route path={caqhSteps[DataCollectionStep.CaqhCredentials]} element={<CAQHSync />} />
          <Route path={caqhSteps[DataCollectionStep.ExistingInsurance]} element={<ExistingInsurance />} />

          <Route path={caqhSteps[DataCollectionStep.Resume]} element={<Resume />} />
          <Route path={caqhSteps[DataCollectionStep.Caqh]} element={<CAQHProfile />} />
          <Route path={caqhSteps[DataCollectionStep.AttestationSignature]} element={<AttestationSignature />} />
          <Route path={caqhSteps[DataCollectionStep.Completed]} element={<Completed />} />
        </Routes>
      </IntakeStepContainerStyled>
    </CAQHSetupContextProvider>
  )
}

// TODO: maybe share this if it makes sense with Signup Intake
const IntakeStepContainerStyled = styled('div')<{ $centered: boolean }>`
  max-width: 932px;
  margin-left: auto;
  margin-right: auto;
  padding-left: var(--margin-3);
  padding-right: var(--margin-3);
  flex: 1;

  ${({ $centered }) =>
    $centered
      ? `
  display: flex;
  height: calc(100% - 78px);
  align-items: center;`
      : `
  padding-top: var(--margin-4);
  padding-bottom: var(--margin-4);
      `}
`
