import { styled } from '@mui/material'
import { Route, Routes } from 'react-router-dom'

import { useIsAdmin } from '@nuna/auth'
import { CancelPolicyItem } from '@nuna/core'
import { ContextualAlert } from '@nuna/tunic'

import { CaptionLight } from '../../../../shared/FormComponents'
import { AddNewPolicyButton } from './AddNewPolicyButton'
import { CancelPolicyForm } from './CancelPolicyForm'
import { CancelPolicyListItem } from './CancelPolicyListItem'

interface Props {
  cancelPolicies: CancelPolicyItem[]
  providerId: string
  supressAdd?: boolean
  onEdit?: (cancelPolicy: CancelPolicyItem) => void
  onViewDetail?: (cancelPolicy: CancelPolicyItem) => void
}

export function CancelPolicyList({
  cancelPolicies,
  providerId,
  supressAdd = false,
  onEdit = undefined,
  onViewDetail = undefined,
}: Props) {
  const isAdmin = useIsAdmin()
  const canAdd = !isAdmin && !cancelPolicies.some(policy => policy.isFuture) && !supressAdd
  return (
    <div>
      <Routes>
        <Route path="new" element={<CancelPolicyForm providerId={providerId} className="mb-1" />} />
        <Route path="/*" element={canAdd && <AddNewPolicyButton className="mb-1" />} />
      </Routes>
      {isAdmin && cancelPolicies.length === 0 && <ContextualAlert>Not set up</ContextualAlert>}
      <StyledList>
        {cancelPolicies.map(cancelPolicy => (
          <CancelPolicyListItem
            key={cancelPolicy.id}
            cancelPolicy={cancelPolicy}
            providerId={providerId}
            className="cancel-policy"
            data-testid={`cancel-policy-${cancelPolicy.id}`}
            onEdit={onEdit}
            onViewDetail={onViewDetail}
          />
        ))}
      </StyledList>

      {!isAdmin && (
        <CaptionLight className="mt-xs">
          You may choose to waive fees for each session for up to 72 hours after a client cancels.
        </CaptionLight>
      )}
    </div>
  )
}

const StyledList = styled('div')`
  .cancel-policy + .cancel-policy {
    margin-top: var(--spacing-1);
  }
`
