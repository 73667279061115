import { useEffect, useState } from 'react'

import { usePatientSessionNotesQuery } from '@nuna/api'
import { SessionDraft } from '@nuna/core'
import { SessionNoteHistory, useSaveSessionDraft } from '@nuna/session-notes'
import { Box } from '@nuna/tunic'

import { useVideoCallContext } from '../../../../../context/VideoCallContext'
import { PatientVideoUser } from '../../../../../types'

interface Props {
  draft: SessionDraft
  patient: PatientVideoUser
}

export function NoteForm({ draft, patient }: Props) {
  const [note, setNote] = useState(draft.note)
  const { appointmentId } = useVideoCallContext()
  const { data: pastSessionNotesData, error: pastSessionNotesError } = usePatientSessionNotesQuery({
    variables: { id: patient.patientId },
  })

  useEffect(() => {
    if (pastSessionNotesError) {
      console.error('Could not load past session notes', pastSessionNotesError)
    }
  }, [pastSessionNotesError])

  const pastSessionNotes = pastSessionNotesData?.patient.sessions ?? []

  const { lastSavedAt, loading } = useSaveSessionDraft({
    draft: { ...draft, note },
    patientId: patient.patientId,
    appointmentId,
  })

  return (
    <Box sx={{ overflow: 'auto', pt: 1 }}>
      <SessionNoteHistory
        isEditable
        pastSessionNotes={pastSessionNotes}
        onChange={e => setNote(e.currentTarget.value)}
        noteDraft={note ?? ''}
        lastSavedAt={lastSavedAt}
        isSaving={loading}
      />
    </Box>
  )
}
