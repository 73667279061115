import { Card, CardProps, IconCreditCard, csx } from '@nuna/tunic'

export function NoCreditCardCard({ className, ...props }: CardProps) {
  return (
    <Card className={csx(['p-2 v-align', className])} {...props}>
      <IconCreditCard showBan size={32} className="flex-static mr-2" />
      <div>
        <div className="mb-xs text-medium">No Card on File</div>
        <div className="text-secondary caption">
          We'll do our best to track down the payment, but you may not get paid right way.
        </div>
      </div>
    </Card>
  )
}
