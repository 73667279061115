import { styled } from '@mui/material'

import { InsuranceCoverageDetailsFragment } from '@nuna/api'

import { CoverageLogoRatio } from '../../CoverageTypography'
import insuranceBGSrc from '../img/insurance-bg.png'
import { BaseCard, BaseCardProps } from './BaseCard'

interface Props extends BaseCardProps {
  details: InsuranceCoverageDetailsFragment
}

export function InsuranceCard({ details, ...props }: Props) {
  return (
    <CardWithBG {...props}>
      <div className="flex-column full-height">
        {details.insuranceLogoUrl ? (
          <CoverageLogoRatio src={details.insuranceLogoUrl} alt={details.planName} landscapeMax="50%" />
        ) : (
          <span>{details.planName}</span>
        )}

        <span className="mt-auto">
          {details.subscriber}
          <br />
          {details.planMemberId}
        </span>
      </div>
    </CardWithBG>
  )
}

const CardWithBG = styled(BaseCard)`
  background-image: url(${insuranceBGSrc});
  background-size: contain;
  background-position: right center;
  background-repeat: no-repeat;
`
