import { css, styled } from '@mui/material'
import { isNil } from 'lodash'
import React, { ReactNode, useEffect } from 'react'

import { IconChevronThick } from '../../icons'
import { greySet, tealSet, whiteTintSet } from '../../styles/colorSets'
import { body1, body2, borderGrey } from '../../styles/colors'
import { fontSize } from '../../styles/global/typography.utils'
import { shadowDepth } from '../../styles/shadows'
import { Scheme } from '../../types/Scheme'
import { csx } from '../../utils/csx'
import { Avatar } from '../Avatar/Avatar'
import { ButtonBase, ButtonProps } from '../Button/ButtonBase'
import { Tooltip } from '../Tooltip/Tooltip'

type ToggleButtonSizes = 'sm' | 'md' | 'lg'
type ToggleButtonType = 'circle' | 'icon' | 'default'

interface ToggleButtonExlusiveProps {
  isActive?: boolean
  scheme?: Scheme
  size?: ToggleButtonSizes
}
export type ToggleButtonProps = ButtonProps & ToggleButtonExlusiveProps & { tooltip: string }
export interface DropdownButtonProps extends Omit<ToggleButtonProps, 'tooltip'> {
  tooltip?: string
  rightSlot?: ReactNode
}
export type CircleToggleButtonProps = ToggleButtonProps
export interface IconToggleButtonProps extends Omit<ToggleButtonProps, 'children'> {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  children: React.ReactElement<any, string | React.JSXElementConstructor<any>>
}
export interface AvatarToggleButtonProps extends Omit<ToggleButtonProps, 'size'> {
  avatarUrl?: string
}

interface ButtonTypeProps {
  buttonType: ToggleButtonType
}

type StyledToggleButtonProps = ButtonProps & ToggleButtonExlusiveProps & ButtonTypeProps
type ToggleButtonBaseProps = ToggleButtonProps & ButtonTypeProps

const styleMap = {
  sizes: {
    sm: {
      padding: 7,
      fontSize: fontSize.caption,
      size: 32,
      iconSize: 16,
    },
    md: {
      padding: 7,
      fontSize: fontSize.body,
      size: 36,
      iconSize: 20,
    },
    lg: {
      padding: 10,
      fontSize: fontSize.large,
      size: 46,
      iconSize: 20,
    },
  },
  colors: {
    border: {
      light: {
        default: borderGrey,
        active: tealSet[50].hex,
      },
      dark: {
        default: whiteTintSet[30],
        active: tealSet[15].hex,
      },
    },
    content: {
      default: {
        light: {
          default: body1,
          active: tealSet[80].hex,
        },
        dark: {
          default: greySet[30].hex,
          active: tealSet[80].hex,
        },
      },
      icon: {
        light: {
          default: body2,
          active: tealSet[80].hex,
        },
        dark: {
          default: greySet[30].hex,
          active: tealSet[80].hex,
        },
      },
      circle: {
        light: {
          default: body1,
          active: tealSet[80].hex,
        },
        dark: {
          default: '#fff',
          active: tealSet[90].hex,
        },
      },
    },
    background: {
      light: {
        default: 'transparent',
        active: tealSet[5].hex,
        hover: '#fff',
        activeHover: tealSet.tint[40],
      },
      dark: {
        default: 'transparent',
        active: tealSet[30].hex,
        hover: 'transparent',
        activeHover: tealSet[50].hex,
      },
    },
  },
}

const StyledToggleButton = styled(ButtonBase, {
  shouldForwardProp: prop => prop !== 'buttonType',
})<StyledToggleButtonProps>`
  ${({ buttonType, scheme = 'light', size = 'sm' }) => css`
    ${getColor(buttonType, scheme)}
    ${getDimensions(size, buttonType)}
    ${getBordersAndShadows(buttonType, size, scheme)}
  `}
  align-items: center;
  display: flex;
`

export const ToggleButtonBase = React.forwardRef<HTMLButtonElement, Omit<ToggleButtonBaseProps, 'as'>>(
  ({ tooltip, isActive, ...props }, ref) => {
    const className = csx([props.className, { active: !!isActive }])
    if (!tooltip) {
      return <StyledToggleButton ref={ref} {...props} className={className} />
    }
    return (
      <Tooltip content={tooltip}>
        <StyledToggleButton aria-label={tooltip} ref={ref} {...props} className={className} />
      </Tooltip>
    )
  },
)

export const ToggleButton = React.forwardRef<HTMLButtonElement, ToggleButtonProps>((props, ref) => {
  return <ToggleButtonBase {...props} buttonType="default" ref={ref} />
})

export const IconToggleButton = React.forwardRef<HTMLButtonElement, IconToggleButtonProps>(
  ({ children, ...props }, ref) => {
    const validIcon = !isNil(children) && typeof children === 'object'

    useEffect(() => {
      if (!validIcon) {
        console.warn('Icon sizing may not work as expected for IconToggleButton due to invalid icon')
      }
    }, [validIcon])

    return (
      <ToggleButtonBase {...props} buttonType="icon" ref={ref}>
        {validIcon ? React.cloneElement(children, { size: styleMap.sizes[props.size ?? 'sm'].iconSize }) : children}
      </ToggleButtonBase>
    )
  },
)

export const AvatarToggleButton = React.forwardRef<HTMLButtonElement, AvatarToggleButtonProps>(
  ({ avatarUrl, ...props }, ref) => {
    return (
      <ToggleButtonBase {...props} buttonType="icon" size="lg" ref={ref}>
        <Avatar size="mini" url={avatarUrl} />
      </ToggleButtonBase>
    )
  },
)

export const CircleToggleButton = React.forwardRef<HTMLButtonElement, CircleToggleButtonProps>(
  ({ size = 'lg', ...props }, ref) => {
    return (
      <ToggleButtonBase
        {...props}
        buttonType="circle"
        size={size}
        className={csx([props.className, 'text-light'])}
        ref={ref}
      />
    )
  },
)

export const DropdownButton = React.forwardRef<HTMLButtonElement, DropdownButtonProps>(
  ({ children, rightSlot, ...props }, ref) => {
    return (
      <ToggleButton {...props} ref={ref} tooltip="">
        {children}
        {rightSlot && rightSlot}
        {!rightSlot && <IconChevronThick direction="down" className="ml-2" size={11} />}
      </ToggleButton>
    )
  },
)

function getColor(type: ToggleButtonType, scheme: Scheme) {
  const defaultColor = styleMap.colors.content[type][scheme].default
  const activeColor = styleMap.colors.content[type][scheme].active
  const backgroundColors = styleMap.colors.background[scheme]

  return css`
    color: ${defaultColor};
    background-color: ${backgroundColors.default};

    &:hover {
      ${scheme === 'dark' && type === 'icon' ? 'color: #fff;' : ''}
      background-color: ${backgroundColors.hover};
    }
    &.active {
      color: ${activeColor};
      background-color: ${backgroundColors.active};
      &:hover {
        background-color: ${backgroundColors.activeHover};
      }
    }
  `
}

function getDimensions(size: ToggleButtonSizes, type: ToggleButtonType) {
  const sizes = styleMap.sizes[size]
  return css`
    padding: ${sizes.padding}px;
    height: ${sizes.size}px;
    min-width: ${sizes.size}px;
    font-size: ${sizes.fontSize};
    ${type === 'default'
      ? `
      min-width: ${sizes.size}px;
    `
      : `
        width: ${sizes.size}px;
      `}
  `
}

function getBordersAndShadows(type: ToggleButtonType, size: ToggleButtonSizes, scheme: Scheme) {
  const isMediumOrLageIcon = ['md', 'lg'].includes(size) && type === 'icon'
  const borderRadius = () => {
    if (type === 'circle') {
      return '50%'
    }

    if (size === 'lg') {
      return '10px'
    }

    return 'var(--border-radius-sm)'
  }
  const borderColor = (variation: 'default' | 'active' | 'hover') =>
    isMediumOrLageIcon && variation === 'default'
      ? 'transparent'
      : styleMap.colors.border[scheme][variation === 'hover' ? 'default' : variation]

  const shadow = (depth: 1 | 1.5) => (scheme === 'light' ? shadowDepth(depth, 'warm') : shadowDepth(depth, 'cool'))

  return css`
    border: 1px solid ${borderColor('default')};
    border-radius: ${borderRadius()};

    &:hover {
      border-color: ${borderColor('hover')};
    }

    &:hover,
    &.focus-visible {
      box-shadow: ${shadow(1.5)};
      &::after {
        border: 2px solid ${borderColor('hover')};
        border-radius: ${borderRadius()};
        content: '';
        display: block;
        height: calc(100% + 4px);
        width: calc(100% + 4px);
        left: -2px;
        top: -2px;
        position: absolute;
      }
    }

    &.active {
      box-shadow: ${shadow(1)};
      border-color: ${borderColor('active')};
      &:hover,
      &.focus-visible {
        box-shadow: ${shadow(1.5)};
        &::after {
          border-color: ${borderColor('active')};
        }
      }
      ${size === 'lg'
        ? `
        &::after {
          border: 2px solid ${borderColor('active')};
          border-radius: ${borderRadius()};
          content: '';
          display: block;
          height: calc(100% + 2px);
          width: calc(100% + 2px);
          left: -1px;
          top: -1px;
          position: absolute;
        }

        &:hover::after {
          height: calc(100% + 4px);
          width: calc(100% + 4px);
          left: -2px;
          top: -2px;
        }
      `
        : ''}
    }
  `
}
