import { styled } from '@mui/material'

import { Phone, fontSize, makeTypographyComponent } from '@nuna/tunic'

export const InputHeading = styled(makeTypographyComponent('h6 sans-serif', 'h3'))`
  font-size: ${fontSize.h6};

  @media (${Phone}) {
    font-size: ${fontSize.body};
  }
`
