import * as Yup from 'yup'
import { useFormikContext } from 'formik'

import { PatientIntakeContractFragment } from '@nuna/api'
import { formService } from '@nuna/core'
import { TextField } from '@nuna/tunic'

import { CoverageFormValues, VerificationStatus } from '../CoverageForm'
import { CoverageSectionHeading } from './SharedStyles'

const { composeNestedHelperTextWithError, oopsRequired } = formService

export interface AccessCodeCoverageFormValues {
  accessCode: string
  accessCodeStatus: VerificationStatus | null
  couponContract: PatientIntakeContractFragment | null
}

export const accessCodeSchema = Yup.object().shape<NonNullable<CoverageFormValues['accessCodeValues']>>({
  accessCode: Yup.string().when('couponContract', {
    is: null,
    then: Yup.string().required(oopsRequired('access code')),
  }),
  accessCodeStatus: Yup.string<VerificationStatus>().nullable(),
  couponContract: Yup.object<PatientIntakeContractFragment>().nullable(),
})

export const accessCodeInitialValues = {
  accessCode: '',
  couponContract: null,
  accessCodeStatus: null,
}

interface AccessCodeCoverageFormProps {
  isCheckout: boolean
}

export function AccessCodeCoverageForm({ isCheckout }: AccessCodeCoverageFormProps) {
  const { values, errors, touched, handleChange } = useFormikContext<CoverageFormValues>()

  const { error, helperText } = composeNestedHelperTextWithError('', errors, touched, 'accessCodeValues', 'accessCode')

  return (
    <section>
      <CoverageSectionHeading isCheckout={isCheckout} className="mt-5">
        Redeem your access code
      </CoverageSectionHeading>
      <TextField
        label="Access code"
        onChange={handleChange}
        value={values.accessCodeValues?.accessCode ?? ''}
        name="accessCodeValues.accessCode"
        error={(isCheckout && values.accessCodeValues?.accessCodeStatus === 'invalid') || error}
        inputProps={{
          'data-testid': 'coverage-code-input',
        }}
        helperText={
          isCheckout && values.accessCodeValues?.accessCodeStatus === 'invalid'
            ? 'Sorry, we couldn’t find that access code. Please check for errors and try again.'
            : helperText
        }
      />
    </section>
  )
}
