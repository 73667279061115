import { LoginData } from '@nuna/auth'

import { defineAbilityFor, updateAbilityFor } from './ability'

/** When this runs prior to login it creates
 * a CustomCan component that has no abilities which
 * denies everyone regardless of role after login */

const ability = defineAbilityFor(undefined)

export function updateAbility(user: LoginData) {
  updateAbilityFor(user, ability)
}
