import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext'
import { styled } from '@mui/material'
import { Link } from 'react-router-dom'

import {
  AssessmentBundleQuery,
  AssessmentBundleType,
  AssessmentStatus,
  Role,
  useAssessmentBundleQuery,
} from '@nuna/api'
import { useUserRole } from '@nuna/auth'
import { routeService } from '@nuna/core'
import { IconAssessment, IconCheckCircle, IconClose, IconDivider, greySet, tealSet, white } from '@nuna/tunic'

import { REMOVE_ASSESSMENT_COMMAND } from '../plugins/AssessmentPlugin'

export type AssessmentDisplayData = AssessmentBundleQuery | { assessmentBundle: { type: AssessmentBundleType } } | null

export function LexicalAssessment({
  assessmentId,
  assessmentType,
  nodeKey,
  showRemoveAssessment = true,
}: {
  assessmentId: string | null
  assessmentType: AssessmentBundleType
  nodeKey: string
  showRemoveAssessment?: boolean
}) {
  const [editor] = useLexicalComposerContext()
  const { data: assessmentData } = useAssessmentBundleQuery({
    variables: {
      bundleId: assessmentId || '',
    },
    skip: !assessmentId,
  })
  const loggedInRole = useUserRole()
  const isPatient = loggedInRole === Role.Patient
  const { patientId } = assessmentData?.assessmentBundle.assessments[0] ?? { patientId: '' }
  const defaultAssessmentLink = isPatient
    ? routeService.assessmentBundle(assessmentId || '')
    : routeService.therapistAssessmentDashboardForClient(patientId || '')
  const completedLink = isPatient
    ? routeService.assessments
    : routeService.therapistAssessmentDashboardForClient(patientId || '')

  // Had to do this ternary to shut typescript up about other enums
  const status =
    assessmentData?.assessmentBundle?.status === AssessmentStatus.Completed
      ? AssessmentStatus.Completed
      : AssessmentStatus.NotCompleted
  const isComplete = status === AssessmentStatus.Completed
  const assessmentLink = isComplete ? completedLink : defaultAssessmentLink

  const assessmentContent = (
    <AssessmentWrapper isComplete={isComplete}>
      <div className="v-align">
        {!isComplete ? (
          <IconAssessment size={24} color={tealSet[30].hex} />
        ) : (
          <IconCheckCircle size={24} color={tealSet[50].hex} />
        )}
        <h6 className="ml-1 body text-medium p-0 mb-0">{assessmentBundleTypeDisplayLookup[assessmentType]}</h6>
      </div>
      <div className="caption mt-2 v-align" style={{ justifyContent: 'space-between' }}>
        <span>{assessmentStatusDisplayLookup[status]}</span>
        <IconDivider color={tealSet[30].hex} size={16} />
      </div>
      {!assessmentId && showRemoveAssessment && (
        <RemoveAssessment onClick={() => editor.dispatchCommand(REMOVE_ASSESSMENT_COMMAND, nodeKey)}>
          <IconClose color={white.hex} size={8} />
        </RemoveAssessment>
      )}
    </AssessmentWrapper>
  )

  return assessmentId ? (
    <Link to={assessmentLink} style={{ display: 'inline-block' }}>
      {assessmentContent}
    </Link>
  ) : (
    assessmentContent
  )
}

const assessmentBundleTypeDisplayLookup = {
  [AssessmentBundleType.MentalHealthAssessment]: 'Mental Health Assessment',
  [AssessmentBundleType.SuicidalityAssessment]: 'Suicidality Assessment',
}

const assessmentStatusDisplayLookup = {
  [AssessmentStatus.Completed]: 'View progress',
  [AssessmentStatus.NotCompleted]: 'Take the assessment now',
}

type AssessmentWrapperProps = {
  isComplete: boolean
}

const AssessmentWrapper = styled('div')<AssessmentWrapperProps>`
  background-color: ${props => (props.isComplete ? greySet[5].hex : tealSet[70].hex)};
  border: ${props => (props.isComplete ? `1px solid ${greySet[30].hex}` : 'none')};
  border-radius: var(--border-radius-sm);
  color: ${props => (props.isComplete ? tealSet[70].hex : white.hex)};
  display: block;
  font-weight: 500;
  padding: var(--spacing-1) var(--spacing-2);
  margin-top: var(--spacing-2);
  width: fit-content;
  position: relative;

  &:hover {
    background-color: ${props => (props.isComplete ? greySet[15].hex : tealSet[70].hover)};
  }

  &:focus {
    outline: none;
    box-shadow: 0 0 0 6px ${tealSet[15].hex};
  }
`

const RemoveAssessment = styled('button')`
  height: 18px;
  width: 18px;
  position: absolute;
  top: -9px;
  right: -9px;
  background-color: ${tealSet[50].hex};
  border-radius: 100%;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;

  &:hover {
    background-color: ${tealSet[50].hover};
  }
`
