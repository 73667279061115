import { noop } from 'lodash'
import moment from 'moment'
import { useNavigate, useParams } from 'react-router-dom'

import { SessionWithPatientLastLoginAtQuery, useSessionWithPatientLastLoginAtQuery } from '@nuna/api'
import { useTitle } from '@nuna/common'
import { parseTime } from '@nuna/tunic'

import { SessionData } from '../types'
import { SessionNote } from './SessionNote'
import { SessionNotesSkeleton } from './SessionNotesFormSkeleton'

interface Props {
  onCancelRedirectLocation?: () => string
}

export function EditSessionNotes({ onCancelRedirectLocation = () => '' }: Props) {
  useTitle('Edit Session Note')
  const navigate = useNavigate()
  const { id = '' } = useParams<{ id: string }>()
  const {
    data,
    loading: sessionLoading,
    refetch: refetchSession,
  } = useSessionWithPatientLastLoginAtQuery({ variables: { id }, skip: !id })

  const loading = sessionLoading || !data

  if (loading || !data) {
    return <SessionNotesSkeleton />
  }
  const { session } = data

  return (
    <SessionNote
      sessionData={buildSessionDataFromSavedSession(session)}
      onMarkedAsNoShow={noop}
      onCanceled={noop}
      onCancelEdit={() => {
        const location = onCancelRedirectLocation()
        if (location) {
          navigate(location)
        }
      }}
      onPublished={() => {
        refetchSession()
      }}
    />
  )
}

function buildSessionDataFromSavedSession(session: SessionWithPatientLastLoginAtQuery['session']): SessionData {
  return {
    addressId: session.appointment.address?.id ?? 'Virtual',
    appointmentId: session.appointment.id,
    billableFieldsDisabled: !session.billableFieldsEditable,
    coverageType: session.coverageType ?? undefined,
    diagnoses: session.diagnoses,
    endTime: parseTime(moment(session.endTime)) ?? '',
    locked: session.locked,
    patient: session.patient,
    provider: session.provider,
    scheduledEndTime: session.appointment.endDatetime,
    scheduledStartTime: session.appointment.startDatetime,
    sessionId: session.id,
    sessionSubmittedAt: moment(session.updatedAt),
    startTime: parseTime(moment(session.startTime)) ?? '',
    therapyTypeSpecialtyId: session.therapyTypeSpecialty?.id ?? '',
    workingDraft: session.note,
  }
}
