import { styled } from '@mui/material'
import { IconButton } from '@mui/material'
import { sortBy } from 'lodash'
import moment from 'moment'
import React, { useState } from 'react'

import { usePatientSessionNotesQuery } from '@nuna/api'
import { Link } from '@nuna/common'
import { routeService } from '@nuna/core'
import { IconChevronThick, greySet, sansSerifFontStack, serifFontStack } from '@nuna/tunic'

import { HeaderLink, SidebarCard } from '../SidebarCard'

interface ProgressNotesGlanceProps extends React.HTMLAttributes<HTMLDivElement> {
  patientId: string
}

export function ProgressNotesGlance({ patientId }: ProgressNotesGlanceProps) {
  const { data, loading, error } = usePatientSessionNotesQuery({ variables: { id: patientId } })
  const [noteIndex, setNoteIndex] = useState(0)

  if (!data || loading) {
    return <div className="loading" style={{ height: 253, borderRadius: 6 }}></div>
  }

  if (error) {
    return (
      <SidebarCard padded={false}>
        <NoNotesWrapper>
          <h3 className="body">There was a problem loading previous session notes</h3>
        </NoNotesWrapper>
      </SidebarCard>
    )
  }

  const {
    patient: { sessions },
  } = data

  if (!sessions.length) {
    return (
      <SidebarCard padded={false}>
        <NoNotesWrapper>
          <h3 className="body">No previous notes</h3>
        </NoNotesWrapper>
      </SidebarCard>
    )
  }

  const sortedSessions = sortBy(sessions, session => session.startTime)
    .reverse()
    .filter(session => !!session.note)
  const noteCount = sortedSessions.length
  const currentSession = sortedSessions[noteIndex]
  const timeStamp = moment(currentSession.date).format('dddd, MMM Do')

  const paginator = () =>
    noteCount > 1 ? (
      <Paginator>
        <IconButton disabled={noteIndex + 1 >= noteCount} onClick={() => setNoteIndex(noteIndex + 1)}>
          <IconChevronThick direction="left" size={16} />
        </IconButton>
        <PaginatorButtonDivider />
        <IconButton disabled={noteIndex === 0} onClick={() => setNoteIndex(noteIndex - 1)}>
          <IconChevronThick direction="right" size={16} />
        </IconButton>
      </Paginator>
    ) : null

  return (
    <SidebarCard padded={false}>
      <Header multiNote={noteCount > 1}>
        {timeStamp}
        <HeaderLink as={Link} to={routeService.sessionDetailRoute(currentSession.id)} openInNewTab>
          View
        </HeaderLink>
        {paginator()}
      </Header>
      <section>
        <NoteWrapper>
          <h4>Progress Note</h4>
          <p className="fs-exclude">{currentSession?.note}</p>
          <h4>Diagnoses</h4>
          <p className="fs-exclude">{currentSession?.diagnoses.map(d => d.label).join(', ')}</p>
          <h4>Plan</h4>
          <p className="fs-exclude">{currentSession?.plan}</p>
        </NoteWrapper>
      </section>
    </SidebarCard>
  )
}

const NoteWrapper = styled('div')`
  height: 200px;
  overflow: auto;
  padding: 0 0 var(--margin-2) 0;
  word-wrap: break-word;

  h4 {
    font-family: ${sansSerifFontStack};
    font-size: 1rem;
    margin-bottom: var(--margin-3);
  }

  p {
    margin-bottom: var(--margin-2);
  }
`

const Header = styled('header')<{ multiNote: boolean }>`
  align-items: center;
  font-family: ${serifFontStack};
  border-bottom: 1px solid ${greySet[15].hex};
  display: flex;
  padding: ${props => (props.multiNote ? '0 0 0 1rem' : '1rem')};
`
Header.defaultProps = { className: 'text-dark' }

const Paginator = styled('span')`
  align-items: center;
  color: ${greySet[70].hex};
  display: flex;
  margin-left: auto;
`

const PaginatorButtonDivider = styled('span')`
  background-color: ${greySet[30].hex};
  width: 1px;
  height: 20px;
`

const NoNotesWrapper = styled('section')`
  background-color: ${greySet[15].hex};
  text-align: center;

  h3 {
    font-size: 1rem;
    margin-bottom: 0;
  }
`
