import { styled } from '@mui/material'
import { HTMLAttributes, ReactNode, useState } from 'react'
import Dropzone, { DropzoneProps, FileRejection } from 'react-dropzone'

import { greySet, tealSet } from '../../styles/colorSets'
import { body2, borderGrey, eggshell, interactiveText } from '../../styles/colors'
import { fontSize } from '../../styles/global/typography.utils'
import { csx } from '../../utils/csx'
import { AttachmentChip } from '../AttachmentChip/AttachmentChip'
import { GhostButton, TextButton } from '../Button'
import { Card } from '../Card/Card'
import { FileTypeIcon } from '../FileTypeIcon/FileTypeIcon'
import { toast } from '../Toast'

type FileUploadSize = 'sm' | 'md' | 'lg'
type Fit = 'fixed' | 'cover'

export interface FileUploadProps extends Omit<DropzoneProps, 'onDrop'> {
  savedFiles?: SavedFile[]
  onDrop: (files: File[]) => void
  accept?: string | string[]
  size?: FileUploadSize
  requirementDescription?: string
  fit?: Fit
  getFileDisplay?: FileUploadDisplayFunction
  renderInlineDisplay?: boolean
  clearOnDrop?: boolean
}

export interface SavedFile {
  url: string
  mimeType?: string
  name?: string
  fileName?: string
}

export interface FileUploadDisplayProps {
  files?: File[]
  savedFiles?: SavedFile[]
  size: FileUploadSize
  fit: Fit
  replaceButtonProps: HTMLAttributes<HTMLButtonElement>
}

export type FileUploadDisplayFunction = (fileDisplayProps: FileUploadDisplayProps) => ReactNode

export function FileUpload({
  onDrop,
  size = 'sm',
  accept = '',
  requirementDescription = '',
  fit = 'fixed',
  getFileDisplay = defaultFileDisplay,
  savedFiles,
  renderInlineDisplay = true,
  clearOnDrop = false,
  ...props
}: FileUploadProps) {
  const [files, setFiles] = useState<File[]>([])
  const [isHovering, setIsHovering] = useState(false)

  const handleDrop = (input: File[], [rejection]: FileRejection[]) => {
    if (rejection) {
      rejection.errors.forEach(error => {
        let message
        if (error.code === 'file-too-large' && props.maxSize) {
          // the default message shows the size in Bytes which is not very human-friendly
          message = `File is larger than ${props.maxSize / 1000000}MB`
        } else {
          message = error.message
        }

        toast.urgent(message)
      })
      return
    }
    if (!clearOnDrop) {
      setFiles(input)
    }

    onDrop(input)
  }

  const enableHovering = () => setIsHovering(true)
  const disableHovering = () => setIsHovering(false)

  const getUploadIconSize = () => {
    if (size === 'sm') {
      return 20
    }
    if (size === 'md') {
      return 48
    }

    return 58
  }

  const isMultiple = !!(size !== 'sm' && props.multiple)
  let inlineDisplay: ReactNode | null = null

  return (
    <Dropzone
      accept={accept}
      onDrop={handleDrop}
      onDragEnter={enableHovering}
      onDragLeave={disableHovering}
      onDropAccepted={disableHovering}
      onDropRejected={disableHovering}
      noKeyboard // noKeyboard to avoid the double focus on this and the TextButton
      {...props}
    >
      {({ getRootProps, getInputProps }) => {
        const { onClick, ...rest } = getRootProps()

        if (renderInlineDisplay && (files.length || (savedFiles && savedFiles.length))) {
          inlineDisplay = getFileDisplay({
            files,
            savedFiles: files.length ? [] : savedFiles,
            size,
            fit,
            replaceButtonProps: { onClick },
          })
        }

        return (
          <FileUploadContainer size={size} fit={fit} {...rest}>
            {inlineDisplay ? (
              inlineDisplay
            ) : (
              <FileDropZone onClick={onClick} size={size} className={csx({ 'is-file-hovering': isHovering })}>
                <div className="inner-wrapper">
                  <span className="content" style={{ opacity: props.disabled ? '0.5' : 'initial' }}>
                    <UploadGraphic width={getUploadIconSize()} color={interactiveText} />
                    <span className="upload-instructions text-medium">
                      Drag to upload or
                      <TextButton className="ml-xs">{isMultiple ? 'choose files' : 'choose a file'}</TextButton>
                    </span>
                    {requirementDescription && size !== 'sm' && (
                      <span className="requirement-description italic">{requirementDescription}</span>
                    )}
                  </span>
                </div>
              </FileDropZone>
            )}
            <input {...getInputProps()} multiple={isMultiple} />
          </FileUploadContainer>
        )
      }}
    </Dropzone>
  )
}

const FileUploadContainer = styled('div')<{ size: FileUploadSize; fit: Fit }>`
  height: ${({ size, fit }) => (fit === 'cover' ? '100%' : sizeSwitch(size, ['49px', '179px', '261px']))};
  width: ${({ size, fit }) => (fit === 'cover' ? '100%' : sizeSwitch(size, ['100%', '100%', '100%']))};
`

const FileDropZone = styled('div')<{ size: FileUploadSize }>`
  align-items: center;
  background: white;
  border-radius: 12px;
  border: dashed 1px ${borderGrey};
  color: ${body2};
  cursor: pointer;
  font-size: 14px;
  height: 100%;
  width: 100%;
  justify-content: center;
  line-height: 1;
  padding: 0 1rem;
  transition-property: padding, border-color;
  transition-duration: 0.3s;
  padding: ${({ size }) => {
    if (size === 'sm') {
      return `var(--spacing-half)`
    }

    if (size === 'md') {
      return `var(--spacing-three-quarter)`
    }

    if (size === 'lg') {
      return `var(--spacing-1)`
    }

    return ''
  }};

  .inner-wrapper {
    background-color: ${eggshell};
    transition: background-color 0.3s;
    border-radius: var(--border-radius);
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    padding-top: 1rem;
    padding-bottom: 1rem;
  }

  .content {
    .upload-instructions {
      color: ${body2};
      transition: color 0.3s;
    }

    .requirement-description {
      font-size: ${fontSize.caption};
      color: ${greySet[50].hex};
      transition: color 0.3s;
    }

    ${({ size }) => {
      if (size === 'sm') {
        return `
          align-items: center;
          display: flex;
          .upload-instructions {
            margin-left: 4px;
            font-size: ${fontSize.caption};
          }
        `
      }

      if (size === 'md') {
        return `
          align-items: center;
          display: flex;
          flex-direction: column;
          .upload-instructions {
            font-size: ${fontSize.body};
            text-align: center;
            width: 128px;
            line-height: 1.3;
            margin-top: var(--spacing-2);
          }
          .requirement-description {
            margin-top: calc(var(--spacing-1) + var(--spacing-half));
          }
        `
      }

      if (size === 'lg') {
        return `
          align-items: center;
          display: flex;
          flex-direction: column;
          .upload-instructions {
            font-size: 18px;
            text-align: center;
            margin-top: var(--spacing-2);
          }
          .requirement-description {
            margin-top: calc(var(--spacing-1) + var(--spacing-half));
          }
        `
      }

      return ''
    }}
  }

  &.is-file-hovering {
    border-width: 2px;
    border-color: ${tealSet[50].hex};
    padding: ${({ size }) => {
      if (size === 'sm') {
        return `3px`
      }

      if (size === 'md') {
        return `var(--spacing-half)`
      }

      if (size === 'lg') {
        return `var(--spacing-half)`
      }

      return ''
    }};

    .inner-wrapper {
      background-color: ${tealSet.tint[20]};
    }

    .content {
      .upload-instructions {
        color: ${tealSet[90].hex};
      }
      .requirement-description {
        font-size: ${fontSize.caption};
        color: ${tealSet[50].hex};
        transition: color 0.3s;
      }
    }
  }
`

function sizeSwitch(size: FileUploadSize, rVals: string[]) {
  const sizeArr: FileUploadSize[] = ['sm', 'md', 'lg']

  return rVals[sizeArr.indexOf(size)] ?? ''
}

interface UploadGraphicProps {
  color: string
  width: number
}
function UploadGraphic({ color, width }: UploadGraphicProps) {
  const height = Math.ceil(width * (31 / 48))
  return (
    <svg width={width} height={height} viewBox="0 0 58 38" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M34.1378 37.6016C33.8608 37.6028 33.6355 37.3786 33.6355 37.1016V27.6482C33.6355 27.3812 33.8519 27.1649 34.1189 27.1649H38.7421C38.8668 27.1649 38.9866 27.1167 39.0766 27.0304C39.2693 26.8457 39.2758 26.5398 39.0911 26.3471L29.3802 16.2167C29.1746 16.018 28.8687 16.0236 28.6834 16.2157L18.9146 26.346C18.8277 26.4361 18.7792 26.5564 18.7792 26.6815C18.7792 26.9485 18.9956 27.1649 19.2625 27.1649H24.1132C24.3801 27.1649 24.5965 27.3812 24.5965 27.6482V37.1038C24.5965 37.38 24.3727 37.6038 24.0965 37.6038H12.0833C5.40989 37.6038 0 32.1939 0 25.5205C0 20.549 3.00239 16.2787 7.29288 14.424C7.70553 10.4743 11.0453 7.39551 15.1042 7.39551C16.7642 7.39551 18.3039 7.91051 19.5724 8.78946C21.9938 3.67951 27.1988 0.145508 33.2292 0.145508C41.571 0.145508 48.3333 6.90787 48.3333 15.2497C48.3333 15.4738 48.3285 15.6968 48.3188 15.9186C53.7632 16.5131 58 21.1262 58 26.7288C58 32.3265 53.7708 36.9364 48.3333 37.5375L34.1378 37.6016Z"
        fill={color}
      />
    </svg>
  )
}

const defaultFileDisplay: FileUploadDisplayFunction = ({ size, files, savedFiles, replaceButtonProps }) => {
  if ((!files || !files.length) && (!savedFiles || !savedFiles.length)) {
    console.error('Attempted to display a file without a file object.')
    return null
  }

  const allFiles = [...(files ?? []), ...(savedFiles ?? [])]

  const isMultiple = !!(size !== 'sm' && allFiles.length > 1)

  return (
    <StyledDisplayCard className="v-align h-align p-1" multiple={isMultiple}>
      {allFiles.map(file => {
        const fileType = ((file as File)?.type || (file as SavedFile)?.mimeType) ?? ''
        const fileName = ((file as File)?.name || (file as SavedFile)?.fileName) ?? 'No file name'

        if (size === 'sm') {
          return (
            <span className="v-align my-1">
              <FileTypeIcon mimeType={fileType} className="mr-1" />
              {fileName}
              <GhostButton variant="secondary" type="button" className="caption ml-1" {...replaceButtonProps}>
                Replace File
              </GhostButton>
            </span>
          )
        }

        if (size === 'md' || size === 'lg') {
          return (
            <div style={{ maxWidth: '100%' }} className="mt-1">
              <AttachmentChip fileName={fileName} mimeType={fileType} className="mr-1" />

              {!isMultiple && (
                <GhostButton variant="secondary" type="button" className="caption mt-2" {...replaceButtonProps}>
                  Replace File
                </GhostButton>
              )}
            </div>
          )
        }

        return null
      })}

      {isMultiple && (
        <GhostButton variant="secondary" type="button" className="caption my-2" {...replaceButtonProps}>
          Replace Files
        </GhostButton>
      )}
    </StyledDisplayCard>
  )
}

const StyledDisplayCard = styled(Card)<{ multiple: boolean }>`
  height: 100%;
  width: 100%;
  text-align: center;

  ${({ multiple }) => multiple && `display: flex; flex-flow: column;`}
`
