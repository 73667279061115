import { styled } from '@mui/material'
import { sortBy } from 'lodash'
import { useMemo, useState } from 'react'

import { CredentialFragment } from '@nuna/api'
import { ContextualAlert, GhostButton } from '@nuna/tunic'

import { LicenseCardHeader } from './components/LicenseCardHeader'
import { LicenseDisplayCard } from './components/LicenseDisplayCard'
import { LicensesForm } from './components/LicensesForm'

interface Props {
  providerId: string
  credentials: CredentialFragment[]
  onSave: () => void
}

export function LicenseCards({ credentials, providerId, onSave }: Props) {
  const [isInEditMode, setIsInEditMode] = useState(false)

  const licenseFormSave = () => {
    setIsInEditMode(false)
    onSave()
  }

  const sortedCredentials = useMemo(() => sortBy(credentials, 'state'), [credentials])

  if (isInEditMode) {
    return (
      <LicensesForm
        credentials={sortedCredentials}
        onCancel={() => setIsInEditMode(false)}
        onSave={() => licenseFormSave()}
        providerId={providerId}
      />
    )
  }

  return (
    <Container className="v-align">
      <div className="cards-wrapper mr-5">
        {sortedCredentials.length ? (
          <>
            <LicenseCardHeader />
            {sortedCredentials.map(credential => (
              <LicenseDisplayCard license={credential} key={credential.id} />
            ))}
          </>
        ) : (
          <ContextualAlert>No licenses</ContextualAlert>
        )}
      </div>
      <div className="ml-auto">
        <GhostButton onClick={() => setIsInEditMode(true)}>Edit</GhostButton>
      </div>
    </Container>
  )
}

const Container = styled('div')`
  .cards-wrapper {
    flex: 1;
  }
`
