import { IconProps } from './IconProps'

export function IconError({ size = 24, color = 'currentColor', ...props }: IconProps) {
  return (
    <svg width={size} height={size} viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M24 2C11.8523 2 2 11.8523 2 24C2 36.1477 11.8523 46 24 46C36.1537 46 46 36.1477 46 24C46 11.8523 36.1537 2 24 2ZM24 7.9661C27.3268 7.9661 30.4151 8.98556 32.9782 10.7217L10.7217 32.9753C8.98556 30.4121 7.9661 27.3209 7.9661 24C7.9661 15.159 15.1612 7.9661 24 7.9661ZM24 40.0339C20.6381 40.0339 17.5156 38.9794 14.9345 37.2022L37.2022 14.9345C38.9854 17.5156 40.0339 20.6329 40.0339 24C40.0339 32.841 32.844 40.0339 24 40.0339Z"
        fill={color}
      />
    </svg>
  )
}
