import { useEffect, useState } from 'react'

export function useVideoDimensions(video: HTMLVideoElement | null) {
  const [dimensions, setDimensions] = useState({ width: video?.videoWidth ?? 0, height: video?.videoHeight ?? 0 })

  useEffect(() => {
    if (video) {
      const handleVideoResize = () => setDimensions({ width: video.videoWidth, height: video.videoHeight })
      handleVideoResize()

      video.addEventListener('resize', handleVideoResize)
      return () => video.removeEventListener('resize', handleVideoResize)
    }
  }, [video])

  return dimensions
}
