import { useParams } from 'react-router-dom'

import { useAssessmentsQuery, usePatientDetailsQuery } from '@nuna/api'
import { useAuthDataContext } from '@nuna/auth'

type RouteProps = {
  id: string
}

export const useClientAssessments = () => {
  const { id: patientIdForTherapist = '' } = useParams<RouteProps>()
  // have to query to get the loginId for the patient from the patientId in the url
  const { data: patientData } = usePatientDetailsQuery({
    variables: { id: patientIdForTherapist },
    skip: !patientIdForTherapist,
  })
  const { loginId: patientLoginIdForTherapist } = patientData?.patient || {}
  const { login } = useAuthDataContext()
  const patientLoginId = login?.patientId ? login?.id : patientLoginIdForTherapist ?? ''

  const { loading, error, data, networkStatus } = useAssessmentsQuery({
    variables: { loginId: patientLoginId },
    skip: !patientLoginId,
    fetchPolicy: 'cache-and-network',
  })
  const assessments = data?.assessments

  return { assessments, loading, error, isComplete: networkStatus === 7 }
}
