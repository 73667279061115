import { TherapyType } from '@nuna/core'

import { IconProps } from './IconProps'
import { IconRelationship } from './IconRelationship'
import { IconUser } from './IconUser'

interface IconTherapyTypeProps extends IconProps {
  therapyType: TherapyType
}

export function IconTherapyType({ size = 24, color = 'currentColor', therapyType, ...props }: IconTherapyTypeProps) {
  switch (therapyType) {
    case TherapyType.INDIVIDUAL_THERAPY:
      return <IconUser size={size} color={color} {...props} />
    default:
      return <IconRelationship size={size} color={color} {...props} />
  }
}
