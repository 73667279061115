export function degreesToRadians(degrees: number) {
  return (degrees * Math.PI) / 180
}

export function radiansToDegrees(radians: number) {
  return (radians * 180) / Math.PI
}

export function translateCenterOriginToTopLeft(location: { x: number; y: number }, radius: number) {
  return { x: location.x + radius, y: location.y + radius }
}
