import * as Yup from 'yup'
import { styled } from '@mui/material'
import { Form, Formik } from 'formik'
import moment from 'moment'
import { HTMLAttributes } from 'react'

import {
  Calendar,
  CalendarSyncStatus,
  Provider,
  useSyncProviderCalendarMutation,
  useUpdateProviderMutation,
} from '@nuna/api'
import {
  BelowTablet,
  Box,
  Checkbox,
  ContextualAlert,
  FillButton,
  GhostButton,
  Grid,
  IconCalendar,
  IconCaution,
  IconCheckCircle,
  IconSwitch,
  Select,
  greySet,
  toast,
} from '@nuna/tunic'

import { CalendarSectionTitle } from './CalendarSectionTitle'
import { CalendarSyncHeader } from './CalendarSyncHeader'

interface Props extends HTMLAttributes<HTMLDivElement> {
  loading: boolean
  provider: Provider
  onSave: () => void
  onRefresh: () => void
  status: CalendarSyncStatus
}

const VALIDATION_SCHEMA = Yup.object().shape({
  calendars: Yup.array(Yup.object<Calendar>()).required('Write to calendar is required'),
  selected: Yup.string(),
})

export function CalendarSyncActive({ loading, provider, onSave, onRefresh, status }: Props) {
  const [updateProvider, { loading: updateLoading }] = useUpdateProviderMutation({})
  const [syncProviderCalendars, { loading: syncLoading }] = useSyncProviderCalendarMutation()

  const isLoading = loading || syncLoading || updateLoading

  const refresh = async (selected: string | undefined, type: 'account' | 'availability') => {
    if (!selected) {
      return
    }

    try {
      const { data: syncData } = await syncProviderCalendars({
        variables: {
          providerId: provider.id,
          start: moment().startOf('week').startOf('day'),
          end: moment().startOf('week').add(30, 'days').endOf('day'),
          dryRun: false,
        },
      })

      const data = syncData?.syncProviderCalendars?.length ?? 0

      onRefresh()

      if (data) {
        toast.success(`${data} updates synced`)
      } else {
        toast.success(`No changes detected`)
      }
    } catch (error) {
      toast.urgent(`There was a problem refreshing calendar ${type}`)
    }
  }

  const submit = async (values: { calendars: Calendar[]; selected: string | undefined }) => {
    const newCalendars = values.calendars.map(c => {
      return {
        calendarId: c.id,
        isDesignatedWrite: values.selected === c.id,
        shouldReadFrom: c.isReadingFrom,
      }
    })

    try {
      await updateProvider({
        variables: {
          id: provider.id,
          calendars: newCalendars,
        },
      })

      onSave()

      toast.success('Saved! It might take a few minutes to sync your calendar setting changes.')
    } catch (e) {
      toast.urgent('There was a problem updating calendars')
    }
  }

  const calendars = provider.calendars || []
  const selected = calendars.find(calendar => calendar.isWritingTo)?.id as string | undefined
  const canEdit = status === CalendarSyncStatus.Running || status === CalendarSyncStatus.Connected

  return (
    <Box sx={{ pb: 6 }}>
      <CalendarSyncHeader status={status} provider={provider} onRefresh={onRefresh} />

      <Formik
        enableReinitialize
        initialValues={{ calendars, selected }}
        validationSchema={VALIDATION_SCHEMA}
        onSubmit={submit}
      >
        {({ values, errors, touched, dirty, handleSubmit, setFieldValue, resetForm, submitForm }) => {
          return (
            <Form onSubmit={handleSubmit} style={{ position: 'relative' }}>
              <FormContainer canEdit={canEdit}>
                {!canEdit && (
                  <ContextualAlert
                    dismissButtonText="Dismiss"
                    icon={<IconCheckCircle size={16} />}
                    intent="information"
                    role="alert"
                    scribbleType="default"
                    className="mb-2"
                  >
                    We're updating your preferences, which may take between 1-24 hours. You can make additional changes
                    once we've finished the sync.
                  </ContextualAlert>
                )}

                <CalendarSectionTitle>
                  <IconCalendar className="mr-1" size={18} />
                  Incoming Calendars
                </CalendarSectionTitle>
                <p className="text-secondary mb-2">
                  I'd like events from these calendars to block availability on my Tava schedule
                </p>
                <div role="group" className="mb-4">
                  {values.calendars?.map((calendar, index) => {
                    return (
                      <Checkbox
                        key={calendar.id}
                        error={touched.calendars && !!errors.calendars}
                        checked={calendar.isReadingFrom}
                        onChange={() => {
                          setFieldValue(`calendars[${index}]`, {
                            ...calendar,
                            isReadingFrom: !calendar.isReadingFrom,
                          })
                        }}
                        disabled={!canEdit || isLoading}
                      >
                        {calendar.name}
                      </Checkbox>
                    )
                  })}
                </div>

                <CalendarSectionTitle className="mt-5">
                  <IconCalendar className="mr-1" size={18} />
                  Outgoing Calendar
                </CalendarSectionTitle>
                <p className="text-secondary">I want my Tava appointments to be saved to this calendar</p>

                <Select
                  className="mb-5"
                  label={values.selected ? '' : 'Select a calendar…'}
                  error={touched.selected && !!errors.selected}
                  onChange={value => {
                    setFieldValue(`selected`, value.currentTarget.value)
                  }}
                  value={values.selected || ''}
                  disabled={!canEdit || updateLoading}
                >
                  <option value="" />
                  {provider.calendars
                    ?.filter(calendar => !calendar.isReadOnly)
                    .map(calendar => {
                      return (
                        <option key={calendar.id} value={calendar.id} selected={selected === calendar.id}>
                          {calendar.name}
                        </option>
                      )
                    })}
                </Select>

                {!dirty && (
                  <>
                    <CalendarSectionTitle className="mt-2">
                      <IconCaution className="mr-1" size={18} />
                      Troubleshooting
                    </CalendarSectionTitle>

                    <div
                      className="v-align mb-4"
                      style={{ display: 'flex', flexFlow: 'row', justifyContent: 'space-between', height: 40 }}
                    >
                      <p className="text-secondary">Not seeing what you expect? Calendar changes not syncing?</p>
                      <GhostButton
                        variant={canEdit ? 'primary' : 'secondary'}
                        onClick={() => refresh(selected, 'availability')}
                        disabled={!canEdit || !selected || isLoading || dirty}
                        className="ml-2"
                        style={{
                          fontSize: 16,
                          cursor: !canEdit || !selected || isLoading || dirty ? 'no-drop' : 'pointer',
                          zIndex: 0,
                        }}
                      >
                        <IconSwitch className="mr-1" size={18} />
                        Refresh
                      </GhostButton>
                    </div>
                  </>
                )}

                {dirty && (
                  <Grid container>
                    <ActionButtonsDiv>
                      <GhostButton
                        onClick={() => resetForm()}
                        disabled={!canEdit || !dirty || isLoading}
                        variant="secondary"
                        style={{ cursor: !canEdit || !dirty || isLoading ? 'no-drop' : 'pointer' }}
                      >
                        Cancel
                      </GhostButton>
                      <FillButton
                        onClick={() => submitForm()}
                        type="submit"
                        className="ml-2"
                        isLoading={isLoading}
                        disabled={!canEdit || !dirty || isLoading}
                      >
                        Save Changes
                      </FillButton>
                    </ActionButtonsDiv>
                  </Grid>
                )}
              </FormContainer>
            </Form>
          )
        }}
      </Formik>
    </Box>
  )
}

const FormContainer = styled('div')<{ canEdit: boolean }>`
  border-radius: 8px;

  ${({ canEdit }) =>
    !canEdit
      ? `
  background-color: ${greySet[5].hex};
  border: 1px solid ${greySet[30].hex};
  box-shadow: none;
`
      : `
  background-color: none;
`}
`

const ActionButtonsDiv = styled('div')`
  height: 84px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;

  @media (${BelowTablet}) {
    justify-content: center;
    height: 72px;
  }
`
