/* eslint-disable @typescript-eslint/no-explicit-any */
import { isNil } from 'lodash'
import { useEffect, useState } from 'react'

import { useHubspotChatTokenQuery } from '@nuna/api'
import { useAuthDataContext } from '@nuna/auth'

interface HubspotConversationSettings {
  loadImmediately?: boolean
  identificationToken?: string
  identificationEmail?: string
  inlineEmbedSelector?: string
}
export type HubspotWindow = {
  hsConversationsSettings?: HubspotConversationSettings
  hsConversationsOnReady: (() => void)[]
  HubSpotConversations?: {
    widget?: {
      open: () => void
      close: () => void
      load: (config?: { widgetOpen: boolean }) => void
    }
  }
}

declare let window: HubspotWindow

export function useHubspotChatSetup() {
  const { login } = useAuthDataContext()
  const { data } = useHubspotChatTokenQuery({ skip: isNil(login) })
  const [hsClientReady, setHsClientReady] = useState(false)
  const [hsUserIdentified, setHsUserIdentified] = useState(false)

  useEffect(() => {
    if (window.HubSpotConversations) {
      setHsClientReady(true)
    } else {
      window.hsConversationsOnReady = [() => setHsClientReady(true)]
    }

    return () => {
      window.hsConversationsOnReady = []
    }
  }, [hsClientReady])

  useEffect(() => {
    if (data && login && hsClientReady) {
      let settings: HubspotConversationSettings = { loadImmediately: false, inlineEmbedSelector: '#hubspot-chat' }

      if (data.hubspotChatToken) {
        settings = { identificationToken: data.hubspotChatToken, identificationEmail: login.email, ...settings }
      }

      window.hsConversationsSettings = settings
      setHsUserIdentified(true)
    }
  }, [data, login, hsClientReady])

  return hsUserIdentified
}
