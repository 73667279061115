import { isArray, isObject, isString } from 'lodash'

import { SessionDraft } from '../types/exported/session'

function parseSessionDraft(
  { sessionDraft: rawSessionDraft }: { sessionDraft?: unknown },
  defaultValues?: Partial<SessionDraft>,
): SessionDraft {
  const sessionDraft: SessionDraft = {
    addressId: null,
    diagnoses: null,
    schemaVersion: '2.0',
    endTime: null,
    note: null,
    startTime: null,
    therapyTypeSpecialtyId: null,
    workingDraft: null,
    ...defaultValues,
  }

  if (!rawSessionDraft || !isObject(rawSessionDraft)) return sessionDraft

  if ('addressId' in rawSessionDraft && isString(rawSessionDraft.addressId) && rawSessionDraft.addressId) {
    // checks rawSessionDraft.addressId for empty string to convert to "Virtual" if so
    sessionDraft.addressId = rawSessionDraft.addressId
  }

  if ('diagnoses' in rawSessionDraft && isArray(rawSessionDraft.diagnoses)) {
    sessionDraft.diagnoses = rawSessionDraft.diagnoses
  }

  if ('schemaVersion' in rawSessionDraft && isString(rawSessionDraft.schemaVersion)) {
    sessionDraft.schemaVersion = rawSessionDraft.schemaVersion
  }

  if ('endTime' in rawSessionDraft && isString(rawSessionDraft.endTime)) {
    sessionDraft.endTime = rawSessionDraft.endTime
  }

  if ('note' in rawSessionDraft && isString(rawSessionDraft.note) && rawSessionDraft.note) {
    sessionDraft.note = rawSessionDraft.note
  }

  if ('startTime' in rawSessionDraft && isString(rawSessionDraft.startTime)) {
    sessionDraft.startTime = rawSessionDraft.startTime
  }

  if ('therapyTypeSpecialtyId' in rawSessionDraft && isString(rawSessionDraft.therapyTypeSpecialtyId)) {
    sessionDraft.therapyTypeSpecialtyId = rawSessionDraft.therapyTypeSpecialtyId
  }

  if ('workingDraft' in rawSessionDraft && isString(rawSessionDraft.workingDraft)) {
    sessionDraft.workingDraft = rawSessionDraft.workingDraft
  }

  return sessionDraft
}

export const sessionService = {
  parseSessionDraft,
}
