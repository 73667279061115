import { MouseEventHandler, useEffect } from 'react'

import { DrawerAppointmentFragment, PaymentPreference } from '@nuna/api'
import {
  type AccessCodeCoverageFragment,
  type CashCoverageFragment,
  type EAPCoverageFragment,
  type InsuranceCoverageFragment,
  errorService,
} from '@nuna/core'
import { Box, GhostButton, OutlineButton, Skeleton, toast } from '@nuna/tunic'

import { useCurrentCoverageCardDetails } from '../../hooks/useCurrentCoverageCardDetails'
import { AccessCodeSummary } from './components/AccessCodeSummary'
import { CashSummary } from './components/CashSummary'
import { EAPSummary } from './components/EAPSummary'
import { InsuranceSummary } from './components/InsuranceSummary'

const { transformUserGraphqlError } = errorService

interface CheckoutSummaryProps {
  date: Date
  provider: DrawerAppointmentFragment['provider']
  currentCoverageCardDetails: ReturnType<typeof useCurrentCoverageCardDetails>
  onChangePaymentClick: MouseEventHandler<HTMLButtonElement>
  onPrefilledPaymentClick: (preference: PaymentPreference) => void
  onCoverageValidityChange: (isValid: boolean) => void
}

export function CheckoutSummary({
  provider,
  currentCoverageCardDetails,
  onChangePaymentClick,
  onPrefilledPaymentClick,
  onCoverageValidityChange,
  date,
}: CheckoutSummaryProps) {
  const {
    coverageCardDetails,
    currentCoverage,
    queryResult: { loading, error, refetch },
  } = currentCoverageCardDetails

  useEffect(() => {
    if (error) {
      toast.urgent(transformUserGraphqlError(error, 'Error loading coverage details'))
    }
  }, [error])

  if (!coverageCardDetails || !currentCoverage || loading) {
    return <Skeleton height={10} />
  }

  const isZeroCapEap = coverageCardDetails.type === 'employer' && coverageCardDetails.isZeroCap

  return (
    <>
      {(() => {
        if (coverageCardDetails.type === 'employer') {
          return (
            <EAPSummary
              onCoverageValidityChange={onCoverageValidityChange}
              coverageCardDetails={coverageCardDetails}
              currentCoverage={currentCoverage as EAPCoverageFragment}
            />
          )
        } else if (coverageCardDetails.type === 'cash') {
          return (
            <CashSummary
              date={date}
              coverageCardDetails={coverageCardDetails}
              currentCoverage={currentCoverage as CashCoverageFragment}
              onAddCardSuccess={() => refetch()}
              onChangePaymentClick={onChangePaymentClick}
              onCoverageValidityChange={onCoverageValidityChange}
            />
          )
        } else if (coverageCardDetails.type === 'insurance') {
          return (
            <InsuranceSummary
              provider={provider}
              coverageCardDetails={coverageCardDetails}
              currentCoverage={currentCoverage as InsuranceCoverageFragment}
              onChangePaymentClick={onChangePaymentClick}
              onCoverageValidityChange={onCoverageValidityChange}
            />
          )
        } else if (coverageCardDetails.type === 'accessCode') {
          return (
            <AccessCodeSummary
              coverageCardDetails={coverageCardDetails}
              currentCoverage={currentCoverage as AccessCodeCoverageFragment}
              onCoverageValidityChange={onCoverageValidityChange}
            />
          )
        }
      })()}

      {coverageCardDetails.isInvalid && (
        <div className="text-center mt-3">
          {!isZeroCapEap && <OutlineButton onClick={onChangePaymentClick}>Select new coverage</OutlineButton>}
          {/*
          NOTE: this is to handle the case where we have an Zero cap eap with
          no insurance or credit card. This works because for the panel context
          provider we are considering zero cap valid when they have an optional
          payment method on file
          */}
          {isZeroCapEap && (
            <>
              <Box sx={{ display: 'inline', paddingRight: 2 }}>
                <OutlineButton onClick={() => onPrefilledPaymentClick(PaymentPreference.Insurance)}>
                  Add Insurance
                </OutlineButton>
              </Box>
              <GhostButton onClick={() => onPrefilledPaymentClick(PaymentPreference.Cash)}>Skip</GhostButton>
            </>
          )}
        </div>
      )}
    </>
  )
}
