import { first } from 'lodash'
import moment from 'moment'
import { SyntheticEvent, useEffect, useState } from 'react'

import {
  InsurancePolicyValidationSortKeys,
  OrderBy,
  SearchInsurancePoliciesQuery,
  SearchInsurancePolicyValidationsQuery,
  useSearchInsurancePolicyValidationsQuery,
} from '@nuna/api'
import { Select } from '@nuna/tunic'

import { PolicyValidationItems } from './PolicyValidationItems'

interface PolicyValidationsProps {
  policy: SearchInsurancePoliciesQuery['searchInsurancePolicies']['items'][number]
  visible: boolean
}

export function PolicyValidations({ policy, visible }: PolicyValidationsProps) {
  const { data: policyValidations } = useSearchInsurancePolicyValidationsQuery({
    variables: {
      filters: { insurancePolicyId: policy.id },
      order: [{ key: InsurancePolicyValidationSortKeys.CreatedAt, direction: OrderBy.Desc }],
    },
  })
  const [selectedValidation, setSelectedValidation] =
    useState<SearchInsurancePolicyValidationsQuery['searchInsurancePolicyValidations']['items'][number]>()

  useEffect(() => {
    const validations = policyValidations?.searchInsurancePolicyValidations.items || []
    const newSelectedValidation = validations.find(v => v.id === selectedValidation?.id) || first(validations)
    setSelectedValidation(newSelectedValidation)
  }, [policyValidations, selectedValidation, setSelectedValidation])

  function handleSelectValidation(event: SyntheticEvent<HTMLSelectElement>) {
    const validationId = event.currentTarget.value
    const validation = (policyValidations?.searchInsurancePolicyValidations.items || []).find(
      validation => validation.id === validationId,
    )
    setSelectedValidation(validation)
  }

  return (
    <div className="px-1" style={{ display: visible ? 'block' : 'none' }}>
      <Select
        className="mt-1 mb-2"
        value={selectedValidation?.id ?? ''}
        onChange={handleSelectValidation}
        label="Validation Attempt"
      >
        {(policyValidations?.searchInsurancePolicyValidations.items || []).map(validation => (
          <option key={validation.id} value={validation.id}>
            {moment(validation.createdAt).format('LLL')}
          </option>
        ))}
      </Select>
      {selectedValidation && <PolicyValidationItems validation={selectedValidation} />}
    </div>
  )
}
