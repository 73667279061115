import { PaymentPreference, Provider } from '@nuna/api'
import { CollapsingList, CollapsingListProps } from '@nuna/tunic'

import { providerPolicyUtils } from '../utils/provider-policies.utils'

interface Props extends Omit<CollapsingListProps, 'listItems'> {
  coverageType?: PaymentPreference | null
  provider: Pick<Provider, 'firstName'>
}
export function ProviderPolicyFAQs({ coverageType, provider, ...props }: Props) {
  return (
    <CollapsingList
      {...props}
      listItems={providerPolicyUtils.getProviderPolicyFAQs(coverageType, provider)}
      showBottomBorder
    />
  )
}
