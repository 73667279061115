import { ApolloQueryResult } from '@apollo/client'
import { useMergeLink } from '@mergeapi/react-merge-link'
import { styled } from '@mui/material'
import { identity } from 'lodash'
import { useCallback, useState } from 'react'
import { useFinchConnect } from 'react-finch-connect'
import { useNavigate } from 'react-router-dom'

import { CustomerCompanyDetailsFragment, CustomerCompanyQuery, HrisIntegratorType } from '@nuna/api'
import { useEnvironmentContext } from '@nuna/environment'
import { supportService } from '@nuna/telemetry'
import { FillButton, Logo, TextButton, interactiveFill, tealSet, toast } from '@nuna/tunic'

interface HrisSyncProps {
  customerCompany: CustomerCompanyDetailsFragment
  refetchCustomerCompany: () => Promise<ApolloQueryResult<CustomerCompanyQuery>>
  isAdmin: boolean
}

export function HrisSync(props: HrisSyncProps) {
  return (
    <IntegrationCard>
      {props.customerCompany.defaultHrisIntegrationType === HrisIntegratorType.Merge ? (
        <NewMergeSyncFlow {...props} />
      ) : (
        <NewFinchSyncFlow {...props} />
      )}
    </IntegrationCard>
  )
}

function NewMergeSyncFlow(props: HrisSyncProps) {
  const [isLoading, setIsLoading] = useState(false)
  const [linkToken, setLinkToken] = useState('')
  const { API_ENDPOINT } = useEnvironmentContext()
  const navigate = useNavigate()
  const onSuccess = useCallback(async (publicToken: string) => {
    const result = await onExchangeForAccountToken({
      apiEndpoint: API_ENDPOINT,
      integrationType: 'merge',
      publicToken,
    })

    if (result instanceof Error) {
      toast.urgent(`${result.message}`)
      return
    }

    await props.refetchCustomerCompany()
    toast.info(`Successfully setup sync!`)
    navigate(`/customers/${props.customerCompany.id}/rosters`)
    // I don't like this but I am washing my hands of it for now
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const { open: openIntegrationStep, isReady } = useMergeLink({
    linkToken: linkToken,
    onSuccess,
  })

  const onRequestConnectScreen = async () => {
    setIsLoading(true)

    const result = await getLinkToken({
      apiEndpoint: API_ENDPOINT,
      integrationType: 'merge',
    })

    setIsLoading(false)
    if (result instanceof Error || !result.linkToken) {
      toast.urgent(result?.message || 'Unable to start sync flow. Please refresh and try again later.')
      return
    }

    setLinkToken(result.linkToken)
    openIntegrationStep()
  }

  return (
    <SetupInfoStep
      logoUrl={props.customerCompany.logoUrl}
      isSyncingWithMerge
      openConnectScreen={onRequestConnectScreen}
      canOpenIntegrationStep={!isLoading && isReady}
    />
  )
}

const FINCH_CLIENT_ID = '65fb709c-be20-4cf0-8e98-f7bd7ad0df47'

function NewFinchSyncFlow(props: HrisSyncProps) {
  const { API_ENDPOINT } = useEnvironmentContext()
  const navigate = useNavigate()
  const { open: handleOpenFinchConnect } = useFinchConnect({
    clientId: FINCH_CLIENT_ID,
    products: ['individual', 'employment', 'directory'],
    sandbox: false,
    manual: true,
    onSuccess,
    onError,
    onClose: identity,
  })

  async function onSuccess({ code }: { code: string }) {
    const result = await onExchangeForAccountToken({
      apiEndpoint: API_ENDPOINT,
      integrationType: 'finch',
      publicToken: code,
    })
    if (result instanceof Error) {
      toast.urgent(`${result.message}`)
      return
    }

    await props.refetchCustomerCompany()
    toast.info(`Successfully setup sync!`)
    navigate(`/customers/${props.customerCompany.id}/rosters`)
  }

  function onError({ errorMessage }: { errorMessage: string }) {
    console.error(errorMessage)
    toast.urgent(`Something went wrong. Please contact ${supportService.supportEmails.providerSupport}.`)
  }

  return (
    <SetupInfoStep
      logoUrl={props.customerCompany.logoUrl}
      isSyncingWithMerge={false}
      openConnectScreen={handleOpenFinchConnect}
      canOpenIntegrationStep={true}
    />
  )
}

function SetupInfoStep({
  logoUrl,
  isSyncingWithMerge,
  openConnectScreen,
  canOpenIntegrationStep,
}: {
  logoUrl?: string | null
  isSyncingWithMerge: boolean
  openConnectScreen: () => void
  canOpenIntegrationStep: boolean
}) {
  return (
    <div className="ml-4">
      {logoUrl && (
        <div className="v-align mb-4">
          <img src={logoUrl} height="40px" alt="Customer Logo" />
          <img
            src="https://tava-public-assets.s3.us-west-2.amazonaws.com/sync-companies.png"
            height="72px"
            alt="Sync HRIS"
            className="ml-2 mr-2 mb-4"
          />
          <Logo color="plum" height={40} />
        </div>
      )}

      <h1 className="h4">Sync your employee roster</h1>
      <p className="mb-4 text-light large-body serif">
        We've partnered with {isSyncingWithMerge ? 'Merge' : 'Finch'} to securely sync your HRIS system with Tava. We'll
        guide you through the process and have your roster synced up in no time.
      </p>

      <FillButton className="m-0" onClick={openConnectScreen} disabled={!canOpenIntegrationStep}>
        Connect HRIS
      </FillButton>
      <p style={{ marginBottom: '4rem' }} className="mt-2 caption">
        Questions? <TextButton onClick={supportService.openChat}>Contact support</TextButton>
      </p>

      <BenefitsContainer>
        <h5>Benefits of Connecting your HRIS</h5>
        <ExplanationList className="mb-0 mt-0">
          <li className="text-light">Ensure new employees have access on their first day.</li>
          <li className="text-light">Ensure that only eligible employees have access.</li>
          <li className="text-light">Receive accurate usage reports from our customer success team.</li>
        </ExplanationList>
      </BenefitsContainer>
    </div>
  )
}

const BenefitsContainer = styled('div')`
  background-color: ${tealSet.tint[20]};
  border: 1px solid ${tealSet[70].hex};
  border-radius: 6px;
  color: ${tealSet[70].hex};
  padding: 1rem;
`

const ExplanationList = styled('ul')`
  list-style: none;
  padding-left: 1rem;
  li::before {
    font-size: 1.6rem;
    content: '• ';
    color: ${interactiveFill};
  }
`

async function onExchangeForAccountToken({
  apiEndpoint,
  integrationType,
  publicToken,
}: {
  integrationType: 'finch' | 'merge'
  publicToken: string
  apiEndpoint: string
}) {
  const options: RequestInit = {
    method: 'POST',
    mode: 'cors',
    cache: 'no-cache',
    credentials: 'include',
    headers: { 'Content-Type': 'application/json' },
    referrerPolicy: 'no-referrer',
    body: JSON.stringify({ publicToken }),
  }

  try {
    const response = await fetch(
      `${apiEndpoint}/hris/exchange-account-token?integrationType=${integrationType}`,
      options,
    )
    if (response.status !== 200) {
      throw new Error('Failed to setup sync. Please try again later.')
    }
    return response.json()
  } catch (e) {
    return new Error(`Failed to setup sync. Please contact ${supportService.supportEmails.providerSupport}.`)
  }
}

async function getLinkToken({
  apiEndpoint,
  integrationType,
}: {
  integrationType: 'finch' | 'merge'
  apiEndpoint: string
}) {
  const options: RequestInit = {
    method: 'POST',
    mode: 'cors',
    cache: 'no-cache',
    credentials: 'include',
    headers: { 'Content-Type': 'application/json' },
    referrerPolicy: 'no-referrer',
    body: JSON.stringify({}),
  }

  try {
    const response = await fetch(`${apiEndpoint}/hris/link-token?integrationType=${integrationType}`, options)
    return response.json()
  } catch (e) {
    return new Error('Failed to get token')
  }
}

const IntegrationCard = styled('div')`
  padding: 1.4rem;
  max-width: 800px;
`
