import { DailyAccess, DailyEventObjectAccessState, SpecifiedDailyAccess } from '@daily-co/daily-js'
import { useDaily, useDailyEvent } from '@daily-co/daily-react'
import { useCallback, useState } from 'react'

import { useUpdateSessionAutonoteRequirementsMutation } from '@nuna/api'

import { useVideoCallContext } from '../context/VideoCallContext'

export function useAccessState() {
  const callObject = useDaily()
  const { appointmentId } = useVideoCallContext()
  const [updateSessionAutonoteRequirements] = useUpdateSessionAutonoteRequirementsMutation({
    variables: { appointmentId },
  })

  const [accessLevel, setAccessLevel] = useState<DailyAccess>(() => {
    if (!callObject) return 'unknown'
    return callObject.accessState().access
  })
  const [awaitingAccessLevel, setAwaitingAccessLevel] = useState<SpecifiedDailyAccess | undefined>(
    callObject?.accessState().awaitingAccess,
  )

  const handleAccessStateUpdated = useCallback(
    (accessEvent: DailyEventObjectAccessState) => {
      console.info(
        '[Daily Lifecycle] Access state updated.',
        'Current:',
        accessEvent.access,
        'Awaiting:',
        accessEvent.awaitingAccess,
      )

      setAccessLevel(accessEvent.access)
      setAwaitingAccessLevel(accessEvent.awaitingAccess)

      if (accessEvent.access !== 'unknown' && accessEvent.access.level === 'full') {
        // when client is given access, sync autonote preferences to appointment
        updateSessionAutonoteRequirements()
      }
    },
    [updateSessionAutonoteRequirements],
  )

  useDailyEvent('access-state-updated', handleAccessStateUpdated)

  return { accessLevel, awaitingAccessLevel }
}
