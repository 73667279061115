import { HTMLAttributes, ReactNode } from 'react'

import { CancelPolicyItem, cancelPolicyService } from '@nuna/core'
import { StatusLabel, csx } from '@nuna/tunic'

import { CaptionLight } from '../../../../shared/FormComponents'

interface CancelPolicyDateProps extends HTMLAttributes<HTMLDivElement> {
  cancelPolicy: Pick<CancelPolicyItem, 'startDate' | 'isFuture' | 'isOld'>
  descriptionDisplay: (description: string) => ReactNode
}
export function CancelPolicyDate({ cancelPolicy, descriptionDisplay, ...props }: CancelPolicyDateProps) {
  const { isFuture, isOld } = cancelPolicy
  const description = cancelPolicyService.dateDescription(cancelPolicy)

  if (isOld) {
    return <CaptionLight {...props}>Old policy • {description}</CaptionLight>
  }

  return (
    <div {...props} className={csx([props.className, 'v-align'])}>
      <StatusLabel className="mr-1" intent={isFuture ? 'default' : 'information'}>
        {isFuture ? 'Future' : 'Current'} policy
      </StatusLabel>
      {descriptionDisplay(description)}
    </div>
  )
}
