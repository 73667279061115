import { IconProps } from './IconProps'

export function IconReply({ size = 24, color = 'currentColor', ...props }: IconProps) {
  return (
    <svg width={size} height={size} viewBox="0 0 22 22" fill={color} xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.4056 13.3811L11.2672 15.5763C11.0372 15.8125 11.0373 16.1966 11.2654 16.4321L12.0628 17.2552C12.2919 17.4917 12.6646 17.4913 12.8936 17.2562L17.8711 11.9732C18.0432 11.7965 18.0427 11.5096 17.8711 11.3333L12.8936 6.36379C12.6638 6.12789 12.2909 6.1293 12.0628 6.36477L11.2654 7.18789C11.0363 7.42433 11.0375 7.80786 11.2672 8.04364L13.4237 10.2577C13.5392 10.3763 13.4986 10.4724 13.334 10.4724L11.6562 10.4724L10.3051 10.4826C8.31525 10.4826 6.70218 8.8877 6.70218 6.92033V4.98774C6.70218 4.66116 6.43368 4.39404 6.10248 4.39404H4.5997C4.26897 4.39404 4 4.65985 4 4.98774V6.92033C4 10.3632 6.82288 13.1543 10.3051 13.1543L11.6562 13.1723L13.3118 13.1679C13.4783 13.1679 13.5198 13.2639 13.4056 13.3811Z"
      />
    </svg>
  )
}
