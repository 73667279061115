import { isNil, sortBy } from 'lodash'
import { HTMLAttributes, useMemo, useState } from 'react'

import { CredentialFragment, LicenseStatus, useProviderLicensesQuery } from '@nuna/api'
import { useIsAdmin } from '@nuna/auth'
import { GhostButton, Skeleton, StatusLabel } from '@nuna/tunic'

import { CreatePsyPactLicenseDrawer } from './components/CreatePsyPactLicenseDrawer'
import { LicenseCards } from './components/LicenseCards'

interface Props extends HTMLAttributes<HTMLDivElement> {
  providerId?: string | null
}

const LICENSE_STATUSES_NEED_ATTENTION = [
  LicenseStatus.Invalid,
  LicenseStatus.Expired,
  LicenseStatus.ExpiresSoon,
  LicenseStatus.NeedsReview,
]

export function Licenses({ providerId }: Props) {
  const { data, refetch } = useProviderLicensesQuery({
    variables: { providerId: providerId ?? '' },
    skip: !providerId,
  })

  const [psyPactDrawerOpen, setPsyPactDrawerOpen] = useState(false)

  const isAdmin = useIsAdmin()

  const credentials = useMemo(
    () => sortBy(data?.provider.credentials ?? [], license => license.id),
    [data?.provider.credentials],
  )

  const needsAttention = credentialsNeedAttention(credentials)

  if (isNil(providerId)) {
    return <Skeleton fullWidth height={25} />
  }

  const licenseCardsSave = () => {
    refetch()
  }

  return (
    <div style={{ maxWidth: 1080 }}>
      <div className="h4 mb-3 v-align">
        {needsAttention && (
          <StatusLabel intent="urgent" className="ml-1">
            Needs Attention
          </StatusLabel>
        )}
        {isAdmin && (
          <GhostButton className="ml-auto" onClick={() => setPsyPactDrawerOpen(true)}>
            Add PSYPACT licenses
          </GhostButton>
        )}
      </div>
      <LicenseCards credentials={credentials} providerId={providerId} onSave={() => licenseCardsSave()} />
      <CreatePsyPactLicenseDrawer
        isOpen={psyPactDrawerOpen}
        providerId={providerId}
        licenses={credentials}
        onClose={() => setPsyPactDrawerOpen(false)}
        onSaved={refetch}
      />
    </div>
  )
}

function credentialsNeedAttention(credentials: CredentialFragment[]) {
  return credentials.some(
    credential =>
      !credential.expiry || (credential.status && LICENSE_STATUSES_NEED_ATTENTION.includes(credential.status)),
  )
}
