import { css } from '@mui/material'

import { Phone } from '../breakpoints'
import { greySet, tealSet, yellowSet } from '../colorSets'
import { body1, body2, error } from '../colors'

export const serifFontStack = 'Recoleta, serif'
export const sansSerifFontStack = 'Sofia Pro, sans-serif'

export const fontSize = {
  caption: '0.8125rem', // 13px
  body: '1rem', // 16px
  large: '1.25rem', // 20px
  display: '1.5rem', // 24px
  h1: '5rem', // 80px
  h2: '3.625rem', // 58px
  h3: '2.75rem', // 44px
  h4: '2rem', // 32px
  h5: '1.5rem', // 24px
  h6: '1.1875rem', // 19px
}

export const fontWeight = {
  light: 300,
  normal: 400,
  medium: 500,
  bold: 600,
}

export const lineHeight = 1.28581 // this was chosen becuase it was the Blueprint default line-height and we didn't want to cause issues when we removed Blueprint
export const headingLineHeight = 1.1
export const paragraphsLineHeight = 1.4 // used for long chunks of text

export const typographicStyles = {}

export const typographyUtils = css`
  h1,
  .h1,
  h2,
  .h2,
  h3,
  .h3,
  h4,
  .h4,
  h5,
  .h5,
  h6,
  .h6 {
    font-family: ${serifFontStack};
    font-weight: 400;
    line-height: 1.1;
    margin: 0 0 0.5em 0;
  }

  h1,
  .h1 {
    font-size: ${fontSize.h1};
  }

  h2,
  .h2 {
    font-size: ${fontSize.h2};
  }

  h3,
  .h3 {
    font-size: ${fontSize.h3};
  }

  h4,
  .h4 {
    font-size: ${fontSize.h4};
  }

  h5,
  .h5 {
    font-size: ${fontSize.h5};

    strong,
    b {
      font-weight: 500;
    }
  }

  h6,
  .h6 {
    font-size: ${fontSize.h6};
    font-weight: 500;
  }

  .body {
    font-family: ${sansSerifFontStack};
    font-size: ${fontSize.body};
    font-weight: 400;
  }

  .caption {
    font-size: ${fontSize.caption};
    line-height: ${paragraphsLineHeight};
  }

  .large {
    font-size: ${fontSize.large};
    line-height: ${paragraphsLineHeight};
  }

  .responsive-large {
    font-size: ${fontSize.large};
    line-height: ${paragraphsLineHeight};

    @media (${Phone}) {
      font-size: ${fontSize.body};
    }
  }

  .display {
    font-size: ${fontSize.display};
    font-weight: 300;
  }

  .sans-serif {
    font-family: ${sansSerifFontStack};
  }

  .serif {
    font-family: ${serifFontStack};
  }

  .text-default {
    color: ${body1};
  }

  .text-secondary {
    color: ${body2};
  }

  .text-light-grey {
    color: ${greySet[50].hex};
  }

  .text-white {
    color: #fff;
  }

  .text-black {
    color: #000;
  }

  .text-teal {
    color: ${tealSet.primary.hex};
  }

  .text-error {
    color: ${error};
  }
  .text-dark-teal {
    color: ${tealSet[90].hex};
  }
  .text-dark {
    color: ${tealSet[100].hex};
  }

  // for user on dark backgrounds
  .text-grey-30 {
    color: ${greySet[30].hex};
  }

  .text-medium-gray,
  .text-medium-grey {
    color: ${greySet[50].hex};
  }

  .text-medium-dark-grey {
    color: ${greySet[70].hex};
  }

  .text-dark-grey {
    color: ${greySet[80].hex};
  }

  .text-orange,
  .text-yellow {
    color: ${yellowSet[80].hex};
  }

  .text-light {
    font-weight: 300;
  }

  .text-normal {
    font-weight: 400;
  }

  .text-medium {
    font-weight: 500;
  }

  .text-bold,
  .semibold // deprecated, here for backwards compatibility
  {
    font-weight: 600;
  }

  .text-center {
    text-align: center;
  }

  .text-right {
    text-align: right;
  }

  .paragraphs {
    line-height: ${paragraphsLineHeight};

    p + p {
      margin-top: 1.1em;
    }
  }

  .italic {
    font-style: italic;
  }

  .nowrap {
    white-space: nowrap;
  }

  .strike-through {
    text-decoration: line-through;
  }

  .uppercase {
    text-transform: uppercase;
  }

  .list {
    li {
      margin-bottom: 1rem;
    }
  }
`
