import * as Sentry from '@sentry/browser'
import { useDevices } from '@daily-co/daily-react'
import { styled } from '@mui/material'
import { motion } from 'framer-motion'
import { useEffect, useMemo, useState } from 'react'

import { supportService } from '@nuna/telemetry'
import { BelowTablet, IconButton, IconClose, TextButton, TextButtonExternalLink } from '@nuna/tunic'

import testSoundSrc from '../../../assets/test-sound.mp3'

interface ExtendedHTMLAudioElement extends HTMLAudioElement {
  setSinkId: (sinkId: string) => Promise<void> // Not all browsers support this & it is not in the TS definition, so we extend it and make sure to check for it before calling.
}

export function TestSound() {
  const [showAudioHelp, setShowAudioHelp] = useState(false)
  const { currentSpeaker } = useDevices()

  const deviceId = currentSpeaker?.device.deviceId

  const testAudio = useMemo(() => new Audio(testSoundSrc) as ExtendedHTMLAudioElement, [])

  useEffect(() => {
    if (testAudio.setSinkId && deviceId) {
      testAudio.setSinkId(deviceId)
    }
  }, [testAudio, deviceId])

  useEffect(() => {
    if (!showAudioHelp) {
      testAudio.pause()
      testAudio.currentTime = 0
    }
  }, [showAudioHelp, testAudio])

  const handleTestAudioClick = async () => {
    setShowAudioHelp(true)

    if (testAudio.currentTime) {
      testAudio.pause()
      testAudio.currentTime = 0
    }

    try {
      testAudio.play()
    } catch (e) {
      let errorMessage
      if (typeof e === 'string') {
        errorMessage = e
      } else if (e instanceof Error) {
        errorMessage = e.message
      }

      Sentry.captureEvent({
        message: 'Error playing test audio',
        extra: {
          error: errorMessage,
        },
      })
    }
  }

  return (
    <TestSoundContainer className="caption">
      <TextButton variant="secondary" scheme="dark" onClick={handleTestAudioClick}>
        Test Sound
      </TextButton>
      <SoundHelpContainer
        className="v-align caption"
        style={{ height: 0 }}
        animate={{ height: showAudioHelp ? 'auto' : 0 }}
        transition={{ type: 'tween' }}
      >
        Don't hear anything?{' '}
        <TextButtonExternalLink className="ml-1" scheme="dark" href={supportService.articles.troubleshootingVideo}>
          Click here for help.
        </TextButtonExternalLink>
        <IconButton
          tooltip="Close sound test"
          variant="dark"
          className="text-light-gray v-align"
          onClick={() => setShowAudioHelp(false)}
        >
          <IconClose size={16} />
        </IconButton>
      </SoundHelpContainer>
    </TestSoundContainer>
  )
}

const TestSoundContainer = styled('div')`
  color: #fff;
  padding-top: 1.5rem;
`

const SoundHelpContainer = styled(motion.div)`
  overflow: hidden;

  button {
    margin-left: auto;

    @media (${BelowTablet}) {
      height: 22px;
      width: 22px;
    }
  }
`
