import amexLogoSrc from '../img/amex-logo.png'
import discoverLogoSrc from '../img/discover-logo.png'
import mastercardLogoSrc from '../img/mastercard-logo.png'
import visaLogoSrc from '../img/visa-logo.png'

interface Props {
  cardBrand: string | null | undefined
}

interface Logo {
  src: string
  width: number
}

const logos: Record<string, Logo | undefined> = {
  amex: {
    src: amexLogoSrc,
    width: 79,
  },
  discover: {
    src: discoverLogoSrc,
    width: 78,
  },
  mastercard: {
    src: mastercardLogoSrc,
    width: 46,
  },
  visa: {
    src: visaLogoSrc,
    width: 48,
  },
}

export function CreditCardLogo({ cardBrand }: Props) {
  if (!cardBrand) return null
  const logoImage = logos[normalizeCardBrand(cardBrand)]
  if (!logoImage) return null

  return <img src={logoImage.src} width={logoImage.width} alt={cardBrand} />
}

/** If the payment methods come from Stripe they are formatted one way. If they come from payment_method they are formatted another. Since this behavior is behind a flag I made this to handle both cases */
function normalizeCardBrand(cardBrand: string | null | undefined) {
  switch (cardBrand) {
    case 'American Express':
      return 'amex'
    case 'Discover':
      return 'discover'
    case 'MasterCard':
      return 'mastercard'
    case 'Visa':
      return 'visa'
  }

  return cardBrand ?? ''
}
