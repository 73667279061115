import { isNil } from 'lodash'
import { useMemo } from 'react'

import {
  EapCoverageDetailsFragment,
  PaymentPreference,
  usePatientContextQuery,
  usePatientCoverageQuery,
  usePublicCompanyQuery,
} from '@nuna/api'

export function usePatientCoverageData() {
  const { data } = usePatientContextQuery()

  // so skip has the effect of NOT running the query until the next one starts
  // these appolo apis are pretty nifty
  const { data: coverageData } = usePatientCoverageQuery({
    skip: isNil(data?.patientContext.patient.id),
    variables: { patientId: data?.patientContext.patient.id ?? '' },
  })

  const company = data?.patientContext.company

  const firstCoverage = coverageData?.patientCoverage?.[0]

  const details = firstCoverage?.details as EapCoverageDetailsFragment

  const isEapAndDependentsCovered = useMemo(() => {
    return firstCoverage?.type === PaymentPreference.Employer && details.availableToDependents
  }, [firstCoverage, details])

  const isZeroCap = useMemo(() => {
    return firstCoverage?.type === PaymentPreference.Employer && details.sessionsCap === 0
  }, [firstCoverage, details])

  const [patientIsReferralIdRequired, patientCompanyPhoneNumber] = useMemo(
    () => [company?.isReferralIdRequired, company?.eapAdminPhoneNumber],
    [company],
  )

  let [patientCompanyOrPartnerName] = useMemo(() => [company?.name], [company])

  const { data: publicCompany } = usePublicCompanyQuery({
    variables: { id: data?.patientContext.company?.id || '' },
    skip: isNil(data?.patientContext.company?.id),
  })

  if (publicCompany?.publicCompany.partnerBranding?.name) {
    patientCompanyOrPartnerName = publicCompany.publicCompany.partnerBranding.name
  }

  return {
    isEapAndDependentsCovered,
    isZeroCap,
    patientIsReferralIdRequired,
    patientCompanyOrPartnerName,
    patientCompanyPhoneNumber,
  }
}
