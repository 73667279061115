import React, { Suspense } from 'react'

import { ImageUploadProps } from '.'

// lazy load this since we don't need the image cropping library under most circumstances
const ImageUpload = React.lazy(() => import('./ImageUpload'))

export function LazyImageUpload(props: ImageUploadProps) {
  return (
    <Suspense fallback={<div>Loading...</div>}>
      <ImageUpload {...props} />
    </Suspense>
  )
}
