import { styled } from '@mui/material'

import { BelowDesktopLarge, DesktopLarge, DesktopXLarge, TabletLandscape } from '../styles/breakpoints'
import { greySet } from '../styles/colorSets'
import { borderGrey } from '../styles/colors'
import { styledUtils } from '../utils/styled'
import { FillButton } from './Button'

const { transientPropOptions } = styledUtils

const Top = styled('section')<{ $reverse?: boolean }>`
  display: flex;
  min-height: 100vh;
  width: 100%;
  flex-direction: ${props => (props.$reverse ? 'row-reverse' : 'row')};

  @media (${TabletLandscape}) {
    flex-direction: ${props => (props.$reverse ? 'column-reverse' : 'column')};
  }
`

const Half = styled('div')`
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex: 0 0 50%;
  padding: var(--margin-2);
  position: relative;

  @media (${TabletLandscape}) {
    min-height: 600px;
  }
`

const Right = styled(Half)``

const Left = styled(Half, transientPropOptions)<{ $backgroundColor: string; $backgroundImage: string }>`
  background-color: ${props => props.$backgroundColor};
  background-size: cover;
  background-image: url(${props => props.$backgroundImage});
  background-position: center;
  color: #fff;

  @media (max-width: 28.125em) {
    padding-top: 6rem;
  }
`

const BrandedHalf = styled(Half)<{ $gradientStart: string; $gradientEnd: string }>`
  background: ${props => `linear-gradient(202deg, ${props.$gradientStart}, ${props.$gradientEnd})`};
  color: #fff;
`

const TypographyContainer = styled('div')`
  h1,
  .h1 {
    @media (${BelowDesktopLarge}) {
      font-size: 58px;
    }
  }

  h2,
  .h2 {
    @media (${BelowDesktopLarge}) {
      font-size: 44px;
    }
  }

  .h3 {
    @media (${BelowDesktopLarge}) {
      font-size: 32px;
    }
  }

  .h4 {
    line-height: 1.4;

    @media (${BelowDesktopLarge}) {
      font-size: 24px;
    }
  }

  .h5 {
    @media (${BelowDesktopLarge}) {
      font-size: 1.1875rem;
    }
  }
`

const BrandedContainer = styled(TypographyContainer)<{ $buttonColor: string; $hoverColor: string }>`
  html:not([data-tunic-theme='harmony']) & {
    --primary-50: ${props => props.$buttonColor};
    --primary-hover: ${props => props.$hoverColor};
    --primary-tint-40: color-mix(in srgb, ${props => props.$buttonColor} 20%, transparent);

    input:checked + * .checkbox-display {
      background-color: ${props => props.$buttonColor};

      &::after {
        border-color: ${props => props.$buttonColor};
      }
    }

    input:focus:checked + * .checkbox-display {
      box-shadow: 0 0 0 6px color-mix(in srgb, ${props => props.$buttonColor} 20%, transparent);
    }
  }

  a.sso-external-link {
    color: ${props => props.$buttonColor};
    font-weight: 500;

    &:hover {
      color: ${props => props.$hoverColor};
      font-weight: 700;
    }

    &:focus-visible {
      outline: none;
      color: ${props => props.$hoverColor};
      font-weight: 700;
    }

    &.dark,
    &:hover.dark,
    &focus-visible.dark {
      color: #fff;
    }
  }
`

const SignupContainer = styled('div')`
  max-width: 1024px;
  margin: auto;
  padding: 0 1.575rem;

  @media (${DesktopLarge}) {
    max-width: 1024px;
  }

  @media (${DesktopXLarge}) {
    max-width: 1024px;
  }
`

const FAQContainer = styled('section')`
  div.large {
    p,
    ul {
      @media (${BelowDesktopLarge}) {
        font-size: 16px;
      }
    }
  }
`

const Header = styled('div')`
  position: absolute;
  top: 0;
  left: 0;
  min-height: 73px;
  padding: var(--margin-2);
`

const RightAlignedHeader = styled(Header)`
  left: auto;
  right: 0;
`

const SignInLink = styled('span')`
  padding-right: var(--margin-2);
  margin-right: var(--margin-1);
  border-right: 1px solid ${borderGrey};
`

const Heading = styled('h1')`
  line-height: 0.97;
`

const CenteredContent = styled('div')<{ maxWidth?: number }>`
  max-width: ${({ maxWidth = 600 }) => `${maxWidth}px`};
  width: 100%;
`

const FAQText = styled('p')`
  position: absolute;
  bottom: 2rem;
`

const GreyBackground = styled('div')`
  background-color: ${greySet[0].hex};
  padding-top: var(--margin-7);
  padding-bottom: var(--margin-7);
`

const FloatingGetStartedButton = styled(FillButton)<{ hidden: boolean }>`
  bottom: 2.5rem;
  display: block;
  left: 0;
  margin: auto;
  position: fixed;
  right: 0;
  transition: opacity 0.3s;
  width: 150px;
  z-index: 10;

  ${({ hidden }) =>
    hidden &&
    `
    opacity: 0;
    pointer-events: none;
  `}
`

/** These are all specific components for specific purposes and I wanted them to be grouped instead of being exported individually out of tunic.
 * It might make sense to move this kind of stuff into its own signup library, but for now, it's here.
 */
export const SignupLayout = {
  FAQText,
  FloatingGetStartedButton,
  GreyBackground,
  CenteredContent,
  Heading,
  SignInLink,
  RightAlignedHeader,
  Header,
  FAQContainer,
  SignupContainer,
  TypographyContainer,
  Half,
  BrandedHalf,
  BrandedContainer,
  Left,
  Right,
  Top,
}
