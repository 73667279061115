/* eslint-disable @typescript-eslint/no-explicit-any */
import { styled } from '@mui/material'
import moment from 'moment'

const timeBreakpoints = [
  {
    label: 'Last hour',
    timeBreakpoint: moment(Date.now()).subtract(1, 'h'),
  },
  {
    label: 'Last day',
    timeBreakpoint: moment(Date.now()).subtract(24, 'h'),
  },
  {
    label: 'Previous 7 Days',
    timeBreakpoint: moment(Date.now()).subtract(7, 'd'),
  },
  {
    label: 'Previous 30 Days',
    timeBreakpoint: moment(Date.now()).subtract(30, 'd'),
  },
  {
    label: 'Earlier',
    timeBreakpoint: moment(Date.now()).subtract(50, 'y'),
  },
]

interface Props {
  items: any[]
  datetimeAccesor: (item: any) => string
  itemGenerator: (item: any) => JSX.Element
}

const TimeGroupContainer = styled('div')`
  margin-bottom: 3rem;
`

export function DateList({ items, itemGenerator, datetimeAccesor }: Props) {
  const groupedItems = timeBreakpoints.map((breakpoint, index) => {
    const filteredItems = items.filter(item => {
      // Grab date from the item
      const itemDatetime = datetimeAccesor(item)

      // Check if items time is after the given breakpoint but before the previous breakpoint
      const isLaterThanBreakpoint = moment(itemDatetime) > breakpoint.timeBreakpoint
      const isBeforePreviousBreakpoint =
        index > 0 ? moment(itemDatetime) < timeBreakpoints[index - 1].timeBreakpoint : true

      return isLaterThanBreakpoint && isBeforePreviousBreakpoint
    })

    return {
      label: breakpoint.label,
      items: filteredItems,
    }
  })

  const groupedRows = groupedItems
    .filter(group => group.items.length)
    .map(group => {
      return (
        <TimeGroupContainer key={group.label}>
          <h2 className="h6 mb-2">{group.label}</h2>
          {group.items.map(item => {
            return itemGenerator(item)
          })}
        </TimeGroupContainer>
      )
    })

  return <div>{groupedRows}</div>
}
