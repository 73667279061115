import { HTMLAttributes, ReactNode } from 'react'

import { HelperText } from '../HelperText'

export interface ChipGroupProps extends HTMLAttributes<HTMLDivElement> {
  error?: boolean
  helperText?: string | ReactNode
}

export function ChipGroup({ error = false, helperText, children, ...props }: ChipGroupProps) {
  return (
    <div role="group" {...props}>
      {children}
      {helperText && <HelperText error={error}>{helperText}</HelperText>}
    </div>
  )
}
