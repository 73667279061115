import { IconProps } from './IconProps'

export function IconCompany({ size = 24, color = 'currentColor', ...props }: IconProps) {
  return (
    <svg width={size} height={size} viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M24.5 13.875V5.375H3.25V43.625H45.75V13.875H24.5ZM11.75 39.375H7.5V35.125H11.75V39.375ZM11.75 30.875H7.5V26.625H11.75V30.875ZM11.75 22.375H7.5V18.125H11.75V22.375ZM11.75 13.875H7.5V9.625H11.75V13.875ZM20.25 39.375H16V35.125H20.25V39.375ZM20.25 30.875H16V26.625H20.25V30.875ZM20.25 22.375H16V18.125H20.25V22.375ZM20.25 13.875H16V9.625H20.25V13.875ZM41.5 39.375H24.5V35.125H28.75V30.875H24.5V26.625H28.75V22.375H24.5V18.125H41.5V39.375ZM37.25 22.375H33V26.625H37.25V22.375ZM37.25 30.875H33V35.125H37.25V30.875Z"
        fill={color}
      />
    </svg>
  )
}
