import moment from 'moment-timezone'
import { ReactNode } from 'react'
import { useParams } from 'react-router-dom'

import { useCustomerContractQuery, useSearchPartnersQuery } from '@nuna/api'
import { coverageService, numberService } from '@nuna/core'
import { IconButtonLink, IconPencil, Skeleton, makeTypographyComponent } from '@nuna/tunic'

import { InternationalAreasTable } from './InternationalAreasTable'
import { AvailableToLabels } from './shared'

export function CustomerContractDetail() {
  const { contractId = '' } = useParams()
  const { getHumanReadablePaymentPreference } = coverageService
  const { data } = useCustomerContractQuery({ variables: { id: contractId }, fetchPolicy: 'cache-and-network' })
  const { data: partnerData } = useSearchPartnersQuery({
    variables: { filters: { id: data?.contract.partnerId } },
    skip: !data,
  })

  if (!data) {
    return (
      <div>
        <Skeleton height={2} width={20} className="mb-2" />
        <Skeleton height={2} width={20} className="mb-2" />
        <Skeleton height={2} width={20} className="mb-2" />
        <Skeleton height={2} width={20} className="mb-2" />
      </div>
    )
  }

  const {
    goLiveDate,
    endDate,
    capResetDate,
    sessionAllowanceToType,
    alternateCoverageSuggestion,
    postCapPatientSessionCost,
    coupon,
    supportedInternationalAreas = [],
    strictlyEnforceRoster,
    isEligiblityEnforced,
    pepmFeeInCents,
    annualFeeInCents,
    sessionFeeInCents,
    isAPreviewContract,
  } = data.contract

  return (
    <section>
      <h2 className="h5 v-align">
        Contract Details{' '}
        <IconButtonLink tooltip="Edit Contract" to="edit" style={{ marginBottom: 4 }}>
          <IconPencil />
        </IconButtonLink>
      </h2>
      {partnerData?.searchPartners?.items?.[0] && (
        <Detail label="Partner" value={partnerData.searchPartners.items[0].name} />
      )}
      <Detail label="Is a trial contract" value={isAPreviewContract ? 'Yes' : 'No'} />
      <Detail label="Go Live Date" value={moment.utc(goLiveDate).format('ll hh:mm a')} />
      <Detail label="End Date" value={moment.utc(endDate).format('ll hh:mm a')} />
      {coupon && <Detail label="Coupon Code" value={coupon.code} />}
      <Detail label="Session Allowance" value={sessionAllowanceToType} />
      <Detail
        label="Zero Cap Payment Preference"
        value={alternateCoverageSuggestion ? getHumanReadablePaymentPreference(alternateCoverageSuggestion) : null}
      />
      <Detail label="Cap Reset Date" value={moment.utc(capResetDate).format('MMM D, YYYY')} />
      <Detail label="Client Cost After Cap" value={numberService.centsToFormattedDollars(postCapPatientSessionCost)} />
      <Detail label="Available To" value={<AvailableToLabels contract={data.contract} />} />
      <Detail label="Is eligibility enforced?" value={isEligiblityEnforced ? 'Yes' : 'No'} />
      <Detail
        label="Strictly enforce roster (dependents must match to themselves)"
        value={strictlyEnforceRoster ? 'Yes' : 'No'}
      />
      <Detail
        label="PEPM Fee"
        value={numberService.centsToFormattedDollars(pepmFeeInCents, {
          maximumFractionDigits: 2,
        })}
      />
      <Detail
        label="Session Fee"
        value={numberService.centsToFormattedDollars(sessionFeeInCents, {
          maximumFractionDigits: 2,
        })}
      />
      <Detail
        label="Annual Fee"
        value={numberService.centsToFormattedDollars(annualFeeInCents, {
          maximumFractionDigits: 2,
        })}
      />
      {supportedInternationalAreas.length > 0 && (
        <div>
          <h6>Supported International Areas</h6>
          <InternationalAreasTable rows={supportedInternationalAreas} />
        </div>
      )}
    </section>
  )
}

function Detail({ value, label }: { value?: ReactNode; label: string }) {
  return (
    <>
      <DetailDatumLabel>{label}</DetailDatumLabel>
      <DetailDatumValue style={{ paddingTop: 4 }}>{value}</DetailDatumValue>
    </>
  )
}

// TODO - these are copied over from ashoka. If the designers decide to stick with this design, move over to core
const DetailDatumLabel = makeTypographyComponent('body caption text-medium text-medium-grey', 'div')
const DetailDatumValue = makeTypographyComponent('mb-2', 'div')
