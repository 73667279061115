import { styled } from '@mui/material'

import { body1, greySet } from '@nuna/tunic'

export enum Sizes {
  Small,
  Medium,
  Large,
}

const mapSize = {
  [Sizes.Small]: '1.5rem',
  [Sizes.Medium]: '3rem',
  [Sizes.Large]: '5rem',
}

const mapFontSize = {
  [Sizes.Small]: '0.75rem',
  [Sizes.Medium]: '1.125rem',
  [Sizes.Large]: '2.25rem',
}

interface AvatarProps {
  url?: string
  size?: Sizes
  name?: {
    first?: string
    last?: string
  }
}

interface AvatarContainerProps {
  size: Sizes
  url?: string
}

const AvatarContainer = styled('div')`
  width: ${({ size }: AvatarContainerProps) => mapSize[size]};
  height: ${({ size }: AvatarContainerProps) => mapSize[size]};
  background-size: cover;
  background-position: top center;
  background-image: url(${({ url }: AvatarContainerProps) => url});
  border-radius: 50%;
  display: inline-flex;
  flex-shrink: 0;
  justify-content: center;
  align-items: center;
  border: 1px solid ${greySet[15].hex};
  font-size: ${({ size }: AvatarContainerProps) => mapFontSize[size]};
  letter-spacing: 1px;
  color: ${body1};
`

/** @deprecated Use Avatar from @nuna/tunic instead */
export function Avatar({ url, name = {}, size = Sizes.Medium }: AvatarProps) {
  const { first, last } = name

  const children = url ? null : `${first ? first[0] : ''}${last ? last[0] : ''}`

  return (
    <AvatarContainer size={size} url={url}>
      {children}
    </AvatarContainer>
  )
}
