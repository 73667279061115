import { PatientIntakeEmployerInput } from '@nuna/api'

import { CoverageFormValues } from '../../CoverageForm'

export function buildEmployerCoverageInput(
  values: Pick<CoverageFormValues, 'employerValues'>,
): PatientIntakeEmployerInput {
  const employerValues = values.employerValues

  if (!employerValues || !employerValues.employer) {
    throw new Error('Employer values not provided')
  }

  return {
    employerId: employerValues.employer.id,
    employerName: employerValues.employer.name,
    relationshipToOrganization: employerValues.employerAssociation,
    relatedEmployeeName: employerValues.employerAssociationEmployee,
    referralId: employerValues.referralId,
  }
}
