import { isNil } from 'lodash'

import { AppointmentForNotificationFragment, useAppointmentForDrawerQuery, useCancelPolicyStatusQuery } from '@nuna/api'
import { useAppointmentDrawerSearchParams } from '@nuna/common'
import { Audience, appointmentService } from '@nuna/core'
import { MessageComposerContextProvider } from '@nuna/messaging'
import { IconRescheduleAppointment, Skeleton } from '@nuna/tunic'

import { AppointmentDrawerPaddedContainer } from '../../AppointmentDrawer'
import { DrawerHeader } from '../DrawerHeader'
import { RescheduleAsClient } from './components/RescheduleAsClient'
import { RescheduleAsProviderOrAdmin } from './components/RescheduleAsProviderOrAdmin'

interface Props {
  audience: Audience
  onAppointmentScheduled?: (appointment: AppointmentForNotificationFragment) => void
}

export function RescheduleAppointment({ audience, onAppointmentScheduled }: Props) {
  const {
    drawerConfig: { appointmentId },
  } = useAppointmentDrawerSearchParams()
  const { data: appointmentData } = useAppointmentForDrawerQuery({ variables: { id: appointmentId ?? '' } })
  const appointment = appointmentData?.internalAppointment
  const { data: cancelPolicyData } = useCancelPolicyStatusQuery({
    variables: {
      providerId: appointment?.provider.id ?? '',
      clientId: appointment?.patient.id ?? '',
      defaultToTavaPolicy: true,
    },
    skip: isNil(appointment),
  })

  if (!cancelPolicyData || !appointment) {
    return (
      <AppointmentDrawerPaddedContainer>
        <Skeleton height={4} />
      </AppointmentDrawerPaddedContainer>
    )
  }

  const cancelPolicy = cancelPolicyData.cancelPolicyStatus.cancelPolicy

  const canChargeLateFee = appointmentService.subjectToCancelationFee({
    policyAcceptedDate: cancelPolicyData?.cancelPolicyStatus?.acceptedDate,
    appointmentStartDatetime: appointment.startDatetime,
    cancelPeriodMinutes: cancelPolicy?.cancelPeriodMinutes,
  })

  return (
    <>
      <DrawerHeader icon={<IconRescheduleAppointment isHovered urgent={canChargeLateFee} style={{ marginTop: -2 }} />}>
        {canChargeLateFee ? 'Late Reschedule' : 'Reschedule'}
      </DrawerHeader>
      <MessageComposerContextProvider
        richTextEditorProps={{ disableAutoLink: true }}
        participantLoginIds={[appointment.patient.loginId ?? '', appointment.provider.loginId]}
      >
        {(() => {
          if (audience === 'client') {
            return (
              <RescheduleAsClient
                appointment={appointment}
                cancelPolicyStatus={cancelPolicyData.cancelPolicyStatus}
                subjectToFee={canChargeLateFee}
                onAppointmentScheduled={onAppointmentScheduled}
              />
            )
          }

          if (['provider', 'admin'].includes(audience)) {
            return (
              <RescheduleAsProviderOrAdmin
                appointment={appointment}
                canChargeLateFee={canChargeLateFee}
                cancelPolicyStatus={cancelPolicyData.cancelPolicyStatus}
                audience={audience}
                onAppointmentScheduled={onAppointmentScheduled}
              />
            )
          }

          return null
        })()}
      </MessageComposerContextProvider>
    </>
  )
}
