import { IconProps } from './IconProps'

export function IconExport({ size = 24, color = 'currentColor', ...props }: IconProps) {
  return (
    <svg width={size} height={size} viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.1744 6.5466H18.1216C18.7817 6.5466 19.3168 7.07981 19.3168 7.74436V10.5131C19.3168 11.1746 18.7784 11.7109 18.1129 11.7109H10.9315V36.5371H37.9108V30.2387C37.9108 29.8593 38.22 29.5517 38.6208 29.5517H41.5007C41.8928 29.5517 42.2107 29.8696 42.2107 30.2387V40.0538C42.2107 40.438 41.9031 40.7494 41.5318 40.7494H7.31192C6.93695 40.7494 6.63298 40.4465 6.63298 40.0538C6.63298 40.0538 6.63159 38.4314 6.63159 38.4172V7.23763C6.63159 6.85822 6.94083 6.55064 7.34163 6.55064H10.2215C10.2646 6.55064 10.3069 6.55449 10.3479 6.56183C10.2944 6.55191 10.2367 6.5466 10.1744 6.5466ZM10.6032 6.66083C10.5525 6.62798 10.4948 6.60099 10.4296 6.5815C10.4915 6.60031 10.5498 6.62722 10.6032 6.66083ZM36.0596 10.7385L31.9256 6.49463C31.4808 6.03802 31.481 5.29545 31.922 4.84023L33.4636 3.24896C33.9064 2.79187 34.6271 2.79264 35.0697 3.24703L44.6922 13.4602C45.025 13.8018 45.024 14.3565 44.6922 14.6972L35.0697 24.3044C34.6255 24.7604 33.9046 24.7577 33.4636 24.3025L31.922 22.7112C31.4792 22.2541 31.4815 21.5127 31.9255 21.0569L36.0945 16.7767C36.3178 16.5474 36.2392 16.3616 35.9211 16.3616L32.6775 16.3616L30.0655 16.3419C26.2188 16.3419 23.1004 19.4251 23.1004 23.2285V29.5713C23.1004 30.2027 22.5813 30.7191 21.9411 30.7191H19.0359C18.3965 30.7191 17.8765 30.2052 17.8765 29.5713V23.2285C17.8765 16.5726 23.3337 11.177 30.0655 11.177L32.6775 11.1421L35.8782 11.1506C36.2001 11.1506 36.2802 10.9651 36.0596 10.7385Z"
        fill={color}
      />
    </svg>
  )
}
