import { useEffect } from 'react'

import { type EAPCoverageFragment } from '@nuna/core'

import { CoverageTypeCard, EmployerProps } from '../../CoverageTypeCard'
import { Cost } from './Cost'

interface EAPSummaryProps {
  coverageCardDetails: EmployerProps
  currentCoverage: EAPCoverageFragment
  onCoverageValidityChange: (isValid: boolean) => void
}

export function EAPSummary({ currentCoverage, coverageCardDetails, onCoverageValidityChange }: EAPSummaryProps) {
  useEffect(() => {
    onCoverageValidityChange(!coverageCardDetails.isInvalid)
  }, [coverageCardDetails.isInvalid, onCoverageValidityChange])

  return (
    <>
      <CoverageTypeCard {...coverageCardDetails} />

      {(() => {
        if (coverageCardDetails.isInvalid && !coverageCardDetails.isZeroCap) {
          return (
            <p className="caption text-error mt-2 mb-0 text-medium">
              You've used all {currentCoverage.details.sessionsCap} sessions covered by{' '}
              {currentCoverage.details.employerName}.
            </p>
          )
        }

        return (
          <Cost type="employer" isZeroCap={coverageCardDetails.isZeroCap} sessionCost={currentCoverage.details.cost} />
        )
      })()}
    </>
  )
}
