import { DrawerAppointmentFragment, PaymentPreference } from '@nuna/api'
import { AppointmentCard, AppointmentCardStatus } from '@nuna/appointment'
import { NavigationLink, ProviderClientPaymentStatusAlert, useAppointmentDrawerSearchParams } from '@nuna/common'
import { Audience, routeService } from '@nuna/core'
import {
  FillButton,
  FillButtonLink,
  GhostButton,
  GhostButtonLink,
  IconCancelAppointment,
  IconHistory,
  IconRescheduleAppointment,
  IconVideo,
} from '@nuna/tunic'

import { AppointmentDrawerPaddedContainer } from '../../../AppointmentDrawer'
import { useMostRecentSessionNote } from '../../../hooks/useMostRecentSessionNote'
import { ClientDrawerHeader } from '../../ClientDrawerHeader'
import { DrawerFooter } from '../../DrawerFooter'
import { ClientCapInfo } from './ClientCapInfo'

interface Props {
  appointment: DrawerAppointmentFragment
  audience: Audience
}

export function OngoingAppointment({ appointment, audience }: Props) {
  const { openCancelAppointmentDrawer, openRescheduleAppointmentDrawer } = useAppointmentDrawerSearchParams()
  const mostRecentAppointmentWithNote = useMostRecentSessionNote(appointment?.patient.id, appointment?.provider.id)
  const hasAddress = 'address' in appointment && !!appointment.address
  const address = hasAddress ? appointment.address : null

  const patientId = appointment.patient.id
  const paymentStatus = appointment?.patient?.paymentStatus

  return (
    <>
      <ClientDrawerHeader client={appointment.patient} />

      {appointment.coverageType === PaymentPreference.Employer && (
        <ClientCapInfo className="px-3" clientId={appointment.patient.id} />
      )}
      {!!patientId && (
        <AppointmentDrawerPaddedContainer className="mt-3">
          <ProviderClientPaymentStatusAlert scene={'clientInfo'} paymentStatus={paymentStatus} />
        </AppointmentDrawerPaddedContainer>
      )}
      <AppointmentDrawerPaddedContainer className="mt-3">
        <AppointmentCard
          appointment={appointment}
          client={appointment.patient}
          provider={appointment.provider}
          appointmentStatus={AppointmentCardStatus.Active}
          audience={audience}
          showAvatar={false}
          boot
        />
        {audience === 'provider' && !address?.id ? (
          <FillButtonLink className="mt-3" style={{ width: 'auto' }} to={routeService.videoSession(appointment.id)}>
            <IconVideo className="mr-1" size={18} /> Join Session
          </FillButtonLink>
        ) : (
          <NavigationLink
            address={appointment.address}
            containerProps={{ className: 'mt-3 mb-2 v-align' }}
            labelOverride="Get Directions"
            loadingMessages={[]}
            buttonElement={FillButton}
            buttonProps={{ className: 'v-align' }}
          />
        )}
        {mostRecentAppointmentWithNote?.sessionId && (
          <div className="mt-3 v-align">
            <IconHistory className="mr-1" size={18} />
            <GhostButtonLink to={routeService.sessionNote(mostRecentAppointmentWithNote.sessionId, audience)}>
              Review Previous Session Note
            </GhostButtonLink>
          </div>
        )}
      </AppointmentDrawerPaddedContainer>
      <DrawerFooter>
        <GhostButton variant="secondary" onClick={() => openRescheduleAppointmentDrawer(appointment.id)}>
          <IconRescheduleAppointment className="mr-1" isHovered={false} />
          Reschedule Appointment
        </GhostButton>
        <GhostButton className="mt-2" variant="secondary" onClick={() => openCancelAppointmentDrawer(appointment.id)}>
          <IconCancelAppointment className="mr-1" isHovered={false} />
          Cancel Appointment
        </GhostButton>
      </DrawerFooter>
    </>
  )
}
