import { styled } from '@mui/material'
import { range } from 'lodash'
import moment from 'moment'

import { AppointmentInfoSummary } from '@nuna/api'
import { Phone } from '@nuna/tunic'

import { DateList } from '../../shared/DateList/DateList'
import { SessionListItem, SessionListItemLoader } from './SessionListItem'

interface Props {
  pastAppointments: AppointmentInfoSummary[]
  loading: boolean
}

// NOTE: Expects caller to filter to just 'active' appointments
export function PastSessionIndex({ loading, pastAppointments }: Props) {
  const sortedPastAppointments =
    pastAppointments.slice().sort((a, b) => (moment(a.startDatetime).isAfter(b.startDatetime) ? -1 : 1)) || []

  if (loading) {
    return (
      <Container>
        <h2 style={{ maxWidth: 100 }} className="loading h6 mb-2">
          Loading
        </h2>

        {range(10).map(() => (
          <SessionListItemLoader />
        ))}
      </Container>
    )
  }

  return (
    <Container>
      {sortedPastAppointments.length && (
        <DateList
          items={sortedPastAppointments}
          datetimeAccesor={item => item.startDatetime}
          itemGenerator={item => {
            return <SessionListItem key={item.id} internalAppointment={item} />
          }}
        />
      )}
    </Container>
  )
}

const Container = styled('div')`
  min-height: 0;
  max-width: 60rem;

  @media (${Phone}) {
    padding: 1rem 0.75rem 0;
  }
`
