import { useMemo } from 'react'
import { Navigate, Route, Routes, useLocation } from 'react-router-dom'

import { useBaseProviderQuery } from '@nuna/api'
import { useAuthDataContext } from '@nuna/auth'
import { useTitle } from '@nuna/common'
import { InsuranceAccepted, Licenses, Preferences } from '@nuna/provider'
import {
  Avatar,
  CenteredHeader,
  MenuItem,
  PageContent,
  PageHeader,
  PageHeading,
  PageWrapper,
  ResponsiveHorizontalMenu,
  Typography,
} from '@nuna/tunic'

import { Payroll } from '../../scenes/Payroll/Payroll'
import { ProviderProfileForProvider } from '../../scenes/ProviderProfile/ProviderProfileForProvider'
import { insurance, licenses, payrollSummary, preferences, providerProfile } from '../../util/routes'

function ProviderNavBarHeader({ header }: { header: string }) {
  return <h1 className="mt-1 ml-1 serif h4 text-medium-dark-grey">{header}</h1>
}

const pathToConfigMap: Record<string, Record<string, string>> = {
  profile: { title: 'Profile', header: 'Your profile & practice site info' },
  payouts: { title: 'Payouts', header: 'Upcoming payouts' },
  licenses: { title: 'Licenses', header: 'Licenses and credentials' },
  insurance: { title: 'Insurance', header: 'Insurance enrollments' },
  preferences: { title: 'Preferences', header: 'Your preferences' },
}

const getConfigForPath = (pathname: string) => {
  const foundConfig = Object.keys(pathToConfigMap).find(path => pathname.includes(path))
  return foundConfig ? pathToConfigMap[foundConfig] : { title: 'Default Title', header: 'Default Header' }
}

export function TherapistNavBar() {
  const { login } = useAuthDataContext()
  const { data } = useBaseProviderQuery({ variables: { id: login?.providerId || '' }, skip: !login?.providerId })
  const provider = data?.provider
  const location = useLocation()

  const { title, header } = useMemo(() => getConfigForPath(location.pathname), [location])

  useTitle(title)

  return (
    <PageWrapper>
      <PageHeader>
        <CenteredHeader className="v-align">
          <PageHeading withDivider className="v-align mb-0">
            <Avatar size="xs" url={provider?.avatarUrl} />
            <Typography variant="h5" sx={{ mb: 0, ml: 1, display: { xs: 'none', sm: 'block', lineHeight: '3rem' } }}>
              {provider?.firstName} {provider?.lastName}
            </Typography>
          </PageHeading>
          <ResponsiveHorizontalMenu style={{ width: '100%' }}>
            <MenuItem to={providerProfile()}>My Profile</MenuItem>
            <MenuItem to={payrollSummary()}>Payouts</MenuItem>
            <MenuItem to={preferences()}>Preferences</MenuItem>
            <MenuItem to={licenses()}>Licenses</MenuItem>
            <MenuItem to={insurance()}>Insurance</MenuItem>
          </ResponsiveHorizontalMenu>
        </CenteredHeader>
      </PageHeader>
      <PageContent>
        <ProviderNavBarHeader header={header} />
        <Routes>
          {/* Provider */}
          <Route path="profile" element={<ProviderProfileForProvider />} />

          {/* Finance */}
          <Route path="payouts/*" element={<Payroll />} />

          <Route path="credentials" element={<Navigate to="../licenses" replace />} />

          <Route
            path="licenses"
            element={
              <div style={{ overflowY: 'auto' }}>
                <Licenses providerId={login?.providerId} />
              </div>
            }
          />

          <Route
            path="insurance"
            element={
              <div style={{ padding: '0.5rem', overflowY: 'auto' }}>
                <InsuranceAccepted providerId={login?.providerId} />
              </div>
            }
          />

          <Route
            path="preferences/*"
            element={
              <div style={{ paddingTop: '1rem' }}>
                <Preferences providerId={login?.providerId} providerLoginId={login?.id} />
              </div>
            }
          />
        </Routes>
      </PageContent>
    </PageWrapper>
  )
}
