import { TextField, TextFieldProps } from '@mui/material'
import { forwardRef } from 'react'
import Input from 'react-phone-number-input/input'

const TextFieldWithRef = forwardRef<HTMLInputElement, TextFieldProps>((props, ref) => (
  <TextField inputRef={ref} {...props} />
))

export type PhoneTextFieldProps = TextFieldProps & {
  onChange: (value: string | undefined) => void
  value: string | undefined | null
}

export function PhoneTextField({ onChange, value = '', ...props }: PhoneTextFieldProps) {
  return (
    <Input
      country="US"
      value={value ?? ''}
      onChange={onChange}
      {...props}
      // the type from forwardRef is a function that might return null and that clashes with the type expected here because it doesn't want a function that returns null.
      // However, TextFieldWithRef won't actually return null and the text field works fine as is so ignoring the error
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      inputComponent={TextFieldWithRef}
    />
  )
}

export const phoneUSAValidationRegex = /^\+1\d{10}$/
