import { MutationFunctionOptions } from '@apollo/client'

import {
  InsurancePolicyFragment,
  VerifyInsuranceCoverageMutation,
  VerifyInsuranceCoverageMutationVariables,
  useVerifyInsuranceCoverageMutation,
} from '@nuna/api'
import { errorService } from '@nuna/core'

import { WrappedResponse } from '../types'

export function useSaveInsuranceCoverage() {
  const [verifyInsuranceCoverageMutation, mutationResult] = useVerifyInsuranceCoverageMutation({
    refetchQueries: ['PatientCoverage'],
  })

  const verifyInsuranceCoverage = async (
    options?: MutationFunctionOptions<VerifyInsuranceCoverageMutation, VerifyInsuranceCoverageMutationVariables>,
  ): Promise<WrappedResponse<InsurancePolicyFragment>> => {
    try {
      const insuranceResult = await verifyInsuranceCoverageMutation(options)

      if (insuranceResult.data && insuranceResult.data.verifyInsuranceCoverage.insurancePolicy) {
        return {
          didSucceed: insuranceResult.data.verifyInsuranceCoverage.isVerified,
          error: !insuranceResult.data.verifyInsuranceCoverage.isVerified
            ? new Error(
                insuranceResult.data.verifyInsuranceCoverage.insurancePolicy.failureReason ??
                  'e-Check was not successful',
              )
            : null,
          data: insuranceResult.data.verifyInsuranceCoverage.insurancePolicy,
        }
      }
    } catch (e) {
      return {
        didSucceed: false,
        error: errorService.getErrorFromUnknown(e),
        data: null,
      }
    }

    return {
      didSucceed: false,
      error: new Error('Could not get insurance policy'),
      data: null,
    }
  }

  return [verifyInsuranceCoverage, mutationResult] as const
}
