import { noop } from 'lodash'

import { PaymentPreference } from '@nuna/api'
import { coverageService } from '@nuna/core'
import { Drawer, DrawerProps } from '@nuna/tunic'

import { CoverageFormPatient } from '../CoverageForm'
import { CoverageTypeIcon } from '../CoverageTypeIcon'
import { DrawerAccessCodeForm } from './components/DrawerAccessCodeForm'
import { DrawerCashForm } from './components/DrawerCashForm'
import { DrawerEmployerForm } from './components/DrawerEmployerForm'
import { DrawerInsuranceForm } from './components/DrawerInsuranceForm'

interface Props extends Omit<DrawerProps, 'isOpen'> {
  client: CoverageFormPatient
  coverageType: PaymentPreference | undefined
  onSubmitCommplete?: () => void
}

export function CoverageFormDrawer({ client, coverageType, onClose, onSubmitCommplete = noop, ...props }: Props) {
  const handleSubmitComplete = () => {
    onClose()
    onSubmitCommplete()
  }

  return (
    <Drawer isOpen={!!coverageType} onClose={onClose} {...props}>
      {coverageType ? (
        <div className="p-3">
          <h3 className="h5 mb-3 v-align">
            <CoverageTypeIcon size={24} type={coverageType} className="mr-1" />
            {coverageService.getHumanReadablePaymentPreference(coverageType)} setup
          </h3>

          {(() => {
            switch (coverageType) {
              case PaymentPreference.Insurance:
                return <DrawerInsuranceForm client={client} onSubmitComplete={handleSubmitComplete} />
              case PaymentPreference.Accesscode:
                return <DrawerAccessCodeForm client={client} onSubmitComplete={handleSubmitComplete} />
              case PaymentPreference.Employer:
                return <DrawerEmployerForm client={client} onSubmitComplete={handleSubmitComplete} />
              case PaymentPreference.Cash:
                return <DrawerCashForm client={client} onSubmitComplete={handleSubmitComplete} />
              default:
                return null
            }
          })()}
        </div>
      ) : null}
    </Drawer>
  )
}
