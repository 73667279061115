/* eslint-disable @typescript-eslint/no-explicit-any */
import { useQuery } from '@apollo/client'
import { styled } from '@mui/material'
import { range } from 'lodash'
import React, { useState } from 'react'

import {
  Patient,
  PatientPaymentStatus,
  ProviderDocument,
  ProviderPatientsDocument,
  ProviderPatientsQuery,
  ProviderQuery,
} from '@nuna/api'
import { useIsAdmin } from '@nuna/auth'
import { useClientInviteDrawerSearchParams } from '@nuna/common'
import {
  Avatar,
  GhostButton,
  IconButton,
  IconCautionCircle,
  IconClose,
  IconSearch,
  TextField,
  greySet,
  salmonSet,
  shadowDepth,
  tealSet,
} from '@nuna/tunic'

interface PatientListProps {
  onSelect: (patient: Pick<Patient, 'id' | 'firstName' | 'lastName' | 'avatarUrl' | 'paymentStatus'>) => void
  onClose?: (event: React.SyntheticEvent<HTMLElement, Event>) => void
  providerId?: string
}

export function NewPatientList({ onClose, onSelect, providerId }: PatientListProps) {
  const { patients, loading: isLoadingPatients } = usePatientsQuery(providerId)
  const isAdmin = useIsAdmin()
  const [searchString, setSearchString] = useState('')
  const { openClientInviteDrawer } = useClientInviteDrawerSearchParams()

  return (
    <>
      <Header>
        <h5 className="px-4 pt-4 mb-0">Who do you want to schedule?</h5>
        <HeaderClientField>
          <TextField
            autoFocus
            renderIcon={color => <IconSearch color={color} />}
            value={searchString}
            onChange={e => setSearchString(e.target.value)}
            placeholder="Find a Client"
            className="full-width"
          />

          <IconButton
            tooltip="Clear"
            variant="secondary"
            onClick={onClose}
            className="ml-auto"
            style={{ flex: '0 0 auto', position: 'relative', top: '3px' }}
          >
            <IconClose size={20} />
          </IconButton>
        </HeaderClientField>
        <GhostButton
          style={{ float: 'right' }}
          className="mb-3 mr-4"
          variant={'secondary'}
          onClick={() =>
            openClientInviteDrawer({ providerId, redirectToAppointmentScheduling: true, wipeOldParams: true })
          }
        >
          Add a client
        </GhostButton>
      </Header>

      <List>
        {isLoadingPatients &&
          range(4).map(i => (
            <li key={i}>
              <Item disabled>
                <Avatar className={`mr-1 loading`} size="xs" />
                <span style={{ minWidth: 150 }} className="loading">
                  Loading...
                </span>
              </Item>
            </li>
          ))}

        {patients
          .filter(client => `${client.firstName} ${client.lastName}`.toLowerCase().includes(searchString.toLowerCase()))
          .map(client => {
            const isDisabledNewAppointment = client.paymentStatus === PatientPaymentStatus.Overdue && !isAdmin
            return (
              <li key={client.id}>
                <Item onClick={() => onSelect(client)} disabled={isDisabledNewAppointment}>
                  <Avatar url={client.avatarUrl} className="mr-1 fs-exclude" size="xs" />
                  {client.firstName} {client.lastName}{' '}
                  {isDisabledNewAppointment && <StyledOverdueIcon className={'overdue-icon'} size={16} />}
                </Item>
              </li>
            )
          })}
      </List>
    </>
  )
}

const Header = styled('div')`
  background-color: #fff;
  box-shadow: 0 1px 0 0 #e1e8ea;
`

const HeaderClientField = styled('div')`
  display: flex;
  align-items: baseline;
  padding: 1rem 1.5rem 1.5rem;
`

const List = styled('ul')`
  padding: 1rem 1.5rem;
  overflow: auto;
  margin-top: 0;
  margin-bottom: 0;
`
List.defaultProps = { className: 'unstyled-list' }

const Item = styled('button')`
  border: 1px solid transparent;
  border-radius: 6px;
  display: flex;
  align-items: center;
  padding: 0.5rem;
  text-align: left;
  width: 100%;

  svg {
    display: none;
  }

  &:disabled {
    cursor: default;
  }

  &:not(:disabled) {
    &:hover,
    &:focus {
      background-color: ${greySet[15].hex};
      color: ${greySet[90].hex};
      outline: none;
    }

    &:active {
      background-color: #fff;
      border-color: ${greySet[5].hex};
      box-shadow: ${shadowDepth(1)};

      svg {
        fill: ${tealSet[90].hex};
      }
    }

    &:hover,
    &:focus,
    &:active {
      svg {
        display: inline;
      }
    }
  }

  .overdue-icon {
    display: inline;
  }
`

const StyledOverdueIcon = styled(IconCautionCircle)`
  display: inline;
  margin-left: 8px;
  color: ${salmonSet[80].hex};
`

function usePatientsQuery(providerId?: string): {
  loading: boolean
  patients: Pick<Patient, 'id' | 'firstName' | 'lastName' | 'avatarUrl' | 'paymentStatus'>[]
} {
  const variables = providerId ? { id: providerId } : {}
  const document = providerId ? ProviderDocument : ProviderPatientsDocument
  const { data, loading } = useQuery<ProviderPatientsQuery | ProviderQuery>(document, {
    variables,
    fetchPolicy: 'cache-and-network',
  })

  const patients = data
    ? providerId
      ? (data as ProviderQuery)?.provider?.patients || []
      : (data as ProviderPatientsQuery)?.providerMe?.patients || []
    : []

  return { loading, patients }
}
