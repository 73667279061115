import { useHubspotContactOwnerQuery } from '@nuna/api'
import { IconChevronThick } from '@nuna/tunic'

import referralImgSrc from '../img/boost-referrals.png'
import { PayoutCTABanner } from '../shared'

interface Props {
  providerId?: string | null
  isPopOut?: boolean
}

export function TavaReferralCTABanner({ providerId, isPopOut }: Props) {
  const { data } = useHubspotContactOwnerQuery({ variables: { providerId: providerId ?? '' }, skip: !providerId })

  const scheduleUrl = data?.hubspotContactOwner?.scheduleUrl ?? ''
  return (
    <PayoutCTABanner
      variant="plum"
      boot={
        <div className="v-align">
          Schedule a chat <IconChevronThick className="ml-1" size={20} direction="right" />
        </div>
      }
      openInNewTab
      to={scheduleUrl}
      isPopOut={isPopOut}
    >
      <div className="v-align space-between gap-2">
        <span>Pump up your caseload. Turn on Tava referrals.</span>
        <img src={referralImgSrc} alt="" />
      </div>
    </PayoutCTABanner>
  )
}
