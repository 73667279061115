import moment from 'moment-timezone'

const ISO_8601_UTC_FORMAT = 'YYYY-MM-DDTHH:mm:ss.SSSZ'

function utcFormat(date: string | moment.Moment | null | undefined, format?: string) {
  if (!date) {
    return ''
  }

  return moment.utc(date).format(format)
}

function isIso8601UtcFormat(dateString?: string | null) {
  if (!dateString) {
    return false
  }
  return moment(dateString, ISO_8601_UTC_FORMAT, true).isValid()
}

function addDays(date: Date, days: number) {
  const result = new Date(date)
  result.setDate(result.getDate() + days)
  return result
}

function formatDate(date: string | moment.Moment | null | undefined, format?: string) {
  if (!date) {
    return ''
  }

  return moment(date).format(format)
}

export const dateService = {
  utcFormat,
  isIso8601UtcFormat,
  addDays,
  formatDate,
}
