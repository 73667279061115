import { useAudioTrack, useDaily, useDevices, useLocalSessionId, useVideoTrack } from '@daily-co/daily-react'
import { styled } from '@mui/material'
import mixpanel from 'mixpanel-browser'
import { HTMLAttributes, useState } from 'react'

import { BelowTablet } from '@nuna/tunic'

import { useVideoCallContext } from '../../../context/VideoCallContext'
import { ABOVE_VIDEO } from '../../../util/zIndexes'
import { EndSessionWarning } from '../../EndSessionWarning'
import { MicrophoneButton } from '../../MicrophoneButton'
import { VideoActionButton, VideoToggleButton } from '../../VideoActionButton'
// import { DebugActions } from './DebugActions'
import { ScreenShareButton } from './ScreenShareButton'

export function VideoButtons(props: HTMLAttributes<HTMLDivElement>) {
  const callObject = useDaily()
  const { appointmentId, localUser, endSession, isMobileFullscreen } = useVideoCallContext()
  const localSessionId = useLocalSessionId()
  const localVideoTrack = useVideoTrack(localSessionId)
  const localAudioTrack = useAudioTrack(localSessionId)
  const { cameras } = useDevices()

  const [showEndSessionConfirmation, setShowEndSessionConfirmation] = useState(false)

  const handleVideoToggleClick = () => callObject?.setLocalVideo(localVideoTrack.isOff)
  const handleAudioToggleClick = () => callObject?.setLocalAudio(localAudioTrack.isOff)

  const handleEndSessionClick = () => {
    if (localUser.isProvider) {
      setShowEndSessionConfirmation(true)
    } else {
      mixpanel.track('video:client leave', { appointmentId })
      endSession()
    }
  }

  const handleEndSessionConfirmClick = () => {
    mixpanel.track('video:provider end session', { appointmentId })
    setShowEndSessionConfirmation(false)
    endSession()
  }

  const handleEndSessionCancelClick = () => {
    setShowEndSessionConfirmation(false)
  }

  return (
    <>
      <Buttons $isHiddenMobile={isMobileFullscreen} {...props}>
        <VideoToggleButton
          disabled={!localVideoTrack || cameras.length === 0}
          isEnabled={!localVideoTrack.isOff}
          onClick={handleVideoToggleClick}
          data-component="video-camera-toggle"
        />

        <MicrophoneButton
          disabled={!localAudioTrack}
          isEnabled={!localAudioTrack.isOff}
          onClick={handleAudioToggleClick}
          data-component="video-microphone-toggle"
        />

        <ScreenShareButton />

        <VideoActionButton
          onClick={handleEndSessionClick}
          data-component="video-end-session"
          data-testid="video-end-session"
        >
          <span>{localUser.isProvider ? 'End Session' : 'Leave'}</span>
        </VideoActionButton>

        {/* <DebugActions /> */}
      </Buttons>

      {showEndSessionConfirmation && (
        <EndSessionWarning onConfirmClick={handleEndSessionConfirmClick} onCancelClick={handleEndSessionCancelClick} />
      )}
    </>
  )
}

const Buttons = styled('div')<{ $isHiddenMobile: boolean }>`
  align-items: center;
  align-self: center;
  bottom: 30px;
  display: flex;
  justify-content: center;
  position: absolute;
  text-align: center;
  transition: transform 0.3s;
  z-index: ${ABOVE_VIDEO};

  @media (${BelowTablet}) {
    bottom: 1rem;

    ${props =>
      props.$isHiddenMobile &&
      `
      transform: translateY(calc(100% + 16px));
    `}
  }
`
