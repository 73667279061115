import { styled } from '@mui/material'
import { MouseEventHandler, useEffect, useState } from 'react'

import {
  ConversationMessageAttachmentFragment,
  ConversationMessageFragment,
  DocumentType,
  useDocumentUpload,
  useRemoveMessageAttachmentMutation,
  useSaveMessageAttachmentMutation,
} from '@nuna/api'
import { AttachmentChip } from '@nuna/tunic'

interface ConversationMessageAttachmentProps {
  attachment: ConversationMessageAttachmentFragment
  onRemoveClick?: MouseEventHandler<HTMLButtonElement>
}

export function ConversationMessageAttachment({ attachment, onRemoveClick }: ConversationMessageAttachmentProps) {
  if (!attachment.url) return null

  return (
    <StyledAttachmentChip
      downloadUrl={attachment.url}
      fileName={attachment.fileName}
      mimeType={attachment.mimeType}
      onRemove={onRemoveClick}
    />
  )
}

export type PendingAttachmentStatus = 'pending' | 'uploading' | 'attaching' | 'success' | 'error'

export interface PendingAttachment {
  file: File
  status: PendingAttachmentStatus
}

interface PendingConversationAttachmentProps {
  pendingAttachment: PendingAttachment
  onRemoveClick: MouseEventHandler<HTMLButtonElement>
  message: ConversationMessageFragment | null
  onSuccess: (pendingAttachment: PendingAttachment) => void
  onUploadStart: (pendingAttachment: PendingAttachment) => void
  onAttachmentStart: (pendingAttachment: PendingAttachment) => void
  onError: (pendingAttachment: PendingAttachment) => void
}

export function PendingConversationAttachment({
  pendingAttachment,
  onRemoveClick,
  message,
  onUploadStart,
  onAttachmentStart,
  onSuccess,
  onError,
}: PendingConversationAttachmentProps) {
  const { uploadDocument } = useDocumentUpload()
  const [saveMessageAttachment, { data: conversationMessageAttachmentData }] = useSaveMessageAttachmentMutation()
  const [removeMessageAttachment, { loading: removeLoading }] = useRemoveMessageAttachmentMutation()
  const [documentId, setDocumentId] = useState<string | null>(null)

  // uploads file to S3
  useEffect(() => {
    if (pendingAttachment.status === 'pending') {
      onUploadStart(pendingAttachment)
      uploadDocument(pendingAttachment.file, DocumentType.MessageAttachment)
        .then(document => setDocumentId(document.id))
        .catch(e => {
          console.error(e)
          // notice this is the original closed-over pendingAttachment and not the one that has changed as a result of calling `onUploadStart`
          onError(pendingAttachment)
        })
    }
  }, [uploadDocument, pendingAttachment, onError, onUploadStart])

  // associates upload with conversation message once a message id is available (i.e. the draft has saved) and a documentId is available (i.e. the file has uploaded)
  useEffect(() => {
    if (documentId && message && !conversationMessageAttachmentData && pendingAttachment.status === 'uploading') {
      onAttachmentStart(pendingAttachment)
      saveMessageAttachment({
        variables: { documentId, messageId: message.id },
      }).catch(e => {
        console.error(e)
        // notice this is the original closed-over pendingAttachment and not the one that has changed as a result of calling `onAttachmentStart`
        onError(pendingAttachment)
      })
    }
  }, [
    documentId,
    message,
    saveMessageAttachment,
    conversationMessageAttachmentData,
    onError,
    pendingAttachment,
    onAttachmentStart,
  ])

  // tells parent component that attachment succeeded
  useEffect(() => {
    if (pendingAttachment.status === 'attaching' && conversationMessageAttachmentData) {
      onSuccess(pendingAttachment)
    }
  }, [pendingAttachment, conversationMessageAttachmentData, onSuccess])

  if (conversationMessageAttachmentData) {
    const attachment = conversationMessageAttachmentData.saveMessageAttachment

    return (
      <StyledAttachmentChip
        status={removeLoading ? 'loading' : 'success'}
        fileName={attachment.fileName}
        mimeType={attachment.mimeType}
        onRemove={async e => {
          await removeMessageAttachment({
            variables: { attachmentId: attachment.id },
          })
          onRemoveClick(e)
        }}
      />
    )
  }

  const getPendingStatus = () => {
    if (pendingAttachment.status === 'success') {
      return 'success'
    }
    if (pendingAttachment.status === 'error') {
      return 'error'
    }

    return 'loading'
  }

  return (
    <StyledAttachmentChip
      fileName={pendingAttachment.file.name}
      mimeType={pendingAttachment.file.type}
      status={getPendingStatus()}
    />
  )
}

// util function that creates a callback to be passed to parent component's setPendingAttachments
export function updateAttachmentStatus(updatingAttachment: PendingAttachment, updatedStatus: PendingAttachmentStatus) {
  return function setStateCb(existingAttachments: PendingAttachment[]) {
    return existingAttachments.map(attachment => {
      if (updatingAttachment.file === attachment.file) {
        // Comparing file here since the identity of the File object doesn't change and we don't have to worry about the onError closure noted above
        return { ...updatingAttachment, status: updatedStatus }
      }

      return attachment
    })
  }
}

const StyledAttachmentChip = styled(AttachmentChip)`
  margin-top: var(--spacing-1);
  margin-right: var(--spacing-1);
`
