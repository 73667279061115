import { DailyVideo, useWaitingParticipants } from '@daily-co/daily-react'
import { styled } from '@mui/material'
import moment from 'moment'
import { useState } from 'react'

import { useDurationString } from '@nuna/common'
import { Avatar, FillButton, IconClock, makeTypographyComponent } from '@nuna/tunic'

import { useAccessState } from '../../../hooks/useAccessState'
import { useMeetingSessionState } from '../../../hooks/useMeetingSessionState'
import { useParticipantSessionId } from '../../../hooks/useParticipantSessionId'
import { useWaitingParticipantId } from '../../../hooks/useWaitingParticipantId'
import { TavaVideoUser } from '../../../types'
import { DIALOG } from '../../../util/zIndexes'
import { ConnectionStatus } from './ConnectionStatus'
import { ParticipantVideo } from './ParticipantVideo'
import { ParticipantVideoStatus } from './ParticipantVideoStatus'

type Props = VideoParticipantProps | ScreenSharingParticipantProps

export function Participant(props: Props) {
  return (
    <Container>
      {(() => {
        if (props.isScreenShare) {
          return <ScreenSharingParticipant {...props} />
        }

        return <VideoParticipant {...props} />
      })()}
    </Container>
  )
}

interface VideoParticipantProps {
  user: TavaVideoUser
  isScreenShare?: false
}

function VideoParticipant({ user }: VideoParticipantProps) {
  const sessionId = useParticipantSessionId(user.loginId)
  const { awaitingAccessLevel } = useAccessState()
  const waitingId = useWaitingParticipantId(user.loginId)

  const isAwaitingAccess = awaitingAccessLevel?.level === 'full'

  // we are the provider and a patient is requesting access
  if (waitingId) return <WaitingParticipant waitingId={waitingId} user={user} />

  return (
    <>
      <div className="text-center flex-column v-align">
        <Avatar className="mb-2 block" size="lg" url={user.avatarUrl} />

        <ConnectionStatus name={user.firstName} isOnline={!!sessionId} canSeeStatus={!isAwaitingAccess} />

        {/* // we are the patient and are awaiting the provider's response */}
        {isAwaitingAccess && user.isProvider && (
          <WaitingSecondaryText className="v-align">
            <IconClock size={20} className="mr-xs" /> Waiting for {user.firstName} to let you in
          </WaitingSecondaryText>
        )}
      </div>

      {sessionId && (
        <>
          <ParticipantVideo sessionId={sessionId} />
          <ParticipantVideoStatus sessionId={sessionId} />
        </>
      )}
    </>
  )
}

function WaitingParticipant({ user, waitingId }: { user: TavaVideoUser; waitingId: string }) {
  const { grantAccess } = useWaitingParticipants()
  const [meetingSessionDate, setMeetingSessionData] = useMeetingSessionState()
  const [waitingStartTime] = useState(moment)
  const waitingDurationString = useDurationString(waitingStartTime)

  const handleBeginSessionClick = () => {
    grantAccess(waitingId)

    if (!meetingSessionDate.sessionStartTime) {
      setMeetingSessionData({
        sessionStartTime: new Date().toISOString(),
      })
    }
  }

  return (
    <WaitingContainer>
      <WaitingSecondaryText>
        {!waitingDurationString ? <>&nbsp;</> : `Waiting for ${waitingDurationString}`}
      </WaitingSecondaryText>

      <h2 className="mt-2 mb-3 h4 text-white px-2">{user.firstName} is ready to see you</h2>

      <Avatar className="mb-4" size="lg" url={user.avatarUrl} />

      <BeginButton onClick={handleBeginSessionClick}>Begin Session</BeginButton>
    </WaitingContainer>
  )
}

interface ScreenSharingParticipantProps {
  sessionId: string
  isScreenShare: true
}

function ScreenSharingParticipant({ sessionId }: ScreenSharingParticipantProps) {
  return (
    <>
      {/* Screenshare doesn't need to have optimized sizing, disabled state, etc. so we can just render the DailyVideo directly */}
      <DailyVideo sessionId={sessionId} type="screenVideo" fit="contain" />
      <ParticipantVideoStatus sessionId={sessionId} />
    </>
  )
}

const BeginButton = styled(FillButton)`
  z-index: ${DIALOG}; // otherwise the mobile toggle button will block
`

const WaitingSecondaryText = makeTypographyComponent('caption italic text-grey-30', 'p')

const WaitingContainer = styled(makeTypographyComponent('text-center flex-column v-align py-5', 'div'))`
  max-height: 100%;
  overflow: auto;

  > * {
    flex: 0 0 auto;
  }
`

const Container = styled('div')`
  border-radius: 12px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 1 0 50%;
  overflow: hidden;
  position: relative;
  height: 100%;

  video {
    width: 100%;
    height: 100%;
  }
`
