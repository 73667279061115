import { styled } from '@mui/material'

import { CashCoverageDetailsFragment, Role } from '@nuna/api'
import { hasRole, useUserRole } from '@nuna/auth'
import { numberService } from '@nuna/core'
import { Card, CardBody, CardHeader, IconCreditCard, IconDollarSymbol, greySet } from '@nuna/tunic'

import { CoverageIcon, CoverageList, CoverageListItem } from './CoverageTypography'

interface Props {
  coverage: CashCoverageDetailsFragment
  sessionCost?: number
}
export function CashCoverageCard({ coverage, sessionCost }: Props) {
  const role = useUserRole()

  const { cardBrand, cardNumber } = coverage
  const cardIsOnFile = !!cardNumber

  return (
    <Card>
      <CardHeader className="v-align space-between" divider={false}>
        <span>Out-of-Pocket</span>
        <IconDollarSymbol size={16} />
      </CardHeader>
      <CardBody>
        <CreditCardContainer>
          {!cardIsOnFile && <CardIsOnFileDisplay isOnFile={false} />}
          {hasRole(role, Role.Provider) && cardIsOnFile && <CardIsOnFileDisplay isOnFile />}
          {cardIsOnFile && hasRole(role, [Role.Admin, Role.Patient]) && (
            <div className="mt-1">
              <div className="v-align caption" style={{ fontWeight: 800, textTransform: 'capitalize' }}>
                <IconCreditCard size={16} className="mr-1" /> {cardBrand}
              </div>
              <div className="large">{cardNumber}</div>
            </div>
          )}
        </CreditCardContainer>
        {sessionCost && (
          <div className="mt-2">At a rate of ${numberService.centsToDollars(sessionCost)} per session</div>
        )}
      </CardBody>
    </Card>
  )
}

const CreditCardContainer = styled('div')`
  border-radius: var(--border-radius-sm);
  background-color: ${greySet.tint[20]};
  padding: var(--margin-1);
  color: ${greySet[70].hex};
`

function CardIsOnFileDisplay({ isOnFile }: { isOnFile: boolean }) {
  return (
    <CoverageList style={{ marginTop: 0, marginBottom: 0 }}>
      <CoverageListItem
        style={{ marginBottom: 0 }}
        disabled={!isOnFile}
        adornment={
          <CoverageIcon disabled={!isOnFile}>
            <IconCreditCard size={20} />
          </CoverageIcon>
        }
      >
        Card is on file
      </CoverageListItem>
    </CoverageList>
  )
}
