import { AppointmentForNotificationFragment, Patient, useBasicClientQuery } from '@nuna/api'
import { useAppointmentDrawerSearchParams } from '@nuna/common'
import { appointmentService } from '@nuna/core'
import { ConversationContextProvider } from '@nuna/messaging'

import { NewAppointments } from './components/NewAppointments'
import { NewPatientList } from './components/NewPatientList'

export function ScheduleAppointmentAsProvider({
  onAppointmentScheduled,
}: {
  onAppointmentScheduled?: (appointment: AppointmentForNotificationFragment) => void
}) {
  const {
    closeDrawer,
    openScheduleAppointmentDrawer,
    drawerConfig: { scheduleProviderId, timeSlot, schedulePatientId, closeOnSave },
  } = useAppointmentDrawerSearchParams()

  const { data: patientData } = useBasicClientQuery({
    variables: {
      id: schedulePatientId || '',
    },
    skip: !schedulePatientId,
    fetchPolicy: 'cache-first',
  })

  const handleOnSelect = (patient: Pick<Patient, 'id' | 'firstName' | 'lastName' | 'avatarUrl'>) => {
    openScheduleAppointmentDrawer(scheduleProviderId || '', {
      patientId: patient.id,
      timeSlot: timeSlot ? appointmentService.timeSlotToTimeStamp(timeSlot) : undefined,
    })
  }

  const handleAfterAppointmentSave = (appointment: AppointmentForNotificationFragment) => {
    if (onAppointmentScheduled) onAppointmentScheduled(appointment)
    if (closeOnSave) {
      closeDrawer()
    } else {
      addAppointmentIdToQueryParams(appointment)
    }
  }

  if (!schedulePatientId || !patientData?.patient) {
    return <NewPatientList onSelect={handleOnSelect} providerId={scheduleProviderId || ''} />
  }

  return (
    <ConversationContextProvider>
      <NewAppointments patient={patientData.patient} afterAppointmentSave={handleAfterAppointmentSave} />
    </ConversationContextProvider>
  )
}

const addAppointmentIdToQueryParams = (appointment: AppointmentForNotificationFragment) => {
  const queryParams = new URLSearchParams(window.location.search)
  if (appointment.id) queryParams.set('appointmentSet', appointment.id)
  window.history.replaceState({}, '', `${window.location.pathname}?${queryParams}`)
}
