import { styled } from '@mui/material'
import { uniqueId } from 'lodash'
import React, { useRef } from 'react'

import { greySet, tealSet } from '../../styles/colorSets'
import { error as errorColor } from '../../styles/colors'
import { csx } from '../../utils/csx'

export interface RadioProps extends React.HTMLProps<HTMLInputElement> {
  checked: boolean
  children?: React.ReactNode
  onChange: React.ChangeEventHandler<HTMLInputElement>
  bgColor?: string
  containerProps?: React.HTMLAttributes<HTMLDivElement>
  error?: boolean
  id?: string
  inline?: boolean
  dataTestId?: string
  labelAlign?: string
  hasLabel?: boolean
}

export function Radio({
  checked,
  children,
  onChange,
  bgColor = '#fff',
  className = '',
  containerProps = {},
  error = false,
  id = undefined,
  inline = false,
  dataTestId = '',
  style = {},
  labelAlign = 'center',
  hasLabel = true,
  ...props
}: RadioProps) {
  const idRef = useRef(id ?? uniqueId('radio-'))

  return (
    <Container className={csx([className, 'radio-container'])} style={style} inline={inline} {...containerProps}>
      <input
        id={idRef.current}
        type="radio"
        checked={checked}
        onChange={onChange}
        className="fs-exception"
        {...props}
      />

      <Label
        error={error}
        labelAlign={labelAlign}
        htmlFor={idRef.current}
        data-testid={dataTestId}
        disabled={props.disabled}
      >
        <RadioCircle className="radio-circle" bgColor={bgColor} error={error} hasLabel={hasLabel} />

        {hasLabel && children}
      </Label>
    </Container>
  )
}

const Container = styled('div')<{ inline?: boolean }>`
  display: ${props => (props.inline ? 'inline-block' : 'block')};
  position: relative;
  ${props =>
    props.inline &&
    `
    margin-right: 2.5rem;
  `}

  input[type="radio"] {
    /* For more reading on why it's hidden this way: https://blog.bitsrc.io/customise-radio-buttons-without-compromising-accessibility-b03061b5ba93 */
    opacity: 0;
    position: absolute;
    pointer-events: none;
  }

  [role='group'] & {
    margin-bottom: 1rem;
  }
`

const RadioCircle = styled('span')<{ bgColor: string; error: boolean; hasLabel?: boolean }>`
  border-radius: 50%;
  border: 3px solid ${props => props.bgColor};
  display: inline-block;
  flex: 0 0 auto;
  height: 19px;
  ${props =>
    props.hasLabel &&
    `
    margin-right: 0.625rem;
  `}

  position: relative;
  width: 19px;

  &::after {
    border-radius: 50%;
    box-shadow: 0 0 0 2px ${greySet[50].hex};
    content: '';
    display: block;
    height: calc(100% + 6px);
    left: -3px;
    position: absolute;
    top: -3px;
    width: calc(100% + 6px);

    ${props =>
      props.error &&
      `
    box-shadow: 0 0 0 2px ${errorColor};
  `}
  }
`

const Label = styled('label')<{ error: boolean; labelAlign: string; disabled?: boolean }>`
  align-items: ${props => props.labelAlign};
  cursor: pointer;
  display: flex;

  ${props =>
    props.disabled &&
    `
    color: ${greySet.tint[60]};
    cursor: default;
  
  `}

  // Hover
  input:not(:disabled) + &:hover > ${RadioCircle} {
    &::after {
      box-shadow: 0 0 0 2px ${props => (props.error ? errorColor : greySet[70].hex)};
      width: calc(100% + 8px);
      height: calc(100% + 8px);
      top: -4px;
      left: -4px;
    }
  }

  // Checked
  input:checked + & > ${RadioCircle} {
    background-color: ${tealSet[50].hex};

    &::after {
      box-shadow: 0 0 0 2px ${tealSet[50].hex};
    }
  }

  // Checked + Hover
  input:checked + &:hover > ${RadioCircle} {
    background-color: ${tealSet[50].hover};

    &::after {
      box-shadow: 0 0 0 2px ${tealSet[50].hover};
    }
  }

  // Focus
  input:focus + & > ${RadioCircle} {
    &::before {
      border-radius: 50%;
      content: '';
      position: absolute;
      width: calc(100% + 10px);
      height: calc(100% + 10px);
      top: -5px;
      left: -5px;
      box-shadow: 0 0 0 6px ${greySet.tint[40]};
    }
  }

  // Focus + Checked
  input:focus:checked + & > ${RadioCircle} {
    &::before {
      box-shadow: 0 0 0 6px ${tealSet.tint[40]};
    }
  }

  // Disabled + Not Checked
  input:disabled:not(:checked) + & > ${RadioCircle} {
    &::after {
      box-shadow: 0 0 0 2px ${greySet.tint[60]};
    }
  }

  // Disabled + Checked
  input:disabled:checked + & > ${RadioCircle} {
    background-color: ${tealSet[50].transparency(0.55)};
    &::after {
      box-shadow: 0 0 0 2px ${tealSet[50].transparency(0.55)};
    }
  }
`
