import * as Yup from 'yup'
import { styled } from '@mui/material'
import { omit, pick } from 'lodash'
import moment from 'moment'
import { HTMLAttributes, ReactNode, useState } from 'react'
import { useMediaQuery } from 'react-responsive'

import {
  AddressType,
  BasicSpecialtyFragment,
  DocumentType,
  Gender,
  Pronouns,
  ProviderIntakeFragment,
  useDocumentUpload,
  useUpdateProviderMutation,
} from '@nuna/api'
import { LazyImageUpload, ProviderSignupAddress } from '@nuna/common'
import { formService, specialtiesService, userService } from '@nuna/core'
import { LicenseTitleSelect } from '@nuna/provider'
import { supportService } from '@nuna/telemetry'
import {
  Avatar,
  AvatarUploadPlaceholder,
  BelowTablet,
  Checkbox,
  DOBTextField,
  GhostButtonProps,
  Grid,
  IconCalendar,
  Radio,
  RadioGroup,
  TextButton,
  TextButtonExternalLink,
  TextField,
  csx,
  greySet,
  phoneUSAValidationRegex,
} from '@nuna/tunic'

import { useProviderAppContext } from '../../../../shared/ProviderAppContext'
import { specialtyToSpecialtyInput } from '../../ProviderSignupIntakeContext'
import { IntakeStepContainer } from '../../shared/IntakeStepContainer'
import { IntakeHeader } from '../../shared/IntakeTypography'
import { ProviderIntakeFormStepper } from '../../shared/ProviderIntakeFormStepper'
import { SpecialtiesChipsSelect } from '../../shared/SpecialtiesChipSelect'
import {
  ProviderSignupFormSchema,
  ProviderSignupFormValues,
  ProviderSignupIntakeFormProps,
  SaveProviderIntakeInput,
} from '../../shared/provider-signup-intake-types'
import { useProviderSignupIntakeStepSetup } from '../../shared/useProviderSignupIntakeStepSetup'
import { buildInitialAddress } from '../../signup-intake-utils'

const { composeHelperTextWithError } = formService
const { humanReadableGenders, humanReadablePronouns } = userService

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore - ENG-4528
const validationSchema: ProviderSignupFormSchema = Yup.object({
  avatarUrl: Yup.string().required('Your photo is required').typeError('Your photo is required'),
  dob: Yup.date()
    .required('Birthdate is required')
    .max(moment().subtract(18, 'years').toDate(), 'You must be at least 18 years old.')
    .typeError('Date is invalid'),
  firstName: Yup.string().required('First name is required'),
  lastName: Yup.string().required('Last name is required'),
  legalFirstName: Yup.string().required('Legal first name is required').typeError('Legal first name is required'),
  legalLastName: Yup.string().required('Legal last name is required').typeError('Legal first name is required'),
  licenseTitle: Yup.string().optional(),
  gender: Yup.mixed()
    .oneOf(Object.values(Gender), 'Gender is required')
    .required('Gender is required')
    .typeError('Gender is required'),
  pronouns: Yup.mixed().oneOf([...Object.values(Pronouns), null, undefined, '']),
  ethnicity: Yup.array<BasicSpecialtyFragment>(Yup.object()),
  address: Yup.object<ProviderSignupAddress>({
    addressLineOne: Yup.string().required('Street Address is required'),
    addressLineTwo: Yup.string().nullable(),
    zipCode: Yup.string().required('Zip Code is required'),
    state: Yup.string().required('State is required'),
    city: Yup.string().required('City is required'),
    name: Yup.string(),
    addressType: Yup.mixed<AddressType>().oneOf(Object.values(AddressType)),
    // @ts-expect-error ENG-4528
    id: Yup.string(),
  }),
  mobilePhone: Yup.string()
    .required('Phone Number required')
    .typeError('Phone Number required')
    .matches(phoneUSAValidationRegex, 'Please enter a valid US phone number'),
})

const GENDER_OPTIONS: Gender[][] = [
  [Gender.Male, Gender.Female, Gender.QuestioningGender],
  [Gender.TransMale, Gender.TransFemale, Gender.GenderNonConforming],
]

const PRONOUN_OPTIONS: Pronouns[][] = [
  [Pronouns.HimHis, Pronouns.HimThey, Pronouns.TheyThem],
  [Pronouns.HerHers, Pronouns.HerThey],
]

export function IntakeProfile(formProps: ProviderSignupIntakeFormProps) {
  const {
    values,
    touched,
    handleChange,
    getFieldProps,
    setFieldValue,
    errors,
    saveIntakeLoading,
    validateForm,
    isValid,
  } = formProps
  const isMobile = useMediaQuery({ query: `(${BelowTablet})` })
  const { provider } = useProviderAppContext()
  const { id: providerId, avatarUrl } = provider ?? {}
  const [isReplacingPhoto, setIsReplacingPhoto] = useState(false)
  const [updateProvider] = useUpdateProviderMutation()
  const { uploadDocument } = useDocumentUpload()
  const [showLicenseTitleField, setShowLicenseTitleField] = useState(!!values.licenseTitle)

  const afterSave = (data: ProviderIntakeFragment) => {
    setFieldValue('address', buildInitialAddress(data?.address))
  }

  useProviderSignupIntakeStepSetup({ validationSchema, buildSaveValues, afterSave }, validateForm)

  const handleShowLicenseTitleFieldChange = () => {
    const newVal = !showLicenseTitleField

    setShowLicenseTitleField(newVal)

    if (!newVal) {
      setFieldValue('licenseTitle', '')
    }
  }

  const renderGridTextField = (name: keyof typeof values, label: string, dataTestId?: string) => (
    <Grid
      size={{
        xs: 12,
        md: 6,
      }}
    >
      <TextField
        label={label}
        data-testid={dataTestId}
        {...getFieldProps(name)}
        {...composeHelperTextWithError('', errors[name], !!touched[name])}
      ></TextField>
    </Grid>
  )

  return (
    <IntakeStepContainer>
      <div className="flex-column gap-5">
        <section>
          <IntakeHeader type="h1" md={{ mb: 6 }}>
            Now for some info about you...
          </IntakeHeader>
          <IntakeHeader type="h2" xs={{ mb: 4 }} className={csx({ 'text-center': isMobile })}>
            Photo & Display Name
          </IntakeHeader>
          {!isReplacingPhoto && (
            <Grid container alignItems="center">
              <Grid
                size={{
                  xs: 12,
                  md: 'auto',
                }}
                className={csx({ 'v-align': isMobile })}
              >
                {/* Cache and state bust previous avatarUrl by appending timestamp */}
                {avatarUrl && <AvatarResponsive url={`${avatarUrl}?t=${Date.now()}`} size="lg" />}
                {!avatarUrl && (
                  <AvatarUploadPlaceholder
                    error={!!errors.avatarUrl && touched.avatarUrl}
                    htmlFor="avatar-file-upload"
                  />
                )}
              </Grid>
              <Grid
                size={{
                  xs: 12,
                  md: 'auto',
                }}
                className={csx({ 'text-center': isMobile })}
              >
                <h1 className={csx({ h4: !isMobile, h5: isMobile })}>
                  {values.firstName || values.legalFirstName} {values.lastName || values.legalLastName}
                  {values.licenseTitle && <>, {values.licenseTitle}</>}
                </h1>
                <TextButtonLabel htmlFor="avatar-file-upload">
                  {avatarUrl ? 'Replace photo' : 'Upload a photo'}
                </TextButtonLabel>
              </Grid>
            </Grid>
          )}
          <div style={{ display: isReplacingPhoto ? 'block' : 'none' }}>
            <LazyImageUpload
              inputProps={{ 'data-testid': 'avatar-upload' }}
              inputId="avatar-file-upload"
              onFileSelect={() => setIsReplacingPhoto(true)}
              onUploadSuccess={() => setIsReplacingPhoto(false)}
              onCancel={() => setIsReplacingPhoto(false)}
              onError={() => setIsReplacingPhoto(false)}
              onCropped={async (file: File) => {
                const document = await uploadDocument(file, DocumentType.ProviderImage)
                if (!document) {
                  return
                }

                updateProvider({ variables: { id: providerId ?? '', avatarDocumentId: document.id } }).then(
                  response => {
                    setFieldValue('avatarUrl', response?.data?.updateProvider.avatarUrl)
                    return response
                  },
                )
              }}
              isAvatarUploading={false}
            />
          </div>
          {errors.avatarUrl && touched.avatarUrl && <p className="text-error caption">{errors.avatarUrl}</p>}

          <Grid container spacing={4}>
            {renderGridTextField('firstName', 'First name')}
            {renderGridTextField('lastName', 'Last name')}
          </Grid>

          <Checkbox className="mt-2" onChange={handleShowLicenseTitleFieldChange} checked={showLicenseTitleField}>
            Display my license title to clients (e.g. LMFT)
          </Checkbox>
          {showLicenseTitleField && (
            <div className={csx({ 'mt-2': !isMobile, 'mt-1': isMobile })}>
              <LicenseTitleSelect {...getFieldProps('licenseTitle')} />
            </div>
          )}
          <div className="mt-3">
            Need help with your photo and display name?{' '}
            <TextButtonExternalLink href={supportService.articles.photoTips}>
              Read our tips here.
            </TextButtonExternalLink>
          </div>
        </section>

        <section>
          <IntakeHeader type="h2" md={{ mb: 0 }}>
            Legal Name
          </IntakeHeader>
          <p className="mt-1 text-light" style={{ color: greySet[70].hex }}>
            Please enter your name as it appears on your driver's license. This is used for payments and license
            verification.
          </p>
          <Grid container spacing={4}>
            {renderGridTextField('legalFirstName', 'Legal first name', 'legal-first-name')}
            {renderGridTextField('legalLastName', 'Legal last name', 'legal-last-name')}
          </Grid>
        </section>

        <section>
          <IntakeHeader type="h2" xs={{ mt: 3 }}>
            Your Gender
          </IntakeHeader>
          <ColumnedRadioGroup {...composeHelperTextWithError('', errors.gender, !!touched.gender)}>
            <Grid container spacing={isMobile ? 0 : 4}>
              {GENDER_OPTIONS.map((genderOptions, idx) => (
                <Grid key={idx} className="column">
                  {genderOptions.map(genderOption => (
                    <Radio
                      data-testid={`gender-option-${genderOption}`}
                      name="gender"
                      onChange={handleChange}
                      value={genderOption}
                      checked={values.gender === genderOption}
                      key={genderOption}
                    >
                      {humanReadableGenders[genderOption]}
                    </Radio>
                  ))}
                </Grid>
              ))}
            </Grid>
          </ColumnedRadioGroup>
          <div className="text-secondary mt-3 mb-3">
            <span className="text-medium">And please select your pronouns below</span>
            <span className="ml-1">(optional)</span>
          </div>
          <ColumnedRadioGroup>
            <Grid container spacing={isMobile ? 0 : 4}>
              {PRONOUN_OPTIONS.map((pronounOptions, idx) => (
                <Grid
                  key={idx}
                  size={{
                    xs: 12,
                    md: 'auto',
                  }}
                  className="column"
                >
                  {pronounOptions.map(pronounOption => (
                    <Radio
                      name="pronouns"
                      onChange={handleChange}
                      value={pronounOption}
                      checked={values.pronouns === pronounOption}
                      key={pronounOption}
                    >
                      {humanReadablePronouns[pronounOption]}
                    </Radio>
                  ))}
                </Grid>
              ))}
            </Grid>
          </ColumnedRadioGroup>
        </section>

        <section>
          <IntakeHeader type="h2" md={{ mb: 1 }} xs={{ mt: 3 }}>
            Your Ethnicity
          </IntakeHeader>
          <div className="text-secondary mb-3">
            <span className="text-medium">Please select any that apply</span>
            <span className="ml-1">(optional)</span>
          </div>
          <SpecialtiesChipsSelect
            dataTestId="ethnicity-specialties"
            specialtyFilter={specialtiesService.getEthnicitySpecialties}
            value={values.ethnicity}
            onChange={v => setFieldValue('ethnicity', v)}
          />
        </section>

        <section>
          <IntakeHeader type="h2" md={{ mb: 2 }} xs={{ mt: 4 }}>
            Birthdate
          </IntakeHeader>
          <FormFieldWithIcon icon={<IconCalendar />} iconTopPadding={4}>
            <DOBTextField
              label=""
              placeholder="MM/DD/YYYY"
              fullWidth
              {...getFieldProps('dob')}
              value={
                moment(getFieldProps('dob').value, 'YYYY-MM-DD', true).isValid()
                  ? moment(getFieldProps('dob').value).format('MM/DD/YYYY')
                  : getFieldProps('dob').value || ''
              }
              {...composeHelperTextWithError('', errors.dob, !!touched.dob)}
              slotProps={{ htmlInput: { 'data-testid': 'dob-input' } }}
            />
          </FormFieldWithIcon>
        </section>

        <ProviderIntakeFormStepper
          currentSection="profile"
          mutationLoading={saveIntakeLoading}
          step="profile"
          isValid={isValid}
        />
      </div>
    </IntakeStepContainer>
  )
}

function buildSaveValues(formValues: ProviderSignupFormValues): SaveProviderIntakeInput {
  const { ethnicity = [], dob, address, ...rest } = omit(formValues, ['licenses', 'expectedAvailability'])

  const updatedAddress = buildAddress(address)

  return {
    ethnicity: ethnicity?.map(specialtyToSpecialtyInput),
    dob: dob ? moment.utc(dob).startOf('day').toISOString() : '',
    address: updatedAddress,
    ...pick(rest, Object.keys(validationSchema.fields)),
  }
}

function buildAddress(address: ProviderSignupAddress): ProviderSignupAddress | undefined {
  const cleaned = omit(address, ['zipCodeValid'])

  if (Object.values(omit(cleaned, ['addressLineTwo', 'addressId'])).some(v => !v)) {
    return undefined
  }

  return { ...omit(address, ['zipCodeValid']), addressId: address.addressId || undefined }
}

function TextButtonLabel({ children, htmlFor, className }: GhostButtonProps & { htmlFor?: string }) {
  return (
    <TextButton as="label" htmlFor={htmlFor} className={className}>
      <span style={{ position: 'relative', zIndex: 1 }}>{children}</span>
    </TextButton>
  )
}

const responsiveAvatar = `
  height: auto;
  margin: auto;
  max-width: 300px;
  width: 100%;
`

const AvatarResponsive = styled(Avatar)`
  ${responsiveAvatar}
`

const ColumnedRadioGroup = styled(RadioGroup)`
  .column {
    width: 244px;
  }
  @media (${BelowTablet}) {
    .column {
      width: auto;
    }
  }
`

interface FormFieldWithIconProps extends HTMLAttributes<HTMLDivElement> {
  icon?: ReactNode
  iconTopPadding?: number
}
function FormFieldWithIcon({ icon, children, iconTopPadding = 20, ...props }: FormFieldWithIconProps) {
  return (
    <FormFieldWithIconStyled container {...props}>
      <Grid className="icon-container text-secondary" style={{ paddingTop: iconTopPadding }}>
        {icon}
      </Grid>
      <Grid
        size={{
          xs: 'grow',
          md: 6,
        }}
      >
        {children}
      </Grid>
    </FormFieldWithIconStyled>
  )
}

const FormFieldWithIconStyled = styled(Grid)`
  .icon-container {
    width: 30px;
    display: flex;
    align-items: flex-start;
  }
`
