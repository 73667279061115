import { styled } from '@mui/material'
import { CSSProperties, ReactNode } from 'react'

import { BasicAppointmentFragment, BasicProviderFragment } from '@nuna/api'
import { appointmentService } from '@nuna/core'
import { Avatar, IconCheckCircle, IconError, StatusLabel, greySet, shadowDepth } from '@nuna/tunic'

interface CompactAppointment extends Pick<BasicAppointmentFragment, 'startDatetime' | 'endDatetime'> {
  provider: Pick<BasicProviderFragment, 'firstName' | 'lastName' | 'avatarUrl'>
}

interface CompactAppointmentCardProps {
  appointment: CompactAppointment
  className?: string
  style?: CSSProperties
  outOfNetwork?: boolean
  inNetwork?: boolean
  renderAction?: ReactNode
}

/** @deprecated try not to use this if possible. Exists as a band-aid for a circular dependency issue */
export function CompactAppointmentCard({
  appointment,
  outOfNetwork,
  inNetwork,
  className,
  renderAction = null,
  ...props
}: CompactAppointmentCardProps) {
  const provider = appointment.provider

  return (
    <Card className={`${className} v-align`} {...props}>
      <div>
        <h3 className="body text-medium">{appointmentService.appointmentTimeSpan(appointment)}</h3>
        <div className="v-align">
          <MiniAvatar
            size={'mini'}
            url={provider.avatarUrl}
            style={{ display: 'inline-block', verticalAlign: 'middle' }}
          />{' '}
          {provider.firstName} {provider.lastName}{' '}
          {outOfNetwork && (
            <StatusLabel className="ml-1 v-align" intent="urgent">
              <IconError className="mr-xs" style={{ marginBottom: 1 }} size={12} /> Out of network
            </StatusLabel>
          )}
          {inNetwork && (
            <StatusLabel className="ml-1 v-align" intent="information">
              <IconCheckCircle className="mr-xs" style={{ marginBottom: 1 }} size={12} /> In network
            </StatusLabel>
          )}
        </div>
      </div>
      {renderAction && <div className="ml-auto">{renderAction}</div>}
    </Card>
  )
}

/** @deprecated try not to use this if possible. Exists as a band-aid for a circular dependency issue */
export function CompactAppointmentCardLoader() {
  return <Card style={{ height: 86 }} className="loading" />
}

const MiniAvatar = styled(Avatar)`
  display: inline-block;
  margin: 0 var(--margin-1) 0 0.125rem;
  vertical-align: middle;
`

const Card = styled('div')<{ inactive?: boolean }>`
  background-color: ${props => (props.inactive ? greySet[15].hex : '#fff')};
  border: 1px solid ${props => (props.inactive ? greySet[30].hex : greySet[15].hex)};
  border-radius: var(--border-radius);
  box-shadow: ${shadowDepth(1)};
  padding: 1rem;

  ${props => props.inactive && `color: ${greySet[80].hex};`};
`
