import { styled } from '@mui/material'
import { useFormikContext } from 'formik'

import { AppointmentChangeReasonRadioGroup } from '@nuna/appointment'
import { formService } from '@nuna/core'
import { body2 } from '@nuna/tunic'

import { SessionForm } from '../../../types'

const { composeHelperTextWithError } = formService

interface Props {
  showCancelReasons: boolean
}

export function SessionNoteCancelForm({ showCancelReasons }: Props) {
  const { values, handleChange, errors, touched } = useFormikContext<SessionForm>()

  if (!showCancelReasons) {
    return null
  }

  return (
    <>
      <h3 className="h6 sans-serif mb-2 mt-3">Why was it canceled?</h3>
      <StyledRadioGroup
        checked={value => value === values.cancelReason}
        name="cancelReason"
        onChange={handleChange}
        aria-labelledby="reason-heading"
        {...composeHelperTextWithError('', errors.cancelReason, touched.cancelReason)}
      ></StyledRadioGroup>
    </>
  )
}

const StyledRadioGroup = styled(AppointmentChangeReasonRadioGroup)`
  && {
    & > div {
      color: ${body2};
      margin-bottom: 0;
      padding: 0.5625rem 0;
      margin-left: 3px;
    }
  }
`
