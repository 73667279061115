import { styled } from '@mui/material'
import { Autocomplete, TextField } from '@mui/material'
import { debounce, isArray } from 'lodash'
import { HTMLAttributes, ReactNode, useEffect, useMemo, useState } from 'react'

import { BasicProviderFragment, ProviderNetworkStatus, useSearchProvidersLazyQuery } from '@nuna/api'
import { Avatar, UserChip, makeTypographyComponent } from '@nuna/tunic'

export interface SelectProvider extends BasicProviderFragment {
  npi?: string | null
}

type ProviderSelectProps = {
  label?: string
  showNpi?: boolean
  error?: boolean
  helperText?: string | ReactNode
} & Pick<HTMLAttributes<HTMLDivElement>, 'className' | 'style'> &
  (
    | {
        multiple: false
        selectedProviders?: SelectProvider | null
        onChange: (providers: SelectProvider | null) => void
      }
    | {
        multiple?: true
        selectedProviders?: SelectProvider[] | null
        onChange: (providers: SelectProvider[] | null) => void
      }
  )

export function ProviderSelect({
  label,
  showNpi = false,
  error = false,
  helperText = '',
  ...props
}: ProviderSelectProps) {
  const [searchInput, setSearchInput] = useState('')
  const [debounceLoading, setDebounceLoading] = useState(false)

  const [searchProviders, { data, loading }] = useSearchProvidersLazyQuery({
    onCompleted: () => setDebounceLoading(false),
  })

  const debouncedSearchProviders = useMemo(
    () =>
      debounce(
        (term: string) =>
          searchProviders({
            variables: {
              pagination: { limit: 25 },
              filters: {
                active: true,
                networkStatusIn: [
                  ProviderNetworkStatus.InProgress,
                  ProviderNetworkStatus.UnderReview,
                  ProviderNetworkStatus.Approved,
                ],
                search: { term },
              },
            },
          }),
        400,
      ),
    [searchProviders],
  )

  useEffect(() => {
    setDebounceLoading(false)
  }, [data])

  useEffect(() => {
    if (searchInput) {
      setDebounceLoading(true)
      debouncedSearchProviders(searchInput)
    }
  }, [searchInput, debouncedSearchProviders])

  function renderProviderTag(provider: SelectProvider) {
    return (
      <UserChip
        className="v-align mr-1 mb-1"
        key={provider.id}
        avatarUrl={provider.avatarUrl}
        onRemoveClick={() => {
          if (props.multiple !== false) {
            const selectedProviders = props.selectedProviders ?? []
            props.onChange(selectedProviders.filter(p => p.id !== provider.id))
          }
        }}
      >
        {provider.firstName} {provider.lastName}{' '}
        {showNpi && provider.npi && (
          <span className="ml-xs">
            {' - '}
            {provider.npi}
          </span>
        )}
      </UserChip>
    )
  }

  function getOptionLabel(provider: SelectProvider) {
    return `${provider.firstName} ${provider.lastName}`
  }

  function handleChange(_e: unknown, providers: SelectProvider | SelectProvider[] | null) {
    if (props.multiple === false && !isArray(providers)) {
      props.onChange(providers)
    } else if ((props.multiple === undefined || props.multiple === true) && isArray(providers)) {
      props.onChange(providers)
    } else {
      console.warn('ProviderSelect: Invalid onChange event')
    }

    // When in single mode, the autocomplete expects the value in the search input to
    // match the label of the selected option or it does not display the selected value at all.
    // Therefore, we are compromising and replacing the search value once the user selects a provider.
    if (props.multiple === false) {
      setSearchInput(providers ? getOptionLabel(providers as SelectProvider) : '')
    }
  }

  const providers: SelectProvider[] = data?.searchProviders.items ?? []

  return (
    <Autocomplete
      multiple={props.multiple !== false} // we want undefined to default to true
      disableClearable={props.multiple !== false}
      options={providers}
      value={props.selectedProviders}
      isOptionEqualToValue={(option, value) => option.id === value.id}
      loading={loading || debounceLoading}
      onChange={handleChange}
      renderInput={params => (
        <TextField
          {...params}
          label={label}
          onChange={e => setSearchInput(e.target.value)}
          error={error}
          helperText={helperText}
        />
      )}
      renderOption={(renderOptionProps, provider: SelectProvider) => (
        <li {...renderOptionProps}>
          <div className="v-align">
            <Avatar className="mr-1" size="xs" url={provider.avatarUrl} />
            <Name>
              {provider.firstName} {provider.lastName}
            </Name>
            {showNpi && provider.npi && (
              <span className="ml-xs">
                {' - '}
                {provider.npi}
              </span>
            )}
          </div>
        </li>
      )}
      renderTags={providers => providers.map(renderProviderTag)}
      getOptionLabel={getOptionLabel}
    />
  )
}

const Name = styled(makeTypographyComponent('text-medium text-secondary', 'span'))``
