import { replace } from 'lodash'
import { HTMLAttributes } from 'react'

import { LicenseStatus } from '@nuna/api'
import { Intent, StatusLabel } from '@nuna/tunic'

interface Props extends HTMLAttributes<HTMLSpanElement> {
  status?: LicenseStatus | null
}

export function LicenseStatusLabel({ status, ...props }: Props) {
  if (!status) {
    return null
  }
  return (
    <StatusLabel {...props} intent={getIntent(status)}>
      {replace(status, '_', ' ')}
    </StatusLabel>
  )
}

function getIntent(status: LicenseStatus): Intent {
  switch (status) {
    case LicenseStatus.ExpiresSoon:
    case LicenseStatus.NeedsReview:
      return 'caution'
    case LicenseStatus.Valid:
      return 'information'
    case LicenseStatus.Invalid:
    case LicenseStatus.Expired:
      return 'urgent'
    case LicenseStatus.Pending:
      return 'default'
    default:
      return 'default'
  }
}
