import { type Persona } from '@nuna/core'

import { HELPFUL_BLURBS, HelpfulBlurb, mentalHealthMonthTips } from './helpfulBlurbsData'

function getRandomInt(max: number) {
  return Math.floor(Math.random() * max)
}

export function getHelpfulItem(audience: Persona): HelpfulBlurb {
  // Yearly Mental Health Awareness Month logic for May
  if (audience === 'client' && new Date().getMonth() === 4) {
    const dayOfMonthInteger = new Date().getDate() - 1
    return mentalHealthMonthTips[dayOfMonthInteger]
  } else {
    // We don't actually have any admin-specific blurbs right now, so use the provider ones
    if (audience === 'admin') audience = 'provider'
    // TODO - remove quote filter once we know how to handle other types of blurbs
    const blurbs = HELPFUL_BLURBS.filter(blurb => blurb.audience.includes(audience)).filter(
      blurb => blurb.type === 'quote',
    )
    return blurbs[getRandomInt(blurbs.length - 1)]
  }
}
