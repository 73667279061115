import '@fullcalendar/common/main.min.css'
import '@fullcalendar/daygrid/main.min.css'
import '@fullcalendar/list/main.min.css'
import '@fullcalendar/resource-timeline/main.min.css'
import '@fullcalendar/timegrid/main.min.css'
import '@fullcalendar/timeline/main.min.css'
import { css } from '@mui/material'

import { greySet, tealSet, yellowSet } from '../colorSets'
import { fontSize } from '../global/typography.utils'

export const fullCalendarStyles = css`
  .fc {
    height: 100%;
    width: 100%;
  }

  .fc .fc-toolbar.fc-header-toolbar {
    margin: 0 !important;
  }

  .fc-event {
    cursor: pointer;
  }

  .fc-right,
  .fc-center,
  .fc-left {
    h2 {
      font-weight: normal;
      font-size: 1.2rem !important;
    }
  }

  .availability-calendar {
    .fc-unthemed td.fc-today {
      background-color: white !important;
    }
  }

  .fc .fc-timegrid-col.fc-day-today {
    background-color: transparent !important;

    .fc-timegrid-col-frame {
      border-left: 2px solid var(--primary-15);
      border-right: 2px solid var(--primary-15);
    }
  }

  .fc .fc-timegrid-now-indicator-container .fc-timegrid-now-indicator-line {
    border-color: ${tealSet[70].hex};
    border-width: 1px;
  }

  body .fc .fc-scrollgrid {
    border: 0;
  }

  .fc.fc-theme-standard th {
    border-top: 0;
    border-left: 0;
    border-right: 0;

    &.fc-timegrid-axis {
      border: 0;
    }
  }

  .fc.fc-theme-standard td {
    border-color: #ebe9e6;
  }

  .fc .fc-timegrid-slot-minor {
    border-top-style: solid !important;
  }

  .fc .fc-timegrid-slot-label-cushion {
    padding-right: 10px !important;
  }

  .fc-col-header-cell {
    .fc-scrollgrid-sync-inner {
      .fc-col-header-cell-cushion {
        color: ${greySet[70].hex};
        padding-bottom: 0.75rem;
        padding-top: 0.5rem;

        &:hover {
          text-decoration: none;
        }
      }
    }
  }

  .fc-col-header-cell .fc-scrollgrid-sync-inner {
    .fc-header-day {
      color: ${greySet[50].hex};
      font-weight: 500;
    }
  }

  .fc .fc-timegrid-slot {
    height: 1.75em !important;
  }

  .fc-col-header-cell .fc-scrollgrid-sync-inner {
    border: 2px solid transparent; // prevents jumping when moving between weeks
    border-bottom: 0;
  }

  .fc-event-main-frame.canceled {
    text-decoration: line-through;
    text-decoration-color: ${yellowSet[80].hex};
    text-decoration-thickness: 2px;
  }

  .fc-timegrid-event .fc-event-time {
    font-size: ${fontSize.caption};
    font-weight: 500;
  }

  .fc-v-event .fc-event-title {
    font-size: ${fontSize.caption};
    font-weight: 700;
  }

  .fc-v-event {
    color: var(--body-color);
  }

  .fc-timegrid-event-harness-inset .fc-timegrid-event {
    padding: 4px 6px;
  }

  .fc-col-header-cell.fc-day-today .fc-scrollgrid-sync-inner {
    background-color: rgba(255, 236, 246, 0.4);
    border: 2px solid var(--primary-15);
    border-bottom: 0;
    border-radius: var(--border-radius-sm) var(--border-radius-sm) 0 0;

    .fc-header-day {
      color: var(--primary-50);
    }

    .fc-header-date {
      color: var(--primary-80);
    }
  }

  .fc-scrollgrid-section.fc-scrollgrid-section-header {
    td:last-child {
      border-right: 0;
    }
  }

  .fc-timegrid-slot.fc-timegrid-slot-label.fc-scrollgrid-shrink {
    font-size: 12px;
    color: ${greySet[50].hex};
    text-transform: uppercase;
  }

  .fc-timegrid-slot.fc-timegrid-slot-label.fc-scrollgrid-shrink,
  .fc-timegrid-slot.fc-timegrid-slot-label.fc-timegrid-slot-minor {
    border: 0;
  }

  .fc-timegrid-now-indicator-arrow {
    display: none;
  }

  .fc .fc-non-business {
    background-color: rgba(205, 185, 175, 0.1) !important; // eggshell converted to alpha + white bg
  }

  .fc .fc-bg-event {
    background: repeating-linear-gradient(
      -45deg,
      rgb(212, 211, 208, 0.3),
      rgb(212, 211, 208, 0.3) 3px,
      rgb(244, 242, 240, 0.3) 3px,
      rgb(244, 242, 240, 0.3) 8px
    ) !important;

    .fc-event-title {
      font-style: normal !important;
      font-weight: 700;
      color: rgb(96, 105, 105) !important;
      overflow: hidden;
    }
  }

  :root {
    --fc-event-border-color: ${tealSet[30].hex};
    --fc-event-bg-color: ${tealSet[15].hex};
    --fc-event-text-color: var(--body-color);
    --fc-bg-event-opacity: 1;
  }
`
