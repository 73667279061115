/* eslint-disable @typescript-eslint/no-explicit-any */
import moment from 'moment'
import { ReactNode } from 'react'

import {
  CustomerCompanyDetailsFragment,
  StagedRosterStats,
  useClearStagedCustomerEmployeeRecordsMutation,
  useMergeStagedRosterUploadMutation,
  useStagedRosterInsightsQuery,
} from '@nuna/api'
import { supportService } from '@nuna/telemetry'
import {
  Card,
  Divider,
  FillButton,
  GhostButton,
  IconDocumentDeprecated,
  SpinningLoader,
  StatusLabel,
  TextButton,
  toast,
} from '@nuna/tunic'

import { DeEligibilizationBreakdownTable } from './DeEligibilizationBreakdownTable'

function hasStagingRecords(stagedRosterInsights?: StagedRosterStats): boolean {
  if (!stagedRosterInsights) return false

  const total = stagedRosterInsights.total
  return (
    Object.values({
      newToLastCount: total.newToLastCount,
      netNewCount: total.netNewCount,
      updateCount: total.updateCount,
      droppedCount: total.droppedCount,
    }).reduce((acc, item) => acc + item, 0) > 0
  )
}

interface StagedRosterInsightProps {
  customerCompany: CustomerCompanyDetailsFragment
  children?: ReactNode
}

export function StagedRosterInsights({ customerCompany, children }: StagedRosterInsightProps) {
  const { loading, error, data } = useStagedRosterInsightsQuery({
    variables: { companyId: customerCompany.id },
    skip: !customerCompany.id,
  })

  const [clearStagedRecords, { loading: clearingStagedData }] = useClearStagedCustomerEmployeeRecordsMutation({
    refetchQueries: ['CustomerCompany', 'StagedRosterInsights'],
  })
  const [mergeStagedRosterUpload, { loading: mergingStagedData }] = useMergeStagedRosterUploadMutation({
    refetchQueries: ['CustomerCompany', 'StagedRosterInsights'],
  })

  async function clearStagedRoster() {
    try {
      const clearResult = await clearStagedRecords({
        variables: {
          companyId: customerCompany.id,
        },
      })
      if (clearResult?.errors && clearResult?.errors?.length > 0) {
        throw clearResult.errors[0]
      }
    } catch (e) {
      toast.urgent('Failed to clear staged roster. Please try again or contact support.')
      return e as Error
    }
  }

  async function submitStagedRoster() {
    try {
      const uploadResult = await mergeStagedRosterUpload({
        variables: {
          companyId: customerCompany.id,
        },
      })
      if (uploadResult && uploadResult.errors && uploadResult.errors?.length > 0) {
        throw uploadResult.errors[0]
      }
      return
    } catch (e) {
      toast.urgent('Failed to merged staged roster. Please try again or contact support.')
      return e as Error
    }
  }

  const stagedRosterInsights = data?.stagedRosterInsights

  if (loading) {
    return <SpinningLoader $inFlow size={24} />
  }

  if (error || !hasStagingRecords(stagedRosterInsights)) {
    return children ? <div>{children}</div> : null
  }

  const lastSyncedDate = customerCompany.lastSyncedDate

  return (
    <>
      <h2 className="h5">Staged Roster Insights</h2>
      <Card>
        <p className="text-bold m-2 flex flex-row v-align">
          <IconDocumentDeprecated className="mr-1" />{' '}
          {moment(stagedRosterInsights?.rosterUploadedDate).format('MM/DD/YYYY')} Roster
        </p>
        <Divider className="full-width" />
        <div className="flex-column m-2" style={{ gap: '1rem' }}>
          <div>
            <div>
              <h3 className="h6 mb-0">Preview of Changes</h3>
              <p className="body caption text-medium">comparison of uploaded changes against existing records</p>
            </div>

            <div className="flex flex-row v-align" style={{ gap: 'var(--spacing-1)' }}>
              <StatusLabel className="uppercase">
                {stagedRosterInsights?.total.newToLastCount} New To Last Roster
              </StatusLabel>
              <StatusLabel className="uppercase">{stagedRosterInsights?.total.updateCount} Updated</StatusLabel>
              <StatusLabel className="uppercase">{stagedRosterInsights?.total.netNewCount} Net New</StatusLabel>
              <StatusLabel className="uppercase">
                {stagedRosterInsights?.total.droppedCount} Removed From Last Roster
              </StatusLabel>
            </div>
          </div>

          <div>
            <div>
              <h3 className="h6 mb-0 mt-2">Causes for Eligibility Changes</h3>
              <p className="body caption text-medium">more than one reason may apply to a single record</p>
            </div>
            {stagedRosterInsights?.deEligibilizationBreakdown && (
              <DeEligibilizationBreakdownTable breakdown={stagedRosterInsights?.deEligibilizationBreakdown} />
            )}
          </div>

          <div className="flex flex-row v-align flex-end">
            <GhostButton
              variant="secondary"
              className="mr-3"
              onClick={clearStagedRoster}
              isLoading={clearingStagedData}
            >
              Clear
            </GhostButton>

            <FillButton className="m-0" onClick={submitStagedRoster} isLoading={mergingStagedData}>
              Submit
            </FillButton>
          </div>
        </div>
      </Card>

      {lastSyncedDate && (
        <p className="caption mt-1">
          Last sync: <strong>{moment(lastSyncedDate).format('M/D/YYYY [at] h:mma')}</strong>
        </p>
      )}

      <p className="mt-2 mb-4 caption">
        Questions? <TextButton onClick={supportService.openChat}>Contact Support</TextButton>
      </p>
    </>
  )
}
