import * as Yup from 'yup'
import { Form, Formik } from 'formik'
import { useMediaQuery } from 'react-responsive'

import { useProviderMeQuery, useSaveProviderIntakeMutation } from '@nuna/api'
import { errorService, formService } from '@nuna/core'
import {
  BelowTablet,
  Card,
  CardBody,
  Checkbox,
  FillButtonWithChevron,
  IntakeFormStepper,
  Stack,
  TextField,
  csx,
  toast,
} from '@nuna/tunic'

import { BaseSignupFormProps } from './shared'

const OTHER_OPTION = 'Other'
const REASON_OPTIONS = [
  'Credentialing',
  'Billing',
  'Practice website',
  'Marketing tools',
  'Free EHR',
  'Automated tools',
  'Client referrals',
  OTHER_OPTION,
]

interface SignupReasonsValues {
  reasons: string[]
  otherExplanation?: string
}

const validationSchema = Yup.object<SignupReasonsValues>({
  reasons: Yup.array().of(Yup.string()),
  otherExplanation: Yup.string().when('reasons', {
    is: reasons => reasons.includes(OTHER_OPTION),
    then: Yup.string().required('Please provide an explanation').typeError('Please provide an explanation'),
  }),
})

export function SignupReasons({ onStepSubmit, step, formValues }: BaseSignupFormProps) {
  const { data, refetch } = useProviderMeQuery()
  const [saveIntakeMutation, { loading }] = useSaveProviderIntakeMutation()
  const isMobile = useMediaQuery({ query: `(${BelowTablet})` })

  const { id: providerId, joinedReasons, joinedOtherExplanation } = data?.providerMe ?? {}

  if (!providerId) return null

  const handleSubmit = async (values: SignupReasonsValues) => {
    try {
      await saveIntakeMutation({
        variables: {
          id: providerId,
          joinedReasons: values.reasons,
          joinedOtherExplanation: values.otherExplanation || null,
        },
      })
      // Refetch to update Apollo cache with saved reasons
      await refetch()
      onStepSubmit(formValues, step, true)
    } catch (error) {
      toast.urgent(errorService.transformGraphQlError(error, 'Failed to save why you are interested in Tava'))
    }
  }

  return (
    <section>
      <div className="bottom-align">
        <h2 className="h2">What brings you to Tava?</h2>
      </div>

      <p className={csx(['text-medium-gray', isMobile ? 'body' : 'large'])}>Select any that apply:</p>
      <Formik
        initialValues={{
          reasons: joinedReasons ?? [],
          otherExplanation: joinedOtherExplanation ?? '',
        }}
        onSubmit={handleSubmit}
        validationSchema={validationSchema}
        enableReinitialize
      >
        {({ errors, touched, values, setFieldValue, getFieldProps }) => {
          const handleClick = (option: string) => {
            if (values.reasons.includes(option)) {
              setFieldValue(
                'reasons',
                values.reasons.filter(reason => reason !== option),
              )
              if (option === OTHER_OPTION) {
                setFieldValue('otherExplanation', '')
              }
            } else {
              setFieldValue('reasons', [...values.reasons, option])
            }
          }

          return (
            <Form>
              <Stack spacing={4}>
                <Card sx={{ p: 1 }}>
                  <CardBody>
                    <Stack spacing={1} data-testid="why-tava-options">
                      <Stack spacing={{ xs: 3, sm: 4 }}>
                        {REASON_OPTIONS.map(option => (
                          <Checkbox
                            key={option}
                            checked={values.reasons.includes(option)}
                            onChange={() => handleClick(option)}
                            className={csx([
                              'text-dark-teal',
                              values.reasons.includes(option) ? 'text-normal' : 'text-light',
                              isMobile ? 'body' : 'large',
                            ])}
                            value={option}
                          >
                            {option}
                          </Checkbox>
                        ))}
                      </Stack>
                      {values.reasons.includes(OTHER_OPTION) && (
                        <TextField
                          {...getFieldProps('otherExplanation')}
                          placeholder="Tell us why you are joining"
                          {...formService.composeHelperTextWithError(
                            '',
                            errors.otherExplanation,
                            touched.otherExplanation,
                          )}
                          data-testid="why-tava-other-explanation"
                        />
                      )}
                    </Stack>
                  </CardBody>
                </Card>
                <IntakeFormStepper
                  previousPath="/"
                  previousButton={null}
                  mutationLoading={loading}
                  nextButton={() => (
                    <FillButtonWithChevron type="submit" isLoading={loading} data-testid="intake-continue-button">
                      Continue
                    </FillButtonWithChevron>
                  )}
                />
              </Stack>
            </Form>
          )
        }}
      </Formik>
    </section>
  )
}
