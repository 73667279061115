import { LoginScreen } from '@nuna/auth'
import { useTitle } from '@nuna/common'

import { updateAbility } from '../../util/CustomCan'

export function Login() {
  useTitle('Login')

  return <LoginScreen audience="provider" onLoginSuccess={updateAbility} showSignupLink={true} />
}
