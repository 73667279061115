import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext'
import { $isRootTextContentEmpty } from '@lexical/text'
import { LexicalEditor } from 'lexical'
import { every, noop } from 'lodash'
import { Dispatch, ReactNode, SetStateAction, createContext, useContext, useState } from 'react'

import {
  ConversationMessageStatus,
  SaveConversationMessageMutation,
  useSaveConversationMessageMutation,
  useSaveConversationMutation,
} from '@nuna/api'
import { AssessmentNode, AssessmentPlugin, useMessageAssessments } from '@nuna/assessments'
import { RichTextEditorContext, RichTextEditorContextProps, rteUtils } from '@nuna/tunic'

import { PendingAttachment } from '../components/MessageComposer/components/MessageAttachment'

interface MessageComposerContextValues {
  getConversationId: () => Promise<string>
  getDraft: () => Promise<SaveConversationMessageMutation['saveConversationMessage']>
  sendMessage: (
    participantLoginId: string,
    options?: { status?: ConversationMessageStatus },
  ) => Promise<SaveConversationMessageMutation['saveConversationMessage']>
  editor: LexicalEditor
  pendingAttachments: PendingAttachment[]
  setPendingAttachments: Dispatch<SetStateAction<PendingAttachment[]>>
  isContentEmpty: boolean
  loading: boolean
  invalidAttachments: boolean
  reset: () => void
}

const MessageComposerContext = createContext<MessageComposerContextValues | null>(null)

type MessageComposerContextProviderProps = {
  children: ReactNode
  richTextEditorProps?: Partial<Omit<RichTextEditorContextProps, 'children'>>
} & ({ conversationId: string } | { participantLoginIds: string[] })

export function MessageComposerContextProvider({
  richTextEditorProps = {},
  ...props
}: MessageComposerContextProviderProps) {
  const [isContentEmpty, setIsContentEmpty] = useState(true)
  const { onChange = noop, ...rteRest } = richTextEditorProps

  const handleEditorChange = (editor: LexicalEditor) => {
    const isEmpty = $isRootTextContentEmpty(editor.isComposing(), false)
    setIsContentEmpty(isEmpty)
    onChange(editor)
  }

  return (
    <RichTextEditorContext onChange={handleEditorChange} {...rteRest} nodes={[AssessmentNode]}>
      <AssessmentPlugin />
      <MessageComposerContextProviderWithEditor isContentEmpty={isContentEmpty} {...props} />
    </RichTextEditorContext>
  )
}

function MessageComposerContextProviderWithEditor({
  isContentEmpty,
  ...props
}: MessageComposerContextProviderProps & { isContentEmpty: boolean }) {
  const [editor] = useLexicalComposerContext()
  const [pendingAttachments, setPendingAttachments] = useState<PendingAttachment[]>([])

  const [getOrCreateConversation, { data: conversationData, loading: conversationLoading }] =
    useSaveConversationMutation()
  const [saveConversationMessage, { data: messageData, loading: messageLoading, reset: resetMessageMutation }] =
    useSaveConversationMessageMutation()
  const { saveMessageAssessments, loading: assessmentsLoading } = useMessageAssessments()

  // hands back conversationId or gets it from the server depending on participantLoginIds
  const getConversationId = async () => {
    if ('conversationId' in props) return props.conversationId
    if (conversationData) return conversationData.saveConversation.id

    return getOrCreateConversation({ variables: { recipients: props.participantLoginIds } }).then(({ data }) => {
      if (!data) throw new Error('Response from server was empty')
      return data.saveConversation.id
    })
  }

  // creates an empty message draft for the sake of attachment uploads
  const getDraft = async () => {
    if (messageData) return messageData.saveConversationMessage

    const conversationId = await getConversationId()

    return saveConversationMessage({
      variables: {
        message: {
          conversationId,
          content: rteUtils.basic.getEmptySerializedState(),
          contentPlainText: '',
          status: ConversationMessageStatus.Draft,
        },
      },
    }).then(({ data }) => {
      if (!data) throw new Error('Response from server was empty')
      return data.saveConversationMessage
    })
  }

  // finalizes any custom nodes and saves the message content. For the sake of cancel/reschedule flows, it can be saved as a draft
  const sendMessage = async (patientLoginId: string, { status = ConversationMessageStatus.Sent } = {}) => {
    const conversationId = await getConversationId()

    await saveMessageAssessments(editor, patientLoginId)
    const contentPlainText = await rteUtils.export.getPlainText(editor)

    const response = await saveConversationMessage({
      variables: {
        message: {
          id: messageData?.saveConversationMessage?.id,
          conversationId: conversationId,
          content: isContentEmpty ? rteUtils.basic.getEmptySerializedState() : rteUtils.export.getJSON(editor),
          contentPlainText,
          status,
        },
      },
    })
    if (!response.data) throw new Error('Response from server was empty')
    return response.data.saveConversationMessage
  }

  const reset = () => {
    rteUtils.basic.clearEditor(editor)
    setPendingAttachments([])
    resetMessageMutation()
  }

  return (
    <MessageComposerContext.Provider
      {...props}
      value={{
        isContentEmpty,
        editor,
        pendingAttachments,
        setPendingAttachments,
        getConversationId,
        getDraft,
        sendMessage,
        loading: conversationLoading || messageLoading || assessmentsLoading,
        invalidAttachments: !every(pendingAttachments, ['status', 'success']),
        reset,
      }}
    />
  )
}

export function useMessageComposerContext() {
  const context = useContext(MessageComposerContext)
  if (!context) {
    throw new Error('useMessageComposerContext must be used within a MessageComposerContextProvider')
  }

  return context
}
