import { css, styled } from '@mui/material'
import { uniqueId } from 'lodash'
import React, { useRef, useState } from 'react'

import { greySet, tealSet, white, yellowSet } from '../../styles/colorSets'
import { body1, error as errorColor, interactiveFill } from '../../styles/colors'
import { Scheme } from '../../types/Scheme'
import { HelperText } from '../HelperText'
import { SelectProps } from '../Select/Select'
import { TextFieldProps } from '../TextField/TextField'

export interface RawInputProps {
  inputProps?: Record<string, string>
}

export interface BaseInputProps
  extends Omit<React.HTMLAttributes<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>, 'children'>,
    RawInputProps {
  value: string
  error?: boolean
  urgent?: boolean
  disabled?: boolean
  helperText?: React.ReactNode
  $hideUnderline?: boolean
  label?: React.ReactNode
  maxCharacters?: number
  hideCharacterCount?: boolean
  name?: string
  required?: boolean
  showBottomBorderOnFocus?: boolean
  scheme?: Scheme
  shrink?: boolean
  labelNoWrap?: boolean
  renderIcon?: (color: string) => React.ReactNode
  children: (
    onFocus: React.FocusEventHandler<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>,
    onBlur: React.FocusEventHandler<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>,
    id: string,
  ) => React.ReactElement<TextFieldProps> | React.ReactElement<SelectProps>
}

export function BaseInput({
  onFocus,
  onBlur,
  helperText,
  label,
  maxCharacters,
  hideCharacterCount,
  value,
  id: passedId,
  children,
  className = '',
  error = false,
  urgent = false,
  renderIcon = undefined,
  scheme = 'light',
  style = {},
  disabled = false,
  showBottomBorderOnFocus = true,
  shrink = false,
  labelNoWrap = false,
}: BaseInputProps) {
  const [isFocused, setIsFocused] = useState(false)

  const handleFocus = (e: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>) => {
    setIsFocused(true)
    onFocus && onFocus(e)
  }

  const handleBlur = (e: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>) => {
    setIsFocused(false)
    onBlur && onBlur(e)
  }

  const idRef = useRef(passedId ?? uniqueId('input-'))

  const getIconColor = () => {
    if (error) {
      return errorColor
    }

    if (urgent) {
      return yellowSet[80].hex
    }

    if (isFocused) {
      return interactiveFill
    }

    return greySet[70].hex
  }

  return (
    <IconInputContainer className={className} style={style}>
      {renderIcon && <IconContainer className="mr-1 icon-container">{renderIcon(getIconColor())}</IconContainer>}

      <InputContainer disabled={disabled} scheme={scheme} className="input-container">
        {label && (
          <Label
            noWrap={labelNoWrap}
            scheme={scheme}
            htmlFor={idRef.current}
            error={error}
            urgent={urgent}
            shrink={value.length > 0 || isFocused || shrink}
          >
            {label}
          </Label>
        )}

        <InputWrapper
          scheme={scheme}
          isFocused={isFocused}
          error={error}
          urgent={urgent}
          showBottomBorderOnFocus={showBottomBorderOnFocus}
          disabled={disabled}
        >
          {children(handleFocus, handleBlur, idRef.current)}
        </InputWrapper>

        {(helperText || maxCharacters) && (
          <HelperText error={error} urgent={urgent}>
            {helperText}
            {maxCharacters && !hideCharacterCount && (
              <CharacterCount>
                {value.length}/{maxCharacters}
              </CharacterCount>
            )}
          </HelperText>
        )}
      </InputContainer>
    </IconInputContainer>
  )
}

export const inputHeightConstants = {
  paddingTop: 6,
  paddingBottom: 7,
  fontSize: 16,
  lineHeight: 1.1875,
  get inputPadding() {
    return this.paddingTop + this.paddingBottom
  },
  get singleRowHeight() {
    return this.fontSize + this.lineHeight
  },
}

export interface InputStylesProps {
  scheme?: Scheme
  $hideUnderline?: boolean
  $placeholderAlwaysVisible?: boolean
}

export const inputStyles = ({
  scheme = 'light',
  $placeholderAlwaysVisible = false,
  $hideUnderline = false,
}: InputStylesProps) => css`
  appearance: none;
  background: transparent;
  border: 0;
  border-radius: 0;
  border-bottom: 1px solid ${greySet[50].hex};
  color: ${scheme === 'dark' ? white.hex : body1};
  font-size: ${inputHeightConstants.fontSize}px;
  line-height: ${inputHeightConstants.lineHeight};
  padding: ${inputHeightConstants.paddingTop}px 0 ${inputHeightConstants.paddingBottom}px;
  width: 100%;

  &::placeholder {
    visibility: ${$placeholderAlwaysVisible ? 'visible' : 'hidden'};
  }

  ${$hideUnderline &&
  `
      border-bottom-color: transparent;
    `}

  &:focus {
    outline: none;
    &::placeholder {
      visibility: visible;
    }
  }

  &:disabled {
    opacity: 0.4;
  }

  // Hide default browser number arrows
  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  &[type='number'] {
    -moz-appearance: textfield;
  }
`

const IconInputContainer = styled('div')`
  display: flex;
  align-items: flex-start;
`

const InputContainer = styled('div')<{ disabled: boolean; scheme: Scheme }>`
  padding-top: 16px;
  position: relative;
  width: 100%;

  ${props =>
    !props.disabled &&
    `
      &:hover {
        label {
          color: ${props.scheme === 'dark' ? tealSet[30].hex : tealSet[70].hex};
        }
      }
    `}
`

const IconContainer = styled('div')`
  padding-top: 20px;
  flex: 0 0 auto;
`

const InputWrapper = styled('div')<{
  scheme: Scheme
  isFocused: boolean
  error: boolean
  urgent: boolean
  showBottomBorderOnFocus: boolean
  disabled: boolean
}>`
  position: relative;

  &::after {
    background-color: ${props => {
      if (props.error) {
        return errorColor
      }

      if (props.urgent) {
        return yellowSet[80].hex
      }

      if (props.scheme === 'dark') {
        return tealSet[30].hex
      }

      return interactiveFill
    }};
    content: '';
    height: 2px;
    width: 100%;
    position: absolute;
    bottom: 0;
    left: 0;
    transform: scaleX(
      ${props =>
        (!props.disabled && props.isFocused && props.showBottomBorderOnFocus) || props.error || props.urgent ? 1 : 0}
    );
    transition: transform 200ms;
  }

  ${props =>
    !props.disabled &&
    `
      &:hover::after {
        transform: scaleX(${props.showBottomBorderOnFocus ? 1 : 0});
      }
    `}

  textarea {
    vertical-align: middle; // get rid of inline line-height space under textarea
  }
`

const Label = styled('label')<{ scheme: Scheme; error: boolean; urgent: boolean; shrink: boolean; noWrap?: boolean }>`
  color: ${props => {
    if (props.error) {
      return errorColor
    }

    if (props.urgent) {
      return yellowSet[80].hex
    }

    if (props.scheme === 'dark') {
      return white.hex
    }

    return greySet[50].hex
  }};
  font-size: 16px;
  position: absolute;
  left: 0;
  top: 0;
  transition: transform 200ms cubic-bezier(0, 0, 0.2, 1) 0ms;
  transform: translateY(22px);
  transform-origin: 0 0;

  ${props => props.noWrap && 'text-wrap: nowrap;'}

  ${props => props.shrink && `transform: translateY(2px) scale(0.75);`}
`

const CharacterCount = styled('span')`
  margin-left: auto;
  text-align: right;
`
