import { Autocomplete, AutocompleteProps, TextField as MUITextField } from '@mui/material'
import { isString } from 'lodash'
import { ReactNode, useMemo, useState } from 'react'

import {
  InsurancePayerPlanSelectOptionsQuery,
  InsurancePayerPlansSearchOptions,
  useInsurancePayerPlanSelectOptionsQuery,
} from '@nuna/api'

export type PayerPlan = InsurancePayerPlanSelectOptionsQuery['insurancePayerPlans']['items'][number]

type LimitedAutocompleteProps = Omit<
  AutocompleteProps<PayerPlan, false, boolean | undefined, false>,
  'onChange' | 'options' | 'renderInput' | 'value'
>

export interface PayerPlanSelectProps extends LimitedAutocompleteProps {
  label: string
  value?: PayerPlan | null | string
  onChange: (payerPlan: PayerPlan | null) => void
  searchFilters?: InsurancePayerPlansSearchOptions
  error?: boolean
  helperText?: string | ReactNode
}

export function PayerPlanSelect({
  label,
  value,
  onChange,
  searchFilters,
  error,
  helperText,
  ...props
}: PayerPlanSelectProps) {
  const [searchValue, setSearchValue] = useState('')
  const { data, loading } = useInsurancePayerPlanSelectOptionsQuery({
    variables: {
      pagination: { limit: 1000 },
      filters: { ...searchFilters, name: searchValue },
    },
    context: { debounceKey: 'insurance-payer-plans-debounce', debounceTimeout: 500 },
  })

  const options: PayerPlan[] = useMemo(() => data?.insurancePayerPlans.items ?? [], [data])

  const valueAsOption = useMemo(() => {
    if (isString(value)) {
      return options.find(plan => plan.id === value) ?? null
    }

    return value
  }, [options, value])

  return (
    <Autocomplete
      options={options}
      isOptionEqualToValue={(option, value) => option.id === value.id}
      getOptionLabel={option => getOptionLabel(option)}
      loading={loading}
      onChange={(_event, payerPlan) => {
        onChange(payerPlan)
        setSearchValue('')
      }}
      renderInput={params => (
        <MUITextField
          {...params}
          label={label}
          placeholder="Filter by plan"
          onChange={e => setSearchValue(e.currentTarget.value)}
          error={error}
          helperText={helperText}
        />
      )}
      value={valueAsOption}
      {...props}
    />
  )
}

function getOptionLabel(option: PayerPlan) {
  return option.name
}
