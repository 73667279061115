import { Formik, FormikHelpers } from 'formik'
import { noop } from 'lodash'
import moment from 'moment'
import pluralize from 'pluralize'
import { useEffect, useState } from 'react'

import {
  AppointmentForNotificationFragment,
  BasicPatientFragment,
  StartEndGroup,
  useBookMultipleAppointmentsAsProviderMutation,
  useCreateBookingAsProviderMutation,
} from '@nuna/api'
import { useIsAdmin } from '@nuna/auth'
import { useAppointmentDrawerSearchParams } from '@nuna/common'
import { errorService } from '@nuna/core'
import { useConversationContext } from '@nuna/messaging'
import { OutlineButtonLink, checkSparkles, toast } from '@nuna/tunic'

import { AppointmentFormValues } from '../shared'
import { NewAppointmentForm } from './NewAppointmentForm'
import { NewSidebarPatientHeader } from './NewSidebarPatientHeader'

interface NewAppointmentsProps {
  patient: BasicPatientFragment
  openPatientLinkInNewTab?: boolean
  afterAppointmentSave?: (appointment: AppointmentForNotificationFragment) => void
}

// TODO:robbie - this is duplicate code from core-deprecated.
// unable to import due to circular dependencies.
const patientDetail = (id: string) => {
  return `/clients/${id}/profile`
}

function getConversationParticipantRoute(participantId: string) {
  return `/messages/${participantId}`
}

function formatAddressId(addressId: string | undefined) {
  return addressId?.toLowerCase() === 'virtual' ? undefined : addressId
}

export function NewAppointments({
  patient,
  openPatientLinkInNewTab,
  afterAppointmentSave = noop,
}: NewAppointmentsProps) {
  const { conversations } = useConversationContext()
  const isAdmin = useIsAdmin()
  const [numberOfBookedAppointments, setNumberOfBookedAppointments] = useState<number | null>(null)
  const [conversationId, setConversationId] = useState<string | null>(null)
  const {
    drawerConfig: { scheduleProviderId, timeSlot },
    closeDrawer,
  } = useAppointmentDrawerSearchParams()

  const [createMultipleBookingsAsProvider, { loading: isMultipleBookingLoading }] =
    useBookMultipleAppointmentsAsProviderMutation({ refetchQueries: ['PatientCap'] })
  const [createBookingAsProvider, { loading: isSingleBookingLoading }] = useCreateBookingAsProviderMutation({
    refetchQueries: ['PatientCap'],
  })

  useEffect(() => {
    if (numberOfBookedAppointments) {
      const conversationWithBookedClient = conversations.find(conversation => {
        return (
          conversation.participants &&
          conversation.participants.some(participant => participant.loginId === patient.loginId)
        )
      })

      !!conversationWithBookedClient && setConversationId(conversationWithBookedClient.id)
    }
  }, [numberOfBookedAppointments, conversations, patient])

  const isLoading = isMultipleBookingLoading || isSingleBookingLoading

  const handleSubmit = async (values: AppointmentFormValues, formikBag: FormikHelpers<AppointmentFormValues>) => {
    const includedAppointments = values.appointments.filter(appointment => appointment.included)
    const formattedAddressId = formatAddressId(values.singleAddressId)

    let singleAppointment: StartEndGroup | null = null
    if (includedAppointments.length === 1) {
      const [appointment] = includedAppointments

      singleAppointment = appointment.newStart
        ? {
            start: appointment.newStart,
            end: appointment.newStart.clone().add('50', 'm'),
            addressId: formattedAddressId,
          }
        : {
            start: appointment.slot.start,
            end: appointment.slot.end,
            addressId: formattedAddressId,
          }
    } else if (values.singleStartTime) {
      singleAppointment = {
        start: values.singleStartTime,
        end: moment(values.singleStartTime).add('50', 'm'),
        addressId: formattedAddressId,
      }
    }

    if (singleAppointment) {
      try {
        const newAppointmentData = await createBookingAsProvider({
          variables: {
            providerId: isAdmin ? scheduleProviderId : undefined,
            patientId: patient.id,
            force: values.force,
            addressId: formattedAddressId,
            ...singleAppointment,
          },
        })

        setNumberOfBookedAppointments(1)
        afterAppointmentSave({
          startDatetime: singleAppointment.start,
          id: newAppointmentData.data?.createBookingAsProvider.id || '',
        })
      } catch (e) {
        const errorMessage = errorService.transformGraphQlError(e, 'Could not schedule appointment')
        if (errorService.getGraphQLErrorExtensions(e).some((ext?: Record<string, unknown>) => ext?.CAN_FORCE)) {
          toast.urgent(errorMessage, {
            action: {
              onClick: () => {
                formikBag.setFieldValue('force', true)
                formikBag.submitForm()
              },
              buttonText: `Schedule Anyway`,
            },
            duration: Number.POSITIVE_INFINITY,
          })
        } else {
          toast.urgent(errorMessage)
        }
      }
    } else {
      try {
        const includedAppointments = values.appointments.filter(appointment => appointment.included)
        await createMultipleBookingsAsProvider({
          variables: {
            providerId: isAdmin ? scheduleProviderId : undefined,
            patientId: values.patientId,
            force: values.force,
            appts: {
              appointments: includedAppointments.map(appointment => {
                if (appointment.newStart) {
                  return { start: appointment.newStart, end: appointment.newStart.clone().add('50', 'm') }
                }

                return {
                  start: appointment.slot.start,
                  end: appointment.slot.end,
                  addressId: formatAddressId(appointment.newLocation),
                }
              }),
            },
          },
        })

        setNumberOfBookedAppointments(includedAppointments.length)
        afterAppointmentSave({
          startDatetime: includedAppointments[0].newStart,
          id: 'multiple' || '',
        })
      } catch (e) {
        const errorMessage = errorService.transformUserGraphqlError(e, 'Could not schedule appointment')
        if (errorService.getGraphQLErrorExtensions(e).some((ext?: Record<string, unknown>) => ext?.CAN_FORCE)) {
          toast.urgent(errorMessage, {
            action: {
              onClick: () => {
                formikBag.setFieldValue('force', true)
                formikBag.submitForm()
              },
              buttonText: `Schedule Anyway`,
            },
            duration: Number.POSITIVE_INFINITY,
          })
        } else {
          toast.urgent(errorMessage)
        }
      }
    }
  }
  return (
    <>
      <NewSidebarPatientHeader
        onClose={() => {
          if (closeDrawer) {
            closeDrawer()
          }
        }}
        openPatientLinkInNewTab={openPatientLinkInNewTab}
        patient={patient}
      />

      {(() => {
        if (numberOfBookedAppointments !== null) {
          return (
            <div className="text-center" style={{ padding: '16px' }}>
              <img className="mt-3 mb-2" src={checkSparkles} style={{ maxWidth: 228 }} alt="" />

              <h3 style={{ maxWidth: 300 }} className="mx-auto h5 text-normal">
                Nice! You&apos;ve got {numberOfBookedAppointments} new{' '}
                {pluralize('session', numberOfBookedAppointments)} locked in with {patient.firstName}.
              </h3>

              <OutlineButtonLink to={patientDetail(patient.id)} className="mt-2 full-width">
                <span>Review {patient.firstName}&apos;s Profile</span>
              </OutlineButtonLink>

              <OutlineButtonLink className="mt-2 full-width" to={getConversationParticipantRoute(conversationId ?? '')}>
                <span>Message {patient.firstName}</span>
              </OutlineButtonLink>
            </div>
          )
        }

        const initialValues: AppointmentFormValues = {
          patientId: patient.id,
          appointments: [],
          singleStartTime: timeSlot?.start ? timeSlot.start : undefined,
          force: false, //used to allow an admin to ignore failures to submit because of conflicts for patient
        }

        return (
          <Formik initialValues={initialValues} onSubmit={handleSubmit}>
            <NewAppointmentForm patient={patient} providerId={scheduleProviderId || ''} isLoading={isLoading} />
          </Formik>
        )
      })()}
    </>
  )
}
