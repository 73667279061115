import { ApolloQueryResult } from '@apollo/client'
import { startCase } from 'lodash'
import { useState } from 'react'
import { Cell, ColumnInstance } from 'react-table'

import { CustomerCompanyQuery, HrisIntegration, useDeleteCompanyHrisIntegrationMutation } from '@nuna/api'
import { DataTableRowComponentProps } from '@nuna/data-table'
import { Confirm, IconButton, IconCheckCircle, IconClose, IconError, StatusLabel, csx, toast } from '@nuna/tunic'

export interface HrisIntegrationRowData extends HrisIntegration {
  stageStatus: string
  syncStatus: string
  empty: string
}

interface CustomColumn extends ColumnInstance<HrisIntegration> {
  className?: string
}

interface Props extends DataTableRowComponentProps<HrisIntegrationRowData> {
  refetchCustomerCompany: () => Promise<ApolloQueryResult<CustomerCompanyQuery>>
}

export function HrisIntegrationRow({ row, refetchCustomerCompany }: Props) {
  const [isDeleteConfirmation, setIsDeleteConfirmation] = useState(false)
  const [deleteIntegration, { loading }] = useDeleteCompanyHrisIntegrationMutation()
  const cells = row.cells.filter(cell => cell.column.id !== 'id')
  const bandedClass = row.index % 2 ? 'row-even' : 'row-odd'

  async function onConfirmDelete(didConfirm: boolean) {
    if (!didConfirm) {
      setIsDeleteConfirmation(false)
      return
    }

    try {
      const result = await deleteIntegration({ variables: { integrationId: row.original.id } })
      if (result?.errors?.length) {
        throw new Error('Failed to delete integration')
      }
      await refetchCustomerCompany()
      toast.info(`Successfully setup sync!`)
    } catch (e) {
      console.error('failed to delete integration', e)
      toast.urgent(`Unable to delete integration. Please try again later`)
    }
    setIsDeleteConfirmation(false)
  }

  return (
    <tr {...row.getRowProps()} className={bandedClass}>
      {cells.map(cell => (
        <td
          {...cell.getCellProps({ style: cell.column.width ? { width: `${cell.column.width}px` } : {} })}
          className={csx([(cell.column as CustomColumn).className as string])}
        >
          <span className="full-width v-align flex-row ">
            <RowCell
              cell={cell}
              isDeleteLoading={loading}
              onRequestDeleteIntegration={() => setIsDeleteConfirmation(true)}
              onConfirmIntegrationDelete={onConfirmDelete}
              isShowingDeleteConfirmation={isDeleteConfirmation}
            />
          </span>
        </td>
      ))}
    </tr>
  )
}

function RowCell({
  cell,
  isDeleteLoading,
  onRequestDeleteIntegration,
  onConfirmIntegrationDelete,
  isShowingDeleteConfirmation,
}: {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  cell: Cell<HrisIntegrationRowData, any>
  onRequestDeleteIntegration: () => void
  isDeleteLoading: boolean
  onConfirmIntegrationDelete: (didConfirm: boolean) => void
  isShowingDeleteConfirmation: boolean
}) {
  switch (cell.column.Header) {
    case '':
      return (
        <IconButton
          className="right-align"
          tooltip="Remove"
          onClick={onRequestDeleteIntegration}
          disabled={isDeleteLoading}
        >
          <IconClose size={20} />
          <Confirm onConfirm={onConfirmIntegrationDelete} isOpen={isShowingDeleteConfirmation}>
            Are you sure you want to remove this HRIS integration?
          </Confirm>
        </IconButton>
      )
    case 'Status':
      return (
        <span className="full-width v-align flex-row">
          <StatusLabel intent={cell.value === 'STAGED' ? 'caution' : 'information'}>{cell.render('Cell')}</StatusLabel>
        </span>
      )
    case 'Sync Status':
      return (
        <span className="full-width v-align flex-column">
          {cell.value === 'SYNCING' ? <IconCheckCircle size={20} /> : <IconError size={20} />}
        </span>
      )
    case 'Facilitator':
      return <>{startCase(cell.value.toLocaleLowerCase())}</>
    default:
      return <>{cell.render('Cell')}</>
  }
}
