import { hideOthers as ariaHide } from 'aria-hidden'
import { useEffect, useRef } from 'react'

export function useAriaHide(shouldHide: boolean) {
  // eslint-disable-next-line @typescript-eslint/ban-types
  const undoAriaHide = useRef<Function | null>()
  const containerRef = useRef<HTMLElement | null>(null)

  const hide = () => {
    // If we are activating the region, apply aria-hidden to all elements outside of it
    if (shouldHide && containerRef.current && !undoAriaHide.current) {
      undoAriaHide.current = ariaHide(containerRef.current)
    }
  }

  const unhide = () => {
    if (undoAriaHide.current && typeof undoAriaHide.current === 'function') {
      undoAriaHide.current()
      undoAriaHide.current = null
    }
  }

  const setContainerRef = (el: HTMLElement | null) => {
    containerRef.current = el
    hide()
  }

  useEffect(() => {
    // If we are activating the region, apply aria-hidden to all elements outside of it
    if (shouldHide) {
      hide()
    } else {
      unhide()
    }

    // Component is unmounting, call unhide to clean up
    return unhide
  }, [shouldHide]) // eslint-disable-line react-hooks/exhaustive-deps

  return [setContainerRef]
}
