import { styled } from '@mui/material'
import React from 'react'

import { IconMicrophoneOff, IconVideoDeprecated, IconVideoOff } from '@nuna/tunic'
import { error, greySet, tealSet } from '@nuna/tunic'

export interface VideoActionButtonProps {
  isEnabled?: boolean
  compact?: boolean
  disabled?: boolean
}

export const VideoActionButton = styled('button', {
  shouldForwardProp: (propName: string) => !['isEnabled', 'compact', 'disabled'].includes(propName),
})<VideoActionButtonProps>`
  align-items: center;
  background-blend-mode: multiply;
  background-color: rgba(88, 88, 88, 0.4);
  border-radius: 48px;
  border: 2px solid rgba(255, 255, 255, 0.13);
  color: white;
  display: inline-flex;
  font-size: 1rem;
  font-weight: 600;
  height: ${props => (props.compact ? '42px' : '48px')};
  justify-content: center;
  margin: 0.25rem;
  min-width: ${props => (props.compact ? '42px' : '48px')};
  text-transform: uppercase;

  span {
    padding: 0 1rem;
  }

  &:not(:disabled):hover {
    border-color: white;
  }

  &:disabled {
    cursor: default;
    opacity: 0.5;
  }

  ${props =>
    props.isEnabled === false &&
    `
    border-color: ${error};

    svg {
      fill: ${greySet[30].hex};
    }

    &:not(:disabled):hover {
      border-color: ${error};
      
      svg {
        fill: white;
      }
    }
  `}
`

const MutedIndicatorContainer = styled('div')`
  align-items: center;
  background-color: ${tealSet[90].transparency(0.7)};
  border-radius: 6px;
  color: white;
  display: inline-flex;
  height: 28px;
  justify-content: center;
  width: 28px;
`

export function MutedIndicator(props: React.HTMLAttributes<HTMLDivElement>) {
  return (
    <MutedIndicatorContainer {...props}>
      <IconMicrophoneOff size={16} />
    </MutedIndicatorContainer>
  )
}

export function VideoToggleButton({
  isEnabled,
  ...props
}: React.HTMLAttributes<HTMLButtonElement> & VideoActionButtonProps) {
  return (
    <VideoActionButton aria-label="Toggle video" isEnabled={isEnabled} {...props}>
      {/* Note: To get rid of the deprecated usage we need to update the IconVideoOff to be the same size */}
      {isEnabled ? <IconVideoDeprecated /> : <IconVideoOff />}
    </VideoActionButton>
  )
}
