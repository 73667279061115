import { useMemo } from 'react'
import { Row } from 'react-table'

import { ProviderDirectoryType } from '@nuna/api'
import { numberService } from '@nuna/core'
import { DataTable, DataTableColumn } from '@nuna/data-table'
import { TextButton } from '@nuna/tunic'

import { useDirectoriesContext } from './DirectoriesContext'
import { DirectoryLogo } from './DirectoryLogo'
import { RECOMMENDED_DIRECTORIES } from './utils'

type RecommendedDirectoryTableRow = {
  id: string
  name: string
  directoryType: ProviderDirectoryType
}

export function RecommendedDirectoryTable({
  joinedDirectoryTypes,
  disabled,
  sessionRate,
}: {
  joinedDirectoryTypes: ProviderDirectoryType[]
  disabled: boolean
  sessionRate: number
}) {
  const { handleDirectoryDrawerClick } = useDirectoriesContext()

  const rowData: Array<RecommendedDirectoryTableRow> = useMemo(
    () =>
      Object.values(RECOMMENDED_DIRECTORIES).filter(rowData => !joinedDirectoryTypes.includes(rowData.directoryType)),
    [joinedDirectoryTypes],
  )
  const columns = useMemo<DataTableColumn<RecommendedDirectoryTableRow>[]>(
    () => [
      {
        id: 'name',
        Cell: ({ row }: { row: Row<RecommendedDirectoryTableRow> }) => (
          <div className="v-align">
            <DirectoryLogo className="mr-2" directoryType={row.original.directoryType} />
            <TextButton
              disabled={disabled}
              variant={'secondary'}
              className="no-wrap"
              onClick={() => handleDirectoryDrawerClick(row.original.directoryType)}
            >
              {row.original.name}
            </TextButton>
          </div>
        ),
        Header: () => <b>WE RECOMMEND JOINING</b>,
        // hardcoding widths here so the two separate tables line up correctly
        style: { width: '25%' },
      },
      {
        id: 'referrals',
        Cell: ({ row }: { row: Row<RecommendedDirectoryTableRow> }) => (
          // TODO: opening "Join Directory drawer" to be handled in SQUDY-26
          <TextButton
            disabled={disabled}
            variant={'primary'}
            onClick={() => handleDirectoryDrawerClick(row.original.directoryType)}
          >
            Join
          </TextButton>
        ),
        style: { width: '15%' },
      },
      {
        id: 'clients',
        Cell: () => <>-</>,
        style: { width: '20%' },
      },
      {
        id: 'rate',
        Cell: () => <>Make {numberService.centsToFormattedDollars(sessionRate)} per session</>,
        style: { width: '20%' },
      },
      {
        id: 'status',
        Cell: 'Not joined',
        style: { width: '20%' },
      },
    ],
    [disabled, handleDirectoryDrawerClick, sessionRate],
  )
  return (
    <DataTable
      customCellStyle={() => ({
        opacity: disabled ? 0.4 : 1,
      })}
      columns={columns}
      rowData={rowData}
      loading={false}
    />
  )
}
