import { isNil, sortBy } from 'lodash'
import moment from 'moment'

import { Persona } from '../types/exported'
import {
  AccountSource,
  Appointment,
  AppointmentChangeReason,
  AppointmentCurrentStatus,
  TimeSlot,
} from '../types/internal-only'

function isAppointmentCanceled({ currentStatus }: Pick<Appointment, 'currentStatus'>) {
  return (
    currentStatus === AppointmentCurrentStatus.CanceledByAdmin ||
    currentStatus === AppointmentCurrentStatus.CanceledByProvider ||
    currentStatus === AppointmentCurrentStatus.CanceledByPatient
  )
}

function isFuture(appointment: { startDatetime: Date }) {
  return moment(appointment.startDatetime).isAfter(moment())
}

function isPast(appointment: { endDatetime: Date }, { endOffset = 0 } = {}) {
  return moment(appointment.endDatetime).add(endOffset, 'm').isBefore(moment())
}

function isHappeningNow(
  appointment: { endDatetime: Date; startDatetime: Date },
  { startOffset = 0, endOffset = 0 } = {},
) {
  return moment().isBetween(
    moment(appointment.startDatetime).add(startOffset, 'm'),
    moment(appointment.endDatetime).add(endOffset, 'm'),
  )
}

function appointmentTimeSpan({
  startDatetime,
  endDatetime,
}: {
  startDatetime?: string | null
  endDatetime?: string | null
}) {
  return `${moment(startDatetime).calendar(null, {
    sameDay: '[Today from] h:mm',
    nextDay: '[Tomorrow from] h:mm',
    nextWeek: 'dddd, MMM Do [from] h:mm',
    sameElse: 'dddd, MMM Do [from] h:mm',
    lastWeek: 'dddd, MMM Do [from] h:mm',
  })}–${moment(endDatetime).format('h:mma')}`
}

interface SubjectToCancelationFeeArgs {
  policyAcceptedDate?: string | null
  appointmentStartDatetime: string | Date | moment.Moment
  cancelPeriodMinutes?: number | null
}

function subjectToCancelationFee({
  policyAcceptedDate,
  appointmentStartDatetime,
  cancelPeriodMinutes,
}: SubjectToCancelationFeeArgs) {
  if (isNil(policyAcceptedDate)) {
    return false
  }

  if (isNil(cancelPeriodMinutes)) {
    return false
  }
  const minutesFromNow = moment(appointmentStartDatetime).diff(moment(), 'minutes')
  return minutesFromNow < cancelPeriodMinutes
}

function upcomingAppointmentInfo(startDatetime: string | moment.Moment, precise?: boolean) {
  const minutesFromNow = moment(startDatetime).diff(moment(), 'minutes')
  const getText = () => {
    if (minutesFromNow < 0) {
      return moment(startDatetime).fromNow()
    } else if (minutesFromNow > 60 * 24 && !precise) {
      // more than a day but not precise

      // since we aren't looking for precision, we need to round up. We can do this accurately by
      // shifting to the start of the day for both today and the appointment date, then diffing
      return `${moment(startDatetime).startOf('day').diff(moment().startOf('day'), 'days')} days`
    } else if (minutesFromNow > 60 * 24 && precise) {
      // more than a day and precise
      const days = Math.floor(minutesFromNow / 60 / 24)
      const hours = (minutesFromNow % (60 * 24)) / 60
      const minutes = minutesFromNow % 60
      const dayString = days > 1 ? 'days' : 'day'
      return `${days} ${dayString} ${Math.floor(hours)} hr ${minutes} min`
    } else if (minutesFromNow >= 61 && precise) {
      return `${Math.floor(minutesFromNow / 60)} hr ${minutesFromNow % 60} min`
    } else if (minutesFromNow > 60) {
      return `${Math.ceil(minutesFromNow / 60)} hours`
    } else if (Math.floor(minutesFromNow) === 60) {
      return '1 hour'
    } else if (Math.floor(minutesFromNow) === 1) {
      return `${minutesFromNow} minute`
    } else {
      return `${Math.floor(minutesFromNow)} minutes`
    }
  }

  return { minutesFromNow, text: getText() }
}

function upcomingValidAppointments<T extends Pick<Appointment, 'startDatetime' | 'endDatetime' | 'currentStatus'>>(
  appointments: T[],
) {
  return appointments
    .filter(appt => new Date(appt.endDatetime) >= new Date())
    .filter(appt => appt.currentStatus === AppointmentCurrentStatus.Active)
    .sort((a, b) => moment(a.startDatetime).valueOf() - moment(b.startDatetime).valueOf())
}

function timeStampToTimeSlot(timeStamp: string): TimeSlot {
  const unixTimestamp = Number(timeStamp)
  if (isNaN(unixTimestamp)) throw new Error('Invalid timestamp')

  return { start: moment.unix(unixTimestamp), end: moment.unix(unixTimestamp).add(50, 'minutes') }
}

function timeSlotToTimeStamp(timeSlot: TimeSlot | string) {
  const startTime = typeof timeSlot === 'string' ? timeSlot : timeSlot.start

  return moment(startTime).unix().toString()
}

function userTypeToAppointmentCancelStatus(userType: Persona): AppointmentCurrentStatus {
  return userType === 'provider'
    ? AppointmentCurrentStatus.CanceledByProvider
    : AppointmentCurrentStatus.CanceledByPatient
}

function userTypeToAppointmentRescheduleStatus(userType: Persona): AppointmentCurrentStatus {
  return userType === 'provider'
    ? AppointmentCurrentStatus.RescheduledByProvider
    : AppointmentCurrentStatus.RescheduledByPatient
}

function sortTimeSlots(timeSlots: TimeSlot[]) {
  return sortBy([...timeSlots], slot => moment(slot.start))
}

const ChangeReasonMap: Record<AppointmentChangeReason, string> = {
  [AppointmentChangeReason.SchedulingConflict]: 'Scheduling conflict',
  [AppointmentChangeReason.NotFeelingWell]: 'Not feeling well',
  [AppointmentChangeReason.LossOfInterest]: 'Loss of interest',
  [AppointmentChangeReason.FeelingNervous]: 'Feeling nervous',
  [AppointmentChangeReason.RequestedByPatient]: 'Requested by patient',
  [AppointmentChangeReason.FreeForm]: 'Something else',
  [AppointmentChangeReason.TechnicalDifficulties]: 'Technical difficulties',
  [AppointmentChangeReason.ClientNoShow]: 'Client no-show',
  [AppointmentChangeReason.ProviderNoShow]: 'Provider no-show',
  [AppointmentChangeReason.InvalidCoverage]: 'Invalid coverage',
  [AppointmentChangeReason.SwitchedProvider]: 'Switched provider',
}

function readableChangeReason(changeReason: AppointmentChangeReason) {
  return ChangeReasonMap[changeReason]
}

function sortAppointmentsByStartTime(a: { startDatetime: Date }, b: { startDatetime: Date }) {
  if (a.startDatetime > b.startDatetime) {
    return 1
  } else if (a.startDatetime < b.startDatetime) {
    return -1
  }

  return 0
}

function isCancelableOnZocdoc(appointment: { source?: AccountSource | null; createdAt: string }) {
  return (
    appointment.source === AccountSource.Zocdoc && moment(appointment.createdAt).isAfter(moment().subtract(1, 'day'))
  )
}

const AppointmentStatusMap = {
  [AppointmentCurrentStatus.Active]: 'Scheduled',
  [AppointmentCurrentStatus.CanceledByAdmin]: 'Admin Canceled',
  [AppointmentCurrentStatus.CanceledByPatient]: 'Patient Canceled',
  [AppointmentCurrentStatus.CanceledByProvider]: 'Provider Canceled',
  [AppointmentCurrentStatus.NoShowByPatient]: 'Patient No-show',
  [AppointmentCurrentStatus.NoShowByProvider]: 'Provider No-show',
  [AppointmentCurrentStatus.RescheduledByPatient]: 'Patient Rescheduled',
  [AppointmentCurrentStatus.RescheduledByProvider]: 'Provider Rescheduled',
  [AppointmentCurrentStatus.Undocumented]: 'Undocumented',
}

const patient = [AppointmentCurrentStatus.CanceledByPatient]
const provider = [AppointmentCurrentStatus.CanceledByProvider]
const both = [...patient, ...provider]

const appointmentChangeReasons = [
  { value: AppointmentChangeReason.SchedulingConflict, display: 'Scheduling conflict', requestableBy: both },
  { value: AppointmentChangeReason.NotFeelingWell, display: 'Not feeling well', requestableBy: both },
  { value: AppointmentChangeReason.LossOfInterest, display: 'Loss of interest', requestableBy: patient },
  { value: AppointmentChangeReason.FeelingNervous, display: 'Feeling nervous', requestableBy: patient },
  { value: AppointmentChangeReason.RequestedByPatient, display: 'Requested by patient', requestableBy: provider },
  { value: AppointmentChangeReason.FreeForm, display: 'Something else', requestableBy: both },
  { value: AppointmentChangeReason.TechnicalDifficulties, display: 'Technical difficulties', requestableBy: both },
  { value: AppointmentChangeReason.InvalidCoverage, display: 'Invalid insurance', requestableBy: patient },
]

export const appointmentService = {
  appointmentChangeReasons,
  isAppointmentCanceled,
  isFuture,
  isPast,
  isHappeningNow,
  upcomingAppointmentInfo,
  appointmentTimeSpan,
  subjectToCancelationFee,
  upcomingValidAppointments,
  timeStampToTimeSlot,
  timeSlotToTimeStamp,
  userTypeToAppointmentCancelStatus,
  userTypeToAppointmentRescheduleStatus,
  sortAppointmentsByStartTime,
  sortTimeSlots,
  readableChangeReason,
  isCancelableOnZocdoc,
  AppointmentStatusMap,
}
