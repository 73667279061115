import { styled } from '@mui/material'
import { noop } from 'lodash'
import { CSSProperties, useRef, useState } from 'react'
import { ColumnInstance, Row } from 'react-table'

import { IconChevronRight, csx } from '@nuna/tunic'

import { DataTableRowComponentProps } from './DataTable'

interface CustomColumn<I extends object> extends ColumnInstance<I> {
  className?: string
  style?: CSSProperties
}

export function DefaultTableRow<I extends object>({
  row,
  onRowClick = noop,
  customCellStyle = (_row: Row<I>) => ({}),
  expandedRow,
  showExpandedRow: shouldShowExpandedRow = () => true,
  ...rowProps
}: DataTableRowComponentProps<I>) {
  const triggerRowRef = useRef<HTMLTableRowElement>(null)
  const [showExpandedRow, setShowExandedRow] = useState(false)

  const handleRowClick = () => {
    if (expandedRow) {
      setShowExandedRow(current => !current)
      return
    }
    onRowClick(row.original)
  }

  const canExpand = shouldShowExpandedRow(row)

  const { key, ...spreadRowProps } = rowProps

  return (
    <>
      <tr
        key={key}
        {...spreadRowProps}
        className={csx([{ 'row-expanded': showExpandedRow, 'no-expand': !canExpand }, rowProps.className])}
        onClick={handleRowClick}
        ref={triggerRowRef}
      >
        {!!expandedRow && (
          <StyledExpandIconCell>
            <IconChevronRight
              className={csx([{ expanded: showExpandedRow, 'no-expand': !canExpand }, 'expand-icon'])}
            />
          </StyledExpandIconCell>
        )}
        {row.cells.map(cell => {
          // For some reason className is missing from ColumnInstance even though it has it and Column
          // has a className typed property
          const columnClassName = (cell.column as CustomColumn<I>).className

          const { key, ...cellProps } = cell.getCellProps()

          return (
            <td
              key={key}
              {...cellProps}
              style={composeCellStyles(customCellStyle(row), cell.column as CustomColumn<I>)}
              className={columnClassName}
            >
              {cell.render('Cell', { isExpanded: showExpandedRow })}
            </td>
          )
        })}
      </tr>
      {!!expandedRow && showExpandedRow && (
        <tr className="expanded-row">
          <td colSpan={row.cells.length + 1}>{expandedRow({ row, triggerRowRef })}</td>
        </tr>
      )}
    </>
  )
}

const StyledExpandIconCell = styled('td')`
  width: 16px;

  .expand-icon {
    transition: transform 0.3s;

    &.expanded {
      transform: rotate(90deg);
    }

    &.no-expand {
      visibility: hidden;
    }
  }
`

function composeCellStyles<I extends object>(overrides: CSSProperties, column: CustomColumn<I>): CSSProperties {
  const columnStyles = column.style ?? {}
  return { ...columnStyles, ...overrides }
}
