import { ProviderDetailsQuery, PublicProviderQuery, useProviderDetailsQuery, usePublicProviderQuery } from '@nuna/api'
import { useAuthDataContext } from '@nuna/auth'

export function useMixedAuthProviderQuery(providerId: string | null | undefined) {
  const { loggedIn } = useAuthDataContext()

  const { data: providerData } = useProviderDetailsQuery({
    variables: {
      id: providerId ?? '',
    },
    skip: !loggedIn || !providerId,
  })

  const { data: publicProviderData } = usePublicProviderQuery({
    variables: {
      providerId: providerId ?? '',
    },
    skip: loggedIn || !providerId,
  })

  return {
    authorizedProvider: providerData?.provider as ProviderDetailsQuery['provider'],
    publicProvider: publicProviderData?.publicProvider as PublicProviderQuery['publicProvider'],
  }
}
