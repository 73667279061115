import mixpanel from 'mixpanel-browser'
import { useNavigate } from 'react-router-dom'

import {
  type ProviderDetailsQuery,
  type PublicAvailabilitySlotFragment,
  SwitchProviderChangeReason,
  useSwitchProviderAndBookAppointmentMutation,
} from '@nuna/api'
import { useAppointmentDrawerSearchParams } from '@nuna/common'
import { errorService } from '@nuna/core'
import { toast } from '@nuna/tunic'

interface SwitchToProviderArgs {
  provider: ProviderDetailsQuery['provider']
  timeSlot: PublicAvailabilitySlotFragment
  oldProviderId: string
}

export function useSwitchToProvider() {
  const { state } = useAppointmentDrawerSearchParams()
  const navigate = useNavigate()

  const [switchProviderAndBookAppointment, { loading }] = useSwitchProviderAndBookAppointmentMutation({
    refetchQueries: ['PatientContext', 'RelatedUsersFeatureFlags'],
  })

  const switchToProvider = async ({ provider, timeSlot, oldProviderId }: SwitchToProviderArgs) => {
    try {
      const { data: booking } = await switchProviderAndBookAppointment({
        variables: {
          oldProviderId: oldProviderId,
          newProviderId: provider.id,
          changeReason: state?.changeReason ?? SwitchProviderChangeReason.Availability,
          start: timeSlot.start,
          end: timeSlot.end,
          freeFormReason: state?.freeFormReason,
        },
      })

      mixpanel.track('patient switched providers', state?.filters)

      navigate('/')
      return booking?.switchProviderAndBookAppointment.id

      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (e: any) {
      console.error(e)
      toast.urgent(errorService.transformUserGraphqlError(e, 'Error switching providers'))
    }
  }

  return { switchToProvider, loading }
}
