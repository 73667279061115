import mixpanel from 'mixpanel-browser'
import React from 'react'
import { Navigate } from 'react-router-dom'

import { useCurrentAppointmentsQuery } from '@nuna/api'
import { routeService } from '@nuna/core'

import { overlappingSessions } from '../../util/routes'

export function ProviderVideoRedirect() {
  const { loading, data } = useCurrentAppointmentsQuery({ fetchPolicy: 'no-cache' })

  if (loading) {
    return null
  }

  const currentAppointments = data?.currentAppointments || []

  if (!currentAppointments[0]) {
    return <Navigate to={routeService.noCurrentSessionRoute()} />
  }

  if (currentAppointments.length === 1) {
    return <Navigate to={routeService.videoSession(currentAppointments[0].id)} />
  }

  mixpanel.track('Overlapping appointments modal triggered', {})

  return <Navigate to={overlappingSessions()} />
}
