import { HTMLAttributes, useMemo } from 'react'
import { useMediaQuery } from 'react-responsive'
import { Route, Routes } from 'react-router-dom'

import { BelowTablet, IconButton, IconClose, Logo, ProgressHeader } from '@nuna/tunic'

import { useProviderSignupIntakeContext } from '../../../scenes/SignupIntake/ProviderSignupIntakeContext'
import { INTAKE_PATHS, INTAKE_STEPS, getStepNumber } from '../../../scenes/SignupIntake/signup-intake-steps'
import { SignupIntakeNavMenu } from './SignupIntakeNavMenu'

interface Props extends HTMLAttributes<HTMLHeadingElement> {
  onSaveAndExitClick: () => void
  onSaveAndNavigate: (to: string) => Promise<boolean>
}

export function IntakeProgressHeader({ onSaveAndExitClick, onSaveAndNavigate, ...props }: Props) {
  const isMobile = useMediaQuery({ query: `(${BelowTablet})` })
  const { currentStep } = useProviderSignupIntakeContext()

  const current = useMemo(() => getStepNumber(currentStep), [currentStep])
  const max = INTAKE_STEPS.length - 1

  const leftSlot = (
    <span className="h6 v-align m-0 text-white">
      <SignupIntakeNavMenu onSaveAndNavigate={onSaveAndNavigate} />
      {!isMobile && (
        <Routes>
          <Route path={INTAKE_PATHS['welcome']} element="Welcome" />
          <Route path={INTAKE_PATHS['professional']} element="Professional Info" />
          <Route path={INTAKE_PATHS['availability']} element="Availability" />
          <Route path={INTAKE_PATHS['profile']} element="Personal Info" />
          <Route path={INTAKE_PATHS['abilities']} element="Abilities" />
          <Route path={INTAKE_PATHS['bios']} element="Bios" />
          <Route index element="Next Steps" />
        </Routes>
      )}
    </span>
  )
  return (
    <ProgressHeader
      persona="provider"
      innerWrapperProps={isMobile ? { style: { padding: '0 0.5rem' } } : undefined}
      current={current}
      max={max}
      leftSlot={leftSlot}
      centerSlot={<Logo color="plum" scheme="dark" height={32} />}
      rightSlot={
        <IconButton tooltip="Save and exit" variant="dark" onClick={onSaveAndExitClick} className="ml-1">
          <IconClose size={20} />
        </IconButton>
      }
      {...props}
    />
  )
}
