import { styled } from '@mui/material'
import { useFormikContext } from 'formik'
import { Moment } from 'moment'
import { useState } from 'react'
import { useEffect } from 'react'

import { AppointmentSlotFragment, BasicPatientFragment, useMultipleAppointmentsInfoLazyQuery } from '@nuna/api'
import { AppointmentLocationSelectValue } from '@nuna/appointment'
import { useAppointmentDrawerSearchParams } from '@nuna/common'

import { AppointmentFormValues } from '../shared'
import { NewAppointmentMultiScheduler } from './NewAppointmentMultiScheduler'
import { NewAppointmentSingleScheduler } from './NewAppointmentSingleScheduler'
import { NewPendingAppointmentList } from './NewPendingAppointmentList'
import { NewQuantitySelector } from './NewQuantitySelector'

interface NewAppointmentFormProps {
  patient: BasicPatientFragment
  providerId: string
  isLoading: boolean
}

interface AppointmentSlotWithSkippedSlots extends AppointmentSlotFragment {
  skippedSlots?: AppointmentSlotFragment[]
}

export interface EditableAppointmentSlot {
  slot: AppointmentSlotWithSkippedSlots
  included: boolean
  newStart?: Moment
  newLocation?: string
}

export function NewAppointmentForm({ patient, providerId, isLoading }: NewAppointmentFormProps) {
  const [apptQuantity, setApptQuantity] = useState<number | null>(null)
  const {
    drawerConfig: { paymentRequired },
  } = useAppointmentDrawerSearchParams()

  const [fetchMultipleAppointmentsInfo, { data: appointmentSlotInfoData, loading: isLoadingAppointmentsInfo }] =
    useMultipleAppointmentsInfoLazyQuery()
  const setMultiSchedulerLocation = (selectedAddress: AppointmentLocationSelectValue) => {
    setFieldValue('multiAddressId', selectedAddress.id)
  }
  const { handleSubmit, values, setFieldValue } = useFormikContext<AppointmentFormValues>()
  const loading = isLoading || isLoadingAppointmentsInfo

  useEffect(() => {
    if (appointmentSlotInfoData) {
      const appointments: EditableAppointmentSlot[] =
        appointmentSlotInfoData.bookMultipleAppointmentsAsProviderInfo.suggestedSlots
          .filter(slot => !!slot)
          .map(slot => ({
            slot: slot as AppointmentSlotFragment,
            included: true,
            newLocation: values.multiAddressId,
          }))

      setFieldValue('appointments', appointments)
      setFieldValue('singleStartTime', undefined)
    }
    // we don't want to trigger this effect when multiAddressId changes as it causes a bug when hitting "nevermind"
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [appointmentSlotInfoData, setFieldValue])

  useEffect(() => {
    setFieldValue('force', paymentRequired === false)
  }, [paymentRequired, setFieldValue])

  return (
    <div style={{ overflow: 'auto' }}>
      <Content>
        {values.appointments.length > 0 ? (
          <NewPendingAppointmentList providerId={providerId} patient={patient} isLoading={loading} />
        ) : (
          <>
            <NewQuantitySelector
              patient={patient}
              value={apptQuantity}
              onChange={quantity => setApptQuantity(quantity)}
            />

            {apptQuantity === 1 && (
              <NewAppointmentSingleScheduler
                providerId={providerId}
                patient={patient}
                onSubmit={handleSubmit}
                isLoading={loading}
              />
            )}

            {apptQuantity && apptQuantity > 1 && (
              <NewAppointmentMultiScheduler
                desiredApptCount={apptQuantity}
                patient={patient}
                providerId={providerId}
                fetchMultipleAppointmentsInfo={fetchMultipleAppointmentsInfo}
                isLoadingAppointmentsInfo={loading}
                setMultiSchedulerLocation={setMultiSchedulerLocation}
              />
            )}
          </>
        )}
      </Content>
    </div>
  )
}

const Content = styled('div')`
  padding: 1rem;
`
