import { IconProps } from './IconProps'

export function IconCamera({ size = 24, color = 'currentColor', ...props }: IconProps) {
  const height = (31 / 38) * size

  return (
    <svg width={size} height={height} viewBox="0 0 38 31" xmlns="http://www.w3.org/2000/svg" fill={color} {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.31754 30.1416C1.69656 30.1416 0.377441 28.8086 0.377441 27.1706V7.36362C0.377441 5.72558 1.69656 4.39257 3.31754 4.39257H6.25763C7.07106 4.39257 8.33334 3.86373 8.9096 3.2814L10.0602 2.12071C11.0147 1.15611 12.7513 0.429199 14.0999 0.429199H23.9002C25.2487 0.429199 26.9853 1.15611 27.9379 2.12071L29.0885 3.2814C29.6628 3.86175 30.929 4.39257 31.7424 4.39257H34.6825C36.3035 4.39257 37.6226 5.72558 37.6226 7.36362V27.1706C37.6226 28.8086 36.3035 30.1416 34.6825 30.1416H34.6786H3.31754ZM2.33751 7.36362V27.1706C2.33751 27.7173 2.77656 28.1609 3.31754 28.1609H34.6786C35.2195 28.1609 35.6586 27.7173 35.6586 27.1706V7.36362C35.6586 6.81694 35.2195 6.37327 34.6786 6.37327H31.7385C30.388 6.37327 28.6533 5.64635 27.6988 4.68373L26.5482 3.51908C25.9739 2.94072 24.7097 2.40989 23.8963 2.40989H14.0959C13.2825 2.40989 12.0183 2.93874 11.44 3.51908L10.2934 4.68373C9.33886 5.64635 7.60224 6.37327 6.25371 6.37327H3.31362C2.77656 6.37327 2.33751 6.81694 2.33751 7.36362ZM10.1797 15.2854C10.1797 10.3693 14.1351 6.37228 19 6.37228C23.8629 6.37228 27.8203 10.3693 27.8203 15.2854C27.8203 20.1995 23.8629 24.1985 19 24.1985C14.1351 24.1985 10.1797 20.1995 10.1797 15.2854ZM12.1398 15.2854C12.1398 19.1082 15.2171 22.2178 19 22.2178C22.7829 22.2178 25.8602 19.1082 25.8602 15.2854C25.8602 11.4627 22.7829 8.35298 19 8.35298C15.2171 8.35298 12.1398 11.4627 12.1398 15.2854Z"
      />
    </svg>
  )
}
