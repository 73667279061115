import { useState } from 'react'

import {
  ProviderCredentialingFlowStatus,
  useProviderCompensationRatesQuery,
  useProviderCredentialingFlowStatusQuery,
  useProviderDirectoriesQuery,
} from '@nuna/api'
import { GhostButton, GhostButtonLink, IconCaution, plumSet, salmonSet } from '@nuna/tunic'

import { DirectoriesContextProvider } from './DirectoriesContext'
import { DirectoryDrawer } from './DirectoryDrawer'
import { JoinedDirectoriesTable } from './JoinedDirectoriesTable'
import { RecommendedDirectoryTable } from './RecommendedTable'
import { WhatToKnowModal } from './WhatToKnowModal'
import { RECOMMENDED_DIRECTORIES } from './utils'

type Props = {
  providerId: string
}

export function DirectoriesTableContent({ providerId }: Props) {
  const [isModalVisible, setIsModalVisible] = useState(false)
  const { data } = useProviderDirectoriesQuery({
    variables: { providerId },
    fetchPolicy: 'cache-and-network',
  })

  const { data: payoutData } = useProviderCompensationRatesQuery({
    variables: { providerId },
  })

  const sessionRate = payoutData?.providerCompensationRates.tavaCompensations.find(
    compensation => compensation.cptCode?.code === '90837',
  )
  const providerDirectories = data?.providerDirectories ?? []

  const { data: credentialingFlowStatus } = useProviderCredentialingFlowStatusQuery()

  const isEnrolledInInsurance =
    credentialingFlowStatus?.providerMe.credentialingFlowStatus === ProviderCredentialingFlowStatus.Completed

  const hasDirectoryReferrals = providerDirectories.some(directory => directory.enabled)

  const closeModal = () => setIsModalVisible(false)

  return (
    <>
      <div className="mt-5">
        <div className="v-align space-between">
          <div>
            <p className="large v-align mb-0 pb-0">
              <span className="text-medium">Directory referrals {hasDirectoryReferrals ? 'ON' : 'OFF'}</span>
              {!isEnrolledInInsurance && (
                <>
                  {' '}
                  -{' '}
                  <span className="text-medium top-align" style={{ color: salmonSet[80].hex }}>
                    <IconCaution className="mt-1 mr-1" size={28} color={plumSet[50].hex} />
                    <span>
                      <GhostButtonLink className="large" variant="destroy" to="/credentialing-setup">
                        Insurance enrollment
                      </GhostButtonLink>{' '}
                      is required first
                    </span>
                  </span>
                </>
              )}
            </p>
          </div>
          <div>
            <GhostButton onClick={() => setIsModalVisible(true)} variant={'secondary'}>
              What to know
            </GhostButton>
          </div>
        </div>

        <div className="mt-3">
          <JoinedDirectoriesTable
            providerId={providerId}
            directoryData={providerDirectories}
            disabled={!isEnrolledInInsurance}
            sessionRate={sessionRate?.rate ?? 0}
          />
          {providerDirectories.length !== Object.values(RECOMMENDED_DIRECTORIES).length && (
            <RecommendedDirectoryTable
              disabled={!isEnrolledInInsurance}
              joinedDirectoryTypes={providerDirectories.map(directory => directory.directoryType) ?? []}
              sessionRate={sessionRate?.rate ?? 0}
            />
          )}
          <DirectoryDrawer
            directoryData={providerDirectories}
            providerId={providerId}
            sessionRate={sessionRate?.rate ?? 0}
          />
        </div>
      </div>
      <WhatToKnowModal isOpen={isModalVisible} onClose={closeModal} />
    </>
  )
}

export function DirectoriesTable(props: Props) {
  return (
    <DirectoriesContextProvider>
      <DirectoriesTableContent {...props} />
    </DirectoriesContextProvider>
  )
}
