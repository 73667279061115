import { css } from '@mui/material'

import { greySet, tealSet } from '../colorSets'

export const materialUIStyles = css`
  html {
    .MuiIconButton-root:hover {
      background-color: ${greySet[5].hex};
    }

    .MuiPickersDay-daySelected {
      border-radius: 4px;
      background-color: ${tealSet[30].hex};

      &:hover {
        background-color: ${tealSet[30].hex};
      }
    }
  }
`
