import { styled } from '@mui/material'
import { groupBy } from 'lodash'
import moment from 'moment'
import { Fragment } from 'react'

import { PublicAvailabilitySlot } from '@nuna/api'
import { useAppointmentDrawerSearchParams } from '@nuna/common'
import { appointmentService } from '@nuna/core'
import { eggshell, greySet, tealSet } from '@nuna/tunic'

interface TimeslotsProps {
  timeslots: PublicAvailabilitySlot[]
  onSlotClick: (slot: PublicAvailabilitySlot) => void
  dayFormat?: string
}

export function RescheduleTimeslots({ timeslots, onSlotClick, dayFormat = 'dddd, MMMM Do' }: TimeslotsProps) {
  const groupedSlots = groupBy(appointmentService.sortTimeSlots(timeslots), slot =>
    moment(slot.start).format(dayFormat),
  )

  const {
    drawerConfig: { timeSlot },
  } = useAppointmentDrawerSearchParams()

  return (
    <div className="mb-4">
      {Object.entries(groupedSlots).map(([day, slots]) => (
        <Fragment key={day}>
          <TimeSlotGrid>
            {slots.map(slot => {
              const isSelected = timeSlot && moment(timeSlot.start).isSame(slot.start)
              return (
                <TimeSlotButton
                  key={slot.start}
                  onClick={() => onSlotClick(slot)}
                  isSelected={isSelected}
                  data-testid="schedule-timeslot"
                >
                  <time dateTime={slot.start}>{moment(slot.start).format('h:mma')}</time>
                </TimeSlotButton>
              )
            })}
          </TimeSlotGrid>
        </Fragment>
      ))}
    </div>
  )
}

const TimeSlotButton = styled('button')<{ isSelected?: boolean }>`
  color: ${tealSet[90].hex};
  background-color: ${eggshell};
  border-radius: var(--border-radius-sm);
  padding: 0.75rem;
  border: 2px solid transparent;

  &:hover {
    background-color: ${greySet[5].hex};
  }

  ${({ isSelected }) =>
    isSelected &&
    `
    background-color: ${tealSet.tint[20]};
    outline: 4px solid ${tealSet.tint[20]};
    border: 2px solid ${tealSet.tint[40]};
    &:hover {
      background-color: ${tealSet.tint[20]};
  `}
`

const TimeSlotGrid = styled('div')`
  display: grid;
  gap: 0.5rem;
  grid-template-columns: 1fr 1fr 1fr;
  margin-bottom: var(--margin-1);
`
