import { styled } from '@mui/material'
import { pick } from 'lodash'
import moment, { Moment } from 'moment'
import { Fragment, useMemo } from 'react'

import {
  AppointmentResponsibleParty,
  FinancialAttribution,
  ProviderPayout,
  SessionFinancialReason,
  useProviderPayoutDetailsQuery,
  useProviderPayrollDetailsQuery,
} from '@nuna/api'
import { Link } from '@nuna/common'
import { numberService } from '@nuna/core'
import { DataTable, DataTableColumn, DefaultTableRow } from '@nuna/data-table'
import {
  Avatar,
  GhostButton,
  GhostButtonLink,
  Grid,
  IconCheck,
  IconChevronThick,
  IconHorizontalReport,
  IconInfo,
  OutlineButton,
  PageContent,
  Print,
  Skeleton,
  Tooltip,
  eggshell,
  greySet,
  tealSet,
  yellowSet,
} from '@nuna/tunic'

import { payrollSummary, sessionIndex } from '../../util/routes'
import { SessionIndexTabs } from '../Sessions/SessionIndex'
import { unpaidParam, upcomingParam } from './Payroll'
import { useUpcomingPayoutsContext } from './UpcomingPayoutsContext'
import exclamation from './img/exclamation.png'

const { formatCurrency, centsToFormattedDollars } = numberService

interface SessionFinancialRow {
  patientName: string
  patientAvatarUrl?: string | null
  startDatetime: Moment
  reason: SessionFinancialReason
  providerCompensatedAmount: string
  responsibleParty?: AppointmentResponsibleParty | null
  cptCodeDescription?: string | null
  note?: string | null
  financialAttribution?: FinancialAttribution | null
}

function buildColumns(): DataTableColumn<SessionFinancialRow>[] {
  const columns: DataTableColumn<SessionFinancialRow>[] = [
    {
      Header: 'Client',
      accessor: 'patientName',
      Cell: ({ row, value }) => (
        <div className="v-align">
          <Avatar className="mr-1" url={row.original.patientAvatarUrl} size="xs" /> {value}
        </div>
      ),
    },
    {
      Header: 'Session Date',
      accessor: 'startDatetime',
      Cell: ({ value }) => <>{value.format('MMM D, YYYY')}</>,
    },
    {
      id: 'time',
      Header: 'Start Time',
      accessor: 'startDatetime',
      Cell: ({ value }) => <>{value.format('h:mm a')}</>,
    },
    {
      Header: 'Session Type',
      accessor: 'cptCodeDescription',
      style: { width: 160 },
      Cell: ({ value, row }) => {
        switch (row.original.reason) {
          case SessionFinancialReason.SessionCompleted:
            return <span>{value}</span>
          case SessionFinancialReason.NoShowByPatient:
            return <span>No-Show</span>
          case SessionFinancialReason.AdminAdjustment:
            return <span>Admin Adjustment</span>
          default:
            return <span>Late Cancelation</span>
        }
      },
    },
    {
      Header: 'Rate',
      accessor: 'providerCompensatedAmount',
      Cell: ({ value }) => <span className="text-teal">{value}</span>,
    },
    {
      Header: 'Coverage Type',
      accessor: 'responsibleParty',
      Cell: ({ value }) => {
        switch (value) {
          case AppointmentResponsibleParty.Client:
            return <>Out-of-Pocket</>
          case AppointmentResponsibleParty.Company:
            return <>Employer Benefit</>
          case AppointmentResponsibleParty.Insurance:
            return <>Insurance</>
          default:
            return <>Sponsored</>
        }
      },
    },
  ]

  columns.splice(4, 0, {
    Header: 'Therapist Referral',
    accessor: 'financialAttribution',
    className: 'center-align',
    style: { width: 180 },
    // eslint-disable-next-line react/jsx-no-useless-fragment
    Cell: ({ value }) => <>{value === FinancialAttribution.Provider ? <IconCheck color={greySet[50].hex} /> : ''}</>,
  })

  return columns
}

interface PayrollDetailProps {
  providerId: string
  payment?: ProviderPayout
  hasPrevious: boolean
  hasNext: boolean
  onPrevious: () => void
  onNext: () => void
  incompleteSessions?: number
  detailId: string
}

export function PayrollDetail(props: PayrollDetailProps) {
  return props.payment ? (
    <PaymentPayrollDetail {...props} />
  ) : props.detailId === upcomingParam ? (
    <UpcomingPayrollDetail {...props} />
  ) : (
    <UnpaidPayrollDetail {...props} />
  )
}

function PaymentPayrollDetail(props: PayrollDetailProps) {
  const { loading, data } = useProviderPayoutDetailsQuery({
    variables: { providerId: props.providerId, payrollId: props.payment?.payrollId || '' },
  })

  const rowData = useMemo(
    () =>
      data?.providerPayoutDetails.map(detail => ({
        ...pick(detail, ['reason', 'responsibleParty', 'cptCodeDescription', 'note']),
        ...pick(detail.appointmentInfoSummary, ['patientName', 'patientAvatarUrl', 'financialAttribution']),
        startDatetime: moment(detail.appointmentInfoSummary.startDatetime),
        providerCompensatedAmount: centsToFormattedDollars(detail.providerCompensatedAmount, {
          maximumFractionDigits: 2,
        }),
      })),
    [data],
  )

  return <Detail rowData={rowData} loading={loading} {...props} />
}

function UpcomingPayrollDetail(props: PayrollDetailProps) {
  const { payrollStartDate, payrollEndDate, incompleteSessions } = useUpcomingPayoutsContext()
  const { loading, data } = useProviderPayrollDetailsQuery({
    variables: { providerId: props.providerId, startDate: payrollStartDate, endDate: payrollEndDate },
  })

  const rowData = useMemo(
    () =>
      data?.payrollDetails.map(detail => ({
        ...pick(detail, ['reason', 'responsibleParty', 'cptCodeDescription', 'note']),
        ...pick(detail.appointmentInfoSummary, ['patientName', 'patientAvatarUrl', 'financialAttribution']),
        startDatetime: moment(detail.appointmentInfoSummary.startDatetime),
        providerCompensatedAmount: `~${centsToFormattedDollars(detail.providerCompensatedAmount, {
          maximumFractionDigits: 2,
        })}`,
      })),
    [data],
  )

  return <Detail rowData={rowData} loading={loading} incompleteSessions={incompleteSessions} {...props} />
}

// This is possible payments previous to the current payroll.  It shows up at the beginning of a new
// pay period, but before payroll is scheduled
function UnpaidPayrollDetail(props: PayrollDetailProps) {
  const { unpaidEndDate, incompleteSessions } = useUpcomingPayoutsContext()
  const { loading, data } = useProviderPayrollDetailsQuery({
    variables: { providerId: props.providerId, endDate: unpaidEndDate },
  })

  const rowData = useMemo(
    () =>
      data?.payrollDetails.map(detail => ({
        ...pick(detail, ['reason', 'responsibleParty', 'cptCodeDescription', 'note']),
        ...pick(detail.appointmentInfoSummary, ['patientName', 'patientAvatarUrl']),
        startDatetime: moment(detail.appointmentInfoSummary.startDatetime),
        providerCompensatedAmount: `~${centsToFormattedDollars(detail.providerCompensatedAmount, {
          maximumFractionDigits: 2,
        })}`,
      })),
    [data],
  )

  return <Detail rowData={rowData} loading={loading} incompleteSessions={incompleteSessions} {...props} />
}

function Detail(props: PayrollDetailProps & { rowData?: SessionFinancialRow[]; loading: boolean }) {
  const { rowData, loading, payment, hasPrevious, hasNext, onPrevious, onNext, incompleteSessions } = props

  const columns = useMemo(() => buildColumns(), [])

  const {
    payrollStartDate,
    payrollEndDate,
    expectedPayDate,
    payrollSummaryTotal,
    unpaidSummaryTotal,
    unpaidPayDate,
    unpaidEndDate,
  } = useUpcomingPayoutsContext()

  const payrollStart = payment ? moment(payment.payrollStartDate) : payrollStartDate
  const hasCatchupPayments = !!payment && rowData?.some(p => p.startDatetime.isBefore(payrollStart))

  const payday = payment
    ? moment(payment?.payday, 'YYYY-MM-DD').format('MMM D, YYYY')
    : props.detailId === upcomingParam
    ? expectedPayDate
    : unpaidPayDate

  const payPeriod =
    props.detailId === unpaidParam
      ? `${moment(unpaidEndDate)
          .date(unpaidEndDate.date() > 15 ? 16 : 1)
          .format('MMM D')} - ${unpaidEndDate.format('MMM D, YYYY')}`
      : `${moment(payment?.payrollStartDate || payrollStartDate).format('MMM D')} - ${moment(
          payment?.payrollEndDate || payrollEndDate,
        ).format('MMM D, YYYY')}`

  return (
    <GreyPageContent>
      {loading ? (
        <Skeleton className="mt-2" height={10} style={{ width: '100%' }} />
      ) : (
        <Container className="mt-2">
          <Grid container alignItems="center">
            <NonPrintableGrid
              size={{
                md: payment ? 3 : 6,
              }}
              className="v-align"
              style={{
                paddingLeft: 'var(--margin-2)',
              }}
            >
              <Link to={payrollSummary()} className="text-medium text-secondary v-align">
                <IconHorizontalReport className="mr-1" /> All Payouts
              </Link>
            </NonPrintableGrid>
            {!!payment && (
              <NonPrintableGrid size={{ md: 6 }} className="vh-align">
                <GhostButton variant="secondary" disabled={!hasPrevious} onClick={onPrevious}>
                  <IconChevronThick size={18} /> Prev Period
                </GhostButton>
                <div style={{ padding: 16, color: greySet[30].hex }}>|</div>
                <GhostButton variant="secondary" disabled={!hasNext} onClick={onNext}>
                  Next Period <IconChevronThick direction="right" size={18} />
                </GhostButton>
              </NonPrintableGrid>
            )}
            <NonPrintableGrid
              size={{
                md: payment ? 3 : 6,
              }}
              className="text-right"
              style={{ paddingRight: 'var(--margin-2)' }}
            >
              <OutlineButton
                variant="secondary"
                onClick={() => {
                  // Workaround for the following Safari print bug: https://bugs.webkit.org/show_bug.cgi?id=195769
                  try {
                    if (!document.execCommand('print')) throw new Error()
                  } catch (error) {
                    window.print()
                  }
                }}
              >
                Print Payout
              </OutlineButton>
            </NonPrintableGrid>
          </Grid>
          <DetailContainer>
            <Grid container alignItems="center" className="mb-3">
              <Grid
                size={{
                  xs: 6,
                  md: 3,
                }}
                style={{ alignItems: 'center' }}
              >
                {hasCatchupPayments && (
                  <h2 className="h6 mb-4 v-align">
                    Catchup Payment
                    <Tooltip content="Now that your bank information is up to date, this payment catches up all previous session.">
                      <span className="v-align">
                        <IconInfo className="ml-1" color={greySet[30].hex} />
                      </span>
                    </Tooltip>
                  </h2>
                )}
                {!payment && (
                  <h2 className="h6 mb-4 v-align">
                    Estimated Payment
                    <Tooltip content="This is only an estimate of your expected payout.  Actual pay may vary on your payment date.">
                      <span className="v-align">
                        <IconInfo className="ml-1" color={greySet[30].hex} />
                      </span>
                    </Tooltip>
                  </h2>
                )}
                {!hasCatchupPayments && !!payment && <h2 className="h6 mb-4 v-align">Standard Payment</h2>}
              </Grid>
              <Grid
                size={{
                  xs: 6,
                  md: 3,
                }}
                style={{ alignItems: 'center' }}
              >
                <div className="caption text-medium text-secondary">{!payment && 'Expected '}Pay Date</div>
                <div className="display text-light" style={{ color: tealSet[80].hex }}>
                  {payday}
                </div>
              </Grid>
              <Grid
                size={{
                  xs: 6,
                  md: 3,
                }}
                style={{ alignItems: 'center' }}
              >
                <div className="caption text-medium text-secondary">Pay Period</div>
                <div className="display text-light" style={{ color: tealSet[80].hex }}>
                  {payPeriod}
                </div>
              </Grid>
              <Grid
                size={{
                  xs: 6,
                  md: 3,
                }}
                style={{ alignItems: 'center' }}
              >
                <TotalPaid estimated={!payment}>
                  <div className="caption text-medium" style={{ color: payment ? tealSet[90].hex : greySet[70].hex }}>
                    {payment ? 'Total Paid' : 'Expected Payment'}
                  </div>
                  <div className="display text-light" style={{ color: payment ? tealSet[80].hex : greySet[70].hex }}>
                    {payment
                      ? formatCurrency(payment?.amount || 0, { maximumFractionDigits: 2 })
                      : centsToFormattedDollars(
                          props.detailId === upcomingParam ? payrollSummaryTotal : unpaidSummaryTotal,
                          { maximumFractionDigits: 2 },
                        )}
                  </div>
                </TotalPaid>
              </Grid>
            </Grid>
            <DataTable
              columns={columns}
              rowData={rowData}
              loading={loading}
              customRow={rowProps => {
                const { row, ...otherProps } = rowProps
                const cells = row.cells.filter(cell => cell.column.id !== 'id')
                return (
                  <Fragment key={row.id}>
                    <DefaultTableRow
                      customCellStyle={row => (row.original.note ? { padding: '11px 11px 0 11px' } : {})}
                      {...rowProps}
                    />
                    {row.original.note && (
                      <tr {...otherProps}>
                        <td colSpan={3} style={{ padding: '0 11px 11px 11px' }}></td>
                        <td colSpan={cells.length - 3} style={{ padding: '0 11px 11px 11px' }}>
                          <span className="caption italic">{row.original.note}</span>
                        </td>
                      </tr>
                    )}
                  </Fragment>
                )
              }}
            />
            {!payment && !!incompleteSessions && (
              <Warning className="text-yellow v-align mt-2">
                <span className="v-align">
                  <img src={exclamation} alt="warning" />
                  {incompleteSessions}{' '}
                  {incompleteSessions > 1
                    ? 'sessions are missing notes and have not been calculated in this estimate yet.'
                    : 'session is missing notes and has not been calculated in this estimate yet.'}
                </span>
                <GhostButtonLink
                  className="ml-2 body text-medium"
                  to={sessionIndex(SessionIndexTabs.PREVIOUS)}
                  variant="secondary"
                >
                  Record Notes
                </GhostButtonLink>
              </Warning>
            )}
          </DetailContainer>
        </Container>
      )}
    </GreyPageContent>
  )
}

const GreyPageContent = styled(PageContent)`
  background-color: ${greySet[5].hex};
  padding: 0;
`

const Container = styled('div')`
  display: flex;
  flex-direction: column;
  height: 100%;
`

const DetailContainer = styled('div')`
  margin: var(--margin-2) var(--margin-2) 0 var(--margin-2);
  padding: var(--margin-3);
  border: 1px solid ${greySet[15].hex};
  background-color: white;
  border-radius: var(--border-radius) var(--border-radius) 0 0;
  box-shadow: 0px 40px 60px -20px rgba(36, 28, 19, 0.12);
  flex: 1 1 100%;
`

const TotalPaid = styled('div')<{ estimated: boolean }>`
  padding: var(--margin-2) var(--margin-3);
  text-align: right;
  background-color: ${({ estimated }) => (estimated ? eggshell : tealSet.tint[20])};
  border: 1px solid ${({ estimated }) => (estimated ? greySet.tint[40] : tealSet.tint[40])};
  border-radius: 6px;
`

const NonPrintableGrid = styled(Grid)`
  @media ${Print} {
    display: none;
  }
`

const Warning = styled('div')`
  border: 1px solid ${yellowSet[80].hex};
  border-radius: 6px;
  padding: var(--margin-1) var(--margin-2);
  background-color: ${yellowSet.tint[20]};
  color: ${yellowSet[80].hex};
  justify-content: space-between;
`
