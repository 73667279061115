import { IconProps } from './IconProps'

export function IconFileText({ size = 24, color = 'currentColor', ...props }: IconProps) {
  return (
    <svg width={size} height={size} viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.31027 5.72027C6.31027 4.93815 6.95752 4.29613 7.74041 4.29613H16.041C16.4439 4.29613 17.1394 4.58832 17.4083 4.86498L24.1206 11.7706C24.4079 12.0662 24.6897 12.7568 24.6897 13.1669V26.2761C24.6897 27.065 24.0498 27.7039 23.2663 27.7039H7.73367C6.95025 27.7039 6.31027 27.0667 6.31027 26.2893V23.1994V9.70329V5.72027ZM4 23.1994V26.2893C4 28.334 5.67344 30 7.73367 30H23.2663C25.3281 30 27 28.3308 27 26.2761V13.1669C27 12.1605 26.4849 10.8982 25.7821 10.1752L19.0699 3.26964C18.3648 2.54426 17.0692 2 16.041 2H7.74041C5.68261 2 4 3.66902 4 5.72027V9.70329V23.1994ZM21.1152 24.3554C21.5405 24.3554 21.8853 24.0127 21.8853 23.59C21.8853 23.1673 21.5405 22.8246 21.1152 22.8246H9.56389C9.13859 22.8246 8.79381 23.1673 8.79381 23.59C8.79381 24.0127 9.13859 24.3554 9.56389 24.3554H21.1152ZM21.1152 19.9544C21.5405 19.9544 21.8853 19.6118 21.8853 19.1891C21.8853 18.7664 21.5405 18.4237 21.1152 18.4237H9.56389C9.13859 18.4237 8.79381 18.7664 8.79381 19.1891C8.79381 19.6118 9.13859 19.9544 9.56389 19.9544H21.1152ZM17.1408 15.5535C17.5661 15.5535 17.9109 15.2109 17.9109 14.7882C17.9109 14.3654 17.5661 14.0228 17.1408 14.0228H9.59598C9.17067 14.0228 8.82589 14.3654 8.82589 14.7882C8.82589 15.2109 9.17067 15.5535 9.59598 15.5535H17.1408Z"
        fill={color}
      />
    </svg>
  )
}
