/* eslint-disable @typescript-eslint/no-explicit-any */
import { last } from 'lodash'
import moment from 'moment'
import { useMemo } from 'react'
import { TiArrowSortedDown, TiArrowSortedUp } from 'react-icons/ti'
import { useGlobalFilter, useSortBy, useTable } from 'react-table'

import { BasicPatientFragment, BasicProviderFragment, ConversationForAppointmentDetailFragment } from '@nuna/api'
import { Avatar, csx } from '@nuna/tunic'

import { DataTable } from '../../shared/DataTable'
import { SubtleParagraph } from '../../shared/Typography'

interface Props {
  messages: ConversationForAppointmentDetailFragment[]
  provider: BasicProviderFragment
  patient: BasicPatientFragment
}

export function AppointmentConversationTable({ patient, provider, messages }: Props) {
  const colData = useMemo(
    () => [
      {
        Header: 'Time',
        accessor: 'dateCreated',
        id: 'date',
        Cell: ({ row }: { row: any }) => moment(row.original.dateCreated).format('YYYY/MM/DD LTS'),
      },
      {
        Header: 'Time Since First Message',
        accessor: 'offset',
        Cell: ({ row }: { row: any }) => {
          const duration = moment.duration(row.original.offset, 'seconds')
          const l = `${duration.minutes()} m${duration.seconds()}s`
          return l
        },
      },
      {
        Header: 'Name',
        accessor: 'authorFullName',
      },
      {
        Header: 'Role',
        accessor: 'authorRole',
      },
      {
        Header: 'Message',
        accessor: 'body',
      },
    ],
    [],
  )

  const memoizedRowData = useMemo(() => {
    const sortedMessages = [...messages].sort((a, b) => moment(b.dateCreated).unix() - moment(a.dateCreated).unix())
    const lastMessage = last(sortedMessages)

    return (sortedMessages || []).map(({ authorFullName, authorId, authorRole, body, dateCreated, id }) => {
      return {
        authorFullName: (
          <div className={csx(['v-align', { 'fs-exclude': authorRole === 'Client' }])}>
            <Avatar
              className="mr-1"
              size="mini"
              url={authorRole === 'provider' ? provider.avatarUrl : patient.avatarUrl}
            />
            {authorFullName}
          </div>
        ),
        authorId,
        authorRole: authorRole === 'provider' ? 'Provider' : 'Client',
        body: <span className="fs-exclude">{body}</span>,
        dateCreated,
        offset: moment(dateCreated).diff(moment(lastMessage?.dateCreated), 'seconds'),
        id,
      }
    })
  }, [messages, patient, provider])

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } = useTable(
    {
      data: memoizedRowData,
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      columns: colData,
      initialState: {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        sortBy: [
          {
            id: 'date',
            desc: false,
          },
        ],
      },
    },
    useGlobalFilter,
    useSortBy,
  )

  // destructering to get className out of props to satisfy
  // DataTable, which already has className defined
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { className, ...tableProps } = getTableProps()

  return (
    <>
      {!messages.length && <SubtleParagraph>No messages</SubtleParagraph>}
      {!!messages.length && (
        <div>
          <DataTable {...tableProps} style={{ width: '100%' }} cellPadding="1rem">
            <thead>
              {headerGroups.map((headerGroup: any) => (
                <tr {...headerGroup.getHeaderGroupProps()}>
                  {headerGroup.headers.map((column: any) => (
                    <th {...column.getHeaderProps(column.getSortByToggleProps())}>
                      <span>{column.render('Header')}</span>
                      <span>
                        {column.isSorted ? (
                          column.isSortedDesc ? (
                            <TiArrowSortedUp
                              style={{ fontSize: '1.1rem', marginBottom: '-.3rem', marginLeft: '.4rem' }}
                            />
                          ) : (
                            <TiArrowSortedDown
                              style={{ fontSize: '1.1rem', marginBottom: '-.3rem', marginLeft: '.4rem' }}
                            />
                          )
                        ) : (
                          <span style={{ width: '.4rem' }} />
                        )}
                      </span>
                    </th>
                  ))}
                </tr>
              ))}
            </thead>
            <tbody {...getTableBodyProps()}>
              {rows.map((row: any) => {
                prepareRow(row)
                return (
                  <tr {...row.getRowProps()}>
                    {row.cells.map((cell: any) => {
                      return (
                        <td style={{ verticalAlign: 'middle' }} {...cell.getCellProps()}>
                          {cell.render('Cell')}
                        </td>
                      )
                    })}
                  </tr>
                )
              })}
            </tbody>
          </DataTable>
        </div>
      )}
    </>
  )
}
