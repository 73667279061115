import { IconRotatableProps } from './IconProps'
import { getIconRotation } from './iconUtils'

// Copied from TiArrowSortedUp
export function IconSortArrow({
  direction = 'up',
  size = 24,
  color = 'currentColor',
  style = {},
  ...props
}: IconRotatableProps) {
  const rotation = getIconRotation(direction, 'up')

  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={{ transform: rotation, ...style }}
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M18.2 13.3l-6.2-6.3-6.2 6.3c-.2.2-.3.5-.3.7s.1.5.3.7c.2.2.4.3.7.3h11c.3 0 .5-.1.7-.3.2-.2.3-.5.3-.7s-.1-.5-.3-.7z"
        fill={color}
      />
    </svg>
  )
}
