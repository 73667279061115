import { DailyProvider, useCallObject, useDaily, useDevices, useMeetingState } from '@daily-co/daily-react'
import { styled } from '@mui/material'
import mixpanel from 'mixpanel-browser'
import { useCallback, useEffect, useState } from 'react'
import Div100vh from 'react-div-100vh'

import { supportService } from '@nuna/telemetry'
import { BelowTablet, toast } from '@nuna/tunic'

import { Call } from './components/Call'
import { DeviceError } from './components/DeviceError'
import { DevicePermissions } from './components/DevicePermissions'
import { HairCheck } from './components/HairCheck'
import { MainVideoWindow } from './components/MainVideoWindow'
import { PostSessionSurvey } from './components/PostSessionSurvey'
import { Sidebar } from './components/Sidebar'
import { Topbar } from './components/Topbar'
import { VideoCallContextProvider, useVideoCallContext } from './context/VideoCallContext'
import { VideoChatContextProvider } from './context/VideoChatContext'
import { useCpuLogging } from './hooks/useCpuLogging'
import { useDebugLogging } from './hooks/useDebugLogging'
import { useKnockLogging } from './hooks/useKnockLogging'
import { useNetworkQualityBroadcast } from './hooks/useNetworkQualityBroadcast'
import { TavaVideoUser } from './types'
import { videoSceneDark } from './util/colors'

interface Props {
  appointmentId: string
  roomUrl: string
  videoToken?: string
  chatToken: string
  localUser: TavaVideoUser
  expectedUsers: TavaVideoUser[]
}

export function DailyVideoScene({ videoToken, chatToken, appointmentId, localUser, expectedUsers, roomUrl }: Props) {
  const tokenOptions = videoToken ? { token: videoToken } : {}

  const callObject = useCallObject({
    options: { ...tokenOptions, url: roomUrl },
  })

  return (
    <DailyProvider callObject={callObject}>
      <VideoCallContextProvider
        appointmentId={appointmentId}
        localUser={localUser}
        expectedUsers={expectedUsers}
        roomUrl={roomUrl}
      >
        <VideoChatContextProvider appointmentId={appointmentId} token={chatToken}>
          <style>{supportService.HIDE_LAUNCHER_CSS}</style>
          <VideoScene />
        </VideoChatContextProvider>
      </VideoCallContextProvider>
    </DailyProvider>
  )
}

function VideoScene() {
  const { isSidebarOpen, isSessionEnded, roomUrl } = useVideoCallContext()
  const callObject = useDaily()
  const { hasMicError, microphones, cameras } = useDevices()
  const meetingState = useMeetingState()
  useNetworkQualityBroadcast()
  useCpuLogging()
  useKnockLogging()
  useDebugLogging()

  const [isInitialized, setIsInitialized] = useState(false)

  const cleanupCall = useCallback(() => {
    if (!callObject) return

    console.info('[Daily Lifecycle] Cleaning up call')
    // wait until leave is finished before destroying
    callObject.leave().then(() => callObject.destroy())
  }, [callObject])

  // cleans up if user navigates away w/in react router
  useEffect(() => {
    return cleanupCall
  }, [cleanupCall])

  // cleans up if user navigates using browser navigation
  useEffect(() => {
    window.addEventListener('beforeunload', cleanupCall)
    return () => window.removeEventListener('beforeunload', cleanupCall)
  }, [cleanupCall])

  useEffect(() => {
    // preAuth allows us to get a list of devices so we can determine if we need to prompt for permissions
    console.info('[Daily Lifecycle] Attempting to preauth')

    callObject
      ?.preAuth({ url: roomUrl })
      .then(() => {
        setIsInitialized(true)
        console.info('[Daily Lifecycle] Successfully preauthed')
      })
      .catch(e => {
        console.error('Preauth error', e)
        toast.urgent('There was an error connecting to the video call. Please try reloading the page.')
        mixpanel.track('Daily preauth error', { error: e })
      })
  }, [callObject, roomUrl])

  const hasPermissions = microphones.length + cameras.length > 0

  return (
    <Background>
      <Topbar />

      <VideoSidebarContainer $sidebarOpen={isSidebarOpen}>
        <MainVideoWindow>
          {(() => {
            if (!isInitialized) {
              return null
            }

            if (isSessionEnded) {
              return <PostSessionSurvey />
            }

            if (hasMicError) {
              return <DeviceError />
            }

            if (!hasPermissions) {
              return <DevicePermissions />
            }

            if (meetingState !== 'joined-meeting') {
              return <HairCheck />
            }

            if (meetingState === 'joined-meeting') {
              return <Call />
            }
          })()}
        </MainVideoWindow>

        <Sidebar />
      </VideoSidebarContainer>
    </Background>
  )
}

const Background = styled(Div100vh)`
  background-color: ${videoSceneDark};
  padding: 1rem 0 1rem 1rem;
  width: 100vw;
  display: flex;
  flex-direction: column;

  @media (${BelowTablet}) {
    overflow: hidden;
  }
`

const VideoSidebarContainer = styled('div')<{ $sidebarOpen: boolean }>`
  align-items: center;
  display: flex;
  height: 100%;
  justify-content: stretch;
  overflow: hidden;

  @media (${BelowTablet}) {
    ${props =>
      props.$sidebarOpen &&
      `
      transform: translateX(-50%);
    `};
    transition: transform 0.3s;
    width: 200%;
  }
`
