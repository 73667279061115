import { styled } from '@mui/material'
import { ReactElement, useCallback, useMemo } from 'react'
import { Row } from 'react-table'

import {
  ProviderDirectory,
  ProviderDirectoryStatus,
  ProviderDirectoryType,
  useSaveProviderDirectoriesMutation,
} from '@nuna/api'
import { numberService } from '@nuna/core'
import { DataTable, DataTableColumn } from '@nuna/data-table'
import { BelowTablet, IconCaution, Switch, TextButton, envelopeSparkle, plumSet, toast } from '@nuna/tunic'

import { useDirectoriesContext } from './DirectoriesContext'
import { DirectoryLogo } from './DirectoryLogo'
import { formatType } from './utils'

type RecommendedDirectoryTableRow = {
  id: string
  name: string
  directoryType: ProviderDirectoryType
  status: ProviderDirectoryStatus
  clients: number
  active: boolean
}

export function JoinedDirectoriesTable({
  directoryData,
  providerId,
  disabled,
  sessionRate,
}: {
  directoryData: ProviderDirectory[] | undefined
  providerId: string
  disabled: boolean
  sessionRate: number
}) {
  const [saveProviderDirectories] = useSaveProviderDirectoriesMutation()
  const { handleDirectoryDrawerClick } = useDirectoriesContext()

  const handleEnableClick = useCallback(
    async (directoryId: string, enabled: boolean) => {
      try {
        const response = await saveProviderDirectories({
          variables: { directories: [{ id: directoryId, enabled }], providerId },
        })
        if (response.errors) {
          throw new Error('Response had errors')
        }
        toast.success(`Directory ${enabled ? 'enabled' : 'disabled'}`)
      } catch (e) {
        console.error(e) // the console.error helps with debugging
        toast.urgent(`Failed to ${enabled ? 'enable' : 'disable'} directory`)
      }
    },
    [providerId, saveProviderDirectories],
  )

  const rowData: Array<RecommendedDirectoryTableRow> = useMemo(
    () =>
      directoryData
        ? directoryData.map(directory => ({
            id: directory.id,
            name: formatType(directory.directoryType),
            directoryType: directory.directoryType,
            status: directory.status,
            clients: 0,
            active: directory.enabled,
          }))
        : [],
    [directoryData],
  )
  const columns = useMemo<DataTableColumn<RecommendedDirectoryTableRow>[]>(
    () => [
      {
        id: 'name',
        Cell: ({ row }: { row: Row<RecommendedDirectoryTableRow> }) => (
          <div className="v-align">
            <DirectoryLogo className="mr-2" directoryType={row.original.directoryType} />
            <TextButton
              onClick={() => handleDirectoryDrawerClick(row.original.directoryType)}
              variant={'secondary'}
              style={{ textAlign: 'left' }}
            >
              {row.original.name}
            </TextButton>
          </div>
        ),
        Header: () => <b>Name</b>,
        // hardcoding widths here so the two separate tables line up correctly
        style: { width: '25%' },
      },
      {
        id: 'active',
        Cell: ({ row }: { row: Row<RecommendedDirectoryTableRow> }): ReactElement => (
          <div className="v-align">
            <Switch
              disabled={disabled}
              checked={row.original.active}
              onChange={() => handleEnableClick(row.original.id, !row.original.active)}
            />
            {row.original.active ? 'On' : 'Off'}
          </div>
        ),
        Header: () => <b>Referrals</b>,
        style: { width: '15%' },
      },
      {
        id: 'clients',
        Cell: () => <>-</>,
        Header: () => <b>Clients</b>,
        style: { width: '20%' },
      },
      {
        id: 'rate',
        Cell: () => <>Make {numberService.centsToFormattedDollars(sessionRate)} per session</>,
        Header: () => <b>Rate</b>,
        style: { width: '20%' },
      },
      {
        id: 'status',
        Cell: ({ row }: { row: Row<RecommendedDirectoryTableRow> }) => <>{formatType(row.original.status)}</>,
        Header: () => <b>Status</b>,
        style: { width: '20%' },
      },
    ],
    [disabled, handleEnableClick, sessionRate, handleDirectoryDrawerClick],
  )
  const emptyDirectoryData = rowData.length === 0
  return (
    <>
      <DataTable
        loadingRows={0}
        customCellStyle={() => ({
          opacity: disabled ? 0.4 : 1,
        })}
        isSkeleton={emptyDirectoryData}
        columns={columns}
        rowData={rowData}
        loading={false}
      />
      {emptyDirectoryData && (
        <div className="py-2 ml-1 relative" style={{ width: '425px', opacity: disabled ? 0.4 : 1 }}>
          <EmptyTitle>
            Join Directories below for a Tava-created and managed profile, crafted to help clients find you.
          </EmptyTitle>
          <div className="caption text-medium top-align" style={{ color: plumSet[80].hex }}>
            <IconCaution className="mr-1" size={16} color={plumSet[50].hex} />
            Please note: You must enroll in insurance to be listed in directories.{' '}
          </div>
          <EmptyStateImage alt={'Join directories'} src={envelopeSparkle} className="mt-2" />
        </div>
      )}
    </>
  )
}

const EmptyTitle = styled('h6')`
  line-height: 1.5;
`

const EmptyStateImage = styled('img')`
  position: absolute;
  top: 14px;
  right: -120px;

  @media (${BelowTablet}) {
    display: none;
  }
`
