import { noop } from 'lodash'
import { HTMLAttributes, ReactNode, createContext, useContext } from 'react'

export type TabId = string | number

interface TabContextValues {
  label: string
  active: TabId
  onChange: (tab: TabId) => void
}

const TabContext = createContext<TabContextValues>({
  label: '',
  active: 0,
  onChange: noop,
})

interface TabProviderProps {
  active: TabId
  label: string
  onChange: (tab: TabId) => void
  children: ReactNode
}

export function TabProvider({ active, label, onChange, children }: TabProviderProps) {
  return <TabContext.Provider value={{ active, label, onChange }}>{children}</TabContext.Provider>
}

// This was originally created to replace the BluePrint tab solution that we had been using so the API was meant to match that
// There aren't a lot of styles so maybe it can be built out further someday
export function Tabs(props: HTMLAttributes<HTMLDivElement>) {
  const { label } = useContext(TabContext)

  return <div {...props} role="tablist" aria-label={label} />
}

interface TabProps extends HTMLAttributes<HTMLButtonElement> {
  value: TabId
}

export function Tab({ value, children, ...props }: TabProps) {
  const { active, onChange, label } = useContext(TabContext)

  return (
    <button
      {...props}
      aria-controls={`${label}-${value}-tabpanel`}
      aria-selected={active === value ? 'true' : 'false'}
      id={`${label}-${value}-tab`}
      role="tab"
      onClick={() => onChange(value)}
    >
      {children}
    </button>
  )
}

interface TabPanelProps extends HTMLAttributes<HTMLDivElement> {
  value: TabId
}

export function TabPanel({ value, ...props }: TabPanelProps) {
  const { label, active } = useContext(TabContext)

  return (
    <div
      {...props}
      aria-labelledby={`${label}-${value}-tab`}
      id={`${label}-${value}-tabpanel`}
      role="tabpanel"
      hidden={active !== value}
    />
  )
}
