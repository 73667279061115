import { IconProps } from './IconProps'

export function IconShield({ size = 24, color = 'currentColor', ...props }: IconProps) {
  return (
    <svg width={size} height={size} viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M24 1L5 9.36364V21.9091C5 33.5136 13.1067 44.3655 24 47C34.8933 44.3655 43 33.5136 43 21.9091V9.36364L24 1ZM24 7.57143L38.4762 13.5455C38.4762 13.5455 38.76 21.6807 37.2698 27.5844C33.6508 39.8312 24 40.7273 24 40.7273V7.57143Z"
        fill={color}
      />
    </svg>
  )
}
