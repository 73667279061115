import { keyframes, styled } from '@mui/material'

import { greySet } from '../styles/colorSets'

const lightGrey = greySet[15].hex
const mediumGrey = greySet[50].hex
const darkGrey = greySet[90].hex

export function UserIsTypingIndicator() {
  return (
    <Indicator>
      <Circle delay={0} />
      <Circle delay={400} />
      <Circle delay={800} />
    </Indicator>
  )
}

const Indicator = styled('span')`
  border: 1px solid ${mediumGrey};
  border-radius: 6px 26px 26px 26px;
  display: flex;
  justify-content: space-between;
  padding: 0.5rem;
  width: 60px;
`

const CircleAnimation = keyframes`
    from {
        background-color: ${lightGrey};
    }
    50% {
        background-color: ${mediumGrey};
    }
    to {
        background-color: ${darkGrey};
    }
`

const Circle = styled('span')<{ delay: number }>`
  animation: 1.2s ${CircleAnimation} infinite ${props => props.delay}ms;
  background-color: ${mediumGrey};
  border-radius: 50%;
  height: 8px;
  width: 8px;
`
