import { debounce } from 'lodash'
import moment from 'moment'
import { useEffect, useMemo, useState } from 'react'

import { ConversationMessageFragment, useMarkConversationMessageReadMutation } from '@nuna/api'

import { useConversationUnreadContext } from '../../../context/ConversationUnreadContext'

export function useMarkConversationMessageAsRead(initialLastReadMessage: ConversationMessageFragment | null) {
  const { updateLastReadMessageForConversation } = useConversationUnreadContext()
  const [markConversationMessageRead] = useMarkConversationMessageReadMutation()

  const [lastReadMessage, setLastReadMessage] = useState<ConversationMessageFragment | null>(null)

  useEffect(() => {
    if (lastReadMessage === null && initialLastReadMessage !== null) {
      setLastReadMessage(initialLastReadMessage)
    }
  }, [initialLastReadMessage, lastReadMessage])

  useEffect(() => {
    if (lastReadMessage && lastReadMessage.id !== initialLastReadMessage?.id) {
      markConversationMessageRead({
        variables: { conversationId: lastReadMessage.conversationId, messageId: lastReadMessage.id },
      })
    }
  }, [initialLastReadMessage?.id, lastReadMessage, markConversationMessageRead])

  const updateLastReadMessage = useMemo(
    () =>
      debounce((message: ConversationMessageFragment) => {
        if (lastReadMessage === null || moment(message.updatedAt).isAfter(moment(lastReadMessage.updatedAt))) {
          setLastReadMessage(message)
          updateLastReadMessageForConversation(message.conversationId, message.id)
        }
      }, 1000),
    [lastReadMessage, updateLastReadMessageForConversation],
  )

  return { lastReadMessage, updateLastReadMessage }
}
