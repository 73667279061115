import { styled } from '@mui/material'
import { useState } from 'react'

import { BasicProviderFragment, CancelPolicyFragment, PaymentPreference } from '@nuna/api'
import { numberService } from '@nuna/core'
import { Collapse, IconCancelAppointment, IconChevronThick, IconNoShow, borderGrey } from '@nuna/tunic'

import { ProviderPolicyFAQs } from '../../ProviderPolicyFAQs'

interface Props {
  cancelPolicy: CancelPolicyFragment
  provider: BasicProviderFragment
  paymentPreference?: PaymentPreference | null
}

export function CollapsiblePolicyDetails({ cancelPolicy, provider, paymentPreference }: Props) {
  const [faqOpen, setFaqOpen] = useState(false)
  const [cancelOpen, setCancelOpen] = useState(false)
  const [lateFeeOpen, setLateFeeOpen] = useState(false)

  return (
    <>
      <CollapseToggle onClick={() => setCancelOpen(!cancelOpen)}>
        <span className="v-align">
          <IconCancelAppointment size={22} className="mr-1" isHovered />
          {numberService.centsToFormattedDollars(cancelPolicy?.chargeAmount)} late cancelation fee
        </span>{' '}
        <IconChevronThick size={14} direction={cancelOpen ? 'up' : 'down'} />
      </CollapseToggle>
      <Collapse isOpen={cancelOpen}>
        <div className="pb-1">
          <p className="body paragraphs text-default">
            {provider?.firstName} charges{' '}
            <span className="text-medium">{numberService.centsToFormattedDollars(cancelPolicy?.chargeAmount)}</span> if
            you cancel or reschedule a session{' '}
            <span className="text-medium">
              less than {numberService.minutesToFormattedHours(cancelPolicy?.cancelPeriodMinutes ?? 0)} beforehand
            </span>
            .
          </p>
        </div>
      </Collapse>

      <CollapseToggle onClick={() => setLateFeeOpen(!lateFeeOpen)}>
        <span className="v-align">
          <IconNoShow size={22} className="mr-1" isHovered />
          {numberService.centsToFormattedDollars(cancelPolicy?.noShowChargeAmount)} no-show fee
        </span>{' '}
        <IconChevronThick size={14} direction={lateFeeOpen ? 'up' : 'down'} />
      </CollapseToggle>
      <Collapse isOpen={lateFeeOpen}>
        <div className="pb-1">
          <p className="body paragraphs text-default">
            {provider?.firstName} charges{' '}
            <span className="text-medium">
              {numberService.centsToFormattedDollars(cancelPolicy?.noShowChargeAmount)}
            </span>{' '}
            if you simply don't show up for a session.
          </p>
        </div>
      </Collapse>

      <CollapseToggle
        onClick={() => setFaqOpen(!faqOpen)}
        style={{ borderBottom: `1px solid ${borderGrey}`, marginBottom: '-1px' }}
      >
        <span className="caption text-medium text-secondary">Common Questions</span>{' '}
        <IconChevronThick size={14} direction={faqOpen ? 'up' : 'down'} />
      </CollapseToggle>
      <Collapse isOpen={faqOpen}>
        <ProviderPolicyFAQs
          provider={provider}
          coverageType={paymentPreference}
          sharedListItemProps={{ className: 'px-0 py-1', compact: true }}
        />
      </Collapse>
    </>
  )
}

const CollapseToggle = styled('button')`
  border-top: 1px solid ${borderGrey};
`
CollapseToggle.defaultProps = { type: 'button', className: 'v-align full-width space-between py-2' }
