import { useInputSettings } from '@daily-co/daily-react'
import { useEffect, useRef } from 'react'

import { PersistedAudioDevicePreferences, PersistedVideoDevicePreferences } from '../types'
import { getInputSettingsFromDevicePreferences, saveDevicePreferences } from '../util/devicePreferences'

/**
 * Updates device preferences in Daily and local storage when the preferences change
 * @param memoizedPreferences - A memoized object containing the preferences to be set on change
 * @returns errorMsg - returns an error message if the preferences could not be applied to Daily
 */
export function useSyncedDevicePreferencesUpdate(
  memoizedPreferences: PersistedVideoDevicePreferences | PersistedAudioDevicePreferences,
) {
  const { updateInputSettings, errorMsg } = useInputSettings()
  const isFirstRender = useRef(true)

  useEffect(() => {
    if (errorMsg) return // if it's errored, stop trying to sync to Daily
    if (isFirstRender.current) {
      isFirstRender.current = false // we don't want this to run on mount, only when the preferences change
      return
    }

    updateInputSettings(getInputSettingsFromDevicePreferences(memoizedPreferences))
    saveDevicePreferences(memoizedPreferences)
  }, [errorMsg, memoizedPreferences, updateInputSettings])

  return { dailyInputError: errorMsg }
}
