import { noop } from 'lodash'

import { specialtiesService } from '@nuna/core'

import { SpecialtiesSectionBase, SpecialtiesSectionProps } from './SpecialtiesSectionBase'

export function SexualitySection({ specialties, id, refetch = noop }: SpecialtiesSectionProps) {
  return (
    <SpecialtiesSectionBase
      headingText="Sexuality"
      description=""
      selectedSpecialties={specialties}
      specialtiesFilter={specialtiesService.getSexualitySpecialties}
      refetch={refetch}
      providerId={id}
      min={0}
    />
  )
}
