import { useProviderMeQuery } from '@nuna/api'
import { Illustration, Layout } from '@nuna/common'
import { routeService } from '@nuna/core'
import { GhostButtonLink, Grid } from '@nuna/tunic'

import { CAQHStepper } from '../CAQHStepper'
import intakeImg from '../img/ProviderLove.png'

export function CAQHWelcome() {
  const { data } = useProviderMeQuery({
    fetchPolicy: 'network-only',
  })

  const { slug } = data?.providerMe || {}
  const p4Url = slug ? routeService.publicProviderRoute(slug, true) : undefined
  return (
    <Layout>
      <Grid container alignItems="center" direction="row-reverse">
        <Grid
          size={{
            md: 4,
            xs: 12,
          }}
        >
          <Illustration src={intakeImg} />
        </Grid>
        <Grid
          size={{
            md: 8,
            xs: 12,
          }}
        >
          <h3>Things look great and your Practice Website and Tava Profile are ready!</h3>
          <p className="text-secondary large">
            Your Practice site is up, complete with your info for easy bookings. Now you’re able to share the link with
            clients and dive into Tava!
          </p>
          {p4Url ? (
            <GhostButtonLink target={'_blank'} className="mt-2" to={p4Url}>
              {p4Url}
            </GhostButtonLink>
          ) : null}
        </Grid>

        <Grid container alignItems="center" className="mt-6" size={12}>
          <CAQHStepper
            customNextButtonText={'Want Referrals? Setup Insurance'}
            customPreviousPath={'/'}
            disabled={false}
          />
        </Grid>
      </Grid>
    </Layout>
  )
}
