import { styled } from '@mui/material'
import { HTMLAttributes, ImgHTMLAttributes, ReactNode } from 'react'

import {
  IconInfo,
  Tooltip,
  body1,
  csx,
  error,
  fontSize,
  greySet,
  makeTypographyComponent,
  tealSet,
  useImageRatio,
} from '@nuna/tunic'

const COVERAGE_LIST_ITEM_LINE_HEIGHT = 1.3

export const CoverageHeader = styled('h6')`
  color: ${greySet[80].hex};
`

export const CoveragePanelName = styled('div')`
  color: ${tealSet[80].hex};
  font-size: ${fontSize.h6};
`

export const CoverageList = styled(makeTypographyComponent('unstyled-list', 'ul'))`
  li {
    margin-bottom: 0.75rem;
  }
`

interface CoverageListItemProps extends HTMLAttributes<HTMLLIElement> {
  adornment: ReactNode
  disabled?: boolean
  tooltip?: string | false
}

export function CoverageListItem({
  adornment,
  children,
  className,
  tooltip,
  disabled = false,
  ...props
}: CoverageListItemProps) {
  return (
    <StyledListItem {...props} className={csx([className, 'top-align text-secondary coverage-list-item'])}>
      <Adornment>{adornment}</Adornment>

      <div className={csx([{ disabled }, 'list-item-body space-between'])}>
        <span className={csx({ 'mr-1': !!tooltip })}>{children}</span>
        {tooltip && (
          <Tooltip content={tooltip}>
            <span>
              <IconInfo color={greySet[30].hex} size={20} />
            </span>
          </Tooltip>
        )}
      </div>
    </StyledListItem>
  )
}

const StyledListItem = styled('li')`
  div.disabled {
    text-decoration: line-through;
  }

  div.list-item-body {
    flex: 1;
  }
  line-height: ${COVERAGE_LIST_ITEM_LINE_HEIGHT};
`

export function CoverageIcon({ disabled, children }: { disabled?: boolean; children: ReactNode }) {
  return (
    <CoverageIconContainer>
      {children}
      {disabled && <PositionedDisabledIcon />}
    </CoverageIconContainer>
  )
}

function DisabledIcon(props: HTMLAttributes<SVGElement>) {
  return (
    <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <circle cx={6} cy={6} r={6} fill={greySet[5].hex} />
      <path
        transform="translate(1.5, 1)"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.00002 4.84852C9.00002 7.21185 7.08416 9.1277 4.72083 9.1277C2.35751 9.1277 0.44165 7.21185 0.44165 4.84852C0.44165 2.48519 2.35751 0.569336 4.72083 0.569336C7.08416 0.569336 9.00002 2.48519 9.00002 4.84852ZM7.51848 4.84852C7.51848 6.39365 6.2659 7.64623 4.72077 7.64623C4.26703 7.64623 3.83852 7.53822 3.45955 7.3465L7.21875 3.5873C7.41047 3.96627 7.51848 4.39478 7.51848 4.84852ZM2.23607 6.13566L6.00791 2.36382C5.6226 2.16381 5.18488 2.0508 4.72077 2.0508C3.17563 2.0508 1.92305 3.30338 1.92305 4.84852C1.92305 5.31263 2.03606 5.75036 2.23607 6.13566Z"
        fill={error}
      />
    </svg>
  )
}

const CoverageIconContainer = styled('span')`
  position: relative;
  display: flex;
  align-items: center;
`

const PositionedDisabledIcon = styled(DisabledIcon)`
  position: absolute;
  bottom: 1px;
  right: -3px;
`

export const Benefit = styled('span')<{ disabled?: boolean }>`
  ${props => props.disabled && 'text-decoration: line-through;'}
`

const Adornment = styled('span')`
  align-items: center;
  color: ${body1};
  display: flex;
  font-weight: 700;
  text-align: right;
  width: 19px;
  margin-right: 0.5rem;
  height: calc(${COVERAGE_LIST_ITEM_LINE_HEIGHT} * ${fontSize.body});
`

export const SecondaryText = styled(makeTypographyComponent('caption italic', 'span'))`
  margin-left: calc(19px + 0.5rem);
`

export const CoverageSection = makeTypographyComponent('mt-3 text-secondary coverage-section', 'section')

export const CoverageSectionHeading = styled(makeTypographyComponent('body text-medium v-align', 'h6'))`
  &:after {
    content: '';
    border-top: 1px solid ${greySet[30].hex};
    height: 1px;
    flex-grow: 1;
    margin: 0 0.5rem;
  }
`

export const CoverageLogo = styled('img')<{ ratio?: number; landscapeMax?: string; portraitMax?: string }>`
  display: block;
  max-height: 80px;
  max-width: ${({ ratio = 0, landscapeMax = '90%', portraitMax = '210px' }) =>
    ratio >= 4.5 ? landscapeMax : portraitMax};
  width: 100%;
  object-fit: contain;
  object-position: left;
`

interface CoverageLogoRatioProps extends ImgHTMLAttributes<HTMLImageElement> {
  landscapeMax?: string
  portraitMax?: string
}

export function CoverageLogoRatio({ src, ...props }: CoverageLogoRatioProps) {
  const ratio = useImageRatio(src ?? '')

  return <CoverageLogo ratio={ratio} src={src} {...props} />
}
