import { useEffect } from 'react'

import {
  CancelPolicyFragment,
  CancelPolicyStatusFragment,
  useCancelPolicyStatusQuery,
  usePatientContextQuery,
} from '@nuna/api'
import { useAuthDataContext } from '@nuna/auth'
import { cancelPolicyService, errorService } from '@nuna/core'
import { useHasValidFeePaymentMethod } from '@nuna/fee-policies'
import { toast } from '@nuna/tunic'

export function useShowFeePolicy(providerId: string | null | undefined):
  | {
      needsAcceptance: false
      cancelPolicyStatus: null | CancelPolicyStatusFragment
      cancelPolicy: null | CancelPolicyFragment
    }
  | { needsAcceptance: true; cancelPolicyStatus: CancelPolicyStatusFragment; cancelPolicy: CancelPolicyFragment }
  | null {
  const { loggedIn } = useAuthDataContext()

  const [hasValidFeePaymentMethod] = useHasValidFeePaymentMethod()
  const { data: patientContextData } = usePatientContextQuery({ skip: !loggedIn })
  const { data: cancelPolicyData, error } = useCancelPolicyStatusQuery({
    variables: {
      providerId: providerId ?? '',
      clientId: patientContextData?.patientContext.patient.id ?? '',
    },
    skip: !patientContextData || !loggedIn || !providerId,
    fetchPolicy: 'network-only',
  })

  useEffect(() => {
    if (error) {
      toast.urgent(errorService.transformUserGraphqlError(error, 'Unable to load cancel policy status'))
    }
  }, [error])

  const cancelPolicyStatus = cancelPolicyData?.cancelPolicyStatus

  if (!cancelPolicyStatus || hasValidFeePaymentMethod === null) return null // loading
  if (cancelPolicyStatus.availableCancelPolicy)
    return { needsAcceptance: true, cancelPolicyStatus, cancelPolicy: cancelPolicyStatus.availableCancelPolicy } // new policy available
  if (!cancelPolicyStatus.cancelPolicy) return { needsAcceptance: false, cancelPolicyStatus: null, cancelPolicy: null } // if no policy, then don't bother checking payment method
  if (hasValidFeePaymentMethod === false && !cancelPolicyService.isZeroDollarPolicy(cancelPolicyStatus.cancelPolicy))
    return { needsAcceptance: true, cancelPolicyStatus, cancelPolicy: cancelPolicyStatus.cancelPolicy } // same policy but card is now invalid

  return {
    needsAcceptance: !cancelPolicyStatus.acceptedDate,
    cancelPolicyStatus,
    cancelPolicy: cancelPolicyStatus.cancelPolicy,
  }
}
