import { HTMLAttributes } from 'react'

import { AppointmentCollectionStatusQuery, AppointmentCurrentStatus } from '@nuna/api'
import { appointmentCollectionStatusService, appointmentService } from '@nuna/core'
import { IconCancelAppointment, IconNoShow, IconRescheduleAppointment, StatusLabel } from '@nuna/tunic'

const { wasRescheduled, hasFee, wasClientNoShow } = appointmentCollectionStatusService

interface Props {
  appointmentData: AppointmentCollectionStatusQuery['appointmentCollectionStatus']
}

export function ChangeReasonDisplay({ appointmentData }: Props) {
  const rescheduled = wasRescheduled(appointmentData)
  const appointmentHasFee = hasFee(appointmentData)
  const wasNoShow = wasClientNoShow(appointmentData)

  const getText = () => {
    const late = appointmentHasFee ? 'late ' : ''

    if (wasNoShow) {
      return <>Session was missed by {appointmentData.patient?.firstName || 'the patient'}</>
    } else if (rescheduled) {
      return `Session was rescheduled ${late}to the following date/time:`
    } else {
      return `Session was canceled ${late}due to:`
    }
  }

  return (
    <div className="v-align text-secondary flex-wrap">
      <FooterIcon wasNoShow={wasNoShow} rescheduled={rescheduled} className="mr-xs" />
      <span className="mr-1 my-1">{getText()}</span>
      {!rescheduled &&
        appointmentData.currentChangeReason &&
        appointmentData.currentStatus !== AppointmentCurrentStatus.NoShowByPatient && (
          <StatusLabel>{appointmentService.readableChangeReason(appointmentData.currentChangeReason)}</StatusLabel>
        )}
    </div>
  )
}
interface FooterIconProps extends HTMLAttributes<HTMLOrSVGElement> {
  wasNoShow: boolean
  rescheduled: boolean
}
function FooterIcon({ wasNoShow, rescheduled, ...props }: FooterIconProps) {
  if (wasNoShow) {
    return <IconNoShow isHovered={false} size={20} {...props} />
  } else if (rescheduled) {
    return <IconRescheduleAppointment isHovered={false} {...props} />
  } else {
    return <IconCancelAppointment isHovered={false} {...props} />
  }
}
