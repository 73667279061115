import { styled } from '@mui/material'
import moment from 'moment-timezone'

import { FinancialAttribution, SessionPaymentStatus } from '@nuna/api'
import { NavigationLink, allNonSpecificLoadingMessages, navigationalLoadingMessages } from '@nuna/common'
import { appointmentService, financialsService, numberService, specialtiesService } from '@nuna/core'
import {
  Avatar,
  IconArmchair,
  IconCancelAppointment,
  IconInfo,
  IconTherapyType,
  IconVideoOutline,
  Intent,
  StatusLabel,
  Tooltip,
  csx,
  greySet,
  yellowSet,
} from '@nuna/tunic'

import {
  AppointmentCardDetailProps,
  AppointmentCardFeeType,
  AppointmentCardStatus,
  AppointmentChargeStatus,
} from '../types'

export function Detail({
  appointment,
  audience,
  provider,
  client,
  stackedBorderColor = 'white',
  showAvatar = true,
  appointmentStatus,
  condensed = false,
  previousAppointmentDatetime,
  chargeStatus,
  cancelPolicyStatus,
  additionalSlots = {},
  hideChargeStatus = false,
  showTopStatus = false,
  ...props
}: AppointmentCardDetailProps) {
  const getShouldShowChargeStatus = () => {
    if (hideChargeStatus) {
      return false
    }

    if (!chargeStatus) {
      return false
    }

    if (
      [AppointmentCardStatus.Canceling].includes(appointmentStatus) &&
      !cancelPolicyStatus?.cancelPolicy?.chargeAmount
    ) {
      return false
    }

    return [AppointmentCardStatus.Canceled, AppointmentCardStatus.Rescheduled, AppointmentCardStatus.NoShow].includes(
      appointmentStatus,
    )
  }

  return (
    <DetailContainer
      className={csx([{ 'px-3 py-2': audience === 'admin', 'p-3': !showAvatar }, 'pr-3 v-align', 'py-1'])}
      {...props}
    >
      {showAvatar && (
        <AppointmentAvatar
          audience={audience}
          provider={provider}
          client={client}
          stackedBorderColor={stackedBorderColor}
          className={csx({ 'mr-2': audience === 'admin' })}
        />
      )}

      <div className="v-align full-width flex-wrap py-xs">
        <div className="mr-auto pr-1 my-xs">
          {additionalSlots.detailTop}
          {showTopStatus && (
            <StatusLabel className="mb-1" intent={getIntent(appointmentStatus)}>
              {statusDisplayText(appointmentStatus)}
            </StatusLabel>
          )}
          <AppointmentTime appointment={appointment} appointmentStatus={appointmentStatus} />
          <div className="text-secondary text-medium caption v-align flex-wrap mb-2">
            {otherParticipantInfo({ appointment, provider, client, audience })}
            {getShouldShowChargeStatus() && (
              <>
                <span className="mx-1">•</span>
                <ChargeStatus
                  chargeStatus={chargeStatus}
                  cancelPolicyStatus={cancelPolicyStatus}
                  audience={audience}
                  provider={provider}
                />
              </>
            )}
          </div>
          {appointmentStatus === AppointmentCardStatus.Canceled && appointment.currentChangeReason && (
            <div>
              <div className="bottom-align mb-1">
                <IconCancelAppointment isHovered={false} color={yellowSet[80].hex} />
                <p className="text-medium caption mb-0 ml-xs">Canceled due to:</p>
              </div>
              <div>
                <StatusLabel className="ml-3 mb-2">
                  {appointmentService.readableChangeReason(appointment.currentChangeReason)}
                </StatusLabel>
                {appointment.currentFreeFormReason && (
                  <p className="ml-3 mb-2 caption text-medium">"{appointment.currentFreeFormReason}"</p>
                )}
              </div>
            </div>
          )}
          <div className="text-secondary text-medium caption v-align pb-1">
            {'address' in appointment && appointment.address?.id ? (
              <div className="v-align">
                <IconArmchair className="mr-1" size={16} />
                <NavigationLink
                  address={appointment.address}
                  usesSpinner={false}
                  usesTrailingEllipsis={true}
                  loadingMessages={[...allNonSpecificLoadingMessages, ...navigationalLoadingMessages]}
                  buttonProps={{ style: { textAlign: 'left' } }}
                />
              </div>
            ) : (
              <>
                <IconVideoOutline className="mr-1" size={16} />
                <span>Virtual</span>
              </>
            )}
          </div>
          {'therapyTypeSpecialty' in appointment &&
            appointment.therapyTypeSpecialty &&
            specialtiesService.isTherapyType(appointment.therapyTypeSpecialty.name) && (
              <div className="text-secondary text-medium caption v-align">
                <div className="v-align">
                  <IconTherapyType size={16} className="mr-1" therapyType={appointment.therapyTypeSpecialty.name} />
                  <span>{appointment.therapyTypeSpecialty.name}</span>
                </div>
              </div>
            )}
          {additionalSlots.detailBottom}
        </div>

        {!condensed && (
          <>
            {!showTopStatus && (
              <StatusDisplay
                appointmentStatus={appointmentStatus}
                previousAppointmentDatetime={previousAppointmentDatetime}
              />
            )}
            <FinancialDetails appointment={appointment} audience={audience} />
          </>
        )}
      </div>
      {additionalSlots.cardRight}
    </DetailContainer>
  )
}

export function otherParticipantInfo({
  provider,
  client,
  audience,
  appointment,
}: Pick<AppointmentCardDetailProps, 'audience' | 'provider' | 'client' | 'appointment'>) {
  if (audience === 'client') {
    return `with ${provider?.firstName} ${provider?.lastName}`
  } else if (audience === 'provider') {
    if (!client?.timezone) {
      return <span>with {client?.firstName}</span>
    }
    return (
      <span>
        {moment(appointment.startDatetime)
          .tz(client?.timezone ?? '')
          .format('h:mma z')}{' '}
        for {client?.firstName}
      </span>
    )
  }

  return (
    <span>
      between {client?.firstName} {client?.lastName}
      and {provider?.firstName} {provider?.lastName}
    </span>
  )
}

export function AppointmentAvatar({
  audience,
  provider,
  client,
  stackedBorderColor = 'white',
  ...props
}: Pick<
  AppointmentCardDetailProps,
  'audience' | 'provider' | 'client' | 'stackedBorderColor' | 'style' | 'className'
>) {
  const getAvatarUrls = () => {
    if (audience === 'client') {
      return [provider?.avatarUrl]
    } else if (audience === 'provider') {
      return [client?.avatarUrl]
    }

    return [client?.avatarUrl, provider?.avatarUrl]
  }

  const avatarUrls = getAvatarUrls()

  if (avatarUrls.length === 2) {
    return (
      <StackedAvatars {...props} className={csx([props.className, 'flex-static fs-exclude'])}>
        <Avatar url={avatarUrls[0]} size="sm" circle />
        <Avatar url={avatarUrls[1]} size="sm" className="stacked" circle style={{ borderColor: stackedBorderColor }} />
      </StackedAvatars>
    )
  }

  return (
    <Avatar
      url={avatarUrls[0]}
      size="sm"
      className={csx([props.className, 'flex-static fs-exclude'])}
      style={props.style}
    />
  )
}

export function AppointmentTime({
  appointmentStatus,
  appointment,
}: Pick<AppointmentCardDetailProps, 'appointment' | 'appointmentStatus'>) {
  return (
    <StyledAppointmentTime
      className={csx([
        {
          strikethrough: [
            AppointmentCardStatus.Canceled,
            AppointmentCardStatus.Canceling,
            AppointmentCardStatus.NoShow,
          ].includes(appointmentStatus),
        },
        'text-default text-medium appointment-time',
      ])}
    >
      {appointmentService.appointmentTimeSpan(appointment)}
    </StyledAppointmentTime>
  )
}

const getIntent = (appointmentStatus: AppointmentCardStatus): Intent => {
  if ([AppointmentCardStatus.Canceled, AppointmentCardStatus.NoShow].includes(appointmentStatus)) {
    return 'caution'
  }

  return 'information'
}

export function StatusDisplay({
  appointmentStatus,
  previousAppointmentDatetime,
}: Pick<AppointmentCardDetailProps, 'appointmentStatus' | 'previousAppointmentDatetime'>) {
  if (
    [AppointmentCardStatus.Canceled, AppointmentCardStatus.Rescheduled, AppointmentCardStatus.NoShow].includes(
      appointmentStatus,
    )
  ) {
    return (
      <div className="my-xs">
        <StatusLabel intent={getIntent(appointmentStatus)}>{statusDisplayText(appointmentStatus)}</StatusLabel>
        {appointmentStatus === AppointmentCardStatus.Rescheduled && previousAppointmentDatetime && (
          <div className="italic caption mt-xs" style={{ color: greySet[50].hex }}>
            from {moment(previousAppointmentDatetime).format('M/YY [at] h:mma')}
          </div>
        )}
      </div>
    )
  }

  return null
}

export function statusDisplayText(appointmentStatus: AppointmentCardStatus) {
  if (appointmentStatus === AppointmentCardStatus.NoShow) {
    return 'Client no-show'
  }
  return appointmentStatus
}

export function ChargeStatus({
  chargeStatus,
  cancelPolicyStatus,
  provider,
  audience,
}: Pick<AppointmentCardDetailProps, 'chargeStatus' | 'cancelPolicyStatus' | 'provider' | 'audience'>) {
  const cancelPolicy = cancelPolicyStatus?.cancelPolicy
  const type = cancelPolicy?.type ?? AppointmentCardFeeType.LateCancel
  const typeText = type === AppointmentCardFeeType.LateCancel ? 'late cancelation' : 'missing an appointment'

  // TODO - this logic probably belongs in a util somewhere
  const getText = () => {
    switch (chargeStatus) {
      case AppointmentChargeStatus.Pending:
        return 'Charge pending'
      case AppointmentChargeStatus.Scheduled:
      case AppointmentChargeStatus.Failure:
      case AppointmentChargeStatus.Success:
        return `${numberService.centsToFormattedDollars(cancelPolicyStatus?.cancelPolicy?.chargeAmount)} charge`
      default:
        return 'No charge applied'
    }
  }

  const getTooltip = () => {
    switch (chargeStatus) {
      case AppointmentChargeStatus.Pending:
        return `${provider?.firstName} charges ${numberService.centsToFormattedDollars(
          cancelPolicy?.chargeAmount,
        )} for ${typeText}, which takes 2-5 business days to reflect in your account`
      case AppointmentChargeStatus.Scheduled:
      case AppointmentChargeStatus.Failure:
      case AppointmentChargeStatus.Success:
        return `${provider?.firstName} charges ${numberService.centsToFormattedDollars(
          cancelPolicy?.chargeAmount,
        )} for ${typeText}.`
      default:
        return `${provider?.firstName} charges ${numberService.centsToFormattedDollars(
          cancelPolicy?.chargeAmount,
        )} for ${typeText}, but chose to waive your fees.`
    }
  }

  return (
    <>
      {getText()}
      {audience === 'client' && (
        <Tooltip content={getTooltip()}>
          <span className="v-align ml-xs" style={{ color: greySet[30].hex }}>
            <IconInfo size={20} />
          </span>
        </Tooltip>
      )}
    </>
  )
}

export function FinancialDetails({
  appointment,
  audience,
}: Pick<AppointmentCardDetailProps, 'appointment' | 'audience'>) {
  if (audience === 'provider' && 'whoIsResponsibleForPayment' in appointment) {
    const { financialAttribution, payoutDetails, paymentHistory, whoIsResponsibleForPayment } = appointment
    const lastPayout = (payoutDetails ?? [])[0]
    const lastPayment = (paymentHistory ?? []).find(p =>
      [SessionPaymentStatus.Success, SessionPaymentStatus.Failure].includes(p.status),
    )
    const hasMultiplePayments = (paymentHistory ?? []).length > 1

    return (
      <div className="flex-column gap-1 mt-2">
        <div className="v-align">
          <StatusLabel intent={financialAttribution === FinancialAttribution.Provider ? 'information' : 'default'}>
            {financialsService.getFinancialAttributionText(financialAttribution)}
          </StatusLabel>
        </div>
        {whoIsResponsibleForPayment && (
          <div className="v-align gap-1">
            <span className="text-secondary text-medium caption">Payment Type:</span>
            <span>{financialsService.getPaymentTypeText(whoIsResponsibleForPayment)}</span>
          </div>
        )}
        {lastPayment && (
          <div className="v-align gap-1">
            <span className="text-secondary text-medium caption">{`${
              hasMultiplePayments ? 'Latest ' : ''
            }Client Charge:`}</span>
            <span>
              {numberService.centsToFormattedDollars(lastPayment.amount, { maximumFractionDigits: 2 })} on{' '}
              {moment(lastPayment.exerciseAt).format('M/D/YY')}
            </span>
            {lastPayment.status === SessionPaymentStatus.Failure && <StatusLabel intent="urgent">Failed</StatusLabel>}
          </div>
        )}
        {lastPayout && (
          <div className="v-align gap-1">
            <span className="text-secondary text-medium caption">Payout:</span>
            <span>
              {numberService.centsToFormattedDollars(lastPayout.providerCompensatedAmount, {
                maximumFractionDigits: 2,
              })}
            </span>
          </div>
        )}
      </div>
    )
  }

  return null
}

const DetailContainer = styled('div')``

const StyledAppointmentTime = styled('div')`
  line-height: 155%;
  &.strikethrough {
    text-decoration: line-through;
  }
`

const StackedAvatars = styled('span')`
  position: relative;
  display: inline-block;
  height: 84px;
  .stacked {
    position: absolute;
    left: 0;
    top: 20px;
    border: 2px solid;
  }
`
