import * as Yup from 'yup'
import { styled } from '@mui/material'
import { Form, Formik, FormikProps } from 'formik'
import { isNil, noop } from 'lodash'
import { ReactNode } from 'react'
import { useMediaQuery } from 'react-responsive'

import {
  ClientDiscountFragment,
  Patient,
  PatientProviderCustomRateType,
  useClientDiscountQuery,
  useEstimateClientDiscountQuery,
  useUpdateClientDiscountMutation,
} from '@nuna/api'
import { compensationService, errorService, formService, numberService } from '@nuna/core'
import {
  BelowTablet,
  Card,
  CardBody,
  CardHeader,
  Dialog,
  DialogProps,
  FillButton,
  GhostButton,
  Grid,
  Select,
  Skeleton,
  makeTypographyComponent,
  toast,
} from '@nuna/tunic'

import { InlineInput } from '../PolciesSetup/components/InlineInput'

const { formatCurrency, centsToFormattedDollars } = numberService
const { customRateAsCentsIfFlatRate, customRateAsDollarsIfFlatRate } = compensationService

interface FormValues {
  customRate?: number | null
  customRateType?: PatientProviderCustomRateType | null
}

interface ClientDiscountModalProps extends DialogProps {
  client: Pick<Patient, 'firstName' | 'id'>
  providerId: string
  onClose: () => void
  onUpdate?: () => void
}

const validationSchema = (providerRate: number) =>
  Yup.object().shape<FormValues>({
    customRate: Yup.number().when('customRateType', {
      is: customRateType => customRateType === PatientProviderCustomRateType.DiscountPercent,
      then: Yup.number()
        .min(0.1, 'Cannot be less than 0.1%')
        .max(100, 'Cannot exceed 100%')
        .typeError('Client discount is required')
        .required('Client discount is required'),
      otherwise: Yup.number()
        .min(0.1, 'Cannot be less than $0.01')
        .max(providerRate, `Your rate cannot exceed ${formatCurrency(providerRate, { maximumFractionDigits: 2 })}`)
        .typeError('Client discount is required')
        .required('Client discount is required'),
    }),
    customRateType: Yup.mixed().oneOf(Object.values(PatientProviderCustomRateType)).required(),
  })

export function ClientDiscountModal({ client, providerId, onUpdate = noop, ...dialogProps }: ClientDiscountModalProps) {
  const isMobile = useMediaQuery({ query: `(${BelowTablet})` })
  const { data } = useClientDiscountQuery({ variables: { clientId: client.id, providerId: providerId } })
  const [updateClientDiscount, { loading }] = useUpdateClientDiscountMutation()

  const clientDiscount = data?.clientDiscount

  const handleSave = async (values: FormValues, onSuccessMessage: string | null, onErrorMessage: string) => {
    const customRate = customRateAsCentsIfFlatRate(
      values.customRate,
      values.customRateType ?? PatientProviderCustomRateType.DiscountPercent,
    )

    try {
      await updateClientDiscount({
        variables: {
          providerId,
          clientId: client.id,
          customRateType: values.customRateType,
          customRate,
        },
      })
      if (onSuccessMessage) {
        toast.success(onSuccessMessage)
      }
      onUpdate()
      dialogProps.onClose()
    } catch (e) {
      toast.urgent(errorService.transformGraphQlError(e, onErrorMessage))
    }
  }

  const handleSubmit = async (values: FormValues) => {
    handleSave(values, 'Client discount saved successfully.', 'There was an error saving the client discount.')
  }

  const handleRemoveDiscount = async () => {
    handleSave({ customRate: null, customRateType: null }, null, 'There was an error removing the discount.')
  }

  return (
    <Dialog {...dialogProps} fullScreen={isMobile} PaperProps={{ style: { width: 'min(427px, 100%)' } }}>
      {!clientDiscount ? (
        <Skeleton height={3} />
      ) : (
        <Formik
          initialValues={buildInitialValues({
            customRate: clientDiscount.customRate,
            customRateType: clientDiscount.customRateType,
          })}
          validationSchema={validationSchema(clientDiscount.cashRate)}
          onSubmit={handleSubmit}
        >
          {formProps => (
            <ClientDiscountForm
              client={client}
              providerId={providerId}
              clientDiscount={clientDiscount}
              onClose={dialogProps.onClose}
              saveLoading={loading}
              onRemoveDiscount={handleRemoveDiscount}
              {...formProps}
            />
          )}
        </Formik>
      )}
    </Dialog>
  )
}

interface ClientDiscountFormProps extends FormikProps<FormValues> {
  client: Pick<Patient, 'firstName' | 'id'>
  providerId: string
  clientDiscount: ClientDiscountFragment
  saveLoading: boolean
  onClose: () => void
  onRemoveDiscount: () => void
}

function ClientDiscountForm({
  client,
  providerId,
  clientDiscount,
  saveLoading,
  values,
  initialValues,
  errors,
  isValid,
  touched,
  onClose,
  getFieldProps,
  onRemoveDiscount,
}: ClientDiscountFormProps) {
  const { data: estimatedData } = useEstimateClientDiscountQuery({
    variables: {
      providerId,
      clientId: client.id,
      customRateType: values.customRateType,
      customRate:
        customRateAsCentsIfFlatRate(
          values.customRate,
          values.customRateType ?? PatientProviderCustomRateType.DiscountPercent,
        ) || null,
    },
    context: { debounceKey: 'estimateClientDiscountQuery', debounceTimeout: 300 },
    fetchPolicy: 'no-cache', // very important that estimated values are not put into the cache
  })

  const clientPays = estimatedData?.estimateClientDiscount.calculatedRate
  const providerPayout = estimatedData?.estimateClientDiscount.reimbursementRate

  return (
    <StyledForm className="p-4 flex-column full-height">
      <h5>Client Discount Rate</h5>
      <p className="mb-3">
        {values.customRateType === PatientProviderCustomRateType.DiscountPercent && (
          <>
            {client.firstName} will be charged your{' '}
            {centsToFormattedDollars(clientDiscount.cashRate, { maximumFractionDigits: 2 })} rate less the discount
            percentage.
          </>
        )}
        {values.customRateType === PatientProviderCustomRateType.Flat && (
          <>{client.firstName} will be charged a fixed rate.</>
        )}
      </p>
      <Card depth={-0.5}>
        <CardHeader>
          <FormRow
            left={<FieldLabel>Discount type</FieldLabel>}
            right={
              <Select {...getFieldProps('customRateType')} className="type-select">
                <option value={PatientProviderCustomRateType.DiscountPercent}>Percent</option>
                <option value={PatientProviderCustomRateType.Flat}>Fixed rate</option>
              </Select>
            }
            className="mb-1"
          />
          <FormRow
            left={<FieldLabel>Client discount</FieldLabel>}
            right={
              <InlineInput
                leftAdornment={values.customRateType === PatientProviderCustomRateType.Flat && '$'}
                rightAdornment={values.customRateType === PatientProviderCustomRateType.DiscountPercent && '%'}
                type="number"
                {...getFieldProps('customRate')}
                {...formService.composeHelperTextWithError('', errors.customRate, touched.customRate)}
              />
            }
          />
        </CardHeader>
        <CardBody>
          <FormRow
            left={<ResultLabel>{client.firstName} pays</ResultLabel>}
            right={
              <ResultValue>
                {(isValid || !values.customRate) && !isNil(clientPays) ? (
                  centsToFormattedDollars(clientPays, { maximumFractionDigits: 2 })
                ) : (
                  <>&mdash;</>
                )}
              </ResultValue>
            }
            className="mb-1"
          />
          <FormRow
            left={<ResultLabel>My payout</ResultLabel>}
            right={
              <ResultValue>
                {(isValid || !values.customRate) && !isNil(providerPayout) ? (
                  centsToFormattedDollars(providerPayout, { maximumFractionDigits: 2 })
                ) : (
                  <>&mdash;</>
                )}
              </ResultValue>
            }
          />
        </CardBody>
      </Card>
      <ButtonRow>
        <FillButton type="submit" isLoading={saveLoading}>
          Save
        </FillButton>
        <GhostButton type="button" onClick={onClose} className="ml-2">
          Cancel
        </GhostButton>
      </ButtonRow>
      {initialValues.customRate && (
        <div className="mt-3">
          <GhostButton variant="destroy" onClick={onRemoveDiscount}>
            Remove discount
          </GhostButton>
        </div>
      )}
    </StyledForm>
  )
}

const StyledForm = styled(Form)`
  .type-select .input-container {
    padding-top: 0;
  }
`

function FormRow({ left, right, className }: { left: ReactNode; right: ReactNode; className?: string }) {
  return (
    <Grid container alignItems="flex-start" className={className}>
      <Grid size={6}>{left}</Grid>
      <Grid size={6}>{right}</Grid>
    </Grid>
  )
}

const FieldLabel = styled(makeTypographyComponent('text-medium', 'div'))`
  margin-top: var(--spacing-three-quarter);
`
const ResultLabel = makeTypographyComponent('text-medium caption', 'div')
const ResultValue = makeTypographyComponent('text-medium caption', 'div')
const ButtonRow = styled(makeTypographyComponent('v-align pt-3 mt-auto', 'div'))`
  @media (${BelowTablet}) {
    flex-wrap: wrap;
    button {
      width: 100%;

      & + button {
        margin-top: var(--spacing-3);
        margin-left: 0;
      }
    }
  }
`

function buildInitialValues(values: FormValues): FormValues {
  const customRateType = values.customRateType ?? PatientProviderCustomRateType.DiscountPercent
  const customRate = customRateAsDollarsIfFlatRate(values.customRate, customRateType)

  return { customRate, customRateType }
}
