import { styled } from '@mui/material'
import moment from 'moment-timezone'
import { CSSProperties, HTMLAttributes, ReactNode, useMemo } from 'react'

import { AddressFragment, PatientHistoryItem } from '@nuna/api'
import { AppointmentLocationIcon } from '@nuna/appointment'
import { Link, useHasScrollbars } from '@nuna/common'
import { addressService } from '@nuna/core'
import { InfoWithHeading, body1, body2, greySet, makeTypographyComponent, shadowDepth } from '@nuna/tunic'

interface SessionItemProps {
  title: string
  date?: string
  text?: ReactNode
  card?: boolean
  note?: ReactNode
  renderActions?: ReactNode
  showDateOutside?: boolean
  linkPath?: string
  address?: AddressFragment | 'Virtual'
}

export interface HistorySessionItemProps {
  showDate: boolean
  historyItem: PatientHistoryItem
}

export function SessionItem({
  date,
  note,
  title,
  address,
  text,
  showDateOutside = false,
  card = true,
  renderActions,
  linkPath = '',
}: SessionItemProps) {
  const { hasScrollbars, noteRef } = useHasScrollbars()
  const addressIsObject = typeof address === 'object'

  const itemContents = (
    <>
      <div>
        <Title>{title}</Title> {text}
        {address && (
          <InfoWithHeading
            style={{ marginBottom: 0 }}
            className="mt-1"
            info={
              <span className="v-align">
                <AppointmentLocationIcon appointment={{ address: addressIsObject ? address : undefined }} />
              </span>
            }
            secondaryInfo={addressIsObject && address.name}
            tertiaryInfo={addressIsObject && <span>{addressService.formatAddress(address)}</span>}
          />
        )}
        {note && (
          <Note hasScrollbars={hasScrollbars} ref={noteRef}>
            {note}
          </Note>
        )}
        {hasScrollbars && <ScrollFade />}
      </div>

      {renderActions && <div className="v-align ml-auto">{renderActions}</div>}
    </>
  )

  // getting the conditional typing right here is super annoying
  // opting for the simplicity of any
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const containerProps: any = useMemo(() => {
    if (linkPath) {
      return {
        as: Link,
        to: linkPath,
        openInNewTab: true,
      }
    }
    return {}
  }, [linkPath])

  if (showDateOutside) {
    return (
      <OutsideDateContainer card={card}>
        <Date>{date && moment(date).format('MMM Do, YYYY')}</Date>
        <Container card={card}>{itemContents}</Container>
      </OutsideDateContainer>
    )
  } else {
    return (
      <Container {...containerProps} card={card}>
        <Date>{date && moment(date).format('MMM Do, YYYY')}</Date>
        {itemContents}
      </Container>
    )
  }
}

interface SessionItemFormProps extends HTMLAttributes<HTMLDivElement> {
  children: ReactNode
}

export function SessionItemForm({ children, ...props }: SessionItemFormProps) {
  return (
    <OutsideDateContainer {...props}>
      <Date className="text-dark text-bold">Today</Date>
      <Container>{children}</Container>
    </OutsideDateContainer>
  )
}

interface SessionItemSkeletonProps extends Pick<SessionItemProps, 'showDateOutside' | 'card'> {
  style?: CSSProperties
}

export function SessionItemSkeleton({ showDateOutside, card, style = {} }: SessionItemSkeletonProps) {
  if (showDateOutside) {
    return (
      <OutsideDateContainer card={card} style={style}>
        <Date>
          <span className="loading">Loading</span>
        </Date>
        <Container card={card}>
          <SkeletonContent maxWidth={600} />

          <div className="ml-auto loading">Loading</div>
        </Container>
      </OutsideDateContainer>
    )
  } else {
    return (
      <Container card={card} style={style}>
        <Date>
          <span className="loading">Loading</span>
        </Date>

        <SkeletonContent />

        <div className="ml-auto loading">Loading</div>
      </Container>
    )
  }
}

const Title = styled(makeTypographyComponent('body text-bold', 'h4'))`
  display: inline;
`

export const Date = styled(makeTypographyComponent('caption text-medium mr-2', 'span'))`
  flex: 0 0 120px;
`

const SkeletonContent = styled('div')<{ maxWidth?: number }>`
  width: 100%;
  height: 40px;
  max-width: ${({ maxWidth = 400 }) => maxWidth}px;
`
SkeletonContent.defaultProps = { className: 'loading' }

export const Container = styled('div')<Pick<SessionItemProps, 'card'>>`
  align-items: center;
  border-radius: var(--border-radius-sm);
  color: ${body2};
  display: flex;
  max-height: 128px;
  padding: 0.5rem 1rem 0.5rem 1.5rem;
  position: relative;

  ${({ card }) =>
    card &&
    `
      background-color: #fff;
      border: 1px solid ${greySet[15].hex};
      box-shadow: ${shadowDepth(1)};
      padding-top: 1rem;
      padding-bottom: 1rem;

      ${Title}, ${Date} {
        color: ${body1};
      }
    `}

  & + & {
    margin-top: var(--margin-2);
  }
`

const OutsideDateContainer = styled('div')<Pick<SessionItemProps, 'card'>>`
  color: ${body2};
  display: flex;
  padding-left: 1.5rem;
  padding-top: 0.5rem;

  & + & {
    margin-top: var(--margin-2);
  }

  ${Date} {
    padding-top: 0.125rem;
  }

  ${Container} {
    flex: 1 1 100%;
    padding-top: 0;

    ${({ card }) => card && `padding-top: 1rem;`}
  }
`

const Note = styled('p')<{ hasScrollbars: boolean }>`
  overflow: auto;
  max-height: 61px;
  margin-bottom: 0;

  ${({ hasScrollbars }) => hasScrollbars && `padding-bottom: 1.5rem;`}
`

const ScrollFade = styled('div')`
  background: linear-gradient(0deg, #ffffff 0%, rgba(255, 255, 255, 0) 100%);
  bottom: 0.5rem;
  height: 29px;
  left: 0;
  position: absolute;
  width: 100%;
  pointer-events: none;
`
