import { isNil } from 'lodash'

import { ProviderStatus, useProviderCaseloadQuery, useProviderSlugQuery } from '@nuna/api'
import { routeService } from '@nuna/core'
import { TextButtonExternalLink, TextButtonLink } from '@nuna/tunic'

type Props = {
  providerId?: string | null
}

export function ReferralLink({ providerId }: Props) {
  const { data: providerCaseload } = useProviderCaseloadQuery({
    variables: { providerId: providerId ?? '' },
    skip: isNil(providerId),
  })
  const { data: providerSlug } = useProviderSlugQuery({
    variables: { providerId: providerId ?? '' },
    skip: isNil(providerId),
  })

  const { acceptsReferrals, status } = providerCaseload?.provider ?? {}

  if (!status) return null

  const template = () => {
    if (status === ProviderStatus.UnderReview) {
      return (
        <p className="m-0 p-0">
          Your profile is now being reviewed by our clinical team. You can share your{' '}
          <TextButtonLink
            to={routeService.publicProviderRoute(providerSlug?.provider.slug ?? '', true)}
            target="_blank"
          >
            practice site
          </TextButtonLink>{' '}
          to invite your own clients in the meantime.
        </p>
      )
    }

    if (acceptsReferrals) {
      return (
        <p className="m-0 p-0">
          Your profile is now active in Tava’s referral networks.
          <br />
          You may periodically receive new clients from us.
        </p>
      )
    }

    return (
      <p className="m-0 p-0" style={{ maxWidth: '350px' }}>
        You will no longer receive new client referrals from Tava, but you can still invite your own with your{' '}
        <TextButtonExternalLink
          variant="secondary"
          href={routeService.publicProviderRoute(providerSlug?.provider.slug ?? '', true)}
          includeReferrer
        >
          profile link
        </TextButtonExternalLink>
        .
      </p>
    )
  }

  return <span className="text-secondary mb-0 caption">{template()}</span>
}
