import * as Yup from 'yup'
import { Formik } from 'formik'
import { AnimatePresence, motion } from 'framer-motion'

import { AssessmentBundleDocument, AssessmentType, useSaveAssessmentBundleMutation } from '@nuna/api'
import { formService } from '@nuna/core'
import { ChipGroup, FillButtonWithChevron, Grid, Radio, toast } from '@nuna/tunic'

import { useAssessmentsContext } from '../../context/AssessmentsContext'
import { AssessmentFromBundleQuery, CssrsFormValues } from '../../types/types'
import { cssrsQuestions, cssrsResponses, validationForAssessmentFields } from '../../util/assessments'

const { getFieldProps, composeNestedHelperTextWithError } = formService

interface CssrsProps {
  assessment: AssessmentFromBundleQuery
}

export function Cssrs({ assessment }: CssrsProps) {
  const [saveBundle, { loading }] = useSaveAssessmentBundleMutation()
  const { assessmentBundle, navigateWithinAssessmentBundle } = useAssessmentsContext()
  const { id: bundleId = '' } = assessmentBundle ?? {}

  const handleSubmit = async (values: CssrsFormValues) => {
    try {
      // Remove null entries as the BE relies on all objects having a value to know when complete.
      // Remove entries where the conditionalOn value is not met—
      // The user could have answered the question and then gone back and changed the conditionalOn value
      // question's value so the dependent question no longer applies.
      const filteredValues = Object.entries(values).reduce((acc: CssrsFormValues, [key, value]) => {
        const question = cssrsQuestions.find(q => q.key === key)
        // Check if the value is not null and (no condition or condition is met)
        if (
          value.value !== null &&
          (!question?.conditionalOn || values[question.conditionalOn.key].value === question.conditionalOn.value)
        ) {
          acc[key as keyof CssrsFormValues] = value
        }
        return acc
      }, {} as CssrsFormValues)

      const { data } = await saveBundle({
        variables: {
          data: {
            id: bundleId,
            assessments: [
              {
                id: assessment.id,
                type: AssessmentType.CSsrs,
                values: filteredValues,
              },
            ],
          },
        },
        refetchQueries: [{ query: AssessmentBundleDocument, variables: { bundleId } }],
      })
      navigateWithinAssessmentBundle(data?.saveAssessmentBundle)
    } catch (e) {
      console.error(e)
      toast.urgent('Error saving assessment')
    }
  }

  return (
    <Formik
      initialValues={buildInitialValues(assessment.values)}
      validationSchema={cssrsSchema}
      onSubmit={handleSubmit}
    >
      {formikProps => {
        const { values, handleSubmit, setFieldValue, errors, touched, submitCount } = formikProps
        return (
          <form onSubmit={handleSubmit}>
            <AnimatePresence>
              {cssrsQuestions.map(({ question, key, conditionalOn, hideWithinLabel }) => {
                const { onBlur, name } = getFieldProps(key, formikProps)
                if (conditionalOn && values[conditionalOn.key].value !== conditionalOn.value) return null

                return (
                  <motion.div
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    transition={{ duration: 0.2 }}
                    exit={{ opacity: 0 }}
                    key={key}
                  >
                    {!hideWithinLabel && <p className="mb-2 text-secondary">Over the past month…</p>}
                    <h2 className="h5 mb-4" id={`${key}-heading`}>
                      {question}
                    </h2>

                    <ChipGroup
                      className="mb-6"
                      key={key}
                      {...composeNestedHelperTextWithError('', errors, touched, key, 'value', submitCount > 0)}
                      aria-labelledby={`${key}-heading`}
                    >
                      <Grid container>
                        {cssrsResponses.map(({ label, value }) => {
                          return (
                            <Grid
                              key={label}
                              size={{
                                xs: 12,
                                sm: 'auto',
                              }}
                            >
                              <Radio
                                inline
                                checked={values[key].value === value}
                                onChange={() => setFieldValue(`${name}.value`, value)}
                                onBlur={onBlur}
                                name={name}
                                value={value.toString()}
                                dataTestId={`${name}-${value}`}
                                className="fs-exclude"
                              >
                                {label}
                              </Radio>
                            </Grid>
                          )
                        })}
                      </Grid>
                    </ChipGroup>
                  </motion.div>
                )
              })}
            </AnimatePresence>
            <FillButtonWithChevron type="submit" isLoading={loading}>
              Next
            </FillButtonWithChevron>
          </form>
        )
      }}
    </Formik>
  )
}

function buildInitialValues(data: AssessmentFromBundleQuery['values']): CssrsFormValues {
  const nullValue = { value: null }
  return {
    'cssrs:1': data['cssrs:1'] ?? nullValue,
    'cssrs:2': data['cssrs:2'] ?? nullValue,
    'cssrs:3': data['cssrs:3'] ?? nullValue,
    'cssrs:4': data['cssrs:4'] ?? nullValue,
    'cssrs:5': data['cssrs:5'] ?? nullValue,
    'cssrs:6': data['cssrs:6'] ?? nullValue,
    'cssrs:7': data['cssrs:7'] ?? nullValue,
    'cssrs:8': data['cssrs:8'] ?? nullValue,
  }
}

// If YES to 2, ask questions 3, 4, 5, 6. If NO to 2, go directly to question 7
// If YES to 7, ask question 8
const cssrsSchema = Yup.object().shape<CssrsFormValues>({
  'cssrs:1': validationForAssessmentFields,
  'cssrs:2': validationForAssessmentFields,
  'cssrs:3': Yup.mixed().when('cssrs:2', {
    is: val => val.value === 1,
    then: validationForAssessmentFields,
  }),
  'cssrs:4': Yup.mixed().when('cssrs:2', {
    is: val => val.value === 1,
    then: validationForAssessmentFields,
  }),
  'cssrs:5': Yup.mixed().when('cssrs:2', {
    is: val => val.value === 1,
    then: validationForAssessmentFields,
  }),
  'cssrs:6': Yup.mixed().when('cssrs:2', {
    is: val => val.value === 1,
    then: validationForAssessmentFields,
  }),
  'cssrs:7': validationForAssessmentFields,
  'cssrs:8': Yup.mixed().when('cssrs:7', {
    is: val => val.value === 1,
    then: validationForAssessmentFields,
  }),
})
