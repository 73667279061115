import { IconProps } from './IconProps'

export function IconLock({ size = 24, color = 'currentColor', ...props }: IconProps) {
  const height = (24 / 25) * size

  return (
    <svg width={size} height={height} viewBox="0 0 25 24" fill={color} xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17.5719 7.67905C17.5719 4.98395 15.2584 2.79834 12.4056 2.79834C9.55279 2.79834 7.23929 4.98395 7.23929 7.67905V10.1194C6.52586 10.1194 5.94727 10.666 5.94727 11.34V18.6611C5.94727 19.3351 6.52586 19.8817 7.23929 19.8817H17.5719C18.2853 19.8817 18.8639 19.3351 18.8639 18.6611V11.34C18.8639 10.666 18.2862 10.1194 17.5719 10.1194V7.67905ZM9.82244 7.67905C9.82244 6.45845 11.1136 5.2387 12.4056 5.2387C13.6976 5.2387 14.9888 6.45929 14.9888 7.67905V10.1194H9.82244V7.67905Z"
      />
    </svg>
  )
}
