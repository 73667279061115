import { styled } from '@mui/material'
import { CSSProperties } from 'react'

import { BlobAvatar, BlobAvatarProps } from './BlobAvatar'
import emptyAvatar from './img/empty-avatar.svg'

const sizes = {
  xl: 296,
  lg: 152,
  md: 96,
  sm: 64,
  xs: 32,
  mini: 24,
}

const wanderSpace = {
  xl: 50,
  lg: 50,
  md: 30,
  sm: 20,
}

interface CommonProps {
  url?: string | null
  size: keyof typeof sizes
  className?: string
  style?: CSSProperties
  error?: boolean
  customSize?: number
}

interface LargeAvatarProps extends Omit<BlobAvatarProps, 'size' | 'url'> {
  size: 'xl' | 'lg' | 'md' | 'sm'
}

type ConditionalProps = { size: 'xs' | 'mini' } | LargeAvatarProps

export type AvatarProps = CommonProps & ConditionalProps & { circle?: boolean }

export function Avatar(props: AvatarProps) {
  if (props.size === 'xs' || props.size === 'mini' || props.circle) {
    const { url, size, customSize, ...rest } = props as CommonProps
    return <CircleImage size={customSize ?? sizes[size]} src={url ?? emptyAvatar} {...rest} />
  }

  const { url, size, ...rest } = props
  return <BlobAvatar url={url ?? emptyAvatar} size={sizes[size]} wanderSpace={wanderSpace[size]} {...rest} />
}

const CircleImage = styled('img')<{ size: number }>`
  border-radius: 100%;
  height: ${props => props.size}px;
  object-fit: cover;
  width: ${props => props.size}px;
`
