import { ThemeProvider } from '@mui/material'
import { LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment'
import { IconContext } from 'react-icons/lib/cjs'
import { BrowserRouter, Route, Routes } from 'react-router-dom'

import { Role } from '@nuna/api'
import { StytchProvider, useAuthDataContext } from '@nuna/auth'
import { CommonRoutes, EnvironmentContextProvider } from '@nuna/environment'
import { FeatureFlagProvider } from '@nuna/feature-flag'
import { ConversationUnreadContextProvider } from '@nuna/messaging'
import { SessionDraftConflictContextProvider } from '@nuna/session-notes'
import { PageViewTracker, useSupportChat } from '@nuna/telemetry'
import { Toaster, globalStyles, harmonyAshokaStyles, tavaMuiTheme } from '@nuna/tunic'

import { environment } from '../environments/environment'
import { Telemetry } from '../scenes/Auth/Telemetry'
import { Login } from '../scenes/Login/Login'
import { ResetPassword } from '../scenes/Login/ResetPassword'
import { OverlappingSessionsModal } from '../scenes/Schedule/OverlappingSessionsModal'
import { ProviderNoCurrentSessionModal } from '../scenes/Schedule/ProviderNoCurrentSessionModal'
import { Signup } from '../scenes/signup/Signup'
import { GlobalWidgetContextProvider } from '../shared/GlobalWidgetContext'
import { clientDetail } from '../util/routes'
import { AuthenticatedApp } from './AuthenticatedApp'

const commonRoutes: Partial<CommonRoutes> = {
  sessionNote: (id: string) => `/sessions/${id}`,
  clientHarmonyProfile: clientDetail,
}

export function App() {
  const { login } = useAuthDataContext()
  useSupportChat({ audience: 'provider', environment })

  const role = login?.role

  const getHomeUrl = () => {
    if (role === Role.Customer) {
      return '/customer/integrations'
    }

    if (role === Role.Provider || role === Role.Admin) {
      return '/schedule'
    }

    return '/login'
  }

  return (
    <BrowserRouter>
      {globalStyles}
      {harmonyAshokaStyles}
      <Toaster />

      <EnvironmentContextProvider environmentValues={{ ...environment, commonRoutes: commonRoutes, app: 'harmony' }}>
        <FeatureFlagProvider initializeWithPii>
          <ThemeProvider theme={tavaMuiTheme}>
            <LocalizationProvider dateAdapter={AdapterMoment}>
              <StytchProvider>
                <GlobalWidgetContextProvider>
                  <ConversationUnreadContextProvider>
                    <SessionDraftConflictContextProvider>
                      <IconContext.Provider value={{ style: { verticalAlign: 'middle' } }}>
                        <PageViewTracker />
                        <Telemetry />
                        <Routes>
                          {/* Auth - public routes */}
                          <Route path="/login" element={<Login />} />
                          <Route path="/reset-password" element={<ResetPassword />} />
                          <Route path="/signup/*" element={<Signup />} />

                          <Route path="*" element={<AuthenticatedApp homeUrl={getHomeUrl()} />} />
                        </Routes>
                        <ProviderNoCurrentSessionModal />
                        <OverlappingSessionsModal />
                      </IconContext.Provider>
                    </SessionDraftConflictContextProvider>
                  </ConversationUnreadContextProvider>
                </GlobalWidgetContextProvider>
              </StytchProvider>
            </LocalizationProvider>
          </ThemeProvider>
        </FeatureFlagProvider>
      </EnvironmentContextProvider>
    </BrowserRouter>
  )
}
