import { isNil } from 'lodash'
import { useCallback } from 'react'
import { useNavigate } from 'react-router-dom'

import { useAttestationSignatureDataMutation } from '@nuna/api'
import { openEmbeddedSignUrl } from '@nuna/common'
import { errorService } from '@nuna/core'
import { useEnvironmentContext } from '@nuna/environment'
import { Box, Card, FillButton, greySet, toast } from '@nuna/tunic'

import { useCAQHSetupContext } from '../../../context/CAQHSetupContext'
import { useCAQHNavigation } from '../../../hooks/useCAQHNavigation'
import { CAQHStepper } from '../CAQHStepper'
import signatureImgSrc from '../img/signature.png'

export function AttestationSignature() {
  const environment = useEnvironmentContext()
  const { providerId, credentialData, resetCredentialingFlowStatus } = useCAQHSetupContext()
  const [attestationSignatureData] = useAttestationSignatureDataMutation()
  const { nextPath } = useCAQHNavigation()
  const navigate = useNavigate()

  const onSignEventListener = useCallback(() => {
    resetCredentialingFlowStatus()
    navigate(nextPath)
  }, [navigate, nextPath, resetCredentialingFlowStatus])

  const handleSignClick = async () => {
    try {
      const response = await attestationSignatureData({
        variables: {
          providerId,
        },
      })

      const attestationSigningUrl = response?.data?.requestAttestationSignatureData?.attestationSigningUrl

      if (!isNil(attestationSigningUrl)) {
        openEmbeddedSignUrl({
          clientId: environment.DROPBOX_SIGN_CLIENT_ID,
          testMode: !environment.production,
          url: attestationSigningUrl,
          listener: onSignEventListener,
        })
      }
    } catch (e) {
      toast.urgent(errorService.transformGraphQlError(e, 'There was an error saving your existing insurance details'))
      console.error(e)
    }
  }

  const { attestationDocumentId, attestationSignedAt, attestationSignatureId } =
    credentialData?.providerCredentialingData ?? {}
  const isAttestationCompleted =
    !isNil(attestationDocumentId) || (!isNil(attestationSignedAt) && !isNil(attestationSignatureId))

  return (
    <div>
      <h3>Attestation Signature Required</h3>
      <Box textAlign={'center'} className="mt-5">
        <Card style={{ backgroundColor: greySet[5].hex, borderColor: greySet[15].hex }} depth={0} shadowColor={'warm'}>
          <img src={signatureImgSrc} alt="" className="mt-4" />
          <h4 className="mt-1">We Need Your Signature</h4>
          <p>To complete your insurance setup, click below and then follow the instructions to sign.</p>
          <FillButton className="mt-2 mb-5" onClick={handleSignClick} disabled={!isNil(attestationSignedAt)}>
            Click Here To Sign
          </FillButton>
        </Card>
      </Box>
      <div className="mt-6">
        <CAQHStepper
          credentialingData={{
            attestationSignedAt: new Date(),
          }}
          disabled={!isAttestationCompleted}
        />
      </div>
    </div>
  )
}
