import { styled } from '@mui/material'

import {
  BelowTablet,
  Dialog,
  DialogContent,
  IconButton,
  IconCaution,
  IconClose,
  greySet,
  noteTakingIllustration,
  plumSet,
} from '@nuna/tunic'

type Props = {
  isOpen: boolean
  onClose: () => void
}

export function WhatToKnowModal({ isOpen, onClose }: Props) {
  return (
    <StyledDialog onClose={onClose} isOpen={isOpen}>
      <DialogContent paddingSize="md">
        <h4>What to know about Tava-managed directories</h4>
        <IconButton style={{ position: 'absolute', top: 32, right: 32 }} onClick={onClose} tooltip="Close">
          <IconClose />
        </IconButton>
        <ListSubSectionTitle className="body text-medium">
          With Tava-created directory profiles, you get:
        </ListSubSectionTitle>
        <StyledList>
          <li>
            <ListItemTitle>Increased exposure</ListItemTitle>
            <ListItemBody>
              Enjoy free directory memberships with profiles designed by Tava experts to increase your visibility and
              attract more referrals. Earn at our Tava referral rate for booked clients.
            </ListItemBody>
          </li>
          <li>
            <ListItemTitle>Hassle-free management</ListItemTitle>
            <ListItemBody>
              Our team takes care of setting up and updating your profiles, as well as handling all directory inquiries
              and referrals, giving you time back to concentrate fully on your clients.
            </ListItemBody>
          </li>
        </StyledList>
        <ListSubSectionTitle className="body text-medium">How it works:</ListSubSectionTitle>
        <StyledList>
          <li>
            <ListItemTitle>Initial enrollment</ListItemTitle>
            <ListItemBody className="v-align text-medium" style={{ color: plumSet[50].hex }}>
              <IconCaution className="mr-1" />
              You must enroll in insurance to be listed in directories.
            </ListItemBody>
          </li>
          <li>
            <ListItemTitle>Profile activation</ListItemTitle>
            <ListItemBody>
              After your insurance enrollment, which may take 3-7 + business days, we'll start creating your directory
              profile.
            </ListItemBody>
          </li>
          <li>
            <ListItemTitle>Monitoring success</ListItemTitle>
            <ListItemBody>
              If your directory listing isn't bringing in clients, we may pause or remove it temporarily to optimize
              performance.
            </ListItemBody>
          </li>
        </StyledList>
      </DialogContent>
      <div style={{ width: '100%', position: 'relative' }}>
        <StyledIllustration src={noteTakingIllustration} alt="Note-taking illustration" />
      </div>
    </StyledDialog>
  )
}

const StyledDialog = styled(Dialog)`
  max-width: 600px;
  margin: auto;
`

const StyledList = styled('ul')`
  padding: var(--spacing-2);
  margin: 0;
`

const ListItemTitle = styled('p')`
  font-size: 16px;
  color: ${greySet[80].hex};
  font-weight: 500;
  margin-bottom: 0;
  padding-bottom: 0;
`

const ListSubSectionTitle = styled('p')`
  color: ${greySet[90].hex};
  margin-top: var(--spacing-3);
`

const ListItemBody = styled('div')`
  margin-top: var(--spacing-1);
  margin-bottom: var(--spacing-2);
  max-width: 400px;
`

const StyledIllustration = styled('img')`
  width: 120px;
  position: absolute;
  bottom: 10px;
  right: 30px;

  @media (${BelowTablet}) {
    display: none;
  }
`
