import { styled } from '@mui/material'
import React, { useEffect } from 'react'

import { BasicPatientFragment, CapStatus, usePatientCapQuery } from '@nuna/api'
import { useIsAdmin } from '@nuna/auth'
import { TimeZoneControl, useTimeZoneContext } from '@nuna/common'
import { capInfoUtils } from '@nuna/coverage'
import { IconButton, IconClose, ProgressBar, StatusLabel } from '@nuna/tunic'

import { NewUserLink } from './NewUserLink'

interface SidebarPatientHeaderProps extends React.HTMLProps<HTMLDivElement> {
  patient?: BasicPatientFragment
  onClose?: React.MouseEventHandler<HTMLButtonElement>
  hideCapInfo?: boolean
  openPatientLinkInNewTab?: boolean
}

export function NewSidebarPatientHeader({
  patient,
  onClose,
  hideCapInfo = false,
  openPatientLinkInNewTab = false,
}: SidebarPatientHeaderProps) {
  const isAdmin = useIsAdmin()
  const { addUserInContext, removeUserInContext } = useTimeZoneContext()
  const {
    data,
    loading: isLoadingCap,
    error: didErrorLoadingCap,
  } = usePatientCapQuery({
    variables: { id: patient?.id ?? '' },
    skip: !patient,
    fetchPolicy: 'cache-and-network',
  })

  const isLoadingPatient = !patient
  const isLoading = isLoadingPatient || isLoadingCap

  const capInfo = data?.patient.appointmentConfiguration.capInfo

  const hasPaymentMethod = data?.patient.appointmentConfiguration.patientHasPaymentMethod
  const { progressBars, capPercentageUsed } = capInfoUtils.generateCapInfoDisplayData(capInfo, hasPaymentMethod)

  const remainingSessions =
    capInfo?.sessionAllowanceToType && capInfo?.sessionsCountingTowardsCap
      ? capInfo?.sessionAllowanceToType - capInfo?.sessionsCountingTowardsCap
      : null

  const isLastCoveredAppointment = !hasPaymentMethod && remainingSessions === 1

  useEffect(() => {
    if (patient?.timezone) {
      addUserInContext(patient)
    }
    return () => removeUserInContext('Patient')
  }, [patient, addUserInContext, removeUserInContext])

  return (
    <Container>
      <div className="v-align">
        <NewUserLink
          user={patient}
          openInNewTab={openPatientLinkInNewTab}
          variant="drawer-header"
          loading={isLoadingPatient}
        />
        {isAdmin && <TimeZoneControl className="ml-2" />}
        <IconButton tooltip="Close" variant="secondary" onClick={onClose} className="ml-auto">
          <IconClose size={20} />
        </IconButton>
      </div>

      {(() => {
        if (hideCapInfo) {
          return null
        }

        if (didErrorLoadingCap) {
          return <div className="mt-1 text-magenta">Error loading contract information for this patient.</div>
        }

        if (isLoadingCap || capInfo?.capStatus !== CapStatus.Nocap) {
          return (
            <>
              <div className={`mt-1 text-dark ${isLoading ? 'loading' : ''}`}>
                Covered for {capInfo?.sessionAllowanceToType} total sessions.
              </div>

              {capPercentageUsed > 0 && (
                <figure className={`v-align mt-1 mb-0 mx-0 ${isLoading ? 'loading' : ''}`}>
                  <ProgressBar style={{ maxWidth: '108px', transform: 'translateY(1px)' }} bars={progressBars} />
                  <figcaption className="ml-1 nowrap text-secondary">
                    {capInfo?.sessionsAttended} Attended • {capInfo?.futureAppointmentsInCap} Upcoming
                  </figcaption>
                </figure>
              )}

              {!hasPaymentMethod && isLastCoveredAppointment && (
                <StatusLabel intent="caution" className="mt-1">
                  one left to schedule
                </StatusLabel>
              )}

              {!hasPaymentMethod && remainingSessions === 0 && (
                <StatusLabel intent="urgent" className="mt-1">
                  none left to schedule
                </StatusLabel>
              )}
            </>
          )
        }

        return null
      })()}
    </Container>
  )
}

const Container = styled('div')`
  padding: 0 16px 16px 16px;
`
