import { HTMLAttributes } from 'react'
import { Route, Routes, useParams } from 'react-router-dom'

import { CancelPolicyItem } from '@nuna/core'

import { CancelPolicyCard } from './CancelPolicyCard'
import { CancelPolicyDetail } from './CancelPolicyDetail'
import { CancelPolicyForm } from './CancelPolicyForm'

interface Props extends HTMLAttributes<HTMLDivElement> {
  cancelPolicy: CancelPolicyItem
  providerId: string
  onEdit?: (cancelPolicy: CancelPolicyItem) => void
  onViewDetail?: (cancelPolicy: CancelPolicyItem) => void
}

export function CancelPolicyListItem(props: Props) {
  return (
    <Routes>
      <Route index element={<CancelPolicyCard {...props} />} />
      <Route path=":cancelPolicyId" element={<RoutableCancelPolicyListItem {...props} />} />
      <Route path=":cancelPolicyId/edit" element={<RoutableCancelPolicyListItem {...props} edit />} />
    </Routes>
  )
}

interface RoutableCancelPolicyListItemProps extends Props {
  edit?: boolean
}
function RoutableCancelPolicyListItem({
  cancelPolicy,
  providerId,
  edit = false,
  ...props
}: RoutableCancelPolicyListItemProps) {
  const params = useParams()
  const paramsMatch = params.cancelPolicyId === cancelPolicy.id

  if (edit && paramsMatch) {
    return <CancelPolicyForm cancelPolicy={cancelPolicy} providerId={providerId} {...props} />
  }

  if (paramsMatch) {
    return <CancelPolicyDetail cancelPolicy={cancelPolicy} providerId={providerId} {...props} />
  }

  return <CancelPolicyCard cancelPolicy={cancelPolicy} {...props} />
}
