import { useMemo } from 'react'
import { useMediaQuery } from 'react-responsive'

import {
  DataCollectionStep,
  useInsurancePayersProviderAcceptsQuery,
  usePotentialInsurancePayerCompensationsQuery,
  useProviderCredentialingDataQuery,
} from '@nuna/api'
import { useIsAdmin } from '@nuna/auth'
import { ExploreFirstEmptyState } from '@nuna/common'
import { PayersAcceptedByProviderTable } from '@nuna/coverage'
import { BelowTablet, GhostButtonLink, IconInfo, csx, greySet } from '@nuna/tunic'

import YogaSrc from './img/Yoga.png'

interface Props {
  providerId?: string | null
}

export function InsuranceAccepted({ providerId }: Props) {
  const isAdmin = useIsAdmin()
  const isMobile = useMediaQuery({ query: `(${BelowTablet})` })
  const { data: payersData, loading } = useInsurancePayersProviderAcceptsQuery({
    variables: { providerId: providerId ?? '' },
    skip: !providerId,
    fetchPolicy: 'cache-first',
    nextFetchPolicy: 'cache-first',
  })
  const { data: providerCredentialData, loading: loadingCredentialingData } = useProviderCredentialingDataQuery({
    variables: { providerId: providerId ?? '' },
    skip: !providerId,
  })
  const { data: compData } = usePotentialInsurancePayerCompensationsQuery({
    variables: {
      providerId: providerId ?? '',
    },
    skip: !providerId,
  })

  const rowData = useMemo(() => payersData?.insurancePayersProviderAccepts ?? [], [payersData])

  const shouldShowProfileEmptyState =
    !isAdmin &&
    !loadingCredentialingData &&
    (!providerCredentialData?.providerCredentialingData ||
      providerCredentialData.providerCredentialingData.currentStep !== DataCollectionStep.Completed) &&
    rowData.length === 0

  return (
    <div>
      <div className="v-align space-between">
        <div style={{ color: greySet[70].hex }} className="text-dark-grey body text-medium v-align">
          <IconInfo className="mr-1" />
          Enrollment can take 2 weeks to 5 months depending on the plan.
        </div>
      </div>

      <div className="relative" style={{ minHeight: shouldShowProfileEmptyState ? '550px' : 'auto' }}>
        <PayersAcceptedByProviderTable compData={compData} rowData={rowData} loading={loading} />
        {shouldShowProfileEmptyState && (
          <ExploreFirstEmptyState
            illustrationSrc={YogaSrc}
            illustrationStyle={{ maxWidth: '95px', height: '240px' }}
            textBlock={
              <div className={csx({ 'ml-5': !isMobile })}>
                <h2 className="h4 mt-0" style={{ maxWidth: '565px' }}>
                  Your book of insurance, where clients can easily verify their coverage with you.
                </h2>
                <p className="large mt-4">
                  <GhostButtonLink to="/signup-intake/resume-intake">Complete your profile</GhostButtonLink> to accept
                  insurance clients.
                </p>
              </div>
            }
            className={csx([{ 'pr-3': !isMobile }, 'v-align'])}
            topOffset={40}
          />
        )}
      </div>
    </div>
  )
}
