import * as Yup from 'yup'
import { styled } from '@mui/material'
import { FormControl, InputLabel, MenuItem, Select as MuiSelect, useTheme } from '@mui/material'
import { Formik, FormikHelpers } from 'formik'
import { AnimatePresence, motion } from 'framer-motion'
import { useState } from 'react'
import { formatPhoneNumber } from 'react-phone-number-input'

import {
  InviteRelationship,
  useInvitesQuery,
  usePublicCompanyQuery,
  useResendInvitationMutation,
  useSendInviteMutation,
} from '@nuna/api'
import { FocusAwareInput } from '@nuna/common'
import { formService } from '@nuna/core'
import {
  BelowTablet,
  Card,
  ContextualAlert,
  FillButton,
  GhostButton,
  Grid,
  IconCommentFilled,
  IconMailDeprecated,
  IconRelationship,
  IconSent,
  IconUser,
  TextField,
  TextField as TunicTextField,
  body2,
  error,
  greySet,
  interactiveFill,
  makeTypographyComponent,
  shadowDepth,
  toast,
} from '@nuna/tunic'

import { usePatientCoverageData } from '../hooks/usePatientCoverageData'
import familyAndFriendsIllustration from '../img/family-and-friends-illustration.png'

const { composeHelperTextWithError } = formService

interface InviteFormValues {
  firstName: string
  lastName: string
  email: string
  relationshipToInviter?: InviteRelationship
  message: string
  destinationCompanyReferralId?: string
}

interface Props {
  hideHeader?: boolean
  headerHeading?: string
  headerText?: string
  companyId?: string
}

/**
 * CompanyId is only passed in from ashoka or harmony.
 */
export function ClientReferral({ hideHeader = false, companyId }: Props) {
  const theme = useTheme()
  const { data } = useInvitesQuery()
  const {
    isEapAndDependentsCovered,
    isZeroCap,
    patientIsReferralIdRequired,
    patientCompanyOrPartnerName,
    patientCompanyPhoneNumber,
  } = usePatientCoverageData()

  const [sendInvite, { loading: sendLoading }] = useSendInviteMutation()
  const [isConfirmationShown, setIsConfirmationShown] = useState(false)

  // Okay this is where we track loading states for pending reuqests.
  const [invitesBeingResent, setInvitesBeingResent] = useState<string[]>([])
  const [resendInvite, { loading: resendLoading }] = useResendInvitationMutation()

  const familyInvites = data?.invites ?? []

  /*
   * This data only populates if comapnyId is passed in
   */
  const { data: publicCompanyData } = usePublicCompanyQuery({ variables: { id: companyId || '' }, skip: !companyId })
  const { publicCompany: company } = publicCompanyData ?? {}
  const { name: companyName, isReferralIdRequired } = company ?? {}

  const initialValues: InviteFormValues = {
    firstName: '',
    lastName: '',
    email: '',
    message: '',
    destinationCompanyReferralId: '',
  }

  const shouldDisableFormForPartners = (values: InviteFormValues): boolean => {
    const DISABLED_INVITE_RELAITIONSHIPS = [InviteRelationship.SpouseOrPartner, InviteRelationship.Child]
    if (patientIsReferralIdRequired) {
      return (
        (values.relationshipToInviter && DISABLED_INVITE_RELAITIONSHIPS.includes(values.relationshipToInviter)) || false
      )
    }
    return false
  }

  const getIconColor = ({ isFocused, isErrored }: { isFocused: boolean; isErrored: boolean }) => {
    if (isFocused) {
      return interactiveFill
    }

    if (isErrored) {
      return error
    }

    return body2
  }

  const handleSubmit = async (values: InviteFormValues, { resetForm }: FormikHelpers<InviteFormValues>) => {
    try {
      if (!companyId && !values.relationshipToInviter) {
        // TODO: Viper-277
        // this would suggest that relationshipToInviter to is not optional
        return
      }

      await sendInvite({
        variables: {
          inviteData: {
            ...values,
            relationshipToInviter: values.relationshipToInviter,
            destinationCompanyReferralId: values.destinationCompanyReferralId?.trim(),
            companyId,
          },
        },
        refetchQueries: ['Invites'],
      })

      resetForm()
      setIsConfirmationShown(true)
      setTimeout(() => setIsConfirmationShown(false), 2000)
    } catch (e) {
      toast.urgent('There was an error sending your invite')

      console.error(e)
    }
  }

  const handleResendClick = async (inviteId: string) => {
    setInvitesBeingResent([...invitesBeingResent, inviteId])

    try {
      await resendInvite({ variables: { id: inviteId } })
      setInvitesBeingResent(invitesBeingResent.filter(id => id !== inviteId))

      const email = familyInvites.find(invite => invite.id === inviteId)?.email
      if (email) {
        toast.success(`We've resent the invite to ${email}`)
      }
    } catch (e) {
      setInvitesBeingResent(invitesBeingResent.filter(id => id !== inviteId))
      toast.urgent(`There was an issue sending the invite`)
    }
  }

  return (
    <>
      {!hideHeader && (
        <Grid className="pt-4" container alignItems="center" component="header" spacing={3} justifyContent="flex-end">
          <Grid
            size={{
              xs: 12,
              sm: 'grow',
            }}
          >
            <h1 className="h3 mb-1" style={{ maxWidth: 420 }}>
              Share healing
            </h1>
            {isEapAndDependentsCovered && !companyId && (
              <div className="text-secondary large">
                Invite someone to try Tava and get the help they need. They'll get an invite to sign up and can pay with
                their insurance, employer, or out-of-pocket.
                {!isZeroCap
                  ? ' If you invite a spouse or child (age 13-26), their care will be covered through your employer.'
                  : ''}
              </div>
            )}
            {!isEapAndDependentsCovered && !companyId && (
              <div className="text-secondary large">
                Invite someone to try Tava and get the help they need. They'll get an invite to sign up and can pay with
                their insurance, employer, or out-of-pocket.
              </div>
            )}
            {companyId && (
              <div className="text-secondary large">
                Invite a member to your sponsored coverage of Tava. They'll get an invite to sign up and will
                automatically be linked under {companyName} as their employer sponsor.
              </div>
            )}
          </Grid>
          <Grid
            size={{
              xs: 12,
              sm: 'auto',
            }}
          >
            <img style={{ maxWidth: 264 }} src={familyAndFriendsIllustration} alt="" />
          </Grid>
        </Grid>
      )}
      <Formik
        initialValues={initialValues}
        validationSchema={buildSchema(!!isReferralIdRequired, companyId)}
        onSubmit={handleSubmit}
      >
        {({ values, errors, touched, handleBlur, handleSubmit, handleChange }) => {
          const commonProps = {
            onChange: handleChange,
            onBlur: handleBlur,
          }

          return (
            <FormCard className="mt-5">
              <form onSubmit={handleSubmit}>
                <Grid container spacing={3}>
                  <Grid
                    size={{
                      xs: 12,
                      md: 5,
                    }}
                  >
                    <Grid container spacing={2} alignItems="baseline" wrap="nowrap">
                      <FocusAwareInput>
                        {(isFocused, setIsFocused) => (
                          <>
                            <Grid>
                              <IconContainer>
                                <IconUser
                                  color={getIconColor({
                                    isFocused,
                                    isErrored: (touched.firstName && errors.firstName) as boolean,
                                  })}
                                  size={24}
                                  style={{ marginBottom: '-8px' }}
                                />
                              </IconContainer>
                            </Grid>
                            <Grid size={6}>
                              <TextField
                                {...commonProps}
                                name="firstName"
                                value={values.firstName}
                                label="First Name"
                                {...composeHelperTextWithError('', errors.firstName, touched.firstName)}
                                onFocus={() => {
                                  setIsFocused(true)
                                }}
                                onBlur={e => {
                                  setIsFocused(false)
                                  handleBlur(e)
                                }}
                              />
                            </Grid>
                          </>
                        )}
                      </FocusAwareInput>

                      <Grid size={6}>
                        <TextField
                          {...commonProps}
                          name="lastName"
                          value={values.lastName}
                          label="Last Name"
                          {...composeHelperTextWithError('', errors.lastName, touched.lastName)}
                        />
                      </Grid>
                    </Grid>
                  </Grid>

                  <Grid
                    size={{
                      xs: 12,
                      md: 'grow',
                    }}
                  >
                    <Grid
                      container
                      alignItems={touched.email && errors.email ? 'center' : 'flex-end'}
                      spacing={2}
                      wrap="nowrap"
                    >
                      <FocusAwareInput>
                        {(isFocused, setIsFocused) => (
                          <>
                            <Grid>
                              <IconContainer>
                                <IconMailDeprecated
                                  style={{ transform: 'translateY(5px)', marginRight: -4, marginLeft: -4 }}
                                  size={32}
                                  color={getIconColor({
                                    isFocused,
                                    isErrored: (touched.email && errors.email) as boolean,
                                  })}
                                />
                              </IconContainer>
                            </Grid>
                            <Grid size={12}>
                              <TextField
                                {...commonProps}
                                name="email"
                                value={values.email}
                                label="Email"
                                {...composeHelperTextWithError('', errors.email, touched.email)}
                                onFocus={() => setIsFocused(true)}
                                onBlur={e => {
                                  setIsFocused(false)
                                  handleBlur(e)
                                }}
                              />
                            </Grid>
                          </>
                        )}
                      </FocusAwareInput>
                    </Grid>
                  </Grid>
                  {companyId && isReferralIdRequired && (
                    <Grid
                      size={{
                        xs: 12,
                        md: 'grow',
                      }}
                    >
                      <Grid container alignItems="flex-end" spacing={2} wrap="nowrap">
                        <Grid size={12}>
                          <TextField
                            {...commonProps}
                            name="destinationCompanyReferralId"
                            value={values.destinationCompanyReferralId}
                            label="Case ID"
                            {...composeHelperTextWithError(
                              '',
                              errors.destinationCompanyReferralId,
                              touched.destinationCompanyReferralId,
                            )}
                          />
                        </Grid>
                      </Grid>
                    </Grid>
                  )}
                  {!companyId && (
                    <Grid
                      size={{
                        xs: 12,
                        md: 'grow',
                      }}
                    >
                      <Grid spacing={2} alignItems="flex-end" container wrap="nowrap">
                        <FocusAwareInput>
                          {(isFocused, setIsFocused) => (
                            <>
                              <Grid>
                                <IconContainer>
                                  <IconRelationship
                                    color={getIconColor({
                                      isFocused,
                                      isErrored: (touched.relationshipToInviter &&
                                        errors.relationshipToInviter) as boolean,
                                    })}
                                    size={28}
                                  />
                                </IconContainer>
                              </Grid>
                              <Grid size={12}>
                                <FormControl fullWidth>
                                  <InputLabel id="relationship-select-label">Relationship</InputLabel>
                                  <MuiSelect
                                    labelId="relationship-select-label"
                                    value={values.relationshipToInviter ?? ''}
                                    name="relationshipToInviter"
                                    onFocus={() => setIsFocused(true)}
                                    {...commonProps}
                                  >
                                    <MenuItem value={InviteRelationship.Child}>
                                      <RelationshipOption>
                                        <span>Child</span>
                                        {isEapAndDependentsCovered && !isZeroCap && (
                                          <span className="caption text-medium-grey">Fully Covered</span>
                                        )}
                                      </RelationshipOption>
                                    </MenuItem>
                                    <MenuItem value={InviteRelationship.SpouseOrPartner}>
                                      <RelationshipOption>
                                        <span>Spouse</span>
                                        {isEapAndDependentsCovered && !isZeroCap && (
                                          <span className="caption text-medium-grey">Fully Covered</span>
                                        )}
                                      </RelationshipOption>
                                    </MenuItem>
                                    <MenuItem value={InviteRelationship.Parent}>Parent</MenuItem>
                                    <MenuItem value={InviteRelationship.Sibling}>Sibling</MenuItem>
                                    <MenuItem value={InviteRelationship.ExtendedFamily}>Extended Family</MenuItem>
                                    <MenuItem value={InviteRelationship.Friend}>Friend</MenuItem>
                                    <MenuItem value={InviteRelationship.Colleague}>Colleague</MenuItem>
                                    <MenuItem value={InviteRelationship.Other}>Someone else</MenuItem>
                                  </MuiSelect>
                                </FormControl>
                              </Grid>
                            </>
                          )}
                        </FocusAwareInput>
                      </Grid>
                    </Grid>
                  )}

                  <MessageGrid size={12} style={{ marginTop: '1rem' }} breakpoint={theme.breakpoints.down('sm')}>
                    <Grid container wrap="nowrap" alignItems="flex-start" spacing={2}>
                      <FocusAwareInput>
                        {(isFocused, setIsFocused) => (
                          <>
                            <Grid>
                              <IconContainer>
                                <IconCommentFilled
                                  color={getIconColor({
                                    isFocused,
                                    isErrored: (touched.message && errors.message) as boolean,
                                  })}
                                  style={{ transform: 'translateY(3px)' }}
                                />
                              </IconContainer>
                            </Grid>

                            <Grid size={12}>
                              <TunicTextField
                                style={{ marginTop: '-16px' }}
                                multiline
                                className="fs-exclude"
                                name="message"
                                value={values.message}
                                onChange={handleChange}
                                rows={2}
                                maxCharacters={1000}
                                placeholder={
                                  companyId && isReferralIdRequired
                                    ? 'Add a message for client (optional)'
                                    : 'Add a personal message (optional)'
                                }
                                error={!!(touched.message && errors.message)}
                                onFocus={() => setIsFocused(true)}
                                onBlur={e => {
                                  setIsFocused(false)
                                  handleBlur(e)
                                }}
                              />
                            </Grid>
                          </>
                        )}
                      </FocusAwareInput>
                    </Grid>
                  </MessageGrid>

                  <ButtonGrid size={{ xs: 12, md: 'auto' }} breakpoint={theme.breakpoints.down('sm')}>
                    <FillButton disabled={shouldDisableFormForPartners(values)} type="submit" isLoading={sendLoading}>
                      Send Invite
                    </FillButton>
                  </ButtonGrid>
                  {shouldDisableFormForPartners(values) && (
                    <Grid size={12}>
                      <ContextualAlert sx={{ marginTop: '13px' }} intent="information">
                        Reach out to {patientCompanyOrPartnerName} at{' '}
                        {formatPhoneNumber(patientCompanyPhoneNumber ?? '')} to invite your dependents to Tava instead
                        of using this form.
                      </ContextualAlert>
                    </Grid>
                  )}
                </Grid>

                <AnimatePresence>
                  {isConfirmationShown && (
                    <InvitationConfirmation initial={{ opacity: 0 }} animate={{ opacity: 1 }} exit={{ opacity: 0 }}>
                      <div className="text-center large">
                        <IconSent size={84} />
                        <p>Invitation Sent</p>
                      </div>
                    </InvitationConfirmation>
                  )}
                </AnimatePresence>
              </form>
            </FormCard>
          )
        }}
      </Formik>
      {familyInvites.length > 0 && (
        <>
          <h2 className="h6 sans-serif mt-5">People you've invited</h2>
          <ul className="unstyled-list" style={{ maxWidth: 450 }}>
            {familyInvites.map(invite => (
              <li key={invite.id} className="mb-1 v-align" style={{ justifyContent: 'space-between' }}>
                <span>{invite.email}</span>
                <GhostButton
                  isLoading={resendLoading && invitesBeingResent.includes(invite.id)}
                  onClick={() => handleResendClick(invite.id)}
                >
                  Resend Invite
                </GhostButton>
              </li>
            ))}
          </ul>
        </>
      )}
    </>
  )
}

const buildSchema = (isReferralIdRequired: boolean, companyId?: string) => {
  const schema = Yup.object().shape<InviteFormValues>({
    firstName: Yup.string().required('First name is required'),
    lastName: Yup.string().required('Last name is required'),
    email: Yup.string().required('Email is required').email('Must be a valid email format'),
    message: Yup.string(),
    destinationCompanyReferralId: isReferralIdRequired ? Yup.string().required('Case ID is required') : Yup.string(),
    // TODO: need to get the "required" relationship box to show up red properly
    relationshipToInviter: !companyId
      ? Yup.mixed<InviteRelationship>().required('Must select relationship')
      : undefined,
  })
  return schema
}

const MessageGrid = styled(Grid)<{ breakpoint: string }>`
  order: 1;

  && {
    ${props => props.breakpoint} {
      order: 0;
    }
  }
`

const ButtonGrid = styled(Grid)<{ breakpoint: string }>`
  && {
    ${props => props.breakpoint} {
      order: 1;
      margin-top: 1.5rem;

      button {
        width: 100%;
      }
    }
  }
`

const FormCard = styled(Card)`
  background-color: #fff;
  position: relative;
  overflow: hidden;
  border-radius: var(--border-radius);
  border: 1px solid ${greySet[5].hex};
  box-shadow: ${shadowDepth(1)};
  padding: 1.5rem 2rem 2.5rem;
`

const InvitationConfirmation = styled(motion.div)`
  align-items: center;
  background-color: ${greySet[5].hex};
  color: ${body2};
  display: flex;
  height: 100%;
  justify-content: center;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 1;
`

const IconContainer = styled('div')`
  @media (${BelowTablet}) {
    width: 28px;
  }
`

const RelationshipOption = makeTypographyComponent('space-between v-align full-width', 'div')
