import { Autocomplete, AutocompleteProps, TextField } from '@mui/material'
import FuzzySearch from 'fuzzy-search'
import { useMemo, useState } from 'react'

import { LicenseAbbreviation } from '@nuna/api'

type LimitedAutocompleteProps = Omit<
  AutocompleteProps<string, true, boolean | undefined, false>,
  'onChange' | 'options' | 'renderInput'
>

interface Props extends LimitedAutocompleteProps {
  value?: string[]
  onChange: (credentials: string[]) => void
  label?: string
}

const credentials = Object.values(LicenseAbbreviation) as string[]

export function CredentialsSelect({ value, onChange, label = 'Credentials', ...props }: Props) {
  const [searchTerm, setSearchTerm] = useState('')

  const filteredCredentials = useMemo<string[]>(() => {
    const searcher = new FuzzySearch(credentials, [], {
      caseSensitive: false,
    })
    if (!searchTerm) {
      return credentials
    }
    return searcher.search(searchTerm)
  }, [searchTerm])

  return (
    <Autocomplete
      {...props}
      multiple
      onChange={(_event, value) => {
        onChange(value)
      }}
      getOptionLabel={credential => credential}
      options={filteredCredentials}
      value={value}
      ListboxProps={{ className: 'MuiAutocomplete-listbox' }}
      onInputChange={(_event, searchTerm) => setSearchTerm(searchTerm)}
      renderInput={params => <TextField name="selectCredential" label={label} {...params} />}
    />
  )
}
