import { Formik } from 'formik'

import { FillButton, toast } from '@nuna/tunic'

import { useSaveAccessCodeCoverage } from '../../../hooks/useSaveAccessCodeCoverage'
import {
  AccessCodeCoverageForm,
  AccessCodeCoverageFormValues,
  CoverageFormPatient,
  accessCodeInitialValues,
  accessCodeSchema,
} from '../../CoverageForm'

interface Props {
  client: CoverageFormPatient
  onSubmitComplete: () => void
}

export function DrawerAccessCodeForm({ client, onSubmitComplete }: Props) {
  const [savePatientAccessCode, { loading }] = useSaveAccessCodeCoverage({
    refetchQueries: ['PatientCoverage'],
  })

  const handleSubmit = async (values: { accessCodeValues: AccessCodeCoverageFormValues }) => {
    const response = await savePatientAccessCode({
      variables: {
        id: client.id,
        code: values.accessCodeValues?.accessCode ?? '',
      },
    })

    if (!response.didSucceed) {
      toast.urgent(response.error?.message)
      return
    }

    onSubmitComplete()
  }

  return (
    <Formik
      validationSchema={accessCodeSchema}
      initialValues={{ accessCodeValues: accessCodeInitialValues }}
      onSubmit={handleSubmit}
    >
      {({ handleSubmit }) => (
        <form onSubmit={handleSubmit}>
          <AccessCodeCoverageForm isCheckout />

          <FillButton className="mt-4" isLoading={loading} type="submit">
            Verify code
          </FillButton>
        </form>
      )}
    </Formik>
  )
}
