import { styled } from '@mui/material'
import { capitalize } from 'lodash'
import { HTMLAttributes } from 'react'

import { greySet, tealSet } from '../../styles/colorSets'
import { body2 } from '../../styles/colors'
import { fontSize } from '../../styles/global/typography.utils'
import { toast } from '../Toast'

export interface CopyButtonProps extends Omit<HTMLAttributes<HTMLButtonElement>, 'children' | 'onClick'> {
  value: string
  valueType?: string
  label?: string
}

export function CopyButton({ value, valueType = 'value', style, label, ...props }: CopyButtonProps) {
  const handleClick = () => {
    navigator.clipboard.writeText(value)
    toast.info(`${capitalize(valueType)} copied to clipboard`)
  }

  return (
    <StyledButton
      {...props}
      type="button"
      onClick={handleClick}
      style={{ ...style, maxWidth: style?.maxWidth ?? '100%' }}
    >
      <span className="value">{label ? label : value}</span>
      <span className="copy-indicator text-bold ml-1">COPY</span>
    </StyledButton>
  )
}

const StyledButton = styled('button')`
  align-items: center;
  border: 1px solid ${greySet[15].hex};
  border-radius: 4px;
  box-sizing: border-box;
  color: ${body2};
  display: flex;
  font-size: ${fontSize.caption};
  padding: 5px;

  .value {
    flex: 1;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .copy-indicator {
    color: ${tealSet[70].hex};
    display: none;
    font-weight: 500;
  }

  &:hover {
    border-color: ${tealSet[50].hex};
    background-color: ${tealSet.tint[20]};
    .copy-indicator {
      display: inline-block;
    }
  }
`
