import { styled } from '@mui/material'
import { useMemo, useState } from 'react'
import { useMediaQuery } from 'react-responsive'

import {
  BelowTablet,
  FillButton,
  Grid,
  IconButton,
  IconChevronRight,
  IconClose,
  TextButtonExternalLink,
  TextField,
  bodyPrimary,
  bodySecondary,
  borderGrey,
  csx,
  eggshell,
  fontSize,
  greySet,
  plumSet,
  sansSerifFontStack,
} from '@nuna/tunic'

import { useProviderSignupIntakeContext } from '../../SignupIntake/ProviderSignupIntakeContext'

type DisclosureSteps = 1 | 2 | 3

interface Props {
  onClose: () => void
  isCalifornia: boolean
  onAgreed: () => void
}

export function BackgroundCheckDisclosures({ onClose, isCalifornia, onAgreed }: Props) {
  const isMobile = useMediaQuery({ query: `(${BelowTablet})` })
  const { saveIntakeLoading } = useProviderSignupIntakeContext()
  const [step, setStep] = useState<DisclosureSteps>(1)
  const [legalName, setLegalName] = useState('')
  const headerText = useMemo(() => {
    switch (step) {
      case 1:
        return 'Summary of your rights'
      case 2:
        return isCalifornia ? 'California Disclosure' : 'Disclosure'
      case 3:
        return 'Authorization'
      default:
        return 'Summary of your rights'
    }
  }, [step, isCalifornia])
  return (
    <>
      <Container className="flex-column top-section">
        <div className="v-align">
          <IconButton
            type="button"
            tabIndex={1}
            variant="secondary"
            className="ml-auto"
            tooltip="Close"
            onClick={onClose}
          >
            <IconClose />
          </IconButton>
        </div>
        <DisclosuresInnerWrapper className="flex-column" style={{ overflow: 'hidden' }}>
          <StepHeader>
            <div className="caption steps uppercase">Step {step} of 3</div>
            <PrimaryHeader className="text-default">{headerText}</PrimaryHeader>
          </StepHeader>

          {(() => {
            switch (step) {
              case 1:
                return <StepOne />
              case 2:
                return isCalifornia ? <CaliforniaStepTwo /> : <StepTwo />
              case 3:
                return <StepThree />
            }
          })()}
        </DisclosuresInnerWrapper>
      </Container>
      <Footer className="mt-3">
        <DisclosuresInnerWrapper>
          <div className="footer-content">
            {(() => {
              switch (step) {
                case 1:
                  return (
                    <Container className="v-align space-between">
                      <Grid container alignContent="center" spacing={3}>
                        <Grid
                          className="caption"
                          size={{
                            xs: 12,
                            md: 8,
                          }}
                        >
                          By clicking agree l acknowledge receipt of the Summary of Your Rights Under the Fair Credit
                          Reporting Act (FCRA) and certify that I have read and understand this document.
                        </Grid>
                        <Grid
                          size={{
                            xs: 12,
                            md: 4,
                          }}
                        >
                          <FillButton
                            data-testid="disclosures-continue-button"
                            type="button"
                            tabIndex={0}
                            onClick={() => setStep(2)}
                            className={csx([{ 'ml-auto': !isMobile }, 'v-align mobile-fullwidth'])}
                          >
                            Agree and Continue <IconChevronRight className="ml-1" />
                          </FillButton>
                        </Grid>
                      </Grid>
                    </Container>
                  )
                case 2:
                  return (
                    <Container className="v-align space-between">
                      <Grid container alignContent="center" spacing={3}>
                        <Grid
                          className="caption"
                          size={{
                            xs: 12,
                            md: 8,
                          }}
                        >
                          By clicking agree I acknowledge receipt of the Disclosure Regarding Background Investigation
                          and certify that I have read and understand this document.
                        </Grid>
                        <Grid
                          size={{
                            xs: 12,
                            md: 4,
                          }}
                        >
                          <FillButton
                            data-testid="disclosures-continue-button"
                            type="button"
                            tabIndex={0}
                            onClick={() => setStep(3)}
                            className={csx([{ 'ml-auto': !isMobile }, 'v-align mobile-fullwidth'])}
                          >
                            Agree and Continue <IconChevronRight className="ml-1" />
                          </FillButton>
                        </Grid>
                      </Grid>
                    </Container>
                  )
                case 3:
                  return (
                    <Container>
                      <div className="text-bold">ELECTRONIC SIGNATURE</div>
                      <p className="mb-3">
                        By typing my name below, I consent to the background checks and indicate my agreement to all of
                        the above.
                      </p>
                      <Grid container spacing={3} className="mb-2">
                        <Grid
                          size={{
                            xs: 12,
                            md: 6,
                          }}
                        >
                          <TextField
                            inputProps={{ 'data-testid': 'disclosures-name-input' }}
                            label="Full Legal Name"
                            value={legalName}
                            autoFocus
                            onChange={e => setLegalName(e.currentTarget.value)}
                            tabIndex={0}
                          />
                        </Grid>
                        <Grid
                          size={{
                            xs: 12,
                            md: 6,
                          }}
                        >
                          <FillButton
                            type="button"
                            data-testid="disclosures-continue-button"
                            isLoading={saveIntakeLoading}
                            onClick={onAgreed}
                            disabled={!legalName}
                            className="v-align mobile-fullwidth"
                          >
                            {isMobile ? `Agree & Initiate Check ` : `Agree & Initiate Background Check `}
                            <IconChevronRight className="ml-1" />
                          </FillButton>
                        </Grid>
                      </Grid>
                    </Container>
                  )
              }
            })()}
          </div>
        </DisclosuresInnerWrapper>
      </Footer>
    </>
  )
}

function StepOne() {
  return (
    <ContentBox>
      <p>
        Para información en español, visite{' '}
        <a href="http://www.consumerfinance.gov/learnmore" tabIndex={1} target="_blank" rel="noreferrer">
          www.consumerfinance.gov/learnmore
        </a>{' '}
        o escribe a la Consumer Financial Protection Bureau, 1700 G Street N.W., Washington, DC 20552.
      </p>
      <h4>A Summary of Your Rights Under the Fair Credit Reporting Act</h4>
      <p>
        The federal Fair Credit Reporting Act (FCRA) promotes the accuracy, fairness, and privacy of information in the
        files of consumer reporting agencies. There are many types of consumer reporting agencies, including credit
        bureaus and specialty agencies (such as agencies that sell information about check writing histories, medical
        records, and rental history records). Here is a summary of your major rights under the FCRA.{' '}
        <b>
          For more information, including information about additional rights, go to{' '}
          <a href="http://www.consumerfinance.gov/learnmore" target="_blank" rel="noreferrer" tabIndex={1}>
            www.consumerfinance.gov/learnmore
          </a>{' '}
          or write to: Consumer Financial Protection Bureau, 1700 G Street N.W., Washington, DC 20552.
        </b>
      </p>
      <p>
        <b>You must be told if information in your file has been used against you.</b> Anyone who uses a credit report
        or another type of consumer report to deny your application for credit, insurance, or employment - or to take
        another adverse action against you - must tell you, and must give you the name, address, and phone number of the
        agency that provided the information.
      </p>
      <p>
        <b>You have the right to know what is in your file.</b> You may request and obtain all the information about you
        in the files of a consumer reporting agency (your "file disclosure"). You will be required to provide proper
        identification, which may include your Social Security number. In many cases, the disclosure will be free. You
        are entitled to a free file disclosure if: - a person has taken adverse action against you because of
        information in your credit report; - you are the victim of identify theft and place a fraud alert in your file;
        - your file contains inaccurate information as a result of fraud; - you are on public assistance; you are
        unemployed but expect to apply for employment within 60 days.
      </p>
    </ContentBox>
  )
}

function StepTwo() {
  return (
    <ContentBox>
      <h4>Disclosure Regarding Background Investigation</h4>
      <p>
        Tava Health (the "Company") may obtain information about you from a third-party consumer reporting agency for
        employment purposes, contracting purposes, or other permissible purposes. Thus, you may be the subject of a
        "consumer report" which may include information about your character, general reputation, personal
        characteristics, and/or mode of living. These reports may contain information regarding your credit history,
        criminal history, motor vehicle records ("driving records"), verification of your education or employment
        history, or other background checks.
      </p>
      <p>
        You have the right, upon written request made within a reasonable time, to request whether a consumer report has
        been run about you and to request a copy of your report. These searches will be conducted by Checkr, Inc., One
        Montgomery Street, Suite 2400, San Francisco, CA 94104 | (844) 824-3257 |
        <a href="https://candidate.checkr.com" target="_blank" rel="noreferrer">
          https://candidate.checkr.com
        </a>
        .
      </p>
    </ContentBox>
  )
}

function CaliforniaStepTwo() {
  return (
    <ContentBox>
      <h4>Notice Regarding Background Checks per California Law</h4>
      <p>
        Tava Health (the "Company") intends to obtain information about you for employment screening purposes,
        contracting purposes, or other permissible purposes from a consumer reporting agency. Thus, you can expect to be
        the subject of an "investigative consumer report" and a "consumer credit report" obtained for employment
        purposes, contracting purposes, or other permissible purposes.
      </p>
      <p>
        Such reports may include information about your character, general reputation, personal characteristics and mode
        of living. With respect to any investigative consumer report from an investigative consumer reporting agency
        ("ICRA"), the Company may investigate the information contained in your employment application and other
        background information about you, including but not limited to obtaining a criminal record report, verifying
        references, work history, your social security number, your educational achievements, licensure, and
        certifications, your driving record, and other information about you, and interviewing people who are
        knowledgeable about you. The results of this report may be used as a factor in making employment decisions or
        decisions pertaining to an independent contractor relationship between you and the Company. The source of any
        investigative consumer report (as that term is defined under California law) will be Checkr, Inc., One
        Montgomery Street, Suite 2400, San Francisco, CA 94104 | (844) 824-3257 |{' '}
        <a href="https://candidate.checkr.com" rel="noreferrer" target="_blank">
          https://candidate.checkr.com
        </a>
        . The Company agrees to provide you with a copy of an investigative consumer report when required to do so under
        California law.
      </p>
      <p>
        Under California Civil Code section 1786.22, you are entitled to find out what is in the CRA's file on you with
        proper identification, as follows:
      </p>
      <ul>
        <li>
          In person, by visual inspection of your file during normal business hours and on reasonable notice. You also
          may request a copy of the information in person. The CRA may not charge you more than the actual copying costs
          for providing you with a copy of your file.
        </li>
        <li>
          A summary of all information contained in the CRA file on you that is required to be provided by the
          California Civil Code will be provided to you via telephone, if you have made a written request, with proper
          identification, for telephone disclosure, and the toll charge, if any, for the telephone call is prepaid by or
          charged directly to you.
        </li>
        <li>
          By requesting a copy be sent to a specified addressee by certified mail. CRAs complying with requests for
          certified mailings shall not be liable for disclosures to third parties caused by mishandling of mail after
          such mailings leave the CRAs.
        </li>
      </ul>
      <p>
        "Proper Identification" includes documents such as a valid driver's license, social security account number,
        military identification card, and credit cards. Only if you cannot identify yourself with such information may
        the CRA require additional information concerning your employment and personal or family history in order to
        verify your identity.
      </p>
      <p>
        The CRA will provide trained personnel to explain any information furnished to you and will provide a written
        explanation of any coded information contained in files maintained on you. This written explanation will be
        provided whenever a file is provided to you for visual inspection. You may be accompanied by one other person of
        your choosing, who must furnish reasonable identification. An CRA may require you to furnish a written statement
        granting permission to the CRA to discuss your file in such person's presence.
      </p>
    </ContentBox>
  )
}

function StepThree() {
  return (
    <ContentBox>
      <h4>Acknowledgement and Authorization for Background Check</h4>
      <p>
        I acknowledge receipt of the separate documents entitled Disclosure Regarding Background Investigation and A
        Summary of Your Rights Under the Fair Credit Reporting Act and certify that I have read and understand both of
        those documents. I hereby authorize the obtaining of "consumer reports" and/or "investigative consumer reports"
        by Tava Health, Inc. (the "Company") at any time after receipt of this authorization and throughout my
        employment or independent contractor relationship, if applicable. To this end, I hereby authorize any law
        enforcement agency, administrator, state or federal agency, institution, school or university (public or
        private), information service bureau, past or present employers, motor vehicle records agencies, or insurance
        company to furnish any and all background information requested by{' '}
        <b>
          Checkr, Inc., One Montgomery Street, Suite 2400, San Francisco, CA 94104 | (844) 824-3257 |{' '}
          <a href="https://candidate.checkr.com" rel="noreferrer" target="_blank">
            https://candidate.checkr.com
          </a>
        </b>{' '}
        and/or the Company. I agree that a facsimile ("fax"), electronic, or photographic copy of this Authorization
        shall be as valid as the original.
      </p>
      <p>
        <b>New York residents/candidates only:</b> Upon request, you will be informed whether or not a consumer report
        was requested by the Employer, and if such report was requested, informed of the name and address of the
        consumer reporting agency that furnished the report. You have the right to inspect and receive a copy of any
        investigative consumer report requested by the Employer by contacting the consumer reporting agency identified
        above directly. By signing below, you acknowledge receipt of{' '}
        <TextButtonExternalLink href="https://www.labor.ny.gov/formsdocs/wp/correction-law-article-23a.pdf">
          Article 23-A of the New York Correction Law
        </TextButtonExternalLink>
        . You acknowledge and authorize the Employer to provide any notices required by federal, state or local law to
        you at the address(es) and/or email address(es) you provided to the Employer.
      </p>
      <p>
        <b>Washington State candidates only:</b> You also have the right to request from the consumer reporting agency a
        written summary of your rights and remedies under the Washington Fair Credit Reporting Act. Minnesota and
        Oklahoma candidates only: Please check the box below if you would like to receive a copy of a consumer report if
        one is obtained by the Company.
      </p>
      <p>
        <b>San Francisco candidates only:</b> Please click below for the San Francisco Fair Chance Act Notice. -{' '}
        <TextButtonExternalLink href="https://s3.amazonaws.com/checkr/public/SFFairChanceNotice.pdf">
          English
        </TextButtonExternalLink>
        -{' '}
        <TextButtonExternalLink href="https://s3.amazonaws.com/checkr/public/SFOrdinance-Spanish.pdf">
          Spanish
        </TextButtonExternalLink>{' '}
        -{' '}
        <TextButtonExternalLink href="https://s3.amazonaws.com/checkr/public/SFOrdinance-Tagalog.pdf">
          Tagalog
        </TextButtonExternalLink>{' '}
        -{' '}
        <TextButtonExternalLink href="https://s3.amazonaws.com/checkr/public/SFOrdinance-Chinese.pdf">
          Chinese
        </TextButtonExternalLink>{' '}
      </p>
      <p>
        <b>Los Angeles candidates only:</b> Please{' '}
        <TextButtonExternalLink href="https://bca.lacity.org/Uploads/fciho/Notice%20to%20Applicants%20and%20Employees%20for%20Private%20Employers.pdf">
          click here
        </TextButtonExternalLink>{' '}
        for the Los Angeles Notice to Candidates and Employees for Private Employers.
      </p>
    </ContentBox>
  )
}

const Container = styled('div')`
  padding: var(--margin-2);
  color: ${bodySecondary};
  .steps {
    color: ${plumSet.primary.hex};
  }

  &.top-section {
    flex: 1;
    overflow: hidden;
  }
`
const PrimaryHeader = styled('h3')`
  @media (${BelowTablet}) {
    font-size: ${fontSize.h5};
  }
`

const StepHeader = styled('div')`
  padding: 0 var(--margin-2);
`

const DisclosuresInnerWrapper = styled('div')`
  margin-left: auto;
  margin-right: auto;
  max-width: 900px;
`

const ContentBox = styled('div')`
  border: 1px solid ${greySet[15].hex};
  padding: var(--margin-2);
  overflow-y: auto;
  flex: 1;

  h4 {
    color: ${bodyPrimary};
    font-size: ${fontSize.h5};
    font-family: ${sansSerifFontStack};
    font-weight: 600;
    @media (${BelowTablet}) {
      font-size: ${fontSize.h6};
    }
  }

  p {
    margin-bottom: var(--margin-4);
  }
`

const Footer = styled('div')`
  background-color: ${eggshell};
  border-top: 1px solid ${borderGrey};
  padding: 0 var(--margin-2);
`
