import * as Yup from 'yup'
import { Formik, useFormikContext } from 'formik'
import { isEmpty, isNil } from 'lodash'
import { useEffect, useMemo, useState } from 'react'

import { AddressOwner } from '@nuna/api'
import { useIsAdmin } from '@nuna/auth'
import { AddressForm, AddressInitialValues, addressValidationSchemaChecks } from '@nuna/common'
import { type ProviderFormAddress, addressService, errorService, formService } from '@nuna/core'
import {
  Checkbox,
  Confirm,
  FillButton,
  GhostButton,
  Grid,
  IconInfo,
  IconLightning,
  IconTrash,
  OutlineButton,
  TextField,
  Tooltip,
  greySet,
  toast,
} from '@nuna/tunic'

import { AddressCardHeader } from './AddressCardHeader'
import { useProviderAddressContext } from './ProviderAddressContextProvider'

const { isHomeAddress } = addressService
const { composeHelperText } = formService

interface Props {
  providerAddress: ProviderFormAddress
  onCancel: () => void
  afterSave: () => void
}

const BLANK_ADDRESS: ProviderFormAddress = {
  ...AddressInitialValues,
  name: '',
  providerId: '',
  key: '',
  owner: AddressOwner.Provider,
}

export function ProviderAddressForm({ providerAddress, onCancel, afterSave }: Props) {
  const { practiceAddresses, saveHomeAddress, savePracticeAddress } = useProviderAddressContext()
  const [saveLoading, setSaveLoading] = useState(false)

  const isHome = isHomeAddress(providerAddress)
  const isPractice = !isHome

  const initialValues: ProviderFormAddress = useMemo(
    () => ({ ...BLANK_ADDRESS, ...providerAddress }),
    [providerAddress],
  )

  const otherAddressNames = useMemo(
    () => practiceAddresses.filter(address => address.id !== providerAddress.id).map(address => address.name),
    [practiceAddresses, providerAddress],
  )

  const validationSchema = useMemo(
    () =>
      Yup.object().shape<ProviderFormAddress>({
        ...addressValidationSchemaChecks,
        name: isPractice
          ? Yup.string()
              .required('Address Nickname is required')
              .test('unique-name', 'Address Nickname must be unique', value => !otherAddressNames.includes(value))
          : Yup.string(),
        providerId: Yup.string(),
        key: Yup.string(),
        owner: Yup.mixed<AddressOwner>(),
      }),
    [isPractice, otherAddressNames],
  )

  const submit = async (values: Partial<ProviderFormAddress>) => {
    setSaveLoading(true)
    try {
      isHome ? await saveHomeAddress(values) : await savePracticeAddress(values)
      afterSave()
      setSaveLoading(false)
      toast.success('Address saved')
    } catch (error) {
      const errorMessage = errorService.transformGraphQlError(error, 'Unable to save address')
      toast.urgent(errorMessage)
      setSaveLoading(false)
    }
  }

  return (
    <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={submit}>
      <ProviderAddressFormWithContext
        providerAddress={providerAddress}
        onCancel={onCancel}
        saveLoading={saveLoading}
        initialValues={initialValues}
      />
    </Formik>
  )
}

function ProviderAddressFormWithContext({
  providerAddress,
  onCancel,
  saveLoading,
  initialValues,
}: Omit<Props, 'afterSave'> & {
  saveLoading: boolean
  initialValues: ProviderFormAddress
}) {
  const isAdmin = useIsAdmin()
  const { practiceAddresses, homeAddress, sameAsPracticeAddressChange, removeAddress, cancelChanges } =
    useProviderAddressContext()

  const [showRemoveConfirmation, setShowRemoveConfirmation] = useState(false)
  const [isRemovingLoading, setIsRemovingLoading] = useState(false)
  const isHome = isHomeAddress(providerAddress)
  const isPractice = !isHome
  const truePracticeAddressCount = homeAddress.sameAsPracticeAddress
    ? practiceAddresses.length + 1
    : practiceAddresses.length
  const hideCancel =
    (isHome && isNil(providerAddress.id)) ||
    (isPractice && isEmpty(providerAddress.addressHash) && truePracticeAddressCount === 1)
  const canRemove =
    (isAdmin || providerAddress.owner === AddressOwner.Provider) &&
    isPractice &&
    !!providerAddress.id &&
    !!providerAddress.addressLineOne

  const removeDisabled = !homeAddress.sameAsPracticeAddress && practiceAddresses.length <= 1

  const handleRemove = async (remove: boolean) => {
    setShowRemoveConfirmation(false)
    setIsRemovingLoading(true)
    if (remove) {
      try {
        await removeAddress(providerAddress?.id ?? '')
      } catch (e) {
        toast.urgent(errorService.transformGraphQlError(e, 'There was an error removing address'))
      } finally {
        setIsRemovingLoading(false)
      }
    }
  }

  const { values, handleBlur, handleChange, setFieldValue, handleSubmit, touched, errors } =
    useFormikContext<ProviderFormAddress>()

  const handleCancel = () => {
    if (isHome && initialValues.sameAsPracticeAddress !== values.sameAsPracticeAddress) {
      sameAsPracticeAddressChange(!!initialValues.sameAsPracticeAddress)
    }
    onCancel()
    cancelChanges(providerAddress.id)
  }

  useEffect(() => {
    setFieldValue('defaultAddress', providerAddress.defaultAddress)
    if (isHomeAddress(providerAddress)) {
      setFieldValue('sameAsPracticeAddress', providerAddress.sameAsPracticeAddress)
    }
  }, [providerAddress, setFieldValue])

  const { name, defaultAddress = false } = values

  const isError = (key: keyof ProviderFormAddress) => {
    return !!touched[key] && !!errors[key]
  }
  const helperText = (key: keyof ProviderFormAddress, text = '') => {
    const isTouched = touched[key] as boolean
    const errorMsg = errors[key] as string | undefined
    return composeHelperText(text, errorMsg, isTouched)
  }

  return (
    <form onSubmit={handleSubmit}>
      <AddressCardHeader className="mb-2">
        {name ? name : isHome ? 'Home Address' : 'Practice Address'}
      </AddressCardHeader>
      <AddressForm dataTestPrefix="provider" />
      <Grid container spacing={6}>
        {isPractice && truePracticeAddressCount > 1 && (
          <Grid className="v-align" size={12}>
            {initialValues.defaultAddress ? (
              <Tooltip content="Remove this address as your default by marking a different address as default">
                <span>
                  <Checkbox
                    name="defaultAddress"
                    checked={defaultAddress}
                    disabled
                    onChange={event => setFieldValue('defaultAddress', event.currentTarget.checked)}
                  >
                    Set as my default practice address
                  </Checkbox>
                </span>
              </Tooltip>
            ) : (
              <Checkbox
                name="defaultAddress"
                checked={defaultAddress}
                onChange={event => setFieldValue('defaultAddress', event.currentTarget.checked)}
              >
                Set as my default practice address
              </Checkbox>
            )}

            <Tooltip content="We'll use this address as the default location to save you time when recording a session note">
              <span className="text-secondary ml-1 v-align">
                <IconInfo />
              </span>
            </Tooltip>
          </Grid>
        )}
        {isPractice && (
          <Grid size={12}>
            <TextField
              name="name"
              label="Address Nickname"
              onChange={handleChange}
              onBlur={handleBlur}
              value={name}
              error={isError('name')}
              helperText={helperText('name')}
            />
            <div className="caption v-align mt-1 text-secondary">
              <IconLightning className="mr-1" />
              This name is for your personal reference. Name it something you can easily refer to later.
            </div>
          </Grid>
        )}

        <Grid size={12} sx={{ mt: 4 }}>
          <Grid container justifyContent="center">
            <Grid>
              {!hideCancel && (
                <GhostButton className="mr-2" onClick={handleCancel}>
                  Cancel
                </GhostButton>
              )}
              <OutlineButton type="submit" isLoading={saveLoading}>
                Save
              </OutlineButton>
            </Grid>
          </Grid>
          {canRemove && (
            <>
              <hr className="my-3" style={{ width: '80%', borderColor: greySet[15].hex, borderStyle: 'solid' }} />
              <Grid container justifyContent="center">
                <Grid style={{ textAlign: 'center' }}>
                  <GhostButton
                    disabled={removeDisabled}
                    variant="destroy"
                    onClick={() => setShowRemoveConfirmation(true)}
                    leadingIcon
                  >
                    <IconTrash />
                    Remove Practice Address
                  </GhostButton>
                  {removeDisabled && (
                    <div className="mt-2 caption">
                      You need at least one practice address on file before you can remove this one.
                    </div>
                  )}
                </Grid>
              </Grid>
            </>
          )}
        </Grid>
      </Grid>
      <Confirm
        isOpen={showRemoveConfirmation}
        onConfirm={handleRemove}
        confirmButton={
          <FillButton onClick={() => handleRemove(true)} isLoading={isRemovingLoading}>
            Remove
          </FillButton>
        }
      >
        Are you sure you want to remove this address?
      </Confirm>
    </form>
  )
}
