import { styled } from '@mui/material'
import { HTMLAttributes } from 'react'

import { IconPreview, StatusLabel } from '@nuna/tunic'

export function VisibleToClientsLabel(props: HTMLAttributes<HTMLLabelElement>) {
  return (
    <Label {...props}>
      <IconPreview size={18} /> Visible To Clients
    </Label>
  )
}

const Label = styled(StatusLabel)`
  align-items: center;
  display: inline-flex;
  svg {
    margin-right: 0.375rem;
  }
`
