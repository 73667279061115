import { AccessCodeCoverageDetailsFragment } from '@nuna/api'
import { Logo } from '@nuna/tunic'

import { BaseCard, BaseCardProps } from './BaseCard'

interface Props extends BaseCardProps {
  details: AccessCodeCoverageDetailsFragment
}

export function AccessCodeCard({ details, ...props }: Props) {
  return (
    <BaseCard {...props}>
      <div className="flex-column full-height">
        <Logo height={32} renderText={false} /> <span className="mt-auto">{details.coupon}</span>
      </div>
    </BaseCard>
  )
}
