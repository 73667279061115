import { styled } from '@mui/material'

import { useEstimateProviderCompensationQuery } from '@nuna/api'
import { numberService } from '@nuna/core'
import { csx } from '@nuna/tunic'

interface Props extends React.HTMLAttributes<HTMLDivElement> {
  billedDuration: number | null | undefined
  providerId: string
  appointmentId: string
}

export function CompensationEstimate({ billedDuration, providerId, appointmentId, className, ...props }: Props) {
  const { data } = useEstimateProviderCompensationQuery({
    fetchPolicy: 'cache-and-network',
    skip: !billedDuration,
    variables: {
      providerId,
      appointmentId,
      sessionDuration: billedDuration || 0,
    },
  })

  const totalCompensation = data?.estimateProviderCompensation?.totalCompensation ?? 0
  const cptCode = data?.estimateProviderCompensation?.cptCode

  return (
    <Container className={csx(['text-medium', className])} {...props}>
      <span className="duration">{billedDuration} min</span>
      {totalCompensation > 0 && (
        <>
          <span>
            {numberService.centsToFormattedDollars(totalCompensation, {
              maximumFractionDigits: 2,
            })}{' '}
            Payout
          </span>
          <span>CPT {cptCode}</span>
        </>
      )}
    </Container>
  )
}

const Container = styled('div')`
  color: var(--interactive-text-color);

  > .duration::before {
    content: '= ';
  }

  > :not(.duration)::before {
    content: ' / ';
  }
`
