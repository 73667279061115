import { styled } from '@mui/material'
import { useEffect, useMemo } from 'react'

import { localStorageService } from '@nuna/core'
import { IconAISparkles, IconCautionCircle, borderGrey, listFormat } from '@nuna/tunic'

import { useVideoCallContext } from '../../../context/VideoCallContext'
import { PatientVideoUser, ProviderVideoUser } from '../../../types'
import { isProvider } from '../../../util/videoUsers'
import { AutonotesSetting } from '../../AutonotesSetting'

const AUTONOTES_HAIR_CHECK_KEY = 'tava-an-hc'

interface AutonotesHairCheckData {
  s: boolean // seen
  prId: string | undefined // provider Id
  aId: string | undefined // appointment Id
}

const defaultAutonotesHairCheckData: AutonotesHairCheckData = {
  s: false,
  prId: undefined,
  aId: undefined,
}

export function AutonotesFooter() {
  const { localUser, expectedUsers } = useVideoCallContext()

  if (localUser.isProvider && localUser.initialAutonotesEnabled) {
    const optedOutUsers = expectedUsers.filter(user => !user.initialAutonotesEnabled)

    return (
      <Footer>
        <p className="caption top-align">
          {optedOutUsers.length > 0 ? (
            <IconCautionCircle
              size={16}
              className="mr-1 flex-static"
              color={borderGrey}
              style={{ transform: 'translateY(1px)' }}
            />
          ) : (
            <IconAISparkles size={16} className="mr-1 flex-static" />
          )}
          <span>
            <strong>Tava Scribe</strong> is{' '}
            {optedOutUsers.length === 0 ? (
              <>
                <strong>on</strong> for this session.
              </>
            ) : (
              <>
                <strong>off</strong>. {listFormat(optedOutUsers.map(u => u.firstName))} opted out.
              </>
            )}
          </span>
        </p>
      </Footer>
    )
  }

  const provider = expectedUsers.find(isProvider)

  if (!localUser.isProvider && provider?.initialAutonotesEnabled) {
    return <PatientAutonotesFooter patient={localUser} provider={provider} />
  }

  return null
}

function PatientAutonotesFooter({ patient, provider }: { patient: PatientVideoUser; provider: ProviderVideoUser }) {
  const { appointmentId } = useVideoCallContext()

  const autonotesHairCheckData = useMemo(
    () => localStorageService.getUserData(patient.patientId, AUTONOTES_HAIR_CHECK_KEY, defaultAutonotesHairCheckData),
    [patient.patientId],
  )

  useEffect(() => {
    if (autonotesHairCheckData.s) return

    localStorageService.writeUserData<AutonotesHairCheckData>(patient.patientId, AUTONOTES_HAIR_CHECK_KEY, {
      s: true,
      prId: provider.providerId,
      aId: appointmentId,
    })
  }, [appointmentId, autonotesHairCheckData, patient, provider])

  if (
    autonotesHairCheckData.s &&
    autonotesHairCheckData.prId === provider.providerId &&
    autonotesHairCheckData.aId !== appointmentId // keep showing for original appointment but hide for subsequent ones
  ) {
    return null
  }

  return (
    <Footer>
      <AutonotesSetting />
    </Footer>
  )
}

const Footer = styled('div')`
  border-top: rgba(255, 255, 255, 0.26) 1px solid;
  padding: var(--spacing-2) var(--spacing-2) var(--spacing-1);
`
