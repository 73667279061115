import { CapInfo, CapStatus } from '@nuna/api'
import { salmonSet, tealSet, yellowSet } from '@nuna/tunic'

interface UtilCapInfo extends Pick<CapInfo, 'sessionsAttended' | 'capStatus'> {
  sessionsCountingTowardsCap?: number | null
  sessionAllowanceToType?: number | null
}

const getProgressBarColor = (capInfo?: CapInfoForDisplay, hasPaymentMethod?: boolean) => {
  const remainingSessions =
    capInfo?.sessionAllowanceToType && capInfo?.sessionsCountingTowardsCap
      ? capInfo?.sessionAllowanceToType - capInfo?.sessionsCountingTowardsCap
      : null

  const isLastCoveredAppointment = !hasPaymentMethod && remainingSessions === 1

  if (!capInfo || hasPaymentMethod) {
    return tealSet[70]
  }

  if (isLastCoveredAppointment || capInfo.capStatus === CapStatus.Close) {
    return yellowSet[70]
  }

  if (capInfo.capStatus === CapStatus.Exhausted) {
    return salmonSet[80]
  }

  return tealSet[70]
}

interface ProcessedCapInfo {
  progressBars: {
    percent: number
    color: string
  }[]
  capPercentageUsed: number
}

export type CapInfoForDisplay = Pick<
  UtilCapInfo,
  'sessionsCountingTowardsCap' | 'sessionAllowanceToType' | 'sessionsAttended' | 'capStatus'
>

const generateCapInfoDisplayData = (
  capInfo?: CapInfoForDisplay,
  patientHasPaymentMethod?: boolean,
): ProcessedCapInfo => {
  const capPercentageUsed = ((capInfo?.sessionsCountingTowardsCap ?? 0) / (capInfo?.sessionAllowanceToType ?? 1)) * 100
  const attendedPercentage = ((capInfo?.sessionsAttended ?? 0) / (capInfo?.sessionAllowanceToType ?? 1)) * 100

  return {
    progressBars: [
      {
        color: getProgressBarColor(capInfo, patientHasPaymentMethod).hex,
        percent: attendedPercentage,
      },
      {
        color: getProgressBarColor(capInfo, patientHasPaymentMethod).transparency(0.5),
        percent: capPercentageUsed,
      },
    ],
    capPercentageUsed,
  }
}

export const capInfoUtils = {
  generateCapInfoDisplayData,
}
