import { styled } from '@mui/material'
import { range } from 'lodash'
import ordinal from 'ordinal'
import pluralize from 'pluralize'
import { useEffect } from 'react'

import { BasicPatientFragment } from '@nuna/api'
import {
  Card,
  ContextualAlert,
  IconDollarSymbol,
  SegmentedControl,
  greySet,
  interactiveFill,
  listFormat,
  yellowSet,
} from '@nuna/tunic'

import { useCapInfo } from '../../../hooks/useCapInfo'
import { DrawerCaption } from '../../DrawerCaption'
import { DrawerSubheading } from '../../DrawerSubheading'
import { NewCard } from '../shared'
import { ExhaustedCapMessage } from './NewCapMessages'

interface QuantitySelectorProps {
  patient: BasicPatientFragment
  value: number | null
  onChange: (quantity: number | null) => void
}

export function NewQuantitySelector({ patient, value, onChange }: QuantitySelectorProps) {
  const { appointmentConfiguration, isLastCoveredAppointment, isOutOfAppointments, remainingSessions } = useCapInfo(
    patient.id,
  )

  useEffect(() => {
    if (isLastCoveredAppointment === true) {
      onChange(1)
    } else if (isOutOfAppointments === true) {
      onChange(null)
    }
  }, [isLastCoveredAppointment, isOutOfAppointments, onChange])

  if (!appointmentConfiguration) {
    return <Card className="loading" style={{ height: 159 }} />
  }

  const {
    patientHasPaymentMethod,
    capInfo: { sessionAllowanceToType, sessionsCountingTowardsCap },
  } = appointmentConfiguration

  const isCashAppointment = (quantity: number) => {
    if (!sessionsCountingTowardsCap || !sessionAllowanceToType) {
      return false
    }

    return quantity + sessionsCountingTowardsCap > sessionAllowanceToType
  }

  const isQuantitySelectionDisabled = (quantity: number) => {
    if (patientHasPaymentMethod) {
      return false
    }

    return isCashAppointment(quantity)
  }

  const isCashRequiredQuantitySelected = value && isCashAppointment(value)

  return (
    <>
      {!isLastCoveredAppointment && !isOutOfAppointments && (
        <NewCard className="mb-2">
          <DrawerSubheading id="quantity-heading" className="mb-2">
            How many sessions?
          </DrawerSubheading>

          <SegmentedControl
            data-component="appointment-quantity-selector"
            aria-labelledby="quantity-heading"
            value={value ? `${value}` : ''}
          >
            {[1, 2, 3, 4].map(qty => (
              <SegmentedControl.Item
                disabled={isQuantitySelectionDisabled(qty)}
                checked={qty === value}
                onChange={e => onChange(parseInt(e.currentTarget.value, 10))}
                key={qty}
                name="apptQuantity"
                value={qty}
              >
                {qty}

                {isCashAppointment(qty) && (
                  <IconDollarSymbol
                    color={
                      qty === value
                        ? interactiveFill
                        : isQuantitySelectionDisabled(qty)
                        ? greySet[30].hex
                        : greySet[50].hex
                    }
                    style={{ marginLeft: '0.25rem' }}
                    size={14}
                  />
                )}
              </SegmentedControl.Item>
            ))}
          </SegmentedControl>

          {remainingSessions !== null && remainingSessions < 4 && (
            <CapMessage className="mt-2">
              <IconDollarSymbol
                className="flex-static mr-1"
                size={14}
                color={isCashRequiredQuantitySelected ? interactiveFill : greySet[30].hex}
              />

              {patientHasPaymentMethod ? (
                <DrawerCaption className={`${isCashRequiredQuantitySelected && 'text-teal'}`}>
                  There will be costs incurred to {patient.firstName}
                  {remainingSessions === 0 && ' for each session'}
                  {remainingSessions !== 0 &&
                    ' on the ' + listFormat(range(remainingSessions, 4).map(number => ordinal(number + 1)))}{' '}
                  {remainingSessions !== 0 && pluralize('session', 4 - remainingSessions)}.
                </DrawerCaption>
              ) : (
                <p className="text-medium-gray italic">
                  You can schedule {remainingSessions} more {pluralize('session', remainingSessions)} with{' '}
                  {patient.firstName}. After that, they'll need to select a new coverage method.
                </p>
              )}
            </CapMessage>
          )}
        </NewCard>
      )}

      {isLastCoveredAppointment && remainingSessions !== null && (
        <ContextualAlert
          className="mb-3"
          intent="caution"
          icon={<IconDollarSymbol className="flex-static mr-1" size={14} color={yellowSet[80].hex} />}
        >
          You can schedule {remainingSessions} more session with {patient.firstName}. After that, they'll need to select
          a new coverage method.
        </ContextualAlert>
      )}

      {isOutOfAppointments && <ExhaustedCapMessage patient={patient} />}
    </>
  )
}

const CapMessage = styled('div')`
  display: flex;

  svg {
    margin-top: 0.125rem;
  }
`
