import { styled } from '@mui/material'
import { ReactNode } from 'react'

import { BelowTablet } from '@nuna/tunic'

import { useVideoCallContext } from '../context/VideoCallContext'
import { videoSceneDarkBorder, videoSceneExtraDark } from '../util/colors'
import { ABOVE_VIDEO } from '../util/zIndexes'

interface Props {
  children: ReactNode
}

export function MainVideoWindow({ children }: Props) {
  const { isMobileFullscreen, isSessionEnded, setIsMobileFullscreen } = useVideoCallContext()

  return (
    <Main $isMobileFullscreen={isMobileFullscreen}>
      {!isSessionEnded && <MobileFullScreenToggle onClick={() => setIsMobileFullscreen(!isMobileFullscreen)} />}
      {children}
    </Main>
  )
}

const Main = styled('main')<{ $isMobileFullscreen: boolean }>`
  align-items: center;
  background-color: ${videoSceneExtraDark};
  border: 1px solid ${videoSceneDarkBorder};
  border-radius: 12px;
  display: flex;
  height: 100%;
  justify-content: center;
  position: relative;
  width: 100%;
  transition: height 0.3s, transform 0.3s;

  @media (${BelowTablet}) {
    height: calc(100% - 116px);
    transform: translateY(-0.75rem);
    flex: 0 0 calc(50% - 1rem);

    ${props => props.$isMobileFullscreen && `height: 100%; transform: translateY(0);`}
  }
`

const MobileFullScreenToggle = styled('button')`
  display: none;

  @media (${BelowTablet}) {
    display: block;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    z-index: ${ABOVE_VIDEO - 1};
  }
`
