import * as Yup from 'yup'
import { FormikProps } from 'formik'

import { LicenseInput, ProviderIntakeFragment, SaveProviderIntakeMutationVariables } from '@nuna/api'
import { ProviderSignupAddress } from '@nuna/common'

export type SaveProviderIntakeInput = Omit<SaveProviderIntakeMutationVariables, 'id'>

export interface ProviderAddressOnlyFormValues
  extends Omit<ProviderIntakeFragment, 'firstName' | 'lastName' | 'ageSpecialties'> {
  mobilePhone?: string | null
}

export type ProviderSignupFormValues = Omit<ProviderIntakeFragment, 'address' | 'credentials'> & {
  address: ProviderSignupAddress
  licenses: LicenseInput[]
  receiveBackgroundCheckCopy: boolean
  backgroundCheckDisclosuresAgreement?: boolean
  intakeCompleted?: boolean
  navigationPath?: string
}
export type ProviderSignupIntakeFormProps = FormikProps<ProviderSignupFormValues> & {
  saveIntakeLoading: boolean
  logFormErrors: () => void
}
export type ProviderSignupFormSchema = Yup.ObjectSchema<Partial<ProviderSignupFormValues>>
export interface ProviderSignupFormStepConfig {
  validationSchema?: ProviderSignupFormSchema | null
  buildSaveValues?: ((formValues: ProviderSignupFormValues) => SaveProviderIntakeInput) | null
  afterSave?: ((intakeData: ProviderIntakeFragment) => void) | null
}
export enum ProviderSignupNavigationDirection {
  Forward = 'forward',
  Back = 'back',
}
