import { css, styled } from '@mui/material'

import {
  GhostButton,
  GhostButtonExternalLink,
  GhostButtonExternalLinkProps,
  GhostButtonLink,
  GhostButtonLinkProps,
  GhostButtonProps,
} from './GhostButton'

const styles = css`
  font-size: inherit;
  padding: 0;

  &::before {
    height: calc(100% + 6px);
  }
`

export const TextButton = styled(GhostButton)<GhostButtonProps>`
  ${styles}
`

export const TextButtonLink = styled(GhostButtonLink)<GhostButtonLinkProps>`
  ${styles}
`

export const TextButtonExternalLink = styled(GhostButtonExternalLink)<GhostButtonExternalLinkProps>`
  ${styles}
`
