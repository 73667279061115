import { styled } from '@mui/material'
import { HTMLAttributes } from 'react'

import { greySet, salmonSet, tealSet, yellowSet } from '../../styles/colorSets'
import { body2, eggshell } from '../../styles/colors'
import { ShadowColor, shadowDepth } from '../../styles/shadows'
import { Intent } from '../../types/Intent'
import { csx } from '../../utils/csx'

type CardDepth = -4 | -1 | -0.5 | 0 | 1 | 1.5 | 4 | 4.5 | 8 | 8.5

export interface CardProps extends HTMLAttributes<HTMLDivElement> {
  depth?: CardDepth
  shadowColor?: ShadowColor
  intent?: Intent
}

export interface CardHeaderProps extends HTMLAttributes<HTMLDivElement> {
  divider?: boolean
  cursor?: string
  margin?: string
  padding?: string
  wrapperClick?: () => void
}

export function CardHeader({
  children,
  className,
  divider = true,
  wrapperClick = () => null,
  ...props
}: CardHeaderProps) {
  return (
    <StyledCardHeader
      divider={divider}
      style={{
        cursor: props.cursor ? props.cursor : undefined,
        margin: props.margin ? props.margin : undefined,
        padding: props.padding ? props.padding : undefined,
      }}
      onClick={wrapperClick}
    >
      <div className={csx([className, 'card-header-inner-wrapper text-medium'])} {...props}>
        {children}
      </div>
    </StyledCardHeader>
  )
}

const StyledCardHeader = styled('div')<{ divider: boolean }>`
  padding: var(--margin-2) var(--margin-2) 0 var(--margin-2);
  margin-bottom: var(--margin-2);
  .card-header-inner-wrapper {
    color: ${greySet[70].hex};
    ${({ divider = true }) =>
      divider
        ? `
      border-bottom: 1px solid ${greySet[15].hex};
      padding-bottom: var(--margin-2);`
        : ''};
  }
`

export const CardBody = styled('div')`
  padding: var(--margin-2);
`

export const Card = styled('div')<CardProps>`
  overflow: hidden;

  ${({ depth = 1 as CardDepth, shadowColor = 'warm', intent = 'default' }) => {
    if (intent === 'urgent') {
      return `
      background-color: ${salmonSet.tint[20]};
      border: 1px solid ${salmonSet[80].hex};
      ${StyledCardHeader} {
          .card-header-inner-wrapper {
            border-bottom-color: ${salmonSet[80].hex}
          }
        }
      `
    }

    if (depth === -4) {
      return `
        background-color: ${greySet[15].hex};
        border-color: ${greySet[30].hex};
        ${StyledCardHeader} {
          .card-header-inner-wrapper {
            border-bottom-color: ${greySet[30].hex}
          }
        }
      `
    }

    if (depth === -0.5) {
      return `
        background-color: ${eggshell};
        border-color: ${greySet[15].hex};
        ${StyledCardHeader} {
          .card-header-inner-wrapper {
            border-bottom-color: ${greySet[15].hex}
          }
        }
      `
    }

    if (depth === -1) {
      return `
        background-color: ${greySet[5].hex};
        border-color: ${greySet[30].hex};
        ${StyledCardHeader} {
          .card-header-inner-wrapper {
            border-bottom-color: ${greySet[30].hex}
          }
        }
      `
    }

    if (depth === 0) {
      return `
        background-color: #fff;
        border-color: ${greySet[15].hex};
      `
    }

    return `
      background-color: #fff;
      box-shadow: ${shadowDepth(depth, shadowColor)};
      border-color: ${greySet[15].hex};
    `
  }};
  border-radius: var(--border-radius);
  border-width: 1px;
  border-style: solid;
  color: ${body2};

  ${StyledCardHeader} + ${CardBody} {
    padding-top: 0;
  }
`

export interface CardBootProps extends HTMLAttributes<HTMLDivElement> {
  intent?: Intent
  className?: string
}

export function CardBoot({ className = '', children, ...props }: CardBootProps) {
  return (
    <Boot className={`v-align py-1 px-2 ${className}`} {...props}>
      <span className="v-align caption boot-content">{children}</span>
    </Boot>
  )
}

const Boot = styled('div')<{ intent?: Intent }>`
  background-color: ${({ intent }) => {
    switch (intent) {
      case 'caution':
        return yellowSet[15].hex
      case 'information':
        return tealSet[15].hex
      case 'urgent':
        return salmonSet[80].hex
      default:
        return greySet[5].hex
    }
  }};
  color: ${({ intent }) => {
    switch (intent) {
      case 'caution':
        return yellowSet[80].hex
      case 'information':
        return tealSet.primary.hex
      case 'urgent':
        return salmonSet[15].hex
      default:
        return body2
    }
  }};

  .boot-content {
    flex-wrap: wrap;
  }
`
