import { styled } from '@mui/material'
import { FormEvent, KeyboardEventHandler, useCallback, useState } from 'react'

import { GhostButton, TextField, shadowDepth } from '@nuna/tunic'

import { useVideoChatContext } from '../../../context/VideoChatContext'

export function VideoChatMessageComposer() {
  const [newMessage, setNewMessage] = useState('')

  const { sendMessage, conversation } = useVideoChatContext()

  const handleSendMessage = useCallback(
    (e?: FormEvent) => {
      e?.preventDefault()

      sendMessage(newMessage)
      setNewMessage('')
    },
    [newMessage, sendMessage],
  )

  const handleKeyDown: KeyboardEventHandler<HTMLTextAreaElement> = e => {
    if (e.shiftKey) {
      return // Allow shift + enter to create new lines
    }

    conversation?.typing()

    if (e.code === 'Enter' || e.code === 'NumpadEnter') {
      e.preventDefault()
      handleSendMessage()
    }
  }

  return (
    <NewMessageForm className="v-align fs-exclude" onSubmit={handleSendMessage}>
      <TextField
        multiline
        rowsMax={3}
        placeholder="Your message"
        className="message-input mr-2"
        showBottomBorderOnFocus={false}
        value={newMessage}
        onKeyDown={handleKeyDown}
        onChange={e => setNewMessage(e.target.value)}
      />
      <GhostButton type="submit" className="ml-auto">
        Send
      </GhostButton>
    </NewMessageForm>
  )
}

const NewMessageForm = styled('form')`
  background-color: #fff;
  border-radius: 12px;
  box-shadow: ${shadowDepth(1)};
  padding: 0 1rem;
  margin-top: auto;
  width: 100%;

  .message-input {
    flex: 1;
    > div {
      padding-top: 0;
    }

    textarea {
      border-bottom: none;
      padding-top: 22px;
      padding-bottom: 4px;
    }
  }
`
