import { IconProps } from './IconProps'

/** @deprecated Use IconFileDocument */
export function IconDocumentDeprecated({ size = 24, color = 'currentColor', ...props }: IconProps) {
  return (
    <svg width={size} height={size} viewBox="0 0 18 18" fill={color} xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.54953 3.21765C3.54953 2.77771 3.91361 2.41657 4.35398 2.41657H9.02306C9.24967 2.41657 9.64091 2.58093 9.79217 2.73655L13.5678 6.62095C13.7294 6.78723 13.888 7.1757 13.888 7.40638V14.7803C13.888 15.2241 13.528 15.5834 13.0873 15.5834H4.35019C3.90951 15.5834 3.54953 15.225 3.54953 14.7877V13.0496V5.4581V3.21765ZM2.25 13.0496V14.7877C2.25 15.9379 3.19131 16.875 4.35019 16.875H13.0873C14.247 16.875 15.1875 15.9361 15.1875 14.7803V7.40638C15.1875 6.84029 14.8977 6.13025 14.5025 5.72357L10.7268 1.83917C10.3302 1.43115 9.60144 1.125 9.02306 1.125H4.35398C3.19647 1.125 2.25 2.06382 2.25 3.21765V5.4581V13.0496ZM8.2964 13.6999C8.53564 13.6999 8.72958 13.5071 8.72958 13.2694C8.72958 13.0316 8.53564 12.8388 8.2964 12.8388H5.40857C5.16933 12.8388 4.97539 13.0316 4.97539 13.2694C4.97539 13.5071 5.16933 13.6999 5.40857 13.6999H8.2964ZM8.2964 11.2244C8.53564 11.2244 8.72958 11.0316 8.72958 10.7938C8.72958 10.5561 8.53564 10.3633 8.2964 10.3633H5.40857C5.16933 10.3633 4.97539 10.5561 4.97539 10.7938C4.97539 11.0316 5.16933 11.2244 5.40857 11.2244H8.2964ZM11.8885 10.2557C12.1275 10.2557 12.3213 10.4486 12.3213 10.6862V13.4129C12.3213 13.6506 12.127 13.8434 11.8885 13.8434H10.1551C9.91606 13.8434 9.72227 13.6505 9.72227 13.4129V10.6862C9.72227 10.4485 9.91656 10.2557 10.1551 10.2557H11.8885Z"
      />
    </svg>
  )
}
