import * as Yup from 'yup'
import { omit } from 'lodash'
import { formatPhoneNumber } from 'react-phone-number-input'

import { Patient, StateAbbreviation, useUpdateClientContactInfoMutation } from '@nuna/api'
import { useIsAdmin } from '@nuna/auth'
import {
  AddressForm,
  OmittedAddressFields,
  ProfileSection,
  ReadOnlyProfileSection,
  UsAddressValues,
  addressValidationSchemaChecks,
} from '@nuna/common'
import { addressService, formService } from '@nuna/core'
import { Grid, InfoWithHeading, PhoneTextField, TextField, phoneUSAValidationRegex, toast } from '@nuna/tunic'

type ContactInfoPatient = Pick<
  Patient,
  'id' | 'email' | 'mobilePhone' | 'addressLineOne' | 'addressLineTwo' | 'city' | 'state' | 'county' | 'zipCode'
>

interface ClientProfileContactInfoProps {
  patient: ContactInfoPatient | undefined
}

type InitialValues = Pick<ContactInfoPatient, 'id' | 'email' | 'mobilePhone'> &
  Omit<UsAddressValues, 'state'> & { state?: string } & Record<string, unknown>

const { oopsRequired } = formService

const getValidationSchema = (isAdmin: boolean, initialValues: InitialValues) => {
  const checkValues = omit(initialValues, 'id', 'email')
  const hasValues = Object.values(checkValues).some(value => !!value)

  const inRequiredState = hasValues || !isAdmin
  return Yup.object().shape(
    {
      ...addressValidationSchemaChecks,
      id: Yup.string().required(),
      email: Yup.string().required().email('Must be a valid email'),
      mobilePhone: Yup.string()
        .when([], {
          is: () => inRequiredState,
          then: Yup.string().required(),
          otherwise: Yup.string().notRequired(),
        })
        .matches(phoneUSAValidationRegex, 'Please enter a valid US phone number'),
      addressLineOne: Yup.string().when(['city', 'state', 'zipCode'], {
        is: (city, state, zipCode) => inRequiredState || city || state || zipCode,
        then: Yup.string().required(oopsRequired('address')),
        otherwise: Yup.string().notRequired(),
      }),
      city: Yup.string().when(['addressLineOne', 'state', 'zipCode'], {
        is: (addressLineOne, state, zipCode) => inRequiredState || addressLineOne || state || zipCode,
        then: Yup.string().required(oopsRequired('city')),
        otherwise: Yup.string().notRequired(),
      }),
      state: Yup.mixed()
        .oneOf(Object.values(StateAbbreviation))
        .when(['addressLineOne', 'city', 'zipCode'], {
          is: (addressLineOne, city, zipCode) => inRequiredState || addressLineOne || city || zipCode,
          then: Yup.string().required(oopsRequired('state')),
          otherwise: Yup.string().notRequired(),
        }),
      county: Yup.string().notRequired(),
      zipCode: Yup.string().when(['addressLineOne', 'city', 'state'], {
        is: (addressLineOne, city, state) => inRequiredState || addressLineOne || city || state,
        then: Yup.string().required(oopsRequired('zip code')),
        otherwise: Yup.string().notRequired(),
      }),
    },
    [
      ['addressLineOne', 'city'],
      ['addressLineOne', 'state'],
      ['addressLineOne', 'zipCode'],
      ['city', 'addressLineOne'],
      ['city', 'state'],
      ['city', 'zipCode'],
      ['state', 'addressLineOne'],
      ['state', 'city'],
      ['state', 'zipCode'],
      ['zipCode', 'addressLineOne'],
      ['zipCode', 'state'],
      ['zipCode', 'city'],
    ],
  )
}

const heading = 'Contact Info'

export function ClientProfileContactInfo({ patient }: ClientProfileContactInfoProps) {
  const isAdmin = useIsAdmin()
  const [updateClientContactInfo, { loading }] = useUpdateClientContactInfoMutation({
    refetchQueries: ['PatientDetails'],
  })

  if (!patient) {
    return <ReadOnlyProfileSection isLoading heading={heading} renderDisplayValue={null} />
  }

  const initialValues: InitialValues = {
    id: patient.id,
    addressLineOne: patient.addressLineOne ?? '',
    addressLineTwo: patient.addressLineTwo ?? '',
    city: patient.city ?? '',
    state: patient.state ?? '',
    county: patient.county ?? '',
    zipCode: patient.zipCode ?? '',
    email: patient.email,
    mobilePhone: patient.mobilePhone ?? '',
  }

  const cleanFormValues = (values: InitialValues) => {
    return Object.fromEntries(Object.entries(values).filter(([, value]) => value !== ''))
  }

  return (
    <ProfileSection
      heading={heading}
      description={null}
      isLoading={loading}
      initialValues={initialValues}
      validationSchema={getValidationSchema(isAdmin, initialValues)}
      handleSubmit={async values => {
        try {
          await updateClientContactInfo({
            variables: {
              patientId: patient.id,
              ...omit(cleanFormValues(values), OmittedAddressFields),
            },
          })
          toast.success('Updated contact info successfully')
        } catch (e) {
          console.error(e)
          toast.urgent('Unable to update contact info')
        }
      }}
      showEditButton={isAdmin}
      displayWidth="full"
      renderDisplayValue={
        <>
          {isAdmin && <InfoWithHeading heading="Email" info={patient.email} />}
          <InfoWithHeading heading="Phone" info={formatPhoneNumber(patient.mobilePhone ?? '')} />
          <InfoWithHeading heading="Address" info={addressService.formatAddress(patient)} />
        </>
      }
      renderForm={({ values, handleChange, handleBlur, setFieldValue, touched, errors }) => (
        <div className="mr-5">
          <Grid container spacing={2}>
            <Grid
              size={{
                xs: 12,
                md: 6,
              }}
            >
              <TextField
                name="email"
                label="Email"
                value={values.email}
                onChange={handleChange}
                onBlur={handleBlur}
                error={(errors.email && touched.email) as boolean}
                helperText={touched.email && errors.email ? errors.email : null}
              />
            </Grid>
            <Grid
              size={{
                xs: 12,
                md: 6,
              }}
            >
              <PhoneTextField
                name="mobilePhone"
                label="Phone"
                value={values.mobilePhone}
                onChange={value => setFieldValue('mobilePhone', value)}
                onBlur={handleBlur}
                error={(errors.mobilePhone && touched.mobilePhone) as boolean}
                helperText={touched.mobilePhone && errors.mobilePhone ? errors.mobilePhone : null}
                fullWidth
              />
            </Grid>
          </Grid>
          <AddressForm gridSpacing={2} />
        </div>
      )}
    />
  )
}
