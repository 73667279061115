import { HTMLAttributes, ReactNode } from 'react'

import { makeTypographyComponent } from '@nuna/tunic'

interface CoverageSectionHeadingProps extends HTMLAttributes<HTMLHeadingElement> {
  isCheckout: boolean
  children: ReactNode
}

export function CoverageSectionHeading({ isCheckout, children, ...props }: CoverageSectionHeadingProps) {
  if (isCheckout) {
    return <CompactSectionHeading {...props}>{children}</CompactSectionHeading>
  }

  return <LargeSectionHeading {...props}>{children}</LargeSectionHeading>
}

const CompactSectionHeading = makeTypographyComponent('body text-bold', 'h3')
const LargeSectionHeading = makeTypographyComponent('h5 mb-3', 'h3')
