import { ReactNode } from 'react'

import { AdminBenefitsGuidesQuery, CustomerBenefitsGuideQuery } from '@nuna/api'
import { TextButtonExternalLink } from '@nuna/tunic'

type Contract = CustomerBenefitsGuideQuery['publicCompany']['contract'] | AdminBenefitsGuidesQuery['contracts'][0]

interface BenefitsGuideDownloadProps {
  contract: Contract
  children?: ReactNode
  className?: string
  renderOnEmpty?: JSX.Element | null
}

export function BenefitsGuideDownload({
  contract,
  children,
  renderOnEmpty = null,
  ...props
}: BenefitsGuideDownloadProps) {
  const url = contract.benefitsGuideUrl

  if (url) {
    return (
      <TextButtonExternalLink {...props} href={url}>
        {children ?? 'Download'}
      </TextButtonExternalLink>
    )
  }

  return renderOnEmpty
}
