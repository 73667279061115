import { ApolloError } from '@apollo/client'
import { includes } from 'lodash'

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function transformGraphQlError(error: any, fallback = '') {
  let errorMessage = fallback
  if (error instanceof ApolloError && error.graphQLErrors[0]) {
    errorMessage = error.graphQLErrors[0].message
  }
  return errorMessage
}

// Used by service to indicate that the error message is intended for the user and safe to display
const USER_ERROR_CODE = 'USER_ERROR'
const USER_ERROR_MESSAGE = 'USER_ERROR_MESSAGE'

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function getGraphQLErrorExtensions(error: any): ({ [key: string]: unknown } | undefined)[] {
  if (error instanceof ApolloError) {
    return error.graphQLErrors.map(gqlError => gqlError.extensions)
  }
  return []
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function transformUserGraphqlError(error: any, defaultMessage: string): string {
  if (
    error instanceof ApolloError &&
    includes([USER_ERROR_CODE, USER_ERROR_MESSAGE], error?.graphQLErrors[0]?.extensions?.code)
  ) {
    return error.graphQLErrors[0].message
  }

  if (error && error.errors && includes([USER_ERROR_CODE, USER_ERROR_MESSAGE], error?.errors[0]?.extensions?.code)) {
    return error.errors[0].message
  }

  return defaultMessage
}

function getErrorFromUnknown(e: unknown): Error {
  if (e instanceof Error) {
    return e
  } else if (typeof e === 'string') {
    return new Error(e)
  }

  console.error('Unknown error', e)
  return new Error('Unknown error')
}

export const errorService = {
  getErrorFromUnknown,
  transformGraphQlError,
  transformUserGraphqlError,
  getGraphQLErrorExtensions,
}
