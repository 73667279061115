import { styled } from '@mui/material'
import { useNavigate } from 'react-router-dom'

import { OutlineButton, greySet } from '@nuna/tunic'

const Container = styled('div')`
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`

const NotFoundContainer = styled('div')`
  border: 2px solid ${greySet[15].hex};
  max-width: 400px;
  border-radius: 1rem;
  padding: 3rem;
  margin: 1rem;
  background-color: white;
`

export function FourOhFour() {
  const navigate = useNavigate()

  return (
    <Container>
      <NotFoundContainer>
        <h1 className="h2">Whoops!</h1>
        <p>We couldn't find the page you're looking for.</p>
        <p>
          {' '}
          If you believe this is a mistake, email us at{' '}
          <a href="mailto:support@tavahealth.com">support@tavahealth.com</a> or use the chat bubble on this page.
        </p>
        <OutlineButton className="mt-4" onClick={() => navigate('/')}>
          Go Home
        </OutlineButton>
      </NotFoundContainer>
    </Container>
  )
}
