import { styled } from '@mui/material'
import { HTMLAttributes } from 'react'
import { useMediaQuery } from 'react-responsive'

import { BelowTablet, csx, fontSize, sansSerifFontStack } from '@nuna/tunic'

interface ResponsiveMargins {
  mb: number
  mt: number
}

type IntakeHeaderType = 'h1' | 'h2' | 'h3'

const defaultHeaderMargins: Record<IntakeHeaderType, Record<'xs' | 'md', ResponsiveMargins>> = {
  h1: {
    xs: { mb: 3, mt: 0 },
    md: { mb: 3, mt: 0 },
  },
  h2: {
    xs: { mb: 2, mt: 0 },
    md: { mb: 3, mt: 0 },
  },
  h3: {
    xs: { mb: 2, mt: 0 },
    md: { mb: 2, mt: 0 },
  },
}

const StyledHeaders = {
  h1: styled('h1')`
    font-size: ${fontSize.h3};
    @media (${BelowTablet}) {
      font-size: ${fontSize.h4};
    }
  `,
  h2: styled('h2')`
    font-size: ${fontSize.h5};
  `,
  h3: styled('h3')`
    font-family: ${sansSerifFontStack};
    font-size: ${fontSize.h6};
    font-weight: 500;
  `,
}

interface IntakeHeaderProps extends HTMLAttributes<HTMLHeadingElement> {
  xs?: Partial<ResponsiveMargins>
  md?: Partial<ResponsiveMargins>
  type: IntakeHeaderType
}

export function IntakeHeader({ type, className, xs = {}, md = {}, children, ...props }: IntakeHeaderProps) {
  const margins = useHeaderMargins(type, xs, md)
  const Tag = StyledHeaders[type]
  return (
    <Tag className={csx([className, `mt-${margins.mt} mb-${margins.mb}`])} {...props}>
      {children}
    </Tag>
  )
}

function useHeaderMargins(
  headerType: keyof typeof defaultHeaderMargins,
  xs: Partial<ResponsiveMargins>,
  md: Partial<ResponsiveMargins>,
) {
  const isMobile = useMediaQuery({ query: `(${BelowTablet})` })
  const margins: ResponsiveMargins = isMobile
    ? { ...defaultHeaderMargins[headerType].xs, ...xs }
    : { ...defaultHeaderMargins[headerType].md, ...md }

  return margins
}
