import { useEffect } from 'react'

export const HARMONY_TITLE_SUFFIX = ' · Tava Health'

export function useTitle(title: string, excludeSuffix = false) {
  useEffect(() => {
    const prevTitle = document.title
    document.title = `${title.trim()}${excludeSuffix ? '' : HARMONY_TITLE_SUFFIX}`
    return () => {
      document.title = prevTitle
    }
  }, [title, excludeSuffix])
}
