import { IconProps } from './IconProps'

export function IconMail({ size = 24, color = 'currentColor', ...props }: IconProps) {
  return (
    <svg width={size} height={size} viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.84573 9C4.70455 9 3 10.8172 3 13.0063V35.5563C3 37.7454 4.70455 39.5119 6.84573 39.5119H42.0703C44.2115 39.5119 46 37.7454 46 35.5563V13.0063C45.9916 10.8172 44.2115 9 42.0619 9H6.84573ZM24.9072 28.3383C24.6805 28.5918 24.1851 28.6172 23.9164 28.3129L8.28159 12.254L40.5589 12.2625L24.9072 28.3383ZM6.10682 14.6375L15.1418 23.9348L6.10682 33.1728V14.6375ZM17.3501 26.1914L21.6157 30.5865C22.9256 32.0318 25.6462 32.1248 27.1492 30.6034L31.4903 26.1407L41.4153 36.3846H7.43351L17.3501 26.1914ZM33.7071 23.8164L42.8008 14.4685V33.1728L33.7071 23.8164Z"
        fill={color}
      />
    </svg>
  )
}
