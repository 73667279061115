import { styled } from '@mui/material'
import moment from 'moment'
import React from 'react'

import { AddressOwner, AddressType, useProviderAddressesQuery } from '@nuna/api'
import { IconArmchair, IconButton, IconPencil, IconVideo, StatusLabel } from '@nuna/tunic'

import { DrawerCaption } from '../../DrawerCaption'
import { EditableAppointmentSlot, NewCard } from '../shared'

interface AppointmentCardProps extends React.HTMLAttributes<HTMLDivElement> {
  appointmentSlot: EditableAppointmentSlot
  subtext: React.ReactNode
  statusLabels?: React.ReactNode
  inactive?: boolean
  onEditClick?: React.MouseEventHandler<HTMLButtonElement>
  providerId: string
}

export function NewAppointmentCard({
  appointmentSlot,
  subtext,
  statusLabels,
  onEditClick,
  inactive = false,
  className = '',
  providerId,
  ...props
}: AppointmentCardProps) {
  const { data: providerAddressesData } = useProviderAddressesQuery({
    variables: {
      searchOptions: {
        addressId: appointmentSlot.newLocation,
        providerId,
        providerAddressType: AddressType.ProviderPractice,
        owner: AddressOwner.Provider,
      },
    },
    skip: !providerId || !appointmentSlot.newLocation || appointmentSlot.newLocation === 'Virtual',
  })
  const { address } = providerAddressesData?.providerAddresses[0] ?? {}

  return (
    <NewCard className={`v-align full-width ${className}`} {...props}>
      <div>
        {statusLabels && <StatusLabels>{statusLabels}</StatusLabels>}

        <h2 className={`sans-serif body text-medium ${inactive ? 'strike-through' : ''}`}>
          {moment(appointmentSlot.newStart || appointmentSlot.slot.start).format('dddd MMM Do [at] LT')}
        </h2>

        <DrawerCaption element="div" className="v-align">
          {address?.name ? <IconArmchair size={13} /> : <IconVideo size={13} />}
          <span className="ml-1">{address?.name || 'Virtual'}</span>
        </DrawerCaption>

        <DrawerCaption element="div">{subtext}</DrawerCaption>
      </div>

      {onEditClick && (
        <EditButton
          tooltip="Edit appointment"
          variant="secondary"
          data-component="appointment-slot-edit"
          onClick={onEditClick}
        >
          <IconPencil />
        </EditButton>
      )}
    </NewCard>
  )
}

const StatusLabels = styled('div')`
  margin-right: -46px; // offsets size of IconButton so that it stays vertically centered in the card

  ${StatusLabel} {
    margin-right: 0.5rem;
    margin-bottom: 0.5rem;
  }
`

const EditButton = styled(IconButton)`
  margin-right: -0.5rem;
  margin-left: auto;
`
