import { styled } from '@mui/material'
import React, { ReactNode } from 'react'

import { type Persona } from '@nuna/core'

import { plumSet } from '../styles/colorSets'
import { shadowDepth } from '../styles/shadows'
import { HeaderProgressBar } from './HeaderProgressBar'

const progressBarHeight = '7px'

export interface IntakeProgressHeaderProps extends React.HTMLAttributes<HTMLDivElement> {
  current: number
  max: number
  rightSlot: ReactNode
  leftSlot: ReactNode
  centerSlot?: ReactNode
  customColors?: { start?: string; end?: string }
  persona?: Persona
  innerWrapperProps?: React.HTMLAttributes<HTMLDivElement>
}

export function ProgressHeader({
  current,
  max,
  leftSlot,
  rightSlot,
  centerSlot,
  customColors,
  persona = 'client',
  innerWrapperProps = {},
  ...props
}: IntakeProgressHeaderProps) {
  return (
    <Header persona={persona} {...props}>
      <Inner {...innerWrapperProps}>
        {leftSlot}
        {centerSlot}
        {rightSlot}
      </Inner>

      <HeaderProgressBar
        style={{ position: 'absolute', bottom: 0, left: 0 }}
        now={current}
        min={0}
        max={max}
        persona={persona}
        customColors={customColors}
      />
    </Header>
  )
}

const Header = styled('div')<{ persona: Persona }>`
  align-items: center;
  background-color: ${({ persona }) => {
    if (persona === 'provider') {
      return plumSet[100].transparency(0.9)
    }

    return 'rgba(255, 255, 255, 0.8)'
  }};
  backdrop-filter: blur(8px);
  box-shadow: ${shadowDepth(1.5)};
  display: flex;
  height: 64px;
  left: 0;
  padding-bottom: ${progressBarHeight};
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 100;
  overflow: hidden;
`

const Inner = styled('div')`
  align-items: center;
  justify-content: space-between;
  display: flex;
  padding: 0 1.5rem;
  width: 100%;
`
