import { Client, Conversation, Message } from '@twilio/conversations'
import { debounce } from 'lodash'
import { ReactNode, createContext, useCallback, useContext, useEffect, useMemo, useState } from 'react'

import { useTwilioChatClient } from './hooks/useTwilioChatClient'
import { useTwilioConversation } from './hooks/useTwilioConversation'

interface ContextValues {
  chatClient: Client | undefined
  conversation: Conversation | undefined
  lastReadMessageIndex: number | null
  markAllMessagesRead: () => void
  messages: Message[]
  previousLastReadMessageIndex: number | null
  sendMessage: (message: string) => void
  unreadMessagesCount: number
  updatePreviousLastReadMessageIndex: () => void
}

const VideoChatContext = createContext<ContextValues | null>(null)

interface Props {
  appointmentId: string
  token: string
  children: ReactNode
}

export function VideoChatContextProvider({ appointmentId, token, children }: Props) {
  const chatClient = useTwilioChatClient(token)
  const conversation = useTwilioConversation({ appointmentId, chatClient })

  const [unreadMessagesCount, setUnreadMessagesCount] = useState(0)
  const [messages, setMessages] = useState<Message[]>([])
  const [lastReadMessageIndex, setLastReadMessageIndex] = useState<number | null>(null)
  const [previousLastReadMessageIndex, setPreviousLastReadMessageIndex] = useState<number | null>(null)

  /**
   * We need to handle read horizon updates in one spot with debounce to prevent race conditions.
   * A chat component might try to update the read horizon at the same time the context provider is updating the read
   * horizon on message added or on the initial messages load.
   */
  const debounceHandleReadHorizon = useMemo(
    () =>
      debounce(async ({ newHorizon, newMessages }: { newHorizon?: number; newMessages?: Message[] }) => {
        if (conversation) {
          let newUnreadCount = 0

          if (newHorizon) {
            newUnreadCount = await conversation.updateLastReadMessageIndex(newHorizon)
          } else if (newMessages) {
            const fromAPIUnreadCount = await conversation.getUnreadMessagesCount()
            if (fromAPIUnreadCount === null) {
              if (newMessages.length > 0) {
                newUnreadCount = newMessages.length
              }
            } else {
              newUnreadCount = fromAPIUnreadCount
            }
          }
          setUnreadMessagesCount(newUnreadCount)
          setLastReadMessageIndex(conversation.lastReadMessageIndex)
        }
      }, 500),
    [conversation],
  )

  const handleInitialMessages = useCallback(async () => {
    if (conversation) {
      const paginator = await conversation.getMessages()
      const newMessages = paginator.items
      setMessages(newMessages)
      debounceHandleReadHorizon({ newMessages })
    }
  }, [conversation, debounceHandleReadHorizon])

  useEffect(() => {
    // Code to execute once the client is intialized and we are in a conversation
    if (conversation) {
      handleInitialMessages()
    }
  }, [conversation, handleInitialMessages])

  useEffect(() => {
    if (conversation) {
      const handleMessageAdded = (message: Message) => {
        setMessages(prev => [...prev, message])
        debounceHandleReadHorizon({ newMessages: [message] })
      }

      conversation.addListener('messageAdded', handleMessageAdded)

      return () => {
        conversation.removeListener('messageAdded', handleMessageAdded)
      }
    }
  }, [conversation, debounceHandleReadHorizon])

  // PUBLIC METHODS

  const markAllMessagesRead = useCallback(() => {
    const lastMessage = messages[messages.length - 1]
    if (lastMessage) {
      debounceHandleReadHorizon({ newHorizon: lastMessage.index })
    }
  }, [messages, debounceHandleReadHorizon])

  const sendMessage = useCallback(
    async (message: string) => {
      if (conversation) {
        const newMessageIndex = await conversation.sendMessage(message)
        conversation.advanceLastReadMessageIndex(newMessageIndex)
      } else {
        throw new Error('Tried to send message without a conversation')
      }
    },
    [conversation],
  )

  const updatePreviousLastReadMessageIndex = useCallback(() => {
    setPreviousLastReadMessageIndex(lastReadMessageIndex)
  }, [lastReadMessageIndex])

  return (
    <VideoChatContext.Provider
      value={{
        chatClient,
        conversation,
        lastReadMessageIndex,
        markAllMessagesRead,
        messages,
        previousLastReadMessageIndex,
        sendMessage,
        unreadMessagesCount,
        updatePreviousLastReadMessageIndex,
      }}
    >
      {children}
    </VideoChatContext.Provider>
  )
}

export function useVideoChatContext() {
  const context = useContext(VideoChatContext)
  if (!context) {
    throw new Error('useVideoChatContext must be used within a VideoCallContextProvider')
  }
  return context
}
