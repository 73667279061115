import { IconProps } from './IconProps'

export function IconFileImage({ size = 24, color = 'currentColor', ...props }: IconProps) {
  return (
    <svg width={size} height={size} viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.31027 5.72027C6.31027 4.93815 6.95752 4.29613 7.74041 4.29613H16.041C16.4439 4.29613 17.1394 4.58832 17.4083 4.86498L24.1206 11.7706C24.4079 12.0662 24.6897 12.7568 24.6897 13.1669V26.2761C24.6897 27.065 24.0498 27.7039 23.2663 27.7039H7.73367C6.95025 27.7039 6.31027 27.0667 6.31027 26.2893V23.1994V9.70329V5.72027ZM4 23.1994V26.2893C4 28.334 5.67344 30 7.73367 30H23.2663C25.3281 30 27 28.3308 27 26.2761V13.1669C27 12.1605 26.4849 10.8982 25.7821 10.1752L19.0699 3.26964C18.3648 2.54426 17.0692 2 16.041 2H7.74041C5.68261 2 4 3.66902 4 5.72027V9.70329V23.1994ZM11.9255 15.0752C12.6877 15.0752 13.3052 14.4614 13.3052 13.7039C13.3052 12.9463 12.6877 12.3326 11.9255 12.3326C11.1633 12.3326 10.5458 12.9464 10.5458 13.7039C10.5458 14.4614 11.1633 15.0752 11.9255 15.0752ZM11.9255 10.8018C13.5381 10.8018 14.8454 12.1008 14.8454 13.7039C14.8454 15.3066 13.5385 16.6059 11.9255 16.6059C10.3129 16.6059 9.00558 15.307 9.00558 13.7039C9.00558 12.1011 10.3125 10.8018 11.9255 10.8018ZM6.30815 24.7604C6.30815 24.7604 10.2986 21.9879 10.2922 21.9897C10.3171 21.9878 14.5632 24.9007 14.5632 24.9007C15.131 25.29 15.919 25.1715 16.3456 24.6331L20.4882 19.4147L24.6906 24.5871L24.6925 22.1529L21.398 18.098C20.9091 17.4961 20.0353 17.5022 19.5541 18.1095L15.2717 23.5269L11.1649 20.712C10.6667 20.3705 9.93913 20.3729 9.44306 20.7172L6.30933 22.8924L6.30815 24.7604Z"
        fill={color}
      />
    </svg>
  )
}
