import { styled } from '@mui/material'
import { HTMLAttributes } from 'react'

import { csx, greySet } from '@nuna/tunic'

import { AppointmentDrawerPaddedContainer } from '../AppointmentDrawer'

export function DrawerFooter({ className, children, ...props }: HTMLAttributes<HTMLDivElement>) {
  return (
    <StyledDrawerFooter {...props} className={csx([className, 'mt-auto pb-1'])}>
      <div className="inner-content pt-2">{children}</div>
    </StyledDrawerFooter>
  )
}

const StyledDrawerFooter = styled(AppointmentDrawerPaddedContainer)`
  .inner-content {
    border-top: 1px solid ${greySet[15].hex};
  }
`
