import { useCallback, useEffect, useRef, useState } from 'react'

export interface ElementSize {
  height: number
  width: number
}
export function useResizeObserver(): [(node: HTMLElement) => void, ElementSize] {
  const [node, setNode] = useState<HTMLElement>()
  const [size, setSize] = useState<ElementSize>({ height: 0, width: 0 })

  const observer = useRef(
    new ResizeObserver(entries => {
      let newSize: ElementSize | undefined
      const entry = entries[0]
      if (entry.contentRect) {
        newSize = { height: entry.contentRect.height, width: entry.contentRect.width }
      }
      if (newSize) {
        setSize(newSize)
      }
    }),
  )

  const setRef = useCallback((node: HTMLElement) => {
    setNode(node)
  }, [])

  useEffect(() => {
    const { current: currentObserver } = observer
    currentObserver.disconnect()

    if (node) {
      currentObserver.observe(node)
    }

    return () => currentObserver.disconnect()
  }, [node])

  return [setRef, size]
}
