import { useCallback, useEffect, useState } from 'react'

import {
  ConversationMessageFragment,
  useActiveConversationContextLazyQuery,
  useConversationMessagesLazyQuery,
} from '@nuna/api'
import { useAuthDataContext } from '@nuna/auth'
import { toast } from '@nuna/tunic'

export function usePaginatedMessages(conversationId: string, messageId?: string, limit = 10) {
  const { login } = useAuthDataContext()

  const [activeConversationContextQuery, { loading: initialLoading }] = useActiveConversationContextLazyQuery({
    fetchPolicy: 'cache-and-network',
    variables: { conversationId, messageId, limit },
  })
  const [conversationMessagesQuery, { loading: pageLoading }] = useConversationMessagesLazyQuery()

  const [messages, setMessages] = useState<ConversationMessageFragment[]>([])
  const [nextToken, setNextToken] = useState<string | null>(null)
  const [prevToken, setPrevToken] = useState<string | null>(null)
  const [initialLastReadMessage, setInitialLastReadMessage] = useState<ConversationMessageFragment | null>(null)

  useEffect(() => {
    activeConversationContextQuery()
      .then(({ data }) => {
        if (data) {
          setMessages(data.activeConversationContext.messages)
          setNextToken(data.activeConversationContext.pagination?.nextToken ?? null)
          setPrevToken(data.activeConversationContext.pagination?.prevToken ?? null)
        }

        if (data && login) {
          const loggedInParticipant = data.activeConversationContext.participants?.find(
            participant => participant.loginId === login.id,
          )

          if (!loggedInParticipant) return
          const lastReadMessage = data.activeConversationContext.messages.find(
            message => message.id === loggedInParticipant.lastReadMessageId,
          )

          if (!lastReadMessage) return

          setInitialLastReadMessage(lastReadMessage)
        }
      })
      .catch(e => {
        console.error(e)
        toast.urgent('Could lot load messages')
      })
  }, [activeConversationContextQuery, login])

  const getMessages = async (token: string) => {
    const { data } = await conversationMessagesQuery({
      variables: { pagination: { token, limit }, filters: { conversationId } },
    })

    if (!data) throw new Error('Pagination response was empty')

    return {
      messages: data.conversationMessages.items,
      nextToken: data.conversationMessages.pagination?.nextToken ?? null,
      prevToken: data.conversationMessages.pagination?.prevToken ?? null,
    }
  }

  const loadUnreadMessages = useCallback(async () => {
    if (nextToken !== null) {
      return
    }
    const { data } = await activeConversationContextQuery({ fetchPolicy: 'network-only' })

    if (!data) throw new Error('New message response was empty')

    setMessages(existingMessages => {
      const newestMessages = filterAlreadyLoadedMessages(existingMessages, data.activeConversationContext.messages)
      return [...existingMessages, ...newestMessages]
    })

    if (data.activeConversationContext.pagination?.nextToken) {
      setNextToken(data.activeConversationContext.pagination.nextToken)
    }
  }, [activeConversationContextQuery, nextToken])

  const loadNewerPage = async () => {
    if (!nextToken) return

    const { messages: newMessages, nextToken: newNextToken } = await getMessages(nextToken)

    const newFilteredMessages = filterAlreadyLoadedMessages(messages, newMessages)

    setMessages([...messages, ...newFilteredMessages])
    setNextToken(newNextToken)
  }

  const loadOlderPage = async () => {
    if (!prevToken) return

    const { messages: oldMessages, prevToken: newPrevToken } = await getMessages(prevToken)

    setMessages([...oldMessages, ...messages])
    setPrevToken(newPrevToken)
  }

  return {
    messages,
    loadNewerPage,
    loadOlderPage,
    loading: initialLoading || pageLoading,
    hasOlder: !!prevToken,
    hasNewer: !!nextToken,
    initialLastReadMessage,
    loadUnreadMessages,
  }
}

function filterAlreadyLoadedMessages(
  alreadyLoadedMessages: ConversationMessageFragment[],
  justLoadedMessages: ConversationMessageFragment[],
) {
  const alreadyLoadedMessageIds = alreadyLoadedMessages.map(message => message.id)
  return justLoadedMessages.filter(message => !alreadyLoadedMessageIds.includes(message.id))
}
