import { ContextualAlert, ContextualAlertProps, IconInfo } from '@nuna/tunic'

import { useVideoCallContext } from '../../../../../context/VideoCallContext'
import { PatientVideoUser } from '../../../../../types'

interface AutonotesAlertProps extends Omit<ContextualAlertProps, 'children'> {
  patient: PatientVideoUser
}

export function AutonotesAlert({ patient, ...props }: AutonotesAlertProps) {
  const { getPatientAutonotesStatus, dismissPatientAutonoteAlert } = useVideoCallContext()
  const autonoteStatus = getPatientAutonotesStatus(patient)

  if (!autonoteStatus.showAlert) return null

  return (
    <ContextualAlert
      {...props}
      onDismiss={() => dismissPatientAutonoteAlert(patient)}
      dismissType="icon"
      icon={<IconInfo />}
      intent={autonoteStatus.enabled ? 'information' : 'caution'}
      variant="outlined"
    >
      {patient.firstName} has opted{' '}
      {autonoteStatus.enabled
        ? "in to Tava Scribe. We'll summarize this and upcoming sessions for you."
        : 'out of Tava Scribe'}
    </ContextualAlert>
  )
}
