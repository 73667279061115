import { IconProps } from './IconProps'

export function IconVideoOutline({ size = 24, color = 'currentColor', ...props }: IconProps) {
  return (
    <svg width={size} height={size} viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.3008 4.73804H1.87866V13.2428H12.3008V4.73804ZM13.9921 8.9904L16.1357 11.0604V6.92039L13.9921 8.9904ZM13.8008 4.19676C13.8008 3.66946 13.3694 3.23804 12.8421 3.23804H1.33739C0.810089 3.23804 0.378662 3.66946 0.378662 4.19676V13.784C0.378662 14.3113 0.810089 14.7428 1.33739 14.7428H12.8421C13.3694 14.7428 13.8008 14.3113 13.8008 13.784V10.8909L16.4169 13.4171C16.4472 13.4463 16.4795 13.4737 16.516 13.4946C17.0072 13.7758 17.6357 13.4236 17.6357 12.8419V5.13888C17.6357 4.55723 17.0072 4.20498 16.516 4.48618C16.4795 4.50707 16.4472 4.53447 16.4169 4.56368L13.8008 7.08986V4.19676Z"
        fill={color}
      />
    </svg>
  )
}
