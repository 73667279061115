import moment, { Moment } from 'moment'

import { InternalAppointmentFragment } from '@nuna/api'
import { specialtiesService } from '@nuna/core'

import { SessionData } from './types'

export function getBilledDuration({
  appointmentDate,
  startTime,
  endTime,
}: {
  appointmentDate: Moment
  startTime: string
  endTime: string
}) {
  return mergeAppointmentDateTime(appointmentDate, endTime).diff(
    mergeAppointmentDateTime(appointmentDate, startTime, true),
    'minutes',
  )
}

export function getDefaultTherapyTypeId(appointment: InternalAppointmentFragment) {
  if (appointment?.sessionDraft?.therapyTypeSpecialtyId) {
    return appointment.sessionDraft.therapyTypeSpecialtyId as string
  }

  if (appointment.therapyTypeSpecialty?.id) {
    return appointment.therapyTypeSpecialty.id
  }

  const providerSpecialties = specialtiesService.getTypesOfTherapy(appointment.provider.specialties)

  if (providerSpecialties.length === 1) {
    return providerSpecialties[0].id
  }
}

export function mergeAppointmentDateTime(date: string | moment.Moment, time: string, asString?: false): moment.Moment
export function mergeAppointmentDateTime(date: string | moment.Moment, time: string, asString?: true): string
export function mergeAppointmentDateTime(date: string | moment.Moment, time: string, asString?: boolean) {
  const dateMoment = moment(date)
  const timeMoment = moment(time, 'hh:mm a')

  if (asString && (!dateMoment.isValid() || !timeMoment.isValid())) {
    return ''
  }
  dateMoment.set('hours', timeMoment.get('hours'))
  dateMoment.set('minutes', timeMoment.get('minutes'))

  if (asString) {
    return dateMoment.toISOString()
  }

  return dateMoment
}

export function hasAutonoteError(sessionData: SessionData) {
  return !!sessionData.sessionSummary?.soapNoteError
}

export function hasAutonote(sessionData: SessionData) {
  return (
    sessionData.provider.tavaScribeEnabled &&
    !sessionData.sessionSummary?.shouldGenerateNote !== false &&
    !hasAutonoteError(sessionData)
  )
}

export function hasInSessionNotes(sessionData: SessionData) {
  return sessionData.inSessionNotes && sessionData.inSessionNotes !== sessionData.template
}
