import { FormikErrors, FormikTouched } from 'formik'
import React from 'react'

import { OrganizationRelationship } from '@nuna/api'
import { formService } from '@nuna/core'
import { ChipGroup, Radio, RadioTextCombo } from '@nuna/tunic'

import { EmployerCoverageFormValues } from './EmployerCoverageForm'
import { CoverageSectionHeading } from './SharedStyles'

// must disable no any's because we're not sure what the parent formik context is
/* eslint-disable @typescript-eslint/no-explicit-any */
interface EmployerAssociationFormProps {
  employerValues?: EmployerCoverageFormValues
  errors: FormikErrors<any>
  touched: FormikTouched<any>
  commonProps: {
    onBlur: (e: React.FocusEvent<HTMLInputElement>) => void
    onChange: (e: React.ChangeEvent<HTMLInputElement>) => void
    name: string // path to the field within the formik values object
  }
  employerAssociationEmployeeProps: {
    name: string
    fullWidth: boolean
    label?: string
    onBlur: (e: React.FocusEvent<HTMLInputElement>) => void
    onChange: (e: React.ChangeEvent<HTMLInputElement>) => void
  }
  isCheckout: boolean
}

const { composeNestedHelperTextWithError } = formService

export function EmployerAssociationForm({
  employerValues,
  errors,
  touched,
  commonProps,
  employerAssociationEmployeeProps,
  isCheckout,
}: EmployerAssociationFormProps) {
  const { employer, employerAssociation } = employerValues || {}
  const parentKey = commonProps.name.split('.')[0]
  const nestedKeyAssociation = commonProps.name.split('.')[1]
  const nestedKeyForEmployee = employerAssociationEmployeeProps.name.split('.')[1]
  const employerAssociationEmployeePropsWithNestedKey = {
    ...employerAssociationEmployeeProps,
    ...composeNestedHelperTextWithError('', errors, touched, parentKey, nestedKeyForEmployee),
  }
  if (!employer || !employer.offersTava) {
    return null
  }
  return (
    <>
      <CoverageSectionHeading isCheckout={isCheckout} id="#employer-association-heading">
        How are you associated with {employer.name}?
      </CoverageSectionHeading>

      <ChipGroup
        className="px-1"
        {...composeNestedHelperTextWithError('', errors, touched, parentKey, nestedKeyAssociation)}
        aria-labelledby="#employer-association-heading"
      >
        <Radio
          {...commonProps}
          checked={employerAssociation === OrganizationRelationship.Employee}
          value={OrganizationRelationship.Employee}
          className="mb-3"
          dataTestId="intake-association-employee"
        >
          Directly (I am directly afilliated with {employer.name})
        </Radio>

        <RadioTextCombo
          radioProps={{
            ...commonProps,
            checked: employerAssociation === OrganizationRelationship.SpousePartner,
            value: OrganizationRelationship.SpousePartner,
            className: 'mb-3',
            dataTestId: 'intake-association-partner',
          }}
          textFieldProps={{
            ...employerAssociationEmployeePropsWithNestedKey,
            label: 'Name of spouse or domestic partner',
          }}
        >
          Through my spouse or domestic partner
        </RadioTextCombo>

        <RadioTextCombo
          radioProps={{
            ...commonProps,
            checked: employerAssociation === OrganizationRelationship.Child,
            value: OrganizationRelationship.Child,
            className: 'mb-3',
            dataTestId: 'intake-association-child',
          }}
          textFieldProps={{
            ...employerAssociationEmployeePropsWithNestedKey,
            label: 'Name of parent or legal guardian',
          }}
        >
          Through my parent or legal guardian
        </RadioTextCombo>
      </ChipGroup>
    </>
  )
}
