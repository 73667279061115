import { styled } from '@mui/material'
import { useState } from 'react'

import {
  ExternalEmploymentStatus,
  ProviderPayout,
  useProviderEmploymentQuery,
  useProviderExternalEmploymentOnboardLinkLazyQuery,
} from '@nuna/api'
import { useAuthDataContext } from '@nuna/auth'
import { routeService } from '@nuna/core'
import { supportService } from '@nuna/telemetry'
import {
  Dialog,
  DialogContent,
  FillButton,
  Grid,
  IconButton,
  IconCheckCircle,
  IconError,
  IconGear,
  PageContent,
  Skeleton,
  SpinningLoader,
  TextButton,
  TextButtonLink,
  eggshell,
  errorIllustration,
  greySet,
  salmonSet,
  tealSet,
} from '@nuna/tunic'

import { RatesAndFees } from './RatesAndFees/RatesAndFees'
import { TaxDocuments } from './TaxDocuments'
import { TherapistPayouts } from './TherapistPayouts'
import { UpcomingPayouts } from './UpcomingPayouts'
import emptyImage from './img/money-sparkle-banner.png'
import { Container, Heading, SectionHeading } from './shared'

interface PayrollSummaryProps {
  payments?: ProviderPayout[]
  loading: boolean
  hasNext: boolean
  hasPrevious: boolean
  nextPage: () => void
  previousPage: () => void
}

export function PayrollSummary(props: PayrollSummaryProps) {
  const [loadingConfig, setLoadingConfig] = useState(false)
  const [showTroubleshootingDialog, setShowTroubleshootingDialog] = useState(false)
  const { login } = useAuthDataContext()
  const providerId = login?.providerId || ''
  const [getOnboardLinkQuery] = useProviderExternalEmploymentOnboardLinkLazyQuery()
  const { data: { providerEmployment } = {}, refetch } = useProviderEmploymentQuery({
    variables: { providerId },
    fetchPolicy: 'cache-and-network',
  })
  const employmentStatus = providerEmployment?.externalEmploymentStatus
  const employmentCompleted = employmentStatus === ExternalEmploymentStatus.Completed

  async function handleConfigureBank() {
    try {
      setLoadingConfig(true)
      const { data } = await getOnboardLinkQuery({
        variables: { providerId },
      })

      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      const handler = Check.create({
        link: data?.providerExternalEmploymentOnboardLink,
        onEvent: (event: string) => {
          if (event === 'check-onboard-app-loaded') {
            setLoadingConfig(false)
          }
          if (event === 'check-onboard-app-completed') {
            setTimeout(() => {
              refetch()
            }, 2000)
          }
        },
      })
      handler.open()
    } catch (err) {
      console.error(err)
      setShowTroubleshootingDialog(true)
      setLoadingConfig(false)
    }
  }

  function BankStatus({ externalEmploymentStatus }: { externalEmploymentStatus?: ExternalEmploymentStatus | null }) {
    switch (externalEmploymentStatus) {
      case ExternalEmploymentStatus.Completed:
        return (
          <BankingStatusChip className="v-align">
            <IconCheckCircle size={22} color={tealSet[50].hex} className="mr-1" />
            Your bank is connected
          </BankingStatusChip>
        )
      default:
        return (
          <BankingStatusChip className="v-align">
            <IconError size={22} color={salmonSet[80].hex} className="mr-1" />
            No connection to bank
          </BankingStatusChip>
        )
    }
  }

  function ConnectBankBanner() {
    return (
      <EmptyPayments className="">
        <img style={{ maxWidth: 288 }} src={emptyImage} alt="Time to get paid" />
        <h2 className="h4">Connect your bank to get paid</h2>
        <EmptyExplanation className="mb-3">
          We'll pay you on the 10th and 25th of each month through a simple ACH transfer to the bank of your choosing
        </EmptyExplanation>
        <FillButton onClick={handleConfigureBank} isLoading={loadingConfig}>
          Setup Direct Deposit
        </FillButton>
      </EmptyPayments>
    )
  }

  return (
    <PageContent>
      {!providerId && <Skeleton className="mt-2" height={10} style={{ width: '100%' }} />}
      {providerId && (
        <Grid container spacing={4}>
          <Grid
            size={{
              xs: 12,
              md: 9,
            }}
          >
            <UpcomingPayouts employmentStatusCompleted={employmentCompleted} />
            {employmentCompleted ? <TherapistPayouts {...props} /> : <ConnectBankBanner />}
          </Grid>
          <Grid
            size={{
              xs: 12,
              md: 3,
            }}
            style={{ marginTop: '-12px' }}
          >
            <Container>
              <Heading container>
                <Grid size={{ xs: 10 }}>
                  <SectionHeading>Your Bank</SectionHeading>
                </Grid>
                <Grid size={{ xs: 2 }} className="text-right">
                  {!loadingConfig ? (
                    <IconButton
                      variant="secondary"
                      tooltip="Configure bank account"
                      small
                      onClick={handleConfigureBank}
                    >
                      <IconGear size={18} />
                    </IconButton>
                  ) : (
                    <SpinningLoader $inFlow size={40} style={{ padding: '0.6rem' }} />
                  )}
                </Grid>
              </Heading>
              <Grid size={12} style={{ display: 'flex' }}>
                <BankStatus externalEmploymentStatus={employmentStatus} />
              </Grid>
            </Container>

            <RatesAndFees providerId={providerId} />

            <TaxDocuments providerId={providerId} />
          </Grid>
        </Grid>
      )}

      <Dialog isOpen={showTroubleshootingDialog} onClose={() => setShowTroubleshootingDialog(false)} maxWidth="xs">
        <DialogContent paddingSize="md">
          <div className="text-center mb-2">
            <img style={{ maxWidth: 200 }} src={errorIllustration} alt="" />
          </div>

          <h2 className="h5 text-medium mb-3">Oops, we can't configure your bank</h2>

          <ul style={{ paddingInlineStart: '1.25rem' }} className="list paragraphs">
            <li>
              Often, this happens when we don't have a validated home address on file.{' '}
              <TextButtonLink to={routeService.providerMeProfile}>Please verify</TextButtonLink> that your home address
              passes the validation check.
            </li>

            <li>
              If you have verified your address and are still having issues, please{' '}
              <TextButton
                variant="secondary"
                onClick={() => {
                  setShowTroubleshootingDialog(false)
                  supportService.openChat()
                }}
              >
                contact support
              </TextButton>
              .
            </li>
          </ul>
        </DialogContent>
      </Dialog>
    </PageContent>
  )
}

const EmptyPayments = styled('div')`
  text-align: center;
  margin-top: var(--margin-4);
  background-color: ${eggshell};
  border-radius: var(--border-radius);
  border: 1px solid ${greySet[15].hex};
  padding: var(--margin-4);
`

const EmptyExplanation = styled('div')`
  max-width: 30rem;
  margin: 0px auto var(--margin-3) auto;
`

const BankingStatusChip = styled('div')`
  border: 1px solid ${greySet[30].hex};
  background-color: ${eggshell};
  border-radius: 36px;
  padding: var(--margin-1) var(--margin-2) var(--margin-1) var(--margin-1);
  font-weight: 300;
`
