import daily from '@daily-co/daily-js'
import { styled } from '@mui/material'
import mixpanel from 'mixpanel-browser'
import { ChangeEvent, Fragment, HTMLAttributes, useEffect, useMemo, useState } from 'react'

import { Checkbox, Collapse, HelperText, IconInfo, Radio, RadioGroup, tealSet, toast } from '@nuna/tunic'

import { useSyncedDevicePreferencesUpdate } from '../hooks/useSyncedDevicePreferencesUpdate'
import { PersistedVideoDevicePreferences } from '../types'
import { videoSceneDark } from '../util/colors'
import { bgImages, blurStrengths, getDevicePreferences } from '../util/devicePreferences'

const { supportsVideoProcessing, mobile } = daily.supportedBrowser()

export function BackgroundOptions({ className = '', ...props }: HTMLAttributes<HTMLDivElement>) {
  const [videoProcessorType, setVideoProcessorType] = useState(getDevicePreferences().videoProcessorType)
  const [bgBlurStrength, setBgBlurStrength] = useState(getDevicePreferences().bgBlurStrength)
  const [bgImageIndex, setBgImageIndex] = useState(getDevicePreferences().bgImageIndex)

  const memoizedPreferences = useMemo<PersistedVideoDevicePreferences>(
    () => ({
      videoProcessorType,
      bgBlurStrength,
      bgImageIndex,
    }),
    [bgBlurStrength, bgImageIndex, videoProcessorType],
  )

  const { dailyInputError } = useSyncedDevicePreferencesUpdate(memoizedPreferences)

  useEffect(() => {
    if (!dailyInputError) return

    mixpanel.track('Video background error', { error: dailyInputError })
    toast.info('Video effect could not be started. It will be unavailable.')
    console.error(dailyInputError)
    setVideoProcessorType('none')
  }, [dailyInputError])

  const handleBlurToggleChange = (e: ChangeEvent<HTMLInputElement>) => {
    mixpanel.track('Background blur toggled', { enabled: e.currentTarget.checked })
    setVideoProcessorType(e.currentTarget.checked ? 'background-blur' : 'none')
  }

  const handleBgToggleChange = (e: ChangeEvent<HTMLInputElement>) => {
    mixpanel.track('Virtual background toggled', { enabled: e.currentTarget.checked })
    setVideoProcessorType(e.currentTarget.checked ? 'background-image' : 'none')
  }

  const handleStrengthChange = (e: ChangeEvent<HTMLInputElement>) => {
    setBgBlurStrength(parseFloat(e.currentTarget.value))
  }

  const handleBgImageChange = (e: ChangeEvent<HTMLInputElement>) => {
    setBgImageIndex(bgImages.indexOf(e.currentTarget.value))
  }

  return (
    <div className={`full-width ${className}`} {...props}>
      {!supportsVideoProcessing && (
        <HelperText error={false} className="top-align mb-2 text-white caption">
          <IconInfo size={16} className="shrink-0 mr-xs" />
          <span className="italic pr-1">
            Background effects are not supported on this {mobile ? 'device' : 'browser'}
          </span>
        </HelperText>
      )}

      <Checkbox
        checked={videoProcessorType === 'background-blur'}
        onChange={handleBlurToggleChange}
        disabled={!!dailyInputError || !supportsVideoProcessing}
      >
        Background blur
      </Checkbox>

      <Collapse isOpen={videoProcessorType === 'background-blur'}>
        <HorizontalRadioGroup className="pt-2 pl-4 v-align">
          {blurStrengths.map(({ value, label }) => (
            <Radio
              key={value}
              bgColor={videoSceneDark}
              name="bgBlur"
              checked={bgBlurStrength === value}
              value={value}
              onChange={handleStrengthChange}
            >
              {label}
            </Radio>
          ))}
        </HorizontalRadioGroup>
      </Collapse>

      <Checkbox
        className="mt-2"
        checked={videoProcessorType === 'background-image'}
        onChange={handleBgToggleChange}
        disabled={!!dailyInputError || !supportsVideoProcessing}
      >
        Virtual background
      </Checkbox>

      <Collapse isOpen={videoProcessorType === 'background-image'}>
        <VirtualBgGroup className="pt-2 pl-4 pb-1 pr-1">
          {bgImages.map((value, index) => (
            <Fragment key={value}>
              <input
                id={value}
                type="radio"
                value={value}
                checked={bgImageIndex === index}
                onChange={handleBgImageChange}
              />
              <ImageRadioLabel htmlFor={value} imgUrl={value} />
            </Fragment>
          ))}
        </VirtualBgGroup>
      </Collapse>
    </div>
  )
}

const HorizontalRadioGroup = styled(RadioGroup)`
  gap: var(--spacing-2);
`

const VirtualBgGroup = styled(RadioGroup)`
  display: grid;
  gap: var(--spacing-2);
  grid-template-columns: repeat(2, 1fr);

  input[type='radio'] {
    opacity: 0;
    position: absolute;
    pointer-events: none;

    &:checked + label {
      outline: 3px solid ${tealSet[50].hex};
    }
  }
`

const ImageRadioLabel = styled('label')<{ imgUrl: string }>`
  background-image: ${props => `url(${props.imgUrl})`};
  background-position: center;
  background-size: cover;
  border-radius: var(--border-radius-sm);
  cursor: pointer;
  height: 0;
  overflow: hidden;
  padding-top: 56.25%; // 16:9 ratio
  position: relative;
  width: 100%;
`
