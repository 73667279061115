import { PaymentPreference } from '@nuna/api'
import { IconCompany, IconCreditCard, IconKey, IconProps, IconShield } from '@nuna/tunic'

interface Props extends IconProps {
  type: PaymentPreference
}

export function CoverageTypeIcon({ type, ...props }: Props) {
  switch (type) {
    case PaymentPreference.Cash:
      return <IconCreditCard {...props} />
    case PaymentPreference.Insurance:
      return <IconShield {...props} />
    case PaymentPreference.Accesscode:
      return <IconKey {...props} />
    case PaymentPreference.Employer:
      return <IconCompany {...props} />
    default:
      return null
  }
}
