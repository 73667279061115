import { useMemo } from 'react'
import { formatPhoneNumber } from 'react-phone-number-input'

import { HrAdminFragment, useListCustomersQuery } from '@nuna/api'
import { Link } from '@nuna/common'
import { DataTable, DataTableColumn } from '@nuna/data-table'

interface Props {
  companyId?: string
  customerDetailRoute?: (companyId: string) => string
}

interface HRAdminRow {
  name: string
  email: string
  companyName: string
  companyId: string
  phone: string
}

export function HrAdminUsersTable({ companyId, customerDetailRoute }: Props) {
  const { data, loading } = useListCustomersQuery({ fetchPolicy: 'cache-and-network', variables: { companyId } })

  const columns = useMemo<DataTableColumn<HRAdminRow>[]>(() => {
    const newColumns: DataTableColumn<HRAdminRow>[] = [
      {
        accessor: 'name',
        Header: 'Name',
      },
      {
        accessor: 'email',
        Header: 'Email',
        Cell: ({ value }) => <a href={`mailto: ${value}`}>{value}</a>,
      },
      {
        accessor: 'phone',
        Header: 'Phone number',
        Cell: ({ value }) => <>{formatPhoneNumber(value)}</>,
      },
    ]

    if (customerDetailRoute) {
      newColumns.push({
        accessor: 'companyName',
        Header: 'Company',
        Cell: ({ value, row }) => <Link to={customerDetailRoute(row.original.companyId)}>{value}</Link>,
      })
    }
    return newColumns
  }, [customerDetailRoute])

  const rowData = useMemo<HRAdminRow[] | undefined>(() => data?.listCustomers.map(mapRow), [data])
  return <DataTable columns={columns} rowData={rowData} loading={loading} />
}

function mapRow(raw: HrAdminFragment): HRAdminRow {
  return {
    name: `${raw.login?.firstName} ${raw.login?.lastName}`,
    email: raw.login?.email ?? '',
    companyName: raw.company?.name ?? '',
    companyId: raw.companyId,
    phone: raw.mobilePhone ?? '',
  }
}
