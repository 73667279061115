import { styled } from '@mui/material'
import { TextField } from '@mui/material'
import { MobileDatePicker, MobileDatePickerProps } from '@mui/x-date-pickers'
// needed for AdapterMoment types to be picked up by TS
import type {} from '@mui/x-date-pickers/AdapterMoment'
import { type Moment } from 'moment'
import React, { FocusEventHandler, ReactNode, useState } from 'react'

import { IconCalendar } from '../../icons'
import { body2, interactiveFill } from '../../styles/colors'

// Note: The MobileDatePicker more closely follows the UX of our old Date Picker so I opted for it as our default
export interface DatePickerProps extends MobileDatePickerProps<Moment> {
  containerProps?: React.HTMLProps<HTMLDivElement>
  hideIcon?: boolean
  hideUnderline?: boolean
  error?: boolean
  helperText?: ReactNode
  onBlur?: FocusEventHandler<HTMLInputElement | HTMLTextAreaElement>
  onFocus?: FocusEventHandler<HTMLInputElement | HTMLTextAreaElement>
  placeholder?: string
  'data-testid'?: string
}

export function DatePicker({
  containerProps = {},
  error = false,
  hideIcon = false,
  hideUnderline = false,
  closeOnSelect = true,
  placeholder,
  onBlur,
  onFocus,
  helperText,
  onChange,
  sx,
  slots,
  ...props
}: DatePickerProps) {
  const [isOpen, setIsOpen] = useState(false)

  const { className = '', ...containerRest } = containerProps

  return (
    <div className={`v-align ${className}`} {...containerRest}>
      {!hideIcon && (
        <IconCalendar
          color={isOpen ? interactiveFill : body2}
          className={`mr-1 flex-static ${props.label ? 'mt-2' : ''}`}
        />
      )}

      <DatePickerStyled
        open={isOpen}
        onOpen={() => setIsOpen(true)}
        onClose={() => setIsOpen(false)}
        $isFocused={isOpen}
        $hideUnderline={hideUnderline}
        closeOnSelect={closeOnSelect}
        sx={{ width: '100%', ...sx }}
        slots={{
          actionBar: closeOnSelect ? () => null : undefined, // if closeOnSelect is false, will show default cancel/accept buttons.
          toolbar: () => null, // disabled by default (can override via slots prop if desired)
          textField: textFieldProps => (
            <TextField
              {...textFieldProps}
              error={error}
              onFocus={onFocus}
              onBlur={onBlur}
              placeholder={placeholder}
              helperText={helperText}
              data-testid={props['data-testid']}
            />
          ),
          ...slots,
        }}
        onChange={(date, context) => {
          if (onChange) {
            onChange(date, context)
          }
        }}
        {...props}
      />
    </div>
  )
}

const DatePickerStyled = styled(MobileDatePicker)<{ $isFocused: boolean; $hideUnderline: boolean }>`
  ${({ $hideUnderline }) =>
    $hideUnderline &&
    `
      .MuiInput-underline:before {
        border-bottom: 0;
      }
    `}

  ${({ $isFocused }) =>
    $isFocused &&
    `
  .MuiInput-underline {
    &::after {
      border-bottom-color: ${interactiveFill};
      transform: scaleX(1);
    }
  }
`}
`
