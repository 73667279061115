import { styled } from '@mui/material'

import { BelowTablet, Skeleton } from '@nuna/tunic'

import { IntakeStepContainer } from './IntakeStepContainer'

export function ProviderSignupIntakeSkeleton() {
  return (
    <IntakeStepContainer>
      <Grid>
        <Skeleton height={10} />
        <Skeleton height={10} />

        <Skeleton className="full-column mt-5" height={30} />
      </Grid>
    </IntakeStepContainer>
  )
}

const Grid = styled('div')`
  display: grid;
  grid-template-columns: 2fr 1fr;
  gap: 1rem;

  > div {
    width: 100%;
  }

  .full-column {
    grid-column: 1 / 3;
  }

  width: 932px;

  @media (${BelowTablet}) {
    width: 300px;
  }
`

export const Container = styled('main')`
  @media (${BelowTablet}) {
    margin-top: 2rem;
  }
`
