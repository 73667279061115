import { ProviderNetworkStatus, useProviderCompensationRatesQuery, useProviderMeQuery } from '@nuna/api'
import { useProviderId } from '@nuna/auth'
import { numberService } from '@nuna/core'
import { Popover, PopoverProps, Skeleton, makeTypographyComponent } from '@nuna/tunic'

import { TavaReferralCTABanner } from '../../../../scenes/Payroll/RatesAndFees/TavaReferralCTABanner'

const SHOW_RATES_STATUSES = [ProviderNetworkStatus.Approved, ProviderNetworkStatus.Offboarding]

export function ReferralsPopover(props: PopoverProps) {
  const providerId = useProviderId()
  const { data } = useProviderCompensationRatesQuery({ variables: { providerId } })
  const { data: providerData } = useProviderMeQuery()
  const initialEvalRate = data?.providerCompensationRates.tavaCompensations.find(
    compensation => compensation.cptCode?.code === '90791',
  )
  const sessionRate = data?.providerCompensationRates.tavaCompensations.find(
    compensation => compensation.cptCode?.code === '90837',
  )

  const networkStatus = providerData?.providerMe.networkStatus

  return (
    <Popover {...props}>
      <div style={{ maxWidth: 314 }}>
        <h3 className="h5 text-default mb-2">Tava Referrals</h3>
        <Body className="mb-3">Tava referrals are clients Tava sends you by marketing on your behalf.</Body>

        <MediumHeading>Rates</MediumHeading>

        <dl className="mb-3 paragraphs text-secondary">
          {!networkStatus || !SHOW_RATES_STATUSES.includes(networkStatus) ? (
            <>
              <Body className="mb-3">No referral rates to show yet.</Body>
              <TavaReferralCTABanner isPopOut providerId={providerData?.providerMe.id} />
            </>
          ) : (
            <>
              <div className="space-between mb-1">
                <dt>Initial Eval</dt>
                <dd className="text-medium">
                  {initialEvalRate ? (
                    numberService.centsToFormattedDollars(initialEvalRate.rate)
                  ) : (
                    <Skeleton width={2} height={1} />
                  )}
                </dd>
              </div>

              <div className="space-between">
                <dt>53+ Min Session</dt>
                <dd className="text-medium">
                  {sessionRate ? (
                    numberService.centsToFormattedDollars(sessionRate.rate)
                  ) : (
                    <Skeleton width={2} height={1} />
                  )}
                </dd>
              </div>
            </>
          )}
        </dl>

        <MediumHeading>Sources</MediumHeading>
        <SmallHeading>Employee Assistance Programs (EAP)</SmallHeading>
        <Body className="mb-2">500+ businesses and care networks refer clients through Tava.</Body>

        <SmallHeading>Therapist Directories</SmallHeading>
        <Body className="mb-0">
          Zocdoc, Psychology Today and Choosing Therapy all partner with Tava to market your practice at no additional
          cost to you.
        </Body>
      </div>
    </Popover>
  )
}

const Body = makeTypographyComponent('paragraphs text-secondary text-light', 'p')
const SmallHeading = makeTypographyComponent('body text-bold', 'h5')
const MediumHeading = makeTypographyComponent('h6 mb-2 text-dark-grey', 'h4')
