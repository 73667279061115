import { CSSProperties, ElementType, ReactNode } from 'react'

export interface TypographyComponentProps {
  children: ReactNode
  className?: string
  element?: ElementType
  style?: CSSProperties
  id?: string
}

interface BaseTypographyComponentProps extends TypographyComponentProps {
  baseClassName: string
  element: ElementType
}

export function TypographyComponent({
  baseClassName,
  children,
  element,
  className = '',
  style = {},
  ...props
}: BaseTypographyComponentProps) {
  const Tag = element

  return (
    <Tag style={style} className={`${baseClassName} ${className}`} {...props}>
      {children}
    </Tag>
  )
}

export function makeTypographyComponent(className: string, element: ElementType) {
  return function (props: TypographyComponentProps) {
    return <TypographyComponent baseClassName={className} element={element} {...props} />
  }
}
