import { IconProps } from './IconProps'

export interface IconCreditCardProps extends IconProps {
  showBan?: boolean
}

export function IconCreditCard({ size = 24, color = 'currentColor', showBan = false, ...props }: IconCreditCardProps) {
  if (showBan) {
    return (
      <svg width={size} height={size} viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M5.44384 25.0633C4.40164 25.0633 3.52178 24.2027 3.52178 23.141V16.0745H19.6294C21.0359 15.2664 22.6664 14.8044 24.4048 14.8044C26.4925 14.8044 28.4245 15.4707 29.9998 16.6024V8.32564C29.9998 6.21355 28.2736 4.5001 26.1457 4.5001C23.3562 4.4999 9.68841 4.50005 5.58491 4.50009L4.52841 4.5001C2.39277 4.5001 0.666504 6.21355 0.666504 8.32564V23.6738C0.666504 25.7859 2.39277 27.4993 4.52068 27.4993H15.3143C15.0514 26.7268 14.8842 25.9101 14.8268 25.0633H5.44384ZM25.3143 7.1093C26.3565 7.1093 27.2364 7.96991 27.2364 9.03152L27.2364 11.5336L3.52214 11.5336L3.52214 9.03152C3.52214 7.94855 4.38267 7.1093 5.4442 7.1093L25.3143 7.1093Z"
          fill={color}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M32 24.3926C32 28.5941 28.5941 32 24.3926 32C20.1911 32 16.7852 28.5941 16.7852 24.3926C16.7852 20.1911 20.1911 16.7852 24.3926 16.7852C28.5941 16.7852 32 20.1911 32 24.3926ZM29.3662 24.3926C29.3662 27.1395 27.1394 29.3663 24.3925 29.3663C23.5858 29.3663 22.824 29.1743 22.1503 28.8334L28.8333 22.1504C29.1742 22.8242 29.3662 23.5859 29.3662 24.3926ZM19.9752 26.6809L26.6807 19.9754C25.9957 19.6198 25.2176 19.4189 24.3925 19.4189C21.6456 19.4189 19.4188 21.6457 19.4188 24.3926C19.4188 25.2177 19.6197 25.9959 19.9752 26.6809Z"
          fill="#B33D3B"
        />
      </svg>
    )
  }

  return (
    <svg width={size} height={size} viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.28291 34.7121C5.28291 36.3045 6.60271 37.5954 8.16601 37.5954H37.9712C39.5635 37.5954 40.8543 36.3365 40.8543 34.7121V24.1122H5.28291V34.7121ZM6.79285 6.75067C3.5894 6.75067 1 9.32084 1 12.489V35.5112C1 38.6793 3.5894 41.2495 6.78127 41.2495H39.2187C42.4106 41.2495 45 38.6793 45 35.5112V12.489C45 9.32084 42.4106 6.75067 39.2187 6.75067C34.5055 6.75033 9.08753 6.75066 6.79285 6.75067ZM40.8549 13.5478C40.8549 11.9554 39.5351 10.6645 37.9718 10.6645L8.16655 10.6645C6.57426 10.6645 5.28345 11.9233 5.28345 13.5478L5.28345 17.301L40.8549 17.301L40.8549 13.5478Z"
        fill={color}
      />
    </svg>
  )
}
