import moment from 'moment-timezone'
import { useEffect, useState } from 'react'

export function CurrentTime({ timezone = moment.tz.guess(), format = 'h:mm a' }) {
  const [currentTime, setCurrentTime] = useState(moment())

  useEffect(() => {
    const nextUpdate = (60 - currentTime.seconds()) * 1000

    const timer = setTimeout(() => {
      setCurrentTime(moment())
    }, nextUpdate)

    return () => clearTimeout(timer)
  }, [currentTime])

  return <span>{moment(currentTime).tz(timezone).format(format)}</span>
}
