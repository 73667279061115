/* eslint-disable @typescript-eslint/no-explicit-any */
import { AddressBase } from '../exported'
import { Maybe } from './common.types'

export interface Address {
  addressLineOne?: Maybe<string>
  addressLineTwo?: Maybe<string>
  city?: Maybe<string>
  state?: Maybe<string>
  zipCode?: Maybe<string>
  county?: Maybe<string>
}

export enum AddressOwner {
  Provider = 'PROVIDER',
  Tava = 'TAVA',
}

export enum AddressType {
  ProviderHome = 'PROVIDER_HOME',
  ProviderPractice = 'PROVIDER_PRACTICE',
}

export type AddressFragment = AddressBase & {
  __typename?: 'Address'
  id: string
  name: string
  addressType: AddressType
  addressHash: string
  timezone?: string | null
  phone?: string | null
  fax?: string | null
  owner: AddressOwner
  createdAt: any
  updatedAt: any
}

export type ProviderAddressFragment = {
  __typename?: 'ProviderAddress'
  primaryPracticeLocation: boolean
  providerId: string
  addressId: string
  address: AddressBase & {
    __typename?: 'Address'
    id: string
    name: string
    addressType: AddressType
    addressHash: string
    timezone?: string | null
    phone?: string | null
    fax?: string | null
    owner: AddressOwner
    createdAt: any
    updatedAt: any
  }
}
