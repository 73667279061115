import { IconProps } from './IconProps'

/** @deprecated wrong size. use IconVideo */
export function IconVideoDeprecated({ size = 24, color = 'currentColor' }: IconProps) {
  const height = (28 / 29) * size

  return (
    <svg width={height} height={height} viewBox="0 0 29 28" fill={color} xmlns="http://www.w3.org/2000/svg">
      <path d="M20.7966 11.4531V7.59569C20.7966 6.89262 20.2214 6.31738 19.5183 6.31738H4.17869C3.47563 6.31738 2.90039 6.89262 2.90039 7.59569V20.3787C2.90039 21.0818 3.47563 21.657 4.17869 21.657H19.5183C20.2214 21.657 20.7966 21.0818 20.7966 20.3787V16.5212L24.2241 19.831C24.3048 19.9089 24.39 19.9842 24.4916 20.0317C25.1338 20.3319 25.9098 19.8689 25.9098 19.1226V8.85184C25.9098 8.10555 25.1338 7.6425 24.4916 7.94274C24.39 7.99023 24.3048 8.06554 24.2241 8.14344L20.7966 11.4531Z" />
    </svg>
  )
}
