import { styled } from '@mui/material'
import { useState } from 'react'

import { InsuranceCoverageDetailsFragment, Role } from '@nuna/api'
import { hasRole, useUserRole } from '@nuna/auth'
import {
  Card,
  CardBody,
  CardHeader,
  CardProps,
  IconButton,
  IconSessions,
  IconTherapist,
  StatusLabel,
  greySet,
} from '@nuna/tunic'

import { isInsuranceInvalid } from '../utils/client-coverage.utils'
import { CoverageLogo, CoveragePanelName } from './CoverageTypography'
import { InsurancePolicyDialog } from './InsurancePolicyDialog/InsurancePolicyDialog'

interface Props extends CardProps {
  coverage: InsuranceCoverageDetailsFragment
}

export function InsuranceCoverageCard({ coverage, ...props }: Props) {
  const role = useUserRole()
  const [showPolicyDialog, setShowPolicyDialog] = useState(false)
  const { planName, insuranceLogoUrl, planMemberId, subscriber } = coverage

  const invalid = isInsuranceInvalid(coverage)

  return (
    <Card depth={invalid ? -4 : 1} {...props}>
      <CardHeader className="v-align space-between">
        <span>Insurance Coverage</span>
        <IconTherapist />
      </CardHeader>
      <CardBody>
        {insuranceLogoUrl && <CoverageLogo className="mt-1" src={insuranceLogoUrl} alt="payer logo" />}
        {!insuranceLogoUrl && <CoveragePanelName className="mt-1">{planName}</CoveragePanelName>}
        {hasRole(role, Role.Admin) && (
          <MemberDetailsContainer className="mt-2">
            <div className="space-between">
              <div>
                <div>{subscriber}</div>
                <div>
                  <span className="text-medium">ID: </span>
                  {planMemberId}
                </div>
              </div>
              <IconButton variant="secondary" tooltip="Validation Log" onClick={() => setShowPolicyDialog(true)}>
                <IconSessions />
              </IconButton>
            </div>
          </MemberDetailsContainer>
        )}
        {hasRole(role, Role.Patient) && (
          <MemberDetailsContainer className="mt-2">
            <div className="caption text-bold">Member ID:</div>
            <div>{planMemberId}</div>
          </MemberDetailsContainer>
        )}
        {invalid && (
          <StatusLabel intent="urgent" className="mt-2">
            Invalid coverage
          </StatusLabel>
        )}
        {invalid && coverage.failureReason && (
          <p className="caption text-error mt-2 mb-0 text-medium">{coverage.failureReason}</p>
        )}
      </CardBody>

      {hasRole(role, Role.Admin) && (
        <InsurancePolicyDialog isOpen={showPolicyDialog} closeDialog={() => setShowPolicyDialog(false)} />
      )}
    </Card>
  )
}

const MemberDetailsContainer = styled('div')`
  border-radius: var(--border-radius-sm);
  background-color: ${greySet.tint[20]};
  padding: var(--margin-1);
  color: ${greySet[70].hex};
`
