import { IconProps } from './IconProps'

export function IconPreview({ size = 24, color = 'currentColor', ...props }: IconProps) {
  return (
    <svg width={size} height={size} viewBox="0 0 56 56" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M52 27.9817C52 27.9817 41.2548 13 28 13C14.7452 13 4 27.9817 4 27.9817C4 27.9817 14.7452 42.9633 28 42.9633C41.2548 42.9633 52 27.9817 52 27.9817ZM38.9091 27.9817C38.9091 22.0716 34.0249 17.2805 28 17.2805C21.9751 17.2805 17.0909 22.0716 17.0909 27.9817C17.0909 33.8918 21.9751 38.6828 28 38.6828C34.0249 38.6828 38.9091 33.8918 38.9091 27.9817ZM27.983 34.426C24.368 34.426 21.4375 31.5514 21.4375 28.0053C21.4375 24.4593 24.368 21.5846 27.983 21.5846V28.0053H34.5284C34.5284 31.5514 31.5979 34.426 27.983 34.426Z"
        fill={color}
      />
    </svg>
  )
}
