import { noop } from 'lodash'
import mixpanel from 'mixpanel-browser'

import { DrawerAppointmentFragment } from '@nuna/api'
import { DrawerStepper } from '@nuna/common'
import { formService } from '@nuna/core'
import { MessageComposer, useMessageComposerContext } from '@nuna/messaging'
import { PaymentMethodSelect } from '@nuna/payment-methods'

import { AppointmentDrawerPaddedContainer } from '../../../AppointmentDrawer'
import { useCancelForm } from '../../../hooks/useCancelForm'
import { useClientCancelOrReschedulePaymentMethods } from '../../../hooks/useClientCancelOrReschedulePaymentMethods'
import { AppointmentChangeReasonForm } from '../../AppointmentChangeReasonForm'

interface Props {
  appointment: DrawerAppointmentFragment
  subjectToFee: boolean
}

export function CancelAsClient({ appointment, subjectToFee }: Props) {
  const { formProps } = useCancelForm({ appointment, audience: 'client' })
  const { loading: saveMessageLoading, invalidAttachments } = useMessageComposerContext()

  const {
    defaultForFees,
    paymentMethods,
    savePaymentMethod,
    queryResult: { loading: paymentMethodsLoading },
  } = useClientCancelOrReschedulePaymentMethods({
    formProps,
    subjectToFee,
  })

  const { submitForm, errors, values } = formProps

  return (
    <AppointmentDrawerPaddedContainer className="mt-4">
      <div className="large text-medium mb-1">Message to {appointment.provider.firstName}</div>
      <MessageComposer
        placeholder="Message your therapist"
        audience="client"
        autoCollapse={false}
        boot={
          <AppointmentChangeReasonForm
            formProps={formProps}
            audience="client"
            requestedBy="client"
            labelText="Why Cancel?"
          />
        }
      />
      {values.hasValidPaymentMethod === false && (
        <>
          <div className="text-bold mt-2 mb-1">Payment</div>
          <PaymentMethodSelect
            defaultFor="fees"
            savePaymentMethod={savePaymentMethod}
            value={defaultForFees}
            paymentMethods={paymentMethods}
            onChange={noop}
            loading={paymentMethodsLoading}
            patientId={appointment.patient.id}
            {...formService.composeHelperTextWithError('', errors.hasValidPaymentMethod, true)}
          />
        </>
      )}

      <DrawerStepper
        nextButtonText="Cancel And Send"
        nextButtonDiabled={invalidAttachments || saveMessageLoading}
        onNextButtonClick={async () => {
          await submitForm()

          if (subjectToFee) {
            mixpanel.track('canceled late', { appointmentId: appointment.id })
          }
        }}
        className="mt-4"
        nextButtonProps={{ 'data-testid': 'siderail-cancel-submit' }}
        formProps={formProps}
      />
    </AppointmentDrawerPaddedContainer>
  )
}
