import { Autocomplete, AutocompleteProps, TextField } from '@mui/material'
import { debounce, noop } from 'lodash'
import { FocusEventHandler, useEffect, useMemo, useRef, useState } from 'react'

import { UsAddress, useLookupUsAddressLazyQuery } from '@nuna/api'
import { IconLocation } from '@nuna/tunic'

interface Props extends Pick<AutocompleteProps<UsAddress, false, true, true>, 'style' | 'className' | 'disabled'> {
  value?: UsAddress | string
  helperText?: string | JSX.Element
  label?: string
  name?: string
  error: boolean
  onBlur?: FocusEventHandler<HTMLInputElement | HTMLTextAreaElement>
  onChange: (event: React.ChangeEvent<unknown>, addressLineOne?: string, address?: UsAddress) => void
  inputProps?: object
  optionProps?: Record<string, unknown>
  setIsLoadingLookupAddress?: (loading: boolean) => void
  setHasOptions?: (hasOptions: boolean) => void
}

export function AddressAutoComplete({
  onBlur,
  error,
  helperText,
  name,
  label,
  onChange,
  setIsLoadingLookupAddress = noop,
  setHasOptions = noop,
  inputProps,
  ...props
}: Props) {
  const [options, setOptions] = useState<UsAddress[]>([])
  const [needsVerification, setNeedsVerification] = useState(true)
  const timeoutRef = useRef<NodeJS.Timeout | null>(null)
  const lastLoadingUpdateTimeRef = useRef<number>(Date.now())
  const [lookupUSAddress, { loading }] = useLookupUsAddressLazyQuery({
    fetchPolicy: 'network-only',
  })

  useEffect(() => {
    const setLoading = () => {
      setIsLoadingLookupAddress(loading)
      lastLoadingUpdateTimeRef.current = Date.now()
    }

    // Check if current update is allowed based on the delay
    const now = Date.now()
    if (now - lastLoadingUpdateTimeRef.current >= 500) {
      // If more than 500ms has passed since the last update, update
      setLoading()
    } else {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current)
      }
      timeoutRef.current = setTimeout(setLoading, 500 - (now - lastLoadingUpdateTimeRef.current))
    }

    return () => {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current)
      }
    }
  }, [loading, setIsLoadingLookupAddress])

  function handleChange(event: React.ChangeEvent<unknown>, newValue: string | UsAddress) {
    if (typeof newValue === 'string') {
      onChange(event, newValue)
      setNeedsVerification(true)
    } else {
      setNeedsVerification(false)
      onChange(event, undefined, newValue)
    }
  }

  const updateOptions = useMemo(
    () =>
      debounce((newValue: string) => {
        const currentAddress = typeof props.value === 'string' ? undefined : props.value
        lookupUSAddress({
          variables: {
            address: {
              addressLineOne: newValue,
              ...(currentAddress?.city && { city: currentAddress.city }),
              ...(currentAddress?.state && { state: currentAddress.state }),
              ...(currentAddress?.zipCode && { zipCode: currentAddress.zipCode }),
            },
          },
        })
          .then(({ data }) => {
            if (data?.lookupUSAddress) {
              setOptions(data.lookupUSAddress)
              setHasOptions(data.lookupUSAddress.length > 0)
            } else {
              setHasOptions(false)
            }
          })
          .catch(console.error)
      }, 500),
    [props.value, lookupUSAddress, setHasOptions],
  )

  const handleInputChange = (event: React.ChangeEvent<unknown>, newValue: string) => {
    if (!event) return
    setNeedsVerification(true)
    updateOptions(newValue)
  }

  const handleBlur = (event: React.FocusEvent<HTMLInputElement, Element>) => {
    if (needsVerification) {
      onChange(event, event?.target?.value)
    }
    onBlur && onBlur(event)
  }

  return (
    <Autocomplete
      multiple={false}
      freeSolo={true}
      disableClearable={true}
      options={options}
      filterOptions={x => x}
      autoComplete
      includeInputInList
      filterSelectedOptions
      getOptionLabel={option => (typeof option === 'string' ? option : option.addressLineOne)}
      renderInput={params => {
        const adjustedParams = { ...params, inputProps: { ...params.inputProps, ...inputProps } }

        return (
          <TextField
            {...adjustedParams}
            name={name}
            onBlur={handleBlur}
            error={error}
            helperText={helperText}
            label={label}
            placeholder="Begin typing to add address"
          />
        )
      }}
      renderOption={(renderOptionProps, option: string | UsAddress) => {
        return (
          <li {...renderOptionProps}>
            <div className={`v-align ${props.optionProps?.className}`}>
              <IconLocation size={16} />
              <span className="ml-1">{typeof option === 'string' ? option : addressToText(option)}</span>
            </div>
          </li>
        )
      }}
      onChange={handleChange}
      onInputChange={handleInputChange}
      {...props}
    />
  )
}

function addressToText(address: UsAddress) {
  return [address.addressLineOne, address.addressLineTwo, address.city, address.state, address.zipCode]
    .filter(v => !!v)
    .join(', ')
}
