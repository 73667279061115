import moment from 'moment'

import {
  AppointmentCollectionStatus,
  AppointmentCurrentStatus,
  HistoryAppointmentCurrentStatus,
  SessionPaymentStatus,
} from '../types/internal-only'

function wasRescheduled(appointment: Pick<AppointmentCollectionStatus, 'appointmentHistoryItemStatus'>) {
  const rescheduledStatuses: (HistoryAppointmentCurrentStatus | null | undefined)[] = [
    HistoryAppointmentCurrentStatus.RescheduledByProvider,
    HistoryAppointmentCurrentStatus.RescheduledByAdmin,
    HistoryAppointmentCurrentStatus.RescheduledByPatient,
  ]

  return rescheduledStatuses.includes(appointment.appointmentHistoryItemStatus)
}

function hasFee(appointment: Pick<AppointmentCollectionStatus, 'sessionPaymentId'>) {
  return !!appointment.sessionPaymentId
}

function wasClientNoShow(appointment: Pick<AppointmentCollectionStatus, 'currentStatus'>) {
  return appointment.currentStatus === AppointmentCurrentStatus.NoShowByPatient
}

function hasScheduledFee(appointment: Pick<AppointmentCollectionStatus, 'paymentStatus'>) {
  return appointment.paymentStatus === SessionPaymentStatus.Scheduled
}

function hasWaivedFee(appointment: Pick<AppointmentCollectionStatus, 'paymentStatus'>) {
  return appointment.paymentStatus === SessionPaymentStatus.Waived
}

function canReverseFeeDecision(appointment: Pick<AppointmentCollectionStatus, 'feeDue'>) {
  return moment(appointment.feeDue).isAfter(moment())
}

export const appointmentCollectionStatusService = {
  canReverseFeeDecision,
  wasRescheduled,
  hasFee,
  hasScheduledFee,
  wasClientNoShow,
  hasWaivedFee,
}
