import * as Yup from 'yup'
import { styled } from '@mui/material'
import { useMediaQuery } from 'react-responsive'

import { FullProviderFragment, Pronouns, useUpdateProviderMutation } from '@nuna/api'
import { ProfileDisplayValue, ProfileSection } from '@nuna/common'
import { userService } from '@nuna/core'
import { BelowTablet, Grid, Radio, RadioGroup, greySet } from '@nuna/tunic'

import { incompleteSectionNotification } from '../../../utils/util'

const { humanReadablePronouns } = userService
const PRONOUN_OPTIONS: Pronouns[][] = [
  [Pronouns.HimHis, Pronouns.HimThey, Pronouns.TheyThem],
  [Pronouns.HerHers, Pronouns.HerThey],
]

export type PronounSectionProps = Pick<FullProviderFragment, 'id' | 'pronouns'>

type PronounValues = {
  pronouns: Pronouns
}

export function PronounSection({ pronouns, id }: PronounSectionProps) {
  const [updateProvider, { loading: updateLoading }] = useUpdateProviderMutation()
  const isMobile = useMediaQuery({ query: `(${BelowTablet})` })

  const initialValues: PronounValues = {
    pronouns: pronouns as Pronouns,
  }

  const [isComplete, valueDisplay] = incompleteSectionNotification<PronounValues>(
    initialValues,
    ['pronouns'],
    <ProfileDisplayValue>{pronouns && humanReadablePronouns[pronouns as Pronouns]}</ProfileDisplayValue>,
  )

  return (
    <ProfileSection
      heading="Preferred Pronouns"
      useOutlineSaveButton
      description=""
      displayWidth="full"
      updateLoading={updateLoading}
      initialValues={initialValues}
      handleSubmit={values =>
        updateProvider({ variables: Object.assign({ id }, { pronouns: values.pronouns as Pronouns }) })
      }
      disabled={({ isValid }) => !isValid}
      sectionComplete={isComplete}
      renderDisplayValue={valueDisplay}
      renderForm={({ values, handleChange }) => (
        <>
          <RadioLabel>Please select one</RadioLabel>

          <ColumnedRadioGroup>
            <Grid container spacing={isMobile ? 0 : 4}>
              {PRONOUN_OPTIONS.map((pronounOptions, idx) => (
                <Grid
                  key={idx}
                  className="column"
                  size={{
                    xs: 12,
                    md: 'auto',
                  }}
                >
                  {pronounOptions.map(pronounOption => (
                    <Radio
                      name="pronouns"
                      onChange={handleChange}
                      value={pronounOption}
                      checked={values.pronouns === pronounOption}
                      key={pronounOption}
                    >
                      {humanReadablePronouns[pronounOption]}
                    </Radio>
                  ))}
                </Grid>
              ))}
            </Grid>
          </ColumnedRadioGroup>
        </>
      )}
      validationSchema={Yup.object().shape({
        pronouns: Yup.string(),
      })}
    ></ProfileSection>
  )
}

const RadioLabel = styled('label')`
  display: block;
  padding-bottom: var(--margin-3);
  color: ${greySet[90].hex};
`

const ColumnedRadioGroup = styled(RadioGroup)`
  .column {
    width: 244px;
  }
  @media (${BelowTablet}) {
    .column {
      width: auto;
    }
  }
`
