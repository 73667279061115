import { styled } from '@mui/material'
import { useMediaQuery } from 'react-responsive'

import { useAuthDataContext } from '@nuna/auth'
import { Link } from '@nuna/common'
import { BelowTablet, IconChevronThick, IconPower, Logo, greySet, tealSet } from '@nuna/tunic'

import { useProviderSignupIntakeContext } from '../../../scenes/SignupIntake/ProviderSignupIntakeContext'
import { SignupIntakeNavMenu } from './SignupIntakeNavMenu'

interface Props {
  onSaveAndNavigate: (to: string) => Promise<boolean>
}

export function SignupIntakeHomeHeader({ onSaveAndNavigate }: Props) {
  const isMobile = useMediaQuery({ query: `(${BelowTablet})` })
  const { resumePath } = useProviderSignupIntakeContext()

  const { onLogout } = useAuthDataContext()
  return (
    <HomeHeader className="space-between v-align text-secondary">
      {isMobile && (
        <Link to={resumePath} className="v-align">
          <IconChevronThick /> Back
        </Link>
      )}
      {!isMobile && (
        <div className="v-align">
          <SignupIntakeNavMenu onSaveAndNavigate={onSaveAndNavigate} variant="secondary" />
          <span className="text-medium large serif" style={{ color: tealSet[90].hex }}>
            Back to Account Setup
          </span>
        </div>
      )}
      <Logo color="plum" height={32} renderText />
      <button type="button" onClick={() => onLogout()} className="v-align">
        <IconPower size={16} className="mr-1" />
        Sign out
      </button>
    </HomeHeader>
  )
}

const HomeHeader = styled('div')`
  height: 78px;
  padding: 0 var(--margin-4);
  flex-shrink: 0;
  width: 100%;
  background-color: ${greySet[0].hex};
  position: fixed;
  z-index: 1;

  @media (${BelowTablet}) {
    padding: 0 var(--margin-2);
  }
`
