import type { Location } from 'react-router-dom'

import type { Role } from '@nuna/api'

export interface RouteState {
  from: string
}

export const buildRedirectParams = (role?: Role) => {
  const searchParams = new URLSearchParams()
  const from = window.location.pathname + window.location.search
  if (from && from !== '/') searchParams.append('from', encodeURIComponent(from))
  if (role) searchParams.append('loggedOutRole', role)
  return searchParams.size > 0 ? `?${searchParams}` : ''
}

export const redirectToLogin = ({ organizationSlug, role }: { organizationSlug?: string; role?: Role } = {}) => {
  window.location.href = `/login${organizationSlug ? '/' + organizationSlug : ''}` + buildRedirectParams(role)
}

export const getFromPath = (location: Location) => {
  if (location.state) return (location.state as RouteState).from
  const queryParam = new URLSearchParams(location.search).get('from')
  if (queryParam) return decodeURIComponent(queryParam)
  return undefined
}
