import { styled } from '@mui/material'
import { noop } from 'lodash'

import { ConversationMessageFragment, Role, useActiveConversationContextQuery } from '@nuna/api'
import { type Persona } from '@nuna/core'
import { FillButton, TextButtonExternalLink, greySet, makeTypographyComponent, toast } from '@nuna/tunic'

import { useConversationContext } from '../../../context/ConversationContext'
import { MessageComposerContextProvider, useMessageComposerContext } from '../../../context/MessageComposerContext'
import { MessageComposer } from '../../MessageComposer/MessageComposer'

interface ConversationDetailComposerProps {
  audience: Persona
  conversationId: string
  onMessageSent?: (message: ConversationMessageFragment) => void
}

export function ConversationDetailComposer(props: ConversationDetailComposerProps) {
  return (
    <MessageComposerContextProvider conversationId={props.conversationId}>
      <ConversationDetailComposerWithContext {...props} />
    </MessageComposerContextProvider>
  )
}

function ConversationDetailComposerWithContext({ audience, onMessageSent = noop }: ConversationDetailComposerProps) {
  const { sendMessage, reset, invalidAttachments, loading } = useMessageComposerContext()
  const { activeConversationId } = useConversationContext()
  const { data: convoData } = useActiveConversationContextQuery({
    skip: !activeConversationId,
    variables: { conversationId: activeConversationId || '' },
  })

  const patientLoginId =
    convoData?.activeConversationContext.participants?.filter(p => p.displayData.role === Role.Patient)[0].loginId || ''

  return (
    <MessageComposer
      audience={audience}
      toolbarRightSlot={
        <FillButton
          onClick={async () => {
            try {
              const message = await sendMessage(patientLoginId)
              onMessageSent(message)
              reset()
            } catch (e) {
              console.error(e)
              toast.urgent('Your message could not be sent. Please try again.')
            }
          }}
          className="ml-3"
          isLoading={loading}
          disabled={invalidAttachments}
        >
          Send
        </FillButton>
      }
      boot={
        audience === 'client' && (
          <MessagingDisclaimer>
            Messaging correspondence is not intended for urgent or crisis challenges. Please use{' '}
            <TextButtonExternalLink href="https://www.tavahealth.com/get-help" variant="secondary">
              these resources
            </TextButtonExternalLink>{' '}
            for emergencies.
          </MessagingDisclaimer>
        )
      }
    />
  )
}

const MessagingDisclaimer = styled(makeTypographyComponent('caption text-medium text-secondary', 'p'))`
  background-color: ${greySet[5].hex};
  margin: 0;
  padding: 1rem;
`
