import { useCPULoad } from '@daily-co/daily-react'
import { omitBy } from 'lodash'
import mixpanel from 'mixpanel-browser'
import { useEffect } from 'react'

import { useVideoCallContext } from '../context/VideoCallContext'
import { getDevicePreferences } from '../util/devicePreferences'

export function useCpuLogging() {
  const { appointmentId, roomUrl } = useVideoCallContext()
  const { state, reason } = useCPULoad()

  useEffect(() => {
    if (state === 'low') return

    mixpanel.track('Daily - high CPU load', {
      appointmentId,
      state,
      reason,
      roomUrl,
      devicePreferences: omitBy(getDevicePreferences(), (_, key) => key.endsWith('DeviceId')), // device IDs are internal to the device and would just be noise
    })
  }, [state, reason, appointmentId, roomUrl])
}
