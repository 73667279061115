import { IconProps } from './IconProps'

export function IconMinus({ size = 24, color = 'currentColor', ...props }: IconProps) {
  return (
    <svg width={size} height={size} viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M20.9756 27.8656H0L0 20H20.9756H27.0244H48V27.8656H27.0244"
        fill={color}
      />
    </svg>
  )
}
