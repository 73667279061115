import { styled } from '@mui/material'
import { Fragment, HTMLAttributes, useEffect } from 'react'
import { useMediaQuery } from 'react-responsive'
import { useMatch, useNavigate } from 'react-router-dom'

import {
  ConversationFragment,
  ConversationParticipantFragment,
  ConversationStatus,
  Role,
  SearchConversationParticipantFragment,
} from '@nuna/api'
import { useAuthDataContext } from '@nuna/auth'
import { type Persona, routeService } from '@nuna/core'
import {
  Avatar,
  BelowTablet,
  IconButton,
  IconChevronThick,
  IconClose,
  MenuItem,
  borderGrey,
  csx,
  greySet,
  toast,
} from '@nuna/tunic'

import { useConversationContext } from '../../context/ConversationContext'
import { useConversationUnreadContext } from '../../context/ConversationUnreadContext'
import { useSaveConversationMutationWithCacheUpdate } from '../../hooks/useSaveConversationMutationWithCacheUpdate'
import { UnreadBadge } from '../UnreadBadge'
import { ConversationCreateDropdown } from './components/ConversationCreateDropdown'

interface ConversationMenuProps extends HTMLAttributes<HTMLDivElement> {
  audience: Persona
}

export function ConversationMenu({ audience, ...props }: ConversationMenuProps) {
  const navigate = useNavigate()
  const { login } = useAuthDataContext()
  const { conversations, canHideConversations, hideConversation } = useConversationContext()

  const [saveConversationMutation] = useSaveConversationMutationWithCacheUpdate()

  const isMobile = useMediaQuery({ query: `(${BelowTablet})` })
  const isConversationActive = !!useMatch(routeService.conversationsDetailFullPath)

  // auto select most recent convo unless on mobile
  useEffect(() => {
    if (!isMobile && conversations.length > 0 && !isConversationActive) {
      navigate(conversations[0].id, { replace: true })
    }
  }, [conversations, isConversationActive, navigate, isMobile])

  if (isMobile && isConversationActive) {
    return null
  }

  const createConversation = async (participants: SearchConversationParticipantFragment[]) => {
    try {
      if (!login) throw new Error('no login')

      const recipients = Array.from(new Set([...participants.map(p => p.loginId), login.id]))

      const response = await saveConversationMutation({
        variables: { recipients },
      })

      if (response.data?.saveConversation) {
        navigate(response.data.saveConversation.id)
      } else {
        toast.urgent('Error initializing conversation')
      }
    } catch (e) {
      console.error(e)
      toast.urgent('Error initializing conversation')
    }
  }

  return (
    <Container {...props} style={{ overflow: 'auto' }}>
      {audience === 'provider' && (
        <ConversationCreateDropdown
          style={{ marginBottom: '0.75rem' }}
          onChange={participant => {
            createConversation([participant])
          }}
        />
      )}
      {conversations.map(conversation => {
        return (
          <ConversationMenuItem
            createConversation={createConversation}
            key={conversation.participantHash}
            conversation={conversation}
            canHideConversations={canHideConversations}
            onHideConversation={hideConversation}
          />
        )
      })}
    </Container>
  )
}

interface ConversationMenuItemProps {
  conversation: ConversationFragment
  createConversation: (participants: ConversationParticipantFragment[]) => Promise<void>
  canHideConversations: boolean
  onHideConversation: (conversationId: string) => void
}

function ConversationMenuItem({
  conversation,
  createConversation,
  canHideConversations,
  onHideConversation,
}: ConversationMenuItemProps) {
  const { login } = useAuthDataContext()
  const { hasUnread } = useConversationUnreadContext()
  const participants = conversation.participants?.filter(participant => participant.loginId !== login?.id) ?? []

  const isMobile = useMediaQuery({ query: `(${BelowTablet})` })

  const handleClick = () => {
    createConversation(participants)
  }

  const handleHideConversationClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation()
    event.preventDefault()
    onHideConversation(conversation.id)
  }

  const innerContent = participants.map(participant => (
    <Fragment key={participant.loginId}>
      <AvatarContainer>
        <Avatar
          className={csx({ 'fs-mask': participant.displayData?.role === Role.Patient })}
          size="xs"
          url={participant.displayData?.avatarUrl}
        />
        {hasUnread(conversation.id) && <UnreadBadgeStyled />}
      </AvatarContainer>
      <span>
        {participant.displayData?.firstName} {participant.displayData?.lastName}
      </span>
      {isMobile && <IconChevronThick size={16} className="ml-auto" color={borderGrey} direction="right" />}
    </Fragment>
  ))

  if (conversation.status === ConversationStatus.NotCreated) {
    return (
      <MenuItemStyled key={conversation.participantHash} button onClick={handleClick}>
        {innerContent}
      </MenuItemStyled>
    )
  } else {
    return (
      <MenuItemStyled key={conversation.participantHash} to={routeService.conversationRoute(conversation.id)}>
        {innerContent}
        {canHideConversations && (
          <IconButton
            small
            className="ml-auto hide-conversation-button"
            type="button"
            tooltip="Hide conversation"
            onClick={handleHideConversationClick}
          >
            <IconClose color={greySet[50].hex} size={14} />
          </IconButton>
        )}
      </MenuItemStyled>
    )
  }
}

const Container = styled('div')`
  width: 250px;

  @media (${BelowTablet}) {
    width: 100%;
  }
`

const AvatarContainer = styled('span')`
  position: relative;
  margin-right: 0.75rem;

  img {
    vertical-align: middle;
  }
`

const UnreadBadgeStyled = styled(UnreadBadge)`
  position: absolute;
  right: -7px;
  bottom: -2px;
  border: 2px solid #fff;
  width: 16px;
  height: 16px;

  a:hover & {
    border-color: ${greySet[15].hex};
  }

  a.active & {
    border-color: ${greySet[15].hex};
  }
`

const MenuItemStyled = styled(MenuItem)`
  padding-top: 0.625rem;
  padding-bottom: 0.625rem;
  margin-top: 0.5rem;

  &.active {
    background-color: ${greySet[15].hex};
  }

  .hide-conversation-button {
    visibility: hidden;
  }

  &:hover {
    .hide-conversation-button {
      visibility: visible;
    }
  }

  @media (${BelowTablet}) {
    border-bottom: 1px solid ${greySet[15].hex};
    border-radius: 0;
    margin-top: 0;
  }
`
