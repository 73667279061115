import { isNil, noop } from 'lodash'
import moment from 'moment-timezone'
import { ReactNode, createContext, useCallback, useContext, useMemo, useRef, useState } from 'react'

import { Patient, Provider } from '@nuna/api'

type TimeZoneUser =
  | Pick<Provider, 'firstName' | 'timezone' | '__typename'>
  | Pick<Patient, 'firstName' | 'timezone' | '__typename'>

type TimeZoneChangeEventHandler = (timeZone: string | undefined | null, oldTimeZone: string | undefined | null) => void

interface TimeZoneContextValues {
  customTimeZone?: string | null
  timeZoneToUse: string
  timeZoneAbbr: string
  usersInContext: TimeZoneUser[]
  addUserInContext: (user?: TimeZoneUser | null) => void
  removeUserInContext: (userType: 'Patient' | 'Provider') => void
  setCustomTimezone: (timezone: string | undefined | null) => void
  timeZoneChange: (type: 'on' | 'off', handler: TimeZoneChangeEventHandler) => void
}

const defaultContext: TimeZoneContextValues = {
  timeZoneToUse: moment.tz.guess(),
  timeZoneAbbr: moment.tz().zoneAbbr(),
  usersInContext: [],
  addUserInContext: noop,
  removeUserInContext: noop,
  setCustomTimezone: noop,
  timeZoneChange: noop,
}

const TimeZoneContext = createContext<TimeZoneContextValues>(defaultContext)

interface Props {
  children: ReactNode
}

export function TimezoneContextProvider({ children }: Props) {
  const [usersInContext, setUsersInContext] = useState<TimeZoneUser[]>([])
  const [customTimeZone, setCustom] = useState<string | undefined | null>()
  const eventHandlers = useRef<TimeZoneChangeEventHandler[]>([])

  const browserTimeZone = useMemo(() => moment.tz.guess(), [])
  const timeZoneToUse = useMemo(
    () => (customTimeZone ? customTimeZone : browserTimeZone),
    [browserTimeZone, customTimeZone],
  )

  const timeZoneAbbr = useMemo(() => moment.tz(moment(), timeZoneToUse).zoneAbbr(), [timeZoneToUse])

  const setCustomTimezone = useCallback(
    (timezone: string | undefined | null) => {
      setCustom(old => {
        if (old === timezone) return old
        eventHandlers.current.forEach(handler => handler(timezone ?? browserTimeZone, old ?? browserTimeZone))
        if (!isNil(timezone)) {
          moment.tz.setDefault(timezone)
        } else {
          moment.tz.setDefault(browserTimeZone)
        }
        return timezone
      })
    },
    [browserTimeZone],
  )

  const timeZoneChange = useCallback((type: 'on' | 'off', handler: TimeZoneChangeEventHandler) => {
    if (type === 'on') {
      eventHandlers.current.push(handler)
    } else {
      eventHandlers.current = eventHandlers.current.filter(h => h !== handler)
    }
  }, [])

  const addUserInContext = useCallback((user?: TimeZoneUser | null) => {
    if (!user?.timezone) return
    setUsersInContext(prev => {
      const index = prev.findIndex(u => u.__typename === user.__typename)
      if (index === -1) {
        return [...prev, user]
      }
      prev[index] = user
      return [...prev]
    })
  }, [])

  const removeUserInContext = useCallback((userType: 'Patient' | 'Provider') => {
    setUsersInContext(prev => prev.filter(u => u.__typename === userType))
  }, [])

  const values: TimeZoneContextValues = {
    timeZoneToUse,
    customTimeZone,
    timeZoneAbbr,
    usersInContext,
    addUserInContext,
    removeUserInContext,
    setCustomTimezone,
    timeZoneChange,
  }
  return <TimeZoneContext.Provider value={values}>{children}</TimeZoneContext.Provider>
}

export function useTimeZoneContext() {
  return useContext(TimeZoneContext)
}
