import { Audience } from '../types/exported'
import { ProfileUser } from '../types/exported'
import { DataCollectionStep } from '../types/internal-only/credentialing.types'

export enum PatientSubRoute {
  profile = 'profile',
  sessions = 'sessions',
  assessments = 'assessments',
  account = 'account',
  admin = 'admin',
}

function userProfile(user?: ProfileUser | null) {
  if (!user) {
    return ''
  }

  if (user.__typename === 'Provider') {
    return `/providers/${user.id}/profile`
  } else if (user.__typename === 'Patient') {
    return `/clients/${user.id}/profile`
  }

  return ''
}

const providerMeProfile = '/me/profile'

function videoSession(appointmentId?: string) {
  const base = '/live-session'

  if (appointmentId) {
    return `${base}/${appointmentId}`
  }

  return base
}

function noCurrentSessionRoute() {
  return '/no-current-session'
}

function providerDoxyRoute() {
  return 'https://doxy.me/account/dashboard'
}

/** TODO: these session detail/notes routes could probably be consolidated. When moving them out of core-deprecated I did not want to have to track them all down and test them */
function sessionNote(sessionId: string, audience: Audience = 'provider') {
  if (audience === 'provider') {
    return `/sessions/${sessionId}`
  }

  return `/session-notes/${sessionId}`
}

function sessionDetailRoute(id?: string, appointmentId?: string) {
  if (id) {
    return `/sessions/${id}`
  } else if (appointmentId) {
    return `/sessions/new/${appointmentId}`
  } else {
    return `/sessions/new`
  }
}

function createSessionNote(appointmentId: string) {
  return `/sessions/new/${appointmentId}`
}

function login() {
  return '/login'
}

function ssoLogin(slug: string) {
  return `${login()}/${slug}`
}

function switchProvider(oldProviderId?: string, newProviderId?: string) {
  const base = '/switch-provider'

  if (oldProviderId && newProviderId) {
    return `${base}/${oldProviderId}/select/${newProviderId}`
  } else if (oldProviderId && !newProviderId) {
    return `${base}/${oldProviderId}`
  }

  return base
}

function intake() {
  return `/intake`
}

function clientBilling() {
  return `/billing`
}

function scheduleDetail() {
  return `/schedule`
}

function appointmentDetailsDrawer(appointmentId: string, { existingSearchParams = new URLSearchParams() } = {}) {
  const newParams = new URLSearchParams(existingSearchParams)
  newParams.set('appointment', appointmentId)
  return `${scheduleDetail()}?${newParams.toString()}`
}

function providerDetail(id: string) {
  return `/providers/${id}`
}

function adminProviderPreferencesLocations(id: string) {
  return `/providers/${id}/preferences/locations`
}

function providerPreferencesLocations() {
  return `/me/preferences/locations`
}

function providerPreferencesScribe() {
  return `/me/preferences/tava-scribe`
}

function patientDetail(id: string, subRoute = PatientSubRoute.profile) {
  return `/clients/${id}/${subRoute}`
}
const patientIndex = '/clients'

const publicProviderBase = '/providers'
const publicProviderPattern = `${publicProviderBase}/:providerSlug`
function publicProvider(providerSlug: string) {
  return `${publicProviderBase}/${providerSlug}`
}
/** These two p4 routes could likely be consolidated but when moving out of core-dep I didn't want to track each one down and test */
function publicProviderRoute(providerSlug: string, fullyQualified?: boolean) {
  return `${fullyQualified ? import.meta.env['VITE_ARROW_BASE_URL'] ?? '' : ''}/providers/${providerSlug}`
}

const providerFeePolicies = '/me/preferences/fee-policies'
const providerCustomRates = '/me/preferences/custom-rates'
const providerCaseload = '/me/preferences/caseload'

const harmonyPayRollSummary = '/me/payouts'

const providerInsurance = '/me/insurance'

function insurancePayer(payerId: string) {
  return `/payers/${payerId}`
}

function insurancePayerPlan(planId: string, payerId: string) {
  return `${insurancePayer(payerId)}/plans/${planId}`
}

function verifiableProviderProfile(verifiableId: string) {
  return `https://discovery.verifiable.com/provider/${verifiableId}`
}

function videoBio(inEditMode?: boolean) {
  return `/video-intro${inEditMode ? '?edit=true' : ''}`
}

const clientsInvite = '/clients/invite'

const signup = '/signup'

const signupLicenses = '/signup/licenses'

const signupLocation = '/signup/location'

const clientProfile = '/profile'

/** CONVERSATIONS */
const conversationsBaseRoute = '/messages'
const conversationsDetailPathParam = '/:conversationId'
const conversationsDetailFullPath = `${conversationsBaseRoute}${conversationsDetailPathParam}`

const conversationsParticipantBaseRoute = '/conversation-participant'
const conversationsParticipantPathParam = '/:participantId'
const conversationsParticipantFullPath = `${conversationsParticipantBaseRoute}${conversationsParticipantPathParam}`

function conversationRoute(conversationId?: string) {
  if (conversationId) {
    return `${conversationsBaseRoute}/${conversationId}`
  }

  return conversationsBaseRoute
}

function conversationParticipantRoute(participantId: string) {
  return `${conversationsParticipantBaseRoute}/${participantId}`
}

function customerDetail(companyId: string) {
  return `/customers/${companyId}/overview`
}

const caqhWelcome = '/welcome'

function caqhSetup(subroute?: string) {
  return `/credentialing-setup${subroute ? `/${subroute}` : ''}`
}

function caqhCredentials() {
  return caqhSetup(caqhSteps[DataCollectionStep.CaqhCredentials])
}

function caqhSubroute(route: string) {
  return route.split('/')[2]
}

const backgroundCheckRoute = '/background-check'

function signupIntake(subRoute?: string) {
  return `/signup-intake${subRoute ? `/${subRoute}` : ''}`
}

const caqhSteps = {
  [DataCollectionStep.Started]: 'intro',
  [DataCollectionStep.CaqhCredentials]: 'caqh-sync',
  [DataCollectionStep.ExistingInsurance]: 'existing-insurance',
  // [DataCollectionStep.ProfessionalLiabilityInsurance]: 'professional-liability-insurance',
  [DataCollectionStep.Resume]: 'resume',
  [DataCollectionStep.Caqh]: 'caqh-profile',
  [DataCollectionStep.AttestationSignature]: 'attestation',
  [DataCollectionStep.Completed]: 'completed',
  // Placeholder for now to make typescript happy
  [DataCollectionStep.ProfessionalLiabilityInsurance]: 'completed',
}

const caqhRouteToStep: Record<string, DataCollectionStep> = {
  intro: DataCollectionStep.Started,
  'caqh-sync': DataCollectionStep.CaqhCredentials,
  'existing-insurance': DataCollectionStep.ExistingInsurance,
  resume: DataCollectionStep.Resume,
  'caqh-profile': DataCollectionStep.Caqh,
  attestation: DataCollectionStep.AttestationSignature,
  completed: DataCollectionStep.Completed,
}

const assessments = '/assessments' as const
function assessmentBundle(assessmentBundleId: string) {
  return `${assessments}/${assessmentBundleId}` as const
}
function assessment(assessmentBundleId: string, assessmentId: string) {
  return `${assessmentBundle(assessmentBundleId)}/${assessmentId}` as const
}
function therapistAssessmentDashboardForClient(clientId: string) {
  return `/clients/${clientId}/assessments` as const
}

const standaloneAvailability = '/availability/add'

const intakeWelcome = 'welcome'
const intakeProfessional = 'professional'
const intakeProfile = 'profile'
const intakeBios = 'bios'
const intakeAbilities = 'abilities'
const intakeAvailability = 'availability'
const intakeCancelPolicy = 'cancel-policy'

export const routeService = {
  adminProviderPreferencesLocations,
  appointmentDetailsDrawer,
  assessment,
  assessments,
  assessmentBundle,
  backgroundCheckRoute,
  caqhSetup,
  caqhSubroute,
  caqhWelcome,
  caqhSteps,
  caqhRouteToStep,
  caqhCredentials,
  clientBilling,
  clientProfile,
  clientsInvite,
  conversationParticipantRoute,
  conversationRoute,
  conversationsBaseRoute,
  conversationsDetailFullPath,
  conversationsDetailPathParam,
  conversationsParticipantBaseRoute,
  conversationsParticipantFullPath,
  conversationsParticipantPathParam,
  createSessionNote,
  customerDetail,
  harmonyPayRollSummary,
  insurancePayer,
  insurancePayerPlan,
  intakeWelcome,
  intakeProfessional,
  intakeProfile,
  intakeBios,
  intakeAbilities,
  intakeAvailability,
  intakeCancelPolicy,
  intake,
  login,
  noCurrentSessionRoute,
  patientDetail,
  patientIndex,
  providerCaseload,
  providerCustomRates,
  providerDetail,
  providerDoxyRoute,
  providerFeePolicies,
  providerInsurance,
  providerMeProfile,
  providerPreferencesLocations,
  providerPreferencesScribe,
  publicProviderBase,
  publicProvider,
  publicProviderPattern,
  publicProviderRoute,
  scheduleDetail,
  sessionDetailRoute,
  sessionNote,
  signup,
  signupIntake,
  signupLicenses,
  signupLocation,
  ssoLogin,
  standaloneAvailability,
  switchProvider,
  therapistAssessmentDashboardForClient,
  userProfile,
  verifiableProviderProfile,
  videoBio,
  videoSession,
}
