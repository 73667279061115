import { styled } from '@mui/material'
import { HTMLAttributes, ReactNode } from 'react'

import { borderGrey, greySet } from '@nuna/tunic'

interface Props extends HTMLAttributes<HTMLDivElement> {
  centered?: boolean
  footer?: ReactNode
  maxWidth?: string
}

export function IntakeStepContainer({ children, footer = null, centered = false, maxWidth = '932px' }: Props) {
  return (
    <Wrapper>
      <IntakeStepContainerStyled centered={centered} $maxWidth={maxWidth}>
        {children}
      </IntakeStepContainerStyled>
      {!!footer && (
        <Footer>
          <div className="footer-inner-wrapper">{footer}</div>
        </Footer>
      )}
    </Wrapper>
  )
}

const Wrapper = styled('div')`
  display: flex;
  flex-direction: column;
  flex: 1;
`

const IntakeStepContainerStyled = styled('div')<{ centered: boolean; $maxWidth: string }>`
  max-width: ${({ $maxWidth }) => $maxWidth};
  margin-left: auto;
  margin-right: auto;
  padding-left: var(--margin-3);
  padding-right: var(--margin-3);
  flex: 1;
  width: 100%;

  ${({ centered }) =>
    centered
      ? `
  display: flex;
  height: calc(100% - 78px);
  align-items: center;`
      : `
  padding-top: var(--margin-4);
  padding-bottom: var(--margin-4);
      `}
`

const Footer = styled('div')`
  background-color: ${greySet[5].hex};
  border-top: 1px solid ${borderGrey};
  .footer-inner-wrapper {
    max-width: 932px;
    margin-left: auto;
    margin-right: auto;
    padding-left: var(--margin-3);
    padding-right: var(--margin-3);
  }
`
