import { IconProps } from './IconProps'

export function IconExternalLink({ size = 24, color = 'currentColor', ...props }: IconProps) {
  return (
    <svg width={size} height={size} viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.81487 2.455H6.79509C7.04262 2.455 7.24329 2.65495 7.24329 2.90416V3.94243C7.24329 4.1905 7.04138 4.39159 6.79181 4.39159H4.09879V13.7014H14.216V11.3395C14.216 11.1973 14.332 11.0819 14.4823 11.0819H15.5622C15.7093 11.0819 15.8285 11.2011 15.8285 11.3395V15.0202C15.8285 15.1643 15.7132 15.2811 15.5739 15.2811H2.74145C2.60084 15.2811 2.48685 15.1675 2.48685 15.0202C2.48685 15.0202 2.48633 14.4118 2.48633 14.4065V2.71413C2.48633 2.57185 2.60229 2.45651 2.75259 2.45651H3.83253C3.84871 2.45651 3.86455 2.45796 3.87994 2.46071C3.8599 2.45699 3.83823 2.455 3.81487 2.455ZM3.97569 2.49783C3.95666 2.48552 3.93503 2.47539 3.9106 2.46808C3.9338 2.47514 3.95566 2.48523 3.97569 2.49783ZM13.5218 4.02698L11.9716 2.43551C11.8048 2.26428 11.8049 1.98582 11.9702 1.81511L12.5483 1.21838C12.7144 1.04697 12.9846 1.04726 13.1506 1.21766L16.7591 5.04759C16.8838 5.17569 16.8835 5.38373 16.7591 5.51148L13.1506 9.11416C12.984 9.28518 12.7137 9.28416 12.5483 9.11345L11.9702 8.51673C11.8042 8.34532 11.805 8.06728 11.9715 7.89635L13.5349 6.29127C13.6187 6.20531 13.5892 6.13562 13.4699 6.13562L12.2535 6.13562L11.2741 6.12824C9.83153 6.12824 8.66213 7.28445 8.66213 8.7107V11.0893C8.66213 11.326 8.46748 11.5197 8.22738 11.5197H7.13794C6.89817 11.5197 6.70318 11.327 6.70318 11.0893V8.7107C6.70318 6.21476 8.74963 4.1914 11.2741 4.1914L12.2535 4.17832L13.4538 4.18151C13.5745 4.18151 13.6046 4.11193 13.5218 4.02698Z"
        fill={color}
      />
    </svg>
  )
}
