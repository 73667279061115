import mixpanel from 'mixpanel-browser'
import { useEffect } from 'react'
import { useLocation } from 'react-router-dom'

const EVENT_NAME = 'page view'

export function PageViewTracker() {
  const location = useLocation()

  useEffect(() => {
    mixpanel.track(EVENT_NAME, {
      path: location.pathname,
    })
  }, [location])

  return null
}
