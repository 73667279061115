import { styled } from '@mui/material'
import { ChangeEvent, ComponentProps, useState } from 'react'

import { IconPreview, IconPreviewOff } from '../../icons'
import { greySet } from '../../styles/colorSets'
import { type InputStylesProps, RawInputProps, inputStyles } from '../BaseInput/BaseInput'

type Props = Omit<ComponentProps<typeof Input>, 'type'> & RawInputProps

export function SsnField({ inputProps, ...props }: Props) {
  const [isMasked, setIsMasked] = useState(true)

  const Icon = isMasked ? IconPreview : IconPreviewOff

  const handleInput = (event: ChangeEvent<HTMLInputElement>) => {
    event.currentTarget.value = formatSsn(event.currentTarget.value)
    return props.onChange?.(event)
  }

  return (
    <Wrapper>
      <Input
        {...props}
        {...inputProps}
        onChange={(e: ChangeEvent<HTMLInputElement>) => handleInput(e)}
        type={isMasked ? 'password' : 'text'}
      />
      <IconWrapper>
        <Icon onClick={() => setIsMasked(!isMasked)} />
      </IconWrapper>
    </Wrapper>
  )
}

// reference: https://tomduffytech.com/how-to-format-ssn-with-react/
const formatSsn = (value: string) => {
  // if input value is falsy eg if the user deletes the input, then just return
  if (!value) return value

  // clean the input for any non-digit values.
  const ssn = value.replace(/[^\d]/g, '')

  // ssnLength is used to know when to apply our formatting for the ssn
  const ssnLength = ssn.length

  // we need to return the value with no formatting if its less then four digits
  if (ssnLength < 4) return ssn

  // if ssnLength is greater than 4 and less the 6 we start to return
  // the formatted number
  if (ssnLength < 6) {
    return `${ssn.slice(0, 3)}-${ssn.slice(3)}`
  }

  // finally, if the ssnLength is greater then 6, we add the last
  // bit of formatting and return it.
  return `${ssn.slice(0, 3)}-${ssn.slice(3, 5)}-${ssn.slice(5, 9)}`
}

export const ssnRegExp = /^[0-9]{3}-[0-9]{2}-[0-9]{4}$/

const Wrapper = styled('span')`
  display: flex;
  border-bottom: 1px solid ${greySet[50].hex};
`

const Input = styled('input')<InputStylesProps>`
  ${inputStyles}
  border-bottom: none;
  flex: 1;
  width: inherit;
`

const IconWrapper = styled('span')`
  align-self: flex-end;
  cursor: pointer;
  color: ${greySet[50].hex};
`
