/* eslint-disable react/jsx-no-useless-fragment */
import { compact, debounce, first, isNil, isObject } from 'lodash'
import moment from 'moment'
import { useCallback, useEffect, useMemo } from 'react'
import { NavLink, useNavigate } from 'react-router-dom'

import {
  ClientListItemFragment,
  FinancialAttribution,
  OrderBy,
  SearchPatientFilterInput,
  TraversablePaginationSortInput,
  useSearchClientsLazyQuery,
} from '@nuna/api'
import { useIsAdmin } from '@nuna/auth'
import { UserLink } from '@nuna/common'
import { routeService } from '@nuna/core'
import { DataTable, DataTableColumn, DataTableProps, useDataTableFiltering, usePagination } from '@nuna/data-table'
import { StatusLabel } from '@nuna/tunic'

import { isMinor } from '../util/age'
import { ClientRemoveDialog } from './ClientRemoveDialog'

export type ClientTableColumn =
  | 'name'
  | 'eap company'
  | 'last appt'
  | 'next appt'
  | 'insurer'
  | 'joined'
  | 'state'
  | 'provider'
  | 'age'
  | 'message'
  | 'source'
  | 'remove'
  | 'referral'
  | 'phone'

export interface ClientTableProps extends Omit<DataTableProps<ClientListItemFragment>, 'columns' | 'loading'> {
  providerId?: string
  searchText?: string
  columns: (ClientTableColumn | DataTableColumn<ClientListItemFragment>)[]
  onNavigate: (row: ClientListItemFragment) => string
}

type ClientFilterValues = Omit<SearchPatientFilterInput, 'search'> & {
  searchTerm?: string | null
}

const INITIAL_FILTER_VALUES: ClientFilterValues = {
  id: '',
  providerId: '',
  companyId: '',
  loginId: '',
  state: '',
  searchTerm: '',
}

const INITIAL_SORT: TraversablePaginationSortInput[] = [{ key: 'createdAt', direction: OrderBy.Desc }]

const SessionLink = ({
  appointmentId,
  sessionId,
  startDatetime,
  isAdmin,
}: {
  appointmentId: string
  startDatetime: string
  sessionId?: string | undefined
  isAdmin: boolean
}) => {
  // For admins, just use appointment id instead of sessionId
  let url: string
  if (isAdmin) {
    url = routeService.sessionDetailRoute(appointmentId, appointmentId)
  } else if (moment(startDatetime).isAfter()) {
    url = routeService.appointmentDetailsDrawer(appointmentId)
  } else {
    url = routeService.sessionDetailRoute(sessionId, appointmentId)
  }

  return (
    <NavLink
      to={url}
      // Prevent bubble-up theft - client rows have an onNavigate which tries to show client profiles
      // it works but causes an ugly redirect
      onClick={e => {
        e.stopPropagation()
      }}
    >
      {moment(startDatetime).format('ll')}
    </NavLink>
  )
}

export function ClientTable({ searchText = '', providerId, onNavigate, columns, ...props }: ClientTableProps) {
  const isAdmin = useIsAdmin()
  const navigate = useNavigate()

  const { filterValues, setFilterVal } = useDataTableFiltering({ ...INITIAL_FILTER_VALUES, ...{ providerId } })
  const [queryClients, { data: clientsData, loading: clientsDataLoading }] = useSearchClientsLazyQuery()

  const { handleSort, queryOptions, getPaginatorProps, initialTableState } = usePagination({
    pagination: clientsData?.searchPatients.pagination,
    loading: clientsDataLoading,
    initialSort: INITIAL_SORT,
    filters: filterValues,
  })

  const search = useMemo(
    () => debounce((searchText?: string | null) => setFilterVal('searchTerm', searchText), 400),
    [setFilterVal],
  )

  useEffect(() => {
    search(searchText)
  }, [search, searchText])

  useEffect(() => {
    const { filters, sortInput, paginationInput } = queryOptions
    queryClients({
      variables: {
        order: sortInput,
        filters: buildFilterValues(filters),
        pagination: paginationInput,
      },
      fetchPolicy: 'cache-first',
    })
  }, [queryClients, queryOptions])

  const refetch = useCallback(() => {
    const { filters, sortInput, paginationInput } = queryOptions
    queryClients({
      variables: {
        order: sortInput,
        filters: buildFilterValues(filters),
        pagination: paginationInput,
      },
    })
  }, [queryClients, queryOptions])

  const rowData: ClientListItemFragment[] | undefined = useMemo(() => clientsData?.searchPatients.items, [clientsData])

  const tableColumns = useMemo<DataTableColumn<ClientListItemFragment>[]>(
    () => compact(columns.map(column => buildColumn(column, isAdmin, refetch, providerId ?? ''))),
    [columns, isAdmin, refetch, providerId],
  )

  return (
    <DataTable
      columns={tableColumns}
      rowData={rowData}
      loading={clientsDataLoading}
      initialState={initialTableState}
      paginated
      paginatorProps={getPaginatorProps()}
      onSort={handleSort}
      onRowClick={row => navigate(onNavigate(row))}
      getUniqueId={row => row.id}
      {...props}
    />
  )
}

function buildFilterValues(values: ClientFilterValues = INITIAL_FILTER_VALUES): SearchPatientFilterInput {
  const { companyId, id, loginId, providerId, searchTerm, state } = values
  return {
    search: {
      term: searchTerm,
      threshold: 0.2,
    },
    companyId,
    id,
    loginId,
    providerId,
    state,
  }
}

function buildColumn(
  columnType: ClientTableColumn | DataTableColumn<ClientListItemFragment>,
  isAdmin: boolean,
  refetch: () => void,
  providerId: string,
): DataTableColumn<ClientListItemFragment> | null {
  if (isObject(columnType)) {
    return columnType
  }
  switch (columnType) {
    case 'name':
      return {
        Header: 'Name',
        accessor: 'lastName',
        Cell: ({ row }) => {
          return (
            <div style={{ display: 'flex', alignItems: 'center' }} className="fs-exlude">
              <UserLink user={row.original} />
              {isMinor(row.original.dob) && (
                <StatusLabel color={'gray'} className="ml-1">
                  Minor
                </StatusLabel>
              )}
              {!row.original.login.active && (
                <StatusLabel color={'gray'} className="ml-1">
                  De-activated
                </StatusLabel>
              )}
            </div>
          )
        },
        paginatedSortable: true,
      }
    case 'eap company':
      return {
        Header: isAdmin ? 'EAP Contract' : 'Company',
        accessor: 'company',
        Cell: ({ value }) => <>{value?.name ?? 'Unknown'}</>,
      }
    case 'last appt':
      return {
        Header: 'Last Appointment',
        accessor: 'lastAppointment',
        Cell: ({ row }) => {
          return (
            <div style={{ display: 'flex', alignItems: 'center' }} className="fs-exlude">
              {row.original.lastAppointment ? (
                <SessionLink
                  appointmentId={row.original.lastAppointment.id}
                  sessionId={row.original.lastAppointment.session?.id}
                  startDatetime={row.original.lastAppointment.startDatetime}
                  isAdmin={isAdmin}
                />
              ) : (
                'None'
              )}
            </div>
          )
        },
        paginatedSortable: false,
      }
    case 'next appt':
      return {
        Header: 'Next Appointment',
        accessor: 'nextAppointment',
        Cell: ({ row }) => {
          return (
            <div style={{ display: 'flex', alignItems: 'center' }} className="fs-exlude">
              {row.original.nextAppointment ? (
                <SessionLink
                  appointmentId={row.original.nextAppointment.id}
                  sessionId={row.original.nextAppointment.session?.id}
                  startDatetime={row.original.nextAppointment.startDatetime}
                  isAdmin={isAdmin}
                />
              ) : (
                'None'
              )}
            </div>
          )
        },
        paginatedSortable: false,
      }
    case 'referral':
      return {
        Header: 'Referral',
        accessor: 'financialAttribution',
        Cell: ({ value }) => <>{financialAttributionMap(value)}</>,
      }
    case 'insurer':
      return {
        Header: 'Insurer',
        accessor: 'currentInsurancePolicy',
        Cell: ({ value }) => <>{value?.insurancePayer ? value.insurancePayer.name : 'None'}</>,
      }
    case 'source':
      return {
        Header: 'Source',
        accessor: 'source',
        Cell: ({ value }) => <>{value ?? 'Unknown'}</>,
      }
    case 'joined':
      return {
        Header: 'Joined',
        accessor: 'createdAt',
        Cell: ({ value }) => <>{moment(value).format('ll')}</>,
        paginatedSortable: true,
      }
    case 'state':
      return {
        Header: 'State',
        accessor: 'state',
      }
    case 'provider':
      return {
        Header: 'Therapist',
        accessor: 'providers',
        Cell: ({ value }) => {
          const provider = first(value.filter(provider => provider.type === 'THERAPIST'))
          const providerName = provider ? `${provider.firstName} ${provider.lastName}` : ''
          return <>{providerName}</>
        },
      }
    case 'age':
      return {
        Header: 'Age',
        accessor: 'dob',
        Cell: ({ row: { original } }) => (
          <>
            {moment().diff(moment(original.dob), 'years')} {moment().diff(original.dob, 'years') < 18 ? 'M' : ''}
          </>
        ),
      }
    case 'remove':
      return {
        accessor: 'id',
        Cell: ({ row }) => {
          return <ClientRemoveDialog patient={row.original} providerId={providerId} onRemove={refetch} />
        },
      }
    case 'phone': {
      return { accessor: 'mobilePhone', Header: 'Mobile Phone' }
    }
  }

  return null
}

function financialAttributionMap(attribution?: FinancialAttribution | null) {
  if (isNil(attribution)) {
    return ''
  }

  switch (attribution) {
    case FinancialAttribution.Provider:
      return 'Myself'
    case FinancialAttribution.TavaHealth:
      return 'Tava'
  }
}
