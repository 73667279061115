import { ProgressBar } from '@nuna/tunic'

import { CapInfoForDisplay, capInfoUtils } from '../utils'

interface CapProgressBarProps extends React.HTMLAttributes<HTMLDivElement> {
  capInfo: CapInfoForDisplay
  hasPaymentMethod: boolean
  width?: number | string
}

export function CapProgressBar({ capInfo, hasPaymentMethod, width = 107, ...props }: CapProgressBarProps) {
  const { progressBars } = capInfoUtils.generateCapInfoDisplayData(capInfo, hasPaymentMethod)

  return (
    <div style={{ width }} {...props}>
      <ProgressBar bars={progressBars} />
    </div>
  )
}
