import { IconProps } from './IconProps'

export function IconFileAudio({ size = 24, color = 'currentColor', ...props }: IconProps) {
  return (
    <svg width={size} height={size} viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.31027 5.72027C6.31027 4.93815 6.95752 4.29613 7.74041 4.29613H16.041C16.4439 4.29613 17.1394 4.58832 17.4083 4.86498L24.1206 11.7706C24.4079 12.0662 24.6897 12.7568 24.6897 13.1669V26.2761C24.6897 27.065 24.0498 27.7039 23.2663 27.7039H7.73367C6.95025 27.7039 6.31027 27.0667 6.31027 26.2893V23.1994V9.70329V5.72027ZM4 23.1994V26.2893C4 28.334 5.67344 30 7.73367 30H23.2663C25.3281 30 27 28.3308 27 26.2761V13.1669C27 12.1605 26.4849 10.8982 25.7821 10.1752L19.0699 3.26964C18.3648 2.54426 17.0692 2 16.041 2H7.74041C5.68261 2 4 3.66902 4 5.72027V9.70329V23.1994ZM19.3697 13.7039C19.3697 13.2812 19.0249 12.9385 18.5996 12.9385C18.1743 12.9385 17.8295 13.2812 17.8295 13.7039V24.3598C17.8295 24.7825 18.1743 25.1252 18.5996 25.1252C19.0249 25.1252 19.3697 24.7825 19.3697 24.3598V13.7039ZM16.0326 16.0274C16.0326 15.6047 15.6879 15.262 15.2626 15.262C14.8372 15.262 14.4925 15.6047 14.4925 16.0274V22.0273C14.4925 22.45 14.8372 22.7927 15.2626 22.7927C15.6879 22.7927 16.0326 22.45 16.0326 22.0273V16.0274ZM9.35854 17.0523C9.35854 16.6296 9.01376 16.2869 8.58845 16.2869C8.16314 16.2869 7.81836 16.6296 7.81836 17.0523V21.0068C7.81836 21.4295 8.16314 21.7722 8.58845 21.7722C9.01376 21.7722 9.35854 21.4295 9.35854 21.0068V17.0523ZM12.6956 17.886C12.6956 17.4633 12.3508 17.1206 11.9255 17.1206C11.5002 17.1206 11.1554 17.4633 11.1554 17.886V20.1184C11.1554 20.5411 11.5002 20.8837 11.9255 20.8837C12.3508 20.8837 12.6956 20.5411 12.6956 20.1184V17.886ZM22.7068 16.3463C22.7068 15.9236 22.362 15.581 21.9367 15.581C21.5114 15.581 21.1666 15.9236 21.1666 16.3463V21.7084C21.1666 22.1311 21.5114 22.4738 21.9367 22.4738C22.362 22.4738 22.7068 22.1311 22.7068 21.7084V16.3463Z"
        fill={color}
      />
    </svg>
  )
}
