import { IconProps } from './IconProps'

interface IconDollarSymbolProps extends IconProps {
  textColor?: string
}

export function IconDollarSymbol({
  color = 'currentColor',
  size = 24,
  textColor = '#fff',
  ...props
}: IconDollarSymbolProps) {
  const height = (19 / 14) * size

  return (
    <svg width={size} height={height} viewBox="0 0 14 19" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M0.375 4.5C0.375 2.29086 2.16586 0.5 4.375 0.5H9.375C11.5841 0.5 13.375 2.29086 13.375 4.5V14.5C13.375 16.7091 11.5841 18.5 9.375 18.5H4.375C2.16586 18.5 0.375 16.7091 0.375 14.5V4.5Z"
        fill={color}
      />
      <path
        d="M4.375 11.8832L4.96058 10.9603C5.28341 11.1861 5.60998 11.3692 5.94031 11.5093C6.27065 11.6495 6.63101 11.7196 7.0214 11.7196C7.38176 11.7196 7.65203 11.6495 7.83221 11.5093C8.0199 11.3692 8.11374 11.1822 8.11374 10.9486C8.11374 10.7461 8.0274 10.5787 7.85473 10.4463C7.68957 10.3139 7.47935 10.1931 7.2241 10.0841C6.96884 9.96729 6.69482 9.85436 6.40203 9.74533C6.10923 9.6285 5.83521 9.48832 5.57995 9.32477C5.3247 9.16122 5.11074 8.96651 4.93806 8.74065C4.7729 8.50701 4.69032 8.21495 4.69032 7.86449C4.69032 7.35826 4.85548 6.94548 5.18581 6.62617C5.51614 6.30685 5.96284 6.10826 6.5259 6.03037V4.5H7.4268V6.01869C7.8247 6.06542 8.16254 6.17056 8.44032 6.33411C8.7256 6.49766 8.98461 6.70016 9.21734 6.94159L8.56419 7.7243C8.30143 7.52181 8.04993 7.36604 7.80969 7.25701C7.57695 7.14798 7.28791 7.09346 6.94257 7.09346C6.62725 7.09346 6.37951 7.15966 6.19932 7.29206C6.02665 7.42446 5.94031 7.60748 5.94031 7.84112C5.94031 8.02025 6.0229 8.16822 6.18806 8.28505C6.36074 8.40187 6.5747 8.5148 6.82996 8.62383C7.08521 8.72508 7.35923 8.83411 7.65203 8.95094C7.95233 9.05997 8.23011 9.19626 8.48536 9.35981C8.74062 9.52337 8.95083 9.72586 9.11599 9.96729C9.28866 10.2009 9.375 10.4969 9.375 10.8551C9.375 11.3536 9.20233 11.778 8.85698 12.1285C8.51914 12.4712 8.04242 12.6893 7.4268 12.7827V14.5H6.5259V12.8061C6.13551 12.7671 5.74887 12.6659 5.36599 12.5023C4.98311 12.331 4.65278 12.1246 4.375 11.8832Z"
        fill={textColor}
      />
    </svg>
  )
}
