import { styled } from '@mui/material'

import { greySet } from '../styles/colorSets'

const Container = styled('div')`
  padding: 1rem 2rem 5rem;
  overflow: auto;
`

const FlexContainer = styled('div')`
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow: hidden;
`

const Buttons = styled('div')`
  padding: 1rem;
`

const Divider = styled('span')`
  background-color: ${greySet[30].hex};
  display: inline-block;
  height: 24px;
  width: 1px;
`

export const SiderailComponents = {
  Container,
  FlexContainer,
  Buttons,
  Divider,
}
