import * as Yup from 'yup'
import { styled } from '@mui/material'
import { Form, Formik } from 'formik'
import { useMemo } from 'react'
import { useNavigate } from 'react-router-dom'

import { EmailVerificationField } from '@nuna/auth'
import { BelowTablet, FillButton, Grid } from '@nuna/tunic'

import { login } from '../../util/routes'
import verifyIllustrationSrc from './img/verify-illustration.png'
import { BaseSignupFormProps, PreValidateProps } from './shared'

interface VerifyFormValues {
  verifyEmail: string
  email: string
}

const validationSchema = Yup.object().shape({
  verifyEmail: Yup.string().email("Oops, that email isn't formatted correctly").required('Please retype your email'),
  email: Yup.string().oneOf([Yup.ref('verifyEmail'), null], `Your email doesn't match`),
})

interface Props extends BaseSignupFormProps, PreValidateProps {
  preValidate: (email: string) => Promise<boolean>
}

export function SignupVerifyEmail({
  formValues,
  step,
  onStepSubmit,
  dismissPreValidateError,
  preValidate,
  preValidateError,
}: Props) {
  const navigate = useNavigate()
  const initialValues = useMemo<VerifyFormValues>(() => ({ verifyEmail: '', email: formValues.email }), [formValues])

  return (
    <Formik
      validationSchema={validationSchema}
      initialValues={initialValues}
      onSubmit={verifyFormValues => onStepSubmit({ ...formValues, email: verifyFormValues.email }, step)}
    >
      {({ values, handleChange, errors, handleBlur, touched, setFieldValue }) => (
        <Form>
          <div className="mb-5">
            <Grid container spacing={4} direction="row-reverse" alignItems="center">
              <Grid
                size={{
                  xs: 12,
                  sm: 4,
                }}
              >
                <Illustration src={verifyIllustrationSrc} alt="" />
              </Grid>
              <Grid
                size={{
                  xs: 12,
                  sm: 8,
                }}
              >
                <h2 className="h3">Let's verify we got that right.</h2>
                <p className="text-secondary">Please retype your email address.</p>
              </Grid>
            </Grid>
          </div>

          <EmailVerificationField
            error={!!errors.verifyEmail}
            existingEmail={values.email}
            helperText={errors.verifyEmail ?? ''}
            isLoading={false}
            name="verifyEmail"
            onBlur={handleBlur}
            onChange={e => {
              dismissPreValidateError()
              handleChange(e)
            }}
            onLoginClick={() => navigate(login)}
            onUpdateEmailClick={async () => {
              if (await preValidate(values.verifyEmail)) {
                setFieldValue('email', values.verifyEmail)
              }
            }}
            touched={!!touched.verifyEmail}
            typedEmail={values.verifyEmail}
            showUpdateError={!!preValidateError}
          />
          <FillButton data-testid="signup-verify-email" className="mt-5" type="submit">
            Continue
          </FillButton>
        </Form>
      )}
    </Formik>
  )
}

const Illustration = styled('img')`
  max-width: 325px;
  width: 100%;

  @media (${BelowTablet}) {
    max-width: 192px;
    margin-bottom: var(--margin-2);
    display: block;
  }
`
