import { HTMLAttributes } from 'react'

import { CalendarSyncStatus, Provider, useManageCalendarMutation } from '@nuna/api'
import { FillButton, IconUser, StatusLabel, progressIndicator, toast } from '@nuna/tunic'

import {
  getCalendarStatusLabel,
  getCalendarStatusLabelIntent,
  getCalendarTypeByEmail,
} from '../../../../utils/calendarSync'
import { CalendarSectionTitle } from './CalendarSectionTitle'

interface Props extends HTMLAttributes<HTMLDivElement> {
  provider: Provider
  status: CalendarSyncStatus
  onRefresh: () => void
}

export function CalendarSyncHeader({ provider, status, onRefresh }: Props) {
  const [manageCalendar, { loading }] = useManageCalendarMutation({})

  const removeCalendar = async () => {
    try {
      await manageCalendar({
        variables: {
          id: provider.id,
          options: {
            removeCalendars: true,
          },
        },
      })

      onRefresh()

      toast.success(`Successfully removed the calendar`)
    } catch (error) {
      toast.urgent(`There was a problem removing the calendar`)
    }
  }

  return (
    <div className="flex-row  mb-4" style={{ gap: '1rem', flexWrap: 'wrap' }}>
      <div style={{ flex: 1 }}>
        <CalendarSectionTitle>
          <IconUser className="mr-1" size={18} />
          {getCalendarTypeByEmail(provider.personalEmail || '')}
        </CalendarSectionTitle>
        <p className="v-align text-secondary">
          {provider?.personalEmail || 'Personal email not found'}
          <StatusLabel className="ml-2" intent={getCalendarStatusLabelIntent(status)} scheme="light">
            {getCalendarStatusLabel(status)}
          </StatusLabel>
          {status === CalendarSyncStatus.SettingUp && (
            <img src={progressIndicator} className="ml-1 mr-xs flex-static" alt="" />
          )}
        </p>
      </div>
      <FillButton disabled={loading} variant="primary" onClick={removeCalendar}>
        Remove
      </FillButton>
    </div>
  )
}
