import { FormEvent } from 'react'

import { TherapyTypeCoverageItem, usePatientCoverageQuery } from '@nuna/api'
import { TherapyType } from '@nuna/core'
import { Select, SelectProps } from '@nuna/tunic'

export interface TherapyTypeSelectProps extends Omit<SelectProps, 'value' | 'onChange' | 'children' | 'defaultValue'> {
  patientId: string
  providerId: string
  startDate?: string | null
  value?: string | null
  showAllTypes?: boolean
  onChange: (therapyType: string | null) => void
  defaultTherapyTypes?: TherapyTypeCoverageItem[]
}

export function TherapyTypeSelect({
  value,
  onChange,
  providerId,
  patientId,
  startDate,
  showAllTypes = false,
  defaultTherapyTypes = [],
  ...props
}: TherapyTypeSelectProps) {
  const { data: coverage, loading } = usePatientCoverageQuery({
    variables: {
      patientId,
      date: startDate,
    },
  })

  const currentCoverage = coverage?.patientCoverage?.[0]
  const therapyTypes =
    currentCoverage?.details?.providers?.find(provider => provider.id === providerId)?.therapyTypeItems ??
    defaultTherapyTypes
  const initialValue = value ?? therapyTypes.find(it => it.enabled && it.name === TherapyType.INDIVIDUAL_THERAPY)?.id

  if (loading) return null

  return (
    <Select
      onChange={(e: FormEvent<HTMLSelectElement>) => onChange(e.currentTarget.value)}
      value={initialValue ?? ''}
      {...props}
    >
      {!initialValue && <option value=""></option>}
      {therapyTypes
        .filter(therapyType => therapyType.enabled || showAllTypes)
        .map(therapyType => (
          <option key={therapyType.id} value={therapyType.id}>
            {therapyType.name}
          </option>
        ))}
    </Select>
  )
}
