import { styled } from '@mui/material'
import { isNil, sortBy } from 'lodash'
import moment from 'moment'
import { ChangeEvent, HTMLAttributes, useMemo } from 'react'

import {
  CredentialFragment,
  ProviderEnrollmentFragment,
  UpdateProviderMutationVariables,
  useProviderCaseloadQuery,
  useProviderSlugQuery,
  useSaveLicensesMutation,
  useSaveProviderPreferencesMutation,
  useUpdateProviderEnrollmentMutation,
  useUpdateProviderMutation,
} from '@nuna/api'
import { useIsAdmin } from '@nuna/auth'
import { errorService, routeService } from '@nuna/core'
import { useFeatureFlags } from '@nuna/feature-flag'
import { ContextualAlert, DatePicker, Switch, TextButtonLink, makeTypographyComponent, toast } from '@nuna/tunic'

import { CollapsablePreferences } from './CollapsablePreferences'
import { DirectoriesTable } from './DirectoriesTable/DirectoriesTable'
import { TavaReferralsToggle } from './TavaReferralsToggle/TavaReferralsToggle'

interface Props extends HTMLAttributes<HTMLDivElement> {
  providerId?: string | null
}

const SecondaryHeader = makeTypographyComponent('text-medium large sans-serif', 'h3')
const ProviderCaption = makeTypographyComponent('text-secondary italic caption mt-2', 'div')

export function CaseloadManagement({ providerId }: Props) {
  const isAdmin = useIsAdmin()
  const { providerDirectories } = useFeatureFlags()
  const [updateProviderMutation] = useUpdateProviderMutation()
  const [updateLicensesMutation] = useSaveLicensesMutation()
  const [updateProviderEnrollment] = useUpdateProviderEnrollmentMutation()
  const [updateProviderPreferencesMutation] = useSaveProviderPreferencesMutation()

  const { data } = useProviderCaseloadQuery({ variables: { providerId: providerId ?? '' }, skip: isNil(providerId) })
  const { data: slugData } = useProviderSlugQuery({
    variables: { providerId: providerId ?? '' },
    skip: isNil(providerId),
  })

  const p4Url = useMemo(() => {
    return routeService.publicProviderRoute(slugData?.provider.slug ?? '', true)
  }, [slugData])

  const { expectedStartDate, acceptsReferrals, preferences } = data?.provider ?? {}

  const credentials = sortBy(data?.provider.credentials ?? [], cred => cred.id)
  const enrollments = sortBy(data?.providerEnrollments ?? [], enrollment => enrollment.id)
  const p4Disabled = preferences?.p4Disabled ?? false

  const showAvailableDate =
    !expectedStartDate || (expectedStartDate && moment(expectedStartDate) > moment().startOf('day'))

  const updateProvider = async (variables: Omit<UpdateProviderMutationVariables, 'id'>) => {
    try {
      await updateProviderMutation({ variables: { id: providerId ?? '', ...variables } })
    } catch (e) {
      toast.urgent(errorService.transformGraphQlError(e, 'There was an issue updating preferences.'))
    }
  }

  const updateProviderPreference = async (p4Disabled: boolean) => {
    try {
      await updateProviderPreferencesMutation({
        variables: { providerId: providerId ?? '', preferences: { p4Disabled } },
      })
    } catch (e) {
      toast.urgent(
        errorService.transformGraphQlError(e, 'There was an issue toggling public provider website visibility.'),
      )
    }
  }

  const toggleActiveLicense = async (credential: CredentialFragment, active: boolean) => {
    try {
      await updateLicensesMutation({
        variables: { licenses: [{ id: credential.id, currentlyUtilized: active, providerId: credential.providerId }] },
        optimisticResponse: { saveLicenses: [{ ...credential, currentlyUtilized: active }] },
      })
    } catch (e) {
      toast.urgent(
        errorService.transformGraphQlError(e, `There was an issue ${active ? 'activating' : 'deactivating'} license`),
      )
    }
  }

  const toggleActiveEnrollment = async (enrollment: ProviderEnrollmentFragment, active: boolean) => {
    try {
      await updateProviderEnrollment({
        variables: { enrollment: { id: enrollment.id, active } },
        optimisticResponse: {
          updateProviderEnrollment: {
            ...enrollment,
            active,
          },
        },
      })
    } catch (e) {
      toast.urgent(
        errorService.transformGraphQlError(
          e,
          `There was an issue ${active ? 'activating' : 'deactivating'} enrollment`,
        ),
      )
    }
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleDateChange = (date: any) => {
    updateProvider({ expectedStartDate: moment.utc(date).startOf('day').toISOString() })
  }

  // const handleExpectedCaseloadChange = (e: ChangeEvent<HTMLInputElement>) => {
  //   const value = Number(e.target.value)
  //   updateProvider({ expectedCaseloadAmount: value })
  // }

  const handleToggleP4Disabled = async (e: ChangeEvent<HTMLInputElement>) => {
    const disabled = !e.currentTarget.checked
    await updateProviderPreference(disabled)
  }

  const handleToggleLicense = (credential: CredentialFragment, e: ChangeEvent<HTMLInputElement>) => {
    toggleActiveLicense(credential, e.currentTarget.checked)
  }

  const handleToggleEnrollment = (enrollment: ProviderEnrollmentFragment, e: ChangeEvent<HTMLInputElement>) => {
    toggleActiveEnrollment(enrollment, e.currentTarget.checked)
  }

  return (
    <div className="pr-2">
      {showAvailableDate && (
        <div className="mb-4">
          <SecondaryHeader>Soonest Available</SecondaryHeader>
          <div>
            <DatePicker
              containerProps={{ className: 'v-align mb-3', style: { maxWidth: 400 } }}
              disablePast
              format="MMM Do, YYYY"
              label=""
              onChange={handleDateChange}
              value={expectedStartDate ? moment.utc(expectedStartDate) : null}
              minDate={moment().add(1, 'day')}
            />
            <p className="text-secondary mt-2">
              This is the first date clients will be able to start scheduling with you.
            </p>
          </div>
        </div>
      )}
      {/* <SecondaryHeader className="mt-4">Max Caseload</SecondaryHeader>
      <NumberField min={1} onChange={handleExpectedCaseloadChange} value={expectedCaseloadAmount ?? 0} />
      <p className="text-secondary mt-1">
        Your total caseload includes <b>your own clients</b> and any <b>Tava referrals</b>. Note that clients have
        varied preferences for session cadence (weekly, bi-weekly, and monthly), so your hours spent in a session may
        vary from week to week.
      </p> */}

      <ToggleContainer>
        <span className="large text-medium">Practice site set to {p4Disabled && 'NOT'} ACCEPT new clients</span>
        <div className="text-secondary mt-1 mb-2 caption v-align" style={{ maxWidth: '450px' }}>
          <Switch checked={!p4Disabled} onChange={handleToggleP4Disabled} />
          <p className="p-0 m-0">
            {!p4Disabled ? (
              <>
                <TextButtonLink variant="secondary" to={p4Url}>
                  Your practice website{' '}
                </TextButtonLink>{' '}
                is now set to accept new clients.
              </>
            ) : (
              <>
                Your practice site is live, but you will no longer receive new client signups from your{' '}
                <TextButtonLink variant="secondary" to={p4Url}>
                  practice website
                </TextButtonLink>
                .
              </>
            )}
          </p>
        </div>
      </ToggleContainer>
      <ToggleContainer>
        <TavaReferralsToggle providerId={providerId} />
      </ToggleContainer>
      {providerDirectories && providerId && <DirectoriesTable providerId={providerId} />}
      {acceptsReferrals && (
        <CollapsablePreferences label="Customize per license" openByDefault={false} className="mt-4">
          {data?.provider && credentials.length === 0 && <ContextualAlert>No licenses set up</ContextualAlert>}
          {data?.provider && credentials.length && (
            <SwitchContainer>
              {credentials.map(credential => (
                <Switch
                  key={credential.id}
                  checked={credential.currentlyUtilized}
                  disabled={!isAdmin}
                  className="mr-5 mt-2"
                  onChange={e => handleToggleLicense(credential, e)}
                >
                  {credential.state}-{credential.credential}
                </Switch>
              ))}
            </SwitchContainer>
          )}
          {!isAdmin && <ProviderCaption>License customization is managed by our admin team</ProviderCaption>}
        </CollapsablePreferences>
      )}
      {acceptsReferrals && (
        <CollapsablePreferences borderBottom label="Customize per insurance enrollment" openByDefault={false}>
          {data?.providerEnrollments && enrollments.length === 0 && (
            <ContextualAlert>No insurance enrollments set up</ContextualAlert>
          )}
          {data?.providerEnrollments && enrollments.length > 0 && (
            <SwitchContainer>
              {enrollments.map(enrollment => (
                <Switch
                  key={enrollment.id}
                  className="mt-2 mr-5"
                  checked={enrollment.active}
                  disabled={!isAdmin}
                  onChange={e => handleToggleEnrollment(enrollment, e)}
                >
                  {enrollment.insurancePayer.name} - {enrollment.insurancePayer.name}
                </Switch>
              ))}
            </SwitchContainer>
          )}

          {!isAdmin && (
            <ProviderCaption>Insurance enrollment customization is managed by our admin team</ProviderCaption>
          )}
        </CollapsablePreferences>
      )}
    </div>
  )
}

const SwitchContainer = styled('div')`
  display: flex;
  flex-wrap: wrap;
`

const ToggleContainer = styled('div')`
  margin-bottom: 64px;
`
