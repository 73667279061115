import { styled } from '@mui/material'
import { HTMLAttributes, ReactNode, useEffect, useRef } from 'react'
import { useLocation } from 'react-router-dom'

import { greySet } from '@nuna/tunic'

import { IntakeSupportChat } from '../../shared/IntakeSupportChat'

interface Props extends HTMLAttributes<HTMLDivElement> {
  header: ReactNode
}

export function IntakeLayout({ children, header }: Props) {
  const scrollContainer = useRef<HTMLDivElement>(null)
  const location = useLocation()

  useEffect(() => {
    scrollContainer.current?.scrollTo({ top: 0 })
  }, [location.pathname])

  return (
    <IntakeLayoutStyled ref={scrollContainer} className="flex-column">
      {header}
      <main className="main flex-column">
        {children}
        <IntakeSupportChat className="chat-button" />
      </main>
    </IntakeLayoutStyled>
  )
}

const IntakeLayoutStyled = styled('div')`
  width: 100%;
  height: 100%;
  background-color: ${greySet[0].hex};
  overflow-y: auto;
  .main {
    flex: 1;
    margin-top: 78px;
  }

  .chat-button {
    position: fixed;
    bottom: 30px;
    right: 30px;
    z-index: 2;
  }
`
