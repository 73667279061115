import { Autocomplete, AutocompleteProps, TextField } from '@mui/material'
import FuzzySearch from 'fuzzy-search'
import { includes, isNil, sortBy } from 'lodash'
import { ReactNode, useMemo, useState } from 'react'

import { LicenseTypeFragment, useLicenseTypesQuery } from '@nuna/api'

type LimitedAutocompleteProps = Omit<
  AutocompleteProps<LicenseTypeFragment | undefined, false, boolean | undefined, false>,
  'onChange' | 'options' | 'renderInput' | 'value' | 'multiple'
>

interface Props extends LimitedAutocompleteProps {
  value?: string | null
  state?: string | null
  onChange: (license: LicenseTypeFragment | undefined | null) => void
  label?: string
  helperText?: string | ReactNode
  error?: boolean
  name?: string
}

export function LicenseAutcompleteSingle({
  value,
  state,
  onChange,
  label = 'State',
  error,
  name = 'selectedLicenseType',
  helperText,
  ...props
}: Props) {
  const { data, loading } = useLicenseTypesQuery()
  const [searchTerm, setSearchTerm] = useState('')

  const licenseTypes = useMemo<LicenseTypeFragment[]>(() => {
    const output = sortBy(data?.licenseTypes ?? [], 'abbreviation')

    if (isNil(state)) {
      return output
    }

    return output.filter(licenseType => includes(licenseType.supportedStates, state))
  }, [data, state])

  const filteredLicenseTypes = useMemo<LicenseTypeFragment[]>(() => {
    const searcher = new FuzzySearch(licenseTypes, ['name'], {
      caseSensitive: false,
    })
    if (!searchTerm) {
      return licenseTypes
    }
    return searcher.search(searchTerm)
  }, [searchTerm, licenseTypes])

  const valueAsOption = licenseTypes.find(licenseType => licenseType.id === value) ?? null

  return (
    <Autocomplete
      {...props}
      // PopperComponent={PopperMy}
      loading={loading}
      onChange={(_event, value) => {
        onChange(value)
      }}
      openOnFocus
      options={filteredLicenseTypes}
      value={valueAsOption}
      getOptionLabel={licenseType => licenseType?.abbreviation ?? ''}
      isOptionEqualToValue={(option, value) => option?.id === value?.id}
      ListboxProps={{ className: 'MuiAutocomplete-listbox' }}
      onInputChange={(_event, searchTerm) => setSearchTerm(searchTerm)}
      renderInput={params => (
        <TextField
          name={name}
          error={error}
          helperText={helperText}
          label={label}
          data-testid="license-input"
          {...params}
        />
      )}
    />
  )
}
