import * as Yup from 'yup'
import { Form, Formik } from 'formik'
import { isNil, isNumber, toString } from 'lodash'
import { HTMLAttributes, useEffect, useMemo, useState } from 'react'

import { useProviderPreferencesQuery, useSaveProviderPreferencesMutation } from '@nuna/api'
import { useIsAdmin } from '@nuna/auth'
import { errorService, formService, numberService, routeService } from '@nuna/core'
import { ContextualAlert, FillButton, GhostButton, Skeleton, TextButtonLink, toast } from '@nuna/tunic'

import { InlineInput } from '../../PolciesSetup/components/InlineInput'
import { CaptionLight, FieldLabel, FormCard, FormSection } from '../../shared/FormComponents'
import { ProviderTakeEstimate } from '../../shared/ProviderTakeEstimate'

const { centsToDollars, dollarsToCents, formatCurrency } = numberService
const { composeHelperTextWithError } = formService

interface Props extends HTMLAttributes<HTMLDivElement> {
  providerId?: string | null
}

interface FormValues {
  providerCashPayRateDollars: number | undefined
}

const validationSchema = Yup.object().shape<FormValues>({
  providerCashPayRateDollars: Yup.number()
    .required('You must set a rate to save')
    .typeError('You must set a rate to save')
    .min(0, 'Your rate cannot be negative')
    .max(350, 'Your rate cannot exceed $350')
    .test('is-valid-currency', 'Please enter a valid dollar and cents amount', value => {
      return !!toString(value).match(/^\d+(\.\d{2})?$|^\d+$/)
    }),
})

export function CashPayRateForm({ providerId, ...props }: Props) {
  const isAdmin = useIsAdmin()
  const [isEditing, setIsEditing] = useState<boolean>(true)
  const [savePreferences, { loading: saveLoading }] = useSaveProviderPreferencesMutation({
    // If the user changes their cash pay rate, we need to clear the cache for their compensation
    // rates so the updated rate will show on their payouts page if they navigate back there.
    update: cache => cache.evict({ fieldName: 'providerCompensationRates', args: { providerId } }),
  })
  const { data } = useProviderPreferencesQuery({
    variables: { providerId: providerId ?? '' },
    skip: isNil(providerId),
    fetchPolicy: 'cache-and-network',
  })
  const preferences = data?.provider.preferences
  const initialValues = useMemo<FormValues>(
    () => ({
      providerCashPayRateDollars: isNumber(preferences?.providerCashPayRateCents)
        ? centsToDollars(preferences?.providerCashPayRateCents ?? 0)
        : undefined,
    }),
    [preferences],
  )

  useEffect(() => {
    setIsEditing(isNil(initialValues.providerCashPayRateDollars))
  }, [initialValues])

  const showNotSetupAlert = preferences && isAdmin && isNil(initialValues.providerCashPayRateDollars)

  const handleSubmit = async (values: FormValues) => {
    try {
      await savePreferences({
        variables: {
          providerId: providerId ?? '',
          preferences: { providerCashPayRateCents: dollarsToCents(values.providerCashPayRateDollars ?? 0) },
        },
      })
      toast.success('Your rate has been saved.')
      setIsEditing(false)
    } catch (e) {
      toast.urgent(errorService.transformGraphQlError(e, 'There was a problem saving your rate.'))
    }
  }

  return (
    <>
      {!preferences && <Skeleton height={4} {...props} />}
      {showNotSetupAlert && <ContextualAlert>Provider has not set up a custom rate</ContextualAlert>}
      {preferences && !showNotSetupAlert && (
        <FormCard canEdit={!isAdmin} isEditing={isAdmin ? false : isEditing} onEdit={() => setIsEditing(true)}>
          {isEditing && (
            <Formik initialValues={initialValues} onSubmit={handleSubmit} validationSchema={validationSchema}>
              {({ values, errors, touched, getFieldProps }) => (
                <Form>
                  <FormSection className="pt-0">
                    <FieldLabel>My Rate</FieldLabel>
                    <CaptionLight className="mb-1">
                      Clients you bring to Tava that pay cash for their therapy are charged this rate for each session.
                      This rate will be visible on your Practice Website.
                    </CaptionLight>
                    <div className="v-align">
                      <InlineInput
                        {...getFieldProps('providerCashPayRateDollars')}
                        type="number"
                        step="0.01"
                        leftAdornment="$"
                        style={{ width: 98 }}
                        className="mr-1"
                        error={!!errors.providerCashPayRateDollars && touched.providerCashPayRateDollars}
                      />
                      <div className="text-secondary">will be charged per session for clients you bring</div>
                    </div>
                    <ProviderTakeEstimate
                      fee={values.providerCashPayRateDollars}
                      debounceKey="customCashRate"
                      {...composeHelperTextWithError(
                        '',
                        errors.providerCashPayRateDollars,
                        touched.providerCashPayRateDollars,
                      )}
                    />
                  </FormSection>
                  <FormSection>
                    <FillButton type="submit" isLoading={saveLoading}>
                      Save
                    </FillButton>
                    {initialValues.providerCashPayRateDollars !== null && (
                      <GhostButton
                        type="button"
                        variant="secondary"
                        className="ml-2"
                        onClick={() => setIsEditing(false)}
                      >
                        Cancel
                      </GhostButton>
                    )}
                  </FormSection>
                </Form>
              )}
            </Formik>
          )}
          {!isEditing && (
            <FormSection>
              <FieldLabel>My Rate</FieldLabel>
              <div>
                {!isEditing && (
                  <b>{formatCurrency(initialValues.providerCashPayRateDollars ?? 0, { maximumFractionDigits: 2 })}</b>
                )}{' '}
                will be charged per session for clients you bring
              </div>
              <ProviderTakeEstimate fee={initialValues.providerCashPayRateDollars ?? 0} debounceKey="customCashRate" />
            </FormSection>
          )}
        </FormCard>
      )}
      {!isAdmin && (
        <div className="text-secondary mt-2">
          For all other rates please review{' '}
          <TextButtonLink variant="secondary" to={routeService.harmonyPayRollSummary}>
            Tava-referred client rates
          </TextButtonLink>
        </div>
      )}
    </>
  )
}
