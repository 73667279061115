import { IconProps } from './IconProps'

export function IconInfo({ size = 24, color = 'currentColor', ...props }: IconProps) {
  return (
    <svg width={size} height={size} viewBox="0 0 22 22" fill={color} xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11 2.5625C15.6599 2.5625 19.4375 6.34005 19.4375 11C19.4375 15.6599 15.6599 19.4375 11 19.4375C6.34005 19.4375 2.5625 15.6599 2.5625 11C2.5625 6.34005 6.34005 2.5625 11 2.5625ZM12.1454 15.3229C11.8603 15.3229 11.6601 15.276 11.5444 15.1824C11.4286 15.0888 11.3709 14.9126 11.3709 14.6541C11.3709 14.5517 11.3887 14.4001 11.4245 14.1994C11.4603 13.9987 11.5005 13.8205 11.5453 13.6644L12.0951 11.7186C12.1486 11.5404 12.1853 11.3442 12.2054 11.1302C12.2255 10.9162 12.2356 10.7668 12.2356 10.6822C12.2356 10.2722 12.0915 9.93903 11.804 9.6826C11.516 9.42636 11.1067 9.29824 10.5757 9.29824C10.2812 9.29824 9.96867 9.35077 9.63852 9.45525C9.30819 9.56011 8.96247 9.68598 8.601 9.83304L8.45374 10.4348C8.56085 10.3947 8.68935 10.3525 8.83923 10.3078C8.98911 10.2634 9.13542 10.241 9.27836 10.241C9.56893 10.241 9.76551 10.2902 9.86831 10.3881C9.97092 10.4862 10.0225 10.6601 10.0225 10.9096C10.0225 11.0478 10.0058 11.2005 9.97223 11.3675C9.93884 11.5348 9.89738 11.7119 9.84805 11.8991L9.29618 13.8515C9.24703 14.0565 9.21139 14.2405 9.18926 14.403C9.16694 14.5658 9.15587 14.725 9.15587 14.8811C9.15587 15.2823 9.30406 15.6132 9.60063 15.874C9.89701 16.1349 10.3127 16.2651 10.8475 16.2651C11.1953 16.2651 11.5005 16.2193 11.7635 16.128C12.0265 16.0368 12.3786 15.904 12.8199 15.7301L12.967 15.1283C12.8912 15.164 12.7687 15.2052 12.5997 15.2521C12.4301 15.2994 12.2788 15.3229 12.1454 15.3229ZM11.7965 5.73472C11.4354 5.73472 11.1255 5.85515 10.867 6.09582C10.6085 6.33649 10.4793 6.62631 10.4793 6.96508C10.4793 7.30386 10.6085 7.59255 10.867 7.83097C11.1255 8.06939 11.4354 8.18869 11.7965 8.18869C12.1576 8.18869 12.4662 8.06957 12.7226 7.83097C12.9788 7.59255 13.1071 7.30386 13.1071 6.96508C13.1071 6.62631 12.9788 6.33649 12.7226 6.09582C12.4662 5.85496 12.1576 5.73472 11.7965 5.73472Z"
      />
    </svg>
  )
}
