import { StytchProvider as StytchProviderInternal } from '@stytch/react'
import { StytchB2BProvider } from '@stytch/react/b2b'
import { StytchUIClient } from '@stytch/vanilla-js'
import { StytchB2BUIClient } from '@stytch/vanilla-js/b2b'

import { useEnvironmentContext } from '@nuna/environment'

export function StytchProvider({ children, b2bEnabled = false }: { children: React.ReactNode; b2bEnabled?: boolean }) {
  const { CI, STYTCH_B2B_PUBLIC_TOKEN, STYTCH_PUBLIC_TOKEN } = useEnvironmentContext()

  const stytch = new StytchUIClient(STYTCH_PUBLIC_TOKEN)

  if (b2bEnabled && !CI) {
    const stytchB2B = new StytchB2BUIClient(STYTCH_B2B_PUBLIC_TOKEN)

    return (
      <StytchB2BProvider stytch={stytchB2B}>
        <StytchProviderInternal stytch={stytch}>{children}</StytchProviderInternal>
      </StytchB2BProvider>
    )
  }

  return <StytchProviderInternal stytch={stytch}>{children}</StytchProviderInternal>
}
