import { styled } from '@mui/material'
import { HTMLAttributes } from 'react'

import { body1, eggshell, greySet } from '@nuna/tunic'

import { VideoChat } from '../../VideoChat'

export function HairCheckChat({ ...props }: HTMLAttributes<HTMLDivElement>) {
  return (
    <ChatSection {...props}>
      <ChatSectionHeader className="v-align">
        <h3 className="mb-0 h6">Send Message</h3>
      </ChatSectionHeader>
      <VideoChat />
    </ChatSection>
  )
}

const ChatSection = styled('section')`
  background-color: ${eggshell};
  display: flex;
  flex-direction: column;
  height: 325px;
  padding: 0 var(--spacing-2) var(--spacing-2);
`

const ChatSectionHeader = styled('header')`
  border-bottom: 1px solid ${greySet[30].hex};
  color: ${body1};
  padding: 1.5rem 0 0.5rem 0;
`
