import Big from 'big.js'
import { compact, isNil } from 'lodash'

const defaultFormatting = {
  style: 'currency',
  currency: 'USD',
  minimumFractionDigits: 0,
  maximumFractionDigits: 0,
}

function centsToDollars(number: number) {
  return Big(number ?? 0)
    .div(100)
    .toNumber()
}

export const centsToFormattedDollars = (numCents: number | undefined | null, options?: Intl.NumberFormatOptions) => {
  if (isNil(numCents)) {
    return 'N/A'
  }

  return formatCurrency(centsToDollars(numCents), options)
}

export const formatCurrency = (amount: number | undefined, options?: Intl.NumberFormatOptions) => {
  if (isNil(amount)) {
    return 'N/A'
  }

  const formattingConfig = Object.assign({}, defaultFormatting, options)

  // Optionally enforce max fraction digits if we have change
  if (formattingConfig.minimumFractionDigits !== formattingConfig.maximumFractionDigits && amount % 1 > 0) {
    formattingConfig.minimumFractionDigits = formattingConfig.maximumFractionDigits
  }

  return amount.toLocaleString('en-US', formattingConfig)
}

function dollarsToCents(number: number | string) {
  const cleaned = typeof number === 'string' ? number.replace(/\$/, '') : number
  return Big(cleaned ?? 0)
    .mul(100)
    .round(0)
    .toNumber()
}

function minutesToFormattedHours(number: number, { hourFormat = 'hour', minuteFormat = 'minute' } = {}) {
  if (number === 0) {
    return 'N/A'
  }
  const bigNumber = Big(number)
  const hours = bigNumber.div(60).round(0, 0).toNumber()
  const minutes = bigNumber.mod(60).toNumber() || null

  const hoursStr = `${hours} ${hours === 1 ? hourFormat : `${hourFormat}s`}`
  const minStr = minutes ? `${minutes} ${minutes === 1 ? minuteFormat : `${minuteFormat}s`}` : null
  return compact([hoursStr, minStr]).join(' and ')
}

function formatPercentage(percent: number | undefined, options?: Intl.NumberFormatOptions) {
  if (isNil(percent)) {
    return 'N/A'
  }

  const formattingConfig = Object.assign(
    {
      style: 'percent',
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    },
    options,
  )

  return percent.toLocaleString('en-US', formattingConfig)
}

export const numberService = {
  centsToDollars,
  centsToFormattedDollars,
  formatCurrency,
  dollarsToCents,
  minutesToFormattedHours,
  formatPercentage,
}
