import { styled } from '@mui/material'
import {
  FormControl,
  FormHelperText,
  InputLabel,
  Select as MUISelect,
  SelectProps as MUISelectProps,
  MenuItem,
  SelectChangeEvent,
} from '@mui/material'
import { ReactNode, useEffect, useRef, useState } from 'react'
import { useParams } from 'react-router-dom'

import { AddressOwner, AddressType, useProviderAddressesQuery } from '@nuna/api'
import { useAuthDataContext, useIsAdmin } from '@nuna/auth'
import { addressService } from '@nuna/core'
import {
  Divider,
  Grid,
  IconMapMarker,
  MenuItem as NunaMenuItem,
  bodyPrimary,
  bodySecondary,
  interactiveFill,
} from '@nuna/tunic'

export interface ProviderAddressSelectValue {
  id: string
  name: string
}
interface ProviderAddressSelect extends Omit<MUISelectProps, 'onChange'> {
  containerProps?: React.HTMLProps<HTMLDivElement>
  error?: boolean
  helperText?: string | ReactNode
  label?: string
  name?: string
  addressId?: string | null
  showMapMarker?: boolean
  onAddressChange: (selectedAddress: ProviderAddressSelectValue) => void
}

export function ProviderAddressSelect({
  helperText,
  containerProps = {},
  error = false,
  label = 'Location',
  addressId,
  showMapMarker = true,
  onAddressChange,
  ...props
}: ProviderAddressSelect) {
  const isAdmin = useIsAdmin()
  const { login } = useAuthDataContext()
  const { id: pathProviderId } = useParams<{ id?: string }>()
  const { providerId: loginProviderId } = login ?? {}
  const providerId = isAdmin ? pathProviderId : loginProviderId
  const {
    data: providerAddressData,
    loading: isLoadingAddresses,
    error: loadingAddressesError,
  } = useProviderAddressesQuery({
    variables: {
      searchOptions: {
        providerId,
        providerAddressType: AddressType.ProviderPractice,
        owner: AddressOwner.Provider,
      },
    },
    skip: !providerId,
  })
  const providerAddresses = providerAddressData?.providerAddresses ?? []
  const [isFocused, setIsFocused] = useState(false)
  const [isOpen, setIsOpen] = useState(false)
  const selectRef = useRef<HTMLDivElement>(null)
  const [menuWidth, setMenuWidth] = useState<number | undefined>(undefined)
  const addLocationLink = isAdmin ? `/providers/${providerId}/preferences/locations` : '/me/preferences/locations'

  useEffect(() => {
    if (isOpen && selectRef.current) {
      const selectWidth = selectRef.current.getBoundingClientRect().width
      setMenuWidth(selectWidth)
    }
  }, [isOpen])

  const getSelectedAddress = (addressId?: string | null) =>
    providerAddresses.find(location => location.address.id === addressId)?.address

  const handleSelectChange = (event: SelectChangeEvent<unknown>) => {
    const value = (event.target.value || '') as string
    const selectedObject = getSelectedAddress(value)
    if (selectedObject) {
      onAddressChange({ id: selectedObject.id, name: selectedObject.name })
    }
  }

  return (
    <>
      <style>
        {`
        .MuiPopover-root .MuiMenu-list {
          padding-top: 0;
          padding-bottom: 0;
        }
      `}
      </style>
      <div className={`v-align mb-2`} {...containerProps}>
        {showMapMarker && (
          <IconMapMarker color={isFocused || isOpen ? interactiveFill : bodySecondary} className={`mr-1`} />
        )}
        <FormControlStyled fullWidth $isFocused={isFocused || isOpen} error={error} ref={selectRef}>
          <InputLabel>{label}</InputLabel>
          <MUISelect
            onOpen={() => setIsOpen(true)}
            onClose={() => setIsOpen(false)}
            onFocus={() => setIsFocused(true)}
            onBlur={() => setIsFocused(false)}
            value={getSelectedAddress(addressId)?.id ?? 'Unknown'}
            onChange={handleSelectChange}
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            renderValue={(locationValue: any) =>
              isLoadingAddresses ? (
                <div>Loading locations...</div>
              ) : !loadingAddressesError ? (
                <div>{getSelectedAddress(locationValue)?.name}</div>
              ) : (
                <div>Error loading locations</div>
              )
            }
            MenuProps={{
              style: {
                maxHeight: 350,
              },
              PaperProps: {
                style: {
                  width: menuWidth,
                },
              },
              anchorOrigin: {
                vertical: 'bottom',
                horizontal: 'left',
              },
              transformOrigin: {
                vertical: 'top',
                horizontal: 'left',
              },
            }}
            disabled={isLoadingAddresses}
            {...props}
          >
            {providerAddresses.map(location => {
              const { address } = location
              const { id, name } = address
              return (
                <MenuItemStyled key={id} value={id}>
                  <Grid container>
                    <Grid className="v-align" size={12}>
                      <span className="v-align full-width">{name}</span>
                    </Grid>
                    <Grid style={{ lineHeight: 1.4 }} size={12}>
                      <span className="caption text-light-grey">{addressService.formatAddress(address)}</span>
                    </Grid>
                  </Grid>
                </MenuItemStyled>
              )
            })}
            <Divider />
            <AddNewItem to={addLocationLink} relative={'path'} target="_blank">
              <span>Add New Location</span>
            </AddNewItem>
          </MUISelect>
          {helperText && <FormHelperText>{helperText}</FormHelperText>}
        </FormControlStyled>
      </div>
    </>
  )
}

const FormControlStyled = styled(FormControl)<{ $isFocused: boolean }>`
  ${({ $isFocused }) =>
    $isFocused &&
    `
  .MuiInput-underline {
    &::after {
      border-bottom-color: ${interactiveFill};
      transform: scaleX(1);
    }
  }
`}
`
const AddNewItem = styled(NunaMenuItem)`
  && {
    margin-top: 0;
    padding-top: var(--spacing-2);
    padding-bottom: var(--spacing-2);
    border-radius: 0;
  }
`

const MenuItemStyled = styled(MenuItem)`
  && {
    color: ${bodyPrimary};
    font-weight: 500;
    white-space: normal;
  }

  .pending-enrollment-label {
    pointer-events: auto;
  }
`
