import { styled } from '@mui/material'
import { HTMLAttributes } from 'react'

import { useIsAdmin } from '@nuna/auth'
import { csx, makeTypographyComponent } from '@nuna/tunic'

export const LICENSE_COLUMN_WIDTHS = ['100px', '19%', '19%', '19%', '19%', '19%', '5%']

const Header = makeTypographyComponent('caption text-secondary uppercase text-bold', 'span')

export function LicenseCardHeader({ className, ...props }: HTMLAttributes<HTMLDivElement>) {
  const isAdmin = useIsAdmin()
  return (
    <Container className={csx(['v-align mb-1', className])} {...props}>
      {isAdmin && <Header style={{ width: LICENSE_COLUMN_WIDTHS[0] }}>Active</Header>}

      <div className="v-align card-section">
        <Header style={{ width: LICENSE_COLUMN_WIDTHS[1] }}>State</Header>
        <Header style={{ width: LICENSE_COLUMN_WIDTHS[2] }}>Title</Header>
        <Header style={{ width: LICENSE_COLUMN_WIDTHS[3] }}>License Number</Header>
        <Header style={{ width: LICENSE_COLUMN_WIDTHS[4] }}>Expires</Header>
        <Header style={{ width: LICENSE_COLUMN_WIDTHS[5] }}>Status</Header>
      </div>
    </Container>
  )
}

const Container = styled('div')`
  .card-section {
    flex: 1;
    padding: 0 var(--margin-2);
  }
`
