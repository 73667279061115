import { styled } from '@mui/material'
import { useFormikContext } from 'formik'
import moment from 'moment'
import pluralize from 'pluralize'
import { useState } from 'react'

import { BasicPatientFragment } from '@nuna/api'
import {
  Checkbox,
  IconCheckCircle,
  IconError,
  IconInfo,
  StatusLabel,
  greySet,
  interactiveFill,
  plumSet,
  tealSet,
} from '@nuna/tunic'

import { EditableAppointmentSlot } from '../shared'
import { NewCard } from '../shared'
import { NewAppointmentCard } from './NewAppointmentCard'
import { NewAppointmentTimeForm } from './NewAppointmentTimeForm'

const getTagColor = (tagInstance: string) => {
  if (tagInstance === 'ONE_FREE_SESSION_LEFT') {
    return 'caution'
  } else if (tagInstance === 'FINAL_FREE_SESSION') {
    return 'urgent'
  }

  return 'information'
}

export function NewAppointmentSlot({
  appointmentSlot,
  patient,
  index,
  providerId,
}: {
  appointmentSlot: EditableAppointmentSlot
  index: number
  patient: BasicPatientFragment
  providerId: string
}) {
  const [isBeingEdited, setIsBeingEdited] = useState(false)
  const { setFieldValue } = useFormikContext()
  const startTimeString: string = appointmentSlot.newStart?.toISOString() ?? appointmentSlot.slot.start
  const startTime = moment(startTimeString)

  return (
    <>
      {appointmentSlot.slot.capNotifiers.map(notifier => {
        return (
          <CapResetDivider>
            <IconInfo className="mr-1" size={20} /> {notifier?.displayLabel}
          </CapResetDivider>
        )
      })}

      {(appointmentSlot.slot.skippedSlots ?? []).map(skippedSlot => {
        if (!skippedSlot) return null

        return (
          <div className="v-align mb-2">
            <div className="mr-1 flex-static" style={{ width: 32 }}>
              <IconError color={greySet[50].hex} />
            </div>

            <NewAppointmentCard
              providerId={providerId}
              appointmentSlot={{ slot: skippedSlot, included: false }}
              subtext={`Conflicting session for ${skippedSlot.patientName}`}
              inactive
            />
          </div>
        )
      })}
      <div className="v-align mb-2">
        {isBeingEdited ? (
          <NewCard className="full-width v-align">
            <NewAppointmentTimeForm
              providerId={providerId}
              containerProps={{ className: 'full-width' }}
              oldStartTime={appointmentSlot.slot.start}
              onChange={startTime => setFieldValue(`appointments[${index}].newStart`, moment(startTime))}
              locationOnChange={location => setFieldValue(`appointments[${index}].newLocation`, location)}
              locationValue={appointmentSlot.newLocation ?? 'Virtual'}
              value={startTimeString}
              patient={patient}
            />
            <SideButton onClick={() => setIsBeingEdited(false)}>
              <IconCheckCircle /> Done
            </SideButton>
          </NewCard>
        ) : (
          <>
            <Checkbox
              data-component="appointment-slot-checkbox"
              className="mr-1"
              checked={appointmentSlot.included}
              onChange={e => {
                if (!e.currentTarget.checked) {
                  setFieldValue(`appointments[${index}].included`, false)
                } else {
                  setFieldValue(`appointments[${index}].included`, true)
                }
              }}
            />

            <NewAppointmentCard
              providerId={providerId}
              appointmentSlot={appointmentSlot}
              onEditClick={() => setIsBeingEdited(true)}
              subtext={
                patient.timezone && (
                  <>
                    {startTime.tz(patient.timezone).format('LT z')} for {patient.firstName}
                  </>
                )
              }
              statusLabels={[
                appointmentSlot.slot.conflictsCount > 0 && (
                  <StatusLabel key="conflicts" color="orange">
                    Skipped {appointmentSlot.slot.conflictsCount}{' '}
                    {pluralize('week', appointmentSlot.slot.conflictsCount)}
                  </StatusLabel>
                ),
                appointmentSlot.slot.capTags?.map(tag => (
                  <StatusLabel key={tag?.displayLabel} intent={getTagColor(tag?.tagInstance ?? '')}>
                    {tag?.displayLabel}
                  </StatusLabel>
                )),
              ]}
            />
          </>
        )}
      </div>
    </>
  )
}

const CapResetDivider = styled('div')`
  align-items: center;
  background-color: ${tealSet.tint[20]};
  border-radius: 6px;
  border: 1px solid ${interactiveFill};
  display: flex;
  font-size: 16px;
  line-height: 1;
  margin-bottom: 1rem;
  margin-left: 2.5rem;
  padding: 0.6125rem;
`

const SideButton = styled('button')`
  align-self: stretch;
  background-color: ${plumSet.primary.hex};
  color: #fff;
  margin: -1rem -1rem -1rem 0.5rem;
  font-weight: 600;
  padding: 0.5rem;
  border-radius: 0 6px 6px 0;
`
