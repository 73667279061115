import { styled } from '@mui/material'
import { isNil } from 'lodash'
import { useState } from 'react'
import { useMediaQuery } from 'react-responsive'

import { PaymentPreference, Provider, useCancelPolicyStatusQuery } from '@nuna/api'
import { AuthorizedAvatar } from '@nuna/common'
import { numberService } from '@nuna/core'
import {
  BelowTablet,
  Card,
  Collapse,
  Dialog,
  DialogProps,
  IconButton,
  IconCancelAppointment,
  IconChevronThick,
  IconClose,
  Skeleton,
} from '@nuna/tunic'

import { ProviderPolicyFAQs } from './ProviderPolicyFAQs'

interface Props extends DialogProps {
  provider?: Pick<Provider, 'id' | 'firstName' | 'avatarUrl'> | null
  coverageType?: PaymentPreference | null
}

export function LateCancelationPolicyModal({ coverageType, provider, ...props }: Props) {
  const isMobile = useMediaQuery({ query: `(${BelowTablet})` })
  const [faqOpen, setFaqOpen] = useState(false)
  const { data: cancelPolicyData } = useCancelPolicyStatusQuery({
    variables: { providerId: provider?.id ?? '' },
    skip: isNil(provider?.id),
    nextFetchPolicy: 'cache-only',
  })

  const cancelPolicyStatus = cancelPolicyData?.cancelPolicyStatus

  const cancelPolicy = cancelPolicyStatus?.cancelPolicy

  if (!provider) {
    return <Skeleton />
  }

  return (
    <Dialog {...props} fullScreen={isMobile}>
      <Container className="p-3">
        <div className="v-align mb-2">
          <AuthorizedAvatar url={provider.avatarUrl} size="md" />
          <h4 className="mb-0 ml-1">{provider.firstName}'s Policies</h4>
          {props.onClose && (
            <IconButton
              small
              tooltip="Close"
              className="ml-auto self-start"
              onClick={() => (props.onClose ? props.onClose({}, 'escapeKeyDown') : null)}
            >
              <IconClose size={16} />
            </IconButton>
          )}
        </div>
        {cancelPolicy && (
          <div className="flex-column gap-2">
            <div className="flex-column gap-1">
              <div className="v-align">
                <IconCancelAppointment isHovered /> <span className="ml-1 text-bold">Late Cancelation</span>
              </div>
              <Card depth={-1} className="p-2">
                <CardText>
                  {provider.firstName} charges <b>{numberService.centsToFormattedDollars(cancelPolicy.chargeAmount)}</b>{' '}
                  if you cancel or reschedule a session{' '}
                  <b>less than {numberService.minutesToFormattedHours(cancelPolicy.cancelPeriodMinutes)} beforehand.</b>
                </CardText>
              </Card>
            </div>
            <div className="flex-column gap-1">
              <div className="v-align">
                <IconCancelAppointment isHovered /> <span className="ml-1 text-bold">No-Show</span>
              </div>
              <Card depth={-1} className="p-2">
                <CardText>
                  {provider.firstName} charges{' '}
                  <b>{numberService.centsToFormattedDollars(cancelPolicy.noShowChargeAmount)}</b> if you simply don't
                  show up for a session.
                </CardText>
              </Card>
            </div>
          </div>
        )}
        <button
          className="caption text-bold text-secondary v-align full-width space-between py-2 mt-1"
          type="button"
          onClick={() => setFaqOpen(!faqOpen)}
        >
          <span>Common Questions</span> <IconChevronThick size={14} direction={faqOpen ? 'up' : 'down'} />
        </button>
        <Collapse isOpen={faqOpen}>
          <ProviderPolicyFAQs
            provider={provider}
            coverageType={coverageType}
            sharedListItemProps={{ className: 'px-0', compact: true }}
          />
        </Collapse>
      </Container>
    </Dialog>
  )
}

const Container = styled('div')`
  max-width: 492px;
`

const CardText = styled('p')`
  margin-bottom: 0;
`
