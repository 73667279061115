import { styled } from '@mui/material'
import moment from 'moment'
import { CSSProperties, ReactNode } from 'react'

import { numberService } from '@nuna/core'
import {
  IconCheckCircle,
  IconDollarSymbol,
  IconError,
  IconLightning,
  IconShield,
  csx,
  error,
  greySet,
  interactiveFill,
  makeTypographyComponent,
  progressIndicator,
  salmonSet,
  shadowDepth,
  tealSet,
} from '@nuna/tunic'

import { CapProgress } from './CapProgress'
import { CoverageLogoRatio } from './CoverageTypography'
import { ZeroCapEAPCard } from './ZeroCapEAPCard'

interface CommonProps {
  type: 'insurance' | 'employer' | 'cash' | 'accessCode'
  isInvalid?: boolean
  className?: string
  style?: CSSProperties
  footerOverride?: ReactNode
}

export interface InsuranceProps extends CommonProps {
  type: 'insurance'
  insuredFullName: string
  memberId: string
  verifiedAt: string | null | undefined
  payerName: string
  payerLogoUrl?: string | null
  isProviderInNetwork?: boolean
  endDate?: string
  failureReason?: string
  failureType?: string
  cardFrontUrl?: string
  cardBackUrl?: string
}

export interface EmployerProps extends CommonProps {
  type: 'employer'
  sessionCount: number | null | undefined
  isSessionCountLoading?: boolean
  employerName: string
  employerLogo?: string | null
  isZeroCap: boolean
  isIntakeStep?: boolean
}

export interface CashProps extends CommonProps {
  type: 'cash'
  sessionCost: number
}

export interface AccessCodeProps extends CommonProps {
  type: 'accessCode'
  code: string
  sessionCount: number | null | undefined
}

export type CoverageTypeCardProps = InsuranceProps | EmployerProps | CashProps | AccessCodeProps

export function CoverageTypeCard({ isInvalid = false, className = '', style = {}, ...props }: CoverageTypeCardProps) {
  return (
    <div style={{ maxWidth: 375, width: '100%' }}>
      {(() => {
        if (props.type === 'employer' && props.isZeroCap) {
          return (
            <ZeroCapEAPCard
              employerName={props.employerName}
              employerLogo={props.employerLogo}
              isIntakeStep={props.isIntakeStep ?? false}
            />
          )
        }

        return (
          <Card isInvalid={isInvalid} className={className} style={style}>
            {(() => {
              if (props.type === 'employer') {
                const {
                  sessionCount,
                  isSessionCountLoading = false,
                  employerLogo,
                  employerName,
                  footerOverride,
                } = props

                return (
                  <>
                    {employerLogo ? (
                      <Logo className="mb-3" src={employerLogo} alt={employerName} />
                    ) : (
                      <p className="large text-medium">{employerName}</p>
                    )}
                    <Heading>Employer Benefit</Heading>

                    <Footer isInvalid={isInvalid} className="v-align">
                      {(() => {
                        if (footerOverride) {
                          return footerOverride
                        } else if (isInvalid) {
                          return <CapProgress />
                        }

                        return (
                          <div className={csx([{ loading: isSessionCountLoading }])}>
                            Covered for {sessionCount === null ? 'unlimited' : `${sessionCount} total`} sessions
                          </div>
                        )
                      })()}
                    </Footer>
                  </>
                )
              } else if (props.type === 'cash') {
                const { sessionCost } = props

                return (
                  <>
                    <Heading className="v-align">
                      <IconDollarSymbol className="mr-1" color={interactiveFill} size={18} /> Out-of-pocket
                    </Heading>
                    <div>At a rate of ${numberService.centsToDollars(sessionCost)} per session</div>
                  </>
                )
              } else if (props.type === 'accessCode') {
                const { sessionCount, code } = props

                return (
                  <>
                    <Heading className="v-align mb-2">
                      <IconShield className="mr-1" color={interactiveFill} size={18} /> Access code
                    </Heading>
                    <div className="mb-1">{code}</div>

                    <Footer isInvalid={isInvalid} className="v-align">
                      {isInvalid ? (
                        <>
                          <IconError size={20} className="mr-xs" color={error} />
                          <span className="mr-2">No code found</span>
                        </>
                      ) : (
                        <div>Covered for {!sessionCount ? 'unlimited' : `${sessionCount} total`} sessions</div>
                      )}
                    </Footer>
                  </>
                )
              } else if (props.type === 'insurance') {
                const {
                  endDate,
                  insuredFullName,
                  memberId,
                  verifiedAt,
                  payerName,
                  payerLogoUrl,
                  footerOverride,
                  isProviderInNetwork = true,
                } = props

                return (
                  <>
                    {payerLogoUrl ? (
                      <Logo className="mb-3" src={payerLogoUrl} alt={payerName} />
                    ) : (
                      <p className="large text-medium">{payerName}</p>
                    )}
                    <Heading>Insurance Coverage</Heading>

                    <div>
                      <span>{insuredFullName}</span>
                      <span className="ml-5">{memberId}</span>
                    </div>

                    {isProviderInNetwork !== false && (
                      <Footer isInvalid={isInvalid} className="v-align">
                        {(() => {
                          if (footerOverride) {
                            return footerOverride
                          } else if (isInvalid) {
                            return (
                              <>
                                <IconError size={20} className="mr-xs" color={error} />
                                <span className="mr-2">Invalid insurance</span>
                                {endDate && (
                                  <span className="ml-auto text-medium">as of {moment(endDate).format('l')}</span>
                                )}
                              </>
                            )
                          } else if (verifiedAt) {
                            return (
                              <>
                                <IconCheckCircle className="mr-xs flex-static" color={tealSet[50].hex} />
                                <span className="mr-2">Coverage verified</span>
                                <span className="ml-auto text-medium">as of {moment(verifiedAt).format('l')}</span>
                              </>
                            )
                          } else if (!verifiedAt) {
                            return (
                              <>
                                <img src={progressIndicator} className="mr-xs flex-static" alt="" />
                                <span className="mr-2">Verifying coverage...</span>
                              </>
                            )
                          }
                        })()}
                      </Footer>
                    )}
                  </>
                )
              }
            })()}
          </Card>
        )
      })()}
    </div>
  )
}

export const NoVerificationFooterMessage = () => (
  <>
    <IconLightning className="mr-xs flex-static" size={29} />
    <span className="mr-2">Not yet verified</span>
    <span className="ml-auto">
      <i>No Action Required</i>
    </span>
  </>
)

const Card = styled('div')<{ isInvalid: boolean }>`
  background-color: #fff;
  border: 1px solid ${greySet[15].hex};
  border-radius: var(--border-radius);
  box-shadow: ${shadowDepth(1)};
  flex-basis: 350px;
  padding: var(--margin-2);

  ${({ isInvalid }) =>
    isInvalid &&
    `
      background-color: ${salmonSet.tint[20]};
      border: 1px solid ${error};
    `}
`

const Heading = makeTypographyComponent('body text-bold', 'h4')

const Logo = styled(CoverageLogoRatio)`
  margin-top: var(--margin-2);
  margin-bottom: var(--margin-4);
`

const Footer = styled('div')<{ isInvalid: boolean }>`
  border-top: 1px solid ${greySet[15].hex};
  color: var(--body-secondary-color);
  margin-top: var(--margin-2);
  padding-top: var(--margin-2);

  ${({ isInvalid }) =>
    isInvalid &&
    `
      color: ${error};
    `}
`
