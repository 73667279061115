import * as Yup from 'yup'
import { Formik } from 'formik'

import { PatientPaymentMethodFragment } from '@nuna/api'
import { formService, paymentMethodService } from '@nuna/core'
import { CreditCard, PaymentMethodSelect, useClientPaymentMethods } from '@nuna/payment-methods'
import { FillButton } from '@nuna/tunic'

import { CoverageFormPatient } from '../../CoverageForm'

const { composeHelperTextWithError } = formService

interface Props {
  client: CoverageFormPatient
  onSubmitComplete: () => void
}

const schema = Yup.object().shape({
  paymentMethod: Yup.object<PatientPaymentMethodFragment>()
    .nullable()
    .required("You'll need to select a card to continue")
    .test('is-expired', "You'll need to add a new card to continue", (card: CreditCard | null) =>
      paymentMethodService.isCardValid(card),
    ),
})

export function DrawerCashForm({ client, onSubmitComplete }: Props) {
  const {
    paymentMethods,
    defaultForSessions,
    savePaymentMethod,
    queryResult: { loading: paymentMethodsLoading },
  } = useClientPaymentMethods(client.id)

  const handleSubmit = () => {
    onSubmitComplete()
  }

  return (
    <Formik
      validationSchema={schema}
      initialValues={{ paymentMethod: defaultForSessions ?? null }}
      onSubmit={handleSubmit}
    >
      {({ handleSubmit, setFieldValue, errors, touched }) => (
        <form onSubmit={handleSubmit}>
          <PaymentMethodSelect
            paymentMethods={paymentMethods}
            value={defaultForSessions}
            onChange={card => {
              setFieldValue('paymentMethod', card)
            }}
            defaultFor="sessions"
            loading={paymentMethodsLoading}
            patientId={client.id}
            savePaymentMethod={savePaymentMethod}
            {...composeHelperTextWithError('', errors.paymentMethod, touched.paymentMethod)}
          />
          <FillButton className="mt-4" type="submit">
            Submit
          </FillButton>
        </form>
      )}
    </Formik>
  )
}
