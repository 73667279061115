import { styled } from '@mui/material'

import { ContextualAlert, Select, greySet, makeTypographyComponent, shadowDepth } from '@nuna/tunic'

export const HoursContainer = styled('div')`
  margin-top: 1.5rem;
`

export const Spacer = styled('span')`
  margin-right: 1.2rem;
`

export const Container = styled('div')`
  max-width: 723px;
`

export const DescriptionCaption = styled(makeTypographyComponent('caption mb-3 text-secondary text-medium', 'div'))`
  display: flex;
  flex-direction: column;

  .timezone {
    margin-top: 0.5rem;
  }
`

export const TimeForm = styled('div')<{ block?: boolean }>`
  background-color: #fff;
  border-radius: 8px;
  border: 1px solid ${greySet[5].hex};
  box-shadow: ${shadowDepth(1)};
  padding: 0.25rem 1.25rem 1.5rem 1rem;
  position: relative;
  width: 100%;
`

export const SeperatorText = styled(makeTypographyComponent('text-medium-gray italic', 'span'))`
  margin-top: 0.75rem;
`

export const InvalidAlert = styled(ContextualAlert)`
  position: absolute;
  left: 100%;
  white-space: nowrap;
`

export const ErrorText = styled(makeTypographyComponent('caption text-error', 'p'))`
  margin: 0.25rem 0 -0.5rem;
`

export const StyledSelect = styled(Select)`
  select {
    padding-bottom: 6px; // aligns with MUI date pickers better
  }
`
