import { styled } from '@mui/material'
import { useEffect } from 'react'
import { useSearchParams } from 'react-router-dom'

import { usePrevious } from '@nuna/common'
import { borderGrey } from '@nuna/tunic'

import { AddressCard } from './AddressCard'
import { ProviderAddressContextProvider, useProviderAddressContext } from './ProviderAddressContextProvider'

interface Props {
  providerId: string
}

export function LocationSection({ providerId }: Props) {
  return (
    <ProviderAddressContextProvider providerId={providerId}>
      <LocationSectionWithProvider />
    </ProviderAddressContextProvider>
  )
}

export function LocationSectionWithProvider() {
  const [searchParams] = useSearchParams()
  const { homeAddress, refetchAddresses, loading } = useProviderAddressContext()

  const previousSearchParams = usePrevious(searchParams)

  useEffect(() => {
    if (previousSearchParams?.get('assignPracticeAddresses')) {
      refetchAddresses()
    }
  }, [searchParams, previousSearchParams, refetchAddresses])

  return (
    <StyledAddressSection>
      <h2 className="h5 mb-4">Location</h2>
      {!loading && <AddressCard address={homeAddress} />}
    </StyledAddressSection>
  )
}

const StyledAddressSection = styled('section')`
  border-top: 1px solid ${borderGrey};
  padding: var(--margin-4) 0;
`
