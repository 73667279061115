/**
 * Hook inspired by https://gist.github.com/kjellski/42ae99e7b1d85a76d835274c255c6af0
 */
import { useEffect, useRef } from 'react'

export function usePrevious<T>(value: T) {
  const ref = useRef<T>()

  useEffect(() => {
    ref.current = value
  }, [value])

  return ref.current
}
