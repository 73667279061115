import { sortBy } from 'lodash'
import moment from 'moment'

import { CancelPolicyItem } from '../types/exported'
import { CancelPolicy } from '../types/internal-only'

const LEGACY_POLICY_ID = '00000000-0000-0000-0000-000000000000'

function isLegacy(policy?: Pick<CancelPolicy, 'id'> | null) {
  if (!policy) {
    return true
  }
  return policy.id === LEGACY_POLICY_ID
}

function prepareCancelPoliciesList(policies: CancelPolicy[]): CancelPolicyItem[] {
  const sorted = sortBy(policies, p => moment(p.startDate)).reverse()
  return sorted.map((p, idx) => {
    const previous = idx > 0 && sorted[idx - 1]
    const endDate = previous ? moment(moment.utc(previous.startDate).format('YYYY-MM-DD')) : undefined
    const policy = { ...p, startDate: moment(moment.utc(p.startDate).format('YYYY-MM-DD')), endDate }
    return {
      ...policy,
      isCurrent: isCurrentPolicy(policy),
      isOld: isOldPolicy(policy),
      isFuture: isFuturePolicy(policy),
    }
  })
}

function isCurrentPolicy(policy: CancelPolicyItem) {
  const startsBeforeToday = moment().utc().isSameOrAfter(policy.startDate)
  if (!policy.endDate) {
    return startsBeforeToday
  }
  return startsBeforeToday && moment().utc().isBefore(policy.endDate)
}

function isOldPolicy(policy: CancelPolicyItem) {
  return moment().utc().isAfter(policy.endDate)
}

function isFuturePolicy(policy: CancelPolicyItem) {
  return moment.utc().isBefore(policy.startDate)
}

function isZeroDollarPolicy(policy: CancelPolicyItem) {
  return policy.chargeAmount === 0 && policy.noShowChargeAmount === 0
}

function dateDescription({
  startDate,
  endDate,
  isCurrent,
  isOld,
}: Pick<CancelPolicyItem, 'startDate' | 'endDate' | 'isFuture' | 'isCurrent' | 'isOld'>) {
  if (isCurrent) {
    return `Since ${startDate.format('ll')}`
  }

  if (isOld) {
    return `${startDate.format('ll')} – ${endDate?.format('ll')}`
  }

  return `Effective on sessions booked after ${startDate.format('ll')}`
}

export const cancelPolicyService = {
  isLegacy,
  prepareCancelPoliciesList,
  isCurrentPolicy,
  isOldPolicy,
  isFuturePolicy,
  isZeroDollarPolicy,
  dateDescription,
}
