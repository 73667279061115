import { styled } from '@mui/material'
import { FormHelperText } from '@mui/material'
import { FormikProps } from 'formik'
import { HTMLAttributes, useState } from 'react'

import { AppointmentChangeReason } from '@nuna/api'
import { Audience, formService } from '@nuna/core'
import { GhostButton, Radio, RadioGroup, TextField, csx, eggshell } from '@nuna/tunic'

interface FormProps {
  reason: AppointmentChangeReason | ''
  reasonData: string | null
  shouldProviderBePaid: boolean
}

interface Props extends HTMLAttributes<HTMLDivElement> {
  requestedBy?: Exclude<Audience, 'admin'>
  audience: Audience
  formProps: Pick<
    FormikProps<FormProps>,
    'values' | 'setFieldValue' | 'errors' | 'touched' | 'getFieldProps' | 'handleChange'
  >
  labelText: string
}

export function AppointmentChangeReasonForm({
  requestedBy = 'provider',
  className,
  audience,
  formProps,
  labelText,
  ...props
}: Props) {
  const [showFreeFormField, setShowFreeFormField] = useState(false)
  const { values, setFieldValue, getFieldProps, errors, touched } = formProps
  const options = getOptions(audience, requestedBy)

  const handleReasonChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = e.currentTarget.value as AppointmentChangeReason
    setFieldValue('shouldProviderBePaid', false)
    setFieldValue('reason', newValue)

    if (newValue === AppointmentChangeReason.FreeForm) {
      setShowFreeFormField(true)
    } else if (!values.reasonData) {
      setShowFreeFormField(false)
    }
  }

  return (
    <Container {...props} className={csx([className, 'px-4 pt-2 pb-3'])}>
      <div className="label text-medium text-default mb-2">{labelText}</div>
      <RadioGroup>
        {options.map(option => (
          <Radio
            key={option.value}
            className="radio-option"
            value={option.value}
            checked={values.reason === option.value}
            onChange={handleReasonChange}
            dataTestId={`appointment-change-${option.value}`}
          >
            {option.label}
          </Radio>
        ))}
        {errors.reason && touched.reason && <FormHelperText error={true}>{errors.reason}</FormHelperText>}
      </RadioGroup>
      {!showFreeFormField && (
        <GhostButton
          type="button"
          variant="secondary"
          className="caption mt-2"
          onClick={() => setShowFreeFormField(true)}
        >
          Add private note
        </GhostButton>
      )}
      {showFreeFormField && (
        <TextField
          autoFocus
          {...getFieldProps('reasonData')}
          {...formService.composeHelperTextWithError('', errors.reasonData, touched.reasonData)}
          placeholder="Additional context"
          multiline
        />
      )}
    </Container>
  )
}

const Container = styled('div')`
  background-color: ${eggshell};
  [role='group'] .radio-option:last-child {
    margin-bottom: 0;
  }
`

interface ReasonOption {
  label: string
  value: AppointmentChangeReason
  audience: Audience
  requestedBy: Audience
}
function getOptions(audience: Audience, requestedBy: Audience): ReasonOption[] {
  const prov: Audience = 'provider'
  const admin: Audience = 'admin'
  const client: Audience = 'client'
  return [
    {
      label: 'I had a scheduling conflict',
      value: AppointmentChangeReason.SchedulingConflict,
      audience: prov,
      requestedBy: prov,
    },
    {
      label: `I'm not feeling well`,
      value: AppointmentChangeReason.NotFeelingWell,
      audience: prov,
      requestedBy: prov,
    },
    {
      label: `Canceling at the client's request`,
      value: AppointmentChangeReason.RequestedByPatient,
      audience: prov,
      requestedBy: prov,
    },
    {
      label: `Something else`,
      value: AppointmentChangeReason.FreeForm,
      audience: prov,
      requestedBy: prov,
    },
    {
      label: 'Scheduling conflict',
      value: AppointmentChangeReason.SchedulingConflict,
      audience: admin,
      requestedBy: prov,
    },
    {
      label: `Not feeling well`,
      value: AppointmentChangeReason.NotFeelingWell,
      audience: admin,
      requestedBy: prov,
    },
    {
      label: `Technical difficulties`,
      value: AppointmentChangeReason.TechnicalDifficulties,
      audience: admin,
      requestedBy: prov,
    },
    {
      label: `Something else`,
      value: AppointmentChangeReason.FreeForm,
      audience: admin,
      requestedBy: prov,
    },
    {
      label: 'Scheduling conflict',
      value: AppointmentChangeReason.SchedulingConflict,
      audience: admin,
      requestedBy: client,
    },
    {
      label: 'Loss of interest',
      value: AppointmentChangeReason.LossOfInterest,
      audience: admin,
      requestedBy: client,
    },
    {
      label: 'Feeling nervous',
      value: AppointmentChangeReason.FeelingNervous,
      audience: admin,
      requestedBy: client,
    },
    {
      label: `Technical difficulties`,
      value: AppointmentChangeReason.TechnicalDifficulties,
      audience: admin,
      requestedBy: client,
    },
    {
      label: `Something else`,
      value: AppointmentChangeReason.FreeForm,
      audience: admin,
      requestedBy: client,
    },
    {
      label: `Scheduling conflict`,
      value: AppointmentChangeReason.SchedulingConflict,
      audience: client,
      requestedBy: client,
    },
    {
      label: `Loss of interest`,
      value: AppointmentChangeReason.LossOfInterest,
      audience: client,
      requestedBy: client,
    },
    {
      label: `Feeling nervous`,
      value: AppointmentChangeReason.FeelingNervous,
      audience: client,
      requestedBy: client,
    },
    {
      label: `Something else`,
      value: AppointmentChangeReason.FreeForm,
      audience: client,
      requestedBy: client,
    },
  ].filter(option => option.audience === audience && option.requestedBy === requestedBy)
}
