import { IconProps } from './IconProps'

export function IconAssessment({ size = 24, color = 'currentColor', ...props }: IconProps) {
  return (
    <svg width={size} height={size} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.84593 14.1867L1.10771 14.5258C0.652861 14.5838 0.398051 14.274 0.538693 13.8347L1.35077 11.2675C0.875406 10.2797 0.608517 9.17133 0.608517 8C0.608517 3.85767 3.94206 0.5 8.05426 0.5C12.1665 0.5 15.5 3.85783 15.5 8C15.5 12.1422 12.1665 15.5 8.05426 15.5C6.49247 15.5 5.04337 15.0145 3.84593 14.1867ZM10.2974 5.74842L9.37747 3.30412C9.20327 2.83703 8.78297 2.74829 8.4388 3.10556L6.61564 4.98612L4.19554 4.81703C3.70369 4.78114 3.44767 5.13002 3.62382 5.59629L4.64869 8.33611L3.87382 10.797C3.72255 11.2722 3.99662 11.6069 4.48588 11.5442L7.31244 11.1922L9.76328 12.6605C10.187 12.916 10.5705 12.7206 10.62 12.224L10.8835 9.65457L12.9062 7.55933C13.2522 7.20353 13.1554 6.78007 12.6905 6.61362L10.2974 5.74842Z"
        fill={color}
      />
    </svg>
  )
}
