import { styled } from '@mui/material'
import { HTMLAttributes, forwardRef } from 'react'

import { body1, borderGrey, tealSet, yellowSet } from '@nuna/tunic'

import { videoSceneTeal } from '../../../util/colors'

interface TopbarToggleButtonProps extends HTMLAttributes<HTMLButtonElement> {
  active: boolean
  showAlert?: boolean
}

export const TopbarToggleButton = forwardRef<HTMLButtonElement, TopbarToggleButtonProps>(
  ({ active, children, showAlert = false, ...props }, ref) => {
    return (
      <Button {...props} active={active} ref={ref}>
        <IconAlertWrapper>
          {children}
          {showAlert && <AlertDot active={active} />}
        </IconAlertWrapper>
      </Button>
    )
  },
)

const Button = styled('button')<{ active: boolean }>`
  align-items: center;
  background-color: ${props => (props.active ? videoSceneTeal : 'transparent')};
  border-radius: 6px;
  color: ${props => (props.active ? body1 : borderGrey)};
  display: flex;
  height: 40px;
  justify-content: center;
  width: 40px;
  ${props =>
    !props.active &&
    `
      &:hover {
        color: #fff;
      }
    `}
`

const IconAlertWrapper = styled('span')`
  position: relative;

  svg {
    vertical-align: middle;
  }
`

const AlertDot = styled('span')<{ active: boolean }>`
  background-color: ${yellowSet[50].hex};
  border: 2px solid ${props => (props.active ? videoSceneTeal : tealSet[90].hex)};
  border-radius: 50%;
  width: 14px;
  height: 14px;
  position: absolute;
  top: -4px;
  right: -4px;
`
