import { IconProps } from './IconProps'

export function IconMailDeprecated({ size = 24, color = 'currentColor', ...props }: IconProps) {
  return (
    <svg width={size} height={size} viewBox="0 0 24 24" fill={color} xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.85239 5.75C3.96023 5.75 3.25 6.50716 3.25 7.41928V16.8151C3.25 17.7272 3.96023 18.4633 4.85239 18.4633H19.5293C20.4214 18.4633 21.1667 17.7272 21.1667 16.8151V7.41928C21.1632 6.50716 20.4214 5.75 19.5258 5.75H4.85239ZM12.378 13.8076C12.2836 13.9133 12.0771 13.9238 11.9652 13.797L5.45066 7.10585L18.8995 7.10937L12.378 13.8076ZM4.54451 8.09896L8.30907 11.9728L4.54451 15.822V8.09896ZM9.22922 12.9131L11.0065 14.7444C11.5523 15.3466 12.6859 15.3853 13.3122 14.7514L15.121 12.892L19.2564 17.1603H5.0973L9.22922 12.9131ZM16.0446 11.9235L19.8337 8.02853V15.822L16.0446 11.9235Z"
      />
    </svg>
  )
}
