import { styled } from '@mui/material'
import { HTMLAttributes } from 'react'

import { Card, CardBody, IconCalendar, IconCategory, IconDollarSymbol, IconReports } from '@nuna/tunic'

export function EnrollmentBenefits(props: HTMLAttributes<HTMLDivElement>) {
  return (
    <Card {...props} depth={0}>
      <CardBody className="p-3">
        <IconLine>
          <IconCalendar size={32} />
          <span>
            Start seeing insurance clients in <strong>7 business days</strong> (vs. 90 day standard).
          </span>
        </IconLine>

        <IconLine>
          <IconReports size={32} />
          <span>
            Market to <strong>4 million</strong> insured clients from <strong>Aetna</strong>,{' '}
            <strong>Select Health</strong> and <strong>500 regional carriers</strong> in your area.
          </span>
        </IconLine>

        <IconLine>
          <IconCategory size={30} />
          <span>
            Grow your caseload with referrals from <strong>Zocdoc</strong>, <strong>Psychology Today</strong>, and{' '}
            <strong>insurance directories</strong>.
          </span>
        </IconLine>

        <IconLine>
          <IconDollarSymbol size={24} />
          <span>
            Get paid <strong>3 times faster</strong> (bi-weekly) than typical insurance reimbursements.
          </span>
        </IconLine>
      </CardBody>
    </Card>
  )
}

const IconLine = styled('div')`
  align-items: center;
  display: grid;
  grid-template-columns: 32px 1fr;
  margin-top: var(--spacing-3);

  > svg {
    justify-self: center;
  }

  > span {
    margin-left: var(--spacing-2);
  }

  &:first-child {
    margin-top: 0;
  }
`
