import { isNil } from 'lodash'
import moment from 'moment-timezone'
import { useEffect, useState } from 'react'

import { AppointmentCurrentStatus, useProviderNotificationsQuery } from '@nuna/api'

import { useProviderAppContext } from '../ProviderAppContext'

const HOURLY = 60 * 60 * 1000
const START = moment('1/1/2020', 'M/D/YYYY')

export function useProviderNotifications() {
  const { provider } = useProviderAppContext()
  const [endDate, setEndDate] = useState(moment().subtract(72, 'hours'))

  useEffect(() => {
    const interval = setInterval(() => {
      setEndDate(moment().subtract(72, 'hours'))
    }, HOURLY)

    return () => {
      clearInterval(interval)
    }
  }, [])

  return useProviderNotificationsQuery({
    skip: isNil(provider?.id),
    variables: {
      providerId: provider?.id ?? '',
      options: {
        start: START,
        end: endDate,
        statusIsIn: [AppointmentCurrentStatus.Active],
        hasUncompletedSessionNote: true,
      },
    },
  })
}
