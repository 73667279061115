import { HTMLAttributes, useState } from 'react'

import { CancelPolicyStatusFragment, ProviderDetailsQuery, type PublicAvailabilitySlotFragment } from '@nuna/api'
import { appointmentService, numberService } from '@nuna/core'
import { LateCancelationPolicyModal } from '@nuna/fee-policies'
import { ContextualAlert, IconInfo, TextButton } from '@nuna/tunic'

import { AppointmentDrawerPaddedContainer } from '../../../AppointmentDrawer'

interface CancelPolicyAlertProps extends HTMLAttributes<HTMLDivElement> {
  provider: ProviderDetailsQuery['provider']
  timeSlot: PublicAvailabilitySlotFragment
  cancelPolicyStatus: CancelPolicyStatusFragment
}

export function CancelPolicyAlert({ provider, timeSlot, cancelPolicyStatus, ...props }: CancelPolicyAlertProps) {
  const [cancelPolicyOpen, setCancelPolicyOpen] = useState(false)

  const cancelPolicy = cancelPolicyStatus.cancelPolicy

  if (!cancelPolicy) {
    return null
  }

  if (
    appointmentService.subjectToCancelationFee({
      policyAcceptedDate: cancelPolicyStatus.acceptedDate,
      appointmentStartDatetime: timeSlot.start,
      cancelPeriodMinutes: cancelPolicy.cancelPeriodMinutes,
    })
  )
    return (
      <>
        <AppointmentDrawerPaddedContainer {...props}>
          <ContextualAlert icon={<IconInfo />}>
            FYI, This session begins in less than{' '}
            {numberService.minutesToFormattedHours(cancelPolicy.cancelPeriodMinutes)} and will be subject to{' '}
            {provider.firstName}’s late{' '}
            <TextButton variant="secondary" onClick={() => setCancelPolicyOpen(true)}>
              cancelation policy
            </TextButton>{' '}
            should you choose to cancel.{' '}
          </ContextualAlert>
        </AppointmentDrawerPaddedContainer>

        <LateCancelationPolicyModal
          coverageType={cancelPolicyStatus.paymentPreference}
          provider={provider}
          isOpen={cancelPolicyOpen}
          onClose={() => setCancelPolicyOpen(false)}
        />
      </>
    )

  return null
}
