import { Dispatch, ReactNode, SetStateAction, createContext, useContext, useState } from 'react'

import { AppointmentForVideoQuery, useAppointmentForVideoQuery } from '@nuna/api'

import { TavaVideoUser } from '../types'
import { AutonotesContextValues, useAutonotesContext } from './hooks/useAutonotesContext'
import { EndSessionContextValues, useEndSessionContext } from './hooks/useEndSessionContext'
import { useMeetingSessionLocalStorageSync } from './hooks/useMeetingSessionLocalStorageSync'
import { OnlineUsersContextValues, useOnlineUsersContext } from './hooks/useOnlineUsersContext'
import { SidebarContextValues, useSidebarContext } from './hooks/useSidebarContext'

interface ContextValues
  extends AutonotesContextValues,
    EndSessionContextValues,
    OnlineUsersContextValues,
    SidebarContextValues {
  appointment: AppointmentForVideoQuery['internalAppointment'] | undefined
  appointmentId: string
  expectedUsers: TavaVideoUser[]
  localUser: TavaVideoUser
  isCameraStarted: boolean
  setIsCameraStarted: Dispatch<SetStateAction<boolean>>
  isMobileFullscreen: boolean
  setIsMobileFullscreen: Dispatch<SetStateAction<boolean>>
  roomUrl: string
}

const VideoCallContext = createContext<ContextValues | null>(null)

interface Props {
  appointmentId: string
  children: ReactNode
  expectedUsers: TavaVideoUser[]
  localUser: TavaVideoUser
  roomUrl: string
}

// This context should be used to provide Tava data to the call.
// State about the video call itself should use Daily's hooks and context and not be duplicated in here.
export function VideoCallContextProvider({ appointmentId, localUser, expectedUsers, roomUrl, ...props }: Props) {
  useMeetingSessionLocalStorageSync(appointmentId)
  const { data } = useAppointmentForVideoQuery({ variables: { appointmentId } })
  const onlineUsersContext = useOnlineUsersContext()
  const sidebarContext = useSidebarContext()
  const endSessionContext = useEndSessionContext(localUser)
  const autonotesContext = useAutonotesContext({ localUser, expectedUsers })
  const [isCameraStarted, setIsCameraStarted] = useState(false) // there may be a better way to do this but I couldn't find anything reliable enough in Daily's API for now
  const [isMobileFullscreen, setIsMobileFullscreen] = useState(false)

  return (
    <VideoCallContext.Provider
      {...props}
      value={{
        ...autonotesContext,
        ...endSessionContext,
        ...onlineUsersContext,
        ...sidebarContext,
        appointment: data?.internalAppointment,
        appointmentId,
        expectedUsers,
        isCameraStarted,
        isMobileFullscreen: endSessionContext.isSessionEnded ? false : isMobileFullscreen,
        localUser,
        roomUrl,
        setIsCameraStarted,
        setIsMobileFullscreen,
      }}
    />
  )
}

export function useVideoCallContext() {
  const context = useContext(VideoCallContext)
  if (!context) {
    throw new Error('useVideoCallContext must be used within a VideoCallContextProvider')
  }
  return context
}
