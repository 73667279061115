import * as Yup from 'yup'
import { ApolloError, useApolloClient } from '@apollo/client'
import { styled } from '@mui/material'
import { Formik, FormikHelpers } from 'formik'
import qs from 'qs'
import { useState } from 'react'
import { useMediaQuery } from 'react-responsive'
import { useLocation, useNavigate } from 'react-router-dom'

import { useResetPasswordMutation } from '@nuna/api'
import { type Persona } from '@nuna/core'
import { BelowTablet, ContextualAlert, FillButton, TextField } from '@nuna/tunic'

import { PasswordValidator } from './PasswordValidator'
import { QuoteContentWrapper } from './QuoteContentWrapper'

interface Props {
  audience: Persona
}

interface FormValues {
  password: string
  confirmPassword: string
}

const ConfirmPasswordSchema = Yup.object().shape({
  password: Yup.string()
    .matches(/^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?([^\w\s]|[_])).{8,}$/, 'Does not meet password requirements')
    .required('Password is required'),
  confirmPassword: Yup.string()
    .oneOf([Yup.ref('password'), null], 'Passwords must match')
    .required('Confirm Password is required'),
})

export function ResetPasswordScreen({ audience }: Props) {
  const location = useLocation()
  const navigate = useNavigate()
  const isMobile = useMediaQuery({ query: `(${BelowTablet})` })
  const [isPasswordFocused, setIsPasswordFocused] = useState(false)
  const [isConfirmPasswordFocused, setIsConfirmPasswordFocused] = useState(false)
  const [resetPasswordMutation] = useResetPasswordMutation()
  const [submissionSuccessful, setSubmissionSuccessful] = useState(false)
  const [resetPasswordError, setResetPasswordError] = useState('')

  const client = useApolloClient()

  const handleSubmit = async (
    { password }: FormValues,
    { setSubmitting }: FormikHelpers<FormValues>,
  ): Promise<void> => {
    setResetPasswordError('')
    // Ensure cache is clear so we run against the latest data
    client.clearStore()

    const queryParams = qs.parse(location.search, { ignoreQueryPrefix: true })

    try {
      await resetPasswordMutation({
        variables: {
          token: queryParams.token as string,
          password,
        },
      })

      setTimeout(() => {
        setSubmissionSuccessful(true)

        setTimeout(() => {
          navigate('/login')
        }, 2500)
      }, 250)
    } catch (error) {
      if (error instanceof ApolloError) {
        setResetPasswordError(error.graphQLErrors[0] ? error.graphQLErrors[0].message : 'Error')
      }
    }

    setSubmitting(false)
  }

  return (
    <QuoteContentWrapper audience={audience}>
      <Container>
        {submissionSuccessful ? (
          <p className="large mb-0">Password confirmed. Taking you to login...</p>
        ) : (
          <>
            <h1 className={isMobile ? 'h3' : 'h2'}>Reset password</h1>
            <Formik
              initialValues={{ password: '', confirmPassword: '' }}
              validationSchema={ConfirmPasswordSchema}
              onSubmit={handleSubmit}
            >
              {({ values, errors, touched, handleBlur, handleSubmit, handleChange, isSubmitting, isValid }) => (
                <form onSubmit={handleSubmit}>
                  <section className="mb-1">
                    <TextField
                      className="mb-3"
                      label="Password"
                      type="password"
                      name="password"
                      onFocus={() => setIsPasswordFocused(true)}
                      onChange={handleChange}
                      onBlur={e => {
                        setIsPasswordFocused(false)
                        handleBlur(e)
                      }}
                      value={values.password}
                      error={(errors.password && touched.password) as boolean}
                      helperText={touched.password && errors.password}
                    />

                    <PasswordValidator
                      style={{ display: 'inline-block' }}
                      password={values.password}
                      isExpanded={isPasswordFocused}
                      error={(errors.password && touched.password) as boolean}
                      wrapperPadding="0 0 16px 0"
                    />
                  </section>

                  <section className="mb-2">
                    <TextField
                      className="mb-3"
                      label="Confirm Password"
                      type="password"
                      name="confirmPassword"
                      onChange={handleChange}
                      onFocus={() => setIsConfirmPasswordFocused(true)}
                      onBlur={e => {
                        setIsConfirmPasswordFocused(false)
                        handleBlur(e)
                      }}
                      value={values.confirmPassword}
                      error={(errors.confirmPassword && touched.confirmPassword) as boolean}
                      helperText={touched.confirmPassword && errors.confirmPassword}
                    />

                    <PasswordValidator
                      password={values.confirmPassword}
                      style={{ display: 'inline-block' }}
                      successMessage="Oh yeah, it's a match"
                      isExpanded={isConfirmPasswordFocused}
                      confirm
                      error={(errors.confirmPassword && touched.confirmPassword) as boolean}
                      matchingPassword={values.password}
                      wrapperPadding="0 0 16px 0"
                    />
                  </section>

                  <FillButton type="submit" disabled={!isValid || isSubmitting}>
                    Update Password
                  </FillButton>

                  {resetPasswordError ? (
                    <ContextualAlert className="mt-2" intent="urgent">
                      {resetPasswordError}
                    </ContextualAlert>
                  ) : null}
                </form>
              )}
            </Formik>
          </>
        )}
      </Container>
    </QuoteContentWrapper>
  )
}

const Container = styled('div')`
  background-color: white;
  width: 486px;
`
