import { styled } from '@mui/material'
import {
  FormControl,
  FormHelperText,
  InputLabel,
  Select as MUISelect,
  SelectProps as MUISelectProps,
} from '@mui/material'
import React, { ReactNode, useState } from 'react'

import { IconClock, body2, interactiveFill } from '@nuna/tunic'

type SelectProps = Omit<MUISelectProps, 'variant'> & {
  containerProps?: React.HTMLProps<HTMLDivElement>
  error?: boolean
  helperText?: string | ReactNode
}

export function AppointmentTimeSelect({
  helperText,
  containerProps = {},
  error = false,
  label = 'Start Time (50 min session)',
  ...props
}: SelectProps) {
  const [isFocused, setIsFocused] = useState(false)
  const [isOpen, setIsOpen] = useState(false)

  return (
    <div {...containerProps}>
      <IconClock color={isFocused || isOpen ? interactiveFill : body2} className="mr-1 mt-2" />

      <FormControlStyled fullWidth $isFocused={isFocused || isOpen} error={error}>
        <InputLabel>{label}</InputLabel>
        <MUISelect
          onOpen={() => setIsOpen(true)}
          onClose={() => setIsOpen(false)}
          onFocus={() => setIsFocused(true)}
          onBlur={() => setIsFocused(false)}
          {...props}
        />

        {helperText && <FormHelperText>{helperText}</FormHelperText>}
      </FormControlStyled>
    </div>
  )
}

const FormControlStyled = styled(FormControl)<{ $isFocused: boolean }>`
  ${({ $isFocused }) =>
    $isFocused &&
    `
  .MuiInput-underline {
    &::after {
      border-bottom-color: ${interactiveFill};
      transform: scaleX(1);
    }
  }
`}
`
