import { styled } from '@mui/material'
import moment from 'moment-timezone'

import { FAQItem, Illustration } from '@nuna/common'
import { Box, FillButton, Grid, IconArrow, List, ListItem, SignupLayout, TextButton, greySet } from '@nuna/tunic'

import { useProviderSignupIntakeContext } from '../ProviderSignupIntakeContext'
import ProviderSessionSrc from '../img/ProviderSession.png'
import { IntakeHeader } from '../shared/IntakeTypography'
import { ProviderSignupIntakeFormProps, SaveProviderIntakeInput } from '../shared/provider-signup-intake-types'
import { useProviderSignupIntakeStepSetup } from '../shared/useProviderSignupIntakeStepSetup'
import { getIntakeFinishFAQs } from './finishFAQs'

export function IntakeFinish({ values, setFieldValue, validateForm, handleSubmit }: ProviderSignupIntakeFormProps) {
  const { guide } = useProviderSignupIntakeContext()
  useProviderSignupIntakeStepSetup({ validationSchema: null, buildSaveValues }, validateForm)
  const today = moment()

  const startDate = today < moment(values.expectedStartDate) ? moment(values.expectedStartDate) : nextWeekday(today)

  const handleButtonClick = (isDashboard?: boolean) => {
    isDashboard && setFieldValue('navigationPath', '/schedule')
    handleSubmit()
  }

  return (
    <>
      <Grid container direction="row-reverse" alignItems="center" spacing={4}>
        <Grid
          size={{
            xs: 12,
            sm: 4,
          }}
        >
          <Box display="flex" justifyContent={{ xs: 'center', sm: 'start' }}>
            <Illustration src={ProviderSessionSrc} alt="provider session" />
          </Box>
        </Grid>
        <Grid
          size={{
            xs: 12,
            sm: 8,
          }}
        >
          <Box textAlign={{ xs: 'center', sm: 'start' }}>
            <IntakeHeader type="h1">You're All Set to Begin Insurance Enrollment</IntakeHeader>
          </Box>

          <p className="text-secondary italic caption">
            * Your account may take a few days to verify, but we'll do our best to have you up and running by{' '}
            {startDate.format('MMM Do')}. We'll email you when your profile is published in our listing.
          </p>
        </Grid>
      </Grid>
      <IntakeHeader type="h2" xs={{ mt: 3 }} md={{ mt: 3 }}>
        What happens now?
      </IntakeHeader>
      <WhatHappensList className="text-secondary">
        <ListItem icon={<IconArrow size={14} />}>Our clinical team will review and validate your information.</ListItem>

        <ListItem icon={<IconArrow size={14} />}>
          In the meantime, you can start getting enrolled in insurance networks.
        </ListItem>
      </WhatHappensList>
      <Box display={{ xs: 'flex', sm: 'block' }} flexDirection="column" alignItems="center">
        <FillButton
          onClick={() => handleButtonClick()}
          className="mt-4 mobile-fullwidth"
          data-testid="start-using-tava-button"
        >
          Begin Insurance Enrollment
        </FillButton>

        <TextButton className="ml-2 mt-2" onClick={() => handleButtonClick(true)} variant="secondary">
          Go to Dashboard
        </TextButton>
      </Box>
      <IntakeHeader type="h2" md={{ mt: 6 }} xs={{ mt: 5 }}>
        Other Common Questions
      </IntakeHeader>
      <SignupLayout.FAQContainer>
        {getIntakeFinishFAQs(guide.firstName).map(faq => (
          <FAQItem
            className="text-secondary"
            style={{ paddingLeft: 16 }}
            question={faq.question}
            answer={faq.answer}
            key={faq.question}
          />
        ))}
      </SignupLayout.FAQContainer>
    </>
  )
}

const WhatHappensList = styled(List)`
  list-style: none;
  padding-left: 4px;
  svg {
    color: ${greySet[30].hex};
  }
`

function buildSaveValues(): SaveProviderIntakeInput {
  return { intakeCompleted: true }
}

function nextWeekday(date: moment.Moment) {
  const futureDate = date.clone().add(2, 'day')

  if (futureDate.isoWeekday() < 6) {
    return futureDate
  }

  return date.clone().add(1, 'week').startOf('week').add(1, 'day')
}
