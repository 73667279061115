import * as Yup from 'yup'
import { styled } from '@mui/material'
import { Formik } from 'formik'
import { isNil, noop, omit } from 'lodash'
import moment from 'moment'
import { useEffect, useState } from 'react'
import { useMediaQuery } from 'react-responsive'
import { useNavigate } from 'react-router-dom'

import {
  AddressType,
  ProviderDataField,
  ProviderIntakeFragment,
  useProviderSignupIntakeQuery,
  useSaveProviderIntakeMutation,
} from '@nuna/api'
import { Illustration, Layout, LayoutHeader, ProviderSignupAddress } from '@nuna/common'
import { InvalidZipCodeError, addressService, formService } from '@nuna/core'
import { supportService } from '@nuna/telemetry'
import {
  BelowTablet,
  Card,
  Checkbox,
  DOBTextField,
  Dialog,
  DialogContent,
  FillButton,
  GhostButtonLink,
  IconShield,
  Logo,
  StatusLabel,
  TextField,
  greySet,
  plumSet,
  ssnRegExp,
  toast,
} from '@nuna/tunic'

import { SignupIntakeNavMenu } from '../../../layouts/IntakeLayout/SignupIntakeHeader/SignupIntakeNavMenu'
import { useProviderAppContext } from '../../../shared/ProviderAppContext'
import { ProviderSignupIntakeContextProvider } from '../../SignupIntake/ProviderSignupIntakeContext'
import backgroundCheckImg from '../../SignupIntake/img/background-check.png'
import { ProviderIntakeFormStepper } from '../../SignupIntake/shared/ProviderIntakeFormStepper'
import { buildInitialAddress } from '../../SignupIntake/signup-intake-utils'
import { BackgroundCheckDisclosures } from './BackgroundCheckDisclosures'

const { getStateByZip } = addressService
const { composeHelperTextWithError } = formService

const validationSchema = Yup.object({
  dob: Yup.date().required('Date of Birth is required').typeError('Date is invalid'),
  ssn: Yup.string()
    .required('Social Security Number is required')
    .typeError('Social Security Number is required')
    .matches(ssnRegExp, 'Please enter a valid Social Security Number'),
  address: Yup.object<ProviderSignupAddress>({
    addressLineOne: Yup.string(),
    addressLineTwo: Yup.string().nullable(),
    zipCode: Yup.string()
      .required('Zip Code is required')
      .test('is-valid', 'Enter a valid U.S. zip code', function () {
        return this.parent.zipCodeValid
      }),
    zipCodeValid: Yup.boolean(),
    state: Yup.string(),
    city: Yup.string(),
    name: Yup.string(),
    addressType: Yup.mixed<AddressType>().oneOf(Object.values(AddressType)),
    addressId: Yup.string(),
  }),
  // used to prevent the form from submitting on enter - it's 100% a hack
  backgroundCheckDisclosuresAgreement: Yup.boolean().required(),
})

type FormValues = {
  dob: Date | null
  address: ProviderSignupAddress
  receiveBackgroundCheckCopy: boolean
  ssn: string
  backgroundCheckDisclosuresAgreement: boolean
}

export function BackgroundCheck() {
  const [backgroundCheckInitiated, setBackgroundCheckInitiated] = useState<undefined | boolean>()
  const [submittingBackgroundCheck, setSubmittingBackgroundCheck] = useState<undefined | boolean>()
  const [saveIntakeMutation, { loading: saveIntakeLoading }] = useSaveProviderIntakeMutation()
  const isMobile = useMediaQuery({ query: `(${BelowTablet})` })
  const [disclosuresDialogOpen, setDisclosuresDialogOpen] = useState(false)
  const { refetchStatus, providerDataSummaryItems, getSummaryItemByField, provider } = useProviderAppContext()
  const {
    data: providerIntakeData,
    called,
    loading,
  } = useProviderSignupIntakeQuery({
    skip: !provider?.id,
    variables: { id: provider?.id ?? '' },
  })

  const navigate = useNavigate()

  const [noZipCode] = useState(called && !loading && !providerIntakeData?.providerIntake?.address?.zipCode)
  const [noDob] = useState(called && !loading && !providerIntakeData?.providerIntake?.dob)

  useEffect(() => {
    if (providerDataSummaryItems.length) {
      setBackgroundCheckInitiated(previousValue =>
        // It's not possible to uninitiate a background check so returning the previousValue when it
        // is true prevents the state from reverting
        previousValue ? previousValue : !!getSummaryItemByField(ProviderDataField.RequestedBackgroundCheck).hasValue,
      )
    }
  }, [providerDataSummaryItems, getSummaryItemByField])

  const handleAgreed = async (values: FormValues, providerIntakeData: ProviderIntakeFragment | undefined) => {
    if (!submittingBackgroundCheck && provider?.id) {
      try {
        setSubmittingBackgroundCheck(true)

        const dob = values.dob
        const saved = await saveIntakeMutation({
          variables: {
            id: provider.id,
            triggerBackgroundCheck: true,
            zipCode: providerIntakeData?.address?.zipCode ?? values.address.zipCode,
            receiveBackgroundCheckCopy: values.receiveBackgroundCheckCopy,
            dob: dob ? moment.utc(dob).startOf('day').toISOString() : '',
            ssn: values.ssn,
          },
        })

        if (saved) {
          setBackgroundCheckInitiated(true)
          refetchStatus()
        }
      } catch (e) {
        setSubmittingBackgroundCheck(false)

        console.error(e)
        toast.urgent(
          `Failed to start background check. Verify personal information and contact ${supportService.supportEmails.providerSupport}.`,
        )
      }
    }

    setDisclosuresDialogOpen(false)
  }

  const initialValues = {
    receiveBackgroundCheckCopy: false,
    dob: providerIntakeData?.providerIntake.dob ? new Date(providerIntakeData.providerIntake.dob) : null,
    ssn: '',
    backgroundCheckDisclosuresAgreement: false,
    address: buildInitialAddress(providerIntakeData?.providerIntake.address),
  }

  if (isNil(backgroundCheckInitiated) || !providerIntakeData) {
    return null
  }

  return (
    <Layout
      header={
        <LayoutHeader
          center={<Logo color="plum" scheme="dark" height={32} renderText />}
          right={
            <GhostButtonLink variant="secondary" scheme="dark" to="/">
              Exit
            </GhostButtonLink>
          }
          backgroundColor={plumSet[90].hex}
          left={
            <span className="h6 v-align m-0 text-white">
              <ProviderSignupIntakeContextProvider>
                <SignupIntakeNavMenu onSaveAndNavigate={(to: string) => navigate(to)} />
              </ProviderSignupIntakeContextProvider>
              Background Check
            </span>
          }
          hasProgressBar
          now={0}
        />
      }
    >
      <Wrapper>
        <Formik initialValues={initialValues} onSubmit={noop} validationSchema={validationSchema}>
          {({ values, errors, touched, handleBlur, getFieldProps, setFieldValue, validateForm, isValid }) => {
            return (
              <>
                <h3>Background Check</h3>
                <div className="text-secondary">
                  <p className="large text-medium">
                    In order to receive referrals and to enroll in insurance, a background check is needed.
                  </p>
                  <p className="large mb-4">
                    The background check is facilitated by a third party company called Checkr* and will look up the
                    following information:
                  </p>
                  <InfoList>
                    <li>County Criminal Search (Current)</li>
                    <li>National Criminal Search (Standard)</li>
                    <li>Sex Offender Search</li>
                    <li>SSN Trace</li>
                    <li>Global Watchlist Search</li>
                  </InfoList>
                </div>

                <h4 className="mt-6">We'll need your social security number</h4>

                {!backgroundCheckInitiated ? (
                  <StyledCard className="p-3">
                    <StatusLabel intent="default" scheme="light" className="mb-2">
                      <div className="vh-align">
                        <IconShield size={12} className="mr-1" /> Your data is securely protected
                      </div>
                    </StatusLabel>
                    {noDob && (
                      <div className="mb-3">
                        <DOBTextField
                          label="Date of Birth"
                          className="input"
                          placeholder="MM/DD/YYYY"
                          inputProps={{ inputmode: 'numeric' }}
                          fullWidth
                          {...getFieldProps('dob')}
                          {...composeHelperTextWithError('', errors.dob, !!touched.dob)}
                        />
                      </div>
                    )}
                    {noZipCode && (
                      <TextField
                        className="input mb-3"
                        label="Zip Code"
                        inputProps={{ inputMode: 'numeric', 'data-testid': 'background-check-zip' }}
                        {...getFieldProps('address.zipCode')}
                        {...composeHelperTextWithError('', errors.address?.zipCode, !!touched.address?.zipCode)}
                        onBlur={async e => {
                          handleBlur(e)

                          if (!e.currentTarget.value) {
                            return
                          }

                          try {
                            const state = await getStateByZip(e.currentTarget.value)
                            setFieldValue('address.state', state)
                            setFieldValue('address.zipCodeValid', true)
                          } catch (e) {
                            if (e instanceof InvalidZipCodeError) {
                              setFieldValue('address.zipCodeValid', false)
                            }
                          }
                        }}
                      />
                    )}
                    <TextField
                      className="input"
                      label="Social Security Number"
                      type="ssn"
                      inputProps={{ inputMode: 'numeric', 'data-testid': 'ssn-input' }}
                      {...getFieldProps('ssn')}
                      {...composeHelperTextWithError('', errors.ssn, touched.ssn)}
                    />
                    <Checkbox {...getFieldProps('receiveBackgroundCheckCopy')} className="mt-4">
                      I'd like to receive a copy of my background check (consumer report) when it is completed
                    </Checkbox>
                    <FillButton
                      type="button"
                      className="mt-4 mobile-fullwidth"
                      data-testid="disclosures-button"
                      onClick={async () => {
                        const formErrors = await validateForm(values)
                        if (Object.keys(omit(formErrors, ['backgroundCheckDisclosuresAgreement'])).length === 0) {
                          setDisclosuresDialogOpen(true)
                        }
                      }}
                    >
                      Review Disclosures
                    </FillButton>
                    <div className="caption text-secondary italic mt-1">
                      You'll receive an email from Checkr once the background check has been initiated.
                    </div>
                  </StyledCard>
                ) : (
                  <AllDoneCard depth={-1} className="p-4">
                    <div className="vh-align flex-column card-body">
                      <Illustration src={backgroundCheckImg} alt="Background check complete" />
                      <h4 className="text-default">Your check is running, all done here.</h4>
                      <p>
                        You'll receive an email from Checkr when your background check is completed. Most checks are
                        completed a within few hours
                      </p>
                    </div>
                  </AllDoneCard>
                )}
                <ButtonWrapper>
                  <ProviderIntakeFormStepper
                    className="mt-6"
                    mutationLoading={saveIntakeLoading}
                    // step="background-check"
                    type="navigation"
                    forwardDisabled={!backgroundCheckInitiated}
                    isValid={isValid}
                    currentSection="background"
                  />
                </ButtonWrapper>

                <Dialog
                  isOpen={disclosuresDialogOpen}
                  onClose={() => setDisclosuresDialogOpen(false)}
                  style={{ width: '100%' }}
                  maxWidth="lg"
                  fullScreen={isMobile}
                  PaperProps={{ style: { width: '100%', height: isMobile ? undefined : '86%' } }}
                >
                  <DialogContent
                    style={{ padding: 0, maxHeight: '100vh', overflow: 'hidden', height: '100%' }}
                    className="flex-column"
                  >
                    <BackgroundCheckDisclosures
                      onClose={() => setDisclosuresDialogOpen(false)}
                      onAgreed={() => handleAgreed(values, providerIntakeData?.providerIntake)}
                      isCalifornia={values.address.state === 'CA'}
                    ></BackgroundCheckDisclosures>
                  </DialogContent>
                </Dialog>
                <FooterContent>
                  <span className="mr-1">*</span>
                  <span>
                    Tava Health, Inc. has engaged Checkr, Ink. to obtain a consumer report and/or investigative consumer
                    report, as defined in California, for employment purposes, contracting purposes, or other
                    permissible purposes. Checkr Inc. will provide a background investigation as a pre-condition of your
                    engagement with the Company and in compliance with applicable law. After you have completed the
                    form, you can check the status of your background check her or on the Checkr Candidate Portal.
                  </span>
                </FooterContent>
              </>
            )
          }}
        </Formik>
      </Wrapper>
    </Layout>
  )
}

const InfoList = styled('ul')`
  padding-left: var(--margin-3);
  li {
    margin-bottom: var(--margin-1);
  }
`

const FooterContent = styled('div')`
  color: ${greySet[50].hex};
  display: flex;
  padding: var(--margin-2) 0;
`

const StyledCard = styled(Card)`
  .input {
    max-width: 400px;
  }
`

const AllDoneCard = styled(Card)`
  text-align: center;

  .card-body {
    max-width: 630px;
    margin: 0 auto;
  }
`

const ButtonWrapper = styled('div')`
  display: flex;
  justify-content: space-between;
  margin-top: var(--margin-3);
`

const Wrapper = styled('div')`
  max-width: 932px;
  margin-left: auto;
  margin-right: auto;
  padding-left: var(--margin-3);
  padding-right: var(--margin-3);
  flex: 1;
`
