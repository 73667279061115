import { FunctionComponent, ReactNode } from 'react'
import { Navigate, Route, Routes, useParams } from 'react-router-dom'

import { usePatientQuery } from '@nuna/api'
import { AssessmentsDashboard } from '@nuna/assessments'
import { PatientSubRoute, routeService } from '@nuna/core'
import {
  IconButtonLink,
  IconMail,
  MenuItem,
  PageContent,
  PageHeader,
  PageHeading,
  PageWrapper,
  ResponsiveHorizontalMenu,
} from '@nuna/tunic'

import { ClientProfile } from '../ClientProfile'
import { ClientSessions } from '../ClientSessions'
import { ClientDetailHeader } from './components/ClientDetailHeader'

const { conversationParticipantRoute } = routeService

interface ClientPageProps {
  patientId: string
}

export interface AdditionalClientPage {
  navText: string
  path: string
  ClientPage: FunctionComponent<ClientPageProps>
}

interface Props {
  additionalPages?: AdditionalClientPage[]
  headerRight?: ReactNode
}

export function ClientDetail({ additionalPages = [], headerRight }: Props) {
  const { id = '' } = useParams<{ id: string }>()
  const { data } = usePatientQuery({
    variables: {
      id,
    },
  })

  return (
    <PageWrapper>
      <PageHeader>
        <div className="v-align flex-remaining-space" style={{ overflow: 'hidden' }}>
          <PageHeading withDivider className="mb-0 mr-2 v-align">
            <ClientDetailHeader className="centered-header-left" />
          </PageHeading>
          <ResponsiveHorizontalMenu className="flex-remaining-space mr-2">
            <MenuItem to={PatientSubRoute.profile} id="patient-profile-link">
              Profile
            </MenuItem>
            <MenuItem to={PatientSubRoute.sessions} id="patient-session-activity-link">
              Session Activity
            </MenuItem>
            <MenuItem to={PatientSubRoute.assessments}>Assessments</MenuItem>
            {additionalPages.map(page => (
              <MenuItem key={page.path} to={page.path}>
                {page.navText}
              </MenuItem>
            ))}
          </ResponsiveHorizontalMenu>
        </div>

        <div className="ml-auto v-align">
          {headerRight}
          <IconButtonLink
            variant="secondary"
            tooltip="Message client"
            to={conversationParticipantRoute(data?.patient.login.id ?? '')}
          >
            <IconMail size={20} />
          </IconButtonLink>

          {/* // TODO: Will add as follow-up. Eng-978 */}
          {/* <OutlineButton>Schedule</OutlineButton> */}
        </div>
      </PageHeader>

      <PageContent id="patient-detail-content">
        <Routes>
          <Route index element={<Navigate to={PatientSubRoute.profile} replace />} />
          <Route path={PatientSubRoute.profile} element={<ClientProfile />} />
          <Route path={PatientSubRoute.sessions} element={<ClientSessions />} />
          <Route path={PatientSubRoute.assessments} element={<AssessmentsDashboard />} />
          {additionalPages.map(page => {
            const { ClientPage } = page
            return <Route key={page.path} path={page.path} element={<ClientPage patientId={id} />} />
          })}

          <Route path="info" element={<Navigate to={PatientSubRoute.profile} />} />
          <Route path="account" element={<Navigate to={PatientSubRoute.profile} />} />
          <Route path="upcoming" element={<Navigate to={PatientSubRoute.sessions} />} />
          <Route path="history" element={<Navigate to={PatientSubRoute.sessions} />} />
        </Routes>
      </PageContent>
    </PageWrapper>
  )
}
