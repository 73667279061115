import { styled } from '@mui/material'
import { FormHelperText } from '@mui/material'
import React, { Fragment, ReactNode } from 'react'

import { AppointmentChangeReason } from '@nuna/api'
import { Radio, StatusLabel, body2 } from '@nuna/tunic'

interface AppointmentChangeReasonRadioGroupProps extends React.HTMLAttributes<HTMLDivElement> {
  onChange: React.ChangeEventHandler<HTMLInputElement>
  name: string
  checked: (value: string) => boolean
  error?: boolean
  helperText?: string | ReactNode
}

export function AppointmentChangeReasonRadioGroup({
  checked,
  name,
  onChange,
  error = false,
  helperText = '',
  children,
  ...props
}: AppointmentChangeReasonRadioGroupProps) {
  return (
    <RadioGroup role="group" {...props}>
      {[
        ['I had a scheduling conflict', AppointmentChangeReason.SchedulingConflict],
        [`I wasn't feeling well`, AppointmentChangeReason.NotFeelingWell],
        [`Canceling at the client's request`, AppointmentChangeReason.RequestedByPatient],
        ['Something else', AppointmentChangeReason.FreeForm],
      ].map(([label, value]) => (
        <Fragment key={value}>
          <Radio error={error} name={name} value={value} checked={checked(value)} onChange={onChange}>
            {label}
            {label === 'Requested by client' && (
              <StatusLabel style={{ marginLeft: 4 }} intent="default" scheme="light">
                PAYOUT 50%
              </StatusLabel>
            )}
          </Radio>
          {checked(value) && children}
        </Fragment>
      ))}

      {helperText && <FormHelperText error={error}>{helperText}</FormHelperText>}
    </RadioGroup>
  )
}

const RadioGroup = styled('div')`
  && {
    & > div {
      color: ${body2};
      margin-bottom: 0;
      padding: 0.5625rem;
    }
  }
`
