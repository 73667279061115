import { IconProps } from './IconProps'

export function IconDownload({ size = 24, color = 'currentColor', ...props }: IconProps) {
  const height = (16 / 18) * size

  return (
    <svg width={size} height={height} viewBox="0 0 18 16" fill={color} xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0.985443 15.6667C0.809792 15.6667 0.667399 15.5248 0.667399 15.3409V14.5942C0.666967 14.5877 0.666748 14.5811 0.666748 14.5744V10.7442C0.666748 10.5665 0.811608 10.4225 0.99936 10.4225H2.42703C2.61073 10.4225 2.75964 10.5713 2.75964 10.7442V13.6939H15.2601V10.7442C15.2601 10.5665 15.405 10.4225 15.5927 10.4225H17.0008C17.1845 10.4225 17.3334 10.5713 17.3334 10.7442V15.3409C17.3334 15.5208 17.1893 15.6667 17.0154 15.6667H0.985443ZM12.2956 6.4174L12.8806 6.98583C13.0487 7.14912 13.0484 7.41485 12.8813 7.57806L9.2382 11.1375C9.11263 11.2602 8.90868 11.2599 8.78343 11.1375L5.14013 7.57806C4.97248 7.41427 4.97348 7.14845 5.14083 6.98583L5.72582 6.4174C5.89385 6.25412 6.16642 6.25496 6.33399 6.41867L7.90751 7.95597C7.99178 8.03829 8.06009 8.00932 8.06009 7.89201L8.06009 0.905159C8.06009 0.720936 8.21066 0.571594 8.39432 0.571594H9.64467C9.82926 0.571594 9.97883 0.724161 9.97875 0.904438L9.97577 7.87618C9.97577 7.99487 10.044 8.02444 10.1273 7.94307L11.6874 6.41871C11.8553 6.25471 12.1283 6.25479 12.2956 6.4174Z"
      />
    </svg>
  )
}
