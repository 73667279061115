import { styled } from '@mui/material'
import moment from 'moment-timezone'
import { NavLink } from 'react-router-dom'

import { useToggleSessionLockMutation } from '@nuna/api'
import { useIsAdmin } from '@nuna/auth'
import { TimeZoneControl, UserLink, useTimeZoneContext } from '@nuna/common'
import { routeService } from '@nuna/core'
import { useEnvironmentContext } from '@nuna/environment'
import {
  Avatar,
  GhostButton,
  IconButton,
  IconButtonLink,
  IconLock,
  IconMail,
  IconUnlock,
  Skeleton,
  makeTypographyComponent,
  tealSet,
  toast,
} from '@nuna/tunic'

import { SessionData } from '../../../types'

const { conversationParticipantRoute } = routeService

type Props = {
  handleEditClick: () => void
  isEditing: boolean
  sessionData: SessionData
}

export function SessionDetailHeader({ isEditing, handleEditClick, sessionData }: Props) {
  const isAdmin = useIsAdmin()
  const { timeZoneToUse } = useTimeZoneContext()
  const { commonRoutes } = useEnvironmentContext()

  const [toggleSessionLock] = useToggleSessionLockMutation()

  const handleLockClick = async () => {
    if (sessionData.sessionId) {
      try {
        await toggleSessionLock({
          variables: {
            id: sessionData.sessionId,
            isLocked: !sessionData.locked,
          },
        })

        toast.success(`Note ${sessionData.locked ? 'unlocked' : 'locked'} successfully`)
      } catch (e) {
        toast.urgent('Error updating lock status of session note')
      }
    } else {
      console.error('Tried to update note without a session id')
    }
  }

  const dateTimeFormat = `ddd, MMM Do YYYY h:mma${!isAdmin ? ' z' : ''}`

  return (
    <>
      <div className="v-align">
        <OverlappingAvatars className="v-align">
          <Avatar size="xs" url={sessionData.patient.avatarUrl} className="top" />
          <Avatar className="bottom" size="xs" url={sessionData.provider.avatarUrl} />
        </OverlappingAvatars>
        <div className="ml-2">
          <h5 className="mb-0">
            <UserLink user={sessionData.patient} variant="page-header" showAvatar={false} />
            <span className="mx-1">with</span>
            <UserLink user={sessionData.provider} variant="page-header" showAvatar={false} showTitle />
          </h5>
          <div style={{ color: tealSet[90].hex }} className="v-align">
            {isAdmin ? (
              <>
                <NavLink
                  to={commonRoutes.adminSessionDetail(sessionData.appointmentId)}
                  style={{ color: tealSet[90].hex }}
                >
                  {moment(sessionData.scheduledStartTime).tz(timeZoneToUse).format(dateTimeFormat)}
                </NavLink>
                <TimeZoneControl className="ml-1" />
              </>
            ) : (
              <div>{moment(sessionData.scheduledStartTime).tz(timeZoneToUse).format(dateTimeFormat)}</div>
            )}
          </div>
        </div>
      </div>

      <div className="ml-auto v-align">
        {!isAdmin && (
          <IconButtonLink
            variant="secondary"
            tooltip="Message client"
            to={conversationParticipantRoute(sessionData.patient.loginId ?? '')}
          >
            <IconMail size={20} />
          </IconButtonLink>
        )}

        {(() => {
          if (!sessionData.sessionId) return null

          if (isAdmin) {
            return (
              <span className="ml-1 v-align text-secondary">
                {sessionData.locked ? 'Note locked' : 'Note unlocked'}
                <IconButton
                  onClick={handleLockClick}
                  variant="secondary"
                  className="ml-1"
                  tooltip={sessionData.locked ? 'Unlock note' : 'Lock note'}
                >
                  {sessionData.locked ? <IconLock /> : <IconUnlock />}
                </IconButton>
              </span>
            )
          }

          if (!isAdmin) {
            return (
              <span className="ml-1 v-align text-secondary">
                {sessionData.locked ? 'Note locked' : 'Note unlocked'}
                {sessionData.locked ? <IconLock className="ml-1" /> : <IconUnlock className="ml-1" />}
              </span>
            )
          }
        })()}

        {sessionData.sessionId && !sessionData.locked && (
          <GhostButton className="ml-1" scheme="light" variant="primary" onClick={handleEditClick}>
            {isEditing ? 'Cancel' : 'Edit'}
          </GhostButton>
        )}
      </div>
    </>
  )
}

export function SessionDetailHeaderSkeleton() {
  return (
    <>
      <UserLink variant="page-header" className="mr-1" loading />
      <Date className="mr-auto">
        <Skeleton width={10} height={1.8} />
      </Date>
    </>
  )
}

const Date = makeTypographyComponent('large body mb-0 text-medium', 'h3')

const OverlappingAvatars = styled('span')`
  position: relative;
  padding-right: 24px;

  .top {
    left: 0;
    border: 1px solid #fff;
    z-index: 1;
  }

  .bottom {
    border: 1px solid transparent;
    left: 24px;
    position: absolute;
  }
`
