import { styled } from '@mui/material'
import { ChangeEvent } from 'react'

import { usePatientAssessmentSettingsQuery, useTogglePatientAssessmentSettingsMutation } from '@nuna/api'
import { errorService } from '@nuna/core'
import { SidebarPanel } from '@nuna/coverage'
import { useFeatureFlags } from '@nuna/feature-flag'
import { Switch, greySet, toast } from '@nuna/tunic'

export function ClientAssessmentSettings({ patientId, providerId }: { patientId: string; providerId: string }) {
  const { assessmentAutomation } = useFeatureFlags()

  const { data } = usePatientAssessmentSettingsQuery({
    variables: {
      patientId,
      providerId,
    },
  })

  const [togglePatientAssessmentSettings] = useTogglePatientAssessmentSettingsMutation({
    refetchQueries: ['PatientAssessmentSettings'],
  })

  const autoAssessments = data?.patientAssessmentSettings?.autoAssessments ?? true

  const handleAssessmentToggle = async (e: ChangeEvent<HTMLInputElement>) => {
    try {
      await togglePatientAssessmentSettings({
        variables: {
          patientId,
          providerId,
          enable: e.target.checked,
        },
        optimisticResponse: {
          toggleAutomatedAssessments: {
            __typename: 'PatientAssessmentSettings',
            autoAssessments: e.target.checked,
          },
        },
      })
    } catch (e) {
      toast.urgent(errorService.transformUserGraphqlError(e, 'Unable to toggle assessment'))
    }
  }

  if (!assessmentAutomation || !providerId || !patientId) return null

  return (
    <SidebarPanel className="mt-1">
      <h2 className="h6 mb-3">Assessment Messages</h2>
      <div>
        <Switch checked={autoAssessments} onChange={handleAssessmentToggle}>
          Send assessment to this client every 4 weeks
        </Switch>
        <Info>
          Your client will receive an automated assessment request every 4 weeks in their messages, as long as they’ve
          logged in the last 4 weeks and haven’t already taken an assessment in that time.
        </Info>
      </div>
    </SidebarPanel>
  )
}

const Info = styled('p')`
  color: ${greySet[70].hex};
  font-weight: 300;
  font-size: 16px;
  padding-top: 1rem;
  max-width: 562px;
`
