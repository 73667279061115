import { ReactNode } from 'react'
import baseToast, { ToastOptions } from 'react-hot-toast'

import { Toast } from './Toast'
import cautionIconSrc from './img/caution.svg'
import infoIconSrc from './img/info.svg'
import successIconSrc from './img/success.svg'
import urgentIconSrc from './img/urgent.svg'
import { CustomToastType, ToastAction } from './types'

export interface CustomToastOptions extends ToastOptions {
  canDismiss?: boolean
  action?: ToastAction
  noIcon?: boolean
}

const iconMap: { [key in CustomToastType]: JSX.Element } = {
  success: <img src={successIconSrc} alt="" />,
  urgent: <img src={urgentIconSrc} alt="" />,
  caution: <img src={cautionIconSrc} alt="" />,
  info: <img src={infoIconSrc} alt="" />,
}

function getToast(
  type: CustomToastType,
  message: ReactNode,
  { canDismiss, action, style, className, noIcon, ...options }: CustomToastOptions = {},
) {
  return baseToast.custom(
    t => (
      <Toast
        type={type}
        message={message}
        toast={t}
        canDismiss={canDismiss}
        action={action}
        className={className}
        style={style}
      />
    ),
    {
      icon: noIcon ? null : iconMap[type],
      ...options,
    },
  )
}

export const toast = {
  success: (message: ReactNode, options?: CustomToastOptions) => getToast('success', message, options),
  urgent: (message: ReactNode, options?: CustomToastOptions) => getToast('urgent', message, options),
  caution: (message: ReactNode, options?: CustomToastOptions) => getToast('caution', message, options),
  info: (message: ReactNode, options?: CustomToastOptions) => getToast('info', message, options),
  dismiss: baseToast.dismiss,
}
