import { HTMLAttributes } from 'react'

import { useRemoteNetworkQuality } from '../../../hooks/useRemoteNetworkQuality'
import { NetworkQualityIcon } from './NetworkQualityIcon'

interface Props extends HTMLAttributes<HTMLDivElement> {
  sessionId: string
}

export function RemoteNetworkQualityIndicator({ sessionId, ...props }: Props) {
  const threshold = useRemoteNetworkQuality(sessionId)

  return <NetworkQualityIcon threshold={threshold} {...props} />
}
