import { Address } from '@nuna/api'
import { addressService } from '@nuna/core'
import { IconArmchair, IconVideoOutline, makeTypographyComponent } from '@nuna/tunic'

interface AppointmentLocationIconProps {
  iconSize?: number
  iconAlignment?: 'left' | 'right'
  hideLabel?: boolean
  includeAddressLabel?: boolean
  appointment: { address?: Partial<Address> | null }
}

const LocationContainer = makeTypographyComponent('v-align nowrap', 'span')

export const AppointmentLocationIcon = ({
  iconSize = 16,
  iconAlignment = 'left',
  hideLabel = false,
  includeAddressLabel = false,
  appointment,
}: AppointmentLocationIconProps) => {
  const hasAddress = 'address' in appointment && !!appointment.address

  const label = hideLabel
    ? null
    : hasAddress
    ? includeAddressLabel
      ? `In Person | ${addressService.formatAddress(appointment.address || {})}`
      : 'In Person'
    : 'Virtual'

  if (iconAlignment === 'right') {
    return hasAddress ? (
      <LocationContainer>
        {label}
        <IconArmchair size={iconSize} className="ml-1 flex-static" />
      </LocationContainer>
    ) : (
      <LocationContainer>
        {label}
        <IconVideoOutline size={iconSize} className="ml-1 flex-static" />
      </LocationContainer>
    )
  }

  return hasAddress ? (
    <LocationContainer>
      <IconArmchair size={iconSize} className="mr-1 flex-static" />
      {label}
    </LocationContainer>
  ) : (
    <LocationContainer>
      <IconVideoOutline size={iconSize} className="mr-1 flex-static" />
      {label}
    </LocationContainer>
  )
}
