import { styled } from '@mui/material'

import { tealSet } from '@nuna/tunic'

export const CAQHSubtitle = styled('p')`
  color: ${tealSet[90].hex};
  font-size: 26px;
  font-weight: 300;

  b {
    color: ${tealSet[90].hex};
    font-weight: 500;
  }
`
