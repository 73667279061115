import { useFormikContext } from 'formik'

import { AddressForm } from '@nuna/common'
import { formService } from '@nuna/core'
import { Box, IconPhone, PhoneTextField, Stack } from '@nuna/tunic'

export function SignupIntakeAddressForm() {
  const { values, handleBlur, setFieldValue, touched, errors } = useFormikContext<{ mobilePhone: string }>()
  return (
    <Stack spacing={4}>
      <AddressForm gridSpacing={4} dataTestPrefix="intake" />
      <Stack direction="row" spacing={2}>
        <Box sx={{ pt: 2.5 }}>
          <IconPhone />
        </Box>
        <PhoneTextField
          label="Phone Number"
          name="mobilePhone"
          inputProps={{ 'data-testid': 'intake-phone' }}
          value={values.mobilePhone}
          onChange={value => setFieldValue('mobilePhone', value)}
          onBlur={handleBlur}
          {...formService.composeHelperTextWithError('', errors.mobilePhone, !!touched.mobilePhone)}
          className="full-width"
        />
      </Stack>
    </Stack>
  )
}
