import * as Yup from 'yup'
import { styled } from '@mui/material'

import { FullProviderFragment, Maybe, useUpdateProviderMutation } from '@nuna/api'
import { ProfileDisplayValue, ProfileSection } from '@nuna/common'
import { BelowTablet, Grid, TextField } from '@nuna/tunic'

import { incompleteSectionNotification } from '../../../utils/util'

export type LoginEmailProps = Pick<FullProviderFragment, 'id' | 'email'>

type LoginEmailValues = {
  email?: Maybe<string> | undefined
} & Omit<LoginEmailProps, 'id' | 'email'>

export function LoginEmailSection({ id, email }: LoginEmailProps) {
  const [updateProvider, { loading: updateLoading }] = useUpdateProviderMutation()

  const initialValues: LoginEmailValues = {
    email: email ?? '',
  }

  const incompleteSectionValues: (keyof LoginEmailValues)[] = ['email']

  const [isComplete, valueDisplay] = incompleteSectionNotification<LoginEmailValues>(
    initialValues,
    incompleteSectionValues,
    <ProfileDisplayValue>{email}</ProfileDisplayValue>,
  )

  return (
    <ProfileSection
      heading="Email"
      showEditButton
      useOutlineSaveButton
      description="All communication from Tava will happen through email. Please be sure to check your email regularly. Clients can only contact you through Tava Messages and never your email address."
      displayWidth="full"
      updateLoading={updateLoading}
      initialValues={initialValues}
      handleSubmit={values => updateProvider({ variables: Object.assign({ id }, values) })}
      disabled={({ isValid }) => !isValid}
      sectionComplete={isComplete}
      renderDisplayValue={valueDisplay}
      renderForm={({ values, handleChange, handleBlur, touched, errors }) => (
        <FormGrid container spacing={2}>
          <Grid
            size={{
              xs: 12,
              md: 6,
            }}
          >
            <TextField
              name="email"
              label="Email"
              value={values.email ?? ''}
              onChange={handleChange}
              onBlur={handleBlur}
              error={(errors.email && touched.email) as boolean}
              helperText={touched.email && errors.email ? errors.email : null}
            />
          </Grid>
        </FormGrid>
      )}
      validationSchema={Yup.object().shape({
        email: Yup.string().required('Email is required').email('Must be a valid email format'),
      })}
    ></ProfileSection>
  )
}

const FormGrid = styled(Grid)`
  padding-right: var(--margin-4);
  @media (${BelowTablet}) {
    padding-right: initial;
  }
`
