import { styled } from '@mui/material'
import { isNumber } from 'lodash'
import React from 'react'

import { styledUtils } from '../../utils/styled'
import { BaseInput, BaseInputProps, type InputStylesProps, inputStyles } from '../BaseInput/BaseInput'
import { MultilineTextField, MultilineTextFieldVariant } from './MultilineTextField'
import { PasswordField } from './PasswordField'
import { SsnField } from './SsnField'
import { TimeField } from './TimeField'

// Export pass-through
export { ssnRegExp } from './SsnField'
export { parseTime, timeRegExp, validateTime } from './TimeField'

const { transientPropOptions } = styledUtils

interface CommonInputProps extends Omit<BaseInputProps, 'children' | 'value' | 'as'> {
  autoFocus?: boolean
  onChange: React.ChangeEventHandler<HTMLTextAreaElement | HTMLInputElement>
  value: string | number | null | undefined
  disabled?: boolean
  placeholder?: string
}
type ConditionalInputProps =
  | {
      multiline: true
      type?: 'text'
      rows?: number
      rowsMax?: number
      variant?: MultilineTextFieldVariant
      $inputRef?: React.RefObject<HTMLTextAreaElement>
      labelNoWrap?: boolean
    }
  | { multiline?: never; type?: string; $inputRef?: React.RefObject<HTMLInputElement> }

export type TextFieldProps = CommonInputProps & ConditionalInputProps

export function TextField({
  className,
  error,
  urgent,
  helperText,
  id: passedId,
  label,
  maxCharacters,
  hideCharacterCount,
  onBlur,
  onChange,
  onFocus,
  style,
  value,
  renderIcon,
  showBottomBorderOnFocus = true,
  inputProps = {},
  labelNoWrap = false,
  shrink,
  ...props
}: TextFieldProps) {
  const isOutlinedTextArea = props.multiline && props.variant === 'outlined'
  if (!props.type) props.type = 'text'
  return (
    <BaseInput
      className={className}
      disabled={props.disabled}
      error={error}
      urgent={urgent}
      helperText={helperText}
      id={passedId}
      label={label}
      maxCharacters={maxCharacters}
      hideCharacterCount={hideCharacterCount}
      onBlur={onBlur}
      onFocus={onFocus}
      style={style}
      value={value || isNumber(value) ? value.toString() : ''}
      renderIcon={renderIcon}
      shrink={shrink}
      showBottomBorderOnFocus={isOutlinedTextArea ? false : showBottomBorderOnFocus}
      labelNoWrap={labelNoWrap}
    >
      {(
        onFocus: React.FocusEventHandler<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>,
        onBlur: React.FocusEventHandler<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>,
        id: string,
      ) => {
        if (props.type === 'time') {
          return (
            <TimeField
              {...props}
              inputProps={inputProps}
              id={id}
              maxLength={maxCharacters}
              onBlur={onBlur}
              onChange={onChange}
              onFocus={onFocus}
              value={value ?? ''}
              ref={props.$inputRef}
              $placeholderAlwaysVisible={!!props.placeholder && (!label || shrink)}
            />
          )
        }

        if (props.type === 'password') {
          return (
            <PasswordField
              {...props}
              inputProps={inputProps}
              id={id}
              maxLength={maxCharacters}
              onBlur={onBlur}
              onChange={onChange}
              onFocus={onFocus}
              value={value ?? ''}
              ref={props.$inputRef}
            />
          )
        }

        if (props.type === 'ssn') {
          return (
            <SsnField
              {...props}
              inputProps={inputProps}
              id={id}
              maxLength={maxCharacters}
              onBlur={onBlur}
              onChange={onChange}
              onFocus={onFocus}
              value={value ?? ''}
              ref={props.$inputRef}
            />
          )
        }

        if (props.multiline) {
          // eslint-disable-next-line @typescript-eslint/no-unused-vars, no-unused-vars
          const { rows, rowsMax, multiline, $inputRef, ...textAreaProps } = props

          return (
            <MultilineTextField
              {...textAreaProps}
              inputProps={inputProps}
              id={id}
              maxLength={maxCharacters}
              onBlur={onBlur}
              onChange={onChange}
              onFocus={onFocus}
              rows={rows}
              rowsMax={rowsMax}
              value={value ?? ''}
              ref={$inputRef}
            />
          )
        } else {
          return (
            <Input
              {...props}
              {...inputProps}
              id={id}
              maxLength={maxCharacters}
              onBlur={onBlur}
              onChange={onChange}
              onFocus={onFocus}
              type={props.type}
              value={value ?? ''}
              ref={props.$inputRef}
              $placeholderAlwaysVisible={!!props.placeholder && (!label || shrink)}
            />
          )
        }
      }}
    </BaseInput>
  )
}

const Input = styled('input', transientPropOptions)<InputStylesProps>`
  ${inputStyles};
`
