import { IconProps } from './IconProps'

export function IconPlus({ size = 24, color = 'currentColor', ...props }: IconProps) {
  return (
    <svg width={size} height={size} viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M20.0336 27.8992H0V20.0336H20.0336V0H27.9664V20.0336H48V27.8992H27.9664V48H20.0336V27.8992Z"
        fill={color}
      />
    </svg>
  )
}
