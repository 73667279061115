import { styled } from '@mui/material'
import { ChangeEvent } from 'react'

import { greySet } from '../../styles/colorSets'
import { TextField, TextFieldProps } from './TextField'

export function HexColorField(props: TextFieldProps) {
  const previewColor = typeof props.value === 'string' && CSS.supports(`color: ${props.value}`) ? props.value : 'white'

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    event.currentTarget.value = formatHexColor(event.currentTarget.value)
    return props.onChange?.(event)
  }

  return (
    <TextField {...props} onChange={handleChange} renderIcon={() => <ColorPreview $previewColor={previewColor} />} />
  )
}

const formatHexColor = (value: string) => {
  if (!value) return value

  // Clear out any duplicate # symbols
  value = value.replace(/#/g, '')

  // Add a # symbol back to the beginning of the input value
  if (value[0] !== '#') value = '#' + value

  // Remove anything that isn't a valid hexcode
  value = value.replace(/[^#0-9a-fA-F]+/g, '')

  // Truncate to a max of 6 hex characters after the # symbol
  return value.substring(0, 7)
}

const ColorPreview = styled('span')<{ $previewColor: string }>`
  display: flex;
  width: 26px;
  height: 26px;
  flex-shrink: 0;
  border: 2px solid ${greySet[50].hex};
  border-radius: 4px;
  padding: var(--spacing-1);
  margin-right: var(--spacing-half);
  background-color: ${props => props.$previewColor};
`
