import { styled } from '@mui/material'
import { entries } from 'lodash'
import moment from 'moment'
import { ReactElement, useMemo, useState } from 'react'
import { useParams } from 'react-router-dom'
import { Column } from 'react-table'

import { AssessmentType, Role, usePatientDetailsQuery } from '@nuna/api'
import { useAuthDataContext, useUserRole } from '@nuna/auth'
import { UserLink } from '@nuna/common'
import { DataTable } from '@nuna/data-table'
import { Drawer, IconButton, IconClose, borderGrey } from '@nuna/tunic'

import { AssessmentFromBundleQuery, AssessmentValues, CssrsFormValues } from '../types/types'
import {
  cssrsSeverityLabelLookup,
  getFormattedAssessmentResponse,
  getLongerReadableAssessmentName,
  severityLookupWithLabels,
} from '../util/assessments'

interface AssessmentResponsesProps {
  assessments: AssessmentFromBundleQuery[]
  displayTopBorder?: boolean
  headerAction?: ReactElement
}

const determineSeverityAndGetLabel = (score: number, type: AssessmentType, assessment: AssessmentFromBundleQuery) => {
  if (type in severityLookupWithLabels) {
    for (const { maxScore, label } of severityLookupWithLabels[type as AssessmentType.Gad_7 | AssessmentType.Phq_9]) {
      if (score <= maxScore) return label
    }
  }
  if (type === AssessmentType.CSsrs) {
    const values = assessment.values as CssrsFormValues
    return cssrsSeverityLabelLookup(values)
  }

  return 'Unknown'
}

interface AssessmentRow {
  date: string
  score: number
  severity: ReturnType<typeof determineSeverityAndGetLabel>
  assessment: string
  id: string
}

type RouteProps = {
  id?: string
}

export function AssessmentResponses({ assessments, displayTopBorder, headerAction }: AssessmentResponsesProps) {
  const userRole = useUserRole() || Role.Patient
  const { id: patientIdForTherapistOrAdmin = '' } = useParams<RouteProps>()
  const { data: patientData, loading: isLoadingPatient } = usePatientDetailsQuery({
    variables: { id: patientIdForTherapistOrAdmin },
    skip: !patientIdForTherapistOrAdmin,
  })
  const { login } = useAuthDataContext()
  const isNotPatient = userRole !== Role.Patient
  const patient = isNotPatient ? patientData?.patient : login
  const [activeAssessment, setActiveAssessment] = useState<AssessmentResponsesProps['assessments'][number]>()

  const sortedAssessments = useMemo(
    () => [...assessments].sort((a, b) => b.updatedAt.localeCompare(a.updatedAt)),
    [assessments],
  )
  const handleClose = () => setActiveAssessment(undefined)
  const handleClick = (row: AssessmentRow) =>
    setActiveAssessment(assessments?.find(assessment => assessment.id === row.id))

  const rowData: AssessmentRow[] = useMemo(
    () =>
      sortedAssessments.map(assessment => ({
        date: moment(assessment.updatedAt).format('LL'),
        score: assessment.score || 0,
        severity: determineSeverityAndGetLabel(assessment.score || 0, assessment.type, assessment),
        assessment: getLongerReadableAssessmentName(assessment.type, userRole),
        id: assessment.id,
      })),
    [sortedAssessments, userRole],
  )

  const columns: Column<AssessmentRow>[] = useMemo(
    () => [
      { Header: 'Date Taken', accessor: 'date' },
      { Header: 'Score', accessor: 'score' },
      { Header: 'Severity', accessor: 'severity' },
      { Header: 'Assessment', accessor: 'assessment' },
    ],
    [],
  )

  return (
    <Container displayTopBorder={displayTopBorder}>
      <header className="v-align space-between">
        <h2 className="h5 mt-3 mb-2">Assessment Responses</h2>
        {headerAction}
      </header>

      <DataTable columns={columns} rowData={rowData} loading={false} onRowClick={handleClick} className="fs-mask" />

      <Drawer isOpen={!!activeAssessment} onClose={handleClose}>
        <div className="v-align p-3">
          {patient && (
            <UserLink user={{ ...patient, loginId: patient.id }} variant="drawer-header" loading={isLoadingPatient} />
          )}
          <IconButton tooltip="Close" variant="secondary" onClick={handleClose} className="ml-auto">
            <IconClose size={20} />
          </IconButton>
        </div>
        {activeAssessment && (
          <DrawerContent>
            <h3 className="h5 mb-3">
              {getLongerReadableAssessmentName(activeAssessment.type, userRole)} from{' '}
              {moment(activeAssessment.updatedAt).format('LL')}
              <br />
              Score: {activeAssessment.score}
            </h3>

            <ResponseList>
              {entries(activeAssessment.values as AssessmentValues).map(([key, { value }]) => {
                const activeAssessmentType = activeAssessment.type
                const { question, response } = getFormattedAssessmentResponse(activeAssessmentType, { id: key, value })

                return (
                  <li key={key} className="larger">
                    <span className="text-bold">{question}</span>
                    <br />
                    {response}
                  </li>
                )
              })}
            </ResponseList>
          </DrawerContent>
        )}
      </Drawer>
    </Container>
  )
}

const Container = styled('section')<{ displayTopBorder?: boolean }>`
  border-top: ${props => (props.displayTopBorder ? `1px solid ${borderGrey}` : 'none')};
`

const DrawerContent = styled('div')`
  padding: 0 var(--spacing-3);
  overflow: auto;
`

const ResponseList = styled('ol')`
  padding-left: var(--spacing-3);
  max-width: 550px;

  li {
    margin-bottom: var(--spacing-2);
  }
`
