import { Autocomplete, InputAdornment, TextField, TextFieldProps } from '@mui/material'
import { ReactNode, useEffect, useState } from 'react'

import {
  CompanySearchResultFragment,
  SearchCompaniesQuery,
  usePublicCompanyQuery,
  useSearchCompaniesLazyQuery,
} from '@nuna/api'
import { useEnvironmentContext } from '@nuna/environment'
import { Grid, IconCompany, IconInfo, Tooltip, bodyPrimary, greySet, interactiveFill } from '@nuna/tunic'

export type Employer = Omit<CompanySearchResultFragment, 'threshold'>

export interface EmployerAutoCompleteProps {
  value: Employer | ''
  onChange: (employer: Employer | null) => void
  label: string
  error: boolean
  helperText?: string | ReactNode
  initialValueEmployerId?: string
  textFieldProps?: TextFieldProps
  noOptionsText?: ReactNode
  tooltip?: string
  clearOnBlur?: boolean
  includeUnknownInOptions?: boolean
  hideIcon?: boolean
}

export function EmployerAutoComplete({
  onChange,
  value,
  label,
  error,
  textFieldProps = {},
  initialValueEmployerId,
  noOptionsText = <span className="caption">Sorry, we can't find any matching organizations</span>,
  helperText,
  tooltip = 'Your participation is confidential. Individual usage is not reported back to your company.',
  includeUnknownInOptions,
  hideIcon,
  ...props
}: EmployerAutoCompleteProps) {
  // this is literally only used here.
  const [searchCompanies, { loading, data }] = useSearchCompaniesLazyQuery()

  const { data: companyData } = usePublicCompanyQuery({
    variables: { id: initialValueEmployerId ?? '' },
    skip: !initialValueEmployerId,
  })

  const [inputValue, setInputValue] = useState('')
  const [isFocused, setIsFocused] = useState(false)

  /**
   * I do not love this solution, but i am not sure how else to acheive it outside
   * of duplicating the code for the apps. Something needs to control this, it might
   * as well be a fitlering function
   *
   * The goal here is to not show companies that require referral ID when in arrow,
   * or harmony as most users in those system won't know what to do at that point.
   */
  let companyFilter = undefined
  const env = useEnvironmentContext()
  const DONT_INCLUDE_REFERRAL_ID_APPS = ['arrow', 'harmony']
  if (DONT_INCLUDE_REFERRAL_ID_APPS.includes(env.app)) {
    companyFilter = (c: SearchCompaniesQuery['searchCompanies'][0]) => c.isReferralIdRequired === false
  }

  useEffect(() => {
    if (companyData && initialValueEmployerId && !value) {
      onChange({
        id: initialValueEmployerId,
        name: companyData.publicCompany.name,
        offersTava: true,
        associationRequired: companyData.publicCompany.contract.associationRequired,
        isReferralIdRequired: companyData.publicCompany.isReferralIdRequired,
      })
      setInputValue(companyData.publicCompany.name)
    }
  }, [companyData, initialValueEmployerId, onChange, value])

  return (
    <Autocomplete
      id="employer"
      forcePopupIcon={false}
      disableClearable
      options={data?.searchCompanies || []}
      loading={loading}
      clearOnBlur
      filterOptions={options => {
        const filteredOptions = companyFilter ? options.filter(o => companyFilter(o)) : options

        if (includeUnknownInOptions && inputValue && !loading && options.length === 0) {
          return [
            ...filteredOptions,
            {
              id: 'unknown',
              name: inputValue,
              offersTava: false,
              threshold: 0,
              associationRequired: false,
              isReferralIdRequired: false,
            },
          ]
        } else {
          return filteredOptions
        }
      }}
      selectOnFocus
      onChange={(_e, value) => {
        if (typeof value !== 'string') {
          onChange(value)
          setInputValue(value.name)
        }
      }}
      inputValue={inputValue}
      isOptionEqualToValue={(option, value) => option.id === value.id}
      getOptionLabel={option => {
        const name = option?.name ?? ''

        if (name === 'Nomi Health') {
          return 'Nomi Health (for Nomi employees only)'
        }
        return name
      }}
      noOptionsText={
        inputValue ? noOptionsText : <span className="caption">Start typing to see a list of matching employers</span>
      }
      renderInput={params => (
        <Grid container spacing={1} alignItems="flex-start" wrap="nowrap">
          {!hideIcon && (
            <Grid>
              <IconCompany style={{ marginTop: '1.25rem' }} color={isFocused ? interactiveFill : bodyPrimary} />
            </Grid>
          )}
          <Grid size={12}>
            <TextField
              {...textFieldProps}
              {...params}
              onFocus={() => setIsFocused(true)}
              onBlur={e => {
                textFieldProps.onBlur && textFieldProps.onBlur(e)
                setIsFocused(false)

                if (props.clearOnBlur) {
                  if (value && inputValue) {
                    setInputValue(value.name)
                  } else {
                    onChange(null)
                    setInputValue('')
                  }
                }
              }}
              label={label}
              autoComplete="new-password"
              error={error}
              inputProps={{
                ...params.inputProps,
                'data-testid': 'signup-employer',
                style: { padding: '5px 0 7px' },
              }}
              helperText={helperText}
              InputProps={{
                ...params.InputProps,
                endAdornment: (
                  <Tooltip content={tooltip}>
                    <InputAdornment position="end">
                      <IconInfo color={greySet[50].hex} />
                    </InputAdornment>
                  </Tooltip>
                ),
              }}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                setInputValue(event.target.value)
                searchCompanies({
                  variables: {
                    query: event.target.value,
                  },
                })
              }}
            />
          </Grid>
        </Grid>
      )}
      {...props}
    />
  )
}
