import { styled } from '@mui/material'
import moment from 'moment-timezone'
import { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'

import {
  AppointmentStats,
  ProviderCredentialingFlowStatus,
  ProviderCredentialingFlowStatusQuery,
  ProviderMeQuery,
  useAppointmentStatsQuery,
  useProviderAvailabilityConstraintsQuery,
  useProviderCredentialingFlowStatusQuery,
  useProviderMeQuery,
} from '@nuna/api'
import { ExploreFirstEmptyState, useAppointmentDrawerSearchParams, useTitle } from '@nuna/common'
import { routeService } from '@nuna/core'
import { ProviderSchedule } from '@nuna/provider'
import {
  BelowDesktopLarge,
  FullAccountSidebarCard,
  GhostButton,
  GhostButtonLink,
  PageContent,
  PageHeader,
  PageHeading,
  PageWrapper,
  PageWrapperWithSidebar,
  Skeleton,
} from '@nuna/tunic'

import girlStarsSrc from './img/StarsGirl.png'

const renderIntakeLinkForProfileStatus = (
  providerData: ProviderMeQuery | undefined,
  credentialingFlowStatus: ProviderCredentialingFlowStatusQuery | undefined,
) => {
  if (!providerData?.providerMe?.isProfileComplete) return routeService.signupIntake('resume-intake')
  if (credentialingFlowStatus?.providerMe.credentialingFlowStatus !== ProviderCredentialingFlowStatus.Completed)
    return routeService.caqhSetup()

  return routeService.backgroundCheckRoute
}

export function ProviderScheduleScene() {
  const [controlsContainer, setControlsContainer] = useState<HTMLDivElement | null>(null)
  const { data: providerData, loading: loadingProviderData } = useProviderMeQuery()
  const location = useLocation()
  useTitle('Schedule')

  const providerId = providerData?.providerMe.id

  const providerHasAvailabilitySet = (providerData?.providerMe?.availabilityPeriods?.allowDayAndTime?.length ?? 0) > 0

  const { data: credentialingFlowStatus, loading: loadingCredentialingFlowStatus } =
    useProviderCredentialingFlowStatusQuery()

  const { data: availabilityData } = useProviderAvailabilityConstraintsQuery()

  const { openScheduleAppointmentDrawer } = useAppointmentDrawerSearchParams()
  const handleOnNewAppointment = () => {
    openScheduleAppointmentDrawer(providerId ?? '')
  }

  const { data: appointmentData, refetch: refetchAppointments } = useAppointmentStatsQuery({
    fetchPolicy: 'cache-and-network',
    variables: {
      options: {
        providerId,
      },
    },
    skip: !providerId,
  })

  useEffect(() => {
    refetchAppointments()
  }, [location, refetchAppointments])

  const showCaqhSidebar =
    !loadingCredentialingFlowStatus &&
    credentialingFlowStatus?.providerMe.credentialingFlowStatus !== ProviderCredentialingFlowStatus.Completed &&
    !!providerData?.providerMe?.isProfileComplete
  const showFullAccountSidebar = !loadingProviderData && !providerData?.providerMe?.isProfileComplete

  const hasAppointmentsOnTheBooks = (appts: AppointmentStats | undefined) => {
    // default to assuming we have appointments
    if (!appts) return true
    return (
      (!!appts && !!appts.filteredAppointments?.length) ||
      !!availabilityData?.providerMe.availabilityPeriods.calendarBlockPeriod.length
    )
  }

  return (
    <PageWrapperWithSidebar $hasSidebar={showCaqhSidebar || showFullAccountSidebar}>
      <PageWrapper $hasSidebar={showCaqhSidebar || showFullAccountSidebar} $fullAccountSetup={showFullAccountSidebar}>
        <ResponsivePageHeader>
          <PageHeading className="my-1 mr-2">Schedule</PageHeading>
          <ControlsContainer ref={setControlsContainer} />
        </ResponsivePageHeader>
        <PageContent $paddingTop={false}>
          {!providerId && <Skeleton className="mt-2" height={10} style={{ width: '100%' }} />}
          {providerId && (
            <>
              <ProviderSchedule
                controlsContainer={controlsContainer}
                providerId={providerId}
                audience="provider"
                timezone={moment.tz.guess()}
              />
              {!hasAppointmentsOnTheBooks(appointmentData?.appointmentStats) && (
                <ExploreFirstEmptyState
                  textBlock={
                    <>
                      <h2 className="h4 mt-0">Your central hub for managing appointments and sessions.</h2>
                      <p className="large">
                        {!providerHasAvailabilitySet ? (
                          <>
                            <GhostButtonLink to={routeService.standaloneAvailability}>
                              Add your availability
                            </GhostButtonLink>{' '}
                            so you can schedule clients.
                          </>
                        ) : (
                          <>
                            <GhostButton onClick={handleOnNewAppointment}>Schedule</GhostButton> a client to see your
                            schedule fill up.
                          </>
                        )}
                      </p>
                    </>
                  }
                  className="pr-3"
                  illustrationSrc={girlStarsSrc}
                  topOffset={80}
                  leftOffset={60}
                />
              )}
            </>
          )}
        </PageContent>
      </PageWrapper>
      {(showFullAccountSidebar || showCaqhSidebar) && (
        <FullAccountSidebarCard
          insuranceOnly={showCaqhSidebar}
          intakeLink={renderIntakeLinkForProfileStatus(providerData, credentialingFlowStatus)}
        />
      )}
    </PageWrapperWithSidebar>
  )
}

const ControlsContainer = styled('div')`
  flex: 1 1 auto;

  h2 {
    @media (${BelowDesktopLarge}) {
      display: none;
    }
  }
`

const ResponsivePageHeader = styled(PageHeader)`
  flex-wrap: wrap;
`
