import { styled } from '@mui/material'
import { noop } from 'lodash'
import { useEffect, useState } from 'react'

import { Role } from '@nuna/api'
import { useHasRole } from '@nuna/auth'
import { IconCar, IconClock, IconMug, Tab, TabId, TabPanel, TabProvider, Tabs, greySet } from '@nuna/tunic'

import { CalendarSyncWidget } from '../CalendarSyncWidget'
import { GreyBox } from '../ProviderLayout'
import { ProviderAwayTime } from './components/ProviderAwayTime'
import { ProviderExtraTime } from './components/ProviderExtraTime'
import { ProviderRegularTimeCalendar } from './components/ProviderRegularTimeCalendar'
import { AvailabilityContextProvider } from './context/AvailabilityContextProvider'
import { ProviderAvailabilityProps } from './types'

interface Props extends ProviderAvailabilityProps {
  refetchQueries?: string[]
  onTabChange?: (activeTab: TabId) => void
}

export enum AvailabilityTabs {
  Regular,
  Away,
  Extra,
}

export function ManageAvailability({ provider, refetchQueries = [], onTabChange = noop }: Props) {
  const isAdmin = useHasRole(Role.Admin)
  const [activeTab, setActiveTab] = useState<TabId>(AvailabilityTabs.Regular)

  useEffect(() => {
    onTabChange(activeTab)
  }, [activeTab, onTabChange])

  return (
    <AvailabilityContextProvider provider={provider} refetchQueries={refetchQueries}>
      <div className="space-between full-width mb-2 v-align">
        {isAdmin && (
          <>
            <h2 className="h5 text-dark">Hours and Time Off</h2>
            <CalendarSyncWidget providerId={provider.id} />
          </>
        )}
      </div>
      <TabProvider label="availability" active={activeTab} onChange={value => setActiveTab(value)}>
        <Tabs className="mb-2">
          <StyledTab value={AvailabilityTabs.Regular}>
            <IconClock size={16} style={{ marginBottom: '-.15rem', marginRight: '.3rem' }} />
            Regular Hours
          </StyledTab>

          <StyledTab value={AvailabilityTabs.Away}>
            <IconCar size={16} style={{ marginBottom: '-.15rem', marginRight: '.3rem' }} />
            Away Time
          </StyledTab>

          <StyledTab value={AvailabilityTabs.Extra}>
            <IconMug size={13} style={{ marginBottom: '0rem', marginRight: '.3rem' }} />
            Extra Time
          </StyledTab>
        </Tabs>

        <TabPanel value={AvailabilityTabs.Regular}>
          <ProviderRegularTimeCalendar provider={provider} />
        </TabPanel>

        <TabPanel value={AvailabilityTabs.Away}>
          <PaddedGreyBox>
            <ProviderAwayTime provider={provider} />
          </PaddedGreyBox>
        </TabPanel>

        <TabPanel value={AvailabilityTabs.Extra}>
          <PaddedGreyBox>
            <ProviderExtraTime provider={provider} />
          </PaddedGreyBox>
        </TabPanel>
      </TabProvider>
    </AvailabilityContextProvider>
  )
}

const PaddedGreyBox = styled(GreyBox)`
  padding: 1.5rem 2rem;
`

const StyledTab = styled(Tab)`
  box-shadow: inset 0 -3px ${greySet[30].hex};
  color: ${greySet[70].hex};
  font-weight: 500;
  padding: 0.75rem 0.5rem;
  margin-right: 0.5rem;

  &:hover {
    color: var(--primary-hover);
  }

  &[aria-selected='true'] {
    box-shadow: inset 0 -3px var(--primary-50);
    color: var(--primary-50);
  }
`
