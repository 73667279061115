import { isEmpty } from 'lodash'
import { useEffect, useMemo } from 'react'
import { Route, Routes } from 'react-router-dom'

import { useListCancelPoliciesQuery } from '@nuna/api'
import { useIsAdmin } from '@nuna/auth'
import { errorService } from '@nuna/core'
import { cancelPolicyService } from '@nuna/core'
import { Skeleton, toast } from '@nuna/tunic'

import { CancelPolicyList } from './components/CancelPolicyList'
import { Instructions } from './components/Instructions'

interface Props {
  providerId?: string | null
}

export function CancelPolicies({ providerId }: Props) {
  const isAdmin = useIsAdmin()
  const { data, error, loading } = useListCancelPoliciesQuery({
    variables: { providerId: providerId ?? '' },
    skip: isEmpty(providerId),
    fetchPolicy: 'cache-and-network',
  })
  const cancelPolicies = useMemo(
    () => cancelPolicyService.prepareCancelPoliciesList(data?.listCancelPolicies ?? []),
    [data],
  )

  useEffect(() => {
    if (error) {
      toast.urgent(errorService.transformGraphQlError(error, 'There was an error loading your cancel policies.'))
    }
  }, [error])

  const showInstructions = !isAdmin && cancelPolicies.length === 0

  return (
    <div>
      {loading && <Skeleton height={8} />}
      {!loading && providerId && (
        <Routes>
          <Route index={showInstructions} element={<Instructions />} />
          <Route path="instructions" element={<Instructions />} />
          <Route path="/*" element={<CancelPolicyList cancelPolicies={cancelPolicies} providerId={providerId} />} />
        </Routes>
      )}
    </div>
  )
}
