import { IconProps } from './IconProps'

export function IconSwitch({ size = 24, color = 'currentColor', ...props }: IconProps) {
  return (
    <svg width={size} height={size} viewBox="0 0 24 24" fill={color} xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17.4918 6.11339L15.9805 4.56188C15.8179 4.39495 15.8179 4.12348 15.9792 3.95705L16.5427 3.37531C16.7046 3.20821 16.9681 3.20849 17.1299 3.37461L20.6589 6.99752C20.7805 7.1224 20.7802 7.32522 20.6589 7.44977L17.1299 11.0729C16.9675 11.2396 16.704 11.2386 16.5427 11.0722L15.9792 10.4904C15.8173 10.3233 15.8181 10.0523 15.9804 9.88562L17.5046 8.32083C17.5862 8.23703 17.5575 8.1691 17.4412 8.1691L8.661 8.1691L7.70612 8.1619C6.29981 8.1619 5.15977 9.28907 5.15977 10.6795V11.204C5.15977 11.4348 4.97001 11.6236 4.73593 11.6236H3.67384C3.4401 11.6236 3.25 11.4358 3.25 11.204V10.6795C3.25 8.24624 5.24507 6.27368 7.70612 6.27368L8.661 6.26094L17.4255 6.26405C17.5431 6.26405 17.5725 6.19621 17.4918 6.11339ZM6.50822 17.8866L8.01954 19.4381C8.18214 19.605 8.18206 19.8765 8.02084 20.0429L7.45727 20.6247C7.29538 20.7918 7.03192 20.7915 6.87011 20.6254L3.34111 17.0025C3.21946 16.8776 3.2198 16.6748 3.34111 16.5502L6.87011 12.9271C7.0325 12.7604 7.29604 12.7614 7.45727 12.9278L8.02084 13.5096C8.18273 13.6767 8.18189 13.9477 8.01958 14.1144L6.49543 15.6792C6.41381 15.763 6.44253 15.8309 6.55884 15.8309H15.339L16.2939 15.8381C17.7002 15.8381 18.8402 14.7109 18.8402 13.3205V12.796C18.8402 12.5652 19.03 12.3764 19.2641 12.3764H20.3262C20.5599 12.3764 20.75 12.5642 20.75 12.796V13.3205C20.75 15.7538 18.7549 17.7263 16.2939 17.7263L15.339 17.7391L6.57453 17.736C6.45686 17.736 6.42755 17.8038 6.50822 17.8866Z"
      />
    </svg>
  )
}
