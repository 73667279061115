import { styled } from '@mui/material'
import { includes, noop } from 'lodash'
import { HTMLAttributes, useState } from 'react'

import { AppointmentForNotificationFragment } from '@nuna/api'
import { useAppointmentDrawerSearchParams } from '@nuna/common'
import { Audience } from '@nuna/core'
import { Confirm, Drawer, FillButton, GhostButton, csx, eggshell } from '@nuna/tunic'

import { AppointmentDetail } from './components/AppointmentDetail/AppointmentDetail'
import { CancelAppointment } from './components/CancelAppointment/CancelAppointment'
import { CancelApointmentSelect } from './components/CancelAppointment/CancelAppointmentSelect'
import { RescheduleAppointment } from './components/RescheduleAppointment/RescheduleAppointment'
import { RescheduleApointmentSelect } from './components/RescheduleAppointmentSelect'
import { ScheduleAppointmentAsClient } from './components/ScheduleAppointmentAsClient/ScheduleAppointmentAsClient'
import { ScheduleAppointmentAsProvider } from './components/ScheduleAppointmentAsProvider/ScheduleAppointmentAsProvider'
import { useWarnOnCashPay } from './hooks/useWarnOnCashPay'

interface AppointmentDrawerProps {
  audience: Audience
  onAppointmentScheduled?: (appointment: AppointmentForNotificationFragment) => void
  transparentBackground?: boolean
}

export function AppointmentDrawer({
  audience,
  onAppointmentScheduled = noop,
  transparentBackground,
}: AppointmentDrawerProps) {
  const {
    drawerConfig: { cancel, reschedule, schedule, appointmentId, detail, drawerOpen, timeSlot, schedulePatientId },
    closeDrawer,
  } = useAppointmentDrawerSearchParams()
  const { warnIfAppointmentFallsToCashPay } = useWarnOnCashPay()
  const [isConfirmOpen, setIsConfirmOpen] = useState(false)
  const handleConfirm = (wasConfirmed: boolean) => {
    if (wasConfirmed) closeDrawer()
    setIsConfirmOpen(false)
  }
  const shouldShowConfirmDialog = () => {
    const appointmentSetId = new URLSearchParams(window.location.search).get('appointmentSet')
    if (appointmentSetId) return false

    if (audience === 'client') {
      return (schedule || reschedule) && timeSlot
    }
    return (schedule || reschedule) && timeSlot && schedulePatientId
  }

  const handleDrawerClose = () => {
    if (shouldShowConfirmDialog()) {
      setIsConfirmOpen(true)
    } else {
      closeDrawer()
    }
  }

  const handleAppointmentScheduled = (appointment: AppointmentForNotificationFragment) => {
    if (onAppointmentScheduled) {
      onAppointmentScheduled(appointment)
    }
    warnIfAppointmentFallsToCashPay(appointment.id)
  }

  return (
    <>
      <StyledDrawer
        size="min(400px, 100vw)"
        onClose={handleDrawerClose}
        isOpen={drawerOpen}
        PaperProps={{ className: csx(['py-2']) }}
        data-testid="appointment-drawer"
        transparentBackground={transparentBackground}
      >
        <div className="flex-column content-wrapper">
          {(() => {
            if (detail && appointmentId) {
              return <AppointmentDetail audience={audience} />
            }

            if (schedule && audience === 'client') {
              return <ScheduleAppointmentAsClient onAppointmentScheduled={handleAppointmentScheduled} />
            }

            if (schedule && includes(['admin', 'provider'], audience)) {
              return <ScheduleAppointmentAsProvider onAppointmentScheduled={handleAppointmentScheduled} />
            }

            if (cancel && appointmentId) {
              return <CancelAppointment audience={audience} />
            }

            if (cancel) {
              return <CancelApointmentSelect />
            }

            if (reschedule && appointmentId) {
              return <RescheduleAppointment audience={audience} onAppointmentScheduled={handleAppointmentScheduled} />
            }

            if (reschedule) {
              return <RescheduleApointmentSelect />
            }

            // TODO - should probably return a default that is like a 404 for the drawer
            return null
          })()}
        </div>
      </StyledDrawer>
      <Confirm
        onConfirm={(wasConfirmed: boolean) => handleConfirm(wasConfirmed)}
        isOpen={isConfirmOpen}
        cancelButton={<GhostButton onClick={() => setIsConfirmOpen(false)}>No, continue</GhostButton>}
        confirmButton={
          <FillButton variant="destroy" onClick={() => handleConfirm(true)} className="mt-1">
            Yes, exit
          </FillButton>
        }
        paddingSize="md"
      >
        <div className="display text-medium">Exit schedule without saving?</div>
      </Confirm>
    </>
  )
}

export function AppointmentDrawerPaddedContainer(props: HTMLAttributes<HTMLDivElement>) {
  return <div {...props} className={csx(['px-2', props.className])}></div>
}

const StyledDrawer = styled(Drawer)`
  .MuiPaper-root {
    background-color: ${eggshell};
  }

  .content-wrapper {
    height: 100%;
    overflow: auto;
  }
`
