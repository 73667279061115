import { styled } from '@mui/material'
import { isNil } from 'lodash'

import { CapStatus, EapCoverageDetailsFragment } from '@nuna/api'
import { type InvalidSponsorCoverageFragment } from '@nuna/core'
import { makeTypographyComponent } from '@nuna/tunic'

import { CapInfoForDisplay } from '../../../utils/cap-info.utils'
import { CapProgressBar } from '../../CapProgressBar'
import { CoverageLogo } from '../../CoverageTypography'
import eapBGSrc from '../img/eap-bg.png'
import { BaseCard, BaseCardProps } from './BaseCard'

interface Props extends BaseCardProps {
  details: EapCoverageDetailsFragment | InvalidSponsorCoverageFragment['details']
}

export function EAPCard({ details, ...props }: Props) {
  return (
    <CardWithBG {...props}>
      <div className="flex-column full-height text-center v-align">
        {details.employerLogo ? (
          <CoverageLogo src={details.employerLogo} alt={details.employerName} />
        ) : (
          <span className="large text-medium text-teal">{details.employerName}</span>
        )}

        {(() => {
          if ('invalidSponsorReason' in details) {
            return null // when this card is displayed with an invalid sponsor the boot displays the reason. If the design changes this could return text
          }

          if (isNil(details.sessionsCap)) {
            return <CardText>Unlimited sessions</CardText>
          }

          if (details.sessionsCap === 0) {
            return <CardText>Covered by insurance or card</CardText>
          }

          if (details.sessionsExhausted === 0) {
            return <CardText>{details.sessionsCap} Covered Sessions</CardText>
          }

          return (
            <div className="mt-auto full-width text-left p-2">
              <span className="caption text-medium">
                Remaining: {details.sessionsCap - details.sessionsExhausted} of {details.sessionsCap}
              </span>
              <CapProgressBar width="100%" capInfo={mapEAPDetailsToCapDisplay(details)} hasPaymentMethod={false} />
            </div>
          )
        })()}
      </div>
    </CardWithBG>
  )
}

function mapEAPDetailsToCapDisplay(details: EapCoverageDetailsFragment): CapInfoForDisplay {
  return {
    capStatus: details.sessionsCapStatus as CapStatus,
    sessionsAttended: details.sessionsAttended,
    sessionAllowanceToType: details.sessionsCap,
    sessionsCountingTowardsCap: details.sessionsExhausted,
  }
}

const CardText = makeTypographyComponent('caption mt-auto text-medium', 'span')

const CardWithBG = styled(BaseCard)`
  background-image: url(${eapBGSrc});
  background-size: cover;
  background-position: center;
`
