import { noop } from 'lodash'

import { CustomerCompanyDetailsFragment, useCustomerSftpInfoQuery } from '@nuna/api'
import { Skeleton, TextField } from '@nuna/tunic'

interface Props {
  customerCompany: CustomerCompanyDetailsFragment
}

export function SftpInfo({ customerCompany }: Props) {
  const { data, loading } = useCustomerSftpInfoQuery({ variables: { id: customerCompany.id } })

  if (loading || !data) {
    return <Skeleton width={100} height={20} dimensionUnits="%" />
  }

  const { sftpUsername, sftpCredential } = data.customerCompany

  const secretLabel = sftpCredential?.PublicKey ? 'Public Key' : 'Password'
  const secret = sftpCredential?.PublicKey ?? sftpCredential?.Password

  return (
    <div>
      <h2 className="h5">Secure File Transfer</h2>
      <div className="flex-column gap-2">
        <TextField label="Host" value={'transfer.tavahealth.com'} contentEditable={false} onChange={noop} />

        <TextField label="Port" value={'22'} contentEditable={false} onChange={noop} />

        <TextField label="Username" value={sftpUsername} contentEditable={false} onChange={noop} />

        <TextField label={secretLabel} type="password" value={secret} contentEditable={false} onChange={noop} />
      </div>
    </div>
  )
}
