import { styled } from '@mui/material'

import { AppointmentForVideoQuery } from '@nuna/api'
import { specialtiesService } from '@nuna/core'
import { BelowTablet, IconTherapyType, greySet } from '@nuna/tunic'

import { Divider } from './Divider'

interface Props {
  appointment: AppointmentForVideoQuery['internalAppointment']
}

export function TherapyType({ appointment }: Props) {
  if (!appointment?.therapyTypeSpecialty || !specialtiesService.isTherapyType(appointment.therapyTypeSpecialty.name)) {
    return null
  }

  return (
    <TherapyTypeContainer>
      <Divider />
      <TherapyTypeWrapper className="caption text-bold v-align">
        <IconTherapyType size={16} className="mr-1" therapyType={appointment.therapyTypeSpecialty.name} />
        <span>{appointment.therapyTypeSpecialty.name}</span>
      </TherapyTypeWrapper>
    </TherapyTypeContainer>
  )
}

const TherapyTypeWrapper = styled('span')`
  color: ${greySet[30].hex};
`

const TherapyTypeContainer = styled('div')`
  align-items: center;
  display: flex;
  transition: display 0.3s transform 0.3s;
  @media (${BelowTablet}) {
    display: none;
  }
`
