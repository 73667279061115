import { styled } from '@mui/material'

import { IconButton, IconClose, IconCommentFilled, greySet } from '@nuna/tunic'

import { useVideoCallContext } from '../../../context/VideoCallContext'
import { SidebarState } from '../../../context/hooks/useSidebarContext'
import { VideoChat } from '../../VideoChat'
import { SidebarContentWrapper } from './SidebarContentWrapper'

export function SidebarChat() {
  const { toggleSidebar } = useVideoCallContext()

  const handleClose = () => {
    toggleSidebar(SidebarState.Closed)
  }

  return (
    <Wrapper>
      <ChatSectionHeader className="v-align">
        <IconCommentFilled />
        <h2 className="h5 ml-2 mb-0">Chat</h2>
        <IconButton tooltip="Close chat" onClick={handleClose} className="ml-auto" variant="secondary">
          <IconClose size={20} />
        </IconButton>
      </ChatSectionHeader>
      <VideoChat />
    </Wrapper>
  )
}

const Wrapper = styled(SidebarContentWrapper)`
  display: flex;
  padding: 1rem;
  flex-direction: column;
`

const ChatSectionHeader = styled('header')`
  border-bottom: 1px solid ${greySet[30].hex};
  padding-bottom: 0.25rem;
`
