import { styled } from '@mui/material'
import moment from 'moment'
import { useState } from 'react'

import { UpcomingAppointmentQuery } from '@nuna/api'
import { IconSwitchRole, whiteTintSet } from '@nuna/tunic'

import { PatientVideoUser, TavaVideoUser } from '../../../../../types'

const SHORT_DATE = 'M/D [at]'
const LONG_DATE = '[Next:] MMM Do [at]'
const SHORT_TIME = 'h:mma'
const FULL_DATE_TIME = `${LONG_DATE} ${SHORT_TIME}`
const TIME_WITH_TIME_ZONE = `${SHORT_TIME} z`
const THEIR_TIME = `${SHORT_TIME} [their time]`
const YOUR_TIME = `${SHORT_TIME} [your time]`

interface Props {
  isMobile: boolean
  localUser: TavaVideoUser
  patient: PatientVideoUser | undefined
  upcomingAppointment: UpcomingAppointmentQuery['upcomingAppointmentForPatient']
}

export function NextAppointment({ upcomingAppointment, localUser, isMobile, patient }: Props) {
  const [isUsingClientTime, setIsUsingClientTime] = useState(patient?.timezone ?? false)

  const browserTimeZone = moment.tz.guess()
  const patientTimeZone = patient?.timezone ?? browserTimeZone // if for some reason tz is undefined, just act like they're in the same tz
  const isTimeZoneMismatch = patientTimeZone !== browserTimeZone
  const timeZoneToUse = isUsingClientTime && isTimeZoneMismatch ? patientTimeZone : browserTimeZone
  const isShowingTimeToggle = localUser.isProvider && isTimeZoneMismatch && !!upcomingAppointment

  const nextAppointmentDateTime = upcomingAppointment
    ? moment(upcomingAppointment.startDatetime).tz(timeZoneToUse)
    : moment()

  const appointmentDateText = () => {
    if (!upcomingAppointment) {
      return 'No Upcoming Session'
    }

    let format: string

    if (localUser.isProvider && isMobile && isTimeZoneMismatch) {
      format = SHORT_DATE
    } else if (localUser.isProvider && isTimeZoneMismatch) {
      format = LONG_DATE
    } else {
      format = FULL_DATE_TIME
    }

    return nextAppointmentDateTime.format(format)
  }

  const appointmentTimeText = () => {
    let format: string
    if (isMobile) {
      format = TIME_WITH_TIME_ZONE
    } else if (isUsingClientTime) {
      format = THEIR_TIME
    } else {
      format = YOUR_TIME
    }

    return nextAppointmentDateTime.format(format)
  }

  return (
    <>
      <span className="ml-1">{appointmentDateText()}</span>
      {isShowingTimeToggle && (
        <AppointmentTimeToggle className="ml-1 v-align" onClick={() => setIsUsingClientTime(!isUsingClientTime)}>
          <span>{appointmentTimeText()}</span>
          <span className="v-align">
            <IconSwitchRole size={18} />
          </span>
        </AppointmentTimeToggle>
      )}
    </>
  )
}

const AppointmentTimeToggle = styled('span')`
  cursor: pointer;
  border: 1px solid ${whiteTintSet[15]};
  border-radius: 6px;
  padding: 0 0.5rem;
  margin-top: -0.25rem; // neg margins can be ugly but this is the least worse evil in this case
  margin-bottom: -0.25rem;

  span:first-child {
    border-right: 1px solid ${whiteTintSet[15]};
    padding: 0.25rem 0.5rem 0.25rem 0;
  }

  span:last-child {
    padding: 0.25rem 0 0.25rem 0.5rem;
  }
`
