import { useEffect, useState } from 'react'

import { ConversationFragment, useConversationsQuery, usePollConversationsLazyQuery } from '@nuna/api'
import { useAuthDataContext } from '@nuna/auth'
import { useVisibilityChanged } from '@nuna/common'

import { useConversationUnreadContext } from '../context/ConversationUnreadContext'

const POLL_INTERVAL = 60 * 1000

export function useConversationsPolling() {
  const { login } = useAuthDataContext()
  const visible = useVisibilityChanged()
  const {
    data: initialData,
    refetch,
    loading: initialLoading,
  } = useConversationsQuery({ fetchPolicy: 'cache-and-network' })
  const [pollConversations, { stopPolling, loading: pollLoading, startPolling }] = usePollConversationsLazyQuery({
    fetchPolicy: 'no-cache',
    pollInterval: POLL_INTERVAL,
    notifyOnNetworkStatusChange: true,
    onCompleted: pollData => {
      if (!pollData) return

      if (pollData.conversations.length !== conversations.length) {
        refetch()
      } else {
        const updatedConversations = conversations.map(conversation => {
          const pollConversation = pollData.conversations.find(
            pollConversation => pollConversation.id === conversation.id,
          )
          if (pollConversation) {
            return {
              ...conversation,
              unreadCount: pollConversation.unreadCount,
              lastMessageId: pollConversation.lastMessageId,
            }
          }

          return conversation
        })

        setConversations(updatedConversations)
        setLastPollTime(Date.now())
      }
    },
  })

  const { updateLocalUnreadConversationStatusMap } = useConversationUnreadContext()
  const [conversations, setConversations] = useState<ConversationFragment[]>([])
  const [lastPollTime, setLastPollTime] = useState<number | null>(null)

  useEffect(() => {
    if (conversations.length > 0) {
      updateLocalUnreadConversationStatusMap(conversations)
    }
  }, [conversations, updateLocalUnreadConversationStatusMap])

  useEffect(() => {
    if (initialData) {
      setConversations(initialData.conversations)
    }
  }, [initialData])

  // kick off polling 1 min after mount
  useEffect(() => {
    const timer = setTimeout(pollConversations, POLL_INTERVAL)

    return () => {
      clearTimeout(timer)
      stopPolling()
    }
  }, [pollConversations, stopPolling])

  useEffect(() => {
    if (visible && login) {
      startPolling(POLL_INTERVAL)
    } else {
      stopPolling()
    }
  }, [visible, login, startPolling, stopPolling])

  return { conversations, lastPollTime, loading: pollLoading || initialLoading, refetchConversations: refetch }
}
