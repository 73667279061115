import { FormikErrors } from 'formik'
import { useEffect } from 'react'

import { buildDefaultFormStepConfig, useProviderSignupIntakeContext } from '../ProviderSignupIntakeContext'
import { ProviderSignupFormStepConfig, ProviderSignupFormValues } from './provider-signup-intake-types'

export function useProviderSignupIntakeStepSetup(
  config: Partial<ProviderSignupFormStepConfig>,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  validateForm: (values?: any) => Promise<FormikErrors<ProviderSignupFormValues>>,
) {
  const { setCurrentFormStepConfig, currentFormStepConfig } = useProviderSignupIntakeContext()
  useEffect(() => {
    setCurrentFormStepConfig({ ...buildDefaultFormStepConfig(), ...config })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setCurrentFormStepConfig]) // adding config to these deps like the linter wants causes an infinite loop

  useEffect(() => {
    validateForm()
  }, [currentFormStepConfig.validationSchema, validateForm])
}
