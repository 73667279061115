import { BlockWithAlignableContents } from '@lexical/react/LexicalBlockWithAlignableContents'
import { DecoratorBlockNode, SerializedDecoratorBlockNode } from '@lexical/react/LexicalDecoratorBlockNode'
import {
  $isElementNode,
  type DOMConversionMap,
  type DOMConversionOutput,
  type DOMExportOutput,
  type EditorConfig,
  type ElementFormatType,
  type LexicalEditor,
  type LexicalNode,
  type NodeKey,
  type SerializedLexicalNode,
  type Spread,
} from 'lexical'

import { AssessmentBundleType } from '@nuna/api'

import { LexicalAssessment } from '../components/LexicalAssessment'

type AssessmentComponentProps = Readonly<{
  className: Readonly<{
    base: string
    focus: string
  }>
  format: ElementFormatType | null
  loadingComponent?: JSX.Element | string
  nodeKey: NodeKey
  onError?: (error: string) => void
  onLoad?: () => void
  assessmentId: string | null
  assessmentType: AssessmentBundleType
}>

export type SerializedAssessmentNode = Spread<
  {
    id: string | null
    assessmentType: AssessmentBundleType
  },
  SerializedDecoratorBlockNode
>

export class AssessmentNode extends DecoratorBlockNode {
  __id: string | null
  __assessmentType: AssessmentBundleType

  constructor(id: string | null, assessmentType: AssessmentBundleType, format?: ElementFormatType, key?: NodeKey) {
    super(format, key)
    this.__id = id
    this.__assessmentType = assessmentType
  }

  static getType(): string {
    return 'assessment'
  }

  static clone(node: AssessmentNode): AssessmentNode {
    return new AssessmentNode(node.__id, node.__assessmentType, node.__format, node.__key)
  }

  static importJSON(serializedNode: SerializedAssessmentNode): AssessmentNode {
    const node = $createAssessmentNode(serializedNode.id, serializedNode.assessmentType)
    node.setFormat(serializedNode.format)
    return node
  }

  static importDOM(): DOMConversionMap<HTMLDivElement> | null {
    return {
      div: (domNode: HTMLDivElement) => {
        if (!domNode.hasAttribute('data-lexical-assessment-id')) {
          return null
        }
        return {
          conversion: convertAssessmentElement,
          priority: 2,
        }
      },
    }
  }

  exportJSON(): SerializedAssessmentNode {
    return {
      ...super.exportJSON(),
      id: this.getId(),
      assessmentType: this.getAssessmentType(),
      type: 'assessment',
      version: 1,
    }
  }

  exportDOM(): DOMExportOutput {
    const element = document.createElement('div')
    element.setAttribute('data-lexical-assessment-id', this.__id || '')
    element.setAttribute('data-lexical-assessment-type', this.__assessmentType)
    const text = document.createTextNode(this.getTextContent())
    element.append(text)
    return { element }
  }

  cloneWithNewId(newId: string): AssessmentNode {
    const newNode = $createAssessmentNode(newId, this.__assessmentType)
    return newNode
  }

  getId(): string | null {
    return this.__id
  }

  getAssessmentType(): AssessmentBundleType {
    return this.__assessmentType
  }

  decorate(editor: LexicalEditor, config: EditorConfig): JSX.Element {
    const embedBlockTheme = config.theme.embedBlock || {}
    const className = {
      base: embedBlockTheme.base || '',
      focus: embedBlockTheme.focus || '',
    }
    return (
      <AssessmentBlock
        className={className}
        format={this.__format}
        nodeKey={this.getKey()}
        assessmentId={this.__id}
        assessmentType={this.__assessmentType}
      />
    )
  }
}

export function $createAssessmentNode(
  assessmentId: string | null,
  assessmentType: AssessmentBundleType,
): AssessmentNode {
  return new AssessmentNode(assessmentId, assessmentType)
}

export function $isAssessmentNode(
  node: AssessmentNode | LexicalNode | SerializedLexicalNode | null | undefined,
): node is AssessmentNode {
  return node instanceof AssessmentNode
}

export const traverseNodes = (node: LexicalNode, nodes: AssessmentNode[] = []) => {
  if ($isElementNode(node)) {
    const children = node.getChildren()
    for (const child of children) {
      if ($isAssessmentNode(child)) {
        nodes.push(child)
      } else {
        traverseNodes(child, nodes)
      }
    }
  }
  return nodes
}

function convertAssessmentElement(domNode: HTMLDivElement): DOMConversionOutput | null {
  const id = domNode.getAttribute('data-lexical-assessment-id')
  const assessmentType = domNode.getAttribute('data-lexical-assessment-type')
  if (assessmentType && !(assessmentType in AssessmentBundleType)) {
    console.error('Invalid AssessmentBundleType')
    return null
  }

  if (id && assessmentType) {
    const node = $createAssessmentNode(id, assessmentType as AssessmentBundleType)
    return { node }
  }
  return null
}

function AssessmentBlock({ className, format, nodeKey, assessmentId, assessmentType }: AssessmentComponentProps) {
  return (
    <BlockWithAlignableContents className={className} format={format} nodeKey={nodeKey}>
      <LexicalAssessment assessmentId={assessmentId} assessmentType={assessmentType} nodeKey={nodeKey} />
    </BlockWithAlignableContents>
  )
}
