import { ReactNode, createContext, useContext, useEffect, useState } from 'react'

import { supportService } from '@nuna/telemetry'

interface GlobalWidgetContext {
  resourceCenterOpen: boolean
  toggleResourceCenter: () => void
  closeResourceCenter: () => void
  beaconOpen: boolean
  toggleBeacon: () => void
  closeBeacon: () => void
}

const GlobalWidgetContext = createContext<GlobalWidgetContext>({} as GlobalWidgetContext)

interface GlobalWidgetContextProviderProps {
  children: ReactNode
}

export function GlobalWidgetContextProvider({ children }: GlobalWidgetContextProviderProps) {
  const [resourceCenterOpen, setResourceCenterOpen] = useState(false)
  const [beaconOpen, setBeaconOpen] = useState(false)

  useEffect(() => {
    supportService.onOpenChat(() => setBeaconOpen(true))
    supportService.onCloseChat(() => setBeaconOpen(false))
  }, [setBeaconOpen])

  const toggleResourceCenter = () => {
    setResourceCenterOpen(!resourceCenterOpen)
  }

  const closeResourceCenter = () => {
    if (resourceCenterOpen) {
      setResourceCenterOpen(false)
    }
  }

  const toggleBeacon = () => {
    if (beaconOpen) {
      supportService.closeChat()
    } else {
      supportService.openChat()
    }
  }

  const closeBeacon = () => {
    if (beaconOpen) supportService.closeChat()
  }

  const value: GlobalWidgetContext = {
    beaconOpen,
    closeBeacon,
    toggleBeacon,
    resourceCenterOpen,
    closeResourceCenter,
    toggleResourceCenter,
  }

  return <GlobalWidgetContext.Provider value={value}>{children}</GlobalWidgetContext.Provider>
}

export function useGlobalWidgetContext() {
  return useContext(GlobalWidgetContext)
}
