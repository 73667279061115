import { IconProps } from './IconProps'

export function IconCautionCircle({ size = 24, color = 'currentColor', ...props }: IconProps) {
  return (
    <svg width={size} height={size} viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11 22C17.0751 22 22 17.0751 22 11C22 4.92487 17.0751 0 11 0C4.92487 0 0 4.92487 0 11C0 17.0751 4.92487 22 11 22ZM10.3975 13.5476L9.12676 4H12.8732L11.6098 13.5476H10.3975ZM12.2412 17.7238C12.0805 17.8475 11.8907 17.9436 11.6789 17.9986C11.4671 18.0535 11.2407 18.081 10.9997 18.081C10.7514 18.081 10.525 18.0535 10.3059 17.9986C10.0868 17.9436 9.89694 17.8543 9.74358 17.7238C9.58291 17.6002 9.45876 17.4353 9.37113 17.2293C9.27619 17.0232 9.23237 16.7691 9.23237 16.4668C9.23237 16.1577 9.27619 15.8967 9.37113 15.6907C9.45876 15.4846 9.58291 15.3129 9.74358 15.1892C9.90424 15.0656 10.0868 14.9763 10.3059 14.9214C10.525 14.8664 10.7514 14.8458 10.9997 14.8458C11.2407 14.8458 11.4671 14.8733 11.6789 14.9214C11.8907 14.9763 12.0805 15.0656 12.2412 15.1892C12.4019 15.3129 12.5333 15.4846 12.6283 15.6907C12.7232 15.8967 12.767 16.1577 12.767 16.4668C12.7743 16.7759 12.7232 17.0301 12.6283 17.2293C12.5333 17.4353 12.4019 17.6002 12.2412 17.7238Z"
        fill={color}
      />
    </svg>
  )
}
