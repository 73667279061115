export type IconDirection = 'up' | 'right' | 'down' | 'left'

const clockwiseDirections = ['up', 'right', 'down', 'left']

export function getIconRotation(direction: IconDirection, defaultDirection: IconDirection = 'left') {
  const defaultIndex = clockwiseDirections.indexOf(defaultDirection)
  const directionIndex = clockwiseDirections.indexOf(direction)

  const degrees = 90 * (directionIndex - defaultIndex)

  return `rotate(${degrees}deg)`
}
