import * as Yup from 'yup'
import { Form, Formik } from 'formik'
import { AnimatePresence } from 'framer-motion'
import { useRef } from 'react'

import { OrDivider, SocialLogin } from '@nuna/auth'
import { formService } from '@nuna/core'
import { CollapsePresence, ContextualAlert, FillButton, GhostButton, OutlineButtonLink, TextField } from '@nuna/tunic'

import { login } from '../../util/routes'
import { BaseSignupFormProps, PreValidateProps } from './shared'

const { composeHelperTextWithError } = formService

export const validationSchema = Yup.object().shape({
  firstName: Yup.string().required('First name is required'),
  lastName: Yup.string().required('Last name is required'),
  email: Yup.string().required('Email is required').email("Oops, that email isn't formatted correctly"),
})

export function SignupNameEmail({
  formValues,
  onStepSubmit,
  step,
  preValidateError,
  dismissPreValidateError,
}: BaseSignupFormProps & PreValidateProps) {
  const emailInputRef = useRef<HTMLInputElement>(null)

  return (
    <Formik
      initialValues={formValues}
      validationSchema={validationSchema}
      onSubmit={formValues => onStepSubmit(formValues, step)}
    >
      {({ handleChange, handleBlur, values, errors, touched, setFieldValue }) => {
        const fieldProps = (key: keyof typeof formValues) => ({
          name: key,
          value: values[key],
          onBlur: handleBlur,
          onChange: handleChange,
          ...composeHelperTextWithError('', errors[key], touched[key]),
        })

        return (
          <div className="my-6">
            <SocialLogin
              audience="provider"
              from="/"
              heading={<h2 className="h3 text-center">Get Started Today</h2>}
              intent="signup"
            />
            <OrDivider className="text-center mb-4 px-3">or</OrDivider>
            <Form>
              <TextField
                label="First name"
                type="text"
                inputProps={{
                  'data-testid': 'signup-first-name',
                }}
                className="mb-3"
                {...fieldProps('firstName')}
              />
              <TextField
                label="Last name"
                type="text"
                inputProps={{
                  'data-testid': 'signup-last-name',
                }}
                className="mb-3"
                {...fieldProps('lastName')}
              />
              <TextField
                label="Email"
                type="email"
                inputProps={{
                  'data-testid': 'signup-email',
                }}
                $inputRef={emailInputRef}
                className="mt-3 mb-2"
                {...fieldProps('email')}
              />
              <FillButton data-testid="signup-get-started" className="full-width mt-2" type="submit">
                Get Started
              </FillButton>
              <div className="mt-4">
                By proceeding, you acknowledge and agree to the{' '}
                <a
                  href="https://www.tavahealth.com/provider-terms-and-conditions"
                  target="_blank"
                  rel="noreferrer noopener"
                  className="text-bold text-primary"
                >
                  Service Provider and Software User Terms and Conditions
                </a>
                , and certify that you have read and understand it.
              </div>

              <AnimatePresence>
                {preValidateError && (
                  <CollapsePresence className="mt-3">
                    <ContextualAlert intent="urgent">
                      Oops, we encountered an error. If you already have an account, please log in instead.
                      <GhostButton
                        variant="secondary"
                        className="no-wrap ml-3"
                        onClick={() => {
                          setFieldValue('email', '')
                          emailInputRef.current?.focus()
                          dismissPreValidateError()
                        }}
                      >
                        Enter another
                      </GhostButton>
                      <OutlineButtonLink variant="secondary" className="ml-2" to={login}>
                        Login
                      </OutlineButtonLink>
                    </ContextualAlert>
                  </CollapsePresence>
                )}
              </AnimatePresence>
            </Form>
          </div>
        )
      }}
    </Formik>
  )
}
