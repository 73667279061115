/** In Safari, if an item has a border-radius and outline the outline does not respect the border radius like it does in other browsers. This util generates a workaround that behaves like outline */
export function safariBorderRadiusOutline(color: string, size: number, borderRadius: number) {
  return `
    &::before {
      content: '';
      position: absolute;
      top: -${size}px;
      left: -${size}px;
      right: -${size}px;
      bottom: -${size}px;
      border: ${color} solid ${size}px;
      border-radius: ${borderRadius}px;
    }
  `
}
