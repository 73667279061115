import { compact } from 'lodash'
import { HTMLAttributes } from 'react'

import { PaymentPreference, Role } from '@nuna/api'
import { useUserRole } from '@nuna/auth'
import { ProviderClientPaymentStatusAlert } from '@nuna/common'
import { DiscriminatedPatientCoverageFragment, coverageService } from '@nuna/core'
import { ContextualAlert, Grid, IconError } from '@nuna/tunic'

import { type ClientCoveragePatient } from '../../types'
import { CoverageFormDrawer } from '../CoverageFormDrawer'
import { SidebarPanel } from '../SidebarPanel'
import { AddCoverageButton } from './components/AddCoverageButton'
import { CoverageDetailsDrawer } from './components/CoverageDetailsDrawer'
import { PanelCoverageStatus } from './components/PanelCoverageStatus'
import { ClientCoveragePanelContextProvider, useClientCoveragePanelContext } from './context/ClientCoveragePanelContext'

interface Props extends HTMLAttributes<HTMLDivElement> {
  client: ClientCoveragePatient
  refetchClient: () => void
  isHorizontalLayout?: boolean
}

// wraps panels in context for handling drawers, etc.
export function ClientCoveragePanel({ client, refetchClient, isHorizontalLayout = false, ...props }: Props) {
  return (
    <ClientCoveragePanelContextProvider
      isHorizontalLayout={isHorizontalLayout}
      client={client}
      refetchClient={refetchClient}
    >
      {isHorizontalLayout ? <HorizontalLayout {...props} /> : <VerticalPanel {...props} />}
    </ClientCoveragePanelContextProvider>
  )
}

// Used in the sidebar of client profiles in harmony/ashoka
function VerticalPanel(props: HTMLAttributes<HTMLDivElement>) {
  const { client, coverages, openCoverageForm } = useClientCoveragePanelContext()
  const role = useUserRole()

  return (
    <SidebarPanel {...props}>
      <h2 className="h6">Coverage</h2>
      <ProviderClientPaymentStatusAlert scene={'clientInfo'} paymentStatus={client?.paymentStatus} />

      {role === Role.Provider && compact(Object.values(coverages)).length === 0 ? (
        <ContextualAlert>This client has not set up any coverage</ContextualAlert>
      ) : null}

      <ClientCoverages
        renderCoverage={coverage => <PanelCoverageStatus key={coverage.type} coverage={coverage} className="mb-1" />}
        renderAddButton={type => (
          <AddCoverageButton key={type} type={type} className="mb-1" onClick={openCoverageForm} />
        )}
      />
    </SidebarPanel>
  )
}

// Used on the coverage/billing page in arrow
function HorizontalLayout(props: HTMLAttributes<HTMLDivElement>) {
  const { openCoverageForm, coverages } = useClientCoveragePanelContext()

  const hasValidCoverage = coverageService.hasValidCoverage(coverages)

  return (
    <>
      {!hasValidCoverage && (
        <ContextualAlert className="mb-1" intent="urgent" icon={<IconError />}>
          Your sessions are not covered. Please resolve one or more coverage options.
        </ContextualAlert>
      )}
      <Grid container spacing={3} {...props} alignItems="flex-start">
        <ClientCoverages
          renderCoverage={coverage => (
            <Grid
              key={coverage.type}
              size={{
                xs: 12,
                sm: 6,
                md: 4,
                lg: 3,
              }}
            >
              <PanelCoverageStatus coverage={coverage} />
            </Grid>
          )}
          renderAddButton={type => (
            <Grid
              key={type}
              style={{ alignSelf: 'stretch' }}
              size={{
                xs: 12,
                sm: 6,
                md: 4,
                lg: 3,
              }}
            >
              <AddCoverageButton type={type} className="full-height" onClick={openCoverageForm} />
            </Grid>
          )}
        />
      </Grid>
    </>
  )
}

interface ClientCoveragesProps {
  renderCoverage: (coverage: DiscriminatedPatientCoverageFragment) => JSX.Element
  renderAddButton: (type: PaymentPreference) => JSX.Element
}

// Handles logic/state of triggering the drawers but allows for the caller to customize the rendering
function ClientCoverages({ renderCoverage, renderAddButton }: ClientCoveragesProps) {
  const {
    activeCoverageDetails,
    activeCoverageFormType,
    client,
    closeCoverageDetails,
    closeCoverageForm,
    coverages,
    refetchCoverage,
    sortedCoverageTypes,
  } = useClientCoveragePanelContext()

  const role = useUserRole()

  return (
    <>
      {sortedCoverageTypes.map(type => {
        const showAddButton = role !== Role.Provider && type !== PaymentPreference.Accesscode
        const coverage = coverages[type]

        if (coverage) {
          return renderCoverage(coverage)
        } else if (showAddButton) {
          return renderAddButton(type)
        }

        return null
      })}

      <CoverageDetailsDrawer coverage={activeCoverageDetails} onClose={closeCoverageDetails} />
      <CoverageFormDrawer
        client={client}
        coverageType={activeCoverageFormType}
        onClose={closeCoverageForm}
        onSubmitCommplete={refetchCoverage}
        size="min(400px, 100vw)"
      />
    </>
  )
}
