import { Role } from '@nuna/api'
import type { EnvironmentContextValues } from '@nuna/environment'

type ZDCallback = (...args: unknown[]) => void
type ZDField = {
  id: string
  value: string | number | boolean
}
type ZDPayload = ZDCallback | ZDField[] | string[]

export interface CustomWindow extends Window {
  Beacon?: (...args: unknown[]) => void
  zE?: (target: string, command: string, payload?: ZDPayload) => void
}

export type ZDUserFields = {
  email: string
  role: Role
  id: string
  patientId?: string | null
  providerId?: string | null
}

declare let window: CustomWindow

const HIDE_LAUNCHER_CSS = 'body #launcher { display: none; }'
const ZD_IFRAME_SELECTOR = 'iframe[name="Messaging window"]'

function sendZDCommand(target: string, command: string): void
function sendZDCommand(target: string, command: 'conversationFields', fields: ZDField[]): void
function sendZDCommand(target: string, command: 'conversationTags', fields: string[]): void
function sendZDCommand(target: string, command: 'loginUser' | 'open' | 'close', callback: ZDCallback): void
function sendZDCommand(target: string, command: string, payload?: ZDPayload) {
  if (!window.zE) return console.warn('ZenDesk not initialized!')

  window.zE(target, command, payload)
}

const showChat = () => {
  sendZDCommand('messenger', 'show')
}

const hideChat = () => {
  sendZDCommand('messenger', 'hide')
}

const openChat = () => {
  sendZDCommand('messenger', 'open')
}

const closeChat = () => {
  sendZDCommand('messenger', 'close')
}

const loginUser = (jwt: string, refreshCallback: () => Promise<unknown>) => {
  let previousJwt: string | undefined
  sendZDCommand('messenger', 'loginUser', callback => {
    if (typeof callback === 'function') {
      if (jwt !== previousJwt) {
        callback(jwt)
        previousJwt = jwt
      } else {
        refreshCallback()
      }
    }
  })
}

const logoutUser = () => {
  sendZDCommand('messenger', 'logoutUser')
}

const populateUserFields = (fields: ZDUserFields, environment: EnvironmentContextValues) => {
  let profileUrl = ''
  if (fields.role === Role.Patient) {
    profileUrl = `${environment.ASHOKA_BASE_URL}/clients/${fields.patientId}/profile`
  }
  if (fields.role === Role.Provider) {
    profileUrl = `${environment.ASHOKA_BASE_URL}/providers/${fields.providerId}/profile`
  }

  sendZDCommand('messenger:set', 'conversationFields', [
    {
      id: '18700321619732',
      value: fields.email,
    },
    {
      id: '18713657153940',
      value: fields.role,
    },
    {
      id: '18714372240660',
      value: profileUrl,
    },
  ])
}

const onOpenChat = (callback: () => void) => {
  sendZDCommand('messenger:on', 'open', callback)
}

const onCloseChat = (callback: () => void) => {
  sendZDCommand('messenger:on', 'close', callback)
}

const excludeFromTrackingOnOpen = () => {
  onOpenChat(() => {
    setTimeout(() => {
      const iframe = document.querySelector(ZD_IFRAME_SELECTOR)
      iframe?.classList?.add('fs-exclude')
    }, 10)
  })
}

const articles = {
  firstSession: 'https://tavahealth.zendesk.com/hc/en-us/articles/20286838197524',
  bioTips: 'https://tavahealthproviders.zendesk.com/hc/en-us/articles/20345514255252',
  photoTips: 'https://tavahealthproviders.zendesk.com/hc/en-us/articles/20345708772500',
  bestPractices:
    'https://therapist-support.tavahealth.com/hc/en-us/articles/20346375153428-Tava-provider-best-practices',
  events: 'https://tavahealthproviders.zendesk.com/hc/en-us/articles/20346427034644',
  coverageOptions: 'https://tavahealthproviders.zendesk.com/hc/en-us/articles/20291842225812',
  insurancePayers: 'https://tavahealthproviders.zendesk.com/hc/en-us/articles/20345520148884',
  calendarSync: 'https://tavahealthproviders.zendesk.com/hc/en-us/articles/20346362331028',
  diagnoses: 'https://tavahealthproviders.zendesk.com/hc/en-us/articles/20343107545492',
  cancellationPolicies: 'https://tavahealthproviders.zendesk.com/hc/en-us/articles/20324417954452',
  initialVisitNoteInstructions: 'https://tavahealthproviders.zendesk.com/hc/en-us/articles/20342163204372',
  noteInstructions: 'https://tavahealthproviders.zendesk.com/hc/en-us/articles/20324441113876',
  troubleshootingVideo: 'https://tavahealth.zendesk.com/hc/en-us/articles/20287610569364',
  cameraPermissions:
    'https://client-support.tavahealth.com/hc/en-us/articles/20288245614356-Supported-browsers-for-Tava-Video',
  changeMicAndCamera: 'https://tavahealth.zendesk.com/hc/en-us/articles/20287995630484',
  mentalHealthLibrary: 'https://tavahealth.zendesk.com/hc/en-us/sections/20289100213396-Mental-Health-Resources',
  therapistSupport: 'https://tavahealthproviders.zendesk.com/hc/en-us',
  insurance: 'https://tavahealth.zendesk.com/hc/en-us/articles/20287142470548',
  missingLicenseType: 'https://therapist-support.tavahealth.com/hc/en-us/articles/21640066135956',
  whatWillClientSee:
    'https://therapist-support.tavahealth.com/hc/en-us/articles/23995416212756-What-Will-Clients-I-Invite-See',
  licenseTypes: 'https://therapist-support.tavahealth.com/hc/en-us/articles/21640066135956-License-types',
} as const

const supportEmails = {
  providerSupport: 'therapist-support@tavahealth.com',
  clientSupport: 'client-support@tavahealth.com',
}

export const supportService = {
  HIDE_LAUNCHER_CSS,
  articles,
  showChat,
  hideChat,
  openChat,
  closeChat,
  onOpenChat,
  onCloseChat,
  loginUser,
  logoutUser,
  populateUserFields,
  excludeFromTrackingOnOpen,
  supportEmails,
}
