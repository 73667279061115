import { styled } from '@mui/material'
import { Component, ReactNode, RefObject, createRef } from 'react'
import { InView } from 'react-intersection-observer'

import { ConversationMessageFragment } from '@nuna/api'
import { toast } from '@nuna/tunic'

interface ConversationInfiniteScrollProps {
  items: ConversationMessageFragment[]
  children: (items: ConversationMessageFragment[]) => ReactNode
  loadNext: () => void
  loadPrev: () => void
  loading: boolean
  hasNext: boolean
  hasPrev: boolean
}

export class ConversationInfiniteScroll extends Component<ConversationInfiniteScrollProps> {
  containerRef: RefObject<HTMLDivElement>
  isLoadingNext: boolean

  constructor(props: ConversationInfiniteScrollProps) {
    super(props)
    this.containerRef = createRef<HTMLDivElement>()
    this.isLoadingNext = false
  }

  // one of the lifecycle methods that has no hooks equivalent yet and is basically made for this situation
  // https://reactjs.org/docs/react-component.html#getsnapshotbeforeupdate
  getSnapshotBeforeUpdate(prevProps: ConversationInfiniteScrollProps) {
    if (this.containerRef.current && prevProps.items.length < this.props.items.length) {
      return this.containerRef.current.scrollHeight - this.containerRef.current.scrollTop
    }

    return null
  }

  componentDidUpdate(_prevProps: ConversationInfiniteScrollProps, _prevState: never, snapshot: number) {
    const container = this.containerRef.current

    if (snapshot !== null && container && this.isLoadingNext) {
      container.scrollTop = container.scrollHeight - snapshot
    }
  }

  render() {
    const { hasNext, hasPrev, children, items, loadNext, loadPrev, loading } = this.props

    return (
      <ScrollContainer ref={this.containerRef}>
        {hasNext && (
          <InView
            // @ts-expect-error - Pretty sure the types are just wrong here
            as={MessageSkeleton}
            threshold={0.5}
            className="loading"
            onChange={inView => {
              if (!loading && inView) {
                this.isLoadingNext = true
                try {
                  loadNext()
                } catch (e) {
                  console.error(e)
                  toast.urgent('Unable to load newer messages')
                }
              }
            }}
          />
        )}

        {children(items)}

        {hasPrev && (
          <InView
            // @ts-expect-error - Pretty sure the types are just wrong here
            as={MessageSkeleton}
            threshold={0.5}
            className="loading mt-3"
            onChange={inView => {
              if (!loading && inView) {
                this.isLoadingNext = false
                try {
                  loadPrev()
                } catch (e) {
                  console.error(e)
                  toast.urgent('Unable to load older messages')
                }
              }
            }}
          />
        )}
      </ScrollContainer>
    )
  }
}

const MessageSkeleton = styled('div')`
  height: 100px;
  border-radius: var(--border-radius);
`

const ScrollContainer = styled('div')`
  max-height: 100%;
  overflow: auto;
  padding-left: 2px; // padding to allow shadows to not cut get off
  padding-right: 2px;
  padding-bottom: 3rem;
  font-size: 14px;
`
