import { DailyEventObjectAppMessage } from '@daily-co/daily-js'
import { HTMLAttributes, useCallback, useState } from 'react'

import { IconNotebook } from '@nuna/tunic'

import { useVideoCallContext } from '../../../context/VideoCallContext'
import { SidebarState } from '../../../context/hooks/useSidebarContext'
import { DailyMessage, useDailyMessage } from '../../../hooks/useDailyMessage'
import { TopbarToggleButton } from './TopbarToggleButton'

export function NotesToggleButton(props: HTMLAttributes<HTMLButtonElement>) {
  const { activeSidebar, toggleSidebar, localUser } = useVideoCallContext()
  const [showAlert, setShowAlert] = useState(false)

  const handleAutonotesToggle = useCallback(
    (e: DailyEventObjectAppMessage<DailyMessage>) => {
      if (e.data.type === 'autonotes-toggle' && activeSidebar !== SidebarState.Notes) {
        setShowAlert(true)
      }
    },
    [activeSidebar],
  )

  useDailyMessage(handleAutonotesToggle)

  if (!localUser.isProvider) return null

  return (
    <TopbarToggleButton
      {...props}
      data-component="video-notes-sidebar"
      active={activeSidebar === SidebarState.Notes}
      onClick={() => {
        setShowAlert(false)
        toggleSidebar(SidebarState.Notes)
      }}
      showAlert={showAlert}
    >
      <IconNotebook />
    </TopbarToggleButton>
  )
}
