import { styled } from '@mui/material'
import { HTMLAttributes } from 'react'

import { borderGrey } from '@nuna/tunic'

export interface BaseCardProps extends HTMLAttributes<HTMLDivElement> {
  contentsProps?: HTMLAttributes<HTMLDivElement>
}

export function BaseCard({ children, contentsProps, ...props }: BaseCardProps) {
  return (
    <RatioContainer {...props}>
      <CardContents {...contentsProps}>{children}</CardContents>
    </RatioContainer>
  )
}

const RatioContainer = styled('div')`
  background: #fff;
  border: 1px solid ${borderGrey};
  height: 0;
  padding-bottom: 62%;
  position: relative;
  width: 100%;
  border-radius: var(--border-radius);
`

const CardContents = styled('div')`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  padding: 1rem;
`
