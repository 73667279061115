import { styled } from '@mui/material'
import { HTMLAttributes } from 'react'

import { tealSet } from '../../styles/colorSets'

export interface DonutGaugeProps extends HTMLAttributes<HTMLOrSVGElement> {
  size?: number
  donutFill?: string
  valueFill?: string
  current?: number
  target?: number
}

export function DonutGauge({ size = 24, current, target, donutFill, valueFill, ...props }: DonutGaugeProps) {
  if (isNaN(current as number) || isNaN(target as number)) {
    return null
  }

  const center = size / 2
  const radius = center - 2
  const value = target === 0 ? 0 : Math.round(((current as number) / (target as number)) * 100)

  return (
    <StyledDonutGauge
      radius={radius}
      width={size}
      height={size}
      value={value}
      donutFill={donutFill}
      valueFill={valueFill}
      viewBox={`0 0 ${size} ${size}`}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <circle className="donut" cx={center} cy={center} r={radius} />
      {value > 0 && <circle className="donut-fill" cx={center} cy={center} r={radius} />}
    </StyledDonutGauge>
  )
}

const StyledDonutGauge = styled('svg')<{ radius: number; value: number; donutFill?: string; valueFill?: string }>`
  transform: rotate(-90deg);
  circle {
    stroke-width: 4px;
    fill: none;
  }
  .donut {
    stroke: ${({ donutFill = '#d9d9d9' }) => donutFill};
  }
  .donut-fill {
    stroke: ${({ valueFill = tealSet[50].hex }) => valueFill};
    position: relative;
    z-index: 1;
    stroke-dasharray: calc(${({ radius }) => radius} * 6);
    stroke-dashoffset: ${({ radius, value }) => `calc((${radius} * 6) - ((${radius} * 6) * ${value}) / 100)`};
    stroke-linecap: round;
  }
`
