import { styled } from '@mui/material'

import { appointmentService, numberService } from '@nuna/core'
import { CardBoot, CardBootProps, IconClock, salmonSet, yellowSet } from '@nuna/tunic'

import { AppointmentCardBootProps, AppointmentCardIntent, AppointmentCardStatus } from '../types'

export function Boot({
  appointment,
  appointmentStatus,
  intent,
  cancelPolicyStatus,
  audience,
}: AppointmentCardBootProps) {
  const cancelPolicy = cancelPolicyStatus?.cancelPolicy
  const subjectToFees = appointmentService.subjectToCancelationFee({
    policyAcceptedDate: cancelPolicyStatus?.acceptedDate,
    appointmentStartDatetime: appointment.startDatetime,
    cancelPeriodMinutes: cancelPolicy?.cancelPeriodMinutes,
  })
  const showCancelFee =
    appointmentStatus === AppointmentCardStatus.Canceling &&
    audience !== 'provider' &&
    subjectToFees &&
    !!cancelPolicy?.chargeAmount
  const { minutesFromNow, text } = appointmentService.upcomingAppointmentInfo(appointment.startDatetime, showCancelFee)
  const lessThanAddendum = minutesFromNow < 60 * 24 && minutesFromNow > 10 && !showCancelFee ? 'less than ' : ''

  return (
    <ExtendedBoot intent={intent}>
      <IconClock size={16} className="mr-xs" />
      <span className="caption">
        {minutesFromNow > 0 ? <>Starts in {lessThanAddendum}</> : 'Started '}
        <span className="text-medium">{text}</span>
        {showCancelFee && <span className="mx-1">•</span>}
      </span>

      {showCancelFee && (
        <span className="caption">
          Subject to{' '}
          <span className="text-medium">{numberService.centsToFormattedDollars(cancelPolicy?.chargeAmount)} fee</span>
        </span>
      )}
    </ExtendedBoot>
  )
}

interface ExtendedBootContainerProps extends Omit<CardBootProps, 'intent'> {
  intent?: AppointmentCardIntent
}

function ExtendedBoot({ intent, className, ...props }: ExtendedBootContainerProps) {
  if (intent === 'caution-action' || intent === 'caution') {
    return <StyledExtendedBoot className={`${intent} ${className}`} {...props} />
  }

  return <CardBoot className={className} intent={intent} {...props} />
}

const StyledExtendedBoot = styled(CardBoot)`
  &.caution-action {
    background-color: ${yellowSet[80].hex};
    color: #fff;
  }

  &.caution {
    background-color: ${salmonSet[15].hex};
    color: ${salmonSet[80].hex};
  }
`
