import { styled } from '@mui/material'
import { HTMLAttributes, useState } from 'react'

import { Collapse, IconChevron, borderGrey } from '@nuna/tunic'

interface Props extends HTMLAttributes<HTMLDivElement> {
  borderBottom?: boolean
  openByDefault?: boolean
  label: string
}

export function CollapsablePreferences({
  borderBottom = false,
  openByDefault = true,
  label,
  children,
  ...props
}: Props) {
  const [isOpen, setIsOpen] = useState(openByDefault)
  return (
    <Container borderBottom={borderBottom} {...props}>
      <button
        type="button"
        className="v-align collapse-button text-secondary text-medium"
        onClick={() => setIsOpen(!isOpen)}
      >
        <IconChevron direction={isOpen ? 'up' : 'right'} className="mr-1" />
        {label}
      </button>

      <Collapse isOpen={isOpen}>
        <div className="children-wrapper">{children}</div>
      </Collapse>
    </Container>
  )
}

const Container = styled('div')<{ borderBottom: boolean }>`
  border: solid ${borderGrey};
  border-width: 1px 0;
  border-bottom-width: ${({ borderBottom }) => (borderBottom ? '1px' : 0)};
  padding: var(--margin-1) 0;
  .collapse-button {
    padding: 16px 0;
    width: 100%;
  }

  .children-wrapper {
    padding-bottom: var(--margin-2);
  }
`
