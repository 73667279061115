/* 
  TODO - someone who is adventurous can figure out the proper way to type a single state autocomplete that supports
  multiple and single. Getting the conditional types correct with inference is the tricky part.

  Also, we should combine this with state select. This was added because autocomplete allows for typing and has a
  clear mechanism to clear the input
*/
import { Autocomplete, AutocompleteProps, TextField } from '@mui/material'
import FuzzySearch from 'fuzzy-search'
import { includes, isString } from 'lodash'
import { ReactNode, useMemo, useState } from 'react'

import { StateOption, addressService } from '@nuna/core'

const STATES = addressService.statesAsOptions()

type LimitedAutocompleteProps = Omit<
  AutocompleteProps<StateOption | undefined, false, boolean | undefined, false>,
  'onChange' | 'options' | 'renderInput' | 'value' | 'multiple'
>

interface Props extends LimitedAutocompleteProps {
  value?: StateOption | string | null
  onChange: (state: StateOption | undefined | null) => void
  label?: string
  placeholder?: string
  helperText?: string | ReactNode
  error?: boolean
  states?: string[]
}

export function StateAutocompleteSingle({
  value,
  onChange,
  label = 'State',
  placeholder,
  error,
  helperText,
  states,
  ...props
}: Props) {
  const [searchTerm, setSearchTerm] = useState('')

  const stateOptions = useMemo<StateOption[]>(() => {
    if (states && states.length) {
      return STATES.filter(state => includes(states, state.value))
    }

    return STATES
  }, [states])

  const valueAsOption = useMemo<StateOption | null | undefined>(() => {
    if (isString(value)) {
      return stateOptions.find(state => state.value === value) ?? null
    }
    return value
  }, [value, stateOptions])

  const filteredStates = useMemo<StateOption[]>(() => {
    const searcher = new FuzzySearch(stateOptions, ['label'], {
      caseSensitive: false,
    })
    if (!searchTerm) {
      return stateOptions
    }
    return searcher.search(searchTerm)
  }, [searchTerm, stateOptions])

  return (
    <Autocomplete
      {...props}
      onChange={(_event, value) => {
        onChange(value)
      }}
      getOptionLabel={state => state?.label ?? ''}
      isOptionEqualToValue={(option, value) => option?.value === value?.value}
      options={filteredStates}
      value={valueAsOption}
      ListboxProps={{ className: 'MuiAutocomplete-listbox' }}
      onInputChange={(_event, searchTerm) => setSearchTerm(searchTerm)}
      renderInput={params => (
        <TextField
          data-testid="state-autocomplete"
          name="selectdSpecialty"
          error={error}
          helperText={helperText}
          label={label}
          placeholder={placeholder}
          {...params}
        />
      )}
    />
  )
}
