import { error, interactiveFill } from '../styles/colors'
import { IconHoverProps } from './IconProps'

interface IconRescheduleAppointmentProps extends IconHoverProps {
  secondaryColor?: string
  urgent?: boolean
}

export function IconRescheduleAppointment({
  isHovered,
  urgent = false,
  size = 24,
  color = 'currentColor',
  secondaryColor = interactiveFill,
  ...props
}: IconRescheduleAppointmentProps) {
  const getCycleColor = () => {
    if (urgent) {
      return error
    }
    if (isHovered) {
      return secondaryColor
    }
    return color
  }

  return (
    <svg width={size} height={size} viewBox="0 0 24 24" fill={color} xmlns="http://www.w3.org/2000/svg" {...props}>
      <path d="M6.24906 19.0857C5.53691 19.0857 4.93569 18.4976 4.93569 17.7723V10.2739H17.4317C17.4317 10.4505 17.5431 10.5231 17.7104 10.5798C18.0584 10.6977 18.3928 10.8672 18.7053 11.058C18.86 11.1525 19.0833 11.0495 19.0833 10.8683V7.64961C19.0833 6.20651 17.9038 5.0358 16.4497 5.0358L16.4444 3.72627C16.4444 3.00603 15.8507 2.41675 15.125 2.41675C14.3993 2.41675 13.8056 3.00603 13.8056 3.72627V5.0358H8.52778V3.72627C8.52778 3.00603 7.93403 2.41675 7.20833 2.41675C6.48264 2.41675 5.88889 3.00603 5.88889 3.72627V5.0358C4.42958 5.0358 3.25 6.20651 3.25 7.64961V18.1363C3.25 19.5794 4.42958 20.7501 5.88361 20.7501H10.1479C10.3394 20.7501 10.4361 20.5056 10.3116 20.3601C10.0365 20.0389 9.77223 19.7042 9.59909 19.3352C9.52853 19.1848 9.37937 19.0857 9.21329 19.0857H6.24906Z" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        fill={getCycleColor()}
        d="M19.8803 13.9086L19.4319 13.4536L18.7778 12.8564C16.896 10.977 13.8965 11.2598 12.1276 13.1692C10.3627 15.0742 10.0084 18.243 12.0422 20.2769C13.5681 21.8027 15.7965 22.2744 17.6731 21.5013C17.8058 21.4467 17.9402 21.357 18.0425 21.2332C18.1499 21.1033 18.2076 20.987 18.2172 20.8351C18.2342 20.5673 18.1179 20.2976 18.0102 20.0822L18.0101 20.0818L18.0092 20.0802L18.009 20.0798L18.0084 20.0785L18.0082 20.078L18.0079 20.0775L18.0038 20.0693L18.0035 20.0688L18.0024 20.0666L17.9979 20.0575L17.9897 20.0412L17.9884 20.0385L17.9881 20.0379L17.9878 20.0374L17.9873 20.0363L17.9867 20.0351L17.9864 20.0345L17.9837 20.0291L17.9834 20.0284L17.983 20.0278L17.9827 20.0272L17.9824 20.0265L17.8272 19.6935C17.7533 19.5457 17.6576 19.4135 17.5291 19.3147C17.3953 19.2117 17.2859 19.1616 17.1418 19.1491C17.0061 19.1373 16.8817 19.1588 16.7856 19.1817C16.7118 19.1992 16.6331 19.2235 16.5703 19.2428C16.5545 19.2477 16.5396 19.2522 16.5262 19.2563C16.0044 19.4138 15.6262 19.4993 15.2679 19.4417C14.9213 19.3859 14.4451 19.1947 13.8541 18.617C13.3488 18.123 13.1829 17.3535 13.2407 16.6304C13.2991 15.8982 13.592 15.2005 13.9029 14.8754C14.2379 14.525 14.8252 14.1801 15.3979 14.0792C15.9559 13.9809 16.5516 14.0792 17.0729 14.6238L17.6917 15.2521L18.1075 15.6653L16.8234 15.6754C16.3753 15.6813 16.0913 15.9861 16.0841 16.4358L16.0723 17.0593C16.065 17.5166 16.3508 17.9482 16.8101 17.9421L21.4131 17.8806C21.8077 17.8754 22.1301 17.5543 22.1353 17.1584L22.1967 12.6052C22.2027 12.1479 21.8327 11.8805 21.3733 11.8878L20.7002 11.8997C20.2522 11.9068 19.8862 12.1693 19.8803 12.6186V13.9086Z"
      />
    </svg>
  )
}
