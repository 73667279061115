import * as Yup from 'yup'
import { Formik } from 'formik'

import { DocumentType, HistorySaveType, useDocumentUpload, useSavePatientHistoryItemMutation } from '@nuna/api'
import { FileUpload, GhostButton, Select, TextField, toast } from '@nuna/tunic'

const historyItemSchema = Yup.object().shape({
  itemType: Yup.string().required(),
  note: Yup.string(),
})

interface ClientHistoryItemFormProps {
  onCancel: () => void
  onSuccess: () => void
  patientId: string
}

const acceptedFileTypes = [
  'application/msword', // doc
  'application/pdf',
  'application/rtf',
  'application/vnd.oasis.opendocument.text', // odt
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document', // docx
  'application/x-tex',
  'image/jpeg',
  'image/png',
  'image/svg+xml',
  'text/plain',
]

export function ClientHistoryItemForm({ patientId, onSuccess, onCancel }: ClientHistoryItemFormProps) {
  const { uploadDocument, loading: isLoadingDocuments } = useDocumentUpload()
  const [savePatientHistoryItem, { loading }] = useSavePatientHistoryItemMutation({
    update(cache, { data }) {
      const newItem = data?.savePatientHistoryItem

      if (!newItem) {
        return
      }

      cache.modify({
        fields: {
          patientHistory(existingHistory = []) {
            return [...existingHistory, newItem]
          },
        },
      })
    },
  })

  const isLoading = loading || isLoadingDocuments

  const handlePatientHistoryItemSubmit = async ({
    itemType,
    note,
    documentId,
  }: {
    itemType: HistorySaveType
    note: string
    documentId?: string
  }) => {
    try {
      await savePatientHistoryItem({
        variables: {
          patientId,
          date: new Date(),
          itemType,
          note,
          documentId,
        },
      })

      onSuccess()
    } catch (e) {
      console.error(e)
      toast.urgent('Failed to save client history item')
    }
  }

  return (
    <Formik
      validationSchema={historyItemSchema}
      initialValues={{
        itemType: HistorySaveType.HistoryCareCoordinationMeta,
        note: '',
      }}
      onSubmit={handlePatientHistoryItemSubmit}
    >
      {({ values, handleChange, handleSubmit, setFieldValue }) => (
        <form style={{ padding: '1px 0' }} className="v-align full-width" onSubmit={handleSubmit}>
          <Select
            style={{ width: 210, flex: '0 0 auto' }}
            className="mr-2"
            label="Event Type"
            name="itemType"
            onChange={handleChange}
            value={values.itemType}
          >
            <option value={HistorySaveType.HistoryCareCoordinationMeta}>Care Coordination</option>
            <option value={HistorySaveType.HistoryClinicalConsultationMeta}>Clinical Consultation</option>
            <option value={HistorySaveType.HistoryFileUploadMeta}>Official Documentation</option>
            <option value={HistorySaveType.HistoryReferralMeta}>Referral</option>
          </Select>

          {values.itemType === HistorySaveType.HistoryFileUploadMeta ? (
            <FileUpload
              onDrop={async files => {
                try {
                  const document = await uploadDocument(files[0], DocumentType.ClientDocument)
                  if (!document) throw new Error('failed to upload document')

                  setFieldValue('documentId', document.id)
                } catch (e) {
                  toast.urgent('Unable to upload file')
                }
              }}
              maxSize={25000000}
              accept={acceptedFileTypes}
            />
          ) : (
            <TextField
              className="fs-exclude"
              name="note"
              onChange={handleChange}
              value={values.note}
              rows={1}
              label="Note"
              style={{ flex: '1 0 auto' }}
              autoFocus
            />
          )}

          <div className="ml-2" style={{ flex: '0 0 auto' }}>
            <GhostButton className="mr-2" variant="secondary" type="button" onClick={onCancel}>
              Cancel
            </GhostButton>
            <GhostButton type="submit" isLoading={isLoading}>
              Submit
            </GhostButton>
          </div>
        </form>
      )}
    </Formik>
  )
}
