import { IconProps } from './IconProps'

export function IconCalendar({ size = 24, color = 'currentColor', ...props }: IconProps) {
  return (
    <svg width={size} height={size} viewBox="0 0 38 38" fill={color} xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        fillRule="evenodd"
        d="M9.03011 28.2356C9.03011 29.3962 9.99207 30.337 11.1315 30.337H26.9224C28.0829 30.337 29.0238 29.4195 29.0238 28.2356V16.2382H9.03011V28.2356ZM27.4441 5.76199C27.4441 4.60961 26.4941 3.66675 25.333 3.66675C24.1719 3.66675 23.2219 4.60961 23.2219 5.76199V7.85722H14.7775V5.76199C14.7775 4.60961 13.8275 3.66675 12.6663 3.66675C11.5052 3.66675 10.5552 4.60961 10.5552 5.76199V7.85722C8.22034 7.85722 6.33301 9.73037 6.33301 12.0393V28.818C6.33301 31.1269 8.22034 33.0001 10.5468 33.0001H27.4526C29.779 33.0001 31.6663 31.1269 31.6663 28.818V12.0393C31.6663 9.73037 29.779 7.85722 27.4526 7.85722L27.4441 5.76199Z"
      />
    </svg>
  )
}
