import { styled } from '@mui/material'
import React from 'react'

import { Scheme } from '../../types/Scheme'
import { BaseInput, BaseInputProps, RawInputProps, inputStyles } from '../BaseInput/BaseInput'
import caretDark from './img/select-caret-dark.svg'
import caret from './img/select-caret.svg'

export interface SelectProps extends Omit<BaseInputProps, 'children' | 'maxCharacters'>, RawInputProps {
  children: React.ReactNode
}

export function Select({
  className,
  disabled,
  error,
  helperText,
  id: passedId,
  label,
  scheme,
  onBlur,
  onFocus,
  renderIcon,
  style,
  value,
  showBottomBorderOnFocus,
  inputProps = {},
  shrink,
  ...props
}: SelectProps) {
  return (
    <BaseInput
      className={className}
      error={error}
      helperText={helperText}
      id={passedId}
      label={label}
      onBlur={onBlur}
      onFocus={onFocus}
      renderIcon={renderIcon}
      style={style}
      value={value}
      scheme={scheme}
      disabled={disabled}
      showBottomBorderOnFocus={showBottomBorderOnFocus}
      shrink={shrink}
    >
      {(onFocus, onBlur, id) => (
        <SelectInput
          {...props}
          {...inputProps}
          disabled={disabled}
          scheme={scheme}
          id={id}
          onBlur={onBlur}
          onFocus={onFocus}
          value={value}
        />
      )}
    </BaseInput>
  )
}

const SelectInput = styled('select')<{ scheme?: Scheme; hideUnderline?: boolean }>`
  ${inputStyles};

  // TODO: this should take hover colors too so at some point this needs to be rendered on page instead of bg image
  // prettier-ignore
  background-image: url("${({ scheme = 'light' }) => (scheme === 'dark' ? caretDark : caret)}");
  background-position: right center;
  background-repeat: no-repeat;
  background-size: 12px auto;
  padding-right: 1rem;
`
