import { EffectCallback, useEffect, useRef } from 'react'

// took idea from here https://www.30secondsofcode.org/react/s/use-effect-once/
export function useEffectOnce(effect: EffectCallback, condition = true) {
  const hasRun = useRef(false)

  useEffect(() => {
    if (condition && !hasRun.current) {
      hasRun.current = true
      return effect()
    }
  }, [condition, effect])
}
