import { HtmlHTMLAttributes, ReactNode } from 'react'

import {
  AppointmentChangeReason,
  AppointmentCollectionStatusQuery,
  AppointmentForDrawerQuery,
  ExtendedClientQuery,
  PatientContextQuery,
} from '@nuna/api'
import { Audience } from '@nuna/core'
import { Intent } from '@nuna/tunic'

export type AppointmentCardIntent = Intent | 'caution-action'

export interface AppointmentUser {
  id: string
  firstName: string
  lastName: string
  timezone?: string | null
  avatarUrl?: string | null
}

export enum AppointmentCardStatus {
  Active = 'ACTIVE',
  Canceled = 'CANCELED',
  Canceling = 'CANCELING',
  Rescheduled = 'RESCHEDULED',
  NoShow = 'NO_SHOW',
}

export enum AppointmentChargeStatus {
  Failure = 'FAILURE',
  Pending = 'PENDING',
  Scheduled = 'SCHEDULED',
  Success = 'SUCCESS',
  Waived = 'WAIVED',
}

interface AppointmentCardAppointmentBase {
  startDatetime: string
  endDatetime: string
}

export enum AppointmentCardFeeType {
  LateCancel = 'LATE_CANCEL',
  NoShow = 'NO_SHOW',
}

interface AppointmentCancelPolicy {
  cancelPeriodMinutes: number
  chargeAmount: number
  type?: AppointmentCardFeeType
}

interface AppointmentCancelPolicyStatus {
  acceptedDate?: string | null
  cancelPolicy?: AppointmentCancelPolicy | null
}

export type AppointmentCardAppointment = AppointmentCardAppointmentBase &
  (
    | NonNullable<AppointmentForDrawerQuery['internalAppointment']>
    | NonNullable<AppointmentCollectionStatusQuery['appointmentCollectionStatus']>
    | NonNullable<PatientContextQuery['patientContext']['patient']['appointments'][0]>
    | NonNullable<ExtendedClientQuery['patient']['appointments'][0]>
  ) & {
    currentChangeReason?: AppointmentChangeReason | null
    currentFreeFormReason?: string | null
  }

interface AdditionalSlots {
  detailTop?: ReactNode
  detailBottom?: ReactNode
  cardRight?: ReactNode
}

interface AppointmentCardPropsBase {
  appointment: AppointmentCardAppointment
  appointmentStatus: AppointmentCardStatus
  previousAppointmentDatetime?: string | null
  audience: Audience
  /** Intent is generally inferred from appointmentStatus and appointment startDatetime, but can be overridden  */
  intent?: AppointmentCardIntent
  /** The card will always fill the width of the parent. The condensed prop determines if a status label should appear on the right side of the card. */
  condensed?: boolean
  client?: AppointmentUser | null
  provider?: AppointmentUser | null
  showAvatar?: boolean
  cancelPolicyStatus?: AppointmentCancelPolicyStatus | null
  chargeStatus?: AppointmentChargeStatus | null
  detail?: ReactNode
  boot?: boolean | ReactNode
  additionalSlots?: AdditionalSlots
  hideChargeStatus?: boolean
  showTopStatus?: boolean
}

export type AppointmentCardProps = AppointmentCardPropsBase & HtmlHTMLAttributes<HTMLDivElement>

interface AppointmentCardDetailPropsBase {
  stackedBorderColor?: string
}

export type AppointmentCardDetailProps = AppointmentCardDetailPropsBase &
  AppointmentCardPropsBase &
  HtmlHTMLAttributes<HTMLDivElement>

export type AppointmentCardBootProps = AppointmentCardPropsBase & HtmlHTMLAttributes<HTMLDivElement>
