import { styled } from '@mui/material'
import { useCallback } from 'react'

import {
  ProviderDirectory,
  ProviderDirectoryType,
  useProviderDirectoriesQuery,
  useSaveProviderDirectoriesMutation,
} from '@nuna/api'
import { dateService, numberService } from '@nuna/core'
import { supportService } from '@nuna/telemetry'
import {
  Card,
  CardBody,
  CardBoot,
  Drawer,
  GhostButtonExternalLink,
  IconExport,
  OutlineButton,
  greySet,
  tealSet,
  toast,
} from '@nuna/tunic'

import { useDirectoriesContext } from './DirectoriesContext'
import { DirectoryLogo } from './DirectoryLogo'
import { RECOMMENDED_DIRECTORIES, formatType } from './utils'

type Props = {
  sessionRate: number
  providerId: string
  directoryData: ProviderDirectory[] | undefined
}

const PSYCH_TODAY_MONTHLY_COST = 3000

const renderCardBoot = (currentDirectoryData: ProviderDirectory | undefined) => {
  if (!currentDirectoryData) {
    return
  }
  if (currentDirectoryData.enabled && !!currentDirectoryData.lastEnabledAt) {
    return (
      <CardBoot intent="information">
        Active since {dateService.formatDate(currentDirectoryData.lastEnabledAt, 'MM/DD/YY')}
      </CardBoot>
    )
  }
  if (!currentDirectoryData.enabled && !!currentDirectoryData.lastDisabledAt) {
    return (
      <CardBoot intent="default">
        Paused on {dateService.formatDate(currentDirectoryData.lastDisabledAt, 'MM/DD/YY')}
      </CardBoot>
    )
  }
  return null
}

export function DirectoryDrawer({ sessionRate, providerId, directoryData }: Props) {
  const { closeDrawer, selectedDirectoryType } = useDirectoriesContext()

  const [saveProviderDirectories, { loading: saveDirectoryLoading }] = useSaveProviderDirectoriesMutation()
  const { refetch } = useProviderDirectoriesQuery({
    variables: { providerId },
    fetchPolicy: 'cache-and-network',
  })

  const handleConfirmJoinClick = useCallback(
    async (directoryType: ProviderDirectoryType) => {
      try {
        const response = await saveProviderDirectories({
          variables: {
            directories: [{ directoryType: directoryType, enabled: true }],
            providerId,
          },
        })
        if (response.errors) {
          throw new Error('Response had errors')
        }
        refetch()
        closeDrawer()
      } catch (e) {
        console.error(e)
        toast.urgent('Failed to join directory')
      }
    },
    [providerId, saveProviderDirectories, refetch, closeDrawer],
  )

  const currentDirectoryData = directoryData?.find(directory => directory.directoryType === selectedDirectoryType)

  if (!selectedDirectoryType) {
    return null
  }

  const formattedDirectoryType = formatType(selectedDirectoryType)
  return (
    <Drawer blurBackground={false} size="450px" onClose={closeDrawer} isOpen={!!selectedDirectoryType}>
      <div className="p-3">
        <div className="baseline-align">
          <DirectoryLogo className="mr-2" directoryType={selectedDirectoryType} />

          <h4>{formatType(selectedDirectoryType)} details</h4>
        </div>
        <Card>
          <CardBody>
            <div>
              <div className="baseline-align text-medium">
                <RateNumber className="h3 mb-0">{numberService.centsToFormattedDollars(sessionRate)}</RateNumber>
                <RateNumberSubtext>per session</RateNumberSubtext>
              </div>
              <p className="caption mt-0 pt-0">when clients find you through {formattedDirectoryType}.</p>
            </div>
            {selectedDirectoryType === ProviderDirectoryType.PsychologyToday && (
              <div className="mt-4">
                <div className="baseline-align text-medium">
                  <RateNumber className="h3 mb-0">
                    {numberService.centsToFormattedDollars(PSYCH_TODAY_MONTHLY_COST)}
                  </RateNumber>
                  <RateNumberSubtext>cost savings</RateNumberSubtext>
                </div>
                <p className="caption">each month when Tava covers your {formattedDirectoryType} account.</p>
              </div>
            )}
          </CardBody>
          {renderCardBoot(currentDirectoryData)}
        </Card>
        {!currentDirectoryData && (
          <div className="mt-4">
            <OutlineButton
              isLoading={saveDirectoryLoading}
              className="full-width"
              onClick={() => handleConfirmJoinClick(selectedDirectoryType)}
            >
              Confirm you're joining {formatType(selectedDirectoryType)}
            </OutlineButton>
          </div>
        )}

        {currentDirectoryData && currentDirectoryData.directoryUrl ? (
          <div className="flex-end mt-3">
            <GhostButtonExternalLink href={currentDirectoryData.directoryUrl}>
              Your {formatType(selectedDirectoryType)} Profile <IconExport />
            </GhostButtonExternalLink>
          </div>
        ) : (
          <div className="flex-end mt-3">
            <GhostButtonExternalLink variant="secondary" href={RECOMMENDED_DIRECTORIES[selectedDirectoryType].url}>
              {RECOMMENDED_DIRECTORIES[selectedDirectoryType].linkText} <IconExport className="ml-1" />
            </GhostButtonExternalLink>
          </div>
        )}

        <div className="mt-5">
          <InfoHeader className="mb-2">What to expect when you turn referrals on/off</InfoHeader>
          <WhatToExpectText>
            With {formattedDirectoryType} referrals on, potential clients will be able to find your Tava-created profile
            on {formattedDirectoryType}, which includes a link to your Practice Website where they can book sessions
            with you.
          </WhatToExpectText>
          <WhatToExpectText>
            When {formattedDirectoryType} referrals are off, potential clients will still see your directory profile and
            your Practice website, but won’t be able to book sessions with you.
          </WhatToExpectText>
          <WhatToExpectText>
            If you want your profile completely removed from this directory, please reach out to
            <br />
            <SupportEmail href={`mailto:${supportService.supportEmails.providerSupport}`}>
              support@tavahealth.com
            </SupportEmail>
          </WhatToExpectText>
        </div>
      </div>
    </Drawer>
  )
}

const InfoHeader = styled('p')`
  color: ${greySet[90].hex};
  font-weight: 500;
`

const RateNumber = styled('h5')`
  color: ${tealSet[70].hex};
  padding-bottom: 0;
  margin-bottom: 0;
`

const RateNumberSubtext = styled('span')`
  font-size: 15px;
`

const WhatToExpectText = styled('p')`
  margin-bottom: var(--margin-3);
  color: ${greySet[90].hex};
  line-height: 1.4;
`

const SupportEmail = styled('a')`
  text-decoration: underline;
  color: ${tealSet[80].hex};
  font-weight: 500;
`
