import { isNil } from 'lodash'

import { AccessCodeCoverageDetailsFragment, PaymentPreference } from '@nuna/api'
import { Avatar } from '@nuna/tunic'

import { AccessCodeCard } from '../../../../CoverageCard/components/AccessCodeCard'
import { CoverageTypeIcon } from '../../../../CoverageTypeIcon'
import { CoverageList, CoverageListItem, CoverageSection, CoverageSectionHeading } from '../../../../CoverageTypography'
import { DefaultTherapyTypeListItems } from '../../../../DefaultTherapyTypeListItems'
import { PreferenceCoverageSection } from './PreferenceCoverageSection'

interface Props {
  details: AccessCodeCoverageDetailsFragment
}

export function AccessCodeDetails({ details }: Props) {
  const { sessionsCap, providers } = details

  return (
    <>
      <h3 className="h5 v-align">
        <CoverageTypeIcon className="mr-1" type={PaymentPreference.Accesscode} /> Access Code Detail
      </h3>

      <AccessCodeCard details={details} />

      <PreferenceCoverageSection type={PaymentPreference.Accesscode} />

      <CoverageSection>
        <CoverageSectionHeading>Includes</CoverageSectionHeading>

        <CoverageList>
          {isNil(sessionsCap) ? (
            <p>Unlimited covered sessions</p>
          ) : (
            <CoverageListItem adornment={sessionsCap}>Covered sessions</CoverageListItem>
          )}

          <DefaultTherapyTypeListItems />
        </CoverageList>
      </CoverageSection>

      <CoverageSection>
        <CoverageSectionHeading>Care Team</CoverageSectionHeading>

        <CoverageList>
          {providers.map(provider => (
            <CoverageListItem key={provider.id} adornment={<Avatar size="mini" url={provider.avatarUrl} />}>
              {provider.firstName} {provider.lastName}
            </CoverageListItem>
          ))}
        </CoverageList>
      </CoverageSection>
    </>
  )
}
