import { noop, some } from 'lodash'
import { ReactNode, createContext, useCallback, useContext, useEffect, useState } from 'react'

import { ConversationFragment } from '@nuna/api'
import { useAuthDataContext } from '@nuna/auth'

import { useUnreadMessageCountPolling } from '../../hooks/useUnreadMessageCountPolling'
import { ConversationUnreadStatus } from './util/ConversationUnreadStatus'

interface ConversationUnreadContextValues {
  totalUnreadCount: number
  localUnreadConversationStatusMap: Map<string, ConversationUnreadStatus>
  hasAnyUnread: () => boolean
  hasUnread: (conversationId: string) => boolean
  updateLastReadMessageForConversation: (conversationId: string, lastReadMessageId: string) => void
  updateLocalUnreadConversationStatusMap: (conversations: ConversationFragment[]) => void
}

const ConversationUnreadContext = createContext<ConversationUnreadContextValues>({
  totalUnreadCount: 0,
  localUnreadConversationStatusMap: new Map(),
  hasAnyUnread: () => false,
  hasUnread: () => false,
  updateLastReadMessageForConversation: noop,
  updateLocalUnreadConversationStatusMap: noop,
})

export function ConversationUnreadContextProvider({ children }: { children: ReactNode }) {
  const { unreadCount: totalUnreadCount, lastPollTime } = useUnreadMessageCountPolling()
  const [lastLocalUpdateTime, setLastLocalUpdateTime] = useState<number | null>(null)

  const { login } = useAuthDataContext()

  const [localUnreadConversationStatusMap, setLocalUnreadConversationStatusMap] = useState<
    Map<string, ConversationUnreadStatus>
  >(new Map())

  useEffect(() => {
    if (localUnreadConversationStatusMap.size > 0) {
      setLastLocalUpdateTime(Date.now())
    }
  }, [localUnreadConversationStatusMap])

  const hasAnyUnread = () => {
    // we have a new unread count from the poll but are not on the conversations page so the local cache is out of date
    if (lastPollTime && lastLocalUpdateTime && lastPollTime > lastLocalUpdateTime) {
      return totalUnreadCount > 0
    }

    // if no local cache just use count from poll
    if (localUnreadConversationStatusMap.size === 0) {
      return totalUnreadCount > 0
    }

    // use local cache to determine unread status
    return some(Array.from(localUnreadConversationStatusMap.values()), conversationUnreadStatus =>
      conversationUnreadStatus.hasUnread(),
    )
  }

  const hasUnread = (conversationId: string) => {
    const conversationStatus = localUnreadConversationStatusMap.get(conversationId)

    if (conversationStatus) {
      return conversationStatus.hasUnread()
    }

    return false
  }

  const updateLastReadMessageForConversation = (conversationId: string, lastReadMessageId: string) => {
    const conversationStatus = localUnreadConversationStatusMap.get(conversationId)

    if (conversationStatus) {
      setLocalUnreadConversationStatusMap(
        new Map(
          localUnreadConversationStatusMap.set(
            conversationId,
            conversationStatus.updateLastReadMessageId(lastReadMessageId),
          ),
        ),
      )
    } else {
      console.warn('Could not find conversation')
    }
  }

  const updateLocalUnreadConversationStatusMap = useCallback(
    (conversations: ConversationFragment[]) => {
      setLocalUnreadConversationStatusMap(
        new Map(
          conversations.map(conversation => {
            const localParticipant = (conversation.participants ?? []).find(
              participant => participant.loginId === login?.id,
            )

            return [
              conversation.id,
              new ConversationUnreadStatus({
                unreadCount: conversation.unreadCount,
                lastMessageId: conversation.lastMessageId,
                lastReadMessageId: localParticipant?.lastReadMessageId,
              }),
            ]
          }),
        ),
      )
    },
    [login?.id],
  )

  return (
    <ConversationUnreadContext.Provider
      value={{
        totalUnreadCount,
        localUnreadConversationStatusMap,
        hasAnyUnread,
        hasUnread,
        updateLastReadMessageForConversation,
        updateLocalUnreadConversationStatusMap,
      }}
    >
      {children}
    </ConversationUnreadContext.Provider>
  )
}

export function useConversationUnreadContext() {
  return useContext(ConversationUnreadContext)
}
