import { styled } from '@mui/material'
import { AnimatePresence, motion } from 'framer-motion'
import { HTMLAttributes, useEffect, useLayoutEffect, useRef, useState } from 'react'

import { Desktop, IconCheckCircle, body2, error, fontSize, greySet, interactiveFill, tealSet } from '@nuna/tunic'

interface PasswordValidatorProps extends HTMLAttributes<HTMLDivElement> {
  error: boolean
  isExpanded: boolean
  password: string
  confirm?: boolean
  matchingPassword?: string
  successMessage?: string
  wrapperPadding?: string
  inDrawer?: boolean
}

const numberTest = (value: string) => /\d/.test(value)
const uppercaseTest = (value: string) => /[A-Z]/.test(value)
const lowercaseTest = (value: string) => /[a-z]/.test(value)
const specialCharacterTest = (value: string) => /[\W|_]/.test(value)
const eightOrMoreTest = (value: string) => value.length >= 8

export function PasswordValidator({
  error,
  isExpanded,
  password,
  confirm = false,
  matchingPassword = '',
  successMessage = 'Strong password',
  wrapperPadding = '0 16px',
  inDrawer = false,
  ...props
}: PasswordValidatorProps) {
  const expanderRef = useRef<HTMLDivElement>(null)
  const wrapperRef = useRef<HTMLDivElement>(null)
  const [isValidTransitionDone, setIsValidTransitionDone] = useState(false)

  const hasNumber = numberTest(password)
  const hasLowercase = lowercaseTest(password)
  const hasUppercase = uppercaseTest(password)
  const hasSpecialCharacter = specialCharacterTest(password)
  const hasEightOrMore = eightOrMoreTest(password)
  const matches = !confirm || password === matchingPassword

  const isValid = hasNumber && hasLowercase && hasUppercase && hasSpecialCharacter && hasEightOrMore && matches

  useEffect(() => {
    if (isValid) {
      const timer = setTimeout(() => {
        setIsValidTransitionDone(true)
      }, 300)

      return () => clearTimeout(timer)
    } else {
      setIsValidTransitionDone(false)
    }

    return
  }, [isValid])

  useLayoutEffect(() => {
    if (expanderRef.current) {
      expanderRef.current.style.height = isExpanded ? `${wrapperRef.current?.offsetHeight ?? 0}px` : '0'
    }
  }, [isExpanded, isValid, isValidTransitionDone])

  return (
    <Expander ref={expanderRef} {...props}>
      <div ref={wrapperRef} style={{ padding: wrapperPadding }}>
        <AnimatePresence>
          {isValidTransitionDone ? (
            <motion.div initial={{ opacity: 0 }} animate={{ opacity: 1 }} exit={{ opacity: 0 }}>
              <Success>
                <IconCheckCircle size={20} className="mr-1" />
                {successMessage}
              </Success>
            </motion.div>
          ) : (
            <PasswordRequirements
              $inDrawer={inDrawer}
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
            >
              <Requirement error={error} valid={hasNumber}>
                1 number
              </Requirement>
              <Requirement error={error} valid={hasLowercase}>
                1 lowercase letter
              </Requirement>
              <Requirement error={error} valid={hasUppercase}>
                1 uppercase letter
              </Requirement>
              <Requirement error={error} valid={hasSpecialCharacter}>
                1 special character @#$%^&amp;*_
              </Requirement>
              <Requirement error={error} valid={hasEightOrMore}>
                8 or more characters
              </Requirement>
              {confirm && (
                <Requirement error={error} valid={matches}>
                  Passwords match
                </Requirement>
              )}
            </PasswordRequirements>
          )}
        </AnimatePresence>
      </div>
    </Expander>
  )
}

const Expander = styled('div')`
  transition: height 0.3s; /* non-performant */
  overflow: hidden;
`

const PasswordRequirements = styled(motion.ul)<{ $inDrawer: boolean }>`
  font-size: ${props => (props.$inDrawer ? fontSize.caption : fontSize.body)};
  list-style: none;
  padding-left: 0;
  margin: 0;

  @media (${Desktop}) {
    column-count: 2;
    column-gap: 8px;
  }
`

const Requirement = styled('li')<{ valid: boolean; error: boolean }>`
  color: ${props => {
    if (props.valid) {
      return greySet[50].hex
    } else if (props.error) {
      return error
    } else {
      return body2
    }
  }};
  font-size: inherit;
  line-height: 1.75;
  transition: color 0.3s;

  &::before {
    background-color: ${props => {
      if (props.valid) {
        return greySet[50].hex
      } else if (props.error) {
        return error
      } else {
        return interactiveFill
      }
    }};
    border-radius: 50%;
    content: '';
    display: inline-block;
    width: 12px;
    height: 12px;
    margin-right: 0.5rem;
    transition: background-color 0.3s;
  }
`
Requirement.defaultProps = { className: 'caption italic' }

const Success = styled('p')`
  align-items: center;
  background: ${tealSet[5].hex};
  border: 1px solid ${tealSet[30].hex};
  border-radius: 6px;
  color: ${tealSet[80].hex};
  display: flex;
  padding: 8px;
  margin: 0;
`
Success.defaultProps = { className: 'text-medium' }
