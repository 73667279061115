import { styled } from '@mui/material'
import React, { CSSProperties, HTMLAttributes, useState } from 'react'
import ReactPlayer from 'react-player'

import { VideoAskResponse } from '@nuna/api'
import { Dialog } from '@nuna/tunic'

interface VideoPreviewProps extends React.HTMLAttributes<HTMLButtonElement> {
  video: Pick<VideoAskResponse, 'gifUrl' | 'mediaUrl'>
  size?: { height?: number; width?: number }
  imgStyle?: CSSProperties
}

const VideoContainer = styled('div')`
  text-align: center;

  video {
    max-height: 80vh;
    width: auto;
    height: auto;
    max-width: 90%;
  }
`

const sizeDefaults = { height: 100, width: 100 }

export function IntakeVideoThumbnail({ video, imgStyle = {}, size: sizeInput = {}, ...props }: VideoPreviewProps) {
  const [isHovered, setIsHovered] = useState(false)
  const [isVideoOpen, setIsVideoOpen] = useState(false)

  const size = { ...sizeDefaults, ...sizeInput }

  return (
    <>
      <Container
        type="button"
        onClick={() => setIsVideoOpen(true)}
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
        style={{ height: size.height, width: size.width }}
        {...props}
      >
        <Thumbnail
          src={video.gifUrl}
          alt="play video"
          style={{ height: size.height, width: size.width, ...imgStyle }}
        />
        <PlayButtonIcon className="absolute-center no-interaction" size={isHovered ? 40 : 30} color="#fff" />
      </Container>

      <Dialog isOpen={isVideoOpen} noChrome onClose={() => setIsVideoOpen(false)}>
        <ReactPlayer
          url={video.mediaUrl}
          height="100%"
          width="100%"
          wrapper={VideoContainer}
          controls={true}
          playing={isVideoOpen}
        />
      </Dialog>
    </>
  )
}

const Container = styled('button')`
  display: block;
  position: relative;
`

const Thumbnail = styled('img')`
  object-fit: cover;
  height: 100%;
  width: 100%;
`

interface PlayButtonProps extends HTMLAttributes<HTMLOrSVGElement> {
  size: number
}

function PlayButtonIcon({ size, ...props }: PlayButtonProps) {
  return (
    <svg width={size} height={size} viewBox="0 0 23 23" fill="#fff" xmlns="http://www.w3.org/2000/svg" {...props}>
      <circle opacity="0.8" cx="11.5" cy="11.5" r="11.5" fill="white" />
      <path
        transform="translate(7, 6)"
        opacity="0.8"
        d="M0.707031 1.43266C0.707031 0.351473 1.88652 -0.316344 2.81363 0.23992L10.0207 4.56414C10.9211 5.10439 10.9211 6.40935 10.0207 6.94961L2.81363 11.2738C1.88652 11.8301 0.707031 11.1623 0.707031 10.0811V1.43266Z"
        fill="#1A2828"
      />
    </svg>
  )
}
