import { noop } from 'lodash'

import { specialtiesService } from '@nuna/core'

import { SpecialtiesSectionBase, SpecialtiesSectionProps } from './SpecialtiesSectionBase'

export function FaithSection({ specialties, id, refetch = noop }: SpecialtiesSectionProps) {
  return (
    <SpecialtiesSectionBase
      headingText="Faith"
      description=""
      selectedSpecialties={specialties}
      specialtiesFilter={specialtiesService.getFaithSpecialties}
      refetch={refetch}
      providerId={id}
      min={0}
    />
  )
}
