import { useDaily, useDailyEvent, useMeetingSessionState, useMeetingState } from '@daily-co/daily-react'
import moment from 'moment'
import { useCallback, useEffect } from 'react'

import { localStorageService } from '@nuna/core'

import { defaultMeetingSessionState } from '../../hooks/useMeetingSessionState'
import { MeetingSessionState } from '../../types'

const MEETING_STATE_LOCAL_STORAGE_KEY = 'tava_meeting_state'

export function useMeetingSessionLocalStorageSync(appointmentId: string) {
  const callObject = useDaily()
  const meetingState = useMeetingState()
  const { data } = useMeetingSessionState<MeetingSessionState | null>()

  // simplify useEffect deps to a boolean instead of running every time meeting state changes
  const hasJoinedMeeting = meetingState === 'joined-meeting'

  // when the meeting loads if there is no data in the meetingSessionState, then put what is in local storage in
  useEffect(() => {
    if (!hasJoinedMeeting || !data || !callObject) return

    const localData = localStorageService.getAppointmentData(
      appointmentId,
      MEETING_STATE_LOCAL_STORAGE_KEY,
      defaultMeetingSessionState,
    )

    if (!data.lastUpdatedAt && localData.lastUpdatedAt) {
      callObject.setMeetingSessionData(localData, 'shallow-merge')
    }
  }, [hasJoinedMeeting, data, appointmentId, callObject])

  // every time meeting session state updates, update local storage as well
  useDailyEvent(
    'meeting-session-state-updated',
    useCallback(
      event => {
        const localData = localStorageService.getAppointmentData(
          appointmentId,
          MEETING_STATE_LOCAL_STORAGE_KEY,
          defaultMeetingSessionState,
        )
        const remoteData = event.meetingSessionState.data as MeetingSessionState

        if (!remoteData.lastUpdatedAt) return
        if (!localData.lastUpdatedAt || moment(remoteData.lastUpdatedAt).isAfter(moment(localData.lastUpdatedAt))) {
          localStorageService.writeAppointmentData(appointmentId, MEETING_STATE_LOCAL_STORAGE_KEY, remoteData)
          return
        }
      },
      [appointmentId],
    ),
  )
}
