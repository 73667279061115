import { styled } from '@mui/material'
import moment from 'moment'
import { FaCheckCircle } from 'react-icons/fa'
import { NavLink } from 'react-router-dom'

import { AppointmentInfoSummary } from '@nuna/api'
import { routeService } from '@nuna/core'
import {
  Avatar,
  Card,
  FillButton,
  IconDescriptionEdit,
  Phone,
  body2,
  makeTypographyComponent,
  shadowWithHoverTransition,
} from '@nuna/tunic'

interface SessionListItemProps {
  internalAppointment: AppointmentInfoSummary
}

export function SessionListItem({ internalAppointment }: SessionListItemProps) {
  const { sessionId, id, startDatetime, patientName, patientAvatarUrl } = internalAppointment
  const sessionNoteRecorded = !!sessionId

  const dayDisplay = moment(startDatetime).format('dddd MMM Do [at] LT').toString()
  const timeAgo = moment(startDatetime).fromNow()

  return (
    <NavLink to={routeService.sessionDetailRoute(sessionId ? sessionId : '', id)}>
      <Item>
        <Avatar url={patientAvatarUrl} size="sm" wanderSpace={10} className="fs-exclude" />

        <div className="ml-1">
          <Name>{patientName}</Name>
          <Date>{dayDisplay}</Date>
        </div>

        <RightSide>
          <Date>{timeAgo}</Date>
          {sessionNoteRecorded ? (
            <FaCheckCircle color={body2} className="ml-2" size={16} />
          ) : (
            <FillButton className="ml-2">
              <IconDescriptionEdit className="mr-1" />
              Write Note
            </FillButton>
          )}
        </RightSide>
      </Item>
    </NavLink>
  )
}

export function SessionListItemLoader() {
  return (
    <Item>
      <LoadingAvatar className="loading" />

      <div className="ml-1" style={{ width: '100%', maxWidth: 300 }}>
        <Name className="loading">Loading</Name>
        <Date style={{ maxWidth: 200 }} className="loading">
          Loading
        </Date>
      </div>

      <RightSide>
        <Date className="loading">Loading</Date>
      </RightSide>
    </Item>
  )
}

const Name = makeTypographyComponent('mb-1 body large text-default text-medium', 'h3')
const Date = makeTypographyComponent('text-secondary mb-0', 'p')

const LoadingAvatar = styled('div')`
  &&.loading {
    border-radius: 74px;
    height: 74px;
    margin: 10px;
    width: 74px;
  }
`

const Item = styled(Card)`
  align-items: center;
  display: flex;
  margin-bottom: var(--margin-2);
  padding: 0.5rem 1rem 0.5rem 0.5rem; // padding comes from avatar

  ${shadowWithHoverTransition(1)}

  @media (${Phone}) {
    flex-wrap: wrap;
  }

  .loading {
    border-radius: 3px;
  }
`

const RightSide = styled('div')`
  align-items: center;
  display: flex;
  margin-left: auto;

  @media (${Phone}) {
    margin-right: auto;

    p {
      display: none;
    }
  }
`
