import { Ability, AbilityBuilder, AbilityClass } from '@casl/ability'

import { Role } from '@nuna/api'
import { LoginData } from '@nuna/auth'

type Actions = 'view'
type Subjects = 'admin' | 'providerOnly' | 'customer'
type AppAbility = Ability<[Actions, Subjects]>

export const AppAbility = Ability as AbilityClass<AppAbility>

const getAbilityRules = (user: LoginData | undefined) => {
  const { rules, can } = new AbilityBuilder<AppAbility>()

  if (user && user.role === Role.Admin) {
    can('view', 'admin')
  }

  if (user && user.role === Role.Provider) {
    can('view', 'providerOnly')
  }

  if (user && user.role === Role.Customer) {
    can('view', 'customer')
  }

  return rules
}

export function defineAbilityFor(user: LoginData | undefined) {
  return new Ability(getAbilityRules(user))
}

export const updateAbilityFor = (user: LoginData, ability: Ability) => {
  ability.update(getAbilityRules(user))
}
