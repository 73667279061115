import { noop } from 'lodash'
import { ReactNode, createContext, useContext } from 'react'
import { useParams } from 'react-router-dom'

import { ConversationFragment, ConversationVisibilityType, useSetConversationVisibilityMutation } from '@nuna/api'
import { errorService } from '@nuna/core'

import { useConversationsPolling } from '../hooks/useConversationsPolling'

interface ConversationContextValues {
  conversations: ConversationFragment[]
  loading: boolean
  lastPollTime: number | null
  canHideConversations: boolean
  hideConversation: (conversationId: string) => void
  activeConversationId?: string
}

const ConversationContext = createContext<ConversationContextValues>({
  conversations: [],
  loading: false,
  lastPollTime: null,
  canHideConversations: false,
  hideConversation: noop,
})

interface ConversationContextProviderProps {
  children: ReactNode
  canHideConversations?: boolean
  onConversationHidden?: (isActiveConversation: boolean) => void
}
export function ConversationContextProvider({
  children,
  canHideConversations = false,
  onConversationHidden = noop,
}: ConversationContextProviderProps) {
  const { conversationId: activeConversationId } = useParams()
  const { conversations, loading, lastPollTime, refetchConversations } = useConversationsPolling()
  const [hideConversationMutation] = useSetConversationVisibilityMutation({ refetchQueries: ['PollConversations'] })

  const hideConversation = async (conversationId: string) => {
    try {
      await hideConversationMutation({ variables: { conversationId, visibility: ConversationVisibilityType.Hidden } })
      await refetchConversations()
      // setTimeout defers calling onConversationHidden until after all state changes are finished in
      // the current cpu cycle. This is important because of the redirect logic in ConversationMenu which
      // prevents redirecting away from a hidden active conversation if the state not updated before calling the callback
      setTimeout(() => {
        onConversationHidden(conversationId === activeConversationId)
      })
    } catch (e) {
      errorService.transformGraphQlError(e, 'Error hiding conversation')
    }
  }

  return (
    <ConversationContext.Provider
      value={{
        conversations,
        loading,
        lastPollTime,
        canHideConversations,
        hideConversation,
        activeConversationId,
      }}
    >
      {children}
    </ConversationContext.Provider>
  )
}

export function useConversationContext() {
  return useContext(ConversationContext)
}
