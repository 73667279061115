import { ChangeEventHandler, HTMLAttributes } from 'react'

import { useCancelPolicyStatusQuery, usePatientContextQuery } from '@nuna/api'
import { AppointmentCard, AppointmentCardProps, AppointmentCardStatus } from '@nuna/appointment'
import { appointmentService } from '@nuna/core'
import { Radio, RadioGroup, Skeleton } from '@nuna/tunic'

interface Props extends Omit<HTMLAttributes<HTMLDivElement>, 'onChange'> {
  value: string | null
  onChange: ChangeEventHandler<HTMLInputElement>
}

export function DrawerAppointmentSelect({ value, onChange, ...props }: Props) {
  const { data } = usePatientContextQuery()

  if (!data) {
    // TODO - make this look good
    return <Skeleton />
  }

  const appointments = appointmentService.upcomingValidAppointments(data.patientContext.patient.appointments)
  return (
    <div {...props}>
      <div className="large text-medium mb-1">Which upcoming session?</div>
      <RadioGroup>
        {appointments.map(appointment => {
          if (!('patient' in appointment) || !('type' in appointment.provider)) return null
          return (
            <Radio key={appointment.id} value={appointment.id} checked={appointment.id === value} onChange={onChange}>
              <SelectAppointmentCard
                clientId={data.patientContext.patient.id}
                provider={appointment.provider}
                audience="client"
                appointment={appointment}
                appointmentStatus={AppointmentCardStatus.Active}
              />
            </Radio>
          )
        })}
      </RadioGroup>
    </div>
  )
}

interface SelectAppointmentCardProps extends AppointmentCardProps {
  clientId: string
}
function SelectAppointmentCard({ clientId, ...props }: SelectAppointmentCardProps) {
  const { data } = useCancelPolicyStatusQuery({ variables: { providerId: props?.provider?.id ?? '', clientId } })
  const cancelPolicyStatus = data?.cancelPolicyStatus
  return (
    <AppointmentCard
      {...props}
      cancelPolicyStatus={cancelPolicyStatus}
      boot={appointmentService.subjectToCancelationFee({
        policyAcceptedDate: cancelPolicyStatus?.acceptedDate,
        appointmentStartDatetime: props.appointment.startDatetime,
        cancelPeriodMinutes: cancelPolicyStatus?.cancelPolicy?.cancelPeriodMinutes,
      })}
    />
  )
}
