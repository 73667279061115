import { isNil } from 'lodash'
import { useEffect, useState } from 'react'

import { useAppointmentForDrawerQuery, useCancelPolicyStatusQuery } from '@nuna/api'
import { AppointmentCard, AppointmentCardStatus } from '@nuna/appointment'
import { useAppointmentDrawerSearchParams, useTimeZoneContext } from '@nuna/common'
import { Audience, appointmentService } from '@nuna/core'
import { LateCancelationPolicyModal } from '@nuna/fee-policies'
import { MessageComposerContextProvider } from '@nuna/messaging'
import { IconCancelAppointment, Skeleton, TextButton } from '@nuna/tunic'

import { AppointmentDrawerPaddedContainer } from '../../AppointmentDrawer'
import { DrawerHeader } from '../DrawerHeader'
import { CancelAsClient } from './components/CancelAsClient'
import { CancelAsProviderOrAdmin } from './components/CancelAsProviderOrAdmin'

interface Props {
  audience: Audience
}

export function CancelAppointment({ audience }: Props) {
  const { addUserInContext, removeUserInContext } = useTimeZoneContext()
  const [cancelPolicyOpen, setCancelPolicyOpen] = useState(false)
  const {
    drawerConfig: { appointmentId },
  } = useAppointmentDrawerSearchParams()
  const { data: appointmentData } = useAppointmentForDrawerQuery({ variables: { id: appointmentId ?? '' } })
  const appointment = appointmentData?.internalAppointment
  const { data: cancelPolicyData } = useCancelPolicyStatusQuery({
    variables: {
      providerId: appointment?.provider.id ?? '',
      clientId: appointment?.patient.id ?? '',
      defaultToTavaPolicy: true,
    },
    skip: isNil(appointment),
    fetchPolicy: 'cache-and-network',
  })

  useEffect(() => {
    if (appointmentData?.internalAppointment?.patient.timezone) {
      addUserInContext(appointmentData.internalAppointment.patient)
    }

    return () => removeUserInContext('Patient')
  }, [appointmentData, addUserInContext, removeUserInContext])

  if (!cancelPolicyData || !appointment) {
    return <Skeleton />
  }

  const cancelPolicyStatus = cancelPolicyData.cancelPolicyStatus
  const { coverageType, provider, startDatetime } = appointment

  const canChargeLateFee = appointmentService.subjectToCancelationFee({
    appointmentStartDatetime: startDatetime,
    policyAcceptedDate: cancelPolicyStatus?.acceptedDate,
    cancelPeriodMinutes: cancelPolicyStatus?.cancelPolicy?.cancelPeriodMinutes,
  })

  return (
    <>
      <DrawerHeader icon={<IconCancelAppointment isHovered style={{ marginTop: -2 }} />}>
        {canChargeLateFee ? 'Late Cancel' : 'Cancel'}
      </DrawerHeader>
      <AppointmentDrawerPaddedContainer>
        <AppointmentCard
          className="mt-2"
          audience={audience}
          appointment={appointment}
          provider={provider}
          client={appointment.patient}
          cancelPolicyStatus={cancelPolicyStatus}
          appointmentStatus={AppointmentCardStatus.Canceling}
          boot={canChargeLateFee}
        />
        {audience === 'client' && (
          <div className="text-center mt-1 caption">
            {canChargeLateFee && !!cancelPolicyStatus?.cancelPolicy?.chargeAmount && (
              <TextButton
                variant="secondary"
                type="button"
                className="caption"
                onClick={() => setCancelPolicyOpen(true)}
              >
                Review Cancelation Policy
              </TextButton>
            )}
            {!canChargeLateFee && (
              <>
                No{' '}
                <TextButton
                  variant="secondary"
                  type="button"
                  className="caption"
                  onClick={() => setCancelPolicyOpen(true)}
                >
                  fees
                </TextButton>{' '}
                will apply
              </>
            )}
          </div>
        )}
      </AppointmentDrawerPaddedContainer>

      <MessageComposerContextProvider
        richTextEditorProps={{ disableAutoLink: true }}
        participantLoginIds={[appointment.patient.loginId ?? '', appointment.provider.loginId]}
      >
        {(() => {
          if (audience === 'client') {
            return (
              <CancelAsClient
                appointment={appointment}
                subjectToFee={canChargeLateFee && !!cancelPolicyStatus?.cancelPolicy?.chargeAmount}
              />
            )
          }

          if (['provider', 'admin'].includes(audience)) {
            return (
              <CancelAsProviderOrAdmin
                appointment={appointment}
                cancelPolicyStatus={cancelPolicyStatus}
                audience={audience}
              />
            )
          }
          return null
        })()}
      </MessageComposerContextProvider>

      <LateCancelationPolicyModal
        coverageType={coverageType}
        provider={provider}
        isOpen={cancelPolicyOpen}
        onClose={() => setCancelPolicyOpen(false)}
      />
    </>
  )
}
