import { isNil, last } from 'lodash'
import moment from 'moment-timezone'
import { useEffect } from 'react'
import { Navigate, useNavigate, useParams, useSearchParams } from 'react-router-dom'

import {
  InternalAppointmentQuery,
  PreviousSessionFragment,
  ProviderSessionNoteTemplateQuery,
  SessionSummaryQuery,
  useInternalAppointmentQuery,
  useProviderSessionNoteTemplateQuery,
} from '@nuna/api'
import { useTitle } from '@nuna/common'
import { routeService, sessionService } from '@nuna/core'
import { parseTime, toast } from '@nuna/tunic'

import { SessionData } from '../types'
import { getDefaultTherapyTypeId } from '../utils'
import { SessionNote } from './SessionNote'
import { useSessionSummaryPoll } from './SessionNote/hooks/useSessionSummaryPoll'
import { SessionNotesSkeleton } from './SessionNotesFormSkeleton'

interface Props {
  onPublishRedirectLocation: (sessionId: string) => string
  onMarkedNoShowRedirectLocation: () => string
  onCancelRedirectLocation: () => string
}

export function CreateSessionNotes({
  onMarkedNoShowRedirectLocation,
  onPublishRedirectLocation,
  onCancelRedirectLocation,
}: Props) {
  useTitle('Create Session Note')

  const navigate = useNavigate()
  const [searchParams] = useSearchParams()
  const { appointmentId = '' } = useParams<{ appointmentId: string }>()
  const { data } = useInternalAppointmentQuery({
    variables: { id: appointmentId },
    fetchPolicy: 'no-cache',
  })

  const { data: templateData } = useProviderSessionNoteTemplateQuery({
    skip: isNil(data),
    variables: {
      appointmentId,
    },
  })

  const { data: sessionSummaryData, error: sessionSummaryError } = useSessionSummaryPoll(appointmentId)

  useEffect(() => {
    if (sessionSummaryError) {
      console.warn(
        'Could not find session sumary. Autonotes will be disabled for this appointment',
        sessionSummaryError,
      )
    }
  }, [sessionSummaryError])

  const loading = (!sessionSummaryData && !sessionSummaryError) || !data || !templateData

  if (loading) {
    return <SessionNotesSkeleton />
  }

  const appointment = data.internalAppointment
  const providerSessionNoteTemplate = templateData.providerSessionNoteTemplate

  if (!appointment) {
    // not sure why this would ever be null if the data has loaded, but TS thinks it might be
    toast.urgent('There was an error loading the appointment data. Please try again or contact support.')
    return null
  }

  if (moment(appointment.startDatetime).isAfter()) {
    return (
      <Navigate
        to={routeService.appointmentDetailsDrawer(appointmentId, { existingSearchParams: searchParams })}
        replace
      />
    )
  }

  const handleMarkedNoShow = () => {
    toast.success('Appointment marked as no show')
    navigate(onMarkedNoShowRedirectLocation())
  }

  const handleCanceled = () => {
    toast.success('Appointment marked as canceled')
    navigate(onMarkedNoShowRedirectLocation())
  }

  const handleNotePublished = (sessionId: string) => {
    toast.success('Note Saved', {
      action: {
        buttonText: 'View Note',
        onClick: () => {
          navigate(onPublishRedirectLocation(sessionId))
        },
      },
    })

    if (searchParams.get('fromLiveSession') === 'true') {
      navigate(onPublishRedirectLocation(sessionId))
    } else {
      navigate(-1)
    }
  }

  return (
    <SessionNote
      sessionData={buildSessionDataFromAppointment({
        appointment,
        lastSession: getLastSession(appointment.patient.sessions),
        sessionSummary: sessionSummaryData?.sessionSummary,
        providerSessionNoteTemplate,
      })}
      onMarkedAsNoShow={handleMarkedNoShow}
      onCanceled={handleCanceled}
      onCancelEdit={() => navigate(onCancelRedirectLocation())}
      onPublished={handleNotePublished}
    />
  )
}

function buildSessionDataFromAppointment({
  appointment,
  sessionSummary,
  lastSession,
  providerSessionNoteTemplate,
}: {
  appointment: NonNullable<InternalAppointmentQuery['internalAppointment']>
  sessionSummary: SessionSummaryQuery['sessionSummary'] | undefined
  lastSession: PreviousSessionFragment | undefined
  providerSessionNoteTemplate: ProviderSessionNoteTemplateQuery['providerSessionNoteTemplate'] | undefined
}): SessionData {
  const sessionDraft = sessionService.parseSessionDraft(appointment, {
    note: providerSessionNoteTemplate?.template.body,
    diagnoses: lastSession?.diagnoses ?? [],
    startTime: parseTime(moment(appointment.startDatetime)),
    therapyTypeSpecialtyId: getDefaultTherapyTypeId(appointment),
  })

  // update the working draft based on the current state of auto notes
  sessionDraft.workingDraft = (() => {
    // already have a note in progress, use that
    if (!isNil(sessionDraft.workingDraft) && sessionDraft.workingDraft !== providerSessionNoteTemplate?.template.body) {
      return sessionDraft.workingDraft
    }

    // auto note is ready
    if (sessionSummary && sessionSummary.autoNoteFormatted) {
      return sessionSummary.autoNoteFormatted
    }

    // there will be an auto note, but it's still being generated
    if (appointment.provider.tavaScribeEnabled && sessionSummary?.shouldGenerateNote !== false) {
      return null
    }

    // No autonotes. Copy the in-session notes
    return sessionDraft.note
  })()

  return {
    addressId: sessionDraft.addressId ?? 'Virtual',
    appointmentId: appointment.id,
    billableFieldsDisabled: false,
    coverageType: appointment.coverageType ?? undefined,
    diagnoses: sessionDraft.diagnoses ?? [],
    endTime: sessionDraft.endTime ?? '',
    inSessionNotes: sessionDraft.note ?? '',
    isFirstSession: providerSessionNoteTemplate ? providerSessionNoteTemplate.sessionCount === 1 : false,
    locked: false,
    patient: appointment.patient,
    provider: appointment.provider,
    scheduledEndTime: appointment.endDatetime,
    scheduledStartTime: appointment.startDatetime,
    startTime: sessionDraft.startTime ?? '',
    template: providerSessionNoteTemplate?.template.body,
    therapyTypeSpecialtyId: sessionDraft.therapyTypeSpecialtyId ?? '',
    workingDraft: sessionDraft.workingDraft,
    sessionSummary,
  }
}

function getLastSession(sessions: PreviousSessionFragment[]) {
  if (sessions.length === 0) {
    return undefined
  }
  const sortedSessions =
    [...sessions].sort((sessionA, sessionB) => (moment(sessionA.createdAt).isAfter(sessionB.createdAt) ? 1 : -1)) || []

  return last(sortedSessions)
}
