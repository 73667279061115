import { styled } from '@mui/material'
import React from 'react'

import { greySet, salmonSet, tealSet, yellowSet } from '../../styles/colorSets'
import { sansSerifFontStack } from '../../styles/global/typography.utils'
import { Intent } from '../../types/Intent'

export interface TagProps extends React.HTMLAttributes<HTMLSpanElement> {
  intent?: Intent
  scheme?: 'dark' | 'light'
}

const tintWhite = 'rgba(255, 255, 255, 0.13)'

const colors: { [key in Intent]: string } = {
  information: `background-color: ${tealSet.tint[40]}; color: ${tealSet.primary.hex};`,
  urgent: `background-color: ${salmonSet.tint[40]}; color: ${salmonSet[80].hex};`,
  caution: `background-color: ${yellowSet.tint[40]}; color: ${yellowSet[80].hex};`,
  default: `background-color: ${greySet.tint[20]}; color: ${greySet[70].hex};`,
}

const darkColors: { [key in Intent]: string } = {
  information: `background-color: ${tintWhite}; color: ${tealSet[30].hex};`,
  urgent: `background-color: ${tintWhite}; color: ${salmonSet[50].hex};`,
  caution: `background-color: ${tintWhite}; color: ${yellowSet[50].hex};`,
  default: `background-color: ${tintWhite}; color: ${greySet[30].hex};`,
}

export const StatusLabel = styled('span')`
  border-radius: 4px;
  display: inline-block;
  font-family: ${sansSerifFontStack};
  font-size: 13px;
  font-weight: 700;
  line-height: 1;
  padding: 0.25rem 0.5rem;
  text-transform: uppercase;
  white-space: nowrap;

  &.cursor-pointer {
    cursor: pointer;
  }

  ${({ intent = 'default', scheme = 'light' }: TagProps) => {
    if (scheme === 'dark') {
      return darkColors[intent]
    }

    return colors[intent]
  }}
`
