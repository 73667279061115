import { isNil } from 'lodash'
import moment from 'moment'
import { useState } from 'react'

import { InsuranceCoverageDetailsFragment, PaymentPreference, Role } from '@nuna/api'
import { hasRole, useUserRole } from '@nuna/auth'
import { numberService, routeService } from '@nuna/core'
import {
  AttachmentChip,
  Avatar,
  GhostButtonExternalLink,
  IconButton,
  IconCheck,
  IconDollarSymbol,
  IconError,
  IconExportDeprecated,
  IconHelp,
  IconMedication,
  IconPencil,
  IconRelationship,
  IconReports,
  IconSessions,
  IconUser,
  PersistentAlert,
  StatusLabel,
  TextButtonLink,
} from '@nuna/tunic'

import { InsuranceCard } from '../../../../CoverageCard/components/InsuranceCard'
import { CoverageTypeIcon } from '../../../../CoverageTypeIcon'
import {
  CoverageIcon,
  CoverageList,
  CoverageListItem,
  CoverageSection,
  CoverageSectionHeading,
} from '../../../../CoverageTypography'
import { DefaultTherapyTypeListItems } from '../../../../DefaultTherapyTypeListItems'
import { InsurancePolicyDialog } from '../../../../InsurancePolicyDialog/InsurancePolicyDialog'
import { useClientCoveragePanelContext } from '../../../context/ClientCoveragePanelContext'
import { PreferenceCoverageSection } from './PreferenceCoverageSection'

interface Props {
  details: InsuranceCoverageDetailsFragment
}

export function InsuranceDetails({ details }: Props) {
  const { openCoverageForm } = useClientCoveragePanelContext()
  const [showPolicyDialog, setShowPolicyDialog] = useState(false)

  const role = useUserRole()
  const {
    verifiedDate,
    copay,
    insuranceWebsiteUrl,
    individualDeductible,
    individualSpentTowardsDeductible,
    familyDeductible,
    familySpentTowardsDeductible,
    availableToDependents,
    cardBackUrl,
    cardFrontUrl,
    providers,
    allowCouplesAndFamilyTherapy,
  } = details

  const formattedCopay = !isNil(copay) ? numberService.centsToFormattedDollars(copay) : ''
  const possessivePronoun = hasRole(role, Role.Patient) ? 'your' : 'their'
  const unverifiedTooltip = `We were unable to retrieve this info from ${possessivePronoun} insurance provider.`

  return (
    <>
      <h3 className="h5 v-align">
        <CoverageTypeIcon className="mr-1" type={PaymentPreference.Insurance} /> Insurance Detail
      </h3>

      <InsuranceCard details={details} />

      <div className="mt-1 v-align">
        <PersistentAlert className="mt-1 pr-2" intent={details.active ? 'success' : 'urgent'}>
          {details.active ? `Verified ${moment(details.verifiedDate).format('M/D/YY')}` : 'Invalid'}
        </PersistentAlert>

        <span className="ml-auto">
          {hasRole(role, Role.Admin) && (
            <IconButton variant="secondary" tooltip="Validation Log" onClick={() => setShowPolicyDialog(true)}>
              <IconSessions />
            </IconButton>
          )}

          {hasRole(role, [Role.Admin, Role.Patient]) && (
            <IconButton
              variant="secondary"
              tooltip="Edit"
              onClick={() => openCoverageForm(PaymentPreference.Insurance)}
            >
              <IconPencil />
            </IconButton>
          )}
        </span>
      </div>

      {details.active ? (
        <>
          <PreferenceCoverageSection type={PaymentPreference.Insurance} />
          <CoverageSection>
            <CoverageSectionHeading>Client Costs</CoverageSectionHeading>
            <p className="caption italic text-medium-grey">
              Exact amounts below will vary.{' '}
              {verifiedDate && `Values last verified on ${moment(verifiedDate).format('M/D/YY')}`}
            </p>
            <CoverageList>
              <CoverageListItem
                adornment={<IconDollarSymbol size={16} />}
                tooltip={
                  !isNil(formattedCopay)
                    ? `Client will be billed once their insurance has processed the claim. This will include a ${formattedCopay} copay and the balance of the session cost, if any.`
                    : unverifiedTooltip
                }
              >
                {formattedCopay
                  ? `${formattedCopay} copay + variable session costs`
                  : 'Variable session costs: Unknown'}
              </CoverageListItem>
              {hasRole(role, [Role.Admin, Role.Patient]) && (
                <>
                  <CoverageListItem
                    adornment={<IconReports />}
                    tooltip={isNil(individualDeductible) && unverifiedTooltip}
                  >
                    {!isNil(individualDeductible)
                      ? `Individual deductible: ${numberService.centsToFormattedDollars(
                          individualSpentTowardsDeductible,
                        )} met of
                ${numberService.centsToFormattedDollars(individualDeductible)}`
                      : 'Individual deductible: unknown'}
                  </CoverageListItem>
                  <CoverageListItem
                    adornment={<IconReports />}
                    tooltip={isNil(familySpentTowardsDeductible) && unverifiedTooltip}
                  >
                    {!isNil(familySpentTowardsDeductible)
                      ? `Family deductible: ${numberService.centsToFormattedDollars(
                          familySpentTowardsDeductible,
                        )} met of
                ${numberService.centsToFormattedDollars(familyDeductible)}`
                      : unverifiedTooltip}
                  </CoverageListItem>
                </>
              )}
            </CoverageList>
          </CoverageSection>
          {hasRole(role, Role.Patient) && insuranceWebsiteUrl && (
            <CoverageSection>
              <GhostButtonExternalLink className="mb-2" href={insuranceWebsiteUrl} variant="secondary">
                Launch Insurer Website <IconExportDeprecated className="ml-1" />
              </GhostButtonExternalLink>
            </CoverageSection>
          )}

          <CoverageSection>
            <CoverageSectionHeading>Includes</CoverageSectionHeading>
            <CoverageList>
              <DefaultTherapyTypeListItems includeFamily={allowCouplesAndFamilyTherapy} />
              {hasRole(role, [Role.Admin, Role.Provider]) && !allowCouplesAndFamilyTherapy && (
                <CoverageListItem
                  disabled
                  adornment={
                    <CoverageIcon disabled>
                      <IconRelationship size={20} />
                    </CoverageIcon>
                  }
                >
                  Couples or family therapy
                </CoverageListItem>
              )}
              {hasRole(role, [Role.Admin, Role.Provider]) && (
                <CoverageListItem
                  disabled
                  adornment={
                    <CoverageIcon disabled>
                      <IconMedication size={20} />
                    </CoverageIcon>
                  }
                >
                  Medication consultations
                </CoverageListItem>
              )}
            </CoverageList>
          </CoverageSection>
          {hasRole(role, [Role.Admin, Role.Provider]) && (
            <CoverageSection>
              <CoverageSectionHeading>Available for</CoverageSectionHeading>
              <CoverageList>
                {!isNil(availableToDependents) ? (
                  <>
                    <CoverageListItem adornment={<IconUser size={16} />}>Client</CoverageListItem>
                    <CoverageListItem
                      disabled={!availableToDependents}
                      tooltip={availableToDependents ? 'Including children age 13-26' : ''}
                      adornment={
                        <CoverageIcon disabled={!availableToDependents}>
                          <IconRelationship size={19} />
                        </CoverageIcon>
                      }
                    >
                      Their Dependents
                    </CoverageListItem>
                  </>
                ) : (
                  <CoverageListItem adornment={<IconHelp />} tooltip={unverifiedTooltip}>
                    Unknown
                  </CoverageListItem>
                )}
              </CoverageList>
            </CoverageSection>
          )}

          {providers.length > 0 && (
            <CoverageSection>
              <CoverageSectionHeading>Care Team</CoverageSectionHeading>

              <CoverageList>
                {providers.map(provider => (
                  <CoverageListItem key={provider.id} adornment={<Avatar size="mini" url={provider.avatarUrl} />}>
                    {provider.firstName} {provider.lastName}{' '}
                    <StatusLabel intent={provider.inInsuranceNetwork ? 'default' : 'urgent'}>
                      {provider.inInsuranceNetwork ? (
                        <span className="v-align">
                          <IconCheck className="mr-xs" size={12} /> In network
                        </span>
                      ) : (
                        <span className="v-align">
                          <IconError className="mr-xs" size={12} /> Out of network
                        </span>
                      )}
                    </StatusLabel>
                    {!provider.inInsuranceNetwork && hasRole(role, [Role.Patient]) && (
                      <TextButtonLink
                        to={routeService.switchProvider(provider.id)}
                        variant="secondary"
                        className="mt-2"
                      >
                        Change Therapist
                      </TextButtonLink>
                    )}
                  </CoverageListItem>
                ))}
              </CoverageList>
            </CoverageSection>
          )}

          {hasRole(role, [Role.Admin]) && cardFrontUrl && cardBackUrl && (
            <CoverageSection>
              <CoverageSectionHeading>Physical Card</CoverageSectionHeading>
              <div className="mt-1">
                <AttachmentChip fileName="Front" mimeType="image" downloadUrl={cardFrontUrl} className="mr-1" />
                <AttachmentChip fileName="Back" mimeType="image" downloadUrl={cardBackUrl} />
              </div>
            </CoverageSection>
          )}
        </>
      ) : (
        <CoverageSection>
          <p className="large paragraphs">
            We couldn't verify {possessivePronoun} insurance. {details.failureReason}
          </p>
        </CoverageSection>
      )}

      {hasRole(role, Role.Admin) && (
        <InsurancePolicyDialog isOpen={showPolicyDialog} closeDialog={() => setShowPolicyDialog(false)} />
      )}
    </>
  )
}
