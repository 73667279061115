/**
 * @param
 * providerId: string
 */
export const MODAL_LATE_CANCELATION_POLICY = 'modalLateCancelationPolicy'
/**
 * @param
 * providerId: string
 */
export const ACCEPT_PROVIDER_FEES_PARAM = 'acceptProviderFees'
