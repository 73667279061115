import { useDaily } from '@daily-co/daily-react'
import { useCallback, useMemo } from 'react'

import { useVideoCallContext } from '../context/VideoCallContext'

export function useTavaVideoUsers() {
  const callObject = useDaily()
  const { expectedUsers, localUser } = useVideoCallContext()

  // Memoized for the sake of dependency arrays
  const users = useMemo(() => [...expectedUsers, localUser], [expectedUsers, localUser])

  const getUserByLoginId = useCallback((loginId: string) => users.find(user => user.loginId === loginId), [users])

  const getUserBySessionId = useCallback(
    (sessionId: string) => {
      const participant = Object.values(callObject?.participants() ?? {}).find(
        participant => participant.session_id === sessionId,
      )

      return users.find(user => user.loginId === participant?.user_name)
    },
    [callObject, users],
  )

  return { getUserByLoginId, getUserBySessionId }
}
