import { Select, SelectProps } from '@nuna/tunic'

const AVAILABLE_LICENSES = ['LCMHC', 'LPC', 'LCSW', 'LMFT', 'PHD', 'PSYD', 'LMHC', 'LCPC', 'LICSW']

export function LicenseTitleSelect(props: Omit<SelectProps, 'children'>) {
  return (
    <Select label="Title" {...props}>
      <option value=""></option>
      {AVAILABLE_LICENSES.map(license => (
        <option key={license} value={license}>
          {license}
        </option>
      ))}
    </Select>
  )
}
