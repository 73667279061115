import { Collapse, PersistentAlert } from '@nuna/tunic'

import { useCAQHSetupContext } from '../../../context/CAQHSetupContext'

export function CAQHSyncIndicator() {
  const { isCredentialPolling } = useCAQHSetupContext()

  return (
    <Collapse isOpen={isCredentialPolling}>
      <PersistentAlert className="mb-3 v-align" style={{ width: 'fit-content' }} intent="loading">
        CAQH is syncing
      </PersistentAlert>
    </Collapse>
  )
}
