import { styled } from '@mui/material'
import { noop } from 'lodash'
import { HTMLAttributes, ReactNode } from 'react'
import { To } from 'react-router-dom'

import { IconChevronThick } from '../icons'
import { borderGrey } from '../styles/colors'
import { csx } from '../utils/csx'
import { FillButton, FillButtonProps, IconButtonLink, OutlineButton, OutlineButtonProps } from './Button'
import { ButtonProps } from './Button/ButtonBase'

const intakeNextButtonDefaultProps = {
  type: 'submit' as const,
  'data-testid': 'intake-next',
}

export interface IntakeFormStepperProps extends HTMLAttributes<HTMLDivElement> {
  previousButton?: ReactNode
  nextButton?: (props: Partial<ButtonProps>) => ReactNode
  skipButton?: (props: Partial<ButtonProps>) => ReactNode
  onPreviousClick?: () => void
  disabled?: boolean
  previousPath: To
  mutationLoading: boolean
}

export function IntakeFormStepper({
  previousButton,
  nextButton,
  skipButton,
  onPreviousClick = noop,
  disabled = false,
  className,
  previousPath,
  mutationLoading,
  ...props
}: IntakeFormStepperProps) {
  return (
    <div className={csx(['v-align', className])} {...props}>
      {previousButton !== undefined ? (
        previousButton
      ) : (
        <>
          <IconButtonLink onClick={onPreviousClick} tooltip="Previous" to={previousPath}>
            <IconChevronThick size={16} style={{ marginRight: '0.25rem' }} />
          </IconButtonLink>

          <Separator />
        </>
      )}

      {nextButton !== undefined ? (
        nextButton({ ...intakeNextButtonDefaultProps, isLoading: mutationLoading })
      ) : (
        <OutlineButtonWithChevron {...intakeNextButtonDefaultProps} disabled={disabled} isLoading={mutationLoading}>
          Next
        </OutlineButtonWithChevron>
      )}

      {skipButton && skipButton({ ...intakeNextButtonDefaultProps, isLoading: mutationLoading })}
    </div>
  )
}

export function OutlineButtonWithChevron({ children, ...props }: OutlineButtonProps) {
  return (
    <OutlineButton {...props} trailingIcon>
      {children} <IconChevronThick direction="right" className="ml-xs" size={16} />
    </OutlineButton>
  )
}

export function FillButtonWithChevron({ children, ...props }: FillButtonProps) {
  return (
    <FillButton {...props} trailingIcon>
      {children} <IconChevronThick direction="right" className="ml-xs" size={16} />
    </FillButton>
  )
}

const Separator = styled('span')`
  background-color: ${borderGrey};
  display: inline-block;
  height: 22px;
  margin-left: 0.5rem;
  margin-right: 1.5rem;
  width: 1px;
`
