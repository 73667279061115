import { IconProps } from './IconProps'

export function IconBold({ size = 24, color = 'currentColor', ...props }: IconProps) {
  return (
    <svg width={size} height={size} viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M38.0368 27.5125C38.5812 28.4413 38.8639 29.5409 38.8639 30.8114C38.8639 32.3274 38.5498 33.6085 37.9217 34.6548C37.2935 35.7011 36.3931 36.5338 35.2206 37.1317C33.9747 37.7829 32.5509 38.2313 30.9282 38.5089C29.3054 38.7758 27.4105 38.9039 25.2119 38.9039H10V37.2598C10.4292 37.2171 10.9632 37.153 11.6123 37.0676C12.2614 36.9822 12.7116 36.8861 12.9733 36.7687C13.4863 36.5552 13.8318 36.2776 14.0202 35.9359C14.2087 35.5943 14.3029 35.1459 14.3029 34.6014V13.5267C14.3029 13.0142 14.2296 12.5979 14.0726 12.2562C13.926 11.9146 13.5596 11.6263 12.9838 11.3701C12.5545 11.1886 12.0625 11.0391 11.4971 10.911C10.9318 10.7936 10.4397 10.6975 10.0105 10.6228V9H25.9448C29.9545 9 32.844 9.56584 34.6238 10.6868C36.4036 11.8185 37.283 13.4626 37.283 15.6619C37.283 16.6762 37.0841 17.5623 36.6863 18.331C36.2884 19.0996 35.7231 19.7616 34.9693 20.3274C34.2888 20.8505 33.4827 21.2989 32.5509 21.6726C31.6087 22.0569 30.6141 22.3665 29.5357 22.6228V23.0178C30.6141 23.1246 31.7238 23.3701 32.8859 23.7438C34.0271 24.1174 35.0321 24.5872 35.8906 25.1851C36.77 25.8043 37.4924 26.5836 38.0368 27.5125ZM27.8606 12.3096C26.9393 11.3915 25.5679 10.9217 23.7357 10.9217C23.474 10.9217 23.139 10.9324 22.7202 10.9537C22.3014 10.9751 21.9455 10.9964 21.6419 11.0071V22.089H22.7097C24.9397 22.089 26.5834 21.5552 27.6408 20.4982C28.7087 19.4306 29.2321 18 29.2321 16.1851C29.2321 14.5302 28.7715 13.2384 27.8606 12.3096ZM28.8657 25.7509C27.6513 24.6406 25.9134 24.0854 23.6415 24.0854C23.3798 24.0854 23.0238 24.0961 22.5946 24.1174C22.1549 24.1388 21.8408 24.1601 21.6314 24.1708V35.1246C21.757 35.6477 22.1444 36.0854 22.783 36.4484C23.4321 36.8114 24.2173 36.9822 25.1386 36.9822C26.7718 36.9822 28.1119 36.4164 29.1379 35.2954C30.1639 34.1744 30.6769 32.5836 30.6769 30.5445C30.6769 28.4626 30.0697 26.8612 28.8657 25.7509Z"
        fill={color}
      />
    </svg>
  )
}
