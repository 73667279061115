import { useEffect } from 'react'

import { usePatientContextQuery, usePatientCoverageQuery } from '@nuna/api'
import { errorService } from '@nuna/core'
import { toast } from '@nuna/tunic'

export function useHasEnteredCoverage() {
  const { data: patientContextData } = usePatientContextQuery()

  const patientId = patientContextData?.patientContext.patient.id ?? ''
  const intakeCompleted = patientContextData?.patientContext.patient.intakeCompleted

  const { data, error } = usePatientCoverageQuery({
    variables: {
      patientId: patientId,
    },
    skip: !patientId,
  })

  useEffect(() => {
    if (error) {
      console.error(error)
      toast.urgent(errorService.transformUserGraphqlError(error, 'Error loading patient coverage'))
    }
  }, [error])

  const patientCoverage = data?.patientCoverage

  if (!patientCoverage || intakeCompleted === undefined) {
    return null
  }

  if (
    'cardNumber' in patientCoverage[0].details &&
    patientCoverage[0].details.cardNumber === null &&
    intakeCompleted === false
  ) {
    return false
  }

  return true
}
