import { styled } from '@mui/material'
import moment from 'moment'

import { AssessmentType, AssessmentsQuery, Role } from '@nuna/api'
import { Severity, TrendDirection, assessmentService } from '@nuna/assessments'
import { IconArrow, StatusLabel, fontSize, greySet } from '@nuna/tunic'

import {
  ASSESSMENT_BUFFER_DAYS,
  calculateTrend,
  getColorBySeverity,
  getStatusIntentBySeverity,
  getTrendText,
  isAssessmentRecent,
} from './utils'

interface AssessmentGlanceChartProps {
  assessments: AssessmentsQuery['assessments']
}

const { getAssessmentSeverityAndLabel, getReadableAssessmentName } = assessmentService

export function AssessmentGlanceChart({ assessments }: AssessmentGlanceChartProps) {
  const currentAssessment = assessments[0]
  const priorAssessment = assessments[1]
  const isCssrs = currentAssessment?.type === AssessmentType.CSsrs
  const { severity = Severity.Minimal, label } = getAssessmentSeverityAndLabel(currentAssessment)

  const hasRecentAssessment = currentAssessment
    ? isAssessmentRecent(currentAssessment.updatedAt, ASSESSMENT_BUFFER_DAYS)
    : false

  if (assessments.length < 1 || !hasRecentAssessment) return null

  const { trend, direction, showTrend } = calculateTrend(currentAssessment, priorAssessment)
  const iconDirection = direction === TrendDirection.Up ? 'up' : direction === TrendDirection.Down ? 'down' : 'right'
  const trendArrow = (
    <IconArrow direction={iconDirection} size={10} className="mr-xs" style={{ position: 'relative', top: -1 }} />
  )

  const trendText = getTrendText(direction, priorAssessment, isCssrs)

  return (
    <section>
      <div className="text-dark-gray text-secondary mb-0 v-align space-between">
        <h3 className="body mb-0 text-medium">{getReadableAssessmentName(currentAssessment.type, Role.Provider)}</h3>
        {currentAssessment.type !== AssessmentType.CSsrs && (
          <AssessmentScore severity={severity}>{currentAssessment.score}</AssessmentScore>
        )}
        {currentAssessment.type === AssessmentType.CSsrs && (
          <div className="v-align" style={{ minHeight: '35.2px' }}>
            <StatusLabel intent={getStatusIntentBySeverity(severity)} className="mb-0">
              {label}
            </StatusLabel>
          </div>
        )}
      </div>
      <div className="caption mb-2">{moment(currentAssessment.updatedAt).fromNow()}</div>
      <TrendRow>
        {!isCssrs && showTrend && (
          <TrendBadge>
            {trendArrow} {Math.round(trend * 10) / 10}%
          </TrendBadge>
        )}
        {trendText}
      </TrendRow>
    </section>
  )
}

const AssessmentScore = styled('h4')<{ severity: Severity }>(
  ({ severity }) => `
  color: ${getColorBySeverity(severity)};
`,
)
AssessmentScore.defaultProps = { className: 'mb-0 fs-mask' }

const TrendRow = styled('div')`
  align-items: center;
  display: flex;
`
TrendRow.defaultProps = { className: 'mt-1' }

const TrendBadge = styled('span')`
  align-items: center;
  color: ${greySet[80].hex};
  border-radius: 4px;
  display: flex;
  font-size: ${fontSize.caption};
  background-color: ${greySet.tint[20]};
  padding: 4px 9px;
`
TrendBadge.defaultProps = { className: 'mr-1' }
