import { styled } from '@mui/material'

import { useInternalAppointmentQuery, useProviderSessionNoteTemplateQuery } from '@nuna/api'
import { sessionService } from '@nuna/core'
import { SessionNotesFormSkeletonNotesOnly, useSessionDraftConflictWarning } from '@nuna/session-notes'
import {
  Box,
  IconAISparkles,
  IconButton,
  IconCautionCircle,
  IconClose,
  PersistentAlert,
  Tooltip,
  greySet,
  interactiveText,
} from '@nuna/tunic'

import { useVideoCallContext } from '../../../../context/VideoCallContext'
import { SidebarState } from '../../../../context/hooks/useSidebarContext'
import { PatientVideoUser } from '../../../../types'
import { SidebarContentWrapper } from '../SidebarContentWrapper'
import { AutonotesAlert } from './components/AutonotesAlert'
import { DraftConflictAlert } from './components/DraftConflictAlert'
import { NoteForm } from './components/NoteForm'

export function SidebarNotes({ patient }: { patient: PatientVideoUser }) {
  const { appointmentId, toggleSidebar, isPatientAutonotesEnabled, localUser } = useVideoCallContext()
  useSessionDraftConflictWarning(appointmentId, { disableToast: true })

  const handleClose = () => {
    toggleSidebar(SidebarState.Notes)
  }

  const { data: appointmentData } = useInternalAppointmentQuery({
    variables: { id: appointmentId },
    fetchPolicy: 'network-only',
  })

  const { data: templateData } = useProviderSessionNoteTemplateQuery({
    variables: {
      appointmentId,
    },
  })

  const appointment = appointmentData?.internalAppointment
  const noteTemplate = templateData?.providerSessionNoteTemplate?.template

  const draft =
    appointment && noteTemplate ? sessionService.parseSessionDraft(appointment, { note: noteTemplate.body }) : undefined

  const isAutoNotesEnabled = isPatientAutonotesEnabled(patient)

  return (
    <Wrapper>
      <SectionHeader className="v-align">
        <h2 className="h5 mb-0 nowrap">Session Notes</h2>

        <IconButton className="ml-auto" small tooltip="Close chat" onClick={handleClose} variant="secondary">
          <IconClose size={20} />
        </IconButton>
      </SectionHeader>

      <AutonotesAlert className="my-1" patient={patient} />

      {!draft ? (
        <SessionNotesFormSkeletonNotesOnly style={{ height: '100%' }} />
      ) : (
        <NoteForm draft={draft} patient={patient} />
      )}

      {localUser.initialAutonotesEnabled && (
        <Box sx={{ pt: 1, mt: 'auto' }}>
          <Tooltip
            content={`Tava Scribe is ${
              isAutoNotesEnabled ? 'on' : 'off'
            }. It automatically creates SOAP summaries from the session’s video transcript and your notes${
              isAutoNotesEnabled ? '.' : " when it's on."
            }`}
          >
            <PersistentAlert
              icon={
                isAutoNotesEnabled ? (
                  <IconAISparkles size={17} color={interactiveText} />
                ) : (
                  <IconCautionCircle size={17} color={greySet[50].hex} />
                )
              }
              className="nowrap"
              small
              intent={isAutoNotesEnabled ? 'success' : 'caution'}
            >
              {isAutoNotesEnabled ? 'Tava Scribe is on' : `${patient.firstName} opted out of Tava Scribe`}
            </PersistentAlert>
          </Tooltip>
        </Box>
      )}

      <DraftConflictAlert className="mt-1" />
    </Wrapper>
  )
}

const Wrapper = styled(SidebarContentWrapper)`
  display: flex;
  padding: 1rem;
  flex-direction: column;
`

const SectionHeader = styled('header')`
  padding-bottom: 0.25rem;
`
