import { IconProps } from './IconProps'

export function IconUnlock({ size = 24, color = 'currentColor', ...props }: IconProps) {
  const height = (24 / 25) * size

  return (
    <svg width={size} height={height} viewBox="0 0 25 24" fill={color} xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.3853 9.79045V7.47131C13.3853 4.90998 11.3063 2.83301 8.74247 2.83301C6.17863 2.83301 4.09961 4.90998 4.09961 7.47131V9.79045H6.42104V7.47131C6.42104 6.31173 7.58175 5.15216 8.74247 5.15216C9.90318 5.15216 11.0639 6.31173 11.0639 7.47131V9.79045H9.90318C9.2618 9.79045 8.74247 10.3093 8.74247 10.95V18.7568C8.74247 19.3975 9.2618 19.9163 9.90318 19.9163H19.1889C19.8303 19.9163 20.3496 19.3975 20.3496 18.7568V10.95C20.3496 10.3093 19.8303 9.79045 19.1889 9.79045H13.3853Z"
      />
    </svg>
  )
}
