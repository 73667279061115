import { isNil } from 'lodash'

import {
  CapStatus,
  CashCoverageDetailsFragment,
  EapCoverageDetailsFragment,
  InsuranceCoverageDetailsFragment,
  PatientCoverageFragment,
  PaymentPreference,
} from '@nuna/api'

interface CurrentCoverages {
  currentCoverage?: PatientCoverageFragment
  alternateCoverage?: PatientCoverageFragment
}

/** @deprecated concept of current and alternative coverage is going away. Use utils from coverageService instead */
export function getCurrentAndAlternativeCoverages(
  intakeCompleted?: boolean | null,
  coverages?: PatientCoverageFragment[],
): CurrentCoverages {
  // the logic is kept flat in this function to make it easier to reason about

  if (!coverages) {
    return {}
  }

  const preferred = coverages[0]
  const alternate = coverages[1]

  if (!preferred) {
    return {}
  }

  // intakeCompleted can be undefined or null thanks to the graphQl query. We only want to
  // return an empty object if it is actually false and the payment preference is cash
  // Because cash contracts are created automatically for a client, we don't want to
  // show it to them if they have not completed intake. This is based on an assummption
  // that they will not select cash as their preferred payment method. That's not a great
  // assumption, but the backend model currently doesn't provide a good way for us to know
  // if a client has even picked a preferred payment method
  if (
    intakeCompleted === false &&
    preferred.type === PaymentPreference.Cash &&
    isNil((preferred.details as CashCoverageDetailsFragment).cardNumber)
  ) {
    return {}
  }

  return {
    currentCoverage: preferred,
    alternateCoverage:
      alternate?.type === PaymentPreference.Cash
        ? !isNil((alternate.details as CashCoverageDetailsFragment).cardNumber) // since cash is the default preference, only show this if they have entered a card number
          ? alternate
          : undefined
        : alternate,
  }
}

/** @deprecated  Use utils from coverageService instead */
export function isEAPInactive(coverage: EapCoverageDetailsFragment) {
  return coverage.sessionsCapStatus === CapStatus.Exhausted
}

/** @deprecated Use utils from coverageService instead */
export function isInsuranceInvalid(coverage: InsuranceCoverageDetailsFragment) {
  return !coverage.active
}

/** @deprecated Use utils from coverageService instead */
export function isCashInvalid(coverage: CashCoverageDetailsFragment) {
  return coverage.cardIsExpired || isNil(coverage.cardNumber)
}
