import { Medication } from '../types/exported'
import { TypeformResponse } from '../types/internal-only/care-preferences.types'

const providerAvailabilityValues = [
  'weekday_mornings',
  'weekend_mornings',
  'weekday_afternoons',
  'weekend_afternoons',
  'weekday_evenings',
  'weekend_evenings',
  'allOfAbove',
] as const

const providerAgeValues = [
  'muchYounger',
  'slightlyYounger',
  'sameAge',
  'slightlyOlder',
  'muchOlder',
  'allOfAbove',
] as const

const providerGenderValues = ['male', 'female', 'transgender', 'allOfAbove'] as const

// Mixed case here is due to the backend expecting the weekday/weekend values to be snake case
const availabilityValueToLabel: Record<(typeof providerAvailabilityValues)[number], string> = {
  weekday_mornings: 'Weekday Mornings',
  weekend_mornings: 'Weekend Mornings',
  weekday_afternoons: 'Weekday Afternoons',
  weekend_afternoons: 'Weekend Afternoons',
  weekday_evenings: 'Weekday Evenings',
  weekend_evenings: 'Weekend Evenings',
  allOfAbove: 'Any time works',
}

const ageValueToLabel: Record<(typeof providerAgeValues)[number], string> = {
  muchYounger: 'Much younger',
  slightlyYounger: 'A little younger',
  sameAge: 'Around my age',
  slightlyOlder: 'A little older',
  muchOlder: 'Much older',
  allOfAbove: 'Any age works',
}

const genderValueToLabel: Record<(typeof providerGenderValues)[number], string> = {
  male: 'Male',
  female: 'Female',
  transgender: 'Transgender',
  allOfAbove: 'Any gender works',
}

// Unfortunately these string values are expected on the backend but aren't typed. So theoeretically, we could end up being passed a key that doesn't exist here yet.
// These wrappers are here to have some type safety for displaying values in our forms but also not throw up if we try to render a key we don't expect.
function getProviderAvailabilityLabel(key: string) {
  const expectedKey = providerAvailabilityValues.find(value => value === key)
  if (expectedKey) {
    return availabilityValueToLabel[expectedKey]
  }

  console.warn(`Unexpected provider availability value: ${key}`)
  return key
}

function getProviderAgeLabel(key: string) {
  const expectedKey = providerAgeValues.find(value => value === key)
  if (expectedKey) {
    return ageValueToLabel[expectedKey]
  }

  console.warn(`Unexpected provider age value: ${key}`)
  return key
}

function getProviderGenderLabel(key: string) {
  const expectedKey = providerGenderValues.find(value => value === key)
  if (expectedKey) {
    return genderValueToLabel[expectedKey]
  }

  console.warn(`Unexpected provider gender value: ${key}`)
  return key
}

function parseMedications(medicationString: string) {
  try {
    return JSON.parse(medicationString) as Medication[]
  } catch {
    return medicationString
  }
}

function medicationsToJSON(medications: Medication[]) {
  return JSON.stringify(medications)
}

function getMedicationOutputString(medicationString: string) {
  const parsedMedications = parseMedications(medicationString)

  if (typeof parsedMedications === 'string') {
    return parsedMedications
  }

  return parsedMedications.map(medication => `${medication.name} ${medication.dose} ${medication.frequency}`).join(', ')
}

/** In the beginning of Tava the intake was handled completely through Typeform. Obviously, this is no longer the case. However, since there are some patient's in the db who still
 * have the Typeform intake blob, we still need to be able to parse it.
 */
function parseTypeformIntake(typeformIntakeBlob: TypeformResponse | null | undefined) {
  const intakeDetails = {
    contributingFactors: 'N/A',
    convenientTimes: 'N/A',
    hasAttemptedSuicideLastThreeMonths: 'N/A',
    hasBeenHospitalizedLastThreeMonths: 'N/A',
    wantsMedMgmt: 'N/A',
    medsBeingTaken: 'N/A',
    allergies: 'None',
    emergencyContactName: 'N/A',
    emergencyContactNumber: 'N/A',
  }

  if (!typeformIntakeBlob) {
    return intakeDetails
  }

  intakeDetails.contributingFactors = typeformIntakeBlob.answers
    .find(answer => answer.field.ref === 'contributingFactors')
    .choices.labels.join(', ')
  intakeDetails.convenientTimes = typeformIntakeBlob.answers
    .find(answer => answer.field.ref === 'convenientTimes')
    .choices.labels.join(', ')
  intakeDetails.wantsMedMgmt = typeformIntakeBlob.answers.find(answer => answer.field.ref === 'wantsMedMgmt')?.boolean
    ? 'Yes'
    : 'No' ?? 'N/A'
  intakeDetails.allergies = typeformIntakeBlob.answers.find(answer => answer.field.ref === 'allergies')?.text ?? 'None'
  intakeDetails.medsBeingTaken =
    typeformIntakeBlob.answers.find(answer => answer.field.ref === 'medsBeingTaken')?.text ?? 'N/A'
  intakeDetails.emergencyContactName =
    typeformIntakeBlob.answers.find(answer => answer.field.ref === 'emergencyContactName')?.text ?? 'N/A'
  intakeDetails.emergencyContactNumber =
    typeformIntakeBlob.answers.find(answer => answer.field.ref === 'emergencyContactNumber')?.phone_number ?? 'N/A'

  return intakeDetails
}

export const carePreferencesService = {
  getMedicationOutputString,
  getProviderAgeLabel,
  getProviderAvailabilityLabel,
  getProviderGenderLabel,
  medicationsToJSON,
  parseMedications,
  parseTypeformIntake,
  providerAgeValues,
  providerAvailabilityValues,
  providerGenderValues,
}
