import { css } from '@mui/material'

import { greySet } from '../colorSets'

export const stripeStyles = css`
  .StripeElement {
    height: 40px;
    padding: 2rem 0;
    width: 100%;
    color: #32325d;
    // background-color: white;
    border-bottom: 1px solid ${greySet[50].hex};
    border-radius: 0;
    margin: 0 1rem 0 0;

    &#stripe-card-number {
      flex: 0 0 66%;

      @media (max-width: 47.9375em) {
        flex: 0 0 calc(100% - 1rem);
      }
    }

    @media (max-width: 47.9375em) {
      &#stripe-card-expiration,
      &#stripe-card-cvc {
        flex: 0 0 calc(50% - 1rem);
      }
    }
  }

  .StripeElement--focus {
    border-bottom: 1px solid var(--interactive-fill-color);
    box-shadow: 0 1px 0 0 var(--interactive-fill-color); // using box-shadow since it doesn't affect layout
  }

  .StripeElement--invalid {
    border-color: var(--error-color);
    box-shadow: 0 1px 0 0 var(--error-color); // using box-shadow since it doesn't affect layout
  }

  .StripeElement--webkit-autofill {
    background-color: #fefde5 !important;
  }
`
