import { groupBy, includes } from 'lodash'
import { ReactNode } from 'react'

import { TherapyType } from '../types/exported'

export type BasicSpecialty = {
  name: string
  id: string
  specialtyCategory: { id: string; name: string }
}

const MEN_ONLY = 'Men ONLY'
const WOMEN_ONLY = 'Women ONLY'

enum SpecialtyCategories {
  ageGroups = 'Age Groups',
  area = 'Area of Impact',
  challenges = 'Issues/Challenges',
  ethnicity = 'Ethnicity',
  faith = 'Faith',
  intervention = 'Intervention',
  languages = 'Languages Spoken',
  modalities = 'Modalities',
  populationServed = 'Population Served',
  sexuality = 'Sexuality',
  type = 'Type of Therapy',
}

function formatList(words: string[]) {
  if (words.length > 2) {
    const commaWords = words.slice(0, -1)
    const lastWord = words.slice(-1)

    return `${commaWords.join(', ')} & ${lastWord[0]}`
  }

  return words.join(' & ')
}

function getTherapistTypeString(specialties: BasicSpecialty[]): ReactNode {
  const therapyTypes = specialties
    .filter(specialty => specialty.specialtyCategory.name === SpecialtyCategories.type)
    .filter(specialty => specialty.name !== 'Coaching')
    .map(therapyType => therapyType.name.replace(' Therapy', ''))

  if (therapyTypes.length === 0) {
    return null
  }

  return `${formatList(therapyTypes)} Therapy`
}

function getChallenges(specialties: BasicSpecialty[]) {
  return specialties.filter(specialty => specialty.specialtyCategory.name === SpecialtyCategories.challenges)
}

function getAreasOfImpact(specialties: BasicSpecialty[]) {
  return specialties.filter(specialty => specialty.specialtyCategory.name === SpecialtyCategories.area)
}

function getTypesOfTherapy(specialties: BasicSpecialty[]) {
  return specialties
    .filter(specialty =>
      includes([SpecialtyCategories.type, SpecialtyCategories.intervention], specialty.specialtyCategory.name),
    )
    .filter(specialty => specialty.name !== 'Coaching')
}

function getSpecialtiesByNames(specialties: BasicSpecialty[], names: string[]) {
  return specialties.filter(specialty => names.includes(specialty.name))
}

function getProviderPopulationSpecialties(specialties: BasicSpecialty[]) {
  return specialties.filter(specialty => specialty.specialtyCategory.name === SpecialtyCategories.populationServed)
}

function getProviderPopulationSpecialtiesExludingGender(specialties: BasicSpecialty[]) {
  return specialties.filter(
    specialty =>
      specialty.specialtyCategory.name === SpecialtyCategories.populationServed &&
      ![MEN_ONLY, WOMEN_ONLY].includes(specialty.name),
  )
}

function getProviderPopulationSpecialtiesOnlyGender(specialties: BasicSpecialty[]) {
  return specialties.filter(
    specialty =>
      specialty.specialtyCategory.name === SpecialtyCategories.populationServed &&
      [MEN_ONLY, WOMEN_ONLY].includes(specialty.name),
  )
}

function getProviderAgesSpecialties(specialties: BasicSpecialty[]) {
  return specialties.filter(specialty => specialty.specialtyCategory.name === SpecialtyCategories.ageGroups)
}

function getLanguagesSpokenSpecialties(specialties: BasicSpecialty[]) {
  return specialties.filter(specialty => specialty.specialtyCategory.name === SpecialtyCategories.languages)
}

function getEthnicitySpecialties(specialties: BasicSpecialty[]) {
  return specialties.filter(specialty => specialty.specialtyCategory.name === SpecialtyCategories.ethnicity)
}

function getModalitySpecialties(specialties: BasicSpecialty[]) {
  return specialties.filter(specialty => specialty.specialtyCategory.name === SpecialtyCategories.modalities)
}

function getSexualitySpecialties(specialties: BasicSpecialty[]) {
  return specialties.filter(speciality => speciality.specialtyCategory.name === SpecialtyCategories.sexuality)
}

function getFaithSpecialties(specialties: BasicSpecialty[]) {
  return specialties.filter(speciality => speciality.specialtyCategory.name === SpecialtyCategories.faith)
}

function getGroupedSpecialties(specialties: BasicSpecialty[]) {
  return groupBy(specialties, specialty => specialty.specialtyCategory.name)
}

function isTherapyType(value: string): value is TherapyType {
  return Object.values(TherapyType).includes(value as TherapyType)
}

export const specialtiesService = {
  MEN_ONLY,
  WOMEN_ONLY,
  getTherapistTypeString,
  getChallenges,
  getAreasOfImpact,
  getTypesOfTherapy,
  getSpecialtiesByNames,
  getProviderPopulationSpecialties,
  getProviderPopulationSpecialtiesExludingGender,
  getProviderPopulationSpecialtiesOnlyGender,
  getProviderAgesSpecialties,
  getLanguagesSpokenSpecialties,
  getEthnicitySpecialties,
  getModalitySpecialties,
  getSexualitySpecialties,
  getFaithSpecialties,
  getGroupedSpecialties,
  isTherapyType,
}
