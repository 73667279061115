import { useNavigate } from 'react-router-dom'

import { useAuthDataContext } from '@nuna/auth'
import { CAQHSetup, CAQHSetupHeader } from '@nuna/caqh-setup'
import { useTitle } from '@nuna/common'

import { IntakeLayout } from '../layouts/IntakeLayout'
import { SignupIntakeNavMenu } from '../layouts/IntakeLayout/SignupIntakeHeader/SignupIntakeNavMenu'
import { ProviderSignupIntakeContextProvider } from './SignupIntake/ProviderSignupIntakeContext'

export function CAQHSetupWrapper() {
  useTitle('Provider Insurance Setup')
  const { login } = useAuthDataContext()
  const navigate = useNavigate()
  if (!login || !login.providerId) {
    throw new Error('Not logged in as provider')
  }

  return (
    <IntakeLayout
      header={
        <CAQHSetupHeader
          navMenu={
            <ProviderSignupIntakeContextProvider>
              <SignupIntakeNavMenu onSaveAndNavigate={(to: string) => navigate(to)} />
            </ProviderSignupIntakeContextProvider>
          }
        />
      }
    >
      <CAQHSetup providerId={login.providerId} providerLoginId={login.id} />
    </IntakeLayout>
  )
}
