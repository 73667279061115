import { FillButton, FillButtonProps, GhostButton, GhostButtonProps, TypographyComponentProps } from '@nuna/tunic'

import { DrawerCaption } from './DrawerCaption'

interface DrawerActionsProps {
  primary: FillButtonProps & { 'data-component'?: string }
  secondary?: GhostButtonProps
  caption?: TypographyComponentProps
  className?: string
}

export function DrawerActions({ primary, secondary, caption, className = '' }: DrawerActionsProps) {
  const { className: primaryClassName, ...primaryProps } = primary

  return (
    <div className={`mt-5 text-center ${className}`}>
      {secondary && <GhostButton variant={secondary.variant ?? 'secondary'} {...secondary} />}
      <FillButton className={`mt-2 full-width ${primaryClassName}`} {...primaryProps} />
      {caption && <DrawerCaption className="mt-1" {...caption} />}
    </div>
  )
}
