import { ChangeEvent } from 'react'

import { IconAttachment, IconButtonLabel } from '@nuna/tunic'

interface ConversationAttachmentButtonProps {
  onSelectFiles: (files: File[]) => void
}

export function AttachmentButton({ onSelectFiles }: ConversationAttachmentButtonProps) {
  const handleSelectFile = (e: ChangeEvent<HTMLInputElement>) => {
    if (e.target.files) {
      onSelectFiles(Array.from(e.target.files))
      e.target.value = ''
    }
  }

  return (
    <IconButtonLabel tooltip="Attach file" customSize={35} variant="secondary">
      <IconAttachment size={18} />
      <input hidden type="file" multiple onChange={handleSelectFile} />
    </IconButtonLabel>
  )
}
