import moment from 'moment'
import { HTMLAttributes, useState } from 'react'

import { CredentialFragment, LicenseStatus, useSaveLicensesMutation } from '@nuna/api'
import { useIsAdmin } from '@nuna/auth'
import { addressService, errorService } from '@nuna/core'
import { Card, CardBody, IconButton, IconGear, Switch, csx, toast } from '@nuna/tunic'

import { SourceLicenseReassign } from '../../SourceLicenseReassign'
import { LICENSE_COLUMN_WIDTHS } from './LicenseCardHeader'
import { LicenseStatusLabel } from './LicenseStatusLabel'

const { USStates } = addressService
interface Props extends HTMLAttributes<HTMLDivElement> {
  license: CredentialFragment
}

export function LicenseDisplayCard({ license, className, ...props }: Props) {
  const isAdmin = useIsAdmin()
  const [reassignDialogOpen, setReassignDialogOpen] = useState(false)

  const [updateLicenses] = useSaveLicensesMutation({
    refetchQueries: ['SimpleProvider'],
    awaitRefetchQueries: true,
  })

  const toggleLicenseActive = async () => {
    try {
      await updateLicenses({
        variables: {
          licenses: [
            {
              id: license.id,
              currentlyUtilized: !license.currentlyUtilized,
              providerId: license.providerId,
            },
          ],
        },
      })
    } catch (e) {
      toast.urgent(errorService.transformGraphQlError(e, 'Failed to change license active status'))
    }
  }

  return (
    <div className={csx([className, 'v-align mb-1'])} {...props}>
      {isAdmin && (
        <div style={{ width: LICENSE_COLUMN_WIDTHS[0] }}>
          <Switch checked={license.currentlyUtilized} onChange={toggleLicenseActive}></Switch>
        </div>
      )}

      <Card style={{ flex: 1 }}>
        <CardBody className="v-align" style={{ position: 'relative' }}>
          <div style={{ width: LICENSE_COLUMN_WIDTHS[1] }}>{license.state ? USStates[license.state] : ''}</div>
          <div style={{ width: LICENSE_COLUMN_WIDTHS[2] }}>{license.credential}</div>
          <div style={{ width: LICENSE_COLUMN_WIDTHS[3], textOverflow: 'ellipsis', overflow: 'auto' }}>
            {license.number}
          </div>
          <div style={{ width: LICENSE_COLUMN_WIDTHS[4] }}>
            {license.expiry ? moment(license.expiry).format('M/D/YY') : ''}
          </div>
          <div style={{ width: LICENSE_COLUMN_WIDTHS[5] }}>
            <LicenseStatusLabel status={!license.expiry ? LicenseStatus.NeedsReview : license.status} />
          </div>
          <div style={{ width: LICENSE_COLUMN_WIDTHS[6], display: 'flex', justifyContent: 'center' }}>
            {isAdmin && (license.status === LicenseStatus.Invalid || !license.verifiableId) && (
              <IconButton
                variant="primary"
                tooltip="Reassign source license"
                onClick={() => setReassignDialogOpen(true)}
                style={{ height: 'auto', width: 'auto' }}
              >
                <IconGear size={16} />
              </IconButton>
            )}
          </div>
        </CardBody>
      </Card>
      <SourceLicenseReassign
        license={license}
        isOpen={reassignDialogOpen}
        onClose={() => setReassignDialogOpen(false)}
      />
    </div>
  )
}
