import * as Sentry from '@sentry/browser'
import { LDClient, initialize as ldClientInitialize } from 'launchdarkly-js-client-sdk'
import { ReactNode, createContext, useCallback, useContext, useEffect, useState } from 'react'

import { PaymentPreference } from '@nuna/api'
import { useAuthDataContext } from '@nuna/auth'
import { FeatureMode, useEnvironmentContext } from '@nuna/environment'

import { FeatureFlags, buildDefaultFlags } from '../generated/generated'
import { camelizeFlags } from '../util/camelizeFlags'

export interface LaunchDarklyContexts {
  'eap-company'?: {
    name: string
    key: string
  }
  coverage?: {
    key: PaymentPreference
  }
}

interface ContextValues {
  clientReady: boolean
  flagUserReady: boolean
  failed: boolean
  flags: FeatureFlags
  fallbackPageFactory: () => JSX.Element
  fallbackComponentFactory: () => JSX.Element
  identify: () => Promise<void>
}

interface Props {
  children: ReactNode
  initializeWithPii?: boolean
  fallbackPageFactory?: () => JSX.Element
  fallbackComponentFactory?: () => JSX.Element
  getAdditionalContext?: () => Promise<LaunchDarklyContexts>
}

const FeatureFlagContext = createContext<ContextValues>(buildDefaultContext())

export function FeatureFlagProvider({
  children,
  initializeWithPii = false,
  fallbackComponentFactory = buildDefaultFallbackComponentFactory(),
  fallbackPageFactory = buildDefaultFallbackPageFactory(),
  getAdditionalContext,
}: Props) {
  const { FEATURE_MODE, LAUNCH_DARKLY_CLIENT_ID, CI } = useEnvironmentContext()
  const [client, setClient] = useState<LDClient | undefined>()
  const [flags, setFlags] = useState<FeatureFlags>(setInitialEnvVars(FEATURE_MODE))
  const [clientReady, setClientReady] = useState(FEATURE_MODE !== 'default' || CI ? true : false)
  const [flagUserReady, setFlagUserReady] = useState(FEATURE_MODE !== 'default' || CI ? true : false)
  const { login } = useAuthDataContext()

  useEffect(() => {
    if (!client && FEATURE_MODE === 'default' && !CI) {
      const client = ldClientInitialize(LAUNCH_DARKLY_CLIENT_ID, { anonymous: true })
      setClient(client)
    }
  }, [client, LAUNCH_DARKLY_CLIENT_ID, FEATURE_MODE, CI])

  const identify = useCallback(async () => {
    if (login?.id && client) {
      let user = { key: login.id, kind: 'user', role: login.role }

      if (initializeWithPii) {
        user = { ...user, ...{ firstName: login.firstName, lastName: login.lastName, email: login.email } }
      }

      const additionalContext = getAdditionalContext ? await getAdditionalContext() : {}
      const flagSet = await client.identify({ kind: 'multi', user, ...additionalContext })
      setFlags(camelizeFlags(flagSet))
      setFlagUserReady(true)
    }
  }, [client, login, initializeWithPii, getAdditionalContext])

  useEffect(() => {
    identify()
  }, [identify])

  useEffect(() => {
    const handleIntialized = () => {
      if (client) {
        const flags = client.allFlags()
        setFlags(camelizeFlags(flags))
        setClientReady(true)
      }
    }
    client?.on('ready', handleIntialized)

    return () => client?.off('ready', handleIntialized)
  }, [client])

  useEffect(() => {
    const handleChange = () => {
      if (client) {
        const flags = client.allFlags()
        setFlags(camelizeFlags(flags))
      }
    }
    client?.on('change', handleChange)

    return () => client?.off('change', handleChange)
  }, [client])

  useEffect(() => {
    const handleFailed = () => {
      setClientReady(true)
      setFlagUserReady(true)
      Sentry.captureEvent({ message: 'Failure to intialize Launch Darkly client' })
    }

    client?.on('failed', handleFailed)

    return () => client?.off('failed', handleFailed)
  }, [client])

  useEffect(() => {
    const handleError = (error: Error) => {
      setClientReady(true)
      setFlagUserReady(true)
      Sentry.captureEvent({ message: 'There was an error with Launch Darly', extra: { error } })
    }

    client?.on('error', handleError)

    return () => client?.off('error', handleError)
  })

  const values: ContextValues = {
    fallbackComponentFactory,
    fallbackPageFactory,
    identify,
    clientReady,
    flagUserReady,
    failed: false,
    flags,
  }

  return <FeatureFlagContext.Provider value={values}>{children}</FeatureFlagContext.Provider>
}

export function useFeatureFlagContext() {
  return useContext(FeatureFlagContext)
}

function buildDefaultContext(): ContextValues {
  return {
    clientReady: false,
    flagUserReady: false,
    failed: false,
    fallbackPageFactory: buildDefaultFallbackPageFactory(),
    fallbackComponentFactory: buildDefaultFallbackComponentFactory(),
    flags: buildDefaultFlags(),
    identify: () => new Promise(() => null),
  }
}

function buildDefaultFallbackPageFactory() {
  return () => <div>Oops, you don't have access to this.</div>
}

function buildDefaultFallbackComponentFactory() {
  return () => null as unknown as JSX.Element
}

function setInitialEnvVars(cypressFeatureMode: FeatureMode) {
  const initialFlags = buildDefaultFlags()
  if (cypressFeatureMode !== 'default') {
    const newValue = cypressFeatureMode === 'allOn' ? true : false

    Object.keys(initialFlags).forEach(key => {
      initialFlags[key as keyof FeatureFlags] = newValue
    })
  }

  return initialFlags
}
