import { ReactNode } from 'react'
import { useNavigate } from 'react-router-dom'

import { routeService } from '@nuna/core'
import { IconButton, IconClose, Logo, ProgressHeader } from '@nuna/tunic'

import { useCAQHNavigation } from '../hooks/useCAQHNavigation'

const { caqhSteps } = routeService

export function CAQHSetupHeader({ navMenu }: { navMenu: ReactNode }) {
  const { currentPathIndex } = useCAQHNavigation({ relativeParentPath: '.' }) // This is rendered a step above the routes in the CAQHSetup component so its relative path is at the same level
  const navigate = useNavigate()
  const handleCloseClick = () => {
    navigate('/')
  }

  return (
    <ProgressHeader
      persona="provider"
      max={Object.keys(caqhSteps).length}
      current={currentPathIndex + 1}
      leftSlot={<span className="h6 v-align m-0 text-white">{navMenu} Insurance Setup</span>}
      rightSlot={
        <IconButton variant="primary" onClick={handleCloseClick} tooltip="Close">
          <IconClose color="white" size={20} />
        </IconButton>
      }
      centerSlot={<Logo color="plum" scheme="dark" height={32} />}
    />
  )
}
