import { capitalize, get, startCase } from 'lodash'
import moment from 'moment'
import { useState } from 'react'
import { CSVLink } from 'react-csv'

import { SearchInsurancePolicyValidationsQuery } from '@nuna/api'
import { numberService } from '@nuna/core'
import { Card, CardBody, ContextualAlert, Grid, IconExport, List, ListItem, Switch, greySet } from '@nuna/tunic'

const relevantServiceTypeCodes = [
  'Health benefit plan coverage',
  'Mental health',
  'Psychotherapy',
  'Medical care',
  'Contact following entity for eligibility or benefit information',
]

interface PolicyValidationItemsProps {
  validation: SearchInsurancePolicyValidationsQuery['searchInsurancePolicyValidations']['items'][number]
}

export function PolicyValidationItems({ validation }: PolicyValidationItemsProps) {
  const [hideIrrelevantCoverages, setHideIrrelevantCoverages] = useState(true)

  const coverageResponse: CoverageResponse = validation.eligibilityResponse
  const coverageRequest: EligibilityRequest = validation.eligibilityRequest
  const coverages = coverageResponse.items?.slice() || []
  const dependentCoverages = get(coverageResponse, 'dependents[0].items', [])
  if (dependentCoverages.length > 0) {
    coverages.push(...dependentCoverages)
  }

  const consolidatedCoverages = consolidateCoverageItems(coverages, hideIrrelevantCoverages)
  const filteredCoverages = hideIrrelevantCoverages
    ? consolidatedCoverages.filter(cc => relevantServiceTypeCodes.includes(cc.serviceTypeCode))
    : consolidatedCoverages

  function generateExportData() {
    return coverages.map(c => [
      c.type,
      c.messages?.join(', '),
      c.benefitAmount?.amount,
      c.benefitPercentage,
      c.timePeriodType,
      c.serviceTypeCodes?.join(', '),
      c.coverageLevelType,
      c.inPlanNetworkType,
      c.coverageDescription,
      c.eligibilityFromDate,
      c.eligibilityThroughDate,
    ])
  }

  function generageExportHeaders() {
    return [
      'type',
      'messages',
      'benefitAmount',
      'benefitPercentage',
      'timePeriodType',
      'serviceTypeCodes',
      'coverageLevelType',
      'inPlanNetworkType',
      'coverageDescription',
      'eligibilityFromDate',
      'eligibilityThroughDate',
    ]
  }

  return (
    <>
      <Grid container spacing={2}>
        <Grid size={12}>
          <div className="mb-3 flex-end">
            <Switch checked={hideIrrelevantCoverages} onChange={e => setHideIrrelevantCoverages(e.target.checked)}>
              Hide Irrelevant Coverages
            </Switch>
            <CSVLink
              download="echeckResponse.csv"
              data={generateExportData()}
              headers={generageExportHeaders()}
              className="v-align ml-1"
            >
              <IconExport />
            </CSVLink>
          </div>
        </Grid>
      </Grid>
      <Card depth={-0.5}>
        <CardBody>
          <Grid container spacing={2}>
            {filteredCoverages.map(consolidatedCoverages => (
              <Grid key={consolidatedCoverages.serviceTypeCode} size={12}>
                <h4 className="h5 text-medium mb-1 mt-1 text-default">{consolidatedCoverages.serviceTypeCode}</h4>
                {consolidatedCoverages.plans.map((plan, i) => (
                  <Grid container spacing={2} key={`plan${i}`}>
                    <Grid
                      size={{
                        xs: 12,
                        md: 6,
                      }}
                    >
                      <h5 className="mt-1 mb-xs body text-bold text-secondary">Plan: {plan.coverageDescription}</h5>
                      <div className="caption m-0">{getPlanDates(plan)}</div>
                    </Grid>
                    <Grid
                      size={{
                        xs: 12,
                        md: 6,
                      }}
                    >
                      {plan.activeCoverageItem && (
                        <ContextualAlert role="status" intent="information" className="mr-1">
                          Active Coverage
                        </ContextualAlert>
                      )}
                    </Grid>
                    <Grid size={12}>
                      {Object.entries(plan.coverages).map(([type, coverageItems]) => (
                        <Grid
                          container
                          key={type}
                          style={{ border: `1px solid ${greySet[70]}` }}
                          className="rounded mb-2"
                        >
                          <Grid className="text-bold text-secondary caption p-1" size={12}>
                            {capitalize(startCase(type))}
                          </Grid>
                          {coverageItems.map((coverageItem, i) => (
                            <Grid
                              key={`coverageItem${i}`}
                              size={{
                                xs: 12,
                                md: 6,
                              }}
                            >
                              <Grid container spacing={2} className="pl-2 pr-2 pb-2">
                                <Grid
                                  size={{
                                    xs: 12,
                                    md: 8,
                                  }}
                                >
                                  {coverageItem.inPlanNetworkType === 'YES' && (
                                    <ContextualAlert role="status" intent="information" className="mr-1">
                                      In Network
                                    </ContextualAlert>
                                  )}
                                  {coverageItem.inPlanNetworkType === 'NO' && (
                                    <ContextualAlert role="status" intent="caution" className="mr-1">
                                      Out of Network
                                    </ContextualAlert>
                                  )}
                                  {coverageItem.coverageLevelType && (
                                    <ContextualAlert role="status" className="mr-1">
                                      {capitalize(startCase(coverageItem.coverageLevelType))}
                                    </ContextualAlert>
                                  )}
                                </Grid>
                                <Grid
                                  className="text-right"
                                  size={{
                                    xs: 12,
                                    md: 4,
                                  }}
                                >
                                  {coverageItem.benefitAmount?.amount !== undefined && (
                                    <div>
                                      {numberService.formatCurrency(
                                        Number.parseFloat(coverageItem.benefitAmount.amount),
                                        {
                                          maximumFractionDigits: 2,
                                        },
                                      )}
                                    </div>
                                  )}
                                  {coverageItem.benefitPercentage !== undefined && (
                                    <div>{formatPercentage(coverageItem.benefitPercentage)}</div>
                                  )}
                                  {coverageItem.timePeriodType && (
                                    <div className="caption">{capitalize(startCase(coverageItem.timePeriodType))}</div>
                                  )}
                                </Grid>
                                {(coverageItem.messages || []).length > 0 && (
                                  <Grid className="caption" size={12}>
                                    <List>
                                      {coverageItem.messages?.map((message, i) => (
                                        <ListItem key={`message${i}`}>{message}</ListItem>
                                      ))}
                                    </List>
                                  </Grid>
                                )}
                              </Grid>
                            </Grid>
                          ))}
                        </Grid>
                      ))}
                    </Grid>
                  </Grid>
                ))}
              </Grid>
            ))}
            {coverageRequest && (
              <Grid size={12}>
                <p className="caption">Validation Request</p>
                <p className="caption">
                  <span className="text-default">Payer: </span> {coverageRequest.payerId}
                  <span className="text-default ml-2">Plan Date: </span>
                  {coverageRequest.planDate}
                </p>
                <p className="caption">
                  <span className="text-default">Provider: </span>
                  {coverageRequest.provider.organization ||
                    `${coverageRequest.provider.first} ${coverageRequest.provider.last}`}
                  <span className="text-default ml-2">NPI: </span>
                  {coverageRequest.provider.npi}
                </p>
                <p className="caption">
                  <span className="text-default">Subscriber: </span>
                  {coverageRequest.subscriber.first} {coverageRequest.subscriber.last}
                  <span className="text-default ml-2">DOB: </span>
                  {coverageRequest.subscriber.dob}
                  <span className="text-default ml-2">ID: </span>
                  {coverageRequest.subscriber.id}
                </p>
                {coverageRequest.subscriber.dependent && (
                  <p className="caption">
                    <span className="text-default">Dependent: </span>
                    {coverageRequest.subscriber.dependent.first} {coverageRequest.subscriber.dependent.last}
                    <span className="text-default ml-2">DOB: </span>
                    {coverageRequest.subscriber.dependent.dob}
                  </p>
                )}
              </Grid>
            )}
            {(coverageResponse.validations || []).length > 0 && (
              <Grid size={12}>
                {(coverageResponse?.validations || []).map((error, i) => (
                  <div key={`validation${i}`}>
                    <ContextualAlert intent="urgent">
                      <span>{capitalize(startCase(error.rejectReasonType))}</span>
                      <span className="text-bold ml-2">{capitalize(startCase(error.followupActionType))}</span>
                    </ContextualAlert>
                  </div>
                ))}
              </Grid>
            )}
            {(coverageResponse.errors || []).length > 0 && (
              <Grid size={12}>
                {(coverageResponse?.errors || []).map((error, i) => (
                  <div key={`error${i}`}>
                    <ContextualAlert intent="urgent">
                      <div>{error.message}</div>
                      {error.trace_id && <div className="text-bold">&nbsp;(Trace id: {error.trace_id})</div>}
                    </ContextualAlert>
                  </div>
                ))}
              </Grid>
            )}
            {coverageResponse.failureReason && (
              <Grid size={12}>
                <div key={coverageResponse.failureType}>
                  <ContextualAlert intent="urgent">
                    <div>{coverageResponse.failureReason}</div>
                  </ContextualAlert>
                </div>
              </Grid>
            )}
          </Grid>
        </CardBody>
      </Card>
    </>
  )
}

function formatPercentage(percentage: string): string {
  try {
    return (Number.parseFloat(percentage) * 100).toFixed(2) + '%'
  } catch (error) {
    return percentage
  }
}

function getPlanDates(plan: CoveragePlan) {
  return plan.eligibilityFromDate && `${plan.eligibilityFromDate} - ${plan.eligibilityThroughDate}`
}

function consolidateCoverageItems(
  coverages: CoverageItem[],
  hideIrrelevantCoverages: boolean,
): ConsolidatedCoverages[] {
  return coverages
    .reduce((consolidatedCoverages, coverage) => {
      if (hideIrrelevantCoverages && coverage.inPlanNetworkType === 'NO') {
        return consolidatedCoverages
      }

      if (
        coverage.serviceTypeCodes?.length === 0 &&
        coverage.type === 'CONTACT_FOLLOWING_ENTITY_FOR_ELIGIBILITY_OR_BENEFIT_INFORMATION'
      ) {
        consolidatedCoverages.push({
          serviceTypeCode: capitalize(startCase(coverage.type)),
          plans: [
            {
              coverageDescription: coverage.coverageDescription || '',
              eligibilityFromDate: undefined,
              eligibilityThroughDate: undefined,
              activeCoverageItem: undefined,
              coverages: { [coverage.type]: [coverage] },
            },
          ],
        })
      }

      const fromDate = coverage.eligibilityFromDate && moment.utc(coverage.eligibilityFromDate)
      const eligibilityFromDate = fromDate && fromDate.isValid() ? fromDate.format('L') : undefined
      const throughDate = coverage.eligibilityThroughDate && moment.utc(coverage.eligibilityThroughDate)
      const eligibilityThroughDate = throughDate && throughDate.isValid() ? throughDate.format('L') : undefined
      const coverageDescription = coverage.coverageDescription || ''
      for (const serviceTypeCode of coverage?.serviceTypeCodes || []) {
        const prettyServiceTypeCode = capitalize(startCase(serviceTypeCode))

        const consolidatedCoverage = consolidatedCoverages.find(cc => cc.serviceTypeCode === prettyServiceTypeCode)
        if (consolidatedCoverage) {
          const plan = consolidatedCoverage.plans.find(
            plan =>
              plan.coverageDescription === coverageDescription &&
              eligibilityFromDate === plan.eligibilityFromDate &&
              eligibilityThroughDate === plan.eligibilityThroughDate,
          )
          if (plan) {
            if (coverage.type === 'ACTIVE_COVERAGE') {
              plan.activeCoverageItem = coverage
            } else {
              if (plan.coverages[coverage.type]) {
                plan.coverages[coverage.type].push(coverage)
              } else {
                plan.coverages[coverage.type] = [coverage]
              }
            }
          } else {
            consolidatedCoverage.plans.push({
              coverageDescription,
              eligibilityFromDate,
              eligibilityThroughDate,
              activeCoverageItem: coverage.type === 'ACTIVE_COVERAGE' ? coverage : undefined,
              coverages: coverage.type !== 'ACTIVE_COVERAGE' ? { [coverage.type]: [coverage] } : {},
            })
          }
        } else {
          consolidatedCoverages.push({
            serviceTypeCode: prettyServiceTypeCode,
            plans: [
              {
                coverageDescription: coverage.coverageDescription || '',
                eligibilityFromDate,
                eligibilityThroughDate,
                activeCoverageItem: coverage.type === 'ACTIVE_COVERAGE' ? coverage : undefined,
                coverages: coverage.type !== 'ACTIVE_COVERAGE' ? { [coverage.type]: [coverage] } : {},
              },
            ],
          })
        }
      }
      return consolidatedCoverages
    }, [] as ConsolidatedCoverages[])
    .sort((a, b) => a.serviceTypeCode.localeCompare(b.serviceTypeCode))
}

interface CoverageResponse {
  items?: CoverageItem[]
  dependents?: {
    items?: CoverageItem[]
  }[]
  validations?: CoverageValidation[]
  errors?: TracableError[]
  failureType?: string
  failureReason?: string
}

interface TracableError {
  message: string
  code?: string
  trace_id?: string
}

interface CoverageValidation {
  rejectReasonType?: string
  followupActionType?: string
}

export enum InPlanNetworkType {
  Yes = 'YES',
  No = 'NO',
  Na = 'NA',
  Unknown = 'UNKNOWN',
}

interface CoverageAmount {
  amount: string
  currency: 'USD'
}

interface CoverageItem {
  type: string
  messages?: string[]
  benefitAmount?: CoverageAmount
  benefitPercentage?: string
  timePeriodType?: string
  serviceTypeCodes?: string[]
  coverageLevelType?: string
  inPlanNetworkType?: InPlanNetworkType
  coverageDescription?: string
  eligibilityFromDate?: string
  eligibilityThroughDate?: string
}

interface CoveragePlan {
  coverageDescription: string
  eligibilityFromDate?: string
  eligibilityThroughDate?: string
  activeCoverageItem?: CoverageItem
  coverages: {
    [type: string]: CoverageItem[]
  }
}

interface ConsolidatedCoverages {
  serviceTypeCode: string
  plans: CoveragePlan[]
}

interface EligibilityRequest {
  payerId: string
  planDate?: string
  provider: {
    npi: string
    organization?: string
    first?: string
    last?: string
  }
  subscriber: {
    id: string
    first: string
    last: string
    dob: string
    dependent?: {
      first: string
      last: string
      dob: string
    }
  }
}
