import { useEffect } from 'react'
import { useLocation } from 'react-router-dom'

export function useCloseOnRouteChange(onClose: () => void) {
  const location = useLocation()

  useEffect(() => {
    return () => onClose()
  }, [location, onClose])
}
