import { IconProps } from './IconProps'

export function IconItalic({ size = 24, color = 'currentColor', ...props }: IconProps) {
  return (
    <svg width={size} height={size} viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M29.409 40.066c-1.603.664-2.875 1.14-3.842 1.4-.954.26-1.783.39-2.446.39-1.396 0-2.433-.375-3.138-1.126-.69-.75-1.036-1.69-1.036-2.8 0-.42.027-.853.097-1.315.069-.462.18-1.025.331-1.718l2.75-11.594c.139-.635.277-1.314.401-2.05a9.974 9.974 0 0 0 .194-1.863c0-1.01-.166-1.689-.484-2.035-.318-.332-.981-.506-1.963-.506-.386 0-.898.073-1.52.188-.622.116-1.12.231-1.437.332l.387-1.747c1.354-.635 2.57-1.112 3.649-1.415 1.078-.317 1.92-.462 2.556-.462 1.424 0 2.46.347 3.096 1.069.636.721.968 1.66.968 2.858 0 .332-.028.78-.097 1.329a12.1 12.1 0 0 1-.318 1.689l-2.75 11.608c-.166.708-.332 1.4-.456 2.08a8.589 8.589 0 0 0-.194 1.674c0 1.04.221 1.747.663 2.123.443.39 1.162.577 2.143.577.331 0 .801-.043 1.423-.144.595-.058 1.078-.16 1.41-.289l-.387 1.747ZM30.667 6.18c-.65.78-1.451 1.155-2.405 1.155-.885 0-1.659-.361-2.294-1.098C25.332 5.501 25 4.664 25 3.725c0-.996.318-1.877.968-2.613C26.618.375 27.378 0 28.262 0c.981 0 1.797.347 2.432 1.054.636.679.94 1.487.94 2.411 0 1.04-.318 1.935-.967 2.715Z"
        fill={color}
      />
    </svg>
  )
}
