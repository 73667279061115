import { HTMLAttributes } from 'react'

import { CalendarSyncStatus, Provider } from '@nuna/api'
import { supportService } from '@nuna/telemetry'
import { Box, ContextualAlert, Divider, GhostButton } from '@nuna/tunic'

import { CalendarSyncHeader } from './CalendarSyncHeader'

interface Props extends HTMLAttributes<HTMLDivElement> {
  provider: Provider
  status: CalendarSyncStatus
  onRefresh: () => void
}

export function CalendarSyncSettingUp({ provider, status, onRefresh }: Props) {
  return (
    <Box>
      <CalendarSyncHeader status={status} provider={provider} onRefresh={onRefresh} />

      <div className="v-align" style={{ display: 'flex', flexFlow: 'row', justifyContent: 'space-between' }}>
        <ContextualAlert intent="information" role="status">
          We're connecting and syncing this account, please check back in a few
        </ContextualAlert>
        <GhostButton variant="primary" onClick={supportService.openChat} className="mr-3">
          Contact Support
        </GhostButton>
      </div>

      <Divider className="mt-3 mb-3" />

      <ul>
        <li className="mb-2">
          Prevent conflicts by blocking times in your Tava Calendar when you have events on your personal calendar.
          (Personal events will simply show as busy on your Tava Calendar)
        </li>
        <li className="mb-2">
          Shows Tava appointments directly on your personal calendar, so you never miss an appointment.
        </li>
      </ul>
    </Box>
  )
}
