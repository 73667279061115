import { GlobalStyles, css } from '@mui/material'
import 'normalize.css'

import { tooltipStyles } from '../components/Tooltip/Tooltip'
import { body1, interactiveText, interactiveTextHover } from './colors'
import { cssVars } from './global/cssVars'
import { fonts } from './global/fonts'
import { miscUtils } from './global/misc.utils'
import { spacingUtils } from './global/spacing.utils'
import { lineHeight, sansSerifFontStack, typographyUtils } from './global/typography.utils'
import { stripeStyles } from './overrides/stripeStyles'

const globalCss = css`
  ${cssVars}

  html {
    box-sizing: border-box;
    height: 100%;
  }

  *,
  *:before,
  *:after {
    box-sizing: inherit;
  }

  body {
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
    color: ${body1};
    font-family: ${sansSerifFontStack};
    font-size: 16px;
    margin: 0;
    line-height: ${lineHeight};
  }

  button {
    appearance: none;
    background: 0 0;
    border: 0;
    border-radius: 0;
    color: inherit;
    cursor: pointer;
    font-weight: inherit;
    padding: 0;
  }

  a {
    color: ${interactiveText};
    text-decoration: none;

    &:hover {
      color: ${interactiveTextHover};
      text-decoration: none;
    }
  }

  p {
    // default from bp. Leaving in to avoid surprises
    margin-top: 0;
    margin-bottom: 10px;
  }

  .js-focus-visible :focus:not(.focus-visible) {
    outline: none;
  }

  ${fonts}
  ${typographyUtils}
  ${spacingUtils}
  ${miscUtils}
  ${tooltipStyles}
  ${stripeStyles}
`

// recommended by MUI to avoid re-rendering the <style> tag https://mui.com/material-ui/customization/how-to-customize/#api
export const globalStyles = <GlobalStyles styles={globalCss} />
