import { styled } from '@mui/material'
import { noop, range, uniqueId } from 'lodash'
import React, { useRef } from 'react'

import { IconClose } from '../../icons'
import { greySet, salmonSet, tealSet } from '../../styles/colorSets'
import { borderGrey, error as errorColor } from '../../styles/colors'
import { shadowDepth } from '../../styles/shadows'
import { csx } from '../../utils/csx'
import { IconButton } from '../Button'
import { Tooltip } from '../Tooltip/Tooltip'

export type ChipType = 'checkbox' | 'link' | 'display'

export interface ChipProps extends React.HTMLProps<HTMLInputElement> {
  children: React.ReactNode
  checked?: boolean
  containerProps?: React.HTMLAttributes<HTMLDivElement>
  anchorProps?: React.HTMLProps<HTMLAnchorElement>
  id?: string
  onRemove?: React.MouseEventHandler<HTMLButtonElement>
  small?: boolean
  dataTestId?: string
  truncateAt?: number
  type?: ChipType
  error?: boolean
}

export function Chip({
  children,
  checked = false,
  className = '',
  containerProps = {},
  id = undefined,
  onRemove = undefined,
  small = false,
  style = {},
  onChange = noop,
  dataTestId = '',
  truncateAt,
  type = 'checkbox',
  error = false,
  anchorProps = {},
  ...props
}: ChipProps) {
  const idRef = useRef(id ?? uniqueId('chip-'))

  const getChipContainerProps = () => {
    if (type === 'checkbox') {
      return { htmlFor: idRef.current }
    }

    if (type === 'link') {
      return { as: 'a', ...anchorProps }
    }

    return { as: 'div' }
  }

  return (
    <Wrapper className={className} style={style} $small={small} {...containerProps}>
      {type === 'checkbox' && (
        <input
          id={idRef.current}
          type="checkbox"
          checked={checked}
          onChange={onChange}
          className="fs-exception"
          {...props}
        />
      )}
      {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
      {/* @ts-ignore - getting the typings all sorted out 2ith 3 different possiblities for as is going to be a chore */}
      <ChipContainer
        {...getChipContainerProps()}
        $small={small}
        data-testid={dataTestId}
        $error={error}
        className={csx([{ 'is-link': type === 'link' }, 'chip-container'])}
      >
        {truncateAt ? (
          <Tooltip content={children}>
            <TruncatedContent width={truncateAt}>{children}</TruncatedContent>
          </Tooltip>
        ) : (
          children
        )}

        {onRemove && (
          <IconButton
            variant={error ? 'destroy' : 'secondary'}
            customSize={23}
            tooltip="Remove"
            type="button"
            className="remove-button"
            style={{ transform: 'translate(0.375rem, .0625em)' }} // translate to offset some of the default padding on the chip since the icon button hover state doesn't really need the space and makes it look weird
            onClick={e => {
              e.preventDefault()
              onRemove(e)
            }}
          >
            <IconClose size={12} />
          </IconButton>
        )}
      </ChipContainer>
    </Wrapper>
  )
}

const Wrapper = styled('div')<{ $small: boolean }>`
  display: inline-block;

  [role='group'] > & {
    margin-bottom: 0.75rem;
    margin-right: 0.75rem;

    ${props =>
      props.$small &&
      `
    margin-bottom: 0.5rem;
    margin-right: 0.5rem;
    `}
  }

  input {
    opacity: 0;
    position: absolute;
    pointer-events: none;
  }
`

const ChipContainer = styled('label')<{ $small: boolean; $error: boolean }>`
  align-items: center;
  background-color: ${props => (props.$error ? salmonSet.tint[20] : greySet[0].hex)};
  border-radius: 6px;
  border: 1px solid ${props => (props.$error ? errorColor : borderGrey)};
  color: ${props => (props.$error ? errorColor : greySet.primary.hex)};
  display: inline-flex;
  font-size: 1rem;
  font-weight: 300;
  padding: 0.8125rem 1rem;
  position: relative;
  transition: background-color 0.3s, box-shadow 0.3s;

  ${({ $small }) =>
    $small &&
    `
    padding: 0.375rem 0.75rem;
    font-size: 0.875rem;
  `}

  &::before,
  &::after {
    border-radius: 6px;
    content: '';
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: -1;
  }

  input:not(:disabled) + & {
    cursor: pointer;
  }

  // on checked, change background, border, text colors
  input:checked + & {
    background-color: ${tealSet[5].hex};
    border-color: ${tealSet[30].hex};
    color: ${tealSet[80].hex};
  }

  // on all hovers, show drop shadow,
  input:not(:disabled) + &:hover,
  &.is-link:hover {
    box-shadow: ${shadowDepth(1)};
  }

  // on unchecked hover & focus, set background to white, make border thicker
  input:not(:disabled):not(:checked) + &:hover,
  input:not(:disabled):not(:checked):focus + &,
  &.is-link:hover,
  &.is-link:focus {
    background-color: #fff;

    &::after {
      // since box-shadow on the element is handling the drop shadow, create the thicker border using the &::after element
      box-shadow: 0 0 0 2px ${borderGrey};
    }
  }

  // on checked hover & focus, make thicker border teal
  input:checked + &:hover,
  input:checked:focus + & {
    &::after {
      box-shadow: 0 0 0 2px ${tealSet[30].hex};
    }
  }

  // on unchecked focus, maintain white bg and show grey focus outline
  input:focus:not(:checked) + &,
  &.is-link:focus-visible {
    &::before {
      box-shadow: 0 0 0 7px ${greySet[15].hex};
    }
    outline-width: 0;
  }

  // on checked focus, show teal focus outline
  input:focus:checked + & {
    &::before {
      box-shadow: 0 0 0 7px ${tealSet[5].hex};
    }
  }

  &.is-link {
    :hover {
      color: inherit;
    }
  }
`

const TruncatedContent = styled('span')<{ width: number }>`
  max-width: ${({ width }) => `${width}px`};
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
`

export function ChipLoader({ quantity, ...props }: { quantity: number } & Omit<ChipProps, 'children'>) {
  return (
    <>
      {range(quantity).map(val => (
        <Chip key={val} disabled className="loading" {...props}>
          Loading
          {range(Math.floor(Math.random() * 4)).map(() => '&nbsp;')} {/* makes the loading chips random sizes */}
        </Chip>
      ))}
    </>
  )
}
