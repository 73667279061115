import { INSERT_ORDERED_LIST_COMMAND, INSERT_UNORDERED_LIST_COMMAND } from '@lexical/list'
import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext'
import { styled } from '@mui/material'
import { FORMAT_TEXT_COMMAND } from 'lexical'
import { ChangeEventHandler, HTMLAttributes } from 'react'

import { IconBold, IconBulletedList, IconItalic, IconNumberedList } from '../../icons'
import { borderGrey } from '../../styles/colors'
import { IconButton, IconButtonProps } from '../Button'
import { rteUtils } from './util'

type ToolbarItem = 'bold' | 'italic' | 'bulleted-list' | 'numbered-list'

export interface RichTextToolbarProps extends HTMLAttributes<HTMLDivElement> {
  toolbarItems?: (ToolbarItem | JSX.Element)[]
  onAttachmentFileSelect?: ChangeEventHandler<HTMLInputElement>
}

export function RichTextToolbar({
  toolbarItems = ['bold', 'italic', 'bulleted-list', 'numbered-list'],
  ...props
}: RichTextToolbarProps) {
  const [editor] = useLexicalComposerContext()

  return (
    <Container {...props}>
      {toolbarItems.map(toolbarItem => {
        if (typeof toolbarItem !== 'string') {
          return toolbarItem
        }

        switch (toolbarItem) {
          case 'bold':
            return (
              <RichTextToolbarButton
                key={toolbarItem}
                tooltip="Bold"
                onClick={() => {
                  editor.dispatchCommand(FORMAT_TEXT_COMMAND, 'bold')
                }}
              >
                <IconBold size={20} />
              </RichTextToolbarButton>
            )
          case 'italic':
            return (
              <RichTextToolbarButton
                key={toolbarItem}
                tooltip="Italicize"
                onClick={() => {
                  editor.dispatchCommand(FORMAT_TEXT_COMMAND, 'italic')
                }}
              >
                <IconItalic size={20} />
              </RichTextToolbarButton>
            )
          case 'bulleted-list':
            return (
              <RichTextToolbarButton
                key={toolbarItem}
                tooltip="Bulleted list"
                onClick={() => {
                  rteUtils.list.toggleList(editor, INSERT_UNORDERED_LIST_COMMAND)
                }}
              >
                <IconBulletedList size={18} />
              </RichTextToolbarButton>
            )
          case 'numbered-list':
            return (
              <RichTextToolbarButton
                key={toolbarItem}
                tooltip="Numbered list"
                onClick={() => {
                  rteUtils.list.toggleList(editor, INSERT_ORDERED_LIST_COMMAND)
                }}
              >
                <IconNumberedList size={18} />
              </RichTextToolbarButton>
            )
          default:
            return null
        }
      })}
    </Container>
  )
}

export function RichTextToolbarButton(props: Pick<IconButtonProps, 'tooltip' | 'onClick' | 'children'>) {
  return <IconButton {...props} customSize={35} variant="secondary" type="button" />
}

const Container = styled('div')`
  background-color: #fff;
  border: 1px solid ${borderGrey};
  border-radius: var(--border-radius-sm);
  padding: 0.25rem 0.75rem;
`
