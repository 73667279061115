import { CustomerCompanyDetailsFragment } from '@nuna/api'
import { useIsAdmin } from '@nuna/auth'

import { CustomerBenefitsGuideAdmin } from './CustomerBenefitsGuideAdmin'
import { CustomerBenefitsGuideView } from './CustomerBenefitsGuideView'

interface Props {
  customerCompany: CustomerCompanyDetailsFragment | undefined
}

export function CustomerBenefitsGuide({ customerCompany }: Props) {
  const isAdmin = useIsAdmin()

  if (isAdmin) {
    return <CustomerBenefitsGuideAdmin />
  }

  return <CustomerBenefitsGuideView customerCompany={customerCompany} />
}
