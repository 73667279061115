import { useState } from 'react'

import { DrawerStepper, useAppointmentDrawerSearchParams } from '@nuna/common'
import { IconRescheduleAppointment, TextButton } from '@nuna/tunic'

import { AppointmentDrawerPaddedContainer } from '../AppointmentDrawer'
import { DrawerAppointmentSelect } from './DrawerAppointmentSelect'
import { DrawerHeader } from './DrawerHeader'

export function RescheduleApointmentSelect() {
  const [selectedAppointmentId, setSelectedAppointmentId] = useState<string | null>(null)
  const { openCancelAppointmentDrawer, openRescheduleAppointmentDrawer } = useAppointmentDrawerSearchParams()

  return (
    <>
      <DrawerHeader icon={<IconRescheduleAppointment isHovered style={{ marginTop: -2 }} />}>Reschedule</DrawerHeader>
      <AppointmentDrawerPaddedContainer>
        <DrawerAppointmentSelect
          className="mt-2"
          value={selectedAppointmentId}
          onChange={e => setSelectedAppointmentId(e.currentTarget.value)}
        />
        <DrawerStepper
          className="mt-4"
          nextButtonDiabled={!selectedAppointmentId}
          onNextButtonClick={() => openRescheduleAppointmentDrawer(selectedAppointmentId)}
        />
        <div className="text-secondary mt-2">
          You can also{' '}
          <TextButton
            disabled={!selectedAppointmentId}
            onClick={() => openCancelAppointmentDrawer(selectedAppointmentId)}
          >
            cancel this session
          </TextButton>
        </div>
      </AppointmentDrawerPaddedContainer>
    </>
  )
}
