import { HTMLAttributes } from 'react'

import { CashPayRateForm } from './components/CashPayRateForm'

interface Props extends HTMLAttributes<HTMLDivElement> {
  providerId?: string | null
}

export function CustomRates({ providerId }: Props) {
  return (
    <div>
      <CashPayRateForm providerId={providerId} />
    </div>
  )
}
