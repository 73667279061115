import { MenuItem, Select, SelectChangeEvent, SelectProps } from '@mui/material'

import { PatientCoverageFragment } from '@nuna/api'
import { TherapyType } from '@nuna/core'

export interface ClientTherapyTypeSelectProps
  extends Omit<SelectProps, 'value' | 'onChange' | 'children' | 'defaultValue'> {
  providerId: string
  coverage: PatientCoverageFragment | undefined
  value?: string | null
  onChange: (therapyType: string | null) => void
}

export function ClientTherapyTypeSelect({
  value,
  providerId,
  coverage,
  onChange,
  ...props
}: ClientTherapyTypeSelectProps) {
  const therapyTypes = coverage?.details?.providers.find(provider => provider.id === providerId)?.therapyTypeItems ?? []
  const initValue = value ?? therapyTypes.find(it => it.enabled && it.name === TherapyType.INDIVIDUAL_THERAPY)?.id

  if (!initValue) return null

  const handleChange = (event: SelectChangeEvent) => {
    const id = event.target.value
    onChange(therapyTypes.find(it => it.id === id)?.id ?? null)
  }

  return (
    <Select autoWidth onChange={handleChange} value={initValue} {...props}>
      {therapyTypes.map(therapyType => {
        return (
          <MenuItem key={therapyType.id} value={therapyType.id} disabled={!therapyType.enabled}>
            <span className="text-medium">{therapyType.name}</span>
            {!therapyType.enabled && (
              <span style={{ paddingLeft: '4rem' }} className="ml-auto caption text-medium">
                Not offered
              </span>
            )}
          </MenuItem>
        )
      })}
    </Select>
  )
}
