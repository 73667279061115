import { styled } from '@mui/material'
import { CardCvcElement, CardExpiryElement, CardNumberElement, Elements } from '@stripe/react-stripe-js'
import { loadStripe } from '@stripe/stripe-js'
import { noop, omit } from 'lodash'
import { useMemo, useState } from 'react'
import { useSearchParams } from 'react-router-dom'

import { useCancelPolicyStatusQuery, usePatientContextQuery } from '@nuna/api'
import { MODAL_LATE_CANCELATION_POLICY } from '@nuna/core'
import { useEnvironmentContext } from '@nuna/environment'
import {
  Card,
  CardBody,
  CardBoot,
  Checkbox,
  FillButton,
  FillButtonProps,
  IconInfo,
  OutlineButton,
  OutlineButtonProps,
  TextButton,
  Tooltip,
  body1,
  error,
  greySet,
  sansSerifFontStack,
} from '@nuna/tunic'

import { useCreditCardForm } from '../hooks/useCreditCardForm'
import { CreditCardSelectChange } from '../types'

const CARD_ELEMENT_OPTIONS = {
  style: {
    base: {
      color: body1,
      fontFamily: sansSerifFontStack,
      fontSmoothing: 'antialiased',
      fontSize: '16px',
      '::placeholder': {
        color: greySet[50].hex,
      },
    },
    invalid: {
      color: error,
      iconColor: error,
    },
  },
}

export interface SavedStripeCard {
  stripeId: string
  isHSA: boolean
}

interface CreditCardFormProps {
  className?: string
  onSuccess?: (card: CreditCardSelectChange) => void
  initialErrorMessage?: string
  showHSACheckbox?: boolean
  onHSAChange?: (isHSA: boolean) => void
  patientId?: string
  useOutlineButton?: boolean
  showProviderFees?: () => void
}

export function CreditCardForm(props: CreditCardFormProps) {
  const { data: patientData } = usePatientContextQuery()
  const { CI, STRIPE_PUBLISHABLE_KEY = '', app } = useEnvironmentContext()
  const [_, setSearchParams] = useSearchParams()
  const { data: cancelPolicyData } = useCancelPolicyStatusQuery({
    variables: {
      providerId: patientData?.patientContext?.patient?.providers?.[0]?.id ?? '',
      clientId: props.patientId || patientData?.patientContext?.patient?.id,
      defaultToTavaPolicy: false,
    },
    skip: !patientData?.patientContext?.patient?.providers?.[0]?.id,
  })
  const { availableCancelPolicy, cancelPolicy } = cancelPolicyData?.cancelPolicyStatus ?? {}

  let showProviderFees = undefined

  if (app === 'arrow' && (availableCancelPolicy || cancelPolicy)) {
    showProviderFees = () => {
      // View existing cancel policy
      setSearchParams(oldParams => {
        oldParams.set(MODAL_LATE_CANCELATION_POLICY, patientData?.patientContext?.patient?.providers?.[0]?.id ?? '')
        return oldParams
      })
    }
  }

  const stripePromise = useMemo(
    () => (!CI ? loadStripe(STRIPE_PUBLISHABLE_KEY) : new Promise<null>(resolve => resolve(null))),
    [CI, STRIPE_PUBLISHABLE_KEY],
  )

  if (CI) {
    return null
  }

  const patientId = props.patientId || patientData?.patientContext?.patient?.id

  return (
    <Elements
      stripe={stripePromise}
      options={{
        fonts: [
          {
            family: 'Sofia Pro',
            src: 'url(https://tava-public-assets.s3.us-west-2.amazonaws.com/fonts/sofia-pro-light.otf)',
            weight: '400',
          },
        ],
      }}
    >
      <FormInputs {...props} patientId={patientId} showProviderFees={showProviderFees} />
    </Elements>
  )
}

function FormInputs({
  className = '',
  onSuccess = noop,
  onHSAChange = noop,
  showHSACheckbox,
  initialErrorMessage = '',
  useOutlineButton = false,
  patientId,
  showProviderFees,
}: CreditCardFormProps) {
  const [isHSACard, setIsHSACard] = useState(false)
  const onSuccessWrapper = (stripeId: string) => onSuccess({ stripePaymentMethodId: stripeId, isHsa: isHSACard })
  const stripeForm = useCreditCardForm(onSuccessWrapper, initialErrorMessage, patientId)

  if (!stripeForm) {
    return null
  }

  const { handleSubmit, errorMessage, isFormBusy, stripe } = stripeForm

  const showBoot = !isHSACard && !!showProviderFees
  return (
    <div className={className}>
      <Card intent={errorMessage ? 'urgent' : 'default'} depth={isHSACard ? 0 : 1} style={{ backgroundColor: 'white' }}>
        <CardBody role="form">
          <h3 className="text-bold body">Add a new credit card</h3>
          <div className="caption text-light-grey italic v-align">
            A pending charge will be held to verify funds
            <Tooltip content="We'll temporarily place a $20 hold on your credit card to verify it. This pending charge will be canceled afterward.">
              <span className="ml-1 v-align">
                <IconInfo size={18} />
              </span>
            </Tooltip>
          </div>

          <Grid>
            <CardNumberElement
              onReady={element => {
                element.focus()
              }}
              id="stripe-card-number"
              options={CARD_ELEMENT_OPTIONS}
            />
            <CardExpiryElement id="stripe-card-expiration" options={CARD_ELEMENT_OPTIONS} />
            <CardCvcElement id="stripe-card-cvc" options={CARD_ELEMENT_OPTIONS} />
          </Grid>

          <div style={{ gap: 'var(--spacing-3)' }} className="v-align space-between mt-4 flex-wrap">
            {showHSACheckbox && (
              <Checkbox
                onChange={() => {
                  onHSAChange(!isHSACard)
                  setIsHSACard(!isHSACard)
                }}
                checked={isHSACard}
              >
                This is an HSA Card
              </Checkbox>
            )}

            <VariableButton
              disabled={isFormBusy || stripe === null}
              isLoading={isFormBusy}
              type="button"
              variant={errorMessage ? 'destroy' : 'primary'}
              onClick={handleSubmit}
              useOutlineButton={useOutlineButton}
            >
              Save Card
            </VariableButton>
          </div>
        </CardBody>
        {showBoot && (
          <CardBoot>
            <p className="italic mb-0">
              By saving, I understand{' '}
              {showProviderFees ? (
                <TextButton onClick={showProviderFees} className="italic">
                  fees
                </TextButton>
              ) : (
                'fees'
              )}{' '}
              may apply if I cancel or reschedule less than 24 hours before a session.
            </p>
          </CardBoot>
        )}
      </Card>
      {errorMessage && <p className="text-center mt-1 caption text-medium text-error">{errorMessage}</p>}
    </div>
  )
}

type VariableButtonProps =
  | ({ useOutlineButton: true } & OutlineButtonProps)
  | ({ useOutlineButton: false } & FillButtonProps)

function VariableButton(props: VariableButtonProps) {
  if (props.useOutlineButton) {
    return <OutlineButton {...omit(props, 'useOutlineButton')} />
  } else {
    return <FillButton {...omit(props, 'useOutlineButton')} />
  }
}

const Grid = styled('div')`
  display: grid;
  gap: 0 1rem;
  grid-template-columns: repeat(4, 1fr);

  div:first-child {
    grid-column: 1 / 5;
  }

  div:nth-child(2) {
    grid-column: 1 / 4;
  }

  div:nth-child(3) {
    grid-column: 4 / 5;
  }
`
