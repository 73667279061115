import * as Yup from 'yup'
import { styled } from '@mui/material'
import { Formik } from 'formik'
import moment from 'moment-timezone'
import { CSSProperties, useMemo, useState } from 'react'

import {
  OrganizationRelationship,
  PatientPaymentMethodFragment,
  PaymentPreference,
  usePatientContextQuery,
  useSavePatientIntakeMutation,
} from '@nuna/api'
import { paymentMethodService } from '@nuna/core'
import { CreditCard, PaymentMethodSelect, useClientPaymentMethods } from '@nuna/payment-methods'
import { FillButton, IconButton, IconChevronThick, SiderailComponents, Skeleton, csx, toast } from '@nuna/tunic'

import { useSaveAccessCodeCoverage } from '../../hooks/useSaveAccessCodeCoverage'
import { useSaveEmployerCoverage } from '../../hooks/useSaveEmployerCoverage'
import {
  CoverageForm,
  CoverageFormValues,
  buildEmployerCoverageInput,
  coverageInitialValues,
  coverageSchema,
  employerCoverageSchema,
  employerSchema,
} from '../CoverageForm'
import { SwitchToInsuranceVerification } from './components/SwitchToInsuranceVerification'

interface SwitchCoverageFormValues extends CoverageFormValues {
  paymentMethod: PatientPaymentMethodFragment | null
}

const checkoutEmployerCoverageSchema = employerCoverageSchema.shape({
  employer: employerSchema.test(
    'offersTava',
    params => `We're sorry, ${params.value?.name} is not using Tava yet`,
    value => value?.offersTava === true,
  ),
})

const checkoutCoverageSchema = coverageSchema.shape({
  employerValues: checkoutEmployerCoverageSchema.nullable().when('paymentPreference', {
    is: PaymentPreference.Employer,
    then: checkoutEmployerCoverageSchema.required(),
  }),
  paymentMethod: Yup.object<PatientPaymentMethodFragment>()
    .nullable()
    .when('paymentPreference', {
      is: PaymentPreference.Cash,
      then: Yup.object<PatientPaymentMethodFragment>()
        .required("You'll need to select a card to continue")
        .test('is-expired', "You'll need to add a new card to continue", (card: CreditCard | null) =>
          paymentMethodService.isCardValid(card),
        ),
    }),
})

export interface SwitchCoverageFormProps {
  onSubmitComplete: () => void
  onBackClick: () => void
  style?: CSSProperties
  className?: string
  isNewAccount?: boolean
  prefilledValues?: Partial<CoverageFormValues>
  providerAcceptsInsurance?: boolean
  providerAcceptsReferrals?: boolean
}

export function SwitchCoverageForm({
  onSubmitComplete,
  onBackClick,
  style = {},
  className = '',
  isNewAccount = false,
  prefilledValues = {},
  providerAcceptsInsurance = true,
  providerAcceptsReferrals = true,
}: SwitchCoverageFormProps) {
  const { data: patientContextData } = usePatientContextQuery()
  const [savePatientIntake, { loading: preferenceLoading }] = useSavePatientIntakeMutation({
    refetchQueries: ['PatientCoverage'], // if preference changes, requery anything using PatientCoverage
  })
  const [savePatientEmployer, { loading: employerLoading }] = useSaveEmployerCoverage({
    refetchQueries: ['PatientCoverage'], // if preference changes, requery anything using PatientCoverage
  })
  const [savePatientAccessCode, { loading: accessCodeLoading }] = useSaveAccessCodeCoverage({
    refetchQueries: ['PatientCoverage'], // if preference changes, requery anything using PatientCoverage
  })

  // insurance
  const [showInsuranceVerification, setShowInsuranceVerification] = useState(false)

  const patient = patientContextData?.patientContext.patient

  const {
    paymentMethods,
    defaultForSessions,
    savePaymentMethod,
    queryResult: { loading: paymentMethodsLoading },
  } = useClientPaymentMethods(patient?.id)

  const upcomingAppointments = useMemo(
    () =>
      (patient?.appointments ?? []).filter(
        appointment => moment(appointment.endDatetime).isAfter(moment()) && !appointment.canceled,
      ),
    [patient],
  )

  if (!patient) {
    return null
  }

  const handleSubmit = async (values: SwitchCoverageFormValues) => {
    if (values.paymentPreference === PaymentPreference.Insurance) {
      setShowInsuranceVerification(true) // let <SwitchToInsuranceVerification /> take it from here
      return
    }

    if (values.paymentPreference === PaymentPreference.Employer) {
      const response = await savePatientEmployer({
        variables: {
          id: patient.id,
          employer: buildEmployerCoverageInput(values),
          setAsPreferredPaymentMethod: true,
        },
      })

      if (!response.didSucceed) {
        if (values.employerValues?.employerAssociation !== OrganizationRelationship.Employee) {
          toast.urgent('This organization is not offering coverage to dependents')
        } else {
          toast.urgent(response.error?.message)
        }
        return
      }
    } else if (values.paymentPreference === PaymentPreference.Accesscode) {
      const response = await savePatientAccessCode({
        variables: {
          id: patient.id,
          code: values.accessCodeValues?.accessCode ?? '',
          setAsPreferredPaymentMethod: true,
        },
      })

      if (!response.didSucceed) {
        toast.urgent(response.error?.message)
        return
      }
    } else if (values.paymentPreference === PaymentPreference.Cash) {
      try {
        await savePatientIntake({
          variables: {
            id: patient.id,
            input: {
              paymentPreference: values.paymentPreference,
            },
          },
        })
      } catch {
        toast.urgent('Oops, something went wrong. Try submitting again.')
      }
    }

    onSubmitComplete()
  }

  if (paymentMethodsLoading) return <Skeleton height={4} />

  return (
    <Formik
      validationSchema={checkoutCoverageSchema}
      onSubmit={handleSubmit}
      initialValues={{
        ...coverageInitialValues,
        ...prefilledValues,
        paymentMethod: defaultForSessions ?? null,
      }}
    >
      {({ setFieldValue, values, handleSubmit, isValid, resetForm }) => (
        <FlexContainer style={style} className={csx([className])}>
          {showInsuranceVerification ? (
            <SwitchToInsuranceVerification
              patient={patient}
              upcomingAppointments={upcomingAppointments}
              onSubmitComplete={onSubmitComplete}
              onBackClick={() => setShowInsuranceVerification(false)}
              onCancelClick={() => {
                setShowInsuranceVerification(false)
                setFieldValue('paymentPreference', null)
                onBackClick()
              }}
              isNewAccount={isNewAccount}
            />
          ) : (
            <>
              <div>
                {values.paymentPreference === null && (
                  <h2 className="h5">How will your care be covered going forward?</h2>
                )}
                {values.paymentPreference === PaymentPreference.Cash && (
                  <PaymentMethodSelect
                    paymentMethods={paymentMethods}
                    value={defaultForSessions}
                    onChange={card => {
                      setFieldValue('paymentMethod', card)
                    }}
                    defaultFor="sessions"
                    loading={paymentMethodsLoading}
                    patientId={patient.id}
                    savePaymentMethod={savePaymentMethod}
                  />
                )}

                <form onSubmit={handleSubmit}>
                  <CoverageForm
                    inDrawer
                    patient={patient}
                    providerAcceptsReferrals={providerAcceptsReferrals}
                    providerAcceptsInsurance={providerAcceptsInsurance}
                  />
                </form>
              </div>

              <div className="v-align mt-6 pb-1">
                <IconButton
                  className="mt-auto"
                  tooltip="Back"
                  type="button"
                  onClick={() => {
                    if (showInsuranceVerification) {
                      setShowInsuranceVerification(false)
                    } else if (values.paymentPreference && !prefilledValues.paymentPreference) {
                      resetForm()
                    } else {
                      onBackClick()
                    }
                  }}
                >
                  <IconChevronThick size={20} />
                </IconButton>

                {values.paymentPreference && (
                  <>
                    <SiderailComponents.Divider className="ml-xs mr-2" />
                    <FillButton
                      isLoading={preferenceLoading || employerLoading || accessCodeLoading}
                      type="button"
                      disabled={!isValid}
                      onClick={() => handleSubmit()}
                      data-testid="siderail-coverage-submit"
                    >
                      Submit
                    </FillButton>
                  </>
                )}
              </div>
            </>
          )}
        </FlexContainer>
      )}
    </Formik>
  )
}

const FlexContainer = styled('div')`
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow: hidden;
`
