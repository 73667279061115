import { styled } from '@mui/material'
import { HTMLAttributes } from 'react'
import { useNavigate } from 'react-router-dom'

import { routeService } from '@nuna/core'
import { DonutGauge, IconCheckOutline, IconChevronRightThin, greySet, sansSerifFontStack, tealSet } from '@nuna/tunic'

import { useProviderSignupIntakeContext } from '../../../../scenes/SignupIntake/ProviderSignupIntakeContext'
import { IntakeStep, getPath } from '../../../../scenes/SignupIntake/signup-intake-steps'
import IntakeMenuAvatarSrc from './img/IntakeMenuAvatar.png'

interface Props extends HTMLAttributes<HTMLDivElement> {
  onSaveAndNavigate: (to: string) => Promise<void>
}

export function SignupIntakeMenu({ onSaveAndNavigate, ...props }: Props) {
  const navigate = useNavigate()
  const { stepStatuses, cancelPolicyStatus, insuranceStepsStatuses, backgroundCheckStatuses } =
    useProviderSignupIntakeContext()

  const handleMenuButtonClick = (step: IntakeStep) => {
    onSaveAndNavigate(getPath(step).pathname)
  }

  return (
    <div {...props}>
      <img src={IntakeMenuAvatarSrc} alt="avatar" />
      <h4 className="mb-3">Setup Progress</h4>
      <MenuButton onClick={() => navigate(routeService.signupIntake('welcome'))}>
        <NavHeader className="body text-medium v-align mb-1 p-1">
          Practice Website <IconChevronRightThin size={16} className="ml-auto" />
        </NavHeader>
      </MenuButton>
      {stepStatuses.map(status => (
        <MenuButton
          type="button"
          className="text-secondary text-medium v-align"
          key={status.label}
          onClick={() => handleMenuButtonClick(status.screens[0].step)}
        >
          <span className="v-align">
            {status.completed >= status.fieldCount ? (
              <IconCheckOutline color={tealSet[50].hex} size={18} className="mr-1" />
            ) : (
              <DonutGauge size={18} target={status.fieldCount} current={status.completed} className="mr-1" />
            )}
            {status.label}
          </span>
        </MenuButton>
      ))}
      {cancelPolicyStatus && (
        <MenuButton
          type="button"
          className="text-secondary text-medium v-align"
          key={cancelPolicyStatus.label}
          onClick={() => handleMenuButtonClick('cancel-policy')}
        >
          <span className="v-align">
            {cancelPolicyStatus.completed ? (
              <IconCheckOutline color={tealSet[50].hex} size={18} className="mr-1" />
            ) : (
              <DonutGauge size={18} target={0} current={1} className="mr-1" />
            )}
            {cancelPolicyStatus.label}
          </span>
        </MenuButton>
      )}
      <MenuButton onClick={() => navigate(routeService.backgroundCheckRoute)}>
        <NavHeader className="body text-medium v-align mb-1 mt-1 p-1">
          Background Check <IconChevronRightThin className="ml-auto" size={16} />
        </NavHeader>
      </MenuButton>
      <MenuButton
        type="button"
        className="text-secondary text-medium v-align"
        onClick={() => navigate(routeService.backgroundCheckRoute)}
      >
        <span className="v-align">
          {backgroundCheckStatuses?.completed ? (
            <IconCheckOutline color={tealSet[50].hex} size={18} className="mr-1" />
          ) : (
            <DonutGauge size={18} target={0} current={100} className="mr-1" />
          )}
          {backgroundCheckStatuses?.label ?? ''}
        </span>
      </MenuButton>
      <MenuButton onClick={() => navigate(routeService.caqhSetup('welcome'))}>
        <NavHeader className="body text-medium v-align mb-1 mt-1 p-1">
          Insurance & Referrals <IconChevronRightThin size={16} className="ml-auto" />
        </NavHeader>
      </MenuButton>
      {insuranceStepsStatuses.map(status => (
        <MenuButton
          type="button"
          className="text-secondary text-medium v-align"
          key={status.label}
          onClick={() => navigate(routeService.caqhSetup(status.slug))}
          disabled={!status.available}
        >
          <span className="v-align">
            {status.completed ? (
              <IconCheckOutline color={tealSet[50].hex} size={18} className="mr-1" />
            ) : (
              <DonutGauge size={18} target={0} current={1} className="mr-1" />
            )}
            {status.label}
          </span>
        </MenuButton>
      ))}

      <hr />
      <MenuButton type="button" onClick={() => navigate('/')} className="text-secondary text-medium v-align pb-2">
        <span>Exit Onboarding</span>
        <IconChevronRightThin className="ml-auto" size={14} />
      </MenuButton>
    </div>
  )
}

const NavHeader = styled('p')`
  font-family: ${sansSerifFontStack};
  color: ${greySet[80].hex};
  display: flex;

  :after {
    content: '';
    border-top: 1px solid ${tealSet[50].hex};
    height: 1px;
    flex-grow: 1;
    margin: 0 0.5rem;
  }
`

const MenuButton = styled('button')<{ disabled?: boolean }>`
  text-align: left;
  width: 100%;
  border-radius: var(--border-radius-sm);
  ${({ disabled }) =>
    !disabled
      ? `:hover {
    background-color: ${greySet[30].hex};
    
    .donut {
      stroke: ${greySet[70].hex};
    }
  }`
      : 'cursor: default; opacity: 0.5;'}

  span {
    width: 100%;
    padding: 8px 4px;
  }
`
