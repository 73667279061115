import { ConversationFragment, useSaveConversationMutation } from '@nuna/api'

export function useSaveConversationMutationWithCacheUpdate() {
  return useSaveConversationMutation({
    update(cache, { data }) {
      const newConversation = data?.saveConversation

      if (!newConversation) {
        return
      }

      cache.modify({
        fields: {
          conversations(existingConversations = [], { readField }) {
            return [
              newConversation,
              ...existingConversations.filter(
                (conversation: ConversationFragment) =>
                  readField('participantHash', conversation) !== newConversation.participantHash,
              ),
            ]
          },
        },
      })
    },
  })
}
