import { DocumentType, useDocumentUpload, useUpdateBenefitsGuideForContractMutation } from '@nuna/api'

export function useBenefitsGuideUpload() {
  const { uploadDocument, loading: uploadLoading } = useDocumentUpload()
  const [updateBenefitsGuideForContract, { loading: contractUpdateLoading }] =
    useUpdateBenefitsGuideForContractMutation({
      refetchQueries: ['AdminBenefitsGuides'],
    })

  const uploadBenefitsGuide = async (file: File, contractId: string) => {
    const document = await uploadDocument(file, DocumentType.BenefitsGuide)

    await updateBenefitsGuideForContract({
      variables: {
        contractId,
        benefitsGuideDocumentId: document.id,
      },
    })

    return document
  }

  return { uploadBenefitsGuide, loading: uploadLoading || contractUpdateLoading }
}
