import { styled } from '@mui/material'
import { useMediaQuery } from 'react-responsive'

import { useProviderAvailabilityConstraintsQuery } from '@nuna/api'
import { ExploreFirstEmptyState } from '@nuna/common'
import { AvailabilityTabs, ManageAvailability } from '@nuna/provider'
import { BelowTablet, GhostButtonLink, TabId } from '@nuna/tunic'

import TextingGirlSrc from '../img/TextingGirl.png'

interface ProviderManageAvailabilityProps {
  onTabChange?: (activeTab: TabId) => void
  showExploreFirstMessage: boolean
  activeAvailabilityTab: TabId
}

const renderTopOffset = (isMobile: boolean, regularActiveAvailabilityTab: boolean) => {
  if (!isMobile) {
    return regularActiveAvailabilityTab ? 270 : 80
  }
  return 180
}

const renderLeftOffset = (isMobile: boolean, regularActiveAvailabilityTab: boolean) => {
  if (!isMobile) {
    return regularActiveAvailabilityTab ? 120 : 0
  }
  return 0
}

export function ProviderManageAvailability({
  onTabChange,
  showExploreFirstMessage,
  activeAvailabilityTab,
}: ProviderManageAvailabilityProps) {
  const { error: didErrorOccurLoadingProvider, data: providerResponse } = useProviderAvailabilityConstraintsQuery({
    fetchPolicy: 'network-only',
  })

  const isMobile = useMediaQuery({ query: `(${BelowTablet})` })

  const provider = providerResponse?.providerMe

  if (didErrorOccurLoadingProvider) {
    return <PaddedBox>Error occurred loading provider</PaddedBox>
  }

  return (
    <PaddedBox>
      {provider && (
        <ManageAvailability
          onTabChange={onTabChange}
          provider={provider}
          refetchQueries={['ProviderAvailabilityConstraints']}
        />
      )}
      {showExploreFirstMessage && (
        <div>
          <ExploreFirstEmptyState
            illustrationSrc={TextingGirlSrc}
            textBlock={
              <>
                <h2 className="h4 mt-0">Your central hub for managing appointments.</h2>
                <p className="large">
                  {/* Link to "Add Your Availability" will be changed to correct url in "ENG-5598 Availability stand alone screen" */}
                  Check your schedule and{' '}
                  <GhostButtonLink to="/availability/add">Add Your Availability</GhostButtonLink>.
                </p>
              </>
            }
            className="intro v-align pr-3"
            topOffset={renderTopOffset(isMobile, activeAvailabilityTab === AvailabilityTabs.Regular)}
            leftOffset={renderLeftOffset(isMobile, activeAvailabilityTab === AvailabilityTabs.Regular)}
          />
        </div>
      )}
    </PaddedBox>
  )
}

const PaddedBox = styled('div')`
  padding: 0.5rem;
`
