import * as Sentry from '@sentry/browser'
import { ApolloLink } from '@apollo/client'
import { onError } from '@apollo/client/link/error'

import { LoggedInUserDocument } from '@nuna/api'

import { redirectToLogin } from './utils'

const UNAUTHENTICATED_ERROR = 'Unauthenticated'
const USER_ROLE_HEADER = 'X-Tava-Role'

export const handleErrorLink = onError(({ graphQLErrors, networkError, operation }) => {
  if (graphQLErrors) {
    const { cache } = operation.getContext()
    const { authContext } = cache.readQuery({ query: LoggedInUserDocument }) ?? {}

    graphQLErrors.map(({ message, locations, path }) =>
      console.error(`[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`),
    )

    if (authContext?.id && graphQLErrors.some(error => error.message === UNAUTHENTICATED_ERROR)) {
      redirectToLogin()
    }
  }
  if (networkError) console.error(`[Network error]: ${networkError}`)
})

export const handleWrongRoleLink = new ApolloLink((operation, forward) => {
  return forward(operation).map(result => {
    const { cache, response } = operation.getContext()
    const { authContext } = cache.readQuery({ query: LoggedInUserDocument }) ?? {}
    const responseRole = response?.headers.get(USER_ROLE_HEADER)

    // Since all apps use the same session cookie, it's possible when making requests
    // from one app to actually be sending auth credentials from another app. If the
    // API tells us that the requests we're making are in the context of different role
    // than what we have stored in our auth context, we kick them out to the login screen.
    if (authContext?.role && responseRole && authContext?.role !== responseRole) {
      Sentry.captureEvent({
        message: `Attempted to login with invalid role ${authContext.role}`,
        extra: { userId: authContext?.id },
      })
      redirectToLogin({ role: authContext.role })
    }

    return result
  })
})
