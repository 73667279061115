import { useInsurancePayerPlanStatesQuery } from '@nuna/api'

export function useHasInsuranceInState(state: string) {
  const queryResult = useInsurancePayerPlanStatesQuery()
  let hasInsuranceInState: boolean | null = null

  const { data } = queryResult

  if (data) {
    hasInsuranceInState = (data.insurancePayerPlanStates ?? []).includes(state)
  }

  return { hasInsuranceInState, queryResult }
}
