import { styled } from '@mui/material'
import { useMediaQuery } from 'react-responsive'

import { Illustration } from '@nuna/common'
import { routeService } from '@nuna/core'
import { BelowTablet, Dialog, FillButtonLink, GhostButtonLink } from '@nuna/tunic'

import CartwheelGirlSrc from './img/CartwheelGirl.png'

export function ExploreMoreWelcome() {
  const isMobile = useMediaQuery({ query: `(${BelowTablet})` })

  return (
    <Dialog isOpen={true} maxWidth="md" fullScreen={isMobile}>
      <div className="vh-align px-6 py-4 relative overflow-hidden">
        <TextWrap className="large text-dark-grey">
          <h2 className="h3 mb-4 text-dark-teal">Welcome to Tava.</h2>
          <p className="display">We’re happy you’re here.</p>
          <p>Enjoy exploring the app at your own pace to get a feel for how everything works.</p>
          <p>
            When you are ready we’ll help you create your own Practice Website, join top insurance networks in your
            area, and get Tava referrals.
          </p>
          <ul className="px-2">
            <li className="mb-4">
              Take a second to{' '}
              <GhostButtonLink variant={'secondary'} to={routeService.providerFeePolicies}>
                review and set up your cancellation policy.
              </GhostButtonLink>
            </li>
            <li className="mb-4">
              Make sure to{' '}
              <GhostButtonLink
                variant={'secondary'}
                target="_blank"
                to={
                  'https://community.tavahealth.com/join?invitation_token=96065337c6aacb26067a2fc4cb2b1e6277baf51b-12ce9821-8132-4bb8-af34-25f04c01b530'
                }
              >
                become part of our community.
              </GhostButtonLink>
            </li>
          </ul>

          <FillButtonLink className="mt-3" to="/schedule">
            Dive in
          </FillButtonLink>
        </TextWrap>
        <WelcomeIllustration src={CartwheelGirlSrc} alt="" />
      </div>
    </Dialog>
  )
}

const TextWrap = styled('div')`
  max-width: 635px;

  p {
    margin-bottom: 1.5rem;
  }
`

const WelcomeIllustration = styled(Illustration)`
  position: absolute;
  bottom: -100px;
  right: -120px;
  max-width: 350px;
  width: 350px;
`
