import * as Yup from 'yup'
import { styled } from '@mui/material'

import { FullProviderFragment, useUpdateProviderMutation } from '@nuna/api'
import { ProfileDisplayValue, ProfileSection } from '@nuna/common'
import { TextField, fontSize } from '@nuna/tunic'

import { incompleteSectionNotification } from '../../../utils/util'
import { VisibleToClientsLabel } from './VisibleToClientsLabel'

export type WrittenBioProps = Pick<FullProviderFragment, 'aboutBlurb' | 'rawAboutBlurb' | 'id'>
type BioValues = Omit<WrittenBioProps, 'id' | 'rawAboutBlurb'>

export function WrittenBio({ aboutBlurb, rawAboutBlurb, id }: WrittenBioProps) {
  const initialValues: BioValues = {
    aboutBlurb: rawAboutBlurb,
  }
  const [updateProvider, { loading: updateLoading }] = useUpdateProviderMutation()

  const [isComplete, valueDisplay] = incompleteSectionNotification<BioValues>(
    initialValues,
    ['aboutBlurb'],
    <BioDisplay className="mr-4" dangerouslySetInnerHTML={{ __html: aboutBlurb ?? '' }} />,
  )

  return (
    <ProfileSection
      heading={
        <>
          Written Bio <VisibleToClientsLabel className="ml-1" />
        </>
      }
      useOutlineSaveButton
      description="Your written bio should appeal to a potential client. Keep it authentic, warm, and personal. Consider including things like: types of people you find yourself helping most often, your approach to working with clients, educational background, licensure, interests and hobbies."
      displayWidth="full"
      updateLoading={updateLoading}
      initialValues={initialValues}
      handleSubmit={values => updateProvider({ variables: Object.assign({ id: id }, values) })}
      disabled={({ isValid }) => !isValid}
      sectionComplete={isComplete}
      renderDisplayValue={valueDisplay}
      renderForm={({ values, handleChange, handleBlur, touched, errors }) => (
        <TextField
          multiline
          className="mr-4"
          name="aboutBlurb"
          rowsMax={20}
          placeholder="Add bio"
          showBottomBorderOnFocus={false}
          value={values.aboutBlurb ?? ''}
          onChange={handleChange}
          onBlur={handleBlur}
          error={(errors.aboutBlurb && touched.aboutBlurb) as boolean}
          helperText={touched.aboutBlurb && errors.aboutBlurb ? errors.aboutBlurb : null}
        />
      )}
      validationSchema={Yup.object().shape({
        aboutBlurb: Yup.string().required('Written Bio is required'),
      })}
    ></ProfileSection>
  )
}

const BioDisplay = styled(ProfileDisplayValue)`
  font-size: ${fontSize.body};
`
