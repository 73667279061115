import { styled } from '@mui/material'
import { ReactNode } from 'react'

import { numberService } from '@nuna/core'
import { tealSet } from '@nuna/tunic'

const { centsToFormattedDollars } = numberService
interface CommonProps {
  type: 'insurance' | 'employer' | 'cash' | 'accessCode'
}

interface InsuranceCostProps {
  type: 'insurance'
  copay: number
}

interface EmployerCostProps {
  type: 'employer'
  sessionCost: number
  isZeroCap?: boolean
}

interface AccessCodeCostProps {
  type: 'accessCode'
  sessionCost: number
}

interface CashCostProps {
  type: 'cash'
  sessionCost: number
}

type ConditionalProps = InsuranceCostProps | EmployerCostProps | AccessCodeCostProps | CashCostProps
type CostProps = CommonProps & ConditionalProps

export function Cost(props: CostProps) {
  let copayRow: ReactNode = null
  let costRow: ReactNode = null
  let formattedTotal = ''
  let formattedSessionCost = ''

  if (props.type === 'insurance') {
    formattedSessionCost = 'TBD'
    formattedTotal = 'TBD'
    copayRow = (
      <tr>
        <th>Copay</th>
        <td>{centsToFormattedDollars(props.copay)}</td>
      </tr>
    )
  } else if (props.type === 'employer') {
    formattedSessionCost = centsToFormattedDollars(props.sessionCost)

    if (props.isZeroCap) {
      formattedTotal = formattedSessionCost
    } else {
      formattedTotal = centsToFormattedDollars(0)
      costRow = (
        <tr>
          <th>Employer benefit</th>
          <td>{centsToFormattedDollars(props.sessionCost * -1)}</td>
        </tr>
      )
    }
  } else if (props.type === 'accessCode') {
    formattedSessionCost = centsToFormattedDollars(props.sessionCost)
    formattedTotal = centsToFormattedDollars(0)
    costRow = (
      <tr>
        <th>Access code balance</th>
        <td>{centsToFormattedDollars(props.sessionCost * -1)}</td>
      </tr>
    )
  } else if (props.type === 'cash') {
    formattedSessionCost = centsToFormattedDollars(props.sessionCost)
    formattedTotal = formattedSessionCost
  }

  return (
    <CostTable>
      <tbody>
        {copayRow}
        <tr>
          <th>Session cost</th>
          <td>{formattedSessionCost}</td>
        </tr>
        {costRow}
      </tbody>
      <tfoot>
        <tr>
          <th>Your total</th>
          <td>{formattedTotal}</td>
        </tr>
      </tfoot>
    </CostTable>
  )
}

const CostTable = styled('table')`
  border-spacing: 0;
  margin-top: var(--margin-3);
  width: 100%;
  padding: 0 0.5rem;

  td,
  th {
    padding: 0 0 0.5rem;
  }

  th {
    text-align: left;
    font-weight: 400;
  }

  td {
    text-align: right;
  }

  tfoot {
    td,
    th {
      border-top: 1px solid ${tealSet.primary.hex};
      font-weight: 600;
      padding-top: 0.5rem;
    }
  }
`
