import { PatientProviderCustomRateType } from '../types/internal-only'
import { numberService } from './number'

type ProviderCompensationRate = {
  maxRate?: number | null
  rate: number
}

function compensationRangeDisplay(compensation: Pick<ProviderCompensationRate, 'rate' | 'maxRate'>) {
  const min = `${numberService.centsToFormattedDollars(compensation.rate)}`

  if (compensation.maxRate && compensation.maxRate !== compensation.rate) {
    return `${min}–${numberService.centsToFormattedDollars(compensation.maxRate, { style: 'decimal' })}`
  }

  return min
}

function customRateAsCentsIfFlatRate(rate: number | null | undefined, rateType: PatientProviderCustomRateType) {
  if (!rate) {
    return rate
  }
  if (rateType === PatientProviderCustomRateType.DiscountPercent) {
    return rate
  }

  return numberService.dollarsToCents(rate)
}

function customRateAsDollarsIfFlatRate(rate: number | null | undefined, rateType: PatientProviderCustomRateType) {
  if (!rate) {
    return rate
  }
  if (rateType === PatientProviderCustomRateType.DiscountPercent) {
    return rate
  }

  return numberService.centsToDollars(rate)
}

export const compensationService = {
  compensationRangeDisplay,
  customRateAsCentsIfFlatRate,
  customRateAsDollarsIfFlatRate,
}
