import mixpanel from 'mixpanel-browser'
import { useLocation, useMatch, useNavigate } from 'react-router-dom'

import {
  type ProviderDetailsQuery,
  type PublicAvailabilitySlot,
  useCreateBookingAsPatientMutation,
  usePatientContextQuery,
} from '@nuna/api'
import { useRankTracking } from '@nuna/appointment'
import { useAuthDataContext, useSourceReferralSearchParams } from '@nuna/auth'
import { useAppointmentDrawerSearchParams } from '@nuna/common'
import { routeService } from '@nuna/core'

interface ScheduleWithProviderArgs {
  provider: ProviderDetailsQuery['provider']
  timeSlot: PublicAvailabilitySlot
  therapyTypeId?: string | null
  addressId: string | undefined
}

export function useScheduleWithProvider() {
  const { closeDrawer } = useAppointmentDrawerSearchParams()
  const location = useLocation()
  const navigate = useNavigate()
  const { trackProviderRank } = useRankTracking()
  const isPublicProviderPage = !!useMatch(routeService.publicProviderPattern)

  const referrals = useSourceReferralSearchParams()
  const { loggedIn } = useAuthDataContext()

  const { data: patientContextData } = usePatientContextQuery({ skip: !loggedIn })
  const [createBookingAsPatient, { loading }] = useCreateBookingAsPatientMutation({
    refetchQueries: ['PatientContext', 'RelatedUsersFeatureFlags'],
  })

  const scheduleWithProvider = async ({ provider, timeSlot, therapyTypeId, addressId }: ScheduleWithProviderArgs) => {
    const { data: booking } = await createBookingAsPatient({
      variables: {
        providerId: provider.id,
        start: timeSlot.start,
        end: timeSlot.end,
        therapyTypeSpecialtyId: therapyTypeId,
        source: referrals.source,
        addressId,
      },
    })
    const bookingId = booking?.createBookingAsPatient.id

    trackProviderRank()

    if (isPublicProviderPage) {
      mixpanel.track('scheduled public provider appointment', { providerId: provider.id })
      if (!patientContextData?.patientContext.patient.intakeCompleted) {
        navigate(`${routeService.intake()}/confirmation`)
        return bookingId
      }
    }

    // if this logic gets any hairier in the future, we should add a way to pass in a redirect path
    if (location.pathname !== '/' && !location.pathname.includes('live-session')) {
      navigate('/')
    } else {
      closeDrawer()
      return bookingId
    }
  }

  return { scheduleWithProvider, loading }
}
