import { styled } from '@mui/material'
import moment from 'moment'

import { AppointmentCurrentStatus, ConversationMessageFragment, Role } from '@nuna/api'
import {
  IconCancelAppointment,
  IconRescheduleAppointment,
  greySet,
  makeTypographyComponent,
  plumSet,
  tealSet,
} from '@nuna/tunic'

/**
 * These come from the AppointmentUpdatedFlow enum in feather apps/feather/src/services/iterable/events.ts
 * Unfortunately it is used in iterable and not in the db or gql so it is not available in generated files :/
 */
const RESCHEDULE_REASONS = ['RESCHEDULED_BY_PATIENT', 'RESCHEDULED_BY_PROVIDER']

// unfortunately we have to use this to cast since we have no exported type for this
interface SystemMessageData {
  status: AppointmentCurrentStatus
  flow: string
  originalTime: string
  newStart: string
}

interface ConversationSystemMessageHeaderProps {
  message: ConversationMessageFragment
}

const rescheduleTimeFormat = 'MMM Do [at] h:mma'

export function ConversationSystemMessageHeader({ message }: ConversationSystemMessageHeaderProps) {
  const systemMessageData = message.typeMetaData as SystemMessageData | undefined
  if (!systemMessageData) {
    return null
  }

  const simpleStatus = getSimpleStatus(systemMessageData.flow)

  const Icon = simpleStatus === 'rescheduled' ? IconRescheduleAppointment : IconCancelAppointment
  const textContent =
    simpleStatus === 'rescheduled'
      ? `moved the appointment from ${moment(systemMessageData.originalTime).format(rescheduleTimeFormat)} to ${moment(
          systemMessageData.newStart,
        ).format(rescheduleTimeFormat)} and left this message:`
      : `canceled the appointment for ${moment(systemMessageData.originalTime).format('MMM Do')} and left this message:`

  return (
    <SystemMessageHeader actor={message.authorDisplayData?.role}>
      <Icon className="mr-1 flex-static" color={greySet[70].hex} isHovered={false} />{' '}
      <span>
        <span>{message.authorDisplayData?.firstName} </span>
        {textContent}
      </span>
    </SystemMessageHeader>
  )
}

function getSimpleStatus(flow: string) {
  if (RESCHEDULE_REASONS.includes(flow)) {
    return 'rescheduled'
  }
  return 'canceled'
}

const SystemMessageHeader = styled(makeTypographyComponent('text-medium text-dark top-align', 'div'))<{
  actor?: Role
}>`
  background-color: ${({ actor }) => {
    switch (actor) {
      case Role.Patient: {
        return tealSet[5].hex
      }
      case Role.Provider: {
        return plumSet[5].hex
      }
      default: {
        return greySet[5].hex
      }
    }
  }};
  padding: var(--margin-2) var(--margin-4);
  line-height: 24px;
`
