import { styled } from '@mui/material'
import React from 'react'

import { AssessmentType, usePatientAssessmentsForLiveSessionQuery } from '@nuna/api'
import { AssessmentFromAssessmentsQuery } from '@nuna/assessments'
import { toast } from '@nuna/tunic'
import { greySet } from '@nuna/tunic'

import { SidebarCard } from '../../SidebarCard'
import { AssessmentGlanceChart } from './AssessmentGlanceChart'
import { ASSESSMENT_BUFFER_DAYS, isAssessmentRecent } from './utils'

interface AssessmentsOverviewProps extends React.HTMLAttributes<HTMLDivElement> {
  patientId: string
}

export function AssessmentsOverview({ patientId, ...props }: AssessmentsOverviewProps) {
  const { data, loading, error } = usePatientAssessmentsForLiveSessionQuery({
    variables: { id: patientId },
  })

  const assessments = data?.patient?.assessments

  if (error) {
    console.error('AssessmentGlanceChart.tsx: There was a problem loading the assessments')
    toast.urgent('There was a problem loading the assessments')
    return (
      <SidebarCard padded={false} {...props}>
        <NoResultsWrapper>
          <h3 className="body">There was a problem loading the chart</h3>
        </NoResultsWrapper>
      </SidebarCard>
    )
  }

  if (loading || !assessments) {
    return <div className="loading" style={{ height: 164, borderRadius: 6 }}></div>
  }

  const sortedAssessments = [...(assessments || [])].sort((a, b) => b.updatedAt.localeCompare(a.updatedAt))

  const mostRecentAssessment = sortedAssessments[0]
  const isMostRecentAssessmentWithinBuffer = mostRecentAssessment
    ? isAssessmentRecent(mostRecentAssessment.updatedAt, ASSESSMENT_BUFFER_DAYS)
    : false

  const groupedAssessments = groupAssessmentsByType(sortedAssessments)

  return (
    <SidebarCard padded={false} {...props}>
      {sortedAssessments.length === 0 || !isMostRecentAssessmentWithinBuffer ? (
        <NoResultsWrapper>
          <h3 className="body mb-0">No assessments in the last {ASSESSMENT_BUFFER_DAYS} days</h3>
        </NoResultsWrapper>
      ) : (
        Object.entries(groupedAssessments).map(([type, assessments]) => (
          <AssessmentGlanceChart key={type} assessments={assessments} />
        ))
      )}
    </SidebarCard>
  )
}

const NoResultsWrapper = styled('section')`
  align-items: center;
  background-color: ${greySet[15].hex};
  display: flex;
  flex-direction: column;
  justify-content: center;
  h3 {
    font-size: 1rem;
    font-style: normal;
  }
`

const groupAssessmentsByType = (assessments: AssessmentFromAssessmentsQuery[]) => {
  return assessments.reduce((acc, assessment) => {
    const type = assessment.type
    if (!acc[type]) {
      acc[type] = []
    }
    acc[type].push(assessment)
    return acc
  }, {} as Record<AssessmentType, AssessmentFromAssessmentsQuery[]>)
}
