import daily from '@daily-co/daily-js'
import { useDevices } from '@daily-co/daily-react'
import { styled } from '@mui/material'
import { ChangeEvent } from 'react'

import { Select } from '@nuna/tunic'

import { saveDevicePreferences } from '../util/devicePreferences'
import { AudioOutputSelect } from './AudioOutputSelect'
import { TestSound } from './TestSound'

const { mobile } = daily.supportedBrowser()

export function DeviceSettings() {
  const { cameras, currentCam, currentMic, microphones, setMicrophone, setCamera } = useDevices()

  const handleChangeCamera = (e: ChangeEvent<HTMLSelectElement>) => {
    setCamera(e.currentTarget.value)
    saveDevicePreferences({ videoDeviceId: e.currentTarget.value })
  }

  const handleChangeMicrophone = (e: ChangeEvent<HTMLSelectElement>) => {
    setMicrophone(e.currentTarget.value)
    saveDevicePreferences({ audioDeviceId: e.currentTarget.value })
  }

  return (
    <Container className="full-width">
      <Select
        disabled={cameras.length === 0}
        scheme="dark"
        label={cameras.length > 0 ? 'Camera' : 'No camera available'}
        value={currentCam?.device.deviceId ?? ''}
        onChange={handleChangeCamera}
        className="full-width mr-1"
        inputProps={{ className: 'fs-exception' }}
        data-component="video-settings-camera-select"
        shrink
      >
        {cameras.map(camera => (
          <option key={camera.device.deviceId} value={camera.device.deviceId}>
            {camera.device.label}
          </option>
        ))}
      </Select>

      <Select
        disabled={microphones.length === 0}
        scheme="dark"
        label={microphones.length > 0 ? 'Microphone' : 'No microphone available'}
        value={currentMic?.device.deviceId ?? ''}
        onChange={handleChangeMicrophone}
        className="full-width mr-1 mt-2"
        inputProps={{ className: 'fs-exception' }}
        data-component="video-settings-microphone-select"
        shrink
      >
        {microphones.map(microphone => (
          <option key={microphone.device.deviceId} value={microphone.device.deviceId}>
            {microphone.device.deviceId === 'default' ? 'Same as System' : microphone.device.label}
          </option>
        ))}
      </Select>

      {!mobile && <AudioOutputSelect />}

      <TestSound />
    </Container>
  )
}

const Container = styled('div')`
  label {
    font-weight: 600 !important;
  }
`
