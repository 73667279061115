import { styled } from '@mui/material'
import { NavLink } from 'react-router-dom'

import { Logo, greySet } from '@nuna/tunic'

import { SidebarNavigationItems } from './SidebarNavigationItems'

const Container = styled('div')`
  height: 100%;
  background-color: ${greySet[0].hex};
  border-right: 1px solid ${greySet[15].hex};
  padding: 1.75rem 0.75rem 0.75rem;
`

export function Sidebar() {
  return (
    <Container className="flex-column">
      <LogoLink className="mb-3" to="/">
        <Logo height={34} color="plum" />
      </LogoLink>
      <SidebarNavigationItems className="flex-remaining-space flex-column" style={{ overflow: 'auto' }} />
    </Container>
  )
}

const LogoLink = styled(NavLink)`
  display: block;
  padding-left: 0.5rem;
`
