import { styled } from '@mui/material'
import { HTMLAttributes } from 'react'

import { Tooltip } from '../..'
import { IconCaution, IconDownload } from '../../icons'
import progressIndicator from '../../img/progress-indicator.svg'
import { tealSet } from '../../styles/colorSets'
import { body2 } from '../../styles/colors'
import { Chip, ChipProps } from '../Chip'
import { FileTypeIcon } from '../FileTypeIcon/FileTypeIcon'

export type AttachmentStatus = 'loading' | 'success' | 'error'

export interface AttachmentChipProps extends Omit<ChipProps, 'noInput' | 'children' | 'ref' | 'as'> {
  fileName: string
  status?: AttachmentStatus
  downloadUrl?: string
  mimeType?: string
}

export function AttachmentChip({
  fileName,
  status = 'success',
  downloadUrl,
  mimeType = '',
  ...props
}: AttachmentChipProps) {
  return (
    <StyledChip
      type={downloadUrl ? 'link' : 'display'}
      anchorProps={{ href: downloadUrl }}
      href={downloadUrl}
      {...props}
    >
      <span className="status-indicator v-align">
        <StatusIndicator status={status} className="status-icon v-align" mimeType={mimeType} />
        <IconDownload size={20} className="download-icon v-align" />
      </span>
      <Tooltip content={fileName}>
        <span className="file-name">{fileName}</span>
      </Tooltip>
    </StyledChip>
  )
}

const StyledChip = styled(Chip)`
  color: ${body2};
  position: relative;

  max-width: 100%;

  .chip-container {
    max-width: 100%;
  }

  &:focus .is-link,
  &:focus-visible .is-link {
    color: ${tealSet[80].hex};
  }
  .download-icon {
    display: none;
  }

  .status-indicator {
    width: 30px;
    position: absolute;
  }

  .file-name {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    flex: 0 1 auto;
    margin-left: 30px;
  }

  .remove-button {
    // not sure why the remove button has a top margin by default. But it's not needed for this
    // use case. Perhaps it can be solved in the Chip component for type="link"
    margin-top: 0;
  }

  :hover .is-link {
    .status-indicator {
      .download-icon {
        display: flex;
      }
    }

    .status-icon {
      display: none;
    }
  }
`

const Spinner = styled('img')`
  width: 24px;
  height: 24px;
`
Spinner.defaultProps = { src: progressIndicator }

interface StatusIndicatorProps extends HTMLAttributes<HTMLOrSVGElement> {
  status: AttachmentStatus
  mimeType?: string
}
function StatusIndicator({ status, mimeType = '', ...props }: StatusIndicatorProps) {
  if (status === 'error') {
    return <IconCaution {...props} style={{ marginTop: 4 }} />
  }

  if (status === 'success') {
    return <FileTypeIcon mimeType={mimeType} {...props} />
  }

  return <Spinner {...props} style={{ marginTop: 3 }} />
}
