import { MenuItem, keyframes, styled } from '@mui/material'
import moment from 'moment'
import { Moment } from 'moment-timezone'
import React, { useEffect, useState } from 'react'

import { BasicPatientFragment, TimeSlotType, useAppointmentInfoForDateLazyQuery } from '@nuna/api'
import { AppointmentLocationSelect, AppointmentLocationSelectValue, AppointmentTimeSelect } from '@nuna/appointment'
import {
  Box,
  DatePicker,
  IconLoading,
  IconReply,
  Stack,
  body1,
  greySet,
  interactiveFill,
  interactiveText,
  tealSet,
} from '@nuna/tunic'

import { DrawerCaption } from '../../DrawerCaption'

interface AppointmentTimeFormProps {
  containerProps?: React.HTMLAttributes<HTMLDivElement>
  oldStartTime?: string
  onChange: (startTime: string) => void
  locationOnChange: (addressId: string) => void
  locationValue: string
  value: string
  patient: BasicPatientFragment
  providerId: string
}

export function NewAppointmentTimeForm({
  oldStartTime,
  onChange,
  locationOnChange,
  value,
  containerProps,
  patient,
  providerId,
  locationValue,
}: AppointmentTimeFormProps) {
  const [oldStart] = useState(oldStartTime ? moment(oldStartTime) : null)
  const [date, setDate] = useState<Moment | null>(() => {
    if (value) {
      return moment(value)
    }

    if (oldStartTime) {
      return moment(oldStartTime)
    }

    return null
  })

  const [queryAvailability, { data: availabilityData, loading: isLoadingTimes }] = useAppointmentInfoForDateLazyQuery({
    fetchPolicy: 'network-only',
  })
  const availableTimes = availabilityData?.appointmentInfoForDate.times ?? []

  useEffect(() => {
    if (date) {
      queryAvailability({
        variables: {
          dateTime: date.isSame(moment(), 'd') ? moment().add(1, 'm') : date,
          providerId,
        },
      })
    }
  }, [date, queryAvailability, providerId])

  useEffect(() => {
    const previousTime = value ? moment(value) : null
    if (date?.isSame(previousTime, 'd')) {
      return
    }

    const updatedTimes = availabilityData?.appointmentInfoForDate.times ?? []

    if (updatedTimes.length > 0) {
      const newInternalTime = updatedTimes.find(slot => {
        const slotStart = moment(slot.start)

        if (slot.isTaken && slotStart.isSame(oldStart) && previousTime && previousTime.isSame(oldStart)) {
          return true
        }

        return !slot.isTaken && slotStart.isSameOrAfter(slotStart.clone().hour(previousTime ? previousTime.hour() : 9))
      })

      if (newInternalTime && newInternalTime.start !== value) {
        onChange(newInternalTime.start)
      }
    }
  }, [availabilityData?.appointmentInfoForDate.times, oldStart, value, onChange, date])

  return (
    <div {...containerProps}>
      <DatePicker
        containerProps={{ className: 'v-align mb-3' }}
        disablePast
        format="dddd, MMM Do"
        label="Day"
        onChange={setDate}
        value={date}
        data-testid="appointment-date-picker"
      />
      <AppointmentTimeSelect
        containerProps={{ className: 'v-align mb-2' }}
        value={isLoadingTimes ? 'loading' : value}
        onChange={e => onChange(`${e.target.value}`)}
        name="newStart"
        label={`Start time in ${moment.tz(moment.tz.guess()).format('z')} (50 min session)`}
        fullWidth
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        renderValue={(value: any) => {
          if (isLoadingTimes) {
            return <div>Finding times...</div>
          }

          const selectedSlot = availableTimes.find(slot => slot.start === value)

          if (selectedSlot && selectedSlot.type === TimeSlotType.OutsideOfAvailability) {
            return (
              <Stack direction="row" justifyContent="space-between" alignItems="center">
                <span>{moment(value).format('LT')}</span>
                <Box component="span" sx={{ color: greySet[50].hex }}>
                  Unavailable
                </Box>
              </Stack>
            )
          }

          return <div>{moment(value).format('LT')}</div>
        }}
        disabled={!date}
        helperText={!date ? 'Please select a date first' : ''}
        MenuProps={{
          style: {
            maxHeight: 350,
          },
        }}
      >
        {(() => {
          if (isLoadingTimes) {
            return (
              <MenuItem>
                <SpinningLoader size={16} className="mr-1" color={interactiveFill} />{' '}
                <span className="text-bold">Finding times...</span>
              </MenuItem>
            )
          }

          return availableTimes.map(({ start, type }) => {
            const isTaken = type === TimeSlotType.Booked
            const isWithinAvailability = type === TimeSlotType.Open
            const slotStart = moment(start)

            if (slotStart.isBefore(moment())) {
              return null
            }

            if (oldStart && slotStart.isBetween(oldStart.clone().subtract('1', 'm'), oldStart.clone().add('50', 'm'))) {
              return (
                <MenuItemStyled key={start} value={start} sameAsAppointment>
                  <CurrentTimeSlot>
                    {slotStart.format('LT')} <span className="ml-auto">Current</span>
                  </CurrentTimeSlot>
                </MenuItemStyled>
              )
            }

            if (
              (isTaken && !oldStart) ||
              (isTaken && oldStart && !slotStart.isBetween(oldStart.clone().subtract('31', 'm'), oldStart))
            ) {
              return (
                <MenuItemStyled key={start} disabled value={start}>
                  {slotStart.format('LT')} <span className="ml-auto">Booked</span>
                </MenuItemStyled>
              )
            }

            if (isWithinAvailability) {
              return (
                <MenuItemStyled key={start} value={start}>
                  {slotStart.format('LT')} <span className="ml-auto">Open</span>
                </MenuItemStyled>
              )
            }

            return (
              <MenuItemStyled key={start} value={start} outsideAvailability>
                {slotStart.format('LT')}{' '}
                <Box component="span" sx={{ ml: 'auto' }}>
                  Unavailable
                </Box>
              </MenuItemStyled>
            )
          })
        })()}
      </AppointmentTimeSelect>
      {patient && patient.timezone && value && (
        <DrawerCaption element="div" className="v-align mt-1">
          <IconReply className="mr-1" />
          {moment(value).tz(patient.timezone).format('LT z')} for {patient.firstName}
        </DrawerCaption>
      )}
      <AppointmentLocationSelect
        providerId={providerId}
        onAddressChange={(selectedAddress: AppointmentLocationSelectValue) => locationOnChange(selectedAddress.id)}
        name="location"
        label="Location"
        value={locationValue}
        patient={patient}
        containerProps={{ className: 'mt-3 mb-2' }}
        disabled={!value}
        helperText={!value ? 'Please select a date and time first' : ''}
      />
    </div>
  )
}

const MenuItemStyled = styled(MenuItem, {
  shouldForwardProp: (prop: string) => !['sameAsAppointment', 'disabled', 'outsideAvailability'].includes(prop),
})<{
  sameAsAppointment?: boolean
  disabled?: boolean
  outsideAvailability?: boolean
}>`
  && {
    color: ${body1};
    font-weight: 500;

    ${props =>
      (props.sameAsAppointment || props.disabled || props.outsideAvailability) &&
      `
        font-weight: 400;
        color: ${greySet[50].hex};
      `}

    ${props =>
      props.sameAsAppointment &&
      `
        background: none !important;
        padding: 1px 8px;
      `}
  }
`

const CurrentTimeSlot = styled('span')`
  background-color: ${tealSet.tint[20]};
  border-radius: 4px;
  color: ${interactiveText};
  padding: 5px 8px;
  width: 100%;
  display: inline-flex;
`
const spin = keyframes`
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
`

const SpinningLoader = styled(IconLoading)`
  animation: ${spin} 2s linear infinite;
`
