import { HTMLAttributes } from 'react'

import { useAuthDataContext, useIsAdmin } from '@nuna/auth'
import { ProfileUser, routeService } from '@nuna/core'
import { userService } from '@nuna/core'
import { Skeleton, csx } from '@nuna/tunic'

import { AuthorizedAvatar } from './AuthorizedAvatar'
import { Link } from './Link'

const { userFullName } = userService

interface Props {
  user?: ProfileUser | null
  loading?: boolean
  variant?: 'page-header' | 'drawer-header' | 'inline'
  openInNewTab?: boolean
  showAvatar?: boolean
  showTitle?: boolean
  customLink?: string
}

export function UserLink({
  user,
  loading = false,
  variant = 'inline',
  openInNewTab = false,
  showAvatar = true,
  showTitle,
  className,
  customLink,
}: Props & HTMLAttributes<HTMLSpanElement>) {
  const { login } = useAuthDataContext()
  const isAdmin = useIsAdmin()
  const isMe = login?.id === user?.loginId
  // TODO - link to other provider profiles once we've enabled that ability
  const noLink = user?.__typename === 'Provider' && !isAdmin && !isMe
  let path = customLink
  if (!path) {
    path = isMe ? '/profile' : routeService.userProfile(user)
  }
  const classes = csx([
    {
      'text-default h5': variant === 'page-header',
      'text-medium text-secondary': variant === 'inline',
      'text-medium text-dark large': variant === 'drawer-header',
      'ml-1': showAvatar,
    },
    'mb-0',
    className,
  ])

  const Content = () => (
    <>
      {loading && <Skeleton className="ml-1" width={10} height={variant === 'inline' ? 1.5 : 2} />}
      {noLink && !loading && <span className={classes}>{userFullName(user, showTitle)}</span>}
      {!noLink && !loading && (
        <Link to={path} className={classes} openInNewTab={openInNewTab}>
          {userFullName(user, showTitle)}
        </Link>
      )}
    </>
  )

  return showAvatar ? (
    <span className="v-align">
      <AuthorizedAvatar url={user?.avatarUrl} size="xs" />

      <Content />
    </span>
  ) : (
    <Content />
  )
}
