import { styled } from '@mui/material'
import { DateCalendar, PickersDay, PickersDayProps } from '@mui/x-date-pickers'
import moment, { type Moment } from 'moment'
import { useState } from 'react'

import { type DrawerAppointmentFragment, type ProviderDetailsQuery, type PublicProviderQuery } from '@nuna/api'
import { AppointmentLocationSelect, useProviderAvailableTimeSlots } from '@nuna/appointment'
import { useEffectOnce } from '@nuna/common'
import { useFeatureFlags } from '@nuna/feature-flag'
import { Radio, RadioGroup } from '@nuna/tunic'

import { CalendarTimeSlotSelect } from './TimeSlotSelect/Calendar/CalendarTimeSlotSelect'

interface LocationDateSchedulingProps {
  therapistHasInPerson: boolean
  patient: DrawerAppointmentFragment['patient'] | undefined
  provider:
    | ProviderDetailsQuery['provider']
    | PublicProviderQuery['publicProvider']
    | DrawerAppointmentFragment['provider']
  firstLocationId: string | null | undefined
  initialAddressId: string | null | undefined
  newAddressId: string | null | undefined
  onAddressChange: (addressId: string | null) => void
  initialDate?: Moment
  rescheduleAppointmentId?: string
}

const DATE_FORMAT = 'MM/DD/YYYY'

export function LocationDateScheduling({
  therapistHasInPerson,
  patient,
  provider,
  firstLocationId,
  initialAddressId,
  newAddressId,
  onAddressChange,
  initialDate,
  rescheduleAppointmentId,
}: LocationDateSchedulingProps) {
  const { cbip } = useFeatureFlags()
  const [isVirtual, setIsVirtual] = useState(!initialAddressId && !newAddressId)
  const notVirtualNewAddressId = isVirtual ? null : newAddressId ?? initialAddressId ?? firstLocationId
  const { lastAvailableDate, slots } = useProviderAvailableTimeSlots({
    providerId: provider.id,
    patientId: patient?.id,
    reschedulingAppointmentId: rescheduleAppointmentId,
    addressId: notVirtualNewAddressId ?? undefined,
  })

  const [selectedDate, setSelectedDate] = useState<Moment>(moment(slots?.[0]?.start ?? initialDate))
  const allowLocationScheduling = therapistHasInPerson && (initialAddressId || (!initialAddressId && cbip)) // after feature flag, we just need to verify if the therapist has in person

  useEffectOnce(() => {
    setSelectedDate(moment(slots[0].start)) // once slots are loaded, set the selected date to the first available slot
  }, slots.length > 0)

  const handleDatePickerChange = (date: Moment) => {
    setSelectedDate(date)
  }

  const handleLocationChange = (updatedIsVirtual: boolean) => {
    onAddressChange(updatedIsVirtual ? null : firstLocationId ?? null)
    setIsVirtual(updatedIsVirtual)
  }

  return (
    <>
      {allowLocationScheduling && (
        <>
          <h4 className="h6 mb-3">Select the type of appointment</h4>
          <StyledRadioGroup className="v-align mt-4 mb-2">
            <Radio value="virtual" checked={isVirtual} onChange={() => handleLocationChange(true)}>
              Virtual
            </Radio>
            <Radio value="in-person" checked={!isVirtual} onChange={() => handleLocationChange(false)}>
              In-Person
            </Radio>
          </StyledRadioGroup>
        </>
      )}
      {!isVirtual && (
        <AppointmentLocationSelect
          providerId={provider.id}
          value={notVirtualNewAddressId}
          onAddressChange={address => onAddressChange(address.id)}
          patient={patient}
          removeVirtualOption
        />
      )}
      <DateCalendar
        value={selectedDate}
        onChange={handleDatePickerChange}
        disablePast
        maxDate={moment(lastAvailableDate ?? selectedDate)}
        slots={{ day: props => <CustomDay {...props} timeSlots={slots} /> }}
      />

      <CalendarTimeSlotSelect
        provider={provider}
        patient={patient}
        date={selectedDate}
        addressId={isVirtual ? 'VIRTUAL' : notVirtualNewAddressId}
        className="pb-1"
        reschedulingAppointmentId={rescheduleAppointmentId}
      />
    </>
  )
}

function CustomDay({
  timeSlots,
  day,
  ...props
}: PickersDayProps<Moment> & { timeSlots: ReturnType<typeof useProviderAvailableTimeSlots>['slots'] }) {
  const isUnavailable = (() => {
    if (!day) return props.disabled

    const dayFilter = day.format(DATE_FORMAT)
    return timeSlots?.filter(slot => moment(slot.start).format(DATE_FORMAT) === dayFilter)?.length === 0
  })()

  return (
    <PickersDay
      day={day}
      {...props}
      disabled={isUnavailable}
      data-testid={isUnavailable ? undefined : 'available-day'}
      sx={{
        ...(!isUnavailable && {
          fontWeight: 'medium',
        }),
      }}
    />
  )
}

const StyledRadioGroup = styled(RadioGroup)`
  display: flex;
  align-items: center;
  justify-content: space-evenly;
`
