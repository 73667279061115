import * as Yup from 'yup'
import { styled } from '@mui/material'

import { FullProviderFragment, Gender, useUpdateProviderMutation } from '@nuna/api'
import { ProfileDisplayValue, ProfileSection } from '@nuna/common'
import { userService } from '@nuna/core'
import { Radio, greySet } from '@nuna/tunic'

import { incompleteSectionNotification } from '../../../utils/util'

const { humanReadableGenders } = userService

export type GenderSectionProps = Pick<FullProviderFragment, 'id' | 'gender'>

interface GenderValues {
  gender: Gender
}

export function GenderSection({ gender, id }: GenderSectionProps) {
  const [updateProvider, { loading: updateLoading }] = useUpdateProviderMutation()

  const initialValues: GenderValues = {
    gender: gender as Gender,
  }

  const [isComplete, valueDisplay] = incompleteSectionNotification<GenderValues>(
    initialValues,
    ['gender'],
    <ProfileDisplayValue>{gender && humanReadableGenders[gender as Gender]}</ProfileDisplayValue>,
  )

  return (
    <ProfileSection
      heading="Gender"
      useOutlineSaveButton
      description="Your gender helps us to match you with clients based on the preferences they set."
      displayWidth="full"
      updateLoading={updateLoading}
      initialValues={initialValues}
      handleSubmit={values => updateProvider({ variables: Object.assign({ id }, { gender: values.gender as Gender }) })}
      disabled={({ isValid }) => !isValid}
      sectionComplete={isComplete}
      renderDisplayValue={valueDisplay}
      renderForm={({ values, handleChange }) => (
        <>
          <RadioLabel>Please select one</RadioLabel>

          <div role="group" style={{ position: 'relative' }}>
            {[
              Gender.Female,
              Gender.Male,
              Gender.TransFemale,
              Gender.TransMale,
              Gender.GenderNonConforming,
              Gender.QuestioningGender,
              Gender.PreferNotToAnswer,
            ].map(value => (
              <Radio key={value} name="gender" onChange={handleChange} value={value} checked={values.gender === value}>
                {humanReadableGenders[value]}
              </Radio>
            ))}
          </div>
        </>
      )}
      validationSchema={Yup.object().shape({
        gender: Yup.string().required('Gender is required'),
      })}
    ></ProfileSection>
  )
}

const RadioLabel = styled('label')`
  display: block;
  padding-bottom: var(--margin-3);
  color: ${greySet[90].hex};
`
