import { styled } from '@mui/material'
import { HTMLAttributes } from 'react'

import { ProviderType, usePatientContextQuery } from '@nuna/api'
import { BelowTablet, csx, interactiveFill } from '@nuna/tunic'
import { FillButton, TextButtonLink } from '@nuna/tunic'

interface PrescriberPromptProps extends HTMLAttributes<HTMLDivElement> {
  provider: { firstName: string }
  onDismiss(): void
  compact?: boolean
}

export function PrescriberPrompt({ onDismiss, provider, compact = false, ...props }: PrescriberPromptProps) {
  const { data } = usePatientContextQuery()

  const providers = data?.patientContext.patient.providers ?? []
  const sessionNumberLimit = data?.patientContext.capInfo.sessionNumberLimit

  const hasTherapist = providers.filter(provider => provider.type === ProviderType.Therapist).length > 0

  return (
    <Container {...props}>
      <Heading compact={compact} className="mt-2">
        {provider.firstName} is your medication prescriber.
        {!compact && <br />} Here's what you'll cover in your session:
      </Heading>

      <ListStyled compact={compact} className="mt-3 mb-3">
        <li>Discuss your symptoms and medication that can support you</li>
        <li>Receive a prescription (if deemed helpful) that you can pick up at your pharmacy</li>
        <li>
          Refer you to a therapist if you don’t have one yet.{' '}
          {!hasTherapist && (
            <>
              You can also <TextButtonLink to="/select-provider">Browse Therapists</TextButtonLink> right now.
            </>
          )}
        </li>
      </ListStyled>

      <FillButton onClick={onDismiss}>Schedule a Medication Consultation</FillButton>

      {sessionNumberLimit && (
        <p
          style={
            compact
              ? { position: 'absolute', bottom: 0, backgroundColor: '#fff', zIndex: 2, paddingBottom: '1rem' }
              : {}
          }
          className={csx([{ 'text-medium caption': compact }, { 'mt-3 pb-3 text-secondary': !compact }])}
        >
          <strong>FYI:</strong>{' '}
          <span>Medical consultations are covered under your plan and count towards your total allotted sessions</span>
        </p>
      )}
    </Container>
  )
}

const Heading = styled('h2')<{ compact: boolean }>`
  font-size: 2.25rem;
  line-height: 1.2;

  ${({ compact }) => compact && `font-size: 1.5rem;`}

  @media (${BelowTablet}) {
    font-size: 1.5rem;
  }
`

const Container = styled('div')`
  margin-bottom: 40px;

  @media (${BelowTablet}) {
    margin: 0;
  }
`
const List = styled('ul')`
  list-style: none;
  font-size: 18px;
  padding: 0;

  li {
    margin-bottom: 0.75rem;
    padding-left: 1rem;
    position: relative;

    &::before {
      background-color: ${interactiveFill};
      border-radius: 50%;
      content: '';
      display: inline-block;
      height: 6px;
      width: 6px;
      position: absolute;
      top: 0.5em;
      left: 0;
    }
  }
`

const ListStyled = styled(List)<{ compact: boolean }>`
  ${({ compact }) => compact && `font-size: 1rem;`}
`
