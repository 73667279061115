import { CashCoverageDetailsFragment } from '@nuna/api'
import { numberService } from '@nuna/core'
import { CashCoverageCard, CoverageList, CoverageListItem } from '@nuna/coverage'
import { IconDollarSymbol } from '@nuna/tunic'

interface Props {
  coverage: CashCoverageDetailsFragment
}

export function CashCoverageGlance({ coverage }: Props) {
  const { cost } = coverage
  return (
    <>
      <CashCoverageCard coverage={coverage} />
      <CoverageList className="ml-1 mt-2">
        <CoverageListItem adornment={<IconDollarSymbol size={16} />}>
          {numberService.centsToFormattedDollars(cost)} per session
        </CoverageListItem>
      </CoverageList>
    </>
  )
}
