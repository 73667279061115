import { isNil } from 'lodash'
import { ChangeEvent, HTMLAttributes } from 'react'

import { useProviderPreferencesQuery, useSaveProviderPreferencesMutation } from '@nuna/api'
import { supportService } from '@nuna/telemetry'
import { Switch, TextButtonExternalLink, toast } from '@nuna/tunic'

interface Props extends HTMLAttributes<HTMLDivElement> {
  providerId: string | undefined | null
}

export function Autonotes({ providerId }: Props) {
  const [saveProviderPreferences] = useSaveProviderPreferencesMutation()
  const { data } = useProviderPreferencesQuery({ variables: { providerId: providerId ?? '' } })

  const isAutonotesEnabled = data?.provider?.preferences?.autonotesEnabled ?? false

  const handleChange = async (e: ChangeEvent<HTMLInputElement>) => {
    if (!providerId) {
      console.error('Provider ID was missing when trying to update preferences')
      toast.urgent(
        `We encountered an unexpected error while updating your preferences. Please contact ${supportService.supportEmails.providerSupport}.`,
      )
      return
    }

    try {
      await saveProviderPreferences({
        variables: {
          providerId,
          preferences: {
            autonotesEnabled: e.target.checked,
          },
        },
        optimisticResponse: {
          updateProvider: {
            __typename: 'Provider',
            id: providerId,
            preferences: {
              ...data?.provider.preferences,
              autonotesEnabled: e.target.checked,
            },
          },
        },
      })

      toast.success(`Tava Scribe has been turned ${e.target.checked ? 'on' : 'off'}`)
    } catch (error) {
      console.error(error)
      toast.urgent('There was an error updating your preferences. Please try again.')
    }
  }

  return (
    <div>
      <h2 className="h4 mb-3">Tava Scribe</h2>

      <p className="large paragraphs text-secondary mb-3">
        Tava Scribe automatically captures your session transcript and combines it with your written notes to produce a
        detailed, SOAP-formatted summary.
      </p>

      <p className="paragraphs">
        <strong>Privacy</strong>: Tava Scribe is HIPAA compliant, encrypted, and source transcripts are not stored.
        Clients are given notice on their first session and allowed to opt-out at any time.{' '}
      </p>

      <Switch
        className="mt-4 mb-4"
        loading={isNil(isAutonotesEnabled)}
        checked={isAutonotesEnabled}
        onChange={handleChange}
      >
        Tava Scribe
      </Switch>

      <TextButtonExternalLink
        variant="secondary"
        href="https://therapist-support.tavahealth.com/hc/en-us/articles/27211161500820-Automatic-session-notes"
      >
        Learn more
      </TextButtonExternalLink>
    </div>
  )
}
