import { isNil } from 'lodash'
import moment, { Moment } from 'moment-timezone'

import { TimezoneOption } from '../types/exported/time'

interface HourOfDay {
  display: string
  value: string
}

interface Day {
  display: string
  value: string
  numDayofWeek: number
}

const hoursOfDay: HourOfDay[] = [
  {
    display: '12:30 AM',
    value: '00:30',
  },
  {
    display: '1:00 AM',
    value: '1:00',
  },
  {
    display: '1:30 AM',
    value: '1:30',
  },
  {
    display: '2:00 AM',
    value: '2:00',
  },
  {
    display: '2:30 AM',
    value: '2:30',
  },
  {
    display: '3:00 AM',
    value: '3:00',
  },
  {
    display: '3:30 AM',
    value: '3:30',
  },
  {
    display: '4:00 AM',
    value: '4:00',
  },
  {
    display: '4:30 AM',
    value: '4:30',
  },
  {
    display: '5:00 AM',
    value: '5:00',
  },
  {
    display: '5:30 AM',
    value: '5:30',
  },
  {
    display: '6:00 AM',
    value: '6:00',
  },
  {
    display: '6:30 AM',
    value: '6:30',
  },
  {
    display: '7:00 AM',
    value: '7:00',
  },
  {
    display: '7:30 AM',
    value: '7:30',
  },
  {
    display: '8:00 AM',
    value: '8:00',
  },
  {
    display: '8:30 AM',
    value: '8:30',
  },
  {
    display: '9:00 AM',
    value: '9:00',
  },
  {
    display: '9:30 AM',
    value: '9:30',
  },
  {
    display: '10:00 AM',
    value: '10:00',
  },
  {
    display: '10:30 AM',
    value: '10:30',
  },
  {
    display: '11:00 AM',
    value: '11:00',
  },
  {
    display: '11:30 AM',
    value: '11:30',
  },
  {
    display: 'Noon',
    value: '12:00',
  },
  {
    display: '12:30 PM',
    value: '12:30',
  },
  {
    display: '1:00 PM',
    value: '13:00',
  },
  {
    display: '1:30 PM',
    value: '13:30',
  },
  {
    display: '2:00 PM',
    value: '14:00',
  },
  {
    display: '2:30 PM',
    value: '14:30',
  },
  {
    display: '3:00 PM',
    value: '15:00',
  },
  {
    display: '3:30 PM',
    value: '15:30',
  },
  {
    display: '4:00 PM',
    value: '16:00',
  },
  {
    display: '4:30 PM',
    value: '16:30',
  },
  {
    display: '5:00 PM',
    value: '17:00',
  },
  {
    display: '5:30 PM',
    value: '17:30',
  },
  {
    display: '6:00 PM',
    value: '18:00',
  },
  {
    display: '6:30 PM',
    value: '18:30',
  },
  {
    display: '7:00 PM',
    value: '19:00',
  },
  {
    display: '7:30 PM',
    value: '19:30',
  },
  {
    display: '8:00 PM',
    value: '20:00',
  },
  {
    display: '8:30 PM',
    value: '20:30',
  },
  {
    display: '9:00 PM',
    value: '21:00',
  },
  {
    display: '9:30 PM',
    value: '21:30',
  },
  {
    display: '10:00 PM',
    value: '22:00',
  },
  {
    display: '10:30 PM',
    value: '22:30',
  },
  {
    display: '11:00 PM',
    value: '23:00',
  },
  {
    display: '11:30 PM',
    value: '23:30',
  },
  {
    display: '12:00 AM',
    value: '00:00',
  },
]

const days: Day[] = [
  {
    display: 'Mondays',
    value: 'monday',
    numDayofWeek: 0,
  },
  {
    display: 'Tuesdays',
    value: 'tuesday',
    numDayofWeek: 1,
  },
  {
    display: 'Wednesdays',
    value: 'wednesday',
    numDayofWeek: 2,
  },
  {
    display: 'Thursdays',
    value: 'thursday',
    numDayofWeek: 3,
  },
  {
    display: 'Fridays',
    value: 'friday',
    numDayofWeek: 4,
  },
  {
    display: 'Saturdays',
    value: 'saturday',
    numDayofWeek: 5,
  },
  {
    display: 'Sundays',
    value: 'sunday',
    numDayofWeek: 6,
  },
]

const daysOfWeek = ['sunday', 'monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday']

function mergeDateAndTime(date: Moment, time: string) {
  const dateTimeString = `${date.format('YYYY-MM-DD')} ${time}`

  return moment(dateTimeString, 'YYYY-MM-DD H:mm:ss')
}

// Time should be a string formatted like 01:00
function addHoursToTime(timeString: string, hours: number) {
  const [startHoursString, startMinutesString] = timeString.split(':')
  const startHours = parseInt(startHoursString)

  if (!startHoursString || !startMinutesString || isNaN(startHours))
    throw new Error(`Invalid time string: ${timeString}`)

  const endHours = startHours + hours

  return `${endHours}:${startMinutesString}`
}

function toAvailabilityFormat(start: Date | null, end: Date | null, timeZone: string) {
  const endHours = moment.tz(end, timeZone).format('HH:mm')
  return {
    day: daysOfWeek[start?.getDay() ?? 0],
    start: moment.tz(start, timeZone).format('HH:mm'),
    end: endHours === '00:00' ? '24:00' : endHours,
  }
}

interface Range {
  start: Moment | string
  end: Moment | string
}
function rangesOverlap(range1: Range, range2: Range) {
  if (moment(range1.start).isBetween(range2.start, range2.end)) {
    return true
  }
  if (moment(range1.end).isBetween(range2.start, range2.end)) {
    return true
  }
  if (moment(range2.start).isBetween(range1.start, range1.end)) {
    return true
  }
  if (moment(range2.end).isBetween(range1.start, range1.end)) {
    return true
  }

  return false
}

const timezones = [
  '',
  'America/Adak',
  'America/Anchorage',
  'America/Anguilla',
  'America/Antigua',
  'America/Araguaina',
  'America/Argentina/Buenos_Aires',
  'America/Argentina/Catamarca',
  'America/Argentina/ComodRivadavi',
  'America/Argentina/Cordoba',
  'America/Argentina/Jujuy',
  'America/Argentina/La_Rioja',
  'America/Argentina/Mendoza',
  'America/Argentina/Rio_Gallegos',
  'America/Argentina/Salta',
  'America/Argentina/San_Juan',
  'America/Argentina/San_Luis',
  'America/Argentina/Tucuman',
  'America/Argentina/Ushuaia',
  'America/Aruba',
  'America/Asuncion',
  'America/Atikokan',
  'America/Atka',
  'America/Bahia',
  'America/Bahia_Banderas',
  'America/Barbados',
  'America/Belem',
  'America/Belize',
  'America/Blanc-Sablon',
  'America/Boa_Vista',
  'America/Bogota',
  'America/Boise',
  'America/Buenos_Aires',
  'America/Cambridge_Bay',
  'America/Campo_Grande',
  'America/Cancun',
  'America/Caracas',
  'America/Catamarca',
  'America/Cayenne',
  'America/Cayman',
  'America/Chicago',
  'America/Chihuahua',
  'America/Coral_Harbour',
  'America/Cordoba',
  'America/Costa_Rica',
  'America/Creston',
  'America/Cuiaba',
  'America/Curacao',
  'America/Danmarkshavn',
  'America/Dawson',
  'America/Dawson_Creek',
  'America/Denver',
  'America/Detroit',
  'America/Dominica',
  'America/Edmonton',
  'America/Eirunepe',
  'America/El_Salvador',
  'America/Ensenada',
  'America/Fort_Wayne',
  'America/Fortaleza',
  'America/Glace_Bay',
  'America/Godthab',
  'America/Goose_Bay',
  'America/Grand_Turk',
  'America/Grenada',
  'America/Guadeloupe',
  'America/Guatemala',
  'America/Guayaquil',
  'America/Guyana',
  'America/Halifax',
  'America/Havana',
  'America/Hermosillo',
  'America/Indiana/Indianapolis',
  'America/Indiana/Knox',
  'America/Indiana/Marengo',
  'America/Indiana/Petersburg',
  'America/Indiana/Tell_City',
  'America/Indiana/Valparaiso',
  'America/Indiana/Vevay',
  'America/Indiana/Vincennes',
  'America/Indiana/Winamac',
  'America/Indianapolis',
  'America/Inuvik',
  'America/Iqaluit',
  'America/Jamaica',
  'America/Jujuy',
  'America/Juneau',
  'America/Kentucky/Louisville',
  'America/Kentucky/Monticello',
  'America/Knox_IN',
  'America/Kralendijk',
  'America/La_Paz',
  'America/Lima',
  'America/Los_Angeles',
  'America/Louisville',
  'America/Lower_Princes',
  'America/Maceio',
  'America/Managua',
  'America/Manaus',
  'America/Marigot',
  'America/Martinique',
  'America/Matamoros',
  'America/Mazatlan',
  'America/Mendoza',
  'America/Menominee',
  'America/Merida',
  'America/Metlakatla',
  'America/Mexico_City',
  'America/Miquelon',
  'America/Moncton',
  'America/Monterrey',
  'America/Montevideo',
  'America/Montreal',
  'America/Montserrat',
  'America/Nassau',
  'America/New_York',
  'America/Nipigon',
  'America/Nome',
  'America/Noronha',
  'America/North_Dakota/Beulah',
  'America/North_Dakota/Center',
  'America/North_Dakota/New_Salem',
  'America/Ojinaga',
  'America/Panama',
  'America/Pangnirtung',
  'America/Paramaribo',
  'America/Phoenix',
  'America/Port_of_Spain',
  'America/Port-au-Prince',
  'America/Porto_Acre',
  'America/Porto_Velho',
  'America/Puerto_Rico',
  'America/Rainy_River',
  'America/Rankin_Inlet',
  'America/Recife',
  'America/Regina',
  'America/Resolute',
  'America/Rio_Branco',
  'America/Rosario',
  'America/Santa_Isabel',
  'America/Santarem',
  'America/Santiago',
  'America/Santo_Domingo',
  'America/Sao_Paulo',
  'America/Scoresbysund',
  'America/Shiprock',
  'America/Sitka',
  'America/St_Barthelemy',
  'America/St_Johns',
  'America/St_Kitts',
  'America/St_Lucia',
  'America/St_Thomas',
  'America/St_Vincent',
  'America/Swift_Current',
  'America/Tegucigalpa',
  'America/Thule',
  'America/Thunder_Bay',
  'America/Tijuana',
  'America/Toronto',
  'America/Tortola',
  'America/Vancouver',
  'America/Virgin',
  'America/Whitehorse',
  'America/Winnipeg',
  'America/Yakutat',
  'America/Yellowknife',
]

const timezoneDisplayMap = {
  'Pacific/Midway': 'Midway Island, Samoa',
  'Pacific/Honolulu': 'Hawaii',
  'America/Adak': 'Adak, Alaska',
  'America/Anchorage': 'Anchorage, Alaska',
  'America/Juneau': 'Juneau, Alaska',
  'America/Boise': 'Mountain Time (Boise)',
  'America/Denver': 'Mountain Time (Denver)',
  'America/Indiana/Indianapolis': 'Indianapolis, Indiana',
  'America/Indiana/Knox': 'Knox, Indiana',
  'America/Indiana/Marengo': 'Marengo, Indiana',
  'America/Indiana/Petersburg': 'Petersburg, Indiana',
  'America/Indiana/Tell_City': 'Tell City, Indiana',
  'America/Indiana/Vevay': 'Vevay, Indiana',
  'America/Indiana/Vincennes': 'Vincennes, Indiana',
  'America/Indiana/Winamac': 'Winimac, Indiana',
  'America/Kentucky/Louisville': 'Louisville, Kentucky',
  'America/Kentucky/Monticello': 'Monticello, Kentucky',
  'America/Menominee': 'Menominee',
  'America/Metlakatla': 'Metlakatla',
  'America/New_York': 'Eastern Time (New York)',
  'America/Nome': 'Nome',
  'America/North_Dakota/Beulah': 'Beulah, North Dakota',
  'America/North_Dakota/Center': 'Center, North Dakota',
  'America/North_Dakota/New_Salem': 'New Salem, North Dakota',
  'America/Sitka': 'Sitka',
  'America/Yakutat': 'Yakutat',
  'America/Dawson': 'Dawson, Yukon',
  'America/Chihuahua': 'Chihuahua, La Paz, Mazatlan',
  'America/Phoenix': 'Arizona',
  'America/Chicago': 'Central Time',
  'America/Regina': 'Saskatchewan',
  'America/Mexico_City': 'Guadalajara, Mexico City, Monterrey',
  'America/Belize': 'Central America',
  'America/Detroit': 'Eastern Time (Detroit)',
  'America/Bogota': 'Bogota, Lima, Quito',
  'America/Caracas': 'Caracas, La Paz',
  'America/Santiago': 'Santiago',
  'America/St_Johns': 'Newfoundland and Labrador',
  'America/Sao_Paulo': 'Brasilia',
  'America/Tijuana': 'Tijuana',
  'America/Montevideo': 'Montevideo',
  'America/Argentina/Buenos_Aires': 'Buenos Aires, Georgetown',
  'America/Godthab': 'Greenland',
  'America/Los_Angeles': 'Pacific Time',
  'Atlantic/Azores': 'Azores',
  'Atlantic/Cape_Verde': 'Cape Verde Islands',
  GMT: 'UTC',
  'Europe/London': 'Edinburgh, London',
  'Europe/Dublin': 'Dublin',
  'Europe/Lisbon': 'Lisbon',
  'Africa/Casablanca': 'Casablanca, Monrovia',
  'Atlantic/Canary': 'Canary Islands',
  'Europe/Belgrade': 'Belgrade, Bratislava, Budapest, Ljubljana, Prague',
  'Europe/Sarajevo': 'Sarajevo, Skopje, Warsaw, Zagreb',
  'Europe/Brussels': 'Brussels, Copenhagen, Madrid, Paris',
  'Europe/Amsterdam': 'Amsterdam, Berlin, Bern, Rome, Stockholm, Vienna',
  'Africa/Algiers': 'West Central Africa',
  'Europe/Bucharest': 'Bucharest',
  'Africa/Cairo': 'Cairo',
  'Europe/Helsinki': 'Helsinki, Kyiv, Riga, Sofia, Tallinn, Vilnius',
  'Europe/Athens': 'Athens, Minsk',
  'Asia/Jerusalem': 'Jerusalem',
  'Africa/Harare': 'Harare, Pretoria',
  'Europe/Moscow': 'Istanbul, Moscow, St. Petersburg, Volgograd',
  'Asia/Kuwait': 'Kuwait, Riyadh',
  'Africa/Nairobi': 'Nairobi',
  'Asia/Baghdad': 'Baghdad',
  'Asia/Tehran': 'Tehran',
  'Asia/Dubai': 'Abu Dhabi, Muscat',
  'Asia/Baku': 'Baku, Tbilisi, Yerevan',
  'Asia/Kabul': 'Kabul',
  'Asia/Yekaterinburg': 'Ekaterinburg',
  'Asia/Karachi': 'Islamabad, Karachi, Tashkent',
  'Asia/Kolkata': 'Chennai, Kolkata, Mumbai, New Delhi',
  'Asia/Kathmandu': 'Kathmandu',
  'Asia/Dhaka': 'Astana, Dhaka',
  'Asia/Colombo': 'Sri Jayawardenepura',
  'Asia/Almaty': 'Almaty, Novosibirsk',
  'Asia/Rangoon': 'Yangon Rangoon',
  'Asia/Bangkok': 'Bangkok, Hanoi, Jakarta',
  'Asia/Krasnoyarsk': 'Krasnoyarsk',
  'Asia/Shanghai': 'Beijing, Chongqing, Hong Kong SAR, Urumqi',
  'Asia/Kuala_Lumpur': 'Kuala Lumpur, Singapore',
  'Asia/Taipei': 'Taipei',
  'Australia/Perth': 'Perth',
  'Asia/Irkutsk': 'Irkutsk, Ulaanbaatar',
  'Asia/Seoul': 'Seoul',
  'Asia/Tokyo': 'Osaka, Sapporo, Tokyo',
  'Asia/Yakutsk': 'Yakutsk',
  'Australia/Darwin': 'Darwin',
  'Australia/Adelaide': 'Adelaide',
  'Australia/Sydney': 'Canberra, Melbourne, Sydney',
  'Australia/Brisbane': 'Brisbane',
  'Australia/Hobart': 'Hobart',
  'Asia/Vladivostok': 'Vladivostok',
  'Pacific/Guam': 'Guam, Port Moresby',
  'Asia/Magadan': 'Magadan, Solomon Islands, New Caledonia',
  'Asia/Kamchatka': 'Kamchatka, Marshall Islands',
  'Pacific/Fiji': 'Fiji Islands',
  'Pacific/Auckland': 'Auckland, Wellington',
  'Pacific/Tongatapu': "Nuku'alofa",
}

const sortedUSTimezones = [
  'America/Los_Angeles',
  'America/Boise',
  'America/Denver',
  'America/Chicago',
  'America/Detroit',
  'America/New_York',
  'Pacific/Honolulu',
  'America/Adak',
  'America/Anchorage',
  'America/Juneau',
  'America/Phoenix',
  'America/North_Dakota/Beulah',
  'America/North_Dakota/Center',
  'America/North_Dakota/New_Salem',
  'America/Indiana/Indianapolis',
  'America/Indiana/Knox',
  'America/Indiana/Marengo',
  'America/Indiana/Petersburg',
  'America/Indiana/Tell_City',
  'America/Indiana/Vevay',
  'America/Indiana/Vincennes',
  'America/Indiana/Winamac',
  'America/Kentucky/Louisville',
  'America/Kentucky/Monticello',
  'America/Menominee',
  'America/Metlakatla',
  'America/Nome',
  'America/Sitka',
  'America/Yakutat',
]

const USATimezones = [
  ...sortedUSTimezones,
  ...moment.tz.zonesForCountry('US').filter(tz => !sortedUSTimezones.includes(tz)),
]

const hiddenMomentTimezones = [
  'CET',
  'CST6CDT',
  'EET',
  'EST',
  'EST5EDT',
  'MET',
  'MST',
  'MST7MDT',
  'PST8PDT',
  'UCT',
  'UTC',
  'WET',
]

const otherTimezones = moment.tz
  .names()
  .filter(timezone => !USATimezones.includes(timezone) && !hiddenMomentTimezones.includes(timezone))

const orderedTimezones = [...USATimezones, ...otherTimezones]

const timezoneOptions: TimezoneOption[] = orderedTimezones.map(tz => ({
  value: tz,
  label: timezoneDisplayMap[tz as keyof typeof timezoneDisplayMap] ?? tz,
}))

function getReadableTimezone(tzKey?: string | null) {
  if (isNil(tzKey)) {
    return ''
  }
  return timezoneDisplayMap[tzKey as keyof typeof timezoneDisplayMap] ?? tzKey
}

export function adjustTimeForTimeZoneChange({
  newTimeZone,
  oldTimeZone,
  time,
  format = 'HH:mm',
}: {
  newTimeZone: string
  oldTimeZone: string
  time: string
  format?: string
}) {
  const anchor = moment().tz(oldTimeZone)
  const timeMoment = moment.tz(time, 'HH:mm', oldTimeZone)

  anchor.set('hours', timeMoment.get('hours'))
  anchor.set('minutes', timeMoment.get('minutes'))
  anchor.tz(newTimeZone)

  return anchor.format(format)
}

export const timeService = {
  addHoursToTime,
  days,
  daysOfWeek,
  getReadableTimezone,
  hoursOfDay,
  mergeDateAndTime,
  orderedTimezones,
  rangesOverlap,
  timezoneOptions,
  timezones,
  toAvailabilityFormat,
  adjustTimeForTimeZoneChange,
}
