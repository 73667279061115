import { FinancialAttribution, WhoIsResponsibleForPayment } from '../types/internal-only/financials.types'

export const paymentTypeMap = {
  [WhoIsResponsibleForPayment.Free]: 'No Charge',
  [WhoIsResponsibleForPayment.Company]: 'EAP',
  [WhoIsResponsibleForPayment.Insurance]: 'Insurance',
  [WhoIsResponsibleForPayment.Client]: 'Credit Card',
}

const getPaymentTypeText = (whoIsResponsibleForPayment?: WhoIsResponsibleForPayment | null) => {
  if (!whoIsResponsibleForPayment) return 'Unknown'
  return paymentTypeMap[whoIsResponsibleForPayment] ?? 'Unknown'
}

export const getFinancialAttributionText = (financialAttribution?: FinancialAttribution | null) => {
  if (financialAttribution === FinancialAttribution.Provider) return 'Client I Brought'
  if (financialAttribution === FinancialAttribution.TavaHealth) return 'Tava Referral'
  return 'Unknown'
}

export const financialsService = {
  getPaymentTypeText,
  getFinancialAttributionText,
  paymentTypeMap,
}
