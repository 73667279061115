import { styled } from '@mui/material'
import { ReactNode, useMemo } from 'react'

import { InsurancePayerLocality, usePotentialInsurancePayerCompensationsQuery } from '@nuna/api'
import { calculateMinAndMaxRate, centsToFormattedDollars } from '@nuna/core'
import { IconCheckCircle, body2, greySet, tealSet } from '@nuna/tunic'

import { useCAQHSetupContext } from '../../../context/CAQHSetupContext'
import { CAQHStepper } from '../CAQHStepper'
import { EnrollmentBenefits } from './EnrollmentBenefits'

export function Intro() {
  const { providerId } = useCAQHSetupContext()

  const { data } = usePotentialInsurancePayerCompensationsQuery({
    variables: {
      providerId,
    },
  })

  const nationalCarriers =
    data?.potentialInsurancePayerCompensations.filter(it => it.payerLocality === InsurancePayerLocality.National) || []
  const regionalCarriers =
    data?.potentialInsurancePayerCompensations.filter(it => it.payerLocality === InsurancePayerLocality.Regional) || []
  const minAndMaxRate = useMemo(() => {
    if (data?.potentialInsurancePayerCompensations.length) {
      return calculateMinAndMaxRate(data?.potentialInsurancePayerCompensations)
    }
    return undefined
  }, [data?.potentialInsurancePayerCompensations])

  return (
    <div>
      <h2 className="h3">Let's get you enrolled in insurance</h2>

      {minAndMaxRate && (
        <section>
          <h3 className="h4 mt-6">Your Rates:</h3>

          <p className="large text-medium-gray" style={{ color: greySet[70].hex }}>
            Based on your location and licenses you’ll be reimbursed up to{' '}
            <span style={{ color: tealSet[90].hex, fontWeight: 500 }}>
              {centsToFormattedDollars(minAndMaxRate.maxRate)}
              /per session
            </span>{' '}
            depending on the carrier.
          </p>
        </section>
      )}

      <section>
        <h3 className="h4 mt-6">By enrolling with Tava you can:</h3>

        <EnrollmentBenefits />
      </section>

      {(nationalCarriers.length > 0 || regionalCarriers.length > 0) && (
        <section>
          <h3 className="h4 mt-6">You'll be enrolled with these carriers:</h3>

          {nationalCarriers.length > 0 && (
            <>
              <h4 className="h5 text-teal mt-4 mb-4">National Carriers</h4>
              <CarrierList>
                {nationalCarriers.map(carrier => (
                  <CarrierListItem key={carrier.id}>{carrier.name}</CarrierListItem>
                ))}
              </CarrierList>
            </>
          )}

          {regionalCarriers.length > 0 && (
            <>
              <h4 className="h5 text-teal mt-4 mb-4">Regional Carriers</h4>
              <CarrierList>
                {regionalCarriers.map(carrier => (
                  <CarrierListItem key={carrier.id}>{carrier.name}</CarrierListItem>
                ))}
              </CarrierList>
            </>
          )}
        </section>
      )}

      <div className="mt-6">
        <CAQHStepper />
      </div>
    </div>
  )
}

function CarrierListItem({ children }: { children: ReactNode }) {
  return (
    <li className="v-align-inline full-width mb-3">
      <IconCheckCircle className="mr-2 shrink-0" size={32} color={tealSet[30].hex} />
      {children}
    </li>
  )
}

const CarrierList = styled('ul')`
  color: ${body2};
  font-weight: 500;
  list-style: none;
  padding: 0;
  column-count: 2;
`
