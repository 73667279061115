import { DailyVideo, useAudioTrack, useVideoTrack } from '@daily-co/daily-react'
import { styled } from '@mui/material'
import { HTMLAttributes } from 'react'

import { Avatar, BelowTablet } from '@nuna/tunic'

import { useTavaVideoUsers } from '../../../hooks/useTavaVideoUsers'
import { videoSceneDark } from '../../../util/colors'
import { NetworkQualityIndicator } from '../../NetworkQualityIndicator'
import { MutedIndicator } from '../../VideoActionButton'
import { ParticipantVideoStatus } from './ParticipantVideoStatus'

interface Props extends HTMLAttributes<HTMLDivElement> {
  sessionId: string
  isLocal: boolean
}

export function FloatingVideo({ sessionId, isLocal, ...props }: Props) {
  const videoTrack = useVideoTrack(sessionId)
  const audioTrack = useAudioTrack(sessionId)
  const { getUserBySessionId } = useTavaVideoUsers()

  const user = getUserBySessionId(sessionId)

  return (
    <Container {...props}>
      {videoTrack.isOff ? (
        <AvatarContainer>
          <Avatar url={user?.avatarUrl} size="sm" className="fs-exclude" />
        </AvatarContainer>
      ) : (
        <DailyVideo automirror fit="cover" sessionId={sessionId} type="video" />
      )}

      {isLocal ? (
        <Indicators>
          {audioTrack.isOff && <MutedIndicator />}
          <NetworkQualityIndicator type="local" />
        </Indicators>
      ) : (
        <ParticipantVideoStatus sessionId={sessionId} />
      )}
    </Container>
  )
}

const DEFAULT_DIMENSION = 158

const Container = styled('div')`
  background-color: ${videoSceneDark};
  border-radius: 12px;
  height: ${DEFAULT_DIMENSION}px;
  width: ${DEFAULT_DIMENSION}px;
  overflow: hidden;
  position: relative;
  transition: height 0.3s ease, width 0.3s ease;

  video {
    height: 100%;
    object-fit: cover;
    width: 100%;
  }

  @media (${BelowTablet}) {
    border-radius: 8px;
    width: 84px;
    height: 128px;
  }
`

const AvatarContainer = styled('div')`
  align-items: center;
  display: flex;
  height: 100%;
  justify-content: center;
  width: 100%;

  @media (${BelowTablet}) {
    img {
      height: auto;
      max-width: 90px;
      width: 80%;
    }
  }
`

const Indicators = styled('div')`
  align-items: center;
  bottom: 0.625rem;
  display: inline-flex;
  left: 0.625rem;
  position: absolute;

  > * {
    margin-right: 0.25rem;
  }

  @media (${BelowTablet}) {
    bottom: 0.25rem;
    left: 0.25rem;
  }
`
