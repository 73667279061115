import { IconProps } from './IconProps'

export function IconExportDeprecated({ size = 24, color = 'currentColor', ...props }: IconProps) {
  return (
    <svg width={size} height={size} viewBox="0 0 56 56" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.1744 10.5466H22.1216C22.7817 10.5466 23.3168 11.0798 23.3168 11.7444V14.5131C23.3168 15.1746 22.7784 15.7109 22.1129 15.7109H14.9315V40.5371H41.9108V34.2387C41.9108 33.8593 42.22 33.5517 42.6208 33.5517H45.5007C45.8928 33.5517 46.2107 33.8696 46.2107 34.2387V44.0538C46.2107 44.438 45.9031 44.7494 45.5318 44.7494H11.3119C10.937 44.7494 10.633 44.4465 10.633 44.0538C10.633 44.0538 10.6316 42.4314 10.6316 42.4172V11.2376C10.6316 10.8582 10.9408 10.5506 11.3416 10.5506H14.2215C14.2646 10.5506 14.3069 10.5545 14.3479 10.5618C14.2944 10.5519 14.2367 10.5466 14.1744 10.5466ZM14.6032 10.6608C14.5525 10.628 14.4948 10.601 14.4296 10.5815C14.4915 10.6003 14.5498 10.6272 14.6032 10.6608ZM40.0596 14.7385L35.9256 10.4946C35.4808 10.038 35.481 9.29545 35.922 8.84023L37.4636 7.24896C37.9064 6.79187 38.6271 6.79264 39.0697 7.24703L48.6922 17.4602C49.025 17.8018 49.024 18.3565 48.6922 18.6972L39.0697 28.3044C38.6255 28.7604 37.9046 28.7577 37.4636 28.3025L35.922 26.7112C35.4792 26.2541 35.4815 25.5127 35.9255 25.0569L40.0945 20.7767C40.3178 20.5474 40.2392 20.3616 39.9211 20.3616L36.6775 20.3616L34.0655 20.3419C30.2188 20.3419 27.1004 23.4251 27.1004 27.2285V33.5713C27.1004 34.2027 26.5813 34.7191 25.9411 34.7191H23.0359C22.3965 34.7191 21.8765 34.2052 21.8765 33.5713V27.2285C21.8765 20.5726 27.3337 15.177 34.0655 15.177L36.6775 15.1421L39.8782 15.1506C40.2001 15.1506 40.2802 14.9651 40.0596 14.7385Z"
        fill={color}
      />
    </svg>
  )
}
