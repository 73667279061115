import { IconProps } from './IconProps'

export function IconMaximize({ size = 24, color = 'currentColor', ...props }: IconProps) {
  return (
    <svg width={size} height={size} viewBox="0 0 56 56" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.8109 35.6756C13.8109 34.5711 12.9155 33.6756 11.8109 33.6756H10.1353C9.03069 33.6756 8.13525 34.5711 8.13525 35.6756V45.8648C8.13525 46.9693 9.03068 47.8648 10.1353 47.8648H20.3244C21.4289 47.8648 22.3244 46.9693 22.3244 45.8648V44.1891C22.3244 43.0846 21.4289 42.1891 20.3244 42.1891H17.3514L26.0586 33.6227C26.857 32.8595 26.8855 31.5934 26.1222 30.795L25.1253 29.7523C24.3621 28.9539 23.096 28.9254 22.2976 29.6887L13.8109 38.0382V35.6756ZM35.6757 8.13522C34.5711 8.13522 33.6757 9.03065 33.6757 10.1352V11.8109C33.6757 12.9154 34.5711 13.8109 35.6757 13.8109H38.2893L30.2982 21.6727C29.4998 22.436 29.4714 23.702 30.2346 24.5004L31.2315 25.5431C31.9948 26.3415 33.2608 26.37 34.0592 25.6067L42.1892 17.6082V20.3243C42.1892 21.4289 43.0846 22.3243 44.1892 22.3243H45.8648C46.9694 22.3243 47.8648 21.4289 47.8648 20.3243V10.1352C47.8648 9.03065 46.9694 8.13522 45.8648 8.13522H35.6757Z"
        fill={color}
      />
    </svg>
  )
}
