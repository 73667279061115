import { useEffect } from 'react'

import { useAppointmentForDrawerQuery } from '@nuna/api'
import { useAppointmentDrawerSearchParams, useTimeZoneContext } from '@nuna/common'
import { Audience, appointmentService } from '@nuna/core'
import { Skeleton } from '@nuna/tunic'

import { FutureAppointment } from './components/FutureAppointment'
import { OngoingAppointment } from './components/OngoingAppointment'
import { PastAppointment } from './components/PastAppointment'

interface Props {
  audience: Audience
}

export function AppointmentDetail({ audience }: Props) {
  const { addUserInContext, removeUserInContext } = useTimeZoneContext()
  const {
    drawerConfig: { appointmentId },
  } = useAppointmentDrawerSearchParams()
  const { data } = useAppointmentForDrawerQuery({
    variables: { id: appointmentId ?? '', includeFinancials: audience === 'provider' },
    fetchPolicy: 'network-only',
  })

  const appointment = data?.internalAppointment

  useEffect(() => {
    if (appointment?.patient?.timezone) {
      addUserInContext(appointment.patient)
    }
    return () => removeUserInContext('Patient')
  }, [appointment, addUserInContext, removeUserInContext])

  if (!appointment) {
    return (
      <>
        <Skeleton height={2} style={{ width: '100%' }} className="m-2 mb-3" />
        <Skeleton height={10} style={{ width: '100%' }} className="m-2" />
      </>
    )
  }

  if (appointmentService.isHappeningNow(appointment, { startOffset: -15, endOffset: -20 })) {
    return <OngoingAppointment appointment={appointment} audience={audience} />
  }

  if (appointmentService.isPast(appointment, { endOffset: -20 })) {
    return <PastAppointment appointment={appointment} audience={audience} />
  }

  if (appointmentService.isFuture(appointment)) {
    return <FutureAppointment appointment={appointment} audience={audience} />
  }

  return null
}
