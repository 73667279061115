import { useState } from 'react'

import { AdminBenefitsGuidesQuery } from '@nuna/api'
import { IconButton, IconButtonExternalLink, IconDocumentDeprecated, IconUpload } from '@nuna/tunic'

import { BenefitsGuideUpload } from './BenefitsGuideUpload'

export function BenefitsGuideActions({ contract }: { contract: AdminBenefitsGuidesQuery['contracts'][0] }) {
  const [isUploading, setIsUploading] = useState(false)
  const downloadUrl = contract.benefitsGuideUrl

  if (isUploading) {
    return (
      <BenefitsGuideUpload
        contract={contract}
        onSuccess={() => setIsUploading(false)}
        onCancel={() => setIsUploading(false)}
      />
    )
  }

  return (
    <div className="v-align">
      <span className="text-secondary mr-auto">
        {contract.benefitsGuideDocument?.fileName ? contract.benefitsGuideDocument.fileName : 'N/A'}
      </span>

      {contract.benefitsGuideDocument && downloadUrl && (
        <IconButtonExternalLink href={downloadUrl} tooltip="Download Benefits Guide">
          <IconDocumentDeprecated />
        </IconButtonExternalLink>
      )}

      <IconButton onClick={() => setIsUploading(true)} tooltip="Upload Benefits Guide">
        <IconUpload />
      </IconButton>
    </div>
  )
}
