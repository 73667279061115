import { PaymentPreference, Provider } from '@nuna/api'
import { CollapsingListItemProps } from '@nuna/tunic'

interface ProviderPolicyFAQ extends CollapsingListItemProps {
  coverageType: PaymentPreference[]
}

const ALL = [
  PaymentPreference.Cash,
  PaymentPreference.Employer,
  PaymentPreference.Insurance,
  PaymentPreference.Accesscode,
]

function getFAQContentWithProviderName(provider: Pick<Provider, 'firstName'>) {
  const PROVIDER_POLICY_FAQS: ProviderPolicyFAQ[] = [
    {
      label: 'Why am I seeing this?',
      coverageType: ALL,
      children: `This policy helps protect ${provider.firstName}'s schedule from unplanned gaps that otherwise could be filled.`,
    },
    {
      label: 'Why do providers charge fees?',
      coverageType: ALL,
      children: `Since it is harder to fill schedule gaps at the last minute, ${provider.firstName} charges a fee to recoup some of the money lost from not being able to fill those gaps.`,
    },
    {
      label: 'Does my employer cover this fee?',
      coverageType: [PaymentPreference.Employer],
      children: 'Unfortunately, your employer does not cover late cancellation/no show fees.',
    },
    {
      label: 'Will insurance cover this fee?',
      coverageType: [PaymentPreference.Insurance],
      children: 'Unfortunately, your insurance does not cover late cancellation/no show fees.',
    },
    {
      label: 'Can I use an HSA card?',
      coverageType: ALL,
      children: 'Unfortunately, you cannot use your HSA card for late cancellation/no show fees.',
    },
    {
      label: 'Who should I contact if I think a mistake has been made?',
      coverageType: ALL,
      children: 'If you believe a mistake has been made, please contact your therapist to resolve the issue.',
    },
  ]

  return PROVIDER_POLICY_FAQS
}

function getProviderPolicyFAQs(
  coverageType: PaymentPreference | null | undefined = PaymentPreference.Cash,
  provider: Pick<Provider, 'firstName'>,
) {
  return getFAQContentWithProviderName(provider).filter(faq =>
    faq.coverageType.includes(coverageType ?? PaymentPreference.Cash),
  )
}

export const providerPolicyUtils = { getProviderPolicyFAQs }
