import { HTMLAttributes } from 'react'

import { IconGear } from '@nuna/tunic'

import { useVideoCallContext } from '../../../context/VideoCallContext'
import { SidebarState } from '../../../context/hooks/useSidebarContext'
import { TopbarToggleButton } from './TopbarToggleButton'

export function SettingsToggleButton(props: HTMLAttributes<HTMLButtonElement>) {
  const { activeSidebar, toggleSidebar } = useVideoCallContext()

  return (
    <TopbarToggleButton
      {...props}
      data-component="video-settings-sidebar"
      active={activeSidebar === SidebarState.Settings}
      onClick={() => toggleSidebar(SidebarState.Settings)}
    >
      <IconGear />
    </TopbarToggleButton>
  )
}
