import { CardCvcElement, CardExpiryElement, CardNumberElement, useElements, useStripe } from '@stripe/react-stripe-js'
import { isString, noop } from 'lodash'
import mixpanel from 'mixpanel-browser'
import { useState } from 'react'

import { useStripeSetupIntentQuery } from '@nuna/api'
import { toast } from '@nuna/tunic'

export function useCreditCardForm(
  onSuccess: (stripeId: string) => void = noop,
  initialErrorMessage = '',
  patientId = '',
) {
  const stripe = useStripe()
  const elements = useElements()
  const { data, loading, error } = useStripeSetupIntentQuery({
    variables: {
      patientId,
    },
    fetchPolicy: 'network-only',
    skip: !patientId,
  })
  const [errorMessage, setErrorMessage] = useState(initialErrorMessage)
  const [isFormBusy, setIsFormBusy] = useState(false)

  if (loading || error || !data) {
    return null
  }

  if (!data.stripeSetupIntent) {
    console.warn('No Stripe setup intent provided')
    return null
  }

  const { stripeSetupIntent } = data

  const handleSubmit = async () => {
    setIsFormBusy(true)

    if (!stripe || !elements) {
      return
    }

    const cardElement = elements.getElement(CardNumberElement)

    if (!cardElement) {
      toast.urgent('Error loading card')
      return
    }

    const result = await stripe.confirmCardSetup(stripeSetupIntent, {
      payment_method: {
        card: cardElement,
        metadata: {
          patientId,
        },
      },
    })

    if (result.error) {
      // Show error to your customer.
      console.error(result.error.message)
      setErrorMessage(result.error.message || '')
      setIsFormBusy(false)
    } else {
      mixpanel.track('added card', {})

      // Re-enable add card
      setIsFormBusy(false)

      // Clear Elements
      const cardElement = elements.getElement(CardNumberElement)
      const cvcElement = elements.getElement(CardCvcElement)
      const cardExpiryElement = elements.getElement(CardExpiryElement)
      cardElement && cardElement.clear()
      cvcElement && cvcElement.clear()
      cardExpiryElement && cardExpiryElement.clear()

      if (result.setupIntent.payment_method === null) {
        setErrorMessage('Error creating payment method')
      } else if (isString(result.setupIntent.payment_method)) {
        onSuccess(result.setupIntent.payment_method)
      } else {
        onSuccess(result.setupIntent.payment_method.id)
      }
    }
  }

  return { handleSubmit, errorMessage, isFormBusy, stripe }
}
