import { styled } from '@mui/material'

import { borderGrey, greySet } from '@nuna/tunic'

const containerPadding = 1.5

export const SidebarPanel = styled('section')`
  background-color: ${greySet[5].hex};
  border: 1px solid ${borderGrey};
  border-radius: 6px;
  padding: ${containerPadding}rem;

  hr {
    background-color: ${borderGrey};
    border: none;
    height: 1px;
    margin: 1.5rem -${containerPadding}rem;
  }
`
