import { Autocomplete, AutocompleteProps, TextField } from '@mui/material'
import { noop, omit } from 'lodash'
import { FocusEventHandler, ReactNode, useMemo } from 'react'

import { BasicSpecialtyFragment, useSpecialtiesQuery } from '@nuna/api'
import { specialtiesService } from '@nuna/core'
import { Checkbox, Chip, IconButton, IconClose } from '@nuna/tunic'

interface Props
  extends Pick<
    AutocompleteProps<BasicSpecialtyFragment, true, false, false>,
    'onChange' | 'style' | 'className' | 'disabled'
  > {
  onRemove: (specialtyId: string) => void
  value: BasicSpecialtyFragment[]
  error: boolean
  onBlur?: FocusEventHandler<HTMLInputElement | HTMLTextAreaElement>
  helperText?: string | ReactNode
}

export function ModalitiesSelect({ error, helperText, onBlur, onRemove, ...props }: Props) {
  const { data } = useSpecialtiesQuery()

  const options = useMemo(() => specialtiesService.getModalitySpecialties(data?.specialties ?? []), [data])

  return (
    <Autocomplete
      multiple
      options={options}
      disableCloseOnSelect
      getOptionLabel={option => option.name}
      renderOption={(renderOptionProps, option, { selected }) => (
        <li {...renderOptionProps}>
          <Checkbox style={{ pointerEvents: 'none' }} checked={selected} onChange={noop}>
            {option.name}
          </Checkbox>
        </li>
      )}
      renderTags={(modalities, getTagProps) => {
        return modalities.map((modality, index) => (
          <Chip
            truncateAt={200}
            small
            {...omit(getTagProps({ index }), ['onDelete'])}
            onRemove={() => onRemove(modality.id)}
          >
            {modality.name}
          </Chip>
        ))
      }}
      renderInput={({ InputProps, ...params }) => (
        <TextField
          {...params}
          onBlur={onBlur}
          error={error}
          helperText={helperText}
          label=""
          placeholder="Begin typing to add modalities"
          InputProps={{
            ...InputProps,
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            'data-testid': 'modalities-select',
            endAdornment: (
              <div className="MuiAutocomplete-endAdornment">
                <div className="v-align" style={{ transform: 'translateY(-0.5rem)' }}>
                  <IconButton
                    tooltip="Clear diagnoses"
                    type="button"
                    variant="secondary"
                    small
                    onClick={event => props.onChange && props.onChange(event, [], 'clear')}
                  >
                    <IconClose size={16} />
                  </IconButton>
                </div>
              </div>
            ),
          }}
        />
      )}
      {...props}
    />
  )
}
