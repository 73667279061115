// For useful context on use of em units in media queries https://zellwk.com/blog/media-query-units/
export const Phone = 'max-width: 37.5em' // 600
export const PhoneMin = 'min-width: 37.5625em' // 601
export const BelowTablet = 'max-width: 47.9375em' // 767
export const TabletPortraitMin = 'min-width: 48em' // 768
export const TabletPortrait = 'max-width: 48em' // 768
export const MediumMin = 'min-width: 60em' // 960 corresponds with MUI grid breakpoints
export const BelowTabletLandscape = 'max-width: 63.9375em' // 1023
export const TabletLandscape = 'max-width: 64em' // 1024
export const Desktop = 'min-width: 64.0625em' // 1025
export const BelowDesktopLarge = 'max-width: 79.9375em' // 1279
export const DesktopLarge = 'min-width: 80em' // 1280
export const DesktopXLarge = 'min-width: 90em' // 1440

export const SmallPhoneHeight = 'max-height: 43.75em' // 700
export const SmallPhoneHeightMin = 'min-height: 43.8125em' // 701

export const Print = 'print'
