import moment from 'moment'

import { Provider } from '../types/internal-only/provider.types'

function getDefaultProvider(providers?: Provider[] | null) {
  if (!providers) {
    return undefined
  }
  const therapistProviders = providers.filter(provider => provider.type === 'THERAPIST')

  const defaultProvider = therapistProviders.length
    ? therapistProviders[0]
    : providers.length
    ? providers[0]
    : undefined

  return defaultProvider
}

function isQualifiedMinor(dob: string) {
  return moment().diff(dob, 'years') >= 13 && moment().diff(dob, 'years') < 18
}

export const patientService = {
  getDefaultProvider,
  isQualifiedMinor,
}
