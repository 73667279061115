import { IconProps } from './IconProps'

export function IconSent({ color = 'currentColor', size = 24 }: IconProps) {
  const height = (64 / 85) * size

  return (
    <svg width={size} height={height} viewBox="0 0 85 64" fill={color} xmlns="http://www.w3.org/2000/svg">
      <rect y="19" width="23" height="3" rx="1.5" />
      <rect x="7" y="27" width="16" height="3" rx="1.5" />
      <rect x="16" y="35" width="7" height="3" rx="1.5" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M33.7014 15.125C31.2926 15.125 29.375 17.1693 29.375 19.632V45.0008C29.375 47.4636 31.2926 49.4508 33.7014 49.4508H73.3291C75.7379 49.4508 77.75 47.4636 77.75 45.0008V19.632C77.7406 17.1693 75.7379 15.125 73.3196 15.125H33.7014ZM54.0207 36.8805C53.7656 37.1658 53.2083 37.1943 52.906 36.852L35.3168 18.7858L71.6287 18.7953L54.0207 36.8805ZM32.8702 21.4672L43.0345 31.9266L32.8702 42.3194V21.4672ZM45.5189 34.4654L50.3177 39.4098C51.7913 41.0358 54.8519 41.1404 56.5428 39.4288L61.4266 34.4083L72.5923 45.9327H34.3627L45.5189 34.4654ZM63.9205 31.7935L74.1509 21.277V42.3194L63.9205 31.7935Z"
      />
    </svg>
  )
}
