import * as Yup from 'yup'
import { Formik } from 'formik'
import { useNavigate } from 'react-router-dom'

import {
  DocumentType,
  UserDocumentsQuery,
  useDocumentUpload,
  useProviderCredentialingDataQuery,
  useSaveProviderCredentialingDataMutation,
  useSaveUserDocumentMutation,
  useUserDocumentsQuery,
} from '@nuna/api'
import { errorService } from '@nuna/core'
import { HelperText, toast } from '@nuna/tunic'
import {
  Card,
  CardBody,
  FileTypeIcon,
  FileUpload,
  FileUploadDisplayProps,
  IconButton,
  IconClose,
  Skeleton,
} from '@nuna/tunic'

import { useCAQHSetupContext } from '../../../context/CAQHSetupContext'
import { useCAQHNavigation } from '../../../hooks/useCAQHNavigation'
import { CAQHStepper } from '../CAQHStepper'
import { CAQHSubtitle } from './CAQHSubtitle'

interface ResumeFormValues {
  file: File | null
}

export function Resume() {
  const { providerId, providerLoginId } = useCAQHSetupContext()
  const { nextPath } = useCAQHNavigation()
  const navigate = useNavigate()
  const { data } = useProviderCredentialingDataQuery({ variables: { providerId } })
  const [saveProviderCredentialingData, { loading: saveDataLoading }] = useSaveProviderCredentialingDataMutation()

  const { uploadDocument, loading: documentUploading } = useDocumentUpload()
  const [saveUserDocument] = useSaveUserDocumentMutation()
  const { data: documentData, loading: documentLoading } = useUserDocumentsQuery({
    fetchPolicy: 'cache-and-network',
    variables: {
      searchOptions: { loginId: providerLoginId, documentType: DocumentType.Resume },
      includeDownloadUrl: true,
    },
  })

  const existingResumeUserDocument = documentData?.userDocuments?.[0]

  const handleSubmit = async (values: ResumeFormValues) => {
    if (!values.file && existingResumeUserDocument) {
      navigate(nextPath)
      return
    }

    if (!values.file) {
      console.error('Validation error: file is required')
      return
    }

    try {
      const document = await uploadDocument(values.file, DocumentType.Resume)

      const { errors: userDocumentErrors } = await saveUserDocument({
        variables: {
          userDocument: {
            id: existingResumeUserDocument?.id,
            documentId: document.id,
            loginId: providerLoginId,
          },
        },
      })
      const response = await saveProviderCredentialingData({
        variables: { providerId, data: { resumeDocumentId: document.id } },
      })

      if (response.data?.saveProviderCredentialingData?.resumeDocumentId && !userDocumentErrors?.length) {
        navigate(nextPath)
      }
    } catch (e) {
      toast.urgent(errorService.transformUserGraphqlError(e, 'There was an error saving your resume'))
      console.error(e)
      return
    }
  }

  const saving = documentUploading || saveDataLoading
  const savedFiles = existingResumeUserDocument && [
    {
      url: existingResumeUserDocument.document.downloadUrl ?? '',
      mimeType: existingResumeUserDocument.document.mimeType,
      name: existingResumeUserDocument.document.fileName,
    },
  ]

  return (
    <div>
      <h2 className="h3 mb-3">Add Your Resume</h2>
      <CAQHSubtitle className="mb-5">This is used by insurance networks to verify your credentials</CAQHSubtitle>

      {!data || documentLoading ? (
        <Skeleton height={5} />
      ) : (
        <Formik
          initialValues={{ file: null }}
          validationSchema={buildSchema(existingResumeUserDocument)}
          onSubmit={handleSubmit}
        >
          {({ errors, setFieldValue, handleSubmit }) => (
            <form onSubmit={handleSubmit}>
              <FileUpload
                fit="cover"
                savedFiles={savedFiles}
                getFileDisplay={DisplayFile}
                multiple={false}
                onDrop={(files: File[]) => setFieldValue('file', files[0])}
                size="lg"
                requirementDescription="Requires PDF or docx"
                accept={[
                  'application/pdf',
                  'application/vnd.openxmlformats-officedocument.wordprocessingml.document', // docx
                ]}
              />
              {errors.file && <HelperText error>{errors.file}</HelperText>}

              <div className="mt-6">
                <CAQHStepper skipSave isLoading={saving} disabled={saving} />
              </div>
            </form>
          )}
        </Formik>
      )}
    </div>
  )
}

type ExistingResumeUserDocument = UserDocumentsQuery['userDocuments'][0]

function buildSchema(existingResumeUserDocument: ExistingResumeUserDocument | undefined) {
  return Yup.object().shape<ResumeFormValues>({
    file: existingResumeUserDocument ? Yup.mixed() : Yup.mixed().required('Resume is required'),
  })
}

interface DisplayedFile {
  name: string
  type: string
  url?: string
}

function DisplayFile({ files, savedFiles, replaceButtonProps }: FileUploadDisplayProps) {
  let displayedFiles: DisplayedFile[] | null = null

  if (files?.length) {
    displayedFiles = files
  } else if (savedFiles?.length) {
    displayedFiles = savedFiles.map(file => ({ name: file.name ?? '', type: file.mimeType ?? '', url: file.url }))
  }

  if (!displayedFiles?.length) return null
  const [file] = displayedFiles

  return (
    <Card>
      <CardBody>
        <div className="v-align">
          <FileTypeIcon mimeType={file.type ?? ''} className="mr-1" />
          <span>{file.name}</span>
          <IconButton
            {...replaceButtonProps}
            small
            variant="secondary"
            className="ml-auto"
            tooltip="Replace file"
            type="button"
          >
            <IconClose size={16} />
          </IconButton>
        </div>
      </CardBody>
    </Card>
  )
}
