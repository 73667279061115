import { dropRight, findIndex, noop, omit, pick, random } from 'lodash'
import { ReactNode, createContext, useContext, useEffect, useState } from 'react'

import {
  AddressOwner,
  AddressType,
  useProviderAddressesQuery,
  useRemoveProviderAddressMutation,
  useSaveProviderAddressMutation,
} from '@nuna/api'
import { OmittedAddressFields } from '@nuna/common'
import { type ProviderFormAddress, addressService } from '@nuna/core'

const { NEW_HOME_ADDRESS_KEY, NEW_PRACTICE_ADDRESS_KEY, generateProviderAddresses, isHomeAddress, mapAddressValues } =
  addressService

interface ContextValue {
  homeAddress: ProviderFormAddress
  practiceAddresses: ProviderFormAddress[]
  sameAsPracticeAddressChange: (sameAsPracticeAddress: boolean) => void
  cancelChanges: (id?: string) => void
  addPracticeAddress: () => void
  upsertHomePracticeAddress: (address: ProviderFormAddress) => Promise<ProviderFormAddress | undefined>
  saveHomeAddress: (values: Partial<ProviderFormAddress>) => Promise<void>
  savePracticeAddress: (values: Partial<ProviderFormAddress>) => Promise<void>
  removeAddress: (id: string) => Promise<void>
  refetchAddresses: () => Promise<unknown>
  loading: boolean
}

interface Props {
  providerId: string
  children: ReactNode
}

const PRACTICE_ADDRESS_NAME = 'Practice Address'

const ProviderAddressContext = createContext<ContextValue>(buildDefaultContextValue())

export function ProviderAddressContextProvider({ children, providerId }: Props) {
  const [loading, setLoading] = useState(true)
  const [saveProviderAddressMutation] = useSaveProviderAddressMutation()
  const [removeAddressMutation] = useRemoveProviderAddressMutation()
  const [homeAddress, setHomeAddress] = useState(defaultHomeAddress(providerId))
  const [practiceAddresses, setPracticeAddresses] = useState<ProviderFormAddress[]>([])

  const { data, refetch } = useProviderAddressesQuery({
    fetchPolicy: 'cache-and-network',
    // we're tracking state locally, including that of unsaved addresses
    // if addresses are refetched on save, it will blow away unsaved addresses
    // standby prevents refetches, unless we explicitly invoke refetchAddresses
    nextFetchPolicy: 'standby',
    variables: { searchOptions: { providerId } },
  })

  useEffect(() => {
    if (data?.providerAddresses) {
      const [newHomeAddress, newPracticeAddresses] = generateProviderAddresses(data.providerAddresses, providerId)
      setHomeAddress(newHomeAddress)
      setPracticeAddresses(newPracticeAddresses)
      setLoading(false)
    }
  }, [data, providerId])

  const generatePracticeAddressName = () => {
    const currentNames = practiceAddresses.map(address => address.name)
    return currentNames.includes(PRACTICE_ADDRESS_NAME)
      ? `${PRACTICE_ADDRESS_NAME} ${random(0, 1000)}`
      : PRACTICE_ADDRESS_NAME
  }

  const sameAsPracticeAddressChange = (sameAsPracticeAddress: boolean) => {
    if (practiceAddresses.length === 0 && !sameAsPracticeAddress) {
      setPracticeAddresses([
        {
          providerId,
          addressType: AddressType.ProviderPractice,
          id: homeAddress.matchedPracticeAddressId || undefined,
          key: NEW_PRACTICE_ADDRESS_KEY,
          owner: AddressOwner.Provider,
        },
      ])
    }

    if (practiceAddresses.length > 0 && sameAsPracticeAddress) {
      setPracticeAddresses(practiceAddresses.filter(address => address.id !== homeAddress.matchedPracticeAddressId))
    }
  }

  const addPracticeAddress = () => {
    setPracticeAddresses([...practiceAddresses, defaultPracticeAddress(providerId)])
  }

  const cancelChanges = (id?: string) => {
    if (!id) {
      setPracticeAddresses(dropRight(practiceAddresses))
    }
  }

  const removeAddress = async (id: string) => {
    await removeAddressMutation({ variables: { addressId: id, providerId } })
    setPracticeAddresses(current => current.filter(address => address.id !== id))
  }

  const upsertHomePracticeAddress = async (address: Partial<ProviderFormAddress>) => {
    const name = isHomeAddress(address) ? generatePracticeAddressName() : address.name
    const result = (
      await saveProviderAddressMutation({
        variables: {
          address: {
            ...prepareAddressForSave(address),
            addressType: AddressType.ProviderPractice,
            providerId,
            name,
          },
        },
      })
    ).data?.saveProviderAddress

    if (address.defaultAddress && isHomeAddress(address)) {
      setHomeAddress({
        ...address,
        defaultAddress: false,
        sameAsPracticeAddress: true,
        matchedPracticeAddressId: result?.address.id ?? '',
        providerId,
        key: address.id ?? NEW_HOME_ADDRESS_KEY,
        owner: AddressOwner.Provider,
      })

      setPracticeAddresses(current => unsetDefaultPracticeAddresses(current))
    }

    return mapAddressValues(providerId, !!result?.primaryPracticeLocation, result?.address ?? {})
  }

  const unsetDefaultPracticeAddresses = (addresses: ProviderFormAddress[]) => {
    return addresses.map(address => ({ ...address, defaultAddress: false }))
  }

  const savePracticeAddress = async (values: Partial<ProviderFormAddress>) => {
    const key = values.key
    const address = prepareAddressForSave(values)
    const result = await (
      await saveProviderAddressMutation({ variables: { address: omit(address, OmittedAddressFields) } })
    ).data?.saveProviderAddress
    const savedAddress = mapAddressValues(providerId, !!result?.primaryPracticeLocation, result?.address ?? {})
    if (savedAddress.defaultAddress && homeAddress.defaultAddress) {
      setHomeAddress(current => {
        return { ...current, defaultAddress: false }
      })
    }

    setPracticeAddresses(current => {
      const copy = savedAddress.defaultAddress ? unsetDefaultPracticeAddresses(current) : [...current]
      const matchIndex = findIndex(copy, practiceAddress => practiceAddress.key === key)
      copy.splice(matchIndex, 1, savedAddress)

      return copy
    })
  }

  const saveHomeAddress = async (values: Partial<ProviderFormAddress>) => {
    const { sameAsPracticeAddress = false } = values
    const address = prepareAddressForSave(values)

    const result = await saveProviderAddressMutation({
      variables: {
        address: omit(address, ['defaultAddress', ...OmittedAddressFields]),
      },
    })

    if (result instanceof Error) throw result

    const savedProviderAddress = result.data?.saveProviderAddress

    const savedAddress = mapAddressValues(
      providerId,
      !!savedProviderAddress?.primaryPracticeLocation,
      savedProviderAddress?.address ?? {},
    )

    const newAddress: ProviderFormAddress = {
      ...savedAddress,
      sameAsPracticeAddress,
      defaultAddress: false,
    }

    setHomeAddress(newAddress)
  }

  const contextValue: ContextValue = {
    homeAddress,
    practiceAddresses,
    sameAsPracticeAddressChange,
    cancelChanges,
    addPracticeAddress,
    saveHomeAddress,
    removeAddress,
    upsertHomePracticeAddress,
    savePracticeAddress,
    refetchAddresses: refetch,
    loading,
  }

  return <ProviderAddressContext.Provider value={contextValue}>{children}</ProviderAddressContext.Provider>
}

export function useProviderAddressContext() {
  return useContext(ProviderAddressContext)
}

function buildDefaultContextValue(): ContextValue {
  return {
    homeAddress: { providerId: '', key: NEW_HOME_ADDRESS_KEY, owner: AddressOwner.Provider },
    practiceAddresses: [],
    sameAsPracticeAddressChange: noop,
    cancelChanges: noop,
    addPracticeAddress: noop,
    saveHomeAddress: () => new Promise(() => null),
    removeAddress: () => new Promise(() => null),
    upsertHomePracticeAddress: () => new Promise(() => null),
    savePracticeAddress: () => new Promise(() => null),
    refetchAddresses: () => new Promise(() => null),
    loading: true,
  }
}

function defaultHomeAddress(providerId: string): ProviderFormAddress {
  return {
    addressType: AddressType.ProviderHome,
    providerId,
    sameAsPracticeAddress: false,
    name: 'Home',
    matchedPracticeAddressId: '',
    key: NEW_HOME_ADDRESS_KEY,
    owner: AddressOwner.Provider,
  }
}

function defaultPracticeAddress(providerId: string): ProviderFormAddress {
  return {
    addressType: AddressType.ProviderPractice,
    providerId,
    key: NEW_PRACTICE_ADDRESS_KEY,
    owner: AddressOwner.Provider,
  }
}

function prepareAddressForSave(address: Partial<ProviderFormAddress>) {
  const newAddress = pick(address, [
    'addressLineOne',
    'addressLineTwo',
    'city',
    'state',
    'zipCode',
    'addressType',
    'country',
    'name',
    'providerId',
    'longitude',
    'latitude',
  ])

  return {
    addressId: address?.id,
    ...newAddress,
  }
}
