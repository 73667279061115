import * as Yup from 'yup'
import { useFormik } from 'formik'
import { useMemo } from 'react'

import { AppointmentChangeReason, DrawerAppointmentFragment } from '@nuna/api'
import { useAppointmentDrawerSearchParams } from '@nuna/common'
import { Audience, appointmentService } from '@nuna/core'
import { useCapProgressQuery } from '@nuna/coverage'
import { toast } from '@nuna/tunic'

import { useCancelOrReschedule } from './useCancelOrReschedule'

export interface CancelFormValues {
  appointmentId: string
  reason: AppointmentChangeReason
  reasonData: string | null
  requestedBy: 'client' | 'provider'
  shouldProviderBePaid: boolean
  hasValidPaymentMethod: boolean | null
}

export const cancelValidationSchema = Yup.object().shape<CancelFormValues>({
  appointmentId: Yup.string().required(),
  reason: Yup.mixed()
    .oneOf(Object.values(AppointmentChangeReason))
    .required('Please select a reason')
    .typeError('Please select a reason'),
  reasonData: Yup.string().when('reason', {
    is: AppointmentChangeReason.FreeForm,
    then: Yup.string().required('Please provide a reason').typeError('Please provide a reason'),
    otherwise: Yup.string().nullable(),
  }),
  requestedBy: Yup.mixed<'client' | 'provider'>().oneOf(['provider', 'client']).required(),
  shouldProviderBePaid: Yup.boolean(),
  hasValidPaymentMethod: Yup.boolean().oneOf([true], `You'll need to add a new credit card to cancel`),
})

interface UseCancelFormArgs {
  appointment: DrawerAppointmentFragment
  audience: Audience
}
export function useCancelForm({ appointment, audience }: UseCancelFormArgs) {
  const { closeDrawer } = useAppointmentDrawerSearchParams()
  const { cancel } = useCancelOrReschedule({
    patient: appointment.patient,
    provider: appointment.provider,
    audience,
  })
  const { refetch: refetchCapProgress } = useCapProgressQuery(appointment.patient.id)
  const initialValues = useMemo(() => buildInitialValues(appointment.id, audience), [appointment, audience])

  const handleSubmit = async (values: CancelFormValues) => {
    try {
      await cancel({
        appointmentId: appointment.id,
        reason: values.reason,
        reasonData: values.reasonData,
        shouldProviderBePaid: values.shouldProviderBePaid,
        appointmentCancelStatus:
          audience === 'admin' ? appointmentService.userTypeToAppointmentCancelStatus(values.requestedBy) : null,
      })
      refetchCapProgress()
      closeDrawer()
    } catch (e) {
      console.error(e)
      toast.urgent('An error occured while rescheduling the appointment.')
    }
  }

  const formik = useFormik<CancelFormValues>({
    initialValues,
    validationSchema: cancelValidationSchema,
    onSubmit: handleSubmit,
  })

  return { formProps: formik }
}

function buildInitialValues(appointmentId: string, audience: Audience): CancelFormValues {
  return {
    appointmentId,
    reason: '' as AppointmentChangeReason,
    reasonData: null,
    requestedBy: 'provider',
    shouldProviderBePaid: false,
    hasValidPaymentMethod: audience === 'client' ? null : true,
  }
}
