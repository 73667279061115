import { ReactNode } from 'react'
import { Navigate, Route, Routes, useParams } from 'react-router-dom'

import { useCustomerCompanyQuery } from '@nuna/api'
import { useIsAdmin } from '@nuna/auth'
import {
  CenteredHeader,
  MenuItem,
  MenuItemStack,
  OutlineButtonLink,
  PageContent,
  PageHeader,
  PageHeading,
  PageWrapper,
  Skeleton,
} from '@nuna/tunic'

import { HrAdminUsersTable } from '../HRAdminUsersTable'
import { CustomerBenefitsGuide } from './components/CustomerBenefitsGuide/CustomerBenefitsGuide'
import { CustomerContractDetail } from './components/CustomerContract/CustomerContractDetail'
import { CustomerContractsList } from './components/CustomerContract/CustomerContractsList'
import { HrisSync } from './components/CustomerIntegration/HrisSync'
import { CustomerOverview } from './components/CustomerOverview'
import { CustomerRosters } from './components/CustomerRosters'
import { MemberInvite } from './components/MemberInvite'

type RouteProps = {
  id: string
}

interface Props {
  children?: ReactNode
  NewCustomerContract?: JSX.Element
  EditCustomerContract?: JSX.Element
  UpdateCompany?: JSX.Element
}

export function CustomerDetail({ children, NewCustomerContract, EditCustomerContract, UpdateCompany }: Props) {
  const { id } = useParams<RouteProps>()
  const isAdmin = useIsAdmin()

  const variables = id ? { id } : {}

  const { data, loading, refetch: refetchCustomerCompany } = useCustomerCompanyQuery({ variables })

  if (loading || !data || !data.customerCompany) {
    return (
      <PageWrapper>
        <PageHeader>
          <PageHeading className="mb-0 loading">Loading Company Detail</PageHeading>
        </PageHeader>

        <PageContent>
          <Skeleton width={100} height={20} dimensionUnits="%" />
        </PageContent>
      </PageWrapper>
    )
  }

  const {
    customerCompany: { name, supportsHrisIntegration },
  } = data

  const isHrisCompany = supportsHrisIntegration
  const hideRosters = !isHrisCompany && !isAdmin

  return (
    <PageWrapper>
      <PageHeader>
        <CenteredHeader className="v-align">
          <div className="centered-header-left">
            <PageHeading className="mb-0 ml-1" style={{ lineHeight: '3rem', whiteSpace: 'normal' }}>
              {name}
            </PageHeading>
          </div>
          <MenuItemStack component="nav" direction="row" className="centered-header-center">
            <MenuItem to={`overview`}>Overview</MenuItem>
            {!hideRosters && <MenuItem to={`rosters`}>Rosters</MenuItem>}
            {isAdmin && <MenuItem to="contracts">Contracts</MenuItem>}
            <MenuItem to={`benefits-guide`}>Benefits guide</MenuItem>
            <MenuItem to={`hr-admins`}>HR Admins</MenuItem>
            <MenuItem to={`member-invite`}>Member Invite</MenuItem>
          </MenuItemStack>
          <div className="centered-header-right v-align">
            {children}
            {!loading && isHrisCompany && (
              <Routes>
                <Route
                  path="/rosters"
                  element={<OutlineButtonLink to="hris/sync">Add HRIS Integration</OutlineButtonLink>}
                />
              </Routes>
            )}
          </div>
        </CenteredHeader>
      </PageHeader>
      <PageContent>
        <Routes>
          <Route index element={<Navigate to="overview" replace />} />
          <Route path="overview" element={<CustomerOverview customerCompany={data.customerCompany} />} />
          {isAdmin && UpdateCompany && <Route path="update" element={UpdateCompany} />}
          <Route
            path="rosters"
            element={
              <CustomerRosters
                customerCompany={data.customerCompany}
                refetchCustomerCompany={refetchCustomerCompany}
                isAdmin={isAdmin}
              />
            }
          />
          <Route
            path="rosters/hris/sync"
            element={
              <HrisSync
                customerCompany={data.customerCompany}
                refetchCustomerCompany={refetchCustomerCompany}
                isAdmin={isAdmin}
              />
            }
          />
          <Route path="benefits-guide" element={<CustomerBenefitsGuide customerCompany={data.customerCompany} />} />
          <Route path={`hr-admins`} element={<HrAdminUsersTable companyId={data.customerCompany.id} />} />
          <Route path="member-invite" element={<MemberInvite companyId={data.customerCompany.id} />} />
          {isAdmin && (
            <>
              <Route path="contracts" element={<CustomerContractsList />}></Route>
              {NewCustomerContract && <Route path="contracts/new" element={NewCustomerContract} />}
              <Route path="contracts/:contractId" element={<CustomerContractDetail />} />
              {EditCustomerContract && <Route path="contracts/:contractId/edit" element={EditCustomerContract} />}
            </>
          )}
        </Routes>
      </PageContent>
    </PageWrapper>
  )
}
