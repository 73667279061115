import { styled } from '@mui/material'
import { useEffect } from 'react'

import { BelowTablet } from '@nuna/tunic'

import { useVideoCallContext } from '../../context/VideoCallContext'
import { useVideoChatContext } from '../../context/VideoChatContext'
import { SidebarState } from '../../context/hooks/useSidebarContext'
import { videoSceneExtraDark } from '../../util/colors'
import { isPatient } from '../../util/videoUsers'
import { SIDEBAR } from '../../util/zIndexes'
import { SidebarChat } from './components/SidebarChat'
import { SidebarClientInfo } from './components/SidebarClientInfo'
import { SidebarNotes } from './components/SidebarNotes'
import { SidebarVideoSettings } from './components/SidebarVideoSettings'

export function Sidebar() {
  const { activeSidebar, isSidebarOpen, toggleSidebar, expectedUsers } = useVideoCallContext()
  const { conversation } = useVideoChatContext()

  const patient = expectedUsers.find(isPatient) // one day this will have to handle multiple patients

  // Automatically open sidebar when new message is received and sidebar isn't already open
  useEffect(() => {
    if (conversation) {
      const handleMessageAdded = () => {
        if (!isSidebarOpen) {
          toggleSidebar(SidebarState.Chat)
        }
      }

      conversation.addListener('messageAdded', handleMessageAdded)
      return () => {
        conversation.removeListener('messageAdded', handleMessageAdded)
      }
    }
  }, [conversation, isSidebarOpen, toggleSidebar])

  return (
    <SpacingWrapper isOpen={isSidebarOpen}>
      <Container>
        <SidebarContentWrapper>
          {(() => {
            switch (activeSidebar) {
              case SidebarState.Settings:
                return <SidebarVideoSettings />
              case SidebarState.Chat:
                return <SidebarChat />
              case SidebarState.ClientInfo:
                return patient ? <SidebarClientInfo patient={patient} /> : null // passing patient in to one day support looping multiple patients
              case SidebarState.Notes:
                return patient ? <SidebarNotes patient={patient} /> : null // passing patient in to one day support looping multiple patients
              default:
                return null
            }
          })()}
        </SidebarContentWrapper>
      </Container>
    </SpacingWrapper>
  )
}

const sidebarWidth = '362px'

const SpacingWrapper = styled('div')<{ isOpen: boolean }>`
  padding-right: 1rem;
  width: ${props => (props.isOpen ? `calc(${sidebarWidth} + 2rem)` : '0')};
  transition: 300ms width;
  overflow: hidden;
  flex: 0 0 auto;
  height: 100%;
  z-index: ${SIDEBAR};

  @media (${BelowTablet}) {
    flex: 0 0 calc(50% + 1rem);
    width: 50%;
  }
`

const Container = styled('section')`
  background-color: ${videoSceneExtraDark};
  border-radius: 12px;
  min-width: ${sidebarWidth};
  margin-left: 1rem;
  position: relative;
  overflow: hidden;
  height: 100%;

  @media (${BelowTablet}) {
    min-width: unset;
  }
`

const SidebarContentWrapper = styled('div')`
  color: white;
  height: 100%;
  min-width: ${sidebarWidth};

  @media (${BelowTablet}) {
    min-width: unset;
  }
`
