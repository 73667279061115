import { styled } from '@mui/material'
import { HTMLAttributes } from 'react'

import { Avatar, IconChevronThick, IconClose, borderGrey, whiteTintSet } from '@nuna/tunic'

import { useVideoCallContext } from '../../../context/VideoCallContext'
import { SidebarState } from '../../../context/hooks/useSidebarContext'
import { videoSceneDark, videoSceneTeal } from '../../../util/colors'
import { isPatient } from '../../../util/videoUsers'

export function ClientInfoToggleButton(props: HTMLAttributes<HTMLButtonElement>) {
  const { activeSidebar, toggleSidebar, expectedUsers, localUser } = useVideoCallContext()

  if (!localUser.isProvider) {
    return null
  }

  const patient = expectedUsers.find(isPatient)

  return (
    <Button
      {...props}
      data-component="video-client-info-sidebar"
      active={activeSidebar === SidebarState.ClientInfo}
      onClick={() => toggleSidebar(SidebarState.ClientInfo)}
    >
      <Avatar url={patient?.avatarUrl} size="mini" className="fs-exclude" />
      {activeSidebar === SidebarState.ClientInfo ? (
        <IconClose size={16} />
      ) : (
        <IconChevronThick direction="right" size={16} />
      )}
    </Button>
  )
}

const Button = styled('button')<{ active: boolean }>`
  align-items: center;
  background-color: ${props => (props.active ? videoSceneTeal : whiteTintSet[15])};
  border: 1px solid ${whiteTintSet[30]};
  border-radius: 56px;
  color: ${props => (props.active ? videoSceneDark : borderGrey)};
  display: flex;
  height: 40px;
  justify-content: space-between;
  padding: 0 0.5rem;
  width: 74px;

  &:hover {
    color: #fff;
  }
`
