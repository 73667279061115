import { styled } from '@mui/material'
import { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'

import {
  AppointmentChangeReason,
  AppointmentCurrentStatus,
  ProviderListItemFragment,
  useBasicProviderQuery,
  useCancelAppointmentAsAdminMutation,
  useCancelAppointmentAsProviderMutation,
  useExtendedClientQuery,
  usePreconfigurePatientTokenQuery,
  useSelectProviderMutation,
} from '@nuna/api'
import { AppointmentCard, AppointmentCardStatus } from '@nuna/appointment'
import { useIsAdmin } from '@nuna/auth'
import { useAppointmentDrawerSearchParams, useClientInviteDrawerSearchParams } from '@nuna/common'
import { Audience, errorService, routeService } from '@nuna/core'
import { ProviderSelectForClient } from '@nuna/provider'
import {
  ContextualAlert,
  FillButton,
  GhostButton,
  IconButton,
  IconInfo,
  IconTrash,
  PlaceholderButton,
  TextButton,
  TextButtonLink,
  toast,
} from '@nuna/tunic'

import { FormActions, Section, SectionTitle } from '../shared'
import { ClientDocuments } from './Components/ClientDocuments'
import { ClientPaymentMethod } from './Components/ClientPaymentMethod'

interface ClientInviteOptionalProps {
  audience: Audience
  providerId: string
  patientId: string
}

export function ClientInviteOptional({ audience, patientId, providerId }: ClientInviteOptionalProps) {
  const navigate = useNavigate()
  const isAdmin = useIsAdmin()
  const {
    openClientInviteDrawer,
    resetClientInviteDrawer,
    closeDrawer,
    drawerConfig: { redirectToAppointmentScheduling },
  } = useClientInviteDrawerSearchParams()

  const {
    drawerConfig: { drawerOpen: appointmentDrawerOpen },
    openScheduleAppointmentDrawer,
  } = useAppointmentDrawerSearchParams()

  const [selectProvider, { loading: selectProviderLoading }] = useSelectProviderMutation()
  const { data: tokenData, loading: tokenLoading } = usePreconfigurePatientTokenQuery({
    variables: { patientId: patientId },
    skip: !patientId || appointmentDrawerOpen,
  })

  const { data: providerData, loading: providerLoading } = useBasicProviderQuery({
    variables: {
      id: providerId,
    },
    skip: !providerId || appointmentDrawerOpen,
  })

  const {
    data: patientData,
    loading: patientLoading,
    refetch,
  } = useExtendedClientQuery({
    variables: {
      id: patientId,
      includeSignupUrl: true,
    },
    skip: !patientId || appointmentDrawerOpen,
  })

  useEffect(() => {
    if (appointmentDrawerOpen === false) {
      refetch()
    }
  }, [refetch, appointmentDrawerOpen])

  const [cancelAppointmentAsAdmin, { loading: cancelAppointmentAdminLoading }] = useCancelAppointmentAsAdminMutation()
  const [cancelAppointmentAsProvider, { loading: cancelAppointmentProviderLoading }] =
    useCancelAppointmentAsProviderMutation()

  const handleProviderSelect = async (provider: ProviderListItemFragment | null) => {
    if (isAdmin && provider) {
      try {
        await selectProvider({
          variables: {
            id: provider.id,
            patientId,
          },
        })

        openClientInviteDrawer({ providerId: provider.id, patientId: patientId })
      } catch (e) {
        toast.urgent(errorService.transformGraphQlError(e, 'Unable to update client'))
        return
      }
    }
  }

  const handleCancelAppointment = async (appointmentId: string) => {
    try {
      const { errors } =
        audience === 'admin'
          ? await cancelAppointmentAsAdmin({
              variables: {
                appointmentId,
                reason: AppointmentChangeReason.FreeForm,
                reasonData: 'Client invite form removal',
                appointmentCancelStatus: AppointmentCurrentStatus.CanceledByAdmin,
              },
            })
          : await cancelAppointmentAsProvider({
              variables: {
                appointmentId,
                reason: AppointmentChangeReason.FreeForm,
                reasonData: 'Client invite form removal',
              },
            })

      if (errors?.length) {
        throw new Error(errors[0].message)
      }

      await refetch({
        id: patientId,
      })
    } catch (e) {
      console.error(e)
      toast.urgent(errorService.transformGraphQlError(e, 'Unable to cancel appointment'))
    }
  }

  const handleAddNewAppointments = () => {
    openScheduleAppointmentDrawer(providerId, { patientId, paymentRequired: false, closeOnSave: true })
  }

  const handleViewClientPage = () => {
    navigate(routeService.patientDetail(patientId))
  }

  const handleInviteAnotherClient = () => {
    resetClientInviteDrawer({ providerId })
  }

  const handleScheduleContinue = () => {
    openScheduleAppointmentDrawer(providerId, { patientId, wipeOldParams: true })
  }

  const patient = patientData?.patient
  const provider = providerData?.provider
  const appointments = (patient?.appointments || []).filter(
    appt => appt.currentStatus === AppointmentCurrentStatus.Active,
  )
  const loading =
    selectProviderLoading ||
    tokenLoading ||
    cancelAppointmentAdminLoading ||
    cancelAppointmentProviderLoading ||
    patientLoading ||
    providerLoading

  if (!patient || (!provider && audience !== 'admin')) {
    return null
  }

  return (
    <Container>
      <Section>
        <h2 className="h5 mb-3" style={{ alignSelf: 'flex-start' }}>
          Add a Client
        </h2>
        <ContextualAlert className="mb-2" icon={<IconInfo />} intent="information" role="alert" scribbleType="default">
          <TextButton onClick={handleViewClientPage}>{patient.firstName || 'Patient'}</TextButton> has been invited!
          They will receive an email with{' '}
          <TextButtonLink to={patientData.patient.signupUrl || ''} variant="primary">
            this
          </TextButtonLink>{' '}
          link to finish intake. They must finish intake before they can join any session.
        </ContextualAlert>
      </Section>

      <ClientPaymentMethod
        audience={audience}
        patient={{
          id: patient.id,
          dob: patient.dob,
          firstName: patient.firstName,
          lastName: patient.lastName,
          paymentPreference: patient.paymentPreference,
          state: patient.state || '',
        }}
        providerId={providerId}
        tokenId={tokenData?.preconfigurePatientToken?.tokenId || ''}
      />

      {audience === 'admin' && (
        <Section>
          <SectionTitle>Provider (optional)</SectionTitle>
          <ProviderSelectForClient
            disabled={!patient.state}
            state={patient.state}
            onSelect={handleProviderSelect}
            selectedProvider={provider as unknown as ProviderListItemFragment}
          />
        </Section>
      )}
      {!redirectToAppointmentScheduling && (
        <Section>
          <SectionTitle>Schedule Appointment (optional)</SectionTitle>

          {appointments.map(appt => {
            return (
              <AppointmentCard
                key={appt.id}
                className="mb-1"
                appointment={appt}
                audience={'provider'}
                appointmentStatus={AppointmentCardStatus.Active}
                client={patient}
                additionalSlots={{
                  cardRight: (
                    <IconButton
                      type="button"
                      tooltip="Remove Appointment"
                      onClick={() => handleCancelAppointment(appt.id)}
                      disabled={loading}
                    >
                      <IconTrash />
                    </IconButton>
                  ),
                }}
              />
            )
          })}

          {provider && (
            <PlaceholderButton type="button" onClick={handleAddNewAppointments}>
              Add New Appointments
            </PlaceholderButton>
          )}

          {!provider && audience === 'admin' && (
            <ContextualAlert className="my-2" icon={<IconInfo />} intent="default" role="alert" scribbleType="default">
              Choose a provider to schedule appointments
            </ContextualAlert>
          )}
        </Section>
      )}

      <ClientDocuments
        audience={audience}
        loading={patientLoading}
        patientLoginId={patient.loginId || ''}
        providerLoginId={provider?.loginId || ''}
      />

      <FormActions>
        {redirectToAppointmentScheduling ? (
          <FillButton type="button" className="ml-2 mr-2" onClick={handleScheduleContinue}>
            Continue Scheduling
          </FillButton>
        ) : (
          <>
            <GhostButton onClick={closeDrawer} disabled={patientLoading} variant="secondary">
              Exit
            </GhostButton>
            <FillButton type="button" className="ml-2 mr-2" onClick={handleInviteAnotherClient}>
              Invite Another Client
            </FillButton>
          </>
        )}
      </FormActions>
    </Container>
  )
}

const Container = styled('form')`
  height: 100%;
  overflow-y: scroll;
  margin-bottom: 72px;
`
