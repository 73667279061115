import { styled } from '@mui/material'

import { ClientReferral } from '@nuna/client-referral'

interface Props {
  companyId?: string
}

export function MemberInvite({ companyId }: Props) {
  return (
    <Container>
      <ClientReferral companyId={companyId} />
    </Container>
  )
}

const Container = styled('div')`
  max-width: 1200px;
  margin: auto;

  td {
    height: 71px;

    &:last-child {
      width: 50%;
    }
  }
`
