import * as Yup from 'yup'
import { FormikProps } from 'formik'
import { capitalize, isNumber } from 'lodash'
import { useState } from 'react'

import { FullProviderFragment, useNpiCheckQuery, useUpdateProviderMutation } from '@nuna/api'
import { ProfileDisplayValue, ProfileSection } from '@nuna/common'
import { formService } from '@nuna/core'
import { PersistentAlert } from '@nuna/tunic'

import { incompleteSectionNotification } from '../../../utils/util'
import { NPIInput } from '../../NPIInput'

const { composeHelperTextWithError } = formService

export type NpiSectionProps = Pick<FullProviderFragment, 'id' | 'npi'>

type NpiValues = {
  npi?: string | null
}

export function NpiSection({ npi, id }: NpiSectionProps) {
  const [npiValid, setNpiValid] = useState<boolean | null | undefined>()
  const [updateProvider, { loading: updateLoading }] = useUpdateProviderMutation()

  const npiNumber = npi && parseInt(npi)

  const { data: npiRegistryData } = useNpiCheckQuery({
    variables: { npi: npiNumber as number },
    skip: !npiNumber || !isNumber(npiNumber),
  })

  const initialValues: NpiValues = {
    npi: npi,
  }

  const npiRegistry = npiRegistryData?.npiCheck

  const [isComplete, valueDisplay] = incompleteSectionNotification<NpiValues>(
    initialValues,
    ['npi'],
    <div className="v-align">
      <ProfileDisplayValue className="mb-0">{npi}</ProfileDisplayValue>
      {npiRegistry && npiRegistry.first_name && npiRegistry.last_name && (
        <PersistentAlert intent="success" className="ml-1">
          Registered to {capitalize(npiRegistry.first_name)} {capitalize(npiRegistry.last_name)}
        </PersistentAlert>
      )}
    </div>,
  )

  return (
    <ProfileSection
      heading="NPI"
      useOutlineSaveButton
      description=""
      displayWidth="full"
      updateLoading={updateLoading}
      initialValues={initialValues}
      handleSubmit={values => updateProvider({ variables: Object.assign({ id }, { npi: values.npi }) })}
      disabled={() => !npiValid}
      sectionComplete={isComplete}
      renderDisplayValue={valueDisplay}
      renderForm={formProps => {
        return <NPIForm {...formProps} setNpiValid={setNpiValid} providerId={id} />
      }}
      validationSchema={Yup.object().shape({
        npi: Yup.string().length(10, 'A valid NPI is 10 digits').nullable(),
      })}
    ></ProfileSection>
  )
}

type NPIFormProps = FormikProps<NpiValues> & { providerId: string; setNpiValid: (isValid?: boolean | null) => void }

function NPIForm({ values, handleChange, setNpiValid, errors, touched, handleBlur }: NPIFormProps) {
  return (
    <NPIInput
      value={values.npi}
      name="npi"
      onChange={handleChange}
      onBlur={handleBlur}
      onValidCheck={setNpiValid}
      {...composeHelperTextWithError('', errors.npi, !!touched.npi)}
    />
  )
}
