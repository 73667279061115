import moment, { Moment } from 'moment'
import { ReactNode, createContext, useCallback, useContext, useEffect, useState } from 'react'

interface OpenTabMessage {
  type: 'open-tab'
  id: string
  path: string
  appointmentId: string
}

interface ClosedTabMessage {
  type: 'closed-tab'
  id: string
}

type DraftConflictMessage = OpenTabMessage | ClosedTabMessage

interface ActiveTab {
  path: string
  lastPing: Moment
  appointmentId: string
}

interface SessionDraftConflictContextValues {
  notifyTabActive: ({ id, path, appointmentId }: Omit<OpenTabMessage, 'type'>) => void
  notifyTabClosed: ({ id }: Omit<ClosedTabMessage, 'type'>) => void
  getAppointmentActiveTabs: (appointmentId: string) => ActiveTab[]
  hasConflictingTabs: (appointmentId: string) => boolean
}

const TIMEOUT = 10000 // 10 Seconds
const CHANNEL_NAME = 'draft-conflict'

const SessionDraftConflictContext = createContext<SessionDraftConflictContextValues | undefined>(undefined)

/**  I explored various ways of handling this and treating the channel as a singleton was the best way to ensure that the channel is closed when not needed, plays nicely with HMR, and simplifies hook deps */
let broadcastChannel: BroadcastChannel | undefined

function getBroadcastChannel() {
  if (!broadcastChannel) {
    try {
      broadcastChannel = new BroadcastChannel(CHANNEL_NAME)
    } catch (e) {
      // BroadcastChannel not supported on this browser
      // Likely only for Safari 15.3 and below
    }
  }
  return broadcastChannel
}

function closeBroadcastChannel() {
  if (broadcastChannel) {
    broadcastChannel.close()
    broadcastChannel = undefined
  }
}

export function SessionDraftConflictContextProvider({ children }: { children: ReactNode }) {
  const channel = getBroadcastChannel()
  const [otherActiveTabs, setOtherActiveTabs] = useState<Map<string, ActiveTab>>(new Map())

  useEffect(() => {
    return () => closeBroadcastChannel()
  }, [])

  useEffect(() => {
    if (!channel) {
      console.warn('BroadcastChannel is not supported on this browser')
      return
    }

    const handleMessage = (e: MessageEvent<DraftConflictMessage>) => {
      const message = e.data

      if (message.type === 'open-tab') {
        setOtherActiveTabs(prev => {
          const newMap = new Map(prev)
          newMap.set(message.id, { path: message.path, lastPing: moment(), appointmentId: message.appointmentId })
          return newMap
        })
      } else if (message.type === 'closed-tab') {
        setOtherActiveTabs(prev => {
          const newMap = new Map(prev)
          newMap.delete(message.id)
          return newMap
        })
      }
    }

    channel.addEventListener('message', handleMessage)

    return () => {
      channel.removeEventListener('message', handleMessage)
    }
  }, [channel]) // TODO: There's a potential non-critical bug here https://linear.app/tavahealth/issue/ENG-6918/session-conflict-broadcast-channel-listener-bug

  // purges any tabs that haven't pinged in over 10 seconds. Ideally the components clean themselves up, but in case they don't, this will catch inactive tabs
  useEffect(() => {
    const interval = setInterval(() => {
      setOtherActiveTabs(prev => {
        const newMap = new Map(prev)
        const now = moment()

        newMap.forEach((tab, id) => {
          if (now.diff(tab.lastPing) > TIMEOUT) {
            newMap.delete(id)
          }
        })

        return newMap
      })
    }, TIMEOUT)

    return () => clearInterval(interval)
  }, [])

  /**
   *
   * @param id result of useUuid(). Unique to each instance of the component since multiple of the same tabs could be open in one browser
   * @param path path of the page where the component is being rendered (mostly for debugging purposes)
   */
  const notifyTabActive = useCallback(({ id, path, appointmentId }: Omit<OpenTabMessage, 'type'>) => {
    const channel = getBroadcastChannel()
    if (!channel) return // BroadcastChannel not supported

    const message: OpenTabMessage = { type: 'open-tab', id, path, appointmentId }
    try {
      channel.postMessage(message)
    } catch (e) {
      console.error('Error posting message', e)
    }
  }, [])

  const notifyTabClosed = useCallback(({ id }: Omit<ClosedTabMessage, 'type'>) => {
    const channel = getBroadcastChannel()
    if (!channel) return // BroadcastChannel not supported

    const message: ClosedTabMessage = { type: 'closed-tab', id }
    try {
      channel.postMessage(message)
    } catch (e) {
      console.error('Error posting message', e)
    }
  }, [])

  const getAppointmentActiveTabs = useCallback(
    (appointmentId: string) => {
      return Array.from(otherActiveTabs.values()).filter(tab => tab.appointmentId === appointmentId)
    },
    [otherActiveTabs],
  )

  const hasConflictingTabs = useCallback(
    (appointmentId: string) => getAppointmentActiveTabs(appointmentId).length > 0,
    [getAppointmentActiveTabs],
  )

  return (
    <SessionDraftConflictContext.Provider
      value={{ notifyTabActive, notifyTabClosed, getAppointmentActiveTabs, hasConflictingTabs }}
    >
      {children}
    </SessionDraftConflictContext.Provider>
  )
}

export function useSessionDraftConflictContext() {
  const context = useContext(SessionDraftConflictContext)
  if (!context) {
    throw new Error('useSessionNoteDraftConflictContext must be used within a SessionNoteDraftConflictContextProvider')
  }
  return context
}
