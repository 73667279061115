import { uniqueId } from 'lodash'
import { useRef, useState } from 'react'

import { DayAndTimeInput } from '@nuna/api'
import { useIsAdmin } from '@nuna/auth'
import { useTimeZoneContext } from '@nuna/common'
import { availabilityService } from '@nuna/core'
import { ConfirmRef, ContextualAlert } from '@nuna/tunic'

import { useProviderLocationsHaveAvailability } from '../../../hooks/useProviderLocationsHaveAvailability'
import { AvailabilityCalendar } from '../../AvailabilityCalendar'
import { useAvailabilityContext } from '../context/AvailabilityContextProvider'
import { ProviderAvailabilityProps } from '../types'
import { AvailabilityTimezoneDisplay } from './AvailabilityTimezoneDisplay'
import { MisMatchTimeZoneConfirm } from './MismatchTimeZoneConfirm'
import { Container, DescriptionCaption } from './ProviderAvailabilitySharedStyles'

const { getAvailabilityConstraintInput } = availabilityService

const getUniqueKey = () => uniqueId('availability-calendar')

export function ProviderRegularTimeCalendar({ provider }: ProviderAvailabilityProps) {
  const [calendarKey, setCalendarKey] = useState(getUniqueKey)
  const [showInPersonAlert, setShowInPersonAlert] = useState(true)
  const isAdmin = useIsAdmin()
  const hasInPersonAvailability = useProviderLocationsHaveAvailability(provider.id)
  const confirmRef = useRef<ConfirmRef>(null)
  const { timeZoneToUse } = useTimeZoneContext()
  const { deleteConstraints, updateConstraints, timezone } = useAvailabilityContext()

  const { allowDayAndTime } = provider.availabilityPeriods

  const makeUpdate = (dayAndTimes: DayAndTimeInput[]) => {
    const availabilityConstraintsInput = getAvailabilityConstraintInput({
      availabilityConstraints: { allowDayAndTime: dayAndTimes },
      isAdmin,
      providerTimeZone: timezone,
      customTimeZone: timeZoneToUse,
    })
    updateConstraints(availabilityConstraintsInput)
  }

  const handleChange = (dayAndTimes: DayAndTimeInput[]) => {
    if (isAdmin && timeZoneToUse && timeZoneToUse !== timezone) {
      confirmRef.current?.open(response => {
        if (response) {
          makeUpdate(dayAndTimes)
        } else {
          // force the calendar to re-render, thus discarding changes
          setCalendarKey(getUniqueKey)
        }
      })
      return
    }
    makeUpdate(dayAndTimes)
  }

  const handleDelete = (periodsToDelete: DayAndTimeInput[]) => {
    deleteConstraints(periodsToDelete.flatMap(period => (period.id ? [period.id] : [])))
  }

  return (
    <>
      <Container>
        <DescriptionCaption>
          <span>
            Click and drag to add days and times for clients to book with you. Then click to edit your location. You can
            add multiple openings for each day.
          </span>
          <AvailabilityTimezoneDisplay />
          {!hasInPersonAvailability && showInPersonAlert && (
            <ContextualAlert
              intent="information"
              dismissButtonText="Dismiss"
              className="mt-1"
              onDismiss={() => setShowInPersonAlert(false)}
            >
              {isAdmin ? `The provider has` : 'You have'} an address that has no availability set. Select a time and set
              a location so clients can book in-person.
            </ContextualAlert>
          )}
        </DescriptionCaption>
      </Container>
      <AvailabilityCalendar
        sideBorders={false}
        availabilityPeriods={allowDayAndTime}
        onChange={handleChange}
        onDelete={handleDelete}
        timeZone={timezone}
        key={calendarKey}
      />
      <MisMatchTimeZoneConfirm ref={confirmRef} />
    </>
  )
}
