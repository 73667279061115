import { css } from '@mui/material'

import { body1, body2, error, interactiveFill, interactiveText, primary } from '../colors'
import { primaryColorVars } from './primaryColorCSSVars'

export const cssVars = css`
  :root {
    --border-radius: 12px;
    --border-radius-sm: 6px;

    --primary-color: ${primary};
    --error-color: ${error};
    --interactive-fill-color: ${interactiveFill};
    --interactive-text-color: ${interactiveText};

    --body-color: ${body1};
    --body-secondary-color: ${body2};
  }

  ${primaryColorVars}
`
