import { startCase } from 'lodash'

import { ProviderDirectoryStatus, ProviderDirectoryType } from '@nuna/api'

export const formatType = (directoryType: ProviderDirectoryType | ProviderDirectoryStatus) => {
  return startCase(directoryType.toLowerCase())
}

export const RECOMMENDED_DIRECTORIES = {
  [ProviderDirectoryType.Zocdoc]: {
    id: 'zocdoc',
    name: 'Zocdoc',
    directoryType: ProviderDirectoryType.Zocdoc,
    url: 'https://www.zocdoc.com/',
    linkText: 'Zocdoc.com',
  },
  [ProviderDirectoryType.PsychologyToday]: {
    id: 'psychtoday',
    name: 'Psychology Today',
    directoryType: ProviderDirectoryType.PsychologyToday,
    url: 'https://www.psychologytoday.com/',
    linkText: 'Psychtoday.com',
  },
}
