import { styled } from '@mui/material'
import { HTMLAttributes, useEffect } from 'react'

import { Provider, useCancelPolicyStatusQuery } from '@nuna/api'
import { cancelPolicyService, errorService, numberService } from '@nuna/core'
import { CoverageList, CoverageListItem, CoverageSectionHeading, SidebarPanel } from '@nuna/coverage'
import {
  ContextualAlert,
  IconCancelAppointment,
  IconDollarSymbol,
  IconNoShow,
  PersistentAlert,
  Skeleton,
  StatusLabel,
  fontSize,
  toast,
} from '@nuna/tunic'

type Props = HTMLAttributes<HTMLDivElement> & {
  clientId: string
} & (
    | {
        audience: 'admin'
        provider: Pick<Provider, 'id' | 'firstName'>
      }
    | {
        audience?: 'provider'
        providerId: string
      }
  )

export function PolicyPanel({ clientId, ...props }: Props) {
  const providerId = props.audience === 'admin' ? props.provider.id : props.providerId

  const { data, error } = useCancelPolicyStatusQuery({
    variables: { providerId, clientId, defaultToTavaPolicy: true },
  })

  useEffect(() => {
    if (error) {
      console.error(error)
      toast.urgent(errorService.transformGraphQlError(error, 'Error loading policy'))
    }
  }, [error])

  const cancelPolicyStatus = data?.cancelPolicyStatus

  const policyType = (() => {
    if (!cancelPolicyStatus?.cancelPolicy && !cancelPolicyStatus?.availableCancelPolicy) {
      return 'none'
    }

    if (cancelPolicyService.isLegacy(cancelPolicyStatus?.cancelPolicy) && !cancelPolicyStatus?.availableCancelPolicy) {
      return 'tava'
    }

    return 'provider'
  })()

  return (
    <SidebarPanel {...props}>
      <h2 className="h6 mb-3">{props.audience === 'admin' ? `${props.provider.firstName}'s ` : ''}Policy</h2>
      {!cancelPolicyStatus ? (
        <Skeleton height={3} />
      ) : (
        <>
          <section>
            <CoverageSectionHeading>Status</CoverageSectionHeading>

            <StatusList>
              <div>
                <dt>Policy:</dt>
                <dd>
                  <StatusLabel>
                    {policyType === 'tava' ? 'Tava' : policyType === 'none' ? 'No' : 'Provider'} Policy
                  </StatusLabel>
                </dd>
              </div>

              <div>
                <dt>Acceptance:</dt>
                <dd>
                  {(() => {
                    if (policyType === 'tava' || policyType === 'none') {
                      return (
                        <PersistentAlert small intent="information">
                          Not applicable
                        </PersistentAlert>
                      )
                    } else if (
                      cancelPolicyStatus.availableCancelPolicy &&
                      cancelPolicyService.isLegacy(cancelPolicyStatus.cancelPolicy)
                    ) {
                      return (
                        <PersistentAlert small intent="urgent">
                          No
                        </PersistentAlert>
                      )
                    } else if (cancelPolicyStatus.availableCancelPolicy) {
                      return (
                        <PersistentAlert small intent="caution">
                          Former policy
                        </PersistentAlert>
                      )
                    }

                    return (
                      <PersistentAlert small intent="success">
                        Latest policy
                      </PersistentAlert>
                    )
                  })()}
                </dd>
              </div>

              <div>
                <dt>Card on file:</dt>
                <dd>
                  <PersistentAlert small intent={cancelPolicyStatus.hasCardOnFile ? 'success' : 'urgent'}>
                    {cancelPolicyStatus.hasCardOnFile ? 'Yes' : 'No'}
                  </PersistentAlert>
                </dd>
              </div>
            </StatusList>
          </section>

          {policyType !== 'none' && (
            <section>
              <CoverageSectionHeading>Payout Details</CoverageSectionHeading>
              <CoverageList className="mb-0">
                {cancelPolicyService.isLegacy(cancelPolicyStatus.cancelPolicy) &&
                  cancelPolicyStatus.availableCancelPolicy && (
                    <ContextualAlert className="mb-2">
                      Until the client accepts the provider policy, payout amounts will not be collected.
                    </ContextualAlert>
                  )}
                <CoverageListItem adornment={<IconCancelAppointment isHovered={false} />}>
                  {numberService.centsToFormattedDollars(
                    cancelPolicyStatus.cancelPolicy?.reimbursedAmount ?? undefined,
                    { maximumFractionDigits: 2 },
                  )}{' '}
                  for late cancelation or late reschedule (&lt;{' '}
                  {numberService.minutesToFormattedHours(cancelPolicyStatus.cancelPolicy?.cancelPeriodMinutes ?? 0, {
                    hourFormat: 'hr',
                  })}
                  )
                </CoverageListItem>
                <CoverageListItem adornment={<IconNoShow isHovered={false} />}>
                  {numberService.centsToFormattedDollars(
                    cancelPolicyStatus.cancelPolicy?.noShowReimbursedAmount ?? undefined,
                    { maximumFractionDigits: 2 },
                  )}{' '}
                  for not showing up
                </CoverageListItem>
                {!cancelPolicyService.isLegacy(cancelPolicyStatus.cancelPolicy) && (
                  <CoverageListItem style={{ marginBottom: 0 }} adornment={<IconDollarSymbol size={16} />}>
                    {'Charged to client'}
                  </CoverageListItem>
                )}
              </CoverageList>
            </section>
          )}
        </>
      )}
    </SidebarPanel>
  )
}

const StatusList = styled('dl')`
  > div {
    align-items: center;
    display: flex;
    margin-top: var(--spacing-1);

    dt {
      font-size: ${fontSize.caption};
    }

    dd {
      margin-left: auto;
    }
  }
`
