import { styled } from '@mui/material'
import { HTMLAttributes } from 'react'

import { supportService } from '@nuna/telemetry'
import { Card, GhostButtonExternalLink, OutlineButtonLink, makeTypographyComponent } from '@nuna/tunic'

import number1Src from './img/Number_1.png'
import number2Src from './img/Number_2.png'
import number3Src from './img/Number_3.png'

export function Instructions() {
  return (
    <Card depth={-1} className="p-4">
      <Header>Collect a fee for late cancelation and no shows, or waive fees if the heart strings tug. </Header>
      <InfoList>
        <ListItem number={1}>Set up your policy</ListItem>
        <ListItem number={2}>Clients will be asked to accept the policy the next time they book</ListItem>
        <ListItem number={3}>
          If the client cancels within 24 hours of your session, we'll charge their card on file
        </ListItem>
      </InfoList>
      <div className="v-align">
        <OutlineButtonLink data-testid="policy-instructions-setup" to="./new">
          Set Up My Policy
        </OutlineButtonLink>
        <GhostButtonExternalLink href={supportService.articles.cancellationPolicies} className="ml-2">
          Learn More
        </GhostButtonExternalLink>
      </div>
    </Card>
  )
}

const numberImages = [number1Src, number2Src, number3Src]

interface ListItemProps extends HTMLAttributes<HTMLSpanElement> {
  number: 1 | 2 | 3
}
function ListItem({ number, children, ...props }: ListItemProps) {
  return (
    <li {...props}>
      <span className="number-image-wrapper">
        <img src={numberImages[number - 1]} alt={`Number ${number}`} />
      </span>
      <span className="content">{children}</span>
    </li>
  )
}

const Header = makeTypographyComponent('h4 sans-serif display text-default', 'div')

const InfoList = styled('ul')`
  list-style: none;
  padding-left: var(--spacing-2);
  margin-bottom: var(--spacing-3);
  margin-top: var(--spacing-3);
  li {
    align-items: center;
    display: flex;
    padding-bottom: var(--spacing-2);
    .number-image-wrapper {
      width: 46px;
      display: flex;
      justify-content: flex-start;
      img {
        margin-right: var(--spacing-1);
      }
    }
    .content {
      flex: 1;
    }
  }
`
