import { styled } from '@mui/material'
import {
  Autocomplete,
  AutocompleteProps,
  AutocompleteRenderGroupParams,
  ListSubheader,
  TextField as MUITextField,
} from '@mui/material'
import { noop, omit, sortBy } from 'lodash'
import { HTMLAttributes, ReactNode, forwardRef, useCallback, useMemo } from 'react'

import { Diagnosis, PaymentPreference, useAvailableDiagnosesQuery } from '@nuna/api'
import {
  Checkbox,
  Chip,
  ContextualAlert,
  IconButton,
  IconCaution,
  IconClose,
  borderGrey,
  greySet,
  tealSet,
} from '@nuna/tunic'

interface Props
  extends Pick<
    AutocompleteProps<Diagnosis, true, false, false>,
    'onChange' | 'style' | 'className' | 'onBlur' | 'disabled'
  > {
  onRemove: (diagnosisId: string) => void
  value: Diagnosis[]
  error: boolean
  helperText?: string | ReactNode
  listBoxTopSlot?: ReactNode
  coverageType?: PaymentPreference | null
  max?: number
}

const DiagnosesListBox = forwardRef<HTMLUListElement, HTMLAttributes<HTMLUListElement>>(
  ({ children, ...props }, ref) => {
    return (
      <StyledListBox role="listbox" ref={ref} {...props}>
        {children}
      </StyledListBox>
    )
  },
)

export function DiagnosesSelect({
  disabled = false,
  helperText,
  value,
  onRemove,
  error,
  className,
  coverageType,
  max,
  ...props
}: Props) {
  const { data, loading } = useAvailableDiagnosesQuery({
    fetchPolicy: 'cache-first',
  })

  const options = useMemo(() => {
    if (!data?.availableDiagnoses) {
      return []
    }
    return sortBy(
      sortBy(data.availableDiagnoses, diagnosis => diagnosis.label),
      diagnosis => diagnosis.diagnosisLevel,
    )
  }, [data])

  const optionDisabled = useCallback(
    (option: Diagnosis) => {
      if (value.find(d => d.id === option.id)) {
        return false
      }

      if (!max) {
        return false
      }

      return max <= value.length
    },
    [value, max],
  )

  return (
    <DiagnosisInput className={className}>
      <Autocomplete
        multiple
        loading={loading}
        noOptionsText="No results found"
        disabled={disabled}
        className="fs-mask"
        disableCloseOnSelect
        isOptionEqualToValue={(option, value) => option.id === value.id}
        options={options}
        getOptionDisabled={optionDisabled}
        getOptionLabel={diagnosis => diagnosis.label}
        value={value}
        forcePopupIcon={false}
        slots={{ listbox: DiagnosesListBox }}
        groupBy={option => option.diagnosisLevel ?? ''}
        renderGroup={params =>
          renderDiagnosesGroup(
            params,
            coverageType,
            max && value.length >= max && (
              <ContextualAlert
                style={{ width: '100%', marginTop: 'var(--spacing-1)' }}
                icon={<IconCaution />}
                intent="caution"
              >
                A maximum of {max} diagnoses is allowed. Deselect options below to make changes.
              </ContextualAlert>
            ),
          )
        }
        renderOption={(renderOptionProps, option, { selected }) => (
          <li {...renderOptionProps} key={option.id}>
            <Checkbox style={{ pointerEvents: 'none' }} checked={selected} onChange={noop}>
              {option.label}
            </Checkbox>
          </li>
        )}
        renderTags={(diagnoses, getTagProps) => {
          return diagnoses.map((diagnosis, index) => (
            <Chip
              truncateAt={200}
              small
              {...omit(getTagProps({ index }), ['onDelete'])}
              onRemove={disabled ? undefined : () => onRemove(diagnosis.id)}
              key={diagnosis.id}
            >
              {diagnosis.label}
            </Chip>
          ))
        }}
        renderInput={({ InputProps, ...params }) => {
          return (
            <MUITextField
              {...params}
              disabled={disabled}
              placeholder={disabled ? '' : 'Begin typing to add diagnoses'}
              error={error}
              helperText={helperText}
              slotProps={{
                input: {
                  ...InputProps,
                  endAdornment: !disabled && (
                    <div className="MuiAutocomplete-endAdornment">
                      <div className="v-align" style={{ transform: 'translate(0.875rem, -0.5rem)' }}>
                        <IconButton
                          tooltip="Clear diagnoses"
                          type="button"
                          variant="secondary"
                          small
                          onClick={event => props.onChange && props.onChange(event, [], 'clear')}
                        >
                          <IconClose size={16} />
                        </IconButton>
                      </div>
                    </div>
                  ),
                },
              }}
            />
          )
        }}
        {...props}
      />
    </DiagnosisInput>
  )
}

function renderDiagnosesGroup(
  params: AutocompleteRenderGroupParams,
  coverageType?: PaymentPreference | null,
  maxAlert?: ReactNode,
) {
  const required = coverageType === PaymentPreference.Insurance && params.group === 'PRIMARY'
  return (
    <StyledGroupItem key={params.key}>
      <ListSubheader className="MuiAutocomplete-groupLabel" component="div">
        {maxAlert}
        <div className="space-between" style={maxAlert ? { marginTop: '-12px' } : undefined}>
          <span className="text-secondary">
            {params.group === 'PRIMARY' ? 'Medically Necessary Diagnoses' : 'Other Informative Diagnoses'}
          </span>
          {required && <span className="text-medium-grey">Select at least one</span>}
        </div>
      </ListSubheader>

      <ul className="MuiAutocomplete-groupUl">{params.children}</ul>
    </StyledGroupItem>
  )
}

const DiagnosisInput = styled('div')`
  .MuiInputBase-root {
    min-height: 44px;
  }

  .MuiAutocomplete-hasClearIcon .MuiAutocomplete-inputRoot {
    padding-right: 80px;
  }
`

const StyledListBox = styled('ul')`
  &.MuiAutocomplete-listbox {
    padding-top: 0;
    padding-left: 8px;
    padding-right: var(--margin-1);
  }
`

const StyledGroupItem = styled('li')`
  .MuiAutocomplete-groupLabel {
    border-bottom: 1px solid ${borderGrey};
  }

  .MuiListSubheader-gutters {
    padding-left: var(--margin-1);
    padding-right: var(--margin-1);
  }

  .MuiAutocomplete-groupUl {
    padding-left: 0;

    .MuiAutocomplete-option {
      padding: var(--margin-1);
      border-radius: 4px;
    }
    .MuiAutocomplete-option[aria-selected='true'] {
      background-color: ${tealSet.tint[20]};
    }
    .MuiAutocomplete-option[data-focus='true'] {
      background-color: ${greySet[30].hex};
    }
  }
`
