import { styled } from '@mui/material'
import { isNil } from 'lodash'
import { useEffect, useState } from 'react'

import { useProviderCaseloadQuery } from '@nuna/api'

import { AcceptingSwitch } from './AcceptingSwitch'
import { AdminLockSwitch } from './AdminLockSwitch'
import { Survey } from './Survey'

type Props = {
  providerId?: string | null
}

export function TavaReferralsToggle({ providerId }: Props) {
  const { data: providerCaseload } = useProviderCaseloadQuery({
    variables: { providerId: providerId ?? '' },
    skip: isNil(providerId),
  })

  const { acceptsReferrals, eapReferralsLocked } = providerCaseload?.provider ?? {}

  const [initialReferralState, setInitialReferralState] = useState<boolean | undefined>(false)

  useEffect(() => {
    eapReferralsLocked && setInitialReferralState(false)
    acceptsReferrals && setInitialReferralState(acceptsReferrals)
  }, [acceptsReferrals, eapReferralsLocked])

  return (
    <>
      <StyledSwitchColumn>
        <AcceptingSwitch providerId={providerId} />
        <AdminLockSwitch providerId={providerId} />
      </StyledSwitchColumn>
      <Survey providerId={providerId} initialReferralState={initialReferralState} />
    </>
  )
}

const StyledSwitchColumn = styled('div')`
  display: flex;
  flex-direction: column;
  gap: 1rem;
`
