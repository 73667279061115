export interface FeatureFlags {
  addressEnrollments: boolean
  adminFees: boolean
  adminFinancials: boolean
  appointmentDrawers: boolean
  assessmentAutomation: boolean
  assessments: boolean
  assessmentsIntake: boolean
  autoCharge: boolean
  autoNotes: boolean
  bringYourOwnClient: boolean
  byocInsurance: boolean
  caqhModule: boolean
  cbip: boolean
  clientCoveragePanels: boolean
  clientInvite: boolean
  credentialing: boolean
  customRates: boolean
  dailyVideo: boolean
  existingClientsCbip: boolean
  inPersonLocations: boolean
  intakeRevamp: boolean
  lateCancelFee: boolean
  manageSessions: boolean
  mentalHealthLibrary: boolean
  multipleCancelPolicies: boolean
  nylasV3: boolean
  patientBilling: boolean
  payerRosters: boolean
  providerDirectories: boolean
  providerPayouts: boolean
  publicProviderProfileAvailability: boolean
  publicProviderProfileQuery: boolean
  requestDbPaymentMethods: boolean
  serverMaintenance: boolean
  smsApptInstantCanceledByPatientToProvider: boolean
  smsApptInstantCanceledByProviderToPatient: boolean
  smsApptReminderPatientDayBefore: boolean
  smsApptReminderPatientFifteenMinute: boolean
  smsApptReminderProviderFifteenMinute: boolean
  smsApptStartingPatient: boolean
  smsCreateBookingAsPatientPatientConfirmation: boolean
  smsCreateBookingAsPatientProviderConfirmation: boolean
  smsCreateBookingAsProviderPatientConfirmation: boolean
  smsSendAppointmentRescheduledToPatient: boolean
  smsSendAppointmentRescheduledToProvider: boolean
  sundanceNoteDraft: boolean
  sundanceScreenSharing: boolean
  switchProviderFiiltering: boolean
  throttleVideoBitrate: boolean
}
export function buildDefaultFlags(): FeatureFlags {
  return {
    addressEnrollments: true,
    adminFees: false,
    adminFinancials: false,
    appointmentDrawers: true,
    assessmentAutomation: true,
    assessments: true,
    assessmentsIntake: true,
    autoCharge: true,
    autoNotes: true,
    bringYourOwnClient: true,
    byocInsurance: true,
    caqhModule: true,
    cbip: true,
    clientCoveragePanels: true,
    clientInvite: true,
    credentialing: false,
    customRates: true,
    dailyVideo: false,
    existingClientsCbip: false,
    inPersonLocations: true,
    intakeRevamp: true,
    lateCancelFee: true,
    manageSessions: false,
    mentalHealthLibrary: true,
    multipleCancelPolicies: true,
    nylasV3: true,
    patientBilling: true,
    payerRosters: false,
    providerDirectories: false,
    providerPayouts: true,
    publicProviderProfileAvailability: true,
    publicProviderProfileQuery: true,
    requestDbPaymentMethods: true,
    serverMaintenance: false,
    smsApptInstantCanceledByPatientToProvider: true,
    smsApptInstantCanceledByProviderToPatient: true,
    smsApptReminderPatientDayBefore: true,
    smsApptReminderPatientFifteenMinute: true,
    smsApptReminderProviderFifteenMinute: true,
    smsApptStartingPatient: true,
    smsCreateBookingAsPatientPatientConfirmation: true,
    smsCreateBookingAsPatientProviderConfirmation: true,
    smsCreateBookingAsProviderPatientConfirmation: true,
    smsSendAppointmentRescheduledToPatient: true,
    smsSendAppointmentRescheduledToProvider: true,
    sundanceNoteDraft: true,
    sundanceScreenSharing: true,
    switchProviderFiiltering: true,
    throttleVideoBitrate: false,
  }
}
