import { IconProps } from './IconProps'

export function IconTherapist({ size = 24, color = 'currentColor', ...props }: IconProps) {
  return (
    <svg width={size} height={size} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0.333984 4.33337C0.333984 2.12423 2.12485 0.333374 4.33398 0.333374H11.6673C13.8765 0.333374 15.6673 2.12423 15.6673 4.33337V11.6667C15.6673 13.8758 13.8765 15.6667 11.6673 15.6667H4.33398C2.12484 15.6667 0.333984 13.8758 0.333984 11.6667V4.33337ZM6.39651 3.32354C6.39651 2.95535 6.69499 2.65688 7.06318 2.65688H8.9382C9.30639 2.65688 9.60487 2.95535 9.60487 3.32354V6.39996H12.6813C13.0495 6.39996 13.3479 6.69844 13.3479 7.06663V8.94165C13.3479 9.30984 13.0495 9.60832 12.6813 9.60832H9.60487V12.6847C9.60487 13.0529 9.30639 13.3514 8.9382 13.3514H7.06318C6.69499 13.3514 6.39651 13.0529 6.39651 12.6847V9.60832H3.32008C2.95189 9.60832 2.65341 9.30984 2.65341 8.94165L2.65341 7.06663C2.65341 6.69844 2.95189 6.39996 3.32008 6.39996H6.39651V3.32354Z"
        fill={color}
      />
    </svg>
  )
}
