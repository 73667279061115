import { styled } from '@mui/material'
import { HTMLAttributes, ReactNode, useState } from 'react'

import { Link, LinkProps } from '@nuna/common'
import {
  Card,
  CardBody,
  Collapse,
  Grid,
  IconButton,
  IconChevronThick,
  borderGrey,
  csx,
  fontSize,
  greySet,
  plumSet,
  shadowDepth,
  tealSet,
} from '@nuna/tunic'

export const Container = styled('div')`
  border-bottom: 1px solid ${borderGrey};
  padding-bottom: var(--margin-2);

  & + & {
    padding-top: var(--margin-2);
  }
`

export const Heading = styled(Grid)`
  cursor: pointer;
  align-items: center;
`

export const SubHeading = styled('h3')`
  color: ${greySet[70].hex};
  font-size: ${fontSize.body};
  font-weight: 500;
  margin-top: 1.125rem;
  margin-bottom: 0.5rem;
`

export const SectionHeading = styled('h2')`
  color: ${greySet[80].hex};
  font-size: ${fontSize.h6};
  font-weight: 500;
  margin: 0;
`

interface PayrollSummaryCollapsableSectionProps extends HTMLAttributes<HTMLDivElement> {
  headerText: string
}
export function PayrollSummaryCollapsableSection({ headerText, children }: PayrollSummaryCollapsableSectionProps) {
  const [isOpen, setIsOpen] = useState(true)

  return (
    <Container>
      <Heading container onClick={() => setIsOpen(!isOpen)}>
        <Grid size={10}>
          <SectionHeading>{headerText}</SectionHeading>
        </Grid>
        <Grid className="text-right" size={2}>
          <IconButton variant="secondary" small tooltip={isOpen ? 'Collapse' : 'Expand'}>
            <IconChevronThick size={14} color={greySet[50].hex} direction={isOpen ? 'up' : 'down'} />
          </IconButton>
        </Grid>
      </Heading>
      <Collapse isOpen={isOpen}>{children}</Collapse>
    </Container>
  )
}

export function RateDetailGrid({ className, ...props }: HTMLAttributes<HTMLDivElement>) {
  return <div className={csx([className, 'text-light text-secondary flex-column gap-1'])} {...props}></div>
}

interface RateDetailProps {
  value: ReactNode
  description: ReactNode
}
export function RateDetail({ value, description }: RateDetailProps) {
  return (
    <div className="top-align">
      <div>{description}</div>
      <div className="text-right flex-remaining-space text-medium">{value}</div>
    </div>
  )
}

const ctaBannerColors = {
  bodyBackground: {
    plum: plumSet[15].hex,
    teal: tealSet[15].hex,
  },
  bodyFont: {
    plum: plumSet[90].hex,
    teal: tealSet[80].hex,
  },
  bootBackground: {
    plum: plumSet[50].hex,
    teal: tealSet[70].hex,
  },
  bootBackgroundHover: {
    plum: plumSet[50].hover,
    teal: tealSet[70].hover,
  },
}
type PayoutCTABannerVariant = 'plum' | 'teal'
const StyledPayoutCTABanner = styled(Link)<{ variant: PayoutCTABannerVariant }>`
  display: block;
  padding-top: 2px;
  transition: transform 0.3s;
  .card {
    overflow: hidden;
    font-size: 15px;
    transition: box-shadow 0.3s;
  }
  .card-body {
    background-color: ${({ variant }) => ctaBannerColors.bodyBackground[variant]};
    color: ${({ variant }) => ctaBannerColors.bodyFont[variant]};
  }

  .boot {
    background-color: ${({ variant }) => ctaBannerColors.bootBackground[variant]};
    color: #fff;
    padding: 10px var(--spacing-2);
    transition: background-color 0.3s;
  }

  &:hover {
    transform: translateY(-2px);
    .card {
      box-shadow: ${shadowDepth(1.5)};
    }

    .boot {
      background-color: ${({ variant }) => ctaBannerColors.bootBackgroundHover[variant]};
    }
  }
`

interface PayoutCTABannerProps extends LinkProps {
  children: ReactNode
  boot: ReactNode
  variant: PayoutCTABannerVariant
  isPopOut?: boolean
}

export function PayoutCTABanner({ children, boot, isPopOut, ...props }: PayoutCTABannerProps) {
  return (
    <StyledPayoutCTABanner {...props}>
      <Card className="card">
        <CardBody className={`card-body text-medium ${isPopOut ? 'py-1' : ''}`}>{children}</CardBody>
        <div className="boot text-bold">{boot}</div>
      </Card>
    </StyledPayoutCTABanner>
  )
}
