import { keyframes } from '@mui/material'
import { styled } from '@mui/material'
import { MenuItem } from '@mui/material'
import { FormikProps } from 'formik'
import moment from 'moment'
import { Moment } from 'moment-timezone'
import React, { ReactNode, useEffect, useState } from 'react'

import {
  type CancelPolicyStatusFragment,
  type DrawerAppointmentFragment,
  TimeSlotType,
  useAppointmentInfoForDateLazyQuery,
} from '@nuna/api'
import { AppointmentLocationSelect, AppointmentLocationSelectValue, AppointmentTimeSelect } from '@nuna/appointment'
import { Audience } from '@nuna/core'
import {
  DatePicker,
  IconLoading,
  IconReply,
  body1,
  greySet,
  interactiveFill,
  interactiveText,
  tealSet,
} from '@nuna/tunic'

import { RescheduleAppointmentCard } from './RescheduleAppointmentCard'

interface FormProps {
  newAddressId?: string | null
  initialAddressId?: string | null
}

interface AppointmentTimeFormProps {
  containerProps?: React.HTMLAttributes<HTMLDivElement>
  appointment: DrawerAppointmentFragment
  cancelPolicyStatus: CancelPolicyStatusFragment
  onChange: (startTime: string) => void
  value: string
  providerId: string
  canChargeLateFee: boolean
  audience: Audience
  error?: boolean
  helperText?: string | ReactNode
  formProps: Pick<FormikProps<FormProps>, 'values' | 'setFieldValue' | 'setFieldTouched' | 'errors' | 'initialValues'> // we unfortunately have to pass this around because a hook as used instead of formik with context
  // TODO: look into refactoring and using formik context instead
}

export function NewAppointmentForm({
  appointment,
  cancelPolicyStatus,
  onChange,
  value,
  containerProps,
  providerId,
  audience,
  canChargeLateFee,
  error = false,
  helperText,
  formProps,
}: AppointmentTimeFormProps) {
  const { patient } = appointment
  const [oldStart] = useState(() => (appointment.startDatetime ? moment(appointment.startDatetime) : null))
  const [date, setDate] = useState<Moment | null>(() => {
    if (value) {
      return moment(value)
    }

    if (appointment.startDatetime) {
      return moment(appointment.startDatetime)
    }

    return null
  })
  const { values, setFieldValue, setFieldTouched, errors } = formProps

  const [queryAvailability, { data: availabilityData, loading: isLoadingTimes }] = useAppointmentInfoForDateLazyQuery({
    fetchPolicy: 'network-only',
  })
  const availableTimes = availabilityData?.appointmentInfoForDate.times ?? []

  useEffect(() => {
    if (date) {
      queryAvailability({
        variables: {
          dateTime: date.isSame(moment(), 'd') ? moment().add(1, 'm') : date,
          providerId,
        },
      })
    }
  }, [date, queryAvailability, providerId])

  useEffect(() => {
    const previousTime = value ? moment(value) : null
    if (date?.isSame(previousTime, 'd')) {
      return
    }

    const updatedTimes = availabilityData?.appointmentInfoForDate.times ?? []

    if (updatedTimes.length > 0) {
      const newInternalTime = updatedTimes.find(({ start, type }) => {
        const slotStart = moment(start)
        const isTaken = type === TimeSlotType.Booked

        if (isTaken && slotStart.isSame(oldStart) && previousTime && previousTime.isSame(oldStart)) {
          return true
        }

        return !isTaken && slotStart.isSameOrAfter(slotStart.clone().hour(previousTime ? previousTime.hour() : 9))
      })

      if (newInternalTime && newInternalTime.start !== value) {
        onChange(newInternalTime.start)
      }
    }
  }, [availabilityData?.appointmentInfoForDate.times, oldStart, value, onChange, date])

  return (
    <div {...containerProps}>
      <RescheduleAppointmentCard
        appointment={appointment}
        cancelPolicyStatus={cancelPolicyStatus}
        audience={audience}
        canChargeLateFee={canChargeLateFee}
        form={
          <StyledForm className="p-2">
            <DatePicker
              containerProps={{ className: 'v-align mb-3' }}
              disablePast
              format="dddd, MMM Do"
              label="Day"
              onChange={setDate}
              value={date}
              data-testid="appointment-date-picker"
            />

            <AppointmentTimeSelect
              containerProps={{ className: 'v-align mb-2' }}
              value={isLoadingTimes ? 'loading' : value}
              onChange={e => onChange(`${e.target.value}`)}
              name="newStart"
              label={`Start time in ${moment.tz(moment.tz.guess()).format('z')} (50 min session)`}
              helperText={helperText}
              error={error}
              fullWidth
              // eslint-disable-next-line @typescript-eslint/no-explicit-any
              renderValue={(value: any) =>
                isLoadingTimes ? <div>Finding times...</div> : <div>{moment(value).format('LT')}</div>
              }
              MenuProps={{
                style: {
                  maxHeight: 350,
                },
              }}
            >
              {(() => {
                if (isLoadingTimes) {
                  return (
                    <MenuItem value="loading">
                      <SpinningLoader size={16} className="mr-1" color={interactiveFill} />{' '}
                      <span className="text-bold">Finding times...</span>
                    </MenuItem>
                  )
                }

                return availableTimes.map(({ start, type }) => {
                  const isTaken = type === TimeSlotType.Booked
                  const isWithinAvailability = type === TimeSlotType.Open
                  const slotStart = moment(start)

                  if (slotStart.isBefore(moment())) {
                    return null
                  }

                  if (
                    oldStart &&
                    slotStart.isBetween(oldStart.clone().subtract('1', 'm'), oldStart.clone().add('50', 'm'))
                  ) {
                    return (
                      <MenuItemStyled key={start} value={start} sameAsAppointment>
                        <CurrentTimeSlot>
                          {slotStart.format('LT')} <span className="ml-auto">Current</span>
                        </CurrentTimeSlot>
                      </MenuItemStyled>
                    )
                  }

                  if (
                    (isTaken && !oldStart) ||
                    (isTaken && oldStart && !slotStart.isBetween(oldStart.clone().subtract('31', 'm'), oldStart))
                  ) {
                    return (
                      <MenuItemStyled key={start} disabled value={start}>
                        {slotStart.format('LT')} <span className="ml-auto">Booked</span>
                      </MenuItemStyled>
                    )
                  }

                  if (isWithinAvailability) {
                    return (
                      <MenuItemStyled key={start} value={start}>
                        {slotStart.format('LT')} <span className="ml-auto">Open</span>
                      </MenuItemStyled>
                    )
                  }

                  return (
                    <MenuItemStyled key={start} value={start} outsideAvailability>
                      {slotStart.format('LT')}
                    </MenuItemStyled>
                  )
                })
              })()}
            </AppointmentTimeSelect>
            {patient && patient.timezone && (
              <div className="v-align mt-1 italic caption text-secondary">
                <IconReply className="mr-1" />
                {moment(value).tz(patient.timezone).format('LT z')} for {patient.firstName}
              </div>
            )}
            <div className="mt-2">
              <AppointmentLocationSelect
                patient={patient}
                providerId={providerId}
                onAddressChange={(selectedAddress: AppointmentLocationSelectValue) => {
                  setFieldValue('newAddressId', selectedAddress.id)
                  setFieldTouched('newAddressId', true)
                }}
                disabled={!date}
                value={values['newAddressId'] ?? values['initialAddressId'] ?? ''}
                error={!!errors.newAddressId}
              />
            </div>
          </StyledForm>
        }
      />
    </div>
  )
}

const MenuItemStyled = styled(MenuItem)<{
  sameAsAppointment?: boolean
  disabled?: boolean
  outsideAvailability?: boolean
}>`
  && {
    color: ${body1};
    font-weight: 500;

    ${props =>
      (props.sameAsAppointment || props.disabled) &&
      `
        font-weight: 400;
      `}

    ${props =>
      props.sameAsAppointment &&
      `
        background: none !important;
        padding: 1px 8px;
      `}

    ${props =>
      props.outsideAvailability &&
      `
        color: ${greySet[50].hex};
      `}
  }
`

const CurrentTimeSlot = styled('span')`
  background-color: ${tealSet.tint[20]};
  border-radius: 4px;
  color: ${interactiveText};
  padding: 5px 8px;
  width: 100%;
  display: inline-flex;
`
const spin = keyframes`
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
`

const SpinningLoader = styled(IconLoading)`
  animation: ${spin} 2s linear infinite;
`

const StyledForm = styled('div')`
  background-color: #fff;
`
