import { DailyVideo } from '@daily-co/daily-react'
import { styled } from '@mui/material'
import { useEffect, useState } from 'react'
import useDimensions from 'react-cool-dimensions'

import { useVideoDimensions } from '../../../hooks/useVideoDimensions'

const MAX_HEIGHT_CROP = 0.91
const MAX_WIDTH_CROP = 0.67

interface Props {
  sessionId: string
}

type Fit = 'cover' | 'contain' | 'scaleX' | 'scaleY'

export function ParticipantVideoSizer({ sessionId }: Props) {
  const [videoElement, setVideoElement] = useState<HTMLVideoElement | null>(null)
  const videoDimensions = useVideoDimensions(videoElement)
  const { observe, width: containerWidth, height: containerHeight } = useDimensions()
  const [fit, setFit] = useState<Fit>('contain')

  useEffect(() => {
    setFit(calculateFit(videoDimensions, { height: containerHeight, width: containerWidth }))
  }, [containerHeight, containerWidth, videoDimensions])

  return (
    <VideoContainer ref={observe} fit={fit}>
      <DailyVideo fit="contain" sessionId={sessionId} type="video" ref={setVideoElement} />
    </VideoContainer>
  )
}

function calculateFit(
  videoDimensions: { width: number; height: number },
  containerDimensions: { width: number; height: number },
) {
  if (videoDimensions.width === 0 || videoDimensions.height === 0) return 'contain'

  const videoHeightRatio = videoDimensions.height / videoDimensions.width
  const containerHeightRatio = containerDimensions.height / containerDimensions.width

  if (videoHeightRatio >= 1) {
    return 'contain'
  }

  if (containerHeightRatio / videoHeightRatio < MAX_HEIGHT_CROP) {
    return 'scaleY'
  }

  if (videoHeightRatio / containerHeightRatio < MAX_WIDTH_CROP) {
    return 'scaleX'
  }

  return 'cover'
}

const VideoContainer = styled('div')<{ fit: Fit }>`
  align-items: center;
  display: flex;
  height: 100%;
  justify-content: center;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;

  video {
    width: 100%;
    height: 100%;
    position: absolute;
    z-index: 10;

    ${({ fit }) => {
      if (fit === 'cover') {
        return `object-fit: cover !important;`
      }

      if (fit === 'contain') {
        return `object-fit: contain !important;`
      }

      // 1 / .91 = 1.10
      if (fit === 'scaleY') {
        return `
          height: 110% !important;
          object-fit: contain !important;
        `
      }

      // 1 / .67 = 1.49
      if (fit === 'scaleX') {
        return `
          width: 149% !important;
          object-fit: contain !important;
        `
      }
    }}
  }
`
