export const sizes = [0, 0.5, 1, 1.5, 2, 3, 5, 8]
export const spacing = 16
const inBetweenBasis = spacing / 2
const inBetweenFactors = [
  { name: 'half', factor: 0.5 },
  { name: 'three-quarter', factor: 0.75 },
]
export const unit = 'px'

export const spacingSizes = sizes.map(size => `${spacing * size}${unit}`)

function generateSpacingCSSVars() {
  return `
    :root {
      // @Deprecated - prefer more generic spacing css var going forward
      ${spacingSizes.map((size, i) => `--margin-${i}: ${size};`).join('\n')}
      ${spacingSizes.map((size, i) => `--spacing-${i}: ${size};`).join('\n')}
      --spacing-1-5: 12px;
      ${inBetweenFactors.map(({ name, factor }) => `--spacing-${name}: ${inBetweenBasis * factor}${unit};`).join('\n')}
    }
  `
}

function generateSpacingUtilities(classPrefix: string, properties: string[]) {
  let cssString = ''

  sizes.forEach((_size, i) => {
    cssString += `
      .${classPrefix}-${i} {
        ${properties.map(property => `${property}: var(--spacing-${i});`).join('\n')}
      }
    `
  })

  cssString += `
    .${classPrefix}-auto {
      ${properties.map(property => `${property}: auto;`).join('\n')}
    }
  `

  return cssString
}

export const spacingUtils = `
  ${generateSpacingCSSVars()}
  ${generateSpacingUtilities('ml', ['margin-left'])}
  ${generateSpacingUtilities('mr', ['margin-right'])}
  ${generateSpacingUtilities('mt', ['margin-top'])}
  ${generateSpacingUtilities('mb', ['margin-bottom'])}
  ${generateSpacingUtilities('my', ['margin-bottom', 'margin-top'])}
  ${generateSpacingUtilities('mx', ['margin-left', 'margin-right'])}
  ${generateSpacingUtilities('m', ['margin'])}
  ${generateSpacingUtilities('pl', ['padding-left'])}
  ${generateSpacingUtilities('pr', ['padding-right'])}
  ${generateSpacingUtilities('pt', ['padding-top'])}
  ${generateSpacingUtilities('pb', ['padding-bottom'])}
  ${generateSpacingUtilities('py', ['padding-bottom', 'padding-top'])}
  ${generateSpacingUtilities('px', ['padding-left', 'padding-right'])}
  ${generateSpacingUtilities('p', ['padding'])}
  ${generateSpacingUtilities('gap', ['gap'])}

  .gap-xs {
    gap: 4px;
  }
  .ml-xs {
    margin-left: 4px;
  }
  .mr-xs {
    margin-right: 4px;
  }
  .mx-xs {
    margin-left: 4px;
    margin-right: 4px;
  }
  .mt-xs {
    margin-top: 4px;
  }
  .mb-xs {
    margin-bottom: 4px;
  }
  .my-xs {
    margin-top: 4px;
    margin-bottom: 4px;
  }

  .p-xs {
    padding: 4px;
  }
  .pl-xs {
    padding-left: 4px;
  }
  .pr-xs {
    padding-right: 4px;
  }
  .px-xs {
    padding-left: 4px;
    padding-right: 4px;
  }
  .pt-xs {
    padding-top: 4px;
  }
  .pb-xs {
    padding-bottom: 4px;
  }
  .py-xs {
    padding-top: 4px;
    padding-bottom: 4px;
  }

  .ml-1-5 {
    margin-left: 12px;
  }
  .mr-1-5{
    margin-right: 12px;
  }
  .mt-1-5 {
    margin-top: 12px;
  }
  .mb-1-5 {
    margin-bottom: 12px;
  }
  .my-1-5 {
    margin-top: 12px;
    margin-bottom: 12px;
  }
  .mx-1-5 {
    margin-left: 12px;
    margin-right: 12px;
  }

  .pl-1-5 {
    padding-left: 12px;
  }
  .pr-1-5{
    padding-right: 12px;
  }
  .pt-1-5 {
    padding-top: 12px;
  }
  .pb-1-5 {
    padding-bottom: 12px;
  }
  .py-1-5 {
    padding-top: 12px;
    padding-bottom: 12px;
  }
  .px-1-5 {
    padding-left: 12px;
    padding-right: 12px;
  }
`
