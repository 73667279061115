import {
  AppointmentChangeReason,
  AppointmentCurrentStatus,
  ConversationMessageStatus,
  Patient,
  Provider,
  RescheduleAppointmentAsPatientMutationVariables,
  RescheduleAppointmentAsProviderMutationVariables,
  useCancelAppointmentAsAdminMutation,
  useCancelAppointmentAsPatientMutation,
  useCancelAppointmentAsProviderMutation,
  useRescheduleAppointmentAsPatientMutation,
  useRescheduleAppointmentAsProviderMutation,
} from '@nuna/api'
import { Audience } from '@nuna/core'
import { useMessageComposerContext } from '@nuna/messaging'

interface Options {
  provider: Pick<Provider, 'id' | 'loginId'>
  patient: Pick<Patient, 'loginId' | 'id'>
  audience: Audience
}

type CancelArgs = {
  appointmentId: string
  reason: AppointmentChangeReason
  reasonData?: string | null
  messageId?: string | null
  appointmentCancelStatus?: AppointmentCurrentStatus | null
  shouldProviderBePaid?: boolean
}

export type RescheduleArgs = (
  | RescheduleAppointmentAsProviderMutationVariables
  | RescheduleAppointmentAsPatientMutationVariables
) & {
  shouldProviderBePaid?: boolean
}

export function useCancelOrReschedule({ provider, patient, audience }: Options) {
  const [rescheduleAsPatient] = useRescheduleAppointmentAsPatientMutation()
  const [rescheduleAsProvider] = useRescheduleAppointmentAsProviderMutation()
  const [cancelAsPatient] = useCancelAppointmentAsPatientMutation()
  const [cancelAsProvider] = useCancelAppointmentAsProviderMutation()
  const [cancelAsAdmin] = useCancelAppointmentAsAdminMutation()
  const { sendMessage } = useMessageComposerContext()

  const reschedule = async ({ shouldProviderBePaid = false, ...variables }: RescheduleArgs) => {
    const message = await sendMessage(patient.loginId ?? '', { status: ConversationMessageStatus.Draft })
    const messageId = message?.id
    if (audience === 'client') {
      return rescheduleAsPatient({ variables: { ...variables, messageId } })
    } else if (audience === 'admin') {
      return rescheduleAsProvider({
        variables: { ...variables, providerId: provider.id, messageId, shouldProviderBePaid },
      })
    } else {
      return rescheduleAsProvider({ variables: { ...variables, messageId, shouldProviderBePaid } })
    }
  }

  const cancel = async ({ shouldProviderBePaid = false, ...variables }: CancelArgs) => {
    const message = await sendMessage(patient.loginId ?? '', { status: ConversationMessageStatus.Draft })
    const messageId = message?.id
    if (audience === 'client') {
      const { appointmentId, ...rest } = variables
      return cancelAsPatient({ variables: { ...rest, id: appointmentId, messageId } })
    } else if (audience === 'admin') {
      return cancelAsAdmin({
        variables: {
          ...variables,
          messageId,
          appointmentCancelStatus: variables.appointmentCancelStatus || AppointmentCurrentStatus.CanceledByAdmin,
          shouldProviderBePaid,
        },
      })
    } else {
      return cancelAsProvider({ variables: { ...variables, messageId, shouldProviderBePaid } })
    }
  }

  return { reschedule, cancel }
}
