import { styled } from '@mui/material'

import { PatientPaymentStatus } from '@nuna/api'
import { ContextualAlert, IconCautionCircle, PersistentAlert, salmonSet, yellowSet } from '@nuna/tunic'

type InputScene = 'clientInfo' | 'clientProfile'

type MessageType = 'caution' | 'urgent'

type Props = {
  scene: InputScene
  paymentStatus?: PatientPaymentStatus | null
  options?: {
    dismissButtonText?: string
  }
}

type AlertMessage = {
  [key in InputScene]: {
    [key in MessageType]?: string
  }
}

export function ProviderClientPaymentStatusAlert({ scene, paymentStatus, options }: Props) {
  if (!paymentStatus) return null

  const alertMessage: AlertMessage = {
    clientProfile: {
      urgent: `This client has an overdue balance. You will not be able to schedule any new appointments with them until they pay their balance. This will not affect your payout.`,
    },
    clientInfo: {
      caution: 'Outstanding balance',
      urgent: 'Outstanding balance',
    },
  }

  const alertState = paymentStatus === PatientPaymentStatus.Overdue ? 'urgent' : 'caution'
  const isUrgent = alertState === 'urgent'
  const iconColor = isUrgent ? salmonSet[80].hex : yellowSet[50].hex
  const urgentIcon = <IconCautionCircle style={{ color: iconColor }} />
  const message = alertMessage[scene][alertState]

  return message ? (
    scene === 'clientProfile' ? (
      <StyledContextualAlert
        largeText
        dismissButtonText={options?.dismissButtonText || ''}
        intent={alertState}
        onDismiss={() => {
          return
        }}
        role="alert"
        scribbleType="default"
      >
        {message}
      </StyledContextualAlert>
    ) : (
      <StyledPersistentAlert intent={alertState} icon={urgentIcon}>
        {message}
      </StyledPersistentAlert>
    )
  ) : null
}

const StyledContextualAlert = styled(ContextualAlert)`
  margin-bottom: 16px;

  .inner-button {
    color: ${yellowSet[80].hex};
  }
`

const StyledPersistentAlert = styled(PersistentAlert)`
  margin-bottom: 16px;
`
