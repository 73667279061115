import { Fragment, ReactNode } from 'react'

import { useFeatureFlagContext } from '../context/FeatureFlagProvider'
import { FeatureType } from './FeatureFlagCheck'

interface FeatureFlagRendererProps {
  children: ReactNode
  featureType: FeatureType
  returnChildren: boolean
  fallback: ReactNode
  loading?: boolean
}

export function FeatureFlagRenderer({
  children,
  returnChildren,
  fallback,
  featureType,
  loading = false,
}: FeatureFlagRendererProps) {
  const { fallbackPageFactory, fallbackComponentFactory } = useFeatureFlagContext()

  if (loading && featureType === 'page') {
    return <div className="loading" style={{ height: '100%', width: '100%' }}></div>
  }

  if (returnChildren) {
    // eslint-disable-next-line react/jsx-no-useless-fragment
    return <Fragment>{children}</Fragment>
  }

  if (fallback) {
    // eslint-disable-next-line react/jsx-no-useless-fragment
    return <Fragment>{fallback}</Fragment>
  }

  if (featureType === 'page') {
    return fallbackPageFactory()
  }

  if (featureType === 'component') {
    return fallbackComponentFactory()
  }

  return null
}
