import { IconProps } from './IconProps'

export function IconVideoOff({ size = 24, color = 'currentColor' }: IconProps) {
  const height = (28 / 29) * size

  return (
    <svg width={height} height={height} viewBox="0 0 29 28" fill={color} xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M19.9871 2.60017C19.5041 2.33242 18.8954 2.50694 18.6277 2.98998L18.1614 3.83116C18.1407 3.91111 18.1096 3.98977 18.0677 4.06536L6.92433 24.1685C6.81247 24.5995 7.0004 25.0679 7.40786 25.2938L7.59862 25.3995C8.08166 25.6673 8.6903 25.4927 8.95805 25.0097L10.8166 21.6569H20.1199C20.8229 21.6569 21.3982 21.0816 21.3982 20.3786V16.5211L24.8257 19.8308C24.9064 19.9087 24.9916 19.984 25.0932 20.0315C25.7354 20.3318 26.5114 19.8687 26.5114 19.1224V8.85169C26.5114 8.1054 25.7354 7.64235 25.0932 7.94259C24.9916 7.99009 24.9064 8.06539 24.8257 8.14329L21.3982 11.453V7.59554C21.3982 6.89247 20.8229 6.31724 20.1199 6.31724H19.3194L20.5677 4.06535C20.8354 3.58231 20.6609 2.97367 20.1779 2.70592L19.9871 2.60017ZM5.78047 21.6569L6.87849 19.676H6.40134C5.87986 19.676 5.45319 19.2493 5.45319 18.7278V9.24627C5.45319 8.72479 5.87986 8.29812 6.40134 8.29812H13.1853L14.2834 6.31724H4.78026C4.07719 6.31724 3.50195 6.89247 3.50195 7.59554V20.3786C3.50195 21.0816 4.07719 21.6569 4.78026 21.6569H5.78047Z"
      />
    </svg>
  )
}
