import { $isRootTextContentEmpty } from '@lexical/text'
import { CLEAR_EDITOR_COMMAND, LexicalEditor, SerializedEditorState } from 'lexical'

export function clearEditor(editor: LexicalEditor) {
  editor.dispatchCommand(CLEAR_EDITOR_COMMAND, undefined)
}

export function getEmptySerializedState(): SerializedEditorState {
  return {
    root: {
      children: [
        {
          type: 'paragraph',
          version: 1,
        },
      ],
      direction: null,
      format: '',
      indent: 0,
      type: 'root',
      version: 1,
    },
  }
}

export const getHasContent = (value: LexicalEditor | null): value is LexicalEditor => {
  let hasContent = false

  if (value) {
    value.getEditorState().read(() => {
      hasContent = !$isRootTextContentEmpty(value.isComposing(), false)
    })
  }

  return hasContent
}
