import { styled } from '@mui/material'
import { HTMLAttributes } from 'react'

import { primary, salmonSet } from '@nuna/tunic'

import { NetworkQualityThreshold } from '../../../types'

interface NetworkQualityIconProps extends HTMLAttributes<HTMLDivElement> {
  threshold: NetworkQualityThreshold
  displayThreshold?: number
}

// map to numbers so we can do > comparisons
const thresholdToQualityMap = {
  good: 3,
  low: 2,
  'very-low': 1,
}

export function NetworkQualityIcon({ threshold, displayThreshold = 2, ...props }: NetworkQualityIconProps) {
  const quality = thresholdToQualityMap[threshold]

  if (quality > displayThreshold) {
    return null
  }

  const emptyBarColor = salmonSet[30].hex
  const fullBarColor = '#fff'

  return (
    <Container {...props}>
      <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M3.5 16.7504C3.5 17.3026 3.94772 17.7504 4.5 17.7504H5.83333C6.38562 17.7504 6.83333 17.3026 6.83333 16.7504V12.4346C6.83333 11.8823 6.38562 11.4346 5.83333 11.4346H4.5C3.94771 11.4346 3.5 11.8823 3.5 12.4346V16.7504Z"
          fill={quality > 0 ? fullBarColor : emptyBarColor}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M9.33203 16.7499C9.33203 17.3022 9.77975 17.7499 10.332 17.7499H11.6654C12.2176 17.7499 12.6654 17.3022 12.6654 16.7499V7.69726C12.6654 7.14498 12.2176 6.69727 11.6654 6.69727H10.332C9.77975 6.69727 9.33203 7.14498 9.33203 7.69727V16.7499Z"
          fill={quality > 1 ? fullBarColor : emptyBarColor}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M15.1641 16.75C15.1641 17.3023 15.6118 17.75 16.1641 17.75H17.4974C18.0497 17.75 18.4974 17.3023 18.4974 16.75V3.75C18.4974 3.19772 18.0497 2.75 17.4974 2.75H16.1641C15.6118 2.75 15.1641 3.19772 15.1641 3.75V16.75Z"
          fill={quality > 2 ? fullBarColor : emptyBarColor}
        />
      </svg>
    </Container>
  )
}

const Container = styled('div')`
  align-items: center;
  background-color: ${primary};
  border-radius: 6px;
  color: #fff;
  display: flex;
  height: 28px;
  justify-content: center;
  width: 28px;
`
