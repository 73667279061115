import { useDaily, useMeetingSessionState as useDailyMeetingSessionState } from '@daily-co/daily-react'
import { useCallback } from 'react'

import { MeetingSessionState } from '../types'

export const defaultMeetingSessionState: MeetingSessionState = {
  sessionStartTime: undefined,
  lastUpdatedAt: undefined,
}

// this is just a wrapper to provide types and include a timestamp when setting (for the sake of local storage sync)
export function useMeetingSessionState() {
  const callObject = useDaily()
  const { data } = useDailyMeetingSessionState<MeetingSessionState | undefined | null>()

  const setMeetingSessionState = useCallback(
    (data: Partial<MeetingSessionState>) => {
      callObject?.setMeetingSessionData({ ...data, lastUpdatedAt: new Date().toISOString() }, 'shallow-merge')
    },
    [callObject],
  )

  return [data ?? defaultMeetingSessionState, setMeetingSessionState] as const
}
