import moment, { Moment } from 'moment'
import { ReactNode, createContext, useContext } from 'react'

import { useProviderPayrollSummaryAndIncompleteNotesQuery } from '@nuna/api'
import { useAuthDataContext } from '@nuna/auth'

interface UpcomingPayoutsContextValue {
  payrollStartDate: Moment
  payrollEndDate: Moment
  unpaidEndDate: Moment
  expectedPayDate: string
  unpaidPayDate: string
  incompleteSessions: number
  payrollSummaryTotal: number
  unpaidSummaryTotal: number
  loading: boolean
}

const UpcomingPayoutsContext = createContext<UpcomingPayoutsContextValue>({} as UpcomingPayoutsContextValue)

export function UpcomingPayoutsContextProvider({ children }: { children: ReactNode }) {
  const { login } = useAuthDataContext()
  const providerId = login?.providerId || ''
  const dayOfMonth = moment().date()
  const startDate = dayOfMonth < 16 ? moment().startOf('month') : moment().date(16).startOf('day')
  const endDate = dayOfMonth < 16 ? moment().date(15).startOf('day') : moment().endOf('month')
  const expectedPayDate = (
    dayOfMonth < 16 ? moment().date(25).startOf('day') : moment().add(1, 'month').date(10).startOf('day')
  ).format('MMM, D YYYY')
  // Before this pay period began, there may be unpaid payments to report
  const unpaidEndDate = moment(startDate).subtract(1, 'day')
  const unpaidPayDate = moment(startDate).add(9, 'days').format('MMM, D YYYY')

  const incompleteNoteStart = moment().subtract(90, 'day').format('YYYY-MM-DD')
  const incompleteNoteEnd = moment().startOf('hour')

  const { loading, data } = useProviderPayrollSummaryAndIncompleteNotesQuery({
    variables: {
      startDate,
      endDate,
      providerId,
      unpaidEndDate,
      incompleteNoteStart,
      incompleteNoteEnd,
    },
    fetchPolicy: 'cache-and-network',
  })

  const incompleteSessions = data?.appointmentStats?.filteredAppointments?.length ?? 0
  const payrollSummaryTotal = data?.currentSummary.items[0]?.total || 0
  const unpaidSummaryTotal = data?.unpaidSummary.items[0]?.total || 0

  return (
    <UpcomingPayoutsContext.Provider
      value={{
        payrollStartDate: startDate,
        payrollEndDate: endDate,
        unpaidEndDate,
        expectedPayDate,
        unpaidPayDate,
        incompleteSessions,
        payrollSummaryTotal,
        unpaidSummaryTotal,
        loading,
      }}
    >
      {children}
    </UpcomingPayoutsContext.Provider>
  )
}

export function useUpcomingPayoutsContext() {
  return useContext(UpcomingPayoutsContext)
}
