import { NoteGenerationSkippedReason } from '@nuna/api'
import { ContextualAlert, ContextualAlertProps, IconInfo } from '@nuna/tunic'

import { SessionData } from '../../../types'

interface Props extends Omit<ContextualAlertProps, 'children'> {
  reason: NoteGenerationSkippedReason
  patient: SessionData['patient']
}

export function SkippedNoteAlert({
  reason,
  patient,
  intent = 'caution',
  variant = 'outlined',
  icon = <IconInfo />,
  ...props
}: Props) {
  return (
    <ContextualAlert intent={intent} variant={variant} icon={icon} {...props}>
      {(() => {
        switch (reason) {
          case NoteGenerationSkippedReason.NoTranscript:
            return 'A transcript was not found for this session, so no note was generated. Please contact support if you believe this is an error.'
          case NoteGenerationSkippedReason.PatientOptedOut:
            return `${patient.firstName} has opted out of Tava Scribe, so no note was generated.`
          case NoteGenerationSkippedReason.ProviderOptedOut:
            return 'Tava Scribe was disabled for this session, so no note was generated.'
          default:
            return 'No note was generated for this session.'
        }
      })()}
    </ContextualAlert>
  )
}
