import { useLayoutEffect, useRef, useState } from 'react'

import { useWindowWidth } from './useWindowWidth'

export function useHasScrollbars() {
  const noteRef = useRef<HTMLParagraphElement>(null)
  const [hasScrollbars, setHasScrollbars] = useState(false)
  const windowWidth = useWindowWidth()

  useLayoutEffect(() => {
    if (noteRef.current) {
      setHasScrollbars(noteRef.current.scrollHeight > noteRef.current.clientHeight)
    }
  }, [noteRef, setHasScrollbars, windowWidth])

  return { hasScrollbars, noteRef }
}
