import { useFormikContext } from 'formik'

import { BasicPatientFragment } from '@nuna/api'

import { DrawerActions } from '../../DrawerActions'
import { AppointmentFormValues } from '../shared'
import { NewAppointmentSlot } from './NewAppointmentSlot'

interface PendingAppointmentListProps {
  isLoading: boolean
  patient: BasicPatientFragment
  providerId: string
}

export function NewPendingAppointmentList({ isLoading, patient, providerId }: PendingAppointmentListProps) {
  const { handleSubmit, values, setFieldValue } = useFormikContext<AppointmentFormValues>()
  const appointmentsWithMultiAddressIdAdded = values.appointments.map(appt => ({
    ...appt,
    newLocation: appt.newLocation || values.multiAddressId, // fallback to the multiAddressId if the newAddressId is not set specifically already
  }))

  return (
    <form onSubmit={handleSubmit}>
      {appointmentsWithMultiAddressIdAdded.map((slot, index) => {
        return (
          <NewAppointmentSlot
            providerId={providerId}
            index={index}
            key={slot.slot.start}
            appointmentSlot={slot}
            patient={patient}
          />
        )
      })}

      <DrawerActions
        secondary={{
          onClick: () => {
            setFieldValue('multiAddressId', 'Virtual')
            setFieldValue('appointments', [])
          },
          children: 'Nevermind',
        }}
        primary={{
          'data-component': 'schedule-multiple-appointments',
          disabled: values.appointments.filter(appointment => appointment.included).length === 0,
          type: 'submit',
          isLoading: isLoading,
          children: `Schedule ${values.appointments.filter(appt => appt.included).length} Sessions`,
        }}
        caption={{
          children: <>We'll let {patient.firstName} know.</>,
        }}
      />
    </form>
  )
}
