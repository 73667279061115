import {
  IconFileAudio,
  IconFileDocument,
  IconFileImage,
  IconFileSlides,
  IconFileSpreadsheet,
  IconFileText,
  IconFileVideo,
} from '../../icons'
import { IconProps } from '../../icons/IconProps'

export interface FileTypeIconProps extends IconProps {
  mimeType: string
}

export function FileTypeIcon({ mimeType, ...props }: FileTypeIconProps) {
  if (mimeType.startsWith('audio')) {
    return <IconFileAudio {...props} />
  } else if (mimeType.startsWith('image')) {
    return <IconFileImage {...props} />
  } else if (mimeType.includes('powerpoint') || mimeType.includes('keynote')) {
    return <IconFileSlides {...props} />
  } else if (mimeType.includes('excel') || mimeType.includes('numbers') || mimeType.includes('csv')) {
    return <IconFileSpreadsheet {...props} />
  } else if (mimeType.startsWith('text')) {
    return <IconFileText {...props} />
  } else if (mimeType.startsWith('video')) {
    return <IconFileVideo {...props} />
  }

  return <IconFileDocument {...props} />
}
