import { styled } from '@mui/material'
import React from 'react'

import { borderGrey } from '../../styles/colors'
import { styledUtils } from '../../utils/styled'
import { Tooltip } from '../Tooltip/Tooltip'

const { transientPropOptions } = styledUtils

export interface ProgressBarProps extends React.HTMLAttributes<HTMLDivElement> {
  bars: {
    percent: number
    color: string
    tooltip?: React.ReactNode
    key?: string // key is necessary if the progress bar should animate
  }[]
}

export function ProgressBar({ bars, ...props }: ProgressBarProps) {
  return (
    <OuterBar {...props}>
      {bars
        .sort((a, b) => b.percent - a.percent) // render longest bars first so they don't get rendered over shorter ones
        .map(({ percent, color, tooltip, key }) => {
          if (percent === 0) {
            return null
          }

          const bar = (
            <InnerBar
              $roundRightEdge={percent === 100}
              key={key ?? `${percent}-${color}`}
              $width={`${Math.max(0, Math.min(percent, 100))}%`}
              $color={color}
            />
          )

          if (tooltip) {
            return (
              <Tooltip key={`${percent}-${color}`} content={tooltip}>
                {bar}
              </Tooltip>
            )
          }

          return bar
        })}
    </OuterBar>
  )
}

const OuterBar = styled('div')`
  background-color: ${borderGrey};
  border-radius: 6px;
  border: 1px solid #fff;
  height: 10px;
  position: relative;
  overflow: hidden;
  width: 100%;
`

const InnerBar = styled(OuterBar, transientPropOptions)<{ $width: string; $color: string; $roundRightEdge: boolean }>`
  background-color: ${props => props.$color};
  border-radius: ${props => (props.$roundRightEdge ? '6px' : '6px 0 0 6px')};
  border-right-width: 2px;
  left: 0;
  position: absolute;
  top: -1px;
  width: ${props => props.$width};
  transition: width 0.5s ease-in-out;
`
