import { IconProps } from './IconProps'

export function IconClock({ size = 24, color = 'currentColor', ...props }: IconProps) {
  return (
    <svg width={size} height={size} viewBox="0 0 20 20" fill={color} xmlns="http://www.w3.org/2000/svg" {...props}>
      <path d="M9.99169 1.6665C5.39169 1.6665 1.66669 5.39984 1.66669 9.99984C1.66669 14.5998 5.39169 18.3332 9.99169 18.3332C14.6 18.3332 18.3334 14.5998 18.3334 9.99984C18.3334 5.39984 14.6 1.6665 9.99169 1.6665ZM10 16.6665C6.31669 16.6665 3.33335 13.6832 3.33335 9.99984C3.33335 6.3165 6.31669 3.33317 10 3.33317C13.6834 3.33317 16.6667 6.3165 16.6667 9.99984C16.6667 13.6832 13.6834 16.6665 10 16.6665Z" />
      <path d="M10.4167 5.8335H9.16669V10.8335L13.5417 13.4585L14.1667 12.4335L10.4167 10.2085V5.8335Z" />
    </svg>
  )
}
