import { useFormikContext } from 'formik'
import { HTMLAttributes } from 'react'
import { Route, Routes } from 'react-router-dom'

import { routeService } from '@nuna/core'

import { useProviderSignupIntakeContext } from '../../../scenes/SignupIntake/ProviderSignupIntakeContext'
import { ProviderSignupFormValues } from '../../../scenes/SignupIntake/shared/provider-signup-intake-types'
import { IntakeProgressHeader } from './IntakeProgressHeader'
import { SignupIntakeHomeHeader } from './SignupIntakeHomeHeader'

export function SignupIntakeHeader(props: HTMLAttributes<HTMLHeadingElement>) {
  const { allRequirementsMet } = useProviderSignupIntakeContext()
  const { values } = useFormikContext<ProviderSignupFormValues>()
  const { saveAndNavigate } = useProviderSignupIntakeContext()

  const handleSaveAndExit = async () => {
    saveAndNavigate(values, routeService.scheduleDetail())
  }

  const handleSaveAndNavigate = (to: string) => {
    return saveAndNavigate(values, to)
  }

  return (
    <Routes>
      <Route
        index
        element={
          allRequirementsMet ? (
            <IntakeProgressHeader
              {...props}
              onSaveAndExitClick={handleSaveAndExit}
              onSaveAndNavigate={handleSaveAndNavigate}
            />
          ) : (
            <SignupIntakeHomeHeader {...props} onSaveAndNavigate={handleSaveAndNavigate} />
          )
        }
      />
      <Route
        path="*"
        element={
          <IntakeProgressHeader
            {...props}
            onSaveAndExitClick={handleSaveAndExit}
            onSaveAndNavigate={handleSaveAndNavigate}
          />
        }
      />
    </Routes>
  )
}
