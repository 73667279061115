import moment from 'moment'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { resolvePath, useNavigate, useResolvedPath } from 'react-router-dom'

import {
  CaqhSyncStatus,
  DataCollectionStep,
  useCaqhCredentialsQuery,
  useProviderCaqhValidationQuery,
  useSaveProviderCredentialingDataMutation,
  useSyncProviderCaqhToVerifiableMutation,
} from '@nuna/api'
import { errorService, providerService, routeService } from '@nuna/core'
import {
  Box,
  ContextualAlert,
  GhostButtonLink,
  IconInfo,
  IconProgressIndicator,
  OutlineButton,
  ProgressBar,
  TextButtonExternalLink,
  TextButtonLink,
  greySet,
  tealSet,
} from '@nuna/tunic'

import { useCAQHSetupContext } from '../../../context/CAQHSetupContext'
import { CAQHStepper } from '../CAQHStepper'
import { CAQHSubtitle } from './CAQHSubtitle'
import { CaqhSyncLoading } from './CAQHSyncLoading'
import { CAQHValidationSection } from './CAQHValidationSection'

const { caqhSteps } = routeService

export function CAQHProfile() {
  const { providerId, isCredentialPolling, startCredentialPolling, stopCredentialPolling, credentialData } =
    useCAQHSetupContext()
  const navigate = useNavigate()
  const parentPath = useResolvedPath('..').pathname
  const [syncProviderCaqhToVerifiable, { loading: syncProviderToVerifiableLoading }] =
    useSyncProviderCaqhToVerifiableMutation()

  const { data: caqhCredentials, loading: caqhCredentialsLoading } = useCaqhCredentialsQuery({
    variables: { providerId },
    skip: !providerId,
  })

  // need this piece of state to differentiate between the initial loading state and the loading state after the user clicks the button
  const [isUpdating, setIsUpdating] = useState(false)
  const isPollingActive = isCredentialPolling || syncProviderToVerifiableLoading

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const {
    data: caqhValidationData,
    error: caqhValidationError,
    refetch,
  } = useProviderCaqhValidationQuery({
    variables: { providerId },
  })

  const [saveProviderCredentialingData] = useSaveProviderCredentialingDataMutation()

  const handleNextStep = useCallback(async () => {
    const response = await saveProviderCredentialingData({
      variables: {
        providerId,
        data: { caqhCompletedAt: new Date() },
      },
    })

    if (response.data && !!response.data.saveProviderCredentialingData?.caqhCompletedAt) {
      navigate(resolvePath(caqhSteps[DataCollectionStep.AttestationSignature], parentPath).pathname)
    }
  }, [navigate, providerId, saveProviderCredentialingData, parentPath])

  useEffect(() => {
    if (caqhValidationData && caqhValidationData.providerCaqhValidation.complete) {
      handleNextStep()
    }
  }, [caqhValidationData, handleNextStep])

  const percentCompleted = useMemo(() => {
    if (caqhValidationError) return 0
    if (caqhValidationData) {
      return providerService.calculatePercentCaqhValidationCompleted(
        caqhValidationData.providerCaqhValidation.sectionResults,
      )
    }
    return 0
  }, [caqhValidationError, caqhValidationData])

  const handleSyncAndCredentialPolling = useCallback(async () => {
    await syncProviderCaqhToVerifiable({
      variables: {
        providerId,
      },
    })
    startCredentialPolling()
  }, [syncProviderCaqhToVerifiable, providerId, startCredentialPolling])

  const handleManualUpdateClick = useCallback(async () => {
    setIsUpdating(true)
    handleSyncAndCredentialPolling()
  }, [handleSyncAndCredentialPolling])

  // hit the syncProviderCaqhToVerifiable mutation every 60 seconds
  useEffect(() => {
    if (isPollingActive) return
    const interval = setInterval(() => {
      handleSyncAndCredentialPolling()
      setIsUpdating(true)
    }, 60000)

    return () => clearInterval(interval)
  }, [handleSyncAndCredentialPolling, isPollingActive])

  useEffect(() => {
    if (
      credentialData?.providerCredentialingData?.caqhSyncStatus === CaqhSyncStatus.Completed ||
      credentialData?.providerCredentialingData?.caqhSyncStatus === CaqhSyncStatus.Failed
    ) {
      stopCredentialPolling()
      refetch()
      setIsUpdating(false)
    }
  }, [credentialData?.providerCredentialingData?.caqhSyncStatus, stopCredentialPolling, refetch])

  useEffect(() => {
    if (
      !caqhCredentialsLoading &&
      (!caqhCredentials || !caqhCredentials.providerCaqh.caqhUsername || !caqhCredentials.providerCaqh.caqhPassword)
    ) {
      navigate(resolvePath(caqhSteps[DataCollectionStep.CaqhCredentials], parentPath).pathname)
    }
  }, [caqhCredentials, caqhCredentialsLoading, navigate, parentPath])

  if (
    credentialData?.providerCredentialingData?.caqhSyncStatus !== CaqhSyncStatus.Completed &&
    credentialData?.providerCredentialingData?.caqhSyncStatus !== CaqhSyncStatus.Failed &&
    !isUpdating &&
    !caqhValidationData?.providerCaqhValidation.sectionResults
  ) {
    return <CaqhSyncLoading />
  }

  const statusMessage =
    !isCredentialPolling && caqhValidationError ? 'Error loading CAQH' : `${percentCompleted}% Complete`

  return (
    <div>
      <h2 className="h3 mb-6">Complete your CAQH Profile</h2>

      <section>
        <Box
          display="flex"
          flexDirection={{ xs: 'column', sm: 'row' }}
          alignItems={{ xs: 'center', sm: 'flex-start' }}
          justifyContent="stretch"
          gap={16}
        >
          <div className="full-width">
            <div className="mb-2">
              <span className="text-medium">Status:</span> {statusMessage}
            </div>

            <ProgressBar
              className="full-width"
              bars={[{ key: 'caqhProgress', percent: percentCompleted, color: tealSet[30].hex }]}
            />
            {credentialData?.providerCredentialingData?.caqhSyncedAt && (
              <p className="mt-1 mb-0" style={{ color: greySet[70].hex }}>
                Last updated {moment().to(credentialData?.providerCredentialingData?.caqhSyncedAt)}
              </p>
            )}
          </div>
          <OutlineButton
            disabled={isPollingActive}
            onClick={() => handleManualUpdateClick()}
            className="mobile-fullwidth"
          >
            {isPollingActive ? (
              <>
                <IconProgressIndicator className="mr-1" />
                Checking
              </>
            ) : (
              'Check Status'
            )}
          </OutlineButton>
        </Box>
        {isPollingActive && (
          <ContextualAlert
            className="mt-2"
            icon={<IconInfo />}
            intent="default"
            role="alert"
            scheme="light"
            scribbleType="default"
          >
            Heads up - Resyncing your CAQH data may take a minute or more.
          </ContextualAlert>
        )}

        {!caqhValidationError && (
          <>
            <CAQHSubtitle className="mt-5 mb-4">
              We’ve scanned your CAQH profile and found <b>the following items need to be completed</b>. Click the links
              below to complete the required sections, then return here to finish enrollment.
            </CAQHSubtitle>
            <p className="body text-dark-grey">
              {/* this "top: 3px" is hacky because setting the p tag to v-align creates a weird smush effect with the spacing around the inline GhostButtonLink */}
              <IconInfo className="mr-1 relative" style={{ top: '3px' }} color={greySet[70].hex} size={16} /> If you're
              running into issues clearing sections below please{' '}
              <GhostButtonLink className="body text-medium" to="https://proview.caqh.org//PR/Review/Review">
                attest in CAQH
              </GhostButtonLink>{' '}
              to proceed.
            </p>
          </>
        )}

        {caqhValidationError && <CAQHImportErrorAlert error={caqhValidationError} />}
      </section>

      <section className="mt-5">
        {caqhValidationData?.providerCaqhValidation.sectionResults
          .filter(section => !section.complete)
          .map(section => (
            <CAQHValidationSection section={section} key={section.name} />
          ))}
      </section>

      <div className="mt-6">
        <CAQHStepper
          credentialingData={{
            caqhCompletedAt: new Date(),
          }}
          responseChecker={response => !!response.saveProviderCredentialingData?.caqhCompletedAt}
          disabled={percentCompleted < 100}
        />
      </div>
    </div>
  )
}

function CAQHImportErrorAlert({ error }: { error: Error }) {
  const errorMessage = errorService.transformGraphQlError(error)

  const content =
    errorMessage === 'Invalid CAQH credentials' ? (
      <span>
        Your CAQH username and password are invalid. Please re-enter them{' '}
        <TextButtonLink variant="destroy" to={routeService.caqhCredentials()}>
          here
        </TextButtonLink>{' '}
        and try again
      </span>
    ) : (
      <span>
        Something went wrong syncing your CAQH profile. Please contact{' '}
        <TextButtonExternalLink variant="destroy" href="mailto:support@tavahealth.com">
          support@tavahealth.com
        </TextButtonExternalLink>
      </span>
    )

  return (
    <ContextualAlert intent="urgent" largeText className="mt-5">
      {content}
    </ContextualAlert>
  )
}
