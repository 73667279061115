import { pick } from 'lodash'
import { useMemo } from 'react'

import { Contract } from '@nuna/api'
import { StatusLabel } from '@nuna/tunic'

type AvailableToValues = Pick<
  Contract,
  | 'availableToMedicalPlanEnrolled'
  | 'availableToBenefitsEligible'
  | 'availableToBenefitsEnrolled'
  | 'availableToContractors'
  | 'availableToDependents'
  | 'availableToFullTime'
  | 'availableToInterns'
  | 'availableToPartTime'
>

interface AvailableToLabelsProps {
  contract: AvailableToValues
}

type AvailableToLabelsMap = Record<keyof AvailableToValues, string>

const AVAILABLE_TO_LABELS_MAP: AvailableToLabelsMap = {
  availableToMedicalPlanEnrolled: 'Medical Plan Enrolled',
  availableToBenefitsEligible: 'Benefits Elibigle',
  availableToBenefitsEnrolled: 'Benefits Enrolled',
  availableToContractors: 'Contractors',
  availableToDependents: 'Dependents',
  availableToFullTime: 'Fulltime employees',
  availableToInterns: 'Interns',
  availableToPartTime: 'Part-time employees',
}

export function AvailableToLabels({ contract }: AvailableToLabelsProps) {
  const labels = useMemo(() => {
    const availableValues = pick(contract, [
      'availableToMedicalPlanEnrolled',
      'availableToBenefitsEligible',
      'availableToBenefitsEnrolled',
      'availableToContractors',
      'availableToDependents',
      'availableToFullTime',
      'availableToInterns',
      'availableToPartTime',
    ])

    return Object.entries(availableValues)
      .filter(([, value]) => value)
      .map(([key]) => AVAILABLE_TO_LABELS_MAP[key as keyof AvailableToValues])
  }, [contract])

  return (
    <>
      {labels.map(label => (
        <StatusLabel className="mr-1 mb-1" key={label}>
          {label}
        </StatusLabel>
      ))}
    </>
  )
}
