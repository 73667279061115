import { styled } from '@mui/material'
import { sortBy } from 'lodash'
import { useMemo } from 'react'

import { useProviderLicensesQuery, useProviderMeQuery } from '@nuna/api'
import { LicensesForm } from '@nuna/provider'

import licenseIllustrationSrc from './img/Intake.png'
import { BaseSignupFormProps } from './shared'

export function SignupLicenses({ onStepSubmit, step, formValues }: BaseSignupFormProps) {
  const { data: providerData } = useProviderMeQuery()
  const providerId = providerData?.providerMe.id
  const { data: providerLicenseData, refetch } = useProviderLicensesQuery({
    variables: { providerId: providerId ?? '' },
    skip: !providerId,
  })

  const sortedCredentials = useMemo(
    () => sortBy(providerLicenseData?.provider.credentials, 'state'),
    [providerLicenseData?.provider.credentials],
  )

  if (!providerId) return null

  const handleOnSave = () => {
    refetch()
    onStepSubmit(formValues, step)
  }

  return (
    <section>
      <div className="bottom-align">
        <h2 className="h2">Let's add your licenses</h2>
        <Illustration src={licenseIllustrationSrc} alt="" />
      </div>

      <p className="text-medium-gray large">
        Tell us which licenses you hold so we can let you know your rates and know which states to credential you in.
      </p>
      <LicensesForm
        credentials={sortedCredentials}
        onCancel={() => undefined}
        providerId={providerId}
        onSave={handleOnSave}
        signupIntake
        containerClasses="mb-5"
        fullWidth
      />
    </section>
  )
}

const Illustration = styled('img')`
  width: 165px;
`
