import * as Yup from 'yup'
import moment from 'moment'

import { FullProviderFragment, useUpdateProviderMutation } from '@nuna/api'
import { ProfileDisplayValue, ProfileSection } from '@nuna/common'
import { dateService } from '@nuna/core'
import { DOBTextField, Grid } from '@nuna/tunic'

import { incompleteSectionNotification } from '../../../utils/util'

export type BirthdateSectionProps = Pick<FullProviderFragment, 'id' | 'dob'>
type BirthdateValues = Omit<BirthdateSectionProps, 'id'>

export function BirthdateSection({ id, dob }: BirthdateSectionProps) {
  const [updateProvider, { loading: updateLoading }] = useUpdateProviderMutation()

  const initialValues: BirthdateValues = {
    dob: dateService.utcFormat(dob, 'YYYY-MM-DD'),
  }

  const [isComplete, valueDisplay] = incompleteSectionNotification<BirthdateValues>(
    initialValues,
    ['dob'],
    <ProfileDisplayValue>{dateService.utcFormat(initialValues.dob, 'MMMM DD, YYYY')}</ProfileDisplayValue>,
  )

  return (
    <ProfileSection
      heading="Birthdate"
      useOutlineSaveButton
      description="Your age is used to help us to match you with clients based on the preferences they set."
      displayWidth="full"
      updateLoading={updateLoading}
      initialValues={initialValues}
      sectionComplete={isComplete}
      handleSubmit={values =>
        updateProvider({
          variables: Object.assign({ id: id }, { dob: moment.utc(values.dob).startOf('day').toISOString() }),
        })
      }
      disabled={({ isValid }) => !isValid}
      renderDisplayValue={valueDisplay}
      renderForm={({ values, handleChange, handleBlur, errors, touched }) => (
        <Grid container>
          <Grid
            size={{
              xs: 12,
              sm: 10,
            }}
          >
            <DOBTextField
              label="Date of Birth"
              value={values.dob}
              placeholder="MM/DD/YYYY"
              fullWidth
              name="dob"
              onChange={handleChange}
              onBlur={handleBlur}
              error={(errors.dob && touched.dob) as boolean}
              helperText={touched.dob && errors.dob ? (errors.dob as string) : null}
            />
          </Grid>
        </Grid>
      )}
      validationSchema={Yup.object().shape({
        dob: Yup.string().required('Birthdate is required'),
      })}
    ></ProfileSection>
  )
}
