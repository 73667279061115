import { AssessmentBundleQuery, AssessmentType, AssessmentsQuery } from '@nuna/api'

export enum CssrsResponseEnum {
  Yes = 1,
  No = 0,
}
export type CssrsResponse = { value: CssrsResponseEnum | null }
export interface CssrsFormValues {
  'cssrs:1': CssrsResponse
  'cssrs:2': CssrsResponse
  'cssrs:3': CssrsResponse
  'cssrs:4': CssrsResponse
  'cssrs:5': CssrsResponse
  'cssrs:6': CssrsResponse
  'cssrs:7': CssrsResponse
  'cssrs:8': CssrsResponse
}
export interface CssrsQuestion {
  question: string
  key: keyof CssrsFormValues
  hideWithinLabel?: boolean
  conditionalOn?: { key: keyof CssrsFormValues; value: CssrsResponseEnum.Yes }
}

export enum CssrsSeverities {
  LowRisk = 'Low risk',
  ModerateRisk = 'Moderate risk',
  HighRisk = 'High risk',
}

export enum Core10Responses {
  NotAtAll = 0,
  Occasionally,
  Sometimes,
  Often,
  AllOfTheTime,
}
export type Core10QuestionIndex =
  | 'CORE10:1'
  | 'CORE10:2'
  | 'CORE10:3'
  | 'CORE10:4'
  | 'CORE10:5'
  | 'CORE10:6'
  | 'CORE10:7'
  | 'CORE10:8'
  | 'CORE10:9'
  | 'CORE10:10'
export type Core10ResponseValue = 4 | 3 | 2 | 1 | 0 | null
export type Core10Response = { value: Core10ResponseValue }

export interface Core10FormValues {
  'core10:1': Core10Response
  'core10:2': Core10Response
  'core10:3': Core10Response
  'core10:4': Core10Response
  'core10:5': Core10Response
  'core10:6': Core10Response
  'core10:7': Core10Response
  'core10:8': Core10Response
  'core10:9': Core10Response
  'core10:10': Core10Response
}

export interface Core10Question {
  question: string
  key: keyof Core10FormValues
}

export enum GAD7PHQ9Responses {
  NotAtAll = 0,
  SeveralDays,
  MoreThanHalf,
  EveryDay,
}
export type GAD7QuestionIndex = 'GAD7:1' | 'GAD7:2' | 'GAD7:3' | 'GAD7:4' | 'GAD7:5' | 'GAD7:6' | 'GAD7:7'
export type Gad7ResponseValue = 3 | 2 | 1 | 0 | null
export type Gad7Response = { value: Gad7ResponseValue }
export interface Gad7FormValues {
  'gad7:1': Gad7Response
  'gad7:2': Gad7Response
  'gad7:3': Gad7Response
  'gad7:4': Gad7Response
  'gad7:5': Gad7Response
  'gad7:6': Gad7Response
  'gad7:7': Gad7Response
}
export interface Gad7Question {
  question: string
  key: keyof Gad7FormValues
}

export type PHQ9QuestionIndex =
  | 'PHQ9:1'
  | 'PHQ9:2'
  | 'PHQ9:3'
  | 'PHQ9:4'
  | 'PHQ9:5'
  | 'PHQ9:6'
  | 'PHQ9:7'
  | 'PHQ9:8'
  | 'PHQ9:9'
export type Phq9ResponseValue = 3 | 2 | 1 | 0 | null
export type Phq9Response = { value: Phq9ResponseValue }
export interface Phq9FormValues {
  'phq9:1': Phq9Response
  'phq9:2': Phq9Response
  'phq9:3': Phq9Response
  'phq9:4': Phq9Response
  'phq9:5': Phq9Response
  'phq9:6': Phq9Response
  'phq9:7': Phq9Response
  'phq9:8': Phq9Response
  'phq9:9': Phq9Response
}
export interface Phq9Question {
  question: string
  key: keyof Phq9FormValues
}

export type AssessmentFromBundleQuery = AssessmentBundleQuery['assessmentBundle']['assessments'][number]
export type AssessmentFromAssessmentsQuery = AssessmentsQuery['assessments'][0]
export type AssessmentValues = { [key: string]: { value: number } }

export enum Severity {
  Minimal = 0,
  Mild = 1,
  Moderate = 2,
  Severe = 3,
}

export interface SeverityThresholds {
  [key: string]: number[]
}

export const severityThresholds: SeverityThresholds = {
  [AssessmentType.Gad_7]: [4, 9, 14, 21],
  [AssessmentType.Phq_9]: [4, 9, 19, 27],
}

export interface ResultDetail {
  illustration: string
  heading: string
  body: React.ReactNode
  button?: React.ReactNode
  secondaryButton?: React.ReactNode
}

export interface ResultDetails {
  [key: number]: ResultDetail
  suicideRisk: ResultDetail
}

export enum TrendDirection {
  Up = 'up',
  Down = 'down',
  Neutral = 'neutral',
}
