import { styled } from '@mui/material'

import { greySet } from '@nuna/tunic'

export const GreyBox = styled('section')`
  background-color: ${greySet[0].hex};
  border: 1px solid ${greySet[15].hex};
  border-radius: var(--border-radius-sm);
  padding: 0.5rem 1rem;
`

export const GreySection = styled(GreyBox)`
  padding: 2rem;
`
