import { Autocomplete, AutocompleteProps, TextField, TextFieldProps } from '@mui/material'
import FuzzySearch from 'fuzzy-search'
import { useMemo, useState } from 'react'

import { type TimezoneOption, timeService } from '@nuna/core'

const { timezoneOptions } = timeService

type LimitedAutocompleteProps = Omit<
  AutocompleteProps<TimezoneOption | undefined, false, boolean | undefined, false>,
  'onChange' | 'options' | 'renderInput' | 'value'
>

interface Props extends LimitedAutocompleteProps {
  value?: string | null
  onChange: (timezone: string | undefined | null) => void
  label?: string
  inputProps?: TextFieldProps
  name?: string
}

export function TimezoneSelect({ value, onChange, label = '', name, inputProps = {}, ...props }: Props) {
  const [searchTerm, setSearchTerm] = useState('')
  const valueAsOption = timezoneOptions.find(tz => tz.value === value) ?? null

  const filteredTimezones = useMemo(() => {
    const searcher = new FuzzySearch(timezoneOptions, ['label', 'value'], {
      caseSensitive: false,
    })
    if (!searchTerm) {
      return timezoneOptions
    }
    return searcher.search(searchTerm)
  }, [searchTerm])

  return (
    <Autocomplete
      {...props}
      autoComplete
      onChange={(_event, option) => {
        onChange(option?.value ?? null)
      }}
      options={filteredTimezones}
      value={valueAsOption}
      isOptionEqualToValue={(option, value) => option?.value === value?.value}
      getOptionLabel={option => option?.label ?? ''}
      ListboxProps={{ className: 'MuiAutocomplete-listbox' }}
      onInputChange={(_event, searchTerm) => setSearchTerm(searchTerm)}
      renderInput={params => (
        <TextField name={name} label={label} placeholder="Type to filter..." {...inputProps} {...params} />
      )}
    />
  )
}
