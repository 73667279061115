import { MouseEvent } from 'react'
import { useNavigate } from 'react-router-dom'

import { routeService } from '@nuna/core'
import { IconButton, IconMail, greySet } from '@nuna/tunic'

interface ClientMessageButtonProps {
  patient: {
    loginId?: string | null
  }
}

export function ClientMessageButton({ patient }: ClientMessageButtonProps) {
  const navigate = useNavigate()

  const handleOpen = (event: MouseEvent<HTMLElement>) => {
    navigate(routeService.conversationParticipantRoute(patient?.loginId ?? ''))

    event.preventDefault()
    event.stopPropagation()
  }

  return (
    <div onClick={e => e.stopPropagation()}>
      <IconButton tooltip="Send a message to this client" variant="secondary" onClick={handleOpen}>
        <IconMail size={20} color={greySet[70].hex} />
      </IconButton>
    </div>
  )
}
