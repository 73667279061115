import { useCallback, useState } from 'react'

import { DocumentType, useSaveDocumentMutation, useSaveUserDocumentMutation } from '../generated/graphql'

function uploadToS3(url: string, file: File) {
  return fetch(url, {
    method: 'PUT',
    body: file,
    headers: {
      'Content-Type': file.type,
    },
  })
}

export function useDocumentUpload() {
  const [saveDocument, { loading: saveDocumentLoading }] = useSaveDocumentMutation()
  const [s3Loading, setS3Loading] = useState(false)

  const uploadDocument = useCallback(
    async (file: File, documentType: DocumentType, fileName?: string) => {
      const { data } = await saveDocument({
        variables: {
          document: {
            documentType,
            fileName: fileName ?? file.name,
            mimeType: file.type,
          },
        },
      })

      const document = data?.saveDocument

      if (!document || !document.uploadUrl) {
        return Promise.reject('Unable to get upload url')
      }
      try {
        setS3Loading(true)
        const response = await uploadToS3(document.uploadUrl, file)
        setS3Loading(false)

        if (!response.ok) {
          throw new Error()
        }
      } catch (e) {
        setS3Loading(false)
        return Promise.reject('Failed to upload to S3')
      }

      return document
    },
    [saveDocument],
  )

  return { uploadDocument, loading: saveDocumentLoading || s3Loading }
}

/** @deprecated use useSaveUserDocument instead */
export function useUserDocumentUpload(loginId: string) {
  const { uploadDocument: baseDocumentUpload, loading } = useDocumentUpload()
  const [saveUserDocument, { loading: saveLoading }] = useSaveUserDocumentMutation()

  const uploadDocument = useCallback(
    async ({
      file,
      documentType,
      fileName,
      userDocumentId,
    }: {
      file: File
      documentType: DocumentType
      fileName?: string
      userDocumentId?: string
    }) => {
      const document = await baseDocumentUpload(file, documentType, fileName)
      const { data } = await saveUserDocument({
        variables: {
          userDocument: {
            id: userDocumentId,
            documentId: document.id,
            loginId,
          },
        },
      })

      if (!data?.saveUserDocument) throw new Error('Failed to save user document')

      return { document, userDocument: data.saveUserDocument }
    },
    [baseDocumentUpload, loginId, saveUserDocument],
  )

  return { uploadDocument, loading: loading || saveLoading }
}
