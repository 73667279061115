import { styled } from '@mui/material'
import {
  Chart,
  ChartOptions,
  Legend,
  LineElement,
  LinearScale,
  PointElement,
  TimeScale,
  Title,
  Tooltip,
} from 'chart.js'
// required for time scale, using moment as that's what we already have currently
import 'chartjs-adapter-moment'
import { Line } from 'react-chartjs-2'
import { useMediaQuery } from 'react-responsive'

import { AssessmentType, AssessmentsQuery, Role } from '@nuna/api'
import { useUserRole } from '@nuna/auth'
import { BelowTablet, GhostButtonExternalLink, IconInfo, plumSet, salmonSet, tealSet } from '@nuna/tunic'

import { getReadableAssessmentName } from '../util/assessments'

Chart.register(TimeScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend)

interface Props {
  assessments: AssessmentsQuery['assessments']
}

export function AssessmentChart({ assessments }: Props) {
  const userRole = useUserRole() || Role.Patient
  const isMobile = useMediaQuery({ query: `(${BelowTablet})` })

  const preparedAssessments = assessments
    .map(assessment => ({
      x: new Date(assessment.updatedAt), // Convert to Date object
      y: assessment.score,
      type: getReadableAssessmentName(assessment.type, userRole),
    }))
    .sort((a, b) => a.x.getTime() - b.x.getTime()) // Sort based on Date object

  const data = {
    datasets: [
      {
        label: getReadableAssessmentName(AssessmentType.Gad_7, userRole),
        borderColor: tealSet[50].hex,
        backgroundColor: tealSet[50].hex,
        pointBackgroundColor: tealSet[50].hex,
        data: preparedAssessments
          .filter(assessment => assessment.type === getReadableAssessmentName(AssessmentType.Gad_7, userRole))
          .map(assessment => ({ x: assessment.x, y: assessment.y })),
      },
      {
        label: getReadableAssessmentName(AssessmentType.Phq_9, userRole),
        borderColor: salmonSet[50].hex,
        backgroundColor: salmonSet[50].hex,
        pointBackgroundColor: salmonSet[50].hex,
        data: preparedAssessments
          .filter(assessment => assessment.type === getReadableAssessmentName(AssessmentType.Phq_9, userRole))
          .map(assessment => ({ x: assessment.x, y: assessment.y })),
      },
      {
        label: getReadableAssessmentName(AssessmentType.Core_10, userRole),
        borderColor: plumSet[50].hex,
        backgroundColor: plumSet[50].hex,
        pointBackgroundColor: plumSet[50].hex,
        data: preparedAssessments
          .filter(assessment => assessment.type === getReadableAssessmentName(AssessmentType.Core_10, userRole))
          .map(assessment => ({ x: assessment.x, y: assessment.y })),
      },
    ],
  }

  const options: ChartOptions<'line'> = {
    maintainAspectRatio: false,
    scales: {
      x: {
        type: 'time' as const,
        time: {
          unit: 'day' as const,
          tooltipFormat: 'll',
          displayFormats: {
            day: 'MMM D, YYYY',
          },
        },
        // Add padding to the start and end otherwise single dates look horrible
        min: new Date(preparedAssessments[0].x).setDate(new Date(preparedAssessments[0].x).getDate() - 1),
        max: new Date(preparedAssessments[preparedAssessments.length - 1].x).setDate(
          new Date(preparedAssessments[preparedAssessments.length - 1].x).getDate() + 1,
        ),
        grid: {
          display: false,
        },
        ticks: {
          autoSkip: true,
          maxRotation: 0,
          minRotation: 0,
          maxTicksLimit: 9,
        },
      },
      y: {
        beginAtZero: true,
        max: 40,
      },
    },
    plugins: {
      legend: {
        display: true,
        position: !isMobile ? ('right' as const) : ('bottom' as const),
        labels: {
          usePointStyle: true,
          pointStyle: 'circle',
        },
      },
    },
  }

  return (
    <Container>
      <div style={{ height: '400px' }} className="mb-4">
        <ChartContainer className="fs-exclude">
          <Line data={data} options={options} width="100%" />
        </ChartContainer>
      </div>
      <div className="v-align mb-4" style={{ justifyContent: 'flex-end' }}>
        <IconInfo size={16} />
        <GhostButtonExternalLink
          href="https://client-support.tavahealth.com/hc/en-us/articles/26181549356052-Assessments"
          className="caption"
        >
          Learn more
        </GhostButtonExternalLink>
      </div>
    </Container>
  )
}

const Container = styled('div')`
  height: 100%;
`

const ChartContainer = styled('div')`
  height: 100%;
  width: 100%;
`
