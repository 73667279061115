import { createContext, useContext } from 'react'

import { AssessmentBundleQuery } from '@nuna/api'

interface AssessmentContextValues {
  assessmentBundle: AssessmentBundleQuery['assessmentBundle'] | undefined | null
  navigateWithinAssessmentBundle: (assessmentBundle?: AssessmentBundleQuery['assessmentBundle']) => void
}

const AssessmentsContext = createContext<AssessmentContextValues | undefined>(undefined)
interface AssessmentsProviderProps {
  children: React.ReactNode
  value: {
    assessmentBundle: AssessmentBundleQuery['assessmentBundle'] | undefined | null
    navigateWithinAssessmentBundle: (assessmentBundle?: AssessmentBundleQuery['assessmentBundle']) => void
  }
}

export function AssessmentsProvider({ children, value }: AssessmentsProviderProps) {
  const { assessmentBundle, navigateWithinAssessmentBundle } = value

  return (
    <AssessmentsContext.Provider value={{ assessmentBundle, navigateWithinAssessmentBundle }}>
      {children}
    </AssessmentsContext.Provider>
  )
}

export function useAssessmentsContext() {
  const context = useContext(AssessmentsContext)
  if (!context) {
    throw new Error('useAssessmentsContext must be used within a AssessmentsProvider')
  }
  return context
}
