import { IconProps } from './IconProps'

export function IconHistory({ size = 24, color = 'currentColor', ...props }: IconProps) {
  return (
    <svg width={size} height={size} viewBox="0 0 48 48" fill={color} xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.3429 13.4857L14.1203 12.8797C16.1384 12.5559 17.898 14.269 17.6284 16.295C17.4479 17.6514 16.3866 18.7219 15.0317 18.9138L4.97025 20.3393C3.96581 20.4816 3.01391 19.8494 2.75561 18.8683L-0.0295402 8.2903C-0.297421 7.27288 0.0413975 6.19196 0.842112 5.5095C2.4229 4.16217 4.86948 4.9968 5.29724 7.02934L6.2701 11.6519C10.2135 6.0185 16.7473 2.33337 24.1448 2.33337C36.2186 2.33337 45.9999 12.1147 45.9999 24.1667C45.9999 36.2187 36.2186 46 24.1448 46C14.6171 46 6.52222 39.887 3.54918 31.3718C3.17696 29.8331 3.63387 28.9086 4.79537 28.4814C6.11941 28.0419 7.25332 28.8766 7.72811 30.0875C10.1523 36.8216 16.5949 41.6333 24.1666 41.6333C33.817 41.6333 41.6333 33.817 41.6333 24.1667C41.6333 14.5164 33.817 6.70004 24.1666 6.70004C18.54 6.70004 13.5369 9.35715 10.3429 13.4857ZM25.5882 17.0815C25.5882 15.836 24.5785 14.8263 23.3329 14.8263C22.0874 14.8263 21.0777 15.836 21.0777 17.0815V26.1055C21.0777 26.8082 21.4464 27.4594 22.0491 27.8207L29.5359 32.3103C30.5801 32.9364 31.9314 32.6389 32.616 31.6321C33.3604 30.5372 33.0273 29.0415 31.8887 28.3659L26.0779 24.9181C25.7743 24.738 25.5882 24.4111 25.5882 24.0581V17.0815Z"
      />
    </svg>
  )
}
