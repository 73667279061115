import { styled } from '@mui/material'
import { capitalize } from 'lodash'
import moment from 'moment-timezone'
import React, { useEffect, useState } from 'react'

import { PeriodConstraint } from '@nuna/api'
import { IconButton, IconClose, IconLoading, StatusLabel, makeTypographyComponent } from '@nuna/tunic'

import { useAvailabilityContext } from '../context/AvailabilityContextProvider'

interface AvailabilityPeriodProps extends React.HTMLAttributes<HTMLElement> {
  constraints: PeriodConstraint[]
  onRemoveClick?: (constraint: PeriodConstraint) => void
  providerTimezone: string
  type: 'extra' | 'away'
}

export function AvailabilityPeriods({
  onRemoveClick,
  constraints,
  providerTimezone,
  type,
  ...props
}: AvailabilityPeriodProps) {
  const [constraintsRenderCounter, setConstraintsRenderCounter] = useState(0)

  // hacky thing to force re-render of constraints when they change since there is no unique ID or data to key on when there are duplicate slots
  useEffect(() => {
    setConstraintsRenderCounter(prev => prev + 1)
  }, [constraints])

  return (
    <AvailabilityPeriodContainer {...props}>
      {constraints?.map((constraint, index) => (
        <div className="v-align" key={`${constraintsRenderCounter}-${index}`}>
          <AvailabilityPeriod
            onRemoveClick={onRemoveClick}
            constraint={constraint}
            type={type}
            providerTimezone={providerTimezone}
          />
        </div>
      ))}
    </AvailabilityPeriodContainer>
  )
}

interface Props {
  constraint: PeriodConstraint
  onRemoveClick?: (constraint: PeriodConstraint) => void
  type: 'extra' | 'away'
  providerTimezone: string
}

function AvailabilityPeriod({ onRemoveClick, constraint, type, providerTimezone }: Props) {
  const { updateLoading } = useAvailabilityContext()
  const [isDeleting, setIsDeleting] = useState(false)

  return (
    <>
      <FixedPeriod type={type} range={constraint} providerTimezone={providerTimezone} />

      {onRemoveClick && (
        <IconButton
          tooltip="Remove time slot"
          className="ml-auto"
          small
          variant="secondary"
          disabled={updateLoading}
          onClick={() => {
            setIsDeleting(true)
            onRemoveClick(constraint)
          }}
        >
          {isDeleting ? <IconLoading className="rotate" size={16} /> : <IconClose size={16} />}
        </IconButton>
      )}
    </>
  )
}

function FixedPeriod({
  range,
  type,
  providerTimezone,
}: {
  range: PeriodConstraint
  type: 'extra' | 'away'
  providerTimezone: string
}) {
  const { start, end } = range

  const startDate = moment.tz(start, providerTimezone)
  const endDate = moment.tz(end, providerTimezone)

  let periodContent: JSX.Element

  if (startDate.isSame(endDate, 'date')) {
    // same day: [date] from [startTime] to [endTime]
    periodContent = (
      <>
        <BoldDateInfo>{startDate.format('MMM Do')}</BoldDateInfo> from{' '}
        <BoldDateInfo>{startDate.format('h:mma')}</BoldDateInfo> to{' '}
        <BoldDateInfo>{endDate.format('h:mma')}</BoldDateInfo>
      </>
    )
  } else if (startDate.isSame(startDate.clone().startOf('day')) && endDate.isSame(endDate.clone().endOf('day'))) {
    // all day: [startDate] until [endDate]
    periodContent = (
      <>
        <BoldDateInfo>{startDate.format('MMM Do')}</BoldDateInfo> until{' '}
        <BoldDateInfo>{endDate.format('MMM Do')}</BoldDateInfo>
      </>
    )
  } else {
    // different days: [startDate] at [startTime] until [endTime] on [endDate]
    periodContent = (
      <>
        <BoldDateInfo>{moment(start).format('MMM Do [at] h:mma')}</BoldDateInfo> until{' '}
        <BoldDateInfo>{moment(end).format('h:mma [on] MMM Do')}</BoldDateInfo>
      </>
    )
  }

  return (
    <Period>
      <StatusLabel intent={type === 'away' ? 'urgent' : 'information'}>{capitalize(type)}</StatusLabel> {periodContent}
    </Period>
  )
}

const BoldDateInfo = makeTypographyComponent('text-medium text-default', 'span')
const Period = makeTypographyComponent('text-secondary', 'div')

const AvailabilityPeriodContainer = styled('div')``
