import { css, styled } from '@mui/material'
import { NavLinkProps } from 'react-router-dom'

import { primarySet, salmonSet, tealSet, white } from '../../styles/colorSets'
import { ButtonBase, ButtonExternalLinkBase, ButtonInternalLinkBase, ButtonProps } from './ButtonBase'
import { buttonBackgroundColor, buttonTextColor } from './utils'

export type FillVariant = 'primary' | 'secondary' | 'destroy'

export interface FillButtonProps extends ButtonProps {
  variant?: FillVariant
}

export interface FillButtonLinkProps extends NavLinkProps {
  variant?: FillVariant
}

export interface FillButtonExternalLinkProps extends React.HTMLAttributes<HTMLAnchorElement> {
  variant?: FillVariant
  href: string
}

const styles = ({ variant = 'primary' }: Pick<FillButtonProps, 'variant'>) => css`
  ${buttonTextColor(white, { disabledAlpha: 0.4 })}

  ${(() => {
    if (variant === 'secondary') {
      return buttonBackgroundColor(
        {
          color: tealSet[70].hex,
          focusColor: tealSet.tint[40],
        },
        { hoverColor: tealSet[70].hover },
      )
    }

    if (variant === 'destroy') {
      return buttonBackgroundColor({
        color: salmonSet[80].hex,
        focusColor: salmonSet.tint[40],
      })
    }

    return buttonBackgroundColor(
      {
        color: primarySet[50],
        focusColor: primarySet.tint[40],
      },
      { hoverColor: primarySet.hover },
    )
  })()}
`

export const FillButton = styled(ButtonBase)<FillButtonProps>`
  ${styles}
`

export const FillButtonLink = styled(ButtonInternalLinkBase)<FillButtonLinkProps>`
  ${styles}
`

export const FillButtonExternalLink = styled(ButtonExternalLinkBase)<FillButtonExternalLinkProps>`
  ${styles}
`
