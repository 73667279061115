import { noop } from 'lodash'
import { HTMLAttributes } from 'react'

import { CalendarSyncStatus, Provider } from '@nuna/api'
import { useEnvironmentContext } from '@nuna/environment'
import { Box, ContextualAlert, Grid } from '@nuna/tunic'

import { onAuthWithNylasClick } from '../../../../utils/calendarSync'
import {
  CalendarSyncButton,
  NylasCalendarProvider,
  NylasCalendarProviderOptions,
  NylasV3CalendarMapping,
} from './CalendarSyncButton'
import { CalendarSyncHeader } from './CalendarSyncHeader'

interface Props extends HTMLAttributes<HTMLDivElement> {
  provider: Provider
  status: CalendarSyncStatus
}

export function CalendarSyncExpired({ provider, status }: Props) {
  const { API_ENDPOINT } = useEnvironmentContext()

  const nylasV3Mapped = NylasV3CalendarMapping[provider?.calendarProvider ?? 'miss']
  const calendarProvider = (nylasV3Mapped ?? provider.calendarProvider) as NylasCalendarProvider

  const submitCalendar = (type: NylasCalendarProvider, email: string) => {
    onAuthWithNylasClick(API_ENDPOINT, type, email, provider.id)
  }

  const refreshCalendar = () => {
    onAuthWithNylasClick(API_ENDPOINT, calendarProvider, provider.personalEmail ?? '', provider.id)
  }

  return (
    <Box>
      <CalendarSyncHeader status={status} provider={provider} onRefresh={noop} />

      <ContextualAlert
        intent="urgent"
        role="status"
        dismissButtonText="Sign In"
        dismissType="text"
        onDismiss={refreshCalendar}
        fullWidth
      >
        Your session has expired. Please sign in to your calendar provider to sync.
      </ContextualAlert>

      <p className="text-secondary mb-2 mt-4">Current Calendar Provider</p>
      <Grid container spacing={5} style={{ margin: -8 }}>
        {
          <CalendarSyncButton
            type={calendarProvider}
            submit={submitCalendar}
            personalEmail={provider.personalEmail ?? ''}
          />
        }
      </Grid>

      <p className="text-secondary mb-2 mt-4">Use a different provider</p>

      <Grid container spacing={5} style={{ margin: -8 }}>
        {NylasCalendarProviderOptions.filter(it => it !== calendarProvider).map(type => (
          <CalendarSyncButton type={type} submit={submitCalendar} />
        ))}
      </Grid>
    </Box>
  )
}
