import { useFormikContext } from 'formik'
import React from 'react'

import { BasicPatientFragment } from '@nuna/api'

import { DrawerActions } from '../../DrawerActions'
import { AppointmentFormValues, NewCard } from '../shared'
import { NewAppointmentTimeForm } from './NewAppointmentTimeForm'

interface SingleAppointmentSchedulerProps {
  patient: BasicPatientFragment
  onSubmit: React.FormEventHandler<HTMLFormElement>
  providerId: string
  isLoading: boolean
}

export function NewAppointmentSingleScheduler({
  patient,
  onSubmit,
  providerId,
  isLoading,
}: SingleAppointmentSchedulerProps) {
  const { values, setFieldValue } = useFormikContext<AppointmentFormValues>()

  return (
    <form onSubmit={onSubmit}>
      <NewCard className="mb-3">
        <NewAppointmentTimeForm
          providerId={providerId}
          value={values.singleStartTime ?? ''}
          onChange={startTime => setFieldValue('singleStartTime', startTime)}
          locationOnChange={addressId => setFieldValue('singleAddressId', addressId)}
          locationValue={values.singleAddressId ?? 'Virtual'}
          patient={patient}
        />
      </NewCard>

      <DrawerActions
        primary={{
          isLoading,
          disabled: !values.singleStartTime,
          type: 'submit',
          children: 'Schedule Appointment',
        }}
        caption={{
          children: <>We'll let {patient.firstName} know.</>,
        }}
      />
    </form>
  )
}
