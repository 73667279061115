import { css, keyframes, styled } from '@mui/material'
import React, { ReactNode, useEffect, useState } from 'react'
import { NavLink, NavLinkProps } from 'react-router-dom'

import { IconLoading } from '../../icons'
import { sansSerifFontStack } from '../../styles/global/typography.utils'
import { csx } from '../../utils/csx'
import { styledUtils } from '../../utils/styled'

export interface ButtonProps extends React.HTMLAttributes<HTMLButtonElement> {
  isLoading?: boolean
  type?: 'button' | 'submit' | 'reset'
  htmlFor?: string // the 'as' prop is not getting the types right so this is needed.
  disabled?: boolean
  leadingIcon?: boolean
  trailingIcon?: boolean
  'data-testid'?: string
}

export const ButtonWithLoader = React.forwardRef<HTMLButtonElement, ButtonProps>(
  (
    {
      isLoading = false,
      type = 'button',
      disabled = false,
      leadingIcon = false,
      trailingIcon = false,
      children,
      ...props
    },
    ref,
  ) => {
    const [showLoader, setShowLoader] = useState(false)

    useEffect(() => {
      if (isLoading) {
        setShowLoader(true)
      }

      if (!isLoading && showLoader) {
        const timeout = setTimeout(() => {
          setShowLoader(false)
        }, 400)

        return () => clearTimeout(timeout)
      }
    }, [isLoading, showLoader])

    return (
      <button aria-busy={showLoader ? 'true' : 'false'} disabled={disabled} type={type} ref={ref} {...props}>
        {showLoader && <SpinningLoader size={16} />}
        <InnerButton
          leadingIcon={leadingIcon}
          trailingIcon={trailingIcon}
          className={csx([{ hidden: showLoader }, 'inner-button'])}
        >
          {children}
        </InnerButton>
      </button>
    )
  },
)

interface InternalButtonLinkProps extends Omit<NavLinkProps, 'children'> {
  children: ReactNode
}
export const InternalButtonLink = React.forwardRef<HTMLAnchorElement, InternalButtonLinkProps>(
  ({ children, ...props }, ref) => {
    return (
      <NavLink ref={ref} {...props}>
        <InnerButton className="inner-button">{children}</InnerButton>
      </NavLink>
    )
  },
)

interface ExternalButtonLinkProps extends React.HTMLAttributes<HTMLAnchorElement> {
  includeReferrer?: boolean
}
export const ExternalButtonLink = React.forwardRef<HTMLAnchorElement, ExternalButtonLinkProps>(
  ({ children, includeReferrer, ...props }, ref) => {
    return (
      // Overriding to allow referrer for external sites that we trust/control (like P4 pages)
      <a ref={ref} target="_blank" rel={includeReferrer ? undefined : 'noreferrer noopener'} {...props}>
        <InnerButton className="inner-button">{children}</InnerButton>
      </a>
    )
  },
)

const borderRadius = '26px 26px 6px 26px'

const buttonStyles = css`
  align-items: center;
  border: 1px solid;
  border-radius: ${borderRadius};
  cursor: pointer;
  display: inline-flex;
  font-family: ${sansSerifFontStack};
  font-size: 1.125rem; // 18px
  font-weight: 500;
  height: 45px;
  justify-content: center;
  padding: 0 1rem;
  position: relative;
  text-align: center;
  text-decoration: none;
  white-space: nowrap;

  &:hover {
    text-decoration: none;
  }

  &.focus-visible {
    outline: none;

    &::after {
      border-radius: ${borderRadius};
      content: '';
      display: block;
      height: calc(100% + 4px);
      left: -2px;
      position: absolute;
      top: -2px;
      width: calc(100% + 4px);
    }
  }

  &:disabled,
  &.disabled {
    cursor: not-allowed;

    &:hover {
      box-shadow: none;
    }
    &.focus-visible {
      &::after {
        display: none;
      }
    }
  }
`

export const ButtonBase = styled(ButtonWithLoader)`
  ${buttonStyles}
`

export const ButtonInternalLinkBase = styled(InternalButtonLink)`
  ${buttonStyles}
`

export const ButtonExternalLinkBase = styled(ExternalButtonLink)`
  ${buttonStyles}
`

const InnerButton = styled('span')<Pick<ButtonProps, 'leadingIcon' | 'trailingIcon'>>`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  line-height: 1;
  z-index: 1;

  &.hidden {
    visibility: hidden;
  }

  ${props =>
    props.leadingIcon &&
    `
    svg {
      margin-right: 0.375rem;
    }
  `}

  ${props =>
    props.trailingIcon &&
    `
    svg {
      margin-left: 0.25rem;
      margin-right: -0.25rem;
    }
  `}
`

const spin = keyframes`
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
`
const { transientPropOptions } = styledUtils

export const SpinningLoader = styled(IconLoading, transientPropOptions)<{ $inFlow?: boolean }>`
  animation: ${spin} 2s linear infinite;
  position: ${({ $inFlow = false }) => ($inFlow ? 'static' : 'absolute')};
`
