import { styled } from '@mui/material'

import { StatusLabel } from '@nuna/tunic'

import { videoSceneTeal } from '../../../util/colors'

export function ConnectionStatus({
  name,
  isOnline,
  canSeeStatus = true,
}: {
  name: string
  isOnline: boolean
  canSeeStatus?: boolean
}) {
  return (
    <ConnectionText className="h5 v-align mb-2 fs-exclude">
      {(() => {
        if (!canSeeStatus) {
          return name
        }

        return (
          <>
            {name} is{' '}
            <StatusLabel className="ml-1" intent={isOnline ? 'information' : 'urgent'} scheme="dark">
              {isOnline ? 'Online' : 'Offline'}
            </StatusLabel>
          </>
        )
      })()}
    </ConnectionText>
  )
}

const ConnectionText = styled('p')`
  color: ${videoSceneTeal};
  justify-content: center;
`
