import { SessionIndexTabs } from '../scenes/Sessions/SessionIndex'

export const login = '/login'

export const signupIntake = '/signup-intake'
export const signupIntakeSupportWelcome = `${signupIntake}/welcome-support`

export const sessionIndex = (selectedTab: SessionIndexTabs) => {
  return `/sessions/view/${selectedTab}`
}

export const scheduleNewAppointmentFromCalendar = () => {
  return `/schedule/new`
}

export const customerIntegrationDashboard = () => {
  return `/customer/integrations`
}

export const availabilityDetail = () => {
  return '/availability'
}

export const adminScheduleDetail = (appointmentId?: string) => {
  if (appointmentId) {
    return `/admin/schedule/${appointmentId}`
  } else {
    return `/admin/schedule`
  }
}

export const appointmentDetail = (appointmentId: string) => {
  return `/appointment/${appointmentId}`
}

export const newProvider = () => {
  return `/admin/providers/new`
}

export const newCustomer = () => {
  return `/admin/customers/new`
}

export const overlappingSessions = () => {
  return '/overlapping-sessions'
}

export const companyDetail = (id: string) => {
  return `/admin/customers/${id}`
}

export const providerProfile = (inEditMode?: boolean) => {
  return `/me/profile${inEditMode ? '?edit=true' : ''}`
}

export const videoBio = (inEditMode?: boolean) => {
  return `/video-intro${inEditMode ? '?edit=true' : ''}`
}

export const clientDetail = (id: string) => `/clients/${id}`

export const employeeMatch = () => `/admin/employee-match`

export const payrollSummary = () => {
  return `/me/payouts`
}

export const licenses = () => `/me/licenses`
export const insurance = () => `/me/insurance`

export const preferences = () => `/me/preferences`

export const payrollDetail = (payrollId: string) => {
  return `/me/payouts/${payrollId}`
}
