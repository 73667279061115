/* eslint-disable react/jsx-no-useless-fragment */
import { styled } from '@mui/material'
import { DialogTitle } from '@mui/material'
import { startCase } from 'lodash'
import { HTMLAttributes, useMemo, useState } from 'react'

import {
  AcceptedAddressEnrollmentSummary,
  AddressEnrollmentStatus,
  InsurancePayerAcceptedByProvider,
  InsurancePayersProviderAcceptsQuery,
  useInsurancePayersProviderAcceptsQuery,
} from '@nuna/api'
import { addressService } from '@nuna/core'
import { DataTable, DataTableColumn } from '@nuna/data-table'
import {
  Dialog,
  DialogContent,
  DialogProps,
  IconArmchair,
  IconButton,
  IconClock,
  IconClose,
  IconHalfway,
  PersistentAlertButton,
  StatusLabel,
  Tooltip,
  csx,
} from '@nuna/tunic'

interface Props extends HTMLAttributes<HTMLSpanElement> {
  addressId?: string
  providerId?: string
}

export function LocationInsuranceIndicator({ providerId, addressId, ...props }: Props) {
  const [dialogOpen, setDialogOpen] = useState(false)
  const { data } = useInsurancePayersProviderAcceptsQuery({
    variables: { providerId: providerId ?? '' },
    skip: !providerId,
  })

  const payerData = getPayerData(data?.insurancePayersProviderAccepts, addressId)

  const hasInsurance = !!data?.insurancePayersProviderAccepts?.length

  const totalPayers = payerData.length
  const enrolledPayers = payerData.filter(payer => payer.addressEnrollment.enrollmentStatus === 'Enrolled').length

  if (!hasInsurance) {
    return null
  }

  const [tooltipContent, customIcon] = (() => {
    if (enrolledPayers === 0) {
      return ['Awaiting approval from insurance payers.', <IconClock size={16} />]
    }
    if (enrolledPayers < totalPayers) {
      return ['Approved for use by some insurance payers. Awaiting approval from others.', <IconHalfway size={16} />]
    }

    return ['Approved for use by all insurance payers accepted.', undefined]
  })()

  return (
    <>
      <Tooltip content={tooltipContent}>
        <ButtonContainer {...props} className={csx([props.className, { 'no-click-event': payerData.length === 0 }])}>
          <PersistentAlertButton icon={customIcon} intent="success" small onClick={() => setDialogOpen(true)}>
            Insurance
          </PersistentAlertButton>
        </ButtonContainer>
      </Tooltip>
      <PayerStatusDialog onClose={() => setDialogOpen(false)} isOpen={dialogOpen} payerData={payerData} />
    </>
  )
}

const ButtonContainer = styled('span')`
  &.no-click-event {
    button {
      pointer-events: none;
    }
  }
`

interface PayerStatusDialogProps extends DialogProps {
  payerData: ReturnType<typeof getPayerData>
}
function PayerStatusDialog({ payerData, ...props }: PayerStatusDialogProps) {
  const address = payerData[0]?.addressEnrollment.address

  const columns = useMemo<DataTableColumn<(typeof payerData)[0]>[]>(
    () => [
      { Header: 'Payer', accessor: 'payer', Cell: ({ value }) => <>{value.name}</> },
      {
        Header: 'Status',
        accessor: 'addressEnrollment',
        Cell: ({ value }) => (
          <StatusLabel intent={value.enrollmentStatus === AddressEnrollmentStatus.Enrolled ? 'information' : 'default'}>
            {startCase(value.enrollmentStatus)}
          </StatusLabel>
        ),
      },
    ],
    [],
  )

  if (!address) {
    return null
  }

  return (
    <Dialog {...props} PaperProps={{ style: { minWidth: 400 } }}>
      <DialogTitle>
        <div className="v-align full-width space-between">
          <div>
            <h5 className="body large text-medium text-teal v-align mb-0">
              <IconArmchair className="mr-1" style={{ flexShrink: 0 }} />
              {address.name}
            </h5>
            <p className="caption text-medium">{addressService.formatAddress(address)}</p>
          </div>
          <IconButton
            style={{ alignSelf: 'flex-start', marginTop: -8, marginRight: -8 }}
            onClick={e => {
              if (props.onClose) {
                props?.onClose(e, 'backdropClick')
              }
            }}
            tooltip="Close"
          >
            <IconClose />
          </IconButton>
        </div>
      </DialogTitle>
      <DialogContent>
        <DataTable loading={false} columns={columns} rowData={payerData} />
      </DialogContent>
    </Dialog>
  )
}

function getPayerData(
  payers?: InsurancePayersProviderAcceptsQuery['insurancePayersProviderAccepts'],
  addressId?: string,
): {
  payer: Pick<InsurancePayerAcceptedByProvider, 'id' | 'name'>
  addressEnrollment: AcceptedAddressEnrollmentSummary
}[] {
  return (payers ?? [])
    .filter(payer => !!payer.addressEnrollments.find(addressEnrollment => addressEnrollment.address.id === addressId))
    .map(payer => ({
      payer,
      addressEnrollment: payer.addressEnrollments.find(
        addressEnrollment => addressEnrollment.address.id === addressId,
      ) as AcceptedAddressEnrollmentSummary,
    }))
}
