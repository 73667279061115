import { HtmlHTMLAttributes } from 'react'
import { NavLink, Path } from 'react-router-dom'

export interface LinkProps extends HtmlHTMLAttributes<HTMLAnchorElement> {
  to: string | undefined | Path
  openInNewTab?: boolean
  includeReferrer?: boolean
}

export function Link({ to = '', openInNewTab = false, includeReferrer = false, children, ...props }: LinkProps) {
  if (openInNewTab) {
    return (
      // Overriding to allow referrer for external sites that we trust/control (like P4 pages)
      // eslint-disable-next-line react/jsx-no-target-blank
      <a href={to as string} target="_blank" rel={includeReferrer ? undefined : 'noreferrer noopener'} {...props}>
        {children}
      </a>
    )
  }

  return (
    <NavLink to={to} {...props}>
      {children}
    </NavLink>
  )
}
