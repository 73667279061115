import { ReactNode, createContext, useContext } from 'react'

import { PatientDetailsFragment } from '@nuna/api'
import { Audience } from '@nuna/core'

export type CoverageFormPatient = Pick<
  PatientDetailsFragment,
  'id' | 'firstName' | 'lastName' | 'state' | 'dob' | 'company'
> & { intakeCompleted?: boolean } & Partial<Pick<PatientDetailsFragment, 'providers'>>

export type PendingPatient = Pick<PatientDetailsFragment, 'firstName' | 'lastName' | 'state' | 'dob'> & {
  intakeCompleted?: boolean
}

interface CoverageFormContext {
  audience: Audience
  patient: CoverageFormPatient | PendingPatient
  inDrawer: boolean
  state: string
  hidePreferenceRadios: boolean
  providerAcceptsInsurance: boolean
  providerAcceptsReferrals: boolean
}

const CoverageFormContext = createContext<CoverageFormContext>({} as CoverageFormContext)

export interface CoverageFormContextProviderProps {
  audience?: Audience
  patient: CoverageFormPatient | PendingPatient
  children: ReactNode
  inDrawer?: boolean
  state?: string
  hidePreferenceRadios?: boolean
  providerAcceptsInsurance?: boolean
  providerAcceptsReferrals?: boolean
}

export function CoverageFormContextProvider({
  audience = 'client',
  children,
  patient,
  inDrawer = false,
  state = '',
  hidePreferenceRadios = false,
  providerAcceptsInsurance = true,
  providerAcceptsReferrals = true,
}: CoverageFormContextProviderProps) {
  return (
    <CoverageFormContext.Provider
      value={{
        audience,
        patient,
        inDrawer,
        state: patient.state ?? state,
        hidePreferenceRadios,
        providerAcceptsInsurance,
        providerAcceptsReferrals,
      }}
    >
      {children}
    </CoverageFormContext.Provider>
  )
}

export function useCoverageFormContext() {
  return useContext(CoverageFormContext)
}
