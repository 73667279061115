import { useMemo } from 'react'

import { AppointmentForDrawerQuery, PaymentPreference } from '@nuna/api'
import { AppointmentCard, AppointmentCardStatus } from '@nuna/appointment'
import { NavigationLink, ProviderClientPaymentStatusAlert, useAppointmentDrawerSearchParams } from '@nuna/common'
import { Audience, routeService } from '@nuna/core'
import {
  FillButton,
  GhostButton,
  GhostButtonLink,
  IconCancelAppointment,
  IconNotebook,
  IconRescheduleAppointment,
  IconVideo,
} from '@nuna/tunic'

import { AppointmentDrawerPaddedContainer } from '../../../AppointmentDrawer'
import { useMostRecentSessionNote } from '../../../hooks/useMostRecentSessionNote'
import { ClientDrawerHeader } from '../../ClientDrawerHeader'
import { DrawerFooter } from '../../DrawerFooter'
import { ClientCapInfo } from './ClientCapInfo'
import { mapAppointmentStatusToCardStatus } from './utils'

interface Props {
  appointment: AppointmentForDrawerQuery['internalAppointment']
  audience: Audience
}

export function FutureAppointment({ appointment, audience }: Props) {
  const { openCancelAppointmentDrawer, openRescheduleAppointmentDrawer } = useAppointmentDrawerSearchParams()
  const mostRecentAppointmentWithNote = useMostRecentSessionNote(appointment?.patient.id, appointment?.provider.id)

  const appointmentStatus = useMemo(
    () =>
      appointment?.currentStatus
        ? mapAppointmentStatusToCardStatus(appointment?.currentStatus)
        : AppointmentCardStatus.Active,
    [appointment?.currentStatus],
  )
  const paymentStatus = appointment?.patient?.paymentStatus

  if (!appointment?.patient) {
    return null
  }

  return (
    <>
      <ClientDrawerHeader client={appointment.patient} />
      {appointment.coverageType === PaymentPreference.Employer && (
        <ClientCapInfo className="px-3" clientId={appointment.patient.id} />
      )}
      {!!paymentStatus && (
        <AppointmentDrawerPaddedContainer className="mt-3">
          <ProviderClientPaymentStatusAlert scene={'clientInfo'} paymentStatus={paymentStatus} />
        </AppointmentDrawerPaddedContainer>
      )}
      <AppointmentDrawerPaddedContainer className="mt-3">
        <AppointmentCard
          appointment={appointment}
          appointmentStatus={appointmentStatus}
          audience={audience}
          client={appointment.patient}
          provider={appointment.provider}
          showAvatar={false}
          showTopStatus={appointmentStatus === AppointmentCardStatus.Canceled}
          boot={appointmentStatus !== AppointmentCardStatus.Canceled}
        />
        {appointment.address?.id && (
          <NavigationLink
            address={appointment.address}
            containerProps={{ className: 'mt-3 mb-2 v-align' }}
            labelOverride="Get Directions"
            loadingMessages={[]}
            buttonElement={FillButton}
            buttonProps={{ className: 'v-align' }}
          />
        )}
        <GhostButton
          className="mt-4"
          onClick={() => openRescheduleAppointmentDrawer(appointment.id)}
          data-testid="reschedule-appointment-button"
        >
          <IconRescheduleAppointment className="mr-1" isHovered={false} />
          Reschedule Appointment
        </GhostButton>
        {appointmentStatus !== AppointmentCardStatus.Canceled && (
          <GhostButton
            className="mt-2"
            variant="secondary"
            onClick={() => openCancelAppointmentDrawer(appointment.id)}
            data-testid="cancel-appointment-button"
          >
            <IconCancelAppointment className="mr-1" isHovered={false} />
            Cancel Appointment
          </GhostButton>
        )}
      </AppointmentDrawerPaddedContainer>
      <DrawerFooter>
        <GhostButtonLink variant="secondary" to={routeService.videoSession(appointment.id)}>
          <IconVideo className="mr-1" />
          Video Session Link
        </GhostButtonLink>
        {mostRecentAppointmentWithNote?.sessionId && (
          <GhostButtonLink
            className="mt-2"
            variant="secondary"
            to={routeService.sessionNote(mostRecentAppointmentWithNote.sessionId, audience)}
          >
            <IconNotebook className="mr-1" />
            Review Last Session Note
          </GhostButtonLink>
        )}
      </DrawerFooter>
    </>
  )
}
