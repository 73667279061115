import { styled } from '@mui/material'
import { HTMLAttributes, ReactNode } from 'react'

import { borderGrey } from '../styles/colors'
import { makeTypographyComponent } from './TypographyComponent'

interface InfoWithHeadingProps extends HTMLAttributes<HTMLDivElement> {
  heading?: string
  info?: ReactNode
  secondaryInfo?: string | ReactNode | null
  tertiaryInfo?: string | ReactNode | null
  renderInfo?: ReactNode
  label?: ReactNode
}

export const InfoWithHeading = ({
  heading,
  info,
  secondaryInfo,
  tertiaryInfo,
  renderInfo,
  className = '',
  label,
  ...props
}: InfoWithHeadingProps) => (
  <>
    <div className={`v-align ${label ? 'mb-1' : 'mb-2'} ${className}`} {...props}>
      {heading && <RowHeading>{heading}</RowHeading>}
      {renderInfo ? renderInfo : <PrimaryInfo>{info}</PrimaryInfo>}
      {secondaryInfo && <SecondaryInfo>{secondaryInfo}</SecondaryInfo>}
      {tertiaryInfo && <SecondaryInfo>{tertiaryInfo}</SecondaryInfo>}
    </div>
    {label && (
      <div className={`v-align mb-2 ${className}`}>
        <RowHeading> </RowHeading>
        {label}
      </div>
    )}
  </>
)

export const PrimaryInfo = makeTypographyComponent('text-medium text-secondary', 'span')
export const SecondaryInfo = styled(makeTypographyComponent('text-secondary', 'span'))`
  margin-left: var(--margin-2);
  padding-left: var(--margin-2);
  border-left: 1px solid ${borderGrey};
`
export const RowHeading = styled(makeTypographyComponent('body caption text-medium text-medium-grey mb-0', 'h4'))`
  width: 150px;
`
