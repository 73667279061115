import * as Yup from 'yup'
import { styled } from '@mui/material'
import { Formik } from 'formik'
import moment, { Moment } from 'moment'
import { useSearchParams } from 'react-router-dom'

import { usePostPaymentToEnterHealthMutation } from '@nuna/api'
import { formService, numberService } from '@nuna/core'
import {
  Checkbox,
  DatePicker,
  Drawer,
  DrawerProps,
  FillButton,
  Grid,
  IconButton,
  IconClose,
  IconPayment,
  OutlineButton,
  TextField,
  eggshell,
  toast,
} from '@nuna/tunic'

export type RecordPaymentSearchParams = 'recordPayment' | 'chargeAmount' | 'appointmentId'

interface Props extends Omit<DrawerProps, 'isOpen' | 'onClose'> {
  patientId: string
}

export const recordPaymentSchema = Yup.object({
  paymentDate: Yup.date().required('Payment date is required'),
  paymentAmount: Yup.string().required('Payment amount is required'),
  paymentTransactionId: Yup.string().required('Transaction ID is required'),
  applyToClaim: Yup.boolean().required('Apply to claim is required'),
})

export interface PaymentFormValues {
  paymentDate: Moment
  paymentAmount: string
  paymentTransactionId: string
  applyToClaim: boolean
}

export function RecordPaymentDrawer({ patientId, ...props }: Props) {
  const [postPaymentToEnterHealth, { loading }] = usePostPaymentToEnterHealthMutation()
  const [searchParams, setSearchParams] = useSearchParams()

  const recordPayment = searchParams.get('recordPayment')
  const appointmentId = searchParams.get('appointmentId')

  const handleClose = () => {
    setSearchParams(oldSearchParams => {
      oldSearchParams.delete('recordPayment')
      oldSearchParams.delete('appointmentId')
      return oldSearchParams
    })
  }

  const handleSubmit = async (values: PaymentFormValues) => {
    try {
      const amountInCents = numberService.dollarsToCents(values.paymentAmount)
      const response = await postPaymentToEnterHealth({
        variables: {
          details: {
            patientId,
            amountInCents,
            paymentDate: values.paymentDate.format('YYYY-MM-DD'),
            paymentTransactionId: values.paymentTransactionId,
            appointmentId: values.applyToClaim ? appointmentId : undefined,
          },
        },
      })
      if (!response.data?.postPaymentToEnterHealth.id) {
        console.error(response)
        throw new Error('Failed to record payment')
      }

      toast.success('Payment recorded.  It may take a few minutes to reflect on the billing screen.')
      handleClose()
    } catch (error) {
      console.error(error)
      toast.urgent('Failed to record payment')
    }
  }

  const { composeHelperTextWithError } = formService

  return (
    <StyledDrawer size="min(400px, 100vw)" isOpen={!!recordPayment} onClose={handleClose} {...props}>
      <Formik
        initialValues={{
          paymentDate: moment(),
          paymentAmount: (parseFloat(searchParams.get('chargeAmount') ?? '0.0') / 100.0).toString(),
          paymentTransactionId: '',
          applyToClaim: true,
        }}
        validationSchema={recordPaymentSchema}
        onSubmit={handleSubmit}
      >
        {formikProps => {
          const { values, handleSubmit, setFieldValue, touched, errors, getFieldProps } = formikProps

          return (
            <form onSubmit={handleSubmit}>
              <Grid container className="px-3 py-2 v-align" spacing={4}>
                <Grid className="v-align space-between" size={12}>
                  <h2 className="h5 mb-0 v-align">
                    <IconPayment className="mr-1" /> Record Payment
                  </h2>
                  <IconButton variant="secondary" tooltip="Close" onClick={handleClose}>
                    <IconClose size={20} />
                  </IconButton>
                </Grid>
                <Grid size={12}>
                  <p className="text-muted mb-0 text-secondary">
                    Record a payment for this claim that was previously collection, but not reported against the claim
                    or client in question. This will <strong>not</strong> cause the card on file to be charged. Once
                    recorded, the claim should be updated to reflect that it has been paid. Payments that cover several
                    claims can also be recorded by unchecking the "Only apply to this claim" checkbox.
                  </p>
                </Grid>
                <Grid size={12}>
                  <DatePicker
                    label="Payment Date"
                    onChange={date => setFieldValue('paymentDate', moment(date).startOf('day'))}
                    format={'MM/DD/YYYY'}
                    value={values.paymentDate}
                    placeholder="MM/DD/YYYY"
                    {...composeHelperTextWithError('', errors.paymentDate, !!touched.paymentDate)}
                  />
                </Grid>
                <Grid size={12}>
                  <TextField
                    label="Payment Amount"
                    {...getFieldProps('paymentAmount')}
                    {...composeHelperTextWithError('', errors.paymentAmount, !!touched.paymentAmount)}
                  />
                </Grid>
                <Grid size={12}>
                  <TextField
                    label="Transaction ID"
                    {...getFieldProps('paymentTransactionId')}
                    {...composeHelperTextWithError('', errors.paymentTransactionId, !!touched.paymentTransactionId)}
                  />
                </Grid>
                <Grid size={12}>
                  <Checkbox
                    {...getFieldProps('applyToClaim')}
                    {...composeHelperTextWithError('', errors.applyToClaim, !!touched.applyToClaim)}
                  >
                    Only apply to this claim
                  </Checkbox>
                </Grid>
                <Grid className="flex-column gap-1" size={12}>
                  <FillButton type="submit" className="full-width" isLoading={loading} disabled={loading}>
                    Record
                  </FillButton>
                  <OutlineButton type="button" className="full-width" onClick={handleClose}>
                    Cancel
                  </OutlineButton>
                </Grid>
              </Grid>
            </form>
          )
        }}
      </Formik>
    </StyledDrawer>
  )
}

const StyledDrawer = styled(Drawer)`
  .MuiPaper-root {
    background-color: ${eggshell};
  }
`
