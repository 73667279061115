import { AppointmentCurrentStatus } from '@nuna/api'
import { AppointmentCardStatus } from '@nuna/appointment'

export const mapAppointmentStatusToCardStatus = (status: AppointmentCurrentStatus) => {
  if (
    [
      AppointmentCurrentStatus.CanceledByAdmin,
      AppointmentCurrentStatus.CanceledByPatient,
      AppointmentCurrentStatus.CanceledByProvider,
    ].includes(status)
  ) {
    return AppointmentCardStatus.Canceled
  }
  return AppointmentCardStatus.Active
}
