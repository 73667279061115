import { useState } from 'react'

import {
  ProviderCompensationRateType,
  ProviderNetworkStatus,
  useProviderCompensationRatesQuery,
  useProviderMeQuery,
} from '@nuna/api'
import { numberService, routeService } from '@nuna/core'
import { GhostButton, GhostButtonLink, IconInfo, Skeleton, Tooltip } from '@nuna/tunic'

import { PayrollSummaryCollapsableSection, RateDetail, RateDetailGrid, SubHeading } from '../shared'
import { InsuranceFeesModal } from './InsuranceFeesModal'
import { TavaReferralCTABanner } from './TavaReferralCTABanner'

const currencyFormatOptions = {
  maximumFractionDigits: 2,
}

const SHOW_RATES_STATUSES = [ProviderNetworkStatus.Approved, ProviderNetworkStatus.Offboarding]

interface PayRatesProps {
  providerId: string
}

export function RatesAndFees({ providerId }: PayRatesProps) {
  const { data: providerData } = useProviderMeQuery()
  const { data, loading } = useProviderCompensationRatesQuery({ variables: { providerId } })
  const [insuranceReferralRatesModalOpen, setInsranceReferralRatesModalOpen] = useState(false)

  const networkStatus = providerData?.providerMe.networkStatus

  const unsortedTavaRates = data?.providerCompensationRates?.tavaCompensations.slice() || []
  const sortedTavaRates = unsortedTavaRates.sort((r1, r2) => r2.cptCode?.duration ?? 0 - (r1.cptCode?.duration ?? 0))

  const unsortedReferralRates = data?.providerCompensationRates?.selfReferredRates.slice() || []
  const sortedReferralRates = unsortedReferralRates.sort((r1, r2) => r1.rate - r2.rate)
  const cashReferralRates = sortedReferralRates.filter(r => r.type === ProviderCompensationRateType.CashPay)
  const insuranceReferralRates = sortedReferralRates.filter(r => r.type === ProviderCompensationRateType.Insurance)

  const unsortedClientFees = data?.providerCompensationRates?.clientFees.slice() || []
  const sortedClientFees = unsortedClientFees.sort((r1, r2) => r1.rate - r2.rate)

  if (loading) return <Skeleton className="mt-4 full-width" height={10} />

  return (
    <>
      <PayrollSummaryCollapsableSection headerText="Payout Rates">
        {!networkStatus && <Skeleton height={4} />}
        {networkStatus && !SHOW_RATES_STATUSES.includes(networkStatus) && (
          <TavaReferralCTABanner providerId={providerData?.providerMe.id} />
        )}
        {networkStatus && networkStatus === ProviderNetworkStatus.Approved && (
          <>
            <SubHeading>Tava Referral</SubHeading>
            <RateDetailGrid>
              {sortedTavaRates.map(compensation => (
                <RateDetail
                  key={compensation.id}
                  description={compensation?.cptCode?.shortDescription}
                  value={numberService.centsToFormattedDollars(compensation?.rate, currencyFormatOptions)}
                />
              ))}
            </RateDetailGrid>
          </>
        )}

        {sortedReferralRates.length > 0 && (
          <>
            <SubHeading style={{ marginTop: 28 }} className="v-align">
              Clients I Bring{' '}
              <Tooltip content="Clients who sign up from my practice website">
                <span className="ml-1 v-align">
                  <IconInfo size={18} />
                </span>
              </Tooltip>
            </SubHeading>
            <RateDetailGrid>
              {cashReferralRates.map(compensation => (
                <RateDetail
                  key={compensation.type}
                  value={
                    <GhostButtonLink to={routeService.providerCustomRates} variant="secondary">
                      {numberService.centsToFormattedDollars(compensation?.rate, currencyFormatOptions)}
                    </GhostButtonLink>
                  }
                  description={`${compensation.shortDescription} Session`}
                />
              ))}
              {insuranceReferralRates.length > 0 && (
                <RateDetail
                  value={
                    <GhostButton
                      type="button"
                      variant="secondary"
                      onClick={() => setInsranceReferralRatesModalOpen(true)}
                    >
                      See rates
                    </GhostButton>
                  }
                  description="Insurance Session"
                />
              )}
            </RateDetailGrid>
          </>
        )}
      </PayrollSummaryCollapsableSection>
      {sortedClientFees.length > 0 && (
        <PayrollSummaryCollapsableSection headerText="Client Fees">
          <RateDetailGrid>
            {sortedClientFees.map(compensation => (
              <RateDetail
                key={compensation.type}
                description={compensation.shortDescription}
                value={
                  <GhostButtonLink to={routeService.providerFeePolicies} variant="secondary">
                    {numberService.centsToFormattedDollars(compensation.rate, currencyFormatOptions)}
                  </GhostButtonLink>
                }
              />
            ))}
          </RateDetailGrid>
        </PayrollSummaryCollapsableSection>
      )}
      {insuranceReferralRates.length > 0 && (
        <InsuranceFeesModal
          rates={insuranceReferralRates}
          isOpen={insuranceReferralRatesModalOpen}
          onClose={() => setInsranceReferralRatesModalOpen(false)}
        />
      )}
    </>
  )
}
