import { useDaily } from '@daily-co/daily-react'

import { DelayedRender } from '@nuna/common'
import { supportService } from '@nuna/telemetry'
import { OutlineButton, TextButtonExternalLink } from '@nuna/tunic'

import { useVideoCallContext } from '../context/VideoCallContext'
import permissionsIllustration from '../img/permissions-illustration.svg'
import { getDailyDevicePreferences } from '../util/devicePreferences'
import { VideoDialog } from './VideoDialog'

export function DevicePermissions() {
  const callObject = useDaily()
  const { setIsCameraStarted } = useVideoCallContext()

  const startCamera = () =>
    callObject?.startCamera(getDailyDevicePreferences({ forceDeviceOn: true })).then(() => setIsCameraStarted(true))

  return (
    <DelayedRender>
      <VideoDialog isOpen>
        <img src={permissionsIllustration} alt="" className="full-width" />

        <h3 className="h5 mt-3">
          For others to see and hear you, your browser will request access to your camera and mic.
        </h3>
        <p className="mt-2 mb-4 text-light-gray text-light">You can toggle them on/off at any point</p>

        <OutlineButton variant="dark" onClick={startCamera} className="full-width">
          Give Access
        </OutlineButton>

        <p className="caption mt-2 mb-0 text-center">
          <TextButtonExternalLink scheme="dark" variant="secondary" href={supportService.articles.cameraPermissions}>
            Need help?
          </TextButtonExternalLink>
        </p>
      </VideoDialog>
    </DelayedRender>
  )
}
