import { IconProps } from './IconProps'

export function IconSwitchRole({ size = 24, color = 'currentColor', ...props }: IconProps) {
  return (
    <svg width={size} height={size} viewBox="0 0 56 56" fill={color} {...props} xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M41.1803 13.8721L37.5531 10.1485C37.1629 9.74788 37.163 9.09634 37.55 8.69693L38.9026 7.30075C39.2911 6.8997 39.9234 6.90037 40.3117 7.29905L48.7813 15.994C49.0733 16.2938 49.0725 16.7805 48.7813 17.0794L40.3117 25.7748C39.922 26.175 39.2895 26.1726 38.9026 25.7732L37.55 24.377C37.1615 23.9759 37.1635 23.3254 37.553 22.9255L41.211 19.17C41.4069 18.9689 41.3379 18.8058 41.0588 18.8058L19.9864 18.8058L17.6947 18.7886C14.3195 18.7886 11.5834 21.4938 11.5834 24.8308V26.0897C11.5834 26.6436 11.128 27.0967 10.5662 27.0967H8.01721C7.45623 27.0967 7 26.6458 7 26.0897V24.8308C7 18.991 11.7882 14.2568 17.6947 14.2568L19.9864 14.2263L41.0211 14.2337C41.3035 14.2337 41.3739 14.0709 41.1803 13.8721ZM14.8197 42.1279L18.4469 45.8515C18.8371 46.2521 18.837 46.9037 18.45 47.3031L17.0974 48.6992C16.7089 49.1003 16.0766 49.0996 15.6883 48.7009L7.21866 40.006C6.92672 39.7062 6.92751 39.2195 7.21866 38.9206L15.6883 30.2252C16.078 29.825 16.7105 29.8274 17.0974 30.2268L18.45 31.623C18.8385 32.0241 18.8365 32.6746 18.447 33.0745L14.789 36.83C14.5931 37.0311 14.6621 37.1942 14.9412 37.1942H36.0136L38.3053 37.2114C41.6805 37.2114 44.4166 34.5062 44.4166 31.1692V29.9103C44.4166 29.3564 44.872 28.9033 45.4338 28.9033H47.9828C48.5438 28.9033 49 29.3542 49 29.9103V31.1692C49 37.009 44.2118 41.7432 38.3053 41.7432L36.0136 41.7738L14.9789 41.7663C14.6965 41.7663 14.6261 41.9291 14.8197 42.1279Z"
        fill={color}
      />
    </svg>
  )
}
