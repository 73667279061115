import { styled } from '@mui/material'

import { ExternalEmploymentStatus, ExternalEmploymentType, ProviderType } from '@nuna/api'
import { StatusLabel, TextButtonLink, fontSize, greySet } from '@nuna/tunic'

import { SessionIndexTabs } from '../../scenes/Sessions/SessionIndex'
import { payrollSummary, sessionIndex } from '../../util/routes'
import { useProviderNotifications } from './useProviderNotifications'

export function ProviderAlerts() {
  const { data } = useProviderNotifications()
  const hasOverdueSessions = !!data?.appointmentStats.hasUncompletedSessionNotes

  const employmentIncomplete =
    data?.providerEmployment?.externalEmploymentStatus !== ExternalEmploymentStatus.Completed &&
    data?.providerEmployment?.externalEmploymentType !== ExternalEmploymentType.Ignored

  if (!data) {
    return null
  }

  const provider = data.providerMe

  if (employmentIncomplete) {
    return (
      <ProfileNotification>
        <StatusLabel intent="caution">Payment Info</StatusLabel>
        <p className="text-dark-grey">Before you can be paid, we need your banking information.</p>
        <TextButtonLink to={payrollSummary()} className="mt-1">
          Set up banking
        </TextButtonLink>
      </ProfileNotification>
    )
  }

  if (hasOverdueSessions && provider.type !== ProviderType.Prescriber) {
    return (
      <ProfileNotification>
        <StatusLabel intent="caution">Overdue Notes</StatusLabel>
        <p className="text-dark-grey">
          Complete your session notes <TextButtonLink to={sessionIndex(SessionIndexTabs.PREVIOUS)}>here</TextButtonLink>
        </p>
      </ProfileNotification>
    )
  }

  return null
}

const ProfileNotification = styled('div')`
  border: 1px solid ${greySet[15].hex};
  border-radius: 12px;
  background-color: #fff;
  padding: 0.75rem;
  margin-bottom: var(--margin-2);
  margin-top: var(--margin-2);
  font-size: ${fontSize.caption};

  p {
    padding: var(--margin-1) 0 0;
    margin-bottom: 0;
  }
`
