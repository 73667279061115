import { error } from '../styles/colors'
import { IconHoverProps } from './IconProps'

export function IconNoShow({ isHovered, size = 24, color = 'currentColor', ...props }: IconHoverProps) {
  return (
    <svg width={size} height={size} viewBox="0 0 48 48" fill={color} xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M22.3278 43.7506C22.5422 41.8627 25.3084 38.9931 27.3112 37.1723C28.3537 36.2246 26.5475 34.5869 24.7116 32.9224C22.8759 31.2579 21.0106 29.5667 21.9339 28.5115C22.1516 28.2627 22.3768 28.0042 22.6081 27.7387C24.4651 25.6073 26.7095 23.0312 28.5051 21.5255C28.5148 21.5174 28.5244 21.5095 28.5342 21.5019L28.5339 21.4741C28.5339 21.4741 29.9109 20.3958 30.3391 17.0139C31.2003 17.2672 32.1041 15.7041 32.1514 14.8791C32.2011 14.0855 32.0331 11.8831 30.9826 12.1051C31.1979 10.4455 31.3541 8.95474 31.2784 8.16595C31.0039 5.27854 28.2145 2.23193 23.9275 2.23193C19.6381 2.23193 16.8487 5.27854 16.5766 8.16595C16.5009 8.95474 16.657 10.4455 16.87 12.1051C15.8195 11.8831 15.6539 14.0855 15.7012 14.8791C15.7509 15.7041 16.6523 17.2672 17.5159 17.0139C17.9417 20.3958 19.3211 21.4741 19.3211 21.4741L19.2856 25.0706C19.2856 25.0706 18.4125 25.611 14.7383 27.1741C13.9234 27.5202 13.1066 27.8433 12.3167 28.1557C9.54485 29.2522 7.10451 30.2175 6.24466 31.586C5.25335 33.1588 6.24466 40.7067 6.24466 40.7067C6.24466 40.7067 11.1358 43.5025 22.3278 43.7506Z"
      />
      <path
        fill={isHovered ? error : color}
        fillRule="evenodd"
        clipRule="evenodd"
        d="M26.5885 30.6167L33.0459 37.0742L26.5885 43.5316C26.0016 44.1185 26.0016 45.07 26.5885 45.6569L28.4914 47.5599C29.0783 48.1467 30.0299 48.1467 30.6167 47.5599L37.0742 41.1024L43.5316 47.5599C44.1185 48.1467 45.07 48.1467 45.6569 47.5599L47.5599 45.6569C48.1467 45.07 48.1467 44.1185 47.5599 43.5316L41.1024 37.0742L47.5599 30.6167C48.1467 30.0299 48.1467 29.0783 47.5599 28.4914L45.6569 26.5885C45.07 26.0016 44.1185 26.0016 43.5316 26.5885L37.0742 33.0459L30.6167 26.5885C30.0299 26.0016 29.0783 26.0016 28.4914 26.5885L26.5885 28.4914C26.0016 29.0783 26.0016 30.0299 26.5885 30.6167Z"
      />
    </svg>
  )
}
