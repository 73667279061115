import { IconProps } from './IconProps'

export function IconDescriptionEdit({ size = 24, color = 'currentColor', ...props }: IconProps) {
  return (
    <svg width={size} height={size} viewBox="0 0 28 28" fill={color} xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.5 18.5556C2.22386 18.5556 2 18.7794 2 19.0556V21.5C2 21.7761 2.22386 22 2.5 22H11.7368C12.013 22 12.2368 21.7761 12.2368 21.5L11.7368 19.0556C11.7368 18.7794 11.513 18.5556 11.2368 18.5556H2.5ZM2.5 12.2778C2.22386 12.2778 2 12.5016 2 12.7778V15.2222C2 15.4984 2.22386 15.7222 2.5 15.7222H13.5C13.7761 15.7222 14.0499 15.5006 14.2054 15.2222L15.5 12.7778C15.5 12.5016 15.2761 12.2778 15 12.2778H2.5ZM2.5 6C2.22386 6 2 6.22386 2 6.5V8.94444C2 9.22059 2.22386 9.44444 2.5 9.44444H17.9211C18.1972 9.44444 18.4612 9.2774 18.6463 8.94444L19.9211 6.5C19.9211 6.22386 19.6972 6 19.4211 6H2.5ZM24.9214 10.6034L26.0937 8.64884C26.3235 8.28107 26.212 7.7984 25.8443 7.56859L23.6376 6.18972C23.2698 5.95991 22.7872 6.07134 22.5574 6.43912L21.3851 8.39368L24.9214 10.6034ZM20.5103 9.93389L14.3313 19.6036L14.9946 22.4766L17.8676 21.8133L24.0466 12.1436L20.5103 9.93389Z"
      />
    </svg>
  )
}
