import { styled } from '@mui/material'

import { supportService } from '@nuna/telemetry'
import { GhostButtonExternalLink, IconVideoOff, error } from '@nuna/tunic'

import { VideoDialog } from './VideoDialog'

export function DeviceError() {
  return (
    <VideoDialog isOpen>
      <MediaError>
        <IconVideoOff size={48} />
        <MediaErrorMessage>Your Mic and Cam are blocked</MediaErrorMessage>
      </MediaError>
      <h3 className="h5 mt-2">You need to allow access to your cam and mic to join.</h3>
      <p className="mt-2 text-light-gray mb-4">You can toggle them on/off at any point</p>

      <div className="text-center">
        <GhostButtonExternalLink scheme="dark" href={supportService.articles.troubleshootingVideo}>
          Need Help?
        </GhostButtonExternalLink>
      </div>
    </VideoDialog>
  )
}

const MediaError = styled('div')`
  align-items: center;
  background-color: ${error};
  border-radius: 6px;
  display: flex;
  height: 225px;
  justify-content: center;
  position: relative;
`

const MediaErrorMessage = styled('p')`
  background-color: rgba(0, 46, 73, 0.7);
  border-radius: 4px;
  bottom: 1rem;
  display: inline;
  margin: 0;
  padding: 4px 8px;
  position: absolute;
`
