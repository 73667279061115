import { styled } from '@mui/material'

import { CashCoverageDetailsFragment } from '@nuna/api'
import { coverageService } from '@nuna/core'
import { csx, greySet } from '@nuna/tunic'

import cardBGSrc from '../img/card-bg.png'
import { BaseCard, BaseCardProps } from './BaseCard'
import { CreditCardLogo } from './CreditCardLogo'

interface Props extends BaseCardProps {
  details: CashCoverageDetailsFragment
}

export function CashCard({ details, ...props }: Props) {
  const isExpired = !coverageService.isCashValid(details)

  return (
    <Container {...props}>
      <div className="flex-column full-height">
        <CreditCardLogo cardBrand={details.cardBrand} />

        <span className="mt-auto">
          {details.cardNumber}
          <span className={csx([{ 'text-error text-medium': isExpired }, 'ml-3'])}>{details.cardExpiration}</span>
        </span>
      </div>
    </Container>
  )
}

const Container = styled(BaseCard)`
  background-image: url(${cardBGSrc}),
    linear-gradient(
      159deg,
      ${greySet[5].hex} 12.99%,
      #fff 24.59%,
      #fff 43.36%,
      ${greySet[0].hex} 53.96%,
      ${greySet[0].hex} 84.2%
    );
  background-size: contain;
  background-position: right center;
  background-repeat: no-repeat;
`
