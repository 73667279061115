import { styled } from '@mui/material'
import React, { MouseEventHandler } from 'react'

import { IconClose } from '../../icons'
import { body2, borderGrey, eggshell } from '../../styles/colors'
import { Avatar } from '../Avatar/Avatar'
import { IconButton } from '../Button'

export interface UserChipProps extends React.HTMLAttributes<HTMLSpanElement> {
  avatarUrl?: string | null
  onRemoveClick?: MouseEventHandler<HTMLButtonElement>
}

export function UserChip({ avatarUrl, children, onRemoveClick, ...props }: UserChipProps) {
  return (
    <Container hasRemoveButton={!!onRemoveClick} {...props}>
      <Avatar url={avatarUrl} size="mini" className="mr-1" /> {children}
      {onRemoveClick && (
        <PositionedIconButton
          tooltip="Remove"
          type="button"
          customSize={32}
          variant="secondary"
          onClick={onRemoveClick}
        >
          <IconClose size={12} />
        </PositionedIconButton>
      )}
    </Container>
  )
}

const Container = styled('span')<{ hasRemoveButton: boolean }>`
  align-items: center;
  background-color: ${eggshell};
  border: 1px solid ${borderGrey};
  border-radius: 48px;
  color: ${body2};
  display: inline-flex;
  font-size: 1rem;
  padding: 0.5rem ${({ hasRemoveButton }) => (hasRemoveButton ? '2.75rem' : '0.75rem')} 0.5rem 0.5rem;
  position: relative;
`

const PositionedIconButton = styled(IconButton)`
  position: absolute;
  right: 0.25rem;
`
