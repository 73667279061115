import { ThemeOptions, createTheme } from '@mui/material'
import '@mui/x-date-pickers/themeAugmentation'
import { CSSProperties } from 'react'

import {
  fontSize,
  fontWeight,
  headingLineHeight,
  paragraphsLineHeight,
  sansSerifFontStack,
  serifFontStack,
} from './global/typography.utils'
import { shadowDepth } from './shadows'
import { palette } from './theme/palette'

const commonHeadingStyles: CSSProperties = {
  lineHeight: headingLineHeight,
  fontFamily: serifFontStack,
  fontWeight: fontWeight.normal,
  margin: '0 0 0.5em 0',
}

const basicTheme = createTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 768,
      md: 960,
      lg: 1280,
      xl: 1920,
    },
  },
  typography: {
    fontFamily: sansSerifFontStack,
    allVariants: {
      lineHeight: 'inherit',
    },
    h1: {
      ...commonHeadingStyles,
      fontSize: fontSize.h1,
    },
    h2: {
      ...commonHeadingStyles,
      fontSize: fontSize.h2,
    },
    h3: {
      ...commonHeadingStyles,
      fontSize: fontSize.h3,
    },
    h4: {
      ...commonHeadingStyles,
      fontSize: fontSize.h4,
    },
    h5: {
      ...commonHeadingStyles,
      fontSize: fontSize.h5,
    },
    h6: {
      ...commonHeadingStyles,
      fontSize: fontSize.h6,
      fontWeight: fontWeight.medium,
    },
    caption: {
      fontSize: fontSize.caption,
      lineHeight: paragraphsLineHeight,
    },
    subtitle1: {
      fontSize: fontSize.large,
      lineHeight: paragraphsLineHeight,
    },
    subtitle2: {
      fontSize: fontSize.display,
    },
    body1: {
      fontSize: fontSize.body,
    },
    body2: undefined,
  },
  palette,
})

export const componentThemeOptions: ThemeOptions = {
  components: {
    MuiFormControl: {
      defaultProps: {
        variant: 'standard',
      },
    },
    MuiSelect: {
      defaultProps: {
        variant: 'standard',
      },
    },
    MuiTextField: {
      defaultProps: {
        variant: 'standard',
      },
    },
    MuiButtonBase: {
      defaultProps: {
        disableRipple: true,
      },
    },
    MuiFilledInput: {
      styleOverrides: {
        root: {
          backgroundColor: basicTheme.palette.grey['100'],
          '&:hover': {
            backgroundColor: basicTheme.palette.grey['200'],
          },
          '&.Mui-focused': {
            backgroundColor: basicTheme.palette.grey['100'],
          },
        },
      },
    },
    MuiPickersDay: {
      styleOverrides: {
        root: {
          '&.Mui-selected': {
            backgroundColor: basicTheme.palette.teal['300'],
          },
          '&.Mui-selected:hover': {
            backgroundColor: basicTheme.palette.teal['300'],
          },
          '&.Mui-selected:focus': {
            backgroundColor: basicTheme.palette.teal['300'],
          },
        },
      },
    },
    MuiPopover: {
      styleOverrides: {
        paper: {
          border: `1px solid ${basicTheme.palette.grey['300']}`,
          '&.MuiPaper-rounded': {
            borderRadius: 6,
          },
          '&.MuiPaper-elevation8': {
            boxShadow: shadowDepth(8.5, 'cool'),
          },
        },
      },
    },
  },
}

export const tavaMuiTheme = createTheme(basicTheme, componentThemeOptions)
