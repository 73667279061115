import { IconProps } from './IconProps'

export function IconAttachment({ size = 24, color = 'currentColor', ...props }: IconProps) {
  return (
    <svg width={size} height={size} viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M31.6987 36.5349C31.6987 40.5108 28.4704 43.7333 24.4863 43.7333C20.5017 43.7333 17.274 40.5136 17.274 36.5384V6.93867C17.274 5.46219 18.4701 4.26667 19.9452 4.26667C21.4203 4.26667 22.6165 5.4606 22.6165 6.93191V35.3781C22.6165 36.5563 23.5732 37.5115 24.7535 37.5115C25.9337 37.5115 26.8904 36.5563 26.8904 35.3781V6.93191C26.8904 3.10402 23.7806 0 19.9452 0C16.1085 0 13 3.10692 13 6.93867V36.5384C13 42.8707 18.142 48 24.4863 48C30.8308 48 35.9727 42.8673 35.9727 36.5349V23.2448C35.9727 22.0666 35.0159 21.1115 33.8357 21.1115C32.6554 21.1115 31.6987 22.0666 31.6987 23.2448V36.5349Z"
        fill={color}
      />
    </svg>
  )
}
