import { supportService } from '@nuna/telemetry'
import { TextButtonExternalLink } from '@nuna/tunic'

import { FAQ } from '../../signup/faqItems'

export function getIntakeFinishFAQs(guideName?: string | null): FAQ[] {
  return [
    {
      question: 'Where should I go for help?',
      answer: (
        <>
          <p>
            Now that you're part of our network, the Clinical Team is available to help and support you with all of your
            questions{guideName ? `; ${guideName} will no longer be your point of contact` : '.'} Click the chat bubble
            on the bottom right of your screen or email us at{' '}
            <TextButtonExternalLink href="mailto:clinicalops@tavahealth.com">
              clinicalops@tavahealth.com
            </TextButtonExternalLink>
            .
          </p>
          <p>
            Additionally, once your profile is confirmed, you'll receive a link to join our therapist community on
            Mobilize to connect with other therapists and get answers to common questions.
          </p>
        </>
      ),
    },
    {
      question: 'How do I move my existing clients to the Tava platform?',
      answer: (
        <>
          With Tava you can hold all your sessions and store documentation for all your clients in one place without
          having to manage multiple systems. Once your account is verified, inviting your own clients is as easy as
          sharing a link to your public booking profile. You can also use this link to recruit new clients.
        </>
      ),
    },
    {
      question: 'How do I start getting new client referrals?',
      answer: (
        <>
          We have thousands of new clients all over the US looking for their therapist. Set your availability with the
          hours you're looking to fill and we will get you in front of well-matched clients. Be sure to{' '}
          <TextButtonExternalLink href={supportService.articles.calendarSync}>
            sync your calendar
          </TextButtonExternalLink>{' '}
          with Tava in order to avoid double-bookings over events on your personal calendar.
        </>
      ),
    },
    {
      question: "What's it like for a client to choose me as their therapist?",
      answer: (
        <>
          Clients see therapist profiles that match their preferences and can schedule with the therapist they choose in
          a few clicks. You will automatically see sessions appear in available slots on your calendar when a client
          books with you. They will also appear in your Clients tab, where you can see their info and message them
          directly from the Tava platform.
        </>
      ),
    },
    {
      question: 'Why should I get paneled with insurance?',
      answer: (
        <>
          We take the hassle and stress out of credentialing and working with insurance providers. Once we get you
          credentialed and enrolled with health insurance payers, we'll boost your online presence through free ZocDoc
          and Insurance Directory profiles, which will help more new clients find and schedule with you. We also handle
          all claims, billing, and collections.
        </>
      ),
    },
    {
      question: 'How do I get paneled with insurance?',
      answer: (
        <>
          Once you let us know that you're interested, we'll reach out to guide you through the next steps. You'll need
          an up-to-date CAQH profile, so if you don't have one yet or it's been a while, get started on that now. You
          must be independently licensed to get paneled; provisionally licensed providers are not eligible.
        </>
      ),
    },
    {
      question: 'Which insurance providers can I get paneled with?',
      answer: <>We are always growing the list of insurance payers we work with.</>,
    },
    {
      question: 'How do I get paid?',
      answer: (
        <>
          Add your bank direct deposit information in the Payouts section of your profile. You can also view your
          reimbursement rates there. Many providers choose to set up their own small business entity and provide an EIN
          instead of a Social Security Number. Tava reimburses bi-weekly for documented sessions.
        </>
      ),
    },
  ]
}
