import { HTMLAttributes, ReactNode } from 'react'
import { useMediaQuery } from 'react-responsive'

import { DesktopNavMenu } from './components/DesktopNavMenu'
import { MobileNavMenu } from './components/MobileNavMenu'

interface Props extends HTMLAttributes<HTMLDivElement> {
  avatarUrl?: string | null
  firstName?: string | null
  children: ReactNode
}

export function NavMenu({ avatarUrl, firstName, children }: Props) {
  const isMobile = useMediaQuery({ maxWidth: 600 })

  if (isMobile) {
    return (
      <MobileNavMenu firstName={firstName} avatarUrl={avatarUrl}>
        {children}
      </MobileNavMenu>
    )
  }

  return <DesktopNavMenu avatarUrl={avatarUrl}>{children}</DesktopNavMenu>
}
