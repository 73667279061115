import { styled } from '@mui/material'
import range from 'lodash/range'
import React, { SVGAttributes, useRef } from 'react'

const POINTS_IN_CIRCLE = 4
interface Coordinate {
  x: number
  y: number
}

interface ImperfectCircleProps extends SVGAttributes<SVGPathElement> {
  size: number
  curveIntensity?: number
  distortion?: number
  position?: Coordinate
}

export function ImperfectCircle({
  size,
  curveIntensity = size / 4,
  distortion = 0,
  position = { x: 0, y: 0 },
  ...props
}: ImperfectCircleProps) {
  const arcPoints = useRef<Coordinate[]>() // putting in ref means they don't shift on every render

  const radius = size / 2
  const angleIncrement = (2 * Math.PI) / POINTS_IN_CIRCLE

  if (!arcPoints.current) {
    arcPoints.current = range(POINTS_IN_CIRCLE).map(i => {
      const x = radius * Math.cos(angleIncrement * i) + (Math.random() * distortion - distortion / 2)
      const y = radius * Math.sin(angleIncrement * i) + (Math.random() * distortion - distortion / 2)

      const xRounded = Math.round(100 * x) / 100
      const yRounded = Math.round(100 * y) / 100

      return { x: xRounded + radius + position.x, y: yRounded + radius + position.y }
    })
  }

  const [eastArcPoint, southArcPoint, westArcPoint, northArcPoint] = arcPoints.current

  const northToEastCurve = {
    control1: {
      x: northArcPoint.x + curveIntensity,
      y: northArcPoint.y,
    },
    control2: {
      x: eastArcPoint.x,
      y: eastArcPoint.y - curveIntensity,
    },
    endPoint: eastArcPoint,
  }

  const eastToSouthCurve = {
    control1: {
      x: eastArcPoint.x,
      y: eastArcPoint.y + curveIntensity,
    },
    control2: {
      x: southArcPoint.x + curveIntensity,
      y: southArcPoint.y,
    },
    endPoint: southArcPoint,
  }

  const southToWestCurve = {
    control1: {
      x: southArcPoint.x - curveIntensity,
      y: southArcPoint.y,
    },
    control2: {
      x: westArcPoint.x,
      y: westArcPoint.y + curveIntensity,
    },
    endPoint: westArcPoint,
  }

  const westToNorthCurve = {
    control1: {
      x: westArcPoint.x,
      y: westArcPoint.y - curveIntensity,
    },
    control2: {
      x: northArcPoint.x - curveIntensity,
      y: northArcPoint.y,
    },
    endPoint: northArcPoint,
  }

  const curves = [northToEastCurve, eastToSouthCurve, southToWestCurve, westToNorthCurve]

  const curveStrings = curves.map(
    curve =>
      `C ${curve.control1.x} ${curve.control1.y}, ${curve.control2.x} ${curve.control2.y}, ${curve.endPoint.x} ${curve.endPoint.y}`,
  )

  return <Path {...props} d={`M ${northArcPoint.x} ${northArcPoint.y} ${curveStrings.join(' ')} Z`} />
}

const Path = styled('path')`
  transition: transform 0.3s;
  transform-origin: center;
`
