import * as Yup from 'yup'
import { Formik, FormikHelpers } from 'formik'

import { AppointmentScheduleFragment, AppointmentWhoNoShowed, useMarkAppointmentAsNoShowMutation } from '@nuna/api'
import { errorService } from '@nuna/core'
import { Checkbox, FillButton, Select, TextField, toast } from '@nuna/tunic'

interface MarkNoShowFormValues {
  whoNoShowed: AppointmentWhoNoShowed
  reasonData: string
  shouldProviderBePaid: boolean
}

const markNoShowSchema = Yup.object<MarkNoShowFormValues>({
  whoNoShowed: Yup.mixed<AppointmentWhoNoShowed>().oneOf(Object.values(AppointmentWhoNoShowed)).required(),
  reasonData: Yup.string().required(),
  shouldProviderBePaid: Yup.boolean().required(),
})

const whoNoShowedOptions = [
  { value: AppointmentWhoNoShowed.Patient, display: 'Patient' },
  { value: AppointmentWhoNoShowed.Provider, display: 'Provider' },
]

export const MarkNoShowAsAdminForm = ({
  appointment,
  onComplete,
}: {
  appointment: Pick<AppointmentScheduleFragment, 'id'>
  onComplete: () => void
}) => {
  const [markAppointmentAsNoShow, { loading, error }] = useMarkAppointmentAsNoShowMutation()

  const handleSubmit = async (values: MarkNoShowFormValues, { resetForm }: FormikHelpers<MarkNoShowFormValues>) => {
    try {
      await markAppointmentAsNoShow({
        variables: {
          appointmentId: appointment.id,
          noShowStatus: true,
          whoNoShowed: values.whoNoShowed,
          reasonData: values.reasonData,
          shouldProviderBePaid: values.shouldProviderBePaid || false,
        },
        refetchQueries: ['InternalAppointmentDetail'],
      })

      resetForm()

      onComplete()
      toast.success(`Marked appointment as a ${values.whoNoShowed.toLocaleLowerCase()} no-show`)
    } catch (e) {
      console.error(e)
      toast.urgent('Failed to mark appointment as a no-show')
    }
  }

  return (
    <Formik
      validationSchema={markNoShowSchema}
      initialValues={{
        whoNoShowed: AppointmentWhoNoShowed.Patient,
        reasonData: '',
        shouldProviderBePaid: false,
      }}
      onSubmit={handleSubmit}
    >
      {({ values, handleChange, handleSubmit, errors, touched }) => {
        return (
          <form onSubmit={handleSubmit}>
            <h1 className="h4 mb-2">Mark No-Show</h1>

            <Select
              value={values.whoNoShowed}
              name="whoNoShowed"
              onChange={handleChange}
              id="who-no-showed"
              label="Who No-Showed"
              className="mb-3"
            >
              {whoNoShowedOptions.map(option => (
                <option key={`whoNoShowed-${option.value}`} value={option.value}>
                  {option.display}
                </option>
              ))}
            </Select>

            <TextField
              id="no-show-message"
              placeholder="Write a message to be shared with the provider or patient"
              rows={4}
              name="reasonData"
              className="mb-3"
              onChange={handleChange}
              value={values.reasonData}
              error={!!(touched.reasonData && errors.reasonData)}
              helperText={touched.reasonData && errors.reasonData}
              multiline
            />

            {values.whoNoShowed !== AppointmentWhoNoShowed.Provider && (
              <div>
                <Checkbox
                  checked={values.shouldProviderBePaid}
                  name="shouldProviderBePaid"
                  onChange={handleChange}
                  className="mb-1"
                >
                  Provider should be paid
                </Checkbox>
              </div>
            )}

            {!!error && <div>Error occurred saving {errorService.transformGraphQlError(error)}</div>}

            <FillButton isLoading={loading} type="submit" style={{ width: '100%' }} className="mt-2">
              Mark No-Show
            </FillButton>
          </form>
        )
      }}
    </Formik>
  )
}
