import { ApolloQueryResult, useApolloClient } from '@apollo/client'
import { ReactNode, createContext, useCallback, useContext, useMemo, useState } from 'react'
import { Navigate, useLocation } from 'react-router-dom'

import {
  Exact,
  Provider,
  ProviderDataField,
  ProviderDataSummaryFragment,
  ProviderMeStatusesQuery,
  ProviderNetworkStatus,
  Role,
  useProviderMeStatusesQuery,
} from '@nuna/api'
import { PrivateRoute, useAuthDataContext } from '@nuna/auth'
import { routeService } from '@nuna/core'
import { Skeleton } from '@nuna/tunic'

import { signupIntake } from '../util/routes'

interface ProviderAppContext {
  provider?: Pick<Provider, 'id' | 'status' | 'avatarUrl'> | null
  providerNetworkStatus: ProviderNetworkStatus | undefined | null
  backgroundCheckInitiated?: boolean
  setBackgroundCheckInitiated: (initiated: boolean) => void
  providerDataSummaryItems: ProviderDataSummaryFragment[]
  getSummaryItemByField: (field: ProviderDataField) => ProviderDataSummaryFragment
  refetchStatus: (
    variables?:
      | Partial<
          Exact<{
            [key: string]: never
          }>
        >
      | undefined,
  ) => Promise<ApolloQueryResult<ProviderMeStatusesQuery>>
}

const defaultProviderDataSummaryItem: ProviderDataSummaryFragment = {
  hasValue: false,
  required: false,
}

const ProviderAppContext = createContext<ProviderAppContext>({} as ProviderAppContext)

interface ProviderAppContextProviderProps {
  children: ReactNode
}

export function ProviderAppContextProvider({ children }: ProviderAppContextProviderProps) {
  const { login } = useAuthDataContext()
  const apolloClient = useApolloClient()
  const isCustomer = login?.role === Role.Customer
  const { data: providerStatusData, refetch: refetchStatus } = useProviderMeStatusesQuery({
    skip: !login?.id || isCustomer,
  })

  const location = useLocation()
  // TODO - move background check state to background check component once provider signup intake is released
  const [backgroundCheckInitiated, setBackgroundCheckInitiated] = useState<undefined | boolean>()

  const providerNetworkStatus = useMemo(() => providerStatusData?.providerMe.networkStatus, [providerStatusData])
  const providerDataSummaryItems = useMemo(() => providerStatusData?.providerDataSummary ?? [], [providerStatusData])
  const getSummaryItemByField = useCallback(
    (field: ProviderDataField) => {
      return providerDataSummaryItems.find(item => item.field === field) ?? defaultProviderDataSummaryItem
    },
    [providerDataSummaryItems],
  )

  const redirectPath = useMemo(() => {
    if (
      providerNetworkStatus &&
      providerNetworkStatus !== ProviderNetworkStatus.InProgress &&
      location.pathname.includes(signupIntake)
    ) {
      // If they attempt to navigate to a signup intake route, but are no longer onboarding, redirect
      // them to harmony home
      return '/'
    }

    if (!getSummaryItemByField(ProviderDataField.Credentials).hasValue) {
      apolloClient.cache.evict({ fieldName: 'providerDataSummary' })
      return routeService.signupLicenses
    }

    if (!getSummaryItemByField(ProviderDataField.Address).hasValue) {
      apolloClient.cache.evict({ fieldName: 'providerDataSummary' })
      return routeService.signupLocation
    }

    return null
  }, [providerNetworkStatus, location.pathname, getSummaryItemByField, apolloClient])

  const value: ProviderAppContext = {
    provider: providerStatusData?.providerMe,
    providerNetworkStatus,
    backgroundCheckInitiated,
    setBackgroundCheckInitiated,
    providerDataSummaryItems,
    getSummaryItemByField,
    refetchStatus,
  }

  return (
    <PrivateRoute>
      <ProviderAppContext.Provider value={value}>
        {(() => {
          if (isCustomer) {
            return children
          }

          if (!login?.id) {
            return children
          }

          if (!providerNetworkStatus) {
            return <Skeleton style={{ margin: 16, height: '100%', width: '100%' }} />
          }

          if (redirectPath === location.pathname) {
            return children
          }

          if (redirectPath) {
            return <Navigate to={redirectPath} />
          }

          return children
        })()}
      </ProviderAppContext.Provider>
    </PrivateRoute>
  )
}

export function useProviderAppContext() {
  return useContext(ProviderAppContext)
}
