import { styled } from '@mui/material'
import moment from 'moment'
import { useEffect, useState } from 'react'
import { useMediaQuery } from 'react-responsive'

import { routeService } from '@nuna/core'
import {
  BelowTablet,
  Card,
  Dialog,
  FillButtonLink,
  GhostButton,
  Grid,
  IconButton,
  IconClose,
  makeTypographyComponent,
} from '@nuna/tunic'

import { useProviderMeCancelPolicy } from '../../hooks/useProviderMeCancelPolicy'
import clockImgSrc from './img/clock.png'
import creditCardImageSrc from './img/credit-card.png'
import heartImageSrc from './img/heart.png'

const DISMISS_TIMESTAMP_KEY = 'fee-policy-announcement-dismissed'

export function FeePolicyAnnouncementModal() {
  const isMobile = useMediaQuery({ query: `(${BelowTablet})` })
  const [modalOpen, setModalOpen] = useState<null | boolean>(null)
  const { cancelPolicy, loading } = useProviderMeCancelPolicy()

  const announcementDismissedTimestamp = localStorage.getItem(DISMISS_TIMESTAMP_KEY)

  useEffect(() => {
    if (!loading && modalOpen === null && !cancelPolicy && getNotDismissedRecently(announcementDismissedTimestamp)) {
      setModalOpen(true)
    }
  }, [cancelPolicy, modalOpen, announcementDismissedTimestamp, loading])

  const handleClose = () => {
    setModalOpen(false)
  }

  const handleDismiss = () => {
    localStorage.setItem(DISMISS_TIMESTAMP_KEY, moment().toISOString())
    setModalOpen(false)
  }

  return (
    <Dialog isOpen={!!modalOpen} fullScreen={isMobile}>
      <Container className="p-4">
        <IconButton
          className="ml-auto close-button"
          tooltip="Close"
          small
          variant="secondary"
          type="button"
          onClick={handleClose}
        >
          <IconClose size={14} />
        </IconButton>
        <ModalTitle>Announcing a new 24 hour cancelation policy</ModalTitle>
        <Card depth={-1} className="p-2">
          <ModalDescription className="mb-3">
            Set your own fees and waive them if the heart strings tug.
          </ModalDescription>
          <Grid container spacing={2}>
            <GridItem>
              <div className="img-container">
                <img src={creditCardImageSrc} alt="Credit Card" />
              </div>
              <GridItemDescription>Fees are charged to a client's card on file.</GridItemDescription>
            </GridItem>
            <GridItem>
              <div className="img-container">
                <img src={clockImgSrc} alt="Clock" style={{ width: '35%' }} />
              </div>
              <GridItemDescription>Applied if less than 24 hours beforehand.</GridItemDescription>
            </GridItem>
            <GridItem>
              <div className="img-container">
                <img src={heartImageSrc} alt="Heart" style={{ width: '40%' }} />
              </div>
              <GridItemDescription>Forgive fees for any reason. It's your call.</GridItemDescription>
            </GridItem>
          </Grid>
        </Card>
        <ButtonContainer className="mt-3">
          <FillButtonLink to={routeService.providerFeePolicies} onClick={handleDismiss}>
            Set Up My Policy
          </FillButtonLink>
          <GhostButton className="ml-2" onClick={handleDismiss}>
            Set it up later
          </GhostButton>
        </ButtonContainer>
      </Container>
    </Dialog>
  )
}

function getNotDismissedRecently(dismissedTimeStamp?: string | null) {
  if (!dismissedTimeStamp) {
    return true
  }

  const timeStamp = moment(dismissedTimeStamp)

  if (!timeStamp.isValid()) {
    return true
  }

  // Return true if the dismissed timestamp is not today, ensuring they only get the announcement once a day
  return !timeStamp.isSame(moment(), 'date')
}

const Container = styled('div')`
  position: relative;
  .close-button {
    position: absolute;
    right: 13px;
    top: 13px;
  }
  @media (${BelowTablet}) {
    .close-button {
      top: 5px;
      right: 5px;
    }
  }
`

const ModalTitle = styled('h4')`
  text-align: center;

  @media (${BelowTablet}) {
    text-align: left;
    font-size: 24px;
  }
`

const ModalDescription = styled(makeTypographyComponent('text-secondary large', 'p'))`
  text-align: center;

  @media (${BelowTablet}) {
    text-align: left;
  }
`

const GridItemDescription = makeTypographyComponent('text-secondary text-medium  item-description', 'div')
const GridItem = styled(Grid)`
  text-align: center;

  .img-container {
    height: 100px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  img {
    max-width: 100%;
  }
`
GridItem.defaultProps = { size: { xs: 12, sm: 4 } }

const ButtonContainer = styled('div')`
  text-align: center;
`
