import { Popover } from '@typeform/embed-react'
import { isNil } from 'lodash'
import moment from 'moment'

import { useBaseProviderQuery, useProviderCaseloadQuery } from '@nuna/api'
import { localStorageService } from '@nuna/core'

type Props = {
  providerId?: string | null
  initialReferralState?: boolean
}

const COMPLETED_REFERRAL_SURVEY = 'COMPLETED_REFERRAL_SURVEY'
const CLOSED_REFERRAL_SURVEY = 'CLOSED_REFERRAL_SURVEY'

export function Survey({ providerId, initialReferralState }: Props) {
  const { data: baseProvider } = useBaseProviderQuery({ variables: { id: providerId || '' }, skip: !providerId })
  const { data: providerCaseload } = useProviderCaseloadQuery({
    variables: { providerId: providerId ?? '' },
    skip: isNil(providerId),
  })
  const { acceptsReferrals, eapReferralsLocked } = providerCaseload?.provider ?? {}
  const provider = baseProvider?.provider

  const neverClosedSurvey = !localStorageService.getData(CLOSED_REFERRAL_SURVEY, false)
  const neverCompletedSurvey = !localStorageService.getData(COMPLETED_REFERRAL_SURVEY, false)

  const shouldShowSurvey =
    neverClosedSurvey || moment().diff(localStorageService.getData(CLOSED_REFERRAL_SURVEY, new Date()), 'days') > 0

  const showPopover =
    !acceptsReferrals && !eapReferralsLocked && initialReferralState && neverCompletedSurvey && shouldShowSurvey

  return showPopover ? (
    <Popover
      open="load"
      id="Ox42Fd8G"
      notificationDays={1}
      autoClose
      onSubmit={() => localStorageService.writeData(COMPLETED_REFERRAL_SURVEY, true)}
      onClose={() => localStorageService.writeData(CLOSED_REFERRAL_SURVEY, new Date())}
      hidden={{
        provider_id: providerId ?? '',
        provider_name: `${provider?.firstName} ${provider?.lastName}` ?? '',
        provider_email: provider?.email ?? '',
        timestamp: moment().toISOString(),
      }}
    />
  ) : null
}
