/* eslint-disable @typescript-eslint/no-explicit-any */
import { styled } from '@mui/material'
import { useEffect, useMemo, useState } from 'react'
import { useLocation } from 'react-router-dom'

import { ClientListItemFragment, ProviderNetworkStatus, useProviderMeQuery } from '@nuna/api'
import { useAuthDataContext } from '@nuna/auth'
import { ClientTable, ClientTableColumn } from '@nuna/client'
import { isMobile, useClientInviteDrawerSearchParams, useTitle } from '@nuna/common'
import { ExploreFirstEmptyState } from '@nuna/common'
import { routeService } from '@nuna/core'
import { DataTableColumn } from '@nuna/data-table'
import { FeatureFlagCheck } from '@nuna/feature-flag'
import {
  FillButton,
  GhostButton,
  IconPlus,
  PageContent,
  PageHeader,
  PageHeading,
  PageSearch,
  PageWrapper,
  csx,
} from '@nuna/tunic'

import { useProviderAppContext } from '../../../shared/ProviderAppContext'
import { ClientMessageButton } from '../ClientMessageButton'
import familySrc from './img/Family.png'

export function PatientIndex() {
  useTitle('Clients')
  const { login } = useAuthDataContext()
  const { provider, providerNetworkStatus } = useProviderAppContext()
  const [searchText, setSearchText] = useState('')
  const { openClientInviteDrawer } = useClientInviteDrawerSearchParams()
  const { data: providerMeData, refetch } = useProviderMeQuery()
  const handleOnNewAppointment = () => {
    openClientInviteDrawer({ providerId: provider?.id })
  }

  const location = useLocation()

  useEffect(() => {
    refetch()
  }, [refetch, location])

  const shouldShowExploreIntro =
    providerNetworkStatus === ProviderNetworkStatus.InProgress && providerMeData?.providerMe?.patients?.length === 0
  const columns = useMemo<(ClientTableColumn | DataTableColumn<ClientListItemFragment>)[]>(() => {
    const cols: (ClientTableColumn | DataTableColumn<ClientListItemFragment>)[] = [
      'name',
      'last appt',
      'next appt',
      'joined',
      'state',
      'age',
      {
        Header: 'Message',
        accessor: 'email',
        Cell: ({ row }) => {
          return <ClientMessageButton patient={row.original} />
        },
      },
      'remove',
    ]

    cols.splice(3, 0, 'referral')
    return cols
  }, [])

  const handleFilterChange = (event: any) => {
    const searchText = event.target.value
    setSearchText(searchText)
  }

  return (
    <PageWrapper>
      <ResponsivePageHeader>
        <div className={csx([{ 'mb-1': isMobile }, 'v-align'])}>
          <PageHeading withDivider className="mb-0">
            Clients
          </PageHeading>
          <PageSearch
            className="ml-2"
            onChange={handleFilterChange}
            placeholder="Search all clients"
            value={searchText}
            onClear={() => setSearchText('')}
            autoFocus={true}
          />
        </div>
        <FeatureFlagCheck flags={['clientInvite']} featureType="component">
          <FillButton onClick={() => openClientInviteDrawer({ providerId: provider?.id })}>
            <IconPlus className="mr-1" size={14} />
            Add Client
          </FillButton>
        </FeatureFlagCheck>
      </ResponsivePageHeader>

      <StyledPageLayout $paddingTop={false} className={shouldShowExploreIntro ? 'overflow-hidden' : ''}>
        <ClientTable
          providerId={login?.providerId ?? ''}
          searchText={searchText}
          columns={columns}
          isSkeleton={shouldShowExploreIntro}
          onNavigate={row => routeService.patientDetail(row.id)}
        />
        {shouldShowExploreIntro && (
          <ExploreFirstEmptyState
            topOffset={75}
            illustrationSrc={familySrc}
            textBlock={
              <>
                <h2 className="h4 mt-0">Your go-to for organizing client details and keeping track of interactions.</h2>
                <p className="large">
                  <GhostButton onClick={handleOnNewAppointment}>Add</GhostButton> a client to start seeing patients
                  here.
                </p>
              </>
            }
            className="v-align pr-3"
          />
        )}
      </StyledPageLayout>
    </PageWrapper>
  )
}

const StyledPageLayout = styled(PageContent)`
  position: relative;
`

const ResponsivePageHeader = styled(PageHeader)`
  flex-wrap: wrap;
`
