import { styled } from '@mui/material'

import { supportService } from '@nuna/telemetry'
import { Desktop, MenuDivider, MenuItem, MenuItemStack, TextButtonExternalLink } from '@nuna/tunic'

import { useGlobalWidgetContext } from '../GlobalWidgetContext'
import { ResourceCenterWrapper } from './ResourceCenterWrapper'

export function ResourceCenter() {
  const { resourceCenterOpen, closeResourceCenter, beaconOpen, toggleBeacon } = useGlobalWidgetContext()

  return (
    <ResourceCenterWrapper isOpen={resourceCenterOpen} onClose={closeResourceCenter}>
      <InnerWrapper className="text-secondary">
        <h6 className="mb-3 mt-3 padded header">Help Resources</h6>
        <MenuItemStack>
          <StyledMenuItem
            className="first-link"
            to="https://community.tavahealth.com/join?invitation_token=96065337c6aacb26067a2fc4cb2b1e6277baf51b-12ce9821-8132-4bb8-af34-25f04c01b530"
            externalLink
          >
            Community
          </StyledMenuItem>
          <StyledMenuItem externalLink to="https://community.tavahealth.com/c/events" className="text-left">
            Events
          </StyledMenuItem>
          <StyledMenuItem className="text-left" externalLink to={supportService.articles.bestPractices}>
            Best Practices
          </StyledMenuItem>
          <StyledMenuItem externalLink to={supportService.articles.therapistSupport}>
            Help Center
          </StyledMenuItem>
          <StyledMenuItem externalLink to="https://www.therapistaid.com/">
            Therapist Aid
          </StyledMenuItem>
          <StyledMenuItem
            button
            onClick={e => {
              e.stopPropagation()
              toggleBeacon()
            }}
            isActive={() => beaconOpen}
          >
            Support
          </StyledMenuItem>
        </MenuItemStack>
        <MenuDivider className="my-3 margined" />
        <p className="caption text-medium padded">
          For clinical consultations or help, reach out to the clinical team at{' '}
          <TextButtonExternalLink href="mailto: clinicalops@tavahealth.com">
            clinicalops@tavahealth.com
          </TextButtonExternalLink>
        </p>
      </InnerWrapper>
    </ResourceCenterWrapper>
  )
}

const StyledMenuItem = styled(MenuItem)`
  padding-top: 11px;
  padding-bottom: 11px;
`

const InnerWrapper = styled('div')`
  padding: 0 10px;
  .padded {
    padding-left: 12px;
    padding-right: 12px;
  }

  .margined {
    margin-left: 12px;
    margin-right: 12px;
  }

  @media (${Desktop}) {
    .header {
      margin-top: 2.55rem;
    }
    .first-link {
      margin-top: 2.47rem;
    }
  }
`
