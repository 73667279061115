import { styled } from '@mui/material'
import { noop } from 'lodash'
import { useMemo } from 'react'

import { FullProviderFragment } from '@nuna/api'
import { useIsAdmin } from '@nuna/auth'
import { Grid, Skeleton, borderGrey } from '@nuna/tunic'

import { ProviderProfileExploreMore } from './ProviderProfileExploreMore'
import { AgeGroupsSection } from './components/AgeGroupsSection'
import { BirthdateSection, BirthdateSectionProps } from './components/BirthdateSection'
import { ChallengesSection } from './components/ChallengesSection'
import { EthnicitiesSection } from './components/EthnicitiesSection'
import { FaithSection } from './components/FaithSection'
import { GenderFocusSection } from './components/GenderFocusSection'
import { GenderSection, GenderSectionProps } from './components/GenderSection'
import { LanguagesSpokenSection } from './components/LanguagesSpokenSection'
import { LocationSection } from './components/LocationSection/LocationSection'
import { LoginEmailProps, LoginEmailSection } from './components/LoginEmailSection'
import { ModalitiesSection } from './components/ModalitiesSection'
import { NpiSection, NpiSectionProps } from './components/NpiSection'
import { PersonalContactInfo, PersonalContactInfoProps } from './components/PersonalContactInfo'
import { PhotoNameTitle, PhotoNameTitleProps } from './components/PhotoNameTitle'
import { PopulationSection } from './components/PopulationSection'
import { PronounSection, PronounSectionProps } from './components/PronounSection'
import { SexualitySection } from './components/SexualitySection'
import { SpecialtiesSectionProps } from './components/SpecialtiesSectionBase'
import { TimezoneSection, TimezoneSectionProps } from './components/TimezoneSection'
import { TypesOfTherapySection } from './components/TypesOfTherapySection'
import { VideoBio, VideoBioProps } from './components/VideoBio'
import { WrittenBio, WrittenBioProps } from './components/WrittenBio'

interface Props {
  provider: FullProviderFragment
  refetch?: () => void
}

export interface ProviderProfileSectionProps {
  isEdit: boolean
}

export function ProviderProfile({ provider, refetch = noop }: Props) {
  const isAdmin = useIsAdmin()
  const photoNameTitleProps = useMemo<PhotoNameTitleProps>(() => {
    const { id, firstName, lastName, legalFirstName, legalLastName, avatarUrl, licenseTitle, slug } = provider
    return { id, firstName, lastName, legalFirstName, legalLastName, avatarUrl, licenseTitle, slug }
  }, [provider])

  const writtenBioProps = useMemo<WrittenBioProps>(() => {
    const { id, aboutBlurb, rawAboutBlurb } = provider
    return { id, aboutBlurb, rawAboutBlurb }
  }, [provider])

  const videoBioProps = useMemo<VideoBioProps>(() => {
    const { videoAskResponses, id } = provider
    return { videoAskResponses, id }
  }, [provider])

  const personalContactInfoProps = useMemo<PersonalContactInfoProps>(() => {
    const { id, personalEmail, mobilePhone } = provider
    return { id, personalEmail, mobilePhone }
  }, [provider])

  const genderSectionProps = useMemo<GenderSectionProps>(() => {
    const { id, gender } = provider
    return { id, gender }
  }, [provider])

  const npiSectionProps = useMemo<NpiSectionProps>(() => {
    const { id, npi } = provider
    return { id, npi }
  }, [provider])

  const pronounSectionProps = useMemo<PronounSectionProps>(() => {
    const { id, pronouns } = provider
    return { id, pronouns }
  }, [provider])

  const birthdateSectionProps = useMemo<BirthdateSectionProps>(() => {
    const { id, dob } = provider
    return { id, dob }
  }, [provider])

  const timezoneProps = useMemo<TimezoneSectionProps>(() => {
    const { id, timezone } = provider
    return { id, timezone }
  }, [provider])

  const specialtySectionProps = useMemo<SpecialtiesSectionProps>(() => {
    const { specialties, id } = provider
    return { id, specialties }
  }, [provider])

  const loginEmailSectionProps = useMemo<LoginEmailProps>(() => {
    const { id, email } = provider
    return { id, email }
  }, [provider])

  const shouldShowProfileEmptyState = !provider.isProfileComplete && !isAdmin

  return (
    <PageWrapper>
      <Container direction="row-reverse" spacing={4}>
        <ProfileContainer size={12}>
          {shouldShowProfileEmptyState && !isAdmin ? (
            <ProviderProfileExploreMore />
          ) : (
            <>
              <PhotoNameTitle {...photoNameTitleProps} />

              <WrittenBio {...writtenBioProps} />
              <VideoBio {...videoBioProps} />
              <PersonalContactInfo {...personalContactInfoProps} />
              <LocationSection providerId={provider.id} />
              <NpiSection {...npiSectionProps} />
              <GenderSection {...genderSectionProps} />
              <PronounSection {...pronounSectionProps} />
              <BirthdateSection {...birthdateSectionProps} />
              <TimezoneSection {...timezoneProps} />
              <EthnicitiesSection {...specialtySectionProps} refetch={refetch} />
              <LanguagesSpokenSection {...specialtySectionProps} refetch={refetch} />
              <SexualitySection {...specialtySectionProps} refetch={refetch} />
              <FaithSection {...specialtySectionProps} refetch={refetch} />
              <ModalitiesSection {...specialtySectionProps} refetch={refetch} />
              <ChallengesSection {...specialtySectionProps} refetch={refetch} />
              <AgeGroupsSection {...specialtySectionProps} refetch={refetch} />
              <PopulationSection {...specialtySectionProps} refetch={refetch} />
              <GenderFocusSection {...specialtySectionProps} refetch={refetch} />
              <TypesOfTherapySection {...specialtySectionProps} refetch={refetch} />
              <LoginEmailSection {...loginEmailSectionProps} />
            </>
          )}
        </ProfileContainer>
      </Container>
    </PageWrapper>
  )
}

interface ProviderProfileHocProps {
  provider?: FullProviderFragment | null
  loading: boolean
  refetch?: () => void
}
export function withProviderLoadingState(
  Profile: typeof ProviderProfile,
  { provider, loading, refetch = noop }: ProviderProfileHocProps,
) {
  if (!provider && loading) {
    return <Skeleton style={{ height: '100vh', width: '100%', maxWidth: '1128px' }} />
  }

  if (!provider) {
    return <div>Unable to load provider profile</div>
  }
  return <Profile provider={provider} refetch={refetch} />
}

const PageWrapper = styled('div')`
  max-width: 1300px;
  padding: 0 var(--margin-2) var(--margin-6) var(--margin-2);
`

const Container = styled(Grid)`
  &.MuiGrid-container {
    width: auto;
  }
`
Container.defaultProps = { container: true }

const ProfileContainer = styled(Grid)`
  &.MuiGrid-grid-md-9 {
    max-width: 1000px;
  }

  .alert-separator {
    border: 0;
    margin-top: 0;
    background-color: ${borderGrey};
    height: 1px;
    display: block;
  }
`
