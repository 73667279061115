import { IconProps } from './IconProps'

export function IconMicrophone({ size = 24, color = 'currentColor' }: IconProps) {
  const height = (28 / 29) * size

  return (
    <svg width={height} height={height} viewBox="0 0 29 28" fill={color} xmlns="http://www.w3.org/2000/svg">
      <path d="M14.3959 17.1579C16.4927 17.1579 18.1727 15.4653 18.1727 13.3684L18.1854 5.78948C18.1854 3.69263 16.4927 2 14.3959 2C12.2991 2 10.6064 3.69263 10.6064 5.78948V13.3684C10.6064 15.4653 12.2991 17.1579 14.3959 17.1579ZM21.5906 13.3684C21.3145 13.3684 21.0927 13.5934 21.0717 13.8687C20.8038 17.3893 17.7267 19.8105 14.3959 19.8105C11.0651 19.8105 7.98799 17.3893 7.72005 13.8687C7.69909 13.5934 7.47729 13.3684 7.20115 13.3684H6.05378C5.77764 13.3684 5.55216 13.5929 5.56927 13.8685C5.81359 17.8031 8.91696 21.0241 12.7083 21.7827C12.9511 21.8313 13.1327 22.0401 13.1327 22.2877V25.5C13.1327 25.7762 13.3566 26 13.6327 26H15.1591C15.4352 26 15.6591 25.7762 15.6591 25.5V22.289C15.6591 22.0408 15.8415 21.8318 16.085 21.7837C19.8761 21.0354 22.979 17.814 23.2226 13.8685C23.2396 13.5929 23.0142 13.3684 22.738 13.3684H21.5906Z" />
    </svg>
  )
}
