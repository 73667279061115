import { useEffect } from 'react'

import { WhoIsResponsibleForPayment, useEstimatePaymentResponsibilityQuery, usePatientContextQuery } from '@nuna/api'
import { errorService } from '@nuna/core'
import { toast } from '@nuna/tunic'

const { transformUserGraphqlError } = errorService

export function useIsProviderInNetwork({ providerId }: { providerId: string }) {
  const { data: patientContextData, loading } = usePatientContextQuery()
  const patientId = patientContextData?.patientContext.patient.id ?? ''

  const { data, error } = useEstimatePaymentResponsibilityQuery({
    skip: !patientId,
    variables: {
      input: {
        patientId,
        providerId,
      },
    },
  })

  useEffect(() => {
    if (error) {
      console.error(error)
      toast.urgent(transformUserGraphqlError(error, 'Could not verify network status of provider'))
    }
  }, [error])

  return [
    data?.estimatePaymentResponsibility.whoIsResponsibleForPayment === WhoIsResponsibleForPayment.Client
      ? data?.estimatePaymentResponsibility.cashFallbackReason
      : undefined,
    loading,
  ] as const
}
