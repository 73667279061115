import { padStart } from 'lodash'

import { AccessCodeCoverageDetailsFragment, Role } from '@nuna/api'
import { hasRole, useUserRole } from '@nuna/auth'
import { Card, CardBody, CardHeader, IconShieldDeprecated } from '@nuna/tunic'

interface Props {
  coverage: AccessCodeCoverageDetailsFragment
}

export function AccessCodeCoverageCard({ coverage }: Props) {
  const role = useUserRole()
  const { coupon } = coverage

  return (
    <Card>
      <CardBody>
        <CardHeader className="v-align space-between" divider={false}>
          <span>Access Code</span>
          <IconShieldDeprecated />
        </CardHeader>
        <CardBody className="large text-dark-grey">
          {hasRole(role, Role.Admin) ? coupon : maskAccessCode(coupon)}
        </CardBody>
      </CardBody>
    </Card>
  )
}

function maskAccessCode(code: string) {
  const length = code.length
  const charactersShownCount = length > 4 ? 4 : 1
  const charactersShown = code.substr(-charactersShownCount, charactersShownCount)
  return padStart(charactersShown, length, '•')
}
