import { useMemo } from 'react'
import { useSearchParams } from 'react-router-dom'

export const ALLOWED_OAUTH_REDIRECT_PARAMS = ['companyId', 'referralId', 'inviteId', 'tokenId']

export interface PatientSignupParams {
  // Used in the normal direct signup flow
  firstName?: string
  lastName?: string
  email?: string

  // Used for invite links from companies that require a referralId
  companyId?: string
  companySlug?: string
  referralId?: string

  // Used for the invite flow from other patients
  inviteId?: string

  // Used in the add client / pre-configure patient flow
  patientSource?: string
  providerName?: string
  tokenId?: string

  // Used in the patient OAuth and SSO signup flow
  oauthToken?: string
  ssoToken?: string
  ssoMissingFields?: string
  ssoCoveredSessions?: string
  ssoSupportPhoneNumber?: string
}

export interface ProviderSignupParams {
  // Used for PG-driven signup flow to associate provider with Hubspot contact
  contactId?: string
}

export type SignupParams = PatientSignupParams & ProviderSignupParams

export function useSignupSearchParams(): SignupParams {
  const [searchParams] = useSearchParams()

  return useMemo(() => {
    const firstName = searchParams.get('firstName')
    const lastName = searchParams.get('lastName')
    const patientSource = searchParams.get('patientSource')

    return {
      firstName: firstName ? firstName.replace('+', ' ') : undefined, // a band-aid but we have issues with Iterable double encoding + signs
      lastName: lastName ? lastName.replace('+', ' ') : undefined, // a band-aid but we have issues with Iterable double encoding + signs
      email: searchParams.get('email') ?? undefined,
      companyId: searchParams.get('companyId') ?? undefined,
      companySlug: searchParams.get('companySlug') ?? undefined,
      referralId: searchParams.get('referralId') ?? undefined,
      inviteId: searchParams.get('inviteId') ?? undefined,
      patientSource: patientSource ? patientSource.toLowerCase() : undefined,
      providerName: searchParams.get('providerName') ?? undefined,
      tokenId: searchParams.get('tokenId') ?? undefined,
      oauthToken: searchParams.get('oauthToken') ?? undefined,
      contactId: searchParams.get('contactId') ?? undefined,
      ssoToken: searchParams.get('ssoToken') ?? undefined,
      ssoMissingFields: searchParams.get('ssoMissingFields') ?? undefined,
      ssoCoveredSessions: searchParams.get('ssoCoveredSessions') ?? undefined,
      ssoSupportPhoneNumber: searchParams.get('ssoSupportPhoneNumber') ?? undefined,
    }
  }, [searchParams])
}
