import { css, styled } from '@mui/material'
import { NavLinkProps } from 'react-router-dom'

import { greySet, salmonSet, tealSet, white } from '../../styles/colorSets'
import { ButtonBase, ButtonExternalLinkBase, ButtonInternalLinkBase, ButtonProps } from './ButtonBase'
import { buttonBackgroundColor, buttonTextColor } from './utils'

type OutlineVariant = 'primary' | 'secondary' | 'dark' | 'destroy'

export interface OutlineButtonProps extends ButtonProps {
  variant?: OutlineVariant
}

export interface OutlineButtonLinkProps extends NavLinkProps {
  variant?: OutlineVariant
}

export interface OutlineButtonExternalLinkProps extends React.HTMLAttributes<HTMLAnchorElement> {
  variant?: OutlineVariant
  href: string
}

const styles = ({ variant = 'primary' }: Pick<OutlineButtonProps, 'variant'>) => css`
  ${(() => {
    if (variant === 'secondary') {
      return buttonTextColor(greySet.primary)
    }

    if (variant === 'dark') {
      return buttonTextColor(white)
    }

    if (variant === 'destroy') {
      return buttonTextColor(salmonSet[80])
    }

    return buttonTextColor(tealSet.primary)
  })()}
  ${(() => {
    if (variant === 'secondary') {
      return buttonBackgroundColor(
        {
          color: greySet[50].hex,
          focusColor: greySet.tint[20],
        },
        { outline: true },
      )
    }

    if (variant === 'dark') {
      return buttonBackgroundColor({ color: '#fff', focusColor: 'rgba(255, 255, 255, 0.15)' }, { outline: true })
    }

    if (variant === 'destroy') {
      return buttonBackgroundColor({ color: salmonSet[80].hex, focusColor: salmonSet.tint[20] }, { outline: true })
    }

    return buttonBackgroundColor(
      {
        color: tealSet[70].hex,
        focusColor: tealSet.tint[40],
      },
      { outline: true },
    )
  })()}
`

export const OutlineButton = styled(ButtonBase)<OutlineButtonProps>`
  ${styles}
`

export const OutlineButtonLink = styled(ButtonInternalLinkBase)<OutlineButtonLinkProps>`
  ${styles}
`

export const OutlineButtonExternalLink = styled(ButtonExternalLinkBase)<OutlineButtonExternalLinkProps>`
  ${styles}
`
