import { WatchQueryFetchPolicy } from '@apollo/client'

import {
  AccessCodeCoverageDetailsFragment,
  CashCoverageDetailsFragment,
  EapCoverageDetailsFragment,
  InsuranceCoverageDetailsFragment,
  PatientCoverageFragment,
  PaymentPreference,
  usePatientContextQuery,
  usePatientCoverageQuery,
} from '@nuna/api'
import { CoverageValidationOptions, coverageService } from '@nuna/core'

import { CoverageTypeCardProps } from '../components/CoverageTypeCard'
import { getCurrentAndAlternativeCoverages, isCashInvalid, isInsuranceInvalid } from '../utils/client-coverage.utils'

interface Options {
  fetchPolicy?: WatchQueryFetchPolicy
  date?: Date
  isProviderSourced?: boolean
}

export function useCurrentCoverageCardDetails(options: Options = { fetchPolicy: 'cache-first', date: undefined }) {
  const { data: patientContextData } = usePatientContextQuery()
  const patientId = patientContextData?.patientContext.patient.id

  const queryResult = usePatientCoverageQuery({
    variables: {
      patientId: patientId ?? '',
      date: options.date,
      isProviderSourced: options.isProviderSourced,
    },
    skip: !patientId,
    fetchPolicy: options.fetchPolicy,
  })

  const { currentCoverage } = getCurrentAndAlternativeCoverages(true, queryResult.data?.patientCoverage)

  let coverageCardDetails: CoverageTypeCardProps | null = null

  if (currentCoverage?.type === PaymentPreference.Employer) {
    const details = currentCoverage.details as EapCoverageDetailsFragment

    const isZeroCap = coverageService.isZeroCapEAP(details)

    const isValidOptions: CoverageValidationOptions = {}
    if (isZeroCap) {
      isValidOptions.checkAlternatePaymentCoverage = queryResult.data?.patientCoverage as PatientCoverageFragment[]
    }
    const isInvalid = !coverageService.isEAPValid(details, isValidOptions)

    coverageCardDetails = {
      type: 'employer',
      sessionCount: details.sessionsCap,
      employerName: details.employerName,
      employerLogo: details.employerLogo,
      isInvalid,
      isZeroCap,
    }
  } else if (currentCoverage?.type === PaymentPreference.Cash) {
    const details = currentCoverage.details as CashCoverageDetailsFragment
    const isInvalid = isCashInvalid(details)

    coverageCardDetails = {
      type: 'cash',
      sessionCost: details.cost,
      isInvalid,
    }
  } else if (currentCoverage?.type === PaymentPreference.Insurance) {
    const details = currentCoverage.details as InsuranceCoverageDetailsFragment
    const isInvalid = isInsuranceInvalid(details)
    coverageCardDetails = {
      type: 'insurance',
      insuredFullName: details.subscriber,
      memberId: details.planMemberId,
      verifiedAt: details.verifiedDate,
      payerName: details.planName,
      payerLogoUrl: details.insuranceLogoUrl,
      isInvalid,
      failureReason: details.failureReason || undefined,
      cardFrontUrl: details.cardFrontUrl || undefined,
      cardBackUrl: details.cardBackUrl || undefined,
    }
  } else if (currentCoverage?.type === PaymentPreference.Accesscode) {
    const details = currentCoverage.details as AccessCodeCoverageDetailsFragment
    const isInvalid = false

    coverageCardDetails = {
      type: 'accessCode',
      code: details.coupon,
      sessionCount: details.sessionsCap,
      isInvalid,
    }
  }

  return { coverageCardDetails, currentCoverage, queryResult }
}
