import { styled } from '@mui/material'
import { CircularProgress } from '@mui/material'

import { CustomerCompanyQuery } from '@nuna/api'
import { useEnvironmentContext } from '@nuna/environment'
import { IconCheckCircle, IconError, TextButtonExternalLink } from '@nuna/tunic'

interface Props {
  customerCompany: CustomerCompanyQuery['customerCompany'] | undefined
}

export function CustomerOverview({ customerCompany }: Props) {
  const { ARROW_BASE_URL } = useEnvironmentContext()

  if (!customerCompany) {
    return (
      <div className="mt-4">
        <CircularProgress className="ml-1" />
      </div>
    )
  }

  const customerSignupLink = `${ARROW_BASE_URL}/signup/${customerCompany?.companySlug}`

  return (
    <Container className="mt-2">
      <section>
        {customerCompany?.logoUrl && (
          <Logo className="mb-2" src={customerCompany?.logoUrl ?? ''} alt={customerCompany?.name} />
        )}
        <IconCheckbox checked={customerCompany.supportsHrisIntegration}>Sync using HRIS</IconCheckbox>
        <IconCheckbox checked={customerCompany.isRunningEligibilityChecks}>
          Check patients eligibility monthly
        </IconCheckbox>
        <IconCheckbox checked={customerCompany.isReferralIdRequired}>
          Require client to sign up with referral partner case ID
        </IconCheckbox>
        {customerCompany.insurancePolicyGroups?.length > 0 && (
          <IconCheckbox checked>
            Insurance Policy Group Numbers: {customerCompany.insurancePolicyGroups.map(g => g.groupId).join(', ')}
          </IconCheckbox>
        )}
        <span className="text-bold mr-1">Your customer sign up URL: </span>
        <TextButtonExternalLink href={customerSignupLink} includeReferrer>
          {customerSignupLink}
        </TextButtonExternalLink>
      </section>
    </Container>
  )
}

const Container = styled('div')`
  display: flex;
  justify-content: flex-start;
  margin-left: 2rem;
`

const Logo = styled('img')`
  height: 40px;
`

function IconCheckbox({ children, checked }: React.HTMLProps<HTMLInputElement>) {
  const classNames = `text-medium v-align mb-2 ${checked ? '' : 'strike-through '}`
  return (
    <div className={classNames}>
      {checked ? <IconCheckCircle size={20} className="mr-1" /> : <IconError size={20} className="mr-1" />}
      {children}
    </div>
  )
}
