import { styled } from '@mui/material'
import moment from 'moment'
import { useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'

import {
  AppointmentCurrentStatus,
  InternalAppointment,
  InternalAppointmentDetailQuery,
  useAppointmentVideoConversationMessagesQuery,
  useInternalAppointmentDetailQuery,
} from '@nuna/api'
import { CancelAsAdminForm } from '@nuna/appointment'
import { appointmentService, routeService } from '@nuna/core'
import {
  Drawer,
  InfoWithHeading,
  OutlineButton,
  PageContent,
  PageHeader,
  PageHeading,
  PageWrapper,
  StatusLabel,
  TextButton,
} from '@nuna/tunic'

import { AppointmentConversationTable } from './AppointmentConversationTable'

const { AppointmentStatusMap, appointmentChangeReasons } = appointmentService

const DrawerContent = styled('div')`
  padding: 1.6rem;
`

type PathParams = {
  appointmentId: string
}

export function AppointmentDetail() {
  const { appointmentId = '' } = useParams<PathParams>()
  const { error, data } = useInternalAppointmentDetailQuery({
    variables: {
      id: appointmentId,
    },
    fetchPolicy: 'network-only',
  })

  const { data: conversationData } = useAppointmentVideoConversationMessagesQuery({
    variables: {
      appointmentId,
    },
    fetchPolicy: 'network-only',
  })

  const navigate = useNavigate()

  const [isCancelDrawerOpen, setIsCancelDrawerOpen] = useState(false)

  if (error || !data?.internalAppointment) {
    return null
  }

  const appointment = data.internalAppointment

  const { currentChangeReason, currentFreeFormReason, currentReasonDate } = appointment

  const provider = appointment.provider
  const { firstName: providerFirstName, lastName: providerLastName } = provider
  const patient = appointment.patient
  const { firstName: patientFirstName, lastName: patientLastName } = patient
  const patientName = `${patientFirstName} ${patientLastName}`
  const providerName = `${providerFirstName} ${providerLastName}`

  return (
    <PageWrapper>
      <PageHeader>
        <PageHeading className="m-0">Appointment Detail</PageHeading>

        {appointment.currentStatus === AppointmentCurrentStatus.Active && (
          <OutlineButton onClick={() => setIsCancelDrawerOpen(!isCancelDrawerOpen)}>Cancel Appointment</OutlineButton>
        )}
      </PageHeader>
      <PageContent>
        <h2 className="h6 mb-3">Overview</h2>

        <InfoWithHeading heading="Date Created" info={moment(appointment.createdAt).format('M/D/YYYY [at] LT')} />
        <InfoWithHeading
          heading="Scheduled Start Time"
          info={moment(appointment.startDatetime).format('M/D/YYYY [at] LT')}
        />
        <InfoWithHeading heading="Provider" info={providerName} secondaryInfo={provider.timezone} />
        <InfoWithHeading heading="Client" info={patientName} secondaryInfo={patient.timezone} />
        <InfoWithHeading
          heading="Status"
          info={
            appointment.session?.id ? (
              <>
                <StatusLabel intent="information">Note Completed</StatusLabel> on{' '}
                {moment(appointment.session.createdAt).format('l [at] h:mm A')}
                <TextButton
                  className="ml-4"
                  onClick={() => navigate(routeService.sessionDetailRoute(appointment.session?.id))}
                >
                  View Session Note
                </TextButton>
              </>
            ) : (
              <div>
                {getAppointmentStatus(appointment)}
                {currentReasonDate && <> on {moment(currentReasonDate).format('M/D/YYYY [at] LT')}</>}
              </div>
            )
          }
        />

        {currentChangeReason && (
          <InfoWithHeading
            heading="Status Details"
            info={
              <>
                {appointmentChangeReasons.find(reason => reason.value === currentChangeReason)?.display}
                {currentFreeFormReason && (
                  <>
                    <br />
                    Note: "{currentFreeFormReason}"
                  </>
                )}
              </>
            }
          />
        )}

        <h2 className="h6 mt-4 mb-3">Session Chat</h2>
        <AppointmentConversationTable
          provider={appointment.provider}
          patient={appointment.patient}
          messages={conversationData?.appointmentVideoConversationMessages || []}
        />

        <Drawer isOpen={isCancelDrawerOpen} onClose={() => setIsCancelDrawerOpen(false)} size="400px">
          <DrawerContent>
            <CancelAsAdminForm
              appointment={appointment as InternalAppointment}
              onComplete={() => setIsCancelDrawerOpen(false)}
            />
          </DrawerContent>
        </Drawer>
      </PageContent>
    </PageWrapper>
  )
}

const getAppointmentStatus = (appt: NonNullable<InternalAppointmentDetailQuery['internalAppointment']>) => {
  if (appt.currentStatus === AppointmentCurrentStatus.Active) {
    if (moment(appt.startDatetime).isAfter(moment())) {
      return AppointmentStatusMap[AppointmentCurrentStatus.Active]
    } else {
      return appt?.session?.id ? '' : 'Needs Session Note'
    }
  } else {
    return AppointmentStatusMap[appt.currentStatus]
  }
}
