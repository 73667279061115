import { styled } from '@mui/material'
import moment from 'moment'

import { UpcomingAppointmentFragment } from '@nuna/api'
import { useIsAdmin } from '@nuna/auth'
import { borderGrey } from '@nuna/tunic'

import { HistorySessionItemProps, SessionItem } from './SessionItem'

interface UpcomingSessionItemProps extends Pick<HistorySessionItemProps, 'showDate'> {
  appointment: UpcomingAppointmentFragment
}

export function UpcomingSessionItem({ appointment, showDate }: UpcomingSessionItemProps) {
  const isAdmin = useIsAdmin()
  return (
    <SessionItem
      date={showDate && appointment.startDatetime}
      title="Upcoming Session"
      linkPath={isAdmin ? `/sessions/${appointment.id}` : undefined}
      text={`with ${appointment.provider.firstName} ${appointment.provider.lastName}`}
      renderActions={
        <>
          <RelativeDate>Starts {moment(appointment.startDatetime).fromNow()}</RelativeDate>

          {/* // TODO: Will add as follow-up. Eng-977 */}
          {/* <HoverState>
            {([isHovered, setIsHovered]) => (
              <Tooltip content="Reschedule Session">
                <IconButton
                  onMouseOver={() => setIsHovered(true)}
                  onMouseLeave={() => setIsHovered(false)}
                  variant="secondary"
                >
                  <IconRescheduleAppointment isHovered={isHovered} />
                </IconButton>
              </Tooltip>
            )}
          </HoverState>

          <HoverState>
            {([isHovered, setIsHovered]) => (
              <Tooltip content="Cancel Session">
                <IconButton
                  onMouseOver={() => setIsHovered(true)}
                  onMouseLeave={() => setIsHovered(false)}
                  variant="secondary"
                >
                  <IconCancelAppointment isHovered={isHovered} />
                </IconButton>
              </Tooltip>
            )}
          </HoverState> */}
        </>
      }
    />
  )
}

export const RelativeDate = styled('span')`
  padding: 13px var(--margin-2); // will remove after adding buttons
  /* padding-right: var(--margin-3); */
  /* margin-right: var(--margin-1); */
  /* border-right: 1px solid ${borderGrey}; */
`
