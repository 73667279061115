import { ApolloError } from '@apollo/client'
import { useState } from 'react'
import { ColumnInstance } from 'react-table'

import { CustomerEmployeeDimension, useRemoveCustomerEmployeeDimensionMutation } from '@nuna/api'
import { DataTableRowComponentProps } from '@nuna/data-table'
import { IconButton, IconPencil, IconTrash, csx, toast } from '@nuna/tunic'

import { CustomerEmployeeDimensionForm } from './CustomerEmployeeDimensionForm'

type CustomColumn = ColumnInstance<CustomerEmployeeDimension> & {
  className?: string
}

export function CustomerEmployeeDimensionRow({ row }: DataTableRowComponentProps<CustomerEmployeeDimension>) {
  const [editMode, setEditMode] = useState(false)

  const [removeDimension, { loading }] = useRemoveCustomerEmployeeDimensionMutation({
    refetchQueries: ['CustomerEmployeeDimensions'],
  })

  const handleRemove = async () => {
    try {
      await removeDimension({
        variables: {
          id: row.original.id,
        },
      })
      toast.success('Removed customer dimension saved')
    } catch (error) {
      let errorMessage = 'There was a problem removing the dimension.'
      if (error instanceof ApolloError && error.graphQLErrors[0]) {
        errorMessage = error.graphQLErrors[0].message
      }
      toast.urgent(errorMessage)
    }
  }

  const cells = row.cells.slice(0, 3)

  if (!editMode) {
    return (
      <tr {...row.getRowProps()} style={loading ? { opacity: 0.5, pointerEvents: 'none' } : {}}>
        {cells.map(cell => (
          <td className={csx([(cell.column as CustomColumn).className as string])}>{cell.render('Cell')}</td>
        ))}

        <td>
          <IconButton tooltip="Edit" onClick={() => setEditMode(true)}>
            <IconPencil />
          </IconButton>
        </td>

        <td className="right-align">
          <IconButton tooltip="Delete" onClick={handleRemove}>
            <IconTrash />
          </IconButton>
        </td>
      </tr>
    )
  }

  return (
    <tr>
      <td colSpan={6} style={{ paddingLeft: 0, paddingRight: 0 }}>
        <CustomerEmployeeDimensionForm dimension={row.original} onDismiss={() => setEditMode(false)} />
      </td>
    </tr>
  )
}
