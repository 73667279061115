import { useWaitingParticipants } from '@daily-co/daily-react'
import { useEffect } from 'react'

import { useVideoCallContext } from '../context/VideoCallContext'

export function useKnockLogging() {
  const { localUser } = useVideoCallContext()
  const { waitingParticipants } = useWaitingParticipants()

  useEffect(() => {
    if (!localUser.isProvider) return

    console.info(
      '[Daily Lifecycle] Waiting participants',
      waitingParticipants.length,
      waitingParticipants.map(p => p.name),
    )
  }, [waitingParticipants, localUser])
}
