import * as Yup from 'yup'
import { styled } from '@mui/material'

import { PaymentPreference } from '@nuna/api'
import { formService, routeService } from '@nuna/core'
import {
  ContextualAlert,
  Grid,
  RadioCard,
  Skeleton,
  TextButton,
  TextButtonExternalLink,
  TextButtonLink,
  borderGrey,
} from '@nuna/tunic'

import { useHasInsuranceInState } from '../../hooks/useHasInsuranceInState'
import {
  AccessCodeCoverageForm,
  AccessCodeCoverageFormValues,
  accessCodeInitialValues,
  accessCodeSchema,
} from './components/AccessCodeCoverageForm'
import {
  EmployerCoverageForm,
  EmployerCoverageFormValues,
  employerCoverageSchema,
  employerInitialValues,
} from './components/EmployerCoverageForm'
import {
  InsuranceCoverageForm,
  InsuranceCoverageFormValues,
  insuranceCoverageSchema,
  insuranceInitialValues,
} from './components/InsuranceCoverageForm'
import {
  CoverageFormContextProvider,
  CoverageFormContextProviderProps,
  useCoverageFormContext,
} from './context/CoverageFormContext'
import { useCoverageFormInputProps } from './hooks/useCoverageFormInputProps'

const { makeSelection, oopsRequired } = formService

export type VerificationStatus = 'invalid' | 'success' | 'pending' | 'zeroCapEAP'

export interface CoverageFormValues {
  paymentPreference: PaymentPreference | null
  insuranceValues: InsuranceCoverageFormValues | null
  employerValues: EmployerCoverageFormValues | null
  accessCodeValues: AccessCodeCoverageFormValues | null
}

export const coverageSchema = Yup.object().shape<CoverageFormValues>({
  paymentPreference: Yup.mixed<PaymentPreference>().nullable().required(makeSelection),
  insuranceValues: insuranceCoverageSchema.nullable().when('paymentPreference', {
    is: PaymentPreference.Insurance,
    then: insuranceCoverageSchema.required(oopsRequired('insurance')),
  }),
  employerValues: employerCoverageSchema.nullable().when('paymentPreference', {
    is: PaymentPreference.Employer,
    then: employerCoverageSchema.required(oopsRequired('employer')),
  }),
  accessCodeValues: accessCodeSchema.nullable().when('paymentPreference', {
    is: PaymentPreference.Accesscode,
    then: accessCodeSchema.required(oopsRequired('access code')),
  }),
})

export const coverageInitialValues: CoverageFormValues = {
  paymentPreference: null,
  insuranceValues: null,
  employerValues: null,
  accessCodeValues: null,
}

export function CoverageForm(props: Omit<CoverageFormContextProviderProps, 'children'>) {
  return (
    <CoverageFormContextProvider {...props}>
      <CoverageFormWithContext />
    </CoverageFormContextProvider>
  )
}

function CoverageFormWithContext() {
  const {
    patient,
    inDrawer,
    hidePreferenceRadios,
    providerAcceptsInsurance,
    state,
    audience,
    providerAcceptsReferrals,
  } = useCoverageFormContext()
  const {
    getInputProps,
    formikContext: { values, handleChange, setValues },
  } = useCoverageFormInputProps()

  const {
    hasInsuranceInState,
    queryResult: { loading },
  } = useHasInsuranceInState(patient.state ?? '')
  const hideInsurance = !hasInsuranceInState || !providerAcceptsInsurance
  if (loading) {
    return <Skeleton height={7} />
  }

  // If we are on p4 page, and have a sourceProviderId then use it to restrict insurance input
  const providerId = window.location.pathname?.includes(routeService.publicProviderBase)
    ? localStorage.getItem('providerSourcedProviderId') ?? undefined
    : undefined

  return (
    <div>
      {((inDrawer && values.paymentPreference === null) || (!inDrawer && !hidePreferenceRadios)) && (
        <CoverageTypeGroup className="mb-5" role="group">
          <Grid container spacing={inDrawer ? 2 : 4}>
            {providerAcceptsReferrals && (
              <Grid
                size={{
                  xs: 12,
                  sm: inDrawer ? 12 : 4,
                }}
              >
                <RadioCard
                  className="coverage-radio-card"
                  dataTestId="coverage-radio-employer"
                  compact={inDrawer}
                  checked={values.paymentPreference === PaymentPreference.Employer}
                  text="Employer or Organization"
                  subtext="I’m receiving care as a benefit through an employer or organization (either mine or someone else’s)"
                  {...getInputProps('paymentPreference')}
                  value={PaymentPreference.Employer}
                  onChange={e => {
                    setValues(
                      {
                        ...values,
                        employerValues: employerInitialValues,
                        accessCodeValues: null,
                        insuranceValues: null,
                      },
                      false,
                    )

                    handleChange(e)
                  }}
                />
              </Grid>
            )}

            {!hideInsurance && (
              <Grid
                size={{
                  xs: 12,
                  sm: inDrawer ? 12 : 4,
                }}
              >
                <RadioCard
                  className="coverage-radio-card"
                  dataTestId="coverage-radio-insurance"
                  compact={inDrawer}
                  checked={values.paymentPreference === PaymentPreference.Insurance}
                  text="Insurance"
                  subtext="I’ll subsidize the cost of my care through my insurance provider."
                  {...getInputProps('paymentPreference')}
                  value={PaymentPreference.Insurance}
                  onChange={e => {
                    setValues(
                      {
                        ...values,
                        employerValues: null,
                        accessCodeValues: null,
                        insuranceValues: insuranceInitialValues,
                      },
                      false,
                    )

                    handleChange(e)
                  }}
                />
              </Grid>
            )}

            <Grid
              size={{
                xs: 12,
                sm: inDrawer ? 12 : 4,
              }}
            >
              <RadioCard
                className="coverage-radio-card"
                dataTestId="coverage-radio-cash"
                compact={inDrawer}
                checked={values.paymentPreference === PaymentPreference.Cash}
                text="Paying out of pocket"
                subtext="I’ll be paying cash out of my own pocket for my care."
                {...getInputProps('paymentPreference')}
                value={PaymentPreference.Cash}
                onChange={e => {
                  setValues(
                    {
                      ...values,
                      employerValues: null,
                      accessCodeValues: null,
                      insuranceValues: null,
                    },
                    false,
                  )
                  handleChange(e)
                }}
              />
            </Grid>

            {(hideInsurance || !providerAcceptsReferrals) && (
              <Grid
                size={{
                  xs: 12,
                  sm: inDrawer ? 12 : 4,
                }}
              >
                <RadioCard
                  className="coverage-radio-card"
                  dataTestId="coverage-code-button"
                  compact={inDrawer}
                  checked={values.paymentPreference === PaymentPreference.Accesscode}
                  text="Redeem a code"
                  subtext="I have an access code that will cover my care"
                  {...getInputProps('paymentPreference')}
                  value={PaymentPreference.Accesscode}
                  onChange={e => {
                    setValues(
                      {
                        ...values,
                        employerValues: null,
                        accessCodeValues: accessCodeInitialValues,
                        insuranceValues: null,
                      },
                      false,
                    )

                    handleChange(e)
                  }}
                />
              </Grid>
            )}
          </Grid>
        </CoverageTypeGroup>
      )}
      {values.paymentPreference === null && !hideInsurance && (
        <p className="text-secondary">
          Have an access code to cover care?{' '}
          <TextButton
            data-testid="coverage-code-button"
            variant="secondary"
            onClick={() => {
              setValues(
                {
                  ...values,
                  paymentPreference: PaymentPreference.Accesscode,
                  employerValues: null,
                  accessCodeValues: accessCodeInitialValues,
                  insuranceValues: null,
                },
                false,
              )
            }}
          >
            Redeem a code
          </TextButton>
        </p>
      )}
      {values.paymentPreference === null && !hasInsuranceInState && (
        <p className="text-secondary">
          FYI, insurance coverage is not available where you live, but we’re expanding rapidly.{' '}
          <TextButtonExternalLink variant="secondary" href="https://tavahealth.typeform.com/client-waitlist">
            Join the waitlist
          </TextButtonExternalLink>
        </p>
      )}
      {values.paymentPreference === null && !providerAcceptsInsurance && (
        <ContextualAlert>
          <span>
            This provider doesn't accept insurance coverage yet.{' '}
            {patient.intakeCompleted ? (
              <>
                You can search other providers{' '}
                <TextButtonLink variant="secondary" to={routeService.switchProvider()}>
                  here
                </TextButtonLink>
                .
              </>
            ) : (
              <>
                Find a provider who accepts your insurance by completing intake{' '}
                <TextButtonLink variant="secondary" to={routeService.intake()}>
                  here
                </TextButtonLink>
                .
              </>
            )}
          </span>
        </ContextualAlert>
      )}
      {values.paymentPreference === PaymentPreference.Employer && <EmployerCoverageForm isCheckout={inDrawer} />}
      {values.paymentPreference === PaymentPreference.Insurance && (
        <InsuranceCoverageForm
          inDrawer={inDrawer}
          patient={patient}
          audience={audience}
          state={state}
          providerId={providerId}
        />
      )}
      {values.paymentPreference === PaymentPreference.Accesscode && <AccessCodeCoverageForm isCheckout={inDrawer} />}
    </div>
  )
}

const CoverageTypeGroup = styled('section')`
  border-bottom: 1px solid ${borderGrey};
  padding-bottom: var(--margin-4);
`
