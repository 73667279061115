import { styled } from '@mui/material'
import moment from 'moment'
import { Fragment, HTMLAttributes, useEffect } from 'react'
import { useInView } from 'react-intersection-observer'

import { ConversationMessageFragment, MessageType, Role } from '@nuna/api'
import { AssessmentNode } from '@nuna/assessments'
import { useAuthDataContext } from '@nuna/auth'
import { Link } from '@nuna/common'
import { Audience } from '@nuna/core'
import { useEnvironmentContext } from '@nuna/environment'
import { Avatar, Card, IconArrow, RichTextDisplay, csx, greySet, plumSet, styledUtils, tealSet } from '@nuna/tunic'

import { AppointmentFooter } from './AppointmentBoot/AppointmentFooter'
import { ConversationMessageAttachment } from './ConversationMessageAttachment'
import { ConversationSystemMessageHeader } from './ConversationSystemMessageHeader'

const { transientPropOptions } = styledUtils
interface ConversationMessageProps {
  message: ConversationMessageFragment
  audience: Audience
  isUnread: boolean
  isInitialLastReadMessage: boolean
  onView: (message: ConversationMessageFragment) => void
  disableMarkAsRead?: boolean
}

export function ConversationMessage({
  message,
  isUnread,
  isInitialLastReadMessage,
  onView,
  audience,
  disableMarkAsRead = false,
}: ConversationMessageProps) {
  const { login } = useAuthDataContext()
  const [ref, inView] = useInView({ threshold: 0, skip: disableMarkAsRead })

  const appointmentId = message.typeMetaData?.appointmentId
  const appointmentHistoryItemId = message.typeMetaData?.appointmentHistoryItemId

  useEffect(() => {
    if (inView && isUnread) {
      onView(message)
    }
  }, [inView, isUnread, message, onView])

  if (!login) {
    console.error('Not rendering message due to missing login')
    return null
  }

  const attachments = message.attachments ?? []

  return (
    <Fragment key={message.id}>
      {isInitialLastReadMessage && (
        <LastReadIndicator className="text-medium caption text-secondary text-bold">
          <span>
            New <IconArrow direction="up" size={12} />
          </span>
        </LastReadIndicator>
      )}

      <MessageBorderContainer
        ref={ref}
        actor={message.authorDisplayData?.role}
        $isAuthor={message.authorLoginId === login.id}
      >
        {message.type === MessageType.System && <ConversationSystemMessageHeader message={message} />}
        <MessageContainer>
          <div className="mb-2 text-medium v-align">
            <AuthorProfileLink
              authorId={message.authorDisplayData?.entityId}
              authorRole={message.authorDisplayData?.role}
            >
              <Avatar
                size="mini"
                className={csx(['mr-1', { 'fs-mask': message.authorDisplayData?.role === Role.Patient }])}
                url={message.authorDisplayData?.avatarUrl}
              />
            </AuthorProfileLink>
            <AuthorDetails>
              <AuthorProfileLink
                authorId={message.authorDisplayData?.entityId}
                authorRole={message.authorDisplayData?.role}
              >
                <span>
                  {message.authorDisplayData?.firstName} {message.authorDisplayData?.lastName}
                </span>
              </AuthorProfileLink>
              <span style={{ color: greySet[50].hex }}>{moment(message.createdAt).format('MMM D, h:mma')}</span>
            </AuthorDetails>
          </div>

          <RichTextDisplay className="fs-mask" editorState={JSON.stringify(message.content)} nodes={[AssessmentNode]} />

          {attachments.length > 0 && (
            <div className="mt-1">
              {attachments.map(attachment => (
                <ConversationMessageAttachment key={attachment.id} attachment={attachment} />
              ))}
            </div>
          )}
          {appointmentId && (
            <AppointmentFooter
              appointmentId={appointmentId}
              appointmentHistoryItemId={appointmentHistoryItemId}
              audience={audience}
            />
          )}
        </MessageContainer>
      </MessageBorderContainer>
    </Fragment>
  )
}

interface MessageContainerProps {
  actor?: Role
  $isAuthor: boolean
}

const COLOR_BORDER_SIZE = 12

function getMessageContainerBorder({ $isAuthor, actor }: MessageContainerProps) {
  const borderSide = $isAuthor ? 'right' : 'left'
  let borderColor: string
  if (actor === Role.Provider) {
    borderColor = plumSet[50].hex
  } else if (actor === Role.Patient) {
    borderColor = tealSet[50].hex
  } else {
    borderColor = greySet[50].hex
  }

  return `
    border: 1px solid ${borderColor};
    border-${borderSide}: ${COLOR_BORDER_SIZE}px solid ${borderColor};
  `
}

interface AuthorProfileLinkProps extends HTMLAttributes<HTMLSpanElement> {
  authorRole?: Role | null
  authorId?: string | null
}
function AuthorProfileLink({ children, authorRole, authorId }: AuthorProfileLinkProps) {
  const { commonRoutes, app } = useEnvironmentContext()
  if (app === 'harmony' && authorRole === Role.Patient) {
    return (
      <Link openInNewTab className="v-align" to={commonRoutes.clientHarmonyProfile(authorId ?? '')}>
        {children}
      </Link>
    )
  }

  // eslint-disable-next-line react/jsx-no-useless-fragment
  return <>{children}</>
}

const AuthorDetails = styled('div')`
  display: flex;
  flex-flow: column;
`

const MessageBorderContainer = styled(Card, transientPropOptions)<MessageContainerProps>`
  width: 100%;
  margin-top: var(--margin-2);
  color: var(--body-color);
  position: relative;
  overflow: hidden;

  &:first-of-type {
    margin-top: 0;
  }

  ${props => getMessageContainerBorder(props)};
`

const MessageContainer = styled('div')`
  padding: var(--margin-2) var(--margin-3);
`

const LastReadIndicator = styled('div')`
  border-bottom: 1px solid ${greySet[70].hex};
  margin-top: var(--margin-3);
  text-transform: uppercase;
  position: relative;
  margin-right: 52px;

  span {
    position: absolute;
    right: -52px;
    top: -8px;
  }
`
