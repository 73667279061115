import { TextButtonExternalLink } from '@nuna/tunic'

import highIllustrationSrc from '../img/assessment-high-illustration.png'
import lowIllustrationSrc from '../img/assessment-low-illustration.png'
import midIllustrationSrc from '../img/assessment-mid-illustration.png'
import suicidalIllustrationSrc from '../img/assessment-suicidal-illustration.png'
import { Severity } from '../types/types'

export const resultsContent = {
  [Severity.Minimal]: {
    illustration: lowIllustrationSrc,
    heading: 'Looks like you’re doing pretty well.',
    body: (
      <p>
        Talking with a therapist can help you build on your skills and prepare for harder days. Many people in your
        situation see meaningful improvement after 8-12 weeks of consistent effort through therapy. You’re worth the
        effort.
      </p>
    ),
  },
  [Severity.Mild]: {
    illustration: lowIllustrationSrc,
    heading: 'Seems like you are faring pretty well.',
    body: (
      <p>
        Talking with your therapist can help you build on your skills and prepare for harder days. Many people in your
        situation see meaningful improvement after 8-12 weeks of consistent effort through therapy. You’re worth the
        effort.
      </p>
    ),
  },
  [Severity.Moderate]: {
    illustration: midIllustrationSrc,
    heading: 'Looks like you’ve been having a tough time.',
    body: (
      <p>
        Talking to your therapist could help you feel better equipped to deal with challenges. Many people in your
        situation see meaningful improvement after 8-12 weeks of consistent effort through therapy. You’re worth the
        effort.
      </p>
    ),
  },
  [Severity.Severe]: {
    illustration: highIllustrationSrc,
    heading: 'Looks like you’re having a really hard time. Therapy can help.',
    body: (
      <p>
        In your case, we recommend therapy, as it can have a significant impact on how you’re feeling and your ability
        to cope. Many people in your situation see meaningful improvement after 8-12 weeks of consistent effort through
        therapy. You’re worth the effort.
      </p>
    ),
  },
  suicideRisk: {
    illustration: suicidalIllustrationSrc,
    heading: `Looks like you’re running on empty and you may need help right now.`,
    body: (
      <>
        <p>
          Based on your answers, you may need support urgently. You are not alone — we are here with you, offering
          understanding, support, and a way forward. The options below are available for your immediate support.
        </p>
        <ul className="list">
          <li>
            Reach out to our TavaCare Crisis team at <a href="tel:8336436093">(833) 643-6093</a> and connect with a
            licensed crisis counselor in less than 90 seconds.
          </li>
          <li>
            If you are considering suicide or self-harm or are in danger, please call your local emergency services
            immediately to ensure your safety.
          </li>
          <li>
            Visit <TextButtonExternalLink href="https://afsp.org/get-help">afsp.org/get-help</TextButtonExternalLink>
          </li>
        </ul>
      </>
    ),
  },
}
