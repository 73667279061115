import { styled } from '@mui/material'
import { HTMLAttributes, useEffect, useLayoutEffect, useState } from 'react'
import { useMediaQuery } from 'react-responsive'

import {
  Avatar,
  BelowTablet,
  IconButton,
  IconClose,
  body2,
  borderGrey,
  csx,
  eggshell,
  fontSize,
  shadowDepth,
  toast,
  useResizeObserver,
} from '@nuna/tunic'

import { useProviderSignupIntakeContext } from '../scenes/SignupIntake/ProviderSignupIntakeContext'
import { HubspotWindow, useHubspotChatSetup } from '../scenes/SignupIntake/useHubspotChatSetup'

declare let window: HubspotWindow

export function IntakeSupportChat(props: HTMLAttributes<HTMLSpanElement>) {
  const hsReady = useHubspotChatSetup()
  const isMobile = useMediaQuery({ query: `(${BelowTablet})` })
  const [chatAnchorEl, setChatAnchorEl] = useState<null | HTMLElement>(null)
  const { guide } = useProviderSignupIntakeContext()

  useLayoutEffect(() => {
    if (hsReady && window.HubSpotConversations?.widget) {
      window.HubSpotConversations.widget.load()
    }
  }, [hsReady])

  const handleToggle = (event: React.MouseEvent<HTMLButtonElement>) => {
    if (window.HubSpotConversations?.widget) {
      const newState = chatAnchorEl ? null : event.currentTarget.parentElement
      setChatAnchorEl(newState as HTMLElement)
    } else {
      toast.urgent('The support chat is not ready yet. Please try again later.')
    }
  }

  return (
    <>
      <style>
        {`
          .BeaconFabButtonFrame {
              display: none;
          }
        `}
      </style>
      <span {...props}>
        {!chatAnchorEl && (
          <UserChatButton onClick={handleToggle} type="button">
            <Avatar url={guide?.avatarUrl} size="mini" className={csx({ 'mr-1': !isMobile })} />
            {isMobile ? null : <>Ask {guide.firstName}</>}
          </UserChatButton>
        )}
        {!!chatAnchorEl && (
          <IconButton tooltip="Close" active onClick={handleToggle}>
            <IconClose />
          </IconButton>
        )}
      </span>
      <ChatPopover anchorEl={chatAnchorEl} id="hubspot-chat" onClose={() => setChatAnchorEl(null)} />
    </>
  )
}

const UserChatButton = styled('button')`
  align-items: center;
  background-color: ${eggshell};
  border: 1px solid ${borderGrey};
  border-radius: 48px;
  color: ${body2};
  display: inline-flex;
  font-size: ${fontSize.large};
  padding: 0.5rem 0.75rem 0.5rem 0.5rem;
  position: relative;

  &:hover {
    background-color: #fff;
    box-shadow: ${shadowDepth(1, 'warm')};
    &::after {
      border-radius: 48px;
      content: '';
      display: block;
      height: calc(100% + 4px);
      left: -2px;
      position: absolute;
      top: -2px;
      width: calc(100% + 4px);
      border: 2px solid ${borderGrey};
    }
  }

  @media (${BelowTablet}) {
    padding: 0.5rem;
  }
`

interface ChatPopoverProps extends HTMLAttributes<HTMLDivElement> {
  anchorEl: HTMLElement | null
  onClose: () => void
}
function ChatPopover({ id, anchorEl, onClose, ...props }: ChatPopoverProps) {
  const [setBody] = useResizeObserver()
  useEffect(() => {
    setBody(document.body)
  }, [setBody])

  const anchorPosition = anchorEl?.getBoundingClientRect()
  const bodyWidth = document.body.getBoundingClientRect().width

  const top = (anchorPosition?.top ?? 0) - 510
  const right = bodyWidth - (anchorPosition?.right ?? 0)

  return (
    <StyledChatPopover {...props} isOpen={!!anchorEl} top={top} right={right}>
      <IconButton tooltip="Close" className="mobile-close-button" variant="dark" type="button" onClick={onClose}>
        <IconClose size={18} />
      </IconButton>
      <div id={id}></div>
    </StyledChatPopover>
  )
}

interface StyledChatPopoverProps {
  isOpen: boolean
  top: number
  right: number
}
const StyledChatPopover = styled('div')<StyledChatPopoverProps>`
  position: fixed;
  z-index: ${({ isOpen }) => (isOpen ? 100 : -1)};
  visibility: ${({ isOpen }) => (isOpen ? 'visible' : 'hidden')};
  top: ${({ top }) => top}px;
  right: ${({ right }) => right}px;
  .mobile-close-button {
    display: none;
    position: absolute;
    right: 6px;
    top: 14px;
  }
  #hubspot-conversations-inline-iframe {
    width: 300px;
    height: 500px;
    border: none;
    border-radius: var(--border-radius);
    box-shadow: ${shadowDepth(1, 'warm')};
  }

  @media (${BelowTablet}) {
    width: 100%;
    height: 100%;
    top: 0 !important;
    left: 0 !important;
    .mobile-close-button {
      display: ${({ isOpen }) => (isOpen ? 'block' : 'none')};
    }
    #hubspot-chat,
    #hubspot-conversations-inline-parent,
    #hubspot-conversations-inline-iframe {
      width: 100%;
      height: 100%;
    }
    #hubspot-conversations-inline-iframe {
      border-radius: 0;
    }
  }
`
