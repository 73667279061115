import { isNil } from 'lodash'
import moment from 'moment'

import { AppointmentCurrentStatus, AppointmentStatsQuery, useAppointmentStatsQuery } from '@nuna/api'

export function useMostRecentSessionNote(clientId?: string | null, providerId?: string | null) {
  const { data: otherAppointmentData } = useAppointmentStatsQuery({
    variables: {
      options: {
        patientId: clientId,
        providerId: providerId,
        end: moment.utc().endOf('day').toISOString(),
        statusIsIn: [AppointmentCurrentStatus.Active],
        hasUncompletedSessionNote: false,
      },
    },
    skip: isNil(clientId) || isNil(providerId),
  })

  return mostRecentNote(otherAppointmentData?.appointmentStats?.filteredAppointments)
}

function mostRecentNote(appointments: AppointmentStatsQuery['appointmentStats']['filteredAppointments']) {
  if (!appointments) {
    return null
  }

  if (appointments.length === 0) {
    return null
  }

  return [...appointments].sort((a, b) => moment(b.startDatetime).diff(moment(a.startDatetime)))[0]
}
