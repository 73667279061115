import { styled } from '@mui/material'

import { BelowTabletLandscape, Grid, eggshell, fontSize, greySet, tealSet } from '@nuna/tunic'

import arrowSrc from './img/arrow.png'
import laptopGuySrc from './img/laptop-guy.png'

export function EmptyState() {
  return (
    <Container>
      <Grid container spacing={1} alignItems="center" direction="row-reverse">
        <Grid
          size={{
            xs: 12,
            md: 'grow',
          }}
        >
          <Illustration alt="" />
        </Grid>
        <Grid
          size={{
            xs: 12,
            md: 8,
          }}
        >
          <Heading className="h3">
            This is your
            <br />
            Message Center
          </Heading>
          <Paragraph className="large text-light mb-3">
            New messages will appear here as they arrive. Get started by opening a new thread with one of your clients.
          </Paragraph>
          <Arrow />
        </Grid>
      </Grid>
    </Container>
  )
}

const Container = styled('div')`
  align-self: flex-start;
  background-color: ${eggshell};
  border: 1px solid ${greySet[15].hex};
  border-radius: var(--border-radius);
  padding: var(--margin-5);
  color: ${tealSet[80].hex};
  max-width: 674px;

  @media (max-width: 959px) {
    padding: var(--margin-3);
  }
`

const Heading = styled('h2')`
  @media (${BelowTabletLandscape}) {
    font-size: ${fontSize.h4};
  }
`

const Paragraph = styled('p')`
  @media (${BelowTabletLandscape}) {
    font-size: ${fontSize.body};
  }
`

const Illustration = styled('img')`
  max-width: 175px;
  width: 100%;

  @media (max-width: 959px) {
    max-width: 75px;
  }
`
Illustration.defaultProps = { src: laptopGuySrc }

const Arrow = styled('img')`
  max-width: 130px;
  width: 100%;
`
Arrow.defaultProps = { src: arrowSrc }
