import { styled } from '@mui/material'

export const CenteredHeader = styled('div')`
  width: 100%;
  .centered-header-left,
  .centered-header-center,
  .centered-header-right {
    display: flex;
    justify-self: center;
    flex: 1;
  }

  .centered-header-left {
    margin-right: auto;
    white-space: nowrap;
  }

  .centered-header-right {
    margin-left: auto;
    justify-content: flex-end;
  }

  .centered-header-center {
    justify-content: center;
  }
`
