import { $isListItemNode, $isListNode, ListItemNode } from '@lexical/list'
import { LexicalEditor } from 'lexical'
import {
  $createParagraphNode,
  $getSelection,
  $isRangeSelection,
  $isTextNode,
  LexicalCommand,
  LexicalNode,
} from 'lexical'

export function convertListItemToParagraph(listItemNode: ListItemNode) {
  const paragraphNode = $createParagraphNode()

  listItemNode
    .getChildren()
    .filter($isTextNode)
    .forEach(textNode => {
      paragraphNode.append(textNode)
    })

  return paragraphNode
}

// TODO: consider list functionality beta for now
export function toggleList(editor: LexicalEditor, command: LexicalCommand<void>) {
  editor.update(() => {
    const selection = $getSelection()
    if (!$isRangeSelection(selection)) {
      return false
    }

    const topLevelElement = selection.anchor.getNode().getTopLevelElement()

    if ($isListNode(topLevelElement)) {
      // if changing list type TODO

      const nodes = selection.getNodes()
      const itemListNodes = nodes.filter($isListItemNode)

      // if multiple lines are selected
      if (itemListNodes.length > 0) {
        let previousNewNode: LexicalNode
        itemListNodes.forEach(listItemNode => {
          const paragraphNode = convertListItemToParagraph(listItemNode)

          if (!paragraphNode.isEmpty()) {
            const insertLocationNode = previousNewNode ?? topLevelElement
            insertLocationNode.insertAfter(paragraphNode)
            previousNewNode = paragraphNode
          }

          listItemNode.remove()
        })
      } else if (nodes.length > 0) {
        // single line is selected
        const listItemNode = nodes[0].getParent()
        if ($isListItemNode(listItemNode)) {
          const paragraphNode = convertListItemToParagraph(listItemNode)

          topLevelElement.insertAfter(paragraphNode)
          listItemNode.remove()
        }
      }
    } else {
      editor.dispatchCommand(command, undefined)
    }
  })
}
