import { HTMLAttributes, useEffect } from 'react'

import { CalendarSyncStatus, Provider, useBaseProviderQuery } from '@nuna/api'
import { useVisibilityChanged } from '@nuna/common'

import { CalendarSyncActive } from './CalendarSyncActive'
import { CalendarSyncExpired } from './CalendarSyncExpired'
import { CalendarSyncSettingUp } from './CalendarSyncSettingUp'
import { CalendarSyncSetup } from './CalendarSyncSetup'
import { CalendarSyncSupport } from './CalendarSyncSupport'

const POLLING_INTERVAL = 1000 * 10

interface Props extends HTMLAttributes<HTMLDivElement> {
  providerId?: string | null
}

export function CalendarSync({ providerId }: Props) {
  const visible = useVisibilityChanged()
  const providerIdToLookup = providerId || ''

  const { error, data, startPolling, stopPolling, loading, refetch } = useBaseProviderQuery({
    variables: { id: providerIdToLookup },
    skip: !providerIdToLookup,
    fetchPolicy: 'cache-and-network',
  })

  useEffect(() => {
    if (visible) {
      startPolling(POLLING_INTERVAL)
    } else {
      stopPolling()
    }
  }, [visible, startPolling, stopPolling])

  const onSubmit = () => {
    refetch()
  }

  const onRefresh = () => {
    refetch()
  }

  if (error || !data?.provider) {
    return null
  }

  const { provider } = data
  const status = provider?.calendarAccountSyncStatus

  switch (status) {
    case CalendarSyncStatus.Running:
    case CalendarSyncStatus.Connected:
      return (
        <CalendarSyncActive
          status={status}
          provider={provider as Provider}
          onSave={onSubmit}
          loading={loading}
          onRefresh={onRefresh}
        />
      )

    case CalendarSyncStatus.SettingUp:
      return <CalendarSyncSettingUp status={status} provider={provider as Provider} onRefresh={onRefresh} />

    case CalendarSyncStatus.SyncError:
    case CalendarSyncStatus.Invalid:
    case CalendarSyncStatus.Stopped:
      return <CalendarSyncSupport status={status} provider={provider as Provider} onRefresh={onRefresh} />
    case CalendarSyncStatus.Expired:
      return <CalendarSyncExpired status={status} provider={provider as Provider} />
    default:
      return <CalendarSyncSetup provider={provider as Provider} />
  }
}
