import { styled } from '@mui/material'
import { useMediaQuery } from 'react-responsive'

import { BelowTablet, IconButton, IconClose, makeTypographyComponent } from '@nuna/tunic'

import { useVideoCallContext } from '../../../context/VideoCallContext'
import { SidebarState } from '../../../context/hooks/useSidebarContext'
import { isProvider } from '../../../util/videoUsers'
import { AutonotesSetting } from '../../AutonotesSetting'
import { BackgroundOptions } from '../../BackgroundOptions'
import { DeviceSettings } from '../../DeviceSettings'
import { NoiseCancelation } from '../../NoiseCancelation'
import { VideoSettingsPreview } from '../../VideoSettingsPreview'

export function SidebarVideoSettings() {
  const isMobile = useMediaQuery({ query: `(${BelowTablet})` })
  const { toggleSidebar, localUser, expectedUsers } = useVideoCallContext()

  return (
    <Container>
      <div className="v-align mb-4">
        <h3 className="h5 m-0 text-white">Settings</h3>
        <IconButton
          tooltip="Close settings"
          variant="dark"
          className="ml-auto text-white"
          onClick={() => toggleSidebar(SidebarState.Closed)}
        >
          <IconClose size={20} />
        </IconButton>
      </div>

      {isMobile && <VideoSettingsPreview roundedCorners className="mb-2" persist={true} />}

      <section>
        <SectionHeading>Devices</SectionHeading>
        <DeviceSettings />
      </section>

      <section className="mt-5">
        <SectionHeading className="mb-2">Background options</SectionHeading>
        <BackgroundOptions />
      </section>

      <section className="mt-5">
        <SectionHeading className="mb-2">Microphone options</SectionHeading>
        <NoiseCancelation />
      </section>

      {!localUser.isProvider && expectedUsers.find(isProvider)?.initialAutonotesEnabled && (
        <section className="mt-auto pt-4">
          <AutonotesSetting />
        </section>
      )}
    </Container>
  )
}

const Container = styled('div')`
  display: flex;
  flex-direction: column;
  height: 100%;
  padding: 1rem;
  overflow: auto;
`

const SectionHeading = makeTypographyComponent('body text-medium large', 'h4')
