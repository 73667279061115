import { styled } from '@mui/material'
import { useEffect, useState } from 'react'

import {
  FullProviderFragment,
  ProviderDataField,
  useProviderDataSummaryQuery,
  useSaveProviderIntakeMutation,
} from '@nuna/api'
import { GhostButton, Stack, TextButton, borderGrey } from '@nuna/tunic'

import { getProviderDataItem } from '../../../utils/provider-data-summary-utils'
import { VideoBioEditDisplay, VideoBioFaqDialog } from '../../VideoBio'
import { VideoBioVideoAsk } from '../../VideoBio/VideoBioVideoAsk'
import { VisibleToClientsLabel } from './VisibleToClientsLabel'

export type VideoBioProps = Pick<FullProviderFragment, 'videoAskResponses' | 'id'>

export function VideoBio({ videoAskResponses = [], id }: VideoBioProps) {
  const [recordDialogOpen, setRecordDialogOpen] = useState(false)
  const [faqDialogOpen, setFaqDialogOpen] = useState(false)
  const [videoRecorded, setVideoRecorded] = useState(false)
  const [saveIntakeMutation] = useSaveProviderIntakeMutation()
  const { data: providerDataSummaryData } = useProviderDataSummaryQuery({ variables: { id } })

  useEffect(() => {
    setVideoRecorded(
      !!getProviderDataItem(ProviderDataField.VideoBioSubmitted, providerDataSummaryData?.providerDataSummary ?? [])
        .hasValue,
    )
  }, [providerDataSummaryData])

  const hasVideos = videoAskResponses.length > 0

  const handleVideoRecorded = async () => {
    setVideoRecorded(true)
    await saveIntakeMutation({ variables: { triggerVideoSubmission: true, id } })
    setRecordDialogOpen(false)
  }

  const hasVideo = !!videoAskResponses[0]

  return (
    <Container>
      <h2 className="h5 mb-4">
        Video Bio <VisibleToClientsLabel className="ml-1" />
      </h2>
      <Stack
        direction={{ xs: 'column', sm: 'row' }}
        spacing={2}
        sx={{ textAlign: 'center', alignItems: { xs: 'flex-start', sm: 'center' }, justifyContent: 'space-between' }}
      >
        <VideoBioEditDisplay video={videoAskResponses[0]} videoRecorded={videoRecorded} />

        <GhostButton sx={{ whiteSpace: 'nowrap' }} onClick={() => setRecordDialogOpen(true)}>
          {hasVideos ? 'Record Again' : 'Record Video Bio'}
        </GhostButton>
      </Stack>
      <div className="text-secondary mt-2">
        {hasVideo
          ? `Interested in improving the quality of your bio?`
          : `Don't know what to share, or how best to record?`}
        <TextButton type="button" variant="secondary" className="ml-1" onClick={() => setFaqDialogOpen(true)}>
          View Recording Tips
        </TextButton>
      </div>
      <VideoBioVideoAsk
        providerId={id}
        isOpen={recordDialogOpen}
        onClose={() => setRecordDialogOpen(false)}
        onVideoRecorded={handleVideoRecorded}
      />
      <VideoBioFaqDialog isOpen={faqDialogOpen} onClose={() => setFaqDialogOpen(false)} />
    </Container>
  )
}

const Container = styled('div')`
  border-top: 1px solid ${borderGrey};
  padding-bottom: var(--margin-4);
  padding-top: var(--margin-4);
`
