import { styled } from '@mui/material'
import { isNumber } from 'lodash'
import { useEffect } from 'react'
import { NavLink } from 'react-router-dom'

import { useCurrentAppointmentsQuery } from '@nuna/api'
import { useAuthDataContext } from '@nuna/auth'
import { useVisibilityChanged } from '@nuna/common'
import { routeService, specialtiesService } from '@nuna/core'
import {
  Avatar,
  IconDivider,
  IconTherapyType,
  IconVideoDeprecated,
  Tooltip,
  borderGrey,
  fontSize,
  greySet,
  tealSet,
} from '@nuna/tunic'

import { LiveSessionPhonePrompt } from './LiveSessionPhonePrompt'

// poll once a minute
const POLLING_INTERVAL = 1000 * 60

export function LiveSessionNotification() {
  const { login } = useAuthDataContext()
  const visible = useVisibilityChanged()
  const { data, stopPolling, startPolling } = useCurrentAppointmentsQuery({
    pollInterval: POLLING_INTERVAL,
  })

  useEffect(() => {
    return stopPolling
  }, [stopPolling])

  useEffect(() => {
    if (visible && login) {
      startPolling(POLLING_INTERVAL)
    } else {
      stopPolling()
    }
  }, [visible, login, startPolling, stopPolling])

  if (!data?.currentAppointments || (data.currentAppointments && data.currentAppointments.length === 0)) {
    return null
  }

  // Always display the upcoming appointment, which is going to be the second item of the array if there are 2
  // appointment,s or the first item if there is only one
  const apptStartingSoon = data.currentAppointments[data.currentAppointments.length === 2 ? 1 : 0]

  if (!apptStartingSoon || apptStartingSoon?.session?.id) {
    return null
  }

  const {
    patient: { avatarUrl, firstName, lastName, mobilePhone },
    minutesFromNow,
  } = apptStartingSoon

  const patientName = () => {
    return `${firstName} ${lastName[0]}`
  }

  const startsIn = () => {
    if (isNumber(minutesFromNow)) {
      if (minutesFromNow > 0) {
        return `Starts in ${minutesFromNow} min`
      } else if (minutesFromNow === 0) {
        return 'Starts now'
      } else {
        return `${Math.abs(minutesFromNow)} min ago`
      }
    }
    return null
  }

  return (
    <Container>
      <NativeVideoLink to={routeService.videoSession(apptStartingSoon.id)}>
        <NotificationRow className="mb-1">
          <Avatar className="fs-exclude" url={avatarUrl || undefined} size="mini" />
          <PatientName className="ml-1">{patientName()}</PatientName>
          {apptStartingSoon?.therapyTypeSpecialty &&
            specialtiesService.isTherapyType(apptStartingSoon.therapyTypeSpecialty.name) && (
              <Tooltip content={apptStartingSoon.therapyTypeSpecialty.name}>
                <SpecialtyIconContainer>
                  <IconTherapyType size={16} therapyType={apptStartingSoon.therapyTypeSpecialty.name} />
                </SpecialtyIconContainer>
              </Tooltip>
            )}
        </NotificationRow>
        <NotificationRow>
          <IconVideoDeprecated color={tealSet[30].hex}></IconVideoDeprecated>
          <StartsIn className="ml-1">{startsIn()}</StartsIn>
          <Divider color={tealSet[30].hex}></Divider>
        </NotificationRow>
        <LiveSessionPhonePrompt mobilePhone={mobilePhone} minutesFromNow={minutesFromNow} />
      </NativeVideoLink>
    </Container>
  )
}

const SpecialtyIconContainer = styled('div')`
  margin-left: auto;
  margin-right: 0.5rem;
  color: ${tealSet[30].hex};
`

const Container = styled('div')`
  border-bottom: 1px solid ${borderGrey};
  margin-bottom: var(--margin-2);
  padding-bottom: var(--margin-2);
`

const NativeVideoLink = styled(NavLink)`
  background-color: ${tealSet[70].hex};
  border-radius: var(--border-radius-sm);
  color: #fff;
  display: block;
  font-weight: 500;
  padding: 0.5rem;

  &:hover {
    background-color: ${tealSet[70].hover};
  }

  &:focus {
    outline: none;
    box-shadow: 0 0 0 6px ${tealSet[15].hex};
  }
`

const NotificationRow = styled('div')`
  display: flex;
  align-items: center;
  width: 100%;
`

const PatientName = styled('span')`
  color: #fff;
`
const StartsIn = styled('span')`
  color: ${greySet[15].hex};
  font-size: ${fontSize.caption};
  white-space: nowrap;
`

const Divider = styled(IconDivider)`
  margin-left: auto;
`
