import HelloSign from 'hellosign-embedded'

let helloSign: HelloSign

export const openEmbeddedSignUrl = ({
  clientId,
  testMode,
  url,
  listener,
}: {
  clientId: string
  testMode: boolean
  url: string
  listener?: () => void
}) => {
  if (!helloSign) {
    helloSign = new HelloSign({
      clientId,
    })
  }

  helloSign.open(url, {
    testMode,
  })

  if (listener) {
    helloSign.on('finish', listener)
  }
}
