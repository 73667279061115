/* eslint-disable react/jsx-no-useless-fragment */
import { loadStripe } from '@stripe/stripe-js'
import moment from 'moment'
import { useEffect, useState } from 'react'
import { useSearchParams } from 'react-router-dom'

import {
  OrderBy,
  PatientTransactionFragment,
  PatientTransactionStatus,
  PatientTransactionType,
  TraversablePaginationSortInput,
  usePatientTransactionHistoryLazyQuery,
} from '@nuna/api'
import { useIsAdmin } from '@nuna/auth'
import { AuthorizedAvatar } from '@nuna/common'
import { centsToFormattedDollars, transactionService } from '@nuna/core'
import { DataTable, DataTableColumn, usePagination } from '@nuna/data-table'
import { useEnvironmentContext } from '@nuna/environment'
import { useFeatureFlags } from '@nuna/feature-flag'
import { supportService } from '@nuna/telemetry'
import { BelowTablet, GhostButton, IconDownload, StatusLabel, greySet, toast } from '@nuna/tunic'

import { getPaymentReceiptDowndloadLink } from '../../utils/paymentReceipt'
import { ChargeClaimDrawer } from './components/ChargeClaimDrawer'
import { RecordPaymentDrawer } from './components/RecordPaymentDrawer'
import { RetryChargeDrawer } from './components/RetryChargeDrawer'
import { WaivePaymentDialog } from './components/WaivePaymentDialog'

interface Props {
  appointmentId?: string
  patientId: string
}

const INITIAL_SORT: TraversablePaginationSortInput[] = [{ key: 'dateOfService', direction: OrderBy.Desc }]

export function TransactionHistory({ appointmentId, patientId }: Props) {
  const [queryTransactionHistory, { data, loading, refetch }] = usePatientTransactionHistoryLazyQuery({
    fetchPolicy: 'cache-and-network',
  })

  const [, setSearchParams] = useSearchParams()
  const [waiveSessionPaymentId, setWaiveSessionPaymentId] = useState<string | undefined>()
  const [isRefund, setIsRefund] = useState<boolean>(false)
  const [isPrePayment, setIsPrePayment] = useState<boolean>(false)
  const isAdmin = useIsAdmin()
  const { adminFees } = useFeatureFlags()
  const { CI, STRIPE_PUBLISHABLE_KEY = '' } = useEnvironmentContext()
  const [verifyingCard, setVerifyingCard] = useState(false)

  const { queryOptions, getPaginatorProps, initialTableState } = usePagination(
    {
      pagination: data?.patientTransactionHistory.pagination,
      loading: loading,
      initialSort: INITIAL_SORT,
    },
    { limit: 10 },
  )

  useEffect(() => {
    const { paginationInput } = queryOptions
    queryTransactionHistory({
      variables: {
        filters: { appointmentId, patientId },
        pagination: paginationInput,
      },
    })
  }, [queryTransactionHistory, queryOptions, appointmentId, patientId])

  const openRetryDrawer = ({
    sessionPaymentId,
    appointmentId,
    chargeAmount,
    chargeType,
  }: {
    sessionPaymentId: string
    appointmentId: string
    chargeAmount: number
    chargeType: PatientTransactionType
  }) => {
    setSearchParams(oldSearchParams => {
      oldSearchParams.set('retryCharge', sessionPaymentId)
      oldSearchParams.set('appointmentId', appointmentId)
      oldSearchParams.set('chargeAmount', chargeAmount.toString())
      oldSearchParams.set('chargeType', chargeType)
      return oldSearchParams
    })
  }

  const openChargeClaimDrawer = ({
    claimId,
    appointmentId,
    chargeAmount,
    chargeType,
  }: {
    claimId: string
    appointmentId: string
    chargeAmount: number
    chargeType: PatientTransactionType
  }) => {
    setSearchParams(oldSearchParams => {
      oldSearchParams.set('claimId', claimId)
      oldSearchParams.set('appointmentId', appointmentId)
      oldSearchParams.set('chargeAmount', chargeAmount.toString())
      oldSearchParams.set('chargeType', chargeType)
      return oldSearchParams
    })
  }

  const openRecordPaymentDrawer = ({
    appointmentId,
    chargeAmount,
  }: {
    appointmentId: string
    chargeAmount: number
  }) => {
    setSearchParams(oldSearchParams => {
      oldSearchParams.set('recordPayment', 'true')
      oldSearchParams.set('appointmentId', appointmentId)
      oldSearchParams.set('chargeAmount', chargeAmount.toString())
      return oldSearchParams
    })
  }

  const handleShowWaiveSessionPaymentDialog = (sessionPaymentId: string, isRefund: boolean, isPrePayment: boolean) => {
    setIsRefund(isRefund)
    setIsPrePayment(isPrePayment)
    setWaiveSessionPaymentId(sessionPaymentId)
  }

  const handleWaivePaymentDialogClose = () => {
    setWaiveSessionPaymentId(undefined)
  }

  const handleVerifyCard = async (paymentIntentClientSecret: string) => {
    if (CI) return
    try {
      const stripe = await loadStripe(STRIPE_PUBLISHABLE_KEY)
      if (stripe) {
        setVerifyingCard(true)
        const response = await stripe.confirmCardPayment(paymentIntentClientSecret)

        if (response instanceof Error || response.error) {
          throw response
        } else {
          await refetch()
        }
      } else {
        throw new Error('Failed to load stripe')
      }
      setVerifyingCard(false)
    } catch (e) {
      console.error(e)
      toast.urgent('We failed to collect this payment. Please try again or contact support.')
      setVerifyingCard(false)
    }
  }

  const columns: DataTableColumn<PatientTransactionFragment>[] = [
    {
      Header: 'Service Date',
      id: 'dateOfService',
      accessor: 'dateOfService',
      Cell: ({ value }) => <>{value ? moment(value).format('MMM D, YYYY') : 'N/A'}</>,
      paginatedSortable: false,
    },
    {
      Header: 'Provider',
      id: 'providerName',
      accessor: 'providerName',
      Cell: ({ value, row }) => {
        return (
          <span className="v-align">
            <AuthorizedAvatar className="mr-xs" size="mini" url={row.original.providerAvatarUrl} /> {value ?? 'Unknown'}
          </span>
        )
      },
      MobileCell: ({ value }) => {
        return <>{value}</>
      },
      paginatedSortable: false,
    },
    {
      Header: 'Type',
      id: 'type',
      accessor: 'type',
      Cell: ({ value }) => <>{transactionService.getTransactionType(value)}</>,
      paginatedSortable: false,
    },
    {
      Header: 'Transacted',
      id: 'dateOfCharge',
      accessor: 'dateOfCharge',
      Cell: ({ value }) => <>{moment(value).format('MMM D, YYYY')}</>,
      paginatedSortable: false,
    },
    {
      Header: 'Status',
      id: 'status',
      accessor: 'status',
      Cell: ({ value }) => (
        <StatusLabel intent={transactionService.getIntentFromTransactionStatus(value)}>{value}</StatusLabel>
      ),
      paginatedSortable: false,
      groupInMobile: true,
    },
    {
      Header: 'Cost',
      id: 'chargeAmount',
      accessor: 'chargeAmount',
      Cell: ({ value, row }) => (
        <>
          {value === 0 &&
          [PatientTransactionStatus.Pending, PatientTransactionStatus.ClaimSubmitted].includes(row.original.status)
            ? 'TBD'
            : centsToFormattedDollars(value, { maximumFractionDigits: 2 })}
        </>
      ),
      showInMobileHeader: true,
      paginatedSortable: false,
      style: { textAlign: 'right' },
    },
    {
      Header: 'Billed to',
      id: 'billedTo',
      accessor: 'billedTo',
      Cell: ({ value }) => <>{value ?? '-'}</>,
      paginatedSortable: false,
    },
    {
      Header: '',
      id: 'actions',
      accessor: 'status',
      Cell: ({ row }) => {
        if (row.original.status === PatientTransactionStatus.Failed && row.original.paymentIntentClientSecret) {
          return (
            <GhostButton
              onClick={() => handleVerifyCard(row.original.paymentIntentClientSecret ?? '')}
              isLoading={verifyingCard}
            >
              Verify Card
            </GhostButton>
          )
        } else if (row.original.status === PatientTransactionStatus.Failed && row.original.sessionPaymentId) {
          return (
            <>
              <GhostButton
                onClick={() => {
                  const { sessionPaymentId, appointmentId, chargeAmount, type } = row.original

                  if (!sessionPaymentId || !appointmentId || !chargeAmount || !type) {
                    toast.urgent(
                      `An unexpected error occurred. Please contact ${supportService.supportEmails.clientSupport}`,
                    )
                    console.error('Did not find expected data for retrying charge', row.original)
                    return
                  }

                  openRetryDrawer({
                    sessionPaymentId,
                    appointmentId,
                    chargeAmount,
                    chargeType: type,
                  })
                }}
              >
                Retry
              </GhostButton>
              {isAdmin && adminFees && (
                <GhostButton
                  className="ml-1"
                  onClick={() => handleShowWaiveSessionPaymentDialog(row.original.sessionPaymentId ?? '', false, false)}
                >
                  Waive
                </GhostButton>
              )}
            </>
          )
        } else if (
          row.original.status === PatientTransactionStatus.Paid &&
          row.original.sessionPaymentId &&
          isAdmin &&
          adminFees
        ) {
          return (
            <GhostButton
              onClick={() => handleShowWaiveSessionPaymentDialog(row.original.sessionPaymentId ?? '', true, false)}
            >
              Refund
            </GhostButton>
          )
        } else if (row.original.status === PatientTransactionStatus.PaymentDue) {
          return (
            <>
              <GhostButton
                onClick={() => {
                  const { claimId, appointmentId, chargeAmount, type } = row.original

                  if (!claimId || !appointmentId || !chargeAmount || !type) {
                    toast.urgent(
                      `An unexpected error occurred. Please contact ${supportService.supportEmails.clientSupport}`,
                    )
                    console.error('Did not find expected data for retrying charge', row.original)
                    return
                  }

                  openChargeClaimDrawer({
                    claimId,
                    appointmentId,
                    chargeAmount,
                    chargeType: type,
                  })
                }}
              >
                Pay
              </GhostButton>
              {isAdmin && adminFees && (
                <GhostButton
                  className="ml-1"
                  onClick={() => {
                    const { appointmentId, chargeAmount } = row.original

                    if (!appointmentId || !chargeAmount) {
                      toast.urgent(
                        `An unexpected error occurred. Please contact ${supportService.supportEmails.clientSupport}`,
                      )
                      console.error('Did not find expected data for recording payment', row.original)
                      return
                    }

                    openRecordPaymentDrawer({ appointmentId, chargeAmount })
                  }}
                >
                  Record Payment
                </GhostButton>
              )}
            </>
          )
        } else if (row.original.status === PatientTransactionStatus.Pending && isAdmin && adminFees) {
          return (
            <GhostButton
              className="ml-1"
              onClick={() => handleShowWaiveSessionPaymentDialog(row.original.sessionPaymentId ?? '', false, true)}
            >
              Waive
            </GhostButton>
          )
        } else if (
          row.original.status === PatientTransactionStatus.Paid &&
          row.original.sessionPaymentId &&
          row.original.appointmentId
        ) {
          return (
            <a
              href={getPaymentReceiptDowndloadLink({
                appointmentId: row.original.appointmentId,
                patientId: patientId,
              })}
              className="v-align"
            >
              <IconDownload size={18} className="m-auto" />
            </a>
          )
        }

        return null
      },
      paginatedSortable: false,
    },
  ]

  return (
    <>
      <DataTable
        mobileBreakpoint={BelowTablet}
        loading={loading}
        columns={columns}
        rowData={data?.patientTransactionHistory.items}
        initialState={initialTableState}
        paginated
        paginatorProps={getPaginatorProps()}
        evenRowBGColor={greySet[5].hex}
      />

      <RetryChargeDrawer patientId={patientId} />
      <ChargeClaimDrawer patientId={patientId} />
      <RecordPaymentDrawer patientId={patientId} />
      <WaivePaymentDialog
        waiveSessionPaymentId={waiveSessionPaymentId}
        isRefund={isRefund}
        isPrePayment={isPrePayment}
        onClose={handleWaivePaymentDialogClose}
      />
    </>
  )
}
