import { IconProps } from './IconProps'

export function IconCommentFilled({ size = 24, color = 'currentColor', ...props }: IconProps) {
  return (
    <svg width={size} height={size} viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8 6H40C42.2091 6 44 7.79086 44 10V34.5439C44 36.753 42.2091 38.5439 40 38.5439C40 38.5439 28.2407 38.5439 22.361 38.5439C21.7205 38.5439 21.4341 38.7335 21.032 39.1021C20.0706 39.9838 17.9911 41.8894 14.9459 44.6736C14.4962 45.1208 13.6579 45.1628 13.2685 44.7711C13.0822 44.5838 12.998 44.2287 12.998 43.9644C12.998 43.9644 12.998 40.9047 12.998 39.2569C12.998 38.7726 12.8043 38.5439 12.3273 38.5439C10.8363 38.5439 8 38.5439 8 38.5439C5.79086 38.5439 4 36.753 4 34.5439L4 10C4 7.79086 5.79086 6 8 6Z"
        fill={color}
      />
    </svg>
  )
}
