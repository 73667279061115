import { isNil } from 'lodash'
import mixpanel from 'mixpanel-browser'
import moment from 'moment'
import { useEffect, useMemo, useState } from 'react'
import { Route, Routes, matchPath, resolvePath, useLocation, useNavigate, useSearchParams } from 'react-router-dom'

import { useProviderSignupBasicMutation, useProviderSignupPrevalidationLazyQuery } from '@nuna/api'
import { useAuthDataContext } from '@nuna/auth'
import { useTitle } from '@nuna/common'
import { errorService } from '@nuna/core'
import { toast } from '@nuna/tunic'

import { SignupLayout } from '../../layouts/SignupLayout'
import { INTAKE_STEPS, getPath } from '../SignupIntake/signup-intake-steps'
import { ExploreMoreWelcome } from './ExploreMoreWelcome'
import { SignupAccountOptions } from './SignupAccountOptions'
import { SignupLicenses } from './SignupLicenses'
import { SignupLocation } from './SignupLocation'
import { SignupNameEmail } from './SignupNameEmail'
import { SignupPassword } from './SignupPassword'
import { SignupReasons } from './SignupReasons'
import { SignupVerifyEmail } from './SignupVerifyEmail'
import { faqItems } from './faqItems'
import { SignupFormValues, SignupStep } from './shared'

const PRE_VALIDATE_EMAIL_ERROR = 'Oops, we encountered an error. If you already have an account, please log in instead.'

export function Signup() {
  useTitle('Sign Up')
  const location = useLocation()
  const navigate = useNavigate()
  const { onLogin } = useAuthDataContext()
  const [formValues, setFormValues] = useState(buildInitialValues())
  const [preValidateError, setPreValidateError] = useState<string | undefined>(undefined)
  const [preValidateQuery, { data: preValidateData }] = useProviderSignupPrevalidationLazyQuery()
  const [createAccountMutation, { loading: createAccountLoading }] = useProviderSignupBasicMutation()
  const [urlParams] = useSearchParams()
  const searchParamsForNextRoute = useMemo(() => (urlParams ? `?${urlParams}` : ''), [urlParams])

  const createAccount = async (newFormValues: SignupFormValues) => {
    const { firstName, lastName, email, password } = newFormValues
    const hubspotId = urlParams.get('contactId')
    try {
      const response = await createAccountMutation({
        variables: {
          firstName,
          lastName,
          email,
          password,
          hubspotId: hubspotId ?? undefined,
          timezone: moment.tz.guess(),
          termsOfServiceChecked: true,
        },
      })

      const loginData = response.data?.providerSignupBasic

      if (loginData && onLogin) {
        onLogin({ login: loginData.login })
        mixpanel.track('provider-signup', { userId: loginData.login.id })
        return true
      } else {
        return false
      }
    } catch (e) {
      toast.urgent(
        errorService.transformGraphQlError(e, 'Error completing signup. Please try again or contact support.'),
      )
    }
  }

  useEffect(() => {
    if (preValidateData?.providerSignupPrevalidation.valid === false) {
      setPreValidateError(PRE_VALIDATE_EMAIL_ERROR)
    } else {
      setPreValidateError(undefined)
    }
  }, [preValidateData])

  const preValidate = async (email: string) => {
    const response = await preValidateQuery({ variables: { email: email } })

    if (isNil(response.data)) {
      toast.urgent('An error occur. Try again. If the problem persists, contact support.')
    }

    return !!response.data?.providerSignupPrevalidation.valid
  }

  const dismissPreValidateError = () => {
    setPreValidateError(undefined)
  }

  const handleStepSubmit = async (newFormValues: SignupFormValues, step: SignupStep, isExploreFirst?: boolean) => {
    setFormValues(newFormValues)
    if (step === 'email') {
      const isPreValidated = await preValidate(newFormValues.email)
      if (!isPreValidated) {
        return
      }
    } else if (step === 'password') {
      const accountCreated = await createAccount(newFormValues)
      if (!accountCreated) {
        return
      }
    }

    navigate({
      pathname: getNextRoute(step, isExploreFirst),
      search: isExploreFirst && step === 'location' ? '?exploreFirstWelcome=true' : searchParamsForNextRoute,
    })
  }

  return (
    <SignupLayout
      maxWidth={
        matchPath('/signup/licenses', location.pathname) ||
        matchPath('/signup/location', location.pathname) ||
        matchPath('/signup', location.pathname)
          ? 540
          : undefined
      }
      showSignInLink
      faqItems={faqItems}
      emptyLayout={!!matchPath('/signup/welcome-explore-first', location.pathname)}
    >
      <Routes>
        <Route
          index
          path="/"
          element={
            <SignupAccountOptions step="account-options" onStepSubmit={handleStepSubmit} formValues={formValues} />
          }
        />
        <Route
          path="email"
          element={
            <SignupNameEmail
              preValidateError={preValidateError}
              dismissPreValidateError={dismissPreValidateError}
              formValues={formValues}
              step="email"
              onStepSubmit={handleStepSubmit}
            />
          }
        ></Route>
        <Route
          path="verify-email"
          element={
            <SignupVerifyEmail
              dismissPreValidateError={dismissPreValidateError}
              preValidateError={preValidateError}
              formValues={formValues}
              step="verifyEmail"
              onStepSubmit={handleStepSubmit}
              preValidate={preValidate}
            />
          }
        />
        <Route
          path="password"
          element={
            <SignupPassword
              formValues={formValues}
              step="password"
              onStepSubmit={handleStepSubmit}
              createAccountLoading={createAccountLoading}
            />
          }
        />
        <Route
          path="why-tava"
          element={<SignupReasons formValues={formValues} step="why-tava" onStepSubmit={handleStepSubmit} />}
        />
        <Route
          path="licenses"
          element={<SignupLicenses formValues={formValues} step="licenses" onStepSubmit={handleStepSubmit} />}
        />
        <Route
          path="location"
          element={<SignupLocation formValues={formValues} step="location" onStepSubmit={handleStepSubmit} />}
        />
        <Route path="welcome-explore-first" element={<ExploreMoreWelcome />} />
      </Routes>
    </SignupLayout>
  )
}

function buildInitialValues(): SignupFormValues {
  return {
    firstName: '',
    lastName: '',
    email: '',
    password: '',
  }
}
// isExploreFirst will be set in a later ticket for building the screen in where the explore first option can be chosen
function getNextRoute(step: SignupStep, isExploreFirst = false) {
  let route = ''
  switch (step) {
    case 'account-options':
      route = 'email'
      break
    case 'email':
      route = 'verify-email'
      break
    case 'verifyEmail':
      route = 'password'
      break
    case 'password':
      route = 'why-tava'
      break
    case 'why-tava':
      route = 'licenses'
      break
    case 'licenses':
      route = 'location'
      break
    case 'location':
      route = isExploreFirst ? resolvePath('/schedule').pathname : getPath(INTAKE_STEPS[0]).pathname
      break
  }

  return route
}
