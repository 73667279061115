import { styled } from '@mui/material'
import moment from 'moment'

import { Card, sansSerifFontStack, serifFontStack, tealSet, yarnCircle } from '@nuna/tunic'

export function MentalHealthAwarenessMonthContent({ helpfulItemContent }: { helpfulItemContent: string }) {
  return (
    <Outer>
      <Card style={{ overflow: 'hidden', border: `1px solid ${tealSet[15].hex}` }} depth={1.5}>
        <CardHeader>Mental Health Awareness Month</CardHeader>
        <CardContent>
          <DateTextContainer>
            <DateText>
              <MonthText className="m-0 sans-serif">{moment().format('MMM')}</MonthText>
              <DayText className="m-0">{moment().format('D')}</DayText>
            </DateText>
          </DateTextContainer>
          <div style={{ marginLeft: '24px' }}>
            <ChallengeHeading>Today's Challenge:</ChallengeHeading>
            <ChallengeText>{helpfulItemContent}</ChallengeText>
          </div>
        </CardContent>
      </Card>
    </Outer>
  )
}

const Outer = styled('div')`
  max-width: 560px;
  min-width: 320px;
`

const CardHeader = styled('div')`
  background-color: ${tealSet[15].hex};
  text-align: center;
  color: ${tealSet[90].hex};
  font-weight: 500;
  padding: 0.6rem;
`

const CardContent = styled('div')`
  display: flex;
  justify-content: space-around;
  align-items: center;
  padding: 1.8rem;
  height: calc(100% - 40px);
`

const ChallengeHeading = styled('p')`
  border-radius: 4px;
  display: inline-block;
  font-family: ${sansSerifFontStack};
  font-size: 1rem;
  font-weight: 700;
  line-height: 1;
  text-transform: uppercase;
  color: ${tealSet[90].hex};
`

const ChallengeText = styled('p')`
  font-weight: medium;
  font-size: 1.4rem;
  color: ${tealSet[90].hex};
`

const DateTextContainer = styled('div')`
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 120px;
  min-height: 120px;
  width: 120px;
  height: 120px;
  background-image: url(${yarnCircle});
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  text-align: center;
`

const DateText = styled('div')`
  color: white;
  font-family: ${serifFontStack};
`

const MonthText = styled('p')`
  font-size: 1.8rem;
  text-transform: uppercase;
  font-weight: 500;
  line-height: 2rem;
`

const DayText = styled('p')`
  font-size: 3rem;
  font-weight: 500;
  line-height: 3rem;
`
