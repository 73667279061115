import { HTMLAttributes } from 'react'

import { FAQ, FAQItem } from '@nuna/common'
import { Dialog, DialogProps, Grid, SignupLayout, makeTypographyComponent } from '@nuna/tunic'

import { IntakeVideoThumbnail } from './IntakeVideoThumbnail'

const exampleVideos = [
  {
    gifUrl: 'https://media.videoask.com/transcoded/45d2a8c8-30df-41c2-845e-824e579b0572/thumbnails/preview.gif',
    mediaUrl:
      'https://media.videoask.com/transcoded/45d2a8c8-30df-41c2-845e-824e579b0572/video.mp4?token=eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiJ9.eyJtZWRpYV9pZCI6IjQ1ZDJhOGM4LTMwZGYtNDFjMi04NDVlLTgyNGU1NzliMDU3MiIsImV4cCI6MTY1MTg2ODA5Mn0.FDYoVx1EM6T3kma3UzkWIHLwHOim0hEmmkAs4sFy3PHEKHAyuW8UMokECVANszjYeP9044Ia01fE9mIFtLgp7j9An2oI7EigEorsnjHZVazgga5MbulKOegAcxvaZm9FbbelpgIpfBLRNLSaBzf7aE_AEjpJtxluFA1G17VXFYRr_tJAWJCOvple-rFKeOZEh_n4A3upB2tWDr8QgFke96LWR--CBI3FmDTT-rnvODjWEXPCj-RhsoMKkldmRB5GMUSFfk9Z9Y3eiv0rDv3dSyCa4FNEOMGJ-1s0uOD6nSxq85hzcof1rWUbrMR_Pdtu-CaQSrNEnZ4cgvsPyxxZH8yi-pfAKmxFCcX0DMr6CWIq-sMuV8rBPJcr0raivTlnxfKTse_sIYF3R0mgzXkF4Fk_Sa3POBc4_q3ipIBxv5hpWED2vl3LOfXsea4615DOEaorFzqio8KAITo7vFSwKKn2PWZkZv3rOARsLpl1-NG6A1LZGaON_iejQAv4wGrTGCw-uMs4QxgeS3jvXj57r7FfXVDfnVEkniUqHaHh04bCnWEMIdH7pMQFvIrNzh-63XV6tKZEjpw10AakjFTAMtahjS4Y7l2oVaGWs4wy2AziVjHS0ttIZbw5GCD1JUIIY2lUzmNqN-vnBnfVDaULJ-xDcEDcfjyn5rnmiak6zOc',
  },
  {
    gifUrl: 'https://media.videoask.com/transcoded/2491137e-a326-4de6-a25d-076aff1da1e1/thumbnails/preview.gif',
    mediaUrl:
      'https://media.videoask.com/transcoded/2491137e-a326-4de6-a25d-076aff1da1e1/video.mp4?token=eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiJ9.eyJtZWRpYV9pZCI6IjI0OTExMzdlLWEzMjYtNGRlNi1hMjVkLTA3NmFmZjFkYTFlMSIsImV4cCI6MTYwMDQ2MDA0NX0.p1nX5uCkmurFDPeZTxeCYtg4y7PJNCp6E-8FSJNcf2AYAlRzNgp4hxJb4C5PgiaacSSQNil01AyN0jc47RRHLmV7cvN_KrRVXge04ZQdjhJnoX17yKaHcGJ3NJcnrt_cO5Cw75mBGX0uKF542PyNKmKFiY0b8dgU0VkMg3q8iuZW2uDz3UGDDmfcFweLoCWKkAjog7Fj0HXjLMpF9vD7Km8zm4wjTh2w6MJzvAEfUwFmbMhyollnD00xN4Zcgs2JI_WF5Vh_gPHCY2NwBTnW5TpNiqBAHaLRG-7UtJV-Y2BPYNSYaATWoaTvgJgnCyKn_P3a_xIUvLM1IRhzNlt-26EVHAO1Oa6qncYJmDqc_lJKakgujeCNKBBJLeHHeEZAfdchdGiaJmZLK47cqIasnI2S8hV7IF8UP1Lz1ORTc0fmRc4KI_rm9XKXINZnzIgoGhu-g2c0sDxJ2cVM7H4DekVHlUI0C6ou1NZXdNRFdhsVpkzvIp39SO0MMz5bFjMCGzeiLbpNNa8KifGZBn28IgKCXH9jWetk_AFdFx0ykvYs26EH6VXReUA13ooyM9tG2zBvamun7FG4KnCHA6T2AObhwhBS1lUDtniaSLQG-_mGLzTxNnZEIT47YqClbsve89Frts3bmdjnxTQUijRWa1sBxnrHzhs9U54cTfWlJb8',
  },
  {
    gifUrl: 'https://media.videoask.com/transcoded/392fe6b0-cf2b-422b-bc9f-3608389f296a/thumbnails/preview.gif',
    mediaUrl:
      'https://media.videoask.com/transcoded/392fe6b0-cf2b-422b-bc9f-3608389f296a/video.mp4?token=eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiJ9.eyJtZWRpYV9pZCI6IjM5MmZlNmIwLWNmMmItNDIyYi1iYzlmLTM2MDgzODlmMjk2YSIsImV4cCI6MTY1ODYyNzM3MX0.cQQ48yIR84sxu4kS-U6F8pMfnB9KlA7PxUhEhMaKBbT4Qfp_hEkX5uEX0ajQZ8NSYWKMVPKnRxdl-TKcez2ZpcZcve-CpS2G-bXuWsO1a9HtvnMkz6nFA3xx7yPEFjQ9WWPWxq10cdYmeJhCUstQWnxNuNA-aK2x4UgidKbgkzTxaEu1mR6_EMGUIX3_RuCGvM_JIlR4H1Y9ld11gxUCgcoFZM1ZyXwsjCbBnjcovKspxkYuLlHVmzjNcBUF-tgUu3-ywdnRdad6gU2_5R_vF1TdaRU2gYhf4D5tMM_aGT8H3rMpkev1DeX-cdrdz3OwyDXc8_EKrUcF0A_udsHiaQeC63QvGNOg7qbDSwbqtSpLBb6QZ-MK9u3QH-fTJpnpqtXRUFhdvf36LoDttZDMLESxS0EIs216bKQKnJcGauQOLV90nuWcmfS_EE_LkIQBFj3Rqud-M5kOnDRrwAB0yKmsTH8sQ4inO1cIOBIbn1h50k4p6JkIcA84GzJHzO8-7h_hDkglnIkhDFz-LUejIUXyTPwpfuouoQ3g8lJGFGmtmGHD0DICO3xvEM_X5b-AZFpouMn2t7ZRjHSoKSEWFvo_qE1kXOZO8F8kGNNRxKoWSoyvjfYKJjaK_p-lWSwywnrWUyiQG_XU2cmd_WaDyOBI2YMBbYmv598LxV4LhFs',
  },
]

export function VideoBioFaqs(props: HTMLAttributes<HTMLDivElement>) {
  return (
    <SignupLayout.FAQContainer {...props}>
      {getFaqs().map(faq => (
        <FAQItem question={faq.question} answer={faq.answer} key={faq.question} className="body" fullWidth />
      ))}
    </SignupLayout.FAQContainer>
  )
}

export function VideoBioFaqDialog(props: DialogProps) {
  return (
    <Dialog {...props} fullWidth maxWidth="lg">
      <VideoBioFaqs className="p-2" />
    </Dialog>
  )
}

function getFaqs(): FAQ[] {
  return [
    {
      question: 'What do I share in my bio?',
      answer: (
        <Answer>
          <TipListHeader className="text-bold">Clients want to know:</TipListHeader>
          <TipList>
            <Tip>How do you work?</Tip>
            <Tip>Who do you help?</Tip>
            <Tip>What will be unique about working with you?</Tip>
          </TipList>
          <TipListHeader className="text-bold">Some ideas to form connection:</TipListHeader>
          <TipList>
            <Tip>Share a story about why you became a therapist.</Tip>
            <Tip>Share a few hobbies or interests clients may have in common with you.</Tip>
          </TipList>
        </Answer>
      ),
    },
    {
      question: 'Tips for recording like a pro',
      answer: (
        <Answer>
          <p className="large mb-2 text-secondary">
            A professional looking video makes a big difference in a client chosing you over another provider. Follow
            these simple tips to add polish to your video bio.
          </p>
          <TipList className="text-medium text-secondary">
            <Tip>Find a well-lit, uncluttered space</Tip>
            <Tip>Choose a camera and set your angle</Tip>
            <Tip>Start with a smile</Tip>
            <Tip>Get to the point</Tip>
            <Tip>Be yourself</Tip>
          </TipList>
        </Answer>
      ),
    },
    {
      question: 'Video sample bios',
      answer: (
        <Grid container className="text-secondary" spacing={4}>
          <Grid
            className="large"
            size={{
              sm: 6,
            }}
          >
            Need to jog your right brain loose? Watch a few bios of Tava providers first for inspiration.
          </Grid>
          <Grid
            size={{
              sm: 6,
            }}
          >
            <Grid container spacing={1}>
              {exampleVideos.map((video, idx) => (
                <Grid key={idx}>
                  <IntakeVideoThumbnail
                    key={idx}
                    size={{ height: 110, width: 110 }}
                    video={video}
                    imgStyle={{ borderRadius: 12 }}
                  />
                </Grid>
              ))}
            </Grid>
          </Grid>
        </Grid>
      ),
    },
  ]
}

const Answer = makeTypographyComponent('body', 'div')
const TipListHeader = makeTypographyComponent('text-bold', 'div')
const TipList = makeTypographyComponent('body mt-1 pl-3', 'ul')
const Tip = makeTypographyComponent('pb-1', 'li')
