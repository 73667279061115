import { useEffect, useState } from 'react'

export function useImageRatio(src: string | null | undefined) {
  const [ratio, setRatio] = useState<number>(0)

  useEffect(() => {
    if (src) {
      const image = new Image()
      image.onload = function () {
        const loadedImage = this as HTMLImageElement

        const width = loadedImage.width
        const height = loadedImage.height
        setRatio(width / height)
      }
      image.src = src
    }
  }, [src])

  return ratio
}
