import { styled } from '@mui/material'
import { TextField } from '@mui/material'
import { ChangeEvent, HTMLAttributes, KeyboardEvent, useEffect, useState } from 'react'

import { PaginationFragment } from '@nuna/api'
import { IconButton, IconChevronLeft, IconChevronRight, Skeleton, borderGrey, csx } from '@nuna/tunic'

export interface PaginatorProps extends Partial<PaginationFragment> {
  loading: boolean
  onPageChange: (newPage: number) => void
  totalLabel: string
  limit: number
  topBorder?: boolean
  controlProps?: HTMLAttributes<HTMLSpanElement>
}

type Props = PaginatorProps & HTMLAttributes<HTMLDivElement>

export function Paginator({
  totalCount,
  page = 1,
  pages = 1,
  totalLabel,
  onPageChange,
  loading,
  className,
  topBorder = true,
  controlProps = {},
  ...props
}: Props) {
  const [inputValue, setInputValue] = useState<string | number | null | undefined>(page)

  useEffect(() => {
    setInputValue(page)
  }, [page])

  const handleInputChange = (event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    setInputValue(event.currentTarget.value)
  }

  const handleInputBlur = () => {
    let newPage = 1

    if (inputValue) {
      const parsedInputValue = parseInt(inputValue as string)
      if (!isNaN(parsedInputValue)) {
        newPage = parsedInputValue
      }
    }

    if (newPage > pages) {
      newPage = pages
    }

    if (newPage === 0) {
      newPage = 1
    }

    setInputValue(newPage)

    if (newPage !== page) {
      onPageChange(newPage)
    }
  }

  const handlePage = (backward: boolean) => {
    const newPage = backward ? page - 1 : page + 1
    onPageChange(newPage)
  }

  const handleKeyUp = (event: KeyboardEvent<HTMLDivElement>) => {
    if (event.key === 'Enter') {
      event.currentTarget.blur()
      handleInputBlur()
    }
  }

  if (totalCount === 0) {
    return null
  }

  return (
    <StyledPaginator topBorder={topBorder} className={csx([className, 'space-between v-align'])} {...props}>
      <span className="text-secondary">
        {loading && <Skeleton height={1} width={20} />}
        {!loading && (
          <>
            {totalCount ? totalCount.toLocaleString('en-US') : <Skeleton />} {totalLabel}
          </>
        )}
      </span>
      {(pages || 1) > 1 && (
        <span {...controlProps} className={csx([controlProps?.className, 'v-align'])}>
          <IconButton type="button" tooltip="Page back" onClick={() => handlePage(true)} disabled={page === 1}>
            <IconChevronLeft />
          </IconButton>
          <StyledTextField
            value={inputValue}
            onChange={handleInputChange}
            onBlur={handleInputBlur}
            onKeyUp={handleKeyUp}
            style={{ width: 30 }}
          />
          <span className="mx-1">/</span>
          <span>{pages || 1}</span>
          <IconButton type="button" tooltip="Page forward" onClick={() => handlePage(false)} disabled={page === pages}>
            <IconChevronRight />
          </IconButton>
        </span>
      )}
    </StyledPaginator>
  )
}

const StyledPaginator = styled('div')<{ topBorder: boolean }>`
  padding-top: var(--margin-1);
  ${({ topBorder }) => (topBorder ? `border-top: 1px solid ${borderGrey};` : '')}
`

const StyledTextField = styled(TextField)`
  input {
    text-align: center;
  }
`
