import { IconProps } from './IconProps'

export function IconDivider({ size = 24, color = 'currentColor', ...props }: IconProps) {
  return (
    <svg width={size} height={size} viewBox="0 0 56 56" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M30.3493 27.9088L19.3535 49.6625C19.1785 50.014 19.3247 50.4398 19.6785 50.6126L22.0672 51.8476C22.4217 52.0207 22.8507 51.8763 23.0253 51.5255L34.6773 28.1059L34.8277 27.8038C34.8775 27.7036 34.8762 27.5408 34.8256 27.442L34.6719 27.1415L23.0267 4.38762C22.8482 4.03883 22.4172 3.89998 22.0653 4.07677L19.5923 5.6099C19.2398 5.78701 19.0991 6.21398 19.2775 6.56259L30.3462 27.3648C30.4229 27.5146 30.4247 27.7572 30.3493 27.9088Z"
        fill={color}
      />
    </svg>
  )
}
