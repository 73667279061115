import { Form, Formik } from 'formik'
import moment from 'moment/moment'
import React from 'react'
import { z } from 'zod'
import { toFormikValidationSchema } from 'zod-formik-adapter'

import {
  CredentialingChecklistDataPoint,
  ProviderEducationInput,
  ProviderEducationVerificationStatus,
  useSaveProviderEducationMutation,
} from '@nuna/api'
import { errorService, formService } from '@nuna/core'
import { Box, DatePicker, FillButton, HelperText, Select, Stack, TextField, toast } from '@nuna/tunic'

const { composeHelperText, composeSelectOptionsFromEnum } = formService
const selectOptions = composeSelectOptionsFromEnum(ProviderEducationVerificationStatus)

const validationSchema = z.object({
  source: z.string(),
  verificationStatus: z.nativeEnum(ProviderEducationVerificationStatus),
  verifiedAt: z.coerce.date(),
})

type Props = {
  selectedItem: CredentialingChecklistDataPoint
}

export const ChecklistEducationForm = ({ selectedItem }: Props) => {
  const [saveProviderEducationData, { loading: isSaving }] = useSaveProviderEducationMutation()
  const handleSubmit = async (values: CredentialingChecklistDataPoint) => {
    const { providerId, verifiedAt, verifiableId, source, verificationStatus } = values as ProviderEducationInput

    try {
      await saveProviderEducationData({
        variables: { data: { id: values.tid, providerId, verifiedAt, verifiableId, source, verificationStatus } },
      })
      toast.success('Education updated')
    } catch (e) {
      toast.urgent(errorService.transformGraphQlError(e, 'Error updating education record'))
    }
  }

  return (
    <Stack direction={'column'} sx={{ height: '100%', p: 1, flex: 1 }}>
      <Formik
        initialValues={selectedItem}
        validationSchema={toFormikValidationSchema(validationSchema)}
        onSubmit={handleSubmit}
      >
        {formikProps => {
          const { values, touched, errors, setFieldValue, getFieldProps } = formikProps

          return (
            <Form>
              <Stack direction={'row'} gap={3} sx={{ justifyContent: 'flex-start', p: 1, flex: 1 }}>
                <Box sx={{ width: '280px', justifyContent: 'flex-start' }}>
                  <DatePicker
                    sx={{ width: '280px' }}
                    label="Verified Date"
                    value={moment(values.verifiedAt)}
                    error={touched.verifiedAt && !!errors.verifiedAt}
                    helperText={composeHelperText('Education verified date', errors.verifiedAt, !!touched.verifiedAt)}
                    onChange={date => setFieldValue('verifiedAt', date?.toDate(), true)}
                  />
                </Box>
                <Box sx={{ width: '280px', justifyContent: 'flex-start' }}>
                  <Select label="Verfication Status" {...getFieldProps('verificationStatus')}>
                    {Object.entries(selectOptions).map(([key, value]) => (
                      <option key={key} value={key}>
                        {value}
                      </option>
                    ))}
                  </Select>

                  <HelperText error={!!errors.verificationStatus} urgent={!!errors.verificationStatus}>
                    {composeHelperText(
                      'Education verification status',
                      errors.verificationStatus,
                      !!touched.verificationStatus,
                    )}
                  </HelperText>
                </Box>
                <Box sx={{ width: '280px', justifyContent: 'flex-start' }}>
                  <TextField
                    label="Source"
                    {...getFieldProps('source')}
                    helperText={composeHelperText('Education source', errors.source, !!touched.source)}
                    autoFocus
                  />
                </Box>
                <Box sx={{ width: '280px', justifyContent: 'flex-start' }}>
                  <FillButton type="submit" isLoading={isSaving}>
                    Save
                  </FillButton>
                </Box>
              </Stack>
            </Form>
          )
        }}
      </Formik>
    </Stack>
  )
}
