import { uniqueId } from 'lodash'
import React, { useMemo, useRef, useState } from 'react'

import { greySet, plumSet, salmonSet, tealSet, yellowSet } from '../../styles/colorSets'
import { error as errorColor, interactiveFill } from '../../styles/colors'
import { ImperfectCircle } from './ImperfectCircle'
import { randomArrayItem, randomNumber, randomPointOnCirclePerimeter } from './util/random'
import { degreesToRadians, translateCenterOriginToTopLeft } from './util/trigonometry'

const blobColors = [salmonSet.tint[40], tealSet.tint[40], yellowSet.tint[40], plumSet.tint[40]]
const hoverDisplacement = 5

function randomBlobColor(omitColor?: string): string {
  const chosenColor = randomArrayItem(blobColors)

  if (chosenColor === omitColor) {
    return randomBlobColor(omitColor)
  }

  return chosenColor
}

export interface BlobAvatarProps extends React.HTMLAttributes<SVGElement> {
  url: string
  uploadPrompt?: boolean
  error?: boolean
  downloadPrompt?: boolean
  distortion?: number
  size?: number
  wanderSpace?: number
  onClick?: React.MouseEventHandler<SVGElement>
  isHoveredExternal?: boolean
  darkOverlay?: boolean
}

export function BlobAvatar({
  url,
  size = 200,
  wanderSpace = 10,
  distortion = 10,
  uploadPrompt = false,
  error = false,
  downloadPrompt = false,
  isHoveredExternal = false,
  darkOverlay = false,
  ...props
}: BlobAvatarProps) {
  const [isHoveredInternal, setIsHoveredInternal] = useState(false)
  const uniqueImageMaskId = useRef(uniqueId('image-circle-'))
  const uniqueAvatarMaskId = useRef(uniqueId('avatar-circle-'))
  const canvasSize = size + wanderSpace + distortion + hoverDisplacement * 2
  const imageSize = size + distortion

  const blobColors = useMemo(() => {
    const firstColor = randomBlobColor()
    const secondColor = error ? salmonSet[80].transparency(0.3) : randomBlobColor(firstColor)
    return [firstColor, secondColor]
  }, [error])

  const blobPositions = useRef(
    (() => {
      const wanderRadius = wanderSpace / 2
      const canvasRadius = canvasSize / 2
      const blobRadius = size / 2

      // calculate a random x,y coordinate somewhere along the perimeter of circle whose diameter is equal to the wanderSpace
      const [firstPositionOriginCenter, { angle }] = randomPointOnCirclePerimeter(wanderRadius)

      // use the random angle from the first circle to generate a second angle that is more than 45 degrees but not more than 120 degrees in order to guarantee an overlap
      const secondAngle = randomNumber(degreesToRadians(45), degreesToRadians(120)) + angle
      const [secondPosition] = randomPointOnCirclePerimeter(wanderRadius, secondAngle)

      // The calculated coordinates are relative to the center of SVG canvas. Make them relative to the top left
      const firstPositionTopLeft = translateCenterOriginToTopLeft(firstPositionOriginCenter, canvasRadius)
      const secondPositionTopLeft = translateCenterOriginToTopLeft(secondPosition, canvasRadius)

      // The coordinates are now relative to the top left of the SVG canvas but they represent the center of our circle. The path is drawn from top left so subtract the radius
      return [
        { x: firstPositionTopLeft.x - blobRadius, y: firstPositionTopLeft.y - blobRadius, angle },
        { x: secondPositionTopLeft.x - blobRadius, y: secondPositionTopLeft.y - blobRadius, angle: secondAngle },
      ]
    })(),
  )

  const { angle: firstBlobAngle } = blobPositions.current[0]
  const { angle: secondBlobAngle } = blobPositions.current[1]

  const firstBlobHover = {
    x: hoverDisplacement * Math.cos(firstBlobAngle),
    y: hoverDisplacement * Math.sin(firstBlobAngle),
  }
  const secondBlobHover = {
    x: hoverDisplacement * Math.cos(secondBlobAngle),
    y: hoverDisplacement * Math.sin(secondBlobAngle),
  }

  const isHovered = isHoveredExternal || isHoveredInternal

  return (
    <svg
      width={canvasSize}
      height={canvasSize}
      onMouseEnter={() => setIsHoveredInternal(true)}
      onMouseLeave={() => setIsHoveredInternal(false)}
      viewBox={`0 0 ${canvasSize} ${canvasSize}`}
      {...props}
    >
      <defs>
        <ImperfectCircle
          id={uniqueImageMaskId.current}
          distortion={distortion}
          position={{ x: canvasSize / 2 - size / 2, y: canvasSize / 2 - size / 2 }}
          size={size}
        />

        <linearGradient id="dark-overlay-gradient">
          <stop offset="0%" stopColor="rgb(1, 103, 153)" />
          <stop offset="100%" stopColor="rgb(0, 162, 223)" />
        </linearGradient>
      </defs>

      <mask id={uniqueAvatarMaskId.current}>
        <use href={`#${uniqueImageMaskId.current}`} fill="white" />
      </mask>

      <ImperfectCircle
        position={blobPositions.current[0]}
        fill={blobColors[0]}
        size={size}
        distortion={distortion}
        style={isHovered ? { transform: `rotate(27deg) translate(${firstBlobHover.x}px, ${firstBlobHover.y}px)` } : {}}
      />

      <ImperfectCircle
        position={blobPositions.current[1]}
        fill={blobColors[1]}
        size={size}
        distortion={distortion}
        style={
          isHovered ? { transform: ` rotate(27deg) translate(${secondBlobHover.x}px, ${secondBlobHover.y}px)` } : {}
        }
      />

      <image
        x={canvasSize / 2 - imageSize / 2}
        y={canvasSize / 2 - imageSize / 2}
        href={url}
        width={imageSize}
        height={imageSize}
        mask={`url(#${uniqueAvatarMaskId.current})`}
        preserveAspectRatio="xMidYMid slice"
      />

      {darkOverlay && (
        <rect
          x="0"
          y="0"
          width={canvasSize}
          height={canvasSize}
          mask={`url(#${uniqueAvatarMaskId.current})`}
          fill="url(#dark-overlay-gradient)"
          style={{ transition: 'opacity 0.3s', opacity: isHovered ? 0.6 : 0.4 }}
        />
      )}

      {downloadPrompt && (
        <rect
          x="0"
          y="0"
          width={canvasSize}
          height={canvasSize}
          mask={`url(#${uniqueAvatarMaskId.current})`}
          {...(isHovered ? { fill: interactiveFill, style: { opacity: 0.4 } } : { fill: 'transparent' })}
        />
      )}

      {uploadPrompt && (
        <>
          <rect
            x="0"
            y="0"
            width={canvasSize}
            height={canvasSize}
            mask={`url(#${uniqueAvatarMaskId.current})`}
            {...(isHovered
              ? { fill: interactiveFill, style: { opacity: 0.7 } }
              : { fill: error ? salmonSet[80].transparency(0.4) : greySet[50].transparency(0.8) })}
          />
          <use
            href={`#${uniqueImageMaskId.current}`}
            stroke={isHovered ? interactiveFill : error ? errorColor : greySet[50].hex}
            strokeWidth="2px"
            strokeDasharray="5,5"
            fill="none"
            style={{ transform: 'scale(1.07)', transformOrigin: 'center' }}
          />
        </>
      )}
    </svg>
  )
}
