import { styled } from '@mui/material'
import moment from 'moment'
import { useEffect, useState } from 'react'

import { AppointmentCurrentStatus, ProviderNetworkStatus, useAppointmentStatsQuery } from '@nuna/api'
import { ExploreFirstEmptyState, useAppointmentDrawerSearchParams, useTitle } from '@nuna/common'
import { errorService } from '@nuna/core'
import {
  GhostButton,
  IconDescriptionEdit,
  PageContent,
  PageHeader,
  PageHeading,
  PageWrapper,
  borderGrey,
  primarySet,
  toast,
} from '@nuna/tunic'

import { useProviderAppContext } from '../../shared/ProviderAppContext'
import { PastSessionIndex } from './PastSessionIndex'
import laptopGuySrc from './img/LaptopGuy.png'

export const launchDay = moment('12/1/2019', 'M/D/YYYY')

export enum SessionIndexTabs {
  HAPPENING = 'happening',
  UPCOMING = 'upcoming',
  PREVIOUS = 'previous',
}

// TODO paginate me
export function SessionIndex() {
  const { provider, providerNetworkStatus } = useProviderAppContext()
  useTitle('Sessions')
  const [endDate] = useState(moment().toDate())
  const { loading, error, data } = useAppointmentStatsQuery({
    fetchPolicy: 'network-only',
    variables: {
      options: {
        end: endDate,
        statusIsIn: [AppointmentCurrentStatus.Active],
      },
    },
  })

  const { openScheduleAppointmentDrawer } = useAppointmentDrawerSearchParams()
  const handleOnNewAppointment = () => {
    openScheduleAppointmentDrawer(provider?.id ?? '')
  }

  useEffect(() => {
    if (error) {
      toast.urgent(errorService.transformUserGraphqlError(error, 'Unable to load sessions'))
    }
  }, [error])

  const pastAppointments = data?.appointmentStats.filteredAppointments || []

  const incompleteAppointments = pastAppointments
    .filter(appointment => !appointment.sessionId)
    .filter(appointment => moment(appointment.startDatetime).isAfter(launchDay))

  return (
    <PageWrapper>
      <PageHeader>
        <PageHeading className="mb-0">Session Notes</PageHeading>

        <NotesNotification className="v-align text-secondary mr-auto">
          <IconDescriptionEdit className="mr-1" color={primarySet[50]} />
          <p className="mb-0">
            <span className="text-bold">{incompleteAppointments.length}</span> Notes Needed
          </p>
        </NotesNotification>
      </PageHeader>

      <PageContent className="relative">
        {pastAppointments.length > 0 && (
          <PastSessionIndex loading={loading && !data} pastAppointments={pastAppointments} />
        )}
        {providerNetworkStatus === ProviderNetworkStatus.InProgress &&
          (!pastAppointments || pastAppointments.length === 0) && (
            <ExploreFirstEmptyState
              textBlock={
                <>
                  <h2 className="h4 mt-0">Your notebook to capture insights and reflections from each session.</h2>
                  <p className="large">
                    <GhostButton onClick={handleOnNewAppointment}>Schedule</GhostButton> an appointment, and once it’s
                    done, session notes will show here.
                  </p>
                </>
              }
              className="intro v-align pr-3"
              illustrationSrc={laptopGuySrc}
            />
          )}
      </PageContent>
    </PageWrapper>
  )
}

const NotesNotification = styled('div')`
  margin-left: var(--margin-2);
  padding-left: var(--margin-2);
  border-left: 1px solid ${borderGrey};
`
