import { styled } from '@mui/material'

import { routeService } from '@nuna/core'
import { BelowTablet, Grid, IconChevronThick, OutlineButtonLink } from '@nuna/tunic'

import illustrationSrc from '../img/team.png'
import { EnrollmentBenefits } from './EnrollmentBenefits'

export function Completed() {
  return (
    <div>
      <Grid container direction="row-reverse" spacing={2} alignItems="center">
        <Grid
          size={{
            xs: 12,
            sm: 5,
          }}
        >
          <IllustrationContainer>
            <Illustration src={illustrationSrc} alt="" />
          </IllustrationContainer>
        </Grid>
        <Grid
          size={{
            xs: 12,
            sm: 7,
          }}
        >
          <Heading className="h3 mb-5">Congrats, You're All Set with Insurance!</Heading>
          <h3 className="h5 mb-0 paragraphs">
            Expect to hear back in around <strong>7 days</strong> on your first enrollment.
          </h3>
        </Grid>
      </Grid>
      <ResponsiveContainer>
        <EnrollmentBenefits />
      </ResponsiveContainer>
      <div className="mt-5">
        <OutlineButtonLink to={routeService.scheduleDetail()}>
          Continue to Tava <IconChevronThick direction="right" className="ml-xs" size={16} />
        </OutlineButtonLink>
      </div>
    </div>
  )
}

const IllustrationContainer = styled('div')`
  text-align: center;

  @media (${BelowTablet}) {
    text-align: left;
  }
`

const Illustration = styled('img')`
  max-width: 287px;
`

const Heading = styled('h2')`
  @media (${BelowTablet}) {
    margin-bottom: var(--margin-2);
  }
`

const ResponsiveContainer = styled('div')`
  @media (${BelowTablet}) {
    margin-top: var(--margin-3);
  }
`
