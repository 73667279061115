import { Formik } from 'formik'
import { ChangeEvent, useEffect, useState } from 'react'

import {
  AccountSource,
  AccountSourceChannel,
  InsuranceCoverageDetails,
  PaymentPreference,
  usePatientCoverageQuery,
  usePreconfigurePatientAccountMutation,
} from '@nuna/api'
import { useAppointmentDrawerSearchParams } from '@nuna/common'
import { Audience, errorService } from '@nuna/core'
import {
  CoverageFormPatient,
  CoverageTypeCard,
  InsuranceCoverageForm,
  InsuranceCoverageFormValues,
  buildPendingPatientInsuranceCoverageInput,
  insuranceInitialValues,
} from '@nuna/coverage'
import { CreditCard, PaymentMethodSelect, useClientPaymentMethods } from '@nuna/payment-methods'
import { ChipGroup, Radio, toast } from '@nuna/tunic'

import { Section, SectionTitle, clientInviteFormInitialValues, clientInviteInsuranceSchema } from '../../shared'

interface PaymentMethodPatient extends CoverageFormPatient {
  paymentPreference: PaymentPreference
  state: string
}

interface ClientPaymentMethodProps {
  audience: Audience
  patient: PaymentMethodPatient
  providerId: string
  tokenId: string
  onCardChange?: (change: CreditCard) => void
}

export function ClientPaymentMethod({ audience, patient, tokenId, onCardChange }: ClientPaymentMethodProps) {
  const [paymentPreference, setPaymentPreference] = useState<PaymentPreference | null>(null)
  const [preconfigurePatientAccount, { loading: preconfigureLoading }] = usePreconfigurePatientAccountMutation()

  const {
    drawerConfig: { drawerOpen: appointmentDrawerOpen },
  } = useAppointmentDrawerSearchParams()

  const {
    data: coverageData,
    loading: coverageLoading,
    refetch: refetchCoverage,
  } = usePatientCoverageQuery({
    variables: { patientId: patient.id },
    skip: !patient.id || appointmentDrawerOpen,
  })

  const {
    paymentMethods,
    defaultForSessions,
    savePaymentMethod,
    queryResult: { loading: paymentMethodsLoading },
  } = useClientPaymentMethods(patient.id)

  useEffect(() => {
    if (patient.paymentPreference) {
      setPaymentPreference(patient.paymentPreference)
    }
  }, [patient])

  const loading = coverageLoading || preconfigureLoading || paymentMethodsLoading
  const insuranceCoverage = coverageData?.patientCoverage.find(
    coverage => coverage.type === PaymentPreference.Insurance,
  )?.details as InsuranceCoverageDetails
  const source = audience === 'admin' ? AccountSource.CareNavigator : clientInviteFormInitialValues.source
  const sourceChannel = audience === 'admin' ? AccountSourceChannel.Other : clientInviteFormInitialValues.sourceChannel

  const handleSubmitInsurance = async (formValues: { insuranceValues: InsuranceCoverageFormValues }) => {
    try {
      await preconfigurePatientAccount({
        variables: {
          tokenId: tokenId,
          paymentInfo: {
            paymentPreference: PaymentPreference.Insurance,
            insurance: buildPendingPatientInsuranceCoverageInput(formValues, {
              firstName: patient.firstName,
              lastName: patient.lastName,
              dob: patient.dob,
              state: patient.state,
            }),
          },
          source: source,
          sourceChannel: sourceChannel,
        },
      })

      await refetchCoverage({
        patientId: patient.id,
      })
    } catch (e) {
      toast.urgent(errorService.transformGraphQlError(e, 'Could not save payment preference. Try again'))
      return
    }
  }

  const handleOnPaymentPreference = async (event: ChangeEvent<HTMLInputElement>) => {
    switch (event.target.value) {
      case PaymentPreference.Cash:
        await preconfigurePatientAccount({
          variables: {
            accountInfo: null,
            paymentInfo: {
              paymentPreference: PaymentPreference.Cash,
            },
            tokenId: tokenId,
            source: source,
            sourceChannel: sourceChannel,
          },
        })

        setPaymentPreference(PaymentPreference.Cash)
        break
      case PaymentPreference.Insurance:
        if (insuranceCoverage) {
          await preconfigurePatientAccount({
            variables: {
              accountInfo: null,
              paymentInfo: {
                paymentPreference: PaymentPreference.Insurance,
              },
              tokenId: tokenId,
              source: source,
              sourceChannel: sourceChannel,
            },
          })
        }

        setPaymentPreference(PaymentPreference.Insurance)
        break
    }
  }

  const handleOnCardChange = (card: CreditCard) => {
    onCardChange && onCardChange(card)
  }

  if (!patient.id || !tokenId) {
    return null
  }

  return (
    <Section>
      <SectionTitle>Payment Method (optional)</SectionTitle>
      <ChipGroup className="ml-1">
        <Radio
          name="paymentPreference"
          checked={paymentPreference === PaymentPreference.Cash}
          value={PaymentPreference.Cash}
          disabled={loading}
          onChange={handleOnPaymentPreference}
        >
          Cash
        </Radio>
        <Radio
          name="paymentPreference"
          checked={paymentPreference === PaymentPreference.Insurance}
          value={PaymentPreference.Insurance}
          disabled={loading || !patient.state}
          onChange={handleOnPaymentPreference}
        >
          Insurance
        </Radio>
      </ChipGroup>

      {paymentPreference === PaymentPreference.Cash && (
        <PaymentMethodSelect
          paymentMethods={paymentMethods}
          value={defaultForSessions}
          defaultFor="sessions"
          loading={paymentMethodsLoading}
          savePaymentMethod={savePaymentMethod}
          showHSAWarning={false}
          patientId={patient.id}
          onChange={handleOnCardChange}
        />
      )}

      {paymentPreference === PaymentPreference.Insurance && insuranceCoverage && (
        <CoverageTypeCard
          type="insurance"
          memberId={insuranceCoverage.planMemberId}
          verifiedAt={insuranceCoverage.verifiedDate}
          payerName={insuranceCoverage.planName}
          insuredFullName={insuranceCoverage?.subscriber}
          payerLogoUrl={insuranceCoverage.insuranceLogoUrl}
        />
      )}

      {paymentPreference === PaymentPreference.Insurance && !insuranceCoverage && (
        <Formik
          initialValues={{ insuranceValues: insuranceInitialValues }}
          onSubmit={handleSubmitInsurance}
          validationSchema={clientInviteInsuranceSchema}
        >
          <InsuranceCoverageForm
            saveButton={true}
            patient={patient}
            state={patient.state || ''}
            inDrawer
            audience={audience}
          />
        </Formik>
      )}
    </Section>
  )
}
