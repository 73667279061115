import { styled } from '@mui/material'
import { ChangeEvent, useState } from 'react'

import {
  PatientPreferencesDocument,
  useUpdatePatientPreferencesMutation,
  useUpdateSessionAutonoteRequirementsMutation,
} from '@nuna/api'
import { Popover, Switch, TextButton, TextButtonExternalLink, fontSize } from '@nuna/tunic'

import { useVideoCallContext } from '../context/VideoCallContext'

export function AutonotesSetting() {
  const { appointmentId, localUser, isPatientAutonotesEnabled, togglePatientAutonotesStatus } = useVideoCallContext()
  const [anchorEl, setAnchorEl] = useState<HTMLElement>()

  const [updatePatientPreferences] = useUpdatePatientPreferencesMutation({
    refetchQueries: [{ query: PatientPreferencesDocument }],
  })

  const [updateSessionAutonoteRequirements] = useUpdateSessionAutonoteRequirementsMutation({
    variables: { appointmentId },
  })

  if (localUser.isProvider) return null // only rendered for patients

  const isAutonotesEnabled = isPatientAutonotesEnabled(localUser)

  const handleChange = async (e: ChangeEvent<HTMLInputElement>) => {
    togglePatientAutonotesStatus(localUser, e.target.checked)

    try {
      await updatePatientPreferences({
        variables: {
          patientId: localUser.patientId,
          preferences: {
            autonotesEnabled: e.target.checked,
          },
        },
      })
    } catch (error) {
      console.error(error)
      togglePatientAutonotesStatus(localUser, !e.target.checked)
      return // Exit early if updatePatientPreferences fails
    }

    try {
      // sync preferences to appointment
      await updateSessionAutonoteRequirements()
    } catch (error) {
      console.error(error)
    }
  }

  return (
    <div>
      <p className="caption">
        <strong>Automatic summaries</strong> are{' '}
        {isAutonotesEnabled ? (
          <>
            <strong>on</strong>. These
          </>
        ) : (
          <>
            <strong>off</strong>. When on, these
          </>
        )}{' '}
        help your provider stay present during your session.{' '}
        <TextButton onClick={e => setAnchorEl(e.currentTarget)} scheme="dark">
          Adjust
        </TextButton>
      </p>

      <StyledPopover
        anchorEl={anchorEl}
        open={!!anchorEl}
        onClose={() => setAnchorEl(undefined)}
        padding="1rem"
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        transformOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        PaperProps={{ style: { maxWidth: 310 } }}
      >
        <Switch className="mb-2" checked={isAutonotesEnabled} onChange={handleChange}>
          Automatic summaries
        </Switch>

        <p className="mb-0 caption">
          <em>Summaries are HIPAA compliant, encrypted, and source data is not stored.</em>
          <br />
          <br />
          <TextButtonExternalLink href="https://client-support.tavahealth.com/hc/en-us/articles/27211209579796">
            More info
          </TextButtonExternalLink>
        </p>
      </StyledPopover>
    </div>
  )
}

const StyledPopover = styled(Popover)`
  .MuiPaper-root {
    color: var(--body-secondary-color);

    p {
      font-size: ${fontSize.caption};
    }
  }
`
