import { css, styled } from '@mui/material'
import { HTMLAttributes } from 'react'
import { useMediaQuery } from 'react-responsive'

import { BelowTablet, Drawer, IconButton, IconClose, greySet, shadowDepth } from '@nuna/tunic'

interface Props extends HTMLAttributes<HTMLDivElement> {
  isOpen: boolean
  onClose: () => void
}

export function ResourceCenterWrapper({ isOpen, children, onClose, ...props }: Props) {
  const isMobile = useMediaQuery({ query: `(${BelowTablet})` })

  if (isMobile) {
    return (
      <MobileDrawer isOpen={isOpen} onClose={onClose} {...props}>
        <IconButton variant="secondary" className="ml-auto mr-1 mt-1" tooltip="Close" onClick={onClose}>
          <IconClose />
        </IconButton>
        {children}
      </MobileDrawer>
    )
  }

  return <DesktopDrawer isOpen={isOpen}>{children}</DesktopDrawer>
}

const sharedStyles = css`
  background-color: ${greySet[5].hex};
  width: 250px;
`

const MobileDrawer = styled(Drawer)`
  border-radius: 0;

  .MuiPaper-root {
    ${sharedStyles}
  }
`

const DesktopDrawer = styled('div')<{ isOpen: boolean }>`
  ${sharedStyles}
  box-shadow: ${shadowDepth(4)};
  position: absolute;
  // TODO - these widths should probably be constants somewhere
  left: ${({ isOpen }) => (isOpen ? '192px' : '-250px')};
  transition: left 0.3s;
  height: 100%;
  z-index: 99999;
`
