import { styled } from '@mui/material'
import { GraphQLError } from 'graphql'
import { truncate } from 'lodash'
import moment from 'moment'
import { HTMLAttributes, useState } from 'react'

import {
  DocumentType,
  UserDocumentDownloadFragment,
  UserDocumentStatus,
  useDocumentUpload,
  useSaveUserDocumentMutation,
  useUserDocumentsQuery,
} from '@nuna/api'
import { Audience } from '@nuna/core'
import { ContextualAlert, FileTypeIcon, FileUpload, IconInfo, IconTrash, Tooltip, greySet, toast } from '@nuna/tunic'

import { Section, SectionTitle } from '../../shared'

interface ClientInviteDocumentsProps extends HTMLAttributes<HTMLDivElement> {
  audience: Audience
  loading: boolean
  patientLoginId: string
  providerLoginId: string
}

export function ClientDocuments({
  audience,
  loading,
  patientLoginId,
  providerLoginId,
  ...props
}: ClientInviteDocumentsProps) {
  const [uploading, setUploading] = useState(false)
  const { uploadDocument } = useDocumentUpload()
  const [saveUserDocument, { loading: saveUserDocumentLoading }] = useSaveUserDocumentMutation()

  const {
    data,
    loading: userDocumentsLoading,
    refetch: refetchDocuments,
  } = useUserDocumentsQuery({
    variables: {
      searchOptions: {
        createdBy: providerLoginId,
        loginId: patientLoginId,
        status: UserDocumentStatus.Default,
      },
    },
    skip: !patientLoginId,
  })

  const handleOnSelection = async (files: File[]) => {
    try {
      if (!files.length) return

      setUploading(true)

      for (const file of files) {
        const document = await uploadDocument(file, DocumentType.UserCustomDocument)

        const { errors: userDocumentErrors } = await saveUserDocument({
          variables: {
            userDocument: {
              documentId: document.id,
              loginId: patientLoginId,
            },
          },
        })

        if (userDocumentErrors?.length) {
          throw new Error(userDocumentErrors[0].message)
        }

        await refetchDocuments({
          searchOptions: {
            createdBy: providerLoginId,
            loginId: patientLoginId,
          },
        })
      }

      setUploading(false)
    } catch (e) {
      toast.urgent('There was an error uploading client document')
      console.error(e)
    }
  }

  const handleOnRemove = async (userDocument: UserDocumentDownloadFragment) => {
    try {
      const { errors: userDocumentErrors } = await saveUserDocument({
        variables: {
          userDocument: {
            id: userDocument.id,
            documentId: userDocument.document.id,
            status: UserDocumentStatus.Archived,
          },
        },
      })

      if (userDocumentErrors?.length) {
        throw new Error(userDocumentErrors[0].message)
      }

      await refetchDocuments({
        searchOptions: {
          createdBy: providerLoginId,
          loginId: patientLoginId,
          status: UserDocumentStatus.Default,
        },
      })
    } catch (e) {
      toast.urgent((e as GraphQLError).message)
      console.error(e)
    }
  }

  const disableUpload = loading || uploading || saveUserDocumentLoading || userDocumentsLoading

  return (
    <Section {...props}>
      <SectionTitle>Upload Client Documents (optional)</SectionTitle>
      {providerLoginId && (
        <>
          <p style={{ fontStyle: 'italic' }}>
            Examples: Consent forms, previous session notes, completed worksheets, assesments
          </p>

          {data?.userDocuments.map(userDocument => {
            return (
              <FileDisplayDiv key={userDocument.document.fileName}>
                <FileTypeIcon mimeType={userDocument.document.documentType} className="mr-1" />
                <Tooltip content={userDocument.document.fileName}>
                  <span>{truncate(userDocument.document.fileName, { length: 24 })}</span>
                </Tooltip>
                <span>{moment(userDocument.document?.createdAt).format('YYYY/MM/DD')}</span>
                <IconTrash
                  style={{ justifySelf: 'flex-end', cursor: 'pointer', opacity: disableUpload ? '0.5' : 'initial' }}
                  onClick={() => !disableUpload && handleOnRemove(userDocument)}
                />
              </FileDisplayDiv>
            )
          })}

          <FileUpload
            fit="fixed"
            accept={[
              'application/msword', // doc
              'application/pdf',
              'application/rtf',
              'application/vnd.oasis.opendocument.text', // odt
              'application/vnd.openxmlformats-officedocument.wordprocessingml.document', // docx
              'application/x-tex',
              'image/jpeg',
              'image/png',
              'image/svg+xml',
              'text/plain',
            ]}
            renderInlineDisplay={false}
            multiple={true}
            onDrop={handleOnSelection}
            requirementDescription="Accepts multiple files at once"
            size="md"
            disabled={disableUpload}
          />
        </>
      )}

      {!providerLoginId && audience === 'admin' && (
        <ContextualAlert className="my-2" icon={<IconInfo />} intent="default" role="alert" scribbleType="default">
          Choose a provider to upload client documents
        </ContextualAlert>
      )}
    </Section>
  )
}

const FileDisplayDiv = styled('div')`
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 56px;
  margin: 8px 0;
  padding: 8px 12px 8px 12px;
  border: 1px solid ${greySet[30].hex};
  border-radius: var(--border-radius);
  background-color: white;
`
