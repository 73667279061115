import { TextField as MUITextField, TextFieldProps as MUITextFieldProps } from '@mui/material'
import { PatternFormat } from 'react-number-format'

export interface DOBTextFieldProps extends Omit<MUITextFieldProps, 'defaultValue' | 'value' | 'type'> {
  defaultValue?: string | number | null | undefined
  value?: string | number | null | undefined
  type?: 'text' | 'tel' | 'password' | undefined
}

export function DOBTextField({ ...props }: DOBTextFieldProps) {
  return <PatternFormat placeholder="MM/DD/YYYY" {...props} mask="_" customInput={MUITextField} format="##/##/####" />
}
