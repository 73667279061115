import { styled } from '@mui/material'

import { ProviderDetailsQuery, PublicProviderQuery, usePatientContextQuery, usePublicProviderQuery } from '@nuna/api'
import { TimeslotSkeleton } from '@nuna/appointment'
import { useAuthDataContext } from '@nuna/auth'
import { useAppointmentDrawerSearchParams } from '@nuna/common'
import { Avatar, CardBody, csx, eggshell } from '@nuna/tunic'

import { LocationDateScheduling } from '../../LocationDateScheduling'

interface ScheduleAppointmentTimeSlotSelectProps {
  provider: ProviderDetailsQuery['provider'] | PublicProviderQuery['publicProvider']
}

export function ScheduleAppointmentTimeSlotSelect({ provider }: ScheduleAppointmentTimeSlotSelectProps) {
  const {
    drawerConfig: { addressId },
    setAddressId,
  } = useAppointmentDrawerSearchParams()
  const { loggedIn } = useAuthDataContext()
  const { data: patientContextData } = usePatientContextQuery({ skip: !loggedIn })
  const patientId = patientContextData?.patientContext.patient.id
  const { data: publicProviderData } = usePublicProviderQuery({
    variables: { providerId: provider?.id ?? '', patientId },
    skip: !provider?.id,
  })
  const locations = publicProviderData?.publicProvider?.therapyLocations

  const firstLocationId = locations?.[0]?.id
  const therapistHasInPerson = !!firstLocationId

  if (loggedIn && !patientContextData) {
    return null
  }
  const patient = patientContextData?.patientContext.patient

  return (
    <>
      <StyledHeaderSection>
        <Avatar url={provider?.avatarUrl} size={'md'} />
        <h2 className={csx([{ loading: provider === null }, 'h5 mb-3'])}>
          {provider?.firstName} {provider?.lastName}
        </h2>
      </StyledHeaderSection>
      {!provider ? (
        <TimeslotSkeleton className="p-2" />
      ) : (
        <StyledCardBody className="pt-4">
          <LocationDateScheduling
            therapistHasInPerson={therapistHasInPerson}
            provider={provider}
            patient={patient}
            firstLocationId={firstLocationId}
            newAddressId={addressId}
            initialAddressId={addressId}
            onAddressChange={addressId => setAddressId(addressId)}
          />
        </StyledCardBody>
      )}
    </>
  )
}

const StyledHeaderSection = styled('section')`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 1rem;
  text-align: center;
  background-color: ${eggshell};
`

const StyledCardBody = styled(CardBody)`
  background-color: #fff;
`
