import { styled } from '@mui/material'
import { useState } from 'react'
import { useMediaQuery } from 'react-responsive'

import { routeService } from '@nuna/core'
import { BelowTablet, GhostButtonLink, IconChevronThick, IconHome, Logo } from '@nuna/tunic'

import { useVideoCallContext } from '../../context/VideoCallContext'
import { Countdown } from '../Countdown'
import { ChatToggleButton } from './components/ChatToggleButton'
import { ClientInfoToggleButton } from './components/ClientInfoToggleButton'
import { Divider } from './components/Divider'
import { NotesToggleButton } from './components/NotesToggleButton'
import { ScheduleToggleButton } from './components/ScheduleToggleButton'
import { SessionTimer } from './components/SessionTimer'
import { SettingsToggleButton } from './components/SettingsToggleButton'
import { TherapyType } from './components/TherapyType'

export function Topbar() {
  const { appointment, isMobileFullscreen, isSidebarOpen, isSessionEnded, localUser, appointmentId } =
    useVideoCallContext()
  const [isScheduleToggleExpanded, setIsScheduleToggleExpanded] = useState(false)
  const isMobile = useMediaQuery({ query: `(${BelowTablet})` })

  return (
    <TopbarWrapper
      $isHiddenMobile={isMobileFullscreen || isSidebarOpen}
      $isScheduleToggleExpanded={isScheduleToggleExpanded}
    >
      <div className="v-align full-width schedule-hide">
        <LogoStyled scheme="dark" color="white" height={29} />
        <TherapyType appointment={appointment} />
        <Divider />
        <Countdown startDatetime={appointment?.startDatetime} inline renderWhenPastDue={<SessionTimer />} />
      </div>

      {isSessionEnded ? (
        localUser.isProvider ? (
          <GhostButtonLink
            scheme="dark"
            className="v-align nowrap"
            to={`${routeService.sessionDetailRoute(undefined, appointmentId)}?fromLiveSession=true`}
          >
            {!isMobile && 'Record session note'}
            <IconChevronThick direction="right" className="ml-1 mr-0" size={18} />
          </GhostButtonLink>
        ) : (
          <GhostButtonLink scheme="dark" className="nowrap" to="/" data-testid="return-to-home">
            {isMobile ? <IconHome /> : 'Return to Home'}
          </GhostButtonLink>
        )
      ) : (
        <Toggles className="v-align ml-auto">
          <ScheduleToggleButton isExpanded={isScheduleToggleExpanded} setIsExpanded={setIsScheduleToggleExpanded} />
          <ChatToggleButton className="schedule-hide" />
          <NotesToggleButton className="schedule-hide" />
          <SettingsToggleButton className="schedule-hide" />
          <ClientInfoToggleButton className="schedule-hide" />
        </Toggles>
      )}
    </TopbarWrapper>
  )
}

const TopbarWrapper = styled('section')<{ $isHiddenMobile: boolean; $isScheduleToggleExpanded: boolean }>`
  align-items: center;
  display: flex;
  justify-content: space-between;
  margin-bottom: 1rem;
  padding-right: 1rem;
  transition: transform 0.3s;
  width: 100%;
  overflow: hidden;
  flex: 0 0 auto;

  @media (${BelowTablet}) {
    position: absolute;
    padding: 0.75rem 1rem;
    left: 0;
    top: 0;
    ${props =>
      props.$isHiddenMobile &&
      `
      transform: translateY(-100%);
    `}

    ${props =>
      props.$isScheduleToggleExpanded &&
      `
        .schedule-hide {
          display: none;
        }
    `}
  }
`

const Toggles = styled('div')`
  gap: var(--spacing-1);
`

const LogoStyled = styled(Logo)`
  @media (${BelowTablet}) {
    display: none;
  }
`
