import { Autocomplete, AutocompleteProps, TextField } from '@mui/material'
import FuzzySearch from 'fuzzy-search'
import { useMemo, useState } from 'react'

import { ProviderCredentialingStatus } from '@nuna/api'
import { providerService } from '@nuna/core'

type LimitedAutocompleteProps = Omit<
  AutocompleteProps<ProviderCredentialingStatus, true, boolean | undefined, false>,
  'onChange' | 'options' | 'renderInput'
>

interface Props extends LimitedAutocompleteProps {
  value?: ProviderCredentialingStatus[]
  onChange: (credentialingStatuses: ProviderCredentialingStatus[]) => void
  label?: string
}

const OPTIONS = Object.values(ProviderCredentialingStatus) as ProviderCredentialingStatus[]

export function CredentialingStatusSelect({ value, onChange, label = 'Credentialing Status', ...props }: Props) {
  const [searchTerm, setSearchTerm] = useState('')

  const filteredCredentialingStatuses = useMemo<ProviderCredentialingStatus[]>(() => {
    if (!searchTerm) {
      return OPTIONS
    }
    const searcher = new FuzzySearch(OPTIONS, [], {
      caseSensitive: false,
    })

    return searcher.search(searchTerm)
  }, [searchTerm])

  return (
    <Autocomplete
      {...props}
      multiple
      onChange={(_event, value) => {
        onChange(value)
      }}
      getOptionLabel={credentialingStatus => providerService.readableCredentialingStatus(credentialingStatus)}
      options={filteredCredentialingStatuses}
      value={value}
      ListboxProps={{ className: 'MuiAutocomplete-listbox' }}
      onInputChange={(_event, searchTerm) => setSearchTerm(searchTerm)}
      renderInput={params => <TextField name="selectCredentialingStatus" label={label} {...params} />}
    />
  )
}
