import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext'
import { $wrapNodeInElement } from '@lexical/utils'
import {
  $createParagraphNode,
  $getRoot,
  $insertNodes,
  $isElementNode,
  $isRootOrShadowRoot,
  COMMAND_PRIORITY_EDITOR,
  LexicalCommand,
  LexicalNode,
  createCommand,
} from 'lexical'
import { useEffect } from 'react'

import { AssessmentBundleType } from '@nuna/api'
import { toast } from '@nuna/tunic'

import { assessmentBundleTypeDisplayLookupDesktop } from '../../../util/assessments'
import { $createAssessmentNode, $isAssessmentNode, AssessmentNode, traverseNodes } from '../nodes/AssessmentNode'

type AssessmentPayload = {
  id: string | null
  type: AssessmentBundleType
}

export const INSERT_ASSESSMENT_COMMAND: LexicalCommand<AssessmentPayload> = createCommand()
export const REMOVE_ASSESSMENT_COMMAND: LexicalCommand<string> = createCommand()

export function AssessmentPlugin(): JSX.Element | null {
  const [editor] = useLexicalComposerContext()

  useEffect(() => {
    if (!editor.hasNodes([AssessmentNode])) {
      throw new Error('AssessmentPlugin: AssessmentNode not registered on editor')
    }

    editor.registerCommand<AssessmentPayload>(
      INSERT_ASSESSMENT_COMMAND,
      payload => {
        const { id, type } = payload

        const existingAssessmentOfSameType = editor.getEditorState().read(() => {
          return traverseNodes($getRoot()).find(node => {
            return $isAssessmentNode(node) && node.getAssessmentType() === type
          })
        })
        if (existingAssessmentOfSameType) {
          toast.urgent(`Your message already has a ${assessmentBundleTypeDisplayLookupDesktop[type]}.`)
          // eslint-disable-next-line no-console
          console.log('AssessmentPlugin: An assessment with the same type already exists in the message.')
          return false
        }

        const assessmentNode = $createAssessmentNode(id, type)
        $insertNodes([assessmentNode])
        if ($isRootOrShadowRoot(assessmentNode.getParentOrThrow())) {
          $wrapNodeInElement(assessmentNode, $createParagraphNode).selectEnd()
        }
        return true
      },
      COMMAND_PRIORITY_EDITOR,
    )

    editor.registerCommand<string>(
      REMOVE_ASSESSMENT_COMMAND,
      key => {
        editor.update(() => {
          const nodeToRemove = editor.getEditorState().read(() => {
            return traverseNodesAndFindByKey($getRoot(), key)
          })

          nodeToRemove.remove()
        })
        return true
      },
      COMMAND_PRIORITY_EDITOR,
    )
  }, [editor])

  return null
}

const traverseNodesAndFindByKey = (node: LexicalNode, key: string, nodes: AssessmentNode[] = []) => {
  if ($isElementNode(node)) {
    const children = node.getChildren()
    for (const child of children) {
      if ($isAssessmentNode(child) && child.getKey() === key) {
        nodes.push(child)
      } else {
        traverseNodesAndFindByKey(child, key, nodes)
      }
    }
  }
  return nodes[0]
}
