import { $getRoot, LexicalEditor } from 'lexical'

export function getJSON(editor: LexicalEditor) {
  return editor.toJSON().editorState
}

export function getPlainText(editor: LexicalEditor) {
  return new Promise<string>(resolve => {
    editor.getEditorState().read(() => {
      resolve($getRoot().getTextContent())
    })
  })
}
