import { HTMLAttributes } from 'react'

import { Patient, Provider } from '@nuna/api'
import { useAuthDataContext, useIsAdmin } from '@nuna/auth'
import { userService } from '@nuna/core'
import { Avatar, Skeleton, csx } from '@nuna/tunic'

import { NewLink } from './NewLink'

const { userFullName } = userService

// TODO:robbie - this is duplicate from core-deprecated
type ProviderProfileUser = Pick<
  Provider,
  '__typename' | 'firstName' | 'lastName' | 'avatarUrl' | 'id' | 'loginId' | 'licenseTitle'
>
type ClientProfileUser = Pick<Patient, '__typename' | 'firstName' | 'lastName' | 'avatarUrl' | 'id' | 'loginId'>

type ProfileUser = ProviderProfileUser | ClientProfileUser

export const userProfile = (user?: ProfileUser | null) => {
  if (user?.__typename === 'Provider') {
    return `/providers/${user.id}/profile`
  } else if (user?.__typename === 'Patient') {
    return `/clients/${user.id}/profile`
  }

  return ''
}
// TODO:end

interface Props {
  user?: ProfileUser | null
  loading?: boolean
  variant?: 'page-header' | 'drawer-header' | 'inline'
  openInNewTab?: boolean
  showAvatar?: boolean
  showTitle?: boolean
}

export function NewUserLink({
  user,
  loading = false,
  variant = 'inline',
  openInNewTab = false,
  showAvatar = true,
  showTitle,
  className,
}: Props & HTMLAttributes<HTMLSpanElement>) {
  const { login } = useAuthDataContext()
  const isAdmin = useIsAdmin()
  const isMe = login?.id === user?.loginId
  // TODO - link to other provider profiles once we've enabled that ability
  const noLink = user?.__typename === 'Provider' && !isAdmin && !isMe
  const path = isMe ? '/profile' : userProfile(user)
  const classes = csx([
    {
      'text-default h5': variant === 'page-header',
      'text-medium text-secondary': variant === 'inline',
      'text-medium text-dark large': variant === 'drawer-header',
      'ml-1': showAvatar,
    },
    'mb-0',
    className,
  ])

  const Content = () => (
    <>
      {loading && <Skeleton className="ml-1" width={10} height={variant === 'inline' ? 1.5 : 2} />}
      {noLink && !loading && <span className={classes}>{userFullName(user, showTitle)}</span>}
      {!noLink && !loading && (
        <NewLink to={path} className={classes} openInNewTab={openInNewTab}>
          {userFullName(user, showTitle)}
        </NewLink>
      )}
    </>
  )

  return showAvatar ? (
    <span className="v-align">
      <Avatar url={user?.avatarUrl} size="xs" />

      <Content />
    </span>
  ) : (
    <Content />
  )
}
