import { Autocomplete, AutocompleteProps, TextField, TextFieldProps } from '@mui/material'
import FuzzySearch from 'fuzzy-search'
import { isString } from 'lodash'
import { useMemo, useState } from 'react'

import { CountryOption, addressService } from '@nuna/core'

const { countriesAsOptions } = addressService

type LimitedAutocompleteProps = Omit<
  AutocompleteProps<CountryOption | undefined, false, boolean | undefined, false>,
  'onChange' | 'options' | 'renderInput' | 'value'
>

interface Props extends LimitedAutocompleteProps {
  value?: CountryOption | string | null
  onChange: (countryOption: CountryOption | undefined | null) => void
  label?: string
  inputProps?: TextFieldProps
}

const options = countriesAsOptions()

export function CountrySelect({ value, onChange, label = 'Country', inputProps = {}, ...props }: Props) {
  const [searchTerm, setSearchTerm] = useState('')

  const valueAsOption = useMemo<CountryOption | null | undefined>(() => {
    if (isString(value)) {
      return options.find(option => option.value === value)
    }
    return value
  }, [value])

  const filteredStates = useMemo<CountryOption[]>(() => {
    const searcher = new FuzzySearch(options, ['label'], {
      caseSensitive: false,
    })
    if (!searchTerm) {
      return options
    }
    return searcher.search(searchTerm)
  }, [searchTerm])

  return (
    <Autocomplete
      {...props}
      onChange={(_event, option) => {
        onChange(option)
      }}
      getOptionLabel={option => option?.label ?? ''}
      isOptionEqualToValue={(option, value) => option?.value === value?.value}
      options={filteredStates}
      value={valueAsOption ?? null}
      ListboxProps={{ className: 'MuiAutocomplete-listbox' }}
      onInputChange={(_event, searchTerm) => setSearchTerm(searchTerm)}
      renderInput={params => <TextField name="selectdSpecialty" label={label} {...inputProps} {...params} />}
    />
  )
}
