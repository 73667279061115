import * as Yup from 'yup'
import { Form, Formik } from 'formik'
import { useMemo } from 'react'

import { useCaqhCredentialsQuery, useSaveCaqhCredentialsMutation } from '@nuna/api'
import { errorService, formService } from '@nuna/core'
import { GhostButtonLink, TextField, toast } from '@nuna/tunic'

import { useCAQHSetupContext } from '../../../context/CAQHSetupContext'
import { CAQHStepper } from '../CAQHStepper'
import { CAQHSubtitle } from './CAQHSubtitle'

interface SyncFields {
  username: string
  password: string
}

const SyncSchema = Yup.object().shape<SyncFields>({
  username: Yup.string().required(),
  password: Yup.string().required(),
})

const bothFieldsFilledIn = (values: SyncFields) => values.username !== '' && values.password !== ''

export function CAQHSync() {
  const { providerId } = useCAQHSetupContext()
  const { data: credentialData } = useCaqhCredentialsQuery({
    variables: { providerId },
    skip: !providerId,
  })

  const [saveCaqhCredentials] = useSaveCaqhCredentialsMutation()

  const handleSubmit = async (values: SyncFields) => {
    if (!bothFieldsFilledIn(values)) {
      return
    }
    const mutationValues = {
      ...(credentialData?.providerCaqh.id ? { id: credentialData?.providerCaqh.id } : {}),
      providerId,
      caqhUsername: values.username,
      caqhPassword: values.password,
    }
    try {
      const result = await saveCaqhCredentials({ variables: { data: mutationValues } })
      const savedCredentials = result.data?.saveProviderCaqh
      if (!savedCredentials) {
        throw new Error('No credentials were saved')
      }
    } catch (e) {
      toast.urgent(errorService.transformGraphQlError(e, 'There was an error saving CAQH credentials'))
    }
  }

  const initialValues: SyncFields = useMemo(
    () => ({
      username: credentialData?.providerCaqh.caqhUsername || '',
      password: credentialData?.providerCaqh.caqhPassword || '',
    }),
    [credentialData],
  )

  return (
    <div>
      <h3>Sync Your CAQH Profile</h3>
      <CAQHSubtitle>
        The <b>Council for Affordable Quality Healthcare (CAQH)</b> is an association used by insurance networks to
        <b> verify your credentials</b> as a provider and is <b>required for getting enrolled</b>.
      </CAQHSubtitle>

      <p className="mt-4 text-secondary">
        Please enter your CAQH login information to enable Tava to check your status.
      </p>
      <Formik
        enableReinitialize
        initialValues={initialValues}
        validationSchema={SyncSchema}
        onSubmit={() => {
          return
        }}
      >
        {({ values, errors, touched, handleChange, handleBlur }) => {
          return (
            <Form>
              <div className="mt-4">
                <TextField
                  name="username"
                  className="mt-4"
                  label="Username"
                  value={values.username}
                  onChange={handleChange}
                  {...formService.composeHelperTextWithError('', errors.username, touched.username)}
                  onBlur={handleBlur}
                />
                <TextField
                  name="password"
                  type="password"
                  className="mt-5"
                  label="Password"
                  value={values.password}
                  onChange={handleChange}
                  {...formService.composeHelperTextWithError('', errors.password, touched.password)}
                  onBlur={handleBlur}
                />
              </div>
              <div>
                <GhostButtonLink
                  variant="secondary"
                  className="mt-5"
                  to={`https://proview.caqh.org/Login/ForgotPassword?Type=PR`}
                >
                  I forgot my password
                </GhostButtonLink>
              </div>
              <div>
                <GhostButtonLink
                  variant="secondary"
                  className="mt-3"
                  to={`https://proview.caqh.org/PR/Registration/SelfRegistration`}
                >
                  I don't have a CAQH profile
                </GhostButtonLink>
              </div>
              <div className="mt-6">
                <CAQHStepper nextStepEffect={() => handleSubmit(values)} skipSave={!bothFieldsFilledIn(values)} />
              </div>
            </Form>
          )
        }}
      </Formik>
    </div>
  )
}
