import { Navigate, Outlet } from 'react-router-dom'

import { useAuthDataContext } from '../context/AuthDataContext'
import { buildRedirectParams } from '../util/utils'

interface PrivateRouteProps {
  children?: JSX.Element
}

export const PrivateRoute = ({ children }: PrivateRouteProps) => {
  const { loading, loggedIn } = useAuthDataContext()

  if (loading) return null

  if (loggedIn) return children ?? <Outlet />

  return <Navigate to={{ pathname: '/login', search: buildRedirectParams() }} />
}
