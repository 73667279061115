import React from 'react'

import { Drawer as BaseDrawer, DrawerProps as BaseDrawerProps } from '@nuna/tunic'

import { FocusRegion } from './FocusRegion'

interface DrawerProps extends BaseDrawerProps {
  children?: JSX.Element
  shouldReturnFocus?: boolean
  label?: string
}

export function Drawer({ children, isOpen = false, shouldReturnFocus = true, label, ...props }: DrawerProps) {
  return (
    <BaseDrawer {...props} isOpen={isOpen}>
      <FocusRegion isActive={isOpen} shouldReturnFocus={shouldReturnFocus} label={label}>
        {children}
      </FocusRegion>
    </BaseDrawer>
  )
}
