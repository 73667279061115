import '@fullcalendar/react/dist/vdom'

import { type Dictionary, type EventApi } from '@fullcalendar/react'

import * as Yup from 'yup'
import { styled } from '@mui/material'
import { Formik } from 'formik'
import { AnimatePresence } from 'framer-motion'
import { startCase } from 'lodash'
import moment from 'moment-timezone'

import { useIsAdmin } from '@nuna/auth'
import { useTimeZoneContext } from '@nuna/common'
import { formService, timeService } from '@nuna/core'
import { BelowTablet, Card, Checkbox, FillButton, GhostButton, Grid, Select, eggshell, greySet } from '@nuna/tunic'

import { ProviderAddressSelect } from '../../ProviderAddressSelect/ProviderAddressSelect'

const { daysOfWeek, hoursOfDay, mergeDateAndTime } = timeService
const { composeHelperTextWithError } = formService

export interface AvailabilityExtendedProps {
  virtualAllowed: boolean
  addressId: string | null
}
export interface AvailabilitySubmission extends Pick<EventApi, 'start' | 'end' | 'id' | 'extendedProps'> {
  extendedProps: Dictionary & Partial<AvailabilityExtendedProps>
}

interface AvailabilityEventEditFormProps {
  event: EventApi
  timeZone: string
  onSubmit: (submissionData: AvailabilitySubmission) => void
  onRemove: () => void
}

enum TypesOfAppointment {
  Virtual = 'Virtual',
  InPerson = 'In-Person',
}

const availabilitySchema = Yup.object().shape({
  dayOfWeek: Yup.number().required(),
  startTime: Yup.string().required(),
  endTime: Yup.string()
    .required()
    .test('is-after-start-time', 'End time must be after start time', function (value) {
      const { startTime } = this.parent
      // use moment to compare times, also allow midnight 00:00 to be valid
      return (
        startTime &&
        value &&
        (moment(value, 'HH:mm').isAfter(moment(startTime, 'HH:mm')) || (value === '00:00' && startTime !== '00:00'))
      )
    }),
  typesOfAppointmentPermitted: Yup.array()
    .of(Yup.string())
    .min(1, 'At least one type of appointment must be selected.')
    .required(),
  addressId: Yup.string()
    .nullable()
    .when('typesOfAppointmentPermitted', {
      is: (types: TypesOfAppointment[]) => types.includes(TypesOfAppointment.InPerson),
      then: Yup.string().required('Please select an in-person location or uncheck in-person.'),
    }),
})

export function AvailabilityEventEditForm({ event, timeZone, onSubmit, onRemove }: AvailabilityEventEditFormProps) {
  const { timeZoneToUse } = useTimeZoneContext()
  const isAdmin = useIsAdmin()

  return (
    <Formik
      initialValues={buildInitialValues(event, timeZoneToUse, timeZone, isAdmin)}
      validationSchema={availabilitySchema}
      onSubmit={values => {
        const newStart = moment(event.start).set('day', values.dayOfWeek)
        const newEnd = moment(event.end).set('day', values.dayOfWeek)
        const isVirtualAllowed = values.typesOfAppointmentPermitted.includes(TypesOfAppointment.Virtual)
        const isInPersonAllowed = values.typesOfAppointmentPermitted.includes(TypesOfAppointment.InPerson)

        onSubmit({
          id: event.id,
          start: mergeDateAndTime(newStart, values.startTime).toDate(),
          end: mergeDateAndTime(newEnd, values.endTime).toDate(),
          extendedProps: {
            virtualAllowed: isVirtualAllowed,
            addressId: isInPersonAllowed ? values.addressId : null,
          },
        })
      }}
      enableReinitialize
    >
      {({ values, handleChange, handleSubmit, errors, touched }) => (
        <Form onSubmit={handleSubmit}>
          <h3 className="body large text-medium">Working Hours</h3>
          <Card style={{ padding: '1rem' }}>
            <Grid container spacing={2}>
              <DesktopOnlyGrid size={12}>
                <Select
                  label="Day of week"
                  value={values.dayOfWeek.toString()}
                  name="dayOfWeek"
                  onChange={handleChange}
                  {...composeHelperTextWithError('', errors.startTime, touched.startTime)}
                >
                  {daysOfWeek.map((day, index) => (
                    <option key={day} value={index}>
                      {startCase(day)}
                    </option>
                  ))}
                </Select>
              </DesktopOnlyGrid>

              <Grid
                size={{
                  xs: 12,
                  sm: 6,
                }}
              >
                <Select label="Start time" value={values.startTime} name="startTime" onChange={handleChange}>
                  {hoursOfDay.map(hour => (
                    <option key={hour.value} value={hour.value}>
                      {hour.display}
                    </option>
                  ))}
                </Select>
              </Grid>

              <Grid
                size={{
                  xs: 12,
                  sm: 6,
                }}
              >
                <Select
                  label="End time"
                  value={values.endTime}
                  name="endTime"
                  onChange={handleChange}
                  {...composeHelperTextWithError('', errors.endTime, touched.endTime)}
                >
                  {hoursOfDay.map(hour => (
                    <option key={hour.value} value={hour.value}>
                      {hour.display}
                    </option>
                  ))}
                </Select>
              </Grid>
              <Grid size={12}>
                <FormLabel>Types of Appointments</FormLabel>
                <Grid container spacing={3} className="pb-3">
                  {Object.values(TypesOfAppointment).map(type => (
                    <Grid key={type}>
                      <Checkbox
                        name="typesOfAppointmentPermitted"
                        checked={values.typesOfAppointmentPermitted.includes(type)}
                        onChange={() => {
                          const newTypes = values.typesOfAppointmentPermitted.includes(type)
                            ? values.typesOfAppointmentPermitted.filter(t => t !== type)
                            : [...values.typesOfAppointmentPermitted, type]
                          handleChange({
                            target: {
                              name: 'typesOfAppointmentPermitted',
                              value: newTypes,
                            },
                          })
                        }}
                        error={!!errors.typesOfAppointmentPermitted}
                      >
                        {type}
                      </Checkbox>
                    </Grid>
                  ))}
                </Grid>
                {touched.typesOfAppointmentPermitted && errors.typesOfAppointmentPermitted && (
                  <div className="text-error caption">{errors.typesOfAppointmentPermitted}</div>
                )}
                {values.typesOfAppointmentPermitted.includes(TypesOfAppointment.InPerson) && (
                  <AnimatePresence>
                    <ProviderAddressSelect
                      addressId={values.addressId}
                      onAddressChange={selectedAddress =>
                        handleChange({
                          target: {
                            name: 'addressId',
                            value: selectedAddress.id,
                          },
                        })
                      }
                      error={!!errors.addressId}
                      helperText={touched.addressId && errors.addressId?.toString()}
                    />
                  </AnimatePresence>
                )}
              </Grid>
            </Grid>
          </Card>

          <div className="text-center">
            <FillButton className="mt-3 full-width" type="submit">
              Update
            </FillButton>

            <GhostButton className="mt-3 mb-2" type="button" variant="destroy" onClick={onRemove}>
              Remove Time Slot
            </GhostButton>
          </div>
        </Form>
      )}
    </Formik>
  )
}

const Form = styled('form')`
  background-color: ${eggshell};
  padding: 1rem;
`

const DesktopOnlyGrid = styled(Grid)`
  @media (${BelowTablet}) {
    display: none;
  }
`

const buildInitialValues = (event: EventApi, timeZoneToUse: string, timeZone: string, isAdmin: boolean) => {
  const typesOfAppointmentPermitted = eventToTypesOfAppointmentPermitted(event)
  const initialValues = {
    dayOfWeek: event.start?.getDay() ?? 0,
    startTime: timeZoneAdjust({ date: event.start, oldTimeZone: timeZoneToUse, newTimeZone: timeZone, isAdmin }),
    endTime: timeZoneAdjust({ date: event.end, oldTimeZone: timeZoneToUse, newTimeZone: timeZone, isAdmin }),
    typesOfAppointmentPermitted,
    addressId: event.extendedProps.addressId ?? null,
  }
  return initialValues
}

function timeZoneAdjust({
  date,
  newTimeZone,
  oldTimeZone,
  isAdmin,
}: {
  date: Date | null
  oldTimeZone: string
  newTimeZone: string
  isAdmin: boolean
}) {
  if (isAdmin && newTimeZone && oldTimeZone && newTimeZone !== oldTimeZone) {
    return moment(date).tz(newTimeZone).format('H:mm')
  }

  return moment(date).format('H:mm')
}

const eventToTypesOfAppointmentPermitted = (event: AvailabilityEventEditFormProps['event']) => {
  const { virtualAllowed, addressId } = event.extendedProps
  const typesOfAppointmentPermitted: TypesOfAppointment[] = []
  if (virtualAllowed) {
    typesOfAppointmentPermitted.push(TypesOfAppointment.Virtual)
  }
  if (addressId) {
    typesOfAppointmentPermitted.push(TypesOfAppointment.InPerson)
  }
  return typesOfAppointmentPermitted
}

const FormLabel = styled('label')`
  margin-bottom: var(--spacing-2);
  margin-top: var(--spacing-2);
  display: block;
  color: ${greySet.primary.hex};
`
