import { IconProps } from './IconProps'

export function IconCommentOutline({ size = 24, color = 'currentColor', ...props }: IconProps) {
  return (
    <svg width={size} height={size} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.91523 15.1735C7.91523 15.1735 8.20658 14.8979 8.32391 14.8063C8.51898 14.6539 8.65192 14.5875 8.93852 14.5875C9.29527 14.5875 8.93375 14.5875 9.147 14.5875C11.5729 14.5875 16.4247 14.5875 16.4247 14.5875C16.6549 14.5875 16.8414 14.401 16.8414 14.1708V4.41434C16.8414 4.18422 16.6549 3.99768 16.4247 3.99768H3.5359C3.30578 3.99768 3.11923 4.18422 3.11923 4.41434V14.1708C3.11923 14.401 3.30578 14.5875 3.5359 14.5875C3.5359 14.5875 5.42646 14.5875 6.39438 14.5875C6.53754 14.5875 6.67832 14.668 6.67832 14.8384C6.67832 15.3033 6.67832 15.7667 6.67832 16.0176C6.67832 16.1643 6.82546 16.2732 7.01706 16.0869C7.48752 15.6294 7.66718 15.4201 7.91523 15.1735ZM3.33366 2.5H16.667C17.5875 2.5 18.3337 3.24619 18.3337 4.16667V14.3933C18.3337 15.3137 17.5875 16.0599 16.667 16.0599C16.667 16.0599 11.7673 16.0599 9.31743 16.0599C9.05055 16.0599 8.93118 16.139 8.76368 16.2926C8.36306 16.6599 7.49663 17.4539 6.22779 18.614C6.04041 18.8003 5.69113 18.8178 5.52888 18.6546C5.45125 18.5766 5.41618 18.4286 5.41618 18.3185C5.41618 18.3185 5.41618 17.0436 5.41618 16.3571C5.41618 16.1553 5.33545 16.0599 5.13669 16.0599C4.51545 16.0599 3.33366 16.0599 3.33366 16.0599C2.41318 16.0599 1.66699 15.3137 1.66699 14.3933V4.16667C1.66699 3.24619 2.41318 2.5 3.33366 2.5Z"
        fill={color}
      />
    </svg>
  )
}
