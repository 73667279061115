import { useEffect } from 'react'

import { useUuid } from '@nuna/common'
import { toast } from '@nuna/tunic'

import { useSessionDraftConflictContext } from '../context/SessionDraftConflictContext'

const PING_INTERVAL = 5000

export function useSessionDraftConflictWarning(appointmentId: string, { disableToast = false } = {}) {
  const { notifyTabActive, notifyTabClosed, hasConflictingTabs } = useSessionDraftConflictContext()

  const showWarning = hasConflictingTabs(appointmentId)
  const id = useUuid() // needs to be universally unique since each tab is a different instance of the App and React's useId won't work

  useEffect(() => {
    notifyTabActive({ id, path: window.location.pathname, appointmentId })

    const interval = setInterval(() => {
      notifyTabActive({ id, path: window.location.pathname, appointmentId })
    }, PING_INTERVAL)

    return () => clearInterval(interval)
  }, [id, notifyTabActive, appointmentId])

  useEffect(() => {
    return () => notifyTabClosed({ id })
  }, [id, notifyTabClosed])

  useEffect(() => {
    const handleUnload = () => notifyTabClosed({ id })
    window.addEventListener('beforeunload', handleUnload)

    return () => window.removeEventListener('beforeunload', handleUnload)
  }, [id, notifyTabClosed])

  useEffect(() => {
    if (showWarning && !disableToast) {
      const toastId = toast.urgent(
        "Another tab is open with this same session's note. Please close it to avoid overwrite conflicts.",
        {
          duration: Infinity,
          canDismiss: false,
        },
      )

      return () => toast.dismiss(toastId)
    }
  }, [showWarning, disableToast, appointmentId])
}
