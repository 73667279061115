import { noop } from 'lodash'
import mixpanel from 'mixpanel-browser'
import moment from 'moment'
import { useState } from 'react'

import {
  type AppointmentForNotificationFragment,
  type CancelPolicyStatusFragment,
  type DrawerAppointmentFragment,
  useProviderTherapyLocationsQuery,
} from '@nuna/api'
import { DrawerStepper, useAppointmentDrawerSearchParams } from '@nuna/common'
import { formService, numberService } from '@nuna/core'
import { LateCancelationPolicyModal } from '@nuna/fee-policies'
import { MessageComposer, useMessageComposerContext } from '@nuna/messaging'
import { PaymentMethodSelect } from '@nuna/payment-methods'
import { Avatar, Card, CardBody, CardHeader, ContextualAlert, GhostButton } from '@nuna/tunic'

import { AppointmentDrawerPaddedContainer } from '../../../AppointmentDrawer'
import { useClientCancelOrReschedulePaymentMethods } from '../../../hooks/useClientCancelOrReschedulePaymentMethods'
import { useRescheduleForm } from '../../../hooks/useRescheduleForm'
import { AppointmentChangeReasonForm } from '../../AppointmentChangeReasonForm'
import { LocationDateScheduling } from '../../LocationDateScheduling'

interface Props {
  appointment: DrawerAppointmentFragment
  cancelPolicyStatus: CancelPolicyStatusFragment
  subjectToFee: boolean
  onAppointmentScheduled?: (appointment: AppointmentForNotificationFragment) => void
}

export function RescheduleAsClient({
  appointment,
  cancelPolicyStatus,
  subjectToFee,
  onAppointmentScheduled = noop,
}: Props) {
  const {
    drawerConfig: { timeSlot },
    setAddressId,
  } = useAppointmentDrawerSearchParams()
  const { data: locationsData } = useProviderTherapyLocationsQuery({
    variables: { providerId: appointment.provider.id, patientId: appointment.patient.id },
    skip: !appointment.provider.id || !appointment.patient.id,
  })
  const firstLocationId = locationsData?.providerTherapyLocations[0]?.address.id
  const therapistHasInPerson = !!firstLocationId

  const [cancelPolicyOpen, setCancelPolicyOpen] = useState(false)

  const { formProps } = useRescheduleForm({ appointment, audience: 'client', subjectToFee })
  const { loading: saveMessageLoading, invalidAttachments } = useMessageComposerContext()
  const { submitForm, errors, values, setFieldValue, initialValues, submitCount } = formProps
  const initialDate = moment(values.newStart)
  const initialAddressId = initialValues.newAddressId || appointment.address?.id
  const { newAddressId } = values

  const {
    defaultForFees,
    paymentMethods,
    savePaymentMethod,
    queryResult: { loading: paymentMethodsLoading },
  } = useClientCancelOrReschedulePaymentMethods({
    formProps,
    subjectToFee: subjectToFee && !!cancelPolicyStatus.cancelPolicy?.chargeAmount,
  })
  const hasFee = subjectToFee && !!cancelPolicyStatus.cancelPolicy?.chargeAmount

  return (
    <>
      <AppointmentDrawerPaddedContainer>
        <p className="text-light text-secondary mt-3">Change the time and/or place of your session</p>
        <Card>
          <CardHeader className="v-align flex-wrap">
            <Avatar url={appointment.provider.avatarUrl} className="mr-2" size="sm" />
            <div>
              <h4 className="body large text-medium text-dark-grey mb-0">
                {appointment.provider.firstName} {appointment.provider.lastName}
              </h4>
            </div>
            {hasFee && (
              <ContextualAlert intent="urgent" fullWidth style={{ width: '100%' }}>
                <span className="caption">
                  Rescheduling now would be subject to a{' '}
                  <GhostButton
                    onClick={() => setCancelPolicyOpen(true)}
                    className="caption text-bold"
                    variant="destroy"
                  >
                    {numberService.centsToFormattedDollars(cancelPolicyStatus.cancelPolicy?.chargeAmount)} fee
                  </GhostButton>
                </span>
              </ContextualAlert>
            )}
          </CardHeader>
          <CardBody>
            <LocationDateScheduling
              therapistHasInPerson={therapistHasInPerson}
              provider={appointment.provider}
              patient={appointment.patient}
              firstLocationId={firstLocationId}
              newAddressId={newAddressId}
              initialAddressId={initialAddressId}
              onAddressChange={addressId => {
                setAddressId(addressId)
                setFieldValue('newAddressId', addressId)
              }}
              initialDate={initialDate}
              rescheduleAppointmentId={appointment.id}
            />
          </CardBody>
        </Card>
      </AppointmentDrawerPaddedContainer>

      {timeSlot && (
        <AppointmentDrawerPaddedContainer className="mt-4 mb-5">
          <div className="large text-medium mb-1">Message to {appointment.provider.firstName}</div>
          <MessageComposer
            audience="client"
            autoCollapse={false}
            error={submitCount > 0 && !!errors.hasMessage}
            boot={
              subjectToFee && (
                <AppointmentChangeReasonForm
                  formProps={formProps}
                  requestedBy={values.requestedBy}
                  audience="client"
                  labelText="Why the late notice?"
                />
              )
            }
          />
          {values.hasValidPaymentMethod === false && (
            <>
              <div className="text-bold mt-2 mb-1">Payment</div>
              <PaymentMethodSelect
                defaultFor="fees"
                savePaymentMethod={savePaymentMethod}
                value={defaultForFees}
                paymentMethods={paymentMethods}
                onChange={noop}
                loading={paymentMethodsLoading}
                patientId={appointment.patient.id}
                {...formService.composeHelperTextWithError('', errors.hasValidPaymentMethod, true)}
              />
            </>
          )}
          <DrawerStepper
            nextButtonText="Reschedule And Send"
            nextButtonDiabled={invalidAttachments || saveMessageLoading}
            nextButtonProps={{ 'data-testid': 'siderail-reschedule-submit' }}
            onNextButtonClick={async () => {
              setFieldValue('newStart', timeSlot.start)

              const didSubmit = await submitForm()
              if (!didSubmit) return

              onAppointmentScheduled({ ...appointment, startDatetime: values.newStart })

              if (subjectToFee) {
                mixpanel.track('rescheduled late', { appointmentId: appointment.id })
              }
            }}
            className="mt-6"
            formProps={formProps}
          />
        </AppointmentDrawerPaddedContainer>
      )}

      <LateCancelationPolicyModal
        coverageType={appointment.coverageType}
        provider={appointment.provider}
        isOpen={cancelPolicyOpen}
        onClose={() => setCancelPolicyOpen(false)}
      />
    </>
  )
}
