import { IconProps } from './IconProps'

export function IconCaution({ size = 24, color = 'currentColor', ...props }: IconProps) {
  return (
    <svg width={size} height={size} viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M26.8001 3.60517C25.2375 0.887951 22.6717 0.953823 21.1091 3.67104L3.56753 34.1862C2.00498 36.9034 3.27969 39.3489 6.413 39.3489H41.488C44.6213 39.3489 45.9042 36.9364 44.3417 34.2192L26.8001 3.60517ZM25.1306 32.9923C24.9497 33.1405 24.7359 33.2558 24.4974 33.3216C24.2589 33.3875 24.004 33.4205 23.7326 33.4205C23.453 33.4205 23.198 33.3875 22.9513 33.3216C22.7046 33.2558 22.4908 33.1487 22.3181 32.9923C22.1371 32.8441 21.9973 32.6465 21.8986 32.3994C21.7917 32.1524 21.7424 31.8478 21.7424 31.4855C21.7424 31.1149 21.7917 30.802 21.8986 30.555C21.9973 30.308 22.1371 30.1022 22.3181 29.9539C22.499 29.8057 22.7046 29.6987 22.9513 29.6328C23.198 29.5669 23.453 29.5422 23.7326 29.5422C24.004 29.5422 24.2589 29.5752 24.4974 29.6328C24.7359 29.6987 24.9497 29.8057 25.1306 29.9539C25.3116 30.1022 25.4596 30.308 25.5665 30.555C25.6734 30.802 25.7228 31.1149 25.7228 31.4855C25.731 31.856 25.6734 32.1607 25.5665 32.3994C25.4596 32.6465 25.3116 32.8441 25.1306 32.9923ZM23.0335 27.986L21.6026 16.5408H25.8214L24.3987 27.986H23.0335Z"
        fill={color}
      />
    </svg>
  )
}
