import * as Yup from 'yup'
import { styled } from '@mui/material'
import { FormikProps } from 'formik'
import { noop } from 'lodash'
import { useState } from 'react'

import { DocumentType, FullProviderFragment, useDocumentUpload, useUpdateProviderMutation } from '@nuna/api'
import { useIsAdmin } from '@nuna/auth'
import { LazyImageUpload, ProfileSection } from '@nuna/common'
import { routeService } from '@nuna/core'
import {
  Avatar,
  AvatarUploadPlaceholder,
  BelowTablet,
  Box,
  Checkbox,
  Grid,
  IconDownload,
  IconInfo,
  Stack,
  TextButton,
  TextButtonExternalLink,
  TextField,
  Tooltip,
  greySet,
  toast,
} from '@nuna/tunic'

import { incompleteSectionNotification } from '../../../utils/util'
import { LicenseTitleSelect } from '../../LicenseTitleSelect'
import { VisibleToClientsLabel } from './VisibleToClientsLabel'

export type PhotoNameTitleProps = Pick<
  FullProviderFragment,
  'firstName' | 'lastName' | 'legalFirstName' | 'legalLastName' | 'licenseTitle' | 'avatarUrl' | 'id' | 'slug'
>
interface NameTitleValues extends Pick<PhotoNameTitleProps, 'firstName' | 'lastName'> {
  legalFirstName: string
  legalLastName: string
  title?: string | null
  useTitle: boolean
}

interface PhotoEditProps {
  isReplacingPhoto: boolean
  setIsReplacingPhoto: (isReplacingPhoto: boolean) => void
}

export function PhotoNameTitle(props: PhotoNameTitleProps) {
  const { uploadDocument, loading: avatarUploading } = useDocumentUpload()
  const [isBeingEdited, setIsBeingEdited] = useState(false)
  const [isReplacingPhoto, setIsReplacingPhoto] = useState(false)
  const [updateProvider, { loading: updateLoading }] = useUpdateProviderMutation()

  const { id, firstName, lastName, legalFirstName, legalLastName, licenseTitle } = props

  const initialValues: NameTitleValues = {
    firstName,
    lastName,
    legalFirstName: legalFirstName ?? '',
    legalLastName: legalLastName ?? '',
    title: licenseTitle,
    useTitle: !!licenseTitle,
  }

  const [isComplete] = incompleteSectionNotification<NameTitleValues>(initialValues, ['firstName', 'lastName'], null)

  const handleUpdateProvider = (values: NameTitleValues) => {
    const variables = Object.assign(
      { id },
      {
        firstName: values.firstName,
        lastName: values.lastName,
        title: values.useTitle ? values.title : null,
        legalFirstName: values.legalFirstName,
        legalLastName: values.legalLastName,
      },
    )
    return updateProvider({ variables }).then(() => setIsBeingEdited(false))
  }

  const handleUpload = async (file: File) => {
    try {
      const document = await uploadDocument(file, DocumentType.ProviderImage, 'provider-image.png')
      if (!document.id) {
        throw new Error()
      }
      await updateProvider({
        // Set null for avatarUrl since we are using a document and the photo will be resolved on the backend
        variables: { id, avatarUrl: null, avatarDocumentId: document.id },
        update: (cache, { data }) => {
          if (data?.updateProvider) {
            cache.modify({
              id: cache.identify(data.updateProvider),
              fields: {
                avatarUrl(cachedAvatarUrl) {
                  // bust the browser cache since the avatarUrl is always the same
                  return `${cachedAvatarUrl}?t=${Date.now()}`
                },
              },
            })
          }
        },
      })
    } catch (e) {
      toast.urgent('There was an issue uploading your photo.')
    }
  }

  return (
    <>
      <Header style={{ display: isReplacingPhoto ? 'block' : 'none' }}>
        <LazyImageUpload
          inputId="avatar-file-upload"
          onFileSelect={() => setIsReplacingPhoto(true)}
          onUploadSuccess={() => setIsReplacingPhoto(false)}
          onCancel={() => setIsReplacingPhoto(false)}
          onError={() => setIsReplacingPhoto(false)}
          onCropped={handleUpload}
          isAvatarUploading={avatarUploading}
        />
      </Header>
      <ProfileSection
        sectionComplete={isComplete}
        style={{ display: !isReplacingPhoto ? 'flex' : 'none' }}
        heading={
          <>
            Photo, Name and Title <VisibleToClientsLabel className="ml-1" />
          </>
        }
        inEditMode={isBeingEdited && !isReplacingPhoto}
        headingShownInEditOnly
        useOutlineSaveButton
        showTopBorder={false}
        description=""
        displayWidth="full"
        updateLoading={updateLoading}
        initialValues={initialValues}
        showEditButton={false}
        handleSubmit={values => handleUpdateProvider(values)}
        disabled={({ isValid }) => !isValid}
        renderDisplayValue={
          <PhotoNameTitleDisplay
            onEditToggle={() => setIsBeingEdited(true)}
            setIsReplacingPhoto={setIsReplacingPhoto}
            isReplacingPhoto={isReplacingPhoto}
            {...props}
          />
        }
        renderForm={formikProps => (
          <PhotoNameTitleEdit
            setIsReplacingPhoto={setIsReplacingPhoto}
            isReplacingPhoto={isReplacingPhoto}
            formikProps={formikProps}
            {...props}
          />
        )}
        validationSchema={Yup.object().shape({
          firstName: Yup.string().required('First Name is required'),
          lastName: Yup.string().required('Last Name is required'),
          legalFirstName: Yup.string()
            .required('Legal First Name is required')
            .typeError('Legal First Name is required'),
          legalLastName: Yup.string().required('Legal Last Name is required').typeError('Legal Last Name is required'),
          useTitle: Yup.boolean().required(),
        })}
        onCancel={() => setIsBeingEdited(false)}
      ></ProfileSection>
    </>
  )
}

interface PhotoNameTitleDisplayProps extends PhotoNameTitleProps {
  onEditToggle?: () => void
  showEditNameButton?: boolean
}

function PhotoNameTitleDisplay({
  firstName,
  lastName,
  licenseTitle,
  avatarUrl,
  slug,
  showEditNameButton = true,
  onEditToggle = noop,
}: PhotoNameTitleDisplayProps & PhotoEditProps) {
  const isAdmin = useIsAdmin()
  const publicProfileLink = slug ? routeService.publicProviderRoute(slug, true) : null

  return (
    <Header>
      <Stack direction={{ xs: 'column', md: 'row' }} sx={{ alignItems: 'center' }} spacing={2}>
        <Box sx={{ maxWidth: 300, minWidth: 150 }}>
          {avatarUrl ? (
            isAdmin ? (
              <DownloadableAvatar url={avatarUrl} />
            ) : (
              <AvatarResponsive url={avatarUrl} size="lg" />
            )
          ) : (
            <AvatarUploadPlaceholder htmlFor="avatar-file-upload" />
          )}
        </Box>

        <div>
          <Name className="h3">
            {firstName} {lastName}
            {licenseTitle && <>, {licenseTitle}</>}
          </Name>

          {!avatarUrl && <Box sx={{ mb: 1 }}>Help your clients connect with you...</Box>}

          <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2} sx={{ alignItems: 'center' }}>
            {avatarUrl ? (
              <TextButtonLabel htmlFor="avatar-file-upload">Replace Photo</TextButtonLabel>
            ) : (
              <TextButtonLabel htmlFor="avatar-file-upload">Add your photo</TextButtonLabel>
            )}
            {showEditNameButton && <TextButton onClick={onEditToggle}>Edit Name and Title</TextButton>}
          </Stack>

          {publicProfileLink && (
            <Stack
              direction={{ xs: 'column', sm: 'row' }}
              sx={{ mt: { xs: 3, sm: 2 }, flexWrap: 'wrap', alignItems: 'center' }}
            >
              <span className="text-bold mr-1">Your Practice Website:</span>

              <Stack direction="row" sx={{ alignItems: 'center' }}>
                <TextButtonExternalLink
                  sx={{ fontSize: { xs: 'caption.fontSize', sm: 'body1.fontSize' } }}
                  href={publicProfileLink}
                  includeReferrer
                >
                  {publicProfileLink}
                </TextButtonExternalLink>
                <Tooltip content="You get paid more per session when you bring your own clients to Tava with this link.">
                  <span>
                    <IconInfo className="ml-1" color={greySet[70].hex} />
                  </span>
                </Tooltip>
              </Stack>
            </Stack>
          )}
        </div>
      </Stack>
    </Header>
  )
}

interface PhotoNameTitleEditProps {
  formikProps: FormikProps<NameTitleValues>
}

function PhotoNameTitleEdit(props: PhotoNameTitleProps & PhotoEditProps & PhotoNameTitleEditProps) {
  const { values, handleChange, handleBlur, touched, errors } = props.formikProps

  return (
    <>
      <PhotoNameTitleDisplay showEditNameButton={false} {...props} />
      <Grid container spacing={2}>
        <Grid
          size={{
            xs: 12,
            md: 6,
          }}
        >
          <TextField
            onChange={handleChange}
            onBlur={handleBlur}
            name="firstName"
            value={values.firstName}
            label="First Name"
            error={(errors.firstName && touched.firstName) as boolean}
            helperText={touched.firstName && errors.firstName ? errors.firstName : null}
          ></TextField>
        </Grid>
        <Grid
          size={{
            xs: 12,
            md: 6,
          }}
        >
          <TextField
            onChange={handleChange}
            onBlur={handleBlur}
            name="lastName"
            value={values.lastName}
            label="Last Name"
            error={(errors.lastName && touched.lastName) as boolean}
            helperText={touched.lastName && errors.lastName ? errors.lastName : null}
          ></TextField>
        </Grid>
        <Grid
          size={{
            xs: 12,
            md: 6,
          }}
        >
          <TextField
            onChange={handleChange}
            onBlur={handleBlur}
            name="legalFirstName"
            value={values.legalFirstName}
            label="Legal First Name"
            error={(errors.legalFirstName && touched.legalFirstName) as boolean}
            helperText={touched.legalFirstName && errors.legalFirstName ? errors.legalFirstName : null}
          ></TextField>
        </Grid>
        <Grid
          size={{
            xs: 12,
            md: 6,
          }}
        >
          <TextField
            onChange={handleChange}
            onBlur={handleBlur}
            name="legalLastName"
            value={values.legalLastName}
            label="Legal Last Name"
            error={(errors.legalLastName && touched.legalLastName) as boolean}
            helperText={touched.legalLastName && errors.legalLastName ? errors.legalLastName : null}
          ></TextField>
        </Grid>
        <Grid
          size={{
            xs: 12,
            md: 12,
          }}
        >
          <Checkbox
            className="mt-2"
            onChange={handleChange}
            onBlur={handleBlur}
            name="useTitle"
            checked={values.useTitle}
          >
            Display my license title to clients (EX: LMFT)
          </Checkbox>
        </Grid>
        {values.useTitle && (
          <Grid
            size={{
              xs: 12,
              md: 12,
            }}
          >
            <LicenseTitleSelect
              onChange={handleChange}
              onBlur={handleBlur}
              name="title"
              value={values.title ?? ''}
              error={(errors.title && touched.title) as boolean}
              helperText={touched.title && errors.title ? errors.title : null}
            />
          </Grid>
        )}
      </Grid>
    </>
  )
}

const responsiveAvatar = `
  height: auto;
  margin: auto;
  max-width: 300px;
  width: 100%;
`

const AvatarResponsive = styled(Avatar)`
  ${responsiveAvatar}
`

// annoyingly, styled components messes up conditional props so it rejects downloadPrompt when added to the jsx but works this way
const AdminAvatarResponsive = styled(Avatar)`
  ${responsiveAvatar}
`
AdminAvatarResponsive.defaultProps = { downloadPrompt: true }

const Name = styled('h1')`
  @media (${BelowTablet}) {
    margin-top: -1rem;
  }
`

const Header = styled('header')`
  padding-top: 2.5rem;
  padding-bottom: 2.5rem;

  @media (${BelowTablet}) {
    padding-bottom: 2rem;
    padding-top: 1.5rem;
    text-align: center;

    h1 {
      font-size: 32px;
    }
  }
`

// using the as prop removes the inner span and makes the hover state cover the text. This is a workaround for that
function TextButtonLabel({ children, htmlFor }: { children: React.ReactNode; htmlFor: string }) {
  return (
    <TextButton as="label" htmlFor={htmlFor}>
      <span style={{ position: 'relative', zIndex: 1 }}>{children}</span>
    </TextButton>
  )
}

function DownloadableAvatar({ url }: { url: string }) {
  const [isHovered, setIsHovered] = useState(false)

  return (
    <DownloadableAvatarContainer
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
      target="_blank"
      rel="noreferrer noopener"
      href={url}
      download
    >
      <AdminAvatarResponsive url={url} size="lg" />
      {isHovered && <IconDownloadHover color="#fff" />}
    </DownloadableAvatarContainer>
  )
}

const DownloadableAvatarContainer = styled('a')`
  position: relative;
  display: inline-flex;
  align-items: center;
  justify-content: center;
`

const IconDownloadHover = styled(IconDownload)`
  pointer-events: none;
  position: absolute;
`
