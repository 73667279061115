import { Maybe, Scalars } from './common.types'

export enum DataCollectionStep {
  AttestationSignature = 'ATTESTATION_SIGNATURE',
  Caqh = 'CAQH',
  CaqhCredentials = 'CAQH_CREDENTIALS',
  Completed = 'COMPLETED',
  ExistingInsurance = 'EXISTING_INSURANCE',
  ProfessionalLiabilityInsurance = 'PROFESSIONAL_LIABILITY_INSURANCE',
  Resume = 'RESUME',
  Started = 'STARTED',
}
export enum CaqhSyncStatus {
  Completed = 'COMPLETED',
  Failed = 'FAILED',
  NotStarted = 'NOT_STARTED',
  Pending = 'PENDING',
  Working = 'WORKING',
}

export enum ProviderCredentialingStatus {
  Archived = 'ARCHIVED',
  CommitteeReview = 'COMMITTEE_REVIEW',
  Credentialed = 'CREDENTIALED',
  CredentialingPacket = 'CREDENTIALING_PACKET',
  CredentialingPacketConcern = 'CREDENTIALING_PACKET_CONCERN',
  DataCollection = 'DATA_COLLECTION',
  DataComplete = 'DATA_COMPLETE',
  Denied = 'DENIED',
  MissingInformation = 'MISSING_INFORMATION',
  VerificationPending = 'VERIFICATION_PENDING',
}

export type CredentialData = {
  acceptsInsurance?: boolean
  attestationSignatureRequestId?: string
  attestationSignedAt?: Date
  attestationSigningUrl?: string
  caqhCompletedAt?: Date
  caqhSyncStartedAt?: Date
  caqhSyncStatus?: CaqhSyncStatus
  currentStep: DataCollectionStep
  currentStepUpdatedAt?: Date
  existingInsurance: string
  id: string
  providerId: string
  resumeDocumentId?: string
}

export type ProviderCredentialing = {
  __typename?: 'ProviderCredentialing'
  createdAt: Scalars['Date']
  id: Scalars['ID']
  initialCredentialedDate?: Maybe<Scalars['Date']>
  providerId: Scalars['ID']
  recredentialDueDate?: Maybe<Scalars['Date']>
  recredentialedDate?: Maybe<Scalars['Date']>
  status: ProviderCredentialingStatus
  updatedAt: Scalars['Date']
}
