import { ReactNode } from 'react'

export interface FAQ {
  question: string
  answer: ReactNode
}

export const faqItems = [
  {
    question: 'What are the benefits of working with Tava?',
    answer: (
      <>
        <p>
          Tava is a virtual mental health platform on a mission to break down the barriers to care and lighten the load
          of therapists like you by handling all things admin work and sending you referrals. All you have to do is what
          you do best: provide excellent therapy.
        </p>
        <p>Therapists love working with us because you get:</p>
        <ul>
          <li>Flexible schedule you control</li>
          <li>Referrals based on your specifications</li>
          <li>No admin work</li>
          <li>Collaborative community</li>
          <li>HIPPA compliant virtual office</li>
        </ul>
        <p>
          Tava can give you complete autonomy and flexibility over your schedule while helping you grow and manage your
          caseload
        </p>
      </>
    ),
  },
  {
    question: `How much does it cost to use Tava's teletherapy tools?`,
    answer: (
      <>
        <p>
          $0. Tava gives therapists the tools they need to manage their practice for free. This is because facilitating
          therapy is crucial for the other part of our mission, which is to increase access to therapy to underserved
          populations that are generally met with the most barriers to care.
        </p>
        <p>Every therapist who uses Tava is given a suite of free tools that include:</p>
        <ul>
          <li>Easy client documentation system</li>
          <li>Schedule and synced calendar</li>
          <li>Video software</li>
          <li>Personal booking website with SEO boosters</li>
          <li>Client intake, scheduling, and reminders</li>
          <li>Automated claim submission, billing, and collections</li>
        </ul>
      </>
    ),
  },
  {
    question: 'Who can work with Tava?',
    answer: (
      <>
        <p>We work with fully licensed therapists in all 50 states.</p>
        <p>
          Because all Tava sessions are virtual, you'll need a laptop or desktop computer equipped with a camera,
          microphone, and reliable internet.
        </p>
      </>
    ),
  },
  {
    question: 'Can Tava send me new clients?',
    answer: (
      <p>
        Yes. We have thousands of new clients all over the US looking for their therapist. You show us the hours you're
        looking to fill and we will get you in front of well-matched clients.
      </p>
    ),
  },
  {
    question: 'Where do Tava clients come from?',
    answer: (
      <p>
        Tava has a large EAP program with employer partners and charitable organizations across the country. We also
        accept credit cards and health insurance with major and local insurance carriers.
      </p>
    ),
  },
  {
    question: 'Can I bring my own clients to Tava?',
    answer: (
      <p>
        Yes. Inviting your own clients is as easy as entering their email. They'll be invited to schedule with you via
        Tava's patient portal. This way you can hold sessions with and store documentation for all your clients in one
        place without having to manage multiple systems.
      </p>
    ),
  },
  {
    question: 'Can I get paneled through Tava to accept insurance clients?',
    answer: (
      <p>
        Yes. We take the hassle and stress out of credentialing and working with insurance providers. Once we get you
        credentialed and enrolled with payers, we also handle claims, billing, and collections. We also boost the online
        presence of paneled providers through free ZocDoc and Insurance Directory profiles.
      </p>
    ),
  },
  {
    question: 'Will I have to take clients during set hours?',
    answer: (
      <p>
        Nope, you get to set your own availability and there are no minimum hour requirements. 100s of therapists have
        used Tava to gain more flexibility and control over their schedule for better work-life balance.
      </p>
    ),
  },
  {
    question: 'How much will I be reimbursed?',
    answer: (
      <p>
        Rates vary by state, licensure level, and length of session. Click the chat button in the bottom right to set up
        a conversation with us to learn what rates you're eligible for. Tava reimburses bi-weekly for documented
        sessions.
      </p>
    ),
  },
  {
    question: 'Can I set my own cash rate?',
    answer: <p>Yes, you set your own cash pay rates. Most providers charge $110-$150 per 53 minute session.</p>,
  },
  {
    question: 'What are other providers saying about Tava?',
    answer: (
      <>
        <p>
          "Working for Tava Health is like having a private practice without the hassles of being a business owner."
          <div className="mt-1">- Nancy, LCSW at Tava</div>
        </p>
        <p className="mt-2">
          "I love working with Tava. Clinicians here are all warmly welcomed into a diverse therapist community that
          supports each other."
          <div className="mt-1">- Erik, Licensed Psychologist at Tava</div>
        </p>
      </>
    ),
  },
  {
    question: 'Can I talk to someone at Tava to learn more?',
    answer: (
      <p>
        Yes. We’d love to chat and walk you through a tour of our platform to see if it would be a good fit for you.
        Just hit that chat button in the bottom right of your screen and we’ll set up a time.
      </p>
    ),
  },
] as FAQ[]
