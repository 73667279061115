import { styled } from '@mui/material'
import moment from 'moment'
import { useEffect, useMemo, useState } from 'react'

import { ConversationMessageFragment } from '@nuna/api'
import { type Persona } from '@nuna/core'
import { toast } from '@nuna/tunic'

import { useConversationContext } from '../../context/ConversationContext'
import { useConversationUnreadContext } from '../../context/ConversationUnreadContext'
import { ConversationDetailComposer } from './components/ConversationDetailComposer'
import { ConversationInfiniteScroll } from './components/ConversationInfiniteScroll'
import { ConversationMessage } from './components/ConversationMessage'
import { useMarkConversationMessageAsRead } from './hooks/useMarkConversationMessageAsRead'
import { usePaginatedMessages } from './hooks/usePaginatedMessages'

interface ConversationDetailProps {
  audience: Persona
}

export function ConversationDetail(props: ConversationDetailProps) {
  const { activeConversationId } = useConversationContext()
  if (activeConversationId) {
    return (
      <ConversationDetailLoaded
        key={activeConversationId} // ensures re-render when it changes
        conversationId={activeConversationId}
        {...props}
      />
    )
  }

  return null
}

interface ConversationDetailLoadedProps extends ConversationDetailProps {
  conversationId: string
}

export function ConversationDetailLoaded({ conversationId, audience }: ConversationDetailLoadedProps) {
  const {
    messages,
    loadNewerPage,
    loadOlderPage,
    loading,
    hasNewer,
    hasOlder,
    initialLastReadMessage,
    loadUnreadMessages,
  } = usePaginatedMessages(conversationId, undefined, 10)

  const { hasUnread } = useConversationUnreadContext()

  const shouldFetchNewMessages = hasUnread(conversationId)

  useEffect(() => {
    if (shouldFetchNewMessages) {
      try {
        loadUnreadMessages()
      } catch (e) {
        console.error(e)
        toast.urgent('Unable to load latest messages')
      }
    }
  }, [loadUnreadMessages, shouldFetchNewMessages])

  const newestFirstMessages = useMemo(() => [...messages].reverse(), [messages])
  const { lastReadMessage, updateLastReadMessage } = useMarkConversationMessageAsRead(initialLastReadMessage)
  const [recentlySentMessages, setRecentlySentMessages] = useState<ConversationMessageFragment[]>([])

  // if a poll has resulted in fetching a message from the server that was previuosly in the recentlySent array, filter it out
  const [filteredRecentlySentMessages, filteredRecentlySentMessagesIds] = useMemo(() => {
    const loadedMessageIds = newestFirstMessages.map(message => message.id)
    const filteredMessages = recentlySentMessages.filter(message => !loadedMessageIds.includes(message.id))

    return [filteredMessages, filteredMessages.map(message => message.id)]
  }, [recentlySentMessages, newestFirstMessages])

  return (
    <Container>
      <div className="mb-2">
        <ConversationDetailComposer
          conversationId={conversationId}
          audience={audience}
          onMessageSent={message => setRecentlySentMessages([message, ...recentlySentMessages])}
        />
      </div>

      <ConversationInfiniteScroll
        items={[...filteredRecentlySentMessages, ...newestFirstMessages]}
        loadNext={loadNewerPage}
        loadPrev={loadOlderPage}
        loading={loading}
        hasNext={hasNewer}
        hasPrev={hasOlder}
      >
        {messages =>
          messages.map((message, index) => (
            <ConversationMessage
              key={message.id}
              message={message}
              disableMarkAsRead={filteredRecentlySentMessagesIds.includes(message.id)}
              audience={audience}
              isUnread={lastReadMessage ? moment(message.updatedAt).isAfter(moment(lastReadMessage.updatedAt)) : true}
              isInitialLastReadMessage={
                initialLastReadMessage ? message.id === initialLastReadMessage.id && index !== 0 : false
              }
              onView={updateLastReadMessage}
            />
          ))
        }
      </ConversationInfiniteScroll>
    </Container>
  )
}

const Container = styled('div')`
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
`
