import { styled } from '@mui/material'
import { noop } from 'lodash'
import { HTMLAttributes, ReactNode, useState } from 'react'

import { paymentMethodService } from '@nuna/core'
import {
  Card,
  GhostButton,
  IconCreditCard,
  IconDivider,
  IconPlus,
  IconSwitchRole,
  PlaceholderButton,
  Radio,
  RadioGroup,
  borderGrey,
  csx,
  tealSet,
} from '@nuna/tunic'

import { CreditCard, CreditCardSelectChange } from '../../types'
import { CreditCardForm } from '../CreditCardForm'
import { DefaultCreditCardValueDisplay } from './DefaultCreditCardValueDisplay'
import { SelectedCardDisplayProps } from './types'

export interface CreditCardManagerProps extends Omit<HTMLAttributes<HTMLDivElement>, 'onChange'> {
  value?: CreditCard | null
  cards: CreditCard[]
  onChange?: (change: CreditCardSelectChange) => Promise<void> | void
  showHSAWarning?: boolean
  getSelectedCardDisplay?: (props: SelectedCardDisplayProps) => ReactNode
  helperText?: string | ReactNode
  error?: boolean
  selectHeadingText?: ReactNode
  patientid?: string
  defaultToFormIfEmpty?: boolean
  hideCancelOnForm?: boolean
  date?: Date
}

export function CreditCardSelect({
  date,
  value,
  showHSAWarning,
  onChange = noop,
  cards,
  getSelectedCardDisplay = (cardDisplayProps: SelectedCardDisplayProps) => (
    <DefaultCreditCardValueDisplay {...cardDisplayProps} />
  ),
  error = false,
  helperText,
  selectHeadingText = 'Set your new default',
  patientid,
  defaultToFormIfEmpty = false,
  hideCancelOnForm = false,
  ...props
}: CreditCardManagerProps) {
  const [showForm, setShowForm] = useState(defaultToFormIfEmpty && cards.length === 0 ? true : false)
  const [showCardSelect, setShowCardSelect] = useState(false)
  const isSelectedCardExpired = !paymentMethodService.isCardValid(value, date)

  const handleCardAdded = async (change: CreditCardSelectChange) => {
    await onChange(change)
    setShowCardSelect(false)
    setShowForm(false)
  }

  const handleCardSwitch = async (card: CreditCard) => {
    await onChange({ stripePaymentMethodId: card.stripePaymentMethodId, isHsa: card.isHsa, id: card.id })
    setShowCardSelect(false)
    setShowForm(false)
  }

  if (showForm) {
    return (
      <>
        <CreditCardForm onSuccess={handleCardAdded} showHSACheckbox patientId={patientid} />
        <CancelRow className="v-align mt-1" leftAlign={cards.length === 0}>
          {!hideCancelOnForm && (
            <span className="cancel-button-container">
              <GhostButton
                variant="secondary"
                onClick={() => {
                  setShowForm(false)
                  setShowCardSelect(false)
                }}
              >
                Cancel
              </GhostButton>
            </span>
          )}
          {cards.length > 0 && (
            <span className={csx([{ 'left-border': !hideCancelOnForm }, 'cancel-button-container'])}>
              <GhostButton variant="secondary" className="v-align" onClick={() => setShowForm(false)}>
                <IconSwitchRole size={18} className="mr-1" /> Use a different card
              </GhostButton>
            </span>
          )}
        </CancelRow>
      </>
    )
  }

  return (
    <div {...props}>
      {value && getSelectedCardDisplay({ card: value, showHSAWarning, isExpired: isSelectedCardExpired })}
      {cards.length === 0 && <AddOrUpdateCardButton className="mt-1" onClick={() => setShowForm(true)} />}
      {!value && cards.length > 0 && (
        <AddOrUpdateCardButton className="mt-1" onClick={() => setShowCardSelect(true)} type="select" />
      )}
      {(() => {
        if (showCardSelect) {
          return (
            <SwitchCardCard depth={-1} className="mt-1">
              <div className="text-medium header px-2 pt-2">{selectHeadingText}</div>
              <RadioGroup>
                {cards.map(card => {
                  const cardDescription = `${card.isHsa ? 'HSA ' : ''}${card.description}`
                  return (
                    <div key={card.id} className="card-row p-2">
                      <Radio
                        checked={card.id === value?.id}
                        onChange={() => handleCardSwitch(card)}
                        className="radio"
                        disabled={!paymentMethodService.isCardValid(card)}
                      >
                        {cardDescription} ending in {card.accountLastFour}{' '}
                        {!paymentMethodService.isCardValid(card) ? '(expired)' : ''}
                      </Radio>
                    </div>
                  )
                })}
              </RadioGroup>
              <div className="p-2">
                <button type="button" className="v-align add-new-button" onClick={() => setShowForm(true)}>
                  <IconPlus size={18} className="mr-1-5" /> Add a new card
                </button>
              </div>
            </SwitchCardCard>
          )
        }

        return (
          <>
            {value && (
              <AddOrUpdateCardButton
                className="mt-1"
                type={isSelectedCardExpired ? 'update' : 'switch'}
                onClick={() => setShowCardSelect(true)}
              />
            )}
            <div className={csx([{ 'text-error': error }, 'caption text-medium italic mt-1'])}>{helperText}</div>
          </>
        )
      })()}
    </div>
  )
}

interface AddOrUpdateCardButtonProps extends HTMLAttributes<HTMLButtonElement> {
  type?: 'add' | 'update' | 'select' | 'switch'
}
function AddOrUpdateCardButton({ type = 'add', ...props }: AddOrUpdateCardButtonProps) {
  return (
    <PlaceholderButton type="button" className="v-align space-between" {...props}>
      {type === 'add' && (
        <span className="v-align">
          <IconCreditCard className="mr-1" /> Add a Credit Card
          <IconDivider className="ml-auto" size={18} />
        </span>
      )}
      {['update', 'select', 'switch'].includes(type) && (
        <>
          <span className="v-align">
            <IconSwitchRole size={18} className="mr-1" />{' '}
            {type === 'update' ? `Update my card` : type === 'select' ? 'Select a card' : 'Use a different card'}
          </span>
          <span></span>
        </>
      )}
    </PlaceholderButton>
  )
}

const CancelRow = styled('div')<{ leftAlign: boolean }>`
  justify-content: ${props => (props.leftAlign ? 'flex-start' : 'center')};
  .cancel-button-container {
    padding: var(--spacing-1) var(--spacing-2);
    &.left-border {
      border-left: 1px solid ${borderGrey};
    }
  }
`

const SwitchCardCard = styled(Card)`
  .header {
    color: ${tealSet[80].hex};
  }

  .card-row {
    border-bottom: 1px solid ${borderGrey};
    .radio {
      margin-bottom: 0;
    }
  }

  .add-new-button {
    color: ${tealSet[70].hex};
  }
`
