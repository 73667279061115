import { DailyEventObjectAppMessage } from '@daily-co/daily-js'
import { useDaily } from '@daily-co/daily-react'
import { useCallback, useEffect, useState } from 'react'

import { DailyMessage, useDailyMessage } from '../../hooks/useDailyMessage'
import { TavaVideoUser } from '../../types'

export function useEndSessionContext(localUser: TavaVideoUser) {
  const callObject = useDaily()
  const [isSessionEnded, setIsSessionEnded] = useState(false)

  useEffect(() => {
    if (isSessionEnded) {
      callObject?.leave()
    }
  }, [callObject, isSessionEnded])

  const handleMessageReceived = useCallback(
    (e: DailyEventObjectAppMessage<DailyMessage>) => {
      if (e.data.type === 'session-ended') {
        setIsSessionEnded(true)
      }
    },
    [setIsSessionEnded],
  )

  const sendDailyMessage = useDailyMessage(handleMessageReceived)

  const endSession = useCallback(() => {
    setIsSessionEnded(true)

    if (localUser.isProvider) {
      sendDailyMessage({ type: 'session-ended', endTime: new Date().toISOString() })
    }
  }, [sendDailyMessage, localUser])

  return { isSessionEnded, endSession }
}

export type EndSessionContextValues = ReturnType<typeof useEndSessionContext>
