import { styled } from '@mui/material'

import { yellowSet } from '../styles/colorSets'
import { body2, error } from '../styles/colors'

export const HelperText = styled('p')<{ error: boolean; urgent?: boolean }>`
  color: ${body2};
  display: flex;
  font-size: 12px;
  line-height: 1.4;
  margin: 5px 0 0;

  ${props => props.urgent && `color: ${yellowSet[80].hex};`}
  ${props => props.error && `color: ${error};`}
`
