import { styled } from '@mui/material'

import { salmonSet } from '@nuna/tunic'

export const UnreadBadge = styled('span')`
  width: 12px;
  height: 12px;
  border-radius: 12px;
  background-color: ${salmonSet[50].hex};
`
