import { isNil } from 'lodash'
import moment, { Moment } from 'moment'

interface CreditCard {
  expirationDate?: string | null | Date | Moment
}

function isCardValid(card?: CreditCard | null, onDate?: Date) {
  if (isNil(card)) {
    return false
  }

  const expiration = moment(card.expirationDate)
  if (!expiration.isValid()) {
    return false
  }

  return expiration.isAfter(moment(onDate))
}

function maskCardNumber(number: string | number = '', maskLength = 19) {
  const asString = number.toString()
  if (asString.length <= 4) {
    return asString.padStart(maskLength, '•••• •••• •••• ')
  }
  return number
}

export const paymentMethodService = {
  isCardValid,
  maskCardNumber,
}
