import { noop } from 'lodash'

import { specialtiesService } from '@nuna/core'

import { SpecialtiesSectionBase, SpecialtiesSectionProps } from './SpecialtiesSectionBase'

export function PopulationSection({ specialties, id, refetch = noop }: SpecialtiesSectionProps) {
  return (
    <SpecialtiesSectionBase
      headingText="Client Focus - Population"
      description="We'll use your selection to match you with your ideal clients. Though you may be comfortable treating all types of people, please select those that you are uniquely experienced with helping."
      selectedSpecialties={specialties}
      specialtiesFilter={specialtiesService.getProviderPopulationSpecialtiesExludingGender}
      refetch={refetch}
      providerId={id}
      min={0}
    />
  )
}
