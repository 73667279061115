import { Location, matchPath, useLocation, useSearchParams } from 'react-router-dom'

import { AccountSource, AccountSourceChannel } from '@nuna/api'
import { SessionStorage, routeService, sessionStorageService } from '@nuna/core'

enum KNOWN_SOURCES {
  CLIENT = 'client',
  PSYCHOLOGY_TODAY = 'psychology-today',
  ITERABLE = 'iterable',
}
const { setSignupSourceParams } = sessionStorageService

function mapLocationToSource({ location, source }: { location: Location; source: string | null }): AccountSource {
  // We send clients to the p4 page from psych today
  if (source === KNOWN_SOURCES.PSYCHOLOGY_TODAY) {
    return AccountSource.PublicProviderProfilePage
  }

  if (!source && matchPath(routeService.clientsInvite, location.pathname)) {
    // These will probably not always be the best way to determine what the source is
    return AccountSource.CareNavigator
  }

  if (!source && matchPath(routeService.publicProviderPattern, location.pathname)) {
    return AccountSource.PublicProviderProfilePage
  }

  if (!source && matchPath(routeService.signup, location.pathname)) {
    return AccountSource.TavaHealth
  }

  // only send known sources
  const accountSource = Object.values(AccountSource).find(s => s.toLocaleLowerCase() === source?.toLocaleLowerCase())

  return accountSource || AccountSource.TavaHealth
}

function mapLocationToSourceChannel({
  source,
  channel,
}: {
  location: Location
  source: string | null
  channel: string | null
}): AccountSourceChannel | undefined {
  if (source?.toLocaleLowerCase() === KNOWN_SOURCES.PSYCHOLOGY_TODAY) {
    return AccountSourceChannel.PsychologyToday
  }

  if (source?.toLocaleLowerCase() === KNOWN_SOURCES.CLIENT) {
    return AccountSourceChannel.ClientReferral
  }

  if (source?.toLocaleLowerCase() === KNOWN_SOURCES.ITERABLE) {
    return AccountSourceChannel.Other
  }

  // only send known channels
  const accountSourceChannel = Object.values(AccountSourceChannel).find(
    s => s.toLocaleLowerCase() === channel?.toLocaleLowerCase(),
  )

  return accountSourceChannel
}

function mapSource(source: string | null): AccountSource | undefined {
  return Object.values(AccountSource).find(s => s.toLocaleLowerCase() === source?.toLocaleLowerCase())
}

export function useSourceReferralSearchParams() {
  const location = useLocation()
  const [searchParams] = useSearchParams()
  const source = searchParams.get('source') || searchParams.get('utm_source')
  const channel = searchParams.get('channel')

  const sourceChannel = mapLocationToSourceChannel({ location, source, channel })
  const isProviderSourced = !sourceChannel || sourceChannel === AccountSourceChannel.Provider
  setSignupSourceParams({
    source: mapSource(source) as AccountSource,
    channel: sourceChannel,
    isProviderSourced: isProviderSourced.toString() as SessionStorage['tavaSignupSourceParams']['isProviderSourced'],
  })

  return {
    isProviderSourced,
    source: mapLocationToSource({ location, source }),
    sourceChannel,
  }
}
