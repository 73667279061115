import daily from '@daily-co/daily-js'
import { useDailyError, useDevices } from '@daily-co/daily-react'
import { useEffect } from 'react'

import { supportsAudioProcessing as tavaSupportsAudioProcessing } from '../util/browserSupport'

const { supportsVideoProcessing, supportsAudioProcessing } = daily.supportedBrowser()

export function useDebugLogging() {
  const { meetingError, nonFatalError } = useDailyError()
  const { cameraError, hasMicError, micState, camState } = useDevices()

  useEffect(() => {
    console.info('[Daily Debug] Video processing supported:', supportsVideoProcessing)
    console.info('[Daily Debug] Audio processing supported:', supportsAudioProcessing)
    console.info('[Daily Debug] Audio processing supported (Tava Wrapped):', tavaSupportsAudioProcessing)
  }, [])

  useEffect(() => {
    console.info('[Daily Debug] Camera state:', camState)
  }, [camState])

  useEffect(() => {
    console.info('[Daily Debug] Microphone state:', micState)
  }, [micState])

  useEffect(() => {
    if (!meetingError) return

    console.error('[Daily Error] Meeting error:', meetingError)
  }, [meetingError])

  useEffect(() => {
    if (!nonFatalError) return

    console.error('[Daily Error] Non-fatal error:', nonFatalError)
  })

  useEffect(() => {
    if (!cameraError) return

    console.error('[Daily Error] Camera error:', cameraError)
  }, [cameraError])

  useEffect(() => {
    if (!hasMicError) return

    console.error('[Daily Error] Mic error:', micState)
  }, [hasMicError, micState])
}
