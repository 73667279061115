import { IconProps } from './IconProps'

export function IconMedication({ size = 24, color = 'currentColor', ...props }: IconProps) {
  const height = (21 / 22) * size

  return (
    <svg width={size} height={height} viewBox="0 0 22 21" fill={color} xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M18.682 2.81821C16.9246 1.06085 14.0754 1.06085 12.318 2.81821L3.30241 11.8338C1.54505 13.5912 1.54505 16.4404 3.30241 18.1978C5.05977 19.9551 7.90901 19.9551 9.66637 18.1978L18.682 9.18217C20.4393 7.42481 20.4393 4.57557 18.682 2.81821ZM4.36307 12.8945L8.87639 8.38116L13.119 12.6238L8.60571 17.1371C7.43414 18.3087 5.53464 18.3087 4.36307 17.1371C3.1915 15.9655 3.19149 14.0661 4.36307 12.8945ZM11.326 7.50066C11.5456 7.72033 11.9018 7.72032 12.1215 7.50066L14.4736 5.14856C14.8733 4.74885 15.1396 4.54299 15.349 4.45343C15.5112 4.38404 15.6491 4.37816 15.8608 4.47334C16.1441 4.60071 16.4771 4.47427 16.6045 4.19093C16.7318 3.90758 16.6054 3.57462 16.3221 3.44725C15.8436 3.23218 15.3777 3.2176 14.9066 3.41906C14.4827 3.60034 14.0863 3.94486 13.6781 4.35307L11.326 6.70516C11.1063 6.92483 11.1063 7.28099 11.326 7.50066ZM9.79896 12.7104C10.0186 12.4907 10.3748 12.4907 10.5945 12.7104C10.8141 12.9301 10.8141 13.2862 10.5945 13.5059L8.24236 15.858C7.83415 16.2662 7.43769 16.6107 7.01381 16.792C6.54276 16.9934 6.07677 16.9789 5.59836 16.7638C5.31502 16.6364 5.18858 16.3035 5.31595 16.0201C5.44333 15.7368 5.77629 15.6103 6.05963 15.7377C6.27134 15.8329 6.40919 15.827 6.57144 15.7576C6.78086 15.6681 7.04715 15.4622 7.44686 15.0625L9.79896 12.7104Z"
      />
    </svg>
  )
}
