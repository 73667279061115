import { Navigate, Route, Routes, useNavigate } from 'react-router-dom'

import { PrivateRoute } from '@nuna/auth'
import { ClientDetail } from '@nuna/client'
import { routeService } from '@nuna/core'
import { CustomerDetail } from '@nuna/customer'
import { ConversationContextProvider, ConversationParticipantLookup } from '@nuna/messaging'
import { CreateSessionNotes, EditSessionNotes } from '@nuna/session-notes'

import { NavLayout } from '../layouts/NavLayout'
import { FourOhFour } from '../scenes/404/404'
import { AppointmentDetail } from '../scenes/Appointment/AppointmentDetail'
import { BackgroundCheck } from '../scenes/BackgroundCheck/components/BackgroundCheck'
import { CAQHSetupWrapper } from '../scenes/CAQHSetupWrapper'
import { PatientIndex } from '../scenes/Patients/PatientList/PatientIndex'
import { PatientToClientRedirect } from '../scenes/Patients/PatientToClientRedirect'
import { ProviderMessages } from '../scenes/ProviderMessages/ProviderMessages'
import { ProviderAvailability } from '../scenes/Schedule/ProviderAvailability/ProviderAvailability'
import { ProviderScheduleScene } from '../scenes/Schedule/ProviderScheduleScene'
import { SessionIndex, SessionIndexTabs } from '../scenes/Sessions/SessionIndex'
import { Intake } from '../scenes/SignupIntake/Intake'
import { StandaloneAvailability } from '../scenes/StandaloneAvailability/StandaloneAvailability'
import { ProviderVideo } from '../scenes/Video/ProviderVideo'
import { ProviderVideoRedirect } from '../scenes/Video/ProviderVideoRedirect'
import { SupportWelcome } from '../scenes/signup/SupportWelcome'
import { TherapistNavBar } from '../shared/NavBar/TherapistNavBar'
import { ProviderAppContextProvider } from '../shared/ProviderAppContext'
import { overlappingSessions, sessionIndex, signupIntake, signupIntakeSupportWelcome } from '../util/routes'

const { conversationsBaseRoute, conversationsParticipantFullPath } = routeService

interface Props {
  homeUrl: string
}

export function AuthenticatedApp({ homeUrl }: Props) {
  const navigate = useNavigate()
  return (
    <ProviderAppContextProvider>
      <Routes>
        <Route path={signupIntakeSupportWelcome} element={<SupportWelcome />} />

        <Route path={`${signupIntake}/*`} element={<Intake />} />
        <Route path={`${routeService.caqhSetup()}/*`} element={<CAQHSetupWrapper />} />
        <Route path={routeService.backgroundCheckRoute} element={<BackgroundCheck />} />
        <Route path={routeService.standaloneAvailability} element={<StandaloneAvailability />} />

        {/* Private routes are outletted by NavLayout, which wraps the Outlet in PrivateRoute, which does the auth check */}
        <Route path="/" element={<NavLayout />}>
          <Route index element={<Navigate to={homeUrl} />} />

          {/* Sessions */}
          <Route path="/sessions/view/:selectedTab" element={<SessionIndex />} />
          <Route
            path="/sessions/new"
            element={
              <CreateSessionNotes
                onMarkedNoShowRedirectLocation={() => sessionIndex(SessionIndexTabs.PREVIOUS)}
                onPublishRedirectLocation={id => routeService.sessionDetailRoute(id)}
                onCancelRedirectLocation={() => sessionIndex(SessionIndexTabs.PREVIOUS)}
              />
            }
          />
          <Route
            path="/sessions/new/:appointmentId"
            element={
              <CreateSessionNotes
                onMarkedNoShowRedirectLocation={() => sessionIndex(SessionIndexTabs.PREVIOUS)}
                onPublishRedirectLocation={id => routeService.sessionDetailRoute(id)}
                onCancelRedirectLocation={() => sessionIndex(SessionIndexTabs.PREVIOUS)}
              />
            }
          />
          <Route
            path="/sessions/:id"
            element={<EditSessionNotes onCancelRedirectLocation={() => sessionIndex(SessionIndexTabs.PREVIOUS)} />}
          />

          {/* Patients */}
          <Route path="/clients" element={<PatientIndex />} />
          <Route path="/patients" element={<Navigate to="/clients" replace />} />
          <Route path="/clients/:id/*" element={<ClientDetail />} />
          <Route path="/patients/*" element={<PatientToClientRedirect />} />

          {/* Customer */}
          <Route path="/customer/integrations/*" element={<CustomerDetail />} />
          <Route path="/customers" element={<Navigate to="/customers/integrations" />} />

          {/* Schedule */}
          <Route path="/schedule/*" element={<ProviderScheduleScene />}>
            <Route path=":appointmentId" element={<ProviderScheduleScene />} />
          </Route>
          <Route path={routeService.noCurrentSessionRoute()} element={<ProviderScheduleScene />} />
          <Route path={overlappingSessions()} element={<ProviderScheduleScene />} />
          <Route path="/availability" element={<ProviderAvailability />} />

          {/* Me Menu Pages */}
          <Route path="/me/*" element={<TherapistNavBar />} />
          <Route path="/profile" element={<Navigate replace to="/me/profile" />} />
          <Route path="/payouts" element={<Navigate replace to="/me/payouts" />} />

          {/* Appointment */}
          <Route path="/appointment/:appointmentId" element={<AppointmentDetail />} />

          {/* Messages */}
          <Route
            path={`${conversationsBaseRoute}/*`}
            element={
              <ConversationContextProvider
                canHideConversations
                onConversationHidden={(isActiveConversation: boolean) => {
                  if (isActiveConversation) {
                    navigate(conversationsBaseRoute)
                  }
                }}
              >
                <ProviderMessages />
              </ConversationContextProvider>
            }
          />

          <Route path={`${conversationsParticipantFullPath}`} element={<ConversationParticipantLookup />} />
        </Route>

        {/* Video */}
        <Route path="/live-session" element={<PrivateRoute />}>
          <Route index element={<ProviderVideoRedirect />} />
          <Route path=":appointmentId" element={<ProviderVideo />} />
        </Route>

        {/* Catch-all */}
        <Route path="*" element={<FourOhFour />} />
      </Routes>
    </ProviderAppContextProvider>
  )
}
