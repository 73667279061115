import mixpanel from 'mixpanel-browser'
import { useEffect, useMemo, useState } from 'react'

import { Checkbox, CheckboxProps, toast } from '@nuna/tunic'

import { useSyncedDevicePreferencesUpdate } from '../hooks/useSyncedDevicePreferencesUpdate'
import { PersistedAudioDevicePreferences } from '../types'
import { supportsAudioProcessing } from '../util/browserSupport'
import { getDevicePreferences } from '../util/devicePreferences'

export function NoiseCancelation(props: CheckboxProps) {
  const [isEnabled, setIsEnabled] = useState(getDevicePreferences().isNoiseCancelationEnabled)

  const syncedPreferences = useMemo<PersistedAudioDevicePreferences>(
    () => ({
      isNoiseCancelationEnabled: isEnabled,
    }),
    [isEnabled],
  )

  const { dailyInputError } = useSyncedDevicePreferencesUpdate(syncedPreferences)

  useEffect(() => {
    if (!dailyInputError) return

    mixpanel.track('Noise cancelation error', { error: dailyInputError })
    toast.info('Noise cancelation could not be started. It will be unavailable.')
    console.error(dailyInputError)
    setIsEnabled(false)
  }, [dailyInputError])

  const handleNoiseCancelationChange = () => {
    mixpanel.track('Noise cancelation toggled', { enabled: !isEnabled })
    setIsEnabled(!isEnabled)
  }

  return (
    <Checkbox
      {...props}
      checked={isEnabled}
      onChange={handleNoiseCancelationChange}
      disabled={!!dailyInputError || !supportsAudioProcessing}
    >
      Noise cancelation
    </Checkbox>
  )
}
