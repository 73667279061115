import { styled } from '@mui/material'
import moment from 'moment'
import { MouseEventHandler } from 'react'
import { useMatch } from 'react-router-dom'

import { AppointmentForNotificationFragment } from '@nuna/api'
import { routeService } from '@nuna/core'
import { Dialog, DialogProps, IconCalendarNone, OutlineButton, TextButton, greySet, tealSet } from '@nuna/tunic'

export interface NoCurrentSessionModalProps extends Omit<DialogProps, 'isOpen'> {
  loading: boolean
  appointment: AppointmentForNotificationFragment | null | undefined
  onHelpClick: MouseEventHandler<HTMLButtonElement>
}

export function NoCurrentSessionModal({
  loading,
  appointment,
  onClose,
  onHelpClick,
  ...props
}: NoCurrentSessionModalProps) {
  const noCurrentSessionMatch = useMatch(routeService.noCurrentSessionRoute())

  if (loading) {
    return null
  }

  const { heading, time } = appointment
    ? {
        heading: "Your next session doesn't start until",
        time: moment(appointment.startDatetime).calendar(null, {
          sameDay: '[Today at] h:mma',
          nextDay: '[Tomorrow at] h:mma',
          nextWeek: 'MMM Do [at] h:mma',
          sameElse: 'MMM Do [at] h:mma',
        }),
      }
    : {
        heading: 'Oops, sorry...',
        time: "You don't have an upcoming appointment scheduled",
      }

  return (
    <NoCurrentSessionDialog
      {...props}
      isOpen={!!noCurrentSessionMatch}
      onClose={(e, reason) => {
        if (reason === 'backdropClick') {
          return
        }

        onClose && onClose(e, reason)
      }}
    >
      <NoSessionIndicator>
        <IconCalendarNone size={56} />
      </NoSessionIndicator>

      <p className="mt-2 text-light">{heading}</p>
      <h3 className="h4 mt-0 mb-3">{time}</h3>

      <OutlineButton
        variant="dark"
        onClick={() => {
          onClose && onClose({}, 'escapeKeyDown')
        }}
      >
        Got it, Thanks.
      </OutlineButton>

      <p className="mt-3 text-light">
        Something seem off?{' '}
        <TextButton scheme="dark" onClick={onHelpClick}>
          Reach Out
        </TextButton>
      </p>
    </NoCurrentSessionDialog>
  )
}

const NoCurrentSessionDialog = styled(Dialog)`
  .MuiPaper-root {
    background: ${tealSet[90].hex};
    border: none;
    color: #fff;
    padding: 1rem;
    text-align: center;
  }
`

const NoSessionIndicator = styled('div')`
  background: ${tealSet[100].hex};
  border: 1px solid ${greySet[90].hex};
  border-radius: 12px;
  color: ${greySet[50].hex};
  height: 173px;
`
NoSessionIndicator.defaultProps = { className: 'vh-align' }
