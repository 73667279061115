import { useNavigate } from 'react-router-dom'

import { SaveProviderCredentialingDataMutation, useSaveProviderCredentialingDataMutation } from '@nuna/api'
import { isMobile } from '@nuna/common'
import { errorService, routeService } from '@nuna/core'
import {
  GhostButtonLink,
  IconChevronThick,
  IntakeFormStepper,
  IntakeFormStepperProps,
  OutlineButtonLink,
  OutlineButtonWithChevron,
  toast,
} from '@nuna/tunic'

import { useCAQHSetupContext } from '../../context/CAQHSetupContext'
import { useCAQHNavigation } from '../../hooks/useCAQHNavigation'

interface Props extends Omit<IntakeFormStepperProps, 'previousPath' | 'mutationLoading'> {
  nextStepEffect?: () => void
  isLink?: boolean
  isLoading?: boolean
  credentialingData?: Record<string, string | number | boolean | string[] | Date>
  responseChecker?: (response: SaveProviderCredentialingDataMutation) => boolean
  skipSave?: boolean
  customPreviousPath?: string
  customNextButtonText?: string
}

export function CAQHStepper({
  nextStepEffect,
  credentialingData = {},
  responseChecker = () => true,
  isLink = false,
  disabled = false,
  isLoading = false,
  skipSave = false,
  previousButton,
  customPreviousPath,
  customNextButtonText,
  ...props
}: Props) {
  const { previousPath, nextPath } = useCAQHNavigation()
  const { providerId } = useCAQHSetupContext()
  const [saveProviderCredentialingData, { loading }] = useSaveProviderCredentialingDataMutation()
  const navigate = useNavigate()
  const handleNextStep = async () => {
    if (nextStepEffect) {
      nextStepEffect()
    }
    // skipSave is hacky, but we need the ability to not send this twice for resume uploading
    if (!skipSave) {
      try {
        const response = await saveProviderCredentialingData({
          variables: {
            providerId,
            data: credentialingData,
          },
        })

        if (response.data && responseChecker(response.data)) {
          navigate(nextPath)
        }
      } catch (e) {
        toast.urgent(errorService.transformGraphQlError(e, 'There was an error saving your existing insurance details'))
        console.error(e)
      }
    }
  }

  return (
    <IntakeFormStepper
      {...props}
      previousPath={customPreviousPath ?? previousPath}
      mutationLoading={loading}
      className={isMobile ? 'block' : ''}
      nextButton={() => {
        if (isLink) {
          return (
            <>
              <OutlineButtonLink to={nextPath}>
                {customNextButtonText ?? 'Continue'} <IconChevronThick direction="right" className="ml-xs" size={16} />
              </OutlineButtonLink>
              <GhostButtonLink
                variant="secondary"
                to={routeService.scheduleDetail()}
                className={`${isMobile ? 'mr-5 mt-2' : 'ml-2'}`}
                data-testid="skip-intake-section"
              >
                Skip Insurance Enrollment
              </GhostButtonLink>
            </>
          )
        }
        return (
          <>
            <OutlineButtonWithChevron
              type={skipSave ? 'submit' : 'button'}
              isLoading={loading || isLoading}
              disabled={loading || disabled}
              onClick={handleNextStep}
            >
              {customNextButtonText ?? 'Continue'}
            </OutlineButtonWithChevron>
            <GhostButtonLink
              data-testid="skip-intake-section"
              variant="secondary"
              to={routeService.scheduleDetail()}
              className={isMobile ? 'mr-5 mt-2' : 'ml-2'}
            >
              Skip Insurance Enrollment
            </GhostButtonLink>
          </>
        )
      }}
      disabled={loading || disabled}
      previousButton={previousPath === undefined && customPreviousPath === undefined ? null : previousButton}
    />
  )
}
