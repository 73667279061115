import { Field, useFormikContext } from 'formik'
import { startCase } from 'lodash'
import { HTMLAttributes, useMemo } from 'react'

import { AccountSource, AccountSourceChannel, useProviderLicensesQuery } from '@nuna/api'
import { formService } from '@nuna/core'
import { DOBTextField, PhoneTextField, Select, StateAutocompleteSingle, TextField, TimezoneSelect } from '@nuna/tunic'

import { ClientInviteFormValues, Section, SectionTitle } from '../../shared'

const { composeHelperTextWithError } = formService

interface ClientInviteContactInfoProps extends HTMLAttributes<HTMLDivElement> {
  disabled: boolean
  isAdmin: boolean
  providerId: string | null
}

export function ClientInviteContactInfo({ disabled, isAdmin, providerId, ...props }: ClientInviteContactInfoProps) {
  const { errors, touched, values, handleBlur, setFieldValue } = useFormikContext<ClientInviteFormValues>()
  const { data } = useProviderLicensesQuery({
    variables: { providerId: providerId || '' },
    skip: !providerId,
  })

  const licenseStates = useMemo(() => {
    if (data?.provider.credentials) {
      return data.provider.credentials.map(license => license.state)
    }

    return []
  }, [data?.provider])

  return (
    <Section {...props}>
      <SectionTitle>Personal Contact Info</SectionTitle>

      <Field
        className="mb-3"
        disabled={disabled}
        as={TextField}
        name="firstName"
        label="First name"
        {...composeHelperTextWithError('', errors.firstName, touched.firstName)}
      />

      <Field
        className="mb-3"
        disabled={disabled}
        as={TextField}
        name="lastName"
        label="Last name"
        {...composeHelperTextWithError('', errors.lastName, touched.lastName)}
      />

      <Field
        disabled={disabled}
        as={DOBTextField}
        name="dob"
        label="Date of birth"
        {...composeHelperTextWithError('', errors.dob, touched.dob)}
      />

      <Field
        className="my-3"
        disabled={disabled}
        as={TextField}
        name="email"
        label="Email"
        {...composeHelperTextWithError('', errors.email, touched.email)}
      />

      <StateAutocompleteSingle
        className="mb-3"
        disabled={disabled}
        value={values.state}
        onChange={state => {
          setFieldValue('state', state?.value ?? '')
        }}
        states={licenseStates}
      />

      <PhoneTextField
        disabled={disabled}
        name="phone"
        label="Phone (optional)"
        fullWidth
        value={values.phone}
        onChange={value => setFieldValue('phone', value)}
        onBlur={handleBlur}
        {...composeHelperTextWithError('', errors.phone, touched.phone)}
      />

      <TimezoneSelect
        className="mt-3"
        disabled={disabled}
        name="timezone"
        label="Timezone (optional)"
        value={values.timezone}
        onChange={value => setFieldValue('timezone', value)}
        onBlur={handleBlur}
      />

      {isAdmin && (
        <>
          <Select
            className="mt-3"
            label={'Source'}
            disabled={disabled}
            value={values.source || ''}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => setFieldValue('source', e.target.value)}
            onBlur={handleBlur}
          >
            <option />
            {Object.values(AccountSource).map(v => (
              <option value={v}>{startCase(v)}</option>
            ))}
          </Select>

          <Select
            className="mt-3"
            label={'Source Channel'}
            disabled={disabled}
            value={values.sourceChannel || ''}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => setFieldValue('sourceChannel', e.target.value)}
            onBlur={handleBlur}
          >
            <option />
            {Object.values(AccountSourceChannel).map(v => (
              <option value={v}>{startCase(v)}</option>
            ))}
          </Select>
        </>
      )}
    </Section>
  )
}
