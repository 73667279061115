import { flattenDeep, isArray, isString } from 'lodash'

type CSXArg = Record<string, boolean> | string | null | undefined

export function csx(args: CSXArg | CSXArg[]): string {
  return recurseCSXArgs(args).join(' ')
}

function recurseCSXArgs(args: CSXArg | CSXArg[]): string[] {
  if (!args) {
    return []
  }

  if (isArray(args)) {
    return flattenDeep(args.map(arg => recurseCSXArgs(arg)))
  }

  if (isString(args)) {
    return [args]
  }

  return Object.keys(args)
    .filter(key => args[key])
    .map(key => key)
}
