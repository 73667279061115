import { isNil, isNumber } from 'lodash'
import { ReactNode } from 'react'

import { useEstimateReimbursementRateQuery } from '@nuna/api'
import { useIsAdmin } from '@nuna/auth'
import { numberService } from '@nuna/core'

import { CaptionLight } from './FormComponents'

const { centsToFormattedDollars, dollarsToCents } = numberService

interface EstimateProps {
  fee?: number | string | null
  debounceKey: string
  error?: boolean
  helperText?: string | ReactNode
}
export function ProviderTakeEstimate({
  fee: feeInDollars,
  helperText = '',
  error = false,
  debounceKey,
}: EstimateProps) {
  const isAdmin = useIsAdmin()
  const fee = isNumber(feeInDollars) ? dollarsToCents(feeInDollars) : 0
  const { data } = useEstimateReimbursementRateQuery({
    variables: { fee },
    context: { debounceKey, debounceTimeout: 500 },
  })

  const take = data?.estimateReimbursementRate

  if (helperText && error) {
    return <CaptionLight className="mt-xs text-error">{helperText}</CaptionLight>
  }

  if (isNil(feeInDollars) || feeInDollars === '') {
    return <CaptionLight className="mt-xs">Enter a value to see your estimated payout</CaptionLight>
  }

  if (isNil(take)) {
    return <CaptionLight className="mt-xs">Calculating your estimated payout...</CaptionLight>
  }

  return (
    <CaptionLight className="mt-xs">
      {isAdmin ? `The provider's reimbursement is ` : `Your payout is`}{' '}
      {centsToFormattedDollars(take < 4 ? 0 : take, { maximumFractionDigits: 2 })} after processing fees
    </CaptionLight>
  )
}
