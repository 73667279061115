import { useCallback, useState } from 'react'

export enum SidebarState {
  Chat,
  ClientInfo,
  Closed,
  Notes,
  Schedule,
  Settings,
}

export function useSidebarContext() {
  const [activeSidebar, setActiveSidebar] = useState(SidebarState.Closed)

  const toggleSidebar = useCallback(
    (type: SidebarState) => setActiveSidebar(prev => (prev === type ? SidebarState.Closed : type)),
    [],
  )

  return { activeSidebar, toggleSidebar, isSidebarOpen: activeSidebar !== SidebarState.Closed }
}

export type SidebarContextValues = ReturnType<typeof useSidebarContext>
