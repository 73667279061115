import { styled } from '@mui/material'
import 'ag-grid-community/dist/styles/ag-grid.css'
import 'ag-grid-community/dist/styles/ag-theme-material.css'
import 'ag-grid-enterprise'
import { AgGridReact, type AgGridReactProps, type AgReactUiProps } from 'ag-grid-react'
import { RefObject, forwardRef } from 'react'

import { Grid, Skeleton, sansSerifFontStack } from '@nuna/tunic'

export const TavaDataGridWrap = styled(Grid)`
  flex: 1 1 0;
  height: 100%;

  .ag-cell-focus {
    outline: none !important;
    border: none !important;
  }

  .ag-theme-material {
    font-family: ${sansSerifFontStack};

    //.ag-row {
    //  &:hover {
    //    background: #f3f2f2;
    //  }
    //  &.ag-row-odd {
    //    background: #faf8f7;
    //
    //    &:hover {
    //      background: #f3f2f2;
    //    }
    //  }
    //}
  }
`

export const TavaDataTableSkeleton = () => {
  return (
    <>
      <Skeleton height={1} />
      <Skeleton height={1} />
      <Skeleton height={1} />
      <Skeleton height={1} />
      <Skeleton height={1} />
      <Skeleton height={1} />
      <Skeleton height={1} />
      <Skeleton height={1} />
      <Skeleton height={1} />
    </>
  )
}

type Props = (AgGridReactProps | AgReactUiProps) & { ref?: RefObject<AgGridReact>; isLoading?: boolean }
export const TavaDataTable = forwardRef<AgGridReact, Props>((props, ref) => {
  const template = () => {
    return props.isLoading ? (
      <TavaDataTableSkeleton />
    ) : (
      <AgGridReact {...props} ref={ref} className="ag-theme-material" />
    )
  }

  return <TavaDataGridWrap>{template()}</TavaDataGridWrap>
})
