import { ReactNode } from 'react'
import { Navigate } from 'react-router-dom'

import { useAuthDataContext } from '@nuna/auth'

import { useFeatureFlagContext } from '../context/FeatureFlagProvider'
import { FeatureFlags } from '../generated/generated'
import { useFeatureFlags } from '../hooks/useFeatureFlags'
import { FeatureFlagRenderer } from './FeatureFlagRenderer'

export type FeatureType = 'page' | 'component'

interface Props {
  flags: (keyof FeatureFlags)[]
  children: ReactNode
  featureType?: FeatureType
  fallback?: ReactNode
  anonymous?: boolean
  negate?: boolean
  checkOperation?: 'and' | 'or'
}

export function FeatureFlagCheck({
  flags,
  featureType = 'page',
  fallback,
  children,
  anonymous = false,
  negate = false,
  checkOperation = 'and',
}: Props) {
  const { loggedIn } = useAuthDataContext()
  const { clientReady, flagUserReady } = useFeatureFlagContext()
  const currentFlagsObj = useFeatureFlags()

  // Replicates the behavior of PrivateRoute for non-anonymous flag checks
  // because some times FeatureFlagCheck is used outside
  // of a component wrapped by PrivateRoute
  if (!anonymous && !loggedIn) {
    return <Navigate to="/login" state={{ from: window.location.pathname }} />
  }

  const ready = anonymous ? clientReady : flagUserReady

  if (!ready && featureType === 'page') {
    return <div className="loading" style={{ height: '100%', width: '100%' }}></div>
  }

  const currentFlagsList = Object.entries(currentFlagsObj)
    .filter(([_key, value]) => value)
    .map(([key]) => key)

  const hasFlags =
    checkOperation === 'and'
      ? flags.every(flag => currentFlagsList.includes(flag))
      : flags.some(flag => currentFlagsList.includes(flag))

  const returnChildren = negate ? !hasFlags : hasFlags

  return (
    <FeatureFlagRenderer
      featureType={featureType}
      returnChildren={returnChildren}
      fallback={fallback}
      children={children}
    />
  )
}
