import { usePatientCapQuery } from '@nuna/api'

export function useCapInfo(patientId: string) {
  const { data } = usePatientCapQuery({
    variables: { id: patientId },
  })

  const appointmentConfiguration = data?.patient.appointmentConfiguration

  if (!appointmentConfiguration) {
    return {
      appointmentConfiguration: null,
      isLastCoveredAppointment: null,
      isOutOfAppointments: null,
      remainingSessions: null,
    }
  }

  const {
    patientHasPaymentMethod,
    capInfo: { sessionAllowanceToType, sessionsCountingTowardsCap },
  } = appointmentConfiguration

  const remainingSessions =
    sessionAllowanceToType && sessionsCountingTowardsCap ? sessionAllowanceToType - sessionsCountingTowardsCap : null

  const isLastCoveredAppointment = !patientHasPaymentMethod && remainingSessions === 1
  const isOutOfAppointments = !patientHasPaymentMethod && remainingSessions === 0

  return {
    appointmentConfiguration,
    isLastCoveredAppointment,
    isOutOfAppointments,
    remainingSessions,
  }
}
