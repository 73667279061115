import { styled } from '@mui/material'

import { usePatientDetailsForLiveSessionQuery } from '@nuna/api'
import { SidebarPatientHeader } from '@nuna/client'
import { Link, ProviderClientPaymentStatusAlert } from '@nuna/common'
import { PatientSubRoute, routeService } from '@nuna/core'
import { IconAssessment, IconNotebook, IconUser, fontSize, greySet, sansSerifFontStack } from '@nuna/tunic'

import { useVideoCallContext } from '../../../../context/VideoCallContext'
import { SidebarState } from '../../../../context/hooks/useSidebarContext'
import { PatientVideoUser } from '../../../../types'
import { HeaderLink } from '../SidebarCard'
import { SidebarContentWrapper } from '../SidebarContentWrapper'
import { AssessmentsOverview } from './Assessments/AssessmentsOverview'
import { ClientCoverageGlance } from './ClientCoverageGlance'
import { ContactInfoGlance } from './ContactInfoGlance'
import { EmergencyContactGlance } from './EmergencyContactGlance'
import { ProgressNotesGlance } from './ProgressNotesGlance'
import { SpecialtiesGlance } from './SpecialtiesGlance'

const { patientDetail } = routeService

export function SidebarClientInfo({ patient: patientVideoUser }: { patient: PatientVideoUser }) {
  const { toggleSidebar } = useVideoCallContext()

  const patientId = patientVideoUser.patientId

  const { data: patientData, loading } = usePatientDetailsForLiveSessionQuery({
    variables: { id: patientId },
    skip: !patientId,
  })

  if (!patientData || loading || !patientId) {
    return <div className="loading" style={{ height: 164, borderRadius: 6 }}></div>
  }

  const patient = patientData?.patient
  const paymentStatus = patient?.paymentStatus

  const handleClose = () => {
    toggleSidebar(SidebarState.Closed)
  }

  return (
    <SidebarContentWrapper>
      <SidebarPatientHeader patient={patient} onClose={handleClose} openPatientLinkInNewTab hideCapInfo />
      <SidebarBody>
        {!!paymentStatus && <ProviderClientPaymentStatusAlert scene={'clientInfo'} paymentStatus={paymentStatus} />}

        <MajorSectionHeader>
          <IconAssessment className="mr-1" />
          Assessments
          <HeaderLink as={Link} to={patientDetail(patientId, PatientSubRoute.assessments)} openInNewTab={true}>
            View
          </HeaderLink>
        </MajorSectionHeader>
        <AssessmentsOverview patientId={patientId} />

        <MajorSectionHeader>
          <IconNotebook className="mr-1" />
          Past Session Notes
        </MajorSectionHeader>
        <ProgressNotesGlance patientId={patientId} className="mt-1" />

        <MajorSectionHeader>
          <IconUser className="mr-1" />
          Client Bio
          <HeaderLink href={patientDetail(patientId)} target="_blank" rel="noreferrer noopener">
            View Full Bio
          </HeaderLink>
        </MajorSectionHeader>

        <SpecialtiesGlance patientId={patientId}></SpecialtiesGlance>

        <ClientCoverageGlance clientId={patientId} className="mt-3" />

        <MinorSectionHeader>Contact Info</MinorSectionHeader>
        <ContactInfoGlance patient={patient} />

        <MinorSectionHeader>Emergency Contact</MinorSectionHeader>
        <EmergencyContactGlance patient={patient} />
      </SidebarBody>
    </SidebarContentWrapper>
  )
}

const SidebarBody = styled('div')`
  background-color: ${greySet[5].hex};
  flex-grow: 1;
  padding: 0 1.25rem;
  overflow: auto;
`

const MajorSectionHeader = styled('h6')``
MajorSectionHeader.defaultProps = { className: 'mt-3 mb-1 v-align' }

const MinorSectionHeader = styled('h3')`
  font-family: ${sansSerifFontStack};
  font-size: ${fontSize.body};

  &:after {
    content: '';
    border-top: 1px solid ${greySet[30].hex};
    height: 1px;
    flex-grow: 1;
    margin: 0 0.5rem;
  }
`
MinorSectionHeader.defaultProps = { className: 'mt-4 mb-2 text-dark v-align' }
