import { styled } from '@mui/material'

import { numberService } from '@nuna/core'
import { GhostButtonLink, Grid, Skeleton, fontSize, greySet, tealSet, yellowSet } from '@nuna/tunic'

import { payrollDetail, sessionIndex } from '../../util/routes'
import { SessionIndexTabs } from '../Sessions/SessionIndex'
import { unpaidParam, upcomingParam } from './Payroll'
import { useUpcomingPayoutsContext } from './UpcomingPayoutsContext'

interface UpcomingPayoutsProps {
  employmentStatusCompleted: boolean
}

export function UpcomingPayouts({ employmentStatusCompleted }: UpcomingPayoutsProps) {
  const { expectedPayDate, incompleteSessions, payrollSummaryTotal, loading, unpaidPayDate, unpaidSummaryTotal } =
    useUpcomingPayoutsContext()
  const payday = employmentStatusCompleted ? expectedPayDate : 'None'
  const unpaidPayday = employmentStatusCompleted ? unpaidPayDate : 'None'

  if (loading) return <Skeleton className="mt-2" height={2} style={{ width: '100%' }} />

  return (
    <Grid container>
      <Grid
        size={{
          xs: 6,
          md: 4,
        }}
      >
        <UpcomingPayoutsLabel className="mb-xs">Expected Pay Date</UpcomingPayoutsLabel>
        {unpaidSummaryTotal > 0 && (
          <UpcomingPayoutsValue employmentStatusCompleted={employmentStatusCompleted} className="mb-1">
            {employmentStatusCompleted && unpaidSummaryTotal ? (
              <PayoutsValueButton to={payrollDetail(unpaidParam)}>{unpaidPayday}</PayoutsValueButton>
            ) : (
              unpaidPayday
            )}
          </UpcomingPayoutsValue>
        )}
        <UpcomingPayoutsValue employmentStatusCompleted={employmentStatusCompleted}>
          {employmentStatusCompleted && payrollSummaryTotal ? (
            <PayoutsValueButton to={payrollDetail(upcomingParam)}>{payday}</PayoutsValueButton>
          ) : (
            payday
          )}
        </UpcomingPayoutsValue>
      </Grid>
      <Grid
        size={{
          xs: 6,
          md: 8,
        }}
      >
        <UpcomingPayoutsLabel className="mb-xs">Expected Payout</UpcomingPayoutsLabel>
        {unpaidSummaryTotal > 0 && (
          <UpcomingPayoutsValue employmentStatusCompleted={employmentStatusCompleted} className="mb-1">
            {employmentStatusCompleted && unpaidSummaryTotal ? (
              <PayoutsValueButton to={payrollDetail(unpaidParam)}>
                {numberService.centsToFormattedDollars(unpaidSummaryTotal, {
                  maximumFractionDigits: 2,
                })}
              </PayoutsValueButton>
            ) : (
              '$0.00'
            )}
          </UpcomingPayoutsValue>
        )}
        <UpcomingPayoutsValue employmentStatusCompleted={employmentStatusCompleted}>
          {employmentStatusCompleted && payrollSummaryTotal ? (
            <PayoutsValueButton to={payrollDetail(upcomingParam)}>
              {numberService.centsToFormattedDollars(payrollSummaryTotal, {
                maximumFractionDigits: 2,
              })}
            </PayoutsValueButton>
          ) : (
            '$0.00'
          )}
        </UpcomingPayoutsValue>
      </Grid>
      {incompleteSessions > 0 && (
        <Grid size={12}>
          <Warning>
            <span className="text-bold" style={{ color: yellowSet[80].hex }}>
              FYI:
            </span>{' '}
            {incompleteSessions} session
            {incompleteSessions > 1 ? 's are' : ' is'} not included in the above estimate
            <GhostButtonLink
              className="ml-1 body text-medium"
              to={sessionIndex(SessionIndexTabs.PREVIOUS)}
              variant="secondary"
            >
              Write Notes
            </GhostButtonLink>
          </Warning>
        </Grid>
      )}
    </Grid>
  )
}

const UpcomingPayoutsLabel = styled('div')`
  font-size: ${fontSize.caption};
  line-height: 1.4;
  color: ${greySet[70].hex};
`

const UpcomingPayoutsValue = styled('div')<{ employmentStatusCompleted: boolean }>`
  color: ${props => (props.employmentStatusCompleted ? tealSet[70].hex : greySet[70].hex)};
  font-size: 1.626rem;
  font-weight: 300;
`

const PayoutsValueButton = styled(GhostButtonLink)`
  font-size: 1.626rem;
  font-weight: 300;
`

const Warning = styled('div')`
  margin-top: 1.1375rem;
  font-weight: 300;
  color: ${greySet[70].hex};
`
