import { styled } from '@mui/material'

import { styledUtils } from '@nuna/tunic'
import { Dialog, DialogProps } from '@nuna/tunic'

import { videoSceneDark } from '../util/colors'

const { transientPropOptions } = styledUtils

interface VideoDialogProps extends DialogProps {
  unpadded?: boolean
}

export function VideoDialog({ unpadded = false, ...props }: VideoDialogProps) {
  return <StyledDialog {...props} transitionDuration={0} $unpadded={unpadded} fullWidth />
}

const StyledDialog = styled(Dialog, transientPropOptions)<{ $unpadded: boolean }>`
  .MuiDialog-paper {
    background-color: ${videoSceneDark};
    border: 1px solid rgba(255, 255, 255, 0.13);
    border-radius: 12px;
    color: #fff;
    ${props => !props.$unpadded && 'padding: 1rem;'}
    max-width: 375px;
  }

  .MuiBackdrop-root {
    background-color: rgba(20, 81, 89, 0.85);

    @supports (backdrop-filter: blur(16px)) {
      backdrop-filter: blur(16px);
      background-color: rgba(0, 49, 56, 0.4);
    }
  }
`
