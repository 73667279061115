import { styled } from '@mui/material'
import moment from 'moment-timezone'
import { useMemo } from 'react'
import { useParams } from 'react-router-dom'
import { Column } from 'react-table'

import { AdminBenefitsGuidesContractFragment, useAdminBenefitsGuidesQuery } from '@nuna/api'
import { DataTable } from '@nuna/data-table'
import { StatusLabel } from '@nuna/tunic'

import { BenefitsGuideActions } from './BenefitsGuideActions'

interface BenefitsGuideRow {
  status: JSX.Element
  goLiveDate: string
  endDate: string
  actions: JSX.Element
}

export function CustomerBenefitsGuideAdmin() {
  const { id = '' } = useParams<{ id: string }>()
  const { data } = useAdminBenefitsGuidesQuery({ variables: { companyId: id } })

  const rowData: BenefitsGuideRow[] = useMemo(
    () =>
      (data?.contracts ?? []).map(contract => ({
        status: <ContractStatus contract={contract} />,
        goLiveDate: moment(contract.goLiveDate).format('l'),
        endDate: moment(contract.endDate).format('l'),
        actions: <BenefitsGuideActions contract={contract} />,
      })),
    [data],
  )

  const columns: Column<BenefitsGuideRow>[] = useMemo(
    () => [
      { Header: 'Status', accessor: 'status' },
      { Header: 'Go Live Date', accessor: 'goLiveDate' },
      { Header: 'End Date', accessor: 'endDate' },
      { Header: 'Benefits Guide', accessor: 'actions' },
    ],
    [],
  )

  return (
    <Container>
      <DataTable columns={columns} rowData={rowData} loading={false} />
    </Container>
  )
}

function ContractStatus({ contract }: { contract: AdminBenefitsGuidesContractFragment }) {
  const now = moment()

  if (now.isAfter(contract.endDate)) {
    return <StatusLabel>Expired</StatusLabel>
  } else if (now.isBefore(contract.goLiveDate)) {
    return <StatusLabel>Future</StatusLabel>
  }

  return <StatusLabel intent="information">Active</StatusLabel>
}

const Container = styled('div')`
  max-width: 1200px;
  margin: auto;

  td {
    height: 71px;

    &:last-child {
      width: 50%;
    }
  }
`
