import { styled } from '@mui/material'

import { useClientInviteDrawerSearchParams } from '@nuna/common'
import { Audience } from '@nuna/core'
import { Drawer, csx, eggshell } from '@nuna/tunic'

import { ClientInviteOptional } from './components/ClientInviteOptional/ClientInviteOptional'
import { ClientInviteRequired } from './components/ClientInviteRequired/ClientInviteRequired'

interface ClientInviteDrawerProps {
  audience: Audience
}

export function ClientInviteDrawer({ audience }: ClientInviteDrawerProps) {
  const {
    drawerConfig: { clientInvite, patientId, providerId, drawerOpen },
    closeDrawer,
  } = useClientInviteDrawerSearchParams()
  return (
    <StyledDrawer
      size="min(400px, 100vw)"
      onClose={closeDrawer}
      isOpen={drawerOpen}
      PaperProps={{ className: csx(['py-2']) }}
    >
      <div className="flex-column content-wrapper">
        {(() => {
          if (clientInvite && !patientId && (providerId || (!providerId && audience === 'admin'))) {
            return <ClientInviteRequired audience={audience} providerId={providerId} />
          }

          if (clientInvite && patientId && (providerId || (!providerId && audience === 'admin'))) {
            return <ClientInviteOptional audience={audience} patientId={patientId} providerId={providerId} />
          }

          return null
        })()}
      </div>
    </StyledDrawer>
  )
}

const StyledDrawer = styled(Drawer)`
  .MuiPaper-root {
    padding: 0;
    background-color: ${eggshell};
  }

  .content-wrapper {
    height: 100%;
    overflow: auto;
  }
`
