import { styled } from '@mui/material'

import { Persona } from '@nuna/core'

import { greySet, plumSet } from '../styles/colorSets'
import { interactiveFill } from '../styles/colors'

interface ProgressBarProps extends React.HTMLAttributes<HTMLDivElement> {
  now: number
  max: number
  min: number
  persona: Persona
  customColors?: { start?: string; end?: string }
}

export function HeaderProgressBar({ now, min, max, persona, customColors, ...props }: ProgressBarProps) {
  const percentage = (now - min) / (max - min)

  return (
    <Bar
      {...props}
      $customColors={customColors}
      $persona={persona}
      $percentage={percentage}
      role="progressbar"
      aria-valuenow={now}
      aria-valuemin={min}
      aria-valuemax={max}
    />
  )
}

const progressBarHeight = '7px'

const Bar = styled('div')<{
  $percentage: number
  $persona: Persona
  $customColors?: { start?: string; end?: string }
}>`
  background-color: ${({ $persona }) => {
    if ($persona === 'provider') {
      return plumSet[100].hex
    }
    return greySet[5].hex
  }};
  height: ${progressBarHeight};
  position: relative;
  width: 100%;

  &::before {
    ${({ $customColors }) => {
      if (!$customColors) return ''

      return `background: linear-gradient(90deg, ${$customColors.start} 13.5%, ${$customColors.end} 81%);`
    }}
    background-color: ${({ $persona }) => {
      if ($persona === 'provider') {
        return plumSet[50].hex
      }
      return interactiveFill
    }};
    content: '';
    height: 100%;
    left: 0;
    position: absolute;
    bottom: 0;
    width: 100%;
    transform: scaleX(${props => props.$percentage});
    transform-origin: center left;
    transition: transform 0.3s;
  }
`
