import { HTMLAttributes } from 'react'

import { Provider } from '@nuna/api'
import { useEnvironmentContext } from '@nuna/environment'
import { Box, Grid, IconUser } from '@nuna/tunic'

import { onAuthWithNylasClick } from '../../../../utils/calendarSync'
import { CalendarSectionTitle } from './CalendarSectionTitle'
import { CalendarSyncButton, NylasCalendarProvider, NylasCalendarProviderOptions } from './CalendarSyncButton'

interface Props extends HTMLAttributes<HTMLDivElement> {
  provider: Provider
  title?: string
}

export function CalendarSyncSetup({ provider }: Props) {
  const { API_ENDPOINT } = useEnvironmentContext()

  const submitCalendar = (type: NylasCalendarProvider, email: string) => {
    onAuthWithNylasClick(API_ENDPOINT, type, email, provider.id)
  }

  return (
    <Box>
      <CalendarSectionTitle>
        <IconUser className="mr-1" size={18} />
        Sign in to your account
      </CalendarSectionTitle>
      <p className="text-secondary mb-4">
        Set up Calendar Sync by signing in to your account with one of the services below.
      </p>

      <Grid container spacing={2}>
        {NylasCalendarProviderOptions.map(type => (
          <CalendarSyncButton key={type} type={type} submit={submitCalendar} />
        ))}
      </Grid>
    </Box>
  )
}
