import { Outlet, Route, Routes, useMatch, useParams } from 'react-router-dom'

import { ConversationFragment, Provider, useProviderMeQuery } from '@nuna/api'
import { useAuthDataContext } from '@nuna/auth'
import { useAppointmentDrawerSearchParams, useClientInviteDrawerSearchParams, useTitle } from '@nuna/common'
import { ExploreFirstEmptyState } from '@nuna/common'
import { routeService } from '@nuna/core'
import {
  ConversationContextProvider,
  ConversationDetail,
  ConversationMenu,
  useConversationContext,
} from '@nuna/messaging'
import { GhostButton, PageContent, PageHeader, PageHeading, PageWrapper } from '@nuna/tunic'

import { useProviderAppContext } from '../../shared/ProviderAppContext'
import { EmptyState } from './EmptyState'
import dogGirl from './img/DogGirl.png'

const { conversationsDetailFullPath } = routeService

export function ProviderMessages() {
  useTitle('Messages')
  const { conversations, loading } = useConversationContext()
  const { data: providerMeData } = useProviderMeQuery()
  const { provider } = useProviderAppContext()

  const showEmptyState = !providerMeData?.providerMe?.patients?.length

  return (
    <PageWrapper>
      <PageHeader>
        <ProviderMessagesHeading />
      </PageHeader>

      <PageContent className="relative">
        <div className="flex-start" style={{ height: showEmptyState ? 'auto' : '100%' }}>
          <Routes>
            <Route
              path="*"
              element={
                <ProviderMessagesWithContext
                  loading={loading}
                  conversations={conversations}
                  showEmptyState={showEmptyState}
                  provider={provider}
                />
              }
            >
              <Route
                path=":conversationId"
                element={
                  <div style={{ maxWidth: 675, width: '100%' }}>
                    <ConversationContextProvider>
                      <ConversationDetail audience="provider" />
                    </ConversationContextProvider>
                  </div>
                }
              />
            </Route>
          </Routes>
        </div>
      </PageContent>
    </PageWrapper>
  )
}

export function ProviderMessagesWithContext({
  showEmptyState,
  conversations,
  loading,
  provider,
}: {
  showEmptyState: boolean
  loading: boolean
  conversations: ConversationFragment[]
  provider: Pick<Provider, 'id' | 'status' | 'avatarUrl'> | null | undefined
}) {
  const isConversationActive = !!useMatch(conversationsDetailFullPath)
  const { openScheduleAppointmentDrawer } = useAppointmentDrawerSearchParams()
  const { openClientInviteDrawer } = useClientInviteDrawerSearchParams()

  const handleOnNewAppointment = () => {
    openScheduleAppointmentDrawer(provider?.id ?? '')
  }
  return showEmptyState ? (
    <ExploreFirstEmptyState
      textBlock={
        <>
          <h2 className="h4 mt-0">Your line of communication to connect with your clients directly.</h2>
          <p className="large">
            <GhostButton onClick={handleOnNewAppointment}>Schedule</GhostButton> an appointment or
          </p>
          <p className="large">
            <GhostButton onClick={() => openClientInviteDrawer({ providerId: provider?.id })}>Add a client</GhostButton>{' '}
            to start sending messages.
          </p>
        </>
      }
      className="v-align pr-3"
      illustrationSrc={dogGirl}
    />
  ) : (
    <>
      <ConversationMenu className="mr-4 flex-static" audience="provider" />

      {conversations.length === 0 && !loading && !isConversationActive && <EmptyState />}

      <Outlet />
    </>
  )
}

export function ProviderMessagesHeading() {
  const { login } = useAuthDataContext()
  const { conversations } = useConversationContext()
  const params = useParams()

  const conversationId = params['*']
  const conversation = conversations.find(c => c.id === conversationId)
  const patient = conversation?.participants?.find(participant => participant.loginId !== login?.id)

  const classNames = ['my-0']

  if (patient) {
    classNames.push('h6')
  }

  return (
    <PageHeading className={classNames.join(' ')}>
      Messages{' '}
      {patient && (
        <span>
          with {patient.displayData.firstName} {patient.displayData.lastName}
        </span>
      )}
    </PageHeading>
  )
}
