import { styled } from '@mui/material'
import { HTMLAttributes, ReactNode } from 'react'

import { type DiscriminatedPatientCoverageFragment, InvalidSponsorCoverageFragment, coverageService } from '@nuna/core'
import { Card, CardBoot, Intent } from '@nuna/tunic'

import { CoverageCard } from './CoverageCard'
import { CoverageTypeIcon } from './CoverageTypeIcon'

export interface CoverageStatusProps extends Omit<HTMLAttributes<HTMLElement>, 'onClick'> {
  coverage: DiscriminatedPatientCoverageFragment | InvalidSponsorCoverageFragment
  intent: Intent
  onClick?: (coverage: DiscriminatedPatientCoverageFragment | InvalidSponsorCoverageFragment) => void
  expanded?: boolean
  boot?: ReactNode
  headerSlot?: ReactNode
}

/** This component is meant as a generic component that provides a consistent visual style but does not try to handle specific logic re: status (validity, primary, etc.).
 * The reason is that depending on the context (and type of coverage within that context), what is considered valid or displayed in the boot or header can vary.
 * For example, in the intake flow the circumstances for determing if coverage is "valid" vary from what we want to display in the coverage panel to an existing client.
 *
 * To use this component, consider creating a wrapping component that handles the specific logic for intent, boot, expanded, header, etc. for your specific
 * circumstances. See PanelCoverageStatus or IntakeCoverageStatus for examples.
 */
export function CoverageStatus({
  coverage,
  className,
  intent,
  onClick,
  headerSlot,
  boot,
  expanded = false,
  ...props
}: CoverageStatusProps) {
  const cardContent = (
    <Container depth={1} intent={intent}>
      <div className="card-content">
        <div className="v-align text-default header-content">
          <CoverageTypeIcon type={coverage.type} size={18} className="mr-xs" />
          <h3 className="body text-bold m-0">{coverageService.getHumanReadablePaymentPreference(coverage.type)}</h3>

          {headerSlot && <span className="ml-auto v-align">{headerSlot}</span>}
        </div>

        {expanded && <CoverageCard className="mt-1" coverage={coverage} />}
      </div>

      {boot && <CardBoot intent={intent}>{boot}</CardBoot>}
    </Container>
  )

  const classNameString = `full-width full-height block text-left ${className}`

  if (onClick) {
    return (
      <button className={classNameString} onClick={() => onClick(coverage)} {...props}>
        {cardContent}
      </button>
    )
  }

  return (
    <div className={classNameString} {...props}>
      {cardContent}
    </div>
  )
}

const Container = styled(Card)`
  overflow: hidden;

  .header-content {
    min-height: 21px;
  }

  .card-content {
    padding: 1rem;
  }
`
