import { styled } from '@mui/material'
import { useFormikContext } from 'formik'
import { startCase } from 'lodash'
import { ChangeEventHandler, ReactNode } from 'react'

import { ClientInsurancePayerFragment, RelationshipToSubscriber } from '@nuna/api'
import { formService } from '@nuna/core'
import {
  DOBTextField,
  GhostButton,
  Grid,
  IconButtonExternalLink,
  IconInfo,
  Select,
  TextField,
  Tooltip,
  borderGrey,
  greySet,
} from '@nuna/tunic'

import { PayerAutocomplete } from '../../PayerAutocomplete'
import { CoverageFormValues } from '../CoverageForm'

const { composeNestedHelperTextWithError } = formService

interface CommonProps {
  isDependent: boolean
  insuredFirstName: string
  insuredLastName: string
  insurancePayer: ClientInsurancePayerFragment | null
  memberId: string
  state: string
  onChange: ChangeEventHandler<HTMLInputElement>
  isCheckout: boolean
  saveButton?: boolean
  providerId?: string
  sponsorCompanyId?: string
}

interface IsNotDependentProps {
  isDependent: false
}

interface IsDependentProps {
  isDependent: true
  insuredDob: string
  relationshipToSubscriber: RelationshipToSubscriber | null
}

type Conditionalprops = IsNotDependentProps | IsDependentProps

export type InsuranceFormProps = CommonProps & Conditionalprops

export function InsuranceForm({ saveButton = false, providerId, onChange, ...props }: InsuranceFormProps) {
  const { errors, touched, submitForm, handleBlur, setFieldValue } = useFormikContext<CoverageFormValues>()

  const commonProps = {
    onChange,
    onBlur: handleBlur,
  }

  if (props.isDependent) {
    const {
      insuredFirstName,
      insuredLastName,
      insurancePayer,
      memberId,
      insuredDob,
      relationshipToSubscriber,
      isCheckout,
      state,
      sponsorCompanyId,
    } = props

    return (
      <Container>
        <Grid container spacing={4}>
          <Grid
            size={{
              xs: 12,
              sm: 6,
            }}
          >
            <TextField
              {...commonProps}
              value={insuredFirstName}
              label="Their first name"
              name="insuranceValues.insuredFirstName"
              {...composeNestedHelperTextWithError('', errors, touched, 'insuranceValues', 'insuredFirstName')}
            />
          </Grid>

          <Grid
            size={{
              xs: 12,
              sm: 6,
            }}
          >
            <TextField
              {...commonProps}
              value={insuredLastName}
              label="Their last name"
              name="insuranceValues.insuredLastName"
              {...composeNestedHelperTextWithError('', errors, touched, 'insuranceValues', 'insuredLastName')}
            />
          </Grid>

          <Grid size={12}>
            <PayerAutocomplete
              {...commonProps}
              onChange={value => setFieldValue('insuranceValues.insurancePayer', value)}
              value={insurancePayer?.externalEligibilityId ?? ''}
              label="Insurance provider"
              state={state}
              providerId={providerId}
              sponsorCompanyId={sponsorCompanyId}
              {...composeNestedHelperTextWithError('', errors, touched, 'insuranceValues', 'insurancePayer')}
            />
          </Grid>

          <Grid
            size={{
              xs: 12,
              sm: isCheckout ? 12 : 6,
            }}
          >
            <DOBTextField
              {...commonProps}
              value={insuredDob}
              label="Their birthdate"
              name="insuranceValues.insuredDob"
              fullWidth
              {...composeNestedHelperTextWithError('', errors, touched, 'insuranceValues', 'insuredDob')}
            />
          </Grid>

          <Grid
            size={{
              xs: 12,
              sm: isCheckout ? 12 : 6,
            }}
          >
            <Select
              {...commonProps}
              value={relationshipToSubscriber ?? ''}
              label="Relationship to primary policy holder"
              name="insuranceValues.relationshipToSubscriber"
              {...composeNestedHelperTextWithError('', errors, touched, 'insuranceValues', 'relationshipToSubscriber')}
            >
              <option value=""></option>
              {Object.values(RelationshipToSubscriber)
                .filter(relationship => relationship !== RelationshipToSubscriber.Self)
                .map(relationship => (
                  <option key={relationship} value={relationship}>
                    {startCase(relationship.toLowerCase())}
                  </option>
                ))}
            </Select>
          </Grid>

          <Grid size={12}>
            <MemberIdInfoWrapper payerHelpUrl={insurancePayer?.helpUrl || ''}>
              <TextField
                {...commonProps}
                value={memberId}
                label="Member ID"
                name="insuranceValues.memberId"
                {...composeNestedHelperTextWithError('', errors, touched, 'insuranceValues', 'memberId')}
              />
            </MemberIdInfoWrapper>
          </Grid>

          {saveButton && (
            <Grid size={12}>
              <GhostButton variant="primary" style={{ float: 'right' }} onClick={submitForm}>
                Save Insurance
              </GhostButton>
            </Grid>
          )}
        </Grid>
      </Container>
    )
  } else {
    const { insuredFirstName, insuredLastName, insurancePayer, memberId, state, sponsorCompanyId } = props

    return (
      <Container>
        <Grid container spacing={4}>
          <Grid
            size={{
              xs: 12,
              sm: 6,
            }}
          >
            <TextField
              {...commonProps}
              value={insuredFirstName}
              label="First name"
              name="insuranceValues.insuredFirstName"
              {...composeNestedHelperTextWithError('', errors, touched, 'insuranceValues', 'insuredFirstName')}
              inputProps={{
                'data-testid': 'coverage-insurance-first-name',
              }}
            />
          </Grid>

          <Grid
            size={{
              xs: 12,
              sm: 6,
            }}
          >
            <TextField
              {...commonProps}
              value={insuredLastName}
              label="Last name"
              name="insuranceValues.insuredLastName"
              {...composeNestedHelperTextWithError('', errors, touched, 'insuranceValues', 'insuredLastName')}
              inputProps={{
                'data-testid': 'coverage-insurance-last-name',
              }}
            />
          </Grid>

          <Grid size={12}>
            <PayerAutocomplete
              {...commonProps}
              onChange={value => setFieldValue('insuranceValues.insurancePayer', value)}
              value={insurancePayer?.externalEligibilityId ?? ''}
              label="Insurance provider"
              state={state}
              providerId={providerId}
              sponsorCompanyId={sponsorCompanyId}
              {...composeNestedHelperTextWithError('', errors, touched, 'insuranceValues', 'insurancePayer')}
            />
          </Grid>

          <Grid size={12}>
            <MemberIdInfoWrapper payerHelpUrl={insurancePayer?.helpUrl || ''}>
              <TextField
                {...commonProps}
                value={memberId}
                label="Member ID"
                name="insuranceValues.memberId"
                {...composeNestedHelperTextWithError('', errors, touched, 'insuranceValues', 'memberId')}
                inputProps={{
                  'data-testid': 'coverage-insurance-member-id',
                }}
              />
            </MemberIdInfoWrapper>
          </Grid>
          {saveButton && (
            <Grid size={12}>
              <GhostButton variant="primary" style={{ float: 'right' }} onClick={submitForm}>
                Save Insurance
              </GhostButton>
            </Grid>
          )}
        </Grid>
      </Container>
    )
  }
}

function MemberIdInfoWrapper({ children, payerHelpUrl }: { children: ReactNode; payerHelpUrl?: string }) {
  return (
    <div style={{ position: 'relative' }}>
      {children}

      {!!payerHelpUrl && (
        <IconButtonExternalLink
          tooltip="This is also called your Subscriber ID number. You can find it on your health insurance card or by logging in to your insurance company's website."
          href={payerHelpUrl}
          style={{ position: 'absolute', right: '-0.75rem', top: '0.75rem', zIndex: 1 }}
        >
          <IconInfo color={greySet[50].hex} />
        </IconButtonExternalLink>
      )}
      {!payerHelpUrl && (
        <Tooltip content="This is also called your Subscriber ID number. You can find it on your health insurance card or by logging in to your insurance company's website.">
          <div style={{ position: 'absolute', right: 0, top: '1.25rem', zIndex: 1 }}>
            <IconInfo color={greySet[50].hex} />
          </div>
        </Tooltip>
      )}
    </div>
  )
}

const Container = styled('div')`
  border: 1px solid ${borderGrey};
  border-radius: var(--border-radius);
  background-color: #fff;
  padding: 1.5rem 1rem 2rem;
`
