import { useFormikContext } from 'formik'

import { CoverageFormValues } from '../CoverageForm'

export function useCoverageFormInputProps() {
  const formikContext = useFormikContext<CoverageFormValues>()

  const getInputProps = (name: keyof CoverageFormValues) => {
    return {
      onChange: formikContext.handleChange,
      onBlur: formikContext.handleBlur,
      fullWidth: true,
      name,
      value: formikContext.values[name],
      error: (formikContext.errors[name] && formikContext.touched[name]) as boolean,
      helperText: formikContext.touched[name] ? (formikContext.errors[name] as string) : '',
    }
  }

  return { getInputProps, formikContext }
}
