import { styled } from '@mui/material'

import { greySet } from '../../../styles/colorSets'
import { NavButton } from './NavButton'

export function Hamburger({ isOpen = false }: { isOpen?: boolean }) {
  return (
    <HamburgerContainer isOpen={isOpen}>
      <TopLine />
      <BottomLine />
    </HamburgerContainer>
  )
}

function TopLine() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="17" height="2" viewBox="0 0 17 2" fill="none">
      <path d="M15.9125 1.57154C15.7308 1.83952 15.428 2 15.1042 2L1.10518 2C0.366316 2 0.0188981 1.08694 0.570873 0.595781C0.712937 0.469369 0.89522 0.397421 1.08532 0.392723L15.0801 0.0469082C15.8744 0.0272792 16.3585 0.913918 15.9125 1.57154Z" />
    </svg>
  )
}

function BottomLine() {
  return (
    <svg width="17" height="3" viewBox="0 0 17 3" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M0.872099 1.59085C0.683002 1.09447 1.04961 0.5625 1.58079 0.5625H15.1708C15.6077 0.5625 15.9917 0.852121 16.1117 1.2722C16.2939 1.91003 15.8021 2.54052 15.1391 2.51903L1.55623 2.07885C1.25067 2.06895 0.980934 1.87654 0.872099 1.59085Z" />
    </svg>
  )
}

const HamburgerContainer = styled('div')<{ isOpen: boolean }>`
  height: 0.5rem;
  position: relative;
  width: 17px;
  margin-left: 0.5rem;
  margin-right: 0.5rem;

  svg {
    position: absolute;
    left: 0;
    transition: transform 0.3s;
    transform-origin: center;

    &:first-of-type {
      top: 0;

      ${({ isOpen }) => isOpen && `transform: translateY(3px) rotate(45deg);`}
    }

    &:last-child {
      bottom: 0;
      ${({ isOpen }) => isOpen && `transform: translateY(-3px) rotate(-45deg);`}
    }
  }

  path {
    fill: ${({ isOpen }) => (isOpen ? greySet[80].hex : greySet[70].hex)};
  }

  ${NavButton}:hover & {
    path {
      fill: ${greySet[80].hex};
    }
  }
`
