import { styled } from '@mui/material'
import { noop } from 'lodash'
import { ReactNode, forwardRef, useImperativeHandle, useState } from 'react'

import { FillButton, GhostButton } from '../Button'
import { Dialog, DialogContent, DialogPaddingSize } from '../Dialog/Dialog'

export type ConfirmRef = {
  open: (callback: (response: boolean) => void) => void
}

type ConfirmPropsBase = {
  children: ReactNode
  isOpen?: boolean
  isLoading?: boolean
  paddingSize?: DialogPaddingSize
}

type ConfirmPropsWithOptionalOnConfirm = {
  cancelButton: ReactNode
  confirmButton: ReactNode
  onConfirm?: (response: boolean) => void
}

type ConfirmPropsWithMandatoryOnConfirm = {
  cancelButton?: ReactNode
  confirmButton?: ReactNode
  onConfirm?: (response: boolean) => void
}

export type ConfirmProps = ConfirmPropsBase & (ConfirmPropsWithOptionalOnConfirm | ConfirmPropsWithMandatoryOnConfirm)

export const Confirm = forwardRef<ConfirmRef, ConfirmProps>(
  (
    {
      onConfirm = noop,
      children,
      cancelButton,
      confirmButton,
      isLoading = false,
      paddingSize = 'sm',
      isOpen: openByProp,
      ...props
    }: ConfirmProps,
    ref,
  ) => {
    const [openByRef, setOpenByRef] = useState(false)
    const [responseCallback, setResponseCallback] = useState<(response: boolean) => void>(() => noop)
    useImperativeHandle<ConfirmRef, ConfirmRef>(ref, () => {
      return {
        open: callback => {
          setResponseCallback(() => callback)
          setOpenByRef(true)
        },
      }
    })

    if (!confirmButton)
      confirmButton = (
        <FillButton
          onClick={() => {
            setOpenByRef(false)
            onConfirm(true)
            responseCallback(true)
          }}
          isLoading={isLoading}
        >
          Confirm
        </FillButton>
      )
    if (!cancelButton)
      cancelButton = (
        <GhostButton
          onClick={() => {
            setOpenByRef(false)
            onConfirm(false)
            responseCallback(false)
          }}
        >
          Cancel
        </GhostButton>
      )

    return (
      <Dialog opaqueBackdrop={false} isOpen={openByProp || openByRef} {...props} maxWidth="xs">
        <DialogContent paddingSize={paddingSize}>
          <div className="large">{children}</div>
          <ButtonContainer className="v-align mt-2">
            {cancelButton}
            {confirmButton}
          </ButtonContainer>
        </DialogContent>
      </Dialog>
    )
  },
)

const ButtonContainer = styled('div')`
  display: grid;
  grid-template-columns: auto auto;
  gap: var(--spacing-2);
  justify-content: end;
`
