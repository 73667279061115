import { IconProps } from './IconProps'

export function IconCheckOutline({ size = 18, color = 'currentColor', ...props }: IconProps) {
  return (
    <svg width={size} height={size} viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9 3.125C5.47918 3.125 2.625 5.97918 2.625 9.5C2.625 13.0208 5.47918 15.875 9 15.875C12.5208 15.875 15.375 13.0208 15.375 9.5C15.375 5.97918 12.5208 3.125 9 3.125ZM0 9.5C0 4.52944 4.02944 0.5 9 0.5C13.9706 0.5 18 4.52944 18 9.5C18 14.4706 13.9706 18.5 9 18.5C4.02944 18.5 0 14.4706 0 9.5Z"
        fill={color}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.2345 12.5776L4.875 9.19386L6.12617 7.93207L8.2345 10.0556L11.8577 6.40625L13.1089 7.66805L8.2345 12.5776Z"
        fill={color}
      />
    </svg>
  )
}
