import { styled } from '@mui/material'
import { Widget } from '@typeform/embed-react'

import {
  EapCoverageDetails,
  InsuranceCoverageDetails,
  PaymentPreference,
  useGetAppointmentCountForPatientAndProviderQuery,
  usePatientCoverageQuery,
} from '@nuna/api'

import { useVideoCallContext } from '../context/VideoCallContext'
import { TavaVideoUser } from '../types'
import { videoSceneExtraDark } from '../util/colors'
import { isPatient, isProvider } from '../util/videoUsers'

export function PostSessionSurvey() {
  const { appointmentId, expectedUsers, localUser } = useVideoCallContext()

  const patient = localUser.isProvider ? expectedUsers.find(isPatient) : localUser
  const provider = localUser.isProvider ? localUser : expectedUsers.find(isProvider)

  const patientId = patient?.patientId ?? ''
  const providerId = provider?.providerId ?? ''

  const { data } = usePatientCoverageQuery({ variables: { patientId }, skip: !patientId })
  const { data: countData } = useGetAppointmentCountForPatientAndProviderQuery({
    variables: {
      appointmentId,
      providerId,
      patientId,
    },
    skip: !appointmentId || !patientId || !providerId,
  })

  const currentCoverage = (data?.patientCoverage ?? [])[0]
  const sessionNumber = countData?.appointmentCountForPatientAndProvider || 1

  let payer = ''

  if (currentCoverage) {
    switch (currentCoverage.type) {
      case PaymentPreference.Insurance:
        payer = (currentCoverage.details as InsuranceCoverageDetails).planName
        break
      case PaymentPreference.Employer:
        payer = (currentCoverage.details as EapCoverageDetails).employerName
        break
      case PaymentPreference.Accesscode:
        payer = 'Access Code'
        break
      case PaymentPreference.Cash:
        payer = 'Out-of-pocket'
        break
      default:
        payer = 'N/A'
    }
  }

  const providerName = ({ firstName, lastName }: TavaVideoUser) => {
    return `${firstName} ${lastName}`
  }

  return (
    <SurveyContainer>
      <Widget
        id="mKZLSnff"
        style={{ width: '100%', height: '600px' }}
        opacity={0}
        hidden={{
          appointment_id: appointmentId,
          is_provider: localUser.isProvider ? 'yes' : 'no',
          host: window.location.host,
          session_number: `${sessionNumber}`,
          // will repeat in the order 1, 2, 3, 0 allowing us to iterate through 4 different questions
          session_question: `${sessionNumber % 4}`,
          provider_name: provider ? providerName(provider) : '',
          payer,
        }}
      ></Widget>
    </SurveyContainer>
  )
}

const SurveyContainer = styled('div')`
  align-items: center;
  background-color: ${videoSceneExtraDark};
  border-radius: 12px;
  display: flex;
  width: 100%;
  height: 100%;
  flex: 1 1 auto;
  overflow: hidden;
  position: relative;
`
