import { ProfileUser } from '../types/exported/user'
import { Gender, Pronouns } from '../types/internal-only/user.types'

export function userFullName(user?: Omit<ProfileUser, 'id'> | null, withTitle?: boolean) {
  const middleNameWithSpace = user?.middleName ? `${user?.middleName} ` : ''
  const name = `${user?.firstName} ${middleNameWithSpace}${user?.lastName}`

  if (withTitle && user?.__typename === 'Provider' && user.licenseTitle) {
    return `${name}, ${user.licenseTitle}`
  }

  return name
}

export const humanReadableGenders = {
  [Gender.Male]: 'Male',
  [Gender.Female]: 'Female',
  [Gender.TransMale]: 'Trans Male',
  [Gender.TransFemale]: 'Trans Female',
  [Gender.GenderNonConforming]: 'Gender Non-Conforming',
  [Gender.QuestioningGender]: 'Questioning Gender',
  [Gender.PreferNotToAnswer]: 'Prefer not to say',
}

export const humanReadablePronouns = {
  [Pronouns.HimHis]: 'Him/his',
  [Pronouns.HimThey]: 'Him/they',
  [Pronouns.TheyThem]: 'They/them',
  [Pronouns.HerHers]: 'Her/hers',
  [Pronouns.HerThey]: 'Her/they',
}

export const userService = {
  humanReadableGenders,
  humanReadablePronouns,
  userFullName,
}
