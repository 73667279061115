import { last } from 'lodash'
import { NavLink } from 'react-router-dom'

import { PatientHistoryItem, ViewLinkAndPreview } from '@nuna/api'
import { useEnvironmentContext } from '@nuna/environment'

import { HistorySessionItemProps, SessionItem } from './SessionItem'

interface CompletedSessionItemItemProps extends HistorySessionItemProps {
  historyItem: PatientHistoryItem
}

export function CompletedSessionItem({ historyItem, showDate }: CompletedSessionItemItemProps) {
  const meta = historyItem.meta as ViewLinkAndPreview
  const { commonRoutes } = useEnvironmentContext()
  const path = commonRoutes.sessionNote(noteIdFromViewLink(meta?.viewLink ?? ''))
  const address = ['Session', 'Appointment'].includes(historyItem.name) ? meta.address ?? 'Virtual' : undefined

  return historyItem.name === 'Session' ? (
    <NavLink className="block my-2 no-underline" to={path}>
      <SessionItem
        showDateOutside
        date={showDate && historyItem.date}
        title={historyItem.name}
        text={meta.preview}
        address={address}
      />
    </NavLink>
  ) : (
    <SessionItem
      showDateOutside
      date={showDate && historyItem.date}
      title={historyItem.name}
      text={meta.preview}
      address={address}
    />
  )
}

function noteIdFromViewLink(viewLink: string) {
  return last(viewLink.split('/')) ?? ''
}
