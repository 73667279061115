import { HTMLAttributes } from 'react'

import { CalendarSyncStatus, Provider } from '@nuna/api'
import { supportService } from '@nuna/telemetry'
import { Box, ContextualAlert, GhostButton } from '@nuna/tunic'

import { CalendarSyncHeader } from './CalendarSyncHeader'

interface Props extends HTMLAttributes<HTMLDivElement> {
  provider: Provider
  status: CalendarSyncStatus
  onRefresh: () => void
}

export function CalendarSyncSupport({ provider, status, onRefresh }: Props) {
  return (
    <Box>
      <CalendarSyncHeader status={status} provider={provider} onRefresh={onRefresh} />

      <div className="v-align" style={{ display: 'flex', flexFlow: 'row', justifyContent: 'space-between' }}>
        <ContextualAlert intent="urgent" role="status">
          We've connected this account, but can't find a primary calendar to sync
        </ContextualAlert>
      </div>

      <p className="text-secondary mt-4">
        To resolve this error, try removing your email account and adding it back again.
        <br />
        <br />
        If this error continues, please contact support.
      </p>

      <div className="v-align" style={{ display: 'flex', flexFlow: 'row', justifyContent: 'end' }}>
        <GhostButton variant="primary" onClick={supportService.openChat}>
          Contact Support
        </GhostButton>
      </div>
    </Box>
  )
}
