import { IconProps } from './IconProps'

export function IconNotebook({ size = 24, color = 'currentColor', ...props }: IconProps) {
  return (
    <svg width={size} height={size} viewBox="0 0 56 56" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M40.7476 6H9.22646V15.7778H8.13332C7.39694 15.7778 6.79999 16.3747 6.79999 17.1111V19.3333C6.79999 20.0697 7.39694 20.6667 8.13332 20.6667H9.22646V25.5556H8.13332C7.39694 25.5556 6.79999 26.1525 6.79999 26.8889V29.1111C6.79999 29.8475 7.39694 30.4444 8.13332 30.4444H9.22646V35.3333H8.13332C7.39694 35.3333 6.79999 35.9303 6.79999 36.6667V38.8889C6.79999 39.6253 7.39694 40.2222 8.13332 40.2222H9.22646V50H40.7476C44.8067 50 48.05 46.7249 48.05 42.6849V13.3151C48.05 9.30715 44.7806 6 40.7476 6ZM15.1725 20.6667H14.0794V25.5556H15.1725C15.9089 25.5556 16.5059 26.1525 16.5059 26.8889V29.1111C16.5059 29.8475 15.9089 30.4444 15.1725 30.4444H14.0794V35.3333H15.1725C15.9089 35.3333 16.5059 35.9303 16.5059 36.6667V38.8889C16.5059 39.6253 15.9089 40.2222 15.1725 40.2222H14.0794V45.1111H18.9323V10.8889H14.0794V15.7778H15.1725C15.9089 15.7778 16.5059 16.3747 16.5059 17.1111V19.3333C16.5059 20.0697 15.9089 20.6667 15.1725 20.6667ZM23.7853 10.8889V45.1111H40.7476C42.1209 45.1111 43.197 44.0304 43.197 42.6849V13.3151C43.197 11.999 42.0922 10.8889 40.7476 10.8889H23.7853Z"
        fill={color}
      />
    </svg>
  )
}
