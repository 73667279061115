import moment from 'moment'
import { useEffect } from 'react'

import { type PublicAvailabilitySlot, useProviderAvailabilityQuery } from '@nuna/api'
import { useAuthDataContext } from '@nuna/auth'
import { useAppointmentDrawerSearchParams } from '@nuna/common'
import { errorService } from '@nuna/core'
import { toast } from '@nuna/tunic'

export function useRejectInvalidTimeSlot(
  timeSlot: PublicAvailabilitySlot | undefined,
  providerId: string | undefined | null,
) {
  const { loggedIn } = useAuthDataContext()

  const { setTimeSlot } = useAppointmentDrawerSearchParams()

  const { error, data } = useProviderAvailabilityQuery({
    skip: !providerId || !loggedIn,
    variables: {
      providerId: providerId ?? '',
      toDate: moment().endOf('day').add(1, 'month').toISOString(),
    },
  })

  useEffect(() => {
    if (error) {
      console.error(error)
      toast.urgent(errorService.transformUserGraphqlError(error, 'Error loading provider availability'))
    }
  }, [error])

  useEffect(() => {
    if (data && timeSlot) {
      const validTime = data.provider.availableTimes.find(time => moment(time.start).isSame(moment(timeSlot.start)))

      if (!validTime) {
        setTimeSlot(null)
      }
    }
  }, [data, setTimeSlot, timeSlot])
}
