import { styled } from '@mui/material'
import { useFormikContext } from 'formik'
import { HTMLAttributes, useState } from 'react'

import { formService } from '@nuna/core'
import { GhostButton, IconCommentOutline, TextField } from '@nuna/tunic'

import { SessionForm } from '../../../types'

interface Props extends HTMLAttributes<HTMLDivElement> {
  required: boolean
}

export function PrivateNote({ required, ...props }: Props) {
  const [showField, setShowField] = useState(false)
  const { errors, touched, values, getFieldProps } = useFormikContext<SessionForm>()

  const shouldShowField = required || showField || !!values.reasonData
  return (
    <div {...props}>
      {!shouldShowField && (
        <GhostButton variant="secondary" type="button" className="caption" onClick={() => setShowField(true)}>
          Add private note
        </GhostButton>
      )}
      {shouldShowField && (
        <>
          <h3 className="h6 sans-serif mb-0">Private note</h3>
          <NoteContainer>
            <IconCommentOutline className="text-secondary comment-icon mr-1" />
            <TextField
              className="text-field"
              maxCharacters={7500}
              label={`Add context for your records ${required ? '(required)' : '(optional)'}`}
              multiline
              {...getFieldProps('reasonData')}
              {...formService.composeHelperTextWithError('', errors.reasonData, touched.reasonData)}
            />
          </NoteContainer>
        </>
      )}
    </div>
  )
}

const NoteContainer = styled('div')`
  display: flex;
  .comment-icon {
    margin-top: 1.35rem;
  }
  .text-field {
    flex: 1;
  }
`
