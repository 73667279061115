import { styled } from '@mui/material'
import { groupBy, range } from 'lodash'
import moment from 'moment'
import { Fragment, HTMLAttributes, ReactNode } from 'react'

import { PublicAvailabilitySlot } from '@nuna/api'
import { appointmentService } from '@nuna/core'
import { IconCalendarNone, borderGrey, eggshell, greySet, makeTypographyComponent } from '@nuna/tunic'

import timeslotSvg from './img/timeslot-bg.svg'

interface TimeslotsProps {
  timeslots: PublicAvailabilitySlot[]
  onSlotClick: (slot: PublicAvailabilitySlot) => void
  dayFormat?: string
}

export function Timeslots({ timeslots, onSlotClick, dayFormat = 'dddd, MMMM Do' }: TimeslotsProps) {
  const groupedSlots = groupBy(appointmentService.sortTimeSlots(timeslots), slot =>
    moment(slot.start).format(dayFormat),
  )

  return (
    <div>
      {Object.entries(groupedSlots).map(([day, slots]) => (
        <Fragment key={day}>
          <HeadingWithLine>{day}</HeadingWithLine>
          <TimeSlotGrid>
            {slots.map(slot => (
              <TimeSlotButton key={slot.start} onClick={() => onSlotClick(slot)}>
                <time dateTime={slot.start}>{moment(slot.start).format('h:mma')}</time>
              </TimeSlotButton>
            ))}
          </TimeSlotGrid>
        </Fragment>
      ))}
    </div>
  )
}

const TimeSlotButton = styled('button')`
  background-color: ${eggshell};
  border-radius: var(--border-radius-sm);
  padding: 0.75rem;

  &:hover {
    background-color: ${greySet[5].hex};
  }
`

const TimeSlotGrid = styled('div')`
  display: grid;
  gap: 0.5rem;
  grid-template-columns: 1fr 1fr 1fr;
  margin-bottom: var(--margin-3);
`

function HeadingWithLine({ children }: { children: ReactNode }) {
  return (
    <DayHeadingContainer className="v-align">
      <DayHeading>{children}</DayHeading>
    </DayHeadingContainer>
  )
}

export function TimeslotSkeleton(props: HTMLAttributes<HTMLDivElement>) {
  return (
    <div {...props}>
      {range(0, 3).map(index => (
        <Fragment key={index}>
          <DayHeading style={{ maxWidth: 150 }} className="loading mb-1">
            Loading...
          </DayHeading>
          <TimeSlotGrid>
            {range(0, 9).map(index => (
              <TimeSlotButton className="loading" key={index}>
                Loading...
              </TimeSlotButton>
            ))}
          </TimeSlotGrid>
        </Fragment>
      ))}
    </div>
  )
}

const defaultEmptyState = (
  <>
    <h3 className="h5">Unavailable</h3>
    <p>
      The selected date & location are unavailable to book. Please try changing the location or date for more options.
    </p>
  </>
)

export function TimeslotsEmptyState({ body = defaultEmptyState }: { body?: JSX.Element }) {
  return (
    <EmptyStateBG>
      <IconCalendarNone color={greySet['50'].hex} className="mb-2" size={48} />
      {body}
    </EmptyStateBG>
  )
}

const EmptyStateBG = styled('div')`
  // prettier-ignore
  background-image: url("${timeslotSvg}");
  padding: 1rem;
  height: 245px;
`

const DayHeadingContainer = styled('div')`
  margin-bottom: var(--margin-2);

  &::after {
    content: '';
    display: inline-block;
    width: 100%;
    height: 1px;
    background-color: ${borderGrey};
  }
`

const DayHeading = makeTypographyComponent('body text-secondary text-medium mb-0 mr-1 no-wrap', 'h4')
