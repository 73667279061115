import * as Yup from 'yup'
import { Form, Formik } from 'formik'
import { useState } from 'react'

import { PasswordValidator } from '@nuna/auth'
import { formService, signupService } from '@nuna/core'
import { FillButton, TextField } from '@nuna/tunic'

import { BaseSignupFormProps } from './shared'

const { composeHelperTextWithError } = formService

interface PasswordValues {
  password: string
  confirmPassword: string
}

const passwordSchema = Yup.object().shape({
  password: signupService.passwordSchema,
  confirmPassword: signupService.confirmPasswordSchema,
})

const initialValues: PasswordValues = {
  password: '',
  confirmPassword: '',
}

interface Props extends BaseSignupFormProps {
  createAccountLoading: boolean
}

export function SignupPassword({ onStepSubmit, step, formValues, createAccountLoading }: Props) {
  const [isPasswordFocused, setIsPasswordFocused] = useState(false)
  const [isConfirmPasswordFocused, setIsConfirmPasswordFocused] = useState(false)

  return (
    <Formik
      validationSchema={passwordSchema}
      initialValues={initialValues}
      onSubmit={passwordFormValues => onStepSubmit({ ...formValues, password: passwordFormValues.password }, step)}
    >
      {({ handleChange, handleBlur, values, errors, touched }) => (
        <Form>
          <h2 className="h3">Set up your password</h2>
          <TextField
            autoFocus
            label="Password"
            type="password"
            name="password"
            onChange={handleChange}
            onFocus={() => setIsPasswordFocused(true)}
            onBlur={e => {
              setIsPasswordFocused(false)
              handleBlur(e)
            }}
            value={values.password}
            inputProps={{
              'data-testid': 'signup-password',
            }}
            {...composeHelperTextWithError('', errors.password, touched.password)}
            className="mb-2"
          />

          <PasswordValidator
            password={values.password}
            isExpanded={isPasswordFocused}
            error={(errors.password && touched.password) as boolean}
            wrapperPadding="0 0 16px"
          />

          <TextField
            label="Confirm password"
            type="password"
            name="confirmPassword"
            value={values.confirmPassword}
            onChange={handleChange}
            onFocus={() => setIsConfirmPasswordFocused(true)}
            onBlur={e => {
              setIsConfirmPasswordFocused(false)
              handleBlur(e)
            }}
            {...composeHelperTextWithError('', errors.confirmPassword, touched.confirmPassword)}
            inputProps={{
              'data-testid': 'signup-confirm-password',
            }}
            className="mb-2"
          />

          <PasswordValidator
            password={values.confirmPassword}
            successMessage="Oh yeah, it's a match"
            isExpanded={isConfirmPasswordFocused}
            confirm
            error={(errors.confirmPassword && touched.confirmPassword) as boolean}
            matchingPassword={values.password}
            wrapperPadding="0 0 16px"
          />

          <FillButton className="mt-5" type="submit" isLoading={createAccountLoading} data-testid="password-continue">
            Continue
          </FillButton>
        </Form>
      )}
    </Formik>
  )
}
