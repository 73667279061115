import { IconProps } from './IconProps'

export function IconAISparkles({ size = 24, color = 'currentColor', ...props }: IconProps) {
  return (
    <svg width={size} height={size} viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M18.4351 35.5084C17.0356 35.3512 14.6764 34.7475 13.8378 33.8912C12.9992 33.0349 12.408 30.6287 12.254 29.1969C12.155 28.2844 11.4017 27.5937 10.5026 27.5937C9.6007 27.5937 8.8473 28.2843 8.7511 29.1969C8.63012 30.3171 8.08844 32.9422 7.16734 33.8912C6.32871 34.7475 3.97231 35.3512 2.57004 35.5084C1.67644 35.6095 1 36.3788 1 37.2968C1 38.2177 1.67638 38.987 2.57004 39.0853C3.66711 39.2088 6.23797 39.7619 7.16734 40.7025C8.00597 41.5588 8.59713 43.9649 8.7511 45.3968C8.85009 46.3093 9.60346 47 10.5026 47C11.4044 47 12.1578 46.3093 12.254 45.3968C12.375 44.2766 12.9167 41.6515 13.8378 40.7025C14.6764 39.8461 17.0328 39.2425 18.4351 39.0853C19.3287 38.9842 20.0051 38.2149 20.0051 37.3024C20.0051 36.3815 19.3287 35.6122 18.4351 35.514V35.5084ZM11.3469 38.1588C11.0307 38.4817 10.7475 38.8635 10.5027 39.2734C10.2553 38.8635 9.97482 38.4816 9.6586 38.1588C9.3424 37.8359 8.96843 37.5467 8.56703 37.2968C8.96848 37.0441 9.33416 36.7578 9.6586 36.4349C9.9748 36.112 10.258 35.7301 10.5027 35.3203C10.7502 35.7302 11.0307 36.112 11.3469 36.4349C11.6631 36.7577 12.0371 37.047 12.4384 37.2968C12.037 37.5495 11.6713 37.8359 11.3469 38.1588Z"
        fill={color}
      />
      <path
        d="M45.43 17.1802C43.332 16.95 37.3493 15.7989 34.9267 13.3282C32.5043 10.8546 31.3797 4.74566 31.1543 2.60318C31.0553 1.69072 30.3019 1 29.4028 1C28.501 1 27.7476 1.69066 27.6514 2.60318C27.4259 4.74542 26.2986 10.8545 23.879 13.3282C21.4566 15.8017 15.4739 16.95 13.3757 17.1802C12.4821 17.2813 11.8057 18.0506 11.8057 18.9686C11.8057 19.8895 12.482 20.6588 13.3757 20.7571C15.4737 20.9873 21.4564 22.1384 23.879 24.6091C26.3014 27.0826 27.4259 33.1916 27.6514 35.3341C27.7504 36.2466 28.5037 36.9373 29.4028 36.9373C30.3047 36.9373 31.0581 36.2466 31.1543 35.3341C31.3798 33.1919 32.5071 27.0828 34.9267 24.6091C37.3491 22.1356 43.3318 20.9873 45.43 20.7571C46.3236 20.656 47 19.8867 47 18.9686C47 18.0477 46.3236 17.2784 45.43 17.1802ZM32.4355 22.0655C31.0992 23.4299 30.1121 25.356 29.4027 27.3045C28.6905 25.356 27.7062 23.4299 26.3699 22.0655C25.0336 20.701 23.1474 19.693 21.2392 18.9686C23.1474 18.2414 25.0336 17.2363 26.3699 15.8718C27.7062 14.5073 28.6933 12.5813 29.4027 10.6328C30.1149 12.5813 31.0992 14.5073 32.4355 15.8718C33.7718 17.2363 35.658 18.2443 37.5662 18.9686C35.658 19.6958 33.7718 20.701 32.4355 22.0655Z"
        fill={color}
      />
      <path
        d="M11.91 7.46866C11.91 10.8238 6.98279 10.8238 6.98279 7.46866C6.98279 4.11352 11.91 4.11352 11.91 7.46866Z"
        fill={color}
      />
    </svg>
  )
}
