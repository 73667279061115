import { NoteObject } from '@nuna/api'

import { HistorySessionItemProps, SessionItem } from './SessionItem'

export function HistoryNoteSessionItem({ historyItem, showDate }: HistorySessionItemProps) {
  const meta = historyItem.meta as NoteObject

  return (
    <SessionItem
      showDateOutside
      card={false}
      date={showDate && historyItem.date}
      title={historyItem.name}
      note={meta.note}
    />
  )
}
