import React from 'react'
import FocusLock from 'react-focus-lock'

import { useAriaHide } from './useAriaHide'

interface FocusRegionProps {
  children?: JSX.Element
  isActive?: boolean
  shouldReturnFocus?: boolean
  shouldContainFocus?: boolean
  label?: string
}

// Content contained within a dialog (this can include modals, trays, etc) needs to be focused. The
// detailed specifications can be found here https://www.w3.org/TR/wai-aria-practices/#dialog_modal
// Essentially we need to ensure the following:
// * When a dialog opens, we should focus the first focusable element
// * Tabbing through the dialog, focus should wrap back around so that focus is always contained in
//   the content and is never lost
// * When the dialog is closed, we should return focus
// * When the dialog is open, all other elements in the document should be hidden from screen readers
//   This is done by applying `aria-hidden` to everything outside this content
//
// This component takes care of all the above for you \o/ so you don't have to worry about it.
// Just wrap your content with `FocusRegion` and set `isActive` to true to activate. Set `isActive`
// to false, or unmount the component to deactivate.

export function FocusRegion({ children, isActive = false, shouldReturnFocus = true, label }: FocusRegionProps) {
  const [setContainerRef] = useAriaHide(isActive)

  const container = (
    <div aria-label={label} role={label ? 'dialog' : ''} ref={setContainerRef}>
      {children}
    </div>
  )

  return isActive ? <FocusLock returnFocus={shouldReturnFocus}>{container}</FocusLock> : container
}
