import { styled } from '@mui/material'

import { ViewLinkFileDownloadAndPreview } from '@nuna/api'
import { IconButtonExternalLink, IconDocumentDeprecated, IconDownload } from '@nuna/tunic'

import { HistorySessionItemProps, SessionItem } from './SessionItem'

export function UploadSessionItem({ historyItem, showDate }: HistorySessionItemProps) {
  const meta = historyItem.meta as ViewLinkFileDownloadAndPreview

  return (
    <SessionItem
      showDateOutside
      date={showDate && historyItem.date}
      title={`${historyItem.name} •`}
      text={
        <>
          <FileIcon size={16} /> {meta.preview}
        </>
      }
      renderActions={
        <IconButtonExternalLink
          tooltip="Download file"
          small
          variant="secondary"
          href={meta.fileDownloadLink}
          download={meta.preview}
        >
          <IconDownload size={18} />
        </IconButtonExternalLink>
      }
    />
  )
}

const FileIcon = styled(IconDocumentDeprecated)`
  vertical-align: middle;
  margin: 0 0.25rem;
`
