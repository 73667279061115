import * as Yup from 'yup'
import { styled } from '@mui/material'
import { Field, Form, Formik } from 'formik'
import { noop, omit, pick } from 'lodash'
import { useState } from 'react'
import { useParams } from 'react-router-dom'

import {
  AddressOwner,
  AddressType,
  ProviderAddressInput,
  useRemoveProviderAddressMutation,
  useSaveProviderAddressMutation,
} from '@nuna/api'
import { useAuthDataContext } from '@nuna/auth'
import { AddressForm, type UsAddressValues } from '@nuna/common'
import { errorService, formService } from '@nuna/core'
import {
  Checkbox,
  Confirm,
  FillButton,
  GhostButton,
  Grid,
  OutlineButton,
  TextButtonExternalLink,
  TextField,
  tealSet,
  toast,
} from '@nuna/tunic'

const { composeHelperTextWithError } = formService

export interface LocationFormFields extends UsAddressValues {
  addressId?: string
  name: string
  usedNightsAndWeekends: boolean
  adaCompliant: {
    wheelchair: boolean
    publicTransport: boolean
    parking: boolean
  }
}

export const validationSchema = Yup.object().shape({
  name: Yup.string().required('Location nickname is required'),
  addressLineOne: Yup.string().required('Street address is required'),
  addressLineTwo: Yup.string().nullable(),
  city: Yup.string().required('City is required'),
  county: Yup.string().nullable().notRequired(),
  state: Yup.string().required('State is required'),
  zipCode: Yup.string().required('Zip code is required'),
  insurancePermission: Yup.boolean().oneOf([true], 'Insurance permission is required'),
  locationRequirements: Yup.boolean().oneOf([true], 'Confirmation of location requirements is required'),
  usedNightsAndWeekends: Yup.boolean(),
  adaCompliant: Yup.object({
    wheelchair: Yup.boolean(),
    publicTransport: Yup.boolean(),
    parking: Yup.boolean(),
  }),
  enforceValidAddress: Yup.boolean(),
  isValidAddress: Yup.boolean().when('enforceValidAddress', {
    is: true,
    then: Yup.boolean().oneOf([true], 'Address must be valid'),
  }),
})

interface LocationFormProps {
  initialFormData?: LocationFormFields
  onCompleted?: () => void
  isTavaVirtual?: boolean
  canEdit: boolean
  isEmbedded?: boolean
}

export function LocationForm({
  canEdit,
  initialFormData,
  onCompleted = noop,
  isTavaVirtual = false,
  isEmbedded = false,
}: LocationFormProps) {
  const { login } = useAuthDataContext()
  const { id: pathProviderId } = useParams<{ id?: string }>()
  const { providerId: loginProviderId } = login ?? {}
  const providerId = loginProviderId || pathProviderId
  const [saveProviderAddress, { loading: isLoadingSaveProvider }] = useSaveProviderAddressMutation({
    refetchQueries: ['ProviderAddresses'],
  })
  const [removeProviderAddress, { loading: isLoadingRemoveProvider }] = useRemoveProviderAddressMutation({
    refetchQueries: ['ProviderAddresses'],
  })
  const [showRemoveConfirmation, setShowRemoveConfirmation] = useState(false)
  const handleRemove = (confirmed: boolean) => {
    if (confirmed) {
      handleRemoveProviderAddress(initialFormData?.addressId ?? '')
    }
    setShowRemoveConfirmation(false)
  }

  const disabled = !canEdit

  const initialValues: LocationFormFields = {
    addressId: undefined,
    name: '',
    addressLineOne: '',
    addressLineTwo: '',
    city: '',
    state: '',
    zipCode: '',
    usedNightsAndWeekends: false,
    adaCompliant: {
      wheelchair: false,
      publicTransport: false,
      parking: false,
    },
    enforceValidAddress: false,
    isValidAddress: false,
    ...initialFormData,
  }
  const isEditMode = Object.values(omit(initialFormData, ['isValidAddress', 'enforceValidAddress']) ?? {}).some(
    value => !!value && typeof value !== 'object',
  )
  const handleSaveProviderAddress = async (values: LocationFormFields) => {
    const {
      adaCompliant: { parking, publicTransport, wheelchair },
    } = values
    const formattedValues: ProviderAddressInput = {
      ...pick(values, [
        'addressId',
        'name',
        'addressLineOne',
        'addressLineTwo',
        'city',
        'county',
        'state',
        'zipCode',
        'usedNightsAndWeekends',
        'latitude',
        'longitude',
      ]),
      adaPublicParkingAccessible: parking,
      adaPublicTransitAccessible: publicTransport,
      adaWheelchairAccessible: wheelchair,
      addressType: AddressType.ProviderPractice,
      country: 'US',
      owner: isTavaVirtual ? AddressOwner.Tava : AddressOwner.Provider,
      providerId: providerId,
    }

    try {
      const result = await saveProviderAddress({ variables: { address: formattedValues } })
      if (result.data?.saveProviderAddress) {
        toast.success('Address saved')
      }
    } catch (e) {
      toast.urgent(errorService.transformGraphQlError(e, 'Error saving address'))
    }
    onCompleted()
  }
  const handleRemoveProviderAddress = async (addressId: string) => {
    try {
      const result = await removeProviderAddress({ variables: { providerId: providerId ?? '', addressId } })
      if (result.data?.removeProviderAddress) {
        toast.success('Address removed')
      }
    } catch (e) {
      toast.urgent(errorService.transformGraphQlError(e, 'Error removing address'))
    }
    onCompleted()
  }

  return (
    <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={handleSaveProviderAddress}>
      {({ values, errors, touched, handleChange }) => (
        <StyledLocationForm>
          {!canEdit && (
            <p className="text-primary large pt-2">
              This location has been submitted to insurance payers and can therefore no longer be edited. If you need to
              make a change, you can delete this location and add a new one. Be aware that doing so will initiate a new
              location approval process by insurance payers.
            </p>
          )}
          <Field
            disabled={disabled}
            as={TextField}
            name="name"
            label="Location nickname"
            fullWidth
            {...composeHelperTextWithError('', errors.name, touched.name)}
            inputProps={{
              'data-testid': 'Name',
            }}
            className="mt-4"
          />
          <div className="mt-4">
            <AddressForm gridSpacing={2} canEdit={canEdit} allowInvalidAddress={false} hideCounty={true} />
          </div>
          <div className="my-4">
            <Checkbox
              className="my-1"
              error={!!errors.usedNightsAndWeekends && !!touched.usedNightsAndWeekends}
              checked={values.usedNightsAndWeekends}
              name="usedNightsAndWeekends"
              onChange={handleChange}
              labelProps={{
                'data-testid': 'usedNightsAndWeekendsLabel',
              }}
              disabled={disabled}
            >
              <span>I conduct sessions on weekends or after regular business hours at this location</span>
            </Checkbox>
            <Checkbox
              className="my-1"
              error={!!errors.adaCompliant?.wheelchair && !!touched.adaCompliant?.wheelchair}
              checked={values.adaCompliant.wheelchair}
              name="adaCompliant.wheelchair"
              onChange={handleChange}
              labelProps={{
                'data-testid': 'ada-compliant-wheelchair',
              }}
              disabled={disabled}
            >
              <span>
                This location is{' '}
                <TextButtonExternalLink href="https://www.ada.gov/topics/mobility-devices/" variant="secondary">
                  wheelchair accessible
                </TextButtonExternalLink>
              </span>
            </Checkbox>
            <Checkbox
              className="my-1"
              error={!!errors.adaCompliant?.publicTransport && !!touched.adaCompliant?.publicTransport}
              checked={values.adaCompliant.publicTransport}
              name="adaCompliant.publicTransport"
              onChange={handleChange}
              labelProps={{
                'data-testid': 'ada-compliant-public-transport',
              }}
              disabled={disabled}
            >
              <span>
                This location is near accessible{' '}
                <TextButtonExternalLink
                  href="https://www.ada.gov/topics/intro-to-ada/#public-transit"
                  variant="secondary"
                >
                  public transportation
                </TextButtonExternalLink>
              </span>
            </Checkbox>
            <Checkbox
              className="my-1"
              error={!!errors.adaCompliant?.parking && !!touched.adaCompliant?.parking}
              checked={values.adaCompliant.parking}
              name="adaCompliant.parking"
              onChange={handleChange}
              labelProps={{
                'data-testid': 'ada-compliant-parking',
              }}
              disabled={disabled}
            >
              <span>
                This location has{' '}
                <TextButtonExternalLink href="https://www.ada.gov/topics/parking/" variant="secondary">
                  accessible car parking and entry
                </TextButtonExternalLink>
              </span>
            </Checkbox>
          </div>
          <div className="mt-2">
            By saving, I attest that this address is{' '}
            <TextButtonExternalLink
              href="https://therapist-support.tavahealth.com/hc/en-us/articles/20346497910548-In-person-sessions"
              variant="secondary"
            >
              clean, comfortable and safe
            </TextButtonExternalLink>
            , and give Tava permission to list it in insurance payer directories.
          </div>
          <div className="mt-3 v-align">
            {/* 
              this change is to ensure, if this component is embedded in another form
              that we don't have multiple submit buttons getting in each others way
            */}
            {isEmbedded ? (
              <OutlineButton
                onClick={() => handleSaveProviderAddress(values)}
                isLoading={isLoadingSaveProvider || isLoadingRemoveProvider}
              >
                Save Location
              </OutlineButton>
            ) : (
              <OutlineButton
                type="submit"
                isLoading={isLoadingSaveProvider || isLoadingRemoveProvider}
                disabled={disabled}
              >
                Save Location
              </OutlineButton>
            )}

            <GhostButton
              className="ml-2"
              onClick={onCompleted}
              isLoading={isLoadingSaveProvider || isLoadingRemoveProvider}
              variant="secondary"
            >
              Cancel
            </GhostButton>
            {isEditMode && (
              <Grid container alignItems="flex-end">
                <Grid size={12}>
                  <GhostButton
                    variant="destroy"
                    className="ml-2"
                    onClick={() => setShowRemoveConfirmation(true)}
                    isLoading={isLoadingRemoveProvider || isLoadingSaveProvider}
                    style={{ float: 'right' }}
                  >
                    Delete
                  </GhostButton>
                </Grid>
              </Grid>
            )}
          </div>
          <Confirm
            isOpen={showRemoveConfirmation}
            onConfirm={handleRemove}
            confirmButton={
              <FillButton onClick={() => handleRemove(true)} isLoading={isLoadingRemoveProvider}>
                Remove
              </FillButton>
            }
          >
            Are you sure you want to remove this address?
          </Confirm>
        </StyledLocationForm>
      )}
    </Formik>
  )
}
const StyledLocationForm = styled(Form)`
  margin-top: -1.5rem;
  color: ${tealSet[90].hex};
`
