import * as Sentry from '@sentry/browser'
import mixpanel from 'mixpanel-browser'
import { useEffect, useState } from 'react'

import { useAuthDataContext } from '@nuna/auth'
import { sessionCaptureService } from '@nuna/telemetry'

interface Beamer {
  init(): void
  update({
    user_id,
    user_email,
    user_firstname,
    user_lastname,
    filter,
    button,
    top,
    right,
  }: {
    user_id: string
    user_email: string
    user_firstname: string
    user_lastname: string
    filter: string
    button: boolean
    top: number
    right: number
  }): void
}

declare const Beamer: Beamer
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
declare const pendo: pendo.Pendo

export const Telemetry = () => {
  const [beamerLoaded, setBeamerLoaded] = useState('Beamer' in window)
  const { login } = useAuthDataContext()

  useEffect(() => {
    if (beamerLoaded) {
      console.error('[DEBUG] Beamer loaded')
      return
    }

    const pid = setInterval(() => {
      console.error('[DEBUG] checking for Beamer ...')
      setBeamerLoaded('Beamer' in window)
    }, 1000)

    return () => {
      console.error('[DEBUG] cleaning up Beamer check')
      clearInterval(pid)
    }
  }, [beamerLoaded])

  useEffect(() => {
    if (import.meta.env.PROD && beamerLoaded && login) {
      console.error('[DEBUG] initializing Beamer')

      Beamer.init()
      // https://www.getbeamer.com/docs
      Beamer.update({
        user_id: login.id,
        user_email: login.email,
        user_firstname: login.firstName,
        user_lastname: login.lastName,
        filter: `therapist;${import.meta.env.MODE}`,
        button: false, // don't show popup button
        top: 15, // alert bubble position from top
        right: 60, // alert bubble position from right
      })
    }
  }, [beamerLoaded, login])

  useEffect(() => {
    if (login) {
      if (import.meta.env.PROD) {
        Sentry.setUser({
          email: login ? login.email : '',
          id: login ? login.id : '',
        })

        mixpanel.identify(login.id)
        mixpanel.register({
          id: login.id,
          name: `${login.firstName} ${login.lastName}`,
          role: login.role,
          email: login.email,
        })

        mixpanel.people.set({
          id: login.id,
          name: `${login.firstName} ${login.lastName}`,
          email: login.email,
          providerId: login.providerId,
        })

        pendo.initialize({
          visitor: {
            id: login.id,
            role: login.role,
          },
        })

        sessionCaptureService.identify(login)
      }
    }
  }, [login])

  return null
}
