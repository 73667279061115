import { styled } from '@mui/material'
import { ReactNode, useCallback, useState } from 'react'

import { IconClose } from '../../../icons'
import { greySet, tealSet } from '../../../styles/colorSets'
import { shadowDepth } from '../../../styles/shadows'
import { Avatar } from '../../Avatar/Avatar'
import { IconButton } from '../../Button'
import { Drawer } from '../../Drawer/Drawer'
import { MenuItemStack } from '../../MenuItem/MenuItem'
import { useCloseOnRouteChange } from '../useCloseOnRouteChange'
import { Hamburger } from './Hamburger'
import { NavButton } from './NavButton'

interface MobileNavMenuProps {
  avatarUrl: string | null | undefined
  firstName: string | null | undefined
  children: ReactNode
}

export function MobileNavMenu({ avatarUrl, firstName, children }: MobileNavMenuProps) {
  const [isOpen, setIsOpen] = useState(false)

  const handleClose = useCallback(() => setIsOpen(false), [])

  useCloseOnRouteChange(handleClose)

  return (
    <>
      <NavButton data-testid="patient-nav" onClick={() => setIsOpen(true)}>
        <Avatar className="fs-exclude" size="xs" url={avatarUrl ?? undefined} />
        <Hamburger />
      </NavButton>

      <StyledDrawer isOpen={isOpen} onClose={handleClose} size="90%">
        <DrawerMenuHeader>
          <Avatar size="xs" url={avatarUrl} className="mr-1 fs-exclude" />

          <h3 className="h5 mb-0">{firstName}</h3>
          <CloseButton tooltip="Close" onClick={handleClose}>
            <IconClose size={16} style={{ color: greySet.primary.hex }} />
          </CloseButton>
        </DrawerMenuHeader>

        <MenuItemStack component="nav">{children}</MenuItemStack>
      </StyledDrawer>
    </>
  )
}

const DrawerMenuHeader = styled('div')`
  font-weight: 600;
  margin-top: 1rem;
  padding: 0 12px;
`
DrawerMenuHeader.defaultProps = { className: 'v-align mb-3' }

const StyledDrawer = styled(Drawer)`
  border-radius: 0;

  .MuiPaper-root {
    background-color: ${greySet[0].hex};
    padding: 1rem;
  }

  a,
  button {
    align-items: center;
    border-radius: 6px;
    display: flex;
    padding: 12px;
    margin-bottom: 0.25rem;
    font-weight: 500;
    height: 52px;
    font-size: 22px;

    &:hover {
      background-color: ${greySet[30].hex};
      color: ${greySet[90].hex};
    }

    &.focus-visible {
      box-shadow: 0 0 0 4px ${greySet[15].hex};
      outline: none;
    }

    &.active,
    &.active:hover {
      background-color: ${greySet[15].hex};
      color: ${tealSet[80].hex};
    }

    &:hover {
      text-decoration: none;
      background-color: transparent;
    }
  }
`

const CloseButton = styled(IconButton)`
  && {
    margin-left: auto;
    transform: translateX(12px); // aligns with hamburger underneath in case I implement the animation

    background-color: #fff;
    width: 46px;
    border-radius: 46px;
    border: 1px solid ${greySet[5].hex};
    box-shadow: ${shadowDepth(1)};
    height: 46px;

    &:hover {
      background-color: #fff;

      &::before {
        display: none;
      }
    }
  }
`
