import { type Moment } from 'moment'
import { HTMLAttributes, useState } from 'react'

import {
  DrawerAppointmentFragment,
  ProviderDetailsQuery,
  ProviderType,
  PublicProviderFragment,
  usePatientContextQuery,
} from '@nuna/api'
import { useAuthDataContext } from '@nuna/auth'

import { AppointmentDrawerPaddedContainer } from '../../../AppointmentDrawer'
import { PrescriberPrompt } from '../PrescriberPrompt'
import { TimezoneMismatchPrompt } from '../TimezoneMismatchPrompt'
import { CalendarPeriodTimeSlots } from './CalendarContractPeriodTimeSlots'

interface Props extends HTMLAttributes<HTMLDivElement> {
  reschedulingAppointmentId?: string
  provider: PublicProviderFragment | ProviderDetailsQuery['provider'] | DrawerAppointmentFragment['provider']
  patient: DrawerAppointmentFragment['patient'] | undefined
  date?: Moment
  addressId?: string | null
  rescheduleAppointmentId?: string
}

export function CalendarTimeSlotSelect({ provider, ...props }: Props) {
  const { loggedIn } = useAuthDataContext()
  const { data: patientContextData } = usePatientContextQuery({ skip: !loggedIn })
  const [isPrescriberPromptDismissed, setIsPrescriberPromptDismissed] = useState(false)
  const [timezoneToUse, setTimezoneToUse] = useState(patientContextData?.patientContext.patient?.timezone || '')

  const patientTimezone = patientContextData?.patientContext.patient?.timezone || ''
  const browserTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone

  if ('type' in provider && provider.type === ProviderType.Prescriber && !isPrescriberPromptDismissed) {
    return (
      <AppointmentDrawerPaddedContainer>
        <PrescriberPrompt
          className="mt-5"
          compact
          onDismiss={() => setIsPrescriberPromptDismissed(true)}
          provider={provider}
        />
      </AppointmentDrawerPaddedContainer>
    )
  }

  if (timezoneToUse !== browserTimezone && !!patientContextData) {
    return (
      <AppointmentDrawerPaddedContainer>
        <TimezoneMismatchPrompt
          className="mt-5"
          compact
          patientTimezone={patientTimezone}
          browserTimezone={browserTimezone}
          onDismiss={() => setTimezoneToUse(browserTimezone)}
        />
      </AppointmentDrawerPaddedContainer>
    )
  }

  return <CalendarPeriodTimeSlots provider={provider} {...props} />
}
