import { useEffect, useState } from 'react'

export function useVisibilityChanged() {
  const [visible, setVisibility] = useState(document.visibilityState === 'visible')

  useEffect(() => {
    const listener = () => {
      setVisibility(document.visibilityState === 'visible')
    }

    document.addEventListener('visibilitychange', listener)

    return () => {
      document.removeEventListener('visibilitychange', listener)
    }
  })

  return visible
}
