import { MouseEvent, ReactNode, useCallback, useState } from 'react'

import { Avatar } from '../../Avatar/Avatar'
import { Menu } from '../../Menu/Menu'
import { MenuItemStack } from '../../MenuItem/MenuItem'
import { useCloseOnRouteChange } from '../useCloseOnRouteChange'
import { Hamburger } from './Hamburger'
import { NavButton } from './NavButton'

interface DesktopNavMenuProps {
  avatarUrl: string | null | undefined
  children: ReactNode
}

export function DesktopNavMenu({ avatarUrl, children }: DesktopNavMenuProps) {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const isOpen = !!anchorEl

  const handleClose = useCallback(() => setAnchorEl(null), [])

  useCloseOnRouteChange(handleClose)

  return (
    <>
      <NavButton data-testid="patient-nav" onClick={(e: MouseEvent<HTMLButtonElement>) => setAnchorEl(e.currentTarget)}>
        <Avatar className="fs-exclude" size="xs" url={avatarUrl} />
        <Hamburger isOpen={isOpen} />
      </NavButton>

      <Menu open={isOpen} anchorEl={anchorEl} onClose={handleClose} anchorOrigin={{ vertical: 48, horizontal: 'left' }}>
        <MenuItemStack component="nav">{children}</MenuItemStack>
      </Menu>
    </>
  )
}
