import { ApolloQueryResult } from '@apollo/client'
import { styled } from '@mui/material'
import { CircularProgress } from '@mui/material'

import { CustomerCompanyDetailsFragment, CustomerCompanyQuery } from '@nuna/api'

import { CustomerEmployeeDimensionList } from './CustomerIntegration/CustomerEmployeeDimensions/CustomerEmployeeDimensionList'
import { HrisIntegrations } from './CustomerIntegration/HrisIntegrations'
import { RosterUpload } from './CustomerIntegration/RosterUpload'
import { SftpInfo } from './CustomerIntegration/SftpInfo'

interface Props {
  customerCompany: CustomerCompanyDetailsFragment
  refetchCustomerCompany: () => Promise<ApolloQueryResult<CustomerCompanyQuery>>
  isAdmin: boolean
}

export function CustomerRosters({ isAdmin, customerCompany, refetchCustomerCompany }: Props) {
  if (!customerCompany) {
    return (
      <div className="mt-4">
        <CircularProgress className="ml-1" />
      </div>
    )
  }

  return (
    <SyncContainer className="mt-2">
      {customerCompany.supportsHrisIntegration && (
        <div>
          <HrisIntegrations
            isAdmin={isAdmin}
            customerCompany={customerCompany}
            refetchCustomerCompany={refetchCustomerCompany}
          />
        </div>
      )}
      {!customerCompany.supportsHrisIntegration && (
        <div className="flex-column gap-4">
          {isAdmin && <CustomerEmployeeDimensionList customerCompany={customerCompany} />}

          {isAdmin && (
            <RosterUpload customerCompany={customerCompany} refetchCustomerCompany={refetchCustomerCompany} />
          )}

          <SftpInfo customerCompany={customerCompany} />
        </div>
      )}
    </SyncContainer>
  )
}

const SyncContainer = styled('div')`
  display: flex;
  justify-content: flex-start;
`
