import { startCase } from 'lodash'

import { Patient } from '@nuna/api'
import { ReadOnlyProfileSection } from '@nuna/common'
import { carePreferencesService } from '@nuna/core'
import { Chip, ChipGroup, InfoWithHeading } from '@nuna/tunic'

import { parseTherapistPreferences } from '../../../util/parseTherapistPreferences'

const { getProviderAgeLabel, getProviderAvailabilityLabel, getProviderGenderLabel } = carePreferencesService

interface ClientProfileTherapistPreferencesProps {
  patient: Pick<Patient, 'therapistPreferences'> | undefined
}

const heading = 'Therapist Preferences'

export function ClientProfileTherapistPreferences({ patient }: ClientProfileTherapistPreferencesProps) {
  if (!patient) {
    return <ReadOnlyProfileSection isLoading heading={heading} renderDisplayValue={null} />
  }

  const therapistPreferences = parseTherapistPreferences(patient.therapistPreferences)

  return (
    <ReadOnlyProfileSection
      heading={heading}
      displayWidth="full"
      renderDisplayValue={
        <>
          <InfoWithHeading
            heading="Availability"
            renderInfo={
              <ChipGroup>
                {therapistPreferences.therapistAvailability.map(timeAvailable => (
                  <Chip key={timeAvailable} disabled small>
                    {getProviderAvailabilityLabel(timeAvailable)}
                  </Chip>
                ))}
              </ChipGroup>
            }
          />
          <InfoWithHeading
            heading="Gender"
            renderInfo={
              <ChipGroup>
                {therapistPreferences.therapistGender.map(gender => (
                  <Chip key={gender} disabled small>
                    {getProviderGenderLabel(gender)}
                  </Chip>
                ))}
              </ChipGroup>
            }
          />
          <InfoWithHeading
            heading="Age"
            renderInfo={
              <ChipGroup>
                {therapistPreferences.therapistAge.map(age => (
                  <Chip key={age} disabled small>
                    {getProviderAgeLabel(age)}
                  </Chip>
                ))}
              </ChipGroup>
            }
          />
          <InfoWithHeading
            heading="Most Important"
            info={startCase(therapistPreferences.mostImportantAttribute.toLowerCase())}
          />
        </>
      }
    />
  )
}
