import * as Yup from 'yup'
import moment from 'moment'

import { Gender, Patient, PatientDetailsQuery, useUpdateClientPersonalInfoMutation } from '@nuna/api'
import { useIsAdmin } from '@nuna/auth'
import { ProfileSection, ReadOnlyProfileSection } from '@nuna/common'
import { formService, userService } from '@nuna/core'
import {
  DOBTextField,
  Grid,
  HelperText,
  InfoWithHeading,
  Radio,
  RadioGroup,
  StatusLabel,
  TimezoneSelect,
  toast,
} from '@nuna/tunic'

const { humanReadableGenders } = userService

const validationSchema = Yup.object().shape({
  id: Yup.string().required(),
  dob: Yup.date()
    .required('Date of birth is required')
    .min(moment().subtract('125', 'years').toDate(), 'Date of birth is out of range')
    .max(moment().subtract('13', 'years').toDate(), 'Therapy is only available to age 13+')
    .typeError('Date is invalid'),
  timezone: Yup.string().required(),
  gender: Yup.mixed().oneOf(Object.values(Gender)).required('Gender is required'),
})

type PersonalInfoPatient = Pick<Patient, 'id' | 'gender' | 'dob' | 'timezone'>

interface PatientProfilePersonalInfoProps {
  patient?: PatientDetailsQuery['patient']
}

const heading = 'Personal Info'

export function ClientProfilePersonalInfo({ patient }: PatientProfilePersonalInfoProps) {
  const isAdmin = useIsAdmin()
  const [updateClientPersonalInfo, { loading }] = useUpdateClientPersonalInfoMutation({
    refetchQueries: ['PatientDetails'],
  })

  if (!patient) {
    return <ReadOnlyProfileSection isLoading heading={heading} renderDisplayValue={null} />
  }

  const initialValues: PersonalInfoPatient = {
    id: patient.id,
    dob: moment(patient?.dob).format('MM/DD/YYYY'),
    timezone: patient.timezone ?? '',
    gender: patient.gender ?? '',
  }
  const isMinor = moment().diff(patient.dob, 'years') < 18 ? 'Minor' : undefined
  const isMinorAndIsMissingParentalConsent = isMinor && !patient.startIntakePermitted
  const isMinorAndHasParentalConsent = isMinor && patient.startIntakePermitted
  const minorLabel = isMinorAndHasParentalConsent ? (
    <StatusLabel intent="information" scheme="light">
      Guardian Consent Form Signed
    </StatusLabel>
  ) : isMinorAndIsMissingParentalConsent ? (
    <StatusLabel intent="caution" scheme="light">
      Guardian Consent Form Required
    </StatusLabel>
  ) : undefined

  return (
    <ProfileSection
      heading={heading}
      description={null}
      isLoading={loading}
      initialValues={initialValues}
      validationSchema={validationSchema}
      handleSubmit={async values => {
        try {
          await updateClientPersonalInfo({
            variables: {
              patientId: patient.id,
              ...values,
              gender: values.gender as Gender,
            },
          })

          toast.success('Updated personal info successfully')
        } catch (e) {
          console.error(e)
          toast.urgent('Unable to update personal info')
        }
      }}
      showEditButton={isAdmin}
      displayWidth="full"
      renderDisplayValue={
        <>
          <InfoWithHeading heading="Gender" renderInfo={<StatusLabel>{patient.gender}</StatusLabel>} />
          <InfoWithHeading
            heading="Birthday"
            info={moment(patient.dob).format('MMMM DD, YYYY')}
            secondaryInfo={`${moment().diff(patient.dob, 'years')} years old`}
            label={minorLabel}
          />
          <InfoWithHeading heading="Timezone" info={patient.timezone} />
        </>
      }
      renderForm={({ values, handleChange, handleBlur, setFieldValue, setTouched, touched, errors }) => (
        <div className="mr-5">
          <Grid container spacing={2}>
            <Grid
              size={{
                xs: 12,
                md: 6,
              }}
            >
              <RadioGroup {...formService.composeHelperTextWithError('', errors.gender, touched.gender)}>
                <HelperText className="mb-1-5" error={false}>
                  Gender
                </HelperText>
                {Object.values(Gender).map((option, idx) => (
                  <Grid key={idx} className="column">
                    <Radio
                      data-testid={`gender-option-${option}`}
                      name="gender"
                      onChange={ev => setFieldValue('gender', ev.target.value)}
                      value={option}
                      checked={values.gender === option}
                      key={option}
                    >
                      {humanReadableGenders[option]}
                    </Radio>
                  </Grid>
                ))}
              </RadioGroup>
            </Grid>
            <Grid
              size={{
                xs: 12,
                md: 6,
              }}
            >
              <Grid>
                <DOBTextField
                  name="dob"
                  label="Date of Birth"
                  value={values.dob}
                  placeholder="MM/DD/YYYY"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={(errors.dob && touched.dob) as boolean}
                  helperText={touched.dob && !!errors.dob ? (errors.dob as string) : null}
                />
              </Grid>
              <Grid>
                <TimezoneSelect
                  name="timezone"
                  label="Timezone"
                  value={values.timezone}
                  onChange={timezone => setFieldValue('timezone', timezone)}
                  inputProps={{
                    onFocus: () => setTouched({ timezone: true }),
                    error: touched.timezone && !!errors.timezone,
                    helperText: touched.timezone && errors.timezone ? errors.timezone : null,
                  }}
                  style={{ minWidth: 300 }}
                />
              </Grid>
            </Grid>
          </Grid>
        </div>
      )}
    />
  )
}
