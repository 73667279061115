import moment from 'moment-timezone'
import { HTMLAttributes, useMemo } from 'react'

import { AuthorizedInternationalRegionFragment, InternationalPhoneNumberType } from '@nuna/api'
import { Link } from '@nuna/common'
import { addressService } from '@nuna/core'
import { DataTable, DataTableColumn } from '@nuna/data-table'

const { getCountryNameFromAbbreviation } = addressService

interface Props extends HTMLAttributes<HTMLDivElement> {
  rows: AuthorizedInternationalRegionFragment[]
}

const columns: DataTableColumn<AuthorizedInternationalRegionFragment>[] = [
  { accessor: 'email', Header: 'Email' },
  { accessor: 'value', Header: 'Name', Cell: ({ value }) => <>{getCountryNameFromAbbreviation(value)}</> },
  { accessor: 'sessionCap', Header: 'Session Cap' },
  {
    accessor: 'resourceSite',
    Header: 'Resource Website',
    Cell: ({ value }) => (
      <Link to={value} openInNewTab>
        {value}
      </Link>
    ),
  },
  {
    accessor: 'firstAvailableDate',
    Header: 'First Available Date',
    Cell: ({ value }) => <>{moment.utc(value).format('ll hh:mm a')}</>,
  },
  {
    accessor: 'wpoPhoneNumbers',
    Header: 'Phone Numbers',
    Cell: ({ value }) => (
      <>
        {value.map(v => (
          <div key={v.phoneNumber}>
            {readablePhoneType(v.phoneType)} : {v.phoneNumber}
          </div>
        ))}
      </>
    ),
  },
]

export function InternationalAreasTable({ rows, ...props }: Props) {
  const rowData = useMemo(() => rows, [rows])
  return (
    <div {...props}>
      <DataTable loading={false} columns={columns} rowData={rowData} />
    </div>
  )
}

function readablePhoneType(phoneType: InternationalPhoneNumberType) {
  if (phoneType === InternationalPhoneNumberType.DirectDial) {
    return 'Direct'
  } else if (phoneType === InternationalPhoneNumberType.TollFree) {
    return 'Toll Free'
  }
  return ''
}
