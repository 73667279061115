import { styled } from '@mui/material'
import { debounce } from 'lodash'
import { ChangeEvent, HTMLAttributes } from 'react'

import { AssessmentBundleType, useProviderPreferencesQuery, useSaveProviderPreferencesMutation } from '@nuna/api'
import { LexicalAssessment } from '@nuna/assessments'
import { useIsAdmin } from '@nuna/auth'
import { AuthorizedAvatar, useDelayedLoading } from '@nuna/common'
import { errorService } from '@nuna/core'
import {
  Card,
  RichTextArea,
  RichTextEditorContext,
  Switch,
  greySet,
  makeTypographyComponent,
  rteUtils,
  toast,
} from '@nuna/tunic'

import { CollapsablePreferences } from '../CaseloadManagement/CollapsablePreferences'

interface Props extends HTMLAttributes<HTMLDivElement> {
  providerId?: string | null
}

const DEFAULT_AUTOMATED_ASSESSMENT_MESSAGE = `It looks like it has been 4 weeks since you last took an assessment. Would you mind taking this to check in on how you’re doing?`

function MessageBody({ onChange, data }: { onChange: (content: string) => void; data?: string }) {
  return (
    <RichTextEditorContext
      initialEditorState={JSON.stringify({
        root: {
          type: 'root',
          format: '',
          indent: 0,
          version: 1,
          children: [
            {
              type: 'paragraph',
              format: '',
              indent: 0,
              version: 1,
              children: [
                {
                  mode: 'normal',
                  text: data,
                  type: 'text',
                  style: '',
                  detail: 0,
                  format: 0,
                  version: 1,
                },
              ],
              direction: 'ltr',
            },
          ],
          direction: 'ltr',
        },
      })}
      editable
      onChange={async editor => {
        try {
          const content = await rteUtils.export.getPlainText(editor)
          if (content !== DEFAULT_AUTOMATED_ASSESSMENT_MESSAGE) {
            onChange && onChange(content)
          }
        } catch (e) {
          console.error(e)
        }
      }}
      nodes={[]}
    >
      <RichTextArea />
      <LexicalAssessment
        assessmentId={null}
        assessmentType={AssessmentBundleType.MentalHealthAssessment}
        nodeKey="ass1"
        showRemoveAssessment={false}
      />
    </RichTextEditorContext>
  )
}

export function AssessmentPreferences({ providerId }: Props) {
  const isAdmin = useIsAdmin()
  const { data, loading } = useProviderPreferencesQuery({
    variables: {
      providerId: providerId ?? '',
    },
    fetchPolicy: 'network-only',
    skip: !providerId,
  })

  const [savePreferences, { loading: isSavingPreferences, called: hasSavedPreferences }] =
    useSaveProviderPreferencesMutation({
      fetchPolicy: 'no-cache',
    })
  const showSavingIndicator = useDelayedLoading(isSavingPreferences)

  const saveOptoutPreference = async (e: ChangeEvent<HTMLInputElement>) => {
    try {
      await savePreferences({
        variables: {
          providerId: providerId ?? '',
          preferences: {
            autoAssessmentsDisabled: !e.target.checked,
          },
        },
        optimisticResponse: {
          updateProvider: {
            id: providerId ?? '',
            preferences: {
              autoAssessmentsDisabled: !e.target.checked,
            },
          },
        },
        refetchQueries: ['ProviderPreferences'],
      })
    } catch (err) {
      toast.urgent(errorService.transformGraphQlError(err, `There was an issue updating the custom assessment text`))
    }
  }

  const saveAssessmentCustomTextPreference = debounce(
    async (content: string) => {
      try {
        await savePreferences({
          variables: {
            providerId: providerId ?? '',
            preferences: {
              assessmentCustomText: content,
            },
          },
        })
      } catch (err) {
        toast.urgent(errorService.transformGraphQlError(err, `There was an issue updating the custom assessment text`))
      }
    },
    250,
    { trailing: true },
  )

  const preferences = data?.provider.preferences
  const content = preferences?.assessmentCustomText ?? DEFAULT_AUTOMATED_ASSESSMENT_MESSAGE
  const assessmentsDisabled = preferences?.autoAssessmentsDisabled ?? false

  return (
    <div>
      <div>
        <SecondaryHeader className="mb-2">Automated Requests</SecondaryHeader>
        {isAdmin ? (
          <>
            <Switch checked={!assessmentsDisabled} onChange={saveOptoutPreference}>
              {!assessmentsDisabled ? 'Automated assessments enabled' : 'Automated assessments disabled'}
            </Switch>
            <p className="text-secondary mt-2">
              All your clients will receive an automated assessment request every 4 weeks in their messages, as long as
              they’ve logged in the last 4 weeks and haven’t taken an assessment in that time.
            </p>
          </>
        ) : (
          <p className="text-secondary">
            All your clients will receive an automated assessment request every 4 weeks in their messages, as long as
            they’ve logged in the last 4 weeks and haven’t taken an assessment in that time.
          </p>
        )}

        <CollapsablePreferences label="Customize the assessment message" openByDefault={false} className="mt-4">
          {!loading && (
            <MessageBorderContainer>
              <div style={{ padding: '1.5rem' }}>
                <div className="mb-2 text-medium v-align">
                  <AuthorizedAvatar
                    size="mini"
                    className="mr-1"
                    url={'https://tava-public-assets.s3.us-west-2.amazonaws.com/admin_avatar.png'}
                  />
                  <span>Tava Admin</span>
                </div>
                <MessageBody data={content} onChange={saveAssessmentCustomTextPreference} />
              </div>
              {(isSavingPreferences || hasSavedPreferences) && (
                <SavingIndicator>
                  <span className="caption">{showSavingIndicator ? 'Saving' : 'Saved'}</span>
                </SavingIndicator>
              )}
            </MessageBorderContainer>
          )}
        </CollapsablePreferences>
      </div>
    </div>
  )
}

const MessageBorderContainer = styled(Card)`
  width: 100%;
  margin-top: var(--margin-2);
  color: var(--body-color);
  position: relative;
  overflow: hidden;

  border: 1px solid ${greySet[50].hex};
  border-right: 12px solid ${greySet[50].hex};
`

const SecondaryHeader = makeTypographyComponent('text-medium large sans-serif', 'h3')
const SavingIndicator = styled('div')`
  position: absolute;
  bottom: 0;
  right: 0;
  padding: 0.5rem;
  background-color: rgba(255, 255, 255, 0.8);
`
