import { IconProps } from './IconProps'

export function IconVirtualBGFill({ size = 24, color = 'currentColor', ...props }: IconProps) {
  return (
    <svg width={size} height={size} viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.91256 6C2.91256 6 1 6 1 7.99543C1 10.0011 1 40.0046 1 40.0046C1 40.0046 1 42 2.91256 42C4.82512 42 45.0874 42 45.0874 42C45.0874 42 47 42 47 40.0046C47 37.9989 47 7.99543 47 7.99543C47 7.99543 47 6 45.0874 6C43.1749 6 2.91256 6 2.91256 6ZM4 39V9H44V39H4Z"
        fill={color}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M27.0119 25.2961L26.99 23.0448C26.99 23.0448 27.8981 22.3699 28.1805 20.2529C28.7485 20.4115 29.3445 19.433 29.3757 18.9166C29.4085 18.4199 29.2977 17.0413 28.6049 17.1802C28.7469 16.1414 28.8499 15.2082 28.8 14.7145C28.619 12.9071 26.7794 11 23.9522 11C21.1233 11 19.2838 12.9071 19.1043 14.7145C19.0544 15.2082 19.1574 16.1414 19.2978 17.1802C18.605 17.0413 18.4958 18.4199 18.527 18.9166C18.5598 19.433 19.1543 20.4115 19.7238 20.2529C20.0046 22.3699 20.9143 23.0448 20.9143 23.0448L20.8909 25.2961C20.8909 25.2961 20.3151 25.6344 17.892 26.6128C15.4689 27.5897 13.0192 28.2737 12.2906 29.3745C11.6368 30.359 12.2906 35.0837 12.2906 35.0837C12.2906 35.0837 15.8227 37 23.9522 37C32.0816 37 35.8949 35.0837 35.8949 35.0837C35.8949 35.0837 36.2659 30.359 35.6137 29.3745C34.8835 28.2737 32.4354 27.5897 30.0107 26.6128C27.5876 25.6344 27.0119 25.2961 27.0119 25.2961Z"
        fill={color}
      />
    </svg>
  )
}
