import { styled } from '@mui/material'
import { HTMLAttributes, ReactNode } from 'react'

import { fontSize as fontSizeMap } from '../../styles/global/typography.utils'
import { csx } from '../../utils/csx'

export const List = styled('ul')`
  list-style: none;
  padding-left: 0;
  margin: 0;
`

export interface ListItemProps extends HTMLAttributes<HTMLLIElement> {
  icon?: ReactNode
  fontSize?: keyof typeof fontSizeMap
}

export function ListItem({ icon, fontSize = 'body', children, className, ...props }: ListItemProps) {
  const hasIcon = !!icon
  return (
    <StyledListItem className={csx(['mb-1', className])} centeredIcon={hasIcon} fontSize={fontSize} {...props}>
      {hasIcon ? (
        <>
          <span className="icon mr-1">{icon}</span>
          <span>{children}</span>
        </>
      ) : (
        children
      )}
    </StyledListItem>
  )
}

interface StyledListItemProps {
  fontSize: keyof typeof fontSizeMap
  centeredIcon: boolean
}

const StyledListItem = styled('li')`
  display: flex;
  align-items: flex-start;
  ${({ centeredIcon, fontSize }: StyledListItemProps) =>
    centeredIcon ? `line-height: calc(1.4 * ${fontSizeMap[fontSize]});` : ``}

  .icon {
    display: flex;
    align-items: center;
    /* 
      set the height to match the line height multiplied by font size to ensure icon is centered 
      with the first line of text
    */
    height: calc(1.4 * ${({ fontSize }: StyledListItemProps) => fontSizeMap[fontSize]});
  }
`
