import { styled } from '@mui/material'
import { HTMLAttributes, ReactNode } from 'react'

import { useIsAdmin } from '@nuna/auth'
import { TimeZoneControl, useAppointmentDrawerSearchParams } from '@nuna/common'
import { IconButton, IconClose, eggshell } from '@nuna/tunic'

import { AppointmentDrawerPaddedContainer } from '../AppointmentDrawer'

interface Props extends HTMLAttributes<HTMLDivElement> {
  icon: ReactNode
}

export function DrawerHeader({ icon, children }: Props) {
  const isAdmin = useIsAdmin()
  const { closeDrawer } = useAppointmentDrawerSearchParams()
  return (
    <Container className="space-between v-align">
      <div className="v-align">
        {icon}
        <div className="h5 mb-0 ml-xs">{children}</div>
        {isAdmin && <TimeZoneControl className="ml-2" />}
      </div>
      <IconButton tooltip="Close" className="close-button" variant="secondary" onClick={() => closeDrawer()}>
        <IconClose size={20} />
      </IconButton>
    </Container>
  )
}

const Container = styled(AppointmentDrawerPaddedContainer)`
  position: sticky;
  top: 0;
  background-color: ${eggshell};
  z-index: 2;
  .close-button {
    margin-right: -0.5rem;
  }
`
