import { styled } from '@mui/material'
import React from 'react'

import { usePatientSpecialtiesForLiveSessionQuery } from '@nuna/api'
import { Chip, ChipGroup, greySet, sansSerifFontStack } from '@nuna/tunic'

import { SidebarCard } from '../SidebarCard'

interface SpecialtiesGlanceProps extends React.HTMLAttributes<HTMLDivElement> {
  patientId: string
}

const SPECIALTY_CATEGORY_CHALLENGES = 'Issues/Challenges'
const SPECIALTY_CATEGORY_AREAS_OF_IMPACT = 'Area of Impact'

export function SpecialtiesGlance({ patientId, ...props }: SpecialtiesGlanceProps) {
  const { data, error } = usePatientSpecialtiesForLiveSessionQuery({ variables: { id: patientId } })

  if (!data) {
    return null
  }

  if (error) {
    return (
      <SidebarCard padded={false}>
        <EmptyMessage>
          <h3>A problem occurred loading Concerns and Challenges and Areas Impacted</h3>
        </EmptyMessage>
      </SidebarCard>
    )
  }

  const { specialties } = data.patient

  const concerns = specialties.filter(specialty => specialty.specialtyCategory.name === SPECIALTY_CATEGORY_CHALLENGES)
  const areasImpacted = specialties.filter(
    specialty => specialty.specialtyCategory.name === SPECIALTY_CATEGORY_AREAS_OF_IMPACT,
  )

  return (
    <Wrapper {...props}>
      <h4 className="text-dark">Concerns and Challenges</h4>
      <ChipSection>
        {concerns.length ? (
          <ChipGroup>
            {concerns.map(specialty => (
              <Chip key={specialty.id} small>
                {specialty.name}
              </Chip>
            ))}
          </ChipGroup>
        ) : (
          <EmptyMessage>
            <h3 className="body">No concerns identified</h3>
          </EmptyMessage>
        )}
      </ChipSection>

      <h4 className="text-dark">Areas Impacted</h4>
      <ChipSection>
        {areasImpacted.length ? (
          <ChipGroup>
            {areasImpacted.map(specialty => (
              <Chip key={specialty.id} small>
                {specialty.name}
              </Chip>
            ))}
          </ChipGroup>
        ) : (
          <EmptyMessage>
            <h3 className="body">No areas identified</h3>
          </EmptyMessage>
        )}
      </ChipSection>
    </Wrapper>
  )
}

const Wrapper = styled(SidebarCard)`
  h4 {
    font-family: ${sansSerifFontStack};
    font-size: 1rem;
  }
`

const EmptyMessage = styled('div')`
  background-color: ${greySet[15].hex};
  border-radius: 6px;
  padding: 0.5rem;
  text-align: center;
  width: 100%;

  h3 {
    font-size: 1rem;
    margin-bottom: 0;
  }
`

const ChipSection = styled('div')`
  display: flex;
  flex-wrap: wrap;
  padding: 0.5rem 0;
  border-bottom-width: 0;

  &:not(:last-child) ${EmptyMessage} {
    margin-bottom: 1rem;
  }
`
