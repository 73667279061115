import { FormikErrors } from 'formik'

import {
  AddressType,
  LicenseAbbreviation,
  LicenseInput,
  ProviderSignupIntakeAddressFragment,
  ProviderSignupIntakeCredentialFragment,
  StateAbbreviation,
} from '@nuna/api'
import { ProviderSignupAddress } from '@nuna/common'

import { ProviderSignupFormValues } from './shared/provider-signup-intake-types'

export function emptyLicense(providerId: string): LicenseInput {
  return {
    number: '',
    licenseTypeId: '',
    currentlyUtilized: true,
    type: '' as LicenseAbbreviation,
    state: '' as StateAbbreviation,
    providerId,
  }
}

export function credentialToLicense(cred: ProviderSignupIntakeCredentialFragment): LicenseInput {
  const { state, licenseTypeId, providerId, id, number, currentlyUtilized, credential } = cred
  return {
    state: state as StateAbbreviation,
    licenseTypeId,
    providerId,
    id,
    number,
    currentlyUtilized: !!currentlyUtilized,
    type: credential as LicenseAbbreviation,
  }
}

export function initialLicenses(providerId: string, credentials?: ProviderSignupIntakeCredentialFragment[] | null) {
  return credentials && credentials.length ? credentials.map(credentialToLicense) : [emptyLicense(providerId)]
}

export function buildInitialAddress(initial?: ProviderSignupIntakeAddressFragment | null): ProviderSignupAddress {
  const {
    addressLineOne = '',
    addressLineTwo = '',
    city = '',
    state = '',
    zipCode = '',
    name = 'Home Address',
    addressType = AddressType.ProviderHome,
    id = '',
  } = initial ?? {}

  return { addressLineOne, addressLineTwo, city, state, zipCode, name, addressType, addressId: id, zipCodeValid: true }
}

export function logIntakeFormErrors(errors: FormikErrors<ProviderSignupFormValues>) {
  if (errors && Object.keys(errors).length > 0) {
    console.error('INTAKE FORM ERRORS', errors)
  }
}
