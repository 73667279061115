import { styled } from '@mui/material'
import mixpanel from 'mixpanel-browser'
import moment from 'moment'
import { ReactNode, useState } from 'react'
import { useMatch, useNavigate } from 'react-router-dom'

import {
  type ProviderDetailsQuery,
  type PublicAvailabilitySlot,
  usePatientContextQuery,
  usePublicProviderQuery,
} from '@nuna/api'
import { ClientTherapyTypeSelect } from '@nuna/appointment'
import { useAuthDataContext, useSourceReferralSearchParams } from '@nuna/auth'
import { DrawerStepper, useAppointmentDrawerSearchParams, useEffectOnce } from '@nuna/common'
import { addressService, routeService } from '@nuna/core'
import {
  CheckoutSummary,
  SwitchCoverageFormPrefilled,
  useCurrentCoverageCardDetails,
  useHasEnteredCoverage,
} from '@nuna/coverage'
import { Grid, IconArmchair, IconVideoOutline as IconVideo, greySet } from '@nuna/tunic'

import { AppointmentDrawerPaddedContainer } from '../../../AppointmentDrawer'
import { AppointmentDrawerHeader } from './AppointmentDrawerHeader'

interface PaymentReviewProps {
  provider: ProviderDetailsQuery['provider']
  timeSlot: PublicAvailabilitySlot
  onSubmit: () => Promise<void>
  loading: boolean
  therapyTypeId: string | null
  cancelPolicyAlert?: ReactNode
  onTherapyTypeChange: (therapyType: string | null) => void
}

export function PaymentReview({
  provider,
  cancelPolicyAlert,
  timeSlot,
  onSubmit,
  loading,
  onTherapyTypeChange,
  therapyTypeId,
}: PaymentReviewProps) {
  const {
    drawerConfig: { switching, addressId, switchingPrefilled },
    showSwitchCoverage,
    showPrefilledSwitchCoverage,
    closeSwitchCoverage,
    state,
  } = useAppointmentDrawerSearchParams()
  const { loggedIn } = useAuthDataContext()
  const { data: patientContextData } = usePatientContextQuery({ skip: !loggedIn })
  const patientId = patientContextData?.patientContext.patient.id ?? ''
  const providerId = provider.id
  const { data: publicProviderData } = usePublicProviderQuery({
    variables: { providerId: providerId, patientId },
    skip: !providerId,
  })

  const appointmentAddress = addressId
    ? publicProviderData?.publicProvider.therapyLocations.find(location => location.id === addressId)
    : null

  const navigate = useNavigate()
  const hasEnteredCoverage = useHasEnteredCoverage()
  const isPublicProviderPage = !!useMatch(routeService.publicProviderPattern)
  const { isProviderSourced } = useSourceReferralSearchParams()

  const currentCoverageCardDetails = useCurrentCoverageCardDetails({
    fetchPolicy: 'network-only',
    date: timeSlot.start,
    isProviderSourced: isPublicProviderPage && isProviderSourced,
  })

  const [isCoverageValid, setIsCoverageValid] = useState(false)

  useEffectOnce(() => {
    showSwitchCoverage()
  }, hasEnteredCoverage === false)

  return (
    <Container>
      <AppointmentDrawerHeader provider={provider} timeSlot={timeSlot} />

      <AppointmentDrawerPaddedContainer>
        <h3 className="h6 mt-4">What type of session is it?</h3>
        <ClientTherapyTypeSelectStyled
          className="mb-3"
          onChange={onTherapyTypeChange}
          value={therapyTypeId}
          providerId={provider.id}
          coverage={currentCoverageCardDetails.currentCoverage}
        />

        <div className="mb-4">
          <h3 className="h6">Location</h3>
          {!appointmentAddress && (
            <Grid container alignContent="center">
              <IconVideo size={18} className="mr-1" />
              <span className="text-sm font-semibold" style={{ alignItems: 'center' }}>
                Virtual
              </span>
            </Grid>
          )}

          {appointmentAddress && (
            <Grid container alignContent="center" wrap="nowrap">
              <IconArmchair size={18} className="mr-1" style={{ flexShrink: 0 }} />
              <span
                className="flex text-sm font-semibold"
                style={{ alignItems: 'center', textOverflow: 'ellipsis', overflow: 'hidden', whiteSpace: 'pre' }}
              >
                {addressService.formatAddress(appointmentAddress)}
              </span>
            </Grid>
          )}
        </div>

        {switching ? (
          <SwitchCoverageFormPrefilled
            onSubmitComplete={() => {
              if (isPublicProviderPage) {
                mixpanel.track('entered public provider payment', { providerId: provider.id })
              }
              closeSwitchCoverage()
            }}
            onBackClick={() => navigate(-1)}
            isNewAccount={hasEnteredCoverage === false}
            payerName={state?.payerName}
            providerAcceptsInsurance={state?.providerAcceptsInsurance !== false} // default to true if undefined
            switchingPrefilledPreference={switchingPrefilled}
            providerAcceptsReferrals={provider?.acceptsReferrals}
          />
        ) : (
          <>
            <CheckoutSummary
              date={moment(timeSlot.start).toDate()}
              provider={provider}
              currentCoverageCardDetails={currentCoverageCardDetails}
              onChangePaymentClick={() => showSwitchCoverage()}
              onPrefilledPaymentClick={showPrefilledSwitchCoverage}
              onCoverageValidityChange={setIsCoverageValid}
            />
            <DrawerStepper
              className="mt-6"
              nextButtonText="Submit"
              loading={loading}
              onNextButtonClick={onSubmit}
              nextButtonDiabled={loading || !isCoverageValid}
              nextButtonProps={{ 'data-testid': 'siderail-schedule-submit' }}
            />
            {isCoverageValid && !switching && cancelPolicyAlert}
          </>
        )}
      </AppointmentDrawerPaddedContainer>
    </Container>
  )
}

const Container = styled('div')`
  .coverage-radio-card {
    background-color: ${greySet[5].hex};
  }
`
const ClientTherapyTypeSelectStyled = styled(ClientTherapyTypeSelect)`
  &::after,
  &::before {
    display: none;
  }
`
