import { styled } from '@mui/material'

import { IconCheckCircle } from '../icons'
import { greySet, tealSet } from '../styles/colorSets'

type CircularIconContainerProps = {
  isFilled?: boolean
}

export function CircularCheckmark({ isFilled }: CircularIconContainerProps) {
  return (
    <CheckmarkContainer>
      {isFilled && (
        <IconCheckCircle style={{ top: ' -3px', position: 'relative', left: '-3px' }} color={tealSet[30].hex} />
      )}
    </CheckmarkContainer>
  )
}

const CheckmarkContainer = styled('div')`
  width: 22px;
  height: 22px;
  flex-shrink: 0;
  border-radius: 50px;
  border: 2px solid ${greySet[50].hex};
`
