import * as Yup from 'yup'
import { styled } from '@mui/material'
import { InputLabel } from '@mui/material'
import { Form, Formik } from 'formik'
import { noop, pick, replace } from 'lodash'
import moment from 'moment'
import { useMemo, useState } from 'react'

import {
  CredentialFragment,
  LicenseAbbreviation,
  LicenseInput,
  LicenseStatus,
  StateAbbreviation,
  useDeleteLicenseMutation,
  useSaveLicensesMutation,
} from '@nuna/api'
import { useIsAdmin } from '@nuna/auth'
import { errorService, formService } from '@nuna/core'
import { supportService } from '@nuna/telemetry'
import {
  Card,
  CardBody,
  Confirm,
  DatePicker,
  FillButton,
  FillButtonWithChevron,
  GhostButton,
  GhostButtonLink,
  Grid,
  IconButton,
  IconClose,
  IntakeFormStepper,
  PlaceholderButton,
  Select,
  Stack,
  StateSelect,
  TextField,
  toast,
} from '@nuna/tunic'

import { LicenseAutcompleteSingle } from '../../../../LicenseAutocompleteSingle'
import { LicenseWithSimpleStatus } from '../../../types'
import { LicenseStatusLabel } from './LicenseStatusLabel'

const { composeHelperTextWithError } = formService

interface Props {
  providerId: string
  credentials: CredentialFragment[]
  onCancel: () => void
  onSave: () => void
  signupIntake?: boolean
  containerClasses?: string
  fullWidth?: boolean
  maxHeight?: string | null
}

const validationSchema = Yup.array(
  Yup.object<LicenseWithSimpleStatus>({
    currentlyUtilized: Yup.boolean(),
    licenseTypeId: Yup.string().required('Title is required').typeError('Title is required'),
    state: Yup.string<StateAbbreviation>().required('State is required'),
    providerId: Yup.string(),
    status: Yup.string<LicenseStatus>(),
    number: Yup.string().required('License number is required'),
  }),
)
  .required()
  .min(1, 'Please select a day and time for availability')

export function LicensesForm({
  credentials,
  onCancel,
  providerId,
  onSave,
  signupIntake,
  containerClasses,
  fullWidth,
  maxHeight,
}: Props) {
  const isAdmin = useIsAdmin()
  const licenses = useMemo(() => credentials.map(mapCredentialToLicense), [credentials])
  const [deleteLicense, { loading: deleteLicenseLoading }] = useDeleteLicenseMutation()
  const [licenseIdToDelete, setLicenseIdToDelete] = useState<string | undefined>()
  const [saveLicenses, { loading: saveLicensesLoading }] = useSaveLicensesMutation()

  const submit = async (values: LicenseWithSimpleStatus[]) => {
    try {
      await saveLicenses({
        variables: {
          licenses: prepareLicensesToSave(values, licenses),
        },
      })
      onSave()
    } catch (e) {
      toast.urgent(errorService.transformGraphQlError(e))
    }
  }

  const gridSize = isAdmin ? 3 : 4
  return (
    <Formik initialValues={licenses} validationSchema={validationSchema} onSubmit={submit} enableReinitialize>
      {({ values, getFieldProps, errors, touched, setFieldValue, setValues }) => {
        const handleDeleteLicense = async () => {
          try {
            await deleteLicense({
              variables: { id: licenseIdToDelete ?? '' },
              refetchQueries: ['ProviderCredentialsAndEnrollment'],
            })
            setValues(values.filter(v => v.id !== licenseIdToDelete))
            setLicenseIdToDelete(undefined)
          } catch (e) {
            toast.urgent(errorService.transformGraphQlError(e, 'Unable to delete license at this time'))
          }
        }

        const handleRemoveNewLicenseFromValues = (idx: number) => {
          setValues(values.filter((_value, vIdx) => idx !== vIdx))
        }

        return (
          <Container className={containerClasses ?? 'v-align'}>
            <div className={`flex-1 ${!fullWidth ? 'mr-5' : ''}`} style={maxHeight ? { maxHeight: maxHeight } : {}}>
              {values.map((value, idx) => {
                const licenseFieldName = (key: keyof LicenseInput) => `[${idx}].${key}`
                const licenseFieldProps = (key: keyof LicenseInput) => ({
                  ...getFieldProps(licenseFieldName(key)),
                  ...composeHelperTextWithError('', (errors[idx] ?? {})[key], !!(touched[idx] ?? {})[key]),
                  ...(key === 'expiryDate' ? { value: moment(getFieldProps(licenseFieldName(key)).value) } : {}),
                })

                return (
                  <div key={value.id ?? idx} className="v-align mb-1">
                    <Card className={`flex-1 ${fullWidth ? '' : 'mr-2'}`}>
                      <CardBody className="v-align">
                        <Grid container spacing={1} className="flex-1">
                          <Grid
                            size={{
                              xs: 12,
                              md: gridSize,
                            }}
                          >
                            <StateSelect {...licenseFieldProps('state')} />
                          </Grid>
                          <Grid
                            size={{
                              xs: 12,
                              md: gridSize,
                            }}
                          >
                            <LicenseAutcompleteSingle
                              label="Title"
                              state={value.state}
                              disabled={!value.state}
                              {...licenseFieldProps('licenseTypeId')}
                              onChange={licenseType => {
                                setFieldValue(licenseFieldName('licenseTypeId'), licenseType?.id)
                                setFieldValue(licenseFieldName('type'), licenseType?.abbreviation)
                              }}
                            />
                          </Grid>
                          <Grid
                            size={{
                              xs: 12,
                              md: gridSize,
                            }}
                          >
                            <TextField
                              labelNoWrap
                              data-testid="license-number-input"
                              label="License Number"
                              {...licenseFieldProps('number')}
                            />
                          </Grid>
                          {isAdmin && (
                            <>
                              <Grid
                                size={{
                                  xs: 12,
                                  md: gridSize,
                                }}
                              >
                                <DatePicker
                                  containerProps={{ style: { paddingTop: '16px' } }}
                                  disabled={!isAdmin}
                                  {...licenseFieldProps('expiryDate')}
                                  onChange={date => {
                                    if (date) {
                                      setFieldValue(licenseFieldName('expiryDate'), date.toISOString())
                                    } else {
                                      setFieldValue(licenseFieldName('expiryDate'), null)
                                    }
                                  }}
                                />
                              </Grid>

                              {value.id && (
                                <Grid
                                  size={{
                                    xs: 12,
                                    md: gridSize,
                                  }}
                                >
                                  <InputLabel htmlFor="license-status-select" style={{ fontSize: '12px' }}>
                                    Status
                                  </InputLabel>
                                  <Select id="license-status-select" {...licenseFieldProps('status')}>
                                    {Object.values(LicenseStatus).map((status: LicenseStatus) => (
                                      <option key={status} value={status}>
                                        {replace(status, '_', ' ')}
                                      </option>
                                    ))}
                                  </Select>
                                </Grid>
                              )}
                            </>
                          )}
                        </Grid>
                        {!isAdmin && (
                          <LicenseStatusLabel
                            className="ml-1"
                            status={value.id && !value.expiryDate ? LicenseStatus.NeedsReview : value.status}
                          />
                        )}
                      </CardBody>
                    </Card>
                    {values.length > 1 && (
                      <IconButton
                        type="button"
                        small
                        tooltip="Delete license"
                        className="text-secondary"
                        onClick={() =>
                          value.id ? setLicenseIdToDelete(value.id) : handleRemoveNewLicenseFromValues(idx)
                        }
                      >
                        <IconClose size={16} />
                      </IconButton>
                    )}
                    <Confirm
                      isOpen={!!licenseIdToDelete}
                      isLoading={deleteLicenseLoading}
                      onConfirm={(response: boolean) =>
                        response ? handleDeleteLicense() : setLicenseIdToDelete(undefined)
                      }
                    >
                      Are you sure you want to delete this license?
                    </Confirm>
                  </div>
                )
              })}
              <div className="v-align">
                <PlaceholderButton
                  className={`flex-1 ${fullWidth ? '' : 'mr-2'}`}
                  type="button"
                  onClick={() => setValues([...values, buildEmptyLicense(providerId)])}
                  data-testid="add-license"
                >
                  {values.length === 0 ? 'Add a License' : 'Add Another License'}
                </PlaceholderButton>
                {values.length > 1 && (
                  <IconButton
                    style={{ visibility: 'hidden' }}
                    type="button"
                    small
                    tooltip="Delete license"
                    className="text-secondary"
                    onClick={noop}
                  >
                    <IconClose size={14} />
                  </IconButton>
                )}
              </div>
            </div>
            {signupIntake ? (
              <div className="mt-5 v-align space-between">
                <Stack direction="row" spacing={2} alignItems="center" justifyContent="space-between" width="100%">
                  <IntakeFormStepper
                    previousPath="/signup/why-tava"
                    mutationLoading={saveLicensesLoading}
                    disabled={values.length === 0}
                    nextButton={() => (
                      <FillButtonWithChevron
                        type="submit"
                        disabled={values.length === 0}
                        isLoading={saveLicensesLoading}
                        data-testid="intake-continue-button"
                      >
                        Continue
                      </FillButtonWithChevron>
                    )}
                    className="mt-5"
                  />
                  <GhostButtonLink target="_blank" variant="secondary" to={supportService.articles.licenseTypes}>
                    License Types
                  </GhostButtonLink>
                </Stack>
              </div>
            ) : (
              <div className="ml-auto text-center">
                <FillButton type="submit" className="mb-1" isLoading={saveLicensesLoading}>
                  Save
                </FillButton>
                <br />
                <GhostButton onClick={onCancel}>Cancel</GhostButton>
              </div>
            )}
          </Container>
        )
      }}
    </Formik>
  )
}

function mapCredentialToLicense(credential: CredentialFragment): LicenseWithSimpleStatus {
  const {
    id,
    number,
    credential: type,
    state,
    licenseTypeId,
    currentlyUtilized,
    providerId,
    status,
    expiry: expiryDate,
  } = credential

  return {
    id,
    number,
    type: type as LicenseAbbreviation,
    state: state as StateAbbreviation,
    licenseTypeId,
    currentlyUtilized,
    providerId,
    status,
    expiryDate,
  }
}

function buildEmptyLicense(providerId: string): LicenseWithSimpleStatus {
  return {
    currentlyUtilized: true,
    providerId,
    state: '' as StateAbbreviation,
    number: '',
    licenseTypeId: '',
    status: LicenseStatus.Pending,
  }
}

function prepareLicensesToSave(
  values: LicenseWithSimpleStatus[],
  initialValues: LicenseWithSimpleStatus[],
): LicenseInput[] {
  const filteredValues = values.filter(value => {
    if (!value.id) {
      return true
    }

    const initialValue = initialValues.find(v => v.id === value.id)
    if (!initialValue) {
      return true
    }

    return concatLicenseValues(initialValue) !== concatLicenseValues(value)
  })

  return filteredValues.map(value => {
    const pickValues = ['currentlyUtilized', 'number', 'licenseTypeId', 'type', 'state', 'providerId', 'id']

    if (value.expiryDate) {
      pickValues.push('expiryDate')
    }

    if (value.id && value.status) {
      pickValues.push('status')
    }

    return pick(value, pickValues)
  })
}

function concatLicenseValues(license: LicenseWithSimpleStatus) {
  return [license.licenseTypeId, license.number, license.state, license.expiryDate, license.status].join('')
}

const Container = styled(Form)`
  .flex-1 {
    flex: 1;
  }
`
