import { $getRoot, LexicalEditor } from 'lexical'
import { useCallback } from 'react'

import { AssessmentSource, useRequestAssessmentBundleMutation } from '@nuna/api'
import { toast } from '@nuna/tunic'

import { AssessmentNode, traverseNodes } from '../components/lexical'

export function useMessageAssessments() {
  const [createAssessmentBundle, { loading }] = useRequestAssessmentBundleMutation()

  const saveMessageAssessments = useCallback(
    async (editor: LexicalEditor, patientLoginId: string) => {
      const assessmentNodes: AssessmentNode[] = []
      editor.getEditorState().read(() => traverseNodes($getRoot(), assessmentNodes))

      const assessmentResults = await Promise.allSettled(
        assessmentNodes.map(async (node, index) => {
          try {
            const { data } = await createAssessmentBundle({
              variables: {
                bundleType: node.__assessmentType,
                forUserLoginId: patientLoginId,
                source: AssessmentSource.ProviderRequested,
              },
            })
            if (data && data.requestAssessmentBundle && data.requestAssessmentBundle.id) {
              editor.update(() => {
                const newNode = node.cloneWithNewId(data.requestAssessmentBundle.id)
                node.replace(newNode)
              })
            } else {
              throw new Error('Submission failed for assessment node.')
            }
          } catch (error) {
            console.error('Failed to post assessment:', error)
            return { error, index }
          }
        }),
      )

      const failedAssessmentIndexes = assessmentResults
        .map((result, index) => (result.status === 'rejected' ? index : null))
        .filter(index => index !== null)

      if (failedAssessmentIndexes.length > 0) {
        if (failedAssessmentIndexes.length !== assessmentNodes.length) {
          // Some assessments failed to post, remove them from the content, and proceed to send the message as we can't rollback the assessments that did save.
          editor.update(() => {
            failedAssessmentIndexes.forEach(index => {
              if (index) {
                const failedNode = assessmentNodes[index]
                failedNode.remove()
              }
            })
          })
          toast.urgent(
            'Some assessments failed to save. They have been removed from your message. Please try re-adding them in a new message or contact support.',
            { duration: 10000, canDismiss: true },
          )
        } else {
          // All assessments have failed to post. Do not send the message. Notify user.
          toast.urgent('Assessment attachment failed. Please try sending your message again or contact support.')
          throw new Error('All asessment attachments failed')
        }
      }
    },
    [createAssessmentBundle],
  )

  return { saveMessageAssessments, loading }
}
