import { styled } from '@mui/material'
import { useEffect } from 'react'
import { useMediaQuery } from 'react-responsive'

import { BelowTablet, Dialog, IconButton, IconClose, greySet } from '@nuna/tunic'

interface Props {
  providerId: string
  onVideoRecorded: () => void
  onClose: () => void
  isOpen: boolean
}
export function VideoBioVideoAsk({ providerId, onVideoRecorded, onClose, isOpen }: Props) {
  const isMobile = useMediaQuery({ query: `(${BelowTablet})` })

  useEffect(() => {
    // taken from example at https://www.videoask.com/help/embed/360048142672-advanced-embed-options
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const isVideoaskMessage = (message: MessageEvent<any>) =>
      message.origin === 'https://www.videoask.com' &&
      message.data &&
      message.data.type &&
      message.data.type.startsWith('videoask_')

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const handler = (message: MessageEvent<any>) => {
      if (!isVideoaskMessage(message)) {
        return
      }

      if (message.data.type === 'videoask_submitted') {
        onVideoRecorded()
      }
    }

    window.addEventListener('message', handler)

    return () => window.removeEventListener('message', handler)
  }, [onVideoRecorded])
  return (
    <Dialog
      isOpen={isOpen}
      onClose={() => onClose()}
      style={{ width: '100%' }}
      maxWidth="lg"
      fullScreen={isMobile}
      PaperProps={{ style: { width: '100%', height: isMobile ? undefined : '86%' } }}
    >
      <Container style={{ height: '100%' }}>
        <IconButton
          type="button"
          tabIndex={1}
          variant="secondary"
          className="close-button"
          tooltip="Close"
          onClick={onClose}
        >
          <IconClose />
        </IconButton>
        <iframe
          src={`https://www.videoask.com/fo46czk8c#provider_id=${providerId}`}
          allow="camera; microphone; autoplay; encrypted-media;"
          width="100%"
          height="100%"
          style={{ border: 'none' }}
          title={'VideoAsk'}
        ></iframe>
        <div className="footer text-secondary text-center italic">
          You can redo your recording as many times as needed. You must finish all steps above to save your video
          recording
        </div>
      </Container>
    </Dialog>
  )
}

const Container = styled('div')`
  height: '100%';
  display: flex;
  flex-direction: column;
  position: relative;

  .close-button {
    position: absolute;
    right: var(--margin-2);
    top: var(--margin-2);
  }

  .footer {
    background-color: ${greySet[30].hex};
    padding: var(--margin-2);
  }
`
