import { AutoLinkNode } from '@lexical/link'
import { ListItemNode, ListNode } from '@lexical/list'
import { AutoLinkPlugin } from '@lexical/react/LexicalAutoLinkPlugin'
import { ClearEditorPlugin } from '@lexical/react/LexicalClearEditorPlugin'
import { InitialEditorStateType, LexicalComposer } from '@lexical/react/LexicalComposer'
import { ContentEditable } from '@lexical/react/LexicalContentEditable'
import { HistoryPlugin } from '@lexical/react/LexicalHistoryPlugin'
import { ListPlugin } from '@lexical/react/LexicalListPlugin'
import { OnChangePlugin } from '@lexical/react/LexicalOnChangePlugin'
import { RichTextPlugin } from '@lexical/react/LexicalRichTextPlugin'
import { styled } from '@mui/material'
import { EditorState, Klass, LexicalEditor, LexicalNode } from 'lexical'
import { noop } from 'lodash'
import { HTMLAttributes, ReactNode, useId } from 'react'

import { error } from '../../styles/colors'
import { csx } from '../../utils/csx'

export interface RichTextEditorContextProps {
  children: ReactNode
  initialEditorState?: InitialEditorStateType
  isDebug?: boolean
  namespace?: string
  editable?: boolean
  // for now the only thing I needed to know on change was if it was empty. We can add more params as necessary as we go along
  onChange?: (editor: LexicalEditor) => void
  // Allow disable of AutoLinkPlugin due to a known issue where it will steal focus away from other inputs
  // when re-rendering the editor
  disableAutoLink?: boolean
  nodes?: Klass<LexicalNode>[]
}

const RTE_PREFIX = 'tava-rte-'

const theme = {
  content: `${RTE_PREFIX}editor-content`,
  ltr: `${RTE_PREFIX}ltr`,
  rtl: `${RTE_PREFIX}rtl`,
  placeholder: `${RTE_PREFIX}editor-placeholder`,
  paragraph: `${RTE_PREFIX}editor-paragraph`,
  link: `${RTE_PREFIX}editor-link`,
  list: {
    listitem: `${RTE_PREFIX}list-item`,
    nested: {
      listitem: `${RTE_PREFIX}nested-list-item`,
    },
  },
}

function onError(error: Error) {
  console.error(error)
}

/**
 * RichTextEditorContext is a wrapper around the LexicalComposer. It sets up our default plugins, themes, etc. and gives you access to useLexicalComposerContext.
 * It does not render anything itself, but provides the context for the RTE components to work. If you need access to editor state in a form, you should wrap the entire form in this context so you can access the editor.
 * NOTE: If you are using this for our conversation message functionality, just use the messageComposerContext which will provide this for you. You will only need this for a RTE editor that is not related to messages (if we ever do that one day).
 */
export function RichTextEditorContext({
  children,
  initialEditorState,
  namespace,
  editable = true,
  isDebug = false,
  onChange = noop,
  disableAutoLink = false,
  nodes = [],
}: RichTextEditorContextProps) {
  const generatedId = useId()

  const initialConfig = {
    namespace: namespace ?? generatedId,
    editable,
    theme,
    onError,
    editorState: initialEditorState,
    nodes: [ListItemNode, ListNode, AutoLinkNode, ...nodes],
  }

  function handleChange(editorState: EditorState, editor: LexicalEditor) {
    editorState.read(() => {
      onChange(editor)
    })

    if (isDebug) {
      // eslint-disable-next-line no-console
      console.log('editorState', editorState.toJSON())
    }
  }

  const URL_MATCHER =
    /((https?:\/\/(www\.)?)|(www\.))?[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_+.~#?&//=]*)/
  const EMAIL_MATCHER = /^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/

  const MATCHERS = [
    (text: string) => {
      const match = EMAIL_MATCHER.exec(text)
      return (
        match && {
          index: match.index,
          length: match[0].length,
          text: match[0],
          url: `mailto:${match[0]}`,
        }
      )
    },
    (text: string) => {
      const match = URL_MATCHER.exec(text)
      return (
        match && {
          index: match.index,
          length: match[0].length,
          text: match[0],
          url: match[0].startsWith('http') ? match[0] : `http://${match[0]}`,
        }
      )
    },
  ]

  return (
    <LexicalComposer initialConfig={initialConfig}>
      <>
        {editable && <HistoryPlugin />}
        <ListPlugin />
        {!disableAutoLink && <AutoLinkPlugin matchers={MATCHERS} />}
        <ClearEditorPlugin />
        {editable && <OnChangePlugin onChange={handleChange} />}
        {children}
      </>
    </LexicalComposer>
  )
}

interface RichTextAreaProps extends HTMLAttributes<HTMLDivElement> {
  placeholder?: string
  error?: boolean
}

export function RichTextArea({ placeholder = '', error = false, ...props }: RichTextAreaProps) {
  return (
    <RichTextContainer {...props}>
      <RichTextPlugin
        contentEditable={<ContentEditable testid="rte-editor" className={theme.content} />}
        placeholder={<div className={csx([{ error }, theme.placeholder])}>{placeholder}</div>}
      />
    </RichTextContainer>
  )
}

export interface RichTextDisplayProps {
  editorState: string
  className?: string
  nodes: Klass<LexicalNode>[]
}

export function RichTextDisplay({ editorState, className, nodes = [] }: RichTextDisplayProps) {
  if ((JSON.parse(editorState).root?.children?.length ?? 0) === 0) return null

  return (
    <RichTextEditorContext initialEditorState={editorState} editable={false} nodes={nodes}>
      <RichTextArea className={className} />
    </RichTextEditorContext>
  )
}

export const RichTextContainer = styled('div')`
  position: relative;

  .${theme.ltr} {
    text-align: left;
  }

  .${theme.rtl} {
    text-align: right;
  }

  .${theme.placeholder} {
    color: #999;
    overflow: hidden;
    padding: inherit;
    position: absolute;
    top: 0;
    left: 0;
    user-select: none;
    pointer-events: none;
    line-height: 1.5;
    &.error {
      color: ${error};
    }
  }

  .${theme.paragraph} {
    margin: 0;
    position: relative;
    &:nth-of-type(n + 2) {
      margin-top: 0.75rem;
    }
  }

  .${theme.list.nested.listitem} {
    list-style-type: none;
  }

  .${theme.content} {
    line-height: 1.5;

    &:focus-visible {
      outline: none;
    }
  }

  .${theme.link} {
    color: inherit;
    text-decoration: underline;
  }
`
