import * as Yup from 'yup'
import { Formik } from 'formik'

import { OrganizationRelationship } from '@nuna/api'
import { FillButton, toast } from '@nuna/tunic'

import { useSaveEmployerCoverage } from '../../../hooks/useSaveEmployerCoverage'
import {
  CoverageFormPatient,
  EmployerCoverageForm,
  EmployerCoverageFormValues,
  buildEmployerCoverageInput,
  employerCoverageSchema,
  employerInitialValues,
} from '../../CoverageForm'

interface Props {
  client: CoverageFormPatient
  onSubmitComplete: () => void
}

const schema = Yup.object().shape({
  employerValues: employerCoverageSchema,
})

export function DrawerEmployerForm({ client, onSubmitComplete }: Props) {
  const [savePatientEmployer, { loading }] = useSaveEmployerCoverage()

  const handleSubmit = async (values: { employerValues: EmployerCoverageFormValues }) => {
    if (values.employerValues.employer?.offersTava === false) {
      toast.urgent('We’re sorry, this organization is not using Tava yet')
      return
    }

    const response = await savePatientEmployer({
      variables: {
        id: client.id,
        employer: buildEmployerCoverageInput(values),
      },
    })

    if (!response.didSucceed) {
      if (
        values.employerValues?.employerAssociation &&
        values.employerValues?.employerAssociation !== OrganizationRelationship.Employee
      ) {
        toast.urgent('This organization is not offering coverage to dependents')
      } else {
        toast.urgent(response.error?.message)
      }
      return
    }

    onSubmitComplete()
  }

  return (
    <Formik validationSchema={schema} initialValues={{ employerValues: employerInitialValues }} onSubmit={handleSubmit}>
      {({ handleSubmit }) => (
        <form onSubmit={handleSubmit}>
          <EmployerCoverageForm isCheckout />
          <FillButton className="mt-4" isLoading={loading} type="submit">
            Verify sponsor
          </FillButton>
        </form>
      )}
    </Formik>
  )
}
