import { IconProps } from './IconProps'

export function IconChevronLeft({ size = 24, color = 'currentColor', ...props }: IconProps) {
  const height = (9 / 16) * size

  return (
    <svg width={size} height={height} viewBox="0 0 9 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path d="M7.5 14.5L1.50001 7.99999L7.5 1.5" stroke={color} strokeWidth="1.5" strokeLinecap="round" />
    </svg>
  )
}
