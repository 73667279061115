import { styled } from '@mui/material'
import { motion } from 'framer-motion'
import mixpanel from 'mixpanel-browser'
import moment from 'moment'
import { useEffect, useRef, useState } from 'react'

import { BelowTablet, IconClock, TextButton, greySet } from '@nuna/tunic'

import { useVideoCallContext } from '../../../context/VideoCallContext'
import { useMeetingSessionState } from '../../../hooks/useMeetingSessionState'
import { videoSceneDarkBorder, videoSceneExtraDark, videoSceneTeal } from '../../../util/colors'

export function SessionTimer() {
  const { appointmentId, isSessionEnded } = useVideoCallContext()
  const [{ sessionStartTime }] = useMeetingSessionState()

  const notInSession = !sessionStartTime

  const [isTimerShown, setIsTimerShown] = useState(false)
  const [isHovered, setIsHovered] = useState(false)

  const [timeElapsedString, setTimeElapsedString] = useState('00:00')
  const interval = useRef<NodeJS.Timeout>()

  const clearIntervalSafely = () => {
    if (interval.current) {
      clearInterval(interval.current)
    }
    interval.current = undefined
  }

  useEffect(() => {
    if (sessionStartTime) {
      console.info('[DEBUG] Session timer interval begin')
      interval.current = setInterval(() => {
        const diff = moment().diff(sessionStartTime)
        const duration = moment.duration(diff)
        const minutes = parseInt(duration.asMinutes().toString())
        const seconds = parseInt((duration.asSeconds() % 60).toString())

        setTimeElapsedString(`${minutes}:${seconds < 10 ? `0${seconds}` : seconds}`)
      }, 1000)
    }

    return () => {
      if (interval.current) {
        console.info('[DEBUG] Clearing session timer interval - sessionStartTimeChanged')
        clearIntervalSafely()
      }
    }
  }, [sessionStartTime])

  useEffect(() => {
    if (isSessionEnded && interval.current) {
      console.info('[DEBUG] Clearing session timer interval - session ended')
      clearIntervalSafely()
      setIsTimerShown(true)
    }
  }, [isSessionEnded])

  useEffect(() => {
    setIsTimerShown(notInSession)
  }, [notInSession])

  return (
    <Container
      style={{ minWidth: 38 }}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
      animate={{ width: isHovered ? 'auto' : isTimerShown ? 'auto' : 38 }}
      whileTap={{ width: 'auto' }}
      className="v-align"
      transition={{ type: 'tween' }}
    >
      <IconClock size={20} />
      <span className="ml-1">
        {isSessionEnded && 'Session lasted '}
        {timeElapsedString}
      </span>
      {!isSessionEnded && (
        <StickyButton
          scheme="dark"
          onClick={() => {
            mixpanel.track('video:toggled session timer', { isTimerShown: !isTimerShown, appointmentId })
            setIsTimerShown(!isTimerShown)
          }}
          className="ml-1"
          color={videoSceneTeal}
          data-component="video-session-timer-toggle"
        >
          {isTimerShown ? 'Hide' : 'Show'}
        </StickyButton>
      )}
    </Container>
  )
}

const Container = styled(motion.span)`
  border: 1px solid transparent;
  color: ${greySet[30].hex};
  padding: 0.5rem;
  transition: background-color 0.3s, border-color 0.3s;
  overflow: hidden;
  white-space: nowrap;

  svg {
    flex: 0 0 auto;
  }

  &:hover {
    background-color: ${videoSceneExtraDark};
    border-color: ${videoSceneDarkBorder};
    border-radius: 6px;
  }
`

const StickyButton = styled(TextButton)`
  @media (${BelowTablet}) {
    display: none;
  }
`
