import { usePatientId } from '@nuna/auth'
import { paymentMethodService } from '@nuna/core'
import { useClientPaymentMethods } from '@nuna/payment-methods'

export function useHasValidFeePaymentMethod(): [boolean | null, ReturnType<typeof useClientPaymentMethods>] {
  const patientId = usePatientId()
  const paymentMethodsObject = useClientPaymentMethods(patientId)

  if (paymentMethodsObject.queryResult.loading) return [null, paymentMethodsObject]

  // TODO: we've created a hairy situation where we default to the first card in the list if there is nothing marked as defaultForFees.
  // If the user has added a card for defaultForSessions then useClientPaymentMethods is defaulting to that card but it is never marked as defaultForFees in the db.
  // Then when the backend tries to charge the fees it ends up in an error because it can't find a card marked as the deafult for fees.
  // It needs a better fix but for now I'm accepting the tech debt because of the Zero cap company situation. Will revisit when we do coverage overhaul next.
  if (paymentMethodsObject.defaultForFees?.defaultForFees === false) {
    return [false, paymentMethodsObject]
  }

  return [paymentMethodService.isCardValid(paymentMethodsObject.defaultForFees), paymentMethodsObject]
}
