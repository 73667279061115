import * as Yup from 'yup'
import { useFormik } from 'formik'
import moment from 'moment'
import { useEffect, useMemo } from 'react'

import { AppointmentChangeReason, DrawerAppointmentFragment } from '@nuna/api'
import { useAppointmentDrawerSearchParams } from '@nuna/common'
import { Audience } from '@nuna/core'
import { useMessageComposerContext } from '@nuna/messaging'
import { toast } from '@nuna/tunic'

import { useCancelOrReschedule } from './useCancelOrReschedule'

export interface RescheduleFormValues {
  appointmentId: string
  reason: AppointmentChangeReason
  reasonData: string | null
  newStart: string
  newEnd: string
  initialStart: string
  requestedBy: 'client' | 'provider'
  shouldProviderBePaid: boolean
  hasValidPaymentMethod: boolean | null
  hasMessage: boolean
  newAddressId?: string | null
  initialAddressId?: string | null
}

const schema = Yup.object().shape<RescheduleFormValues>({
  appointmentId: Yup.string().required(),
  reason: Yup.mixed()
    .oneOf(Object.values(AppointmentChangeReason))
    .required('Please select a reason')
    .typeError('Please select a reason'),
  reasonData: Yup.string().when('reason', {
    is: AppointmentChangeReason.FreeForm,
    then: Yup.string().required('Please provide a reason').typeError('Please provide a reason'),
    otherwise: Yup.string().notRequired().nullable(),
  }),
  initialStart: Yup.string(),
  // verify that newStart is not initialStart
  // unless we're changing location via newAddressId
  newStart: Yup.string().when('newAddressId', {
    is: newAddressId => newAddressId === null,
    then: Yup.string().notOneOf([Yup.ref('initialStart')], 'You must select a different time'),
    otherwise: Yup.string().notRequired(),
  }),
  newEnd: Yup.string(),
  requestedBy: Yup.mixed<'client' | 'provider'>().oneOf(['provider', 'client']),
  shouldProviderBePaid: Yup.boolean(),
  hasValidPaymentMethod: Yup.boolean().oneOf([true], `You'll need to add a new credit card to reschedule`),
  hasMessage: Yup.boolean().oneOf([true], `Please provide a message`),
  initialAddressId: Yup.string().notRequired().nullable(),
  newAddressId: Yup.string().notRequired().nullable(),
})

interface InitialValuesArgs {
  appointmentId: string
  newStart: string
  newEnd: string
  audience: Audience
  subjectToFee?: boolean
  initialAddressId?: string | null
}
export function buildInitialValues({
  appointmentId,
  newStart,
  newEnd,
  audience,
  subjectToFee,
  initialAddressId,
}: InitialValuesArgs): RescheduleFormValues {
  return {
    appointmentId,
    newStart,
    newEnd,
    reason:
      audience === 'client' && !subjectToFee
        ? AppointmentChangeReason.SchedulingConflict
        : ('' as AppointmentChangeReason),
    reasonData: null,
    shouldProviderBePaid: false,
    initialStart: newStart,
    requestedBy: audience === 'client' ? 'client' : 'provider',
    hasValidPaymentMethod: audience === 'client' ? null : true,
    hasMessage: false,
    initialAddressId,
  }
}

interface UseRescheduleFormArgs {
  appointment: DrawerAppointmentFragment
  audience: Audience
  subjectToFee?: boolean
}
export function useRescheduleForm({ appointment, audience, subjectToFee }: UseRescheduleFormArgs) {
  const { closeDrawer } = useAppointmentDrawerSearchParams()
  const { isContentEmpty } = useMessageComposerContext()

  const { reschedule } = useCancelOrReschedule({
    patient: appointment.patient ?? '',
    provider: appointment.provider,
    audience,
  })

  const initialValues = useMemo(
    () =>
      buildInitialValues({
        appointmentId: appointment.id,
        newStart: appointment.startDatetime,
        newEnd: appointment.endDatetime,
        audience,
        subjectToFee,
        initialAddressId: appointment.address?.id ?? null,
      }),
    [appointment, audience, subjectToFee],
  )

  const handleSubmit = async (values: RescheduleFormValues) => {
    try {
      await reschedule({
        appointmentId: appointment.id,
        shouldProviderBePaid: values.shouldProviderBePaid,
        reason: values.reason,
        reasonData: values.reasonData,
        newStart: values.newStart,
        newEnd: moment(values.newStart).add('50', 'm'),
        newAddressId:
          values.newAddressId && values.newAddressId.toLowerCase() !== 'virtual' ? values.newAddressId : null,
      })
      closeDrawer()
    } catch (e) {
      console.error(e)
      toast.urgent('An error occured while rescheduling the appointment.')
    }
  }

  const formik = useFormik<RescheduleFormValues>({
    initialValues,
    validationSchema: schema,
    onSubmit: handleSubmit,
  })

  const { setFieldValue } = formik

  useEffect(() => {
    setFieldValue('hasMessage', !isContentEmpty)
  }, [isContentEmpty, setFieldValue])

  function submitForm() {
    // workaround due to the fact that submitForm doesn't actually reject the promise when the form is invalid like it should
    // https://github.com/jaredpalmer/formik/issues/1580 - bug has existed for years w/o a fix
    return formik.submitForm().then(() => formik.isValid)
  }

  return { formProps: { ...formik, submitForm } }
}
