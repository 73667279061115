import { styled } from '@mui/material'

import { PaymentPreference } from '@nuna/api'
import { type DiscriminatedPatientCoverageFragment } from '@nuna/core'
import { Drawer, DrawerProps, eggshell } from '@nuna/tunic'

import { AccessCodeDetails } from './components/AccessCodeDetails'
import { CashDetails } from './components/CashDetails'
import { EAPDetails } from './components/EAPDetails'
import { InsuranceDetails } from './components/InsuranceDetails'

interface Props extends Omit<DrawerProps, 'isOpen'> {
  coverage: DiscriminatedPatientCoverageFragment | undefined
}

export function CoverageDetailsDrawer({ coverage, ...props }: Props) {
  return (
    <StyledDrawer isOpen={!!coverage} size="min(400px, 100vw)" {...props}>
      <div className="p-3 flex-column content-wrapper">
        {(() => {
          switch (coverage?.type) {
            case PaymentPreference.Accesscode:
              return <AccessCodeDetails details={coverage.details} />
            case PaymentPreference.Cash:
              return <CashDetails details={coverage.details} />
            case PaymentPreference.Employer:
              return <EAPDetails details={coverage.details} />
            case PaymentPreference.Insurance:
              return <InsuranceDetails details={coverage.details} />
            default:
              return null
          }
        })()}
      </div>
    </StyledDrawer>
  )
}

const StyledDrawer = styled(Drawer)`
  .MuiPaper-root {
    background-color: ${eggshell};
  }

  .content-wrapper {
    height: 100%;
    overflow: auto;
  }
`
