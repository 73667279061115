import { styled } from '@mui/material'
import { noop } from 'lodash'
import { useEffect } from 'react'
import { useLocation } from 'react-router-dom'

import { type Persona } from '@nuna/core'
import { BelowTablet, GhostButtonLink } from '@nuna/tunic'

import { LoginData, useAuthDataContext } from '../context/AuthDataContext'
import { getFromPath } from '../util/utils'
import { LoginWidget } from './LoginWidget'
import { QuoteContentWrapper } from './QuoteContentWrapper'

interface Props {
  audience: Persona
  showSignupLink?: boolean
  getRedirectPath?: (
    loginData: LoginData,
    fromPath?: string | null,
  ) => Promise<string | null | undefined> | string | null | undefined
  onLoginSuccess?: (loginData: LoginData) => void
}

export function LoginScreen({ audience, showSignupLink = false, getRedirectPath, onLoginSuccess = noop }: Props) {
  const { loggedIn } = useAuthDataContext()
  const location = useLocation()

  useEffect(() => {
    // need to do some pre-redirect check before redirecting for providers
    if (loggedIn) {
      // Hard navigation to force reloading JS bundle
      window.location.href = getFromPath(location) ?? '/'
    }
  }, [loggedIn, location])

  return (
    <QuoteContentWrapper
      audience={audience}
      headerRight={
        showSignupLink ? (
          <>
            <NoAccountMessage>No account?</NoAccountMessage>
            <GhostButtonLink className="ml-1" variant="secondary" to="/signup" state={location.state}>
              Sign up
            </GhostButtonLink>
          </>
        ) : undefined
      }
    >
      <LoginWidget
        getRedirectPath={getRedirectPath}
        audience={audience}
        onLoginSuccess={onLoginSuccess}
        withSso={audience === 'client'}
      />
    </QuoteContentWrapper>
  )
}

const NoAccountMessage = styled('span')`
  @media (${BelowTablet}) {
    display: none;
  }
`
