import { styled } from '@mui/material'

import { body1, greySet } from '@nuna/tunic'

export const Label = styled('label')`
  font-weight: bold;
  font-size: 14px;
`

export const AppHeading = styled('div')`
  margin-right: 16px;
  font-size: 1.8rem;
  font-weight: 300;
  display: flex;
  align-items: center;
`

export const Paragraph = styled('p')`
  font-size: 18px;
  color: ${greySet[70].hex};
  margin: 0;
`

export const Link = styled('a')`
  color: ${body1};
  cursor: pointer;
  :hover {
    text-decoration: underline;
    color: ${body1};
  }
`

export const SubtleParagraph = styled('div')`
  color: ${greySet[70].hex};
`
