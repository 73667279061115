import { isNil } from 'lodash'
import { useEffect, useRef, useState } from 'react'

import { ContextualAlert, IconInfo } from '@nuna/tunic'

import { useIsConnectedToChat } from '../../../hooks/useIsConnectedToChat'
import { TavaVideoUser } from '../../../types'

const NOTIFICATION_TIMEOUT = 6 * 1000 // 6 seconds

export function ChatOnlineIndicator({ user }: { user: TavaVideoUser }) {
  const isOnline = useIsConnectedToChat(user)
  const [isHidden, setIsHidden] = useState(true)
  const isFirstMount = useRef(true)

  useEffect(() => {
    if (isNil(isOnline)) return // since the call to get the online status is async, we ignore any changes in this effect until we have a boolean
    if (isFirstMount.current) {
      // Ignore the first mount so it doesn't show online indicators when the sidebar is opened.
      // We only want to show the indicators if the user goes from offline to online while it is open.
      isFirstMount.current = false
      return
    }

    if (isOnline) {
      setIsHidden(false)

      const timeout = setTimeout(() => setIsHidden(true), NOTIFICATION_TIMEOUT)
      return () => clearTimeout(timeout)
    }
  }, [isOnline])

  if (isOnline === false) {
    return (
      <ContextualAlert className="mb-1" intent="default" icon={<IconInfo />}>
        <span>
          {user.firstName} is <span className="text-error text-bold">Offline</span>. They won't receive your message
          until they come online.
        </span>
      </ContextualAlert>
    )
  }

  if (isOnline && !isHidden) {
    return (
      <ContextualAlert className="mb-1 full-width" intent="information" icon={<IconInfo />}>
        <span>
          {user.firstName} is <span className="text-bold">Online now</span>.
        </span>
      </ContextualAlert>
    )
  }

  return null
}
