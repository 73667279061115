import { useState } from 'react'
import { useParams, useSearchParams } from 'react-router-dom'

import { AddressOwner, AddressType, useProviderAddressesQuery } from '@nuna/api'
import { useAuthDataContext, useIsAdmin } from '@nuna/auth'
import { GhostButtonLink, Grid, PlaceholderButton } from '@nuna/tunic'

import { LocationCard } from './LocationCard'

export function Locations() {
  const { login } = useAuthDataContext()
  const { id: pathProviderId } = useParams<{ id?: string }>()
  const { providerId: loginProviderId } = login ?? {}
  const isAdmin = useIsAdmin()
  const providerId = isAdmin ? pathProviderId : loginProviderId || pathProviderId
  const [isOpenCreate, setIsOpenCreate] = useState(false)
  const [isOpenTavaCreate, setIsOpenTavaCreate] = useState(false)
  const [, setSearchParams] = useSearchParams()

  const { data: providerAddressData } = useProviderAddressesQuery({
    variables: {
      searchOptions: {
        providerId,
        providerAddressType: AddressType.ProviderPractice,
        owner: AddressOwner.Provider,
      },
    },
    skip: !providerId,
  })

  const { data: tavaVirtualAddressData } = useProviderAddressesQuery({
    variables: {
      searchOptions: {
        providerId,
        providerAddressType: AddressType.ProviderPractice,
        owner: AddressOwner.Tava,
      },
    },
    skip: !isAdmin,
  })

  const openAddressAssignmentDrawer = () => {
    if (!providerId) return
    setSearchParams(oldParams => {
      const newParams = new URLSearchParams(oldParams)
      newParams.append('assignPracticeAddresses', providerId)
      return newParams
    })
  }

  const { providerAddresses } = providerAddressData || {}

  return (
    <>
      <div>
        <div className="v-align mb-3 space-between">
          <h2 className="h4 mb-0">In-person practice locations</h2>
          <GhostButtonLink to={isAdmin ? '../../availability' : '/availability'}>Adjust availability</GhostButtonLink>
        </div>
        <Grid container wrap="wrap">
          <Grid size={12}>
            {providerAddresses?.map(address => (
              <LocationCard key={address.addressId} address={address} className="mb-2" />
            ))}
            {!isOpenCreate ? (
              <PlaceholderButton onClick={() => setIsOpenCreate(true)}>Add a location</PlaceholderButton>
            ) : (
              <LocationCard onCancel={() => setIsOpenCreate(false)} />
            )}
          </Grid>
        </Grid>
      </div>
      {isAdmin && (
        <div className="mt-5">
          <h2 className="h4 mb-3">Tava Virtual Locations</h2>
          <Grid container wrap="wrap">
            <Grid size={12}>
              {tavaVirtualAddressData?.providerAddresses?.map(address => (
                <LocationCard
                  key={address.addressId}
                  address={address}
                  className="mb-2"
                  isTavaVirtual={address.address.owner === AddressOwner.Tava}
                />
              ))}
              {!isOpenTavaCreate ? (
                <PlaceholderButton onClick={openAddressAssignmentDrawer}>Assign and manage locations</PlaceholderButton>
              ) : (
                <LocationCard onCancel={() => setIsOpenTavaCreate(false)} isTavaVirtual={true} />
              )}
            </Grid>
          </Grid>
        </div>
      )}
    </>
  )
}
