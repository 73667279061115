import { IconProps } from './IconProps'

export function IconPower({ size = 24, color = 'currentColor', ...props }: IconProps) {
  return (
    <svg width={size} height={size} viewBox="0 0 48 48" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M20.8633 3.25549V25.3927C20.8633 27.1461 22.2716 28.5578 24.0008 28.5578C25.73 28.5578 27.1383 27.1461 27.1383 25.4017V3.25549C27.1383 1.51112 25.73 0.0904541 24.0008 0.0904541C22.2716 0.0904541 20.8633 1.51112 20.8633 3.25549ZM14.0088 43.5377C16.3531 44.7426 18.8756 45.5069 21.4961 45.8126C22.3162 45.9115 23.1451 45.9565 23.9741 45.9565C27.8514 45.9565 31.7198 44.9224 35.0891 42.9623C36.7203 42.0002 38.2178 40.8223 39.5459 39.4825C43.6995 35.3104 45.9814 29.7446 45.9814 23.8282C45.9814 17.9117 43.6906 12.346 39.5459 8.16488C38.3158 6.93304 36.3281 6.92405 35.107 8.16488C33.8858 9.40572 33.8858 11.4108 35.107 12.6427C38.0662 15.6279 39.6974 19.6022 39.6974 23.8282C39.6974 28.0452 38.0662 32.0195 35.098 35.0137C34.1443 35.9758 33.0747 36.812 31.9159 37.4954C29.0102 39.1948 25.5606 39.9141 22.2181 39.5365C20.3463 39.3207 18.5458 38.7722 16.87 37.909C15.4171 37.1627 14.089 36.1826 12.9214 35.0047C9.9621 32.0195 8.33094 28.0452 8.33094 23.8192C8.33094 19.5932 9.9621 15.6189 12.9214 12.6337C14.1425 11.3929 14.1514 9.38774 12.9214 8.15589C11.6913 6.92405 9.70361 6.92405 8.48247 8.15589C4.33771 12.346 2.05587 17.9028 2.05587 23.8192C2.05587 29.7357 4.33771 35.2925 8.48247 39.4735C10.1136 41.119 11.9676 42.4857 14.0088 43.5377Z"
        fill={color}
      />
    </svg>
  )
}
