import { ReactNode, createContext, useContext } from 'react'

export interface CommonRoutes {
  adminSessionDetail: (appointmentId: string) => string
  sessionNote: (id: string) => string
  clientHarmonyProfile: (id: string) => string
}

export type FeatureMode = 'allOn' | 'allOff' | 'default'
type App = 'arrow' | 'harmony' | 'ashoka'

export interface EnvironmentContextValues {
  MIXPANEL_TOKEN: string
  API_ENDPOINT: string
  STRICT_MODE: boolean
  STRIPE_PUBLISHABLE_KEY?: string
  STYTCH_PUBLIC_TOKEN: string
  STYTCH_B2B_PUBLIC_TOKEN: string
  TWILIO_LOG_LEVEL?: string
  CI: boolean
  production: boolean
  LAUNCH_DARKLY_CLIENT_ID: string
  AG_GRID_LICENSE_KEY: string
  FEATURE_MODE: FeatureMode
  HARMONY_BASE_URL: string
  ARROW_BASE_URL: string
  ASHOKA_BASE_URL: string
  SANCTUARY_API_ENDPOINT: string
  SANCTUARY_API_KEY: string
  DROPBOX_SIGN_CLIENT_ID: string
  DAILY_BASE_URL: string
  KNOCK_PUBLIC_API_KEY: string
  KNOCK_FEED_CHANNEL_ID: string
}

interface PartialAdditionalAppVariables {
  commonRoutes: Partial<CommonRoutes>
  app: App | ''
}

interface AdditionalAppVariables {
  commonRoutes: CommonRoutes
  app: App | ''
}

export const EnvironmentContext = createContext<EnvironmentContextValues & AdditionalAppVariables>({
  MIXPANEL_TOKEN: '',
  API_ENDPOINT: 'http://localhost:5000',
  STRICT_MODE: false,
  STRIPE_PUBLISHABLE_KEY: '',
  STYTCH_PUBLIC_TOKEN: '',
  STYTCH_B2B_PUBLIC_TOKEN: '',
  TWILIO_LOG_LEVEL: 'info',
  HARMONY_BASE_URL: '',
  ARROW_BASE_URL: '',
  ASHOKA_BASE_URL: '',
  CI: false,
  production: false,
  commonRoutes: buildDefaultCommonRoutes(),
  LAUNCH_DARKLY_CLIENT_ID: '',
  AG_GRID_LICENSE_KEY: '',
  SANCTUARY_API_ENDPOINT: '',
  SANCTUARY_API_KEY: '',
  FEATURE_MODE: 'default',
  DROPBOX_SIGN_CLIENT_ID: '',
  app: '',
  DAILY_BASE_URL: '',
  KNOCK_FEED_CHANNEL_ID: '',
  KNOCK_PUBLIC_API_KEY: '',
})

interface Props {
  children: ReactNode
  environmentValues: EnvironmentContextValues & Partial<PartialAdditionalAppVariables>
}
export function EnvironmentContextProvider({ children, environmentValues }: Props) {
  const commonRoutes = { ...buildDefaultCommonRoutes(), ...(environmentValues.commonRoutes ?? {}) }
  const value: EnvironmentContextValues & AdditionalAppVariables = {
    ...environmentValues,
    commonRoutes: { ...buildDefaultCommonRoutes(), ...commonRoutes },
    app: environmentValues.app ?? '',
  }
  return <EnvironmentContext.Provider value={value}>{children}</EnvironmentContext.Provider>
}

export function useEnvironmentContext() {
  return useContext(EnvironmentContext)
}

function buildDefaultCommonRoutes(): CommonRoutes {
  return {
    sessionNote: () => '',
    clientHarmonyProfile: () => '',
    adminSessionDetail: () => '',
  }
}
