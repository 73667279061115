import * as Yup from 'yup'
import { Formik } from 'formik'
import { Fragment } from 'react'

import { AssessmentBundleDocument, AssessmentType, useSaveAssessmentBundleMutation } from '@nuna/api'
import { formService } from '@nuna/core'
import { ChipGroup, FillButtonWithChevron, Grid, Radio, toast } from '@nuna/tunic'

import { useAssessmentsContext } from '../../context/AssessmentsContext'
import { AssessmentFromBundleQuery, Gad7FormValues } from '../../types/types'
import { gad7Questions, gad7Responses, validationForAssessmentFields } from '../../util/assessments'

const { getFieldProps, composeNestedHelperTextWithError } = formService

interface Gad7Props {
  assessment: AssessmentFromBundleQuery
}

export function Gad7({ assessment }: Gad7Props) {
  const [saveBundle, { loading }] = useSaveAssessmentBundleMutation()
  const { assessmentBundle, navigateWithinAssessmentBundle } = useAssessmentsContext()
  const { id: bundleId = '' } = assessmentBundle ?? {}

  const handleSubmit = async (values: Gad7FormValues) => {
    try {
      const { data } = await saveBundle({
        variables: {
          data: {
            id: bundleId,
            assessments: [
              {
                id: assessment.id,
                type: AssessmentType.Gad_7,
                values,
              },
            ],
          },
        },
        refetchQueries: [{ query: AssessmentBundleDocument, variables: { bundleId } }],
      })
      navigateWithinAssessmentBundle(data?.saveAssessmentBundle)
    } catch (e) {
      console.error(e)
      toast.urgent('Error saving assessment')
    }
  }

  return (
    <Formik initialValues={buildInitialValues(assessment.values)} validationSchema={gad7Schema} onSubmit={handleSubmit}>
      {formikProps => {
        const { values, handleSubmit, setFieldValue, errors, touched, submitCount } = formikProps
        return (
          <form onSubmit={handleSubmit}>
            {gad7Questions.map(({ question, key }) => {
              const { error, onBlur, name } = getFieldProps(key, formikProps)

              return (
                <Fragment key={key}>
                  <p className="mb-2 text-secondary">Over the two last weeks, how often have you been bothered by…</p>
                  <h2 className="h5 mb-4" id={`${key}-heading`}>
                    {question}
                  </h2>

                  <ChipGroup
                    className="mb-6"
                    key={key}
                    aria-labelledby={`${key}-heading`}
                    {...composeNestedHelperTextWithError('', errors, touched, key, 'value', submitCount > 0)}
                  >
                    <Grid container>
                      {gad7Responses.map((response, index) => (
                        <Grid
                          key={response}
                          size={{
                            xs: 12,
                            sm: 'auto',
                          }}
                        >
                          <Radio
                            inline
                            checked={values[key].value === index}
                            onChange={() => setFieldValue(`${name}.value`, index)}
                            onBlur={onBlur}
                            name={`${name}.value`}
                            value={index.toString()}
                            dataTestId={`${name}-${index}`}
                            error={error}
                            className="fs-exclude"
                          >
                            {response}
                          </Radio>
                        </Grid>
                      ))}
                    </Grid>
                  </ChipGroup>
                </Fragment>
              )
            })}

            <FillButtonWithChevron type="submit" isLoading={loading}>
              Next
            </FillButtonWithChevron>
          </form>
        )
      }}
    </Formik>
  )
}

function buildInitialValues(data: AssessmentFromBundleQuery['values']): Gad7FormValues {
  const nullValue = { value: null }
  return {
    'gad7:1': data['gad7:1'] ?? nullValue,
    'gad7:2': data['gad7:2'] ?? nullValue,
    'gad7:3': data['gad7:3'] ?? nullValue,
    'gad7:4': data['gad7:4'] ?? nullValue,
    'gad7:5': data['gad7:5'] ?? nullValue,
    'gad7:6': data['gad7:6'] ?? nullValue,
    'gad7:7': data['gad7:7'] ?? nullValue,
  }
}

const gad7Schema = Yup.object().shape<Gad7FormValues>({
  'gad7:1': validationForAssessmentFields,
  'gad7:2': validationForAssessmentFields,
  'gad7:3': validationForAssessmentFields,
  'gad7:4': validationForAssessmentFields,
  'gad7:5': validationForAssessmentFields,
  'gad7:6': validationForAssessmentFields,
  'gad7:7': validationForAssessmentFields,
})
