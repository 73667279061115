import moment from 'moment'

import { AssessmentType } from '@nuna/api'
import { AssessmentFromAssessmentsQuery, Severity, TrendDirection, assessmentService } from '@nuna/assessments'
import { IntentEnum, greySet, salmonSet, tealSet, yellowSet } from '@nuna/tunic'

const { getAssessmentSeverityAndLabel } = assessmentService

export const isAssessmentRecent = (updatedAt: string, bufferDays: number) => {
  return moment().diff(updatedAt, 'days') <= bufferDays
}

export const getTrendText = (
  direction: TrendDirection,
  priorAssessment: AssessmentFromAssessmentsQuery | undefined,
  isCssrs: boolean,
) => {
  if (!priorAssessment) {
    return null
  }
  if (direction === TrendDirection.Neutral) {
    return <span>Same as last time</span>
  }
  const directionText = direction === TrendDirection.Up ? 'Up' : 'Down'
  if (isCssrs) {
    return (
      <span>
        {directionText} from {getAssessmentSeverityAndLabel(priorAssessment).label?.toLowerCase()} last time
      </span>
    )
  }
  return (
    <span>
      {directionText} from {priorAssessment?.score} last time
    </span>
  )
}

export const ASSESSMENT_BUFFER_DAYS = 60

export function calculateTrend(
  currentAssessment: AssessmentFromAssessmentsQuery | undefined,
  previousAssessment: AssessmentFromAssessmentsQuery | undefined,
) {
  let direction = TrendDirection.Neutral
  let trend = 0
  let showTrend = true

  if (currentAssessment?.type !== AssessmentType.CSsrs) {
    const currentScore = currentAssessment?.score ?? 0
    const priorScore = previousAssessment?.score ?? 0
    if (priorScore === 0) {
      showTrend = false // Do not show trend percentage when prior score is 0
    }

    trend = ((currentScore - priorScore) / Math.abs(priorScore)) * 100

    if (trend > 0) {
      direction = TrendDirection.Up
    } else if (trend < 0) {
      direction = TrendDirection.Down
    } else {
      direction = TrendDirection.Neutral
    }
  } else {
    const currentSeverity = getAssessmentSeverityAndLabel(currentAssessment).severity || 0
    const previousSeverity = getAssessmentSeverityAndLabel(previousAssessment).severity || 0
    trend = currentSeverity - previousSeverity

    if (trend > 0) {
      direction = TrendDirection.Up
    } else if (trend < 0) {
      direction = TrendDirection.Down
    } else {
      direction = TrendDirection.Neutral
    }
  }

  return { trend, direction, showTrend }
}

export function getColorBySeverity(severity: Severity) {
  switch (severity) {
    case Severity.Mild:
      return greySet[70].hex
    case Severity.Moderate:
      return yellowSet[80].hex
    case Severity.Severe:
      return salmonSet[80].hex
    default:
      return tealSet[70].hex
  }
}

export function getStatusIntentBySeverity(severity: Severity) {
  switch (severity) {
    case Severity.Mild:
      return IntentEnum.Default
    case Severity.Moderate:
      return IntentEnum.Caution
    case Severity.Severe:
      return IntentEnum.Urgent
    default:
      return IntentEnum.Information
  }
}
