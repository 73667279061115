import { styled } from '@mui/material'
import { TextFieldProps } from '@mui/material'
import React, { useEffect, useRef } from 'react'

import { ConditionalTextField } from '../ConditionalTextField'
import { Radio, RadioProps } from './Radio'

export interface RadioTextComboProps {
  children: React.ReactNode
  radioProps: Omit<RadioProps, 'children'>
  textFieldProps: TextFieldProps
}

export function RadioTextCombo({ radioProps, textFieldProps, children }: RadioTextComboProps) {
  const textFieldRef = useRef<HTMLInputElement>(null)

  useEffect(() => {
    if (radioProps.checked) {
      window.setTimeout(() => {
        textFieldRef.current && textFieldRef.current.focus()
      }, 250)
    }
  }, [radioProps.checked])

  return (
    <>
      <Radio {...radioProps}>{children}</Radio>

      <ConditionalTextFieldWithPadding isOpen={radioProps.checked} inputRef={textFieldRef} {...textFieldProps} />
    </>
  )
}

const ConditionalTextFieldWithPadding = styled(ConditionalTextField)`
  && {
    &,
    label {
      padding-left: 1.75rem;

      &.MuiInputLabel-shrink {
        left: 0.5rem;
      }
    }
  }
`
