import * as Yup from 'yup'
import moment from 'moment'

import { passwordService } from './password'

const firstNameSchema = Yup.string().required('First name is required')
const lastNameSchema = Yup.string().required('Last name is required')
const emailSchema = Yup.string().required('Email is required').email("Oops, that email isn't formatted correctly")
const stateSchema = Yup.string().required()
const agreesToTermsSchema = Yup.bool().oneOf([true], 'Field must be checked')

const dobSchema = Yup.date()
  .required('Date of birth is required')
  .min(moment().subtract('125', 'years').toDate(), 'Date of birth is out of range')
  .max(moment().subtract('13', 'years').toDate(), 'Sorry, therapy is only available to age 13+')
  .typeError('Date is invalid')

const parentGuardianEmailSchema = Yup.string().when('dob', {
  is: dob => moment().diff(dob, 'years') >= 13 && moment().diff(dob, 'years') < 18,
  then: Yup.string()
    .email("Oops, that email isn't formatted correctly")
    .required('A parent/guardian email is required for minors'),
  otherwise: Yup.string(),
})

const passwordSchema = Yup.string()
  .matches(passwordService.passwordRegex, 'Does not meet password requirements')
  .required('Password is required')

const confirmPasswordSchema = Yup.string()
  .required('Please confirm your password')
  .oneOf([Yup.ref('password'), null], 'Passwords must match')

export interface SignupValues {
  firstName: string
  lastName: string
  email: string
  dob: string
  state: string
  password: string
  confirmPassword: string
  agreesToTerms: boolean
  parentGuardianEmail: string
}

export const signupService = {
  firstNameSchema,
  lastNameSchema,
  emailSchema,
  dobSchema,
  stateSchema,
  parentGuardianEmailSchema,
  agreesToTermsSchema,
  passwordSchema,
  confirmPasswordSchema,
}
